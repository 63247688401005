import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import DetailNewSubscribersMobile from './DetailNewSubscribersMobile';
import DetailNewSubscribersEmail from './DetailNewSubscribersEmail';
import DetailNewSubscribersSms from './DetailNewSubscribersSms';
import DetailNewSubscribersCall from './DetailNewSubscribersCall';

const DetailNewSubscribers = ({
  data,
  fetchState,
}) => {
  const [mobileExpanded, setMobileExpanded] = useState(false);
  const [emailExpanded, setEmailExpanded] = useState(false);
  const [smsExpanded, setSmsExpanded] = useState(false);
  const [callExpanded, setCallExpanded] = useState(false);

  useEffect(() => {
    if (fetchState === 'send-request') {
      setMobileExpanded(false);
      setEmailExpanded(false);
      setSmsExpanded(false);
      setCallExpanded(false);
    }
  }, [fetchState]);

  return (
    <>
      <DetailNewSubscribersMobile
        data={data.mobile}
        expanded={mobileExpanded}
        onClick={() => setMobileExpanded(!mobileExpanded)}
      />
      <DetailNewSubscribersEmail
        data={data.email}
        expanded={emailExpanded}
        onClick={() => setEmailExpanded(!emailExpanded)}
      />
      <DetailNewSubscribersSms
        data={data.sms}
        expanded={smsExpanded}
        onClick={() => setSmsExpanded(!smsExpanded)}
      />
      <DetailNewSubscribersCall
        data={data.phone}
        expanded={callExpanded}
        onClick={() => setCallExpanded(!callExpanded)}
      />
    </>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.newSubscribersReport.fetchState
});

export default connect(mapStateToProps, null)(DetailNewSubscribers);
