const actionTypes = {
  // Event actionTypes for setting loading status
  SET_EVENT_LOADING_ACTION: 'SET_EVENT_LOADING_ACTION',
  SET_EVENT_SNACKBAR_ACTION: 'SET_EVENT_SNACKBAR_ACTION',

  // Event actionTypes for setting the stage of event wizard
  SET_EVENT_WIZARD_STAGE_ACTION: 'SET_EVENT_WIZARD_STAGE_ACTION',

  // Event actionTypes for setting event wizard id
  SET_EVENT_WIZARD_ID_ACTION: 'SET_EVENT_WIZARD_ID_ACTION',

  // Event actionTypes for fetching all events
  GET_EVENTS_LIST_REQUEST: 'GET_EVENTS_LIST_REQUEST',
  GET_EVENTS_LIST_RECEIVE: 'GET_EVENTS_LIST_RECEIVE',
  GET_EVENTS_LIST_FAIL: 'GET_EVENTS_LIST_FAIL',

  // Event actionTypes for Creating an event
  CREATE_EVENT_REQUEST: 'CREATE_EVENT_REQUEST',
  CREATE_EVENT_RECEIVE: 'CREATE_EVENT_RECEIVE',
  CREATE_EVENT_FAIL: 'CREATE_EVENT_FAIL',

  // Event actionTypes for editing an event
  EDIT_EVENT_REQUEST: 'EDIT_EVENT_REQUEST',
  EDIT_EVENT_RECEIVE: 'EDIT_EVENT_RECEIVE',
  EDIT_EVENT_FAIL: 'EDIT_EVENT_FAIL',

  // Event actionTypes for fetching google address suggestions
  GET_EVENT_ADDRESS_SUGGESTIONS_REQUEST: 'GET_EVENT_ADDRESS_SUGGESTIONS_REQUEST',
  GET_EVENT_ADDRESS_SUGGESTIONS_RECEIVE: 'GET_EVENT_ADDRESS_SUGGESTIONS_RECEIVE',
  GET_EVENT_ADDRESS_SUGGESTIONS_FAIL: 'GET_EVENT_ADDRESS_SUGGESTIONS_FAIL',

  // // Event actionTypes for finding location from coordinates
  // GET_EVENT_COORDINATES_REQUEST: 'GET_EVENT_COORDINATES_REQUEST',
  // GET_EVENT_COORDINATES_RECEIVE: 'GET_EVENT_COORDINATES_RECEIVE',
  // GET_EVENT_COORDINATES_FAIL: 'GET_EVENT_COORDINATES_FAIL',

  // Event actionTypes for removing an event
  DELETE_EVENT_REQUEST: 'DELETE_EVENT_REQUEST',
  DELETE_EVENT_RECEIVE: 'DELETE_EVENT_RECEIVE',
  DELETE_EVENT_FAIL: 'DELETE_EVENT_FAIL',
};

export default actionTypes;
