import React from 'react';
import { Text, View, StyleSheet, } from '@react-pdf/renderer';
import { addCommasToNumber } from '../../../../utils';
// Create styles
const styles = StyleSheet.create({
  h2: {
    fontSize: '20pt'
  },
  h6: {
    fontSize: '12pt'
  },
  marginLeft: {
    marginLeft: 15
  },
  body: {
    fontSize: '10pt'
  },
  image: {
    width: '110px',
    height: '40px',
    marginLeft: '13px',
    marginTop: '5px',
  }
});

const stripTags = (data) => {
  let temp = data.replace(/(<([^>]+)>)/ig, '');
  temp = temp.replaceAll('&nbsp', ' ');
  return temp;
};

// Create Document Component
const SpotlightReportPDF = ({
  data,
  spMaterialName,
  spMaterialView,
}) => (
  <>
    <View style={{
      width: '100%',
      marginRight: '20pt',
      minHeight: '40px',
      borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
      paddingTop: '10px',
      paddingBottom: '10px',
      justifyContent: 'center',
      alignItems: 'center'
    }}
    >
      <Text style={styles.h2}>
        Material Spotlight
      </Text>
    </View>

    <View style={{
      width: '100%',
      marginRight: '20pt',
      minHeight: '25px',
      flexDirection: 'row',
      borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
      marginTop: '10px'
    }}
    >

      <View style={{
        width: '70%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingBottom: '10px',
        paddingLeft: '5px'
      }}
      >
        <Text style={styles.h6}>
          {spMaterialName}
        </Text>
      </View>

      <View style={{
        width: '30%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '10px',
      }}
      >
        <Text style={styles.h6}>
          {spMaterialView}
        </Text>
      </View>

    </View>
    {
      data
      && [...data].sort((a, b) => Number(b.max) - Number(a.max))
        .map((item, index) => (
          <View
            key={`sp_material_pdf_item_${String(index)}`}
            style={{
              flexGrow: 1,
              width: '100%',
              minHeight: '40px',
              flexDirection: 'row',
              borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
              backgroundColor: index % 2 ? process.env.REACT_APP_COLOR_PRIMARY_BG : null,
              paddingTop: '5px',
              paddingBottom: '5x'
            }}
          >
            <View style={{
              width: '70%',
              justifyContent: 'center',
              alignItems: 'flex-start',
              paddingRight: '5pt',
              paddingLeft: '5pt',
            }}
            >
              <Text style={styles.body}>
                {stripTags(item.name) || ''}
              </Text>
            </View>

            <View style={{
              width: '30%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
            >
              <Text style={styles.body}>
                {item.max ? addCommasToNumber(item.max) : ''}
              </Text>
            </View>

          </View>
        ))
    }
  </>
);
export default SpotlightReportPDF;
