import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

import { Typography, CustomButton } from '../../components';

import { toAbsoluteUrl } from '../../../../_metronic';

const useStyles = makeStyles((theme) => ({
  headerText: {
    fontSize: 36,
    fontWeight: 700,
    letterSpacing: '1.8px',
    marginTop: 110,
  },
  learnMoreButton: {
    width: 192,
    height: 48,
    margin: '100px 0',
  },
  titleText: {
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: '1px',
  },
  subTitleText: {
    color: 'rgba(64, 64, 64, 0.80)',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '0.56px',
  },
  promoContainer: {
    marginBottom: 50,
  },
  buttonContainer: {
    display: 'flex',
    gap: 20,
  },
  setupButton: {
    maxWidth: 360,
    height: 48,
    margin: '100px 0',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    width: '60%',
    flexDirection: 'column',
  }
}));

const CompletedPerpetualSchedulesSetup = ({
  intl,
  exitWizard
}) => {
  const classes = useStyles();


  const handleExit = () => {
    exitWizard();
  };

  return (
    <>
      <div className="d-flex flex-wrap mt-3 justify-content-center text-center">

        <div className={classes.contentContainer}>

          <Typography variant="h1" className={classes.headerText}>
            {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES.SETUP.COMPLETED' })}
          </Typography>
          <img
            alt="Perpetual Schedules"
            width={400}
            height={400}
            src={toAbsoluteUrl('/media/icons/PerpetualSchedules/setup-completed.svg')}
          />
          <Typography variant="body">
            {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES.ALL.DONE.1' })}
          </Typography>
          <CustomButton
            variant="contained"
            label={(
              <Typography variant="h2" color="white">
                {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES.EXIT' })}
              </Typography>
            )}
            onClick={() => handleExit()}
            className={classes.learnMoreButton}
          />
        </div>

      </div>
    </>
  );
};

export default injectIntl(CompletedPerpetualSchedulesSetup);
