import React, { useState, useEffect } from 'react';
import Portal from '@material-ui/core/Portal';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Snackbar = ({
  message,
  status = 'success',
  closeAction,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    if (closeAction) {
      closeAction();
    }
  };

  return (
    <Portal>
      {message && (
        <MuiSnackbar
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity={status}
          >
            {message}
          </MuiAlert>
        </MuiSnackbar>
      )}
    </Portal>
  );
};

export default Snackbar;
