import moment from 'moment';

const CustomMonthHeader = ({ date }) => {
  return (
    <div className="rbc-header">
      {moment(date).format('dddd')}
    </div>
  );
};

export default CustomMonthHeader;
