/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { ListLoading } from '../../components/loading';

import * as actions from '../redux/actions';
import {
  Dialog,
  IconButton,
  Typography,
  Pagination
} from '../../components';
import {
  DeleteIcon,
  EditIcon,
  CloseIcon,
} from '../../../assets/icons';

const CustomList = ({
  intl,
  type,
  fetchState,
  reminderListRequest,
  deleteReminderRequest,
  loadReminderRequest,
  reminderList,
  reminderCount,
}) => {
  const useStyles = makeStyles((theme) => ({
    expanded: {
      backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_BG,
      boxShadow: `-15px 0px 0px 0px ${process.env.REACT_APP_COLOR_PRIMARY_BG}, 15px 0px 0px 0px ${process.env.REACT_APP_COLOR_PRIMARY_BG}`,
    },
    root: {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      }
    },
    header: {
      fontSize: '18px',
      fontWeight: 700,
      justifyContent: 'flex-start',
      color: process.env.REACT_APP_COLOR_PRIMARY,
      textAlign: 'left',
      textTransform: 'none',
    },
    close: {
      fontSize: '15px',
      fontWeight: 600,
      textTransform: 'none',
      color: process.env.REACT_APP_COLOR_PRIMARY,
    },
    extra: {
      borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    },
    emptyList: {
      color: process.env.REACT_APP_COLOR_BACKGROUND_FILL,
      marginTop: theme.spacing(30)
    },
    pagination: {
      width: 'max-content',
      '& ul': {
        justifyContent: 'flex-end',
        width: 'max-content',
      }
    }
  }));

  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);
  const [page, setPage] = useState(1);
  const isActive = type === 'active';
  const isSendRequest = fetchState === 'send-request';

  useEffect(() => {
    setPage(1);
    reminderListRequest({
      type,
      page: 1
    });
  }, [type, reminderListRequest]);

  const handlePaginationChange = (event, page) => {
    setPage(page);
    reminderListRequest({
      type,
      page
    });
  };
  return (
    <div className="h-100 d-flex flex-column justify-content-between">
      {
        isSendRequest
          ? (
            <div className="w-100">
              <ListLoading width="100%" />
            </div>
          ) : (
            <>
              <div>
                {reminderCount ? reminderList.map((item, index) => (
                  <div
                    key={item.reminder_id}
                    className={expanded === item.reminder_id ? classes.expanded : null}
                  >
                    {!isSendRequest ? (
                      <>
                        <div
                          className={`row ${clsx({ 'pt-4': index !== 0 })}`}
                        >
                          <Button
                            className="col-10 pb-0"
                            classes={{
                              root: classes.root,
                              label: classes.header,
                            }}
                            onClick={() => setExpanded(item.reminder_id)}
                          >
                            {item.schedule_from}
                            {' '}
                            {intl.formatMessage({ id: 'GENERAL.TO.SMALL' })}
                            {' '}
                            {item.schedule_to}
                          </Button>
                          {expanded === item.reminder_id
                            ? (
                              <div className="col-2 d-flex justify-content-end">
                                <Button
                                  className="pb-0"
                                  classes={{
                                    root: classes.root,
                                    label: classes.close,
                                  }}
                                  onClick={() => setExpanded(null)}
                                  endIcon={
                                    <CloseIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                                  }
                                >
                                  Close
                                </Button>
                              </div>
                            ) : (
                              <div className="col-xl-2 d-flex justify-content-end align-items-center">
                                {isActive && (
                                  <>
                                    <IconButton
                                      tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
                                      onClick={() => loadReminderRequest(item.reminder_id)}
                                    >
                                      <EditIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                                    </IconButton>
                                    <Dialog
                                      iconTooltip={intl.formatMessage({ id: 'GENERAL.DELETE' })}
                                      button={(
                                        <IconButton
                                          tooltip={intl.formatMessage({ id: 'GENERAL.DELETE' })}
                                          onClick={() => { }}
                                        >
                                          <DeleteIcon color={process.env.REACT_APP_COLOR_DELETE} />
                                        </IconButton>
                                      )}
                                      title={(
                                        <Typography variant="h1">
                                          {intl.formatMessage({ id: 'GENERAL.DELETE' })}
                                        </Typography>
                                      )}
                                      content={(
                                        <Typography variant="body">
                                          {intl.formatMessage({ id: 'REMINDER.DELETE_REMINDER.HEADER' })}
                                        </Typography>
                                      )}
                                      submitText={intl.formatMessage({ id: 'GENERAL.YES' })}
                                      handleSubmit={() => deleteReminderRequest({
                                        id: item.reminder_id,
                                        type,
                                        page,
                                      })}
                                    />
                                  </>
                                )}
                              </div>
                            )}
                        </div>
                        {expanded === item.reminder_id ? (
                          <>
                            <Typography className="mb-3" variant="body">
                              <span>Message for:</span>
                              {' '}
                              <strong>
                                {item.all_col === 'f' ? item.collection_name : 'All'}
                              </strong>
                            </Typography>
                            <Typography variant="body">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.message_body
                                }}
                              />
                            </Typography>
                          </>
                        )
                          : (
                            <>
                              <Typography className="mb-3" variant="body">
                                <span>Message for:</span>
                                {' '}
                                <strong>
                                  {item.all_col === 't' ? 'All' : 'Selected collections'}
                                </strong>
                              </Typography>
                              <Typography variant="body">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: `${item.message_body.substring(0, 120)} ${item.message_body.length > 120 ? ' ...' : ''}`
                                  }}
                                />
                              </Typography>
                            </>
                          )}
                        <div className={`${classes.extra} d-flex justify-content-between mt-3 pb-3`}>
                          <Typography variant="body">
                            <span>District:</span>
                            {' '}
                            <strong>
                              {item.district_name === 'All Districts'
                                ? 'All'
                                : item.district_name}
                            </strong>
                          </Typography>
                          <Typography variant="body">
                            <strong>
                              Scheduled by:
                              {' '}
                              {item.user_name}
                            </strong>
                          </Typography>
                        </div>
                      </>
                    ) : null}
                  </div>
                )) : (
                  <div className="h-100 d-flex justify-content-center">
                    <Typography className={classes.emptyList} variant="h2">
                      No
                      {' '}
                      {type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}
                      {' '}
                      Reminder
                    </Typography>
                  </div>
                )}
              </div>
              {
                reminderCount && (
                  <div
                    className={`pt-2 pb-4 d-flex justify-content-end align-items-center ${clsx({ 'justify-content-center': window.innerWidth < 700 })}`}
                  >
                    <Pagination
                      pager={false}
                      page={page}
                      rowsPerPage={5}
                      handlePageChange={(value) => handlePaginationChange(value)}
                      handleRowsPerPageChange={() => { }}
                      dataLength={reminderCount}
                      mobileView={window.innerWidth < 700}
                    />
                  </div>
                )
              }
            </>
          )
      }
    </div>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.reminder.fetchState,
  reminderList: store.reminder.list,
  reminderCount: store.reminder.listCount,
});

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(CustomList)
);
