import React from 'react';
import { injectIntl } from 'react-intl';

import CustomButton from '../../components/CustomButton';
import { PreviewIcon } from '../../../assets/icons';

const Preview = ({ onClick, intl }) => (
  <CustomButton
    variant="contained"
    label={(() => (
      <>
        <PreviewIcon color="#fff" style={{ fontSize: 23 }} />
        <span style={{ paddingLeft: 10 }}>
          {intl.formatMessage({ id: 'GENERAL.PREVIEW' })}
        </span>
      </>
    ))()}
    onClick={onClick}
  />
);

export default injectIntl(Preview);
