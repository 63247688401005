/* eslint-disable import/prefer-default-export */
import cts from '../../../crud/cts';

/**
 * PageViews report API which fetches material report data
 */
export const getPageViewsReport = async ({
  municipality, from, to, county
}) => cts(
  'get',
  `reports/page-view?county=${county}&from=${from}&to=${to}&municipality=${municipality}`,
);
