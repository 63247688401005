import React from 'react';

const LogoutIcon = ({
  color,
  className,
  style
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg
    width="1em"
    fill={color}
    version="1.1"
    x="0px"
    y="0px"
    className={className}
    style={style}
    viewBox="0 0 384.971 384.971"
  >
    <g>
      <g>
        <path
          strokeWidth="12"
          d="M180.455,360.91H24.061V24.061h156.394c6.641,0,12.03-5.39,12.03-12.03s-5.39-12.03-12.03-12.03H12.03 C5.39,0.001,0,5.39,0,12.031V372.94c0,6.641,5.39,12.03,12.03,12.03h168.424c6.641,0,12.03-5.39,12.03-12.03
C192.485,366.299,187.095,360.91,180.455,360.91z"
        />
        <path
          strokeWidth="12"
          d="M381.481,184.088l-83.009-84.2c-4.704-4.752-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l62.558,63.46H96.279 c-6.641,0-12.03,5.438-12.03,12.151c0,6.713,5.39,12.151,12.03,12.151h247.74l-62.558,63.46c-4.704,4.752-4.704,12.439,0,17.179
c4.704,4.752,12.319,4.752,17.011,0l82.997-84.2C386.113,196.588,386.161,188.756,381.481,184.088z"
        />
      </g>
    </g>
  </svg>
);

export default LogoutIcon;
