import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { Portlet } from '../../../../_metronic/partials/content/Portlet';
import {
  Typography, Opacity, CustomButton
} from '../../components';
import Tabs from './Tabs';

const useStyles = makeStyles({
  header: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    padding: '15px 0'
  },
});

const Widget = ({
  intl,
  widgetData,
  selectedWidgetTabIndex,
  setSelectedWidgetTabIndex
}) => {
  const classes = useStyles();

  const selectedtabWidgetData = widgetData[selectedWidgetTabIndex - 1];

  return (
    <Portlet>
      <Typography variant="h1" className={`px-4 ${classes.header}`}>
        {intl.formatMessage({ id: 'POPULAR.WGW.DATABASE.ACTIVITIES' })}
      </Typography>

      {/* tabs */}
      <Tabs
        data={widgetData}
        setSelectedIndex={setSelectedWidgetTabIndex}
        selectedIndex={selectedWidgetTabIndex}
      />

      {/* content */}
      <Opacity slide key={selectedWidgetTabIndex}>
        <div className="px-4">
          <Typography variant="h2" color="#0789C4">
            {intl.formatMessage({ id: selectedtabWidgetData.title })}
          </Typography>
          <Typography className="my-3" variant="body" color={process.env.REACT_APP_COLOR_TEXT}>
            {intl.formatMessage({ id: selectedtabWidgetData.content })}
          </Typography>
          <div className="d-flex justify-content-end py-4">
            <CustomButton
              onClick={selectedtabWidgetData.onButtonClick}
              variant="contained"
              fontSize={13}
              paddingVertical={3}
              style={{ width: 'max-content' }}
              label={<FormattedMessage id={selectedtabWidgetData.buttonName} />}
            />
          </div>
        </div>
      </Opacity>
    </Portlet>
  );
};

export default injectIntl(Widget);
