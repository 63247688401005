import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { Snackbar } from '../components';
import { Header, Events } from './components';
import {
  useLocation, useHistory
} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  content: {
    width: '85%',
    margin: 'auto',
    [theme.breakpoints.down(1350)]: {
      width: '95%'
    },
    [theme.breakpoints.down(900)]: {
      width: '100%'
    },
  }
}));

const Event = ({
  responseMessage,
  responseStatus,
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation()
  const history = useHistory()
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const queryParamSearch = queryParams.get('search');
    if (queryParamSearch) {
      setSearchTerm(decodeURI(queryParamSearch));
      queryParams.delete('search');
      history.replace({
        search: queryParams.toString(),
      })
    }
  }, []);

  return (
    <div className={classes.content}>
      <Snackbar
        message={responseMessage}
        status={responseStatus}
      />
      <Header
        searchTerm={searchTerm}
        onSearchTermChange={setSearchTerm}
      />
      <Events
        searchTerm={searchTerm}
      />
    </div>
  );
};

const mapStateToProps = (store) => ({
  responseMessage: store.event.responseMessage,
  responseStatus: store.event.responseStatus,
});

export default connect(mapStateToProps, null)(Event);
