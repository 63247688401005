import actionTypes from './actionTypes';

// Discovery zone report actions for setting loading status
export const setDiscoveryZoneReportLoadingAction = (status) => ({
  type: actionTypes.SET_DISCOVERY_ZONE_REPORT_LOADING_ACTION,
  payload: status
});

// Discovery zone report actions for setting all time mode
export const setDiscoveryZoneReportAllTimeAction = (toDate) => ({
  type: actionTypes.SET_DISCOVERY_ZONE_REPORT_ALL_TIME_ACTION,
  payload: toDate
});

// Discovery zone report actions for fetching report data
export const DiscoveryZoneReportRequest = ({
  municipality, from, to, county
}) => ({
  type: actionTypes.GET_DISCOVERY_ZONE_REPORT_REQUEST,
  payload: {
    municipality, from, to, county
  }
});

export const DiscoveryZoneReportReceive = (data) => ({
  type: actionTypes.GET_DISCOVERY_ZONE_REPORT_RECEIVE,
  payload: { data }
});

export const DiscoveryZoneReportFail = (error) => ({
  type: actionTypes.GET_DISCOVERY_ZONE_REPORT_FAIL,
  payload: { ...error }
});
