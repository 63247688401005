import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableLoading } from '../../../components/loading';

import {
  Portlet, PortletBody
} from '../../../../../_metronic/partials/content/Portlet';
import { Tab } from '../../../components';
import dizyReportTabName from '../helper';
import RecyclingFundamentalTable from './RecyclingFundamentalTable';
import MisunderstoodMaterialsTable from './MisunderstoodMaterialsTable';
import BlogReport from './BlogReport';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_PRIMARY,
  },
  seletBar: {
    border: '10px !important',
    marginTop: '21px',
    [theme.breakpoints.down(992)]: {
      paddingBottom: '20px !important'
    }
  },
  tabs: {
    marginTop: '28px',
    marginBottom: '15px',
    marginLeft: '5px',
  }
}));

const Detail = ({
  fetchState,
  data,
  isAllDistricts
}) => {
  const classes = useStyles();
  const [tabNumber, setTabNumber] = useState(0);
  const isSendRequest = fetchState === 'send-request';

  const handleTabChange = (_, number) => {
    setTabNumber(number);
  };

  return (
    <Portlet>
      <PortletBody className="pt-0">
        {
          isSendRequest ? (
            <div className="pt-4">
              <TableLoading width="100%" />
            </div>
          ) : (
            <div className="row w-100 mb-4 flex-column">
              <div className={classes.tabs}>
                <Tab
                  selectedTab={tabNumber}
                  onChange={handleTabChange}
                  labels={dizyReportTabName}
                  unselectedColor={process.env.REACT_APP_COLOR_DISABLED_TEXT}
                  selectedBottomBorderColor={process.env.REACT_APP_COLOR_SECONDARY}
                />
                {tabNumber === 0 && (
                <RecyclingFundamentalTable
                  data={data && data.rf ? data.rf : null}
                />
                )}
                {tabNumber === 1 && (
                <MisunderstoodMaterialsTable
                  isAllDistricts={isAllDistricts}
                  data={data && data.mm ? Object.values(data.mm) : null}
                />
                )}
                {tabNumber === 2 && (
                <BlogReport
                  data={data.blog ? data.blog : null}
                />
                )}

              </div>
            </div>
          )
        }
      </PortletBody>
    </Portlet>
  );
};


export default Detail;
