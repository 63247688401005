/**
 *
 */
const initialState = {
  campaigns: [],
  tab: 'current_schedule',
  switchItem: [],
  district: {}
};

export default initialState;
