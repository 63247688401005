import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { SwitchIcon } from '../../../assets/icons';

const useStyles = makeStyles({
  container: {
    borderRadius: '50%',
    backgroundColor: process.env.REACT_APP_COLOR_TERTIARY,
    marginLeft: 12,
    paddingTop: 4,
    width: 50,
    height: 50,
    display: 'inline-block',
    fontSize: 26,
    '&.switch-item': {
      width: 35,
      height: 35,
      fontSize: 19,
      paddingLeft: 7
    }
  }
});

const SwitchBadge = ({ switchItem }) => {
  const { container } = useStyles();
  let classes = container;

  if (switchItem) {
    classes = classNames(container, 'switch-item');
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <span className={classes}>
      <SwitchIcon color="#fff" />
    </span>
  );
};

export default SwitchBadge;
