const actionTypes = {
  GET_FEATURED_LESSONS_DATA_REQUEST: 'GET_FEATURED_LESSONS_DATA_REQUEST',
  GET_FEATURED_LESSONS_DATA_RECEIVE: 'GET_FEATURED_LESSONS_DATA_RECEIVE',
  GET_FEATURED_LESSONS_DATA_FAIL: 'GET_FEATURED_LESSONS_DATA_FAIL',
  FEATURED_LESSON_READ_REQUEST: 'FEATURED_LESSON_READ_REQUEST',
  FEATURED_LESSON_READ_RECEIVE: 'FEATURED_LESSON_READ_RECEIVE',
  FEATURED_LESSON_READ_FAIL: 'FEATURED_LESSON_READ_FAIL',
  FEATURED_LESSON_UNREAD_REQUEST: 'FEATURED_LESSON_UNREAD_REQUEST',
  FEATURED_LESSON_UNREAD_RECEIVE: 'FEATURED_LESSON_UNREAD_RECEIVE',
  FEATURED_LESSON_UNREAD_FAIL: 'FEATURED_LESSON_UNREAD_FAIL',
  FEATURED_LESSON_FEEDBACK_REQUEST: 'FEATURED_LESSON_FEEDBACK_REQUEST',
  FEATURED_LESSON_FEEDBACK_RECEIVE: 'FEATURED_LESSON_FEEDBACK_RECEIVE',
  FEATURED_LESSON_FEEDBACK_FAIL: 'FEATURED_LESSON_FEEDBACK_FAIL',
};

export default actionTypes;
