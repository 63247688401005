/* eslint-disable import/prefer-default-export */
import cts from '../../../crud/cts';

/**
 * Campaign report API which fetches interaction report data
 */
export const getCampaignReport = async ({
  municipality, from, to, county
}) => cts(
  'get',
  `reports/campaigns?from=${from}&to=${to}&county=${county}&municipality=${municipality}`,
);


export const campaignCdnAddress = 'https://cdn.recyclecoach.com/campaigns/';
