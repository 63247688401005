import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  Typography, CheckBox, CustomButton
} from '../../../components';
import {
  Portlet,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';

import * as actions from '../redux/actions';
import * as sharedActions from '../../../redux/actions';
import * as loginActions from '../../../Auth/Login/redux/actions';

const useStyles = makeStyles((theme) => ({
  button: {
    width: 150,
    height: 48,
    marginTop: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 20,
    gap: 20
  },
}));

const MultiDistrict = ({
  intl,
  district,
  municipalities,
  setDistrictAction,
  getPerpetualSchedulesRequest,
  perpetualSchedules,
  setMultiDistricts,
  managePerpetualSchedulesRequest,
  setCompletedSetup,
  updateUserInfoManagesSchedules
}) => {
  useEffect(() => {
    setMultiDistricts([]);
  }, []);
  useEffect(() => {
    if (district.district_id === '#all-districts#') {
      setDistrictAction(municipalities[0]);
    } else {
      const districts = municipalities.map((municipality) => municipality.district_id);
      getPerpetualSchedulesRequest(districts);
    }
  }, [district]);
  useEffect(() => {

    if (perpetualSchedules && municipalities.length > 0) {
      const scheduleIds = perpetualSchedules.map((schedule) => `${schedule.project_id}#$#${schedule.district_id}`);
      const municipalitiesNotInSchedules = municipalities.filter((municipality) => !scheduleIds.includes(municipality.district_id)).map((municipality) => ({
        ...municipality,
        checked: false
      }));
      setDistrictsWithoutPerpetualSchedules(municipalitiesNotInSchedules);
    }
  }, [perpetualSchedules, municipalities]);

  const [selectAllDistricts, setSelectAllDistricts] = useState(false);
  const [districtsWithoutPerpetualSchedules, setDistrictsWithoutPerpetualSchedules] = useState([]);
  const [doNotManage, setDoNotManage] = useState(false);

  const history = useHistory();
  const classes = useStyles();


  useEffect(() => {
    if (selectAllDistricts) {
      const updatedDistricts = districtsWithoutPerpetualSchedules.map((district) => ({
        ...district,
        checked: selectAllDistricts
      }));
      setDistrictsWithoutPerpetualSchedules(updatedDistricts);
    }
  }, [selectAllDistricts]);

  useEffect(() => {
    const allChecked = districtsWithoutPerpetualSchedules.length && districtsWithoutPerpetualSchedules.every((district) => district.checked);
    if (allChecked) {
      setSelectAllDistricts(true);
    } else if (selectAllDistricts) {
      setSelectAllDistricts(false);
      setDoNotManage(false);
    }
  }, [districtsWithoutPerpetualSchedules]);

  useEffect(() => {
    if (doNotManage) {
      const updatedDistricts = districtsWithoutPerpetualSchedules.map((district) => ({
        ...district,
        checked: false
      }));
      setDistrictsWithoutPerpetualSchedules(updatedDistricts);
      setSelectAllDistricts(false);
    }
  }, [doNotManage]);

  const handleSelectAll = () => {
    const updatedDistricts = districtsWithoutPerpetualSchedules.map((district) => ({
      ...district,
      checked: !selectAllDistricts
    }));
    setDistrictsWithoutPerpetualSchedules(updatedDistricts);
    setSelectAllDistricts(!selectAllDistricts);
    setDoNotManage(false);
  };

  const goBackHandler = () => {
    history.push('/schedules');
  };

  const goNextHandler = async () => {
    if (doNotManage) {
      updateUserInfoManagesSchedules('f');
      managePerpetualSchedulesRequest('false');
      setCompletedSetup(true);
      setTimeout(() => {
        goBackHandler();
      }, 500);
    } else {
      setMultiDistricts(districtsWithoutPerpetualSchedules.filter((district) => district.checked).map((district) => district.district_id));
    }
  };

  const canGoNext = districtsWithoutPerpetualSchedules.some((district) => district.checked)
    || doNotManage;

  return (
    <>
      <div className="d-flex flex-wrap mt-3">
        <Portlet>
          <PortletBody className="d-flex justify-content-center">
            <div>
              <div>
                <Typography variant="h1" style={{ fontSize: 24, fontWeight: 700 }}>
                  {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES.ADMIN.PERMISSIONS' })}
                </Typography>
                <Typography variant="body" style={{ margin: '20px 0', fontSize: 14 }} color={process.env.REACT_APP_COLOR_TEXT}>
                  {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES.ADMIN.PERMISSIONS.CONTENT' })}

                </Typography>
                <div>
                  <CheckBox
                    label={(
                      <Typography variant="body" style={{ paddingLeft: 5 }}>
                        Select all
                      </Typography>
                    )}
                    checked={selectAllDistricts}
                    onChange={() => handleSelectAll()}
                  />
                  <div style={{ borderBottom: '1px solid #C4C4C4', paddingTop: 20 }} />

                </div>
                {
                  districtsWithoutPerpetualSchedules.map((municipality) => (
                    <div style={{ paddingTop: 10 }}>

                      <CheckBox
                        key={municipality.district_id}
                        label={(
                          <Typography variant="body" style={{ paddingLeft: 5 }}>
                            {municipality.district_nm}
                          </Typography>
                        )}
                        checked={municipality.checked}
                        onChange={(checked) => {
                          const updatedDistricts = districtsWithoutPerpetualSchedules.map((district) => {
                            if (district.district_id === municipality.district_id) {
                              return {
                                ...district,
                                checked
                              };
                            }
                            return district;
                          });
                          setDistrictsWithoutPerpetualSchedules(updatedDistricts);
                          if (checked) {
                            setDoNotManage(false);
                          }
                        }}
                      />
                      <div style={{ borderBottom: '1px solid #C4C4C4', paddingTop: 20 }} />
                    </div>
                  ))
                }
                <div style={{ paddingTop: 25 }}>
                  <CheckBox
                    label={(
                      <Typography variant="body" style={{ paddingLeft: 5 }}>
                        {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES.I.DO.NOT.MANAGE' })}
                      </Typography>
                    )}
                    checked={doNotManage}
                    onChange={() => setDoNotManage(!doNotManage)}
                  />
                </div>

                <div className={classes.buttonContainer}>
                  <CustomButton
                    variant="outlined"
                    size="large"
                    label={(
                      <Typography variant="h1" color={process.env.REACT_APP_COLOR_PRIMARY}>
                        Back
                      </Typography>
                    )}
                    onClick={() => goBackHandler()}
                    className={classes.button}
                  />
                  <CustomButton
                    variant="contained"
                    size="large"
                    label={(
                      <Typography variant="h1" color="white">
                        Continue
                      </Typography>
                    )}
                    onClick={() => goNextHandler()}
                    className={classes.button}
                    disabled={!canGoNext}
                  />
                </div>
              </div>
            </div>

          </PortletBody>
        </Portlet>
      </div>
    </>
  );
};
const mapStateToProps = (store) => ({
  district: store.common.district,
  municipalities: store.common.municipalities,
  holidaysChanges: store.perpetualSchedules.holidaysChanges,
  perpetualSchedules: store.perpetualSchedules.districts,
});

export default injectIntl(connect(mapStateToProps, { ...actions, ...sharedActions, ...loginActions })(MultiDistrict));
