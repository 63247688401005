import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Formik } from 'formik';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import * as actions from './redux/actions';
import {
  Body,
  Title,
  SubTitle,
  Form,
  Action,
  Submit,
  Back,
} from '../components';

const MFA = ({
  intl,
  fetchState,
  email,
  sms,
  voice,
  access_token,
  MFARequest,
  resetLoadingAction,
  responseMessage,
}) => {
  const [value, setValue] = useState('send_email');

  const isSucceedRequest = fetchState === 'succeed-request';
  const isSendRequest = fetchState === 'send-request';

  if (isSucceedRequest) {
    resetLoadingAction();
    return <Redirect to="/auth/otp" />;
  }

  if (!access_token || (responseMessage && responseMessage.status === 'error')) {
    resetLoadingAction();
    return <Redirect to="/auth/login" />;
  }

  return (
    <Body>
      <Title text="AUTH.MFA.TITLE" />
      <SubTitle text="AUTH.MFA.SUB_TITLE1" />
      <Formik>
        {() => (
          <Form onSubmit={() => MFARequest(value)}>
            <RadioGroup
              name="mfaWay"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            >
              <FormControlLabel
                value="send_email"
                control={<Radio />}
                disabled={email === 'f'}
                label={intl.formatMessage({ id: 'AUTH.INPUT.SEND_EMAIL' }) + email}
              />
              {/* <FormControlLabel
                value="send_sms"
                control={<Radio />}
                disabled={sms === 'f'}
                label={intl.formatMessage({ id: 'AUTH.INPUT.SEND_SMS' }) + sms}
              />
              <FormControlLabel
                value="send_voice"
                control={<Radio />}
                disabled={voice === 'f'}
                label={intl.formatMessage({ id: 'AUTH.INPUT.VOICE_CALL' }) + voice}
              /> */}
            </RadioGroup>
            <Action>
              <Link to="/auth">
                <Back title="GENERAL.BACK" onClick={resetLoadingAction} />
              </Link>
              <Submit
                title="AUTH.GENERAL.SUBMIT_BUTTON"
                onSubmit={() => MFARequest(value)}
                isSubmitting={isSendRequest}
              />
            </Action>
          </Form>
        )}
      </Formik>
    </Body>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.auth.fetchState,
  email: store.auth.email,
  sms: store.auth.phone,
  voice: store.auth.phone,
  access_token: store.auth.access_token,
  responseMessage: store.auth.responseMessage,
});

export default injectIntl(connect(mapStateToProps, actions)(MFA));
