/* eslint-disable import/prefer-default-export */
import cts from '../../../crud/cts';

/**
 * New Subscribers report API which fetches New Subscribers report data
 */
export const getNewSubscribersReport = async ({
  municipality, from, to, county
}) => cts(
  'get',
  `reports/new-subscribers?county=${county}&municipality=${municipality}&from=${from}&to=${to}`,
);
