import React from 'react';

const CrownIcon = ({
  width = 12, height = 12, color, className, style
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 12 13"
    fill="none"
    className={className}
    style={style}
  >
    <path d="M12 0.333328L8.66667 3.66666L6 0.333328L3.33333 3.66666L0 0.333328V9H12V0.333328ZM1.33333 13H10.6667C11.0203 13 11.3594 12.8595 11.6095 12.6095C11.8595 12.3594 12 12.0203 12 11.6667V10.3333H0V11.6667C0 12.0203 0.140476 12.3594 0.390524 12.6095C0.640573 12.8595 0.979711 13 1.33333 13Z" fill={color} />
  </svg>
);

export default CrownIcon;
