/* eslint-disable import/no-dynamic-require */
import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { CustomButton, Dialog, Typography } from '../../components';

const useStyles = makeStyles({
  container: {
    minWidth: 178,
    height: 250,
    border: `1px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    borderRadius: 5,
    margin: '0 10px',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },
  image: {
    height: 100,
    margin: '0 auto',
  },
  button: {
    padding: '0 !important'
  }
});

const WhatToDoCard = ({ data, intl }) => {
  const classes = useStyles();

  return (
    <div className={`d-flex flex-column pt-3 ${classes.container}`}>
      <img
        className={classes.image}
        // eslint-disable-next-line global-require
        src={require(`../../../assets/images/${data.title.split('.').slice(6).join('-').toLowerCase()}-dashboard.png`)}
        alt={data.title}
      />

      <div className="text-center w-50 mx-auto mt-3">
        <Typography variant="h2">
          {intl.formatMessage({ id: data.title })}
        </Typography>
      </div>
      <div style={{ marginTop: 'auto' }} className="text-center mb-3">
        <Typography variant="body">
          {intl.formatMessage({ id: 'GENERAL.VIEW' })}
          /
          {intl.formatMessage({ id: data.subTitle })}
        </Typography>
      </div>
      <div>
        {
          data.link === '/educational-content' || data.disabled || data.notAllowed ? (
            <Dialog
              disableBackdropClick
              hasButtons={false}
              button={(
                <CustomButton
                  className={classes.button}
                  variant="contained"
                  label={<FormattedMessage id="GENERAL.GO" />}
                />
              )}
              title={(
                <Typography variant="h1">
                  {intl.formatMessage({ id: data.title })}
                </Typography>
              )}
              content={data.disabled || data.notAllowed ? (
                <Typography variant="body">
                  {intl.formatMessage({
                    id: data.notAllowed
                      ? 'MENU.WARNING.THIS.FUTURE.IS.NOT.AVAILABLE'
                      : 'MENU.WARNING.YOU.DONT.HAVE.ACCESS.TO.THIS.FUTURE'
                  })}
                </Typography>
              ) : (
                <Typography variant="body">
                  This feature is currently under implementation and will be released soon.
                </Typography>
              )}
            />
          ) : (
            <Link to={data.link}>
              <CustomButton
                className={classes.button}
                variant="contained"
                label={<FormattedMessage id="GENERAL.GO" />}
              />
            </Link>
          )
        }
      </div>
    </div>
  );
};

export default injectIntl(WhatToDoCard);
