import actionTypes from './actionTypes';

// Auth actions for setting loading status
export const setLoadingAction = () => ({
  type: actionTypes.SET_LOADING
});

export const resetLoadingAction = () => ({
  type: actionTypes.RESET_LOADING
});

// OTP actions
export const OTPRequest = (otp) => ({
  type: actionTypes.OTPـREQUEST,
  payload: { otp }
});

export const OTPReceive = (data) => ({
  type: actionTypes.OTPـRECEIVE,
  payload: { ...data }
});

export const OTPFail = (error) => ({
  type: actionTypes.OTPـFAIL,
  payload: { ...error }
});
