import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../redux/createReducer';
import initialState from './store';

const dashboardReducer = persistReducer(
  { storage, key: 'dashboard', whitelist: [] },
  createReducer(initialState, {
    GET_DASHBOARD_DATA_REQUEST: (state) => Immutable({
      ...state,
      loading: true,
      error: false,
    }),
    GET_DASHBOARD_DATA_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      ...payload.data,
      loading: false,
      isFirstLoading: false
    }),
    GET_DASHBOARD_DATA_FAIL: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      isFirstLoading: true,
      error: payload,
    }),
  })
);

export default dashboardReducer;
