import moment from 'moment';

/**
 * Dashboard Store
 */
const initialState = {
  district: {},
  fromDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
  toDate: moment().format('YYYY-MM-DD'),
  loading: false,
  error: false,
  isFirstLoading: true
};

export default initialState;
