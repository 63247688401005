const actionTypes = {
  GET_LESSONS_LIBRARY_DATA_REQUEST: 'GET_LESSONS_LIBRARY_DATA_REQUEST',
  GET_LESSONS_LIBRARY_DATA_RECEIVE: 'GET_LESSONS_LIBRARY_DATA_RECEIVE',
  GET_LESSONS_LIBRARY_DATA_FAIL: 'GET_LESSONS_LIBRARY_DATA_FAIL',
  FEATURED_LESSON_FEEDBACK_REQUEST: 'FEATURED_LESSON_FEEDBACK_REQUEST',
  FEATURED_LESSON_FEEDBACK_RECEIVE: 'FEATURED_LESSON_FEEDBACK_RECEIVE',
  FEATURED_LESSON_FEEDBACK_FAIL: 'FEATURED_LESSON_FEEDBACK_FAIL',
};

export default actionTypes;
