import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Tab, Snackbar } from '../components';
import {
  Header,
  Content,
  History,
  NewMessage,
  CustomList,
  CustomForm,
} from './components';
import * as actions from './redux/actions';

const Notification = ({
  intl,
  resetMessageAction,
  setTabAction,
  responseMessage,
  responseStatus,
  tab,
  cat,
  setNotificationCat
}) => {
  const handleChange = (event, newValue) => {
    setTabAction(newValue);
  };

  return (
    <>
      <Snackbar
        message={responseMessage}
        status={responseStatus}
        closeAction={resetMessageAction}
      />
      <Header
        title={intl.formatMessage({ id: 'NOTIFICATION.HEADER.TITLE' })}
        content="NOTIFICATION.HEADER.CONTENT"
      />
      <Content>
        <History
          title={intl.formatMessage({ id: 'NOTIFICATION.HISTORY.TITLE' })}
          isActive={cat}
          onChange={() => setNotificationCat(!cat)}
        >
          {!cat
            ? (
              <>
                <Tab
                  selectedTab={tab}
                  onChange={handleChange}
                  labels={['Scheduled', 'Drafts']}
                />
                {tab === 0 && <CustomList type="SCHEDULE" />}
                {tab === 1 && <CustomList type="DRAFT" />}
              </>
            )
            : <CustomList type="SENT" />}
        </History>
        <NewMessage
          title={intl.formatMessage({ id: 'NOTIFICATION.NEW_MESSAGE.TITLE' })}
        >
          <CustomForm />
        </NewMessage>
      </Content>
    </>
  );
};

const mapStateToProps = (store) => ({
  responseMessage: store.notification.responseMessage,
  responseStatus: store.notification.responseStatus,
  tab: store.notification.tab,
  cat: store.notification.cat,
});

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(Notification)
);
