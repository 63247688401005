import React from 'react';
import ContentLoader from 'react-content-loader';

const DetailRowLoading = ({
  width = 320,
  height = 90
}) => (
  <ContentLoader
    speed={2}
    style={{ width, height: (height * 4) + 200 }}
    backgroundColor={process.env.REACT_APP_COLOR_LOADING_BACKGROUND}
    foregroundColor={process.env.REACT_APP_COLOR__LOADING_FOREGROUND}
  >
    <rect x="0" y="0" rx="6" ry="6" width="100%" height={`${height}`} />
    <rect x="0" y={`${height + 60}`} rx="6" ry="6" width="100%" height={`${height}`} />
    <rect x="0" y={`${(height * 2) + 120}`} rx="6" ry="6" width="100%" height={`${height}`} />
    <rect x="0" y={`${(height * 3) + 180}`} rx="6" ry="6" width="100%" height={`${height}`} />
  </ContentLoader>
);

export default DetailRowLoading;
