import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';
import createReducer from '../../../../redux/createReducer';
import initialState from './store';

const reportsDashboardReducer = persistReducer(
  { storage, key: 'reportsDashboard', whitelist: [] },
  createReducer(initialState, {
    SET_DASHBOARD_DISTRICT_ACTION: (state, { payload }) => Immutable({
      ...state,
      district: payload
    }),
    SET_DASHBOARD_FROM_DATE_ACTION: (state, { payload }) => Immutable({
      ...state,
      fromDate: payload
    }),
    SET_DASHBOARD_TO_DATE_ACTION: (state, { payload }) => Immutable({
      ...state,
      toDate: payload
    }),

  })
);

export default reportsDashboardReducer;
