import actionTypes from './actionTypes';

// Schedule actions for setting loading status
export const setLoadingAction = (status) => ({
  type: actionTypes.SET_SCHEDULE_LOADING_ACTION,
  payload: status
});

export const getAllScheduleRequest = (data) => ({
  type: actionTypes.GET_SCHEDULE_REQUEST_ACTION,
  payload: { ...data }
});

export const getAllScheduleReceive = (data) => ({
  type: actionTypes.GET_SCHEDULE_RECEIVE_ACTION,
  payload: { ...data }
});

export const getAllScheduleFail = (data) => ({
  type: actionTypes.GET_SCHEDULE_FAIL_ACTION,
  payload: { ...data }
});


// get zones

export const getZoneRequest = (district_id) => ({
  type: actionTypes.GET_ZONE_SCHEDULE_REQUEST,
  payload: { district_id }
});

export const getZoneReceive = (data) => ({
  type: actionTypes.GET_ZONE_SCHEDULE_RECEIVE,
  payload: { ...data }
});

export const getZoneFail = (error) => ({
  type: actionTypes.GET_ZONE_SCHEDULE_FAIL,
  payload: { ...error }
});

// get perpetual schedules holidays

export const getPerpetualSchedulesHolidaysRequest = (project_id, district_id) => ({
  type: actionTypes.GET_PERPETUAL_SCHEDULES_HOLIDAYS_REQUEST,
  payload: { project_id, district_id }
});

export const getPerpetualSchedulesHolidaysReceive = (data) => ({
  type: actionTypes.GET_PERPETUAL_SCHEDULES_HOLIDAYS_RECEIVE,
  payload: { ...data }
});

export const getPerpetualSchedulesHolidaysFail = (error) => ({
  type: actionTypes.GET_PERPETUAL_SCHEDULES_HOLIDAYS_FAIL,
  payload: { ...error }
});
