import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../../redux/createReducer';
import initialState from './store';
import { getEmptySeasonalListConfig } from '../../helper';

const scheduleReducer = persistReducer(
  { storage, key: 'schedule', whitelist: [] },
  createReducer(initialState, {
    GET_HOLIDAYS_SCHEDULE_REQUEST: (state) => Immutable({
      ...state,
      loading: true,
      error: false,
    }),
    GET_HOLIDAYS_SCHEDULE_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      ...payload.data,
    }),
    GET_HOLIDAYS_SCHEDULE_FAIL: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      error: payload,
    }),
    SAVE_HOLIDAYS_SCHEDULE_REQUEST: (state) => Immutable({
      ...state,
      loading: true,
      error: false,
    }),
    SAVE_HOLIDAYS_SCHEDULE_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      ...payload.data,
    }),
    SAVE_HOLIDAYS_SCHEDULE_FAIL: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      error: payload,
    }),
    GET_PERPETUAL_SCHEDULES_REQUEST: (state) => Immutable({
      ...state,
      loading: true,
      error: false,
    }),
    GET_PERPETUAL_SCHEDULES_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      ...payload.data,
    }),
    GET_PERPETUAL_SCHEDULES_FAIL: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      error: payload,
    }),
    MANAGE_PERPETUAL_SCHEDULES_REQUEST: (state) => Immutable({
      ...state,
      loading: true,
      error: false,
    }),
    MANAGE_PERPETUAL_SCHEDULES_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      ...payload
    }),
    MANAGE_PERPETUAL_SCHEDULES_FAIL: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      error: payload,
    }),
    SET_MULTI_DISTRICTS: (state, { payload }) => Immutable({
      ...state,
      multiDistrict: payload,
    }),
    REMOVE_FROM_MULTI_DISTRICTS: (state, { payload }) => {
      const removedDistricts = payload.map((item) => `${item.project_id}#$#${item.district_id}`);
      return Immutable({
        ...state,
        multiDistrict: state.multiDistrict.filter(item => !removedDistricts.includes(item))
      });
    },
    GET_SEASONAL_LIST_REQUEST: (state) => Immutable({
      ...state,
      seasonalListLoading: true,
      error: false,
    }),
    GET_SEASONAL_LIST_RECEIVE: (state, { payload }) => {
      const filteredSeasonalList = getEmptySeasonalListConfig(payload.data);
      const sortedSeasonsalList = filteredSeasonalList.seasonal_list.sort((a, b) => a.col_type_id - b.col_type_id);
      return Immutable({
        ...state,
        seasonalListLoading: false,
        seasonalList: { seasonal_list: sortedSeasonsalList },
      });
    },
    GET_SEASONAL_LIST_FAIL: (state, { payload }) => Immutable({
      ...state,
      seasonalListLoading: false,
      error: payload,
    }),
    SAVE_SEASONAL_COLLECTION_REQUEST: (state) => Immutable({
      ...state,
      loading: true,
      error: false,
    }),
    SAVE_SEASONAL_COLLECTION_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      ...payload.data,
    }),
    SAVE_SEASONAL_COLLECTION_FAIL: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      error: payload,
    }),
    SET_CURRENT_COLLECTION: (state, { payload }) => Immutable({
      ...state,
      currentCollection: payload
    }),
    UPDATE_SEASONAL_COLLECTION: (state, { payload }) => Immutable({
      ...state,
      seasonalList: {
        ...state.seasonalList,
        seasonal_list: state.seasonalList.seasonal_list.map((item) => {
          if (item.col_type_id === payload.col_type_id) {
            return {
              ...item,
              ...payload
            };
          }
          return item;
        })
      }
    }),
    SET_COMPLETED_SETUP: (state, { payload }) => Immutable({
      ...state,
      completedSetup: payload
    }),
    RESET_SEASONAL_LIST: (state) => Immutable({
      ...state,
      seasonalList: initialState.seasonalList,
    }),
  })
);

export default scheduleReducer;
