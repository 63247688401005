import React from 'react';
import CmsColumnLoading from './CmsColumnLoading';

const CmsLoading = ({
  length = 3
}) => (
  <div className="d-flex flex-row">
    {
        Array(length).fill(1).map((item, index) => (
          <CmsColumnLoading key={`loading_${String(index)}`} />
        ))
      }
  </div>
);
export default CmsLoading;
