import React, { useEffect } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { toAbsoluteUrl } from '../../../../_metronic';
import { CheckIcon } from '../../../assets/icons';
import { Typography } from '../../components';
import * as actions from './redux/actions';

const useStyles = makeStyles((theme) => ({
  aside: {
    position: 'relative',
    width: '100%',
    height: '60vh',
    backgroundColor: '#ffffff',
    backgroundImage: `url(${toAbsoluteUrl('/media/backgrounds/auth-page-bg.svg')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up(993)]: {
      width: '45%',
      minWidth: '645px',
      height: '100%',
    }
  },
  websiteInfo: {
    position: 'absolute',
    bottom: 30,
    left: 80,
    [theme.breakpoints.up(993)]: {
      top: '35%',
      left: '30%',
    }
  },
  tickIcon: {
    width: 25,
    height: 25,
  },
}));

const LoginAs = ({
  intl,
  loginAsRequest,
  resetLoadingAction,
  fetchState
}) => {
  const classes = useStyles();
  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get('key');
  const isSucceedRequest = fetchState === 'succeed-request';
  const isFailedRequest = fetchState === 'failed-request';

  useEffect(() => {
    loginAsRequest(encodeURIComponent(token));
  }, []);

  if (isSucceedRequest) {
    resetLoadingAction();
    return <Redirect to="/home-page" />;
  }

  return (
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <div
        id="kt_login"
        className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
          <div className={classes.aside}>
            <div className={classes.websiteInfo}>
              <Link to="/">
                <img
                  alt="Logo"
                  src={toAbsoluteUrl('/media/logos/logo-rc.svg')}
                />
              </Link>
              <Typography className="mt-2" variant="h1">
                {intl.formatMessage({ id: 'AUTH.GENERAL.TITLE' })}
              </Typography>
              <div className="d-flex pl-3 pt-3">
                <div className={classes.tickIcon}>
                  <CheckIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                </div>
                <Typography component="span" className="mt-2 ml-3" variant="h2Medium">
                  {intl.formatMessage({ id: 'AUTH.GENERAL.TEXT_1' })}
                </Typography>
              </div>
              <div className="d-flex pl-3 pt-2">
                <div className={classes.tickIcon}>
                  <CheckIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                </div>
                <Typography component="span" className="mt-2 ml-3" variant="h2Medium">
                  {intl.formatMessage({ id: 'AUTH.GENERAL.TEXT_2' })}
                </Typography>
              </div>
              <div className="d-flex pl-3 pt-2">
                <div className={classes.tickIcon}>
                  <CheckIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                </div>
                <Typography component="span" className="mt-2 ml-3" variant="h2Medium">
                  {intl.formatMessage({ id: 'AUTH.GENERAL.TEXT_3' })}
                </Typography>
              </div>
              <div className="d-flex pl-3 pt-2">
                <div className={classes.tickIcon}>
                  <CheckIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                </div>
                <Typography component="span" className="mt-2 ml-3" variant="h2Medium">
                  {intl.formatMessage({ id: 'AUTH.GENERAL.TEXT_4' })}
                </Typography>
              </div>
            </div>
          </div>
          <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
            <div className=" w-100 h-100 d-flex justify-content-center align-items-center">
              {isFailedRequest
                ? (
                  <div>
                    <Typography variant="h1">
                      {intl.formatMessage({ id: 'AUTH.LOGIN_AS.FAILED_TITLE' })}
                    </Typography>
                    <Typography variant="h2">
                      {intl.formatMessage({ id: 'AUTH.LOGIN_AS.FAILED_TEXT' })}
                    </Typography>
                  </div>
                ) : (
                  <Typography variant="h2">
                    {intl.formatMessage({ id: 'AUTH.LOGIN_AS.LOADING_TITLE' })}
                  </Typography>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.auth.fetchState
});

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(LoginAs)
);
