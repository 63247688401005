import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Typography, IconButton, Dialog } from '../../components';
import { DeleteIcon } from '../../../assets/icons';
import * as actions from '../redux/actions';

const useStyles = makeStyles(() => ({
  container: {
    maxHeight: 180,
    overflow: 'auto',
    paddingRight: 25,
  },
  depotItem: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
}));

const CategoryDepots = ({
  intl,
  depots,
  category,
  locality,
  deleteDepotRequest,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {
        depots && depots.map((item, index) => (
          <div
            key={item.drop_depot_id}
            className={clsx({
              'pt-4 d-flex justify-content-between': true,
              'pb-4': index !== depots.length - 1,
              [classes.depotItem]: index !== depots.length - 1,
            })}
          >
            <Typography variant="body">
              {item.drop_depot_admin_nm}
            </Typography>
            <Dialog
              disableBackdropClick={false}
              button={(
                <IconButton>
                  <DeleteIcon color={process.env.REACT_APP_COLOR_DELETE} />
                </IconButton>
              )}
              title={(
                <Typography variant="h1">
                  {intl.formatMessage({ id: 'GENERAL.DELETE' })}
                </Typography>
              )}
              content={(
                <Typography variant="body">
                  {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.DELETE_TEXT' })}
                </Typography>
              )}
              handleSubmit={() => {
                deleteDepotRequest({
                  locality: locality.cms_locality_id,
                  depot: item.drop_depot_id,
                  category
                });
              }}
            />
          </div>
        ))
      }
    </div>
  );
};

const mapStateToProps = (store) => ({
  locality: store.common.locality,
});

export default injectIntl(connect(mapStateToProps, actions)(CategoryDepots));
