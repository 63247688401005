import { put, call, takeLatest } from 'redux-saga/effects';

import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  getLocalities,
  getWasteTypes,
  getCategories,
  getAllCategories,
  deleteCategory,
  deleteDepot,
  deleteEvent,
  renameCategory,
  getDepots,
  getEvents,
  getCollections,
  getCollectionDetails,
  assignDepot,
  assignEvent,
  assignCollection,
  moveCategory,
  moveMaterials,
  searchMaterials,
  getSearchResults,
  getSearchedWasteType,
  addCategory,
  updateCategoryDescriptionFixed,
  updateCategoryDescriptionCustom,
} from '../apis';

function* getWasteTypeSaga({ payload: { locality } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(getWasteTypes, { locality });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getWasteTypesListReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getWasteTypesListFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* getCategorySaga({ payload: { wasteType, locality } }) {
  try {
    yield put(actions.setLoadingAction('send-request'));
    const res = yield call(getCategories, { wasteType, locality });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getCategoriesReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getCategoriesFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* getAllCategorySaga({ payload: { locality } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(getAllCategories, { locality });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getAllCategoriesReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getAllCategoriesFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* deleteCategorySaga({ payload: { locality, category } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(deleteCategory, { locality, category });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getAllCategoriesRequest({ locality }));
        yield put(actions.deleteCategoryReceive(category));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.deleteCategoryFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* deleteDepotSaga({ payload: { locality, depot, category } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(deleteDepot, { locality, depot, category });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.deleteDepotReceive({ depot, category }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.deleteDepotFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* deleteEventSaga({ payload: { locality, event, category } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(deleteEvent, { locality, event, category });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.deleteEventReceive({ event, category }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.deleteEventFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* renameCategorySaga({ payload: { locality, category, title } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(renameCategory, { locality, category, title });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getAllCategoriesRequest({ locality }));
        yield put(actions.renameCategoryReceive({ category, title }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.renameCategoryFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* getDepotsSaga({ payload: { locality } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(getDepots, { locality });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getDepotsReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getDepotsFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* getEventsSaga({ payload: { locality } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(getEvents, { locality });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getEventsReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getEventsFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* getCollectionsSaga({ payload: { locality } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(getCollections, { locality });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getCollectionsReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getCollectionsFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* getCollectionDetailsSaga({ payload: { locality, collection } }) {
  try {
    if (collection) {
      yield put(actions.setSnackbarAction('send-request'));
      const res = yield call(getCollectionDetails, { locality, collection });
      if (res.data) {
        if (res.data.status === 'success') {
          yield put(actions.getCollectionDetailsReceive(res.data));
        } else if (res.data.status === 'fail') {
          throw new Error(res.data.message);
        }
      } else if (res.error) {
        throw new Error(res.error.message);
      }
    } else {
      yield put(actions.getCollectionDetailsReceive({ data: null }));
    }
  } catch (error) {
    yield put(actions.getCollectionDetailsFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* assignDepotSaga({ payload: { locality, category, depot } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(assignDepot, { locality, category, depot: depot.drop_depot_id });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.assignDepotReceive({ category, depot }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.assignDepotFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* assignEventSaga({ payload: { locality, category, event } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(assignEvent, { locality, category, event: event.drop_event_id });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.assignEventReceive({ category, event }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.assignEventFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* assignCollectionSaga({ payload: { locality, wasteType, collection } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(assignCollection, { locality, wasteType, collection });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getCollectionDetailsRequest({ locality, collection }));
        yield put(actions.assignCollectionReceive({ wasteType, collection }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.assignCollectionFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* moveCategorySaga({ payload: { locality, wasteType, category } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(moveCategory, { locality, wasteType, category });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.moveCategoryReceive({ category }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.moveCategoryFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* moveMaterialsSaga({
  payload: {
    locality, category, materials, currentCategory
  }
}) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(moveMaterials, { locality, category: category.mat_cat_id, materials });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.moveMaterialsReceive({ category: category.mat_cat_id_front, materials, currentCategory }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.moveMaterialsFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* searchMaterialsSaga({ payload: { locality, search } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(searchMaterials, { locality, search });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.searchMaterialsReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.searchMaterialsFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* getSearchResultsSaga({ payload: { locality, material } }) {
  try {
    yield put(actions.setLoadingAction('send-request'));
    const res = yield call(getSearchResults, { locality, material });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getSearchResultsReceive(res.data));
        if (res.data.data.length !== 0) {
          yield put(actions.getSearchedWasteTypeRequest({
            locality,
            wasteType: res.data.data[0].waste_type_id
          }));
        }
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getSearchResultsFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* getSearchedWasteTypeSaga({ payload: { locality, wasteType } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(getSearchedWasteType, { locality, wasteType });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getSearchedWasteTypeReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getSearchedWasteTypeFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* addCategorySaga({ payload: { locality, wasteType, categoryName, categoryHeader, categoryDescription } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(addCategory, { locality, wasteType, categoryName, categoryHeader, categoryDescription });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getAllCategoriesRequest({ locality }));
        yield put(actions.getCategoriesRequest({ locality, wasteType }));
        yield put(actions.addCategoryReceive());
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.addCategoryFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* updateCategoryDescriptionSaga({
  payload: {
    locality, wasteType, category, descriptionId, descriptionHeader, descriptionBody, moreInfo
  }
}) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    let res = '';
    if (descriptionId) {
      res = yield call(updateCategoryDescriptionFixed, {
        locality, wasteType, category, descriptionId, moreInfo
      });
    } else {
      res = yield call(updateCategoryDescriptionCustom, {
        locality, wasteType, category, descriptionHeader, descriptionBody, moreInfo
      });
    }

    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.updateCategoryDescriptionReceive({
          category, descriptionId, descriptionHeader, descriptionBody, moreInfo
        }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.updateCategoryDescriptionFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

/**
 * Saga watcher function
 */
function* categorySagas() {
  yield takeLatest(actionTypes.GET_WASTE_TYPES_REQUEST, getWasteTypeSaga);
  yield takeLatest(actionTypes.GET_CATEGORIES_REQUEST, getCategorySaga);
  yield takeLatest(actionTypes.GET_ALL_CATEGORIES_REQUEST, getAllCategorySaga);
  yield takeLatest(actionTypes.DELETE_CATEGORY_REQUEST, deleteCategorySaga);
  yield takeLatest(actionTypes.UNASSIGN_DEPOT_REQUEST, deleteDepotSaga);
  yield takeLatest(actionTypes.UNASSIGN_EVENT_REQUEST, deleteEventSaga);
  yield takeLatest(actionTypes.RENAME_CATEGORY_REQUEST, renameCategorySaga);
  yield takeLatest(actionTypes.GET_DEPOTS_REQUEST, getDepotsSaga);
  yield takeLatest(actionTypes.GET_EVENTS_REQUEST, getEventsSaga);
  yield takeLatest(actionTypes.GET_COLLECTIONS_REQUEST, getCollectionsSaga);
  yield takeLatest(actionTypes.GET_COLLECTION_DETAILS_REQUEST, getCollectionDetailsSaga);
  yield takeLatest(actionTypes.ASSIGN_DEPOT_REQUEST, assignDepotSaga);
  yield takeLatest(actionTypes.ASSIGN_EVENT_REQUEST, assignEventSaga);
  yield takeLatest(actionTypes.ASSIGN_COLLECTION_REQUEST, assignCollectionSaga);
  yield takeLatest(actionTypes.MOVE_CATEGORY_REQUEST, moveCategorySaga);
  yield takeLatest(actionTypes.MOVE_MATERIALS_REQUEST, moveMaterialsSaga);
  yield takeLatest(actionTypes.SEARCH_MATERIALS_REQUEST, searchMaterialsSaga);
  yield takeLatest(actionTypes.GET_SEARCH_RESULTS_REQUEST, getSearchResultsSaga);
  yield takeLatest(actionTypes.GET_SEARCHED_WASTE_TYPE_REQUEST, getSearchedWasteTypeSaga);
  yield takeLatest(actionTypes.ADD_CATEGORY_REQUEST, addCategorySaga);
  yield takeLatest(actionTypes.UPDATE_CATEGORY_DESCRIPTION_REQUEST, updateCategoryDescriptionSaga);
}

export default categorySagas;
