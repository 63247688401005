import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { toAbsoluteUrl } from '../../../../../../_metronic';
import { Typography } from '../../../../components';

const useStyles = makeStyles(() => ({
  specialOffer: {
    minWidth: 433,
    height: 253,
    borderRadius: 5,
    background: '#2A95FF'
  },
  innerContainer: {
    margin: '20px 10px',
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
    letterSpacing: 1,
  },
  contactSuccessManagerText: {
    fontSize: 14,
    fontWeight: 700,
    textDecoration: 'underline',
  },
  recycleRightImage: {
    margin: '15px auto',
    display: 'flex',
  }
}));
const MajorCampaignSpecialOffer = ({
  intl,
  csm
}) => {
  const classes = useStyles();
  return (
    <div className={classes.specialOffer}>
      <div className={classes.innerContainer}>
        <Typography color={process.env.REACT_APP_COLOR_SECONDARY} className={classes.title} variant="h1">
          {intl.formatMessage({ id: 'EDUCATION.MAJOR.CAMPAIGNS.SPECIAL.OFFER' })}
        </Typography>
        <Typography color={process.env.REACT_APP_COLOR_SECONDARY} variant="educationBody">
          {intl.formatMessage({ id: 'EDUCATION.MAJOR.CAMPAIGNS.SPECIAL.OFFER.CONTENT' })}
        </Typography>
        <img
          alt="Recycle Right"
          src={toAbsoluteUrl('/media/icons/Education/MajorCampaigns/recycle-right-hand.png')}
          className={`${classes.recycleRightImage}`}
        />
        <a target="_blank" href={csm?.calendly_link} rel="noreferrer">
          <Typography color={process.env.REACT_APP_COLOR_SECONDARY} className={classes.contactSuccessManagerText} variant="educationBody">
            {intl.formatMessage({ id: 'EDUCATION.MAJOR.CAMPAIGNS.SPECIAL.OFFER.CONTACT' })}
          </Typography>
        </a>
      </div>
    </div>
  );
};

export default injectIntl(MajorCampaignSpecialOffer);
