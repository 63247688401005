import { put, call, takeLatest } from 'redux-saga/effects';

import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  getResidentKnowledgeIndicatorReport,
} from '../apis';

function* residentReportSaga({
  payload: {
    municipality, from, to, county
  }
}) {
  try {
    yield put(actions.setResidentKnowledgeIndicatorLoadingAction('send-request'));
    const res = yield call(getResidentKnowledgeIndicatorReport, {
      municipality, from, to, county
    });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.ResidentKnowledgeIndicatorReportReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.ResidentKnowledgeIndicatorReportFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}
/**
 * Saga watcher function
 */
function* residentKnowledgeIndicatorReportSaga() {
  yield takeLatest(actionTypes.GET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_REQUEST, residentReportSaga);
}

export default residentKnowledgeIndicatorReportSaga;
