import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';


import createReducer from '../../../../redux/createReducer';
import initialState from './store';


const userReportReducer = persistReducer(
  { storage, key: 'userReport', whitelist: [] },
  createReducer(initialState, {

    SET_USER_REPORT_LOADING_ACTION: (state, { payload }) => Immutable({
      ...state,
      fetchState: payload,
    }),

    GET_USER_REPORT_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      data: payload.data.data,
      fetchState: 'succeed-request',
    }),

    GET_USER_REPORT_FAIL: (state) => Immutable({
      ...state,
      fetchState: 'failed-request',
    }),
  })
);


export default userReportReducer;
