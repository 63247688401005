import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Dropdown } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';

import {
  Typography, CustomButton, Dialog, TextField
} from '../../components';
import { ThreeDotMenuIcon } from '../../../assets/icons';
import * as actions from '../redux/actions';
import Preview from './Preview';

const useStyles = makeStyles(() => ({
  dropdown: {
    '& button': {
      padding: 0
    }
  },
  dropdownIcon: {
    '&::after': {
      display: 'none !important'
    },
    '& svg': {
      width: '1.3em',
      height: '1.3em',
    }
  },
  dropdownMenu: {
    maxHeight: 300,
    overflow: 'auto'
  },
  dropdownItem: {
    padding: '0 !important',
  },
}));

const CategoryMenu = ({
  intl,
  data,
  selectedMaterials,
  locality,
  wasteTypeList,
  allCategoryList,
  deleteCategoryRequest,
  renameCategoryRequest,
  moveCategoryRequest,
  moveMaterialsRequest,
  snackbarState,
  setSelectedMaterials
}) => {
  const classes = useStyles();
  const [newName, setNewName] = useState(data.mat_cat_admin_nm);
  const [dropdownMode, setDropdownMode] = useState('default');
  const [movingMaterials, setMovingMaterials] = useState(false);


  useEffect(() => {
    if (snackbarState === 'succeed-request' && movingMaterials) {
      setSelectedMaterials([]);
      setMovingMaterials(false);
    }
  }, [snackbarState]);


  const handleMoveMaterials = (category) =>{
    setMovingMaterials(true);
    setDropdownMode('close');
    moveMaterialsRequest({
      locality: locality.cms_locality_id,
      category,
      materials: selectedMaterials,
      currentCategory: data.mat_cat_id
    });
  }

  return (
    <Dropdown
      alignRight
      className={classes.dropdown}
      drop="down"
      onToggle={() => setDropdownMode('default')}
    >
      <Dropdown.Toggle
        variant="transparent"
        className={classes.dropdownIcon}
      >
        <ThreeDotMenuIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu className={`dropdown-menu-fit dropdown-menu-right ${classes.dropdownMenu}`}>
        {
          dropdownMode === 'default' && (
            <>
              {/* <Dialog
                disableBackdropClick={false}
                submitDisable={newName === ''}
                button={(
                  <CustomButton
                    className={classes.dropdownItem}
                    label={(
                      <Typography variant="bodySemiBold">
                        {intl.formatMessage({ id: 'CATEGORY.MENU.RENAME' })}
                      </Typography>
                    )}
                  />
                )}
                title={(
                  <Typography variant="h1">
                    {intl.formatMessage({ id: 'CATEGORY.DIALOG.RENAME.TITLE' })}
                  </Typography>
                )}
                content={(
                  <>
                    <Typography variant="body">
                      {intl.formatMessage({ id: 'CATEGORY.DIALOG.RENAME.TEXT' })}
                    </Typography>
                    <TextField
                      className="mb-3"
                      value={newName}
                      onChange={(value) => setNewName(value)}
                    />
                  </>
                )}
                handleSubmit={() => {
                  renameCategoryRequest({
                    locality: locality.cms_locality_id,
                    category: data.mat_cat_id,
                    title: newName
                  });
                }}
              /> */}

              <CustomButton
                className={classes.dropdownItem}
                onClick={() => setDropdownMode('category')}
                label={(
                  <Typography variant="bodySemiBold">
                    {intl.formatMessage({ id: 'CATEGORY.MENU.MOVE_CATEGORY' })}
                  </Typography>
                )}
              />

              <CustomButton
                className={classes.dropdownItem}
                disabled={selectedMaterials.length === 0}
                onClick={() => setDropdownMode('material')}
                label={(
                  <Typography
                    color={
                      selectedMaterials.length === 0
                        ? process.env.REACT_APP_COLOR_GRAY_DARK
                        : process.env.REACT_APP_COLOR_TEXT
                    }
                    variant="bodySemiBold"
                  >
                    {intl.formatMessage({ id: 'CATEGORY.MENU.MOVE_MATERIALS' })}
                  </Typography>
                )}
              />
              <Preview data={data} />
              <Dialog
                disableBackdropClick={false}
                isDisabled={data.mats.length !== 0}
                button={(
                  <CustomButton
                    className={classes.dropdownItem}
                    disabled={data.mats.length !== 0}
                    label={(
                      <Typography
                        color={
                          data.mats.length !== 0
                            ? process.env.REACT_APP_COLOR_GRAY_DARK
                            : process.env.REACT_APP_COLOR_TEXT
                        }
                        variant="bodySemiBold"
                      >
                        {intl.formatMessage({ id: 'CATEGORY.MENU.DELETE' })}
                      </Typography>
                    )}
                  />
                )}
                title={(
                  <Typography variant="h1">
                    {intl.formatMessage({ id: 'GENERAL.DELETE' })}
                  </Typography>
                )}
                content={(
                  <Typography variant="body">
                    {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.DELETE_TEXT' })}
                  </Typography>
                )}
                handleSubmit={() => {
                  deleteCategoryRequest({
                    locality: locality.cms_locality_id,
                    category: data.mat_cat_id
                  });
                }}
              />
            </>
          )
        }
        {
          dropdownMode === 'category' && wasteTypeList.map((wasteType) => (
            <CustomButton
              key={wasteType.waste_type_id}
              className={classes.dropdownItem}
              onClick={() => {
                setDropdownMode('close');
                moveCategoryRequest({
                  locality: locality.cms_locality_id,
                  wasteType: wasteType.waste_type_id,
                  category: data.mat_cat_id,
                });
              }}
              label={(
                <Typography variant="bodySemiBold">
                  {wasteType.waste_type_admin_nm}
                </Typography>
              )}
            />
          ))
        }
        {
          dropdownMode === 'material' && allCategoryList.map((category) => (
            <CustomButton
              key={category.mat_cat_id}
              className={classes.dropdownItem}
              onClick={() => handleMoveMaterials(category)}
              label={(
                <Typography variant="bodySemiBold">
                  {category.mat_cat_admin_nm}
                </Typography>
              )}
            />
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = (store) => ({
  locality: store.common.locality,
  wasteTypeList: store.category.wasteTypeList,
  wasteType: store.category.wasteType,
  allCategoryList: store.category.allCategoryList,
  snackbarState: store.category.snackbarState,
});

export default injectIntl(connect(mapStateToProps, actions)(CategoryMenu));
