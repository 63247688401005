import actionTypes from './actionTypes';

export const sendSubmittedPhotoRequestAction = (image, material_id) => ({
  type: actionTypes.SEND_SUBMITTED_PHOTO_REQUEST,
  payload: { image, material_id }
});
export const sendSubmittedPhotoReceiveAction = (data) => ({
  type: actionTypes.SEND_SUBMITTED_PHOTO_RECEIVE,
  payload: data
});

export const sendSubmittedPhotoFailAction = (error) => ({
  type: actionTypes.SEND_SUBMITTED_PHOTO_FAIL,
  payload: error
});

export const sendSuggestedNewTermRequestAction = (synonym, additional_comments) => ({
  type: actionTypes.SEND_SUGGESTED_NEW_TERM_REQUEST,
  payload: { synonym, additional_comments }
});

export const sendSuggestedNewTermReceiveAction = (data) => ({
  type: actionTypes.SEND_SUGGESTED_NEW_TERM_RECEIVE,
  payload: data
});

export const sendSuggestedNewTermFailAction = (error) => ({
  type: actionTypes.SEND_SUGGESTED_NEW_TERM_FAIL,
  payload: error
});
