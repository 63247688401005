import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';

import { Typography, InfoTooltip } from '../../../components';

import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { EducationModal } from '../../ReportDashboardNew/components';
import { DropdownMenu } from '../../../Education/EducationalContent/components';


const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: 'white',
    marginRight: 0,
    marginLeft: 0,
    marginTop: '0 !important',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: '8px 0',
    minHeight: 72,
    textTransform: 'uppercase',
    borderBottom: '1px solid lightgrey',


  },
  tableHeaderFont: {
    color: '#404040',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    fontSize: '13px',
  },
  tableRow: {
    borderBottom: '1px solid lightgrey',
    marginLeft: 0,
    marginRight: 0,
    '&::after': {
      borderBottom: '1px solid lightgrey',
    }
  },
  tableLabel: {
    borderBottom: 'none !important',
    borderRadius: '0 !important',
    padding: '0 !important',
  },
  tableBody: {
    padding: '0 !important',
    textAlign: 'left'
  },
  filter: {
    display: "inline-flex",
    border: "1px solid #0086C2",
    borderRadius: "16px"
  },
  filterItem: {
    padding: "6px 10px",
    borderRight: "1px solid #0086C2",
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "20px",
    color: "#0086C2",
    textAlign: "center",
    letterSpacing: "0.04em",
    cursor: "pointer",
    "&:first-of-type": {
      borderTopLeftRadius: "16px",
      borderBottomLeftRadius: "16px"
    },
    "&:last-of-type": {
      borderTopRightRadius: "16px",
      borderBottomRightRadius: "16px",
      borderRight: "0"
    }
  },
  filterItemFirstOfType: {
    borderTopLeftRadius: "16px",
    borderBottomLeftRadius: "16px"
  },
  filterItemLastOfType: {
    borderTopRightRadius: "16px",
    borderBottomRightRadius: "16px",
    borderRight: "0"
  },
  filterItemSelected: {
    backgroundColor: 'rgba(0, 134, 194, 0.12)',
    fontWeight: '600',

  },
  backArrow: {
    fontSize: '2rem',
    color: '#404040',
    '&:hover': {
      color: '#0086C2',
    }
  }

}));

const EducationDetails = ({
  intl,
  data,
  month,
  year,
  handleMonthsFetch,
  monthFilterParent
}) => {
  const classes = useStyles();

  const [monthFilter, setMonthFilter] = useState(monthFilterParent);
  const history = useHistory();

  /**
* Menu and menuitem functions.
*/
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const descriptionHandler = () => {
    setOpen(true);

  };
  const descriptionColumn = [
    {
      title: 'Unique Users',
      description: "The number of Unique Users that were impacted in some way by the campaign."
    },
    {
      title: 'Total Impressions',
      description: 'Similar to how digital advertising is measured, an impression is counted when a piece of content is displayed to a user.'
    },
    {
      title: 'Total Engagements',
      description: 'An engagement occurs when a user interacts with a piece of content - for example, if they click on a link, read a blog article or answer a quiz question.'
    },
    {
      title: 'Knowledge Measurements Engagements',
      description: 'For some types of content the user is asked to provide a response that can be definitively classified as either correct or incorrect - these are called Knowledge Measurement Engagements (KME). Quiz questions, the Recycle RIGHT game and Recycling Fundamentals are examples.'
    },
    {
      title: 'Correct Responses',
      description: 'These are the number of KME’s that the user answered correctly.'
    },
    {
      title: 'Correct Response Rate ',
      description: 'This is the percentage of correct responses in KME engagements.'
    }
  ];
  /**
  * Data for dropdown menu items.
  */
  const menuItemData = [
    {
      id: 1,
      name: 'Description',
      method: descriptionHandler,
      disabled: false
    },

  ];

  const getDateSeason = (date) => {
    const month = date.getMonth();
    if (month >= 2 && month <= 4) {
      return 'Spring';
    } else if (month >= 5 && month <= 7) {
      return 'Summer';
    } else if (month >= 8 && month <= 10) {
      return 'Fall';
    } else {
      return 'Winter';
    }
  };
  const getFormattedTime = (seconds, hourOnly) => {
    let d = Number(seconds);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";

    const hours = h + 'h';
    const minutes = m + 'm';
    if (hourOnly) return hours; // return only hours
    return `${hours} ${minutes}`;
  }


  const filterBy = (months) => () => {
    handleMonthsFetch(months);
    setMonthFilter(months);
  };

  const goBack = () => {
    history.goBack();
  };


  return (
    <>
      <Portlet>
        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start">
                <div className="row col-xs-12 col-lg-12 align-items-center" style={{ justifyContent: 'space-between' }} >
                  <div className="align-items-center pl-3 d-flex">
                    <div
                      role="button"
                      style={{ marginRight: 10 }}
                    >
                      <ArrowBackIcon className={classes.backArrow} onClick={goBack} />
                    </div>
                    <Typography variant="h1">
                      Learning Activities
                    </Typography>
                  </div>
                  <div className='d-flex'>
                    <div className={`${classes.filter}`}>
                      <div className={`${classes.filterItem} ${monthFilter === 1 ? classes.filterItemSelected : ''}`} onClick={filterBy(1)}>
                        {month} {year}
                      </div>
                      <div className={`${classes.filterItem} ${monthFilter === 3 ? classes.filterItemSelected : ''}`} onClick={filterBy(3)}>
                        Last 3 Months
                      </div>
                      <div className={`${classes.filterItem} ${monthFilter === 6 ? classes.filterItemSelected : ''}`} onClick={filterBy(6)}>
                        Last 6 Months
                      </div>
                      <div className={`${classes.filterItem} ${monthFilter === 12 ? classes.filterItemSelected : ''}`} onClick={filterBy(12)}>
                        Last 12 Months
                      </div>
                    </div>
                    <DropdownMenu
                      handleClick={handleClick}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      menuItemData={menuItemData}
                    />
                  </div>
                </div>
                <EducationModal
                  open={open}
                  onClose={() => setOpen(false)}
                  title={'Description'}
                  content={(
                    <Typography variant="body1" style={{ textAlign: 'left' }}>
                      For each type of Recycle Coach activity, this report shows:
                      <ul>
                        {
                          descriptionColumn.map((item) => (
                            <li> <strong>{item.title}</strong> - {item.description} </li>
                          ))
                        }
                      </ul>
                      Want help increasing engagements and Correct Responses? Contact your Customer Success Manager to ask about a PRO upgrade.

                    </Typography>
                  )}
                />
              </div>
            )}
          />
        </div>
        <PortletBody style={{ padding: '25px 0px' }}>
          <div className="row" style={{ margin: '0px' }}>

            {
              data && (
                <div style={{ width: '100%' }}>
                  <PortletBody className={`${classes.tableBody}`}>
                    <div className={`row d-flex align-items-center mt-4 ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableHeader}`}>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Activity Name
                        </Typography>
                      </div>
                      {/* <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Unique Users
                        </Typography>
                      </div> */}
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Total Impressions
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Total Engagements
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Engagement Rate
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Knowledge Measurement Engagements
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Correct Responses
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Correct Response Rate
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Time Spent

                        </Typography>
                      </div>
                      {/* <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Campaign Replacement Value
                        </Typography>
                      </div> */}
                    </div>


                    <div className={classes.dataTable}>
                      {
                        Object.keys(data).map((item) => (
                          <div
                            key={data[item].id}
                            className={`row d-flex align-items-center pt-4 pb-4 ${classes.border} ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableRow}`}
                          >
                            <div className="col row d-flex ml-2">
                              <Typography variant="bodySemiBold" className="col">
                                {data[item].title}
                              </Typography>
                            </div>
                            {/* <div className="col row d-flex align-items-center ml-2">
                              <Typography className="col-6" variant="bodySemiBold">
                                {data[item].unique_users === 'N/A' ? data[item].unique_users : Number(data[item].unique_users).toLocaleString()}

                              </Typography>
                            </div> */}
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography variant="bodySemiBold" className="col">
                                {data[item].exposure === 'N/A' ? data[item].exposure : Number(data[item].exposure).toLocaleString()}

                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography className="col-3" variant="bodySemiBold">
                                {data[item].engagement === 'N/A' ? data[item].engagement : Number(data[item].engagement).toLocaleString()}


                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography variant="bodySemiBold" className="col">
                                {data[item].engagement_rate === 'N/A' ? data[item].engagement_rate : `${Number(data[item].engagement_rate).toLocaleString()}%`}

                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography className="col-3" variant="bodySemiBold">
                                {data[item].ekm === 'N/A' ? data[item].ekm : Number(data[item].ekm).toLocaleString()}

                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography variant="bodySemiBold" className="col">
                                {data[item].correct_responses === 'N/A' ? data[item].correct_responses : `${Number(data[item].correct_responses).toLocaleString()}`}
                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography variant="bodySemiBold" className="col">
                                {data[item].crr === 'N/A' ? data[item].crr : `${Number(data[item].crr).toLocaleString()}%`}
                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography variant="bodySemiBold" className="col">
                                {data[item].time_spent === 'N/A' ? data[item].time_spent : getFormattedTime(Number(data[item].time_spent))}

                              </Typography>
                            </div>
                            {/* <div className="col row d-flex align-items-center ml-2">
                              <Typography variant="bodySemiBold" className="col">
                                $
                                {Number(data[item].campaign_replacement_value).toLocaleString()}
                              </Typography>
                            </div> */}
                          </div>

                        ))
                      }
                    </div>
                  </PortletBody>
                </div>
              )

            }

          </div>
        </PortletBody>
      </Portlet>
    </>
  );
};

export default injectIntl(EducationDetails);
