import React from 'react';
import ContentLoader from 'react-content-loader';

const FormLoading = ({
  width = '100%',
  height = 900
}) => (
  <ContentLoader
    speed={2}
    style={{ width, height }}
    backgroundColor={process.env.REACT_APP_COLOR_LOADING_BACKGROUND}
    foregroundColor={process.env.REACT_APP_COLOR__LOADING_FOREGROUND}
  >
    <rect x="0" y="0" rx="6" ry="6" width="100%" height="50" />
    <rect x="0" y="70" rx="6" ry="6" width="100%" height="2" />
    <rect x="0" y="100" rx="6" ry="6" width="30%" height="20" />
    <rect x="0" y="140" rx="6" ry="6" width="30%" height="20" />
    <rect x="0" y="200" rx="6" ry="6" width="47.5%" height="50" />
    <rect x="50%" y="200" rx="6" ry="6" width="47.5%" height="50" />

    <rect x="0" y="280" rx="6" ry="6" width="100%" height="50" />
    <rect x="0" y="350" rx="6" ry="6" width="100%" height="2" />

    <rect x="0" y="370" rx="6" ry="6" width="100%" height="50" />
    <rect x="0" y="440" rx="6" ry="6" width="100%" height="170" />
    <rect x="0" y="630" rx="6" ry="6" width="100%" height="2" />

    <rect x="0" y="660" rx="6" ry="6" width="100%" height="25" />
    <rect x="0" y="697" rx="6" ry="6" width="100%" height="25" />
    <rect x="0" y="732" rx="6" ry="6" width="100%" height="25" />

    <rect x="45%" y="830" rx="6" ry="6" width="25%" height="70" />
    <rect x="75%" y="830" rx="6" ry="6" width="25%" height="70" />

  </ContentLoader>
);

export default FormLoading;
