/* eslint-disable max-len */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

import { CmsLoading } from '../../components/loading';
import Depot from './Depot';
import SetupWizard from './SetupWizard';
import { CustomButton, Typography } from '../../components';
import { CalendarIcon, InfoIcon } from '../../../assets/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    transform: 'rotateX(180deg)',
    overflowX: 'auto',
  },
  wrapper: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'visible',
    padding: 0,
    transform: 'rotateX(180deg)',
    [theme.breakpoints.down(800)]: {
      flexDirection: 'column',
      justifyContent: 'center'
    },
  },
  item: {
    minWidth: '30%',
    maxWidth: '30%',
    [theme.breakpoints.down(1350)]: {
      minWidth: '45%',
      maxWidth: '45%',
    },
    [theme.breakpoints.down(800)]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
  btnWrapper: {
    marginTop: '30vh',
    [theme.breakpoints.down(800)]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '2vh',
    }
  },
  button: {
    width: '60px',
    height: '60px !important',
    borderRadius: '100px !important',
  },
  emptyList: {
    width: '100%',
    minHeight: 120,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    borderRadius: 10,
    backgroundColor: process.env.REACT_APP_COLOR_TERTIARY_DARK
  },
  calendarIcon: {
    width: 80,
    height: 80,
    marginLeft: 50,
    marginRight: 50,
  },
  infoIcon: {
    width: 30,
    height: 30,
    marginRight: 15,
  },
}));

const Depots = ({
  intl,
  searchTerm,
  addDepot,
  depotList,
  fetchState
}) => {
  const classes = useStyles();
  const [loadMore, setLoadMore] = useState(1);
  const isSendRequest = fetchState === 'send-request';

  const filteredDepotList = searchTerm
    ? [...depotList]
      .sort((a, b) => (a.drop_depot_admin_nm.toLowerCase() < b.drop_depot_admin_nm.toLowerCase() ? -1 : 1))
      .filter((item) => item.depot_nm.toLowerCase().includes(searchTerm.toLowerCase())
        || item.drop_depot_admin_nm.toLowerCase().includes(searchTerm.toLowerCase()))
    : [...depotList].sort((a, b) => (a.drop_depot_admin_nm.toLowerCase() < b.drop_depot_admin_nm.toLowerCase() ? -1 : 1));

  return (
    <>
      {
      isSendRequest
        ? <CmsLoading />
        : (
          <div className={classes.container}>
            <div className={classes.wrapper}>
              { filteredDepotList.length ? (
                <>
                  {
                    <div key="firstDepot" className={classes.item}>
                      <Depot data={filteredDepotList[0]} />
                    </div>
                  }
                  { addDepot
                    ? <SetupWizard />
                    : filteredDepotList
                      .slice(1, filteredDepotList.length > loadMore * 10 ? loadMore * 10 : filteredDepotList.length)
                      .map((item, index) => (
                        <div key={`depot_${String(index)}`} className={classes.item}>
                          <Depot key={item} data={item} />
                        </div>
                      ))}
                  { addDepot === 0
                    && filteredDepotList.length > loadMore * 10
                    && (
                      <div className={classes.btnWrapper}>
                        <CustomButton
                          className={classes.button}
                          label={intl.formatMessage({ id: 'GENERAL.MORE' })}
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={() => {
                            setLoadMore(loadMore + 1);
                          }}
                        />
                      </div>
                    )}
                </>
              ) : (
                <div className="w-100">
                  <div className={classes.emptyList}>
                    <CalendarIcon className={classes.calendarIcon} />
                    <Typography
                      variant="h1"
                      className="d-flex align-items-center"
                      color={process.env.REACT_APP_COLOR_SECONDARY}
                    >
                      <InfoIcon
                        className={classes.infoIcon}
                        color={process.env.REACT_APP_COLOR_SECONDARY}
                      />
                      {intl.formatMessage({ id: 'DEPOT.EMPTY_LIST.TEXT' })}
                    </Typography>
                  </div>
                  { addDepot ? <SetupWizard /> : null }
                </div>
              )}
            </div>
          </div>
        )
      }
    </>
  );
};

const mapStateToProps = (store) => ({
  depotList: store.depot.depotList,
  addDepot: store.depot.addDepot,
  fetchState: store.depot.fetchState,
});

export default injectIntl(connect(mapStateToProps, null)(Depots));
