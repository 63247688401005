import actionTypes from './actionTypes';

export const getLessonsLibraryDataRequestAction = (project_id, district_id) => ({
  type: actionTypes.GET_LESSONS_LIBRARY_DATA_REQUEST,
  payload: { project_id, district_id }
});

export const getLessonsLibraryDataReceiveAction = (data) => ({
  type: actionTypes.GET_LESSONS_LIBRARY_DATA_RECEIVE,
  payload: data
});

export const getLessonsLibraryDataFailAction = (error) => ({
  type: actionTypes.GET_LESSONS_LIBRARY_DATA_FAIL,
  payload: error
});


export const featuredLessonFeedbackRequest = (meta, feedback, type = 'campaign') => ({
  type: actionTypes.FEATURED_LESSON_FEEDBACK_REQUEST,
  payload: { meta, feedback, type }
});

export const featuredLessonFeedbackdReceive = (data) => ({
  type: actionTypes.FEATURED_LESSON_FEEDBACK_RECEIVE,
  payload: data
});

export const featuredLessonFeedbackFail = (error) => ({
  type: actionTypes.FEATURED_LESSON_FEEDBACK_FAIL,
  payload: error
});
