import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import Main from './components/Main';
import Widget from './components/Widget';
import Modal from './components/Modal';
import * as actions from '../Dashboard/redux/actions';
import { UserInfoCard } from '../Dashboard/components';
import { ErrorContainer, LoadingSpinner } from '../components/loading';
import { Snackbar2 } from '../components';

const useStyles = makeStyles({
  container: {
    width: '1200px',
    margin: '0 auto',
    alignItems: 'start'
  },
});

/**
 * TODO'S
 *  1. Remove dummy images, dummy-text (en .js)
 *  2. Add CSM card
 *  3. Verify modal designs
 *  4. Remove component, delete components
 *  5. Add keys
 *  6. Handle server response.
 *  7. Delete moxk adapter
 */

const WGWHome = ({
  intl,
  csm,
  getDashboardDataRequestAction,
  toDate,
  fromDate,
  loading,
  errorDashboard,
  errorWGWHome,
  isFirstLoading,
  responseStatus,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [selectedMainTabIndex, setSelectedMainTabIndex] = useState(1);
  const [selectedWidgetTabIndex, setSelectedWidgetTabIndex] = useState(1);
  const [openSubmitAPhoto, setOpenSubmitAPhoto] = useState();
  const [openSuggestANewTerm, setOpenSuggestANewTerm] = useState();

  const mainContentData = [
    {
      id: 1,
      title: 'WGW.EDIT.MATERIAL.TITLE',
      video: 'https://player.vimeo.com/video/715635505?h=a27d467a8f',
      onButtonClick: () => history.push('/category'),
      description: 'WGW.EDIT.MATERIAL.DESCRIPTION',
      stepImageBaseName: 'edit-material-step-',
      stepCount: 7,
      stepContentBaseName: 'WGW.EDIT.MATERIAL.STEP.'
    },
    {
      id: 2,
      title: 'WGW.ADD.EDIT.A.DROP.OFF.TITLE',
      video: 'https://player.vimeo.com/video/715604745?h=2d341a8add',
      onButtonClick: () => history.push('/depot'),
      description: null,
      stepImageBaseName: 'add-edit-a-drop-off-step-',
      stepCount: 11,
      stepContentBaseName: 'WGW.ADD.EDIT.A.DROP.OFF.STEP.'
    },
    {
      id: 3,
      title: 'WGW.ADD.EDIT.AN.EVENT.TITLE',
      video: 'https://player.vimeo.com/video/715634384?h=3976b6f142',
      onButtonClick: () => history.push('/event'),
      description: null,
      stepImageBaseName: 'add-edit-an-event-step-',
      stepCount: 7,
      stepContentBaseName: 'WGW.ADD.EDIT.AN.EVENT.STEP.'
    },
    {
      id: 4,
      title: 'WGW.HOW.TO.ALLOCATE.A.DEPOT.TO.A.MATERIAL.TITLE',
      video: 'https://player.vimeo.com/video/716802359?h=0d7ae6c887',
      onButtonClick: () => history.push('/category'),
      description: null,
      stepImageBaseName: 'how-to-allocate-a-depot-',
      stepCount: 6,
      stepContentBaseName: 'WGW.HOW.TO.ALLOCATE.A.DEPOT.TO.A.MATERIAL.STEP.'
    },
    {
      id: 5,
      title: 'WGW.HOW.TO.MOVE.A.MATERIAL.TITLE',
      video: 'https://player.vimeo.com/video/716803081?h=d87361c33f',
      onButtonClick: () => history.push('/category'),
      description: null,
      stepImageBaseName: 'how-to-move-a-material-step-',
      stepCount: 7,
      stepContentBaseName: 'WGW.HOW.TO.MOVE.A.MATERIAL.STEP.'
    },
  ];

  const widgetData = [
    {
      id: 1,
      title: 'WGW.REVIEW.YOUR.WASTE.RECYCLING.RULES',
      buttonName: 'WGW.REVIEW.YOUR.WASTE.RECYCLING.RULES.BUTTON',
      content: 'WGW.REVIEW.YOUR.WASTE.RECYCLING.RULES.CONTENT',
      onButtonClick: () => history.push('/waste-recycling-rules')
    },
    {
      id: 2,
      title: 'WGW.SUGGEST.A.NEW.SEARCH.TERM',
      buttonName: 'WGW.SUGGEST.A.NEW.SEARCH.TERM.BUTTON',
      content: 'WGW.SUGGEST.A.NEW.SEARCH.TERM.CONTENT',
      onButtonClick: () => setOpenSuggestANewTerm(true)
    },
    {
      id: 3,
      title: 'WGW.SUBMIT.A.PHOTO',
      buttonName: 'WGW.SUBMIT.A.PHOTO.BUTTON',
      content: 'WGW.SUBMIT.A.PHOTO.CONTENT',
      onButtonClick: () => setOpenSubmitAPhoto(true)
    },
    {
      id: 4,
      title: 'WGW.REVIEW.EDIT.COLLECTION.REQUIREMENTS',
      buttonName: 'WGW.REVIEW.EDIT.COLLECTION.REQUIREMENTS.BUTTON',
      content: 'WGW.REVIEW.EDIT.COLLECTION.REQUIREMENTS.CONTENT',
      onButtonClick: () => history.push('/collection-requirement')
    },

  ];

  // fetch dashboard data if csm data is not available
  useEffect(() => {
    if (!csm) {
      getDashboardDataRequestAction(fromDate, toDate);
    }
  }, [toDate, fromDate, getDashboardDataRequestAction, csm]);

  /**
   * Display snackabar after closing modal
   */
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    if (responseStatus) {
      setShowSnackbar(true);
      if (responseStatus !== 'error') {
        setOpenSubmitAPhoto(false);
        setOpenSuggestANewTerm(false);
      }
    }
  }, [responseStatus]);

  useEffect(() => {
    setShowSnackbar(false);
  }, []);

  return (
    <>

      <Snackbar2
        key={showSnackbar}
        isOpen={showSnackbar}
        setIsOpenHandler={setShowSnackbar}
        message={responseStatus === 'error'
          ? errorWGWHome
          : intl.formatMessage({ id: 'GENERAL.MESSAGE.SENT' })}
        status={responseStatus}
      />

      <div className={`d-flex ${classes.container}`}>
        <Main
          mainContentData={mainContentData}
          selectedMainTabIndex={selectedMainTabIndex}
          setSelectedMainTabIndex={setSelectedMainTabIndex}
          selectedTabData={mainContentData[selectedMainTabIndex - 1]}
        />
        <div className="col-4">
          <Widget
            widgetData={widgetData}
            selectedWidgetTabIndex={selectedWidgetTabIndex}
            setSelectedWidgetTabIndex={setSelectedWidgetTabIndex}
          />

          {/* contitionally show loading spinner, error component or content for CSM card */}
          {loading && isFirstLoading ? <LoadingSpinner height={200} />
            : errorDashboard ? (<ErrorContainer small reportName="CSM" portlet height={200} error={errorDashboard} />)
              : csm?.has_csm && <UserInfoCard isCsm />}
        </div>

        {/* submit a photo modal */}
        {openSubmitAPhoto && (
          <Modal
            open={openSubmitAPhoto}
            onClose={() => setOpenSubmitAPhoto(false)}
            title={widgetData[2].title}
            submitAPhoto
          />
        )}

        {/* suggest a new term modal */}
        {openSuggestANewTerm && (
          <Modal
            open={openSuggestANewTerm}
            onClose={() => setOpenSuggestANewTerm(false)}
            title={widgetData[1].title}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (store) => ({
  csm: store.dashboard.csm,
  toDate: store.common.toDate,
  fromDate: store.common.fromDate,
  loading: store.dashboard.loading,
  errorDashboard: store.dashboard.error,
  isFirstLoading: store.dashboard.isFirstLoading,
  responseStatus: store.WGWHome.responseStatus,
  errorWGWHome: store.WGWHome.error,
});

export default injectIntl(connect(mapStateToProps, actions)(WGWHome));
