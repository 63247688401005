import React from 'react';

const Body = ({ children }) => (
  <div className="kt-login__body">
    <div className="kt-login__form">
      {children}
    </div>
  </div>
);

export default Body;
