import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as actions from './redux/actions';
import { LayoutSplashScreen } from '../../../../_metronic';

const Logout = ({ logoutRequest, hasAuthToken }) => {
  useEffect(() => {
    logoutRequest();
  }, [logoutRequest]);

  return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
};

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  actions
)(Logout);
