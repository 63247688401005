import * as utils from '../../_metronic/utils/utils';

const localStorageLastLocationKey = 'demo1-lastLocation';

function acceptLocation(lastLocation) {
  if (
    lastLocation
    && lastLocation.pathname
    && lastLocation.pathname !== '/'
    && lastLocation.pathname.indexOf('auth') === -1
    && lastLocation.pathname !== '/logout'
  ) {
    return true;
  }

  return false;
}

export function saveLastLocation(lastLocation) {
  const localStorageLocations = utils.getStorage(localStorageLastLocationKey);
  const userLocations = localStorageLocations
    ? JSON.parse(localStorageLocations)
    : [];

  if (acceptLocation(lastLocation)) {
    userLocations.push(lastLocation.pathname);
    utils.setStorage(
      localStorageLastLocationKey,
      JSON.stringify(userLocations),
      120
    );
  }
}

export function forgotLastLocation() {
  utils.removeStorage(localStorageLastLocationKey);
}

export function getLastLocation() {
  const localStorageLocations = utils.getStorage(localStorageLastLocationKey);
  if (!localStorageLocations) {
    return '/';
  }

  const userLocations = JSON.parse(localStorageLocations);
  const result = userLocations.length > 0 ? userLocations.pop() : '/';
  return result;
}
