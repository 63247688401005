import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import {
  Portlet,
  PortletBody
} from '../../../../../_metronic/partials/content/Portlet';

import { MunicipalitySelector, Typography } from '../../../components';
import { validation } from '../../../../utils';

import * as sharedActions from '../../../redux/actions';

const useStyles = makeStyles({
  container: {
    minHeight: 176.44,
    maxHeight: 176.44,
  },
  cityTitle: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
});

const Welcome = ({
  intl,
  setDistrictAction,
  municipalities,
  district,
  username,
  hasCounty,
  counties,
  county,
  isCounty,
  setCountyAction
}) => {
  const { container, cityTitle } = useStyles();
  const hasDistrict = !!(Object.keys(district).length);

  return (
    <Portlet id="welcome-box" className={container}>
      <PortletBody>
        {hasDistrict ? (
          <Typography variant="hero" className={cityTitle}>
            {intl.formatMessage({ id: 'DASHBOARD.WELCOME' }, { cityName: username })}
          </Typography>
        ) : (
          <Typography variant="hero" className={cityTitle}>
            {intl.formatMessage({ id: 'GENERAL.SELECT_MUNICIPALITY' })}
          </Typography>
        )}
        <div className="row">
          <div className="col-xl-5">
            {
              municipalities
                && municipalities.length
                ? (
                  <div style={{ marginTop: 15 }}>
                    <MunicipalitySelector
                      isCountyEnable
                      title={
                        (hasCounty !== null && isCounty && counties)
                          ? 'County'
                          : intl.formatMessage({ id: 'REPORT.GENERAL.REPORTING_DISTRICT' })
                      }
                      onChange={
                        (hasCounty !== null && isCounty)
                          ? setCountyAction
                          : setDistrictAction
                      }
                      value={(hasCounty !== null && isCounty) ? county : district}
                      invalidText={
                        !validation(district || '', 'required')
                          ? intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
                          : null
                      }
                    />
                  </div>
                ) : null
            }
          </div>
        </div>
      </PortletBody>
    </Portlet>
  );
};

/**
 * Map state to props
 */
const mapStateToProps = (store) => ({
  municipalities: store.common.municipalities,
  district: store.common.district,
  username: `${store.auth.user_info.first_name} ${store.auth.user_info.last_name}`,
  hasCounty: store.auth.user_info.counties,
  counties: store.common.counties,
  county: store.common.county,
  isCounty: store.common.isCounty,
});

export default injectIntl(
  connect(
    mapStateToProps,
    { ...sharedActions }
  )(Welcome)
);
