import React from 'react';
import { injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';


import { Typography } from '../../../components';
import { toAbsoluteUrl } from '../../../../../_metronic';

import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';
import { SummaryOtherLoading } from '../../../components/loading';


const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 385,
    // maxHeight: 450,
    backgroundColor: '#3398fc',
    color: 'white',
    height: '100%',
    '@media (max-width: 1390px)': {
      height: '100%',
    },
    '@media (max-width: 1200px)': {
      minHeight: 'unset',
    }
  },
  group: {
    display: 'flex',
    gap: '10px'
  },
  groupItem: {
    display: 'flex',
    flexDirection: 'column',
    width: 235,
    MightNeedFullWidthSoTheItemsDontGetTooBigOnLargeScreenColor: '#63666A',
    ChangeTheColorFontFamily: 'sans-serif',
  },
  groupTitle: {
    fontWeight: '600',
    fontSize: 16,
    color: 'white',
    letterSpacing: '0.04em'
  },
  groupNumber: {
    fontSize: '30px',
    fontWeight: '600',
    marginTop: '6px',
    marginBottom: '6px',
    color: '#2A95FF'
  },

}));

const ParticipationRate = ({
  intl,
  reportData,
  isLoading,
}) => {
  const classes = useStyles();

  const user_participation_rate = {
    "value": 0,
    "peer_group": 0,
    "household": 0
  };

  if (!reportData || !reportData.user_participation_rate) {
    reportData.user_participation_rate = JSON.parse(JSON.stringify(user_participation_rate));
  }

  return (
    <>
      <Portlet className={classes.container}>
        <PortletBody>
          <div className="column">
            {
              isLoading ?
                <SummaryOtherLoading /> :
                (
                  <>
                    <div className={`${classes.groupItem}`}>
                      <Typography variant="h1" style={{ color: 'white' }}>
                        Participation Rate*
                      </Typography>
                      {/* <div className={classes.groupTitle}>Participation Rate*</div> */}
                      <div>Users per 1,000 households</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20 }}>
                      <Typography variant="h1" style={{ color: 'white', fontSize: 20, }}>
                        Your rating
                      </Typography>
                      <Typography variant="body" style={{ color: 'white', fontSize: 40, }}>
                        {reportData.user_participation_rate.value && reportData.user_participation_rate.value !== 'N/A' ? Number(reportData.user_participation_rate.value).toLocaleString() : reportData.user_participation_rate.value}
                      </Typography>
                      <br />
                      <br />

                      <Typography variant="h1" style={{ color: 'white', fontSize: 20, }}>
                        Peer Group rating
                      </Typography>
                      <Typography variant="body" style={{ color: 'white', fontSize: 40, marginBottom: 20 }}>
                        {reportData.user_participation_rate.peer_group && reportData.user_participation_rate.peer_group !== 'N/A' ? Number(reportData.user_participation_rate.peer_group).toLocaleString() : reportData.user_participation_rate.peer_group}
                      </Typography>
                      <br />

                      <div>*Based on a household count of {Number(reportData.user_participation_rate.household).toLocaleString()}</div>

                    </div>

                  </>
                )
            }


          </div>
        </PortletBody>
      </Portlet>
    </>
  );

};

export default injectIntl(ParticipationRate);
