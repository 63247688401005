import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Typography } from '..';

const CustomToolbar = ({ label, onNavigate }) => {
  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <div className="d-flex mt-1 align-items-center">

          <NavigateBeforeIcon
            onClick={() => onNavigate('PREV')}
            fontSize="large"
            style={{
              color: '#757575',
              transition: 'all .3s',
              cursor: 'pointer',
              opacity: 1,
            }}
          />
          <Typography style={{ textAlign: 'center', fontSize: 18, fontWeight: 600, letterSpacing: 0.72 }} color="#2A95FF" variant="bodySemiBold">
            {label}
          </Typography>
          <NavigateNextIcon
            onClick={() => onNavigate('NEXT')}
            fontSize="large"
            style={{
              color: '#757575',
              transition: 'all .3s',
              cursor: 'pointer',
              opacity: 1,
            }}
          />
        </div>

      </span>
    </div>
  );
};

export default CustomToolbar;

