import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Typography } from '../../components';

const useStyles = makeStyles(() => ({
  container: {
    maxHeight: 170,
    overflow: 'auto',
    paddingRight: 25,
  },
  drawerLinkItem: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
}));

const EventDrawerLinks = ({
  drawerLinks
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {
        drawerLinks.map((item, index) => (
          <div
            key={item.drawer_title}
            className={clsx({
              'py-4': true,
              [classes.drawerLinkItem]: index !== drawerLinks.length - 1,
            })}
          >
            <Typography variant="body">
              {item.drawer_title}
            </Typography>
          </div>
        ))
      }
    </div>
  );
};

export default EventDrawerLinks;
