import moment from 'moment';
import { truncate } from '../../utils';

/**
 * Normalize the campaign data like adding date
 * and short description.
 *
 * @public
 * @param {array} campaignData The raw campaign data
 * @author Hesan
 * @returns Normalized campaign data
 */
const campaignNormalizer = (campaignData) => campaignData.map((campaign) => {
  const {
    campaign_id: id,
    campaign_nm: title,
    coachables: acs,
    publish_dt,
    end_dt,
    is_active,
    notes: description,
    local_requirements: localRequirements,
    ...other
  } = campaign;
  const startMonth = publish_dt ? moment(publish_dt).startOf('month').format('MMMM') : '';
  const startDay = publish_dt ? moment(publish_dt).format('DD') : '';
  const endDay = end_dt ? moment(end_dt).format('DD') : '';
  const shortDescription = description.length > 250 ? truncate(description, 255) : '';

  let endMonthDay = '';
  if (!moment(publish_dt).isSame(end_dt, 'month')) {
    endMonthDay = moment(end_dt).format('MMMM');
  }

  let date = '';
  let schedule_date = '';
  if (startMonth && startDay && endDay) {
    /**
     * Normal Date for items
     */
    date = `${startMonth} ${startDay} - ${endMonthDay} ${endDay}`;

    /**
     * History item date details
     */
    schedule_date = `${moment(publish_dt).startOf('month').format('MMM')} ${startDay}, ${moment(publish_dt).format('YYYY')} - ${moment(end_dt).startOf('month').format('MMM')} ${endDay}, ${moment(end_dt).format('YYYY')}`;
  }

  return {
    id,
    activities: acs,
    date,
    title,
    description,
    shortDescription,
    schedule_date,
    active: (is_active === 't'),
    localRequirements,
    publish_dt,
    end_dt,
    ...other
  };
});

const getNextnMonths = (dt, len) => {
  const months = [];
  for (let i = 1; i < (len + 1); i += 1) {
    months.push(
      moment(dt).add(i, 'months').format('MMMM')
    );
  }
  return months;
};

export {
  campaignNormalizer,
  getNextnMonths,
  campaignNormalizer as default
};
