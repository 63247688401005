import actionTypes from './actionTypes';

export const getBlogDetailsRequest = (blogId) => ({
  type: actionTypes.GET_BLOG_DETAILS_REQUEST,
  payload: { blogId }
});

export const getBlogDetailsReceive = (data) => ({
  type: actionTypes.GET_BLOG_DETAILS_RECEIVE,
  payload: data
});

export const getBlogDetailsFail = (error) => ({
  type: actionTypes.GET_BLOG_DETAILS_FAIL,
  payload: error
});

export const blogReadRequest = (blogId) => ({
  type: actionTypes.BLOG_READ_REQUEST,
  payload: { blogId }
});

export const blogReadReceive = (data) => ({
  type: actionTypes.BLOG_READ_RECEIVE,
  payload: data
});

export const blogReadFail = (error) => ({
  type: actionTypes.BLOG_READ_FAIL,
  payload: error
});

export const blogUnreadRequest = (blogId) => ({
  type: actionTypes.BLOG_UNREAD_REQUEST,
  payload: { blogId }
});

export const blogUnreadReceive = (data) => ({
  type: actionTypes.BLOG_UNREAD_RECEIVE,
  payload: data
});

export const blogUnreadFail = (error) => ({
  type: actionTypes.BLOG_UNREAD_FAIL,
  payload: error
});


export const blogFeedbackRequest = (meta, feedback, type = 'blog') => ({
  type: actionTypes.BLOG_FEEDBACK_REQUEST,
  payload: { meta, feedback, type }
});

export const blogFeedbackdReceive = (data) => ({
  type: actionTypes.BLOG_FEEDBACK_RECEIVE,
  payload: data
});

export const blogFeedbackFail = (error) => ({
  type: actionTypes.BLOG_FEEDBACK_FAIL,
  payload: error
});
