import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '../../../components';

import {
  Portlet, PortletBody, PortletHeader,
} from '../../../../../_metronic/partials/content/Portlet';

const useStyles = makeStyles(() => ({
  tableHeader: {
    backgroundColor: '#3398fc',
    marginRight: 0,
    marginLeft: 0,
    marginTop: '0 !important',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: '8px 0',
    minHeight: 72,
  },
  tableHeaderFont: {
    color: '#FFF'
  },
  tableMaterialsItem: {
    color: '#3398fc',
    textDecoration: 'underline'
  },
  tableRow: {
    borderBottom: '1px solid lightgrey',
    borderRight: '1px solid lightgrey',
    borderLeft: '1px solid lightgrey',
    marginLeft: 0,
    marginRight: 0,
  },
  materialsTable: {
    // margin: '0 16px',
    borderRadius: '0 !important',
    boxShadow: 'none !important',
  },
  tableLabel: {
    borderBottom: 'none !important',
    borderRadius: '0 !important',
    padding: '0 !important',
  },
  tableBody: {
    padding: '0 !important'
  },
  materialsLabel: {
    color: '#6C7293',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },

}));

const MaterialsTable = ({
  data,
  thisYear,
  lastYear,
}) => {
  const classes = useStyles();

  return (
    <div>
      {
        Object.keys(data.material_questions).map((material_question) => (
          <Portlet className={`h-100 position-relative ${classes.materialsTable}`}>
            <PortletHeader
              className={`${classes.tableLabel}`}
              title={(
                <div className="d-flex align-items-center">
                  <Typography variant="h1" className={`${classes.materialsLabel}`}>
                    {material_question}
                    {' '}
                    Materials
                  </Typography>
                </div>
              )}
            />
            <PortletBody className={`${classes.tableBody}`}>
              <div className={`row d-flex align-items-center mt-4 ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableHeader}`}>
                <div className="col-3">
                  <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                    Materials
                  </Typography>
                </div>
                <div className="col ml-3">
                  <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                    Accepted in Recycling
                  </Typography>
                </div>
                <div className="col ml-2">
                  <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                    # of Responses
                  </Typography>
                </div>
                <div className="col ml-2">
                  <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                    # of Correct Responses
                  </Typography>
                </div>
                <div className="col ml-2">
                  <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                    % of Correct({lastYear})
                  </Typography>
                </div>
                <div className="col ml-2">
                  <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                    % of Correct ({thisYear})
                  </Typography>
                </div>
                <div className="col ml-2">
                  <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                    % of Correct ({thisYear} - National Year)
                  </Typography>
                </div>
              </div>
              <div className={classes.dataTable}>
                {
                  data.material_questions[material_question].map((item) => (
                    <div
                      key={item.mat_nm}
                      className={`row d-flex align-items-center pt-4 pb-4 ${classes.border} ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableRow}`}
                    >
                      <div className="col-3">
                        <Typography variant="bodySemiBold" className={`${classes.tableMaterialsItem}`}>
                          {item.mat_nm}
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-3">
                        <Typography className="col-3" variant="bodySemiBold">
                          {item.accepted ? 'Yes' : item.accepted !== null ? 'No' : ''}
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="bodySemiBold" className={`col ${classes.ranking}`}>
                          {item.num_responses}
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography className="col-3" variant="bodySemiBold">
                          {item.num_correct_responses}

                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="bodySemiBold" className={`col ${classes.ranking}`}>
                          {item.correct_last_yr}
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography className="col-3" variant="bodySemiBold">
                          {item.correct_this_yr}
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="bodySemiBold" className={`col ${classes.ranking}`}>
                          {item.correct_national_this_yr}
                        </Typography>
                      </div>
                    </div>

                  ))
                }
              </div>
            </PortletBody>
          </Portlet>
        ))
      }
    </div>
  );
};

export default injectIntl(MaterialsTable);
