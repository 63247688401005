/* eslint-disable max-len */
import React from 'react';
import { injectIntl } from 'react-intl';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import MuiPagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

import Typography from './Typography';

const useStyles = makeStyles((theme) => ({
  responsive: {
    [theme.breakpoints.down(800)]: {
      flexDirection: 'column-reverse !important',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
    },
  },
  items: {
    [theme.breakpoints.down(800)]: {
      marginTop: '15px'
    },
  }
}));

const Pagination = ({
  intl,
  rowsPerPageOptions = [5, 10, 20],
  page,
  rowsPerPage,
  handlePageChange,
  handleRowsPerPageChange,
  dataLength,
  pager = true,
  hideRowsPerPage = false,
}) => {
  const onRowsPerPageChange = (value) => {
    handleRowsPerPageChange(value);
    handlePageChange(1);
  };

  const onPageChange = (event, newPage) => {
    handlePageChange(newPage);
  };
  const classes = useStyles();

  if (dataLength) {
    return (
      <div className={`d-flex justify-content-end align-items-center ${classes.responsive}`}>
        {
          pager && !hideRowsPerPage &&(
            <div
              className={`d-flex justify-content-center align-items-center ${classes.items}`}
            >
              <Typography variant="bodySemiBold">
                {intl.formatMessage({ id: 'GENERAL.ROWS_PER_PAGE' })}
              </Typography>
              <div
                className="ml-4"
              >
                <form autoComplete="off">
                  <FormControl>
                    <Select
                      disableUnderline
                      value={rowsPerPage}
                      onChange={(event) => onRowsPerPageChange(event.target.value)}
                      IconComponent={KeyboardArrowDown}
                    >
                      {rowsPerPageOptions.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                    </Select>
                  </FormControl>
                </form>
              </div>
            </div>
          )
        }
        <div
          className={`d-flex align-items-center ${classes.responsive}`}
        >
          <Typography
            className={`mr-5 ml-5 ${classes.items}`}
            variant="bodySemiBold"
          >

            {intl.formatMessage(
              { id: 'GENERAL.PAGINATION_INFO' },
              {
                from: (page - 1) * rowsPerPage + 1,
                to: page * rowsPerPage < dataLength ? page * rowsPerPage : dataLength,
                count: dataLength
              }
            )}
          </Typography>
          <div className={classes.items}>
            <MuiPagination
              page={page}
              count={Math.ceil(Number(dataLength) / rowsPerPage)}
              onChange={onPageChange}
            />
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default injectIntl(Pagination);
