const actionTypes = {
  // Profile actionTypes for setting loading status
  SET_LOADING: 'SET_LOADING',
  RESET_LOADING: 'RESET_LOADING',

  // Profile actionTypes for editing user Profile
  EDIT_PROFILE_REQUEST: 'EDIT_PROFILE_REQUEST',
  EDIT_PROFILE_RECEIVE: 'EDIT_PROFILE_RECEIVE',
  EDIT_PROFILE_FAIL: 'EDIT_PROFILE_FAIL',

  // Profile actionTypes for uploading user Profile image
  EDIT_PROFILE_PICTURE_REQUEST: 'EDIT_PROFILE_PICTURE_REQUEST',
  EDIT_PROFILE_PICTURE_RECEIVE: 'EDIT_PROFILE_PICTURE_RECEIVE',
  EDIT_PROFILE_PICTURE_FAIL: 'EDIT_PROFILE_PICTURE_FAIL',
};

export default actionTypes;
