import actionTypes from './actionTypes';

export const getAllEducationBlogsLibraryRequest = (project_id, district_id) => ({
  type: actionTypes.GET_ALL_EDUCATION_BLOGS_LIBRARY_REQUEST,
  payload: { project_id, district_id }
});

export const getAllEducationBlogsLibraryReceive = (data) => ({
  type: actionTypes.GET_ALL_EDUCATION_BLOGS_LIBRARY_RECEIVE,
  payload: data
});

export const getAllEducationBlogsLibraryFail = (error) => ({
  type: actionTypes.GET_ALL_EDUCATION_BLOGS_LIBRARY_FAIL,
  payload: error
});
