/* eslint-disable import/prefer-default-export */
import cts from '../../../crud/cts';

/**
 * User report API which fetches user report data
 */
export const getAnnualSurveyReport = async ({
  municipality,
  year
}) => {
  const currentYear = new Date().getFullYear();
  console.log('year', year)
  return cts(
    'get',
    `reports/annual-recycling-survey?year=${year}&municipality=${municipality}`,
  );
};
