import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {
  Typography, CustomSelect, CheckBox, CustomButton
} from '../../../components';
import {
  Portlet,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';
import {
  renderCheckboxLabel, MOVES_TO_OPTIONS, findNextDayHandler, findPreviousDayHandler, ALL_WEEK
} from '../../helper';

const useStyles = makeStyles((theme) => ({
  button: {
    width: 150,
    height: 48,
    marginTop: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 20,
    gap: 20
  },
}));

const CalendarChange = ({
  holidaysChanges,
  handleSaveHolidays,
}) => {
  const [day, setDay] = useState('monday');

  const [data, setData] = useState(holidaysChanges[day]);
  const [holidaysChangesData, setHolidaysChangesData] = useState(holidaysChanges);
  const history = useHistory();
  const classes = useStyles();

  const selectSomethingDifferentHandler = () => {
    setData((prev) => ({
      ...prev,
      notAffected: false,
      isCustom: true,
      oneDayLater: false,
    }));
  };

  const updateDataHandler = (value, item, index) => {
    const copyData = { ...data };
    const copyCustomConfig = { ...copyData.customConfig };
    let copyDay = { ...copyCustomConfig[item] };
    const modify = index === 'action'
      ? { action: value, day: copyDay.day }
      : { action: copyDay.action, day: value };
    copyDay = modify;
    copyCustomConfig[item] = copyDay;
    copyData.customConfig = copyCustomConfig;
    setData(copyData);
  };

  const getNoDayRequired = (action) => action === 'NoChange'
    || action === 'NotANormalPickupDay'
    || action === 'CollectionCancelled';

  const goBackHandler = () => {
    if (day === 'monday') {
      history.push('/schedules');
    } else {
      const previousDay = findPreviousDayHandler(day);
      setData(holidaysChangesData[previousDay]);
      setDay(previousDay);
    }
  };

  const goNextHandler = async () => {
    if (day !== 'sunday') {
      const holidaysChangesDataTemp = { ...holidaysChangesData };
      holidaysChangesDataTemp[day] = data;
      setHolidaysChangesData(holidaysChangesDataTemp);
      const nextDay = findNextDayHandler(day);
      setData(holidaysChangesDataTemp[nextDay]);
      setDay(nextDay);
    } else {
      handleSaveHolidays(holidaysChangesData);
    }
  };

  const isCustomConfigValid = () => {
    const config = data.customConfig;
    for (const key in config) {
      const { action, day } = config[key];
      if (
        !(getNoDayRequired(action) || (action.length > 0 && day.length > 0))
      ) {
        return false;
      }
    }
    return true;
  };

  const canGoNext = data.oneDayLater
    || data.notAffected
    || (data.isCustom && isCustomConfigValid());

  return (
    <>
      <div className="d-flex flex-wrap mt-3">
        <Portlet>
          <PortletBody className="d-flex justify-content-center">
            <div key={day}>
              <div>
                <Typography variant="h1" style={{ fontSize: 24, fontWeight: 700 }}>Holiday Collection Changes</Typography>
                <Typography variant="h2" style={{ margin: '20px 0', fontSize: 16 }}>
                  What happens when the holiday lands on
                  {' '}
                  {day.toUpperCase()}
                  ?:
                </Typography>

                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                >
                  {(day === 'sunday' || day === 'saturday') && (
                    <CheckBox
                      style={{ margin: '10px 10px 10px 0px' }}
                      label={(
                        <Typography variant="bodyTableSemiBold">
                          Collections are NOT AFFECTED
                          {' '}
                        </Typography>
                      )}
                      checked={data.notAffected}
                      onChange={() => {
                        setData((prev) => ({
                          ...prev,
                          notAffected: true,
                          isCustom: false,
                          oneDayLater: false,
                        }));
                      }}
                      checkedIcon={
                        <CheckCircleIcon fontSize="medium" style={{ color: '#00C8BC' }} />
                      }
                      icon={<RadioButtonUncheckedIcon fontSize="medium" />}
                    />
                  )}
                  <CheckBox
                    style={{ margin: '10px 10px 10px 0px' }}
                    label={renderCheckboxLabel(day)}
                    checked={data.oneDayLater}
                    onChange={() => {
                      setData((prev) => ({
                        ...prev,
                        notAffected: false,
                        isCustom: false,
                        oneDayLater: true,
                      }));
                    }}
                    checkedIcon={
                      <CheckCircleIcon fontSize="medium" style={{ color: '#00C8BC' }} />
                    }
                    icon={<RadioButtonUncheckedIcon fontSize="medium" />}
                  />
                  <CheckBox
                    style={{ margin: '10px 10px 10px 0px' }}
                    label={(
                      <Typography variant="bodyTableSemiBold">
                        Something different
                      </Typography>
                    )}
                    checked={data.isCustom}
                    onChange={selectSomethingDifferentHandler}
                    checkedIcon={
                      <CheckCircleIcon fontSize="medium" style={{ color: '#00C8BC' }} />
                    }
                    icon={<RadioButtonUncheckedIcon fontSize="medium" />}
                  />
                </div>

                {data.isCustom && (
                  <div style={{ border: '1px solid #c4c4c4' }}>
                    <div style={{
                      display: 'flex', alignItems: 'center', gap: '30px', padding: '12px', borderTop: '1px solid #c4c4c4', backgroundColor: '#2A95FF'
                    }}>
                      {['Collection Day', 'Holiday Impact'].map((item, index) => (
                        <Typography
                          key={item}
                          style={{
                            color: '#fff',
                            flex: index === 0 ? 0.3 : index === 1 ? 1.3 : 0.5,
                          }}
                          variant='h2Medium'
                        >
                          {item}
                        </Typography>
                      ))}
                    </div>

                    {[
                      'Monday',
                      'Tuesday',
                      'Wednesday',
                      'Thursday',
                      'Friday',
                      'Saturday',
                      'Sunday',
                    ].map((item) => (
                      <div
                        key={item}
                        style={{
                          display: 'flex', alignItems: 'center', gap: '30px', padding: '12px', borderTop: '1px solid #c4c4c4'
                        }}
                      >
                        <Typography style={{ flex: 0.3 }} variant="bodyTableSemiBold">
                          {item}
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1.3, gap: 30 }}>
                          <CustomSelect
                            textVariant="bodyTableSemiBold"
                            selectedValue={
                              MOVES_TO_OPTIONS.find(
                                (i) => i.action === data.customConfig[item]?.action
                              )?.name || ''
                            }
                            onSelect={(value) => updateDataHandler(
                              MOVES_TO_OPTIONS.find((i) => i.name === value)?.action,
                              item,
                              'action'
                            )}
                            options={MOVES_TO_OPTIONS.map((i) => i.name)}
                          />
                          {getNoDayRequired(
                            MOVES_TO_OPTIONS.find(
                              (i) => i.action === data.customConfig[item]?.action
                            )?.action
                          ) ||
                            !MOVES_TO_OPTIONS.find(
                              (i) => i.action === data.customConfig[item]?.action
                            )?.action ? (
                            ''
                          ) : (
                            <CustomSelect
                              style={{ marginLeft: 10 }}
                              placeholder='Select the alternative collection day'
                              textVariant='bodySemiBold'
                              selectedValue={data.customConfig[item]?.day}
                              onSelect={(value) =>
                                updateDataHandler(value, item, 'day')
                              }
                              options={ALL_WEEK}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div className={classes.buttonContainer}>
                  <CustomButton
                    variant="outlined"
                    size="large"
                    label={(
                      <Typography variant="h1" color={process.env.REACT_APP_COLOR_PRIMARY}>
                        Back
                      </Typography>
                    )}
                    onClick={() => goBackHandler()}
                    className={classes.button}
                  />
                  <CustomButton
                    variant="contained"
                    size="large"
                    label={(
                      <Typography variant="h1" color="white">
                        Next
                      </Typography>
                    )}
                    onClick={() => goNextHandler()}
                    className={classes.button}
                    disabled={!canGoNext}
                  />
                </div>
              </div>
            </div>

          </PortletBody>
        </Portlet>
      </div>
    </>
  );
};
const mapStateToProps = (store) => ({
  holidaysChanges: store.perpetualSchedules.holidaysChanges,
  perpetualSchedulesState: store.perpetualSchedules,
});

export default injectIntl(connect(mapStateToProps, null)(CalendarChange));
