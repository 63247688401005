import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Report from '@material-ui/icons/Report';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { Typography } from '../../components';

const CustomRadioButton = ({
  firstLabel,
  firstValue,
  secondLabel,
  secondValue,
  row,
  onChange,
  firstLabelComment,
  secondLabelComment,
  isDisable,
  value
}) => {
  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      width: '100%',
    }
  }));

  const classes = useStyles();

  function handleChange(event) {
    if (onChange) {
      onChange(event.target.value);
    }
  }

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" disabled={isDisable} className="w-100">
        <RadioGroup
          value={value}
          onChange={handleChange}
        >
          {row ? (
            <div className="row w-100">
              <div className="col-xl-4">
                <FormControlLabel
                  value={firstValue}
                  control={<Radio color="primary" />}
                  label={<Typography variant="bodySemiBold">{firstLabel}</Typography>}
                />
              </div>
              <div className="col-xl-4">
                <FormControlLabel
                  value={secondValue}
                  control={<Radio color="primary" />}
                  label={<Typography variant="bodySemiBold">{secondLabel}</Typography>}
                />
              </div>
            </div>
          )
            : (
              <div style={{ height: 50 }}>
                <FormControlLabel
                  value={firstValue}
                  control={<Radio color="primary" />}
                  label={
                    (
                      <div className="row p-0 mt-3 ml-1">
                        <Typography variant="bodySemiBold">
                          {firstLabel}
                        </Typography>
                        <Typography variant="bodySemiBold">
                          {firstLabelComment}
                        </Typography>
                      </div>
                    )
                  }
                  className="pt-0 pb-0 h-25"
                />
                <FormControlLabel
                  value={secondValue}
                  control={<Radio color="primary" />}
                  label={
                    (
                      <div className="row p-0 mt-3 ml-1">
                        <Report color="error" />
                        <div className="row ml-2 p-0">
                          <p className="text-dark mr-1">
                            {secondLabel}
                          </p>
                          <p className="text-black-50">
                            {secondLabelComment}
                          </p>
                        </div>
                      </div>
                    )
                  }
                  className="pb-0 pt-0 h-50"
                />
              </div>
            )}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default CustomRadioButton;
