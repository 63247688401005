const actionTypes = {
  // Auth actionTypes for setting loading status
  SET_LOADING: 'SET_LOADING',
  RESET_LOADING: 'RESET_LOADING',

  // MFA actionTypes
  MFAـREQUEST: 'MFAـREQUEST',
  MFAـRECEIVE: 'MFAـRECEIVE',
  MFAـFAIL: 'MFAـFAIL',
};

export default actionTypes;
