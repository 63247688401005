const actionTypes = {
  // Depot actionTypes for setting loading status
  SET_DEPOT_LOADING_ACTION: 'SET_DEPOT_LOADING_ACTION',
  SET_DEPOT_SNACKBAR_ACTION: 'SET_DEPOT_SNACKBAR_ACTION',

  // Depot actionTypes for setting a depot value
  SET_ADD_DEPOT_ACTION: 'SET_ADD_DEPOT_ACTION',

  // Depot actionTypes for setting a new depot
  SET_NEW_DEPOT_ACTION: 'SET_NEW_DEPOT_ACTION',
  // Depot actionTypes for reset new depot
  RESET_NEW_DEPOT_ACTION: 'RESET_NEW_DEPOT_ACTION',

  // Depot actionTypes for fetching all Depots
  GET_ALL_DEPOTS_REQUEST: 'GET_ALL_DEPOTS_REQUEST',
  GET_ALL_DEPOTS_RECEIVE: 'GET_ALL_DEPOTS_RECEIVE',
  GET_ALL_DEPOTS_FAIL: 'GET_ALL_DEPOTS_FAIL',

  // Depot actionTypes for fetching a depot detail
  GET_DEPOTS_DETAILS_REQUEST: 'GET_DEPOTS_DETAILS_REQUEST',
  GET_DEPOTS_DETAILS_RECEIVE: 'GET_DEPOTS_DETAILS_RECEIVE',
  GET_DEPOTS_DETAILS_FAIL: 'GET_DEPOTS_DETAILS_FAIL',

  // Depot actionTypes for removing a depot
  DELETE_DEPOT_REQUEST: 'DELETE_DEPOT_REQUEST',
  DELETE_DEPOT_RECEIVE: 'DELETE_DEPOT_RECEIVE',
  DELETE_DEPOT_FAIL: 'DELETE_DEPOT_FAIL',

  // Depot actionTypes for Add a depot
  ADD_DEPOT_REQUEST: 'ADD_DEPOT_REQUEST',
  ADD_DEPOT_RECEIVE: 'ADD_DEPOT_RECEIVE',
  ADD_DEPOT_FAIL: 'ADD_DEPOT_FAIL',

  // Depot actionTypes for edit a depot
  EDIT_DEPOT_REQUEST: 'EDIT_DEPOT_REQUEST',
  EDIT_DEPOT_RECEIVE: 'EDIT_DEPOT_RECEIVE',
  EDIT_DEPOT_FAIL: 'EDIT_DEPOT_FAIL',

  // google actionTypes for google location suggestion
  GET_GOOGLE_ADDRESS_REQUEST: 'GET_GOOGLE_ADDRESS_REQUEST',
  GET_GOOGLE_ADDRESS_RECEIVE: 'GET_GOOGLE_ADDRESS_RECEIVE',
  GET_GOOGLE_ADDRESS_FAIL: 'GET_GOOGLE_ADDRESS_FAIL',

  // google actionTypes for find location from latlng
  GET_GOOGLE_LATLANG_REQUEST: 'GET_GOOGLE_LATLANG_REQUEST',
  GET_GOOGLE_LATLANG_RECEIVE: 'GET_GOOGLE_LATLANG_RECEIVE',
  GET_GOOGLE_LATLANG_FAIL: 'GET_GOOGLE_LATLANG_FAIL',

  SET_INITIAL_SEASON: 'SET_INITIAL_SEASON',
};

export default actionTypes;
