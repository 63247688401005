import React, { useRef, useEffect } from 'react';
import { Chart } from 'chart.js';
import { ChartLoading } from '../../components/loading';

Chart.defaults.global.defaultFontStyle = 'bold';

export default function SpiderChart({
  labels,
  values,
  colors,
  isLoading,
  sixtyPercent = false
}) {
  const ref = useRef();

  useEffect(() => {
    if (!isLoading && values.length !== 0) {
      const chart = new Chart(ref.current, {
        type: 'radar',
        data: {
          labels,
          datasets: values.map((item, index) => ({
            pointBorderWidth: 0.2,
            backgroundColor: `${colors[index]}11`,
            borderColor: colors[index],
            borderWidth: 1,
            pointBackgroundColor: colors[index],
            pointRadius: 3,
            data: item
          }))
        },
        options: {
        // startAngle: 90,
          elements: {
            line: {
              tension: 0.0,
            }
          },
          tooltips: {
            callbacks: {
              title(tooltipItem, data) {
                return data.labels[tooltipItem[0].index];
              },
            },
            intersect: true,
            mode: 'nearest',
            xPadding: 10,
            yPadding: 10,
            caretPadding: 10
          },
          scale: {
            gridLines: {
              color: process.env.REACT_APP_COLOR_GRAY_LIGHT,
              lineWidth: 2
            },
            angleLines: {
              display: false
            },
            ticks: {
              display: true,
              callback(tick) { return `${tick}%`; },
              min: sixtyPercent ? -15 : -25,
              max: sixtyPercent ? 60 : 100,
              stepSize: sixtyPercent ? 15 : 25,
              fontSize: 12,
              fontColor: process.env.REACT_APP_COLOR_GRAY_DARK
            },
            pointLabels: {
              fontSize: 14,
              fontColor: process.env.REACT_APP_COLOR_TEXT,
            }
          },
          legend: {
            display: false,
            position: 'left'
          },
          animation: {
            duration: 3500
          }
        },
      });

      return () => {
        chart.destroy();
      };
    }
  }, [values, isLoading]);

  return (
    <>
      {isLoading
        ? (
          <div className="d-flex justify-content-center align-items-center">
            <ChartLoading />
          </div>
        )
        : (
          <>
            { values && values.length !== 0 ? <canvas ref={ref} /> : null}
          </>
        ) }
    </>
  );
}
