/* eslint-disable no-nested-ternary */
import React from 'react';
import { injectIntl } from 'react-intl';

import { Typography } from '../../components';
import { addCommasToNumber } from '../../../utils';
import { SummaryOtherLoading } from '../../components/loading';

const SummaryOtherTotals = ({
  intl,
  data,
  isLoading
}) => (
  <div className="h-100 d-flex flex-column justify-content-between mt-4 mt-lg-0">
    { isLoading
      ? <SummaryOtherLoading />
      : (
        <>
          {
            data && data.map((item, index) => (
              <div key={String(index)} className="row align-items-center">
                <Typography className="col-8 col-lg-6 " variant="h2">
                  {item.problem_type_label}
                </Typography>
                <Typography className="col-4 col-lg-6 row justify-content-end justify-content-lg-start" variant="numberSmall">
                  {addCommasToNumber(item.count)}
                </Typography>
              </div>
            ))
          }
        </>
      )}
  </div>
);

export default injectIntl(SummaryOtherTotals);
