const actionTypes = {
  GET_BLOG_DETAILS_REQUEST: 'GET_BLOG_DETAILS_REQUEST',
  GET_BLOG_DETAILS_RECEIVE: 'GET_BLOG_DETAILS_RECEIVE',
  GET_BLOG_DETAILS_FAIL: 'GET_BLOG_DETAILS_FAIL',
  BLOG_READ_REQUEST: 'BLOG_READ_REQUEST',
  BLOG_READ_RECEIVE: 'BLOG_READ_RECEIVE',
  BLOG_READ_FAIL: 'BLOG_READ_FAIL',
  BLOG_UNREAD_REQUEST: 'BLOG_UNREAD_REQUEST',
  BLOG_UNREAD_RECEIVE: 'BLOG_UNREAD_RECEIVE',
  BLOG_UNREAD_FAIL: 'BLOG_UNREAD_FAIL',
  BLOG_FEEDBACK_REQUEST: 'BLOG_FEEDBACK_REQUEST',
  BLOG_FEEDBACK_RECEIVE: 'BLOG_FEEDBACK_RECEIVE',
  BLOG_FEEDBACK_FAIL: 'BLOG_FEEDBACK_FAIL',
};

export default actionTypes;
