import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { addCommasToNumber } from '../../../../utils';
import { Typography, Pagination } from '../../../components';
import { CorrectAnswerIcon } from '../../../../assets/icons';

const useStyles = makeStyles((theme) => ({
  standaredView: {
    display: 'flex',
    // [theme.breakpoints.down(992)]: {
    //   display: 'none !important'
    // }
  },
  tabsTitle: {
    marginTop: '28px',
    marginBottom: '15px',
    [theme.breakpoints.down(992)]: {
      marginLeft: '15px',
      marginTop: '15px',
      marginBottom: '0px',
    }
  },
  tableRow: {
    minHeight: '45px',
    borderTop: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  tableHeader: {
    minHeight: '55px',
  },
  tableDatas: {
    minHeight: '45px',
  },
  tableBorder: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  tableDataOthers: {
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_BG
  },
  responsiveMode: {
    display: 'none !important',
    [theme.breakpoints.down(992)]: {
      display: 'flex !important'
    }
  },
  correctAnswerIcon: {
    minWidth: '25px',
    marginLeft: '10px'
  }
}));

const RecyclingFundamentalTable = ({
  intl,
  data,
}) => {
  const initialChoiceData = [
    {
      name: intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.RF_TABLE.SELECTED' }),
      value: 'selected'
    },
    {
      name: intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.RF_TABLE.SELECTED_PERSENTAGE' }),
      value: 'selected_percentage'
    },
    {
      name: intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.RF_TABLE.OTHER' }),
      value: 'other'
    }
  ];
  const classes = useStyles();
  const [choice, setChoice] = useState(initialChoiceData[0]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <div className={`row d-flex flex-column mr-3 ml-3 ${classes.standaredView}`}>
        <Typography className={classes.tabsTitle} variant="h1">
          {intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.RF_TITLE' })}
        </Typography>
        <div className={`row w-100 d-flex align-items-center ${classes.tableHeader}`}>
          <Typography
            className="d-flex col-3 justify-content-start"
            variant="bodySemiBold"
          >
            {intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.RF_TABLE.QUIZ.TITLE' })}
          </Typography>

          <div className="row col-9 d-flex">

            <Typography
              className="d-flex pl-0 col-3 justify-content-start"
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.RF_TABLE.ANSWER' })}
            </Typography>

            <Typography
              className="d-flex col-3 justify-content-center"
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.RF_TABLE.SELECTED' })}
            </Typography>

            <Typography
              className="d-flex col-3 justify-content-center"
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.RF_TABLE.SELECTED_PERSENTAGE' })}
            </Typography>

            <Typography
              className="d-flex col-3 justify-content-center"
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.RF_TABLE.OTHER' })}
            </Typography>
          </div>
        </div>

        { data && data.slice((page - 1) * rowsPerPage, (page * rowsPerPage)).map((item, id) => {
          let sum = 0;

          /* eslint-disable no-unused-expressions */
          Object.values(item)
          && Object.values(item)
            .filter((ans) => typeof (ans) === 'object' && ans.name)
            .map((ans) => {
              sum += Number(ans.comparison);
              return null;
            });

          return (
            <div
              key={`RF_REPORT_${item.title} - ${String(id)}`}
              className={`row w-100 d-flex h-100 ${classes.tableRow}`}
            >

              <Typography className="col-3 h-100 d-flex align-items-start pt-3 pr-3" variant="body">
                {`${String(id + 1)}.`}
                {' '}
                {
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.poll_quiz_question
                    }}
                  />
              }
              </Typography>

              <div className={`col-9 h-100 d-flex flex-column ${classes.tableDatas}`}>
                { Object.values(item)
                && Object.values(item)
                  .filter((ans) => typeof (ans) === 'object' && ans.name)
                  .map((ans, index) => (
                    <div
                      className={`row d-flex align-items-center ${classes.tableDatas}
                      ${Object.values(item).filter((ans) => typeof (ans) === 'object' && ans.name).length - 1 !== index
                        ? classes.tableBorder : null}`}
                      key={`RF_ANSWER_${ans.name} - ${String(index)}`}
                    >
                      <Typography
                        className={`col-3 d-flex align-items-center justify-content-start pl-0 ${classes.tableDatas}`}
                        variant="bodySemiBold"
                        color={ans.is_correct_answer === 't' ? process.env.REACT_APP_COLOR_POSITIVE : process.env.REACT_APP_COLOR_TEXT}
                      >
                        {ans.name || ''}
                        {ans.is_correct_answer === 't' && <CorrectAnswerIcon className={classes.correctAnswerIcon} />}
                      </Typography>
                      <Typography className={`col-3 d-flex justify-content-center align-items-center ${classes.tableDatas}`} variant="bodySemiBold">
                        {ans.value ? addCommasToNumber(ans.value) : 0}
                      </Typography>
                      <Typography className={`col-3 d-flex justify-content-center align-items-center ${classes.tableDatas}`} variant="bodySemiBold">
                        {ans.percent ? `${ans.percent}%` : '0%'}
                      </Typography>
                      <Typography className={`col-3 h-100 d-flex justify-content-center align-items-center ${classes.tableDatas} ${classes.tableDataOthers}`} variant="bodySemiBold">
                        {ans.comparison ? `${((Number(ans.comparison) * 100) / sum).toFixed(2)}%` : '0%'}
                      </Typography>
                    </div>
                  ))}
              </div>

            </div>
          );
        })}
        <div className={`mt-5 mb-4 d-flex justify-content-end align-items-center ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
          <Pagination
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={(value) => handleChangePage(value)}
            handleRowsPerPageChange={(value) => setRowsPerPage(value)}
            dataLength={data && data.length}
          />
        </div>
      </div>

      {/* <div className={`row d-flex flex-column m-3 mb-0 ${classes.responsiveMode}`}>
        <AutoComplete
          className="mt-2 mb-3"
          options={initialChoiceData}
          value={choice}
          onChange={setChoice}
          optionTitle="name"
          optionId="value"
        />
        { data && data.map((item, id) => (
          <div key={`RF_REPORT_${item.title} - ${String(id)}`}>
            <Typography
              className={`row w-100 d-flex align-items-center ${id === 0 ? 'my-3' : 'mt-5'}
             ${classes.tableDatas} ${classes.tableBorder}`}
              variant="body"
            >
              {item.title || ''}
            </Typography>

            { Object.values(item) && Object.values(item).map((ans, index) => {
              if (typeof (ans) === 'object' && ans.name) {
                return (
                  <div
                    key={`RF_Report_sub_${ans.name} - ${String(index)}`}
                    className={`row w-100 d-flex align-items-center pl-2 ${classes.tableDatas}`}
                  >
                    <Typography
                      className={`col-6 d-flex align-items-center ${classes.tableDatas}`}
                      variant="bodySemiBold"
                      color={ans.is_correct_answer === 't' ? process.env.REACT_APP_COLOR_POSITIVE : process.env.REACT_APP_COLOR_TEXT}
                    >
                      {ans.name || ''}
                      {ans.is_correct_answer === 't' && <CorrectAnswerIcon className="ml-2" />}
                    </Typography>

                    <div className="col-6 h-100 d-flex justify-content-center align-items-center">
                      <Typography
                        variant="bodySemiBold"
                      >
                        {(choice.value === 'selected_percentage' || choice.value === 'other')
                          ? `${ans[choice.value]} %`
                          : addCommasToNumber(ans[choice.value])}
                      </Typography>
                    </div>

                  </div>
                );
              }
            })}
          </div>
        ))}
      </div> */}
    </>
  );
};

export default injectIntl(RecyclingFundamentalTable);
