import cts from '../../../../crud/cts';

export const getAllLessonData = async ({ project_id, district_id }) => cts(
  'get',
  `/education/get_all_lesson?project_id=${project_id}&district_id=${district_id}`,
);


/**
 * API to leave feedback
 */
export const feedbackRequest = async ({ meta, feedback, type }) => cts(
  'post',
  'feedback',
  {
    type,
    meta,
    feedback
  }
);

