import React from 'react';
import { FormattedMessage } from 'react-intl';

const SubTitle = ({ text }) => (
  <div className="kt-login__subtitle">
    <h4>
      <FormattedMessage id={text} />
    </h4>
  </div>
);

export default SubTitle;
