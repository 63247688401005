import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, styled } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import {
  PollTable,
  MaterialsTable,
  PressRelease,
  TopMaterialsDetail,
  CorrectSummary
} from './components';
import * as actions from './redux/actions';
import * as sharedActions from '../../redux/actions';
import { MunicipalitySelector } from '../../components';

import {
  Portlet, PortletBody, PortletHeader, PortletHeaderToolbar,
} from '../../../../_metronic/partials/content/Portlet';

import { LoadingSpinner, ErrorContainer } from '../../components/loading';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(() => ({
  layout__topLeft: {
    width: '40%'
  },
  layout__topRight: {
    width: '60%',
    marginRight: 25
  },
  layout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  layout__top: {
    display: 'flex',
    width: '100%',
    marginBottom: 40
  },
  layout__bottom: {
    display: 'flex',
    width: '100%',
    marginBottom: 40
  },
  reportingDistrictContainer: {
    width: 500,
  },
  headerHeight: {
    height: 75,
  },
  buttonContainer: {
    borderRadius: 16,
    marginRight: 6,
    marginBottom: 10,
    maxWidth: 153
  },
  outerButtonContainer: {
    display: 'flex'
  },
  dateSelectorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
  },
  dateLabel: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 14,
  },

}));

const AnnualSurvey = ({
  data,
  intl,
  annualSurveyRequest,
  municipalities,
  district,
  setDistrictAction,
  loading,
  error
}) => {
  const classes = useStyles();
  const [displayPoll, setDispalyPoll] = useState(false);

  const thisYear = new Date().getMonth() < 9 ? new Date().getFullYear() - 1 : new Date().getFullYear();

  const [selectedYear, setSelectedYear] = useState(thisYear);
  const lastYear = selectedYear - 1;


  const dateOptions = [2022, 2023, 2024];

  useEffect(() => {
    if (municipalities.length !== 0) {
      annualSurveyRequest({
        municipality: district && district.district_id
          ? encodeURIComponent(district.district_id)
          : '',
        year: selectedYear
      });
    }
  }, [annualSurveyRequest, district, municipalities.length, selectedYear]);

  if (data && data.survey_year) {
    let totalResponsesPlastic = 0;
    let totalCorrecResponsesPlastic = 0;
    let totalCorrectLastYearPlastic = 0;
    let totalCorrectThisYearPlastic = 0;
    let totalCorrectNationalPlastic = 0;
    let totalResponsesPaper = 0;
    let totalCorrecResponsesPaper = 0;
    let totalCorrectLastYearPaper = 0;
    let totalCorrectThisYearPaper = 0;
    let totalCorrectNationalPaper = 0;
    let totalResponsesOther = 0;
    let totalCorrecResponsesOther = 0;
    let totalCorrectLastYearOther = 0;
    let totalCorrectThisYearOther = 0;
    let totalCorrectNationalOther = 0;

    data.material_questions.paper.forEach((mat) => {
      totalResponsesPaper += mat.num_responses;
      totalCorrecResponsesPaper += mat.num_correct_responses;
      totalCorrectLastYearPaper += mat.correct_last_yr;
      totalCorrectThisYearPaper += mat.correct_this_yr;
      totalCorrectNationalPaper += mat.correct_national_this_yr;
    });

    data.material_questions.plastic.forEach((mat) => {
      totalResponsesPlastic += mat.num_responses;
      totalCorrecResponsesPlastic += mat.num_correct_responses;
      totalCorrectLastYearPlastic += mat.correct_last_yr;
      totalCorrectThisYearPlastic += mat.correct_this_yr;
      totalCorrectNationalPlastic += mat.correct_national_this_yr;
    });

    data.material_questions.other.forEach((mat) => {
      totalResponsesOther += mat.num_responses;
      totalCorrecResponsesOther += mat.num_correct_responses;
      totalCorrectLastYearOther += mat.correct_last_yr;
      totalCorrectThisYearOther += mat.correct_this_yr;
      totalCorrectNationalOther += mat.correct_national_this_yr;
    });

    // // eslint-disable-next-line no-param-reassign
    // data = data.updateIn(['material_questions', 'plastic'], (arr) => arr.concat([{
    //   mat_nm: 'Totals',
    //   text: '',
    //   accepted: null,
    //   num_responses: totalResponsesPlastic,
    //   num_correct_responses: totalCorrecResponsesPlastic,
    //   correct_last_yr: totalCorrectLastYearPlastic,
    //   correct_this_yr: totalCorrectThisYearPlastic,
    //   correct_national_this_yr: totalCorrectNationalPlastic
    // }]));

    // // eslint-disable-next-line no-param-reassign
    // data = data.updateIn(['material_questions', 'paper'], (arr) => arr.concat([{
    //   mat_nm: 'Totals',
    //   text: '',
    //   accepted: null,
    //   num_responses: totalResponsesPaper,
    //   num_correct_responses: totalCorrecResponsesPaper,
    //   correct_last_yr: totalCorrectLastYearPaper,
    //   correct_this_yr: totalCorrectThisYearPaper,
    //   correct_national_this_yr: totalCorrectNationalPaper
    // }]));

    // // eslint-disable-next-line no-param-reassign
    // data = data.updateIn(['material_questions', 'other'], (arr) => arr.concat([{
    //   mat_nm: 'Totals',
    //   text: '',
    //   accepted: null,
    //   num_responses: totalResponsesOther,
    //   num_correct_responses: totalCorrecResponsesOther,
    //   correct_last_yr: totalCorrectLastYearOther,
    //   correct_this_yr: totalCorrectThisYearOther,
    //   correct_national_this_yr: totalCorrectNationalOther
    // }]));
  }


  const handleChange = (event, data) => {
    setSelectedYear(data.props.item);
  };


  return (
    <div id="annual-survey-report">
      {
        data && (
          <Portlet>
            <PortletHeader
              title={<div className='d-flex'><div className='kt-portlet__head-title'>{intl.formatMessage({ id: 'REPORT.NATIONAL_SURVEY.TITLE' })}</div> <div className={`${classes.dateSelectorContainer}`}>
              {
                selectedYear && (
                  <FormControl>
                    <Select
                      value={selectedYear}
                      onChange={handleChange}
                      disableUnderline
                      disabled={loading}
                      className={classes.selectEmpty}
                      options={dateOptions}
                      inputProps={{
                        classes: {
                          root: classes.dateLabel,
                        },
                      }}
                    >
                      {dateOptions.length > 0 && dateOptions.map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={item}
                            item={item}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )
              }
              </div></div>}
              toolbar={(<>
                <PortletHeaderToolbar className={`${classes.headerHeight}`}>
                  <div className={`${classes.reportingDistrictContainer}`}>
                    <MunicipalitySelector
                      title={intl.formatMessage({ id: 'REPORT.GENERAL.REPORTING_DISTRICT' })}
                      onChange={setDistrictAction}
                      value={district}
                      hasAllDistrictOption
                      isCountyEnable={false}
                      disbleSingleCountyDropdown
                    />
                  </div>

                </PortletHeaderToolbar>
                </>
              )}
            />
            <div>
              {loading ? <LoadingSpinner height={600} />
                : (
                  <PortletBody>
                    <div>
                      {
                        error ? (
                          <div>
                            <ErrorContainer
                              reportName="Annual Survey"
                              small
                              portlet
                              height={200}
                              error={error.message}
                            />
                          </div>
                        )
                          : (
                            <div>
                              {
                                data && data.survey_year && (
                                  <div>
                                    <div className={`${classes.layout}`}>
                                      <div className={`${classes.layout__top}`}>
                                        <div className={`${classes.layout__topRight}`}>
                                          <CorrectSummary data={data} thisYear={selectedYear} lastYear={lastYear} />
                                        </div>
                                        <div className={`${classes.layout__topLeft}`}>
                                          <PressRelease data={data} district={district} selectedYear={selectedYear}/>
                                        </div>
                                      </div>
                                      <div className={`${classes.layout__bottom}`}>
                                        <TopMaterialsDetail data={data.mats_know_best} title="REPORT.NATIONAL_SURVEY.MATERIALS.RIGHT.HEADER" thisYear={selectedYear} lastYear={lastYear}/>
                                        <TopMaterialsDetail data={data.mats_misunderstood} title="REPORT.NATIONAL_SURVEY.MATERIALS.WRONG.HEADER" thisYear={selectedYear} lastYear={lastYear}/>
                                        <TopMaterialsDetail data={data.mats_improved} title="REPORT.NATIONAL_SURVEY.MOST.IMPROVED.HEADER" thisYear={selectedYear} lastYear={lastYear}/>
                                      </div>
                                    </div>
                                    <div className={classes.outerButtonContainer}>
                                      <div className={classes.buttonContainer}>
                                        <StyledTabButton
                                          selected={!displayPoll}
                                          onClick={() => { setDispalyPoll(false); }}
                                        >
                                          Material Questions
                                        </StyledTabButton>
                                      </div>
                                      <div className={classes.buttonContainer}>
                                        <StyledTabButton
                                          selected={displayPoll}
                                          onClick={() => { setDispalyPoll(true); }}
                                        >
                                          Poll Questions
                                        </StyledTabButton>
                                      </div>
                                    </div>
                                    {
                                      !displayPoll && (
                                        <div>
                                          <MaterialsTable data={data} thisYear={selectedYear} lastYear={lastYear} />
                                        </div>
                                      )
                                    }
                                    {
                                      displayPoll && (
                                        <div>
                                          <PollTable data={data} thisYear={selectedYear} lastYear={lastYear} />
                                        </div>
                                      )
                                    }

                                  </div>
                                )
                              }
                            </div>
                          )
                      }
                    </div>

                  </PortletBody>
                )}
            </div>
          </Portlet>
        )
      }

    </div>
  );
};

const mapStateToProps = (store) => ({
  data: store.annualSurveyReport.data,
  loading: store.annualSurveyReport.loading,
  error: store.annualSurveyReport.error,
  municipalities: store.common.municipalities,
  district: store.common.district,
  fetchState: store.annualSurveyReport.fetchState,
});

export default injectIntl(connect(mapStateToProps, { ...actions, ...sharedActions })(AnnualSurvey));

const StyledTabButton = styled(({ color, ...props }) => <div {...props} />)({
  color: ({ selected }) => (selected ? '#fff' : '#2A95FF'),
  background: ({ selected }) => (selected ? '#2A95FF' : '#F1F9FF'),
  textShadow: ({ selected }) => (selected ? '-0.08ex 0 currentColor, 0.08ex 0 currentColor' : 'none'),
  borderRadius: 16,
  padding: '8px 18px',
  cursor: 'pointer',
  letterSpacing: '0.335px',
  transition: 'all .3s',
  fontSize: 12,

  '& svg': {
    fill: ({ selected }) => (selected ? '#fff' : '#2A95FF'),
    marginLeft: 4,
    marginBottom: 2,
    transition: 'all .3s',
  },

  '&:hover': {
    background: '#2A95FF',
    color: '#fff',
    textShadow: '-0.08ex 0 currentColor, 0.08ex 0 currentColor',

    '& svg': {
      fill: '#fff'
    }
  },
});
