import React from 'react';

import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

import Typography from './Typography';

const AddContent = ({
  onClick, label, style = {}, disabled = false
}) => (
  <Typography
    style={{
      display: 'flex',
      gap: 10,
      alignItems: 'center',
      color: process.env.REACT_APP_COLOR_PRIMARY,
      pointerEvents: disabled ? 'none' : 'auto',
      cursor: 'pointer',
      opacity: disabled ? 0.6 : 1,
      fontSize: 16,
      fontWeight: 600,
      ...style,
    }}
    variant="h3"
    onClick={onClick}
  >
    <AddCircleOutlineOutlinedIcon sx={{ fill: process.env.REACT_APP_COLOR_PRIMARY }} />
    {label}
  </Typography>
);

export default AddContent;
