import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Portlet,
  PortletHeader,
  PortletBody,
  PortletHeaderToolbar
} from '../../../../../_metronic/partials/content/Portlet';
import CampaignItem from '../../../Campaign/components/CampaignItem';
import { DoughnutChart } from '../../components';

import { ListLoading } from '../../../components/loading';
import { DropdownMenu } from '../../../Education/EducationalContent/components';
import { EducationModal } from '../components';
import ColorLegend from '../../AnnualSurvey/components/ColorLegend';
import { useHistory } from 'react-router-dom';


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';


import { Switch, Typography } from '../../../components';
import { SummaryOtherLoading } from '../../../components/loading';



const useStyles = makeStyles((theme) => ({
  container: {
    width: '1300px',
    margin: '0 auto'
  },
  //.group
  group: {
    display: "flex",
    gap: "10px"
  },
  //.groupItem
  groupItem: {
    display: "flex",
    flexDirection: "column",
    border: "2px solid #E7ECEF",
    borderRadius: 9,
    ChangeTheColorBorderRadius: "8px",
    padding: "12px",
    // width: "25%",
    width: 235,
    // maxWidth: "200px",
    MightNeedFullWidthSoTheItemsDontGetTooBigOnLargeScreenColor: "#63666A",
    ChangeTheColorFontFamily: "sans-serif"
  },
  //.group__title
  groupTitle: {
    fontWeight: "600"
  },
  //.group__number
  groupNumber: {
    fontSize: "20px",
    fontWeight: "800",
    marginTop: "6px",
    marginBottom: "6px"
  },
  //.group__change
  groupChange: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontWeight: "600"
  },
  //.group__arrow
  groupArrow: {
    marginRight: "6px"
  },
  ///* Item shows increase */
  groupChangePositive: {
    color: "green"
  },
  ///* Item shows decrease */
  groupChangeNegativet: {
    color: "red"
  },
  //.group__change--negative .group__arrow
  groupChangeNegativeGroupArrow: {
    transform: "rotate(180deg)"
  },
  ///* Blue item */
  groupItemHighlighted: {
    backgroundColor: "#2A95FF",
    color: "white"
  },
  lists: {
    display: 'flex',
    flexDirection: 'row',
    gap: '80px',
    fontSize: '14px',
  },
  list__heading: {
    color: '#63666A', /* Change the color */
    fontFamily: 'sans-serif',
    textTransform: 'uppercase',
    fontSize: '0.8rem', /* Change as needed */
    marginBottom: '16px',
    fontSize: '14px',
  },
  list__rows: {
    display: 'flex',
    flexDirection: 'column',
    color: '#63666A', /* Change the color */
    fontFamily: 'sans-serif'
  },
  list__row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    height: '2rem'
  },
  list__number: {
    marginRight: '6px',
    fontWeight: 600 /* Change as needed */
  },
  list__arrow: {
    marginRight: 6
  },
  list__change: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24
  },

  /* Item shows increase */
  list__changePositive: {
    color: '#409A5F'
  },
  /* Item shows decrease */
  list__changeNegative: {
    color: '#FF1E1E'
  },
  list__changeNegativeList__arrow: {
    transform: 'rotate(180deg)'
  },

  /* Legend for the circle graph */
  list__legend: {
    backgroundColor: 'grey',
    color: 'white',
    borderRadius: 4,
    padding: '4px 10px',
    marginRight: 20
  },
  list__legend1: {
    backgroundColor: '#2A95FF' /* Colors correspond to sections of the graph */
  },
  list__legend2: {
    backgroundColor: '#A663FF' /* Colors correspond to sections of the graph */
  },
  list__legend3: {
    backgroundColor: '#97CCFF' /* Colors correspond to sections of the graph */
  },
  list__legend4: {
    backgroundColor: '#00C192' /* Colors correspond to sections of the graph */
  },
  list__legend5: {
    backgroundColor: '#FFCD5B' /* Colors correspond to sections of the graph */
  },
  list__topic: {
    fontWeight: 600,
    fontSize: 14,
  },
  legendContainer: {
    position: 'relative',
    left: 0,
    right: 0,
    // marginLeft: 'auto',
    // marginRight: 'auto',
    width: 100,
    display: "flex",
    gap: "16px",
    marginTop: 50,
  },
  legend: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    textTransform: "uppercase",
    fontFamily: "sans-serif"
  },
  legendCircle: {
    content: "''",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    border: "2px solid rgba(118, 203, 196, 1)"
  },
  legendLine: {
    position: "relative",
    top: "0px",
    width: "20px",
    margin: "0 6px 0 0",
    height: "2px",
    left: "1px"
  },
  legendDottedLegendLine: {

    borderTop: "2px dashed rgba(118, 203, 196, 1)"
  },
  legendDottedLegendCircle: {
    content: "''",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    border: "2px solid rgba(118, 203, 196, 1)"
  },
  legendSolidLegendLine: {
    borderTop: "2px solid rgba(42, 149, 255, 1)"
  },
  legendSolidLegendCircle: {
    content: "''",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    border: "2px solid rgba(42, 149, 255, 1)"
  },
  chart: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  chartTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  chartTotal: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'center',
  },
  chartLegend: {
    display: "flex",
    flexDirection: "row"
  },
  chart__legendchartTop: {
    gap: "16px"
  },
  chartLegend: {
    gap: "16px"
  },
  chartMain: {
    position: "relative",
    height: "calc( 40000px / 200 )"
  },
  //.chart__grid
  chartGrid: {
    display: "flex",
    flexDirection: "column-reverse",
    height: "100%"
  },
  //.chart__increment
  chartIncrement: {
    display: "flex",
    flexDirection: "row",
    height: "25%",
    "&:first-of-type": {
      top: "0"
    },
  },
  //.chart__y
  chartY: {
    width: "50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    position: "relative"
  },
  //.chart__yNumber
  chartYNumber: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    height: "20px",
    top: "10px"
  },
  //.chart__increment:first-of-type .chart__yNumber
  chartIncrementFirstOfType: {
    top: "0"
  },
  ///* Removes top grey line without a Y axis number */
  // chart__increment:last-of-type .chart__line:after
  // removestopgreylinewithoutayaxisnumberChartIncrementLastOfTypeChartLineAfterElement: {
  //   display: "none"
  // },
  //.chart__line
  chartLine: {
    width: "100%",
    position: "relative",
    '&::after': {
      content: "''",
      position: "absolute",
      height: "1px",
      width: "100%",
      backgroundColor: "lightgrey"
    }
  },
  //.chart__line::after
  chartLineAfter: {
    content: "''",
    position: "absolute",
    height: "1px",
    width: "100%",
    backgroundColor: "lightgrey"
  },
  ///* Chart data layer */
  // .chart__data
  chartData: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0",
    display: "flex"
  },
  chartDataY: {
    width: "50px"
  },
  chartDataItems: {
    width: "100%",
    display: "flex",
    gap: "16px",
    justifyContent: "space-between",
  },
  chartDataItemGraphWrap: {
    height: "calc( 100% - 20px )"
  },
  chartDataItem: {
    maxWidth: "calc( 100% / 12 )",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
  },
  chartDataItemText: {
    height: "25px",
    Display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  graph: {
    display: 'inline-flex',
    flexDirection: 'column',
    height: '100%',
  },
  graph__2items: {
    marginRight: 20,
    marginLeft: 20,
  },
  graph__wrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  graph__item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
    margin: '0 2px',
    "&:first-of-type": {
      marginLeft: 0
    },
  },
  graph__number: {
    marginBottom: 6,
    fontSize: 12,
    fontSeight: 600,
    fontFamily: 'sans-serif'
  },
  graph__pillar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    maxWidth: 21,
    maxHeight: '100%',
    width: '100%',
    borderRadius: 13,
    minHeight: 20,
  },
  graph__ball: {
    display: 'block',
    position: 'relative',
    top: 1,
    height: 25,
    width: 25,
    // backgroundColor: 'white',
    borderRadius: '50%'
  },
  graph__pillar_lastYear: {
    backgroundColor: '#79cbc4'
  },
  graph__pillar_thisYear: {
    backgroundColor: '#3398fc'
  },
  chartTopNumber: {
    fontWeight: '600',
    fontSize: 16,
    color: '#404040',
    letterSpacing: '0.04em'
  },
  groupChange: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: '500',
    fontSize: 24,
  },
  groupArrow: {
    marginRight: '6px'
  },
  groupChangePositive: {
    color: '#409A5F'
  },
  groupChangeNegativet: {
    color: 'red'
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    width: '55%',
    justifyContent: 'space-between',
  }


}));

const MostSearchMaterials = ({
  intl,
  reportData,
  isLoading,
}) => {
  const classes = useStyles();

  const history = useHistory();



  let labels;
  let totalTop5;
  let top5Values;


  /**
* Menu and menuitem functions.
*/
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const descriptionHandler = () => {
    setOpen(true);

  };

  const viewDetailsHandler = (e) => {
    history.push('/report/material');
    // setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    handleClose();
  }

  /**
  * Data for dropdown menu items.
  */
  const menuItemData = [
    {
      id: 1,
      name: 'Description',
      method: descriptionHandler,
      disabled: false
    },
    {
      id: 1,
      name: 'View Detailed Report',
      method: viewDetailsHandler,
      disabled: false
    },

  ];

  let highestValue;
  if(!isLoading) {
    highestValue = Math.max(...reportData.material_most_searched.map(item => {
      if (item.this_year > item.last_year) {
        return item.this_year;
      } else {
        return item.last_year;
      }
    }));
  
    // calculate highest value for the chart based on the highest value in the data 
    highestValue = highestValue * 1.25;
  }




  return (
    <>

      <Portlet>
        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start" style={{ justifyContent: 'space-between' }}>
                <div className="row col-xs-12 col-lg-6 align-items-center">
                  <div className="align-items-center pl-3">
                    <Typography variant="h1">
                      Most Searched Materials
                    </Typography>
                  </div>
                </div>
                <DropdownMenu
                  handleClick={handleClick}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  menuItemData={menuItemData}
                />
              </div>
            )}
          />
          <EducationModal
            open={open}
            onClose={() => closeModal()}
            title={'Description'}
            content={(
              <Typography variant="body1" style={{ textAlign: 'left' }}>
                Material searches provide insights into resident knowledge gaps. These are the top searched materials for the latest month, which is shown in the upper right corner of this page. The percentage change is a comparison to the same month last year.
              </Typography>
            )}
          />
        </div>
        <PortletBody>
          {
            isLoading ?
              <SummaryOtherLoading /> :
              (
                <div className="row">
                  <div className={classes.chart}>

                    <div className={classes.chartMain}>
                      <div className={classes.chartGrid}>
                        <div className={classes.chartIncrement}>
                          <div className={classes.chartY}>
                            {/* <div className={classes.chartYNumber}>0</div> */}
                          </div>
                          {/* <div className={classes.chartLine}></div> */}
                        </div>
                        <div className={classes.chartIncrement}>
                          <div className={classes.chartY}>
                            {/* <div className={classes.chartYNumber}>10K</div> */}
                          </div>
                          {/* <div className={classes.chartLine}></div> */}
                        </div>
                        <div className={classes.chartIncrement}>
                          <div className={classes.chartY}>

                            {/* <div className={classes.chartYNumber}>20K</div> */}
                          </div>
                          {/* <div className={classes.chartLine}></div> */}
                        </div>
                        <div className={classes.chartIncrement}>
                          <div className={classes.chartY}>
                            {/* <div className={classes.chartYNumber} chartYNumber>30K</div> */}
                          </div>
                          {/* <div className={classes.chartLine}></div> */}
                        </div>
                      </div>
                      <div className={classes.chartData}>
                        <div className={classes.chartDataY}></div>
                        <div className={classes.chartDataItems}>
                          {
                            reportData.material_most_searched.map((item, index) => (
                              <div className={classes.chartDataItem}>
                                <div className={classes.chartDataItemGraphWrap}>
                                  <div className={classes.graph}>
                                    <div className={classes.graph__wrapper}>
                                      <div className={classes.graph__item}>
                                        <div className={`${classes.graph__number}`}>
                                          {item.last_year}
                                          {/* {Math.round((100 * item.last_year) / highestValue)}%` */}
                                        </div>
                                        <div className={`${classes.graph__pillar} ${classes.graph__pillar_lastYear}`} style={{ height: `${Math.round((100 * item.last_year / highestValue))}%` }}>
                                          <div className={classes.graph__ball}></div>
                                        </div>
                                      </div>
                                      <div className={classes.graph__item}>
                                        <div className={`${classes.graph__number}`}>
                                          {item.this_year}

                                          {/* {Math.round((100 * item.this_year) / highestValue)}%` */}
                                        </div>
                                        {/* <div className={`${classes.graph__pillar} ${classes.graph__pillar_thisYear}`} style={{ height: `${Math.abs(item.this - highestValue)}%` }}> */}
                                        <div className={`${classes.graph__pillar} ${classes.graph__pillar_thisYear}`} style={{ height: `${Math.round((100 * item.this_year / highestValue))}%` }}>

                                          <div className={classes.graph__ball}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={classes.chartDataItemText}>{item.title}</div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className={classes.legendContainer}>
                      <ColorLegend thisYear='This Year' lastYear='Last Year' textWidth={true} />

                    </div>

                  </div>

                </div>
              )
          }


        </PortletBody>
      </Portlet>

    </>
  );

};

export default injectIntl(MostSearchMaterials);
