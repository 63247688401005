import React from 'react';
import { connect } from 'react-redux';

import { Summary, Detail } from './components';

const ProblemReport = ({
  data,
  helpItems,
  summaryData,
  firstHelpItem,
  firstOtherProblemTypeItems
}) => (
  <div id="problem-report">
    <Summary
      data={summaryData}
    />
    <Detail
      data={data.problems}
      helpItems={helpItems}
      firstOtherProblemTypeItems={firstOtherProblemTypeItems}
    />
  </div>
);

const mapStateToProps = (store) => ({
  data: store.problemReport.data,
  helpItems: store.problemReport.helpItems,
  firstOtherProblemTypeItems: store.problemReport.firstOtherProblemTypeItems,
  summaryData: store.problemReport.summaryData,
});

export default connect(mapStateToProps, null)(ProblemReport);
