import React from 'react';

import IconButton from './IconButton';
import { InfoIcon } from '../../assets/icons';

const InfoTooltip = ({
  tooltip
}) => (
  <IconButton
    tooltip={tooltip}
  >
    <InfoIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
  </IconButton>
);

export default InfoTooltip;
