import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { CalendarDate } from '../../../assets/icons';

import { Typography } from '../../components';

const useStyles = makeStyles({
  container: {
    padding: '20px 20px 0',
    borderBottom: '2px solid #eee',
    marginLeft: -20,
    marginRight: -20,
  },
  dateTitle: {
    color: '#5E647E'
  }
});


const NextMonthItem = ({ intl, month }) => {
  const { container, dateTitle } = useStyles();

  return (
    <div className={container}>
      <div>
        <Typography variant="h2" className={dateTitle}>
          {month}
        </Typography>
      </div>
      <div>
        <Typography variant="h2" color="#868ba3">
          <CalendarDate style={{ fontSize: 30, margin: 20 }} />
          {intl.formatMessage({ id: 'CAMPAIGN.SCHEDULED_CAMPAIGN_COMING_SOON' })}
        </Typography>
      </div>
    </div>
  );
};

export default injectIntl(NextMonthItem);
