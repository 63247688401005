import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import {
  Dialog,
  DatePicker,
  Typography
} from '../../components';

const useStyles = makeStyles((theme) => ({
  dialog: {
    color: process.env.REACT_APP_COLOR_PRIMARY,
    marginLeft: theme.spacing(2)
  }
}));

const EditDate = ({
  intl,
  item,
  onClick,
  notificationList,
}) => {
  const classes = useStyles();
  const notif = notificationList.find((notif) => notif.id === item.id);
  const [selectedDate, setSelectedDate] = useState(moment(`${notif.expiry_date}T${notif.expiry_time}`));

  return (
    <Dialog
      button={(
        <Typography className={classes.dialog} variant="body">
          {intl.formatMessage({ id: 'NOTIFICATION.EDIT_DATE' })}
        </Typography>
      )}
      title={intl.formatMessage({ id: 'NOTIFICATION.EDIT_DATE.TITLE' })}
      content={(
        <>
          <Typography variant="bodySemiBold">
            {intl.formatMessage({ id: 'NOTIFICATION.EDIT_DATE.CONTENT' })}
          </Typography>
          <div className="row w-100 mx-auto d-flex justify-content-between mt-2">
            <DatePicker
              className="col-xl-12"
              label={intl.formatMessage({ id: 'GENERAL.DATE' })}
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
            />
          </div>
        </>
      )}
      submitText={intl.formatMessage({ id: 'GENERAL.SAVE' })}
      handleSubmit={() => onClick({
        id: item.id,
        time: moment(selectedDate).format('HH:mm:ss'),
        date: moment(selectedDate).format('YYYY-MM-DD'),
        type: item.type,
        page: item.page,
      })}
    />
  );
};

const mapStateToProps = (store) => ({
  notificationList: store.notification.list,
});

export default injectIntl(
  connect(
    mapStateToProps,
    null
  )(EditDate)
);
