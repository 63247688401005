import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Typography } from '../../../components';
import { addCommasToNumber } from '../../../../utils';

const useStyles = makeStyles((theme) => ({
  tabsTitle: {
    marginTop: '28px',
    marginBottom: '15px',
    [theme.breakpoints.down(992)]: {
      marginLeft: '15px',
      marginTop: '15px',
      marginBottom: '0px',
    }
  },
  tabs: {
    marginTop: '28px',
    marginBottom: '15px',
    marginLeft: '5px',
  },
  tableRow: {
    minHeight: '45px',
    borderTop: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  tableHeader: {
    minHeight: '55px',
  },
  tableCell: {
    minHeight: '45px',
  },
}));

const SpotlightReport = ({
  intl,
  data,
  municipalities
}) => {
  const classes = useStyles();

  if (!municipalities.some((item) => item.stage === '4')) {
    return (
      <div className="w-100 d-flex flex-column">
        <div className={`row w-100 d-flex align-items-center ${classes.tableHeader}`}>
          <Typography
            className="d-flex col-6 justify-content-start"
            variant="bodySemiBold"
          >
            {intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.TAB.MATERIAL_SPOTLIGHT.NOT_SUPPORTED' })}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    data
      ? (
        <div className="w-100 d-flex flex-column">
          <div className={`row w-100 d-flex align-items-center ${classes.tableHeader}`}>
            <Typography
              className="d-flex col-6 justify-content-start"
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.TAB.MATERIAL_SPOTLIGHT.TITLE' })}
            </Typography>

            <Typography
              className="d-flex col-6 justify-content-center"
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.MATERIAL_SPOTLIGHT.USERS_VIEWED' })}
            </Typography>
          </div>

          { data && [...data]
            .sort((a, b) => Number(b.max) - Number(a.max))
            .map((item, id) => (
              <div
                key={`SP_MAT_REPORT_${item.name} - ${String(id)}`}
                className={`row w-100 d-flex h-100 ${classes.tableRow}`}
              >

                <Typography className={`col-6 h-100 d-flex align-items-center ${classes.tableCell}`} variant="body">
                  {item.name ? item.name : ''}
                </Typography>

                <Typography className={`col-6 h-100 d-flex align-items-center justify-content-center ${classes.tableCell}`} variant="body">
                  {item.max ? addCommasToNumber(item.max) : ''}
                </Typography>

              </div>
            ))}

        </div>
      ) : null
  );
};

const mapStateToProps = (store) => ({
  municipalities: store.common.municipalities || []
});

export default injectIntl(connect(mapStateToProps, null)(SpotlightReport));
