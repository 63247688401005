import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { Typography, CustomButton, Pagination } from '../../../components';

import {
  Portlet, PortletBody, PortletHeader,
} from '../../../../../_metronic/partials/content/Portlet';

import { Modal } from '.';

import { utils, writeFile } from 'xlsx-js-style'


const useStyles = makeStyles(() => ({
  tableHeader: {
    backgroundColor: '#3398fc',
    marginRight: 0,
    marginLeft: 0,
    marginTop: '0 !important',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: '8px 0',
    minHeight: 72,
  },
  tableHeaderFont: {
    color: '#FFF'
  },
  tableMaterialsItem: {
    color: '#3398fc',
    textDecoration: 'underline'
  },
  tableRow: {
    borderBottom: '1px solid lightgrey',
    borderRight: '1px solid lightgrey',
    borderLeft: '1px solid lightgrey',
    marginLeft: 0,
    marginRight: 0,
  },
  materialsTable: {
    // margin: '0 16px',
    borderRadius: '0 !important',
    boxShadow: 'none !important',
  },
  tableLabel: {
    borderBottom: 'none !important',
    borderRadius: '0 !important',
    padding: '0 !important',
  },
  tableBody: {
    padding: '0 !important'
  },
  materialsLabel: {
    color: '#6C7293',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },
  displayAllLink: {
    color: '#0086c2',
    cursor: 'pointer'
  },
  detailReportButton: {
    width: '85%',
  },
  modalRow: {
    margin: '5px 10px',
  },
  downloadButton: {
    width: 150,
    justifyContent: 'flex-end',
  },
  cancelButton: {
    width: 180,
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: '#FF1E1E',
    margin: 10,
    '&:hover': {
      backgroundColor: '#E32945',
    },
  },

}));

const MaterialsTable = ({
  data,
  thisYear,
  lastYear,
  handleGetData,
  displayAll,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const [page, setPage] = useState(1);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };


  const hasPayment = data.find(item => {
    if (item.has_payment === 't' || item.payment_system.length > 0) {
      return true;
    }
  })

  const exportData = () => {
    // STEP 1: Create a new workbook
    const wb = utils.book_new();

    // STEP 2: Create data rows and styles

    const ws = utils.json_to_sheet([]);
    let row = [];
    let headers = [];
    let headersData = [[
      { v: `Booking Date`, t: "s", s: { alignment: { wrapText: true }, font: { bold: true, color: { rgb: "000000" }, name: "Calibri", sz: 15 }, fill: { fgColor: { rgb: "d8843b" } } } },
      { v: `Scheduled pick-up date`, t: "s", s: { alignment: { wrapText: true }, font: { bold: true, color: { rgb: "000000" }, name: "Calibri", sz: 15 }, fill: { fgColor: { rgb: "d8843b" } } } },
      { v: `Name`, t: "s", s: { alignment: { wrapText: true }, font: { bold: true, color: { rgb: "000000" }, name: "Calibri", sz: 15 }, fill: { fgColor: { rgb: "d8843b" } } } },
      { v: `Address`, t: "s", s: { alignment: { wrapText: true }, font: { bold: true, color: { rgb: "000000" }, name: "Calibri", sz: 15 }, fill: { fgColor: { rgb: "d8843b" } } } },
      { v: `Items`, t: "s", s: { alignment: { wrapText: true }, font: { bold: true, color: { rgb: "000000" }, name: "Calibri", sz: 15 }, fill: { fgColor: { rgb: "d8843b" } } } },
      // { v: `Total fee`, t: "s", s: { alignment: { wrapText: true }, font: { bold: true, color: { rgb: "000000" }, name: "Calibri", sz: 15 }, fill: { fgColor: { rgb: "d8843b" } } } },
    ]]

    if (hasPayment) {
      headersData[0].push({ v: `Total fee`, t: "s", s: { alignment: { wrapText: true }, font: { bold: true, color: { rgb: "000000" }, name: "Calibri", sz: 15 }, fill: { fgColor: { rgb: "d8843b" } } } })
    }
    utils.sheet_add_aoa(ws, headersData, { origin: -1 });

    data.forEach((item, index) => {
      let bpData = [[
        { v: `${moment(item.booking_date).format('DD MMM, YYYY')}`, t: "s", s: { font: { name: "Calibri", sz: 15 }, alignment: { wrapText: true } } },
        { v: `${moment(item.pickup_dt).format('DD MMM, YYYY')}`, t: "s", s: { font: { name: "Calibri", sz: 15 }, alignment: { wrapText: true } } },
        { v: `${item.contact_full_nm}`, t: "s", s: { font: { name: "Calibri", sz: 15 }, alignment: { wrapText: true } } },
        { v: `${item.location}`, t: "s", s: { font: { name: "Calibri", sz: 15 }, alignment: { wrapText: true } } },
        {
          v: `${item.items ? JSON.parse(item.items).map((mat) => (
            `${mat.quantity > 1 ? ` ${mat.quantity}x` : ' '} ${mat.mat_nm}`
          )).toString()
            : ''}`, t: "s", s: { font: { name: "Calibri", sz: 15 }, alignment: { wrapText: true } }
        },
        // { v: `${item.fees}`, t: "s", s: { font: { name: "Calibri", sz: 15 }, alignment: { wrapText: true } } },
      ]];
      if (hasPayment) {
        bpData[0].push({ v: `${item.fees}`, t: "s", s: { font: { name: "Calibri", sz: 15 }, alignment: { wrapText: true } } })
      }
      utils.sheet_add_aoa(ws, bpData, { origin: -1 });
    });

    const wscols = [
      { wch: 40 },
      { wch: 50 },
      { wch: 80 },
      { wch: 80 },
      { wch: 40 },
      { wch: 40 }
    ];

    ws['!cols'] = wscols;
    utils.book_append_sheet(wb, ws, 'Sheet1');

    writeFile(wb, 'BulkPickUp.xlsx');
  }

  const handleDisplayAll = () => {
    handleGetData();
  }

  const handleCancelPickup = (pickup_uuid) => {
    window.open(`https://bp.recyclecoach.com/bulk/cancel?id=${pickup_uuid}`, '_blank');
  }



  return (
    <div>
      {
        // Object.keys(data.material_questions).map((material_question) => (
        <Portlet className={`h-100 position-relative ${classes.materialsTable}`}>
          <PortletHeader
            className={`${classes.tableLabel} w-100`}
            title={(
              <div className="d-flex align-items-center w-100 justify-content-between">
                <div>

                  <Typography variant="h1" className={`${classes.materialsLabel}`}>
                    {
                      displayAll ? 'All Bookings' : 'Outstanding Bookings'
                    }
                  </Typography>
                  <div onClick={() => {
                    handleDisplayAll()
                  }}>

                    <Typography variant="body" className={`${classes.displayAllLink}`}>
                      {
                        displayAll ? 'Click here to view outstanding bookings' : 'Click here to view all bookings'
                      }
                    </Typography>
                  </div>
                </div>


                <CustomButton
                  paddingVertical={4}
                  className={classes.downloadButton}
                  variant={'contained'}
                  label={'Download'}
                  onClick={() => {
                    exportData()

                  }}
                />
              </div>
            )}
          />
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            title={'Pickup Request Details'}
            content={(
              <div>
                {
                  transaction && (
                    <>
                      <div style={{ borderBottom: '2px solid #E7ECEF', margin: '5px 0px' }}>
                        <Typography variant="body1" style={{ textAlign: 'left' }}>
                          <div className="row">
                            <div className={`${classes.modalRow} col-6`}>
                              <Typography variant="body1" style={{ textAlign: 'left', fontSize: 14, fontWeight: 400 }}>
                                Name:
                              </Typography>
                            </div>
                            <div className={`${classes.modalRow} col-5`}>
                              <Typography variant="body1" style={{ textAlign: 'right', fontSize: 14, fontWeight: 400 }}>
                                <strong>{transaction.contact_full_nm}</strong>
                              </Typography>
                            </div>
                          </div>
                          <div className="row">
                            <div className={`${classes.modalRow} col-6`}>
                              <Typography variant="body1" style={{ textAlign: 'left', fontSize: 14, fontWeight: 400 }}>
                                Address:
                              </Typography>
                            </div>
                            <div className={`${classes.modalRow} col-5`}>
                              <Typography variant="body1" style={{ textAlign: 'right', fontSize: 14, fontWeight: 400 }}>
                                <strong>{transaction.location}</strong>
                              </Typography>
                            </div>
                          </div>
                          <div className="row">
                            <div className={`${classes.modalRow} col-6`}>
                              <Typography variant="body1" style={{ textAlign: 'left', fontSize: 14, fontWeight: 400 }}>
                                Phone:
                              </Typography>
                            </div>
                            <div className={`${classes.modalRow} col-5`}>
                              <Typography variant="body1" style={{ textAlign: 'right', fontSize: 14, fontWeight: 400 }}>
                                <strong>{transaction.contact_phone_nbr}</strong>
                              </Typography>
                            </div>
                          </div>
                          <div className="row">
                            <div className={`${classes.modalRow} col-6`}>
                              <Typography variant="body1" style={{ textAlign: 'left', fontSize: 14, fontWeight: 400 }}>
                                Email:
                              </Typography>
                            </div>
                            <div className={`${classes.modalRow} col-5`}>
                              <Typography variant="body1" style={{ textAlign: 'right', fontSize: 14, fontWeight: 400 }}>
                                <strong>{transaction.contact_email_addr}</strong>
                              </Typography>
                            </div>
                          </div>
                        </Typography>
                      </div>
                      <div style={{ borderBottom: '2px solid #E7ECEF', margin: '5px 0px' }}>
                        <Typography variant="body1" style={{ textAlign: 'left' }}>
                          <div className="row">
                            <div className={`${classes.modalRow} col-6`}>
                              <Typography variant="body1" style={{ textAlign: 'left', fontSize: 14, fontWeight: 400 }}>
                                Booking day:
                              </Typography>
                            </div>
                            <div className={`${classes.modalRow} col-5`}>
                              <Typography variant="body1" style={{ textAlign: 'right', fontSize: 14 }}>
                                <strong>{moment(transaction.booking_date).format('DD MMM, YYYY')}</strong>
                              </Typography>
                            </div>
                          </div>
                          <div className="row">
                            <div className={`${classes.modalRow} col-6`}>
                              <Typography variant="body1" style={{ textAlign: 'left', fontSize: 14, fontWeight: 400 }}>
                                Scheduled pick-up day:
                              </Typography>
                            </div>
                            <div className={`${classes.modalRow} col-5`}>
                              <Typography variant="body1" style={{ textAlign: 'right', fontSize: 14 }}>
                                <strong>{moment(transaction.pickup_dt).format('DD MMM, YYYY')}</strong>
                              </Typography>
                            </div>
                          </div>
                          {
                            transaction.transaction_id && (
                              <div className="row">
                                <div className={`${classes.modalRow} col-6`}>
                                  <Typography variant="body1" style={{ textAlign: 'left', fontSize: 14, fontWeight: 400 }}>
                                    Transaction ID:
                                  </Typography>
                                </div>
                                <div className={`${classes.modalRow} col-5`}>
                                  <Typography variant="body1" style={{ textAlign: 'right', fontSize: 14 }}>
                                    <strong>{transaction.transaction_id}</strong>
                                  </Typography>
                                </div>
                              </div>
                            )
                          }
                          {/* <div className="row">
                            <div className={`${classes.modalRow} col-6`}>
                              <Typography variant="body1" style={{ textAlign: 'left', fontSize: 14, fontWeight: 400 }}>
                                Status:
                              </Typography>
                            </div>
                            <div className={`${classes.modalRow} col-5`}>
                              <Typography variant="body1" style={{ textAlign: 'right', fontSize: 14 }}>
                                <strong>{transaction.payment_status && transaction.payment_status === 'cancelled' ? 'Cancelled' : moment(transaction.pickup_dt, "YYYY/MM/DD").isBefore(moment()) ? 'Closed' : 'Open'}</strong>
                              </Typography>
                            </div>
                          </div> */}
                        </Typography>
                      </div>
                      <div style={{ margin: '5px 0px' }}>
                        <Typography variant="body1" style={{ textAlign: 'left' }}>
                          <div className="row">
                            <div className={`${classes.modalRow} col-6`}>
                              <Typography variant="body1" style={{ textAlign: 'left' }}>
                                Items
                              </Typography>
                            </div>
                            <div className={`${classes.modalRow} col-2`}>
                              <Typography variant="body1" style={{ textAlign: 'left' }}>
                                Qty
                              </Typography>
                            </div>
                            {
                              hasPayment && transaction && transaction.fees && (
                                <div className={`${classes.modalRow} col-2`}>
                                  <Typography variant="body1" style={{ textAlign: 'right' }}>
                                    Fee
                                  </Typography>
                                </div>
                              )
                            }

                          </div>
                          {
                            JSON.parse(transaction.items).map((item, index) => (
                              <div className="row" style={{ borderBottom: `${index <= transaction.items.length ? '2px solid #E7ECEF' : ''}` }}>
                                <div className={`${classes.modalRow} col-6`}>
                                  <Typography variant="body1" style={{ textAlign: 'left', fontSize: 14, fontWeight: 500 }}>
                                    {item.mat_nm}
                                  </Typography>
                                </div>
                                <div className={`${classes.modalRow} col-2`}>
                                  <Typography variant="body1" style={{ textAlign: 'left', fontSize: 14, fontWeight: 600 }}>
                                    {item.quantity}
                                  </Typography>
                                </div>
                                {
                                  hasPayment && transaction && transaction.fees && (
                                    <div className={`${classes.modalRow} col-2`}>
                                      <Typography variant="body1" style={{ textAlign: 'right', fontSize: 14, fontWeight: 600, color: '#2A95FF' }}>
                                        ${Number(item.fee.replace(/[^0-9.-]+/g, "")) * item.quantity}
                                      </Typography>
                                    </div>
                                  )
                                }

                              </div>
                            ))
                          }
                          <div className="row">
                            <>
                              {
                                hasPayment && transaction && transaction.fees && (
                                  <>
                                    <div className={`${classes.modalRow} col-6`}>
                                      <Typography variant="body1" style={{ textAlign: 'left', fontSize: 18, fontWeight: 600 }}>
                                        Total fee:
                                      </Typography>
                                    </div>
                                    <div className={`${classes.modalRow} col-2`}>
                                      <Typography variant="body1" style={{ textAlign: 'right' }}>
                                      </Typography>
                                    </div>
                                    <div className={`${classes.modalRow} col-2`}>
                                      <Typography variant="body1" style={{ textAlign: 'right', fontSize: 18, fontWeight: 600, color: '#2A95FF' }}>
                                        ${
                                          JSON.parse(transaction.items).map((item) => (
                                            Number(item.fee.replace(/[^0-9.-]+/g, "")) * item.quantity
                                          )).reduce((a, b) => a + b, 0)

                                        }
                                      </Typography>
                                    </div>
                                  </>
                                )
                              }
                            </>

                          </div>
                        </Typography>
                      </div>
                      {
                        new Date(transaction.pickup_dt) > new Date() && (
                          <CustomButton
                            paddingVertical={4}
                            className={classes.cancelButton}
                            variant={'contained'}
                            label={'Cancel Pickup'}
                            onClick={() => handleCancelPickup(transaction.pickup_uuid)}
                          />
                        )
                      }

                    </>
                  )
                }

              </div>

            )}
          />
          <PortletBody className={`${classes.tableBody}`}>
            <div className={`row d-flex align-items-center mt-4 ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableHeader}`}>
              {/* <div className="col-1">
                <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                  Transaction ID
                </Typography>
              </div> */}
              <div className="col ml-3">
                <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                  Booking date
                </Typography>
              </div>
              <div className="col ml-2">
                <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                  Scheduled pick-up date
                </Typography>
              </div>
              <div className="col ml-2">
                <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                  Name
                </Typography>
              </div>
              <div className="col-2 ml-2">
                <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                  Address
                </Typography>
              </div>
              <div className="col ml-3">
                <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                  Items
                </Typography>
              </div>
              {
                data && data.length && hasPayment && (
                  <div className="col ml-2">
                    <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                      Total fee
                    </Typography>
                  </div>
                )
              }
              {/* <div className="col ml-2">
                <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                  Status
                </Typography>
              </div> */}
              <div className="col ml-2">
                <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                </Typography>
              </div>
            </div>
            <div className={classes.dataTable}>
              {
                data && data.length > 0 && data.slice((page - 1) * 10, (page * 10)).map((item, index) => (
                  <div
                    key={item.problem_id}
                    className={`row d-flex align-items-center pt-4 pb-4 ${classes.border} ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableRow}`}
                  >
                    {/* <div className="col-1">
                      <Typography variant="bodySemiBold" className={`${classes.ranking}`}>
                        #{item.problem_id}
                      </Typography>
                    </div> */}
                    <div className="col row d-flex align-items-center ml-3">
                      <Typography variant="bodySemiBold">
                        {moment(item.booking_date).format('DD MMM, YYYY')}
                      </Typography>
                    </div>
                    <div className="col row d-flex align-items-center ml-2">
                      <Typography variant="bodySemiBold" className={`col ${classes.ranking}`}>
                        {moment(item.pickup_dt).format('DD MMM, YYYY')}
                      </Typography>
                    </div>
                    <div className="col row d-flex align-items-center ml-2">
                      <Typography variant="bodySemiBold">
                        {item.contact_full_nm}

                      </Typography>
                    </div>
                    <div className="col-2 row d-flex align-items-center ml-2">
                      <Typography variant="bodySemiBold" className={`col ${classes.ranking}`}>
                        {item.location}
                      </Typography>
                    </div>
                    <div className="col row d-flex align-items-center ml-2">
                      <Typography variant="bodySemiBold">
                        {JSON.parse(item.items).map((mat) => (
                          <div>
                            {
                              `${mat.quantity > 1 ? `${mat.quantity}x` : ''} ${mat.mat_nm}`
                            }

                          </div>
                        ))}
                      </Typography>
                    </div>
                    {
                      hasPayment && (
                        <div className="col row d-flex align-items-center ml-2">
                          <Typography variant="bodySemiBold" className={`col ${classes.ranking}`}>
                            {item.fees}
                          </Typography>
                        </div>
                      )
                    }
                    {/* <div className="col row d-flex align-items-center ml-2">
                      <Typography variant="bodySemiBold">
                        {
                          item.payment_status && item.payment_status === 'cancelled' ? 'Cancelled' : moment(item.pickup_dt, "YYYY/MM/DD").isBefore(moment()) ? 'Closed' : 'Open'
                        }
                      </Typography>
                    </div> */}
                    <div className="col row d-flex align-items-center ml-2">
                      <CustomButton
                        paddingVertical={4}
                        className={classes.detailReportButton}
                        variant={'contained'}
                        label={'Detail'}
                        onClick={() => {
                          setTransaction(item);
                          setOpen(true);

                        }}
                      />
                    </div>
                  </div>
                ))
              }
            </div>
            <div className={`mt-5 mb-4 d-flex justify-content-end align-items-center w-100 ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
              <Pagination
                page={page}
                rowsPerPage={10}
                handlePageChange={(value) => handleChangePage(value)}
                dataLength={data && data.length}
                hideRowsPerPage
              />
            </div>
          </PortletBody>
        </Portlet>
        // ))
      }
    </div>
  );
};

export default injectIntl(MaterialsTable);
