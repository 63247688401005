import cts from '../../../../crud/cts';

const getEducationBlogsData = ({
  project_id,
  district_id
}) => cts(
  'get',
  `blogs?project_id=${project_id}&district_id=${district_id}`
);

export { getEducationBlogsData, getEducationBlogsData as default };
