/* eslint-disable import/prefer-default-export */
import cts from '../../../../crud/cts';

export const getRecyclingForKidsGoData = async ({
  project_id,
  district_id
}) => cts(
  'get',
  'https://cdn.recyclecoach.com/resources/kids-recycle-kit/education-recycling-for-kids.json',
  {},
  {},
  false,
  true
);

/**
 *  API to leave feedback
 */
 export const feedbackAPI = async ({ meta, feedback, type }) => cts(
  'post',
  'feedback',
  {
    type,
    meta,
    feedback
  }
);