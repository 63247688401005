import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import { DropdownMenu } from '../../../Education/EducationalContent/components';
import { EducationModal } from '../components';

import moment from 'moment';
import { useHistory } from 'react-router-dom';


import { Typography } from '../../../components';

import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';


const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 500,
    '@media (max-width: 1390px)': {
      height: '100%',
      maxHeight: 'unset !important',
    },
    '@media (max-width: 1200px)': {
      height: 'unset !important',
      maxHeight: 'unset !important',
    }
  },
  group: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
  },
  groupItem: {
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid #E7ECEF',
    borderRadius: 9,
    ChangeTheColorBorderRadius: '8px',
    padding: '12px',
    // width: "25%",
    width: 235,
    // maxWidth: "200px",
    MightNeedFullWidthSoTheItemsDontGetTooBigOnLargeScreenColor: '#63666A',
    ChangeTheColorFontFamily: 'sans-serif',
    cursor: 'pointer',
    color: '#404040',
  },
  groupTitle: {
    fontWeight: '600',
    fontSize: 14,
  },
  groupNumber: {
    fontSize: '24px',
    fontWeight: '700',
    marginTop: '6px',
    marginBottom: '6px'
  },
  groupChange: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: '500',
    fontSize: 16,
  },
  groupArrow: {
    marginRight: '6px'
  },
  groupChangePositive: {
    // color: '#27ED6A'
    color: '#409A5F'
  },
  groupChangePositiveActive: {
    color: '#27ED6A'
  },
  groupChangeNegativet: {
    color: '#FF1E1E'
  },
  groupChangeNegativeGroupArrowClass: {
    transform: 'rotate(180deg)'
  },
  groupItemHighlighted: {
    backgroundColor: '#2A95FF',
    color: 'white'
  },
  lists: {
    display: 'flex',
    flexDirection: 'row',
    gap: '80px'
  },
  list__heading: {
    color: '#63666A', /* Change the color */
    fontFamily: 'sans-serif',
    textTransform: 'uppercase',
    fontSize: '0.8rem', /* Change as needed */
    marginBottom: '16px'
  },
  list__rows: {
    display: 'flex',
    flexDirection: 'column',
    color: '#63666A', /* Change the color */
    fontFamily: 'sans-serif'
  },
  list__row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    height: '2rem'
  },
  list__number: {
    marginRight: '6px',
    fontWeight: 600 /* Change as needed */
  },
  list__arrow: {
    marginRight: 6
  },
  list__change: {
    display: 'flex',
    flexDirection: 'row'
  },

  /* Item shows increase */
  list__changePositive: {
    color: '#27ED6A'
  },
  /* Item shows decrease */
  list__changeNegative: {
    color: '#FF1E1E'
  },
  list__changeNegativeList__arrow: {
    transform: 'rotate(180deg)'
  },

  /* Legend for the circle graph */
  list__legend: {
    backgroundColor: 'grey',
    color: 'white',
    borderRadius: 4,
    padding: '4px 10px',
    marginRight: 20
  },
  list__legend1: {
    backgroundColor: '#2A95FF' /* Colors correspond to sections of the graph */
  },
  list__legend2: {
    backgroundColor: '#A663FF' /* Colors correspond to sections of the graph */
  },
  list__legend3: {
    backgroundColor: '#97CCFF' /* Colors correspond to sections of the graph */
  },
  list__legend4: {
    backgroundColor: '#00C192' /* Colors correspond to sections of the graph */
  },
  list__legend5: {
    backgroundColor: '#FFCD5B' /* Colors correspond to sections of the graph */
  },
  list__topic: {
    fontWeight: 600
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  legendContainer: {
    position: 'relative',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 100,
    display: "flex",
    gap: "16px"
  },
  legend: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    textTransform: "uppercase",
    fontFamily: "sans-serif"
  },
  legendCircle: {
    content: "''",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    border: "2px solid rgba(118, 203, 196, 1)"
  },
  legendLine: {
    position: "relative",
    top: "0px",
    width: "20px",
    margin: "0 6px 0 0",
    height: "2px",
    left: "1px"
  },
  legendDottedLegendLine: {

    borderTop: "2px dashed rgba(118, 203, 196, 1)"
  },
  legendDottedLegendCircle: {
    content: "''",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    border: "2px solid rgba(118, 203, 196, 1)"
  },
  legendSolidLegendLine: {
    borderTop: "2px solid rgba(42, 149, 255, 1)"
  },
  legendSolidLegendCircle: {
    content: "''",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    border: "2px solid rgba(42, 149, 255, 1)"
  },
  chartTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // marginBottom: 25,
  },
  chartTopNumber: {
    fontWeight: '600',
    fontSize: 16,
    color: '#404040',
    letterSpacing: '0.04em',
  },
  groupChange: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: '500',
    fontSize: 24,
  },
  groupArrow: {
    marginRight: '6px'
  },
  groupChangePositive: {
    color: '#409A5F'
  },
  groupChangeNegativet: {
    color: 'red'
  },
  top: {
    display: 'flex',
    // alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 15,
    marginTop: '-10px',
  },
}));

const WhatGoesWhereSearches = ({
  intl,
  reportData,
  isLoading,
  reportMonth,
  reportYear,
}) => {
  const classes = useStyles();
  const history = useHistory();


  /**
* Menu and menuitem functions.
*/
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const descriptionHandler = () => {
    setOpen(true);

  };

  const viewDetailsHandler = (e) => {
    history.push('/report/material');
  };

  const closeModal = () => {
    setOpen(false);
    handleClose();
  }

  /**
  * Data for dropdown menu items.
  */
  const menuItemData = [
    {
      id: 1,
      name: 'Description',
      method: descriptionHandler,
      disabled: false
    },
    {
      id: 2,
      name: 'View Detailed Report',
      method: viewDetailsHandler,
      disabled: false
    },

  ];



  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const educationLabels = [];
  let educationDatapointsCurrentYear = [];
  let educationWhereDoesItGoTotalLastYear = [];


  let educationData;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          return `${moment(tooltipItem[0].xLabel, 'MMM').format('MMMM')} ${tooltipItem[0].datasetIndex > 0 ? Number(tooltipItem[0].xLabel.substr(4, 7)) : Number(tooltipItem[0].xLabel.substr(4, 7)) - 1} `;
          // return `${moment(tooltipItem[0].xLabel, 'MMM').format('MMMM')} ${tooltipItem[0].datasetIndex > 0 ? tooltipItem[0].xLabel.substr(4, 7) : reportYear - 1} `;

        },
        label: function (tooltipItem, data) {
          return tooltipItem.yLabel.toLocaleString();
        }
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          // display: false
        },
        ticks: {
          callback: function (value, index, ticks) {
            return value.substr(0, 3);
          }
        }
      }],
      yAxes: [{
        gridLines: {
          // display: false
        },
        ticks: {
          callback: function (value, index, ticks) {
            return value.toLocaleString();
          }
        }
      }]
    }
  };

  if (reportData && reportData.material_wgw_search && !isLoading) {
    // eslint-disable-next-line no-plusplus

    // If there is no data for the current year, create the labels needed to render the chart
    if (!reportData.material_wgw_search.detail.length) {
      let currentYear = reportYear;
      let currentMonth = reportMonth;
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      for (let i = 0; i < 12; i++) {
        let year = currentYear;
        let month = currentMonth - i;
        while (month < 1) {
          year--;
          month += 12;
        }
        let monthName = monthNames[month - 1];
        educationLabels.push(`${monthName} ${year}`);
      }
    } else {
      for (let i = 0; i < reportData.material_wgw_search.detail.length; i++) {
        if (i <= 11) {
          educationLabels.push(`${month[reportData.material_wgw_search.detail[i].month - 1]} ${reportData.material_wgw_search.detail[i].year}`);
          educationDatapointsCurrentYear.push(reportData.material_wgw_search.detail[i].value);
        } else {
          educationWhereDoesItGoTotalLastYear.push(reportData.material_wgw_search.detail[i].value);
        }
      }
    }
  }


  if (educationLabels.length && !isLoading) {
    educationData = {
      labels: educationLabels.reverse(),
      datasets: [
        {
          label: 'Last Year',
          data: educationWhereDoesItGoTotalLastYear ? educationWhereDoesItGoTotalLastYear : [],
          borderColor: 'rgba(118, 203, 196, 1)',
          borderDash: [5, 3],
          dashedBorderWidth: 15,
          fill: false,
        },
        {
          label: 'This Year',
          data: educationDatapointsCurrentYear ? educationDatapointsCurrentYear.reverse() : [],
          borderColor: 'rgba(42, 149, 255, 1)',
          backgroundColor: (context) => {
            const { ctx } = context.chart;
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, "rgba(42, 149, 255, 0.2)");
            gradient.addColorStop(1, "rgba(42, 149, 255, 0)");
            return gradient;
          },

        },

      ],
    };
  }

  return (
    <>
      <Portlet>
        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-between">
                <div className="row col-xs-12 col-lg-6 align-items-center">
                  <div className="align-items-center pl-3">
                    <Typography variant="h1">
                      "What Goes Where" Searches
                    </Typography>
                  </div>
                </div>
                <DropdownMenu
                  handleClick={handleClick}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  menuItemData={menuItemData}
                />
              </div>
            )}
          />
          <EducationModal
            open={open}
            onClose={() => closeModal()}
            title={'Description'}
            content={(
              <Typography variant="body1" style={{ textAlign: 'left' }}>
                Material searches are an indicator of the kind of resident engagement that leads to behavior change. The headline number is for the latest month, which is shown in the upper right corner of this page. The percentage change is a comparison to the same month last year.
              </Typography>
            )}
          />
        </div>
        <PortletBody>
          <div className="row">
            {
              isLoading ? null : (
                <div className={classes.top}>
                  <div className={classes.chartTop}>
                    <div className={classes.chartTopNumber}>{Number(reportData.material_wgw_search.total.value).toLocaleString()}</div>
                    <div className={`${classes.groupChange} ${reportData.material_wgw_search.total.diff > 0 ? classes.groupChangePositive : classes.groupChangeNegativet}`}>
                      <div className={classes.groupArrow}>{reportData.material_wgw_search.total.diff > 0 ? '↑' : '↓'}</div>
                      <div className={classes.group__percentage}>{reportData.material_wgw_search.total.diff}%</div>
                    </div>
                  </div>
                  <div className={classes.legendContainer}>
                    <div class={`${classes.legend}`}>
                      <div class={classes.legendDottedLegendCircle}></div>
                      <div class={`${classes.legendLine} ${classes.legendDottedLegendLine}`}></div>
                      <div style={{ width: 70 }}>Last Year</div>
                    </div>

                    <div class={`${classes.legend}`}>
                      <div class={classes.legendSolidLegendCircle}></div>
                      <div class={`${classes.legendLine} ${classes.legendSolidLegendLine}`}></div>
                      <div style={{ width: 70 }}>This Year</div>
                    </div>
                  </div>
                </div>

              )
            }

            <div className="col-12" style={{ height: 225 }}>
              {
                !isLoading && educationData && (
                  <Line options={options} data={educationData} />

                )
              }


            </div>

          </div>
        </PortletBody>
      </Portlet>
    </>
  );

};

export default injectIntl(WhatGoesWhereSearches);
