import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Typography } from '../../../components';
import {
  Portlet,
  PortletBody
} from '../../../../../_metronic/partials/content/Portlet';

import {
  PositiveIcon,
  NegativeIcon,
  NeutralIcon
} from '../../../../assets/icons';

import { addCommasToNumber } from '../../../../utils';
import { StatCardLoading } from '../../../components/loading';

const useStyles = makeStyles(() => ({
  containerStyle: {
    margin: -10,
    position: 'relative'
  },
  periodIconDataStyle: {
    display: 'flex'
  },
  periodDataStyle: {
    alignSelf: 'center',
    flex: 1,
    textAlign: 'center',
    letterSpacing: 1
  },
  positiveColorStyle: {
    color: process.env.REACT_APP_COLOR_POSITIVE
  },
  negativeColorStyle: {
    color: process.env.REACT_APP_COLOR_NEGATIVE
  },
  neutralColorStyle: {
    color: process.env.REACT_APP_COLOR_NEUTRAL
  },
  iconStyle: {
    display: 'inline-block !important',
    marginLeft: '8px !important',
    fontSize: 50
  },
  typeTitleStyle: {
    paddingBottom: 8,
    marginBottom: 8,
    borderBottom: '2px solid #eee',
    textAlign: 'center',
  },
  titleStyle: {
    textAlign: 'center'
  },
  nonArrowStyle: {
    color: process.env.REACT_APP_COLOR_REPORT_1
  }
}));

const StatCard = ({
  data,
  title,
  intl,
  stat,
  typeTitle,
  link,
  type = undefined,
  noneArrow = false,
  isLoading
}) => {
  const {
    current_period,
    last_period
  } = data;
  const {
    periodIconDataStyle,
    periodDataStyle,
    positiveColorStyle,
    negativeColorStyle,
    neutralColorStyle,
    iconStyle,
    containerStyle,
    typeTitleStyle,
    titleStyle,
    nonArrowStyle
  } = useStyles();

  /**
   * Decide the color of the number and the arrow point
   */
  const getTypeByNumbers = () => {
    if (type) return '';
    if (current_period > last_period * 1.001) {
      return 'positive';
    } if (current_period < last_period / 1.001) {
      return 'negative';
    }
    return 'neutral';
  };

  const isPositive = (type !== undefined && type === 'positive') || getTypeByNumbers() === 'positive';
  const isNegative = (type !== undefined && type === 'negative') || getTypeByNumbers() === 'negative';
  const isNeutral = (type !== undefined && type === 'neutral') || getTypeByNumbers() === 'neutral';

  // Defaults to neutral
  let icon = <NeutralIcon className={iconStyle} />;

  if (isPositive) {
    icon = <PositiveIcon className={iconStyle} />;
  }

  if (isNegative) {
    icon = <NegativeIcon className={iconStyle} />;
  }

  return (
    <Portlet>
      <PortletBody>
        <div className={containerStyle}>
          {isLoading
            ? (
              <div className="d-flex justify-content-center align-items-center">
                <StatCardLoading />
              </div>
            )
            : (
              <>
                <div className={periodIconDataStyle}>
                  <span className={periodDataStyle}>
                    <Typography
                      component="span"
                      variant="numberLarge"
                      className={clsx({
                        [neutralColorStyle]: isNeutral,
                        [positiveColorStyle]: isPositive,
                        [negativeColorStyle]: isNegative,
                        [nonArrowStyle]: noneArrow,
                      })}
                    >
                      {addCommasToNumber(stat)}
                    </Typography>
                    {
                      !noneArrow
                        ? (
                          <span className={`ml-5 d-flex align-items-center ${iconStyle}`}>
                            {icon}
                          </span>
                        ) : null
                    }
                  </span>
                </div>
                <div className={typeTitleStyle}>
                  <Typography variant="h2">
                    {typeTitle}
                  </Typography>
                </div>
                <div className={titleStyle}>
                  <Typography className="pb-2" variant="h1">
                    {title}
                  </Typography>
                </div>
                <div className={`pb-3 ${titleStyle}`}>
                  <Link to={link}>
                    {intl.formatMessage({ id: 'GENERAL.VIEW_FULL_REPORT' })}
                  </Link>
                </div>
              </>
            )}
        </div>
      </PortletBody>
    </Portlet>
  );
};

export default injectIntl(StatCard);
