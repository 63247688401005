import React from 'react';

const ThreeDotMenuIcon = ({
  color,
  className,
}) => (
  <svg className={`prefix__bi prefix__bi-three-dots-vertical ${className}`} width="1em" height="1em" viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      d="M9.5 13a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0-5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0-5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
    />
  </svg>
);

export default ThreeDotMenuIcon;
