import cts from '../../../../crud/cts';

export const getWWtKData = async ({
  project_id,
  district_id
}) => cts(
  'get',
  `/education-wwtk?project_id=${project_id}&district_id=${district_id}`
);


/**
 *  API to leave feedback
 */
export const feedbackAPI = async ({ meta, feedback, type }) => cts(
  'post',
  'feedback',
  {
    type,
    meta,
    feedback
  }
);
