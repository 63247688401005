import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';
import createReducer from '../../../../../redux/createReducer';
import initialStore from './initialStore';

const FeaturedLessonReducer = persistReducer({
  storage,
  key: 'featuredLesson',
  blackList: [],
  whitelist: []
}, createReducer(initialStore, {
  GET_LESSONS_LIBRARY_DATA_REQUEST: (state) => Immutable({
    ...state,
    loading: true,
    error: false,
  }),
  GET_LESSONS_LIBRARY_DATA_RECEIVE: (state, { payload }) => Immutable({
    ...state,
    loading: false,
    ...payload.data,
    error: false

  }),
  GET_LESSONS_LIBRARY_DATA_FAIL: (state, { payload }) => Immutable({
    ...state,
    loading: false,
    error: payload.data,
  }),
  FEATURED_LESSON_FEEDBACK_REQUEST: (state) => Immutable({
    ...state,
    error: null,
  }),
  FEATURED_LESSON_FEEDBACK_RECEIVE: (state, { payload }) => Immutable({
    ...state,
    responseStatus: payload.status,
  }),
  FEATURED_LESSON_FEEDBACK_FAIL: (state, { payload }) => Immutable({
    ...state,
    responseStatus: payload.status,
    error: payload,
  }),

}));

export default FeaturedLessonReducer;
