import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';


import { Typography } from '../../../components';

import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { DropdownMenu } from '../../../Education/EducationalContent/components';

import { ListLoading } from '../../../components/loading';
import { EducationModal } from '../components';


const useStyles = makeStyles((theme) => ({
  container: {
    width: '1300px',
    margin: '0 auto'
  },


  graph: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    fontFamily: "sans-serif"
  },
  graphRowClass: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
    marginBottom: "16px",
    '&:last-of-type': {
      marginBottom: "0"
    }
  },
  graphRowLastOfTypeClass: {
    marginBottom: "0"
  },
  graphTitle: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#404040",
    lineHeight: "24px",
    width: "400px",
    letterSpacing: '0.04em',
  },
  graphBar: {
    width: "100%",
    height: "40px",
    backgroundColor: "rgba(108, 114, 147, 0.06)"
  },
  graphPercentage: {
    backgroundColor: "#2A95FF",
    height: "100%"
  },
  graphNumber: {
    color: "#6C7293",
    width: "120px",
    textAlign: "center",
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
  },
  sectionImage: {
    display: "flex",
    margin: "0 auto",
    marginBottom: "16px",
    maxWidth: "100%",
  }

}));

const MaterialExposure = ({
  intl,
  reportData,
  isLoading
}) => {
  const classes = useStyles();
  const history = useHistory();


  /**
* Menu and menuitem functions.
*/

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const descriptionHandler = () => {
    setOpen(true);
  };

  const viewDetailsHandler = (e) => {
    history.push('/report/materials/detail');
    // setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    handleClose();
  }

  /**
* Data for dropdown menu items.
*/
  const menuItemData = [
    {
      id: 1,
      name: 'Report Description',
      method: descriptionHandler,
      disabled: false
    },
    {
      id: 2,
      name: 'View Details',
      method: viewDetailsHandler,
      disabled: false
    },

  ];

  const maxValue = reportData.material_top_10 && reportData.material_top_10.length ? Math.round(reportData.material_top_10[0].value * 1.05) : 0;


  return (
    <>
      <Portlet>
        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start" style={{ justifyContent: 'space-between' }}>
                <div className="row col-xs-12 col-lg-6 align-items-center">
                  <div className="align-items-center pl-3">
                    <Typography variant="h1">
                      Top 10 Materials (# of Engagements)
                    </Typography>
                  </div>
                </div>
                <DropdownMenu
                  handleClick={handleClick}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  menuItemData={menuItemData}
                />
              </div>
            )}
          />
          <EducationModal
            open={open}
            onClose={() => closeModal()}
            title={'Description'}
            content={(
              <Typography variant="body1" style={{ textAlign: 'left' }}>
                This report lists the materials that received the most engagement from your residents. An engagement occurs when a user interacts with a piece of content - for example, if they click on a link, read a blog or answer a quiz question. The number shown for each material is the number of engagements during the latest month, which is identified in the upper right corner of this page.
              </Typography>
            )}
          />
        </div>
        {
          isLoading ?
            <ListLoading /> : (
              <PortletBody>
                <div className="row">
                  <div style={{ width: '100%' }} className="row">

                    <div className="col-xl-9 col-centered">
                      {
                        reportData && reportData.material_top_10 && (
                          <div className={classes.graph}>
                            {
                              reportData.material_top_10.map((item, index) => (
                                <div className={classes.graphRowClass}>
                                  <div className={classes.graphTitle}>{item.title}</div>
                                  <div className={classes.graphBar}>
                                    <div className={classes.graphPercentage} style={{ width: `${100 - (((maxValue - item.value) / maxValue) * 100)}%` }} />
                                  </div>
                                  <div className={classes.graphNumber}>
                                    {Number(item.value).toLocaleString()}
                                  </div>
                                </div>
                              ))

                            }
                          </div>
                        )
                      }
                    </div>
                    <div className="col-xl-3 col-centered d-flex align-items-center">
                      <img
                        alt="Download"
                        src={toAbsoluteUrl('/media/icons/waste-management.svg')}
                        className={`${classes.sectionImage}`}
                      />
                    </div>
                  </div>
                </div>
              </PortletBody>
            )
        }

      </Portlet>
    </>
  );
};

export default injectIntl(MaterialExposure);
