import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Dropdown } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { ThreeDotMenuIcon } from '../../../assets/icons';
import * as actions from '../redux/actions';
import Preview from './Preview';

const useStyles = makeStyles(() => ({
  dropdown: {
    '& button': {
      padding: 0
    }
  },
  dropdownIcon: {
    '&::after': {
      display: 'none !important'
    },
    '& svg': {
      width: '1.3em',
      height: '1.3em',
    }
  },
  dropdownMenu: {
    maxHeight: 300,
    overflow: 'auto'
  },
}));

const CollectionMenu = ({
  collection,
}) => {
  const classes = useStyles();
  const [dropdownMode, setDropdownMode] = useState('default');

  return (
    <Dropdown
      alignRight
      className={classes.dropdown}
      drop="down"
      onToggle={() => setDropdownMode('default')}
    >
      <Dropdown.Toggle
        variant="transparent"
        className={classes.dropdownIcon}
      >
        <ThreeDotMenuIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu className={`dropdown-menu-fit dropdown-menu-right ${classes.dropdownMenu}`}>
        <Preview collection={collection} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = (store) => ({
  suggestAddress: store.depot.suggestAddress,
});

export default injectIntl(connect(mapStateToProps, actions)(CollectionMenu));
