const actionTypes = {
  // Set district
  SET_DASHBOARD_DISTRICT_ACTION: 'SET_DASHBOARD_DISTRICT_ACTION',

  // Dashboard actionTypes for setting from date
  SET_DASHBOARD_FROM_DATE_ACTION: 'SET_DASHBOARD_FROM_DATE_ACTION',

  // Dashboard actionTypes for setting to date
  SET_DASHBOARD_TO_DATE_ACTION: 'SET_DASHBOARD_TO_DATE_ACTION',

  GET_REPORT_DASHBOARD_REQUEST: 'GET_REPORT_DASHBOARD_REQUEST',
  GET_REPORT_DASHBOARD_RECEIVE: 'GET_REPORT_DASHBOARD_RECEIVE',
  GET_REPORT_DASHBOARD_FAIL: 'GET_REPORT_DASHBOARD_FAIL',

  GET_REPORT_DASHBOARD_DETAILS_REQUEST: 'GET_REPORT_DASHBOARD_DETAILS_REQUEST',
  GET_REPORT_DASHBOARD_DETAILS_RECEIVE: 'GET_REPORT_DASHBOARD_DETAILS_RECEIVE',
  GET_REPORT_DASHBOARD_DETAILS_FAIL: 'GET_REPORT_DASHBOARD_DETAILS_FAIL',


  GET_MATERIAL_DETAILS_REQUEST: 'GET_MATERIAL_DETAILS_REQUEST',
  GET_MATERIAL_DETAILS_RECEIVE: 'GET_MATERIAL_DETAILS_RECEIVE',
  GET_MATERIAL_DETAILS_FAIL: 'GET_MATERIAL_DETAILS_FAIL',

  GET_MAJOR_CAMPAIGN_DETAILS_REQUEST: 'GET_MAJOR_CAMPAIGN_DETAILS_REQUEST',
  GET_MAJOR_CAMPAIGN_DETAILS_RECEIVE: 'GET_MAJOR_CAMPAIGN_DETAILS_RECEIVE',
  GET_MAJOR_CAMPAIGN_DETAILS_FAIL: 'GET_MAJOR_CAMPAIGN_DETAILS_FAIL',

  GET_BULK_PICKUP_DETAILS_REQUEST: 'GET_BULK_PICKUP_DETAILS_REQUEST',
  GET_BULK_PICKUP_DETAILS_RECEIVE: 'GET_BULK_PICKUP_DETAILS_RECEIVE',
  GET_BULK_PICKUP_DETAILS_FAIL: 'GET_BULK_PICKUP_DETAILS_FAIL',

  GET_BULK_PICKUP_DASHBOARD_REQUEST: 'GET_BULK_PICKUP_DASHBOARD_REQUEST',
  GET_BULK_PICKUP_DASHBOARD_RECEIVE: 'GET_BULK_PICKUP_DASHBOARD_RECEIVE',
  GET_BULK_PICKUP_DASHBOARD_FAIL: 'GET_BULK_PICKUP_DASHBOARD_FAIL',

  GET_WGW_MATERIAL_DETAILS_REQUEST: 'GET_WGW_MATERIAL_DETAILS_REQUEST',
  GET_WGW_MATERIAL_DETAILS_RECEIVE: 'GET_WGW_MATERIAL_DETAILS_RECEIVE',
  GET_WGW_MATERIAL_DETAILS_FAIL: 'GET_WGW_MATERIAL_DETAILS_FAIL',

};

export default actionTypes;
