import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { Portlet } from '../../../../_metronic/partials/content/Portlet';
import {
  Typography, Opacity, CustomButton
} from '../../components';
import Step from './Step';
import Tabs from './Tabs';
import StepModal from './StepModal';

const useStyles = makeStyles({
  header: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    padding: '15px 0'
  },
});

const Main = ({
  intl,
  mainContentData,
  selectedMainTabIndex,
  setSelectedMainTabIndex,
  selectedTabData
}) => {
  const classes = useStyles();

  const [showStepModal, setShowStepModal] = useState();
  const [currentStep, setCurrentStep] = useState();

  const openStepModalHandler = (index) => {
    setShowStepModal(true);
    setCurrentStep(index + 1);
  };

  const closeStepModalHandler = () => {
    setShowStepModal(false);
  };

  const nextStepHandler = () => {
    if (currentStep < selectedTabData.stepCount) setCurrentStep((prevState) => prevState + 1);
  };

  const prevStepHandler = () => {
    if (currentStep > 1) setCurrentStep((prevState) => prevState - 1);
  };

  return (
    <div className="col-8 mr-1">
      <Portlet>
        <Typography variant="h1" className={`px-4 ${classes.header}`}>
          {intl.formatMessage({ id: 'MENU.WHAT.GOES.WHERE.TITLE' })}
        </Typography>

        {/* navigation buttons */}
        <Tabs
          data={mainContentData}
          setSelectedIndex={setSelectedMainTabIndex}
          selectedIndex={selectedMainTabIndex}
        />

        <Opacity slide className="px-4" key={selectedMainTabIndex}>
          <Typography variant="h1" color="#0789C4">
            {intl.formatMessage({ id: selectedTabData.title })}
          </Typography>

          {/* video */}
          <div className="d-flex justify-content-center">
            <iframe
              title="vimeo-player"
              src={selectedTabData.video}
              width="512"
              height="360"
              frameBorder="0"
              allowFullScreen
            />
          </div>

          <Typography variant="h1" color="#0789C4">
            {intl.formatMessage({ id: 'GENERAL.TUTORIAL.TITLE' })}
          </Typography>

          {/* description */}
          {selectedTabData.description && (
            <Typography className="mt-3 mb-2" variant="body" color={process.env.REACT_APP_COLOR_TEXT}>
              {intl.formatMessage({ id: selectedTabData.description })}
            </Typography>
          )}

          {/* steps */}
          {[...Array(selectedTabData.stepCount)].map((_, index) => (
            <Step
              selectedTabData={selectedTabData}
              openStepModalHandler={openStepModalHandler}
              index={index}
            />
          ))}

          {/* steps modal (when a individual step is clicked) */}
          <StepModal
            showStepModal={showStepModal}
            closeStepModalHandler={closeStepModalHandler}
            currentStep={currentStep}
            selectedTabData={selectedTabData}
            nextStepHandler={nextStepHandler}
            prevStepHandler={prevStepHandler}
          />

          <div className="d-flex justify-content-end my-5">
            <CustomButton
              onClick={selectedTabData.onButtonClick}
              variant="contained"
              paddingVertical={3}
              style={{ width: 200 }}
              label={<FormattedMessage id="GENERAL.START" />}
            />
          </div>

        </Opacity>
      </Portlet>
    </div>
  );
};

export default injectIntl(Main);
