import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { Dialog } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Opacity, Typography } from '../../components';
import { CloseIcon } from '../../../assets/icons';

const useStyles = makeStyles({
  dialogPaper: {
    minWidth: '1011px',
    height: '613px'
  },
  dialogImage: {
    width: '1011px',
    height: '533px'
  },
  closeIcon: {
    height: 25,
    width: 25,
    color: '#000',
    position: 'absolute',
    top: 10,
    right: 5,
    borderRadius: 100,
    background: '#fff',
    cursor: 'pointer',
    marginRight: 5,
    border: '1px solid rgba(99, 99, 99, 0.2)',
    outline: 'none'
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)'
  },
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    width: 30,
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      boxShadow: 'rgba(99, 99, 99, 0.3) 0px 4px 8px 0px',
    }
  },
  arrow: {
    fontSize: 25,
    color: '#fff'
  },
  dialogTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    '& p': {
      margin: 0,
    },
  }
});
function StepModal({
  intl,
  showStepModal,
  closeStepModalHandler,
  currentStep,
  selectedTabData,
  nextStepHandler,
  prevStepHandler,
}) {
  const classes = useStyles();

  const styleArrowIconHandler = (isDisabled) => ({
    background: isDisabled ? 'gray' : '#1FA0EA',
    opacity: isDisabled ? 0.4 : 1,
    cursor: isDisabled && 'not-allowed',
    boxShadow: isDisabled && 'none'
  });

  return (
    <>
      {showStepModal && (
        <Dialog
          onClose={closeStepModalHandler}
          open={showStepModal}
          classes={{ paper: classes.dialogPaper }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
        >
          <Opacity key={currentStep}>
            <CloseIcon onClick={closeStepModalHandler} className={classes.closeIcon} />

            <img
              src={require(`../../../assets/images/${selectedTabData.stepImageBaseName}${currentStep}.png`)}
              alt={selectedTabData.stepImageBaseName}
              className={classes.dialogImage}
            />

            <div style={{ height: 80 }} className="d-flex align-items-center justify-content-between mx-3">
              <div
                role="button"
                style={styleArrowIconHandler(currentStep === 1)}
                className={classes.arrowContainer}
                onClick={prevStepHandler}
              >
                <ArrowBackIcon className={classes.arrow} />
              </div>
              <div style={{ width: 900 }} className={classes.dialogTextContainer}>
                <Typography className="mr-2" variant="bodySemiBold">
                  <i>
                    {intl.formatMessage({ id: 'GENERAL.STEP' })}
                    {' '}
                    {currentStep}
                  </i>
                </Typography>

                <Typography
                  variant="body"
                  color={process.env.REACT_APP_COLOR_TEXT}
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: `${selectedTabData.stepContentBaseName}${currentStep}` })
                  }}
                />
              </div>

              <div
                role="button"
                style={styleArrowIconHandler(currentStep === selectedTabData.stepCount)}
                className={classes.arrowContainer}
                onClick={nextStepHandler}
              >
                <ArrowForwardIcon className={classes.arrow} />
              </div>
            </div>
          </Opacity>
        </Dialog>
      )}
    </>
  );
}

export default injectIntl(StepModal);
