import React from 'react';
import { connect } from 'react-redux';

import Main from './components/Main';

const BadgeReport = () => (
  <div>
    <Main />
  </div>
);

export default connect(null, null)(BadgeReport);
