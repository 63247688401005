import React from 'react';

const DisplayColorIcon = ({
  color,
  className,
}) => (
  <svg className={className} width="2em" height="2em" viewBox="0 0 409.6 409.6" fill={color}>
    <circle cx={204.8} cy={204.8} r={70} />
  </svg>
);

export default DisplayColorIcon;
