const actionTypes = {
  SEND_SUBMITTED_PHOTO_REQUEST: 'SEND_SUBMITTED_PHOTO_REQUEST',
  SEND_SUBMITTED_PHOTO_RECEIVE: 'SEND_SUBMITTED_PHOTO_RECEIVE',
  SEND_SUBMITTED_PHOTO_FAIL: 'SEND_SUBMITTED_PHOTO_FAIL',

  SEND_SUGGESTED_NEW_TERM_REQUEST: 'SEND_SUGGESTED_NEW_TERM_REQUEST',
  SEND_SUGGESTED_NEW_TERM_RECEIVE: 'SEND_SUGGESTED_NEW_TERM_RECEIVE',
  SEND_SUGGESTED_NEW_TERM_FAIL: 'SEND_SUGGESTED_NEW_TERM_FAIL',
};

export default actionTypes;
