import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';


import { Typography, Pagination } from '../../../components';

import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { DropdownMenu } from '../../../Education/EducationalContent/components';
import { DoughnutChart } from '../../components';
import { UsersByZonesModal } from '../components/index';

import { TableLoading } from '../../../components/loading';


const useStyles = makeStyles((theme) => ({
  container: {
    width: '1300px',
    margin: '0 auto'
  },


  graph: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    fontFamily: "sans-serif"
  },
  graphRowClass: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
    marginBottom: "16px",
    '&:last-of-type': {
      marginBottom: "0"
    }
  },
  graphRowLastOfTypeClass: {
    marginBottom: "0"
  },
  graphTitle: {
    fontWeight: "600",
    fontSize: "16px",
    color: "#404040",
    lineHeight: "24px",
    width: "400px",
    letterSpacing: '0.04em',
  },
  graphBar: {
    width: "100%",
    height: "40px",
    backgroundColor: "rgba(108, 114, 147, 0.06)"
  },
  graphPercentage: {
    backgroundColor: "#2A95FF",
    height: "100%"
  },
  graphNumber: {
    color: "#6C7293",
    width: "120px",
    textAlign: "center",
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 18,
  },
  sectionImage: {
    display: "flex",
    margin: "0 auto",
    marginBottom: "16px",
  },
  lists: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxHeight: 267,
  },
  list__heading: {
    color: '#404040', /* Change the color */
    fontFamily: 'sans-serif',
    textTransform: 'uppercase',
    fontSize: 13,
    marginBottom: '16px'
  },
  list__rows: {
    display: 'flex',
    flexDirection: 'column',
    color: '#63666A', /* Change the color */
    fontFamily: 'sans-serif'
  },
  list__row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    height: '2rem',
    fontSize: 18,
  },
  list__number: {
    marginRight: '6px',
    fontWeight: 600 /* Change as needed */
  },
  list__arrow: {
    marginRight: 6
  },
  list__change: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24
  },

  /* Item shows increase */
  list__changePositive: {
    color: '#409A5F'
  },
  /* Item shows decrease */
  list__changeNegative: {
    color: '#FF1E1E'
  },
  list__changeNegativeList__arrow: {
    transform: 'rotate(180deg)'
  },

  /* Legend for the circle graph */
  list__legend: {
    backgroundColor: 'grey',
    color: 'white',
    borderRadius: 4,
    padding: '4px 10px',
    marginRight: 20,
    // minWidth: 27,
    // minHeight: 27,
  },
  list__legend1: {
    backgroundColor: '#2A95FF' /* Colors correspond to sections of the graph */
  },
  list__legend2: {
    backgroundColor: '#A663FF' /* Colors correspond to sections of the graph */
  },
  list__legend3: {
    backgroundColor: '#97CCFF' /* Colors correspond to sections of the graph */
  },
  list__legend4: {
    backgroundColor: '#00C192' /* Colors correspond to sections of the graph */
  },
  list__legend5: {
    backgroundColor: '#FFCD5B' /* Colors correspond to sections of the graph */
  },
  list__legend6: {
    backgroundColor: '#F070FA' /* Colors correspond to sections of the graph */
  },
  list__topic: {
    fontWeight: 600,
    fontSize: 14,
  },
  tableHeader: {
    backgroundColor: 'white',
    marginRight: 0,
    marginLeft: 0,
    marginTop: '0 !important',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: '8px 0',
    minHeight: 72,
    textTransform: 'uppercase',
    borderBottom: '1px solid lightgrey',


  },
  tableHeaderFont: {
    color: '##404040',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.04em'
  },
  tableRow: {
    borderBottom: '1px solid lightgrey',
    marginLeft: 0,
    marginRight: 0,
    '&::after': {
      borderBottom: '1px solid lightgrey',
    }
  },
  tableLabel: {
    borderBottom: 'none !important',
    borderRadius: '0 !important',
    padding: '0 !important',
  },
  tableBody: {
    padding: '0 !important'
  },
  tableListChange: {
    lineHeight: 'unset',
    marginLeft: 15
  },
  chartContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dataTable: {
    maxHeight: 450,
    overflow: 'auto',
    overflowX: 'hidden',
  },

}));

const UsersByZones = ({
  intl,
  reportData,
  isLoading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);


  /**
* Menu and menuitem functions.
*/

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setModalContent(null);
  };

  const descriptionHandler = () => {
    setOpen(true);
    setModalContent('description');
  };
  const detailHandler = () => {
    setOpen(true);
    setModalContent('detail');
  };

  const viewUserReportsHandler = (e) => {
    history.push('/report/user');
  };

  const viewNewSubscribersHandler = (e) => {
    history.push('/report/new-subscribers');
  };

  const closeModal = () => {
    setOpen(false);
    handleClose();
  }

  /**
* Data for dropdown menu items.
*/
  const menuItemData = [
    {
      id: 1,
      name: 'Description',
      method: descriptionHandler,
      disabled: false
    },
    {
      id: 2,
      name: 'Details',
      method: detailHandler,
      disabled: false
    },
    // {
    //   id: 3,
    //   name: 'View User Report',
    //   method: viewUserReportsHandler,
    //   disabled: false
    // },
    // {
    //   id: 4,
    //   name: 'View New Subscribers Report',
    //   method: viewNewSubscribersHandler,
    //   disabled: false
    // },
  ];
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  let usersByZonesTitle;
  let usersByZonesValues;
  let userData;
  if (!isLoading && reportData.user_by_zone) {
    usersByZonesValues = reportData.user_by_zone.map((item) => item.value);
    userData = reportData.user_by_zone.map((item) => {
      return {
        id: Number(item.id),
        diff: item.diff,
        title: item.title,
        value: item.value
      }
    })

    const usersByZonesTotal = reportData.user_by_zone.map((item) => item.value).reduce((a, b) => a + b, 0);
    usersByZonesTitle = reportData.user_by_zone.map((item) => item.title);
  }



  return (
    <div style={{ height: '100%' }}>
      <Portlet style={{ marginBottom: 0 }}>
        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start" style={{ justifyContent: 'space-between' }}>
                <div className="row col-xs-12 col-lg-6 align-items-center">
                  <div className="align-items-center pl-3">
                    <Typography variant="h1">
                      Users by Zones
                    </Typography>
                  </div>
                </div>
                <DropdownMenu
                  handleClick={handleClick}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  menuItemData={menuItemData}
                />
              </div>
            )}
          />
        </div>
        <PortletBody>
          <div className="row">
            <div style={{ width: '100%', padding: 'unset' }}>
              <div className={`col-12 ${classes.chartContainer}`}>
                <div style={{ width: 500 }}>
                  <DoughnutChart
                    chartID="UserByZones"
                    isLoading={isLoading}
                    total={reportData.participation_method && !isLoading ? reportData.participation_method?.total?.mobile_app[0]?.value.toLocaleString() : 0}
                    insideText="Mobile App Users"
                    labels={usersByZonesTitle}
                    values={usersByZonesValues}
                    colors={['#2A95FF', '#A663FF', '#97CCFF', '#00C192', '#FFCD5B', '#F070FA']}
                  />
                </div>
              </div>

              <UsersByZonesModal
                open={open}
                onClose={() => closeModal()}
                title={'Users by Zone'}
                content={(
                  <>
                    {
                      modalContent === 'detail' && (
                        <div style={{ width: '100%' }}>
                          <PortletBody className={`${classes.tableBody}`}>
                            <div className={`row d-flex align-items-center mt-4 ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableHeader}`}>
                              <div className="col-4">
                                <Typography className={` d-flex align-items-center${classes.tableHeaderFont}`}>
                                  Zone
                                </Typography>
                              </div>
                              <div className="col ml-3">
                                <Typography className={`d-flex align-items-center ${classes.tableHeaderFont}`}>
                                  # Of Users (Last Year)
                                </Typography>
                              </div>
                              <div className="col ml-3">
                                <Typography className={`d-flex align-items-center ${classes.tableHeaderFont}`}>
                                  # Of Users (This Year)
                                </Typography>
                              </div>
                              {/* <div className="col ml-2" /> */}
                            </div>


                            <div className={classes.dataTable}>
                              {
                                userData.map((item) => (

                                  <div
                                    key={item.id}
                                    className={`row d-flex align-items-center pt-4 pb-4 ${classes.border} ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableRow}`}
                                  >
                                    <div className="col-4">
                                      <Typography variant="bodySemiBold" className="d-flex align-items-start">
                                        {item.title}
                                      </Typography>
                                    </div>
                                    <div className="col row d-flex align-items-center ml-2">
                                      <Typography variant="bodySemiBold" className="col d-flex align-items-center">
                                        {Math.round(item.value - (item.value * (Number(item.diff) / 100))).toLocaleString()}
                                        {/* {item.number_of_users_this_yr} */}

                                      </Typography>
                                    </div>
                                    <div className="col row d-flex align-items-center ml-2">
                                      <Typography className="col-3 d-flex align-items-center" variant="bodySemiBold">
                                        {/* {item.number_of_users_this_yr} */}
                                        {item.value.toLocaleString()}

                                      </Typography>
                                      <div className={`${classes.list__change} ${classes.tableListChange} ${classes.list__changePositive}`}>
                                        <div className={classes.list__arrow}>{item.diff >= 0 ? '↑' : '↓'}</div>

                                        <div className={classes.list__percentage}>{item.diff}%</div>
                                      </div>
                                    </div>
                                  </div>

                                ))
                              }
                            </div>
                          </PortletBody>
                        </div>
                      )
                    }
                    {
                      modalContent === 'description' && (
                        <div style={{ width: '100%' }}>
                          <PortletBody className={`${classes.tableBody}`}>
                            <Typography variant="body" className="d-flex align-items-start text-left">
                              Provides insights into where your Recycle Coach users are located, based on your local collection areas. In some cases the users' zone is not known; typically this happens when a resident engages with educational content without first completing the ‘My Schedule’ set-up step. The number of users in each zone is as of the latest month, which is shown in the upper right corner of this page. The percentage change is a comparison to the same month last year.
                            </Typography>
                          </PortletBody>
                        </div>
                      )
                    }
                  </>


                )}
              />
              {
                isLoading ?
                  <TableLoading /> :
                  <div className="col-12">
                    <div className={classes.lists} style={{ overflowY: reportData?.user_by_zone?.length > 5 ? 'auto' : 'initial' }}>
                      <div className={classes.list}>
                        <div className={classes.list__heading} style={{ display: 'flex', justifyContent: 'center', marginRight: 18 }}>Zone</div>
                        <div className={classes.list__rows}>
                          {
                            reportData?.user_by_zone?.map((item, index) => (
                              <div className={classes.list__row}>
                                <div className={`${classes.list__legend} ${index + 1 === 1 ? classes.list__legend1 : index + 1 === 2 ? classes.list__legend2 : index + 1 === 3 ? classes.list__legend3 : index + 1 === 4 ? classes.list__legend4 : classes.list__legend5}`}>{index + 1}</div>
                                <div className={classes.list__topic}>{item.title}</div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      <div className={classes.list} >
                        <div className={classes.list__heading}># Of Users</div>
                        <div className={classes.list__rows}>
                          {
                            reportData?.user_by_zone?.map((item, index) => (
                              <div className={classes.list__row}>
                                <div className={classes.list__number}>{item.value.toLocaleString()}</div>
                                <div className={`${classes.list__change} ${classes.list__changePositive}`}>
                                  <div className={classes.list__arrow}>↑</div>
                                  <div className={classes.list__percentage}>{item.diff}%</div>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
              }


            </div>
          </div>
        </PortletBody>
      </Portlet>
    </div>
  );
};

export default injectIntl(UsersByZones);
