import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import MenuItem from '@material-ui/core/MenuItem';

import { LoadingSpinner, ErrorContainer } from '../../components/loading';


import { MajorCampaignDetailsTable } from './components'
import { Typography } from '../../components';

import * as actions from '../ReportDashboardNew/redux/actions';
import * as sharedActions from '../../redux/actions';
import qs from 'querystring';
import { useHistory } from 'react-router-dom';
import { TableLoading } from '../../components/loading';
import moment from 'moment';



const useStyles = makeStyles((theme) => ({
  container: {
    // width: '90%',
    margin: '0 auto'
  },
  dateSelector: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  dateLabel: {
    color: '#2A95FF',
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 16,
  },
  topSection: {
    display: 'flex',
    alignContent: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  sectionTitle: {
    color: '#404040',
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 22,
    letterSpacing: '0.05em'
  },
}));


const MajorCampaigns = ({
  intl,
  getMajorCampaignDetailsRequest,
  campaignData,
  loading,
  error,
  municipality,
  isUserPro,
  stage,
  district,
  municipalities,
  setDistrictAction,
  hasCounty,
  counties,
  county,
  isCounty,
  setCountyAction,
}) => {

  const classes = useStyles();
  const [datePeriod, setDatePeriod] = useState({ month: 0, year: 0 });
  const [dateOptions, setDateOptions] = useState([]);

  const history = useHistory();


  const campaignID = qs.parse(location.search.substring(1)).campaignID
  if (!campaignID) {
    history.push(`/report/dashboard`);
  }

  const handleChange = (event, data) => {
    setDatePeriod(data.props.item);

  };

  useEffect(() => {

    const options = generateLast24Months();

    setDateOptions(options);
    setDatePeriod(options[0]);
    if (campaignID) {
      getMajorCampaignDetailsRequest({
        municipality,
        month: options[0].month,
        year: options[0].year,
        major_campaign_id: campaignID,
        county,
        isCounty,
      })
    }

  }, []);

  // function that generates the last 24 months based on a given date
  const generateLast24Months = (date) => {
    const months = [];
    for (let i = 0; i < 24; i++) {
      const month = moment(date).subtract(i, 'months');
      months.push({
        month: month.month() ?  month.month() : 12,
        year: month.month() ? month.year() : month.year() - 1,
      });
    }
    return months;
  };

  const generateMonthYear = () => {
    const date = new Date();
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();
    const monthYear = [];
    for (let i = 0; i < 24; i++) {
      if (currentMonth - i < 0) {
        monthYear.push({
          month: Math.abs(12 + currentMonth - i),
          year: currentYear - 1,
        });
      } else {
        monthYear.push({
          month: currentMonth - i,
          year: currentYear,
        });
      }
    }
    return monthYear;
  };

  const getMonthName = (monthNumber, format = 'short') => {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', { month: format });
  };

  const majorCampaignsData = [
    {
      title: 'Learning Activity 1',
      unique_user: 100,
      exposure: 100,
      engagement: 100,
      knowledge_measurement_eng: 100,
      correct_answer_rate: 100,
      time_spent: 100,
    },
    {
      title: 'Learning Activity 2',
      unique_user: 100,
      exposure: 100,
      engagement: 100,
      knowledge_measurement_eng: 100,
      correct_answer_rate: 100,
      time_spent: 100,
    },
    {
      title: 'Learning Activity 3',
      unique_user: 100,
      exposure: 100,
      engagement: 100,
      knowledge_measurement_eng: 100,
      correct_answer_rate: 100,
      time_spent: 100,
    },
    {
      title: 'Learning Activity 4',
      unique_user: 100,
      exposure: 100,
      engagement: 100,
      knowledge_measurement_eng: 100,
      correct_answer_rate: 100,
      time_spent: 100,
    },
    {
      title: 'Learning Activity 5',
      unique_user: 100,
      exposure: 100,
      engagement: 100,
      knowledge_measurement_eng: 100,
      correct_answer_rate: 100,
      time_spent: 100,
    },
  ];

  return (
    <>
      <div className={classes.container}>
        {
          loading ?
            <TableLoading /> :
            <div className="row">
              {dateOptions && dateOptions.length > 0 && !campaignData && loading ? <LoadingSpinner height={350} /> : error ? (
                <ErrorContainer
                  small
                  reportName="Report Dashboard"
                  height={430}
                  error={error}
                />
              ) : (
                <div className="col-xl-12 col-centered">
                  <div className={classes.topSection}>
                    <Typography variant="h2" className={classes.sectionTitle}>
                      Major Campaign
                    </Typography>
                  </div>
                  {
                    campaignData && campaignData.data && (
                      <MajorCampaignDetailsTable data={campaignData.data} month={getMonthName(datePeriod.month, 'long')} year={datePeriod.year} />
                    )
                  }

                </div>
              )}
            </div>}

      </div>
    </>
  );
};

/**
 * Map state to props
 */
const mapStateToProps = (store) => ({
  campaignData: store.reportsDashboardReducer.campaign,
  loading: store.reportsDashboardReducer.loading,
  error: store.reportsDashboardReducer.error,
  municipality: store.common.district.district_id,
  isUserPro: store.common.isUserPro,
  stage: store.common.district.stage,
  district: store.common.district,
  municipalities: store.common.municipalities,
  hasCounty: store.auth.user_info.counties,
  counties: store.common.counties,
  county: store.common.county,
  isCounty: store.common.isCounty,
});

export default injectIntl(
  connect(
    mapStateToProps, { ...actions, ...sharedActions }
  )(MajorCampaigns)
);
