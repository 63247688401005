import React from 'react';
import ContentLoader from 'react-content-loader';

const SummaryCellLoading = ({
  width = 200,
  height = 150
}) => (
  <ContentLoader
    speed={2}
    style={{ width, height }}
    backgroundColor={process.env.REACT_APP_COLOR_LOADING_BACKGROUND}
    foregroundColor={process.env.REACT_APP_COLOR__LOADING_FOREGROUND}
  >
    <rect x="0" y="0" rx="3" ry="3" width="70" height="15" />
    <circle cx="30" cy="70" r="30" />
    <rect x="0" y="125" rx="3" ry="3" width="100" height="15" />
  </ContentLoader>
);

export default SummaryCellLoading;
