import React from 'react';
import { injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';


import { Typography } from '../../../components';
import { toAbsoluteUrl } from '../../../../../_metronic';

import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';
import { SummaryOtherLoading } from '../../../components/loading';


const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 500,

    '@media (max-width: 1390px)': {
      height: '100%',
    },
    '@media (max-width: 1200px)': {
      minHeight: 'unset',
    }
  },
  group: {
    display: 'flex',
    gap: '10px'
  },
  groupItem: {
    display: 'flex',
    flexDirection: 'column',
    width: 235,
    MightNeedFullWidthSoTheItemsDontGetTooBigOnLargeScreenColor: '#63666A',
    ChangeTheColorFontFamily: 'sans-serif',
  },
  groupTitle: {
    fontWeight: '600',
    fontSize: 16,
    color: '#404040',
    letterSpacing: '0.04em'
  },
  groupNumber: {
    fontSize: '30px',
    fontWeight: '600',
    marginTop: '6px',
    marginBottom: '6px',
    color: '#2A95FF'
  },

}));

const AdvertisingEquivalentValue = ({
  intl,
  reportData,
  isLoading,
}) => {
  const classes = useStyles();


  return (
    <>
      <Portlet className={classes.container}>
        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start">
                <div className="row col-xs-12 col-lg-6 align-items-center">
                  <div className="align-items-center">
                    <Typography variant="h1" style={{ width: 275 }}>
                      Advertising Equivalent Value
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          />
        </div>
        <PortletBody>
          <div className="row">
            {
              isLoading ?
                <SummaryOtherLoading /> :
                (
                  <>
                    <div className={`${classes.groupItem}`}>
                      <div className={classes.groupTitle}>Last 12 Months</div>
                      {
                        reportData.advertising_equivalent_value && (
                          <div className={classes.groupNumber}>{reportData.advertising_equivalent_value.value.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}</div>
                        )
                      }
                    </div>
                    <div className="col-12 col-xl-10">
                      <img
                        alt="Download"
                        src={toAbsoluteUrl('/media/icons/advertisingValue.svg')}
                        className={`${classes.packImage}`}
                      />
                    </div>
                    <Typography variant="body" style={{ color: '##6C7293', fontSize: 14 }}>
                      This is the advertising value of the learning activities your residents accessed through the Recycle Coach platform during the last 12 months, calculated using a rate of $10/K for Impressions and $75/K for Engagements.
                    </Typography>
                  </>
                )
            }


          </div>
        </PortletBody>
      </Portlet>
    </>
  );

};

export default injectIntl(AdvertisingEquivalentValue);
