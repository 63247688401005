import React from 'react';
import ContentLoader from 'react-content-loader';

const CmsColumnLoading = ({
  height = 900,
  x = 2
}) => (
  <ContentLoader
    speed={2}
    style={{ width: '100%', height }}
    backgroundColor={process.env.REACT_APP_COLOR_LOADING_BACKGROUND}
    foregroundColor={process.env.REACT_APP_COLOR__LOADING_FOREGROUND}
  >

    <rect x={`${x - 0.6}%`} y="10" rx="0" ry="0" width="2" height="526" />
    <rect x={`${x + 94.8}%`} y="10" rx="0" ry="0" width="2" height="526" />
    <rect x={`${x - 0.6}%`} y="10" rx="0" ry="0" width="96%" height="2" />
    <rect x={`${x - 0.6}%`} y="536" rx="0" ry="0" width="96%" height="2" />
    {/* body */}
    <rect x={`${x + 4}%`} y="28" rx="6" ry="6" width="87%" height="60" />
    <rect x={`${x}%`} y="105" rx="6" ry="6" width="95%" height="2" />

    <rect x={`${x + 4}%`} y="130" rx="6" ry="6" width="70%" height="25" />
    <rect x={`${x + 4}%`} y="175" rx="6" ry="6" width="70%" height="25" />
    <rect x={`${x + 4}%`} y="220" rx="6" ry="6" width="70%" height="25" />
    {/* <rect x={`${x}%`} y="182" rx="6" ry="6" width="31%" height="2" /> */}

    <rect x={`${x + 4}%`} y="305" rx="6" ry="6" width="87%" height="60" />
    <rect x={`${x}%`} y="382" rx="6" ry="6" width="95%" height="2" />

    <rect x={`${x + 4}%`} y="407" rx="6" ry="6" width="70%" height="25" />
    <rect x={`${x + 4}%`} y="452" rx="6" ry="6" width="70%" height="25" />
    <rect x={`${x + 4}%`} y="497" rx="6" ry="6" width="70%" height="25" />

  </ContentLoader>
);

export default CmsColumnLoading;
