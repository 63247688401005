import React from 'react';
import { connect } from 'react-redux';

import Summary from './components/Summary';
import Detail from './components/Detail';

const DiscoveryZoneReport = ({
  data,
  fetchState,
  district
}) => (
  <div>
    <Summary
      data={data.summary}
      fetchState={fetchState}
      PDFDetailData={data.detail}
    />

    <Detail
      data={data.detail}
      fetchState={fetchState}
      isAllDistricts={district.district_id === '#all-districts#'}
    />
  </div>
);

const mapStateToProps = (store) => ({
  data: store.discoveryZoneReport.data,
  fetchState: store.discoveryZoneReport.fetchState,
  district: store.common.district,
});
export default connect(mapStateToProps, null)(DiscoveryZoneReport);
