import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';


import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ImageCarouselItem from './ImageCarouselItem';
import { Portlet, PortletHeader } from '../../../../_metronic/partials/content/Portlet';
import { Typography } from '../../components';


const useStyles = makeStyles({
  container: {
    width: 900,
    overflow: 'hidden',
    position: 'relative'
  },
  slideContainer: {
    display: 'flex',
    transition: 'all 1s',
  },
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 40,
    borderRadius: '50%',
    cursor: 'pointer',
    transform: 'translateX(10px)',
    position: 'absolute',
    top: 220,

    zIndex: 999,
    transition: 'all 0.3s'
  },
  arrow: {
    fontSize: 30,
    color: '#fff'
  },
  arrowRight: {
    right: 120,
  },
  arrowLeft: {
    left: 120,
  }
});

const ImageCarousel = ({ intl, permission_list }) => {
  const classes = useStyles();
  const [selectedImageName, setSelectedImageName] = useState('');
  const [slide, setSlide] = useState(0);

  const isRemindersAllowed = permission_list.find((item) => item.group_id === 'reminder')?.permission === 't';
  const isReportAllowed = permission_list.find((item) => item.group_id === 'report')?.permission === 't';
  const isEducationAllowed = permission_list.find((item) => item.group_id === 'education')?.permission === 't';


  /*
 * Url must be the main image name.
 * i.e. image-search-thumbnail-300x300.png, image-search-full.png,  url: `image-search`
 *
*/

const imageData = [
  { id: 1, url: 'education-tab', link: '/education/', external: false, isAllowed: isEducationAllowed },
  { id: 2, url: 'enhanced-reminders', link: '/reminder/', external: false, isAllowed: isRemindersAllowed },
  { id: 3, url: 'reporting-dashboard', link: '/report/dashboard', external: false, isAllowed: isReportAllowed },
  { id: 4, url: 'recycle-right-game' },
  // { id: 5, url: '2024-calendars', link: 'https://share.hsforms.com/1ByQkxTAqTdCEvBf929ppFAe4tiu', external: true, isAllowed: true },
  { id: 5, url: 'print-solutions' },
];

  const imageCount = imageData.length;
  const imageWidth = 300;

  const isReachedToEnd = slide === (imageCount - 3) * imageWidth * -1;

  const slideHandler = (direction) => {
    if (selectedImageName && selectedImageName.length > 0) {
      setSelectedImageName();
    }
    if (direction === 'right' && !isReachedToEnd) {
      setSlide((prevState) => prevState - imageWidth);
    }
    if (direction === 'left' && slide !== 0) {
      setSlide((prevState) => prevState + imageWidth);
    }
  };

  const handleOpenDialog = (imageName) => {
    setSelectedImageName(imageName);
  };
  const handleCloseDialog = () => {
    setSelectedImageName();
  };

  return (
    <Portlet style={{ position: 'relative' }}>
      <PortletHeader title={(
        <div className="d-flex align-items-center">
          <Typography className="mr-4" variant="h1">
            {intl.formatMessage({ id: 'DASHBOARD.NEW.AND.POPULAR.FEATURES' })}
          </Typography>
        </div>
      )}
      />
      <div
        role="button"
        style={{ background: slide === 0 ? 'gray' : '#1FA0EA', opacity: slide === 0 ? 0.4 : 1 }}
        className={`${classes.arrowContainer} ${classes.arrowLeft}`}
        onClick={() => slideHandler('left')}
      >
        <ArrowBackIcon className={classes.arrow} />
      </div>
      <div
        role="button"
        style={{ background: isReachedToEnd ? 'gray' : '#1FA0EA', opacity: isReachedToEnd ? 0.4 : 1 }}
        className={`${classes.arrowContainer} ${classes.arrowRight}`}
        onClick={() => slideHandler('right')}
      >
        <ArrowForwardIcon className={classes.arrow} />
      </div>
      <div className={`col-9 mx-auto py-4 ${classes.container}`}>
        <div style={{ transform: `translateX(${slide}px)` }} className={classes.slideContainer}>
          {imageData.map((item) => (
            <ImageCarouselItem
              key={item.id}
              data={item}
              selectedImageName={selectedImageName}
              handleOpenDialog={handleOpenDialog}
              handleCloseDialog={handleCloseDialog}
            />
          ))}
        </div>
      </div>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  permission_list: store.auth.user_info.permission_list,
  municipalities: store.common.municipalities || []
});

export default injectIntl(connect(mapStateToProps, null)(ImageCarousel));

