export const holidaysChangesInitial = {
  monday: {
    notAffected: false,
    oneDayLater: false,
    isCustom: false,
    customConfig: {
      Monday: {
        action: '',
        day: '',
      },
      Tuesday: {
        action: '',
        day: '',
      },
      Wednesday: {
        action: '',
        day: '',
      },
      Thursday: {
        action: '',
        day: '',
      },
      Friday: {
        action: '',
        day: '',
      },
      Saturday: {
        action: '',
        day: '',
      },
      Sunday: {
        action: '',
        day: '',
      },
    },
  },
  tuesday: {
    notAffected: false,
    oneDayLater: false,
    isCustom: false,
    customConfig: {
      Monday: {
        action: '',
        day: '',
      },
      Tuesday: {
        action: '',
        day: '',
      },
      Wednesday: {
        action: '',
        day: '',
      },
      Thursday: {
        action: '',
        day: '',
      },
      Friday: {
        action: '',
        day: '',
      },
      Saturday: {
        action: '',
        day: '',
      },
      Sunday: {
        action: '',
        day: '',
      },
    },
  },
  wednesday: {
    notAffected: false,
    oneDayLater: false,
    isCustom: false,
    customConfig: {
      Monday: {
        action: '',
        day: '',
      },
      Tuesday: {
        action: '',
        day: '',
      },
      Wednesday: {
        action: '',
        day: '',
      },
      Thursday: {
        action: '',
        day: '',
      },
      Friday: {
        action: '',
        day: '',
      },
      Saturday: {
        action: '',
        day: '',
      },
      Sunday: {
        action: '',
        day: '',
      },
    },
  },
  thursday: {
    notAffected: false,
    oneDayLater: false,
    isCustom: false,
    customConfig: {
      Monday: {
        action: '',
        day: '',
      },
      Tuesday: {
        action: '',
        day: '',
      },
      Wednesday: {
        action: '',
        day: '',
      },
      Thursday: {
        action: '',
        day: '',
      },
      Friday: {
        action: '',
        day: '',
      },
      Saturday: {
        action: '',
        day: '',
      },
      Sunday: {
        action: '',
        day: '',
      },
    },
  },
  friday: {
    notAffected: false,
    oneDayLater: false,
    isCustom: false,
    customConfig: {
      Monday: {
        action: '',
        day: '',
      },
      Tuesday: {
        action: '',
        day: '',
      },
      Wednesday: {
        action: '',
        day: '',
      },
      Thursday: {
        action: '',
        day: '',
      },
      Friday: {
        action: '',
        day: '',
      },
      Saturday: {
        action: '',
        day: '',
      },
      Sunday: {
        action: '',
        day: '',
      },
    },
  },
  saturday: {
    notAffected: false,
    oneDayLater: false,
    isCustom: false,
    customConfig: {
      Monday: {
        action: '',
        day: '',
      },
      Tuesday: {
        action: '',
        day: '',
      },
      Wednesday: {
        action: '',
        day: '',
      },
      Thursday: {
        action: '',
        day: '',
      },
      Friday: {
        action: '',
        day: '',
      },
      Saturday: {
        action: '',
        day: '',
      },
      Sunday: {
        action: '',
        day: '',
      },
    },
  },
  sunday: {
    notAffected: false,
    oneDayLater: false,
    isCustom: false,
    customConfig: {
      Monday: {
        action: '',
        day: '',
      },
      Tuesday: {
        action: '',
        day: '',
      },
      Wednesday: {
        action: '',
        day: '',
      },
      Thursday: {
        action: '',
        day: '',
      },
      Friday: {
        action: '',
        day: '',
      },
      Saturday: {
        action: '',
        day: '',
      },
      Sunday: {
        action: '',
        day: '',
      },
    },
  },
};

export const seasonalListInitial = {
  seasonal_list: [],
}
