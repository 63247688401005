/**
 *
 */
const initialState = {
  fetchState: 'no-request',
  snackbarState: 'no-request',
  responseStatus: 'success',
  responseMessage: null,
  data: [],
  prompts: [],
  zones: [],
  years: [],
  district_holidays: [],
};

export default initialState;
