import actionTypes from './actionTypes';

// Set district
export const setCampaignDistrictAction = (district) => ({
  type: actionTypes.SET_DISTRICT,
  payload: district
});

// Notification actions for setting loading status
export const setLoadingAction = () => ({
  type: actionTypes.SET_LOADING
});

export const resetLoadingAction = () => ({
  type: actionTypes.RESET_LOADING
});

// Notification actions for setting tab in notification history
export const setTabAction = (tab) => ({
  type: actionTypes.SET_TAB,
  payload: { tab }
});

export const setSwitchItem = (item) => ({
  type: actionTypes.SET_SWITCH_ITEM,
  payload: { item }
});

// Campaign actions for fetching campaign list
export const campaignListRequest = ({ page, type }) => ({
  type: actionTypes.CAMPAIGN_LIST_REQUEST,
  payload: { page, type }
});

export const campaignListReceive = (data) => ({
  type: actionTypes.CAMPAIGN_LISTـRECEIVE,
  payload: { ...data }
});

export const campaignListFail = (error) => ({
  type: actionTypes.CAMPAIGN_LISTـFAIL,
  payload: { ...error }
});
