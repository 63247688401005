/* eslint-disable import/prefer-default-export */
import cts from '../../../crud/cts';

/**
 * Material report API which fetches material report data
 */
export const getMaterialReport = async ({
  municipality, from, to, county
}) => cts(
  'get',
  `reports/materials?county=${county}&municipality=${municipality}&from=${from}&to=${to}`,
);
