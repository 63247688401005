import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

/**
 * Components
 */
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from '../../../../_metronic/partials/content/Portlet';
import CampaignList from './CampaignList';

import { campaignNormalizer } from '../helpers';
import { getCampaignLibraryApi, ACTIVE } from '../apis';


const SelectTemplate = ({
  district,
  intl
}) => {
  const [campaigns, setCampaigns] = useState({
    campaigns: [],
    loading: false,
    error: null
  });

  const getCampaign = async (params) => {
    try {
      setCampaigns({
        campaigns: [],
        loading: true,
        error: null
      });
      const { data, error } = await getCampaignLibraryApi(params);
      const { campaigns: camps } = data.data;
      if (!error) {
        setCampaigns({
          campaigns: camps.length ? campaignNormalizer(camps) : [],
          loading: false,
          error: null
        });
      }
      if (error) {
        throw new Error('Could not get campaign data');
      }
    } catch (e) {
      setCampaigns({
        campaigns: [],
        loading: false,
        error: e
      });
    }
  };


  useEffect(() => {
    getCampaign({
      district_id: district.district_id,
      state: ACTIVE
    });
  }, [district]);

  return (
    <div>
      <Portlet>
        <PortletHeader title={intl.formatMessage({ id: 'CAMPAIGN.SWITCH_ITEM_TITLE' })} />
        <PortletBody>
          <CampaignList
            campaigns={campaigns.campaigns}
            loading={campaigns.loading}
            err={campaigns.error}
            campPage="library-template"
            uiOptions={{
              preview: true,
              switch: true,
              reject: false,
              date: false,
              use: true
            }}
          />
        </PortletBody>
      </Portlet>
    </div>
  );
};

export default injectIntl(
  connect(
    (store) => ({
      district: store.common.district
    })
  )(SelectTemplate)
);
