import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Typography } from '../../components';

const useStyles = makeStyles(() => ({
  container: {
    maxHeight: 170,
    overflow: 'auto',
    paddingRight: 25,
  },
  assignedCategoriesItem: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
}));

const EventAssignedCategories = ({
  assignedCategories
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {
        assignedCategories.map((item, index) => (
          <div
            key={item.mat_cat_admin_nm}
            className={clsx({
              'py-4': true,
              [classes.assignedCategoriesItem]: index !== assignedCategories.length - 1,
            })}
          >
            <Typography variant="body">
              {item.mat_cat_admin_nm}
            </Typography>
          </div>
        ))
      }
    </div>
  );
};

export default EventAssignedCategories;
