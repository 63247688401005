import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../../redux/createReducer';
import initialState from './store';

const pageViewsReportReducer = persistReducer(
  { storage, key: 'pageViewsReport', whitelist: [] },
  createReducer(initialState, {

    SET_PAGE_VIEWS_REPORT_LOADING_ACTION: (state, { payload }) => Immutable({
      ...state,
      fetchState: payload,
    }),

    GET_PAGE_VIEWS_REPORT_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      data: payload.data.data,
      fetchState: 'succeed-request',
    }),

    GET_PAGE_VIEWS_REPORT_FAIL: (state) => Immutable({
      ...state,
      fetchState: 'failed-request',
    }),

    SET_PAGE_TYPE: (state, { payload }) => Immutable({
      ...state,
      pageType: payload.data
    }),

    SET_REPORT_TYPE: (state, { payload }) => Immutable({
      ...state,
      reportType: payload
    })
  })
);

export default pageViewsReportReducer;
