import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';

import { Typography, CustomButton, IconButton } from '../../../components';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { CloseIcon } from '../../../../assets/icons';



const useStyles = makeStyles(() => ({

  applyButton: {
    fontSize: 14,
    fontWeight: 700,
    color: ({ disabled }) => (disabled
      ? 'rgba(255, 30, 30, 0.4)' : 'rgba(255, 30, 30, 1)'),
    marginRight: 20,
    transition: 'all 0.3s'
  },
  iframeOverride: {
    transform: 'scale( 0.75, 0.75)',
    position: 'relative',
    top: '-50px',
    border: 0
  },
  dialogOverride: {
    display: 'block',
    overflow: 'hidden',
    // maxWidth: 'unset'
    // maxWidth: '100%',
    width: 800,
    maxWidth: '100%',
    borderRadius: '10px 15px 15px 10px'
  },
  modalContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: 427,
    margin: '0 auto',
  },
  backgroundImage: {
    position: 'absolute',
    width: 420,
    top: 170,
    // right: 120
  },
  characterLeftmage: {
    position: 'absolute',
    // right: -50,
    top: 360,
    left: '-90px',
  },
  characterRightImage: {
    position: 'absolute',
    top: 370,
    order: 2,
    // left: -50
    right: '-48px',
  },
  plantImage: {
    top: 90,
    position: 'absolute',
    left: 0
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'center',
    top: 40,
  },
  lessonDescription: {
    height: 350,
    overflow: 'scroll',
    margin: 20
  },
  previewButton: {
    // marginTop: '45%',
    // display: 'flex',
    // left: 40
    position: 'absolute',
    width: 100,
    left: '-120px',
    top: 620,
  },
  nextButton: {
    // marginTop: '45%',
    // display: 'flex',
    // left: 40
    width: 100,
    position: 'absolute',
    top: 620,
    left: 'auto',
    right: '-120px',
  },
  headerContainer: {
    position: 'relative',
    top: 40,
  },
  modalCloseButton: {
    position: 'absolute',
    right: 16,
    top: 2
  }
}));
const PreviewLessonModal = ({
  intl, open, onClose, disabled = false, rfLessonVideo, rfLessonTitle, rfLessonDescription, nextHandler, previewHandler, currentRFLessonIndex, numberOfLessons
}) => {
  const classes = useStyles({ disabled });

  return (
    <>
      {open && (
        <Dialog classes={{
          paperScrollPaper: classes.dialogOverride,
        }}
          open={open} onClose={onClose}>
          <div className={classes.headerContainer}>
            <Typography color="#37474F" className={classes.modalTitle} variant="h2">
              Now playing on your phone
            </Typography>
            <IconButton tooltip={intl.formatMessage({ id: 'GENERAL.CLOSE' })} className={classes.modalCloseButton} onClick={() => onClose()} style={{ top: '-30px' }}>
              <CloseIcon width="3em" height="3em" />
            </IconButton>
          </div>
          <div className={classes.modalContentContainer}>
            <CustomButton
              paddingVertical={4}
              className={classes.previewButton}
              variant={'contained'}
              fontSize={12}
              label={'Previous'}
              onClick={previewHandler}
              disabled={currentRFLessonIndex === 0}
            />
            <img
              alt="background"
              src={toAbsoluteUrl('/media/icons/Education/freepik--background-simple--inject-1.png')}
              className={`${classes.backgroundImage}`}
            />
            <img
              alt="character1"
              src={toAbsoluteUrl('/media/icons/Education/freepik--character-1--inject-1.png')}
              className={`${classes.characterLeftmage}`}
            />
            <img
              alt="character2"
              src={toAbsoluteUrl('/media/icons/Education/freepik--character-2--inject-1.png')}
              className={`${classes.characterRightImage}`}
            />
            <img
              alt="plant"
              src={toAbsoluteUrl('/media/icons/Education/freepik--Plant--inject-1.png')}
              className={`${classes.plantImage}`}
            />
            <div className={classes.iframeOverride}>
              <div className="marvel-device iphone-x">
                <div className="notch">
                  <div className="camera"></div>
                  <div className="speaker"></div>
                </div>
                <div className="top-bar"></div>
                <div className="sleep"></div>
                <div className="bottom-bar"></div>
                <div className="volume"></div>
                <div className="overflow">
                  <div className="shadow shadow--tr"></div>
                  <div className="shadow shadow--tl"></div>
                  <div className="shadow shadow--br"></div>
                  <div className="shadow shadow--bl"></div>
                </div>
                <div className="inner-shadow"></div>
                <div className="screen">
                  <iframe src={`${rfLessonVideo}`} width="380"
                    height="360"
                    frameBorder="0"
                    allowFullScreen></iframe>

                  <Typography color="#6C7293" variant="h2" style={{ margin: 20 }}>
                    {rfLessonTitle}
                  </Typography>
                  <Typography
                    color={process.env.REACT_APP_COLOR_TEXT}
                    className={`${classes.lessonDescription}`}
                    variant="educationBody"
                    dangerouslySetInnerHTML={{ __html: rfLessonDescription }}
                  />

                </div>
              </div>
            </div>
            <CustomButton
              paddingVertical={4}
              className={classes.nextButton}
              variant={'contained'}
              fontSize={12}
              label={'Next'}
              onClick={nextHandler}
              disabled={currentRFLessonIndex === numberOfLessons - 1}
            />

          </div>
        </Dialog>
      )}
    </>
  );
};

export default injectIntl(PreviewLessonModal);
