import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Typography } from '../../../components';
import { Portlet } from '../../../../../_metronic/partials/content/Portlet';
import * as actions from '../redux/actions';
import { ErrorContainer, LoadingSpinner } from '../../../components/loading';

const useStyles = makeStyles(() => ({
  container: {
    width: '1200px',
    margin: '0 auto',
    // minHeight: '100vh'
  },
  header: {
    borderBottom: '2px solid #E7ECEF',
    padding: '15px 0'
  },
  image: {
    width: 300,
    float: 'left',
    marginRight: 25,
    marginBottom: 25,
  },
  backArrow: {
    fontSize: '2rem',
    color: '#404040',
    '&:hover': {
      color: '#0086C2',
    }
  }

}));

const BlogDetails = ({
  getBlogDetailsRequest,
  blogReadRequest,
  error,
  loading,
  blogDetails
}) => {
  const classes = useStyles();
  const { blogId } = useParams();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    getBlogDetailsRequest(blogId);
    blogReadRequest(blogId);
  }, []);


  const goBack = () => {
    history.goBack();
  };

  return (
    <Portlet className={classes.container}>
      {loading ? <LoadingSpinner height={400} /> : error ? (
        <ErrorContainer
          reportName="Blog Details"
          height={400}
          error={error}
        />
      ) : (
        <>
          <div className={`d-flex align-items-center px-4 ${classes.header}`}>
            <div
              role="button"
              style={{ marginRight: 10 }}
            >
              <ArrowBackIcon className={classes.backArrow} onClick={goBack} />
            </div>
            <Typography variant="h1">
              {blogDetails.title}
            </Typography>

          </div>
          <div className="p-4">
            <img className={classes.image} src={blogDetails.image} alt={blogDetails.title} />
            <div
              style={{ color: process.env.REACT_APP_COLOR_TEXT }}
              dangerouslySetInnerHTML={{ __html: blogDetails.detail }}
            />
          </div>
        </>
      )}
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  loading: store.educationCommon.blogDetailsLoading,
  error: store.educationCommon.blogDetailsError,
  blogDetails: store.educationCommon.blogDetails
});

export default injectIntl(connect(mapStateToProps, actions)(BlogDetails));
