/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { makeStyles, styled } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { Portlet } from '../../../../_metronic/partials/content/Portlet';
import { Typography, MunicipalitySelector } from '../../components';
import { validation } from '../../../utils';
import { CrownIcon, PlusIcon } from '../../../assets/icons';
import { EducationRoutes } from './components';

import * as sharedActions from '../../redux/actions';

/**
 * Create education section tabs
 * @param {string} name must be in the same name with the name on {...} en.json file.
 * @param {boolean} hasCrown crown icon which is displayed on tab button
 * @param {string} to routing path.
 */

const createTabs = [
  {
    id: 1,
    name: 'EDUCATION.BLOGS',
    hasCrown: false,
    route: '/education/blogs'
  },
  {
    id: 2,
    name: 'EDUCATION.BLOG.LIBRARY',
    hasCrown: false,
    route: '/education/blogs-library'
  },
  {
    id: 3,
    name: 'EDUCATION.WE.WANTED.TO.KNOW',
    hasCrown: false,
    route: '/education/we-wanted-to-know'
  },
  {
    id: 4,
    name: 'EDUCATION.CUSTOM.POOLS.AND.SURVEYS',
    hasCrown: true,
    route: '/education/polls-and-surveys'
  },
  {
    id: 5,
    name: 'EDUCATION.FEATURED.LESSONS',
    hasCrown: true,
    route: '/education/featured-lessons',
    hideForNonPro: true
  },
  {
    id: 6,
    name: 'EDUCATION.LESSON.LIBRARY',
    hasCrown: true,
    route: '/education/lessons-library'
  },
  {
    id: 7,
    name: 'EDUCATION.WHERE.DOES.THIS.GO',
    hasCrown: true,
    route: '/education/where-does-this-go'
  },
  {
    id: 8,
    name: 'EDUCATION.PDF.ADD.ONS',
    hasCrown: true,
    route: '/education/pdf-add-ons'
  },
  {
    id: 9,
    name: 'EDUCATION.RECYCLING.FOR.KIDS',
    hasCrown: false,
    route: '/education/recycling-for-kids'
  },
  {
    id: 10,
    name: 'EDUCATION.ABOUT.CASY',
    hasCrown: false,
    route: '/education/about-casy'
  },
  {
    id: 11,
    name: 'EDUCATION.MAJOR.CAMPAIGNS',
    hasCrown: true,
    route: '/education/major-campaigns',
  },
];

const MajorCampaignTabs = [
  {
    id: 1,
    name: 'EDUCATION.SURVEYS.AND.MORE.RECYCLING.FUNDAMENTALS',
    hasCrown: false,
    route: '/education/blogs'
  },
  {
    id: 2,
    name: 'EDUCATION.WE.WANTED.TO.KNOW',
    hasCrown: false,
    route: '/education/we-wanted-to-know'
  },
  {
    id: 3,
    name: 'EDUCATION.MAJOR.CAMPAIGNS',
    hasCrown: true,
    route: '/education/major-campaigns',
  },
  {
    id: 4,
    name: 'EDUCATION.RECYCLING.FOR.KIDS',
    hasCrown: true,
    route: '/education/recycling-for-kids'
  },
  {
    id: 5,
    name: 'EDUCATION.SURVEYS.AND.ANNUAL.SURVEY',
    hasCrown: true,
    route: '/education/blogs'
  },
  {
    id: 6,
    name: 'EDUCATION.MORE',
    hasCrown: false,
    route: '/education/blogs',
    displayAltTab: true
  },

];


const useStyles = makeStyles((theme) => ({
  container: {
    padding: 25
  },
  tooltip: {
    backgroundColor: process.env.REACT_APP_COLOR_SECONDARY,
    color: process.env.REACT_APP_COLOR_TEXT,
    boxShadow: theme.shadows[3],
    fontSize: 14,
  },
  arrow: {
    color: process.env.REACT_APP_COLOR_SECONDARY,
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  buttonContainer: {
    borderRadius: 16,
    marginRight: 6,
    marginBottom: 10,
  },
  hr: {
    borderBottom: '2px solid #E7ECEF',
    marginTop: 15,
    marginBottom: 20,
  },
}));

const EducationalContent = ({
  intl,
  setDistrictAction,
  municipalities,
  district,
  hasCounty,
  counties,
  county,
  isCounty,
  setCountyAction,
  setIsCountyAction,
  isUserPro,
  stage,
  fullWidth,
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const [selectedTabRoute, setSelectedTabRoute] = useState();
  const [tabs, setTabs] = useState([]);

  /**
   * Select blogs tab by default after redirection from "/education" to "/education/blogs"
   */

  useEffect(() => {
    if (pathname.includes('/education/major-campaigns')) {
      // set tabs to empty new array
      setTabs([...MajorCampaignTabs]);


      setSelectedTabRoute('/education/major-campaigns');
    } else {
      setTabs([...createTabs]);
      setSelectedTabRoute(pathname);
    }


    /**
     * all districts options is disabled
     */
    setIsCountyAction(false);
    if (district.district_id === '#all-districts#') {
      setDistrictAction(municipalities[0]);
    }
  }, [pathname]);

  return (
    <Portlet className={`${fullWidth ? 'col-12' : 'col-8'} mr-2 ${classes.container}`}>
      <div style={{ height: 45 }} className="d-flex justify-content-between mb-1 mt-1">
        <Typography variant="h1">
          {intl.formatMessage({ id: 'EDUCATION.EDUCATIONAL.CONTENT' })}
        </Typography>
        {
          municipalities && municipalities.length
            ? (
              <div style={{ width: 300, transform: 'translateY(-10px)' }}>
                <MunicipalitySelector
                  hasAllDistrictOption={false}
                  isCountyEnable={false}
                  title={
                    (hasCounty !== null && isCounty && counties)
                      ? 'County'
                      : intl.formatMessage({ id: 'REPORT.GENERAL.REPORTING_DISTRICT' })
                  }
                  onChange={
                    (hasCounty !== null && isCounty)
                      ? setCountyAction
                      : setDistrictAction
                  }
                  value={(hasCounty !== null && isCounty) ? county : district}
                  invalidText={
                    !validation(district || '', 'required')
                      ? intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
                      : null
                  }
                />
              </div>
            ) : null
        }
      </div>
      <div className="d-flex flex-wrap">
        {/**
         * create tabs
        */}
        {tabs.map((tab) => {

          if (tab.hideForNonPro && !isUserPro && stage < 4) return null;
          /**
           * dipsplay tooltip if it is a pro package future.
           */
          if (tab.hasCrown) {
            return (
              <Tooltip
                key={tab.id}
                classes={{
                  tooltip: classes.tooltip,
                  arrow: classes.arrow,
                }}
                title={tab.hasCrown ? (
                  <Typography variant="educationBody">
                    {intl.formatMessage({ id: 'EDUCATION.PRO.PACKAGE.FUTURE' })}
                  </Typography>
                ) : null}
                placement="top"
                arrow
                leaveDelay={100}
              >
                <Link Link to={tab.route} className={classes.buttonContainer} key={tab.id}>
                  <StyledTabButton
                    selected={selectedTabRoute === tab.route}
                  >
                    {intl.formatMessage({ id: tab.name })}
                    {tab.hasCrown && <CrownIcon />}
                  </StyledTabButton>
                </Link>
              </Tooltip>
            );
          }

          if (tab.displayAltTab) {
            return (
              <Link Link to={tab.route} className={classes.buttonContainer} key={tab.id}>
                <WhiteStyledTabButton
                  selected={selectedTabRoute === tab.route}
                >
                  {intl.formatMessage({ id: tab.name })}
                  <PlusIcon />
                </WhiteStyledTabButton>
              </Link>
            );
          }

          return (
            <Link Link to={tab.route} className={classes.buttonContainer} key={tab.id}>
              <StyledTabButton
                selected={selectedTabRoute === tab.route}
              >
                {intl.formatMessage({ id: tab.name })}
              </StyledTabButton>
            </Link>
          );
        })}
      </div>

      <div className={classes.hr} />
      {/**
      * Create education routes
      */}
      <EducationRoutes />
    </Portlet>
  );
};

/**
 * Map state to props
 */

const mapStateToProps = (store) => ({
  municipalities: store.common.municipalities,
  district: store.common.district,
  username: `${store.auth.user_info.first_name} ${store.auth.user_info.last_name}`,
  hasCounty: store.auth.user_info.counties,
  counties: store.common.counties,
  county: store.common.county,
  isCounty: store.common.isCounty,
  isUserPro: store.common.isUserPro,
  stage: store.common.district.stage,
});

export default injectIntl(
  connect(
    mapStateToProps,
    { ...sharedActions }
  )(EducationalContent)
);

const StyledTabButton = styled(({ color, ...props }) => <div {...props} />)({
  color: ({ selected }) => (selected ? '#fff' : '#2A95FF'),
  background: ({ selected }) => (selected ? '#2A95FF' : '#F1F9FF'),
  textShadow: ({ selected }) => (selected ? '-0.08ex 0 currentColor, 0.08ex 0 currentColor' : 'none'),
  borderRadius: 16,
  padding: '8px 18px',
  cursor: 'pointer',
  letterSpacing: '0.335px',
  transition: 'all .3s',
  fontSize: 12,

  /**
  * Target crown icon. It is child element of StyledTabButton
  */

  '& svg': {
    fill: ({ selected }) => (selected ? '#fff' : '#2A95FF'),
    marginLeft: 4,
    marginBottom: 2,
    transition: 'all .3s',
  },

  '&:hover': {
    background: '#2A95FF',
    color: '#fff',
    textShadow: '-0.08ex 0 currentColor, 0.08ex 0 currentColor',

    '& svg': {
      fill: '#fff'
    }
  },
});


const WhiteStyledTabButton = styled(({ color, ...props }) => <div {...props} />)({
  color: ({ selected }) => (selected ? '#fff' : '#2A95FF'),
  background: ({ selected }) => (selected ? '#2A95FF' : '#FFF'),
  textShadow: ({ selected }) => (selected ? '-0.08ex 0 currentColor, 0.08ex 0 currentColor' : 'none'),
  borderRadius: 16,
  padding: '8px 18px',
  cursor: 'pointer',
  letterSpacing: '0.335px',
  transition: 'all .3s',
  fontSize: 12,
  border: '1px solid #2A95FF',

  /**
  * Target crown icon. It is child element of StyledTabButton
  */

  '& svg': {
    fill: ({ selected }) => (selected ? '#fff' : '#2A95FF'),
    marginLeft: 4,
    marginBottom: 2,
    transition: 'all .3s',
  },

  '&:hover': {
    background: '#2A95FF',
    color: '#fff',
    textShadow: '-0.08ex 0 currentColor, 0.08ex 0 currentColor',

    '& svg': {
      fill: '#fff'
    }
  },
});
