import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import {
  FormHeader,
  UsersForm,
} from '../components';
import * as actions from '../redux/actions';

const Form = ({
  intl,
  newUser
}) => (
  <>
    <FormHeader
      title={newUser.user_id === null ? intl.formatMessage({ id: 'USERS.HEADER.FORM.TITLE.CREATE' }) : intl.formatMessage({ id: 'USERS.HEADER.FORM.TITLE.EDIT' })}
      content={newUser.user_id === null ? intl.formatMessage({ id: 'USERS.HEADER.FORM.CONTENT.CREATE' }) : intl.formatMessage({ id: 'USERS.HEADER.FORM.CONTENT.EDIT' })}
    />
    <UsersForm />
  </>
);

const mapStateToProps = (store) => ({
  newUser: store.user.newUser,
});

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(Form)
);
