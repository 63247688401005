const actionTypes = {
  // User report actionTypes for setting loading status
  SET_USER_REPORT_LOADING_ACTION: 'SET_USER_REPORT_LOADING_ACTION',

  // User report actionTypes for fetching report data
  GET_USER_REPORT_REQUEST: 'GET_USER_REPORT_REQUEST',
  GET_USER_REPORT_RECEIVE: 'GET_USER_REPORT_RECEIVE',
  GET_USER_REPORT_FAIL: 'GET_USER_REPORT_FAIL',
};


export default actionTypes;
