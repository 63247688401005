import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import TableSort from './TableSort';
import {
  Portlet,
  PortletBody,
} from '../../../../_metronic/partials/content/Portlet';
import { Snackbar } from '../../components';

import * as actions from '../redux/actions';

const UsersList = ({
  userListRequest,
  responseMessage,
  responseStatus,
  resetMessageAction,
  resetNewUserAction,
}) => {
  useEffect(() => {
    userListRequest();
    resetNewUserAction();
  }, []);

  return (
    <div>
      <Snackbar
        message={responseMessage}
        status={responseStatus}
        closeAction={resetMessageAction}
      />
      <div className="ml-2 h-100">
        <Portlet fluidHeight>
          <PortletBody>
            <TableSort />
          </PortletBody>
        </Portlet>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  responseMessage: store.user.responseMessage,
  responseStatus: store.user.responseStatus
});

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(UsersList)
);
