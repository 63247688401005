/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import { EmergencyIcon, InfoIcon } from '../../../assets/icons';
import {
  AutoComplete, TextField, DatePicker, TimePicker, IconButton,
  MunicipalitySelector, Typography, RadioButton, WysiwygEditor,
} from '../../components';
import NewMessageSubheader from './NewMessageSubheader';
import ButtonSection from './ButtonSection';
import { validation } from '../../../utils';
import * as actions from '../redux/actions';
import { FormLoading } from '../../components/loading';

const useStyles = makeStyles((theme) => ({
  container: {
    '-webkit-justify-content': 'space-between',
    '-webkit-flex': 1,
    '-webkit-flex-direction': 'column',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  emergency: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
  },
}));

const CustomForm = ({
  intl,
  setFormDataAction,
  resetFormAction,
  newMessage,
  newDraftRequest,
  newScheduledRequest,
  editDraftRequest,
  editScheduledRequest,
  getZoneRequest,
  municipalities,
  zones,
  prompts,
  newImmediatelyRequest,
  editImmediatelyRequest,
  formLoading,
  fetchState,
  userName,
  cat,
  updateNotificationRequest,
  advocateAdmin
}) => {
  const [descriptionInvalidText, setDescriptionInvalidText] = useState(true);
  const [allDisable, setAllDisable] = useState(false);
  const [invalidText, setInvalidText] = useState({
    send_date: true,
    send_time: true,
    district_id: true,
    pickup_type: true,
    zones: true,
    title: true,
  });
  const classes = useStyles();
  const handleChange = (data, propertyName) => {
    setFormDataAction({
      data,
      propertyName,
    });
  };

  const shorten_body = (body) => {
    const postfix = ' ... for more info use RecycleCoach app, reply "0" to unsubscribe';
    const max = 160;

    const span = document.createElement('span');
    span.innerHTML = String(body);
    const temp = span.textContent || span.innerText;
    if (temp.length > max) {
      return `${temp.slice(0, max - postfix.length)}${postfix}`;
    }
    return temp;
  };

  useEffect(() => {
    if (municipalities.length === 1) {
      handleChange(municipalities, 'district_id');
    }
  }, [municipalities, userName, fetchState]);

  useEffect(() => {
    if (
      newMessage.district_id.length === 1
      && newMessage.district_id[0].district_id !== '#all-districts#'
    ) {
      getZoneRequest(newMessage.district_id[0].district_id);
    }
  }, [newMessage.district_id, getZoneRequest]);

  useEffect(() => {
    if (newMessage.notif_id) {
      if (cat) {
        setAllDisable(true);
      } else {
        setAllDisable(false);
      }
    } else {
      setAllDisable(false);
    }
  }, [newMessage.notif_id]);

  const handleSaveClick = () => {
    if (newMessage.schedule === 'immediately' && newMessage.notif_id === null) {
      newImmediatelyRequest({
        notif_type: newMessage.notif_type,
        send_date: newMessage.send_date,
        send_time: newMessage.send_time,
        district_id: newMessage.district_id,
        check_zone: newMessage.check_zone,
        pickup_types: newMessage.pickup_types,
        zones: newMessage.zones,
        title: newMessage.title,
        description: newMessage.description,
        notif_msg: shorten_body(newMessage.description),
      });
    }
    if (newMessage.schedule === 'immediately' && newMessage.notif_id !== null) {
      editImmediatelyRequest({
        notif_id: newMessage.notif_id,
        notif_type: newMessage.notif_type,
        send_date: newMessage.send_date,
        send_time: newMessage.send_time,
        district_id: newMessage.district_id,
        check_zone: newMessage.check_zone,
        pickup_types: newMessage.pickup_types,
        zones: newMessage.zones,
        title: newMessage.title,
        description: newMessage.description,
        notif_msg: shorten_body(newMessage.description),
      });
    }
    if (newMessage.schedule === 'schedule' && newMessage.notif_id === null) {
      newScheduledRequest({
        notif_type: newMessage.notif_type,
        send_date: newMessage.send_date,
        send_time: newMessage.send_time,
        district_id: newMessage.district_id,
        check_zone: newMessage.check_zone,
        pickup_types: newMessage.pickup_types,
        zones: newMessage.zones,
        title: newMessage.title,
        description: newMessage.description,
        notif_msg: shorten_body(newMessage.description),
      });
    } else if (
      newMessage.schedule === 'schedule'
      && newMessage.notif_id !== null
    ) {
      editScheduledRequest({
        notif_id: newMessage.notif_id,
        notif_type: newMessage.notif_type,
        send_date: newMessage.send_date,
        send_time: newMessage.send_time,
        district_id: newMessage.district_id,
        check_zone: newMessage.check_zone,
        pickup_types: newMessage.pickup_types,
        zones: newMessage.zones,
        title: newMessage.title,
        description: newMessage.description,
        notif_msg: shorten_body(newMessage.description),
      });
    }
    setInvalidText({
      send_date: false,
      send_time: false,
      district_id: false,
      pickup_type: false,
      zones: false,
      title: false,
    });
    setDescriptionInvalidText(false);
    if (municipalities.length === 1) {
      handleChange(municipalities, 'district_id');
    }
  };

  const handleCancelClick = () => {
    resetFormAction();
    setInvalidText({
      send_date: false,
      send_time: false,
      district_id: false,
      pickup_type: false,
      zones: false,
      title: false,
    });
    setDescriptionInvalidText(false);
    if (municipalities.length === 1) {
      handleChange(municipalities, 'district_id');
    }
  };

  const handleDraftClick = () => {
    if (!allDisable) {
      if (newMessage.notif_id === null) {
        newDraftRequest({
          notif_type: newMessage.notif_type,
          send_date: newMessage.send_date,
          send_time: newMessage.send_time,
          district_id: newMessage.district_id,
          check_zone: newMessage.check_zone,
          pickup_types: newMessage.pickup_types,
          zones: newMessage.zones,
          title: newMessage.title,
          description: newMessage.description,
          notif_msg: shorten_body(newMessage.description),
        });
      } else {
        editDraftRequest({
          notif_id: newMessage.notif_id,
          notif_type: newMessage.notif_type,
          send_date: newMessage.send_date,
          send_time: newMessage.send_time,
          district_id: newMessage.district_id,
          check_zone: newMessage.check_zone,
          pickup_types: newMessage.pickup_types,
          zones: newMessage.zones,
          title: newMessage.title,
          description: newMessage.description,
          notif_msg: shorten_body(newMessage.description),
        });
      }
      setInvalidText({
        send_date: false,
        send_time: false,
        district_id: false,
        pickup_type: false,
        zones: false,
        title: false,
      });
      setDescriptionInvalidText(false);
      if (municipalities.length === 1) {
        handleChange(municipalities, 'district_id');
      }
    } else {
      updateNotificationRequest({
        notif_id: newMessage.notif_id,
        title: newMessage.title,
        description: newMessage.description,
      });
      setInvalidText({
        send_date: false,
        send_time: false,
        district_id: false,
        pickup_type: false,
        zones: false,
        title: false,
      });
      setDescriptionInvalidText(false);
    }
  };

  const disableButtons = newMessage.schedule === 'schedule'
    ? newMessage.district_id.length === 0
  || newMessage.send_date === ''
  || newMessage.send_time === ''
  || !(moment(`${newMessage.send_date}T${newMessage.send_time}`).format() > moment().format())
  || (newMessage.check_zone !== 'all-zones'
    && newMessage.pickup_type.length === 0)
  || (newMessage.pickup_type.length !== 0
    && newMessage.zones.length === 0)
  || (newMessage.title !== null && !newMessage.title.trim().length)
  || newMessage.description === ''
    : newMessage.district_id.length === 0
  || (newMessage.check_zone !== 'all-zones'
    && newMessage.pickup_type.length === 0)
  || (newMessage.pickup_type.length !== 0
    && newMessage.zones.length === 0)
  || (newMessage.title !== null && !newMessage.title.trim().length)
  || newMessage.description === '';

  return (
    <>
      {formLoading ? (
        <FormLoading />
      ) : (
        <div
          className={`h-100 d-flex flex-column justify-content-between ${classes.container}`}
        >
          <div>
            {municipalities.length > 1 && (
              <div className="mb-4">
                <MunicipalitySelector
                  isDisable={allDisable}
                  title={advocateAdmin !== true ? intl.formatMessage({ id: 'GENERAL.MUNICIPALITY' })
                    : intl.formatMessage({ id: 'GENERAL.WORKPLACE' })}
                  isMulti
                  disableClearable={false}
                  onChange={(data) => {
                    handleChange(data, 'district_id');
                    handleChange('all-zones', 'check_zone');
                    handleChange([], 'pickup_type');
                    handleChange([], 'zones');
                  }}
                  onFocus={() => {
                    if (invalidText.district_id === false) {
                      setInvalidText({ ...invalidText, district_id: true });
                    }
                  }}
                  value={newMessage.district_id}
                  invalidText={
                    invalidText.district_id
                    && !validation(newMessage.district_id, 'required')
                      ? intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
                      : null
                  }
                />
              </div>
            )}
            <div className="mb-4">
              <NewMessageSubheader
                title={intl.formatMessage({
                  id: 'NOTIFICATION.NEW_MESSAGE.MESSAGE_TYPE',
                })}
              />
              { advocateAdmin !== true && (
              <RadioButton
                isDisable={allDisable}
                firstLabel={intl.formatMessage({
                  id: 'NOTIFICATION.NEW_MESSAGE.RADIO.GENERAL',
                })}
                secondLabel={(
                  <div className="row d-flex align-items-center pl-3">
                    <EmergencyIcon
                      color={process.env.REACT_APP_COLOR_DELETE}
                      className={classes.emergency}
                    />
                    <Typography className={classes.nowrap} variant="h2">
                      {intl.formatMessage({
                        id: 'NOTIFICATION.NEW_MESSAGE.RADIO.EMERGENCY',
                      })}
                    </Typography>
                    <IconButton
                      tooltip={intl.formatMessage({
                        id: 'NOTIFICATION.NEW_MESSAGE.RADIO.EMERGENCY.TOOLTIP',
                      })}
                    >
                      <InfoIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                    </IconButton>
                  </div>
                )}
                firstLabelComment={intl.formatMessage({
                  id: 'NOTIFICATION.NEW_MESSAGE.RADIO.GENERAL.COMMENT',
                })}
                secondLabelComment={intl.formatMessage({
                  id: 'NOTIFICATION.NEW_MESSAGE.RADIO.EMERGENCY.COMMENT',
                })}
                firstValue="General"
                secondValue="Eme"
                tooltipText={intl.formatMessage({
                  id: 'NOTIFICATION.NEW_MESSAGE.RADIO.EMERGENCY.TOOLTIP',
                })}
                value={newMessage.notif_type}
                onChange={(data) => {
                  handleChange(data, 'notif_type');
                }}
              />
              )}
              <div className={`row p-0 ${advocateAdmin !== true ? 'mt-5' : 'mt-2'}`}>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <RadioButton
                    isDisable={allDisable}
                    firstLabel="Send Immediately"
                    secondLabel="Schedule for"
                    firstValue="immediately"
                    secondValue="schedule"
                    tooltipText={intl.formatMessage({
                      id: 'NOTIFICATION.NEW_MESSAGE.RADIO.EMERGENCY.TOOLTIP',
                    })}
                    value={newMessage.schedule}
                    onChange={(data) => {
                      handleChange(data, 'schedule');
                      if (newMessage.schedule === 'schedule') {
                        handleChange('', 'send_date');
                        handleChange('', 'send_time');
                      }
                    }}
                  />
                </div>
                {newMessage.schedule === 'schedule' && (
                  <div className="row col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 mt-xl-5 mt-md-5 mt-lg-5 mt-sm-0 mt-xs-0">
                    <div className="col-6 col-md-8 col-lg-8 col-xl-8">
                      <DatePicker
                        className="mr-2"
                        label={intl.formatMessage({ id: 'GENERAL.DATE' })}
                        value={
                          newMessage.send_date && newMessage.send_date !== ''
                            ? moment(`${newMessage.send_date}`)
                            : null
                        }
                        onChange={(data) => {
                          handleChange(
                            data ? moment(data).format('YYYY-MM-DD') : '',
                            'send_date'
                          );
                        }}
                        onFocus={() => {
                          if (invalidText.send_date === false) {
                            setInvalidText({ ...invalidText, send_date: true });
                          }
                        }}
                        invalidText={
                          invalidText.send_date
                          && !validation(newMessage.send_date, 'required')
                            ? intl.formatMessage({
                              id: 'VALIDATION.REQUIRED_FIELD',
                            })
                            : null
                        }
                        disabled={
                          newMessage.schedule === 'immediately' || allDisable
                        }
                      />
                    </div>
                    <div className="col-6 col-md-4 col-lg-4- col-xl-4">
                      <TimePicker
                        className="ml-2"
                        label={intl.formatMessage({ id: 'GENERAL.TIME' })}
                        value={
                          newMessage.send_time === null
                          || newMessage.send_time === ''
                            ? null
                            : newMessage.send_date === ''
                              ? moment(`${'2020-12-12'}T${newMessage.send_time}`)
                              : moment(
                                `${newMessage.send_date}T${newMessage.send_time}`
                              )
                        }
                        onChange={(data) => {
                          handleChange(
                            data ? `${moment(data).format('HH:mm')}:00` : '',
                            'send_time'
                          );
                        }}
                        onFocus={() => {
                          if (invalidText.send_time === false) {
                            setInvalidText({ ...invalidText, send_time: true });
                          }
                        }}
                        invalidText={
                          invalidText.send_time
                          && !validation(newMessage.send_time, 'required')
                            ? intl.formatMessage({
                              id: 'VALIDATION.REQUIRED_FIELD',
                            })
                            : !(moment(`${newMessage.send_date}T${newMessage.send_time}`).format() > moment().format())
                              ? intl.formatMessage({ id: 'VALIDATION.MIN.TIME', }) : null
                        }
                        disabled={
                          newMessage.schedule === 'immediately' || allDisable
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              { advocateAdmin !== true && (
              <NewMessageSubheader
                title={intl.formatMessage({
                  id: 'NOTIFICATION.NEW_MESSAGE.ZONE_SELECT.HEADER',
                })}
              />
              )}

              {advocateAdmin !== true && (
              <RadioButton
                row
                firstLabel={intl.formatMessage({
                  id: 'NOTIFICATION.NEW_MESSAGE.ZONE.SELECT.ALL_ZONE',
                })}
                secondLabel={intl.formatMessage({
                  id: 'NOTIFICATION.NEW_MESSAGE.ZONE.SELECT.SELECTED_ZONES',
                })}
                firstValue="all-zones"
                secondValue="selected-zone"
                value={newMessage.check_zone}
                onChange={(data) => {
                  handleChange(data, 'check_zone');
                  if (data === 'all-zones') {
                    handleChange([], 'pickup_type');
                    handleChange([], 'zones');
                  }
                }}
                isDisable={
                  newMessage.district_id.length !== 1
                  || (newMessage.district_id.length === 1
                    && newMessage.district_id[0].district_id
                      === '#all-districts#')
                  || allDisable
                }
              />
              )}
              {advocateAdmin !== true && newMessage.check_zone === 'selected-zone' && (
              <div className="row mb-3 mt-3 justify-content-center">
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <AutoComplete
                    isMulti
                    title={intl.formatMessage({
                      id: 'NOTIFICATION.NEW_MESSAGE.ZONE.SELECT.PICKED_TYPE',
                    })}
                    optionTitle="prompt_nm"
                    optionId="prompt_id"
                    options={prompts}
                    value={newMessage.pickup_type}
                    onChange={(data) => {
                      handleChange(data, 'pickup_type');
                      handleChange([], 'zones');
                    }}
                    onFocus={() => {
                      if (invalidText.pickup_type === false) {
                        setInvalidText({ ...invalidText, pickup_type: true });
                      }
                    }}
                    isDisable={
                        newMessage.check_zone === 'all-zones' || allDisable
                      }
                    invalidText={
                        invalidText.pickup_type
                        && !validation(newMessage.pickup_type, 'required')
                          ? intl.formatMessage({
                            id: 'VALIDATION.REQUIRED_FIELD',
                          })
                          : null
                      }
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6 mt-4 mt-xl-0 mt-md-0 mt-lg-0">
                  <AutoComplete
                    isMulti
                    title={intl.formatMessage({
                      id: 'NOTIFICATION.NEW_MESSAGE.ZONE.SELECT.PICKED_ZONE',
                    })}
                    optionTitle="zone_nm"
                    optionId="zone_id"
                    value={newMessage.zones}
                    options={zones.filter(
                      (zone) => newMessage.pickup_type.filter(
                        (item) => item.prompt_id === zone.prompt_id
                      ).length !== 0
                    )}
                    onChange={(data) => {
                      handleChange(data, 'zones');
                    }}
                    onFocus={() => {
                      if (invalidText.zones === false) {
                        setInvalidText({ ...invalidText, zones: true });
                      }
                    }}
                    isDisable={
                        newMessage.check_zone === 'all-zones'
                        || newMessage.pickup_type.length === 0
                        || allDisable
                      }
                    invalidText={
                        invalidText.zones
                        && !validation(newMessage.zones, 'required')
                          ? intl.formatMessage({
                            id: 'VALIDATION.REQUIRED_FIELD',
                          })
                          : null
                      }
                  />
                </div>
              </div>
              )}
            </div>
            <div className="mb-4">
              <NewMessageSubheader />
              <TextField
                label={intl.formatMessage({
                  id: 'NOTIFICATION.NEW_MESSAGE.MESSAGE.TITLE',
                })}
                value={newMessage.title}
                onChange={(data) => {
                  handleChange(data, 'title');
                }}
                onFocus={() => {
                  if (invalidText.title === false) {
                    setInvalidText({ ...invalidText, title: true });
                  }
                }}
                invalidText={
                  invalidText.title && !validation(newMessage.title, 'required')
                    ? intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
                    : null
                }
              />
              <div className="mt-3">
                <WysiwygEditor
                  value={newMessage.description}
                  onFocus={() => {
                    if (descriptionInvalidText === false) {
                      setDescriptionInvalidText(true);
                    }
                  }}
                  onChange={(data) => {
                    handleChange(data, 'description');
                  }}
                  isRequired={
                    descriptionInvalidText && newMessage.description === ''
                  }
                />
              </div>
            </div>
            <div className="mb-4">
              <NewMessageSubheader
                title={intl.formatMessage({
                  id: 'NOTIFICATION.SMS_MESSAGE.TITLE',
                })}
              />
              <Typography variant="bodySemiBold">
                {intl.formatMessage({ id: 'NOTIFICATION.SMS_MESSAGE.NOTE' })}
              </Typography>
            </div>
          </div>
          <ButtonSection
            scheduled={newMessage.schedule}
            isDisable={disableButtons}
            isDisableDraft={newMessage.district_id.length === 0}
            saveAction={handleSaveClick}
            cancelAction={handleCancelClick}
            draftAction={handleDraftClick}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (store) => ({
  newMessage: store.notification.newMessage,
  municipalities: store.common.municipalities,
  zones: store.notification.zones,
  userName: store.auth.user_info.user_name,
  prompts: store.notification.prompts,
  formLoading: store.notification.formLoading,
  fetchState: store.notification.fetchState,
  cat: store.notification.cat,
  advocateAdmin: store.auth.advocateAdmin
});

export default injectIntl(connect(mapStateToProps, actions)(CustomForm));
