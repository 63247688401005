import * as React from 'react';

const WrongAnswerIcon = ({ width = 15, height = 15, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    className={className}
  >
    <path
      data-name="Path 255"
      d="M10 0A10 10 0 000 10a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0010 0zm3.834 14.961l-3.825-3.792-3.8 3.83-1.175-1.17 3.79-3.827-3.827-3.794 1.171-1.171 3.823 3.787L13.778 5l1.18 1.169-3.788 3.823 3.827 3.79z"
      fill="#fe1a20"
    />
  </svg>
);
export default WrongAnswerIcon;
