/* eslint-disable import/prefer-default-export */
import cts from '../../crud/cts';

/**
 * TODO: Insert comment
 */

export const getDepots = async ({ locality }) => cts(
  'post',
  'depot/get-all',
  {
    cms_locality_id: locality,
  }
);

export const getDepotDetails = async ({ locality, depotId }) => cts(
  'post',
  'depot/get-one',
  {
    cms_locality_id: locality,
    drop_depot_id: depotId
  }
);

export const deleteDepot = async ({ locality, depotId }) => cts(
  'post',
  'depot/remove',
  {
    cms_locality_id: locality,
    drop_depot_id: depotId
  }
);

export const newDepot = async ({
  data
}) => cts(
  'post',
  'depot/new',
  {
    cms_locality_id: data.locality,
    geo_loc: data.geoLocation,
    drop_depot_admin_nm: data.name,
    depot_nm: data.name,
    depot_details: data.details,
    display_addr: data.address,
    phone_nbr: data.phone,
    alt_phone_nbr: data.altPhone,
    email_addr: data.email,
    web_addr: data.website,
    drop_depot_seasons: data.seasons,
    is_open_all_time: data.open24
  }
);

export const editDepot = async ({
  data
}) => cts(
  'post',
  'depot/edit',
  {
    cms_locality_id: data.locality,
    drop_depot_id: data.id,
    geo_loc: data.geoLocation,
    depot_nm: data.name,
    depot_details: data.details,
    display_addr: data.address,
    phone_nbr: data.phone,
    alt_phone_nbr: data.altPhone,
    email_addr: data.email,
    web_addr: data.website,
    drop_depot_seasons: data.seasons,
    is_open_all_time: data.open24
  }
);

export const getLatLng = async ({ address }) => cts(
  'get',
  `geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
  {},
  {},
  false
);

export const getAddress = async ({ latlng }) => cts(
  'get',
  `geocode/json?latlng=${latlng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
  {},
  {},
  false
);
