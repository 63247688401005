import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, CustomButton, Dialog
} from '../../components';
import { PhoneIcon, LocationIcon } from '../../../assets/icons';
import * as actions from '../redux/actions';
import { removeZeroFromTime, transformToAmpm } from '../../../utils';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    maxHeight: '500px',
    overflow: 'auto',
    flexDirection: 'column',
  },
  dropdownItem: {
    padding: '0 !important',
  },
  header: {
    width: '100%',
    height: '110px',
    backgroundColor: process.env.REACT_APP_COLOR_PREVIEW_HEADER,
    display: 'flex',
    alignItems: 'center',
    padding: '25px'
  },
  content: {
    width: '100%',
    padding: '25px',
  },
  date: {
    marginBottom: '5px',
    paddingLeft: '25px',
  },
  seasons: {
    marginBottom: '5px',
    padding: '25px',
    paddingTop: '0px'
  },
  address: {
    width: '100%',
    display: 'flex',
    padding: '25px',
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  phone: {
    width: '100%',
    display: 'flex',
    padding: '25px',
  },
  locationIcon: {
    width: '25px',
    height: '25px',
    marginRight: '25px'
  },
  phoneIcon: {
    width: '15px',
    height: '15px',
    marginRight: '35px',
    marginLeft: '5px',
  },
  marker: {
    width: '50px',
    height: '50px',
    position: 'absolute',
    top: '35%',
    left: '45%'
  }
}));

const Preview = ({
  intl,
  event,
}) => {
  const classes = useStyles();

  const days = [
    intl.formatMessage({ id: 'GENERAL.SUNDAY' }),
    intl.formatMessage({ id: 'GENERAL.MONDAY' }),
    intl.formatMessage({ id: 'GENERAL.TUESDAY' }),
    intl.formatMessage({ id: 'GENERAL.WEDNESDAY' }),
    intl.formatMessage({ id: 'GENERAL.THURSDAY' }),
    intl.formatMessage({ id: 'GENERAL.FRIDAY' }),
    intl.formatMessage({ id: 'GENERAL.SATURDAY' }),
  ];

  return (
    <Dialog
      hasButtons={false}
      disableBackdropClick={false}
      button={(
        <CustomButton
          className={classes.dropdownItem}
          onClick={() => { }}
          label={(
            <Typography
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'CATEGORY.MENU.PREVIEW' })}
            </Typography>
          )}
        />
      )}
      title={(
        <Typography variant="h1">
          {intl.formatMessage({ id: 'GENERAL.PREVIEW' })}
        </Typography>
      )}
      contentClassName="p-0"
      content={(
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography
              color={process.env.REACT_APP_COLOR_SECONDARY}
              variant="h1"
            >
              {intl.formatMessage({ id: 'DEPOT.PREVIEW.HEADER' })}
            </Typography>
          </div>
          <div className={classes.content}>
            <Typography
              variant="h1"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: event.event_nm
                }}
              />
            </Typography>
            <Typography
              variant="body"
              className="mt-2"
              color={process.env.REACT_APP_COLOR_TEXT}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: event.event_details
                }}
              />
            </Typography>
          </div>

          { event.event_dates
            && !!event.event_dates.length
            && event.event_dates
              .map((item, index) => (
                <div
                  key={`${item.date}_plans_${String(index)}`}
                  className={classes.date}
                >
                  <div className="d-flex justify-content-between">
                    <Typography
                      variant="h2Medium"
                      color={process.env.REACT_APP_COLOR_TEXT}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.date
                        }}
                      />
                    </Typography>
                  </div>
                </div>
              ))}
          { !!event.drop_event_loc_ids.length
            && event.drop_event_loc_ids
              .map((item, index) => (
                <div
                  key={`${item.drop_event_loc_id}_plans_${String(index)}`}
                  className={classes.seasons}
                >
                  <div className="d-flex justify-content-between pt-3">
                    <Typography
                      variant="h2Medium"
                      color={process.env.REACT_APP_COLOR_TEXT}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.event_loc_admin_nm ? item.event_loc_admin_nm : ''
                        }}
                      />
                    </Typography>
                  </div>
                  { !!item.drop_event_loc_hours.length
                    && item.drop_event_loc_hours
                      .map((item, index) => {
                        if (item.hours_lower_tm
                             && item.hours_upper_tm
                             && !!item.hours_lower_tm.length
                             && !!item.hours_upper_tm.length) {
                          return (
                            <div key={`${item}_is_${String(index)}`} className="d-flex mt-2">
                              <Typography
                                color={process.env.REACT_APP_COLOR_TEXT}
                                variant="body"
                              >
                                {days[item.dow_nbr]}
                                {': '}
                              </Typography>
                              <div className="d-flex ml-1">
                                <Typography
                                  color={process.env.REACT_APP_COLOR_TEXT}
                                  variant="body"
                                >
                                  {item.hours_lower_tm ? removeZeroFromTime(transformToAmpm(item.hours_lower_tm)) : ''}
                                  {item.hours_lower_tm && item.hours_upper_tm && '-'}
                                  {item.hours_upper_tm ? removeZeroFromTime(transformToAmpm(item.hours_upper_tm)) : ''}
                                </Typography>
                                {(item.add_hours_lower_tm || item.add_hours_upper_tm)
                                  && (
                                  <Typography
                                    className="ml-1"
                                    variant="body"
                                    color={process.env.REACT_APP_COLOR_TEXT}
                                  >
                                    {', '}
                                    {item.add_hours_lower_tm ? removeZeroFromTime(transformToAmpm(item.add_hours_lower_tm)) : ''}
                                    {item.add_hours_lower_tm && item.add_hours_upper_tm && '-'}
                                    {item.add_hours_upper_tm ? removeZeroFromTime(transformToAmpm(item.add_hours_upper_tm)) : ''}
                                  </Typography>
                                  )}
                              </div>
                            </div>
                          );
                        }
                      })}
                </div>
              ))}
          { !!event.drop_event_loc_ids.length
            && (
            <div className="position-relative">
              <div
                style={{
                  width: '100%',
                  height: '120px',
                  marginTop: '10px',
                  backgroundImage: `url(http://maps.google.com/maps/api/staticmap?center=${event.drop_event_loc_ids[0].geo_loc.slice(1, event.drop_event_loc_ids[0].geo_loc.length - 1)}6&zoom=13&size=400x150&sensor=false&key=${process.env.REACT_APP_GOOGLE_API_KEY})`
                }}
              />
              <LocationIcon
                className={classes.marker}
              />
            </div>
            )}
          {!!event.drop_event_loc_ids.length
            && (
            <div className={classes.address}>
              <LocationIcon
                color={process.env.REACT_APP_COLOR_TEXT}
                className={classes.locationIcon}
              />
              <Typography
                color={process.env.REACT_APP_COLOR_TEXT}
                variant="h2Medium"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: event.drop_event_loc_ids.length
                      ? event.drop_event_loc_ids[0].display_addr : ''
                  }}
                />
              </Typography>
            </div>
            )}
          <div className={classes.phone}>
            <PhoneIcon
              color={process.env.REACT_APP_COLOR_TEXT}
              className={`mb-3 ${classes.phoneIcon}`}
            />
            {!!event.drop_event_loc_ids.length
              && (
              <Typography
                color={process.env.REACT_APP_COLOR_TEXT}
                variant="h2Medium"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: event.drop_event_loc_ids[0].phone_nbr
                  }}
                />
              </Typography>
              )}
          </div>
        </div>
      )}
    />
  );
};

export default injectIntl(connect(null, actions)(Preview));
