import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { injectIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';

import { LoadingSpinner, ErrorContainer } from '../../../components/loading';

import { Opacity, Typography, CustomButton } from '../../../components';
import { BlogCard } from '../components';
import * as actions from './redux/actions';
import * as sharedActions from '../../../redux/actions';




const useStyles = makeStyles(() => ({
  // container: {
  //   paddingTop: 20,
  //   letterSpacing: 0.7
  // },

}));
const BlogsLibrary = ({
  intl,
  setDistrictAction,
  getAllEducationBlogsLibraryRequest,
  district,
  blogs,
  loading,
  municipalities,
  error
}) => {

  const classes = useStyles();


  useEffect(() => {
    if (district.district_id === '#all-districts#') {
      setDistrictAction(municipalities[0]);
    } else {
      const [projectId, districtId] = district.district_id.split('#$#');
      getAllEducationBlogsLibraryRequest(projectId, districtId);
    }
  }, [district]);


  return (
    <>
      <Opacity slide>
        <Typography className="mb-4" variant="h1">
          {intl.formatMessage({ id: 'EDUCATION.BLOG.LIBRARY' })}
        </Typography>

        <Typography color="#404040" className="mb-4" variant="educationBody">
          {intl.formatMessage({ id: 'EDUCATION.BLOG.PROMO.1' })}
        </Typography>

        <Typography color="#404040" className="mb-5" variant="educationBody">
          {intl.formatMessage({ id: 'EDUCATION.BLOG.PROMO.2' })}
        </Typography>

        <Typography color="#6C7293" variant="h2">
          {intl.formatMessage({ id: 'EDUCATION.ARTICLES' })}
        </Typography>

        {loading ? <LoadingSpinner height={430} /> : error ? (
          <ErrorContainer
            small
            reportName="Blogs Library"
            height={430}
            error={error}
          />
        ) : (
          <div className="d-flex flex-wrap mt-3">
            {blogs.map((item) => (
              <BlogCard key={item.id} blogsLibrary isDeletable={false} data={item} showLastFeatured={true} />
            ))}
          </div>)}
      </Opacity>
    </>

  );
}

const mapStateToProps = (store) => ({
  district: store.common.district,
  municipalities: store.common.municipalities,
  blogs: store.educationBlogsLibrary.blogs,
  loading: store.educationBlogsLibrary.loading,
  error: store.educationBlogsLibrary.error,
});

export default injectIntl(connect(mapStateToProps, { ...actions, ...sharedActions })(BlogsLibrary));
