import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  AutoComplete
} from '../../components';
import * as actions from '../redux/actions';
import { getUniqueCollections } from '../helper';

const useStyles = makeStyles((theme) => ({
  zoneContainer: {
    display: 'flex',
    width: '60%',
    justifyContent: 'flex-end',
    gap: 20
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    margin: '20px 0px',
    alignItems: 'center'
  },
  zoneContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '70%',
  },
  autoCompleteWrapper: {
    flex: '1 1 25%', // Each AutoComplete takes up 25% of the row
    boxSizing: 'border-box',
    padding: '0 8px', 
    margin: '8px 0px'
  },
}));

const Header = ({
  intl,
  getZoneRequest,
  allZones,
  prompts,
  municipalities,
  getAllScheduleRequest,
  getPerpetualSchedulesHolidaysRequest,
  district,
  scheduleData,
  handleCollectionChange,
  fetchState
}) => {
  const classes = useStyles();
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState({});
  const [prompt, setPrompt] = useState();
  const [collections, setCollections] = useState([]);
  const [changingZone, setChangingZone] = useState(false);
  const allPrompt = {
    prompt_id: 'all',
    prompt_nm: 'All'
  };

  useEffect(() => {
    if (district !== null && district.district_id !== '#all-districts#') {

      getZoneRequest(district.district_id);
      const project_id_value = (district.district_id).split('#$#')[0];
      const district_id_value = (district.district_id).split('#$#')[1];
      getPerpetualSchedulesHolidaysRequest(project_id_value, district_id_value);
    }
    setZones([]);
    setCollections([]);
    setSelectedZone({});
    setPrompt(null);
  }, [district]);

  useEffect(() => {
    if (allZones && allZones.length) {
      setZones(allZones)
    }
  }, [allZones]);

  useEffect(() => {
    if (prompts && prompts.length && scheduleData && scheduleData.length && fetchState === 'succeed-request') {
      const uniqueCollection = getUniqueCollections(scheduleData);
      if (uniqueCollection.length === 1) {
        if (!prompt) {
          setPrompt(allPrompt)
        }
        setCollections(uniqueCollection);
      } else {
        setCollections([allPrompt, ...uniqueCollection]);
        if (!prompt) {
          setPrompt(allPrompt)
        }
      }

    }
  }, [prompts, scheduleData, fetchState]);

  useEffect(() => {
    if (zones && zones.length) {
      const allZoneIds = zones.map((zone) => zone.zone_id).filter((zone) => zone !== 'all');

      const selectedZoneIds = Object.values(selectedZone).flat().map(zone => zone.zone_id);
      getAllScheduleRequest({
        project_id: (district.district_id).split('#$#')[0],
        district_id: (district.district_id).split('#$#')[1],
        zones: selectedZoneIds.length ? selectedZoneIds : allZoneIds,
      })
    }
  }, [zones, selectedZone]);

  useEffect(() => {
    if (prompt) {
      prepareZones();
    }
  }, [prompt]);

  const sortZonesByName = (zones) => {
    const dayOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'];

    return [...zones].sort((a, b) => {
      const getDayIndex = (zoneName) => {
        for (let i = 0; i < dayOrder.length; i++) {
          const day = dayOrder[i];
          const exactMatchRegex = new RegExp(`^${day}$`, 'i');
          const variationRegex = new RegExp(`\\b${day}\\b`, 'i');
          if (exactMatchRegex.test(zoneName)) {
            return { index: i, exact: true };
          } else if (variationRegex.test(zoneName)) {
            return { index: i, exact: false };
          }
        }
        return { index: -1, exact: false };
      };

      const aDay = getDayIndex(a.zone_nm);
      const bDay = getDayIndex(b.zone_nm);

      if (aDay.index !== -1 && bDay.index !== -1) {
        if (aDay.index === bDay.index) {
          return aDay.exact === bDay.exact ? 0 : aDay.exact ? -1 : 1;
        }
        return aDay.index - bDay.index;
      }
      if (aDay.index !== -1) {
        return -1;
      }
      if (bDay.index !== -1) {
        return 1;
      }
      return a.zone_nm.localeCompare(b.zone_nm);
    });
  };

  const prepareZones = () => {
    if (prompt && prompt.prompt_id !== 'all') {
      const zonesByPrompt = allZones.filter((zone) => zone.prompt_id === prompt.prompt_id);
      const sortedZones = sortZonesByName(zonesByPrompt);
      handleCollectionChange(prompt);

      // Set the selected zone to be the first zone with prompt_id as the key
      if (sortedZones.length > 0) {
        setSelectedZone({ [prompt.prompt_id]: sortedZones[0] });
      }
    } else if (prompt && prompt.prompt_id === 'all' && !changingZone) {
      const sortedZones = sortZonesByName(allZones);

      // Select the first zone for each prompt and use prompt_id as the key
      const prompts = [...new Set(allZones.map(zone => zone.prompt_id))];
      const selectedZones = prompts.reduce((acc, promptId) => {
        const zonesForPrompt = sortedZones.filter(zone => zone.prompt_id === promptId);
        if (zonesForPrompt.length > 0) {
          acc[promptId] = zonesForPrompt[0];
        }
        return acc;
      }, {});
      setSelectedZone(selectedZones);
      setZones(sortedZones);
    } else if (prompt && prompt.prompt_id === 'all' && changingZone) {
      setChangingZone(false);
    }
  };

  return (
    <>
      <div className={classes.topContainer}>
        {
          municipalities && (
            <div className={classes.zoneContainer}>
              {collections && collections.length && (
                <div className={classes.autoCompleteWrapper} style={{ maxWidth: prompts.length >=3 ? '25%' : null}} >
                  <AutoComplete
                    title="Collections/Events"
                    isDisable={district === null || allZones === []}
                    className="w-100"
                    disableClearable={false}
                    optionTitle="prompt_nm"
                    optionId="prompt_id"
                    value={prompt}
                    options={collections}
                    onChange={(data) => {
                      if (data) {
                        setPrompt(data);
                      } else {
                        setPrompt(allPrompt);
                      }
                    }}
                  />
                </div>
              )}
              {allZones && allZones.length && zones && zones.length && prompts && prompts.length && selectedZone && Object.keys(selectedZone).length && (
                prompts.map((prompt) => {
                  const filteredZones = prompt.prompt_id === 'all' ? zones : zones.filter(zone => zone.prompt_id === prompt.prompt_id);
                  return (
                    <div key={prompt.prompt_id} className={classes.autoCompleteWrapper} style={{ maxWidth: prompts.length >=3 ? '25%' : null}}>
                      <AutoComplete
                        title={`${prompt.prompt_nm}`}
                        isDisable={district === null || filteredZones.length === 0}
                        className="w-100"
                        disableClearable={false}
                        optionTitle="zone_nm"
                        optionId="zone_id"
                        value={selectedZone[prompt.prompt_id] || ''}
                        options={filteredZones}
                        onChange={(data) => {
                          if (data) {
                            if (collections.length > 1) {
                              setPrompt(allPrompt);
                            }
                            setChangingZone(true);
                            setSelectedZone(prevSelectedZone => ({
                              ...prevSelectedZone,
                              [prompt.prompt_id]: data
                            }));
                          }
                        }}
                      />
                    </div>
                  );
                })
              )}
            </div>
          )
        }
      </div>
    </>

  );
};

const mapStateToProps = (store) => ({
  allZones: store.schedule.zones,
  prompts: store.schedule.prompts,
  municipalities: store.common.municipalities,
  district: store.common.district,
  scheduleData: store.schedule.data,
  fetchState: store.schedule.fetchState,
});

export default injectIntl(connect(mapStateToProps, actions)(Header));
