import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';

/**
 * TODO LIST
 *
 *   1. Replace loadings with react-content-loader
 *   2. Implement Campaign logic to show or hide the campaign report
 *   3. Implement Campaign report stat box
 *   4. Implement the custom message box logic
 *   5. Fix user details box ui
 *   6. Wider container for boxes
 *   7. Code documentations
 *
 * TODO LIST
 */

/**
 * Components
 */
import {
  StatCard,
  Campaign,
  Users,
} from './components';
import {
  useDashboardInfo,
  useUpcomingCampaigns,
} from './hooks';

const ReportDashboard = ({
  municipalityFetchState,
  district,
  fromDate,
  toDate,
  intl,
  county,
  isCounty,
  hasCounty,
  advocateAdmin
}) => {
  /**
     * Get interactions report
     */
  const {
    dashboardDetails,
    error: dashboardInfoError,
  } = useDashboardInfo(district?.district_id, fromDate, toDate,
    municipalityFetchState, county?.county_id, isCounty, hasCounty);

  /**
     * Get upcoming campaigns
     */
  const {
    campaigns,
    error: campaignError,
  } = useUpcomingCampaigns(district);

  /**
     * Get card stat based on three states
     *
     * @param {number} stat Stat number 0 | 1 | -1
     */
  const getStat = (stat) => {
    switch (stat) {
      case 0:
        return 'neutral';
      case 1:
        return 'positive';
      case -1:
        return 'negative';

      default:
        return 'neutral';
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12 col-centered">
          <div className="row">
            {/* <div className="col-xl-9 col-md-8 col-sm-6 col-xs-12">
              <Welcome />
            </div> */}
            {/* <div className="col-xl-3 col-md-4 col-sm-6 col-xs-12">
              {
                district.district_id
                  ? (
                    <MessageCard
                      title={intl.formatMessage({ id: 'DASHBOARD.MESSAGE_CARD.TITLE' })}
                      description={intl.formatMessage({ id: 'DASHBOARD.MESSAGE_CARD.DESCRIPTION' })}
                      linkDescription={intl.formatMessage({ id: 'DASHBOARD.MESSAGE_CARD.LINK' })}
                      linkUrl="https://vimeo.com/452273039/3d123230a8"
                    />
                  ) : null
              }
            </div> */}
          </div>
          <div className="row">
            <div className="col-9">
              <Users
                isLoading={!dashboardDetails}
                advocateAdmin={advocateAdmin}
                data={dashboardDetails && !dashboardInfoError ? {
                  calendars: dashboardDetails.calendars.total,
                  communications: dashboardDetails.communications.total,
                  web_app: dashboardDetails.web_app.total,
                  mobile: dashboardDetails.mobile.total
                } : {}}
              />

            </div>
            <div className="col-3">
              {/* Interactions */}
              {advocateAdmin !== true && (
                <StatCard
                  isLoading={dashboardDetails === null}
                  title={intl.formatMessage({ id: 'REPORT.GENERAL.INTERACTIONS' })}
                  typeTitle={fromDate === moment().subtract(1, 'months').format('YYYY-MM-DD') && toDate === moment().format('YYYY-MM-DD')
                    ? intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_30_PERIOD' })
                    : intl.formatMessage({ id: 'DASHBOARD.STAT_CARD.SELECTED_PERIOD' })}
                  stat={dashboardDetails !== null && dashboardDetails.interactions.total}
                  data={dashboardDetails !== null && dashboardDetails.interactions}
                  type={dashboardDetails !== null && getStat(dashboardDetails.interactions.growth)}
                  link={`/report/interaction/${district.district_id.replace('#$#', '-')}/${district.district_nm}`}
                />
              )}

              {/* New subscribers && Campaign */}
              <StatCard
                isLoading={dashboardDetails === null}
                title={
                  dashboardDetails !== null && ('new_subscribers' in dashboardDetails
                    ? intl.formatMessage({ id: 'REPORT.GENERAL.NEW_SUBSCRIBERS' })
                    : intl.formatMessage({ id: 'MENU.REPORTS.CAMPAIGN' }))
                }
                typeTitle={
                  ((dashboardDetails !== null && ('new_subscribers' in dashboardDetails)
                    && fromDate === moment().subtract(1, 'months').format('YYYY-MM-DD')
                    && toDate === moment().format('YYYY-MM-DD'))
                    && intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_30_PERIOD' }))

                  || ((dashboardDetails !== null && ('new_subscribers' in dashboardDetails)
                    && (fromDate !== moment().subtract(1, 'months').format('YYYY-MM-DD')
                      || toDate !== moment().format('YYYY-MM-DD')))
                    && intl.formatMessage({ id: 'DASHBOARD.STAT_CARD.SELECTED_PERIOD' }))

                  || (dashboardDetails !== null && ('campaign' in dashboardDetails)
                    && intl.formatMessage({ id: 'CAMPAIGN.ITEM.PARTICIPATION_RATE_DASHBOARD' }))
                }
                stat={
                  dashboardDetails !== null && ('new_subscribers' in dashboardDetails ? dashboardDetails.new_subscribers.total
                    : dashboardDetails.campaign.total)
                }
                data={
                  dashboardDetails !== null && ('new_subscribers' in dashboardDetails ? dashboardDetails.new_subscribers
                    : dashboardDetails.campaign)
                }
                type={dashboardDetails !== null && ('new_subscribers' in dashboardDetails
                  ? getStat(dashboardDetails.new_subscribers.growth)
                  : getStat(dashboardDetails.campaign.growth))}
                link={
                  dashboardDetails !== null && ('new_subscribers' in dashboardDetails
                    ? `/report/new-subscribers/${district.district_id.replace('#$#', '-')}/${district.district_nm}`
                    : `/report/campaign/${district.district_id.replace('#$#', '-')}/${district.district_nm}`)
                }
              />

              {/* Materials */}
              <StatCard
                isLoading={dashboardDetails === null}
                title={intl.formatMessage({ id: 'REPORT.INTERACTIONS.DETAIL.CATEGORIES.MATERIAL.TITLE' })}
                typeTitle={fromDate === moment().subtract(1, 'months').format('YYYY-MM-DD') && toDate === moment().format('YYYY-MM-DD')
                  ? intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_30_PERIOD' })
                  : intl.formatMessage({ id: 'DASHBOARD.STAT_CARD.SELECTED_PERIOD' })}
                stat={
                  dashboardDetails !== null && dashboardDetails.material_search
                  && dashboardDetails.material_search.total
                }
                data={
                  dashboardDetails !== null && dashboardDetails.material_search
                  && dashboardDetails.material_search
                }
                link={`/report/material/${district.district_id.replace('#$#', '-')}/${district.district_nm}`}
                noneArrow
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              {
                advocateAdmin !== true
                  && campaigns
                  && !campaignError
                  && campaigns.length
                  ? (
                    <Campaign
                      campaignInfo={campaigns[0]}
                    />
                  ) : null
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

/**
 * Map state to props
 */
const mapStateToProps = (store) => ({
  municipalityFetchState: store.common.fetchState,
  district: store.common.district,
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
  isCounty: store.common.isCounty,
  hasCounty: store.auth.user_info.counties,
  county: store.common.county,
  advocateAdmin: store.auth.advocateAdmin
});

export default injectIntl(
  connect(
    mapStateToProps
  )(ReportDashboard)
);
