import { put, call, takeLatest } from 'redux-saga/effects';
import actionTypes from './actionTypes';
import * as actions from './actions';
import { getRecyclingFundamentalsData, getMisunderstoodMaterialsData } from '../apis';

function* getRecyclingFundamentalsDataSaga({ payload: { project_id, district_id } }) {
  const response = yield call(getRecyclingFundamentalsData, { project_id, district_id });
  try {
    yield put(actions.getRecyclingFundamentalsDataReceiveAction(response));
  } catch (error) {
    yield put(actions.getRecyclingFundamentalsFailAction(response.error.detail.data));
  }
}

function* getMisunderstoodMaterialsDataSaga({ payload: { project_id, district_id } }) {
  const response = yield call(getMisunderstoodMaterialsData, { project_id, district_id });
  try {
    yield put(actions.getMisunderstoodMaterialsDataReceiveAction(response.data));
  } catch (error) {
    yield put(actions.getMisunderstoodMaterialsFailAction(response.error.detail.data));
  }
}

function* recyclingFundamentalsSaga() {
  yield takeLatest(actionTypes.GET_RECYCLING_FUNDAMENTALS_DATA_REQUEST, getRecyclingFundamentalsDataSaga);
  yield takeLatest(actionTypes.GET_MISUNDERSTOOD_MATERIALS_DATA_REQUEST, getMisunderstoodMaterialsDataSaga);
}

export default recyclingFundamentalsSaga;
