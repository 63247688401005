import React from 'react';
import { DatePicker } from 'antd';

const CustomDatePicker = ({
  setDate, style = {}, placeholder, ...props
}) => {
  const onChange = (_, dateString) => {
    setDate(dateString);
  };
  return (
    <DatePicker
      {...props}
      style={style}
      placeholder={placeholder}
      showToday={false}
      onChange={onChange}
    />
  );
};

export default CustomDatePicker;
