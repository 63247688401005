import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../redux/createReducer';
import initialState from './store';

const WGWHomeReducer = persistReducer(
  {
    storage, key: 'WGWHome', whitelist: [], blacklist: ['responseStatus']
  },
  createReducer(initialState, {
    SEND_SUBMITTED_PHOTO_REQUEST: (state) => Immutable({
      ...state,
      loading: true,
      error: null,
      responseStatus: null
    }),
    SEND_SUBMITTED_PHOTO_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      responseStatus: payload.status
    }),
    SEND_SUBMITTED_PHOTO_FAIL: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      error: payload.message,
      responseStatus: payload.status
    }),
    SEND_SUGGESTED_NEW_TERM_REQUEST: (state) => Immutable({
      ...state,
      loading: true,
      error: null,
      responseStatus: null
    }),
    SEND_SUGGESTED_NEW_TERM_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      responseStatus: payload.status,
      ...payload
    }),
    SEND_SUGGESTED_NEW_TERM_FAIL: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      error: payload.message,
      responseStatus: payload.status
    }),
  })
);

export default WGWHomeReducer;
