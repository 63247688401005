import React from 'react';
import { connect } from 'react-redux';

import { Summary, Detail } from './components';
import * as actions from './redux/actions';

const MaterialReport = ({
  data,
}) => (
  <div id="material-report">
    <Summary
      data={data.summary}
    />
    <Detail
      data={data.detail}
    />
  </div>
);

const mapStateToProps = (store) => ({
  data: store.materialReport.data,
});

export default connect(mapStateToProps, actions)(MaterialReport);
