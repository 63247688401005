import React from 'react';
import {
  Document, Page, Text, View, StyleSheet, Image
} from '@react-pdf/renderer';
import { addCommasToNumber } from '../../../../utils';
import { toAbsoluteUrl } from '../../../../../_metronic';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    paddingVertical: 5
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  summaryHead: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    margin: 10,
    marginBottom: 0,
    paddingHorizontal: '20pt',
  },
  headerText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'black',
  },
  subTitleText: {
    fontSize: 12,
    color: 'black'
  },
  marginLeft: {
    marginLeft: 15
  },
  h1: {
    fontSize: '22pt',
    fontWeight: 'bold',
  },
  h2: {
    fontSize: '20pt'
  },
  h3: {
    fontSize: '18pt'
  },
  h4: {
    fontSize: '18pt'
  },
  h5: {
    fontSize: '16pt'
  },
  h6: {
    fontSize: '14pt'
  },
  body: {
    fontSize: '12pt'
  },
  circle: {
    width: '10pt',
    height: '10pt',
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY,
    borderRadius: '100pt',
    marginRight: '10pt',
  },
  image: {
    width: '110px',
    height: '40px',
    marginLeft: '25px',
    marginTop: '5px',
  }
});

// Create Document Component
const ReportPDF = ({
  interactionAtAGlance,
  currentPeriod,
  date,
  reportingDistrict,
  detailData,
}) => (
  <Document>
    {/* summary 's PDF */}
    <Page size="A4" orientation="landscape" style={styles.page}>
      <Image
        style={styles.image}
        src={`${toAbsoluteUrl('/media/logos/RCLogo.png')}`}
      />
      <View style={[styles.summaryHead, { maxHeight: 40, justifyContent: 'space-between' }]}>
        <View style={{ flexGrow: '3', flexDirection: 'row' }}>
          <Text style={styles.headerText}>
            {interactionAtAGlance}
          </Text>
          <Text style={[styles.subTitleText, styles.marginLeft,
            {
              color: process.env.REACT_APP_COLOR_DARK_GRAY,
            }
          ]}
          >
            {currentPeriod}
          </Text>
          <Text style={[styles.subTitleText, styles.marginLeft,
            { color: process.env.REACT_APP_COLOR_PRIMARY }]}
          >
            {date}
          </Text>
        </View>
        <View style={{ paddingRight: 10 }}>
          <Text style={[styles.subTitleText]}>
            {reportingDistrict}
          </Text>
        </View>
      </View>

      <View style={{
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 30,
        marginVertical: 20
      }}
      >
        <View style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
            <View style={[styles.circle, {
              backgroundColor: process.env.REACT_APP_COLOR_REPORT_1,
            }]}
            />
            <Text>
              Current Period
            </Text>
          </View>

          <View style={[{ flexDirection: 'row', marginLeft: 0, justifyContent: 'flex-start' }]}>
            <Text
              style={[{
                fontSize: 30,
                fontWeight: 'bold',
                color: process.env.REACT_APP_COLOR_REPORT_1
              }]}
            >
              {addCommasToNumber(detailData.total.current_period)}
            </Text>
          </View>
        </View>

        <View style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
            <View style={[styles.circle, {
              backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_LIGHT,
            }]}
            />
            <Text>
              Previous Period
            </Text>
          </View>

          <View
            style={[{
              fontSize: 30,
              fontWeight: 'bold',
              flexDirection: 'row',
              justifyContent: 'flex-start'
            }]}
          >
            <Text style={[{ color: process.env.REACT_APP_COLOR_PRIMARY_LIGHT }]}>
              {addCommasToNumber(detailData.total.previous_period)}
            </Text>
          </View>
        </View>

        <View style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
            <View style={[styles.circle, {
              backgroundColor: process.env.REACT_APP_COLOR_NEGATIVE,
            }]}
            />
            <Text>
              Current Period Last Year
            </Text>
          </View>
          <View
            style={[{
              fontSize: 30,
              fontWeight: 'bold',
              flexDirection: 'row',
              justifyContent: 'flex-start'
            }]}
          >
            <Text style={[{ color: process.env.REACT_APP_COLOR_NEGATIVE }]}>
              {addCommasToNumber(detailData.total.last_year)}
            </Text>
          </View>
        </View>

      </View>

      <View style={{
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        paddingHorizontal: 30,
        marginBottom: 20
      }}
      >
        <View
          style={{
            width: '100%',
            minHeight: 30,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginVertical: 10,
            paddingHorizontal: 5,
            backgroundColor: '#aaaaaa',
          }}
        >
          <View
            style={{
              width: '50%',
            }}
          >
            <Text style={[styles.h5]}>
              Collections
            </Text>
          </View>

          <View style={{
            width: '50%',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          >
            <View style={{
              width: '33%',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
            >
              <Text style={[styles.h5]}>
                Current Period
              </Text>
            </View>

            <View style={{
              width: '33%',
              flexDirection: 'row',
              alignItems: 'center',
              paddingLeft: 14
            }}
            >
              <Text style={[styles.h5]}>
                Previous Period
              </Text>
            </View>

            <View style={{
              width: '33%',
              flexDirection: 'row',
              alignItems: 'center',
              paddingLeft: 30
            }}
            >
              <Text style={[styles.h5]}>
                Last Year
              </Text>
            </View>

          </View>
        </View>
        {
            detailData.materials.map((item, index) => (
              <>
                <View
                  key={String(index)}
                  style={[{
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 5,
                    paddingHorizontal: 5,
                    backgroundColor: index % 2 === 0 ? process.env.REACT_APP_COLOR_PRIMARY_BG : 'white'
                  }]}
                >
                  <View
                    style={{
                      width: '50%',
                    }}
                  >
                    <Text style={[styles.h5]}>
                      {item.title}
                    </Text>
                  </View>

                  <View style={{
                    width: '50%',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  >
                    <View style={{
                      width: '33%',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}
                    >
                      <Text style={[styles.h5]}>
                        {item.current_period}
                      </Text>
                      <Text
                        style={[styles.h6,
                          {
                            color: process.env.REACT_APP_COLOR_TERTIARY_DARK,
                            marginLeft: 10
                          }]}
                      >
                        {'('}
                        {item.current_period_rank}
                        {')'}
                      </Text>
                    </View>

                    <View style={{
                      width: '33%',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      marginLeft: 13
                    }}
                    >
                      <Text style={[styles.h5]}>
                        {item.previous_period}
                      </Text>
                      <Text
                        style={[styles.h6,
                          {
                            color: process.env.REACT_APP_COLOR_TERTIARY_DARK,
                            marginLeft: 10
                          }]}
                      >
                        {'('}
                        {item.previous_period_rank}
                        {')'}
                      </Text>
                    </View>

                    <View style={{
                      width: '33%',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      marginLeft: 17
                    }}
                    >
                      <Text style={[styles.h5]}>
                        {item.last_year}
                      </Text>
                      <Text
                        style={[styles.h6,
                          {
                            color: process.env.REACT_APP_COLOR_TERTIARY_DARK,
                            marginLeft: 10
                          }]}
                      >
                        {'('}
                        {item.last_year_rank}
                        {')'}
                      </Text>
                    </View>
                  </View>

                </View>
                <View style={{
                  width: '100%',
                  marginBottom: 5,
                  borderBottom: index !== detailData.materials.length && `2pt solid ${process.env.REACT_APP_COLOR_GRAY_DARK}`,
                }}
                />
              </>
            ))
          }
      </View>

    </Page>
  </Document>
);

export default ReportPDF;
