import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { DropdownMenu } from '../../../Education/EducationalContent/components';

import { SummaryCellLoading, SummaryOtherLoading } from '../../../components/loading';


import { Typography } from '../../../components';

import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';

import { EducationModal } from '../components';


const useStyles = makeStyles((theme) => ({
  container: {
    '@media (max-width: 1390px)': {
      height: '100%',
      maxHeight: 'unset !important',
    },
    '@media (max-width: 1200px)': {
      height: 'unset !important',
      maxHeight: 'unset !important',
    }
  },
  group: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
  },
  groupItem: {
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid #E7ECEF',
    borderRadius: 9,
    ChangeTheColorBorderRadius: '8px',
    padding: '12px',
    // width: "25%",
    width: 325,
    // maxWidth: "200px",
    height: 82,
    MightNeedFullWidthSoTheItemsDontGetTooBigOnLargeScreenColor: '#63666A',
    ChangeTheColorFontFamily: 'sans-serif',
    cursor: 'pointer',
    color: '#404040',
    '@media (max-width: 1536px)': {
      width: '275px!important'
    },
    '@media (max-width: 1410px)': {
      width: '250px!important'
    },
    '@media (max-width: 1110px)': {
      width: '225px!important'
    },
  },
  groupTitle: {
    fontWeight: '600',
    fontSize: 14,
  },
  groupNumber: {
    fontSize: '24px',
    fontWeight: '700',
    marginTop: '6px',
    marginBottom: '6px'
  },
  groupChange: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: '500',
    fontSize: 16,
  },
  groupArrow: {
    marginRight: '6px'
  },
  groupChangePositive: {
    // color: '#27ED6A'
    color: '#409A5F'
  },
  groupChangePositiveActive: {
    color: 'white'
  },
  groupChangeNegativet: {
    color: '#FF1E1E'
  },
  groupChangeNegativeGroupArrowClass: {
    transform: 'rotate(180deg)'
  },
  groupItemHighlighted: {
    // backgroundColor: '#2A95FF',
    color: 'white'
  },
  lists: {
    display: 'flex',
    flexDirection: 'row',
    gap: '80px'
  },
  list__heading: {
    color: '#63666A', /* Change the color */
    fontFamily: 'sans-serif',
    textTransform: 'uppercase',
    fontSize: '0.8rem', /* Change as needed */
    marginBottom: '16px'
  },
  list__rows: {
    display: 'flex',
    flexDirection: 'column',
    color: '#63666A', /* Change the color */
    fontFamily: 'sans-serif'
  },
  list__row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    height: '2rem'
  },
  list__number: {
    marginRight: '6px',
    fontWeight: 600 /* Change as needed */
  },
  list__arrow: {
    marginRight: 6
  },
  list__change: {
    display: 'flex',
    flexDirection: 'row'
  },

  /* Item shows increase */
  list__changePositive: {
    color: '#27ED6A'
  },
  /* Item shows decrease */
  list__changeNegative: {
    color: '#FF1E1E'
  },
  list__changeNegativeList__arrow: {
    transform: 'rotate(180deg)'
  },

  /* Legend for the circle graph */
  list__legend: {
    backgroundColor: 'grey',
    color: 'white',
    borderRadius: 4,
    padding: '4px 10px',
    marginRight: 20
  },
  list__legend1: {
    backgroundColor: '#2A95FF' /* Colors correspond to sections of the graph */
  },
  list__legend2: {
    backgroundColor: '#A663FF' /* Colors correspond to sections of the graph */
  },
  list__legend3: {
    backgroundColor: '#97CCFF' /* Colors correspond to sections of the graph */
  },
  list__legend4: {
    backgroundColor: '#00C192' /* Colors correspond to sections of the graph */
  },
  list__legend5: {
    backgroundColor: '#FFCD5B' /* Colors correspond to sections of the graph */
  },
  list__topic: {
    fontWeight: 600
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  legendContainer: {
    position: 'relative',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 100,
    display: "flex",
    gap: "16px"
  },
  legend: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    textTransform: "uppercase",
    fontFamily: "sans-serif"
  },
  legendCircle: {
    content: "''",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    border: "2px solid rgba(118, 203, 196, 1)"
  },
  legendLine: {
    position: "relative",
    top: "0px",
    width: "20px",
    margin: "0 6px 0 0",
    height: "2px",
    left: "1px"
  },
  legendDottedLegendLine: {

    borderTop: "2px dashed rgba(118, 203, 196, 1)"
  },
  legendDottedLegendCircle: {
    content: "''",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    border: "2px solid rgba(118, 203, 196, 1)"
  },
  legendSolidLegendLine: {
    borderTop: "2px solid rgba(42, 149, 255, 1)"
  },
  legendSolidLegendCircle: {
    content: "''",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    border: "2px solid rgba(42, 149, 255, 1)"
  },
  legendSolidLegendLineGreen: {
    borderTop: "2px solid rgba(0, 193, 146, 1)"
  },
  legendSolidLegendCircleGreen: {
    content: "''",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    border: "2px solid rgba(0, 193, 146, 1)"
  },
  legendSolidLegendLinePurple: {
    borderTop: "2px solid rgba(166, 99, 255, 1)"
  },
  legendSolidLegendCirclePurple: {
    content: "''",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    border: "2px solid rgba(166, 99, 255, 1)"
  },
  legendSolidLegendLineYellow: {
    borderTop: "2px solid rgba(255, 205, 91, 1)"
  },
  legendSolidLegendCircleYellow: {
    content: "''",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    border: "2px solid rgba(255, 205, 91, 1)"
  },
  filter: {
    display: "inline-flex",
    border: "1px solid #0086C2",
    borderRadius: "16px",
    position: "relative",
  },
  filterItem: {
    padding: "6px 10px",
    borderRight: "1px solid #0086C2",
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "20px",
    color: "#0086C2",
    textAlign: "center",
    letterSpacing: "0.04em",
    cursor: "pointer",
    "&:first-of-type": {
      borderTopLeftRadius: "16px",
      borderBottomLeftRadius: "16px"
    },
    "&:last-of-type": {
      borderTopRightRadius: "16px",
      borderBottomRightRadius: "16px",
      borderRight: "0"
    },
    minWidth: 92,
  },
  filterItemFirstOfType: {
    borderTopLeftRadius: "16px",
    borderBottomLeftRadius: "16px"
  },
  filterItemLastOfType: {
    borderTopRightRadius: "16px",
    borderBottomRightRadius: "16px",
    borderRight: "0"
  },
  filterItemSelected: {
    backgroundColor: 'rgba(0, 134, 194, 0.12)',
    fontWeight: '600',
  },
  participationMethodCard: {
    bottom: 5,
    position: 'relative',
  },
  viewReportText: {
    color: process.env.REACT_APP_COLOR_PRIMARY
  },
  viewReportContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: 10
  }

}));

const ParticipationMethod = ({
  intl,
  reportData,
  reportMonth,
  reportYear,
  isLoading,
  csm,
  onReportDashboard,
  title,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openTitle, setOpenTitle] = useState(false);


  // useEffect(() => {
  //   getReportDashboardRequest({
  //     municipality,
  //     month: datePeriod.month,
  //     year: datePeriod.year,
  //   });
  // }, [datePeriod]);




  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const educationLabels = [];
  // let participationMethodDatapointsCurrentYear = [];
  // let participationMethodDatapointsLastYear = [];

  const participationMethodLabels = [];
  let participationMethodDatapointsCurrentYear = [];
  let participationMethodDatapointsLastYear = [];

  const educationExposureDatapointsCurrentYear = [];
  const educationExposureDatapointsLastYear = [];
  const educationEngagementDatapointsCurrentYear = [];
  const educationEngagementDatapointsLastYear = [];
  const educationCorrectAnswerRateDatapointsCurrentYear = [];
  const educationCorrectAnswerRateDatapointsLastYear = [];
  const educationTimeRateDatapointsCurrentYear = [];
  const educationTimeRateDatapointsLastYear = [];

  let educationExposureTotalCurrentYear = 0;
  let educationExposureTotalLastYear = 0;
  let educationEngagementTotalCurrentYear = 0;
  let educationEngagementTotalLastYear = 0;
  let educationCorrectAnswerRateTotalCurrentYear = 0;
  let educationCorrectAnswerRateTotalLastYear = 0;
  let educationTimeRateTotalCurrentYear = 0;
  let educationTimeRateTotalLastYear = 0;


  let participationMethodData;
  const participationMethodStats = [];

  const [participationMethodSelection, setParticipationMethodSelection] = useState('mobile_app');
  const [modalDescriptionSelection, setModalDescriptionSelection] = useState('mobile_app');
  const [participationMethodSwitch, setParticipationMethodSwitch] = useState('total');

  const handleParticipationMethodSelection = (section) => {
    setParticipationMethodSelection(section);
  }

  const handleParticipationMethodSwitch = (section) => {
    if (section === 'total') {
      setParticipationMethodSelection('mobile_app');
    } else {
      setParticipationMethodSelection('email');
    }
    setParticipationMethodSwitch(section);
  }


  /**
 * Menu and menuitem functions.
 */

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElTitle, setAnchorElTitle] = useState(null);

  const handleClick = (section, event) => {
    setModalDescriptionSelection(section);
    setAnchorEl(event.currentTarget);
  };
  const handleClickTitle = (event) => {
    setAnchorElTitle(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElTitle(null);
    setTimeout(() => {
      setParticipationMethodSelection(modalDescriptionSelection)
      setModalDescriptionSelection(participationMethodSelection)
    }, 0);


  };

  const closeModal = () => {
    setOpen(false);
    handleClose();
  }

  const descriptionHandler = (section, e) => {
    setOpen(true);
  };

  const descriptionHandlerTitle = (e) => {
    setOpenTitle(true);
  };

  const viewDetailsHandler = (e) => {
    if (participationMethodSwitch === 'total') {
      history.push('/report/user');
    } else if (participationMethodSwitch === 'new') {
      history.push('/report/new-subscribers');
    }
  };

  useEffect(() => {
    if (modalDescriptionSelection !== participationMethodSelection) {
      setParticipationMethodSelection(modalDescriptionSelection)
    }
  }, [open]);

  /**
* Data for dropdown menu items.
*/
  const menuItemData = [
    {
      id: 1,
      name: 'Description',
      method: descriptionHandler,
      disabled: false
    },
    {
      id: 2,
      name: 'View Details',
      method: viewDetailsHandler,
      disabled: false
    },

  ];
  /**
* Data for dropdown menu items.
*/
  const menuItemDataTitle = [
    {
      id: 1,
      name: 'Description',
      method: descriptionHandler,
      disabled: false
    },

  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          return `${moment(tooltipItem[0].xLabel, 'MMM').format('MMMM')} ${tooltipItem[0].datasetIndex > 0 ? Number(tooltipItem[0].xLabel.substr(4, 7)) : Number(tooltipItem[0].xLabel.substr(4, 7)) - 1} `;
          // return `${moment(tooltipItem[0].xLabel, 'MMM').format('MMMM')} ${tooltipItem[0].datasetIndex > 0 ? tooltipItem[0].xLabel.substr(4, 7) : reportYear - 1} `;

        },
        label: function (tooltipItem, data) {
          return tooltipItem.yLabel.toLocaleString();
        }
      }
    },
    tension: 0,
    bezierCurve: false,
    scales: {
      xAxes: [{
        gridLines: {
          // display: false
        },
        ticks: {
          callback: function (value, index, ticks) {
            return value.substr(0, 3);
          }
        }
      }],
      yAxes: [{
        gridLines: {
          // display: false
        },
        ticks: {
          callback: function (value, index, ticks) {
            return value.toLocaleString();
          }
        }
      }]
    }
  };

  // if (participationMethodSelection === 'communication' || participationMethodSelection === 'sms') {
  //   options.scales.yAxes[0].ticks = {
  //     beginAtZero: true,
  //     suggestedMax: 100,

  //     callback: function (value, index, ticks) {
  //       return value + '%';
  //     }
  //   }
  // } else if (participationMethodSelection === 'calendar' || participationMethodSelection === 'phone') {
  //   const highestTimeSpent = Math.round(Math.max(...reportData.education['time_spent'].map(o => Number(o.value))));
  //   options.tooltips = {
  //     callbacks: {
  //       title: function (tooltipItem, data) {
  //         // return `${moment(tooltipItem[0].xLabel, 'MMM').format('MMMM')} ${tooltipItem[0].datasetIndex > 0 ? reportYear : reportYear - 1} `;
  //         return `${moment(tooltipItem[0].xLabel, 'MMM').format('MMMM')} ${tooltipItem[0].datasetIndex > 0 ? Number(tooltipItem[0].xLabel.substr(4, 7)) : Number(tooltipItem[0].xLabel.substr(4, 7)) - 1} `;

  //       },
  //       label: function (tooltipItem, data) {

  //         return getFormattedTime(tooltipItem.yLabel)
  //         // if (highestTimeSpent >= 600) {
  //         //   return Math.round(tooltipItem.yLabel / 60) + ' H';
  //         // } else {
  //         //   return tooltipItem.yLabel + ' M';
  //         // }
  //         // return tooltipItem.yLabel.toLocaleString();
  //       }
  //     }

  //   }
  //   options.scales.yAxes[0].ticks = {
  //     beginAtZero: true,
  //     callback: function (value, index, ticks) {
  //       return getFormattedTime(value, true)
  //       if (highestTimeSpent >= 600) {
  //         return Math.round(value / 60) + ' H';
  //       } else {
  //         return value + ' M';
  //       }

  //     }
  //   }
  // }



  if (reportData && reportData.participation_method && !isLoading) {
    // eslint-disable-next-line no-plusplus

    for (let i = 0; i < reportData.participation_method[participationMethodSwitch][participationMethodSelection].length; i++) {
      if (i <= 11) {
        participationMethodLabels.push(`${month[reportData.participation_method[participationMethodSwitch][participationMethodSelection][i].month - 1]} ${reportData.participation_method[participationMethodSwitch][participationMethodSelection][i].year}`);
        participationMethodDatapointsCurrentYear.push(reportData.participation_method[participationMethodSwitch][participationMethodSelection][i].value || reportData.participation_method[participationMethodSwitch][participationMethodSelection][i].minutes);
      } else {
        participationMethodDatapointsLastYear.push(reportData.participation_method[participationMethodSwitch][participationMethodSelection][i].value || reportData.participation_method[participationMethodSwitch][participationMethodSelection][i].minutes);
      }
    }


    if (participationMethodSwitch === 'total') {
      for (let i = 0; i < reportData.participation_method[participationMethodSwitch]['web_app'].length; i++) {
        if (i <= 11) {
          // educationLabels.push(month[reportData.education['exposure'][i].month - 1]);
          educationExposureDatapointsCurrentYear.push(reportData.participation_method[participationMethodSwitch]['web_app'][i].value || reportData.participation_method[participationMethodSwitch]['web_app'][i].minutes);
        } else {
          educationExposureDatapointsLastYear.push(reportData.participation_method[participationMethodSwitch]['web_app'][i].value || reportData.participation_method[participationMethodSwitch]['web_app'][i].minutes);
        }
      }
    } else if (participationMethodSwitch === 'new') {
      for (let i = 0; i < reportData.participation_method[participationMethodSwitch]['mobile_app'].length; i++) {
        if (i <= 11) {
          // educationLabels.push(month[reportData.education['exposure'][i].month - 1]);
          educationExposureDatapointsCurrentYear.push(reportData.participation_method[participationMethodSwitch]['mobile_app'][i].value || reportData.participation_method[participationMethodSwitch]['mobile_app'][i].minutes);
        } else {
          educationExposureDatapointsLastYear.push(reportData.participation_method[participationMethodSwitch]['mobile_app'][i].value || reportData.participation_method[participationMethodSwitch]['mobile_app'][i].minutes);
        }
      }
    }

    if (participationMethodSwitch === 'total') {
      for (let i = 0; i < reportData.participation_method[participationMethodSwitch]['mobile_app'].length; i++) {
        if (i <= 11) {
          // educationLabels.push(month[reportData.education['exposure'][i].month - 1]);
          educationEngagementDatapointsCurrentYear.push(reportData.participation_method[participationMethodSwitch]['mobile_app'][i].value || reportData.participation_method[participationMethodSwitch]['mobile_app'][i].minutes);
        } else {
          educationEngagementDatapointsLastYear.push(reportData.participation_method[participationMethodSwitch]['mobile_app'][i].value || reportData.participation_method[participationMethodSwitch]['mobile_app'][i].minutes);
        }
      }
    } else if (participationMethodSwitch === 'new') {
      for (let i = 0; i < reportData.participation_method[participationMethodSwitch]['email'].length; i++) {
        if (i <= 11) {
          // educationLabels.push(month[reportData.education['exposure'][i].month - 1]);
          educationEngagementDatapointsCurrentYear.push(reportData.participation_method[participationMethodSwitch]['email'][i].value || reportData.participation_method[participationMethodSwitch]['email'][i].minutes);
        } else {
          educationEngagementDatapointsLastYear.push(reportData.participation_method[participationMethodSwitch]['email'][i].value || reportData.participation_method[participationMethodSwitch]['email'][i].minutes);
        }
      }
    }



    if (participationMethodSwitch === 'total') {
      for (let i = 0; i < reportData.participation_method[participationMethodSwitch]['communication'].length; i++) {
        if (i <= 11) {
          // educationLabels.push(month[reportData.education['exposure'][i].month - 1]);
          educationCorrectAnswerRateDatapointsCurrentYear.push(reportData.participation_method[participationMethodSwitch]['communication'][i].value || reportData.participation_method[participationMethodSwitch]['communication'][i].minutes);
        } else {
          educationCorrectAnswerRateDatapointsLastYear.push(reportData.participation_method[participationMethodSwitch]['communication'][i].value || reportData.participation_method[participationMethodSwitch]['communication'][i].minutes);
        }
      }
    } else if (participationMethodSwitch === 'new') {
      for (let i = 0; i < reportData.participation_method[participationMethodSwitch]['sms'].length; i++) {
        if (i <= 11) {
          // educationLabels.push(month[reportData.education['exposure'][i].month - 1]);
          educationCorrectAnswerRateDatapointsCurrentYear.push(reportData.participation_method[participationMethodSwitch]['sms'][i].value || reportData.participation_method[participationMethodSwitch]['sms'][i].minutes);
        } else {
          educationCorrectAnswerRateDatapointsLastYear.push(reportData.participation_method[participationMethodSwitch]['sms'][i].value || reportData.participation_method[participationMethodSwitch]['sms'][i].minutes);
        }
      }
    }


    if (participationMethodSwitch === 'total') {
      for (let i = 0; i < reportData.participation_method[participationMethodSwitch]['calendar'].length; i++) {
        if (i <= 11) {
          // educationLabels.push(month[reportData.education['exposure'][i].month - 1]);
          educationTimeRateDatapointsCurrentYear.push(reportData.participation_method[participationMethodSwitch]['calendar'][i].value || reportData.participation_method[participationMethodSwitch]['calendar'][i].minutes);
        } else {
          educationTimeRateDatapointsLastYear.push(reportData.participation_method[participationMethodSwitch]['calendar'][i].value || reportData.participation_method[participationMethodSwitch]['calendar'][i].minutes);
        }
      }
    } else if (participationMethodSwitch === 'new') {
      for (let i = 0; i < reportData.participation_method[participationMethodSwitch]['phone'].length; i++) {
        if (i <= 11) {
          // educationLabels.push(month[reportData.education['exposure'][i].month - 1]);
          educationTimeRateDatapointsCurrentYear.push(reportData.participation_method[participationMethodSwitch]['phone'][i].value || reportData.participation_method[participationMethodSwitch]['phone'][i].minutes);
        } else {
          educationTimeRateDatapointsLastYear.push(reportData.participation_method[participationMethodSwitch]['phone'][i].value || reportData.participation_method[participationMethodSwitch]['phone'][i].minutes);
        }
      }
    }

    // eslint-disable-next-line radix
    educationExposureTotalCurrentYear = educationExposureDatapointsCurrentYear.reduce((acc, o) => acc + parseInt(o), 0);
    educationExposureTotalLastYear = educationEngagementDatapointsLastYear.reduce((acc, o) => acc + parseInt(o), 0);

    // eslint-disable-next-line radix
    educationEngagementTotalCurrentYear = educationEngagementDatapointsCurrentYear.reduce((acc, o) => acc + parseInt(o), 0);
    educationEngagementTotalLastYear = educationEngagementDatapointsLastYear.reduce((acc, o) => acc + parseInt(o), 0);

    // eslint-disable-next-line radix

    educationCorrectAnswerRateTotalCurrentYear = educationCorrectAnswerRateDatapointsCurrentYear.reduce((acc, o) => acc + parseInt(o), 0);
    educationCorrectAnswerRateTotalLastYear = educationCorrectAnswerRateDatapointsLastYear.reduce((acc, o) => acc + parseInt(o), 0);

    // eslint-disable-next-line radix
    educationTimeRateTotalCurrentYear = educationTimeRateDatapointsCurrentYear.reduce((acc, o) => acc + parseInt(o), 0);
    educationTimeRateTotalLastYear = educationTimeRateDatapointsLastYear.reduce((acc, o) => acc + parseInt(o), 0);

    // const educationExposures = {
    //   title: 'Web App',
    //   color: '#00C192',
    //   total: educationExposureTotalCurrentYear,
    //   percentChange: `${((educationExposureTotalLastYear / educationExposureTotalCurrentYear) * 100).toFixed(1)}%`,
    //   selectionKey: 'exposure',

    //   currentMonthTotal: reportData.education['exposure'].filter((item) => item.month === reportMonth.toString() && item.year === reportYear.toString())[0],
    //   lastYearCurrentMonthTotal: reportData.education['exposure'].filter((item) => item.month === reportMonth.toString() && item.year === (reportYear - 1).toString())[0],
    // }
    const educationExposures = {
      title: `${participationMethodSwitch === 'total' ? `Web App` : `Mobile App`}`,
      color: '#00C192',
      total: educationExposureTotalCurrentYear,
      percentChange: `${((educationExposureTotalLastYear / educationExposureTotalCurrentYear) * 100).toFixed(1)}%`,
      selectionKey: `${participationMethodSwitch === 'total' ? `web_app` : `mobile_app`}`,

      currentMonthTotal: participationMethodSwitch === 'total' ? reportData.participation_method[participationMethodSwitch]['web_app'].filter((item) => item.month === reportMonth.toString() && item.year === reportYear.toString())[0] : reportData.participation_method[participationMethodSwitch]['mobile_app'].filter((item) => item.month === reportMonth.toString() && item.year === reportYear.toString())[0],
      lastYearCurrentMonthTotal: participationMethodSwitch === 'total' ? reportData.participation_method[participationMethodSwitch]['web_app'].filter((item) => item.month === reportMonth.toString() && item.year === (reportYear - 1).toString())[0] : reportData.participation_method[participationMethodSwitch]['mobile_app'].filter((item) => item.month === reportMonth.toString() && item.year === (reportYear - 1).toString())[0],
    };
    educationExposures.reportingPercentChange = educationExposures?.currentMonthTotal && educationExposures?.lastYearCurrentMonthTotal ? `${(((Number(educationExposures?.currentMonthTotal?.value) - Number(educationExposures?.lastYearCurrentMonthTotal?.value)) / Number(educationExposures?.currentMonthTotal?.value)) * 100).toFixed(1)} %` : '0%';

    if (csm && csm.calendly_link) {
      educationExposures.descripton = (<div>
        <Typography variant="body1" style={{ textAlign: 'left' }}>
          Web apps are the one or more widgets on your website that allow residents to access your Recycle Coach program information. The headline number is for the latest month, which is shown in the upper right corner of this page. The percentage change is a comparison to the same month last year.
          <br />
          <br />

          The #1 way to increase your participation rate is to optimize web app placements on your website. To request a free web app optimization audit, contact your
          <a href={csm.calendly_link} target="_blank"> Customer Success Manager</a>.
        </Typography>
      </div>)
    }


    const educationEngagements = {
      title: `${participationMethodSwitch === 'total' ? `Mobile App` : `Email`}`,
      color: '#2A95FF',
      total: educationEngagementTotalCurrentYear,
      percentChange: `${((educationEngagementTotalLastYear / educationEngagementTotalCurrentYear) * 100).toFixed(1)}%`,
      selectionKey: `${participationMethodSwitch === 'total' ? `mobile_app` : `email`}`,
      currentMonthTotal: participationMethodSwitch === 'total' ? reportData.participation_method[participationMethodSwitch]['mobile_app'].filter((item) => item.month === reportMonth.toString() && item.year === reportYear.toString())[0] : reportData.participation_method[participationMethodSwitch]['email'].filter((item) => item.month === reportMonth.toString() && item.year === reportYear.toString())[0],
      lastYearCurrentMonthTotal: participationMethodSwitch === 'total' ? reportData.participation_method[participationMethodSwitch]['mobile_app'].filter((item) => item.month === reportMonth.toString() && item.year === (reportYear - 1).toString())[0] : reportData.participation_method[participationMethodSwitch]['email'].filter((item) => item.month === reportMonth.toString() && item.year === (reportYear - 1).toString())[0],
    }
    educationEngagements.reportingPercentChange = educationEngagements?.currentMonthTotal && educationEngagements?.lastYearCurrentMonthTotal ? `${(((Number(educationEngagements?.currentMonthTotal.value) - Number(educationEngagements?.lastYearCurrentMonthTotal.value)) / Number(educationEngagements?.currentMonthTotal.value)) * 100).toFixed(1)}%` : '0%';

    if (csm && csm.calendly_link) {
      educationEngagements.descripton = (<div>
        <Typography variant="body1" style={{ textAlign: 'left' }}>
          Residents download the free Recycle Coach mobile app from their Apple or Android store. The headline number is the active users as of the latest month, which is shown in the upper right corner of this page. The percentage change is a comparison to the same month last year.
          <br />
          <br />

          The #1 way to increase your mobile app users is to optimize web app placements on your website because the web apps contain direct links to the app stores. To request a free web app optimization audit, contact your
          <a href={csm.calendly_link} target="_blank"> Customer Success Manager</a>.
        </Typography>
      </div>)
    }

    const educationCorrectAnswerRate = {
      title: `${participationMethodSwitch === 'total' ? `Communication` : `SMS`}`,
      color: '#A663FF',
      total: educationCorrectAnswerRateTotalCurrentYear,
      percentChange: `${((educationCorrectAnswerRateTotalLastYear / educationCorrectAnswerRateTotalCurrentYear) * 100).toFixed(1)}%`,
      selectionKey: `${participationMethodSwitch === 'total' ? 'communication' : `sms`}`,
      currentMonthTotal: participationMethodSwitch === 'total' ? reportData.participation_method[participationMethodSwitch]['communication'].filter((item) => item.month === reportMonth.toString() && item.year === reportYear.toString())[0] : reportData.participation_method[participationMethodSwitch]['sms'].filter((item) => item.month === reportMonth.toString() && item.year === reportYear.toString())[0],
      lastYearCurrentMonthTotal: participationMethodSwitch === 'total' ? reportData.participation_method[participationMethodSwitch]['communication'].filter((item) => item.month === reportMonth.toString() && item.year === (reportYear - 1).toString())[0] : reportData.participation_method[participationMethodSwitch]['sms'].filter((item) => item.month === reportMonth.toString() && item.year === (reportYear - 1).toString())[0],
    }
    educationCorrectAnswerRate.reportingPercentChange = educationCorrectAnswerRate?.currentMonthTotal && educationCorrectAnswerRate?.lastYearCurrentMonthTotal ? `${(((Number(educationCorrectAnswerRate?.currentMonthTotal?.value) - Number(educationCorrectAnswerRate?.lastYearCurrentMonthTotal?.value)) / Number(educationCorrectAnswerRate?.currentMonthTotal?.value)) * 100).toFixed(1)}%` : '0%';
    if (csm && csm.calendly_link) {
      educationCorrectAnswerRate.descripton = (<div>
        <Typography variant="body1" style={{ textAlign: 'left' }}>
          These are users who subscribe to Recycle Coach reminders via email, SMS or telephone (SMS and telephone incur network fees and are optional) and telephone. The headline number is the active subscribers as of the latest month, which is shown in the upper right corner of this page. The percentage change is a comparison to the same month last year.
          <br />
          <br />

          To get a quote for SMS/Telephone fees, contact your
          <a href={csm.calendly_link} target="_blank"> Customer Success Manager</a>.
        </Typography>
      </div>)
    }


    const educationTimeSpent = {
      title: `${participationMethodSwitch === 'total' ? `Calendar` : `Phone`}`,
      color: '#FFCD5B',
      total: educationTimeRateTotalCurrentYear,
      percentChange: `${((educationTimeRateTotalLastYear / educationTimeRateTotalCurrentYear) * 100).toFixed(1)}%`,
      selectionKey: `${participationMethodSwitch === 'total' ? `calendar` : `phone`}`,
      currentMonthTotal: participationMethodSwitch === 'total' ? reportData.participation_method[participationMethodSwitch]['calendar'].filter((item) => item.month === reportMonth.toString() && item.year === reportYear.toString())[0] : reportData.participation_method[participationMethodSwitch]['phone'].filter((item) => item.month === reportMonth.toString() && item.year === reportYear.toString())[0],
      lastYearCurrentMonthTotal: participationMethodSwitch === 'total' ? reportData.participation_method[participationMethodSwitch]['calendar'].filter((item) => item.month === reportMonth.toString() && item.year === (reportYear - 1).toString())[0] : reportData.participation_method[participationMethodSwitch]['phone'].filter((item) => item.month === reportMonth.toString() && item.year === (reportYear - 1).toString())[0],
    }
    educationTimeSpent.reportingPercentChange = educationTimeSpent.currentMonthTotal && educationTimeSpent.lastYearCurrentMonthTotal ? `${Number(educationTimeSpent.currentMonthTotal.value == 0) && Number(educationTimeSpent.currentMonthTotal.value) == 0 ? '0' : (((Number(educationTimeSpent.currentMonthTotal.value) - Number(educationTimeSpent.lastYearCurrentMonthTotal.value)) / Number(educationTimeSpent.currentMonthTotal.value)) * 100).toFixed(1)}%` : '0%';
    if (csm && csm.calendly_link) {
      educationTimeSpent.descripton = (<div>
        <Typography variant="body1" style={{ textAlign: 'left' }}>
          These are users who subscribe to the Recycle Coach platform in order to sync their pick-up schedule to their everyday calendar. Also included are residents who subscribe to receive downloadable PDF collection calendars and those who download the PDF calendar or Kids Activity Packets without subscribing. The headline number is the active users/subscribers as of the latest month, which is shown in the upper right corner of this page. The percentage change is a comparison to the same month last year.
          <br />
          <br />

          To learn how to add educational content when residents download a collection calendar and other PRO features, contact your
          <a href={csm.calendly_link} target="_blank"> Customer Success Manager</a>.
        </Typography>
      </div>)
    }




    participationMethodStats.push(educationExposures, educationEngagements, educationCorrectAnswerRate, educationTimeSpent);
  }

  if (participationMethodDatapointsCurrentYear.length && participationMethodLabels.length) {
    if (participationMethodSwitch === 'total' && participationMethodSelection === 'web_app' || participationMethodSwitch === 'new' && participationMethodSelection === 'mobile_app') {
      participationMethodData = {
        labels: participationMethodLabels.reverse(),
        datasets: [
          {
            label: 'Last Year',
            data: participationMethodDatapointsLastYear.reverse(),
            borderColor: 'rgba(118, 203, 196, 1)',
            borderDash: [5, 3],
            dashedBorderWidth: 15,
            fill: false,
            lineTension: 0,
          },
          {
            label: 'This Year',
            data: participationMethodDatapointsCurrentYear.reverse(),
            borderColor: 'rgba(0, 193, 146, 1)',
            lineTension: 0,
            fill: "start",
            backgroundColor: (context) => {
              const { ctx } = context.chart;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, "rgba(0, 193, 146, 4)");
              gradient.addColorStop(1, "rgba(0, 193, 146, 0.15)");
              return gradient;
            },

          },

        ],
      };

    } else if (participationMethodSwitch === 'total' && participationMethodSelection === 'mobile_app' || participationMethodSwitch === 'new' && participationMethodSelection === 'email') {
      participationMethodData = {
        labels: participationMethodLabels.reverse(),
        datasets: [
          {
            label: 'Last Year',
            data: participationMethodDatapointsLastYear.reverse(),
            borderColor: 'rgba(118, 203, 196, 1)',
            borderDash: [5, 3],
            dashedBorderWidth: 15,
            fill: false,
            lineTension: 0,
          },
          {
            label: 'This Year',
            data: participationMethodDatapointsCurrentYear.reverse(),
            borderColor: 'rgba(42, 149, 255, 1)',
            lineTension: 0,
            backgroundColor: (context) => {
              const { ctx } = context.chart;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, "rgba(42, 149, 255, 4)");
              gradient.addColorStop(1, "rgba(42, 149, 255, 0.1)");
              return gradient;
            },

          },

        ],
      };
    } else if (participationMethodSwitch === 'total' && participationMethodSelection === 'communication' || participationMethodSwitch === 'new' && participationMethodSelection === 'sms') {
      participationMethodData = {
        labels: participationMethodLabels.reverse(),
        datasets: [
          {
            label: 'Last Year',
            data: participationMethodDatapointsLastYear.reverse(),
            borderColor: 'rgba(118, 203, 196, 1)',
            borderDash: [5, 3],
            dashedBorderWidth: 15,
            fill: false,
            lineTension: 0,

          },
          {
            label: 'This Year',
            data: participationMethodDatapointsCurrentYear.reverse(),
            borderColor: 'rgba(166, 99, 255, 1)',
            lineTension: 0,

            backgroundColor: (context) => {
              const { ctx } = context.chart;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, "rgba(166, 99, 255, 4)");
              gradient.addColorStop(1, "rgba(166, 99, 255, 0.1)");
              return gradient;
            },

          },

        ],
      };

    } else if (participationMethodSwitch === 'total' && participationMethodSelection === 'calendar' || participationMethodSwitch === 'new' && participationMethodSelection === 'phone') {
      participationMethodData = {
        labels: participationMethodLabels.reverse(),
        datasets: [
          {
            label: 'Last Year',
            data: participationMethodDatapointsLastYear.reverse(),
            borderColor: 'rgba(118, 203, 196, 1)',
            borderDash: [5, 3],
            dashedBorderWidth: 15,
            fill: false,
            lineTension: 0,

          },
          {
            label: 'This Year',
            data: participationMethodDatapointsCurrentYear.reverse(),
            borderColor: 'rgba(255, 205, 91, 1)',
            lineTension: 0,

            backgroundColor: (context) => {
              const { ctx } = context.chart;
              const gradient = ctx.createLinearGradient(0, 0, 0, 200);
              gradient.addColorStop(0, "rgba(255, 205, 91, 4)");
              gradient.addColorStop(1, "rgba(255, 205, 91, 0.1)");
              return gradient;
            },

          },

        ],
      };

    }

  }

  if (participationMethodSelection && participationMethodSelection === 'mobile_app') {
    if (participationMethodData && participationMethodData.datasets) {

      const dataValues = participationMethodData.datasets.reduce((acc, item) => acc.concat(item.data), []);
      const minValue = Math.min(...dataValues);
      const maxValue = Math.max(...dataValues) * 1.1;
      const range = maxValue - minValue;
      const min = Math.round(maxValue - (range * 1.5));

      const stepSize = Math.ceil(range / 5); 
      const adjustedMax = minValue + stepSize * 5;

      options.scales.yAxes[0].ticks = {
        beginAtZero: true,
        min: min,
        max: adjustedMax,
        stepSize: stepSize,
        callback: function (value, index, ticks) {
          if (value >= 1000) {
            return Math.round(value / 100) * 100; // Round to the nearest hundred
          } else {
            return value.toLocaleString();
          }
        }
      };
    }
  } else if (participationMethodSelection && participationMethodSelection !== 'mobile_app') {
    // Reset to default options
    const dataValues = participationMethodData.datasets.reduce((acc, item) => acc.concat(item.data), []);
    const minValue = Math.min(...dataValues);
    const buffer = minValue * 0.1;
    const adjustedMinValue = minValue - buffer;
    const stepSize = Math.ceil((Math.max(...dataValues) - adjustedMinValue) / 8);

    options.scales.yAxes[0].ticks = {
      min: Math.ceil(adjustedMinValue),
      max: Math.ceil(Math.max(...dataValues) + buffer),
      stepSize: stepSize,
      callback: function (value, index, ticks) {
        if (value <= 100) {
          return value.toLocaleString();
        } else {
          const roundedValue = Math.round(value / 10) * 10;
          const previousRoundValue = Math.round((ticks[index -1]) / 10) * 10;
          if (roundedValue !== previousRoundValue) {
            return roundedValue;
          }
        }
      }
    };
  }

  const getFormattedTime = (seconds, hourOnly) => {
    let d = Number(seconds);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";

    const hours = h + 'H';
    const minutes = m + 'M';
    if (hourOnly) return hours; // return only hours
    return `${hours} ${minutes}`;
  }


  return (
    <>
      <Portlet className={classes.container} style={{ maxHeight: onReportDashboard ? 580 : 'unset' }}>

        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start" style={{ justifyContent: 'space-between' }}>
                <div className="row col-xs-12 col-lg-12 align-items-center justify-content-between">
                  <div className="align-items-center pl-3">
                    <Typography variant="h1">
                      {title ? title : 'Participation Method'}
                      {/* Participation Method */}
                    </Typography>
                  </div>
                  <div className='d-flex'>
                    <div className={`${classes.filter}`} style={{ left: onReportDashboard ? 'unset' : 35 }}>
                      <div className={`${classes.filterItem} ${participationMethodSwitch === 'total' ? classes.filterItemSelected : ''}`} onClick={() => {
                        handleParticipationMethodSwitch('total');
                      }}>
                        Total
                      </div>
                      <div className={`${classes.filterItem} ${participationMethodSwitch === 'new' ? classes.filterItemSelected : ''}`} onClick={() => {
                        handleParticipationMethodSwitch('new');
                      }}>
                        New users
                      </div>
                    </div>
                    {/* <DropdownMenu
                      handleClick={handleClickTitle}
                      anchorEl={anchorElTitle}
                      handleClose={handleClose}
                      menuItemData={menuItemData}
                    /> */}
                  </div>
                </div>
              </div>
            )}
          />

        </div>
        <PortletBody>
          <div className="row">
            {
              isLoading ? <div className={classes.group}>
                <div className={`${classes.groupItem}`} style={{ width: onReportDashboard ? 325 : 183 }}>
                  <SummaryCellLoading />
                </div>
                <div className={`${classes.groupItem}`} style={{ width: onReportDashboard ? 325 : 183 }}>
                  <SummaryCellLoading />
                </div>
                <div className={`${classes.groupItem}`} style={{ width: onReportDashboard ? 325 : 183 }}>
                  <SummaryCellLoading />
                </div>
                <div className={`${classes.groupItem}`} style={{ width: onReportDashboard ? 325 : 183 }}>
                  <SummaryCellLoading />
                </div>
              </div> :
                <div className={classes.group}>
                  {
                    participationMethodStats.length && participationMethodStats.map((item) => (
                      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                      isLoading ? <SummaryOtherLoading /> :
                        <div className={`${classes.groupItem} ${participationMethodSelection === item.selectionKey ? classes.groupItemHighlighted : null}`} style={{ borderColor: item.color, backgroundColor: participationMethodSelection === item.selectionKey ? item.color : '', width: onReportDashboard ? 325 : 183 }}>
                          <div className={classes.topSection} onClick={() => handleParticipationMethodSelection(item.selectionKey)}>
                            <div className={classes.groupTitle} onClick={() => handleParticipationMethodSelection(item.selectionKey)}>{item.title}</div>
                            <DropdownMenu
                              handleClick={((e) => { handleClick(item.selectionKey, e) })}
                              anchorEl={anchorEl}
                              handleClose={handleClose}
                              menuItemData={participationMethodSwitch === 'total' ? menuItemDataTitle : menuItemData}
                              section={item.selectionKey}
                            />
                          </div>
                          <div className={`d-flex justify-content-between ${classes.participationMethodCard}`} onClick={() => handleParticipationMethodSelection(item.selectionKey)}>
                            {
                              item.currentMonthTotal && item.currentMonthTotal && item.reportingPercentChange && (
                                // <div className={classes.groupNumber}>{item.selectionKey === 'correct_answer_rate' ? item.currentMonthTotal + '%' : item.selectionKey === 'time_spent' ? getFormattedTime(item.value) : item.currentMonthTotal.toLocaleString()}</div>
                                <div className={classes.groupNumber} style={{ color: participationMethodSelection === item.selectionKey ? 'white' : item.color }}>{item.selectionKey === 'correct_answer_rate' ? item.currentMonthTotal.value + '%' : item.selectionKey === 'time_spent' ? getFormattedTime(item.currentMonthTotal.value) : Number(item.currentMonthTotal.value).toLocaleString()}</div>


                              )
                            }
                            <div className={`${classes.groupChange} ${parseInt(item.reportingPercentChange) > 0 ? classes.groupChangePositive : classes.groupChangeNegativet} ${participationMethodSelection === item.selectionKey ? classes.groupChangePositiveActive : null}`}>
                              {
                                (item.reportingPercentChange !== 'NaN' || !item.reportingPercentChange !== 'NaN%' || item.reportingPercentChange !== '0%') && (
                                  <>
                                    <div className={classes.groupArrow}>{parseInt(item.reportingPercentChange) > 0 ? '↑' : '↓'}</div>

                                    <div className={classes.group__percentag}>{item.reportingPercentChange == 'NaN' || item.reportingPercentChange == 'NaN%' || item.reportingPercentChange == 'NaN %' ? '0%' : item.reportingPercentChange}</div>
                                  </>
                                )
                              }

                            </div>
                          </div>
                        </div>
                    ))
                  }
                  <EducationModal
                    open={open}
                    onClose={() => closeModal()}
                    title={participationMethodStats.map((item) => { if (item.selectionKey === modalDescriptionSelection) { return item.title; } })}
                    content={(
                      <Typography variant="body1" style={{ textAlign: 'left' }}>
                        {participationMethodStats.map((item) => { if (item.selectionKey === modalDescriptionSelection) { if (item.descripton) return item.descripton; } })}
                      </Typography>
                    )}
                  />
                </div>
            }

          </div>
          <div className="row mt-3">
            {
              isLoading ? null : (
                <div className={classes.legendContainer}>
                  <div class={`${classes.legend}`}>
                    <div class={classes.legendDottedLegendCircle}></div>
                    <div class={`${classes.legendLine} ${classes.legendDottedLegendLine}`}></div>
                    <div style={{ width: 70 }}>Last Year</div>
                  </div>
                  <div class={`${classes.legend}`}>
                    {
                      participationMethodSwitch === 'total' && participationMethodSelection === 'web_app' && (
                        <>
                          <div class={classes.legendSolidLegendCircleGreen}></div>
                          <div class={`${classes.legendLine} ${classes.legendSolidLegendLineGreen}`}></div>
                        </>
                      )
                    }
                    {
                      participationMethodSwitch === 'new' && participationMethodSelection === 'mobile_app' && (
                        <>
                          <div class={classes.legendSolidLegendCircleGreen}></div>
                          <div class={`${classes.legendLine} ${classes.legendSolidLegendLineGreen}`}></div>
                        </>
                      )
                    }
                    {
                      participationMethodSwitch === 'total' && participationMethodSelection === 'mobile_app' && (
                        <>
                          <div class={classes.legendSolidLegendCircle}></div>
                          <div class={`${classes.legendLine} ${classes.legendSolidLegendLine}`}></div>
                        </>
                      )
                    }
                    {
                      participationMethodSwitch === 'new' && participationMethodSelection === 'email' && (
                        <>
                          <div class={classes.legendSolidLegendCircle}></div>
                          <div class={`${classes.legendLine} ${classes.legendSolidLegendLine}`}></div>
                        </>
                      )
                    }
                    {
                      participationMethodSwitch === 'total' && participationMethodSelection === 'communication' && (
                        <>
                          <div class={classes.legendSolidLegendCirclePurple}></div>
                          <div class={`${classes.legendLine} ${classes.legendSolidLegendLinePurple}`}></div>
                        </>
                      )
                    }
                    {
                      participationMethodSwitch === 'new' && participationMethodSelection === 'sms' && (
                        <>
                          <div class={classes.legendSolidLegendCirclePurple}></div>
                          <div class={`${classes.legendLine} ${classes.legendSolidLegendLinePurple}`}></div>
                        </>
                      )
                    }
                    {
                      participationMethodSwitch === 'total' && participationMethodSelection === 'calendar' && (
                        <>
                          <div class={classes.legendSolidLegendCircleYellow}></div>
                          <div class={`${classes.legendLine} ${classes.legendSolidLegendLineYellow}`}></div>
                        </>
                      )
                    }
                    {
                      participationMethodSwitch === 'new' && participationMethodSelection === 'phone' && (
                        <>
                          <div class={classes.legendSolidLegendCircleYellow}></div>
                          <div class={`${classes.legendLine} ${classes.legendSolidLegendLineYellow}`}></div>
                        </>
                      )
                    }
                    <div style={{ width: 70 }}>This Year</div>
                  </div>
                </div>
              )
            }

            <div className="col-12" style={{ height: onReportDashboard ? 350 : 300 }}>
              {
                isLoading
                  ? <><SummaryOtherLoading /> <SummaryOtherLoading /></>
                  : (
                    participationMethodData ? <Line options={options} data={participationMethodData} /> : <></>
                  )
              }
            </div>
            {
              !onReportDashboard && !isLoading && (
                <div className={classes.viewReportContainer}>
                  <Link to="/report/dashboard">
                    <Typography
                      className={`d-flex justify-content-end ${classes.viewReportText}`}
                      variant="bodySemiBold"
                    >
                      View {intl.formatMessage({ id: 'GENERAL.REPORTS.HOME.PAGE' })}
                    </Typography>
                  </Link>
                </div>
              )
            }
          </div>
        </PortletBody>

      </Portlet>
    </>
  );

};

export default injectIntl(ParticipationMethod);
