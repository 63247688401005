/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { LastLocationProvider } from 'react-router-last-location';
import Routes from './app/router/routes';
import { LayoutSplashScreen, ThemeProvider } from './_metronic';
import I18nProvider from './i18n/I18nProvider';

const App = ({ store, persistor }) => (
  /* Provide Redux store */
  <Provider store={store}>
    {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
    <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
      {/* Override `basename` (e.g: `homepage` in `package.json`) */}
      <BrowserRouter>
        {/* This library only returns the location that has been active
         before the recent location change in the current window lifetime. */}
        <LastLocationProvider>
          {/* Provide Metronic theme overrides. */}
          <ThemeProvider>
            {/* Provide `react-intl` context synchronized with Redux state.  */}
            <I18nProvider>
              {/* Render routes with provided `Layout`. */}
              <Routes />
            </I18nProvider>
          </ThemeProvider>
        </LastLocationProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default App;
