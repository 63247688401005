import * as React from 'react';

const PhoneIcon = ({
  color,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill={color}
    className={className}
  >
    <path d="M19.957 15.496c-.057-.17-.417-.421-1.08-.752-.18-.105-.435-.247-.766-.427-.332-.18-.633-.345-.902-.497a30.13 30.13 0 01-.76-.44 18.655 18.655 0 00-.355-.249c-.2-.137-.367-.239-.505-.305a.93.93 0 00-.405-.1c-.19 0-.426.136-.71.405-.284.27-.544.564-.78.881-.237.318-.488.61-.753.88-.266.27-.483.406-.654.406a.966.966 0 01-.32-.071 3.313 3.313 0 01-.29-.12c-.067-.034-.18-.1-.341-.2a8.77 8.77 0 00-.27-.163c-1.298-.72-2.41-1.544-3.338-2.472-.928-.928-1.752-2.04-2.472-3.338a8.228 8.228 0 00-.163-.27 5.122 5.122 0 01-.2-.34 3.078 3.078 0 01-.12-.292.961.961 0 01-.07-.32c0-.17.134-.388.404-.653s.563-.516.88-.753c.318-.236.611-.497.881-.78.27-.285.405-.521.405-.71a.927.927 0 00-.1-.406 3.948 3.948 0 00-.305-.504c-.137-.199-.22-.317-.249-.355a31.2 31.2 0 01-.44-.76c-.151-.27-.317-.57-.497-.902-.18-.331-.322-.587-.426-.767C4.925.46 4.674.1 4.503.042A.796.796 0 004.205 0c-.256 0-.59.047-1.001.142A5.625 5.625 0 002.23.44c-.474.2-.976.777-1.506 1.733C.242 3.063 0 3.943 0 4.815c0 .255.017.504.05.746.033.241.093.513.178.816.085.303.154.529.206.675.052.147.149.41.29.788.143.38.228.611.256.696.332.928.725 1.757 1.18 2.486.747 1.212 1.768 2.465 3.06 3.757 1.293 1.293 2.545 2.313 3.757 3.061.73.455 1.558.848 2.486 1.18.085.028.317.113.696.255.379.142.641.24.788.291.147.053.372.121.675.207.303.085.575.144.817.177.241.033.49.05.745.05.871 0 1.752-.242 2.642-.724.957-.53 1.534-1.033 1.733-1.506.104-.237.203-.561.298-.973.095-.412.142-.746.142-1.002a.792.792 0 00-.042-.299z" />
  </svg>
);

export default PhoneIcon;
