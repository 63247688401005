import React from 'react';
import {
  Text, View, StyleSheet, Image
} from '@react-pdf/renderer';
import { addCommasToNumber } from '../../../../utils';
import { toAbsoluteUrl } from '../../../../../_metronic';

// Create styles
const styles = StyleSheet.create({
  h2: {
    fontSize: '20pt'
  },
  h6: {
    fontSize: '12pt',
    fontWeight: 'bold'
  },
  marginLeft: {
    marginLeft: 15
  },
  body: {
    fontSize: '10pt'
  },
  image: {
    width: '15px',
    height: '15px',
    padding: '3px',
    paddingTop: '1px',
    marginLeft: '5px',
    marginTop: '3px'
  }
});

const stripTags = (data) => {
  let temp = data.replace(/(<([^>]+)>)/ig, '');
  temp = temp.replaceAll('&nbsp', ' ');
  return temp;
};

// Create Document Component
const RFLessonsPDF = ({
  data,
  RFQuestion,
  RFAnswer,
  RFResponse,
  RFSelected,
  RFComparison,
}) => (
  <>
    <View style={{
      width: '100%',
      marginRight: '20pt',
      minHeight: '40px',
      borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
      borderTop: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
      paddingTop: '10px',
      paddingBottom: '10px',
      justifyContent: 'center',
      alignItems: 'center'
    }}
    >
      <Text style={styles.h2}>
        Recycling Fundamentals Quiz
      </Text>
    </View>

    <View style={{
      width: '100%',
      marginRight: '20pt',
      minHeight: '25px',
      flexDirection: 'row',
      borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
      marginTop: '10px'
    }}
    >

      <View style={{
        width: '30%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingBottom: '10px',
        paddingLeft: '5px'
      }}
      >
        <Text style={styles.h6}>
          {RFQuestion}
        </Text>
      </View>

      <View style={{
        width: '15%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        paddingLeft: '5px',
        paddingBottom: '10px',
      }}
      >
        <Text style={styles.h6}>
          {RFAnswer}
        </Text>
      </View>

      <View style={{
        width: '15%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '10px',
      }}
      >
        <Text style={styles.h6}>
          {RFResponse}
        </Text>
      </View>

      <View style={{
        width: '15%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '10px',
      }}
      >
        <Text style={styles.h6}>
          {RFSelected}
        </Text>
      </View>

      <View style={{
        width: '25%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '10px',
      }}
      >
        <Text style={[styles.h6]}>
          {RFComparison}
        </Text>
      </View>

    </View>
    {
      data.map((item, index) => {
        let sum = 0;
        /* eslint-disable no-unused-expressions */
        Object.values(item)
          && Object.values(item)
            .filter((ans) => typeof (ans) === 'object' && ans.name)
            .map((ans) => {
              sum += Number(ans.comparison);
              return null;
            });
        return (
          <View
            key={`sp_material_pdf_item_${String(index)}`}
            style={{
              flexGrow: 1,
              width: '100%',
              minHeight: '40px',
              flexDirection: 'row',
              borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
              backgroundColor: index % 2 ? process.env.REACT_APP_COLOR_PRIMARY_BG : null,
              paddingTop: '5px',
              paddingBottom: '5x'
            }}
          >
            <View style={{
              width: '30%',
              justifyContent: 'center',
              alignItems: 'flex-start',
              paddingRight: '5pt',
              paddingLeft: '5pt',
            }}
            >
              <Text style={styles.body}>
                {`${index + 1}.${stripTags(item.poll_quiz_question) || ''}`}
              </Text>
            </View>

            <View style={{
              width: '70%',
              justifyContent: 'center',
              alignItems: 'flex-start',
              flexDirection: 'column',
              paddingLeft: '5px'
            }}
            >
              { Object.values(item)
                && Object.values(item)
                  .filter((ans) => typeof (ans) === 'object' && ans.name)
                  .map((ans, index) => (
                    <View
                      key={`RF_quiz${ans.name} - ${String(index)}`}
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <View
                        style={{
                          width: '24.5%',
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Text style={[styles.body, {
                          marginTop: '5pt',
                          color: ans.is_correct_answer === 't' ? '#60D008' : '#404040'
                        }]}
                        >
                          {ans.name || ''}
                        </Text>
                        { ans.is_correct_answer === 't'
                        && (
                        <Image
                          style={styles.image}
                          src={`${toAbsoluteUrl('/media/icons/correctAnswer.png')}`}
                        />
                        )}
                      </View>

                      <View
                        style={{
                          width: '19.5%',
                        }}
                      >
                        <Text style={[styles.body, {
                          marginTop: '5pt',
                          paddingRight: '22pt',
                          textAlign: 'center'
                        }]}
                        >
                          {ans.value ? addCommasToNumber(ans.value) : 0}
                        </Text>
                      </View>

                      <View
                        style={{
                          width: '25.5%',
                        }}
                      >
                        <Text style={[styles.body, {
                          marginTop: '5pt',
                          paddingRight: '25pt',
                          textAlign: 'center'
                        }]}
                        >
                          {ans.percent ? `${ans.percent}%` : '0%'}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: '31.5%',
                        }}
                      >
                        <Text style={[styles.body, {
                          marginTop: '5pt',
                          paddingRight: '30pt',
                          textAlign: 'center'
                        }]}
                        >
                          {ans.comparison ? `${((Number(ans.comparison) * 100) / sum).toFixed(2)}%` : '0%'}
                        </Text>
                      </View>

                    </View>
                  ))}
            </View>

          </View>
        );
      })
    }
  </>
);
export default RFLessonsPDF;
