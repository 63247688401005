import React from 'react';
import {
  Document, Page, Text, View, StyleSheet, Image
} from '@react-pdf/renderer';
import { toAbsoluteUrl } from '../../../../../_metronic';
import SummaryPDF from './SummaryPDF';
import WWTKDetailPDF from './WWTKDetailPDF';
import SurveyDetailPDF from './SurveyDetailPDF';
import SpotlightReportPDF from './SpotlightReportPDF';
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    paddingVertical: 5
  },
  summaryHead: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 10,
    marginBottom: 0,
    padding: 10,
  },
  headerText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'black',
  },
  subTitleText: {
    fontSize: 12,
    color: 'black'
  },
  marginLeft: {
    marginLeft: 15
  },
  image: {
    width: '110px',
    height: '40px',
    marginLeft: '13px',
    marginTop: '5px',
  }
});

// Create Document Component
const ReportPDF = ({
  title,
  currentPeriod,
  date,
  district,
  wwtkAnswerTitle,
  wwtkAnswerValue,
  uniqueUserTitle,
  uniqueUserValue,
  correctAnswerTitle,
  correctAnswerValue,
  appInstallTitle,
  appInstallValue,
  onboardingSurveyTitle,
  onboardingSurveyValue,
  userCompleteSurveyTitle,
  userCompleteSurveyValue,
  PDFDataDetail,
  wwtkDetailStartDate,
  wwtkDetailQuestion,
  wwtkDetailType,
  wwtkDetailAnswer,
  wwtkDetailResponse,
  wwtkDetailPercent,
  surveyDetailQuestion,
  surveyDetailAnswers,
  surveyDetailResponse,
  surveyDetailPercent,
  spMaterialName,
  spMaterialView,
  isCounty
}) => (
  <Document>
    {/* summary 's PDF */}
    <Page size="A4" orientation="landscape" style={styles.page}>
      <>
        <Image
          style={styles.image}
          src={`${toAbsoluteUrl('/media/logos/RCLogo.png')}`}
        />
        <View style={styles.summaryHead}>
          <Text style={styles.headerText}>
            {title}
          </Text>

          <Text style={[styles.subTitleText, styles.marginLeft,
            {
              color: process.env.REACT_APP_COLOR_DARK_GRAY,
            }
          ]}
          >
            {currentPeriod}
          </Text>

          <Text style={[styles.subTitleText, styles.marginLeft,
            { color: process.env.REACT_APP_COLOR_PRIMARY }]}
          >
            {date}
          </Text>

          <Text style={[styles.subTitleText, {
            marginLeft: isCounty ? 100 : 150
          }]}
          >
            {district}
          </Text>

        </View>
        <SummaryPDF
          wwtkAnswerTitle={wwtkAnswerTitle}
          wwtkAnswerValue={wwtkAnswerValue}
          uniqueUserTitle={uniqueUserTitle}
          uniqueUserValue={uniqueUserValue}
          correctAnswerTitle={correctAnswerValue}
          correctAnswerValue={correctAnswerTitle}
          appInstallTitle={appInstallTitle}
          appInstallValue={appInstallValue}
          onboardingSurveyTitle={onboardingSurveyTitle}
          onboardingSurveyValue={onboardingSurveyValue}
          userCompleteSurveyTitle={userCompleteSurveyTitle}
          userCompleteSurveyValue={userCompleteSurveyValue}
        />
        {/* detail  */}

        {/* Start WWTK  */}
        <View style={{
          width: '100%',
          minHeight: '30px',
          marginRight: '20pt',
          flexDirection: 'column',
        }}
        >
          <WWTKDetailPDF
            data={PDFDataDetail.wwtk}
            wwtkDetailStartDate={wwtkDetailStartDate}
            wwtkDetailQuestion={wwtkDetailQuestion}
            wwtkDetailType={wwtkDetailType}
            wwtkDetailAnswer={wwtkDetailAnswer}
            wwtkDetailResponse={wwtkDetailResponse}
            wwtkDetailPercent={wwtkDetailPercent}
          />
        </View>
        {/* End WWTK  */}

        {/* Start Survey  */}
        <View style={{
          width: '100%',
          minHeight: '30px',
          marginRight: '20pt',
          flexDirection: 'column',
        }}
        >
          <SurveyDetailPDF
            data={PDFDataDetail.survey}
            surveyDetailQuestion={surveyDetailQuestion}
            surveyDetailAnswers={surveyDetailAnswers}
            surveyDetailResponse={surveyDetailResponse}
            surveyDetailPercent={surveyDetailPercent}
          />
        </View>
        {/* End Survey  */}

        {/* Start Spotlight Material  */}
        <View style={{
          width: '100%',
          minHeight: '30px',
          marginRight: '20pt',
          flexDirection: 'column',
        }}
        >
          <SpotlightReportPDF
            data={PDFDataDetail.sp_materials}
            spMaterialName={spMaterialName}
            spMaterialView={spMaterialView}
          />
        </View>
        {/* End Spotlight Material  */}

      </>
    </Page>
  </Document>
);
export default ReportPDF;
