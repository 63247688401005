const actionTypes = {
  SET_TAB: 'SET_TAB',
  SET_ALLOCATION_CODE: 'SET_ALLOCATION_CODE',
  SET_MAJOR_CAMPAIGN: 'SET_MAJOR_CAMPAIGN',
  SET_CURRENT_QUESTION_INDEX: 'SET_CURRENT_QUESTION_INDEX',
  GET_MAJOR_CAMPAIGNS_DATA_REQUEST: 'GET_MAJOR_CAMPAIGNS_DATA_REQUEST',
  GET_MAJOR_CAMPAIGNS_DATA_RECEIVE: 'GET_MAJOR_CAMPAIGNS_DATA_RECEIVE',
  GET_MAJOR_CAMPAIGNS_DATA_FAIL: 'GET_MAJOR_CAMPAIGNS_DATA_FAIL',
  GET_MAJOR_CAMPAIGNS_DETAILS_DATA_REQUEST: 'GET_MAJOR_CAMPAIGNS_DETAILS_DATA_REQUEST',
  GET_MAJOR_CAMPAIGNS_DETAILS_DATA_RECEIVE: 'GET_MAJOR_CAMPAIGNS_DETAILS_DATA_RECEIVE',
  GET_MAJOR_CAMPAIGNS_DETAILS_DATA_FAIL: 'GET_MAJOR_CAMPAIGNS_DETAILS_DATA_FAIL',
  GET_MAJOR_CAMPAIGNS_REPORTS_DATA_REQUEST: 'GET_MAJOR_CAMPAIGNS_REPORTS_DATA_REQUEST',
  GET_MAJOR_CAMPAIGNS_REPORTS_DATA_RECEIVE: 'GET_MAJOR_CAMPAIGNS_REPORTS_DATA_RECEIVE',
  GET_MAJOR_CAMPAIGNS_REPORTS_DATA_FAIL: 'GET_MAJOR_CAMPAIGNS_REPORTS_DATA_FAIL',
};

export default actionTypes;
