import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../../redux/createReducer';
import initialState from './store';

const problemReportReducer = persistReducer(
  { storage, key: 'problemReport', whitelist: [] },
  createReducer(initialState, {
    SET_PROBLEM_REPORT_LOADING_ACTION: (state, { payload }) => Immutable({
      ...state,
      fetchState: payload,
    }),

    GET_PROBLEM_REPORT_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      data: payload.data.data.data,
      helpItems: payload.data.data.help,
      firstOtherProblemTypeItems: payload.data.data.first_other_problem_items ? payload.data.data.first_other_problem_items.title : '',
      fetchState: 'succeed-request'
    }),

    GET_PROBLEM_REPORT_FAIL: (state) => Immutable({
      ...state,
      fetchState: 'failed-request'
    }),

    GET_PROBLEM_REPORT_SUMMARY_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      summaryData: payload.data.data,
      fetchState: 'succeed-request'
    }),

    GET_PROBLEM_REPORT_SUMMARY_FAIL: (state) => Immutable({
      ...state,
      fetchState: 'failed-request'
    }),
  })
);

export default problemReportReducer;
