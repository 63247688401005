import cts from '../../../../crud/cts';

export const getFeaturedLessonData = async ({ project_id, district_id }) => cts(
  'post',
  `/campaigns/get-all`,
  {
    district_id: `${project_id}#$#${district_id}`
  }
);

// export { getFeaturedLessonData, getFeaturedLessonData as default };


/**
 * Blog API to mark a featuredLessont a read
 */
 export const featuredLessonReadRequest = async ({ campaign_id }) => cts(
  'post',
  `/campaigns/toggle-read?campaign_id=${campaign_id}`,
  {
    campaign_id,
  }
);

/**
 * Blog API to unead a featured lesson
 */
export const featuredLessonUnreadRequest = async ({ campaign_id }) => cts(
  'post',
  `/campaigns/toggle-read?campaign_id=${campaign_id}`,
  {
    campaign_id,
  }
);

/**
 * Blog API to leave feedback
 */
export const feedbackRequest = async ({ meta, feedback, type }) => cts(
  'post',
  'feedback',
  {
    type,
    meta,
    feedback
  }
);

