import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  CustomButton,
} from '../../../../components';

const useStyles = makeStyles(() => ({
  viewButton: {
    maxWidth: 456,
  },
  promoBold: {
    color: 'rgba(64, 64, 64, 0.80)',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.56px'
  }
}));
const MajorCampaignsPromo = ({
  intl,
  handleViewCampaigns,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-5" variant="educationBody">
        <span className={classes.promoBold}>
          {intl.formatMessage({ id: 'EDUCATION.MAJOR.CAMPAIGNS.PROMO.1' })}
        </span>
        <span>
          {intl.formatMessage({ id: 'EDUCATION.MAJOR.CAMPAIGNS.PROMO.1.1' })}
        </span>
      </Typography>
      <Typography color={process.env.REACT_APP_COLOR_TEXT} className={`mb-1 ${classes.promoBold}`} variant="educationBody">
        {' '}
        {intl.formatMessage({ id: 'EDUCATION.MAJOR.CAMPAIGNS.ABOUT' })}
      </Typography>
      <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-5" variant="educationBody">
        {intl.formatMessage({ id: 'EDUCATION.MAJOR.CAMPAIGNS.ABOUT.PROMO.1' })}
      </Typography>
      {/* <CustomButton
        paddingVertical={4}
        className={classes.viewButton}
        variant={'contained'}
        label={'View upcoming Major Campaign topics and content '}
        onClick={() => {
          handleViewCampaigns();
        }}
      /> */}
    </div>
  );
};

export default injectIntl(MajorCampaignsPromo);
