import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link, useHistory } from 'react-router-dom';
import { EducationModal } from '../../components/index';
import pencilImage from '../../../../../assets/images/pencil.png';



import {
  Opacity, Typography
} from '../../../../components';

import { changeCurrentMonthsIndexHandler, findCurrentMonthsDataIndexHandler } from '../../../../../utils';
import * as actions from '../redux/actions';
import { CrownIcon } from '../../../../../assets/icons';


const useStyles = makeStyles(() => ({
  materialTitle: {
    color: '#0086C2'
  },
  materialParentContainer: {
    marginBottom: 20
  },
  materialInnerContainer: {
    display: 'flex'
  },
  materialImage: {
    height: 200,
    width: 200
  },
  materialContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flexStart',
    marginLeft: 20
  },
  materialBody: {
    marginTop: '0 !important',
    marginLeft: '0 !important',
    marginBottom: '0 !important',
    color: 'rgba(64, 64, 64, 0.8)'
  },
  materialSubTitle: {
    marginBottom: '10px!important'
  }


}));

const Main = ({
  intl,
  currentQuestionIndex,
  setCurrentQuestionAction,
  whereDoesThisGo,
  isUserPro,
  stage,
  loading,
  hasSpotLightMaterial
}) => {
  const classes = useStyles();

  const [isDisplayingModal, setIsDisplayingModal] = useState();
  const history = useHistory();


  const changeCurrentQuestionIndexHandler = (direction) => {
    setCurrentQuestionAction(
      changeCurrentMonthsIndexHandler(direction, currentQuestionIndex, whereDoesThisGo.length)
    );
  };

  useEffect(() => {
    setCurrentQuestionAction(findCurrentMonthsDataIndexHandler(whereDoesThisGo));
  }, []);

  return (
    <Opacity slide>
      {hasSpotLightMaterial || isUserPro && stage >= 4 && (
        <>
          <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="h1">
            {intl.formatMessage({ id: 'EDUCATION.WHERE.DOES.THIS.GO.TITLE' })}
            <CrownIcon style={{ marginLeft: 7 }} width={15} height={15} color="#FFC611" />

          </Typography>
          <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="educationBody">
            {intl.formatMessage({ id: 'EDUCATION.WHERE.DOES.THIS.GO.SUBTITLE' })}
          </Typography>
        </>
      )}
      {
        !loading && whereDoesThisGo && whereDoesThisGo.length && whereDoesThisGo.length >= currentQuestionIndex && (
          <>
            <Typography className={`mb-4`} variant="h2">
              Featured Items
              {' '}
              {!isUserPro && stage < 4 && 'which are displayed to PRO Users '}
            </Typography>

            {/* navigation buttons */}
            <div style={{ transform: 'translateX(-10px)', marginBottom: 10 }} className="d-flex align-items-center mt-2">
              <NavigateBeforeIcon
                onClick={() => changeCurrentQuestionIndexHandler('backward')}
                fontSize="large"
                style={{
                  transition: 'all .3s',
                  cursor: currentQuestionIndex === whereDoesThisGo.length - 1 ? 'not-allowed' : 'pointer',
                  opacity: currentQuestionIndex === whereDoesThisGo.length - 1 ? 0.5 : 1,
                  color: '#757575'
                }}
                disabled={currentQuestionIndex === whereDoesThisGo.length - 1}
              />
              <Opacity key={currentQuestionIndex}>
                <Typography style={{ width: 100, textAlign: 'center' }} color="#404040" variant="bodySemiBold">
                  {whereDoesThisGo[currentQuestionIndex].month}
                </Typography>
              </Opacity>
              <NavigateNextIcon
                onClick={() => changeCurrentQuestionIndexHandler('forward')}
                fontSize="large"
                style={{
                  transition: 'all .3s',
                  cursor: currentQuestionIndex === 0 ? 'not-allowed' : 'pointer',
                  opacity: currentQuestionIndex === 0 ? 0.5 : 1,
                  color: '#757575'
                }}
                disabled={currentQuestionIndex === 0}

              />
            </div>

            <div>
              {
                whereDoesThisGo[currentQuestionIndex].materials.map((material) => {
                  return (
                    <div className="mb-4">
                      <Typography className={`mb-4 ${classes.materialTitle}`} variant="h2">
                        {material.name}
                      </Typography>
                      <div className={`${classes.materialInnerContainer}`}>
                        <img src={material.image} alt={material.name} className={`${classes.materialImage}`} />
                        <div className={`${classes.materialContent}`}>
                          <Typography color={process.env.REACT_APP_COLOR_TEXT} className={`${classes.materialSubTitle}`} variant="h1">
                            {material.category_header}
                          </Typography>
                          <Typography
                            className={`${classes.materialBody}`}
                            variant="educationBody"
                            dangerouslySetInnerHTML={{ __html: material.category_body }}
                          />
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <div>
              <Link to={'/what-goes-where/home'}>
                Learn how to edit material descriptions
              </Link>
            </div>
            <div>
              <Link onClick={() => { setIsDisplayingModal(true) }}>
                Change selected materials
              </Link>
            </div>
            <EducationModal
              open={isDisplayingModal}
              icon={<img style={{ height: 64, width: 64 }} src={pencilImage} alt="pencil" />}
              onClose={() => setIsDisplayingModal(false)}
              title="Edit the text"
              content={<div>Where does it go? text comes from your What Goes Where database. To change a material description, please visit the What Goes Where section of this Client Portal.</div>}
              onClickHandler={() => history.push('/what-goes-where/home')}
              buttonApply="Go to What Goes Where"
              buttonApply2="Later"
              buttonDirections="column"
              buttonVariant1="contained"
              buttonVariant2="outlined"
            />
          </>
        )
      }


    </Opacity>
  );
};

const mapStateToProps = (store) => ({
  currentQuestionIndex: store.whereDoesThisGo.currentQuestionIndex,
  whereDoesThisGo: store.whereDoesThisGo.spotlight_materials,
  loading: store.whereDoesThisGo.loading,

});

export default injectIntl(connect(mapStateToProps, actions)(Main));
