import cts from '../../crud/cts';

/**
 * Login API which authenticates users
 * @param {string} username User's username used for authentication
 * @param {string} password User's password used for authentication
 */
export const login = async ({ username, password }) => cts(
  'post',
  'users/login',
  {
    user_name: username,
    pass_word: password
  }
);

export const loginAs = async ({ key }) => cts(
  'post',
  'users/login-as',
  { key }
);

/**
 * Determines the method of sending OTP
 * @param {string} type Multi Factor Authentication type. There are three types:
 * 'send-mail', 'send-sms', 'send-voice'
 */
export const sendMFA = async ({ type }) => cts(
  'post',
  'users/check-mfa',
  {
    mfa_way: type
  }
);

/**
 * Sends OTP for user authorization
 * @param {string} otp One-time password sent to user via specified method in sendMFA API
 */
export const sendOTP = async ({ otp }) => cts(
  'post',
  'users/validation-mfa',
  {
    otp_pass: otp
  }
);

/**
 * Sends email in order to verify it for resetting password
 * @param {string} email User's email which his/her account is registered with it
 */
export const sendResetLinkToEmail = async ({ email }) => cts(
  'post',
  'users/reset-password',
  {
    email
  }
);

/**
 * Return user's full name
 * @param {string} token Token sent back from sendResetLinkToEmail in order to authorize user
 */
export const getUserFullName = async ({ token }) => cts(
  'post',
  'users/check-reset-password',
  {
    token
  }
);

/**
 * Reset user password with new password
 * @param {string} password New password that user wants to set
 * @param {string} token Token sent back from sendResetLinkToEmail in order to authorize user
 */
export const resetPassword = async ({ newPassword, newPasswordRepeat, token }) => cts(
  'post',
  'users/change-password',
  {
    new_password: newPassword,
    new_password_repeat: newPasswordRepeat,
    token
  }
);

/**
 * Logout API which redirects user to login page.
 */
export const logout = async () => cts(
  'post',
  'users/logout'
);

/**
 * Profile API which fetches editing user profile
 */
export const editProfile = async ({ user }) => cts(
  'post',
  'users/edit-profile',
  {
    ...user
  }
);

/**
 * Profile API which uploads user image as profile picture
 */
export const editProfilePicture = async ({ image }) => {
  const formData = new FormData();
  formData.append('image_profile', image);
  return cts(
    'post',
    'users/upload-image',
    formData,
  );
};
