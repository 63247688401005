import actionTypes from './actionTypes';

export const getFeaturedLessonsDataRequestAction = (project_id, district_id) => ({
  type: actionTypes.GET_FEATURED_LESSONS_DATA_REQUEST,
  payload: { project_id, district_id }
});

export const getFeaturedLessonsDataReceiveAction = (data) => ({
  type: actionTypes.GET_FEATURED_LESSONS_DATA_RECEIVE,
  payload: data
});

export const getFeaturedLessonsDataFailAction = (error) => ({
  type: actionTypes.GET_FEATURED_LESSONS_DATA_FAIL,
  payload: error
});


export const featuredLessonReadRequest = (campaign_id) => ({
  type: actionTypes.FEATURED_LESSON_READ_REQUEST,
  payload: { campaign_id }
});

export const featuredLessonReadReceive = (data) => ({
  type: actionTypes.FEATURED_LESSON_READ_RECEIVE,
  payload: data
});

export const featuredLessonReadFail = (error) => ({
  type: actionTypes.FEATURED_LESSON_READ_FAIL,
  payload: error
});

export const featuredLessonUnreadRequest = (campaign_id) => ({
  type: actionTypes.FEATURED_LESSON_UNREAD_REQUEST,
  payload: { campaign_id }
});

export const featuredLessonUnreadReceive = (data) => ({
  type: actionTypes.FEATURED_LESSON_UNREAD_RECEIVE,
  payload: data
});

export const featuredLessonUnreadFail = (error) => ({
  type: actionTypes.FEATURED_LESSON_UNREAD_FAIL,
  payload: error
});


export const featuredLessonFeedbackRequest = (meta, feedback, type = 'campaign') => ({
  type: actionTypes.FEATURED_LESSON_FEEDBACK_REQUEST,
  payload: { meta, feedback, type }
});

export const featuredLessonFeedbackdReceive = (data) => ({
  type: actionTypes.FEATURED_LESSON_FEEDBACK_RECEIVE,
  payload: data
});

export const featuredLessonFeedbackFail = (error) => ({
  type: actionTypes.FEATURED_LESSON_FEEDBACK_FAIL,
  payload: error
});
