import React from 'react';
import { Text, View, StyleSheet, } from '@react-pdf/renderer';
import { addCommasToNumber } from '../../../../utils';
// Create styles
const styles = StyleSheet.create({
  h2: {
    fontSize: '20pt'
  },
  h6: {
    fontSize: '12pt'
  },
  marginLeft: {
    marginLeft: 15
  },
  body: {
    fontSize: '10pt'
  },
  image: {
    width: '110px',
    height: '40px',
    marginLeft: '13px',
    marginTop: '5px',
  }
});

const stripTags = (data) => {
  let temp = data.replace(/(<([^>]+)>)/ig, '');
  temp = temp.replaceAll('&nbsp', ' ');
  return temp;
};

// Create Document Component
const SurveyDetailPDF = ({
  data,
  surveyDetailQuestion,
  surveyDetailAnswers,
  surveyDetailResponse,
  surveyDetailPercent,
}) => (
  <>
    <View style={{
      width: '100%',
      marginRight: '20pt',
      minHeight: '40px',
      borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
      paddingTop: '10px',
      paddingBottom: '10px',
      justifyContent: 'center',
      alignItems: 'center'
    }}
    >
      <Text style={styles.h2}>
        On-boarding Survey
      </Text>
    </View>
    <View style={{
      width: '100%',
      marginRight: '20pt',
      minHeight: '25px',
      flexDirection: 'row',
      borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
      marginTop: '10px'
    }}
    >

      <View style={{
        width: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingBottom: '10px',
        paddingLeft: '5px'
      }}
      >
        <Text style={styles.h6}>
          {surveyDetailQuestion}
        </Text>
      </View>

      <View style={{
        width: '30%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingBottom: '10px',
      }}
      >
        <Text style={styles.h6}>
          {surveyDetailAnswers}
        </Text>
      </View>

      <View style={{
        width: '10%',
        justifyContent: 'flex-start',
        alignItems: 'center'
      }}
      >
        <Text style={styles.h6}>
          {surveyDetailResponse}
        </Text>
      </View>

      <View style={{
        width: '10%',
        justifyContent: 'flex-start',
        alignItems: 'center'
      }}
      >
        <Text style={styles.h6}>
          {surveyDetailPercent}
        </Text>
      </View>

    </View>
    {
        data && data.map((item, index) => (
          <View
            key={`survey_pdf_item_${String(index)}`}
            style={{
              flexGrow: 1,
              width: '100%',
              minHeight: '40px',
              flexDirection: 'row',
              borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
              paddingBottom: '10px',
              backgroundColor: index % 2 ? process.env.REACT_APP_COLOR_PRIMARY_BG : null
            }}
          >
            <View style={{
              width: '50%',
              justifyContent: 'center',
              alignItems: 'flex-start',
              paddingRight: '5pt',
              paddingLeft: '5pt',
            }}
            >
              <Text style={styles.body}>
                {stripTags(item.poll_quiz_question) || ''}
              </Text>
            </View>

            <View style={{
              width: '30%',
              justifyContent: 'center',
              alignItems: 'flex-start',
              flexDirection: 'column'
            }}
            >
              {
               item.options && item.options.map((opt, id) => (
                 <Text
                   key={`survey_opt_pdf_item_${String(id)}`}
                   style={[styles.body, { marginTop: '5px' }]}
                 >
                   {opt.name || ''}
                 </Text>
               ))
               }
            </View>
            <View style={{
              width: '10%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
            >
              {
               item.options && item.options.map((opt, id) => (
                 <Text
                   key={`survey_opt_val_pdf_item_${String(id)}`}
                   style={[styles.body, { marginTop: '5px' }]}
                 >
                   {opt.value ? `${addCommasToNumber(opt.value)}` : 0}
                 </Text>
               ))
               }
            </View>
            <View style={{
              width: '10%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
            >
              {
               item.options && item.options.map((opt, id) => (
                 <Text
                   key={`survey_opt_percent_pdf_item_${String(id)}`}
                   style={[styles.body, { marginTop: '5px' }]}
                 >
                   {opt.value ? `${((opt.value * 100) / item.sum).toFixed(2)} %` : '0%'}
                 </Text>
               ))
               }
            </View>

          </View>
        ))
    }
  </>
);
export default SurveyDetailPDF;
