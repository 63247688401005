import React from 'react';
import { injectIntl } from 'react-intl';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../../../_metronic/partials/content/Portlet';
import { Switch } from '../../components';

const History = ({
  intl,
  title,
  isActive,
  onChange,
  children,
}) => (
  <div className="col-xl-6">
    <div className="mr-2 h-100 position-relative">
      <Portlet fluidHeight>
        <PortletHeader
          title={title}
          toolbar={(
            <PortletHeaderToolbar>
              <Switch
                isActive={isActive}
                onChange={onChange}
                leftLabel={intl.formatMessage({ id: 'GENERAL.ACTIVE' })}
                rightLabel={intl.formatMessage({ id: 'GENERAL.PAST' })}
              />
            </PortletHeaderToolbar>
          )}
        />
        <PortletBody>
          {children}
        </PortletBody>
      </Portlet>
    </div>
  </div>
);

export default injectIntl(History);
