export default {
  header: {
    self: {},
    items: [
      {
        title: 'Home', // dashboard renamed to home-page
        root: true,
        alignment: 'left',
        page: 'home-page',
        translate: 'MENU.DASHBOARD',
      },
      {
        title: 'Education',
        root: true,
        alignment: 'left',
        page: 'education',
        translate: 'MENU.EDUCATION',
      },
      {
        title: 'Notifications',
        root: true,
        alignment: 'left',
        page: 'notification',
        translate: 'MENU.NOTIFICATION',
      },
      {
        title: 'Reminder Messages',
        root: true,
        alignment: 'left',
        page: 'reminder',
        translate: 'MENU.REMINDER',
      },
      {
        title: 'Users',
        root: true,
        alignment: 'left',
        page: 'users',
        translate: 'MENU.USERS',
      },
      {
        title: 'Reports',
        root: true,
        alignment: 'left',
        toggle: 'hover',
        translate: 'MENU.REPORTS',
        submenu: [
          {
            title: 'Reports Home',
            bullet: 'dot',
            page: 'report/dashboard',
            translate: 'MENU.REPORTS',
          },
          {
            title: 'Dashboard',
            bullet: 'dot',
            page: 'report/dashboard',
            translate: 'MENU.REPORTS.BADGE',
          },
          {
            title: 'Learning Reports',
            root: true,
            bullet: 'dot',
            alignment: 'left',
            toggle: 'hover',
            translate: 'MENU.REPORTS',
            submenu: [
              {
                title: 'Learning Activities Report',
                bullet: 'dot',
                page: 'report/discovery-zone',
                // isUserPro: true,
                translate: 'MENU.REPORTS.DISCOVERY_ZONE',
              },
              {
                title: 'Featured Lesson Report',
                bullet: 'dot',
                page: 'report/campaign',
                translate: 'MENU.REPORTS.CAMPAIGN',
              },
              {
                title: 'Knowledge Measurement Report',
                bullet: 'dot',
                page: 'report/resident-knowledge-indicator',
                translate: 'MENU.REPORTS.RESIDENT_KNOWLEDGE_INDICATOR',
              },
              {
                title: 'Material Searches Report',
                bullet: 'dot',
                page: 'report/material',
                translate: 'MENU.REPORTS.MATERIAL',
              },
              {
                title: 'Page Views Report',
                bullet: 'dot',
                page: 'report/page-views',
                translate: 'MENU.REPORTS.PAGE_VIEWS',
              },
              {
                title: 'Annual National Survey',
                bullet: 'dot',
                page: 'report/annual-survey',
                translate: 'MENU.REPORTS.BADGE',
              },
            ],
          },
          {
            title: 'Activity Reports',
            root: true,
            alignment: 'left',
            bullet: 'dot',

            toggle: 'hover',
            translate: 'MENU.REPORTS',
            submenu: [
              // {
              //   title: 'Users Report',
              //   bullet: 'dot',
              //   page: 'report/user',
              //   translate: 'MENU.REPORTS.USER',
              // },
              {
                title: 'Interactions Report',
                bullet: 'dot',
                page: 'report/interaction',
                translate: 'MENU.REPORTS.INTERACTION',
              },
              {
                title: 'New Subscriber Report',
                bullet: 'dot',
                page: 'report/new-subscribers',
                translate: 'MENU.REPORTS.NEW_SUBSCRIBERS',
              },
              {
                title: 'Problems Reported',
                bullet: 'dot',
                page: 'report/problem',
                translate: 'MENU.REPORTS.PROBLEM',
              },
              {
                title: 'Bulk Pickup Requests',
                bullet: 'dot',
                page: 'report/bulk-pickup/detail',
                translate: 'MENU.REPORTS.BULK.PICKUP',
              },
              {
                title: 'Badge Report',
                bullet: 'dot',
                page: 'report/badge',
                translate: 'MENU.REPORTS.BADGE',
              },
            ],
          },
        ],
      },
      // {
      //   title: 'Campaigns',
      //   root: true,
      //   alignment: 'left',
      //   page: 'campaigns',
      //   translate: 'MENU.CAMPAIGN'
      // },
      {
        title: 'What Goes Where',
        root: true,
        alignment: 'left',
        toggle: 'hover',
        page: 'report/wgw-material/detail',
        translate: 'MENU.WHAT_GOES_WHERE',
        submenu: [
          {
            title: 'What Goes Where Dashboard',
            bullet: 'dot',
            page: 'report/wgw-material/detail',
            translate: 'MENU.WHAT.GOES.WHERE.DASHBOARD',
          },
          {
            title: 'Getting Started/User Guides',
            bullet: 'dot',
            page: 'what-goes-where/home',
            translate: 'MENU.GET_STARTED_USER_GUIDES',
          },
          {
            title: 'Waste Types',
            bullet: 'dot',
            page: 'waste-type',
            translate: 'MENU.WASTE_TYPE',
          },
          {
            title: 'Categories',
            bullet: 'dot',
            page: 'category',
            translate: 'MENU.CATEGORY',
          },
          {
            title: 'Collection Requirements',
            bullet: 'dot',
            page: 'collection-requirement',
            translate: 'MENU.COLLECTION_REQUIREMENT',
          },
          {
            title: 'Depots',
            bullet: 'dot',
            page: 'depot',
            translate: 'MENU.DEPOT',
          },
          {
            title: 'Events',
            bullet: 'dot',
            page: 'event',
            translate: 'MENU.EVENT',
          },
          {
            title: 'Waste And Recycling Rules',
            bullet: 'dot',
            page: 'waste-recycling-rules',
            translate: 'MENU.WASTE_AND_RECYCLING_RULES',
          }
        ],
      },
      {
        title: 'Schedule',
        root: true,
        alignment: 'left',
        page: 'schedules',
        translate: 'MENU.SCHEDULE',
      },
      {
        title: 'Marketing Resources',
        root: true,
        alignment: 'left',
        toggle: 'hover',
        translate: 'MENU.MARKETING_SUPPORT',
        submenu: [
          {
            title: 'Browse By Material',
            isNotWorkplaceAdmin: true,
            bullet: 'dot',
            page: 'https://recyclingcalendars.sharepoint.com/:f:/s/RCExternal/EiN1akSvr3xCtYuisWlEz5oBQ-Ykr8-x7TpgX_8F7aMtVQ?e=tM4Cdu',
            translate: 'MENU.MARKETING_SUPPORT.BROWSE_BY_MATERIAL',
          },
          {
            title: 'Browse by Feature',
            isNotWorkplaceAdmin: true,
            bullet: 'dot',
            page: 'https://recyclingcalendars.sharepoint.com/:f:/s/RCExternal/EnIcOEpVQkFBuhAkIgjkyRoBup0_HGsLc6HUK-VhrdBXqQ?e=vfVJu5',
            translate: 'MENU.MARKETING_SUPPORT.BROWSE_BY_FEATURE',
          },
          {
            title: 'Browse by Campaign',
            isNotWorkplaceAdmin: true,
            isUserPro: true,
            bullet: 'dot',
            page: 'https://recyclingcalendars.sharepoint.com/:f:/s/RCExternal/EiHLg6YbcoxGikXQgiAyEKMBlxUsIVbOlCkQH2GS6Jvjmw?e=2jl31l',
            translate: 'MENU.MARKETING_SUPPORT.BROWSE_BY_CAMPAIGN',
          },
          {
            title: 'Monthly Social Planners',
            isNotWorkplaceAdmin: true,
            bullet: 'dot',
            page: 'https://recyclingcalendars.sharepoint.com/:f:/s/RCExternal/En4DMyhUCftIvEuF0CpqgRYBD4PBcIJpHKGy4_JVhEGlpw?e=Tmau7u',
            translate: 'MENU.MARKETING_SUPPORT.MONTHLY_SOCIAL_PLANNERS',
          },
          {
            title: 'Seasonal Assets',
            isNotWorkplaceAdmin: true,
            bullet: 'dot',
            page: 'https://recyclingcalendars.sharepoint.com/:f:/s/RCExternal/EtRr0K7pvpVIiWH5YVJFwvoBqc9GOW3Ol7pQt7OfF4IU1A?e=Dbr6Q9',
            translate: 'MENU.MARKETING_SUPPORT.SEASONAL_ASSETS',
          },
          {
            title: 'Guides, Best Practices & FAQs',
            isNotWorkplaceAdmin: true,
            bullet: 'dot',
            page: 'https://recyclingcalendars.sharepoint.com/:f:/s/RCExternal/Ersrjd9ZH2ZNrbIhOWGmIoIBgp2kgmg7i9qHoQJ4HjCadg?e=wBPuOE',
            translate: 'MENU.MARKETING_SUPPORT.GUIDES_BEST_PRACTICES_FAQ',
          },
          {
            title: 'Flyers, Posters and Handouts',
            isNotWorkplaceAdmin: true,
            bullet: 'dot',
            page: 'https://recyclingcalendars.sharepoint.com/:f:/s/RCExternal/EiKBy-4ZTklDubp4jxVUizwBrOY1PbVzxDxZ3N6M79UmNQ?e=JyT2dV',
            translate: 'MENU.MARKETING_SUPPORT.FLYERS_POSTERS_HANDOUTS',
          },
          {
            title: 'App Promotion and User Acquisition',
            isNotWorkplaceAdmin: true,
            bullet: 'dot',
            page: 'https://recyclingcalendars.sharepoint.com/:f:/s/RCExternal/EqOCGaQJpoFAk1vVhfii7xoBxR8ZhS4f1yNYRfUJXDlbvg?e=HUkkaY',
            translate: 'MENU.MARKETING_SUPPORT.APP_PROMOTION',
          },
          {
            title: 'Featured Partner: Foam Cycle',
            isNotWorkplaceAdmin: true,
            bullet: 'dot',
            page: 'https://recyclingcalendars.sharepoint.com/:f:/s/RCExternal/EjS7qU3ZRbdLqI-cRHjwlwkBsCglRs9mBzfu92YzOmj8cA?e=m2VeGs',
            translate: 'MENU.MARKETING_SUPPORT.FEATURES_PARTNERS',
          },
        ],
      },
      {
        title: 'Request Support',
        root: true,
        alignment: 'left',
        page: 'request-support',
        translate: 'MENU.REQUEST.SUPPORT',
      },
    ],
  },
};
