import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';
import { ProfileDropdown, ProfileImage } from '../../../app/pages/Auth/Profile/components';

const UserProfile = () => (
  <Dropdown
    className="kt-header__topbar-item kt-header__topbar-item--user"
    drop="down"
    alignRight
  >
    <Dropdown.Toggle
      as={HeaderDropdownToggle}
      id="dropdown-toggle-user-profile"
    >
      <div className="kt-header__topbar-user">
        <span className="kt-header__topbar-welcome kt-hidden-mobile">
          <ProfileImage />
        </span>
      </div>
    </Dropdown.Toggle>
    <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
      <ProfileDropdown />
    </Dropdown.Menu>
  </Dropdown>
);

export default UserProfile;
