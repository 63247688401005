/* eslint-disable react/no-danger */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  default: {
    color: process.env.REACT_APP_COLOR_TEXT
  },
  hero: {
    fontSize: '26px',
    fontWeight: 700
  },
  h1: {
    fontSize: '18px',
    fontWeight: 700,
  },
  h2: {
    fontSize: '15px',
    fontWeight: 600,
  },
  h2Medium: {
    fontSize: '15px',
    fontWeight: 500,
  },
  h2Large: {
    fontSize: '18px',
    fontWeight: 500,
  },
  body: {
    fontSize: '12px',
    fontWeight: 400,
  },
  bodySemiBold: {
    fontSize: '12px',
    fontWeight: 600,
  },
  subText: {
    fontSize: '10px',
    fontWeight: 500,
  },
  numberSmall: {
    fontSize: '26px',
    fontWeight: 600,
  },
  numberLarge: {
    fontSize: '35px',
    fontWeight: 600,
  },
  cta: {
    fontSize: '15px',
    fontWeight: 700,
  },
  educationBody: {
    fontSize: '14px',
    fontWeight: 400,
  },
  bodyTable: {
    fontSize: '14px',
    fontWeight: 400,
  },
  bodyTableSemiBold: {
    fontSize: '14px',
    fontWeight: 600,
  },
  // create custom font size and font weight, pass these values as props
  custom: {
    fontSize: ({ fontSize }) => fontSize,
    fontWeight: ({ fontWeight }) => fontWeight,
  },
  pdd: {
    fontSize: '14px',
    fontWeight: 600,
  },
}));

const Typography = ({
  children,
  fontSize,
  fontWeight,
  variant,
  className,
  color,
  style,
  component = 'div',
  dangerouslySetInnerHTML,
  onClick,
}) => {
  const classes = useStyles({ fontSize, fontWeight });

  if (component === 'span') {
    return (
      <span
        className={`${classes.default} ${classes[variant]} ${className}`}
        style={{ color: color || 'none', ...style }}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        onClick={onClick}
      >
        {children}
      </span>
    );
  }
  return (
    <div
      className={`${classes.default} ${classes[variant]} ${className}`}
      style={{ color: color || 'none', ...style }}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Typography;
