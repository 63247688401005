/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import objectPath from 'object-path';
import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MenuItemInner from './HMenuItemInner';
import excludedBCDistrictsFromNS from '../../../../app/pages/Dashboard/excludedBCDistrictsFromNS';

const HMenuItem = ({
  item,
  currentUrl,
  rootArrowEnabled,
  parentItem,
}) => {
  const history = useHistory();
  const advocateAdmin = useSelector(({ auth }) => auth.advocateAdmin);
  const isUserPro = useSelector(({ common }) => common.isUserPro);
  const municipalities = useSelector(({ common }) => common.municipalities);

  let exclude = false;

  // don't show NS for some districts
  if (municipalities.length > 0) {
    exclude = municipalities.some((item) => {
      const [projectId, districtId] = item.district_id.split('#$#');
      return excludedBCDistrictsFromNS.some(
        (item) => item.project_id === projectId && item.district_id === districtId
      );
    });
  }

  // route to related homepage if tab clicked
  const tabClickHandler = (title) => {
    if (title === 'Reports') {
      history.push('/report/dashboard');
    } else if (title === 'What Goes Where') {
      history.push('/report/wgw-material/detail');
    }
  };

  /**
   * Returns Attribute SubMenu Toggle
   * @param item: any
   */
  const getItemAttrSubmenuToggle = (item) => {
    const toggleEvent = objectPath.get(item, 'toggle');
    if (toggleEvent === 'click') {
      return 'click';
    }

    if (objectPath.get(item, 'submenu.type') === 'tabs') {
      return 'tabs';
    }

    return 'hover';
  };

  /**
   * Check Menu is active
   * @param item: any
   */
  const isMenuItemIsActive = (item) => {
    if (item.submenu) {
      return isMenuRootItemIsActive(item);
    }

    if (!item.page) {
      return false;
    }

    return currentUrl.indexOf(item.page) !== -1;
  };

  /**
   * Check Menu Root Item is active
   * @param item: any
   */
  const isMenuRootItemIsActive = (item) => {
    if (item.submenu && !item.submenu.type) {
      for (const subItem of item.submenu) {
        const isActive = isMenuItemIsActive(subItem);
        if (isActive) {
          return true;
        }
      }
      return false;
    }

    if (item.submenu && item.submenu.columns) {
      for (const column of item.submenu.columns) {
        for (const subItem of column.items) {
          const isActive = isMenuItemIsActive(subItem);
          if (isActive) {
            return true;
          }
        }
      }
      return false;
    }

    return false;
  };

  /**
   * Return Css Class Name
   * @param item: any
   */
  const getItemCssClasses = (item) => {
    let classes = 'kt-menu__item';

    if (objectPath.get(item, 'submenu')) {
      classes += ' kt-menu__item--submenu';
    }

    const isActive = isMenuItemIsActive(item);
    if (!item.submenu && isActive) {
      classes += ' kt-menu__item--active kt-menu__item--here';
    }

    if (item.submenu && isActive) {
      classes += ' kt-menu__item--open kt-menu__item--here';
    }

    if (objectPath.get(item, 'resizer')) {
      classes += ' kt-menu__item--resize';
    }

    const menuType = objectPath.get(item, 'submenu.type') || 'classic';
    if (
      (objectPath.get(item, 'root') && menuType === 'classic')
      || parseInt(objectPath.get(item, 'submenu.width'), 10) > 0
    ) {
      classes += ' kt-menu__item--rel';
    }

    const customClass = objectPath.get(item, 'custom-class');
    if (customClass) {
      classes += ` ${customClass}`;
    }

    if (objectPath.get(item, 'icon-only')) {
      classes += ' kt-menu__item--icon-only';
    }

    return classes;
  };

  /**
   * Returns Submenu CSS Class Name
   * @param item: any
   */
  const getItemMenuSubmenuClass = (item) => {
    let classes = '';

    const alignment = objectPath.get(item, 'alignment') || 'right';

    if (alignment) {
      classes += ` kt-menu__submenu--${alignment}`;
    }

    const type = objectPath.get(item, 'type') || 'classic';
    if (type === 'classic') {
      classes += ' kt-menu__submenu--classic';
    }
    if (type === 'tabs') {
      classes += ' kt-menu__submenu--tabs';
    }
    if (type === 'mega') {
      if (objectPath.get(item, 'width')) {
        classes += ' kt-menu__submenu--fixed';
      }
    }

    if (objectPath.get(item, 'pull')) {
      classes += ' kt-menu__submenu--pull';
    }

    return classes;
  };

  const shouldDisplay = () => {
    if (
      item.isUserPro
      && !isUserPro
    ) {
      return false;
    }

    if (
      advocateAdmin
      && item.isNotWorkplaceAdmin
    ) {
      return false;
    }

    if (
      !advocateAdmin
      && item.isWorkplaceAdmin
    ) {
      return false;
    }

    return true;
  };

  const isVisibleItem = shouldDisplay();

  return (
    <li
      className={getItemCssClasses(item)}
      data-ktmenu-submenu-toggle={getItemAttrSubmenuToggle(item)}
      data-ktmenu-submenu-mode={item.mode}
      data-ktmenu-dropdown-toggle-class={item['dropdown-toggle-class']}
    >
      {/* if item has submenu */}
      {item.submenu && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          className={`kt-menu__link ${clsx({
            'kt-menu__toggle': item.root || item.submenu
          })}`}
          role="button"
          onClick={() => tabClickHandler(item.title)}
        >
          <MenuItemInner
            item={item}
            parentItem={parentItem}
            currentUrl={currentUrl}
          />
          {rootArrowEnabled && (
            <>
              {item.submenu && item.root && (
                <>
                  <i className="kt-menu__hor-arrow la la-angle-down" />
                  <i className="kt-menu__ver-arrow la la-angle-right" />
                </>
              )}
            </>
          )}

          {item.submenu && !item.root && (
            <>
              <i className="kt-menu__hor-arrow la la-angle-right" />
              <i className="kt-menu__ver-arrow la la-angle-right" />
            </>
          )}
        </a>
      )}

      {/* if item hasn't submenu */}
      {
        !item.submenu
          && isVisibleItem ? (
          <>
            {
              item.page.startsWith('https://') ? (
                <a href={item.page} target="_blank" className="kt-menu__link" rel="noreferrer">
                  <MenuItemInner
                    item={item}
                    parentItem={parentItem}
                    currentUrl={currentUrl}
                  />
                </a>
              ) : (
                <Link to={`/${item.page || ''}`} className="kt-menu__link">
                  <MenuItemInner
                    item={item}
                    parentItem={parentItem}
                    currentUrl={currentUrl}
                  />
                </Link>
              )
            }
          </>
        ) : null
      }

      {/* if menu item has submenu child then recursively call new menu item component */}
      {
        item.submenu && (
          <div
            className={`kt-menu__submenu ${getItemMenuSubmenuClass(item)}`}
            style={{ width: item.submenu.width }}
          >
            <span
              className={`kt-menu__arrow ${clsx({
                'kt-menu__arrow--adjust': item.root
              })}`}
            />

            {item.submenu && item.submenu.length && (
              <ul className="kt-menu__subnav">
                {item.submenu.map((child, index) => {
                  if (
                    (child.title === 'Enhanced Education Report' && !isUserPro)
                    || (child.title === 'Enhanced Education Report' && advocateAdmin === true)
                    || (child.title === 'Knowledge Measurement Report' && advocateAdmin === true)
                    || (child.title === 'Badge Report' && advocateAdmin === true)
                    || (child.title === 'Campaigns Report' && advocateAdmin === true)
                    || (child.title === 'Problems Reported' && advocateAdmin === true)
                    || child.title === 'Reports Home'
                    || child.title === 'What Goes Where Home'
                    || (exclude && child.title === 'Annual National Survey')
                  ) {
                    return null;
                  }

                  return (
                    <React.Fragment key={`hmenuListInner${index}`}>
                      <HMenuItem
                        item={child}
                        parentItem={item}
                        currentUrl={currentUrl}
                        isUserPro={isUserPro}
                      />
                    </React.Fragment>
                  );
                })}
              </ul>
            )}

            {item.submenu.items && item.submenu.items.length && (
              <ul className="kt-menu__subnav">
                {item.submenu.items.map((child, index) => (
                  <React.Fragment key={`hmenuListInner2${index}`}>
                    <HMenuItem
                      item={child}
                      parentItem={item}
                      currentUrl={currentUrl}
                    />
                  </React.Fragment>
                ))}
              </ul>
            )}
          </div>
        )
      }
    </li>
  );
};

export default HMenuItem;
