const actionTypes = {
  // Auth actionTypes for setting loading status
  SET_LOADING: 'SET_LOADING',
  RESET_LOADING: 'RESET_LOADING',

  // Reset Password actionTypes for email verification
  EMAIL_VERIFICATION_REQUEST: 'EMAIL_VERIFICATION_REQUEST',
  EMAIL_VERIFICATION_RECEIVE: 'EMAIL_VERIFICATION_RECEIVE',
  EMAIL_VERIFICATION_FAIL: 'EMAIL_VERIFICATION_FAIL',
};

export default actionTypes;
