import React from 'react';

const PositiveIcon = ({
  color,
  className,
}) => (
  <svg className={className} width="1em" height="1em" fill={color} viewBox="0 0 56.742 33.546">
    <path
      d="M44.739 0L23.374 11.877l3.68 6.626 8.762-4.871a12.3 12.3 0 01-12.233 11.241H0v8.669h23.583A20.948 20.948 0 0044.319 15.48l.5.142 5.311 9.458 6.609-3.715z"
      fill="#60d008"
    />
  </svg>
);

export default PositiveIcon;
