import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';


import { Typography } from '../../../components';

import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';
import { ListLoading } from '../../../components/loading';
import { DropdownMenu } from '../../../Education/EducationalContent/components';
import { EducationModal } from '../components';

const useStyles = makeStyles((theme) => ({
  groupChange: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: '500',
    fontSize: 24,
  },
  groupArrow: {
    marginRight: '6px'
  },
  groupChangePositive: {
    color: '#409A5F'
  },
  groupChangeNegativet: {
    color: 'red'
  },
  graph: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    fontFamily: "sans-serif"
  },
  graphRowClass: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
    marginBottom: "16px",
    '&:last-of-type': {
      marginBottom: "0"
    }
  },
  graphTitle: {
    fontWeight: "600",
    fontSize: "16px",
    color: "#404040",
    lineHeight: "24px",
    maxWidth: "280px",
    minWidth: "280px",
    letterSpacing: '0.04em',
  },
  graphBar: {
    width: "100%",
    height: "40px",
    backgroundColor: "rgba(108, 114, 147, 0.06)"
  },
  graphPercentage: {
    backgroundColor: "#2A95FF",
    height: "100%"
  },
  graphPercentageQuiz: {
    backgroundColor: "#97CCFF",
  },
  graphType: {
    fontWeight: "600",
    fontSize: "16px",
    color: "#404040",
    lineHeight: "24px",
    width: "95px",
    letterSpacing: '0.04em',
    textTransform: "uppercase",
  },
  graphNumber: {
    color: "#6C7293",
    width: "120px",
    textAlign: "center",
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 18,
  },
  engagementsRateSection: {
    width: "100%",
  },
  engagementsRateTitle: {
    color: '#404040',
    fontWeight: 600,
    fontSize: 28,
    letterSpacing: '0.04em'
  },
  engagementsRateNumber: {
    color: '#2A95FF',
    fontWeight: 700,
    fontSize: 32,
    letterSpacing: '0.05em',
    // margin: '28px 0'
  },
  engagementsRatePercent: {
    color: '#404040',
    fontWeight: 600,
    fontSize: 28,
    letterSpacing: '0.04em'
  },
  legendContainer: {
    display: 'flex',
    width: '100%',
    margin: '25px 0',
  },
  legend_item: {
    display: 'flex',
    margin: '0 16px',
    color: '#404040',
    fontWeight: 400,
    fontSize: 13,
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    alignItems: 'center',
    '&::before': {
      content: '""',
      display: 'block',
      height: 10,
      width: 10,
      borderRadius: '50%',
      backgroundColor: 'red',
      marginRight: 4
    }
  },
  legend__item_Engagement: {
    '&::before': {
      backgroundColor: '#2A95FF'
    }
  },
  legend__item_Correct: {
    '&::before': {
      backgroundColor: '#97CCFF'
    }
  },
  divider: {
    border: 'none',
    '@media (min-width: 991px)': {
      borderLeft: `1px solid #dee2e6 !important`
    }
  }

}));

const WeWantedToKnow = ({
  intl,
  reportData,
  isLoading,
}) => {
  const classes = useStyles();
  /**
 * Menu and menuitem functions.
 */
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const descriptionHandler = () => {
    console.log('handler');
    setOpen(true);

  };

  const viewDetailsHandler = (e) => {
    history.push('/report/materials/detail');
    // setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    handleClose();
  }

  /**
  * Data for dropdown menu items.
  */
  const menuItemData = [
    {
      id: 1,
      name: 'Description',
      method: descriptionHandler,
      disabled: false
    },

  ];

  const descriptionColumn = [
    {
      title: 'Poll Questions ',
      description: "these are questions for which there is no correct answer. Their purpose is to provide insights into residents' attitudes and preferences. The chart shows the number of engagements (responses) for each question. To get detailed response information, click on the 3 dots to the right of this number."
    },
    {
      title: 'Knowledge Questions',
      description: 'or these there is a definitive correct or incorrect response. For these questions there are two bars - one that shows you the number of responses and the other is the percentage of responses that were correct.'
    },
    {
      title: 'Knowledge Questions',
      description: 's the total number of responses during the latest month, which is shown in the upper right corner of this page. The percentage change is a comparison to the same month last year.'
    },
    {
      title: 'Knowledge Questions',
      description: 'is the percentage of correct answers for knowledge questions during the month. The percentage change is a comparison to the same month last year.'
    }
  ]

  // const wwtkData = [
  //   { "wwtk_id": 12335, "question": "Lorem ipsum", "type": "poll", "participant": 345 },
  //   { "wwtk_id": 12335, "question": "", "type": "quiz", "participant": 345, "correct": 120 },
  //   { "wwtk_id": 12335, "question": "Lorem ipsum", "type": "poll", "participant": 345 },
  //   { "wwtk_id": 12335, "question": "", "type": "quiz", "participant": 345, "correct": 120 },
  //   { "wwtk_id": 12335, "question": "Lorem ipsum", "type": "poll", "participant": 345 },
  //   { "wwtk_id": 12335, "question": "", "type": "quiz", "participant": 345, "correct": 120 },
  //   { "wwtk_id": 12335, "question": "Lorem ipsum", "type": "poll", "participant": 345 },
  //   { "wwtk_id": 12335, "question": "", "type": "quiz", "participant": 345, "correct": 120 }
  // ];



  // if (reportData && !reportData.wwtkData) {
  //   // eslint-disable-next-line no-param-reassign
  //   reportData.wwtk = JSON.parse(JSON.stringify(wwtkData));
  // }

  const maxValue = reportData.wwtk ? Math.round(Math.max(...reportData.wwtk.wwtk.map(wwtk => wwtk.participant))
    * 1.05) : 0;
  const totalEngagement = reportData.wwtk ? reportData.wwtk.wwtk.reduce((acc, item) => acc + item.participant, 0) : 0;
  const totalCorrect = reportData.wwtk ? reportData.wwtk.wwtk.reduce((acc, item) => acc + item.correct ? item.correct : 0, 0) : 0;

  return (
    <>
      <Portlet>
        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start" style={{ justifyContent: 'space-between' }}>
                <div className="row col-xs-12 col-lg-6 align-items-center">
                  <div className="align-items-center pl-3">
                    <Typography variant="h1">
                      Weekly We Wanted to Know Questions
                    </Typography>
                  </div>
                </div>
                <DropdownMenu
                  handleClick={handleClick}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  menuItemData={menuItemData}
                />
              </div>
            )}
          />
          <EducationModal
            open={open}
            onClose={() => closeModal()}
            title={'Description'}
            content={(
              <Typography variant="body1" style={{ textAlign: 'left' }}>
                This popular and engaging weekly activity provides valuable insights into the knowledge and opinions of your residents.
                <ul> 
                  {
                    descriptionColumn.map((item) => (
                      <li> <strong>{item.title}</strong> - {item.description} </li>
                    ))
                  }
                </ul>
              </Typography>
            )}
          />
        </div>
        <PortletBody>
          {
            isLoading ?
              <ListLoading /> :
              <div className="row">
                <div className="row col-xs-12 col-lg-10">
                  {
                    reportData && reportData.wwtk && (
                      <div className={classes.graph}>
                        {
                          reportData.wwtk.wwtk.map((item, index) => (
                            <div className={classes.graphRowClass}>
                              <div style={{ maxWidth: 400, display: 'flex', alignItems: 'center' }}>
                                <div className={classes.graphTitle}>{item.question}</div>
                                <div className={classes.graphType} style={{ display: 'flex', justifyContent: 'flex-end' }}>{item.type}</div>
                              </div>

                              {
                                item.type === 'poll' && (
                                  <>
                                    <div style={{ width: 'calc(100% - 400px)', display: 'flex', alignItems: 'center' }}>
                                      <div className={classes.graphBar}>
                                        <div className={classes.graphPercentage} style={{ width: `${100 - (((maxValue - item.participant) / maxValue) * 100)}%` }} />
                                      </div>
                                      <div className={classes.graphNumber}>
                                        {item.participant.toLocaleString()}
                                      </div>
                                    </div>
                                  </>

                                )
                              }
                              {
                                item.type === 'quiz' && (
                                  <>
                                    <div style={{ width: 'calc(100% - 400px)', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                      <div style={{ display: 'flex', width: '100%', margin: '5px 0px' }}>
                                        <div className={classes.graphBar}>
                                          <div className={classes.graphPercentage} style={{ width: `${100 - (((maxValue - item.participant) / maxValue) * 100)}%` }} />
                                        </div>
                                        <div className={classes.graphNumber}>
                                          {item.participant.toLocaleString()}
                                        </div>
                                      </div>
                                      <div style={{ display: 'flex', width: '100%', margin: '5px 0px' }}>
                                        <div className={classes.graphBar}>
                                          <div className={`${classes.graphPercentage} ${classes.graphPercentageQuiz}`} style={{ width: `${Math.round((100 * item.correct / item.participant))}%` }} />
                                        </div>
                                        <div className={classes.graphNumber}>
                                          {Math.round((100 * item.correct / item.participant))}%

                                        </div>
                                      </div>
                                    </div>
                                  </>

                                )
                              }

                            </div>
                          ))

                        }
                      </div>
                    )
                  }
                  <div className={`${classes.legendContainer}`}>
                    <div className={`${classes.legend_item} ${classes.legend__item_Engagement}`}>
                      Engagements (Participants)
                    </div>
                    <div className={`${classes.legend_item} ${classes.legend__item_Correct}`}>
                      Correct Response Rate
                    </div>
                  </div>
                </div>
                <div className={`${classes.divider} row col-xs-12 col-lg-2`}>
                  <div className={classes.engagementsRateSection}>
                    <Typography variant="h1" className={classes.engagementsRateTitle}>
                      Total Engagements
                    </Typography>
                    <div className={classes.engagementsRateNumber}>{reportData.wwtk.total_value.total_engagement.toLocaleString()}</div>
                    <div className={`${classes.groupChange} ${classes.groupChangePositive}`}>
                      <div className={classes.groupArrow}>↑</div>
                      <div className={classes.group__percentage}>{reportData.wwtk.total_value.diff_engagement}%</div>
                    </div>
                  </div>
                  <div className={classes.engagementsRateSection}>
                    <Typography variant="h1" className={classes.engagementsRateTitle}>
                      Correct Response Rate
                    </Typography>
                    <div className={classes.engagementsRateNumber}>{Math.round(100 - (((reportData.wwtk.total_value.total_engagement - reportData.wwtk.total_value.total_correct) / reportData.wwtk.total_value.total_engagement) * 100))}%</div>
                    <div className={`${classes.groupChange} ${classes.groupChangePositive}`}>
                      <div className={classes.groupArrow}>↑</div>
                      <div className={classes.group__percentage}>{reportData.wwtk.total_value.diff_diff}%</div>
                    </div>
                  </div>

                </div>
              </div>
          }

        </PortletBody>
      </Portlet>
    </>
  );
};

export default injectIntl(WeWantedToKnow);
