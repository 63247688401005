import cts from '../../crud/cts';

export const sendSubmittedPhoto = async ({ image, material_id }) => {
  const formData = new FormData();
  formData.append('image_profile', image);
  formData.append('material_id', material_id);

  return cts(
    'post',
    'popular-wgw/submit-photo',
    formData
  );
};

export const sendSuggestedNewTerm = async ({ synonym, additional_comments }) => cts(
  'post',
  'popular-wgw/suggest-synonym',
  { synonym, additional_comments }
);
