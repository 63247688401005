import React from 'react';
import ContentLoader from 'react-content-loader';

const TableLoading = ({
  width = 1100,
  height = 625
}) => (
  <ContentLoader
    speed={2}
    style={{ width, height }}
    backgroundColor={process.env.REACT_APP_COLOR_LOADING_BACKGROUND}
    foregroundColor={process.env.REACT_APP_COLOR__LOADING_FOREGROUND}
  >

    {/* Table header */}

    <rect x="0" y="0" rx="6" ry="6" width="100%" height="20" />
    <rect x="0" y="18" rx="0" ry="0" width="5%" height="24" />
    <rect x="15%" y="20" rx="0" ry="0" width="10%" height="24" />
    <rect x="55%" y="20" rx="0" ry="0" width="10%" height="24" />
    <rect x="75%" y="20" rx="0" ry="0" width="10%" height="24" />
    <rect x="95%" y="20" rx="0" ry="0" width="5%" height="24" />
    <rect x="0" y="40" rx="6" ry="6" width="100%" height="20" />

    {/* left border */}
    <rect x="0" y="6" rx="0" ry="0" width="0.2%" height="616" />
    {/* right border */}
    <rect x="99.8%" y="6" rx="0" ry="0" width="0.2%" height="616" />

    {/* first section */}
    <rect x="4%" y="90" rx="6" ry="6" width="15%" height="15" />
    <rect x="22%" y="90" rx="6" ry="6" width="36%" height="15" />
    <rect x="63%" y="90" rx="6" ry="6" width="15%" height="15" />
    <rect x="85%" y="90" rx="6" ry="6" width="10%" height="15" />
    <rect x="0" y="130" rx="0" ry="0" width="100%" height="2" />

    {/* second section */}
    <rect x="4%" y="160" rx="6" ry="6" width="15%" height="15" />
    <rect x="22%" y="160" rx="6" ry="6" width="36%" height="15" />
    <rect x="63%" y="160" rx="6" ry="6" width="15%" height="15" />
    <rect x="85%" y="160" rx="6" ry="6" width="10%" height="15" />
    <rect x="0" y="200" rx="0" ry="0" width="100%" height="2" />

    {/* third section */}
    <rect x="4%" y="230" rx="6" ry="6" width="15%" height="15" />
    <rect x="22%" y="230" rx="6" ry="6" width="36%" height="15" />
    <rect x="63%" y="230" rx="6" ry="6" width="15%" height="15" />
    <rect x="85%" y="230" rx="6" ry="6" width="10%" height="15" />
    <rect x="0" y="270" rx="0" ry="0" width="100%" height="2" />

    <rect x="4%" y="300" rx="6" ry="6" width="15%" height="15" />
    <rect x="22%" y="300" rx="6" ry="6" width="36%" height="15" />
    <rect x="63%" y="300" rx="6" ry="6" width="15%" height="15" />
    <rect x="85%" y="300" rx="6" ry="6" width="10%" height="15" />
    <rect x="0" y="340" rx="0" ry="0" width="100%" height="2" />

    <rect x="4%" y="370" rx="6" ry="6" width="15%" height="15" />
    <rect x="22%" y="370" rx="6" ry="6" width="36%" height="15" />
    <rect x="63%" y="370" rx="6" ry="6" width="15%" height="15" />
    <rect x="85%" y="370" rx="6" ry="6" width="10%" height="15" />
    <rect x="0" y="410" rx="0" ry="0" width="100%" height="2" />

    <rect x="4%" y="440" rx="6" ry="6" width="15%" height="15" />
    <rect x="22%" y="440" rx="6" ry="6" width="36%" height="15" />
    <rect x="63%" y="440" rx="6" ry="6" width="15%" height="15" />
    <rect x="85%" y="440" rx="6" ry="6" width="10%" height="15" />
    <rect x="0" y="480" rx="0" ry="0" width="100%" height="2" />

    <rect x="4%" y="510" rx="6" ry="6" width="15%" height="15" />
    <rect x="22%" y="510" rx="6" ry="6" width="36%" height="15" />
    <rect x="63%" y="510" rx="6" ry="6" width="15%" height="15" />
    <rect x="85%" y="510" rx="6" ry="6" width="10%" height="15" />
    <rect x="0" y="550" rx="0" ry="0" width="100%" height="2" />

    <rect x="4%" y="580" rx="6" ry="6" width="15%" height="15" />
    <rect x="22%" y="580" rx="6" ry="6" width="36%" height="15" />
    <rect x="63%" y="580" rx="6" ry="6" width="15%" height="15" />
    <rect x="85%" y="580" rx="6" ry="6" width="10%" height="15" />
    <rect x="0" y="620" rx="0" ry="0" width="100%" height="3" />

  </ContentLoader>
);

export default TableLoading;
