import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Typography, CheckBox } from '../../components';

const useStyles = makeStyles(() => ({
  container: {
    maxHeight: 300,
    overflow: 'auto',
    paddingRight: 25,
  },
  materialItem: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
}));

const CategoryMaterials = ({
  materials,
  selectedMaterials,
  setSelectedMaterials,
}) => {
  const classes = useStyles();

  const handleChange = (material) => {
    if (selectedMaterials.find((item) => item.mat_id === material.mat_id)) {
      setSelectedMaterials(
        selectedMaterials.filter((item) => item.mat_id !== material.mat_id)
      );
    } else {
      setSelectedMaterials([...selectedMaterials, material]);
    }
  };

  return (
    <div className={classes.container}>
      {materials
        && materials.map((item, index) => (
          <div
            key={item.mat_id}
            className={clsx({
              'pt-4': true,
              'pb-4': index !== materials.length - 1,
              [classes.materialItem]: index !== materials.length - 1,
            })}
          >
            <CheckBox
              label={(
                <Typography className="ml-2" variant="body">
                  {item.mat_nm}
                </Typography>
              )}
              onChange={() => handleChange(item)}
            />
          </div>
        ))}
    </div>
  );
};

export default CategoryMaterials;
