import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '../../components';

const useStyles = makeStyles({
  stepContainer: {
    padding: 7,
    transition: 'all 0.3s',
    borderRadius: 3,
    '&:hover': {
      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    }
  },
  stepImage: {
    width: 180,
    height: 108,
    cursor: 'pointer',
    marginRight: 15,
  },
  stepDescription: {
    margin: '5px 10px 0px 10px'
  },
});
function Step({
  intl,
  selectedTabData,
  index,
  openStepModalHandler
}) {
  const classes = useStyles();

  return (
    <>
      <div role="button" onClick={() => openStepModalHandler(index)} className={classes.stepContainer}>
        <Typography className="mb-2" variant="h2">
          {intl.formatMessage({ id: 'GENERAL.STEP' })}
          {' '}
          {index + 1}
        </Typography>
        <div className="d-flex">
          <img
            className={classes.stepImage}
            src={require(`../../../assets/images/${selectedTabData.stepImageBaseName}${index + 1}.png`)}
            alt={selectedTabData.stepImageBaseName}
          />
          <Typography
            className={classes.stepDescription}
            variant="body"
            color={process.env.REACT_APP_COLOR_TEXT}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: `${selectedTabData.stepContentBaseName}${index + 1}` })
            }}
          />
        </div>
      </div>
    </>
  );
}

export default injectIntl(Step);
