import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import actionTypes from './actionTypes';
import getEducationBlogsLibraryData from '../apis';

function* getEducationBlogsLibrarySaga({ payload: { project_id, district_id } }) {
  const response = yield call(getEducationBlogsLibraryData, { project_id, district_id });
  try {
    yield put(actions.getAllEducationBlogsLibraryReceive(response.data));
  } catch (error) {
    yield put(actions.getAllEducationBlogsLibraryFail(response.error.detail.data.data));
  }
}

function* educationBlogsLibrarySagas() {
  yield takeLatest(actionTypes.GET_ALL_EDUCATION_BLOGS_LIBRARY_REQUEST, getEducationBlogsLibrarySaga);
}

export default educationBlogsLibrarySagas;
