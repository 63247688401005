import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import MenuItem from '@material-ui/core/MenuItem';

import { LoadingSpinner, ErrorContainer } from '../../components/loading';


import { MaterialsDetailsTable } from './components'
import { Typography } from '../../components';

import * as actions from '../ReportDashboardNew/redux/actions';
import * as sharedActions from '../../redux/actions';

import { TableLoading } from '../../components/loading';

const useStyles = makeStyles((theme) => ({
  container: {
    // width: '90%',
    margin: '0 auto'
  },
  dateSelector: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  dateLabel: {
    color: '#2A95FF',
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 16,
  },
  topSection: {
    display: 'flex',
    alignContent: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  sectionTitle: {
    color: '#404040',
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 22,
    letterSpacing: '0.05em'
  },
}));


const MajorCampaigns = ({
  intl,
  getMaterialDetailsRequest,
  majorDetails,
  loading,
  error,
  municipality,
  isUserPro,
  stage,
  district,
  municipalities,
  setDistrictAction,
  hasCounty,
  counties,
  county,
  isCounty,
  setCountyAction,
}) => {

  const classes = useStyles();
  const [datePeriod, setDatePeriod] = useState({ month: 0, year: 0 });
  const [dateOptions, setDateOptions] = useState([]);
  const [monthFilter, setMonthFilter] = useState(1);



  const handleChange = (event, data) => {
    setDatePeriod(data.props.item);

  };

  useEffect(() => {
    const options = generateMonthYear();
    setDateOptions(options);
    setDatePeriod(options[0]);
    getMaterialDetailsRequest({
      municipality,
      month: options[0].month,
      year: options[0].year,
      county,
      isCounty,
    })
  }, []);

  useEffect(() => {
    if (datePeriod && datePeriod.month && datePeriod.year) {
      getMaterialDetailsRequest({
        municipality,
        month: datePeriod.month,
        year: datePeriod.year,
        county,
        isCounty,
      })
    }

  }, [datePeriod]);

  const handleMonthsFetch = (months) => {
    setMonthFilter(months);
    getMaterialDetailsRequest({
      municipality,
      month: datePeriod.month,
      year: datePeriod.year,
      last_month: months,
      county,
      isCounty,
    })

  };

  const generateMonthYear = () => {
    const date = new Date();
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();
    const monthYear = [];
    for (let i = 0; i < 24; i++) {
      if (currentMonth - i < 0) {
        monthYear.push({
          month: Math.abs(12 + currentMonth - i),
          year: currentYear - 1,
        });
      } else {
        monthYear.push({
          month: currentMonth - i,
          year: currentYear,
        });
      }
    }
    return monthYear;
  };

  const getMonthName = (monthNumber, format = 'short') => {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', { month: format });
  };

  const materialsData = [
    {
      title: 'Material 1',
      exposures_last_yr: 100,
      exposures_this_yr: 125,
      engagements_last_yr: 100,
      engagements_this_yr: 125,
    },
    {
      title: 'Material 2',
      exposures_last_yr: 100,
      exposures_this_yr: 125,
      engagements_last_yr: 100,
      engagements_this_yr: 125,
    },
    {
      title: 'Material 3',
      exposures_last_yr: 200,
      exposures_this_yr: 125,
      engagements_last_yr: 200,
      engagements_this_yr: 125,
    },
    {
      title: 'Material 4',
      exposures_last_yr: 100,
      exposures_this_yr: 125,
      engagements_last_yr: 100,
      engagements_this_yr: 125,
    },
    {
      title: 'Material 5',
      exposures_last_yr: 200,
      exposures_this_yr: 125,
      engagements_last_yr: 200,
      engagements_this_yr: 125,
    },
  ];

  return (
    <>
      <div className={classes.container}>
        {
          loading ?
            <TableLoading /> :
            <div className="row">
              {dateOptions && dateOptions.length > 0 && !majorDetails && loading ? <LoadingSpinner height={350} /> : error ? (
                <ErrorContainer
                  small
                  reportName="Report Dashboard"
                  height={430}
                  error={error}
                />
              ) : (
                <div className="col-xl-12 col-centered">
                  <div className={classes.topSection}>
                    <Typography variant="h2" className={classes.sectionTitle}>
                      Materials Exposure and Engagement
                    </Typography>
                    {
                      datePeriod && dateOptions.length > 0 && datePeriod.month && datePeriod.year > 0 && (
                        <FormControl className={classes.dateSelector}>
                          <Select
                            value={`${getMonthName(datePeriod.month)} ${datePeriod.year}`}
                            onChange={handleChange}
                            disableUnderline
                            className={classes.selectEmpty}
                            options={dateOptions}
                            inputProps={{
                              classes: {
                                root: classes.dateLabel,
                              },
                            }}
                          >
                            {dateOptions.length > 0 && dateOptions.map((item, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={`${getMonthName(item.month)} ${item.year}`}
                                  item={item}
                                >
                                  {getMonthName(item.month)} {item.year}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )
                    }
                  </div>
                  {
                    majorDetails && majorDetails.data && (
                      <MaterialsDetailsTable data={majorDetails.data} month={getMonthName(datePeriod.month, 'long')} year={datePeriod.year} handleMonthsFetch={handleMonthsFetch} monthFilterParent={monthFilter}/>
                    )
                  }

                </div>
              )}
            </div>}

      </div>
    </>
  );
};

/**
 * Map state to props
 */
const mapStateToProps = (store) => ({
  majorDetails: store.reportsDashboardReducer.majorDetails,
  loading: store.reportsDashboardReducer.loading,
  error: store.reportsDashboardReducer.error,
  municipality: store.common.district.district_id,
  isUserPro: store.common.isUserPro,
  stage: store.common.district.stage,
  district: store.common.district,
  municipalities: store.common.municipalities,
  hasCounty: store.auth.user_info.counties,
  counties: store.common.counties,
  county: store.common.county,
  isCounty: store.common.isCounty,

});


export default injectIntl(
  connect(
    mapStateToProps, { ...actions, ...sharedActions }
  )(MajorCampaigns)
);
