const actionTypes = {
  // Set district
  SET_DISTRICT: 'SET_DISTRICT',

  // Campaign actionTypes for setting loading status
  SET_LOADING: 'SET_LOADING',
  RESET_LOADING: 'RESET_LOADING',

  SET_TAB: 'SET_TAB',

  // Campaign actionTypes for fetching campaign list
  CAMPAIGN_LIST_REQUEST: 'CAMPAIGN_LIST_REQUEST',
  CAMPAIGN_LISTـRECEIVE: 'CAMPAIGN_LISTـRECEIVE',
  CAMPAIGN_LISTـFAIL: 'CAMPAIGN_LISTـFAIL',

  // Switch Campaign
  SET_SWITCH_ITEM: 'SET_SWITCH_ITEM'

};

export default actionTypes;
