import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import EditOutlined from '@material-ui/icons/EditOutlined';
import { useHistory } from 'react-router-dom';
import UnfoldMore from '@material-ui/icons/UnfoldMore';

import { DeleteIcon } from '../../../assets/icons';
import {
  TextField,
  CheckBox,
  Dialog,
  IconButton,
  Typography,
  Pagination
} from '../../components';
import { TableLoading } from '../../components/loading';
import * as actions from '../redux/actions';

const TableSort = ({
  intl,
  userList,
  getOneUserRequest,
  deleteUserRequest,
  activationUserRequest,
  newUser,
  loadingList
}) => {
  const [navigation, setNavigation] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('user_name');
  const [page, setPage] = useState(1);
  const [dense] = useState(true);
  const [search, setSearch] = useState('');
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const history = useHistory();

  const navigateToForm = () => {
    history.push('/users/form');
  };

  useEffect(() => {
    setPage(1);
  }, [rowsPerPage]);

  useEffect(() => {
    if (typeof (userList) === 'object') {
      setRows(Object.values(userList));
    } else {
      setRows(userList);
    }
    if (userList.length < 9) {
      setRowsPerPage(5);
    } else {
      setRowsPerPage(10);
    }
  }, [userList]);

  useEffect(() => {
    if (newUser.user_id && navigation) {
      navigateToForm();
    }
  }, [newUser, history, navigation]);

  // REVIEW: Declare this outside the function
  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  // REVIEW: Declare this outside the function
  function stableSort(array, cmp) {
    const stabilizedThis = [...array].map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  // REVIEW: Declare this outside the function
  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  // REVIEW: Declare this outside the function
  const headRows = [
    {
      id: 'user_name',
      numeric: false,
      disablePadding: false,
      label: intl.formatMessage({ id: 'GENERAL.USERNAME' }),
      width: '15%'
    },
    {
      id: 'first_name',
      numeric: true,
      disablePadding: false,
      label: intl.formatMessage({ id: 'GENERAL.FIRST_NAME' }),
      width: '15%'
    },
    {
      id: 'last_name',
      numeric: true,
      disablePadding: false,
      label: intl.formatMessage({ id: 'GENERAL.LAST_NAME' }),
      width: '15%'
    },
    {
      id: 'email',
      numeric: true,
      disablePadding: false,
      label: intl.formatMessage({ id: 'GENERAL.EMAIL' }),
      width: '20%'
    },
    {
      id: 'lastlogin',
      numeric: true,
      disablePadding: false,
      label: intl.formatMessage({ id: 'USERS.LIST.LAST_LOGIN' }),
      width: '20%'
    },
    {
      id: 'Active',
      numeric: true,
      disablePadding: false,
      label: intl.formatMessage({ id: 'GENERAL.ACTIVE' }),
      width: '15%'
    },

  ];

  function EnhancedTableHead(props) {
    const {
      order,
      orderBy,
      onRequestSort
    } = props;

    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headRows.map((row) => (
            <TableCell
              key={row.id}
              align={row.label === 'Active' ? 'center' : 'left'}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
              width={row.width}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
                IconComponent={UnfoldMore}
              >
                <Typography variant="bodySemiBold">
                  {row.label}
                </Typography>

              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  // REVIEW: GOOD STUFF
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(0),
      position: 'relative'
    },
    table: {
      width: '100%',
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    pagination: {
      width: 'max-content',
      '& ul': {
        justifyContent: 'flex-end',
        width: 'max-content',
      }
    },
    deActive: {
      color: process.env.REACT_APP_COLOR_DISABLED_TEXT
    },
    rootPagination: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    smallerThanMdHide: {
      [theme.breakpoints.between('xs', 'md')]: {
        display: 'none !important'
      }
    },
    smallerThanMdShow: {
      [theme.breakpoints.between('md', 'xl')]: {
        display: 'none !important'
      },
    },
    onlyMd: {
      [theme.breakpoints.only('md')]: {
        display: 'flex !important'
      },
    },
  }));

  const classes = useStyles();

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleChangePage(newPage) {
    setPage(newPage);
  }

  return (
    <div className={classes.root}>
      {loadingList
        ? <TableLoading width="100%" />
        : (
          <>
            {/* start Delete Item Modal */}
            <div className="row">
              <div className="col-9" />
              <div className="col-xl-3 col-sm-6 pr-4 mb-4">
                <TextField
                  label={intl.formatMessage({ id: 'GENERAL.SEARCH' })}
                  onChange={(e) => {
                    setSearch(e);
                  }}
                  value={search}
                />
              </div>
            </div>
            <div className={classes.tableWrapper}>
              <Table
                className={classes.table}
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {
                userList.length > 0
                    && stableSort(search !== '' ? rows.filter((item) => JSON.stringify(item).includes(search)) : rows, getSorting(order, orderBy))
                      .slice((page - 1) * rowsPerPage, (page * rowsPerPage))
                      .map((row) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.user_name}
                          width="100%"
                        >
                          <TableCell color="red" width="15%" align="left">
                            <Typography
                              variant="body"
                              className={row.is_active === 'f' ? classes.deActive : null}
                            >
                              {row.user_name}
                            </Typography>
                          </TableCell>

                          <TableCell align="left" width="15%">
                            <Typography
                              variant="body"
                              className={row.is_active === 'f' ? classes.deActive : null}
                            >
                              {row.first_name}
                            </Typography>
                          </TableCell>

                          <TableCell align="left" width="15%">
                            <Typography
                              variant="body"
                              className={row.is_active === 'f' ? classes.deActive : null}
                            >
                              {row.last_name}
                            </Typography>
                          </TableCell>

                          <TableCell align="left" width="20%">
                            <Typography
                              variant="body"
                              className={row.is_active === 'f' ? classes.deActive : null}
                            >
                              {row.email}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" width="25%">
                            <Typography
                              variant="body"
                              className={row.is_active === 'f' ? classes.deActive : null}
                            >
                              {row.last_login_dt}
                              {' '}
                              {row.last_login_ts && 'at '}
                              {row.last_login_ts && ' '}
                              {row.last_login_ts && row.last_login_ts.slice(0, 5)}
                            </Typography>
                          </TableCell>

                          <TableCell align="center" width="10%">
                            <div className="d-flex align-items-center justify-content-center">
                              {/* Edit Action */}
                              <div className="mr-3 col-2">
                                {
                                row.is_active === 't'
                                && (
                                  <IconButton
                                    tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
                                    onClick={() => {
                                      getOneUserRequest({ user_id: row.user_id });
                                      setNavigation(true);
                                    }}
                                  >
                                    <EditOutlined color="primary" />
                                  </IconButton>
                                )
                                }
                              </div>
                              {/* Active box */}
                              <div className="pl-3">
                                <CheckBox
                                  label={(
                                    <div className="p-3">
                                      {intl.formatMessage({ id: 'GENERAL.ACTIVE' })}
                                    </div>
                                  )}
                                  onChange={() => {
                                    activationUserRequest(row.user_id, row.is_active === 't' ? 'f' : 't');
                                    const temp = [...rows];
                                    const id = temp.findIndex(
                                      (item) => item.user_name === row.user_name
                                    );
                                    temp[id] = { ...temp[id], is_active: temp[id].is_active === 't' ? 'f' : 't' };
                                    setRows(temp);
                                  }}
                                  checked={row.is_active === 't'}
                                />
                              </div>
                              <div className="ml-2 col-2">
                                {/* Delete Action */}
                                <div className="p-0 float-right">
                                  { row.is_active === 'f'
                                && (
                                <Dialog
                                  button={(
                                    <IconButton tooltip={intl.formatMessage({ id: 'GENERAL.DELETE' })}>
                                      <DeleteIcon color={process.env.REACT_APP_COLOR_DELETE} />
                                    </IconButton>
                                  )}
                                  title={intl.formatMessage({ id: 'USERS.LIST.DELETE.TITLE' })}
                                  content={(
                                    <Typography variant="body">
                                      {intl.formatMessage({ id: 'USERS.LIST.DELETE.TEXT' })}
                                    </Typography>
                                  )}
                                  submitText={intl.formatMessage({ id: 'GENERAL.DELETE' })}
                                  handleSubmit={() => {
                                    deleteUserRequest(row.user_id);
                                    const temp = [...rows];
                                    const id = temp.findIndex(
                                      (item) => item.user_name === row.user_name
                                    );
                                    temp.splice(id, 1);
                                    setRows(temp);
                                  }}
                                />
                                )}
                                </div>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                  }
                </TableBody>
              </Table>
            </div>

            {/* table pagination */}

            <div className={`mt-5 mb-4 d-flex justify-content-end align-items-center ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
              <Pagination
                page={page}
                rowsPerPage={rowsPerPage}
                handlePageChange={(value) => handleChangePage(value)}
                handleRowsPerPageChange={(value) => setRowsPerPage(value)}
                dataLength={rows.length}
              />
            </div>

            <div className={`row mt-3 mb-3 justify-content-center align-items-center ${classes.smallerThanMdShow}`}>
              <div className="row col-12 justify-content-center align-items-center">
                <Pagination
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handlePageChange={(value) => handleChangePage(value)}
                  handleRowsPerPageChange={(value) => setRowsPerPage(value)}
                  dataLength={rows.length}
                />
              </div>
            </div>
          </>
        )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  userList: store.user.userList,
  newUser: store.user.newUser,
  loadingList: store.user.loadingList,
});

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(TableSort)
);
