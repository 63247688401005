import React, { useState } from 'react';
import { injectIntl } from 'react-intl';


import { makeStyles } from '@material-ui/core/styles';


import { Typography } from '../../../components';

import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';
import { ListLoading } from '../../../components/loading';
import { EducationModal } from '../../ReportDashboardNew/components';
import { DropdownMenu } from '../../../Education/EducationalContent/components';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '1300px',
    margin: '0 auto'
  },
  verticalGraph: {
    width: "100%",
    height: "350px",
    display: "flex",
    flexDirection: "row"
  },
  verticalGraphColumn: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  verticalGraphBar: {
    height: "calc( 100% - 50px )",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end"
  },
  verticalGraphNumberPercentage: {
    color: "grey",
    marginBottom: "5px",
    textAlign: "center"
  },
  verticalGraphPercentage: {
    backgroundColor: "#2A95FF",
    width: "100%"
  },
  verticalGraphNumber: {
    color: "white",
    textAlign: "center",
    paddingTop: "10px",
    fontWeight: "600"
  },
  verticalGraphSpan: {
    fontWeight: "400"
  },
  verticalGraphTitle: {
    textAlign: "center",
    padding: "10px 6px",
    height: "100%",
    maxHeight: "100px",
  },
  verticalGraphPercentage1: {
    backgroundColor: "#2A95FF",
    width: "100%"
  },
  verticalGraphPercentage2: {
    backgroundColor: '#52A8FF',
    width: "100%"
  },
  verticalGraphPercentage3: {
    backgroundColor: '#72B9FF',
    width: "100%"
  },
  verticalGraphPercentage4: {
    backgroundColor: '#80C0FF',
    width: "100%"
  },
  verticalGraphPercentage5: {
    backgroundColor: '#97CCFF',
    width: "100%"
  },
  verticalGraphPercentage6: {
    backgroundColor: "#2A95FF",
    width: "100%"
  },
  verticalGraphPercentage7: {
    backgroundColor: '#52A8FF',
    width: "100%"
  },
  verticalGraphPercentage8: {
    backgroundColor: '#72B9FF',
    width: "100%"
  },
  verticalGraphPercentage9: {
    backgroundColor: '#80C0FF',
    width: "100%"
  },
  verticalGraphPercentage10: {
    backgroundColor: '#97CCFF',
    width: "100%"
  },
  verticalGraphPercentage11: {
    backgroundColor: "#2A95FF",
    width: "100%"
  },
  verticalGraphPercentage12: {
    backgroundColor: '#52A8FF',
    width: "100%"
  },
  verticalGraphPercentage13: {
    backgroundColor: '#72B9FF',
    width: "100%"
  },
  verticalGraphPercentage14: {
    backgroundColor: '#80C0FF',
    width: "100%"
  },
  verticalGraphPercentage15: {
    backgroundColor: '#97CCFF',
    width: "100%"
  },


}));

const AnswerRate = ({
  intl,
  answerRateData,
  title,
  isLoading,

}) => {
  const classes = useStyles();


  /**
* Menu and menuitem functions.
*/
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const descriptionHandler = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    handleClose();
  }

  /**
  * Data for dropdown menu items.
  */
  const menuItemData = [
    {
      id: 1,
      name: 'Description',
      method: descriptionHandler,
      disabled: false
    },

  ];



  return (
    <>
      <Portlet>
        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start">
                <div className="row col-xs-12 col-lg-12 align-items-center" style={{ justifyContent: 'space-between' }} >
                  <div className="align-items-center">
                    <Typography variant="h1">
                      {title}
                    </Typography>
                  </div>
                  <DropdownMenu
                    handleClick={handleClick}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    menuItemData={menuItemData}
                  />
                </div>
                <EducationModal
                  open={open}
                  onClose={() => closeModal()}
                  title={'Description'}
                  content={(
                    <Typography variant="body1" style={{ textAlign: 'left' }}>
                      For some types of content the user is asked to provide a response that can be definitively classified as either correct or incorrect. Quiz questions, the Recycle RIGHT game and Recycling Fundamentals are examples. For these types of interactions the Correct Response Rate calculates the percentage of correct responses. This chart shows the Correct Response Rate for the materials your residents engaged with the most during the latest month, which is identified in the upper right corner of this page.
                    </Typography>
                  )}
                />
              </div>

            )}
          />
        </div>
        <PortletBody>
          <div className="row">
            {
              isLoading ?
                <ListLoading /> :
                <div>
                  {
                    answerRateData && (
                      <div className={classes.verticalGraph}>
                        {
                          answerRateData.map((item, index) => (
                            <div className={classes.verticalGraphColumn} style={{width: `calc( 100% / ${answerRateData.length} )`}}>
                              <div className={classes.verticalGraphBar}>
                                {/* <div className={classes.verticalGraphNumberPercentage}>{Math.round(100 - (100 * Math.abs((item.total - item.correct) / ((item.total + item.correct) / 2))))}%</div> */}
                                <div className={classes.verticalGraphNumberPercentage}>{Math.round((100 * item.correct) / item.total)}%</div>
                                <div className={`${classes[`verticalGraphPercentage${index + 1}`]}`} style={{ height: `${Math.round((100 * item.correct) / item.total)}%` }}>

                                  {/* <div className={`${classes[`verticalGraphPercentage${index + 1}`]}`} style={{ height: `${Math.round(100 - (100 * Math.abs((item.total - item.correct) / ((item.total + item.correct) / 2))))}%` }}> */}
                                  <div className={classes.verticalGraphNumber}>
                                    <span className={classes.verticalGraphSpan}>{item.correct}</span>/{item.total}
                                  </div>
                                </div>
                              </div>
                              <div className={classes.verticalGraphTitle}>{item.title}</div>
                            </div>
                          ))

                        }
                      </div>
                    )
                  }
                </div>
            }

          </div>
        </PortletBody>
      </Portlet>
    </>
  );
};

export default injectIntl(AnswerRate);
