const actionTypes = {
  // Notification actionTypes for setting loading status
  SET_LOADING: 'SET_LOADING',
  RESET_LOADING: 'RESET_LOADING',

  // Notification actionTypes for resetting responseMessage
  RESET_MESSAGE: 'RESET_MESSAGE',

  // Notification actionTypes for setting form data
  SET_FORM_DATA: 'SET_FORM_DATA',

  // Notification actionTypes for updating form
  SET_FORM: 'SET_FORM',
  RESET_FORM: 'RESET_FORM',
  SET_FORM_LOADING: 'SET_FORM_LOADING',

  // Notification actionTypes for setting tab in notification history
  SET_NOTIFICATION_TAB: 'SET_NOTIFICATION_TAB',

  // Notification actionTypes for setting category in notification history
  SET_NOTIFICATION_CAT: 'SET_NOTIFICATION_CAT',

  // Notification actionTypes for fetching notification list
  NOTIFICATION_LIST_REQUEST: 'NOTIFICATION_LIST_REQUEST',
  NOTIFICATION_LISTـRECEIVE: 'NOTIFICATION_LISTـRECEIVE',
  NOTIFICATION_LISTـFAIL: 'NOTIFICATION_LISTـFAIL',

  // Notification actionTypes for deleting a notification
  DELETE_NOTIFICATION_REQUEST: 'DELETE_NOTIFICATION_REQUEST',
  DELETE_NOTIFICATION_RECEIVE: 'DELETE_NOTIFICATION_RECEIVE',
  DELETE_NOTIFICATION_FAIL: 'DELETE_NOTIFICATION_FAIL',

  // Notification actionTypes for loading a notification into the form
  LOAD_NOTIFICATION_REQUEST: 'LOAD_NOTIFICATION_REQUEST',
  LOAD_NOTIFICATION_RECEIVE: 'LOAD_NOTIFICATION_RECEIVE',
  LOAD_NOTIFICATION_FAIL: 'LOAD_NOTIFICATION_FAIL',

  // Notification actionTypes for sending a notification right now
  SEND_NOW_REQUEST: 'SEND_NOW_REQUEST',
  SEND_NOW_RECEIVE: 'SEND_NOW_RECEIVE',
  SEND_NOW_FAIL: 'SEND_NOW_FAIL',

  // Notification actionTypes for editing the delete date
  EDIT_DELETE_DATE_REQUEST: 'EDIT_DELETE_DATE_REQUEST',
  EDIT_DELETE_DATE_RECEIVE: 'EDIT_DELETE_DATE_RECEIVE',
  EDIT_DELETE_DATE_FAIL: 'EDIT_DELETE_DATE_FAIL',

  // Notification actionTypes for fetching zone list
  GET_ZONE_REQUEST: 'GET_ZONE_REQUEST',
  GET_ZONE_RECEIVE: 'GET_ZONE_RECEIVE',
  GET_ZONE_FAIL: 'GET_ZONE_FAIL',

  // Notification actionTypes for creating a draft notification
  NEW_DRAFT_REQUEST: 'NEW_DRAFT_REQUEST',
  NEW_DRAFT_RECEIVE: 'NEW_DRAFT_RECEIVE',
  NEW_DRAFT_FAIL: 'NEW_DRAFT_FAIL',

  // Notification actionTypes for creating a scheduled notification
  NEW_SCHEDULED_REQUEST: 'NEW_SCHEDULED_REQUEST',
  NEW_SCHEDULED_RECEIVE: 'NEW_SCHEDULED_RECEIVE',
  NEW_SCHEDULED_FAIL: 'NEW_SCHEDULED_FAIL',

  // Notification actionTypes for creating a immediately notification
  NEW_IMMEDIATELY_REQUEST: 'NEW_IMMEDIATELY_REQUEST',
  NEW_IMMEDIATELY_RECEIVE: 'NEW_IMMEDIATELY_RECEIVE',
  NEW_IMMEDIATELY_FAIL: 'NEW_IMMEDIATELY_FAIL',

  // Notification actionTypes for editing a draft notification
  EDIT_DRAFT_REQUEST: 'EDIT_DRAFT_REQUEST',
  EDIT_DRAFT_RECEIVE: 'EDIT_DRAFT_RECEIVE',
  EDIT_DRAFT_FAILED: 'EDIT_DRAFT_FAILED',

  // Notification actionTypes for editing a scheduled notification
  EDIT_SCHEDULED_REQUEST: 'EDIT_SCHEDULED_REQUEST',
  EDIT_SCHEDULED_RECEIVE: 'EDIT_SCHEDULED_RECEIVE',
  EDIT_SCHEDULED_FAIL: 'EDIT_SCHEDULED_FAIL',

  // Notification actionTypes for editing a immediately notification
  EDIT_IMMEDIATELY_REQUEST: 'EDIT_IMMEDIATELY_REQUEST',
  EDIT_IMMEDIATELY_RECEIVE: 'EDIT_IMMEDIATELY_RECEIVE',
  EDIT_IMMEDIATELY_FAIL: 'EDIT_IMMEDIATELY_FAIL',

  // Notification actionTypes for updating a notification
  NOTIFICATION_UPDATE_REQUEST: 'NOTIFICATION_UPDATE_REQUEST',
  NOTIFICATION_UPDATE_RECEIVE: 'NOTIFICATION_UPDATE_RECEIVE',
  NOTIFICATION_UPDATE_FAIL: 'NOTIFICATION_UPDATE_FAIL',
};

export default actionTypes;
