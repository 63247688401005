import React from 'react';
import { Text, View, StyleSheet, } from '@react-pdf/renderer';
import { addCommasToNumber } from '../../../../utils';

// Create styles
const styles = StyleSheet.create({
  marginLeft: {
    marginLeft: 15
  },
  h2: {
    fontSize: '20pt'
  },
  circle: {
    width: '10pt',
    height: '10pt',
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY,
    borderRadius: '100pt',
    marginRight: '10pt',
  },
  image: {
    width: '110px',
    height: '40px',
    marginLeft: '13px',
    marginTop: '5px',
  }
});

// Create Document Component
const SummaryPDF = ({
  wwtkAnswerTitle,
  wwtkAnswerValue,
  uniqueUserTitle,
  uniqueUserValue,
  correctAnswerTitle,
  correctAnswerValue,
  appInstallTitle,
  appInstallValue,
  onboardingSurveyTitle,
  onboardingSurveyValue,
  userCompleteSurveyTitle,
  userCompleteSurveyValue,
}) => (
  <>
    <View style={{
      flexDirection: 'column',
      width: '100%',
      margin: 10,
      padding: 10,
    }}
    >
      {/* summary first row */}

      <View style={{
        flexGrow: 1,
        height: '100px',
        marginRight: '20px',
        flexDirection: 'row'
      }}
      >
        <View style={{
          height: '100%',
          width: '33%',
          marginRight: '20px',
          flexDirection: 'column',
          borderRight: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
          justifyContent: 'space-between',
          paddingRight: '10pt'
        }}
        >
          <Text style={styles.h2}>
            {uniqueUserTitle}
          </Text>
          <Text style={styles.h2}>
            {addCommasToNumber(uniqueUserValue)}
          </Text>
        </View>

        <View style={{
          height: '100%',
          width: '33%',
          marginRight: '20px',
          flexDirection: 'column',
          borderRight: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
          justifyContent: 'space-between',
          paddingRight: '10pt'
        }}
        >
          <Text style={styles.h2}>
            {wwtkAnswerTitle}
          </Text>
          <Text style={styles.h2}>
            {addCommasToNumber(wwtkAnswerValue)}
          </Text>
        </View>

        <View style={{
          height: '100%',
          width: '33%',
          marginRight: '20px',
          flexDirection: 'column',
          justifyContent: 'space-between',
          paddingRight: '10pt',
        }}
        >
          <Text style={styles.h2}>
            {addCommasToNumber(correctAnswerValue)}
          </Text>
          <Text style={styles.h2}>
            {correctAnswerTitle}
          </Text>
        </View>

      </View>

      {/* summary second row */}
      <View style={{
        flexGrow: 1,
        height: '130px',
        marginRight: '20px',
        flexDirection: 'row',
        paddingTop: '30pt',
        marginTop: '30pt',
        borderTop: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
      }}
      >
        <View style={{
          height: '100%',
          width: '33%',
          marginRight: '20px',
          flexDirection: 'column',
          borderRight: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
          justifyContent: 'space-between',
          paddingRight: '10pt'
        }}
        >
          <Text style={styles.h2}>
            {appInstallTitle}
          </Text>
          <Text style={styles.h2}>
            {addCommasToNumber(appInstallValue)}
          </Text>
        </View>

        <View style={{
          height: '100%',
          width: '33%',
          marginRight: '20px',
          flexDirection: 'column',
          borderRight: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
          justifyContent: 'space-between',
          paddingRight: '10pt'
        }}
        >
          <Text style={styles.h2}>
            {onboardingSurveyTitle}
          </Text>
          <Text style={styles.h2}>
            {addCommasToNumber(onboardingSurveyValue)}
          </Text>
        </View>

        <View style={{
          height: '100%',
          width: '33%',
          marginRight: '20px',
          flexDirection: 'column',
          justifyContent: 'space-between',
          paddingRight: '10pt'
        }}
        >
          <Text style={styles.h2}>
            {userCompleteSurveyTitle}
          </Text>
          <Text style={styles.h2}>
            {addCommasToNumber(userCompleteSurveyValue)}
          </Text>
        </View>

      </View>

    </View>
  </>
);

export default SummaryPDF;
