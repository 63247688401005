import actionTypes from './actionTypes';

export const setCurrentQuestionAction = (index) => ({
  type: actionTypes.SET_CURRENT_QUESTION_INDEX,
  index
});



export const feedbackRequest = (meta, feedback, type = 'pdfAddons') => ({
  type: actionTypes.FEEDBACK_REQUEST,
  payload: { meta, feedback, type }
});

export const feedbackReceive = (data) => ({
  type: actionTypes.FEEDBACK_RECEIVE,
  payload: data
});

export const feedbackFail = (error) => ({
  type: actionTypes.FEEDBACK_FAIL,
  payload: error
});