import React from 'react';
import UserProfile from '../../partials/layout/UserProfile';

const Topbar = () => (
  <div className="kt-header__topbar">
    <UserProfile showAvatar showHi showBadge={false} />
  </div>
);

export default Topbar;
