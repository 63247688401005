import actionTypes from './actionTypes';

// Reminder actions for setting loading status
export const setLoadingAction = () => ({
  type: actionTypes.SET_LOADING
});

export const resetLoadingAction = () => ({
  type: actionTypes.RESET_LOADING
});

// Reminder actions for setting form data
export const setValueAction = (data) => ({
  type: actionTypes.REMINDER_SET_VALUE_ACTION,
  payload: { ...data }
});

export const resetValueAction = () => ({
  type: actionTypes.REMINDER_RESET_VALUE_ACTION,
});

// Reminder actions for fetching reminder list
export const reminderListRequest = ({ type, page }) => ({
  type: actionTypes.REMINDER_LIST_REQUEST,
  payload: { type, page }
});

export const reminderListReceive = (data) => ({
  type: actionTypes.REMINDER_LISTـRECEIVE,
  payload: { data }
});

export const reminderListFail = (error) => ({
  type: actionTypes.REMINDER_LISTـFAIL,
  payload: { ...error }
});

// Reminder actions for deleting a reminder
export const deleteReminderRequest = ({ id, type, page }) => ({
  type: actionTypes.DELETE_REMINDER_REQUEST,
  payload: { id, type, page }
});

export const deleteReminderReceive = (data) => ({
  type: actionTypes.DELETE_REMINDER_RECEIVE,
  payload: { ...data }
});

export const deleteReminderFail = (error) => ({
  type: actionTypes.DELETE_REMINDER_FAIL,
  payload: { ...error }
});

// Reminder actions for loading a reminder into the form
export const loadReminderRequest = (id) => ({
  type: actionTypes.LOAD_REMINDER_REQUEST,
  payload: { id }
});

export const loadReminderReceive = (data) => ({
  type: actionTypes.LOAD_REMINDER_RECEIVE,
  payload: { ...data }
});

export const loadReminderFail = (error) => ({
  type: actionTypes.LOAD_REMINDER_FAIL,
  payload: { ...error }
});

// Reminder actions for fetching collection list
export const getCollectionRequest = (district_id) => ({
  type: actionTypes.GET_COLLECTION_REQUEST,
  payload: { district_id }
});

export const getCollectionReceive = (data) => ({
  type: actionTypes.GET_COLLECTION_RECEIVE,
  payload: { ...data }
});

export const getCollectionFail = (error) => ({
  type: actionTypes.GET_COLLECTION_FAIL,
  payload: { ...error }
});

// Reminder actions for creating a new reminder
export const newReminderRequest = ({ reminder }) => ({
  type: actionTypes.NEW_REMINDER_REQUEST,
  payload: { reminder }
});

export const newReminderReceive = (data) => ({
  type: actionTypes.NEW_REMINDER_RECEIVE,
  payload: { ...data }
});

export const newReminderFail = (error) => ({
  type: actionTypes.NEW_REMINDER_FAIL,
  payload: { ...error }
});

// Reminder actions for editing an existing reminder
export const editReminderRequest = ({ reminder }) => ({
  type: actionTypes.EDIT_REMINDER_REQUEST,
  payload: { reminder }
});

export const editReminderReceive = (data) => ({
  type: actionTypes.EDIT_REMINDER_RECEIVE,
  payload: { ...data }
});

export const editReminderFail = (error) => ({
  type: actionTypes.EDIT_REMINDER_FAIL,
  payload: { ...error }
});

export const setFormLoading = (loading) => ({
  type: actionTypes.SET_FORM_LOADING,
  payload: loading
});
