import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import AutoComplete from './AutoComplete';
import Typography from './Typography';
import * as actions from '../redux/actions';
import TextField from './TextField';

const useStyles = makeStyles(() => ({
  isCounty: {
    marginTop: '5px',
  },
  switchText: {
    color: process.env.REACT_APP_COLOR_PRIMARY
  },
  switchButton: {
    outline: 'none',
  },
}));

const MunicipalitySelector = ({
  intl,
  municipalities,
  title,
  isMulti,
  isDisable,
  disableClearable,
  onChange,
  value,
  invalidText,
  hasAllDistrictOption = true,
  onFocus,
  isCountyEnable = false,
  counties,
  isCounty,
  setIsCountyAction,
  hasCounty,
  advocateAdmin,
  disbleSingleCountyDropdown = false
}) => {
  const classes = useStyles();

  const returnDisabledOptions = (option) => {
    if (isMulti && value && value.length !== 0) {
      if (value[0].district_id === '#all-districts#' && option.district_id !== '#all-districts#') {
        return true;
      }
      if (option.district_id === '#all-districts#') {
        return true;
      }
    }
    return false;
  };

  const handleSwitch = () => {
    setIsCountyAction(!isCounty);
  };

  const reportMunicipalities = hasAllDistrictOption && municipalities.length > 1 ? [
    {
      district_id: '#all-districts#',
      // eslint-disable-next-line no-nested-ternary
      district_nm: isCounty ? 'All Counties' : advocateAdmin ? 'All Workplaces' : 'All Districts'
    },
    ...municipalities
  ] : [...municipalities];
  if (municipalities.length > 1) {
    return (
      <div>
        {
          disbleSingleCountyDropdown && isCountyEnable && hasCounty !== null
            && isCounty && counties && counties.length === 1
            ? (
              <TextField
                variant="outlined"
                label="County"
                value={counties[0].county_nm}
              />
            ) : (
              <AutoComplete
                title={title}
                options={isCountyEnable && isCounty ? counties : reportMunicipalities}
                disabledOptions={returnDisabledOptions}
                optionTitle={isCountyEnable && isCounty ? 'county_nm' : 'district_nm'}
                optionId={isCountyEnable && isCounty ? 'county_id' : 'district_id'}
                isMulti={isMulti}
                isDisable={isDisable}
                disableClearable={disableClearable}
                onChange={onChange}
                value={
                  !value || value.district_id === ''
                    ? reportMunicipalities[0]
                    : value
                }
                invalidText={invalidText}
                onFocus={onFocus}
              />
            )
        }

        {
          hasCounty
          && isCountyEnable
          && (
            <div className={`d-flex justify-content-center align-items-center ${classes.isCounty}`}>
              <span
                className={classes.switchButton}
                onClick={handleSwitch}
                onKeyPress={handleSwitch}
                role="button"
                tabIndex={0}
              >
                <Typography variant="body" className={classes.switchText}>
                  {isCounty
                    ? intl.formatMessage({ id: 'MUNICIPALITY.SELECT.DISTRICT' })
                    : intl.formatMessage({ id: 'MUNICIPALITY.SELECT.COUNTY' })}
                </Typography>
              </span>
            </div>
          )
        }
      </div>
    );
  }
  return null;
};

const mapStateToProps = (store) => ({
  municipalities: store.common.municipalities,
  counties: store.common.counties,
  county: store.common.county,
  isCounty: store.common.isCounty,
  hasCounty: store.auth.user_info.counties,
  advocateAdmin: store.auth.advocateAdmin
});

export default injectIntl(connect(mapStateToProps, actions)(MunicipalitySelector));
