const actionTypes = {
  // Interaction report actionTypes for setting loading status
  SET_INTERACTION_REPORT_LOADING_ACTION: 'SET_INTERACTION_REPORT_LOADING_ACTION',

  // Interaction report actionTypes for fetching report data
  GET_INTERACTION_REPORT_REQUEST: 'GET_INTERACTION_REPORT_REQUEST',
  GET_INTERACTION_REPORT_RECEIVE: 'GET_INTERACTION_REPORT_RECEIVE',
  GET_INTERACTION_REPORT_FAIL: 'GET_INTERACTION_REPORT_FAIL',
};


export default actionTypes;
