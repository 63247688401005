import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '../../components';

const useStyles = makeStyles({
  container: {
    paddingBottom: 10,
  }
});

const Date = ({ children }) => {
  const { container } = useStyles();

  return (
    <Typography variant="h2" className={container}>
      {children}
    </Typography>
  );
};

export default Date;
