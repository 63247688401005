import React from 'react';
import { TextField } from '@material-ui/core';

const InputField = ({
  type, label, name, onBlur, onChange, value, helperText, error
}) => (
  <div className="form-group">
    <TextField
      type={type}
      label={label}
      margin="normal"
      className="kt-width-full"
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      helperText={helperText}
      error={error}
    />
  </div>
);

export default InputField;
