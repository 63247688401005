const actionTypes = {
  // Set district
  SET_DASHBOARD_DISTRICT_ACTION: 'SET_DASHBOARD_DISTRICT_ACTION',

  // Dashboard actionTypes for setting from date
  SET_DASHBOARD_FROM_DATE_ACTION: 'SET_DASHBOARD_FROM_DATE_ACTION',

  // Dashboard actionTypes for setting to date
  SET_DASHBOARD_TO_DATE_ACTION: 'SET_DASHBOARD_TO_DATE_ACTION',

};

export default actionTypes;
