import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '../../../components';
import ColorLegend from './ColorLegend';

const useStyles = makeStyles(() => ({
  graph: {
    display: 'inline-flex',
    flexDirection: 'column'
  },
  graph__2items: {
    marginRight: 20,
    marginLeft: 20,
  },
  graph__wrapper: {
    display: 'flex',
  },
  graph__item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 200,
    margin: '0 8px'
  },
  graph__number: {
    marginBottom: 6,
    fontSize: 12,
    fontSeight: 600,
    fontFamily: 'sans-serif'
  },
  graph__pillar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    maxWidth: 21,
    maxHeight: '100%',
    width: '100%',
    borderRadius: 13
  },
  graph__ball: {
    display: 'block',
    position: 'relative',
    top: 1,
    height: 19,
    width: 19,
    backgroundColor: 'white',
    borderRadius: '50%'
  },
  graph__pillar_lastYear: {
    backgroundColor: '#79cbc4'
  },
  graph__pillar_thisYear: {
    backgroundColor: '#3398fc'
  },
  layout__graphWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  layout__bottomColumn: {
    width: '33.33%'
  },
  materialsInnerWrapper: {
    display: 'inline-block'
  },
  materialsItemTitle: {
    textAlign: 'center',
    maxWidth: 100,
    fontWeight: 500,
    '&::first-letter': {
      textTransform: 'uppercase'
    }

  },
}));

const TopMaterialsDetail = ({
  data,
  intl,
  title,
  thisYear,
  lastYear
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.layout__bottomColumn}`}>
      <div className={`${classes.materialsInnerWrapper}`}>
        <Typography className="mr-4" variant="h1">
          {intl.formatMessage({ id: title })}
        </Typography>
        <ColorLegend legendSubtitle="Correct answers" thisYear={thisYear} lastYear={lastYear}/>
        <div className={`${classes.graph__2items}`}>
          {
            data.length
              ? (
                <div className={`${classes.layout__graphWrapper}`}>
                  {
                    data.filter((item, index) => index < 3).map((item, i) => (
                      <div className={`${classes.graph}`}>
                        <div className={`${classes.graph__wrapper}`}>
                          <div className={`${classes.graph__item}`}>
                            <div className={`${classes.graph__number}`}>
                              {item.last_yr}
                              %
                            </div>
                            <div className={`${classes.graph__pillar} ${classes.graph__pillar_lastYear}`} style={{ height: `${item.last_yr}%` }}>
                              <div className={`${classes.graph__ball}`} />
                            </div>
                          </div>
                          <div key={`${item.mat_nm + i}`} className={`${classes.graph__item}`}>
                            <div className={`${classes.graph__number}`}>
                              {item.this_yr}
                              %
                            </div>
                            <div className={`${classes.graph__pillar} ${classes.graph__pillar_thisYear}`} style={{ height: `${item.this_yr}%` }}>
                              <div className={`${classes.graph__ball}`} />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className={`${classes.materialsItemTitle}`}>
                          {item.mat_nm}
                        </div>
                      </div>
                    ))
                  }
                </div>
              )
              : (
                <Typography className="mr-4" variant="h2">
                  Cannot Display Data
                </Typography>
              )
          }
        </div>
      </div>
    </div>
  );
};

export default injectIntl(TopMaterialsDetail);
