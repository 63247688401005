import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { Link, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { TableLoading } from '../../../components/loading';
import ReportPDF from './ReportPDF';

import { PdfIcon, BackIcon } from '../../../../assets/icons';
import {
  Portlet, PortletBody, PortletHeader, PortletHeaderToolbar,
} from '../../../../../_metronic/partials/content/Portlet';
import { SummaryHeader, RCSpinner } from '../../components';
import * as actions from '../redux/actions';
import * as sharedActions from '../../../redux/actions';
import Badge from './Badge';
import { isValidDate, checkValidDistrict } from '../../../../utils';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_PRIMARY,
  },
  selectBar: {
    border: '10px !important',
    marginTop: '21px',
    [theme.breakpoints.down(992)]: {
      paddingBottom: '20px !important'
    }
  },
  tabs: {
    marginTop: '28px',
    marginBottom: '15px',
    marginLeft: '5px',
  },
  pdfDisable: {
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_GRAY_DARK,
  }
}));

const Main = ({
  intl,
  municipalities,
  district,
  setDistrictAction,
  fromDate,
  toDate,
  setDateRangeAction,
  allTime,
  county,
  isCounty,
  fetchState,
  setIsCountyAction,
  BadgeReportRequest,
  setBadgeReportAllTimeAction,
  data
}) => {
  const classes = useStyles();
  const history = useHistory();
  const isSendRequest = fetchState === 'send-request';
  const queryParams = new URLSearchParams(useLocation().search);
  const [pdfSpinner, setPdfSpinner] = useState(false);

  useEffect(() => {
    setIsCountyAction(false);
    if (history.location.search) {
      if (queryParams.get('district') && checkValidDistrict(
        queryParams.get('district').replace('-', '#$#'),
        municipalities,
        'district_id'
      )) {
        setDistrictAction({
          district_id: queryParams.get('district').replace('-', '#$#'),
          district_nm: municipalities.filter((item) => item.district_id === queryParams.get('district').replace('-', '#$#'))[0].district_nm,
        });
        setIsCountyAction(false);
      } else {
        setDistrictAction({
          district_id: '#all-districts#',
          district_nm: 'All Districts'
        });
      }
      if (queryParams.get('from') && queryParams.get('to') && isValidDate(queryParams.get('from')) && isValidDate(queryParams.get('to'))) {
        setDateRangeAction({
          fromDate: queryParams.get('from'),
          toDate: queryParams.get('to'),
        });
      }
    } else {
      setDistrictAction({
        district_id: '#all-districts#',
        district_nm: 'All Districts'
      });
    }
  }, []);

  useEffect(() => {
    if (isCounty) {
      history.replace({
        pathname: '/report/badge',
        search: !allTime ? `?county=${county.county_id}&from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
          : `?county=${county.county_id}`
      });
    } else {
      history.replace({
        pathname: '/report/badge',
        search: !allTime ? `?district=${district.district_id === '#all-districts#' ? 'all-districts' : district.district_id.replace('#$#', '-')}&from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
          : `?district=${district.district_id.replace('#$#', '-')}`
      });
    }
  }, [fromDate, toDate, district, county, isCounty]);

  const generatePDF = async () => {
    setPdfSpinner(true);
    const blob = await pdf((
      <ReportPDF
        title={intl.formatMessage({ id: 'REPORT.BADGE.TITLE' })}
        currentPeriod={`${intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_PERIOD' })}:`}
        date={`${allTime ? '2021-01-01' : moment(fromDate).format('YYYY-MM-DD')} - ${moment(toDate).format('YYYY-MM-DD')}`}
        district={isCounty ? `Reporting County: ${county.county_nm}`
          : `Reporting District: ${district.district_id !== '#all-districts#' ? district.district_nm : 'All Districts'}`}
        data={data}
        badgeUserTitle={intl.formatMessage({ id: 'REPORT.BADGE.TABLE.UNIQUE_USER' })}
        badgePass={intl.formatMessage({ id: 'REPORT.BADGE.TABLE.PASS_USER' })}
        badgePercent={intl.formatMessage({ id: 'REPORT.BADGE.TABLE.PASS_PERCENTAGE' })}
        badgeHouseHold={intl.formatMessage({ id: 'REPORT.BADGE.TABLE.HOUSE_HOLD' })}
        badgeMunicipality={intl.formatMessage({ id: 'REPORT.BADGE.TABLE.SUBTITLE.YOUR_MUNICIPALITY' })}
        badgeOtherMunicipality={intl.formatMessage({ id: 'REPORT.BADGE.TABLE.SUBTITLE.OTHER_MUNICIPALITY' })}
      />
    )).toBlob();
    saveAs(blob, '‌Badge Report.pdf');
    setPdfSpinner(false);
  };

  return (
    <Portlet>
      <PortletHeader
        title={(
          <Link to="/report/dashboard">
            <Button
              classes={{
                label: classes.label,
              }}
              startIcon={<BackIcon color={process.env.REACT_APP_COLOR_PRIMARY} />}
            >
              {intl.formatMessage({ id: 'REPORT.GENERAL.BACK' })}
            </Button>
          </Link>
        )}
        toolbar={(
          <PortletHeaderToolbar>
            <Button
              disabled={isSendRequest}
              classes={{
                label: isSendRequest ? classes.pdfDisable : classes.label,
              }}
              startIcon={(
                <PdfIcon
                  color={isSendRequest ? process.env.REACT_APP_COLOR_GRAY_DARK
                    : process.env.REACT_APP_COLOR_PRIMARY}
                />
              )}
              onClick={generatePDF}

            >
              {intl.formatMessage({ id: 'REPORT.GENERAL.PDF' })}
            </Button>
            <RCSpinner isActive={pdfSpinner} />
          </PortletHeaderToolbar>
        )}
      />
      <PortletHeader
        className={classes.selectBar}
        toolbar={(
          <PortletHeaderToolbar
            className="w-100 d-flex align-items-center"
          >
            <SummaryHeader
              className="w-100"
              title={intl.formatMessage({ id: 'REPORT.BADGE.TITLE' })}
              handleDataRequest={BadgeReportRequest}
              allTime={allTime}
              handleAllTimeChange={setBadgeReportAllTimeAction}
              hasAllTimeOption
              startOfAllTime="2021-01-01"
              isCountyEnable
            />

          </PortletHeaderToolbar>
        )}
      />
      <PortletBody className="pt-0">
        {
          isSendRequest ? (
            <div className="pt-4">
              <TableLoading width="100%" />
            </div>
          ) : (
            <div className="row w-100 mb-4 flex-column">
              <Badge data={data} />
            </div>
          )
        }
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
  detailData: store.userReport.data,
  municipalities: store.common.municipalities,
  county: store.common.county,
  counties: store.common.counties,
  isCounty: store.common.isCounty,
  hasCounty: store.auth.user_info.counties,
  allTime: store.badgeReport.allTime,
  data: store.badgeReport.data,
  fetchState: store.badgeReport.fetchState,
});

export default injectIntl(
  connect(
    mapStateToProps,
    { ...actions, ...sharedActions }
  )(Main)
);
