import actionTypes from './actionTypes';

// Auth actions for setting loading status
export const setLoadingAction = () => ({
  type: actionTypes.SET_LOADING
});

export const resetLoadingAction = () => ({
  type: actionTypes.RESET_LOADING
});

// Login actions
export const loginAsRequest = (key) => ({
  type: actionTypes.LOGIN_AS_REQUEST,
  payload: { key }
});

export const loginAsReceive = (data) => ({
  type: actionTypes.LOGIN_AS_RECEIVE,
  payload: { ...data }
});

export const loginAsFail = (error) => ({
  type: actionTypes.LOGIN_AS_FAIL,
  payload: { ...error }
});
