/**
 *
 */
const initialState = {
  fetchState: 'no-request',
  snackbarState: 'no-request',
  responseStatus: 'success',
  responseMessage: null,
  eventWizardStage: 0,
  eventWizardId: null,
  eventList: [],
  addressSuggestions: [],
};

export default initialState;
