import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';
import createReducer from '../../../../redux/createReducer';
import initialState from './store';

const reportsDashboardReducer = persistReducer(
  { storage, key: 'reportsDashboard', whitelist: [] },
  createReducer(initialState, {
    SET_DASHBOARD_DISTRICT_ACTION: (state, { payload }) => Immutable({
      ...state,
      district: payload
    }),
    SET_DASHBOARD_FROM_DATE_ACTION: (state, { payload }) => Immutable({
      ...state,
      fromDate: payload
    }),
    SET_DASHBOARD_TO_DATE_ACTION: (state, { payload }) => Immutable({
      ...state,
      toDate: payload
    }),
    GET_REPORT_DASHBOARD_REQUEST: (state) => Immutable({
      ...state,
      loading: true,
      error: null
    }),
    GET_REPORT_DASHBOARD_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      ...payload.data,
      loading: false,
    }),
    GET_REPORT_DASHBOARD_FAIL: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      error: payload,
    }),
    GET_REPORT_DASHBOARD_DETAILS_REQUEST: (state, { payload }) => Immutable({
      ...state,
      loading: true,
      error: null,
    }),
    GET_REPORT_DASHBOARD_DETAILS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      ...payload,
      loading: false,
    }),
    GET_REPORT_DASHBOARD_DETAILS_FAIL: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      error: payload,
    }),
    GET_MATERIAL_DETAILS_REQUEST: (state, { payload }) => Immutable({
      ...state,
      loading: true,
      error: null,
    }),
    GET_MATERIAL_DETAILS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      ...payload,
      loading: false,
    }),
    GET_MATERIAL_DETAILS_FAIL: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      error: payload,
    }),
    GET_MAJOR_CAMPAIGN_DETAILS_REQUEST: (state, { payload }) => Immutable({
      ...state,
      loading: true,
      error: null,
    }),
    GET_MAJOR_CAMPAIGN_DETAILS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      ...payload,
      loading: false,
    }),
    GET_MAJOR_CAMPAIGN_DETAILS_FAIL: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      error: payload,
    }),
    GET_BULK_PICKUP_DETAILS_REQUEST: (state, { payload }) => Immutable({
      ...state,
      // loading: true,
      bpLoading: true,
      error: null,
    }),
    GET_BULK_PICKUP_DETAILS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      ...payload,
      // loading: false,
      bpLoading: false,

    }),
    GET_BULK_PICKUP_DETAILS_FAIL: (state, { payload }) => Immutable({
      ...state,
      // loading: false,
      bpLoading: false,
      error: payload,
    }),
    GET_BULK_PICKUP_DASHBOARD_REQUEST: (state, { payload }) => Immutable({
      ...state,
      // loading: true,
      bpLoading: true,
      error: null,
    }),
    GET_BULK_PICKUP_DASHBOARD_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      ...payload,
      // loading: false,
      bpLoading: false,

    }),
    GET_BULK_PICKUP_DASHBOARD_FAIL: (state, { payload }) => Immutable({
      ...state,
      // loading: false,
      bpLoading: false,
      error: payload,
    }),
    GET_WGW_MATERIAL_DETAILS_REQUEST: (state, { payload }) => Immutable({
      ...state,
      wgwLoading: true,
      error: null,
    }),
    GET_WGW_MATERIAL_DETAILS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      ...payload,
      wgwLoading: false,

    }),
    GET_WGW_MATERIAL_DETAILS_FAIL: (state, { payload }) => Immutable({
      ...state,
      wgwLoading: false,
      error: payload,
    }),
  })
);

export default reportsDashboardReducer;
