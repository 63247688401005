const actionTypes = {
  GET_ZONE_SCHEDULE_FAIL: 'GET_ZONE_SCHEDULE_FAIL',

  GET_HOLIDAYS_SCHEDULE_REQUEST: 'GET_HOLIDAYS_SCHEDULE_REQUEST',
  GET_HOLIDAYS_SCHEDULE_RECEIVE: 'GET_HOLIDAYS_SCHEDULE_RECEIVE',
  GET_HOLIDAYS_SCHEDULE_FAIL: 'GET_HOLIDAYS_SCHEDULE_FAIL',

  SAVE_HOLIDAYS_SCHEDULE_REQUEST: 'SAVE_HOLIDAYS_SCHEDULE_REQUEST',
  SAVE_HOLIDAYS_SCHEDULE_RECEIVE: 'SAVE_HOLIDAYS_SCHEDULE_RECEIVE',
  SAVE_HOLIDAYS_SCHEDULE_FAIL: 'SAVE_HOLIDAYS_SCHEDULE_FAIL',

  GET_PERPETUAL_SCHEDULES_REQUEST: 'GET_PERPETUAL_SCHEDULES_REQUEST',
  GET_PERPETUAL_SCHEDULES_RECEIVE: 'GET_PERPETUAL_SCHEDULES_RECEIVE',
  GET_PERPETUAL_SCHEDULES_FAIL: 'GET_PERPETUAL_SCHEDULES_FAIL',

  MANAGE_PERPETUAL_SCHEDULES_REQUEST: 'MANAGE_PERPETUAL_SCHEDULES_REQUEST',
  MANAGE_PERPETUAL_SCHEDULES_RECEIVE: 'MANAGE_PERPETUAL_SCHEDULES_RECEIVE',
  MANAGE_PERPETUAL_SCHEDULES_FAIL: 'MANAGE_PERPETUAL_SCHEDULES_FAIL',

  SET_MULTI_DISTRICTS: 'SET_MULTI_DISTRICTS',
  REMOVE_FROM_MULTI_DISTRICTS: 'REMOVE_FROM_MULTI_DISTRICTS',

  GET_SEASONAL_LIST_REQUEST: 'GET_SEASONAL_LIST_REQUEST',
  GET_SEASONAL_LIST_RECEIVE: 'GET_SEASONAL_LIST_RECEIVE',
  GET_SEASONAL_LIST_FAIL: 'GET_SEASONAL_LIST_FAIL',

  SAVE_SEASONAL_COLLECTION_REQUEST: 'SAVE_SEASONAL_COLLECTION_REQUEST',
  SAVE_SEASONAL_COLLECTION_RECEIVE: 'SAVE_SEASONAL_COLLECTION_RECEIVE',
  SAVE_SEASONAL_COLLECTION_FAIL: 'SAVE_SEASONAL_COLLECTION_FAIL',

  SET_CURRENT_COLLECTION: 'SET_CURRENT_COLLECTION',

  UPDATE_SEASONAL_COLLECTION: 'UPDATE_SEASONAL_COLLECTION',

  SET_COMPLETED_SETUP: 'SET_COMPLETED_SETUP',

  RESET_SEASONAL_LIST: 'RESET_SEASONAL_LIST',
};

export default actionTypes;
