import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';


import { Typography } from '../../../components';

import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';

import { PopularFeaturesChart, ProFeaturesOverlay } from './index';

import { SummaryCellLoading } from '../../../components/loading';
import { DropdownMenu } from '../../../Education/EducationalContent/components';

import { EducationModal } from '../components';


const useStyles = makeStyles((theme) => ({
  popularFeatutesItem: {
    maxWidth: '20%!important',
    padding: '0 5px!important',
    '@media (max-width: 1390px)': {
      maxWidth: 'unset !important',
    }
  },
  popularFeatutesItemTitle: {
    color: '#404040',
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
  }

}));

const PopularFeatures = ({
  intl,
  reportData,
  isUserPro,
  stage,
  csm,
  isLoading,
  district_pro_packages,
}) => {
  const classes = useStyles();

  /**
* Menu and menuitem functions.
*/

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const descriptionHandler = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    handleClose();
  }

  /**
  * Data for dropdown menu items.
  */
  const menuItemData = [
    {
      id: 1,
      name: 'Report Description',
      method: descriptionHandler,
      disabled: false
    },

  ];

  const descriptionColumn = [
    {
      title: 'Recycle RIGHT game',
      description: 'users play this engaging game by performing a ‘swipe’ motion to indicate whether a material is accepted in their local recycling program (swipe right) or not (swipe left). The chart shows the number of swipes during the latest 3-month period, vs the same period last year.'
    },
    {
      title: 'Recycling Fundamentals',
      description: 'this is a series of five lessons that address contamination. The chart shows lesson participation during the latest 3-month period, vs the same period last year.'
    },
    {
      title: 'Misunderstood Materials',
      description: 'these ten materials can be confusing for residents and this feature helps clarify what is accepted in your local program. The chart shows engagement with this feature during the latest 3-month period, vs the same period last year.'
    },
    {
      title: 'Where does this go?',
      description: 'each month there are five materials highlighted to teach residents where they belong (e.g., garbage, recycling, yard waste, etc.). Some are seasonal, others are sometimes disposed of incorrectly. The chart shows engagement with these materials during the latest 3-month period, vs the same period last year.'
    },
    {
      title: 'Featured Lesson',
      description: 'Featured lessons are a core element of Campaigns. They focus attention on the main campaign topic. The chart shows engagement with Featured Lessons during the latest 3-month period, vs the same period last year.'
    },
  ]

  let proFeatures = [
    'recycle_right_game_engagement',
    'where_does_it_go_engagement',
    'featured_lesson_engagement',
  ];

  if (district_pro_packages && district_pro_packages[0].has_spot_light_material === 't') {
    proFeatures = proFeatures.filter(feature => feature !== 'where_does_it_go_engagement');
  }
  
  if (district_pro_packages && district_pro_packages[0].has_game === 't') {
    proFeatures = proFeatures.filter(feature => feature !== 'recycle_right_game_engagement');
  }
  
  if (district_pro_packages && district_pro_packages[0].has_featured_lesson === 't') {
    proFeatures = proFeatures.filter(feature => feature !== 'featured_lesson_engagement');
  }

  return (
    <>
      <Portlet>
        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start" style={{ justifyContent: 'space-between' }}>
                <div className="row col-xs-12 col-lg-6 align-items-center">
                  <div className="align-items-center pl-3">
                    <Typography variant="h1">
                      Popular Features
                    </Typography>
                  </div>
                </div>
                <DropdownMenu
                  handleClick={handleClick}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  menuItemData={menuItemData}
                />
              </div>
            )}
          />
          <EducationModal
            open={open}
            onClose={() => closeModal()}
            title={'Description'}
            content={(
              <Typography variant="body1" style={{ textAlign: 'left' }}>
                These popular educational activities help residents better understand their local waste & recycling program:
                <ul>
                  {
                    descriptionColumn.map((item) => (
                      <li> <strong>{item.title}</strong> - {item.description} </li>
                    ))
                  }
                </ul>
              </Typography>
            )}
          />
        </div>
        <PortletBody>
          {
            isLoading ?
              <div className="row mt-2">
                <div className={`col-md-6 col-xl-3 ${classes.popularFeatutesItem}`}> <SummaryCellLoading /> </div>
                <div className={`col-md-6 col-xl-3 ${classes.popularFeatutesItem}`}> <SummaryCellLoading /> </div>
                <div className={`col-md-6 col-xl-3 ${classes.popularFeatutesItem}`}> <SummaryCellLoading /> </div>
                <div className={`col-md-6 col-xl-3 ${classes.popularFeatutesItem}`}> <SummaryCellLoading /> </div>
                <div className={`col-md-6 col-xl-3 ${classes.popularFeatutesItem}`}> <SummaryCellLoading /> </div>
              </div>
              : <div className="row mt-2">
                {
                  reportData.popular_feature && Object.keys(reportData.popular_feature).map((item) => (
                    <div className={`col-md-6 col-xl-3 ${classes.popularFeatutesItem}`}>
                      {
                        proFeatures.includes(item) && (
                          <>
                            {(!isUserPro && !stage) && (
                              <div style={{ width: 252 }}>
                                <ProFeaturesOverlay csm={csm} />
                              </div>
                            )}
                            {!isUserPro && Number(stage) < 4 && (
                              <div style={{ width: 252 }}>
                                <ProFeaturesOverlay csm={csm} />
                              </div>)}
                          </>
                        )
                      }


                      <Typography variant="h4" className={`ml-3 ${classes.popularFeatutesItemTitle}`}>
                        {item.replace(/_/g, ' ').replace(/engagement/g, '').replace(/fundamental/g, 'fundamentals').replace(/material/g, 'materials').replace(/lesson/g, 'lessons')}
                      </Typography>
                      <PopularFeaturesChart reportData={reportData} feature={item} />

                    </div>
                  ))
                }
              </div>
          }

        </PortletBody>
      </Portlet >
    </>
  );

};

export default injectIntl(PopularFeatures);
