import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import moment from 'moment';

import { Typography } from '../../../../components';

import { DropdownMenu } from '../../../../Education/EducationalContent/components';
import { TableLoading } from '../../../../components/loading';

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: 'white',
    marginRight: 0,
    marginLeft: 0,
    marginTop: '0 !important',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: '8px 0',
    minHeight: 72,
    textTransform: 'uppercase',
    borderBottom: '1px solid lightgrey',


  },
  tableHeaderFont: {
    color: '#404040',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.52px',
    textTransform: 'uppercase',
    fontSize: 13,
  },
  tableRow: {
    borderBottom: '1px solid lightgrey',
    marginLeft: 0,
    marginRight: 0,
    '&::after': {
      borderBottom: '1px solid lightgrey',
    }
  },
  tableLabel: {
    borderBottom: 'none !important',
    borderRadius: '0 !important',
    padding: '0 !important',
  },
  tableBody: {
    padding: '0 !important'
  },
  threeDotMenmu: {
    justifyContent: 'flex-end',
    marginLeft: '-28px',
  }

}));

const MajorCampaignsTable = ({
  intl,
  isLoading = false,
  majorCampaignsDetails,
}) => {
  const classes = useStyles();
  const history = useHistory();
  /**
 * Menu and menuitem functions.
 */

  const [anchorEl, setAnchorEl] = useState(null);
  const [campaignID, setCampaignID] = useState(null);

  const handleClick = (id, event) => {
    setCampaignID(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElTitle(null);
  };

  const viewDetailsHandler = (e) => {
    history.push(`/report/major-campaign/detail?campaignID=${campaignID}`);
  };

  /**
* Data for dropdown menu items.
*/
  const menuItemData = [
    {
      id: 1,
      name: 'View Details',
      method: viewDetailsHandler,
      disabled: false
    },

  ];

  const headers = [
    {
      name: 'Campaign Name',
      className: 'col-2',
    },
    {
      name: 'Active period',
      className: 'col-1',
    },
    {
      name: 'Unique users',
      className: 'col-1',
    },
    {
      name: 'Total Exposure',
      className: 'col-1',
    },
    {
      name: 'Total engagements',
      className: 'col-2',
    },
    {
      name: 'Knowledge Measurement Engagements',
      className: 'col-2',
    },
    {
      name: 'Correct Response Rate',
      className: 'col-1',
    },
    {
      name: 'Campaign Replacement Value',
      className: 'col-1',
    }
  ];

  const renderTableData = (item) => {
    const tableData = [
      {
        className: 'col-2',
        value: item.title,
      },
      {
        className: 'col-1',
        value: `${moment(new Date(item.start_dt)).utc().format('MMM D')} - ${moment(new Date(item.end_dt)).utc().format('MMM D, YYYY')}`,
      },
      {
        className: 'col-1',
        value: item.unique_user === 'N/A' ? item.unique_user : Number(item.unique_user).toLocaleString(),
      },
      {
        className: 'col-1',
        value: item.exposure === 'N/A' ? item.exposure : Number(item.exposure).toLocaleString(),
      },
      {
        className: 'col-2',
        value: item.engagement === 'N/A' ? item.engagement : Number(item.engagement).toLocaleString(),
      },
      {
        className: 'col-2',
        value: Number(item.knowledge_measurement_eng).toLocaleString(),
      },
      {
        className: 'col-1',
        value: item.correct_answer_rate === 'N/A' ? item.correct_answer_rate : `${Number(item.correct_answer_rate).toLocaleString()}%`,
      },
      {
        className: 'col-1',
        // value: Number(item.campaign_replacement_value).toLocaleString('en-US', {
        //   style: 'currency',
        //   currency: 'USD',
        // }),
        value: 'N/A'
      }
    ];

    return (
      <div
        key={item.id}
        className={`row d-flex align-items-center pt-4 pb-4 ${classes.border} ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableRow}`}
      >
        {
          tableData.map((data, index) => (
            <div key={index} className={data.className}>
              <Typography variant="bodySemiBold">
                {data.value}
              </Typography>
            </div>
          ))
        }
        <div className={`col-1 d-flex ${classes.threeDotMenmu}`}>
          <DropdownMenu
            handleClick={((e) => { handleClick(item.id, e) })}
            anchorEl={anchorEl}
            handleClose={handleClose}
            menuItemData={menuItemData}
          />
        </div>
      </div>

    )
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className='mt-5'>
        <Typography variant="h1" className="mb-4">
          {intl.formatMessage({ id: 'EDUCATION.MAJOR.CAMPAIGNS' })}

        </Typography>
        {
          isLoading ?
            <div className='d-flex justify-content-center'><TableLoading /> </div> : (
              <div style={{ padding: '25px 0px' }}>
                <div className="row" style={{ margin: '0px' }}>

                  {
                    majorCampaignsDetails && (
                      <div style={{ width: '100%' }}>
                        <div className={`${classes.tableBody}`}>
                          <div className={`row d-flex align-items-center mt-4 ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableHeader}`}>
                            {
                              headers.map((header) => (
                                <div key={header.name} className={header.className}>
                                  <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                                    {header.name}
                                  </Typography>
                                </div>
                              ))
                            }
                            <div className="col row d-flex" />
                          </div>
                          <div className={classes.dataTable}>
                            {
                              majorCampaignsDetails.map((item) => renderTableData(item))
                            }
                          </div>
                        </div>
                      </div>
                    )

                  }

                </div>
              </div>
            )
        }

      </div>
    </div>
  );
};

export default injectIntl(MajorCampaignsTable);
