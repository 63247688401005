/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../../_metronic/partials/content/Portlet';
import CustomButton from './CustomButton';
import IconButton from './IconButton';

/**
 * Custom Dialog based on material-ui Dialog.
 * @param {string} buttonText The title for button that is responsible for opening our Dialog box.
 * @param {string} icon The icon that is responsible for opening our Dialog box.
 * @param {string} iconTooltip The tooltip for icon that is responsible for opening our Dialog box.
 * @param {string} title The title of Dialog box.
 * @param {string} text The information text inside of Dialog box.
 * @param {ReactNode} children The extra content that you want to show in Dialog box, like inputs.
 * @param {string} maxWidth The size of Dialog box (values = 'xs', 'sm', 'md', 'lg', 'xl').
 * @param {string} submitText The title for submit button inside Dialog box.
 * @param {string} cancelText The title for cancel button inside Dialog box.
 * @param {function} onClick It is called when user clicks on submit button.
 */

const useStyles = makeStyles((theme) => ({
  dialogButton: {
    outline: 'none'
  },
  paper: {
    position: 'absolute',
    margin: 0,
    top: 100,
    borderRadius: 5,
    maxHeight: '80vh'
  },
  scrollPaper: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  buttonWrapper: {
    display: 'flex',
    width: '100%',
    marginTop: 10,
    justifyContent: 'flex-end'
  },
  buttonGroup: {
    width: '80%',
    display: 'flex',
    [theme.breakpoints.down(500)]: {
      width: '100%',
      flexDirection: 'column-reverse'
    }
  },
  button: {
    minWidth: '150px',
    maxHeight: '50px',
    flex: '1 1 0px',
    marginLeft: 10,
    [theme.breakpoints.down(500)]: {
      marginLeft: 0,
      marginBottom: 10,
    }
  }
}));

const Dialog = ({
  className,
  contentClassName,
  button,
  title,
  content,
  maxWidth = 'xs',
  submitText = 'Submit',
  cancelText = 'Cancel',
  handleSubmit,
  handleCancel,
  hasButtons = true,
  disableBackdropClick = true,
  forceOpen = false,
  isDisabled = false,
  cancelDisable = false,
  submitDisable = false
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(forceOpen);

  return (
    <div className={className}>
      <span
        className={classes.dialogButton}
        onClick={() => setOpen(!isDisabled && true)}
        onKeyPress={() => setOpen(!isDisabled && true)}
        role={isDisabled ? 'layout' : 'button'}
        tabIndex={0}
      >
        {button}
      </span>
      <MuiDialog
        classes={{
          paper: classes.paper,
          scrollPaper: classes.scrollPaper
        }}
        open={open}
        onClose={() => { if (disableBackdropClick) { setOpen(false); } }}
        maxWidth={maxWidth}
        fullWidth
      // disableBackdropClick={disableBackdropClick}
      >
        <Portlet className="mb-0">
          <PortletHeader
            title={title}
            toolbar={(
              <PortletHeaderToolbar>
                <IconButton tooltip="Close" onClick={() => setOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </PortletHeaderToolbar>
            )}
          />
          <PortletBody className={contentClassName}>
            {content}
            {hasButtons && (
              <div className={classes.buttonWrapper}>
                <div className={classes.buttonGroup}>
                  <div className={classes.button}>
                    <CustomButton
                      disabled={cancelDisable}
                      label={cancelText}
                      variant="outlined"
                      onClick={() => {
                        if (handleCancel) {
                          handleCancel();
                        }
                        setOpen(false);
                      }}
                    />
                  </div>
                  <div className={classes.button}>
                    <CustomButton
                      disabled={submitDisable}
                      label={submitText}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (handleSubmit) {
                          handleSubmit();
                        }
                        setOpen(false);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </PortletBody>
        </Portlet>
      </MuiDialog>
    </div>
  );
};

export default Dialog;
