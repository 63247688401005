/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';

import {
  Typography, IconButton, CheckBox, TextField, CustomButton, WysiwygEditor,
} from '../../components';
import { EditIcon } from '../../../assets/icons';
import * as actions from '../redux/actions';

const useStyles = makeStyles(() => ({
  descriptionItem: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    maxWidth: 350,
  },
  content: {
    maxWidth: 350,
  },
  more: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    padding: '0 !important',
  },
  radioIcon: {
    padding: 0,
    paddingRight: 10,
  },
  radioLabel: {
    margin: 0,
    display: 'flex',
    alignItems: 'flex-start',
  },
  checkboxLabel: {
    paddingLeft: 10,
  },
}));

const stripTags = (data) => data.replace(/(<([^>]+)>)/ig, '');

const CategoryDescription = ({
  intl,
  locality,
  data,
  updateCategoryDescriptionRequest,
}) => {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [desc, setDesc] = useState(
    data.fixed_body_id && data.fixed_body_id !== ''
      ? data.fixed_body_id
      : 'custom'
  );
  const [isMoreInfo, setIsMoreInfo] = useState(data.more_info !== '');
  const [moreInfo, setMoreInfo] = useState(data.more_info);
  const [newDescriptionHeader, setNewDescriptionHeader] = useState(
    data.disposal_header || ''
  );
  const [newDescriptionContent, setNewDescriptionContent] = useState(
    data.custom_body || ''
  );
  const [showMore, setShowMore] = useState(false);
  let descriptionHeader;
  let descriptionBody;

  useEffect(() => {
    if (newDescriptionContent && newDescriptionContent.length) {
      setDesc('custom');
    }
  }, []);

  useEffect(() => {
    if (!isMoreInfo) {
      setMoreInfo('');
    }
  }, [isMoreInfo]);

  if (data.fixed_bodies.find((item) => item.id === data.fixed_body_id)) {
    descriptionHeader = data.fixed_bodies.find(
      (item) => item.id === data.fixed_body_id
    ).disposal_header;
    descriptionBody = data.fixed_bodies.find(
      (item) => item.id === data.fixed_body_id
    ).disposal_fixed_body;
  } else {
    descriptionHeader = data.disposal_header;
    descriptionBody = data.custom_body;
  }

  return (
    <>
      {!editMode ? (
        <div className="pt-4">
          <div className="mb-3 d-flex justify-content-between">
            <Typography variant="bodySemiBold">
              <div
                dangerouslySetInnerHTML={{
                  __html: descriptionHeader
                }}
              />
            </Typography>
            <IconButton onClick={() => setEditMode(true)}>
              <EditIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
            </IconButton>
          </div>
          {descriptionBody && descriptionBody.length > 200 ? (
            <>
              <Typography className={classes.content} variant="body">
                <div
                  dangerouslySetInnerHTML={{
                    __html: showMore
                      ? descriptionBody
                      : `${descriptionBody.slice(0, 200)} ...`
                  }}
                />

              </Typography>
              <CustomButton
                className={classes.more}
                label={(
                  <Typography
                    variant="body"
                    color={process.env.REACT_APP_COLOR_PRIMARY}
                  >
                    {showMore
                      ? intl.formatMessage({ id: 'GENERAL.LESS' })
                      : intl.formatMessage({ id: 'GENERAL.MORE' })}
                  </Typography>
                )}
                onClick={() => setShowMore(!showMore)}
              />
            </>
          ) : (
            <Typography className={classes.content} variant="body">
              <div
                dangerouslySetInnerHTML={{ __html: descriptionBody }}
              />
            </Typography>
          )}
        </div>
      ) : (
        <>
          <FormControl className="w-100" component="fieldset">
            <RadioGroup
              value={desc}
              onChange={(event) => setDesc(event.target.value)}
            >
              {data.fixed_bodies
                && data.fixed_bodies.map((item) => (
                  <div
                    key={item.id}
                    className={clsx({
                      'py-4': true,
                      [classes.descriptionItem]: true,
                    })}
                  >
                    <FormControlLabel
                      classes={{
                        root: classes.radioLabel,
                      }}
                      value={item.id}
                      control={(
                        <Radio
                          classes={{
                            root: classes.radioIcon,
                          }}
                          color="primary"
                        />
                      )}
                      label={(
                        <>
                          <Typography className="mb-2" variant="bodySemiBold">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.disposal_header
                              }}
                            />
                          </Typography>
                          <Typography
                            className={classes.content}
                            variant="body"
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.disposal_fixed_body
                              }}
                            />
                          </Typography>
                        </>
                      )}
                    />
                  </div>
                ))}
              <FormControlLabel
                classes={{
                  root: classes.radioLabel,
                }}
                className="py-4"
                value="custom"
                control={(
                  <Radio
                    classes={{
                      root: classes.radioIcon,
                    }}
                    color="primary"
                  />
                )}
                label={(
                  <Typography className="mb-2" variant="body">
                    {intl.formatMessage({
                      id:
                        'CATEGORY.CATEGORIES.CATEGORY_DESCRIPTION.CUSTOM_DESCRIPTION',
                    })}
                  </Typography>
                )}
              />
              {desc === 'custom' && (
                <>
                  <TextField
                    label={intl.formatMessage({
                      id: 'CATEGORY.CATEGORIES.CATEGORY_DESCRIPTION.HEADER',
                    })}
                    value={newDescriptionHeader}
                    onChange={(value) => setNewDescriptionHeader(value)}
                  />
                  <WysiwygEditor
                    value={newDescriptionContent}
                    onChange={(value) => {
                      setNewDescriptionContent(value)}
                    }
                  />
                </>
              )}
            </RadioGroup>
          </FormControl>
          <CheckBox
            label={(
              <Typography className={classes.checkboxLabel} variant="body">
                {intl.formatMessage({
                  id:
                    'CATEGORY.CATEGORIES.CATEGORY_DESCRIPTION.MORE_INFORMATION',
                })}
              </Typography>
            )}
            checked={isMoreInfo}
            onChange={() => setIsMoreInfo(!isMoreInfo)}
          />
          {isMoreInfo && (
            <WysiwygEditor
              value={moreInfo}
              onChange={(value) => setMoreInfo(value)}
            />

          )}
          <CustomButton
            label="Done"
            variant="contained"
            className="mt-4"
            disabled={
              desc === ''
              || (desc === 'custom'
                && (newDescriptionHeader.trim() === '' || newDescriptionContent.trim() === ''))
            }
            onClick={() => {
              setEditMode(false);
              updateCategoryDescriptionRequest({
                locality: locality.cms_locality_id,
                wasteType: data.waste_type_id,
                category: data.mat_cat_id,
                descriptionId: desc !== 'custom' ? desc : null,
                descriptionHeader:
                  desc === 'custom' ? newDescriptionHeader : null,
                descriptionBody:
                  desc === 'custom' ? newDescriptionContent : null,
                moreInfo,
              });
            }}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (store) => ({
  locality: store.common.locality,
});

export default injectIntl(
  connect(mapStateToProps, actions)(CategoryDescription)
);
