const actionTypes = {
  // Collection actionTypes for setting loading status
  SET_COLLECTION_LOADING_ACTION: 'SET_COLLECTION_LOADING_ACTION',
  SET_COLLECTION_SNACKBAR_ACTION: 'SET_COLLECTION_SNACKBAR_ACTION',

  // Collection actionTypes for setting collection wizard status
  SET_COLLECTION_WIZARD_STATUS_ACTION: 'SET_COLLECTION_WIZARD_STATUS_ACTION',

  // Collection actionTypes for setting collection wizard id
  SET_COLLECTION_WIZARD_ID_ACTION: 'SET_COLLECTION_WIZARD_ID_ACTION',

  // Collection actionTypes for fetching all collections
  GET_COLLECTIONS_LIST_REQUEST: 'GET_COLLECTIONS_LIST_REQUEST',
  GET_COLLECTIONS_LIST_RECEIVE: 'GET_COLLECTIONS_LIST_RECEIVE',
  GET_COLLECTIONS_LIST_FAIL: 'GET_COLLECTIONS_LIST_FAIL',

  // Collection actionTypes for creating a new collection
  CREATE_COLLECTION_REQUEST: 'CREATE_COLLECTION_REQUEST',
  CREATE_COLLECTION_RECEIVE: 'CREATE_COLLECTION_RECEIVE',
  CREATE_COLLECTION_FAIL: 'CREATE_COLLECTION_FAIL',

  // Collection actionTypes for editing a collection
  EDIT_COLLECTION_REQUEST: 'EDIT_COLLECTION_REQUEST',
  EDIT_COLLECTION_RECEIVE: 'EDIT_COLLECTION_RECEIVE',
  EDIT_COLLECTION_FAIL: 'EDIT_COLLECTION_FAIL',
};

export default actionTypes;
