import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import actionTypes from './actionTypes';
import getBlogsData from '../apis';

function* getEducationBlogsSaga({ payload: { project_id, district_id } }) {
  const response = yield call(getBlogsData, { project_id, district_id });
  try {
    yield put(actions.getAllEducationBlogsReceive(response.data));
  } catch (error) {
    yield put(actions.getAllEducationBlogsFail(response.error.detail.data.data));
  }
}

function* educationBlogsSagas() {
  yield takeLatest(actionTypes.GET_ALL_EDUCATION_BLOGS_REQUEST, getEducationBlogsSaga);
}

export default educationBlogsSagas;
