import React, { useEffect, useState } from 'react';

import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';

import { styled } from '@material-ui/core/styles';


import CustomDatePicker from './CustomDatePicker';
import CustomSelect from './CustomSelect';
import Typography from './Typography';
import TextField from './TextField';


import {
  ALL_WEEK,
  getDaysInMonth, isDayWithinTheMonth, isEqual, MONTHS_IN_YEAR, WEEK_IN_MONTH
} from '../Schedule/helper';

const StartAndEndDateSelector = ({ initialState, setStartAndEndDateCb }) => {
  const [data, setData] = useState(initialState);

  useEffect(() => {
    setData(initialState);
  }, [initialState]);

  const isValid = ((data.startDate === 'specificStartDate'
    && data.specificStartDate.day
    && data.specificStartDate.month
    && isDayWithinTheMonth(
      data.specificStartDate.day,
      data.specificStartDate.month
    ))
    || (data.startDate === 'specificStartWeek'
      && data.specificStartWeek.week
      && data.specificStartWeek.month
      && data.specificStartWeek.day)
    || (data.startDate === 'itHasAlreadyStarted' && data.itHasAlreadyStarted)
    || (data.startDate === 'IKnowNextStartDate' && data.whenWillItStart)
    || (data.startDate === 'IDoNotNowNextStartDate'
      && data.approximateStartDate)
    || (data.startDate === 'otherStartDate' && data.otherStartDate))
    && ((data.endDate === 'specificEndDate'
      && data.specificEndDate.day
      && data.specificEndDate.month
      && isDayWithinTheMonth(
        data.specificEndDate.day,
        data.specificEndDate.month
      ))
      || (data.endDate === 'specificEndWeek'
        && data.specificEndWeek.week
        && data.specificEndWeek.month
        && data.specificEndWeek.day)
      || (data.endDate === 'itHasAlreadyEnded' && data.itHasAlreadyEnded)
      || (data.endDate === 'IKnowNextEndDate' && data.whenWillItEnd)
      || (data.endDate === 'IDoNotNowNextEndDate' && data.approximateEndDate)
      || (data.endDate === 'otherEndDate' && data.otherEndDate));

  useEffect(() => {
    setStartAndEndDateCb({
      data,
      isValid: Boolean(isValid),
      isUpdated: isEqual(initialState, data),
    });
  }, [JSON.stringify(data)]);


  return (
    <>
      <Typography variant="h3" style={{ fontSize: 16, fontWeight: 600 }}>
        When does Collection START each year?
      </Typography>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
      >
        <FormControl
          style={{
            width: 360
          }}
          value={data.startDate}
          onChange={(e) => {
            const val = e.target.value;
            setData((s) => ({ ...s, startDate: val }));
            if (
              val === 'specificStartDate'
              || val === 'specificStartWeek'
              || val === 'otherStartDate'
            ) {
              setData((s) => ({ ...s, canChangeEachYearStart: false }));
            }
          }}
        >
          <RadioGroup value={data.startDate}>
            <FormControlLabel
              value="specificStartDate"
              control={<StyledRadio />}
              label={(
                <Typography variant="bodySemiBold">
                  Specific Date (i.e., Same date every year)
                </Typography>
              )}
              checked={data.startDate === 'specificStartDate'}
            />
            {data.startDate === 'specificStartDate' && (
              <>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '8px',
                }}
                >

                  <div style={selectWrapper}>
                    <CustomSelect
                      textVariant="h3"
                      options={getDaysInMonth(
                        data?.specificStartDate?.month || 'January'
                      )}
                      selectedValue={data.specificStartDate.day}
                      onSelect={(val) => {
                        setData((s) => ({
                          ...s,
                          specificStartDate: {
                            ...data.specificStartDate,
                            day: val,
                          },
                        }));
                      }}
                      style={{ transform: 'translate(5px, 5px)' }}
                      textStyle={{ fontWeight: 400, fontSize: 14 }}
                      otherModalTitle="Other Color"
                      otherModalPlaceHolder="Color"
                      placeholder="Select"
                    />
                  </div>
                  <div style={selectWrapper}>
                    <CustomSelect
                      textVariant="h3"
                      options={MONTHS_IN_YEAR}
                      selectedValue={data.specificStartDate.month}
                      onSelect={(val) => {
                        setData((s) => ({
                          ...s,
                          specificStartDate: {
                            ...data.specificStartDate,
                            month: val,
                          },
                        }));
                      }}
                      style={{ transform: 'translate(5px, 5px)' }}
                      textStyle={{ fontWeight: 400, fontSize: 14 }}
                      otherModalTitle="Other Color"
                      otherModalPlaceHolder="Color"
                      placeholder="Select"
                    />
                  </div>
                </div>

                <Typography variant="body">
                  {!isDayWithinTheMonth(
                    data.specificStartDate.day,
                    data.specificStartDate.month
                  ) && '*Please select a date within the month!'}
                </Typography>
              </>
            )}
            <FormControlLabel
              value="specificStartWeek"
              control={<StyledRadio />}
              label={(
                <Typography variant="bodySemiBold">
                  Specific Week (i.e., Same week every year)
                </Typography>
              )}
              checked={data.startDate === 'specificStartWeek'}
            />
            {data.startDate === 'specificStartWeek' && (
              <>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  alignItems: 'center',
                }}
                >
                  <div style={selectWrapper}>
                    <CustomSelect
                      textVariant="h3"
                      options={WEEK_IN_MONTH.map((i) => i.name)}
                      selectedValue={
                        WEEK_IN_MONTH.find(
                          (i) => i.id === data.specificStartWeek.week
                        )?.name
                      }
                      onSelect={(val) => {
                        setData((s) => ({
                          ...s,
                          specificStartWeek: {
                            ...data.specificStartWeek,
                            week: WEEK_IN_MONTH.find((i) => i.name === val).id,
                          },
                        }));
                      }}
                      style={{ transform: 'translate(5px, 5px)' }}
                      textStyle={{ fontWeight: 400, fontSize: 14 }}
                      otherModalTitle="Other Color"
                      otherModalPlaceHolder="Color"
                      placeholder="Select"
                    />
                  </div>
                  <div style={selectWrapper}>
                    <CustomSelect
                      textVariant='h3'
                      options={ALL_WEEK}
                      selectedValue={data.specificStartWeek.day}
                      onSelect={(val) => {
                        setData((s) => ({
                          ...s,
                          specificStartWeek: {
                            ...data.specificStartWeek,
                            day: val,
                          },
                        }));
                      }}
                      style={{ transform: 'translate(5px, 5px)' }}
                      textStyle={{ fontWeight: 400, fontSize: 14 }}
                      otherModalTitle='Other Color'
                      otherModalPlaceHolder='Color'
                      placeholder='Select'
                    />
                  </div>
                  <div style={selectWrapper}>
                    <CustomSelect
                      textVariant="h3"
                      options={MONTHS_IN_YEAR}
                      selectedValue={data.specificStartWeek.month}
                      onSelect={(val) => {
                        setData((s) => ({
                          ...s,
                          specificStartWeek: {
                            ...data.specificStartWeek,
                            month: val,
                          },
                        }));
                      }}
                      style={{ transform: 'translate(5px, 5px)' }}
                      textStyle={{ fontWeight: 400, fontSize: 14 }}
                      otherModalTitle="Other Color"
                      otherModalPlaceHolder="Color"
                      placeholder="Select"
                    />
                  </div>
                </div>
                {data.specificStartWeek.day && data.specificStartWeek.week && data.specificStartWeek.month && <Typography variant="body">*The week collection begins each year is the one that contains the <b>{WEEK_IN_MONTH.find(
                  (i) => i.id === data.specificStartWeek.week
                )?.name}</b> <b>{data.specificStartWeek.day}</b> of <b>{data.specificStartWeek.month}</b>. </Typography>}
              </>
            )}

            <FormControlLabel
              value="itHasAlreadyStarted"
              onChange={() => {
                setData((s) => ({ ...s, canChangeEachYearStart: true }));
              }}
              control={<StyledRadio checked={data.canChangeEachYearStart} />}
              label={(
                <Typography variant="bodySemiBold">
                  Can change each year
                </Typography>
              )}
              checked={data.startDate === 'itHasAlreadyStarted'}
            />
            {data.canChangeEachYearStart && (
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                marginLeft: '16px',
              }}
              >

                <FormControlLabel
                  value="itHasAlreadyStarted"
                  control={<StyledRadio />}
                  label={(
                    <Typography variant="bodySemiBold">
                      It has already started
                    </Typography>
                  )}
                  checked={data.startDate === 'itHasAlreadyStarted'}
                />
                {data.startDate === 'itHasAlreadyStarted' && (
                  <>
                    <Typography variant="h3">
                      What was the start date?
                      {' '}
                    </Typography>
                    <CustomDatePicker
                      placeholder={data.itHasAlreadyStarted || 'Select Date'}
                      style={{ width: '200px', height: '40px' }}
                      setDate={(date) => setData((s) => ({ ...s, itHasAlreadyStarted: date }))}
                    />
                  </>
                )}

                <FormControlLabel
                  value="IKnowNextStartDate"
                  control={<StyledRadio />}
                  label={(
                    <Typography variant="bodySemiBold">
                      I know the next start date
                    </Typography>
                  )}
                  checked={data.startDate === 'IKnowNextStartDate'}
                />

                {data.startDate === 'IKnowNextStartDate' && (
                  <>
                    <Typography variant="h3">When will it start?</Typography>
                    <CustomDatePicker
                      placeholder={data.whenWillItStart || 'Select Date'}
                      style={{ width: '200px', height: '40px' }}
                      setDate={(date) => setData((s) => ({ ...s, whenWillItStart: date }))}
                    />
                  </>
                )}

                <FormControlLabel
                  value="IDoNotNowNextStartDate"
                  control={<StyledRadio />}
                  label={(
                    <Typography variant="bodySemiBold">
                      I don
                      {'\''}
                      t know next start date
                    </Typography>
                  )}
                  checked={data.startDate === 'IDoNotNowNextStartDate'}
                />

                {data.startDate === 'IDoNotNowNextStartDate' && (
                  <>
                    <Typography variant="h3">
                      Please provide an approximate date so we can remind you in
                      advance.
                    </Typography>
                    <CustomDatePicker
                      placeholder={data.approximateStartDate || 'Select Date'}
                      style={{ width: '200px', height: '40px' }}
                      setDate={(date) => setData((s) => ({ ...s, approximateStartDate: date }))}
                    />
                  </>
                )}
              </div>

            )}
            <FormControlLabel
              value="otherStartDate"
              control={<StyledRadio />}
              label={<Typography variant="bodySemiBold">Other</Typography>}
              checked={data.startDate === 'otherStartDate'}
            />
            {data.startDate === 'otherStartDate' && (
              <TextField
                label="Provide details"
                value={data.otherStartDate}
                onChange={(e) => {
                  setData((s) => ({ ...s, otherStartDate: e }));
                }}
                size="small"
              />
            )}
          </RadioGroup>
        </FormControl>
      </div>
      <Typography variant="h3" style={{ margin: '40px 0 10px 0', fontSize: 16, fontWeight: 600 }}>
        When does Collection END each year?
      </Typography>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        marginBottom: '40px',
      }}
      >
        <FormControl
          style={{
            width: 360
          }}
          value={data.endDate}
          onChange={(e) => {
            const val = e.target.value;
            setData((s) => ({ ...s, endDate: val }));
            if (
              val === 'specificEndDate'
              || val === 'specificEndWeek'
              || val === 'otherEndDate'
            ) {
              setData((s) => ({ ...s, canChangeEachYearEnd: false }));
            }
          }}
        >
          <RadioGroup value={data.endDate}>
            <FormControlLabel
              value="specificEndDate"
              control={<StyledRadio />}
              label={(
                <Typography variant="bodySemiBold">
                  Specific Date (i.e., Same date every year)
                </Typography>
              )}
              checked={data.endDate === 'specificEndDate'}
            />
            {data.endDate === 'specificEndDate' && (
              <>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '8px',
                }}
                >

                  <div style={selectWrapper}>
                    <CustomSelect
                      textVariant="h3"
                      options={getDaysInMonth(
                        data?.specificEndDate?.month || 'January'
                      )}
                      selectedValue={data.specificEndDate.day}
                      onSelect={(val) => {
                        setData((s) => ({
                          ...s,
                          specificEndDate: {
                            ...data.specificEndDate,
                            day: val,
                          },
                        }));
                      }}
                      style={{ transform: 'translate(5px, 5px)' }}
                      textStyle={{ fontWeight: 400, fontSize: 14 }}
                      otherModalTitle="Other Color"
                      otherModalPlaceHolder="Color"
                      placeholder="Select"
                    />
                  </div>
                  <div style={selectWrapper}>
                    <CustomSelect
                      textVariant="h3"
                      options={MONTHS_IN_YEAR}
                      selectedValue={data.specificEndDate.month}
                      onSelect={(val) => {
                        setData((s) => ({
                          ...s,
                          specificEndDate: {
                            ...data.specificEndDate,
                            month: val,
                          },
                        }));
                      }}
                      style={{ transform: 'translate(5px, 5px)' }}
                      textStyle={{ fontWeight: 400, fontSize: 14 }}
                      otherModalTitle="Other Color"
                      otherModalPlaceHolder="Color"
                      placeholder="Select"
                    />
                  </div>
                </div>

                <Typography variant="body">
                  {!isDayWithinTheMonth(
                    data.specificEndDate.day,
                    data.specificEndDate.month
                  ) && '*Please select a date within the month!'}
                </Typography>
              </>
            )}
            <FormControlLabel
              value="specificEndWeek"
              control={<StyledRadio />}
              label={(
                <Typography variant="bodySemiBold">
                  Specific Week (i.e., Same week every year)
                </Typography>
              )}
              checked={data.endDate === 'specificEndWeek'}
            />
            {data.endDate === 'specificEndWeek' && (
              <>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  alignItems: 'center',
                }}
                >
                  <div style={selectWrapper}>
                    <CustomSelect
                      selectedValue={
                        WEEK_IN_MONTH.find(
                          (i) => i.id === data.specificEndWeek.week
                        )?.name
                      } textVariant="h3"
                      options={WEEK_IN_MONTH.map((i) => i.name)}
                      onSelect={(val) => {
                        setData((s) => ({
                          ...s,
                          specificEndWeek: {
                            ...data.specificEndWeek,
                            week: WEEK_IN_MONTH.find((i) => i.name === val).id,
                          },
                        }));
                      }}
                      style={{ transform: 'translate(5px, 5px)' }}
                      textStyle={{ fontWeight: 400, fontSize: 14 }}
                      otherModalTitle="Other Color"
                      otherModalPlaceHolder="Color"
                      placeholder="Select"
                    />
                  </div>
                  <div style={selectWrapper}>
                    <CustomSelect
                      textVariant='h3'
                      options={ALL_WEEK}
                      selectedValue={data.specificEndWeek.day}
                      onSelect={(val) => {
                        setData((s) => ({
                          ...s,
                          specificEndWeek: {
                            ...data.specificEndWeek,
                            day: val,
                          },
                        }));
                      }}
                      style={{ transform: 'translate(5px, 5px)' }}
                      textStyle={{ fontWeight: 400, fontSize: 14 }}
                      otherModalTitle='Other Color'
                      otherModalPlaceHolder='Color'
                      placeholder='Select'
                    />
                  </div>
                  <div style={selectWrapper}>
                    <CustomSelect
                      selectedValue={data.specificEndWeek.month}
                      textVariant="h3"
                      options={MONTHS_IN_YEAR}
                      onSelect={(val) => {
                        setData((s) => ({
                          ...s,
                          specificEndWeek: {
                            ...data.specificEndWeek,
                            month: val,
                          },
                        }));
                      }}
                      style={{ transform: 'translate(5px, 5px)' }}
                      textStyle={{ fontWeight: 400, fontSize: 14 }}
                      otherModalTitle="Other Color"
                      otherModalPlaceHolder="Color"
                      placeholder="Select"
                    />
                  </div>
                  {/* <DatePicker
                      placeholder={data.specificEndWeek.month || 'Month'}
                      style={{
                        width: '200px',
                        height: '40px',
                        transform: 'translateY(-4px)',
                      }}
                      onChange={(_, month) =>
                        setData((s) => ({
                          ...s,
                          specificEndWeek: {
                            ...data.specificEndWeek,
                            month,
                          },
                        }))
                      }
                      picker='month'
                    /> */}
                </div>
                {data.specificEndWeek.day && data.specificEndWeek.week && data.specificEndWeek.month && <Typography variant="body">*The week collection ends each year is the one that contains the <b>{WEEK_IN_MONTH.find(
                  (i) => i.id === data.specificEndWeek.week
                )?.name}</b> <b>{data.specificEndWeek.day}</b> of <b>{data.specificEndWeek.month}</b>. </Typography>}
              </>
            )}

            <FormControlLabel
              value="itHasAlreadyEnded"
              onChange={() => {
                setData((s) => ({ ...s, canChangeEachYearEnd: true }));
              }}
              control={<StyledRadio checked={data.canChangeEachYearEnd} />}
              label={(
                <Typography variant="bodySemiBold">
                  Can change each year
                </Typography>
              )}
              checked={data.endDate === 'itHasAlreadyEnded'}
            />
            {data.canChangeEachYearEnd && (
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                marginLeft: '16px',
              }}
              >
                <FormControlLabel
                  value="itHasAlreadyEnded"
                  control={<StyledRadio />}
                  label={(
                    <Typography variant="bodySemiBold">
                      It has already ended
                    </Typography>
                  )}
                  checked={data.endDate === 'itHasAlreadyEnded'}
                />
                {data.endDate === 'itHasAlreadyEnded' && (
                  <>
                    <Typography variant="h3">
                      What was the end date?
                      {' '}
                    </Typography>
                    <CustomDatePicker
                      placeholder={data.itHasAlreadyEnded || 'Select Date'}
                      style={{ width: '200px', height: '40px' }}
                      setDate={(date) => setData((s) => ({ ...s, itHasAlreadyEnded: date }))}
                    />
                  </>
                )}

                <FormControlLabel
                  value="IKnowNextEndDate"
                  control={<StyledRadio />}
                  label={(
                    <Typography variant="bodySemiBold">
                      I know the next end date
                    </Typography>
                  )}
                  checked={data.endDate === 'IKnowNextEndDate'}
                />

                {data.endDate === 'IKnowNextEndDate' && (
                  <>
                    <Typography variant="h3">When will it end?</Typography>
                    <CustomDatePicker
                      placeholder={data.whenWillItEnd || 'Select Date'}
                      style={{ width: '200px', height: '40px' }}
                      setDate={(date) => setData((s) => ({ ...s, whenWillItEnd: date }))}
                    />
                  </>
                )}

                <FormControlLabel
                  value="IDoNotNowNextEndDate"
                  control={<StyledRadio />}
                  label={(
                    <Typography variant="bodySemiBold">
                      I don
                      {'\''}
                      t know next end date
                    </Typography>
                  )}
                  checked={data.endDate === 'IDoNotNowNextEndDate'}
                />

                {data.endDate === 'IDoNotNowNextEndDate' && (
                  <>
                    <Typography variant="h3">
                      Please provide an approximate date so we can remind you in
                      advance.
                    </Typography>
                    <CustomDatePicker
                      placeholder={data.approximateEndDate || 'Select Date'}
                      style={{ width: '200px', height: '40px' }}
                      setDate={(date) => setData((s) => ({ ...s, approximateEndDate: date }))}
                    />
                  </>
                )}
              </div>
            )}
            <FormControlLabel
              value="otherEndDate"
              control={<StyledRadio />}
              label={<Typography variant="bodySemiBold">Other</Typography>}
              checked={data.endDate === 'otherEndDate'}
            />
            {data.endDate === 'otherEndDate' && (
              <TextField
                label="Provide details"
                value={data.otherEndDate}
                onChange={(e) => {
                  setData((s) => ({ ...s, otherEndDate: e }));
                }}
                size="small"
              />
            )}
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};

export default StartAndEndDateSelector;

const selectWrapper = {
  border: '1px solid #d9d9d9',
  borderRadius: 5,
  height: 39,
  margin: '0 3px 7px 3px',
  padding: '0 10px',
};

const StyledRadio = styled(Radio)(() => ({
  '&.Mui-checked': {
    color: process.env.REACT_APP_PRIMARY_COLOR,
  },
  transform: 'scale(0.9)',
}));
