import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { TableLoading } from '../../components/loading';
import { Typography, IconButton } from '../../components';
import { SortIcon, EditIcon } from '../../../assets/icons';
import * as actions from '../redux/actions';
import * as categoryActions from '../../Category/redux/actions';

const useStyles = makeStyles(() => ({
  wrapper: {
    minWidth: '950px',
  },
  dataTable: {
    '& > div:last-child': {
      borderBottom: '0px !important',
    },
  },
  border: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  icon: {
    width: '1rem',
    height: '1rem',
  },
  deleteIcon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  editIcon: {
    width: '1.2rem',
    height: '1.2rem',
  },
  largeItem: {
    width: '20%',
  },
  normalItem: {
    width: '10%',
  },
}));

const List = ({
  intl,
  fetchState,
  data,
  page,
  rowsPerPage,
  setWasteTypeAction,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const isSendRequest = fetchState === 'send-request';
  const [sortedColumn, setSortedColumn] = useState('waste_type_admin_nm');
  const [isAscending, setIsAscending] = useState(true);

  const sort = (column) => {
    if (sortedColumn === column) {
      setIsAscending(!isAscending);
    } else {
      setSortedColumn(column);
      setIsAscending(true);
    }
  };

  const handleEditClick = (item) => {
    window.scrollTo(0, 0);
    history.push(`/category?id=${item.waste_type_id}`);
    setWasteTypeAction(item);
  };

  return (
    <>
      {isSendRequest ? (
        <div className="d-flex justify-content-center align-items-center">
          <TableLoading width="100%" />
        </div>
      ) : (
        <div className={`p-3 ${classes.wrapper}`}>
          <div className={`pt-3 pb-3 d-flex ${classes.border}`}>
            <div className={`d-flex align-items-center ${classes.largeItem}`}>
              <Typography variant="bodySemiBold">
                {intl.formatMessage({ id: 'WASTE_TYPE.TABLE.WASTE_TYPES' })}
              </Typography>
              <span className="d-flex flex-column">
                <IconButton onClick={() => sort('waste_type_admin_nm')}>
                  <SortIcon
                    className={classes.icon}
                    color={process.env.REACT_APP_COLOR_TEXT}
                  />
                </IconButton>
              </span>
            </div>

            <div className={`d-flex align-items-center ${classes.normalItem}`}>
              <Typography variant="bodySemiBold">
                {intl.formatMessage({ id: 'WASTE_TYPE.TABLE.CATEGORIES' })}
              </Typography>
              <span className="d-flex flex-column">
                <IconButton onClick={() => sort('category_counts')}>
                  <SortIcon
                    className={classes.icon}
                    color={process.env.REACT_APP_COLOR_TEXT}
                  />
                </IconButton>
              </span>
            </div>

            <div className={`d-flex align-items-center ${classes.normalItem}`}>
              <Typography variant="bodySemiBold">
                {intl.formatMessage({ id: 'WASTE_TYPE.TABLE.MATERIALS' })}
              </Typography>
              <span className="d-flex flex-column">
                <IconButton onClick={() => sort('material_counts')}>
                  <SortIcon
                    className={classes.icon}
                    color={process.env.REACT_APP_COLOR_TEXT}
                  />
                </IconButton>
              </span>
            </div>

            <div className={`d-flex align-items-center ${classes.largeItem}`}>
              <Typography variant="bodySemiBold">
                {intl.formatMessage({ id: 'WASTE_TYPE.TABLE.COLLECTION' })}
              </Typography>
              <span className="d-flex flex-column">
                <IconButton onClick={() => sort('coll_admin_nm')}>
                  <SortIcon
                    className={classes.icon}
                    color={process.env.REACT_APP_COLOR_TEXT}
                  />
                </IconButton>
              </span>
            </div>

            <div className={`d-flex align-items-center ${classes.normalItem}`}>
              <Typography variant="bodySemiBold">
                {intl.formatMessage({ id: 'WASTE_TYPE.TABLE.DEPOTS' })}
              </Typography>
              <span className="d-flex flex-column">
                <IconButton onClick={() => sort('depot_counts')}>
                  <SortIcon
                    className={classes.icon}
                    color={process.env.REACT_APP_COLOR_TEXT}
                  />
                </IconButton>
              </span>
            </div>

            <div className={`d-flex align-items-center ${classes.normalItem}`}>
              <Typography variant="bodySemiBold">
                {intl.formatMessage({ id: 'WASTE_TYPE.TABLE.EVENTS' })}
              </Typography>
              <span className="d-flex flex-column">
                <IconButton onClick={() => sort('event_counts')}>
                  <SortIcon
                    className={classes.icon}
                    color={process.env.REACT_APP_COLOR_TEXT}
                  />
                </IconButton>
              </span>
            </div>

            <div className={`d-flex align-items-center ${classes.largeItem}`} />
          </div>
          <div className={classes.dataTable}>
            {[...data]
              .sort((a, b) => {
                if (!isAscending) {
                  return a[sortedColumn] > b[sortedColumn] ? -1 : 1;
                }
                return a[sortedColumn] > b[sortedColumn] ? 1 : -1;
              })
              .slice((page - 1) * rowsPerPage, page * rowsPerPage)
              .map((item) => (
                <div
                  key={item.waste_type_id}
                  className={`d-flex align-items-center py-4 ${classes.border}`}
                >
                  <Typography className={classes.largeItem} variant="body">
                    {item.waste_type_admin_nm}
                  </Typography>
                  <Typography className={classes.normalItem} variant="body">
                    {item.category_counts}
                  </Typography>
                  <Typography className={classes.normalItem} variant="body">
                    {item.material_counts}
                  </Typography>
                  <Typography className={classes.largeItem} variant="body">
                    {item.coll_admin_nm
                      || intl.formatMessage({ id: 'WASTE_TYPE.NO_COLLECTION' })}
                  </Typography>
                  <Typography className={classes.normalItem} variant="body">
                    {item.depot_counts}
                  </Typography>
                  <Typography className={classes.normalItem} variant="body">
                    {item.event_counts}
                  </Typography>

                  <div
                    className={`d-flex pr-4 justify-content-center align-items-center ${classes.largeItem}`}
                  >
                    <>
                      <Typography
                        color={process.env.REACT_APP_COLOR_PRIMARY}
                        className="d-flex"
                        variant="h2"
                      >
                        <div>

                          <IconButton onClick={() => handleEditClick(item)}>
                            <EditIcon
                              className={`${classes.editIcon} mr-2`}
                              color={process.env.REACT_APP_COLOR_PRIMARY}
                            />
                            <Typography
                              color={process.env.REACT_APP_COLOR_PRIMARY}
                              variant="bodySemiBold"
                            >
                              {intl.formatMessage({ id: 'WASTE_TYPE.EDIT' })}
                            </Typography>
                          </IconButton>

                        </div>
                      </Typography>

                    </>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.wasteType.fetchState,
  locality: store.common.locality,
});

export default injectIntl(
  connect(mapStateToProps, { ...actions, ...categoryActions })(List)
);
