import actionTypes from './actionTypes';

// Collections actions for setting loading status
export const setLoadingAction = (status) => ({
  type: actionTypes.SET_COLLECTION_LOADING_ACTION,
  payload: status
});

export const setSnackbarAction = (status) => ({
  type: actionTypes.SET_COLLECTION_SNACKBAR_ACTION,
  payload: status
});

// Collection actions for setting collection wizard status
export const setCollectionWizardStatusAction = (status) => ({
  type: actionTypes.SET_COLLECTION_WIZARD_STATUS_ACTION,
  payload: { status }
});

// Collections actions for setting collection wizard id
export const setCollectionWizardIdAction = (id) => ({
  type: actionTypes.SET_COLLECTION_WIZARD_ID_ACTION,
  payload: { id }
});

// Collections actions for fetching all collections
export const getCollectionsListRequest = ({ locality }) => ({
  type: actionTypes.GET_COLLECTIONS_LIST_REQUEST,
  payload: { locality }
});

export const getCollectionsListReceive = (data) => ({
  type: actionTypes.GET_COLLECTIONS_LIST_RECEIVE,
  payload: data
});

export const getCollectionsListFail = (error) => ({
  type: actionTypes.GET_COLLECTIONS_LIST_FAIL,
  payload: { ...error }
});

// Collections actions for creating a new collection
export const createCollectionRequest = (data) => ({
  type: actionTypes.CREATE_COLLECTION_REQUEST,
  payload: { data }
});

export const createCollectionReceive = (data) => ({
  type: actionTypes.CREATE_COLLECTION_RECEIVE,
  payload: data
});

export const createCollectionFail = (error) => ({
  type: actionTypes.CREATE_COLLECTION_FAIL,
  payload: { ...error }
});

// Collections actions for editing a collection
export const editCollectionRequest = (data) => ({
  type: actionTypes.EDIT_COLLECTION_REQUEST,
  payload: { data }
});

export const editCollectionReceive = (data) => ({
  type: actionTypes.EDIT_COLLECTION_RECEIVE,
  payload: data
});

export const editCollectionFail = (error) => ({
  type: actionTypes.EDIT_COLLECTION_FAIL,
  payload: { ...error }
});
