/* eslint-disable max-len */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

import { CmsLoading } from '../../components/loading';
import Collection from './Collection';
import SetupWizard from './SetupWizard';
import { CustomButton, Typography } from '../../components';
import { CalendarIcon, InfoIcon } from '../../../assets/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    transform: 'rotateX(180deg)',
    overflowX: 'auto',
  },
  wrapper: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'visible',
    padding: 0,
    transform: 'rotateX(180deg)',
    [theme.breakpoints.down(800)]: {
      flexDirection: 'column',
      justifyContent: 'center'
    },
  },
  item: {
    minWidth: '30%',
    maxWidth: '30%',
    [theme.breakpoints.down(1350)]: {
      minWidth: '45%',
      maxWidth: '45%',
    },
    [theme.breakpoints.down(800)]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
  buttonWrapper: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    width: '60px',
    height: '60px !important',
    borderRadius: '100px !important',
  },
  emptyList: {
    width: '100%',
    minHeight: 120,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    borderRadius: 10,
    backgroundColor: process.env.REACT_APP_COLOR_TERTIARY_DARK
  },
  calendarIcon: {
    width: 80,
    height: 80,
    marginLeft: 50,
    marginRight: 50,
  },
  infoIcon: {
    width: 30,
    height: 30,
    marginRight: 15,
  },
}));

const Collections = ({
  intl,
  fetchState,
  collectionsList,
  wizardStatus,
}) => {
  const classes = useStyles();
  const defaultDisplayedCount = 10;
  const [displayedCount, setDisplayedCount] = useState(defaultDisplayedCount);
  const isSendRequest = fetchState === 'send-request';

  return (
    <>
      {
        isSendRequest
          ? <CmsLoading /> : (
            <div className={classes.container}>
              <div className={classes.wrapper}>
                {
                  collectionsList.length ? (
                    <>
                      <div className={classes.item}>
                        <Collection data={collectionsList[0]} />
                      </div>
                      {
                        wizardStatus
                          ? <SetupWizard />
                          : collectionsList
                            .slice(1, collectionsList.length > displayedCount ? displayedCount : collectionsList.length)
                            .map((item) => (
                              <div key={item.coll_id} className={classes.item}>
                                <Collection data={item} />
                              </div>
                            ))
                      }
                      {
                        !wizardStatus
                        && collectionsList.length > displayedCount
                        && (
                          <div className={classes.buttonWrapper}>
                            <CustomButton
                              className={classes.button}
                              label={intl.formatMessage({ id: 'GENERAL.MORE' })}
                              variant="outlined"
                              color="primary"
                              size="large"
                              onClick={() => setDisplayedCount(displayedCount + defaultDisplayedCount)}
                            />
                          </div>
                        )
                      }
                    </>
                  ) : (
                    <div className="w-100">
                      <div className={classes.emptyList}>
                        <CalendarIcon className={classes.calendarIcon} />
                        <Typography
                          variant="h1"
                          className="d-flex align-items-center"
                          color={process.env.REACT_APP_COLOR_SECONDARY}
                        >
                          <InfoIcon className={classes.infoIcon} color={process.env.REACT_APP_COLOR_SECONDARY} />
                          {intl.formatMessage({ id: 'COLLECTION.EMPTY_LIST.TEXT' })}
                        </Typography>
                      </div>
                      { wizardStatus ? <SetupWizard /> : null }
                    </div>
                  )
                }
              </div>
            </div>
          )
      }
    </>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.collection.fetchState,
  collectionsList: store.collection.collectionsList,
  wizardStatus: store.collection.wizardStatus,
});

export default injectIntl(connect(mapStateToProps, null)(Collections));
