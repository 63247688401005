import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingSpinner = ({
  height, width
}) => (
  <div className="d-flex justify-content-center align-items-center" style={{ height, width }}>
    <CircularProgress />
  </div>
);

export default LoadingSpinner;
