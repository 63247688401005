import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { Typography, CustomButton, } from '../../../components';
import { toAbsoluteUrl } from '../../../../../_metronic';


import { LoadingSpinner, ErrorContainer } from '../../../components/loading';
import { PreviewLessonModal, PreviewQuizModal } from ".";

import { ProUser } from '../../EducationalContent/components';
import * as actions from '../redux/actions';



const useStyles = makeStyles({
  container: {
    padding: '20px 20px',
    marginLeft: '8px'
  },
  hr: {
    borderBottom: '2px solid #E7ECEF',
    marginTop: 15,
    marginBottom: 20,
  },
  contentContainer: {
    marginTop: 20,
  },
  button: {
    justifyContent: 'left',
    margin: '5px 0px',
    fontSize: 12,
  },
  quizButton: {
    width: '50%',
    marginLeft: '50%',
    marginTop: 5,
  },
  misunderstoodMaterialsItem: {
    cursor: 'default',
    pointerEvents: 'none',
  }
});

const RecyclingFundamentals = ({
  disabled = false, isUserPro, stage, loading, error, district, recyclingFundamentals, getRecyclingFundamentalsRequestAction
}) => {

  useEffect(() => {
    if (district.district_id !== '#all-districts#' && isUserPro && stage >= 4) {
      const [projectId, districtId] = district.district_id.split('#$#');
      getRecyclingFundamentalsRequestAction(projectId, districtId);
    } else {
      getRecyclingFundamentalsRequestAction('556', '556');
    }
  }, [district]);

  useEffect(() => {
    let totalRFQuizes = [];
    recyclingFundamentals.forEach((recyclingFundamentals) => {
      totalRFQuizes = [...totalRFQuizes, ...recyclingFundamentals.rf_quiz];
    });
    setRfQuiz(totalRFQuizes);
  }, [recyclingFundamentals]);


  const classes = useStyles({ disabled });
  const [isPreviewingQuiz, setIsPreviewingQuiz] = useState(false);
  const [rfLessonVideo, setRfLessonVideo] = useState(false);
  const [rfLessonTitle, setRfLessonTitle] = useState(false);
  const [rfLessonDescription, setRfLessonDescription] = useState(false);
  const [currentRFLessonIndex, setCurrentRFLessonIndex] = useState(0);
  const [currentRFQuizIndex, setCurrentRFQuizIndex] = useState(0);
  const [isPreviewingFeaturedLesson, setIsPreviewingFeaturedLesson] = useState(false);
  const [rfQuiz, setRfQuiz] = useState(null);
  const numberOfQuizes = 10;


  const RFLessonHandler = (rfLesson, index) => {
    setCurrentRFLessonIndex(index);
    setIsPreviewingFeaturedLesson(true);
    const videoLinkSplit = rfLesson.video_link.split("/");
    const videoURL = `https://player.vimeo.com/video/${videoLinkSplit[videoLinkSplit.length - 2]}?h=${videoLinkSplit[videoLinkSplit.length - 1]}`
    setRfLessonVideo(videoURL);
    setRfLessonTitle(rfLesson.title);
    setRfLessonDescription(rfLesson.description);
  };


  const nextHandler = () => {
    RFLessonHandler(recyclingFundamentals[currentRFLessonIndex + 1].rf_lessons[0], currentRFLessonIndex + 1)
  }

  const previewHandler = () => {
    RFLessonHandler(recyclingFundamentals[currentRFLessonIndex - 1].rf_lessons[0], currentRFLessonIndex - 1)
  }

  const RFQuizHandler = (rfLesson, index = 0) => {
    setRfLessonTitle(recyclingFundamentals[Math.floor(index / 2)].rf_lessons[0].title);
    setIsPreviewingQuiz(true);
    setCurrentRFQuizIndex(index);
  };

  const nextQuizHandler = () => {
    RFQuizHandler(null, currentRFQuizIndex + 1);
  }

  const previousQuizHandler = () => {
    RFQuizHandler(null, currentRFQuizIndex - 1);
  }

  return (
    <>
       <>
          <div style={{ display: 'flex' }}>
            <img
              alt="Badge Icon"
              src={toAbsoluteUrl('/media/icons/badge.png')}
              className={`${classes.packImage}`}
            /><div>Be a more confident recycler by learning about contamination and wish-cycling. Earn a badge!</div>
          </div>
          <div className={classes.contentContainer}>
            <Typography color="#6C7293" variant="h2">
              Lessons
            </Typography>

            {loading ? <LoadingSpinner height={430} /> : error || recyclingFundamentals.length === 0 ? (
              <ErrorContainer
                small
                reportName="Recycling Fundamentals"
                height={430}
                error={error}
              />
            ) : (
              <>
                {
                  recyclingFundamentals.map((rf, index) => {
                    return (
                      <CustomButton
                        paddingVertical={4}
                        className={classes.button}
                        variant={'outlined'}
                        fontSize={12}
                        label={rf.rf_lessons[0].title}
                        onClick={() => { RFLessonHandler(rf.rf_lessons[0], index) }}
                      />
                    )

                  })
                }
              </>
            )
            }
          </div>
          <CustomButton
            paddingVertical={4}
            className={classes.quizButton}
            variant={'contained'}
            label={'Quiz Questions'}
            disabled={recyclingFundamentals.length === 0 || error || loading}
            onClick={RFQuizHandler}
          />
        </>
      <>
        <PreviewLessonModal
          open={isPreviewingFeaturedLesson}
          onClose={() => setIsPreviewingFeaturedLesson()}
          rfLessonVideo={rfLessonVideo}
          rfLessonTitle={rfLessonTitle}
          rfLessonDescription={rfLessonDescription}
          nextHandler={nextHandler}
          previewHandler={previewHandler}
          numberOfLessons={recyclingFundamentals.length}
          currentRFLessonIndex={currentRFLessonIndex}
        />


        <PreviewQuizModal
          open={isPreviewingQuiz}
          onClose={() => setIsPreviewingQuiz()}
          rfLessonTitle={rfLessonTitle}
          rfQuiz={rfQuiz}
          nextHandler={nextQuizHandler}
          previewHandler={previousQuizHandler}
          currentRFQuizIndex={currentRFQuizIndex}
          numberOfQuizes={numberOfQuizes}
        />
      </>
    </>

  )
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  loading: store.recyclingFundamentals.loading,
  error: store.recyclingFundamentals.error,
  recyclingFundamentals: store.recyclingFundamentals.data,
});


export default injectIntl(connect(mapStateToProps, actions)(RecyclingFundamentals));

