import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';


const useStyles = makeStyles(() => ({
  dialogOverride: {
    overflow: 'hidden',
    maxWidth: 'unset'
  },
}));
const PreviewPDFAddOnModal = ({
  intl, open, onClose, title, disabled = false, previewID
}) => {
  const classes = useStyles({ disabled });
  return (
    <>
      {open && (
        <Dialog classes={{
          paperScrollPaper: classes.dialogOverride,
        }}
          open={open} onClose={onClose}>
          <iframe src={`${previewID}`} title={title} width="700" height="1000" />
        </Dialog>
      )}
    </>
  );
};

export default injectIntl(PreviewPDFAddOnModal);
