import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { addCommasToNumber } from '../../../../utils';
import { Typography, AutoComplete } from '../../../components';

const useStyles = makeStyles((theme) => ({
  standaredView: {
    display: 'flex',
    // [theme.breakpoints.down(992)]: {
    //   display: 'none !important'
    // }
  },
  tableSmallCell: {
    width: '10%',
    marginRight: '2.5%',
    marginLeft: '2.5%',
  },
  tableMediumCell: {
    width: '25%',
    marginLeft: '2.5%',
  },
  tableLargeCell: {
    width: '40%',
    marginLeft: '2.5%'
  },
  tableHeaderDatas: {
    height: '45px',
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    borderTop: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  tableDatas: {
    height: '45px',
  },
  tableBorder: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  tableDataPassPercentage: {
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_BG
  },
  responsiveMode: {
    display: 'none !important',
    [theme.breakpoints.down(992)]: {
      display: 'flex !important'
    }
  },
  responsiveTableBorder: {
    [theme.breakpoints.down(992)]: {
      borderTop: '0px !important',
    }
  }
}));

const BadgeTable = ({
  intl,
  data,
}) => {
  const initialChoiceData = [
    {
      name: intl.formatMessage({ id: 'REPORT.BADGE.TABLE.UNIQUE_USER' }),
      value: 'unique'
    },
    {
      name: intl.formatMessage({ id: 'REPORT.BADGE.TABLE.PASS_USER' }),
      value: 'pass'
    },
    {
      name: intl.formatMessage({ id: 'REPORT.BADGE.TABLE.PASS_PERCENTAGE' }),
      value: 'pass_percentage'
    },
    {
      name: intl.formatMessage({ id: 'REPORT.BADGE.TABLE.HOUSE_HOLD' }),
      value: 'per_house'
    }
  ];
  const classes = useStyles();
  const [choice, setChoice] = useState(initialChoiceData[0]);

  return (
    <>
      <div className={`row d-flex flex-column mr-3 ml-3 ${classes.standaredView}`}>

        <div className={`w-100 row d-flex flex-row align-items-center ${classes.tableDatas}`}>
          <div className="col-3" />
          <div className="col-9 d-flex">
            <Typography
              className={`d-flex justify-content-center ${classes.tableMediumCell}`}
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.BADGE.TABLE.UNIQUE_USER' })}
            </Typography>

            <Typography
              className={`d-flex justify-content-center ${classes.tableMediumCell}`}
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.BADGE.TABLE.PASS_USER' })}
            </Typography>

            <Typography
              className={`d-flex justify-content-center ${classes.tableSmallCell}`}
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.BADGE.TABLE.PASS_PERCENTAGE' })}
            </Typography>

            <Typography
              className={`d-flex justify-content-center ${classes.tableLargeCell}`}
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.BADGE.TABLE.HOUSE_HOLD' })}
            </Typography>
          </div>
        </div>

        { data && Object.values(data).map((item, index) => (
          <div key={`Badge_${item.title} - ${String(index)}`} className={`p-0 ${index === 0 ? 'mt-1' : 'mt-3'}`}>
            <div
              className={`row p-0 w-100 d-flex align-items-center ${classes.tableHeaderDatas}`}
            >
              <Typography className="col-3 " variant="bodySemiBold">
                {item.title || ''}
              </Typography>
              <div className="col-9 h-100 d-inline-flex">
                <div className={classes.tableMediumCell} />
                <div className={classes.tableMediumCell} />
                <div className={`${classes.tableSmallCell}`} />
                <div className={`${classes.tableLargeCell} `} />
              </div>
            </div>

            <div
              className={`row w-100 d-flex align-items-center p-0 mt-2 ${classes.tableDatas}`}
            >
              <Typography className="col-3" variant="subText">
                { intl.formatMessage({ id: 'REPORT.BADGE.TABLE.SUBTITLE.YOUR_MUNICIPALITY' })}
              </Typography>

              <div className="col-9 p-0 h-100 d-flex">
                <Typography
                  className={`d-flex justify-content-center align-items-center
                    ${classes.tableMediumCell}`}
                  variant="bodySemiBold"
                >
                  {addCommasToNumber(item.sum) || ''}
                </Typography>

                <Typography
                  className={`d-flex justify-content-center align-items-center
                    ${classes.tableMediumCell}`}
                  variant="bodySemiBold"
                >
                  {addCommasToNumber(item.value) || ''}
                </Typography>

                <Typography
                  className={`d-flex justify-content-center align-items-center
                    ${classes.tableSmallCell} ${classes.tableDataPassPercentage}`}
                  variant="bodySemiBold"
                >
                  {`${addCommasToNumber(item.pass_percent)} %` || ''}
                </Typography>

                <Typography
                  className={`d-flex p-0 justify-content-center align-items-center
                    ${classes.tableLargeCell} ${classes.tableDataPassPercentage}`}
                  variant="bodySemiBold"
                >
                  {addCommasToNumber(item.house_hold) || ''}
                </Typography>

              </div>


            </div>

            <div
              className={`row w-100 d-flex align-items-center p-0 ${classes.tableDatas}`}
            >
              <Typography className="col-3" variant="subText">
                { intl.formatMessage({ id: 'REPORT.BADGE.TABLE.SUBTITLE.OTHER_MUNICIPALITY' })}
              </Typography>

              <div className="col-9 p-0 h-100 d-flex">
                <Typography
                  className={`d-flex justify-content-center align-items-center
                    ${classes.tableMediumCell}`}
                  variant="bodySemiBold"
                />

                <Typography
                  className={`d-flex justify-content-center align-items-center
                    ${classes.tableMediumCell}`}
                  variant="bodySemiBold"
                />

                <Typography
                  className={`d-flex justify-content-center align-items-center
                    ${classes.tableSmallCell} ${classes.tableDataPassPercentage}`}
                  variant="bodySemiBold"
                >
                  {`${addCommasToNumber(Number(item.other_pass).toFixed(2))} %` || ''}
                </Typography>

                <Typography
                  className={`d-flex p-0 justify-content-center align-items-center
                    ${classes.tableLargeCell} ${classes.tableDataPassPercentage}`}
                  variant="bodySemiBold"
                >
                  {addCommasToNumber(Number(item.other_house_hold).toFixed(2)) || ''}
                </Typography>

              </div>
            </div>
          </div>
        ))}

      </div>
      {/* <div className={`row d-flex flex-column m-3 mb-0 ${classes.responsiveMode}`}>
        <AutoComplete
          className="mt-2 mb-3"
          options={initialChoiceData}
          value={choice}
          onChange={setChoice}
          optionTitle="name"
          optionId="value"
        />
        { data && data.map((item, id) => (
          <div key={`Badge_${item.header} - ${String(id)}`}>
            <div
              className={`row w-100 d-flex align-items-center ${classes.tableHeaderDatas} ${id === 0 ? classes.responsiveTableBorder : null}`}
            >
              <Typography className="col-12 d-flex h-100 align-items-center" variant="bodySemiBold">
                {item.header || ''}
              </Typography>
              <div className="col-9 h-100 d-inline-flex" />
            </div>

            { item.detail && item.detail.map((member, index) => (
              <div
                key={`Badge_sub_${member.title} - ${String(index)}`}
                className={`row w-100 d-flex align-items-center ${classes.tableDatas}
                ${index !== item.detail.length - 1 ? classes.tableBorder : null}`}
              >
                <Typography className="col-6" variant="subText">
                  {member.title || ''}
                </Typography>

                <div className="col-6 h-100 d-flex justify-content-center align-items-center">
                  <Typography
                    variant="bodySemiBold"
                  >
                    {(choice.value === 'pass_percentage'
                      ? `${member[choice.value]} %`
                      : addCommasToNumber(member[choice.value])) || ''}
                  </Typography>
                </div>

              </div>
            ))}
          </div>
        ))}
      </div> */}
    </>
  );
};

export default injectIntl(BadgeTable);
