import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';
import createReducer from '../../../../../redux/createReducer';
import initialStore from './initialStore';

const RecyclingForKidsReducer = persistReducer({
  storage,
  key: 'recyclingForKids',
  blackList: ['currentQuestionIndex'],
  whitelist: []
}, createReducer(initialStore, {

  SET_CURRENT_RFK_QUESTION_INDEX: (state, { index }) => Immutable({
    ...state,
    currentRFKQuestionIndex: index
  }),

  FEEDBACK_REQUEST: (state) => Immutable({
    ...state,
    error: null,
  }),
  FEEDBACK_RECEIVE: (state, { payload }) => Immutable({
    ...state,
    loading: false,
  }),
  FEEDBACK_FAIL: (state, { payload }) => Immutable({
    ...state,
    error: payload,
  }),
}));

export default RecyclingForKidsReducer;
