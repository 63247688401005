import actionTypes from './actionTypes';

// Discovery zone report actions for setting loading status
export const setResidentKnowledgeIndicatorLoadingAction = (status) => ({
  type: actionTypes.SET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_LOADING_ACTION,
  payload: status
});

// Discovery zone report actions for setting all time mode
export const setResidentKnowledgeIndicatorReportAllTimeAction = (toDate) => ({
  type: actionTypes.SET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_ALL_TIME_ACTION,
  payload: toDate
});

// Discovery zone report actions for fetching report data
export const ResidentKnowledgeIndicatorReportRequest = ({
  municipality, from, to, county
}) => ({
  type: actionTypes.GET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_REQUEST,
  payload: {
    municipality, from, to, county
  }
});

export const ResidentKnowledgeIndicatorReportReceive = (data) => ({
  type: actionTypes.GET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_RECEIVE,
  payload: { data }
});

export const ResidentKnowledgeIndicatorReportFail = (error) => ({
  type: actionTypes.GET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_FAIL,
  payload: { ...error }
});
