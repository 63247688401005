import actionTypes from './actionTypes';

export const getDashboardDataRequestAction = (fromDate, toDate) => ({
  type: actionTypes.GET_DASHBOARD_DATA_REQUEST,
  payload: { fromDate, toDate }
});

export const getDashboardDataReceiveAction = (data) => ({
  type: actionTypes.GET_DASHBOARD_DATA_RECEIVE,
  payload: data
});

export const getDashboardDataFailAction = (error) => ({
  type: actionTypes.GET_DASHBOARD_DATA_FAIL,
  payload: error
});
