import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { makeStyles, styled } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Portlet } from '../../../../_metronic/partials/content/Portlet';
import { Typography, CustomButton } from '../../components';
import { CrownIcon } from '../../../assets/icons';
import { toAbsoluteUrl } from '../../../../_metronic';
// eslint-disable-next-line import/no-unresolved
import { RecyclingFundamentals, MisunderstoodMaterials } from './components';

import { PreviewLessonModal as SurveyPrevieModal } from '../EducationalContent/components';


import * as actions from './redux/actions';


const useStyles = makeStyles({
  container: {
    padding: '20px 20px',
    marginLeft: '8px'
  },
  hr: {
    borderBottom: '2px solid #E7ECEF',
    marginTop: 15,
    marginBottom: 20,
  },
  contentContainer: {
    marginTop: 20,
  },
  button: {
    justifyContent: 'left',
    margin: '5px 0px',
    fontSize: 12,
  },
  quizButton: {
    width: '50%',
    marginLeft: '50%',
    marginTop: 5,
  },
  misunderstoodMaterialsItem: {
    cursor: 'default',
    pointerEvents: 'none',
  }
});




const SurveyAndMore = ({
  intl,
  district,
  stage,
  isUserPro,
}) => {
  const classes = useStyles();
  const [selectedTabRoute, setSelectedTabRoute] = useState(1);

  const [isPreviewingOnboardingSurvey, setIsPreviewingOnboardingSurvey] = useState(false);
  /**
 * Create education section tabs
 * @param {string} name must be in the same name with the name on {...} en.json file.
 * @param {boolean} hasCrown crown icon which is displayed on tab button
 * @param {string} to routing path.
 */

  const createTabs = [
    {
      id: 1,
      name: 'EDUCATION.SURVEYS.AND.MORE.RECYCLING.FUNDAMENTALS',
      // hasCrown: true,
      text: 'Add copy here'
    },
    {
      id: 2,
      name: 'EDUCATION.SURVEYS.AND.MORE.ONBOARDING.SURVEY',
      hasCrown: false,
      text: `The onboarding screens of the Recycle Coach App present users with a survey that helps evaluate their initial recycling knowledge and comfort level. You can view the survey results under the <a href="${window.location.origin}/report/resident-knowledge-indicator?district=${district.district_id}" target="_blank">Knowledge Measurement</a> report. `
    },
    {
      id: 3,
      name: 'EDUCATION.SURVEYS.AND.MORE.MISUNDERSTOOD.MATERIALS',
      // hasCrown: true,
      text: 'Add copy here'
    },
    {
      id: 4,
      name: 'EDUCATION.SURVEYS.AND.ANNUAL.SURVEY',
      hasCrown: false,
      text: 'This national survey takes place in early October and the results are released in time for November 15th. It allows clients to measure changes in resident knowledge and see how their residents compare to national and regional averages.'
    },
    {
      id: 5,
      name: 'EDUCATION.SURVEYS.AND.MORE.PRINT.SOLUTIONS',
      hasCrown: false,
      text: 'Our Print Solutions division can work with your program to create custom calendars, oops labels, fridge magnets and much more. Contact Lorie Wilson at 1-800-343-3363 ext. 111 or by email at <a href = "mailto: print-solutions@recyclecoach.com">print-solutions@recyclecoach.com</a> to get a free quote.'

    }
  ];

  return (
    <Portlet className={`col-4 ${classes.container}`}>
      <Typography variant="h1">
        {intl.formatMessage({ id: 'EDUCATION.SURVEY.AND.MORE' })}
      </Typography>
      <div className="d-flex flex-wrap">
        {/**
         * create tabs
        */}
        {createTabs.map((tab) => {
          /**
           * dipsplay tooltip if it is a pro package future.
           */
          if (tab.hasCrown) {
            return (
              <Tooltip
                key={tab.id}
                classes={{
                  tooltip: classes.tooltip,
                  arrow: classes.arrow,
                }}
                title={tab.hasCrown ? (
                  <Typography variant="educationBody">
                    {intl.formatMessage({ id: 'EDUCATION.PRO.PACKAGE.FUTURE' })}
                  </Typography>
                ) : null}
                placement="top"
                arrow
                leaveDelay={100}
              >
                <StyledTabButton
                  selected={selectedTabRoute === tab.id}
                  onClick={() => {
                    setSelectedTabRoute(tab.id)
                  }}>
                  {intl.formatMessage({ id: tab.name })}
                  {tab.hasCrown && <CrownIcon />}
                </StyledTabButton>
              </Tooltip>
            );
          }
          return (
            <StyledTabButton
              selected={selectedTabRoute === tab.id}
              onClick={() => {
                setSelectedTabRoute(tab.id)
              }}
            >
              {intl.formatMessage({ id: tab.name })}
            </StyledTabButton>
          );
        })}
      </div>
      <div className={classes.hr} />
      <div>
        {createTabs.map((tab) => {
          if (selectedTabRoute === tab.id )
            return <Typography variant="h1"> {intl.formatMessage({ id: tab.name })} </Typography>
        })}
      </div>
      <div>
        {createTabs.map((tab) => {
          if (selectedTabRoute === tab.id) if (tab.id === 1) {
            return (
              < RecyclingFundamentals district={district} isUserPro={isUserPro} stage={stage} />
            )
          } else if (tab.id === 2) {
            return (
              <>
                <div style={{ display: 'flex' }}>
                  {/* <div>{tab.text}</div> */}
                  <div dangerouslySetInnerHTML={{ __html: tab.text }} />
                </div>
                <div className={classes.contentContainer}>
                  <>
                    <img
                      alt="Onboarding Survey"
                      src={toAbsoluteUrl('/media/icons/Education/Exams-rafiki1.png')}
                    />
                  </>
                </div>
                <CustomButton
                  paddingVertical={4}
                  className={classes.quizButton}
                  variant={'contained'}
                  label={'View Survey'}
                  onClick={() => {
                    setIsPreviewingOnboardingSurvey(true);
                  }} />
              </>
            )
          } else if (tab.id === 3) {
            return (
              <MisunderstoodMaterials district={district} isUserPro={isUserPro} stage={stage} />
            )
          } else if (tab.id === 4) {
            return (
              <>
                <div style={{ fontSize: 14 }} dangerouslySetInnerHTML={{ __html: tab.text }} />
                <a href="/report/annual-survey" target="_blank">
                  <CustomButton
                    paddingVertical={4}
                    style={{ marginTop: 10 }}
                    variant={'contained'}
                    label={'Check your most recent results'} />
                </a>
              </>
            )
          } else {
            return <div style={{ fontSize: 14 }} dangerouslySetInnerHTML={{ __html: tab.text }} />;
          }

        })}
      </div>

      <SurveyPrevieModal
        open={isPreviewingOnboardingSurvey}
        onClose={() => setIsPreviewingOnboardingSurvey()}
        // content={<textarea onChange={setFeedbackTextHandler} className={classes.textarea} />}
        buttonApply={intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}
        // disabled={!feedbackText.length}
        previewID={1833}
      />
    </Portlet>
  );
};


const mapStateToProps = (store) => ({
  district: store.common.district,
  stage: store.common.district.stage,
  isUserPro: store.common.isUserPro,


});

export default injectIntl(connect(mapStateToProps, actions)(SurveyAndMore));


const StyledTabButton = styled(({ color, ...props }) => <div {...props} />)({
  color: ({ selected }) => (selected ? '#fff' : '#2A95FF'),
  background: ({ selected }) => (selected ? '#2A95FF' : '#F1F9FF'),
  textShadow: ({ selected }) => (selected ? '-0.08ex 0 currentColor, 0.08ex 0 currentColor' : 'none'),
  borderRadius: 16,
  padding: '6px 14px',
  cursor: 'pointer',
  letterSpacing: '0.335px',
  transition: 'all .3s',
  fontSize: 12,
  margin: '5px 3px',

  /**
  * Target crown icon. It is child element of StyledTabButton
  */

  '& svg': {
    fill: ({ selected }) => (selected ? '#fff' : '#2A95FF'),
    marginLeft: 4,
    marginBottom: 2,
    transition: 'all .3s',
  },

  '&:hover': {
    background: '#2A95FF',
    color: '#fff',
    textShadow: '-0.08ex 0 currentColor, 0.08ex 0 currentColor',

    '& svg': {
      fill: '#fff'
    }
  },
});

