import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  useLocation, useHistory, Link
} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { isValidDate, checkValidDistrict, dataChangeTracking } from '../../../../utils';

import ReportPDF from './ReportPDF';
import {
  AutoComplete,
  // CheckBox,
  Typography,
  IconButton,
} from '../../../components';
import {
  PdfIcon,
  BackIcon,
  // LineThroughDotIcon,
  DisplayColorIcon,
  InfoIcon
} from '../../../../assets/icons';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../../../../_metronic/partials/content/Portlet';
import {
  // SpiderChart,
  SummaryHeader,
  RCSpinner,
  SummaryCurrentPeriod,
  SummaryPreviousPeriod,
  SummaryUserRating,
  SummaryPeerRating,
} from '../../components';
import * as actions from '../redux/actions';
import * as sharedActions from '../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '12px',
    fontWeight: 400,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_PRIMARY,
  },
  border: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
  },
  description: {
    textAlign: 'right',
  },
  spiderChart: {
    width: '50%',
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      width: '75%'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none !important'
    },
  },
  smallerThanMdHide: {
    [theme.breakpoints.down('md')]: {
      display: 'none !important'
    },
    [theme.breakpoints.up('md')]: {
      display: 'inline-block !important'
    },
  },
  smallerThanMdShow: {
    [theme.breakpoints.up('md')]: {
      display: 'none !important'
    },
  },
  onlyMd: {
    [theme.breakpoints.only('md')]: {
      display: 'flex !important',
    },
  },
  currentPeriod: {
    color: process.env.REACT_APP_COLOR_REPORT_1
  },
  previousPeriod: {
    color: process.env.REACT_APP_COLOR_PRIMARY_LIGHT
  },
  lastYear: {
    color: process.env.REACT_APP_COLOR_DELETE
  },
  noData: {
    minHeight: '300px'
  },
  noDataColor: {
    color: process.env.REACT_APP_COLOR_GRAY_DARK
  },
  pdfDisable: {
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_GRAY_DARK,
  }
}));

const Summary = ({
  intl,
  fetchState,
  materialReportRequest,
  data,
  detailData,
  district,
  setDistrictAction,
  fromDate,
  toDate,
  setDateRangeAction,
  municipalities,
  county,
  counties,
  setCountyAction,
  isCounty,
  setIsCountyAction,
  hasCounty,
  advocateAdmin
}) => {
  const classes = useStyles();
  // const [displayCurrentPeriod, setDisplayCurrentPeriod] = useState(true);
  // const [displayPreviousPeriod, setDisplayPreviousPeriod] = useState(true);
  // const [displayLastYear, setDisplayLastYear] = useState(true);
  const [type, setType] = useState();
  const [wasteType, setWasteType] = useState();
  const isSendRequest = fetchState === 'send-request';
  const [pdfSpinner, setPdfSpinner] = useState(false);

  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (history.location.search) {
      if (queryParams.get('district') && checkValidDistrict(
        queryParams.get('district').replace('-', '#$#'),
        municipalities,
        'district_id'
      )) {
        setDistrictAction({
          district_id: queryParams.get('district').replace('-', '#$#'),
          district_nm: municipalities.filter((item) => item.district_id === queryParams.get('district').replace('-', '#$#'))[0].district_nm,
        });
        setIsCountyAction(false);
      } else if (municipalities.length === 1) {
        setDistrictAction(municipalities[0]);
      } else {
        setDistrictAction({
          district_id: '#all-districts#',
          district_nm: 'All Districts'
        });
      }
      if (queryParams.get('county') && checkValidDistrict(
        queryParams.get('county'),
        counties,
        'county_id'
      ) && hasCounty !== null) {
        setCountyAction({
          county_id: queryParams.get('county'),
          county_nm: counties.filter((item) => item.county_id === queryParams.get('county'))[0].county_nm,
        });
        setIsCountyAction(true);
      } else {
        setCountyAction(counties[0]);
      }
      if (queryParams.get('from') && queryParams.get('to') && isValidDate(queryParams.get('from')) && isValidDate(queryParams.get('to'))) {
        setDateRangeAction({
          fromDate: queryParams.get('from'),
          toDate: queryParams.get('to'),
        });
      }
    } else if (municipalities.length === 1) {
      setDistrictAction(municipalities[0]);
    } else {
      setDistrictAction({
        district_id: '#all-districts#',
        district_nm: 'All Districts'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCounty) {
      history.replace({
        pathname: '/report/material',
        search: `?county=${county.county_id}&from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
      });
    } else {
      history.replace({
        pathname: '/report/material',
        search: `?district=${district.district_id === '#all-districts#' ? 'all-districts' : district.district_id.replace('#$#', '-')}&from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, district, county, isCounty]);

  useEffect(() => {
    if (data && data.titles) {
      const temp = [];
      data.titles.map((item, index) => temp.push({ type: item, id: index }));
      setType(temp);
      setWasteType(temp[0]);
    }
  }, [data]);

  const generatePDF = async () => {
    setPdfSpinner(true);
    const blob = await pdf((
      <ReportPDF
        interactionAtAGlance={intl.formatMessage({ id: 'REPORT.MATERIALS.SUMMARY.HEADER' })}
        currentPeriod={`${intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_PERIOD' })}:`}
        date={`${fromDate.slice(0, 13)} - ${toDate.slice(0, 13)}`}
        reportingDistrict={isCounty ? `Reporting County : ${county.county_nm}`
          : `Reporting District : ${district.district_id !== '#all-districts#' ? district.district_nm : 'All Districts'}`}
        detailData={detailData.detail}
        isCounty={isCounty}
      />
    )).toBlob();
    saveAs(blob, 'Material Report.pdf');
    setPdfSpinner(false);
  };

  return (
    <Portlet>
      <PortletHeader
        title={(
          <Link to="/report/dashboard">
            <Button
              classes={{
                label: classes.label,
              }}
              startIcon={<BackIcon color={process.env.REACT_APP_COLOR_PRIMARY} />}
            >
              {intl.formatMessage({ id: 'REPORT.GENERAL.BACK' })}
            </Button>
          </Link>
        )}
        toolbar={(
          <PortletHeaderToolbar>
            <Button
              disabled={!data}
              classes={{
                label: !data ? classes.pdfDisable : classes.label,
              }}
              startIcon={(
                <PdfIcon
                  color={!data ? process.env.REACT_APP_COLOR_GRAY_DARK
                    : process.env.REACT_APP_COLOR_PRIMARY}
                />
              )}
              onClick={generatePDF}
            >
              {intl.formatMessage({ id: 'REPORT.GENERAL.PDF' })}
            </Button>
            <RCSpinner isActive={pdfSpinner} />
          </PortletHeaderToolbar>
        )}
      />
      <PortletBody>
        <SummaryHeader
          title={intl.formatMessage({ id: 'REPORT.MATERIALS.SUMMARY.HEADER' })}
          handleDataRequest={materialReportRequest}
        />

        <div className="row mb-4">
          <SummaryCurrentPeriod
            type={dataChangeTracking(
              data ? data.current_period : 0,
              data ? data.last_period : 0
            )}
            data={data ? data.current_period : 0}
            isLoading={isSendRequest}
          />
          <SummaryPreviousPeriod
            data={data ? data.last_period : 0}
            isLoading={isSendRequest}
          />
          {advocateAdmin !== true
            && (
              <SummaryUserRating
                title={intl.formatMessage({ id: 'REPORT.GENERAL.YOUR_RATING' })}
                data={data ? data.rating : 0}
                isLoading={isSendRequest}
                keyword="Searches"
              />
            )}
          {advocateAdmin !== true
            && (
              <SummaryPeerRating
                title={intl.formatMessage({ id: 'REPORT.GENERAL.PEER_GROUP_RATING' })}
                data={data ? data.peer_rating : 0}
                isLoading={isSendRequest}
                keyword="Searches"

              />
            )}
        </div>
        {/* <div className={`mt-3 ${classes.smallerThanMdShow}`} /> */}
        {/*
        <div className={`d-flex align-items-center ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
          <CheckBox
            checked={displayCurrentPeriod}
            onChange={setDisplayCurrentPeriod}
            label={(
              <div className="d-flex align-items-center ml-2">
                <LineThroughDotIcon color={process.env.REACT_APP_COLOR_REPORT_1} />
                <Typography className="ml-2 mr-5" variant="bodySemiBold">
                  {intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_PERIOD' })}
                </Typography>
              </div>
                )}
          />
          <CheckBox
            checked={displayPreviousPeriod}
            onChange={setDisplayPreviousPeriod}
            label={(
              <div className="d-flex align-items-center ml-2">
                <LineThroughDotIcon color={process.env.REACT_APP_COLOR_PRIMARY_LIGHT} />
                <Typography className=" ml-2 mr-5" variant="bodySemiBold">
                  {intl.formatMessage({ id: 'REPORT.GENERAL.PREVIOUS_PERIOD' })}
                  <IconButton
                    tooltip={intl.formatMessage({ id: 'REPORT.GENERAL.PREVIOUS_PERIOD.TOOLTIP' })}
                  >
                    <InfoIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                  </IconButton>
                </Typography>
              </div>
                )}
          />
          <CheckBox
            checked={displayLastYear}
            onChange={setDisplayLastYear}
            label={(
              <div className="d-flex align-items-center ml-2">
                <LineThroughDotIcon color={process.env.REACT_APP_COLOR_DELETE} />
                <Typography className=" ml-2 mr-5" variant="bodySemiBold">
                  {intl.formatMessage({ id: 'REPORT.GENERAL.LAST_YEAR' })}
                </Typography>
              </div>
                )}
          />
        </div>
        <div className={`w-50 m-auto pt-5 pb-5 d-flex justify-content-center align-items-center
        ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
          <SpiderChart
            isLoading={isSendRequest}
            labels={data ? data.titles : []}
            values={data && data.current_period && data.previous_period && data.last_year ? [
              displayCurrentPeriod ? data.current_period : null,
              displayPreviousPeriod ? data.previous_period : null,
              displayLastYear ? data.last_year : null,
            ] : []}
            colors={[
              process.env.REACT_APP_COLOR_REPORT_1,
              process.env.REACT_APP_COLOR_PRIMARY_LIGHT,
              process.env.REACT_APP_COLOR_DELETE,
            ]}
          />
        </div> */}

        <div className={`mt-3 ${classes.smallerThanMdShow}`}>
          {data && data.titles && type
            && (
              <>
                <AutoComplete
                  optionTitle="type"
                  title={intl.formatMessage({ id: 'REPORT.MATERIALS.MOBILE.WASTE_TYPE' })}
                  isClearable={false}
                  options={type}
                  onChange={(data) => {
                    setWasteType(data);
                  }}
                  value={wasteType}
                />
                <Typography className="mt-4 ml-1" variant="body">
                  {wasteType.type}
                </Typography>

                <div className={`mt-3 pr-3 pb-3 ${classes.border} ${classes.smallerThanMdShow}`}>
                  {data && Object.keys(data).length > 0
                    && (
                      <>
                        <Typography className={`mt-3 ${classes.currentPeriod}`} variant="numberSmall">
                          {`${Math.round(((data.current_period[wasteType.id]) * 100) / (data.current_period.reduce((a, b) => a + b, 0))) || '0'} %`}
                        </Typography>
                        <div className="d-flex justify-content-start align-items-center mt-2">
                          <DisplayColorIcon color={process.env.REACT_APP_COLOR_REPORT_1} />
                          <Typography className="text-center" variant="bodySemiBold">
                            {intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_PERIOD' })}
                          </Typography>
                        </div>
                      </>
                    )}
                </div>

                <div className={`mt-3 ml-1 pr-3 pb-3 ${classes.border} ${classes.smallerThanMdShow}`}>
                  {data && Object.keys(data).length > 0
                    && (
                      <>
                        <Typography className={`mt-3 ${classes.previousPeriod}`} variant="numberSmall">
                          {`${Math.round(((data.previous_period[wasteType.id]) * 100) / (data.previous_period.reduce((a, b) => a + b, 0))) || '0'} %`}
                        </Typography>
                        <div className="d-flex justify-content-start align-items-center mt-2">
                          <DisplayColorIcon color={process.env.REACT_APP_COLOR_PRIMARY_LIGHT} />
                          <Typography className="text-center" variant="bodySemiBold">
                            {intl.formatMessage({ id: 'REPORT.GENERAL.PREVIOUS_PERIOD' })}
                            <IconButton
                              tooltip={intl.formatMessage({ id: 'REPORT.GENERAL.PREVIOUS_PERIOD.TOOLTIP' })}
                            >
                              <InfoIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                            </IconButton>
                          </Typography>
                        </div>
                      </>
                    )}
                </div>

                <div className={`mt-3 ml-1 pr-3 pb-3 ${classes.smallerThanMdShow}`}>
                  {data && Object.keys(data).length > 0
                    && (
                      <>
                        <Typography className={`mt-3 ${classes.lastYear}`} variant="numberSmall">
                          {`${Math.round(((data.last_year[wasteType.id]) * 100) / (data.last_year.reduce((a, b) => a + b, 0))) || '0'} %`}
                        </Typography>

                        <div className="d-flex justify-content-start align-items-center mt-2">
                          <DisplayColorIcon color={process.env.REACT_APP_COLOR_DELETE} />
                          <Typography className="text-center" variant="bodySemiBold">
                            {intl.formatMessage({ id: 'REPORT.GENERAL.LAST_YEAR' })}
                          </Typography>
                        </div>
                      </>
                    )}
                </div>
              </>
            )}
        </div>

      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.materialReport.fetchState,
  district: store.common.district,
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
  detailData: store.materialReport.data,
  municipalities: store.common.municipalities,
  county: store.common.county,
  counties: store.common.counties,
  isCounty: store.common.isCounty,
  hasCounty: store.auth.user_info.counties,
  advocateAdmin: store.auth.advocateAdmin
});

export default injectIntl(
  connect(
    mapStateToProps,
    { ...actions, ...sharedActions }
  )(Summary)
);
