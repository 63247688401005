import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import {
  Dialog,
  DatePicker,
  Typography
} from '../../components';
import { EditIcon } from '../../../assets/icons';

const useStyles = makeStyles(() => ({
  dateRange: {
    color: ({ isDashboardDatePicker }) => (isDashboardDatePicker ? '#696969' : process.env.REACT_APP_COLOR_PRIMARY)
  }
}));

const CurrentPeriodDatePicker = ({
  intl,
  fromDate,
  toDate,
  handleDateRangeChange,
  allTime,
  handleAllTimeChange,
  hasAllTimeOption = false,
  isDashboardDatePicker = false,
}) => {
  const [startOfPeriod, setStartOfPeriod] = useState(fromDate);
  const [endOfPeriod, setEndOfPeriod] = useState(toDate);
  const [isDatePicked, setIsDatePicked] = useState();
  const classes = useStyles({ isDashboardDatePicker });

  let label = (
    <Typography className={classes.dateRange} variant="body">
      {allTime
        ? intl.formatMessage({ id: 'REPORT.GENERAL.ALL_TIME' })
        : `${moment(fromDate).format('MMMM Do, YYYY')} - ${moment(toDate).format('MMMM Do, YYYY')}`}
    </Typography>
  );

  if (isDashboardDatePicker) {
    label = (
      <Typography style={{ color: '#696969' }} variant="bodySemiBold">
        {isDatePicked
          ? `${moment(fromDate).format('MMMM Do, YYYY')} - ${moment(toDate).format('MMMM Do, YYYY')}`
          : intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_30_DAYS' })}
      </Typography>
    );
  }

  return (
    <div className="d-flex pt-1">
      {!isDashboardDatePicker && (
        <Typography className="mr-2" variant="body">
          {`${intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_PERIOD' })}:`}
        </Typography>
      )}
      <Dialog
        disableBackdropClick={false}
        button={(
          <div className="d-flex align-items-center">
            {label}
            <EditIcon className="ml-1" color={isDashboardDatePicker ? '#696969' : process.env.REACT_APP_COLOR_PRIMARY} />
          </div>
        )}
        title={intl.formatMessage({ id: 'REPORT.GENERAL.CUSTOM_RANGE.TITLE' })}
        content={(
          <>
            <Typography variant="body">
              {intl.formatMessage({ id: 'REPORT.GENERAL.CUSTOM_RANGE.TEXT' })}
            </Typography>
            <div className="d-flex">
              <DatePicker
                className="mr-2"
                label={intl.formatMessage({ id: 'GENERAL.FROM' })}
                value={startOfPeriod}
                onChange={(date) => setStartOfPeriod(date)}
                clearable={false}
                maxDate={moment(endOfPeriod).subtract(1, 'days').format('YYYY-MM-DD')}
              />
              <DatePicker
                className="ml-2"
                label={intl.formatMessage({ id: 'GENERAL.TO' })}
                minDate={startOfPeriod}
                maxDate={moment().format('YYYY-MM-DD')}
                value={endOfPeriod}
                onChange={(date) => setEndOfPeriod(date)}
                clearable={false}
              />
            </div>
          </>
        )}
        submitText={intl.formatMessage({ id: 'GENERAL.SAVE' })}
        handleSubmit={() => {
          /*
          * if date is not changed return
          */
          if (fromDate === moment(startOfPeriod).format('YYYY-MM-DD')
            && toDate === moment(endOfPeriod).format('YYYY-MM-DD')) {
            return;
          }

          setIsDatePicked(true);
          handleDateRangeChange({
            fromDate: moment(startOfPeriod).format('YYYY-MM-DD'),
            toDate: moment(endOfPeriod).format('YYYY-MM-DD'),
          });
          if (hasAllTimeOption) {
            handleAllTimeChange(false);
          }
        }}
      />
    </div>
  );
};

export default injectIntl(CurrentPeriodDatePicker);
