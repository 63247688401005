import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Typography, Tab, IconButton
} from '../../components';
import * as actions from '../redux/actions';
import SetupWizardOperationHoursTab from './SetupWizardOperationHoursTab';
import { PlusCircleIcon } from '../../../assets/icons';

const SetupWizardOperationHours = ({
  intl,
  newDepot,
  setNewDepotAction,
  initialSeason,
  setInitialSeason
}) => {
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState();

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChange = (data, propertyName) => {
    setNewDepotAction({
      data,
      propertyName
    });
  };

  useEffect(() => {
    setInitialSeason();
  }, []);

  useEffect(() => {
    let seasons = [intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.HOURS.DEFAULT_TAB' })];
    newDepot.seasons.map((item, index) => {
      seasons = index > 0 ? [...seasons, intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.HOURS.SEASONAL_TAB' })] : [...seasons];
      setTabName(seasons);
    });
  }, [newDepot.id]);

  return (
    <div
      id="wizard-content1"
      className="w-100 d-flex flex-column justify-content-start align-items-start"
    >
      <Typography variant="h2" className="mb-4">
        {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.HOURS' })}
      </Typography>
      {tabName
      && (
      <>
        <Tab
          selectedTab={tab}
          onChange={handleTabChange}
          labels={tabName}
          unselectedColor={process.env.REACT_APP_COLOR_DISABLED_TEXT}
          borderColor={process.env.REACT_APP_COLOR_PRIMARY}
          selectedBottomBorderColor={process.env.REACT_APP_COLOR_PRIMARY_BG}
          addButton={(
            <IconButton
              tooltip={intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.HOURS.SEASONAL_HOURS.BUTTON_TOOLTIP' })}
              onClick={() => {
                let AllSeason = [...newDepot.seasons];
                AllSeason = [...AllSeason, { ...initialSeason, depot_season_slot: tab + 1 }];
                handleChange(AllSeason, 'seasons');
                setTabName([...tabName, intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.HOURS.SEASONAL_TAB' })]);
                setTab(tabName.length);
              }}
            >
              <PlusCircleIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
            </IconButton>
          )}
        />
        <SetupWizardOperationHoursTab
          key={`hoursTab${String(tab)}`}
          tab={tab}
        />
      </>
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  newDepot: store.depot.newDepot,
  initialSeason: store.depot.initialSeason,
});

export default injectIntl(connect(mapStateToProps, actions)(SetupWizardOperationHours));
