const initialStore = {
  loading: true,
  error: false,
  currentQuestionIndex: 0,
  data: [],
  misunderstood_materials_data: [],
  misunderstood_materials_loading: false

}

export default initialStore;
