import React, { useEffect } from 'react';
import moment from 'moment';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import { CustomButton, Opacity, Typography } from '../../../components';
import { CrownIcon } from '../../../../assets/icons';
import ProfileImage from '../../../Auth/Profile/components/ProfileImage';
import * as actions from '../../../Dashboard/redux/actions';
import { LoadingSpinner, ErrorContainer } from '../../../components/loading';
import { ProUser } from '../components';
import { toAbsoluteUrl } from '../../../../../_metronic';



const useStyles = makeStyles({
  handIcon: {
    width: 40,
    height: 40,
    border: 'none',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    transform: 'translateX(25px)'
  },
  letterSpacing: {
    letterSpacing: '1px'
  },
  emailText: {
    color: process.env.REACT_APP_COLOR_PRIMARY,
    textDecoration: 'underline'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 17,
    height: 17,
    background: '#00C8BC',
    borderRadius: '50%',
    marginRight: 5,
    '& svg': {
      fontSize: '1rem'
    }
  }
});

const PollsAndSurveys = ({
  intl,
  csm,
  loading,
  error,
  isFirstLoading,
  getDashboardDataRequestAction,
  isUserPro
}) => {
  const classes = useStyles();

  // fetch dashboard data
  useEffect(() => {
    if (!csm) {
      const fromDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
      const toDate = moment().format('YYYY-MM-DD');
      getDashboardDataRequestAction(fromDate, toDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Opacity slide>

      {isUserPro ? (
        <>
          <Typography className="d-flex align-items-center mb-3" variant="h1">
            {intl.formatMessage({ id: 'EDUCATION.CUSTOM.POOLS.AND.SURVEYS' })}
            <CrownIcon style={{ marginLeft: 7 }} width={15} height={15} color="#FFC611" />
          </Typography>
          <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-3" variant="educationBody">
            {intl.formatMessage({ id: 'EDUCATION.CUSTOM.POOLS.AND.SURVEYS.CONTENT.1' })}
          </Typography>
          <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-3" variant="educationBody">
            {intl.formatMessage({ id: 'EDUCATION.CUSTOM.POOLS.AND.SURVEYS.CONTENT.2' })}
          </Typography>
          <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-3" variant="educationBody">
            {intl.formatMessage({ id: 'EDUCATION.CUSTOM.POOLS.AND.SURVEYS.CONTENT.3' })}
          </Typography>
          <Typography color={process.env.REACT_APP_COLOR_TITLE} className="mb-4 mt-4 pt-2" variant="h2">
            {intl.formatMessage({ id: 'EDUCATION.YOUR.CUSTOMER.SUCCESS.MANAGER' })}
          </Typography>
          {loading && isFirstLoading ? <LoadingSpinner height={90} />
            : error ? <ErrorContainer small reportName="CSM" height={90} error={error} />
              : csm?.has_csm ? (
                <div className="d-flex justify-content-between">
                  <ProfileImage isCsm width={70} height={70} variant="numberSmall" />
                  <div className="col d-flex flex-column justify-content-center ml-2">
                    <Typography className={classes.letterSpacing} variant="h2">
                      {csm?.name}
                    </Typography>

                    <div className="mt-1">
                      T:
                      {' '}
                      <Typography component="span" variant="educationBody">
                        {`${csm?.phone} ext.${csm?.phone_ext}`}
                      </Typography>
                    </div>

                    <div className="mt-1">
                      E:
                      {' '}
                      <Typography component="span" variant="educationBody" className={classes.emailText}>
                        {`${csm?.email}`}
                      </Typography>
                    </div>
                  </div>
                  <a style={{ marginTop: 'auto' }} target="_blank" href={toAbsoluteUrl(`/media/pdfs/${encodeURIComponent('Weekly Quiz Questions Request Form.pdf')}`)} download="Weekly Quiz Questions Request Form.pdf" rel="noreferrer">
                    <CustomButton
                      size="large"
                      variant="contained"
                      fontSize={14}
                      paddingVertical={2}
                      label={(
                        <FormattedMessage
                          id="EDUCATION.CUSTOM.POOLS.AND.SURVEYS.DOWNLOAD.FORM"
                        />
                      )}
                    />
                  </a>
                </div>
              ) : null}
        </>
      ) : (
        <ProUser
          title={intl.formatMessage({ id: 'EDUCATION.CUSTOM.POOLS.AND.SURVEYS' })}
          description="As part of the PRO Package, up to four times per year you can replace the 'We Wanted to Know' question with a quiz or poll question of your own. "
          subtitle="Customization of our educational content can help you gain valuable insights about your community."
          imagePath="creative-writing-amico.png"
          benefits={['Tailor content to the needs of your program ', 'View reports on your custom questions', 'Prepare residents for changes in your program ', 'Measure residents’ attitudes and actions ', 'Promote behavior change ']}
          alternateRightTitle={intl.formatMessage({ id: 'GENERAL.WHAT.BENEFITS' })}
        />
      )}
    </Opacity>
  );
};

const mapStateToProps = (store) => ({
  csm: store.dashboard.csm,
  loading: store.dashboard.loading,
  error: store.dashboard.error,
  isFirstLoading: store.dashboard.isFirstLoading,
  isUserPro: store.common.isUserPro
});

export default injectIntl(connect(mapStateToProps, actions)(PollsAndSurveys));
