/* eslint-disable import/prefer-default-export */
import cts from '../../crud/cts';

/**
 * TODO: Insert comment
 */
export const getLocalities = async () => cts(
  'post',
  'users/locality/get-all'
);

/**
 * TODO: Insert comment
 */
export const getWasteTypes = async ({ locality }) => cts(
  'post',
  'waste-type/get-all',
  { cms_locality_id: locality }
);

/**
 * TODO: Insert comment
 */
export const getCategories = async ({ wasteType, locality }) => cts(
  'post',
  'category/get-all',
  {
    cms_locality_id: locality,
    waste_type_id: wasteType
  }
);

/**
 * TODO: Insert comment
 */
export const getAllCategories = async ({ locality }) => cts(
  'post',
  'locality/category/get-all',
  {
    cms_locality_id: locality
  }
);

/**
 * TODO: Insert comment
 */
export const deleteCategory = async ({ locality, category }) => cts(
  'post',
  'category/remove',
  {
    cms_locality_id: locality,
    mat_cat_id: category
  }
);

/**
 * TODO: Insert comment
 */
export const deleteDepot = async ({ locality, depot, category }) => cts(
  'post',
  'category/depot/remove',
  {
    cms_locality_id: locality,
    drop_depot_id: depot,
    mat_cat_id: category,
  }
);

/**
 * TODO: Insert comment
 */
export const deleteEvent = async ({ locality, event, category }) => cts(
  'post',
  'category/event/remove',
  {
    cms_locality_id: locality,
    drop_event_id: event,
    mat_cat_id: category,
  }
);

/**
 * TODO: Insert comment
 */
export const renameCategory = async ({ locality, category, title }) => cts(
  'post',
  'category/edit',
  {
    cms_locality_id: locality,
    mat_cat_id: category,
    mat_cat_admin_nm: title,
  }
);

/**
 * TODO: Insert comment
 */
export const getDepots = async ({ locality }) => cts(
  'post',
  'depot/get-all',
  {
    cms_locality_id: locality,
  }
);

/**
 * TODO: Insert comment
 */
export const getEvents = async ({ locality }) => cts(
  'post',
  'event/get-all',
  {
    cms_locality_id: locality,
  }
);

/**
 * TODO: Insert comment
 */
export const getCollections = async ({ locality }) => cts(
  'post',
  'collection/get-all',
  {
    cms_locality_id: locality,
  }
);

/**
 * TODO: Insert comment
 */
export const getCollectionDetails = async ({ locality, collection }) => cts(
  'post',
  'collection/get-one',
  {
    cms_locality_id: locality,
    coll_id: collection,
  }
);

/**
 * TODO: Insert comment
 */
export const assignDepot = async ({ locality, category, depot }) => cts(
  'post',
  'category/depot/assign',
  {
    cms_locality_id: locality,
    mat_cat_id: category,
    drop_depot_id: depot,
  }
);

/**
 * TODO: Insert comment
 */
export const assignEvent = async ({ locality, category, event }) => cts(
  'post',
  'category/event/assign',
  {
    cms_locality_id: locality,
    mat_cat_id: category,
    drop_event_id: event,
  }
);

/**
 * TODO: Insert comment
 */
export const assignCollection = async ({ locality, wasteType, collection }) => cts(
  'post',
  'waste-type/collection/assign',
  {
    cms_locality_id: locality,
    waste_type_id: wasteType,
    coll_id: collection,
  }
);

/**
 * TODO: Insert comment
 */
export const moveCategory = async ({ locality, wasteType, category }) => cts(
  'post',
  'waste-type/category/move',
  {
    cms_locality_id: locality,
    waste_type_id: wasteType,
    mat_cat_id: category,
  }
);

/**
 * TODO: Insert comment
 */
export const moveMaterials = async ({ locality, category, materials }) => cts(
  'post',
  'category/material/move',
  {
    cms_locality_id: locality,
    mat_cat_id: category,
    mat_list: materials,
  }
);

/**
 * TODO: Insert comment
 */
export const searchMaterials = async ({ locality, search }) => cts(
  'post',
  'material/search',
  {
    cms_locality_id: locality,
    search,
  }
);

/**
 * TODO: Insert comment
 */
export const getSearchResults = async ({ locality, material }) => cts(
  'post',
  'category/material-search',
  {
    cms_locality_id: locality,
    mat_id: material,
  }
);

/**
 * TODO: Insert comment
 */
export const getSearchedWasteType = async ({ locality, wasteType }) => cts(
  'post',
  'waste-type/get-one',
  {
    cms_locality_id: locality,
    waste_type_id: wasteType,
  }
);

/**
 * TODO: Insert comment
 */
export const addCategory = async ({ locality, wasteType, categoryName, categoryHeader, categoryDescription }) => cts(
  'post',
  'category/new',
  {
    cms_locality_id: locality,
    waste_type_id: wasteType,
    mat_cat_admin_nm: categoryName,
    disposal_header: categoryHeader, 
    custom_body: categoryDescription
  }
);

/**
 * TODO: Insert comment
 */
export const updateCategoryDescriptionFixed = async ({
  locality, wasteType, category, descriptionId, moreInfo
}) => cts(
  'post',
  'category/edit',
  {
    cms_locality_id: locality,
    waste_type_id: wasteType,
    mat_cat_id: category,
    fixed_body_id: descriptionId,
    more_info: moreInfo,
  }
);

/**
 * TODO: Insert comment
 */
export const updateCategoryDescriptionCustom = async ({
  locality, wasteType, category, descriptionHeader, descriptionBody, moreInfo
}) => cts(
  'post',
  'category/edit',
  {
    cms_locality_id: locality,
    waste_type_id: wasteType,
    mat_cat_id: category,
    disposal_header: descriptionHeader,
    custom_body: descriptionBody,
    more_info: moreInfo,
  }
);
