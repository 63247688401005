import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';


const CustomEvent = ({ event }) => {

  const useStyles = makeStyles((theme) => ({
    tooltip: {
      backgroundColor: process.env.REACT_APP_COLOR_SECONDARY,
      color: process.env.REACT_APP_COLOR_TEXT,
      boxShadow: theme.shadows[3],
      fontSize: 14,
    },
    arrow: {
      color: process.env.REACT_APP_COLOR_SECONDARY,
    },
    root: {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
  }));

  const classes = useStyles();


  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
      title={event.title}
      placement="top"
      arrow
    >
      <div className='rbc-event-content'>{event.title}</div>
    </Tooltip>
  );
};

export default CustomEvent;
