import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import CustomToolbar from './CustomToolbar';
import CustomMonthHeader from './CustomMonthHeader';
import CustomDate from './CustomDate';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../CustomCalendar/custom.css'
import CustomEvent from './CustomEvent';

const localizer = momentLocalizer(moment)

const CustomCalendar = ({
  events
}) => {
  const useStyles = makeStyles(() => ({
    root: {
      height: 800
    }
  }));

  const classes = useStyles();

  const eventPropGetter = (event) => {
    if (event.holidayEvent) {
      return { style: { color: '#FF1E1E' } };
    }
    return {};
  };

  return (
    <div className={classes.root}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        defaultView='month'
        toolbar={true}
        popup={true}
        components={{
          toolbar: CustomToolbar,
          month: {
            header: CustomMonthHeader,
            dateHeader: CustomDate,
            event: CustomEvent
          }
        }}
        eventPropGetter={eventPropGetter}
      />
    </div>
  );
};

export default CustomCalendar;


