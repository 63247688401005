import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import {
  WhatToDo, UserInfoCard, HowResidentsAnswered, Reports, ImageCarousel, NationalSurvey, SchedulesAnnounce, PerpetualSchedulesBanner
} from './components';
import * as actions from './redux/actions';
import * as reportDashboardActions from '../Report/ReportDashboardNew/redux/actions';

import { LoadingSpinner, ErrorContainer } from '../components/loading';
import excludedBCDistrictsFromNS from './excludedBCDistrictsFromNS';

import { ParticipationMethod } from '../Report/ReportDashboardNew/components';

const useStyles = makeStyles({
  container: {
    width: '1200px',
    margin: '0 auto'
  }
});

const Dashboard = ({
  csm,
  wwtk,
  loading,
  error,
  isFirstLoading,
  getDashboardDataRequestAction,
  municipalities,
  municipality,
  district,
  county,
  isCounty,
  reportData,
  getReportDashboardRequest,
  reportDataLoading
}) => {
  const { container } = useStyles();

  // fetch dashboard data
  useEffect(() => {
    const fromDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
    const toDate = moment().format('YYYY-MM-DD');

    getDashboardDataRequestAction(fromDate, toDate);
  }, [getDashboardDataRequestAction]);

  // function that generates the last 24 months based on a given date
  const generateLast24Months = (date) => {
    const months = [];
    for (let i = 0; i < 24; i++) {
      const month = moment(date).subtract(i, 'months');
      months.push({
        month: month.month() ? month.month() : 12,
        year: month.month() ? month.year() : month.year() - 1,
      });
    }
    return months;
  };
  const options = generateLast24Months();
  useEffect(() => {
    if (isFirstLoading) return;

    setTimeout(() => {
      getReportDashboardRequest({
        municipality,
        month: options[0].month,
        year: options[0].year,
        county,
        isCounty,
      });
    }, 300);

  }, [district, county, isCounty, isFirstLoading]);

  let dashboardCardsContent = (
    <>
      {csm?.has_csm && <UserInfoCard isCsm />}
      {wwtk?.has_wwtk && <HowResidentsAnswered />}
    </>
  );

  if (loading && isFirstLoading) {
    dashboardCardsContent = <LoadingSpinner height={350} />;
  }

  if (error) {
    dashboardCardsContent = (
      <ErrorContainer
        reportName="Dashboard"
        small
        portlet
        height={200}
        error={error}
      />
    );
  }

  let exclude = false;

  // don't show NS for some districts
  if (municipalities.length > 0) {
    exclude = municipalities.some((item) => {
      const [projectId, districtId] = item.district_id.split('#$#');
      return excludedBCDistrictsFromNS.some(
        (item) => item.project_id === projectId && item.district_id === districtId
      );
    });
  }
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth();

  const NSTimeFrame = (month === 8 || (month === 9 && day <= 31));
  const schedulesTimeFrame = (month === 9 && day >= 3) || (month === 10) || (month === 11);

  return (
    <div className={container}>
      {/* {
        NSTimeFrame
        && !exclude
        && municipalities.length > 0
        && district
        && <NationalSurvey />
      } */}
      {/* {schedulesTimeFrame && <SchedulesAnnounce />} */}
      <PerpetualSchedulesBanner />
      <div className="row">
        <div className="col-8">
          <WhatToDo />
          <ParticipationMethod reportData={reportData} reportMonth={options[0].month} reportYear={options[0].year} isLoading={reportDataLoading || isFirstLoading} csm={csm} onReportDashboard={false} title="Users" />
        </div>
        <div className="col-4">
          <UserInfoCard isCsm={false} />
          {dashboardCardsContent}
        </div>
      </div>
      <ImageCarousel />
    </div>
  );
};

const mapStateToProps = (store) => ({
  csm: store.dashboard.csm,
  wwtk: store.dashboard.wwtk,
  loading: store.dashboard.loading,
  error: store.dashboard.error,
  isFirstLoading: store.dashboard.isFirstLoading,
  municipalities: store.common.municipalities,
  municipality: store.common.district.district_id,
  district: store.common.district,
  county: store.common.county,
  isCounty: store.common.isCounty,
  reportData: store.reportsDashboardReducer,
  reportDataLoading: store.reportsDashboardReducer.loading,
});

export default injectIntl(connect(mapStateToProps, { ...actions, ...reportDashboardActions })(Dashboard));
