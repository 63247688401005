import React from 'react';
import ContentLoader from 'react-content-loader';

const ChartLoading = ({
  width = 300,
  height = 300
}) => (
  <ContentLoader
    speed={2}
    style={{ width, height }}
    backgroundColor={process.env.REACT_APP_COLOR_LOADING_BACKGROUND}
    foregroundColor={process.env.REACT_APP_COLOR__LOADING_FOREGROUND}
  >
    <circle cx="150" cy="150" r="150" />
  </ContentLoader>
);

export default ChartLoading;
