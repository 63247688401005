const actionTypes = {
  // Auth actionTypes for setting loading status
  SET_LOADING: 'SET_LOADING',
  RESET_LOADING: 'RESET_LOADING',

  // OTP actionTypes
  OTPـREQUEST: 'OTPـREQUEST',
  OTPـRECEIVE: 'OTPـRECEIVE',
  OTPـFAIL: 'OTPـFAIL',
};

export default actionTypes;
