import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { Typography } from '../../../components';
import BadgeTable from './BadgeTable';

const useStyles = makeStyles((theme) => ({
  tabsTitle: {
    marginTop: '28px',
    marginBottom: '15px',
    marginLeft: '5px',
    [theme.breakpoints.down(992)]: {
      marginLeft: '15px',
      marginTop: '15px',
      marginBottom: '0px',
    }
  },
}));

const Badge = ({
  intl,
  data
}) => {
  const classes = useStyles();

  return (
    <div className="w-100 d-flex flex-column">
      <Typography className={classes.tabsTitle} variant="h1">
        {intl.formatMessage({ id: 'REPORT.BADGE.DETAIL.TITLE' })}
      </Typography>

      <BadgeTable
        data={data}
      />
    </div>
  );
};

export default injectIntl(Badge);
