/* eslint-disable import/prefer-default-export */
import cts from '../../../crud/cts';

/**
 * Problem report API which fetches problem report data
 */
export const getProblemReport = async ({
  municipality, from, to, county
}) => cts(
  'post',
  'reports/get_problems',
  {
    municipality, from, to, county
  },
);

export const getProblemSummaryReport = async ({
  municipality, from, to, county
}) => cts(
  'get',
  `reports/problems?from=${from}&to=${to}&county=${county}&municipality=${municipality}`,
);
