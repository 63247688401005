/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';
import { SummaryCellLoading } from '../../../components/loading';

import { Typography } from '../../../components';
import {
  PositiveIcon,
  NegativeIcon,
  NeutralIcon
} from '../../../../assets/icons';

import { addCommasToNumber } from '../../../../utils';

const useStyles = makeStyles(() => ({
  value: {
    minHeight: 80,
  },
  positiveColor: {
    color: process.env.REACT_APP_COLOR_POSITIVE,
  },
  negativeColor: {
    color: process.env.REACT_APP_COLOR_NEGATIVE
  },
  neutralColor: {
    color: process.env.REACT_APP_COLOR_NEUTRAL
  },
  peerColor: {
    color: process.env.REACT_APP_COLOR_BACKGROUND_FILL
  },
  icon: {
    width: 80,
    height: 50,
    '@media (max-width: 1500px)': {
      width: 65
    },
  },
}));

const SummaryCell = ({
  intl,
  type,
  data,
  description,
  isLoading = true
}) => {
  const classes = useStyles();
  const isPositive = type === 'positive';
  const isNegative = type === 'negative';
  const isNeutral = type === 'neutral';
  const isPrevious = type === 'previous';
  const icon = isPositive ? <PositiveIcon className={classes.icon} />
    : isNegative ? <NegativeIcon className={classes.icon} />
      : <NeutralIcon className={classes.icon} />;

  return (
    <div>
      { isLoading
        ? <SummaryCellLoading />
        : (
          <>
            <div className={`d-flex align-items-center ${classes.value}`}>
              <Typography
                variant="numberLarge"
                className={clsx({
                  [classes.positiveColor]: isPositive,
                  [classes.negativeColor]: isNegative,
                  [classes.neutralColor]: isNeutral,
                })}
              >
                {addCommasToNumber(data)}
              </Typography>
              {!isPrevious && (
              <span className="ml-5 d-flex align-items-center">
                {icon}
              </span>
              )}
            </div>
            <div className="mt-2">
              <Typography variant="h2">
                {description}
              </Typography>
            </div>
          </>
        )}
    </div>
  );
};

export default injectIntl(SummaryCell);
