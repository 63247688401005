import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { Typography } from '../../../components';
import { addCommasToNumber } from '../../../../utils';

const useStyles = makeStyles((theme) => ({
  tabsTitle: {
    marginTop: '28px',
    marginBottom: '15px',
    [theme.breakpoints.down(992)]: {
      marginLeft: '15px',
      marginTop: '15px',
      marginBottom: '0px',
    }
  },
  tabs: {
    marginTop: '28px',
    marginBottom: '15px',
    marginLeft: '5px',
  },
  tableRow: {
    minHeight: '45px',
    borderTop: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  tableHeader: {
    minHeight: '55px',
  },
  tableCell: {
    minHeight: '45px',
  },
}));

const BlogReport = ({
  intl,
  data
}) => {
  const classes = useStyles();

  return (
    data
      ? (
        <div className="w-100 d-flex flex-column">
          <Typography className={classes.tabsTitle} variant="h1">
            {intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.BLOG_TITLE' })}
          </Typography>
          <div className={`row w-100 d-flex align-items-center ${classes.tableHeader}`}>
            <Typography
              className="d-flex col-6 justify-content-start"
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.BLOG.TITLE' })}
            </Typography>

            <Typography
              className="d-flex col-3 justify-content-start"
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.BLOG.SOURCE' })}
            </Typography>

            <Typography
              className="d-flex col-3 justify-content-start"
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.MM_TABLE.OF_USER.VIEWS' })}
            </Typography>
          </div>

          {data && data.map((item, id) => (
            <div
              key={`MM_REPORT_${item.title} - ${String(id)}`}
              className={`row w-100 d-flex h-100 ${classes.tableRow}`}
            >

              <Typography className={`col-6 h-100 d-flex align-items-center ${classes.tableCell}`} variant="body">
                {item.title ? item.title : ''}
              </Typography>

              <Typography className={`col-3 h-100 d-flex align-items-center ${classes.tableCell}`} variant="body">
                {item.type === 'hs'
                  ? intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.TABLE.TYPE.HOMESCREEN' })
                  : intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.TABLE.TYPE.DISCOVERY_ZONE' })}
              </Typography>

              <Typography className={`col-3 h-100 d-flex align-items-center ${classes.tableCell}`} variant="body">
                {item.visits ? addCommasToNumber(item.visits) : ''}
              </Typography>

            </div>
          ))}

        </div>
      ) : null
  );
};

export default injectIntl(BlogReport);
