import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Typography, TextField, RadioButton, WysiwygEditor
} from '../../components';
import * as actions from '../redux/actions';

const SetupWizardDropOffLocation = ({
  intl,
  setNewDepotAction,
  newDepot,
}) => {
  const handleChange = (data, propertyName) => {
    setNewDepotAction({
      data,
      propertyName
    });
  };

  return (
    <div
      id="wizard-content1"
      className="w-100 d-flex flex-column justify-content-start align-items-start"
    >
      <Typography variant="h2">
        {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.DROP_OF_LOCATION' })}
      </Typography>

      <Typography className="mt-3" variant="bodySemiBold">
        {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.LOCATION_NAME' })}
      </Typography>
      <TextField
        className="my-3"
        onChange={(e) => { handleChange(e, 'name'); }}
        value={newDepot.name}
      />
      <Typography className="mt-4" variant="bodySemiBold">
        {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.INSTRUCTION' })}
      </Typography>
      <div className="w-100 mt-4">
        <WysiwygEditor
          fullWidth
          value={newDepot.instruction}
          onChange={(e) => {
            handleChange(e, 'instruction');
          }}
        />
      </div>
      <Typography className="mt-3" variant="bodySemiBold">
        {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.ALWAYS_OPEN' })}
      </Typography>
      <div className="w-50">
        <RadioButton
          row
          firstLabel="Yes"
          secondLabel="No"
          firstValue
          secondValue={false}
          value={newDepot.open24}
          onChange={() => { handleChange(!newDepot.open24, 'open24'); }}
        />
      </div>
    </div>

  );
};

const mapStateToProps = (store) => ({
  categoryList: store.depot.categoryList,
  addDepot: store.depot.addDepot,
  newDepot: store.depot.newDepot,
});

export default injectIntl(
  connect(mapStateToProps, actions)(SetupWizardDropOffLocation)
);
