/* eslint-disable import/prefer-default-export */
import cts from '../../../crud/cts';

/**
 * User report API which fetches user report data
 */
export const getUserReport = async ({
  county, municipality, from, to
}) => cts(
  'get',
  `reports/users?county=${county}&municipality=${municipality}&from=${from}&to=${to}`,
);
