import { put, call, takeLatest } from 'redux-saga/effects';
import actionTypes from './actionTypes';
import * as actions from './actions';
import { getFeaturedLessonData, featuredLessonReadRequest, featuredLessonUnreadRequest, feedbackRequest } from '../api';

function* getFeaturedLessonDataSaga({ payload: { project_id, district_id } }) {
  const response = yield call(getFeaturedLessonData, { project_id, district_id });

  try {
    response.data.data.campaigns.sort((a, b) => new Date(b.date_from) - new Date(a.date_from));
    yield put(actions.getFeaturedLessonsDataReceiveAction(response.data));
  } catch (error) {
    yield put(actions.getFeaturedLessonsDataFailAction(response.error.detail.data));
  }
}

function* featuredLessonRead({ payload: { campaign_id } }) {
  const response = yield call(featuredLessonReadRequest, { campaign_id });
  try {
    yield put(actions.featuredLessonReadReceive(response.data));
  } catch (error) {
    yield put(actions.featuredLessonReadFail(response.error));
  }
}

function* featuredLessonUnRead({ payload: { campaign_id } }) {
  const response = yield call(featuredLessonUnreadRequest, { campaign_id });
  try {
    yield put(actions.featuredLessonUnreadReceive(response.data));
  } catch (error) {
    yield put(actions.featuredLessonUnreadFail(response.error));
  }
}

function* feedbackSaga({ payload: { meta, feedback, type } }) {
  const response = yield call(feedbackRequest, { meta, feedback, type });
  try {
    yield put(actions.featuredLessonFeedbackdReceive(response.data));
  } catch (error) {
    yield put(actions.featuredLessonFeedbackFail(response.error));
  }
}

function* featuredLessonSaga() {
  yield takeLatest(actionTypes.GET_FEATURED_LESSONS_DATA_REQUEST, getFeaturedLessonDataSaga);
  yield takeLatest(actionTypes.FEATURED_LESSON_READ_REQUEST, featuredLessonRead);
  yield takeLatest(actionTypes.FEATURED_LESSON_UNREAD_REQUEST, featuredLessonUnRead);
  yield takeLatest(actionTypes.FEATURED_LESSON_FEEDBACK_REQUEST, feedbackSaga);
}

export default featuredLessonSaga;
