import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Blogs from '../Blogs';
import BlogLibrary from '../BlogLibrary';
import WeWantedToKnow from '../WeWantedToKnow';
import PollsAndSurveys from '../PollsAndSurveys';
import FeaturedLessons from '../FeaturedLessons';
import LessonLibrary from '../LessonLibrary';
import WhereDoesThisGo from '../WhereDoesThisGo';
import PdfAddOns from '../PdfAddOns';
import RecyclingForKids from '../RecyclingForKids';
import AboutCASy from '../AboutCASy';
import MajorCampaigns from '../MajorCampaigns';


const EducationRoutes = () => (
  <Switch>
    <Redirect exact from="/education" to="/education/major-campaigns" />
    <Route path="/education/blogs" component={Blogs} />
    <Route path="/education/blogs-library" component={BlogLibrary} />
    <Route path="/education/where-does-this-go" component={WhereDoesThisGo} />
    <Route path="/education/we-wanted-to-know" component={WeWantedToKnow} />
    <Route path="/education/major-campaigns" component={MajorCampaigns} />
    <Route path="/education/polls-and-surveys" component={PollsAndSurveys} />
    <Route path="/education/featured-lessons" component={FeaturedLessons} />
    <Route path="/education/lessons-library" component={LessonLibrary} />
    <Route path="/education/pdf-add-ons" component={PdfAddOns} />
    <Route path="/education/recycling-for-kids" component={RecyclingForKids} />
    <Route path="/education/about-casy" component={AboutCASy} />
    <Redirect to="/error" />
  </Switch>
);

export default EducationRoutes;
