import actionTypes from './actionTypes';

// Page Views report actions for setting loading status
export const setPageViewsReportLoadingAction = (status) => ({
  type: actionTypes.SET_PAGE_VIEWS_REPORT_LOADING_ACTION,
  payload: status
});

// Page Views report actions for fetching report data
export const pageViewsReportRequest = ({
  municipality, from, to, county
}) => ({
  type: actionTypes.GET_PAGE_VIEWS_REPORT_REQUEST,
  payload: {
    municipality, from, to, county
  }
});

export const pageViewsReportReceive = (data) => ({
  type: actionTypes.GET_PAGE_VIEWS_REPORT_RECEIVE,
  payload: { data }
});

export const pageViewsReportFail = (error) => ({
  type: actionTypes.GET_PAGE_VIEWS_REPORT_FAIL,
  payload: { ...error }
});

export const setPageType = (data) => ({
  type: actionTypes.SET_PAGE_TYPE,
  payload: { data }
});

export const setReport = (data) => ({
  type: actionTypes.SET_REPORT_TYPE,
  payload: data
});
