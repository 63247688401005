import React from 'react';
import { FormattedMessage } from 'react-intl';

const Title = ({ text }) => (
  <div className="kt-login__title">
    <h3>
      <FormattedMessage id={text} />
    </h3>
  </div>
);

export default Title;
