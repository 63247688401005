import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { addCommasToNumber } from '../../../../utils';
import { InfoTooltip, Typography } from '../../../components';

const useStyles = makeStyles((theme) => ({
  border: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
  },
  value: {
    color: '#3161a8'
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: '#3161a8'
  },
  header: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  smallerThanMdHide: {
    [theme.breakpoints.down(1200)]: {
      display: 'none !important'
    }
  },
  smallerThanMdShow: {
    [theme.breakpoints.up(1200)]: {
      display: 'none !important'
    },
  },
}));

const InteractionReportCalendarDetail = ({
  intl,
  data
}) => {
  const classes = useStyles();

  return (
    <div>
      <div className="row d-flex align-items-center p-4">
        <div className={`col-1 mr-3 mr-xl-0 ${classes.smallerThanMdHide}`}>
          <div className={classes.circle} />
        </div>
        <div className={`col-10 col-xl ${classes.smallerThanMdHide}`}>
          <Typography className="d-flex align-items-center" variant="h2">
            {intl.formatMessage({ id: 'REPORT.INTERACTIONS.DETAIL.CATEGORIES.CALENDAR.TITLE' })}
            <InfoTooltip tooltip={intl.formatMessage({ id: 'REPORT.INTERACTIONS.DETAIL.CATEGORIES.CALENDAR.DESCRIPTION' })} />
          </Typography>
        </div>
        <div className={`col-11 col-xl-5 mt-3 mt-xl-0 ${classes.smallerThanMdHide}`}>
          <Typography variant="numberLarge" className={classes.value}>
            {addCommasToNumber(data.total)}
          </Typography>
        </div>
        <div className="col-1" />
      </div>

      <div className={`d-flex w-100 p-0 ${classes.smallerThanMdShow}`}>
        <div className={`mr-5 ${classes.circle}`} />
        <div className="row p-0 pb-3 w-100 justify-content-between">
          <div className="d-flex flex-column justify-content-start">
            <div className="row pl-3">
              <Typography className="d-flex align-items-center" variant="h2">
                {intl.formatMessage({ id: 'REPORT.INTERACTIONS.DETAIL.CATEGORIES.CALENDAR.TITLE' })}
                <InfoTooltip tooltip={intl.formatMessage({ id: 'REPORT.INTERACTIONS.DETAIL.CATEGORIES.CALENDAR.DESCRIPTION' })} />
              </Typography>
            </div>
            <Typography variant="numberLarge" className={classes.value}>
              {addCommasToNumber(data.total)}
            </Typography>
          </div>
          <div className="d-flex mt-3" />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(InteractionReportCalendarDetail);
