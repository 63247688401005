/* eslint-disable import/prefer-default-export */
import cts from '../../../crud/cts';

export const getRecyclingFundamentalsData = async ({
  project_id,
  district_id
}) => cts(
  'get',
  `/education/rf_lessons?project_id=${project_id}&district_id=${district_id}`

);

export const getMisunderstoodMaterialsData = async ({
  project_id,
  district_id
}) => cts(
  'get',
  `/education/misunderstood_materials?project_id=${project_id}&district_id=${district_id}`

);
