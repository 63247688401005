import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';


import { Tooltip } from '@material-ui/core';
import { CustomButton, Typography, Snackbar } from '../../../components';
import { EducationModal, DropdownMenu } from './index';
import * as actions from '../redux/actions';
import romoveArticle from '../../../../assets/images/remove-article.png';


const useStyles = makeStyles((theme) => ({
  container: {
    width: '32.333%',
    margin: '10px 0.5%',
    transition: 'all .3s',
    borderRadius: 7,
    '&:hover': {
      boxShadow: 'rgba(212, 190, 190, 0.1) 0px 1px 4px 8px'
    },
    '& .MuiButton-label': {
      fontWeight: ({ isread }) => (isread ? 400 : 'bold')
    }
  },
  title: {
    fontSize: '15px',
    width: 200,
    height: 45,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  lastDate: {
    fontSize: '12px',
    width: 200,
    height: 28,
    color: 'rgba(64, 64, 64, 0.8)',
    fontWeight: '400',
  },
  imageContainer: {
    backgroundColor: '#FFFAFA',
    borderRadius: 5,
    boxShadow: 'rgba(212, 190, 190, 0.1) 0px 1px 2px 2px'
  },
  blogImage: {
    maxWidth: 229,
    height: 180,
  },
  summary: {
    height: 108,
    width: '100%',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 6,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textarea: {
    resize: 'none',
    width: '95%',
    padding: 5,
    height: 100,
    marginTop: 15,
    borderRadius: 5,
    '&:focus': {
      outline: 'none'
    }
  },
  tooltip: {
    backgroundColor: process.env.REACT_APP_COLOR_SECONDARY,
    color: process.env.REACT_APP_COLOR_TEXT,
    boxShadow: theme.shadows[3],
    fontSize: 14,
  },
  arrow: {
    color: process.env.REACT_APP_COLOR_SECONDARY,
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
}));
const BlogCard = ({
  intl,
  data,
  isDeletable,
  blogsLibrary,
  blogReadRequest,
  blogUnreadRequest,
  error,
  blogFeedbackRequest,
  showLastFeatured = false,
}) => {

  /**
  * Feedback modal states and functions.
  */

  const [feedbackText, setFeedbackText] = useState('');
  const setFeedbackTextHandler = (e) => {
    setFeedbackText(e.target.value);
  };

  /**
   * Menu and menuitem functions.
   */

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isDeleting, setIsDeleting] = useState();
  const isDeletingHandler = () => {
    setAnchorEl(null);
    setIsDeleting(true);
  };

  const [isSubmittingAFeedback, setIsSubmittingAFeedback] = useState();
  const isSubmittingAFeedbackHandler = () => {
    setAnchorEl(null);
    setIsSubmittingAFeedback(true);
  };

  const [isMarkingAsRead, setIsMarkingAsRead] = useState(data.isread === 't');
  const classes = useStyles({ isread: isMarkingAsRead });

  const toggleMarkAsReadHandler = () => {
    if (localBlogRead) {
      blogUnreadRequest(data.id);
      setIsMarkingAsRead(false);
    } else {
      blogReadRequest(data.id);
      setIsMarkingAsRead(true);
    }
    setAnchorEl(null);
  };
  let localBlogRead = isMarkingAsRead || (isMarkingAsRead && !error) ? true : false;
  /**
   * Data for dropdown menu items.
   */
  const menuItemData = [
    {
      id: 1,
      name: 'EDUCATUION.REMOVE.THE.ARTICLE',
      method: isDeletingHandler,
      disabled: !isDeletable
    },
    {
      id: 2,
      name: 'EDUCATUION.SUBMIT.A.FEEDBACK',
      method: isSubmittingAFeedbackHandler,
      disabled: false
    },
    {
      id: 3,
      name: localBlogRead ? 'EDUCATUION.MARK.AS.UNREAD' : 'EDUCATUION.MARK.AS.READ',
      method: toggleMarkAsReadHandler,
      disabled: false
    },
  ];

  const feedbackHandler = () => {
    blogFeedbackRequest(data, feedbackText);
    setIsSubmittingAFeedback(false);
  };


  return (
    <>
      {error && error.message
        && (
          <Snackbar
            message={`${error.message}`}
            status="Error"
            closeAction={null}
          />
        )}
      <div className={`d-flex flex-wrap p-2 ${classes.container}`}>
        <div style={{ height: 45 }} className="d-flex justify-content-between mb-3">
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.arrow,
            }}
            title={data.title}
            placement="top"
            arrow
            leaveDelay={100}
          >
            <div
              color={localBlogRead ? '#404040' : '#0789C4'}
              className={classes.title}
              style={{ fontWeight: localBlogRead ? 500 : 600 }}
            >
              {data.title}
            </div>
          </Tooltip>

          <DropdownMenu
            handleClick={handleClick}
            anchorEl={anchorEl}
            handleClose={handleClose}
            menuItemData={menuItemData}
          />
        </div>
        {showLastFeatured && (
          <div
            className={classes.lastDate}
          >
            {data.from_dt ? `LAST FEATURED: ${moment(data.from_dt).format('MMM YY')}` : 'NOT FEATURED YET'}
          </div>
        )


        }

        <div className={`${classes.imageContainer} d-flex justify-content-center w-100 mb-3`}>
          <img
            src={data.image}
            className={classes.blogImage}
            alt={data.image}
          />
        </div>

        <Typography
          color="#404040"
          className={classes.summary}
          variant="educationBody"
          dangerouslySetInnerHTML={{ __html: data.summary }}
        />

        <Link
          to={`/education/blog-details/${data.id}`}
          style={{ width: '100%', marginTop: 10 }}
        >
          <CustomButton
            paddingVertical={4}
            variant={localBlogRead ? 'outlined' : 'contained'}
            label={<FormattedMessage id="EDUCATUION.READ.MORE" />}
            onClick={() => { }}
          />
        </Link>

        {/**
         * Remove the article modal.
         */}

        <EducationModal
          open={isDeleting}
          onClose={() => setIsDeleting()}
          icon={<img style={{ height: 80, width: 80 }} src={romoveArticle} alt="remove" />}
          title={intl.formatMessage({ id: 'EDUCATUION.REMOVE.THE.ARTICLE' })}
          content={(
            <Typography color="#404040" className="mt-2" variant="educationBody">
              {intl.formatMessage({
                id: blogsLibrary
                  ? 'EDUCATION.REMOVE.BLOG.LIBRARY.ARTICLE.WARNING'
                  : 'EDUCATION.REMOVE.BLOGS.ARTICLE.WARNING'
              })}
            </Typography>
          )}
          buttonApply={intl.formatMessage({ id: 'EDUCATION.REMOVE.THE.BLOG' })}
        />

        {/**
         * Submit a feedback modal.
         */}

        <EducationModal
          open={isSubmittingAFeedback}
          onClose={() => setIsSubmittingAFeedback()}
          title={data.title}
          content={<textarea onChange={setFeedbackTextHandler} className={classes.textarea} />}
          buttonApply={intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}
          disabled={!feedbackText.length}
          onClickHandler={() => feedbackHandler()}
        />
      </div>
    </>
  );
};

const mapStateToProps = (store) => ({
  error: store.educationCommon.blogReadError,
});


export default injectIntl(connect(mapStateToProps, actions)(BlogCard));

