import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Opacity, Typography } from '../../../components';
import { ErrorContainer, LoadingSpinner } from '../../../components/loading';
import { findCurrentMonthsDataIndexHandler } from '../../../../utils';
import { CASySettings, Main } from './components';
import * as actions from './redux/actions';
/**
 * 1. Double check correct answer for quiz and poll.
 * 2. add keys
 * 3. Modify initial state
 * 4. Feedback endpoint
 * 5. Date-labe -> date_label
 * 6. eslint errors
 * 7. Refactor
 * 8. Delete unneccessary commets
 * 9. Submit Feedback endpoint
 * 10. Modify modal buttons
 */

const WeWantedToKnow = ({
  intl,
  showCASySettings,
  district,
  getWWtKDataRequestAction,
  setCurrentQuestionAction,
  setShowCASySettingsAction,
  loading,
  error,
  WWtKs
}) => {
  useEffect(() => {
    if (district.district_id !== '#all-districts#') {
      const [projectId, districtId] = district.district_id.split('#$#');
      getWWtKDataRequestAction(projectId, districtId);
    }
  }, [district]);

  useEffect(() => {
    setCurrentQuestionAction(findCurrentMonthsDataIndexHandler(WWtKs));
    setShowCASySettingsAction(false);
  }, []);

  return (
    <Opacity slide>
      <Typography className="d-flex align-items-center mb-3" variant="h1">
        {intl.formatMessage({ id: 'EDUCATION.WE.WANTED.TO.KNOW' })}
      </Typography>

      {loading
        ? <LoadingSpinner height={350} />
        : error ? (
          <ErrorContainer
            small
            reportName="WWtK"
            height={350}
            error={error}
          />
        ) : showCASySettings ? <CASySettings /> : <Main />}
    </Opacity>
  );
};

const mapStateToProps = (store) => ({
  showCASySettings: store.WWtK.showCASySettings,
  district: store.common.district,
  loading: store.WWtK.loading,
  error: store.WWtK.error,
  WWtKs: store.WWtK.WWtKs,
});

export default injectIntl(connect(mapStateToProps, actions)(WeWantedToKnow));
