import React from 'react';
import clsx from 'clsx';

const Alert = ({ condition, responseMessage }) => {
  if (condition) {
    return (
      <div
        role="alert"
        className={clsx({
          'alert alert-info': responseMessage.status === 'success',
          'alert alert-danger': responseMessage.status === 'error' || responseMessage.status === 'fail',
          'overflow-auto': true
        })}
      >
        <div className="alert-text">{responseMessage.message}</div>
      </div>
    );
  }
  return null;
};

export default Alert;
