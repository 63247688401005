import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../redux/createReducer';
import initialState from './store';

const eventReducer = persistReducer(
  { storage, key: 'event', whitelist: [] },
  createReducer(initialState, {
    SET_EVENT_LOADING_ACTION: (state, { payload }) => Immutable({
      ...state,
      fetchState: payload,
      responseMessage: initialState.responseMessage,
    }),

    SET_EVENT_SNACKBAR_ACTION: (state, { payload }) => Immutable({
      ...state,
      snackbarState: payload,
      responseMessage: initialState.responseMessage,
    }),

    SET_EVENT_WIZARD_STAGE_ACTION: (state, { payload }) => Immutable({
      ...state,
      eventWizardStage: payload.stage,
    }),

    SET_EVENT_WIZARD_ID_ACTION: (state, { payload }) => Immutable({
      ...state,
      eventWizardId: payload.id,
    }),

    GET_EVENTS_LIST_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      eventList: payload.data || [],
      eventWizardStage: 0,
      fetchState: 'succeed-request',
    }),

    GET_EVENTS_LIST_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    CREATE_EVENT_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.message,
      responseStatus: 'success',
      eventList: [
        {
          drop_event_id: payload.id,
          event_admin_nm: payload.data.name,
          event_nm: payload.data.name,
          event_dates: payload.data.dates,
          event_details: payload.data.description,
          drop_event_loc_ids: payload.data.locations,
        },
        ...state.eventList
      ]
    }),

    CREATE_EVENT_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    EDIT_EVENT_RECEIVE: (state, { payload }) => (Immutable({
      ...state,
      responseMessage: payload.message,
      responseStatus: 'success',
      eventList: state.eventList.map((event) => (
        event.drop_event_id === payload.data.id
          ? {
            ...event,
            drop_event_id: payload.data.id,
            event_nm: payload.data.name,
            event_dates: payload.data.dates,
            event_details: payload.data.description,
            drop_event_loc_ids: payload.data.locations,
          }
          : event
      ))
    })),

    EDIT_EVENT_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    GET_EVENT_ADDRESS_SUGGESTIONS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      addressSuggestions: payload.data.results,
    }),

    GET_EVENT_ADDRESS_SUGGESTIONS_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    DELETE_EVENT_RECEIVE: (state, { payload }) => (Immutable({
      ...state,
      responseMessage: payload.data.message,
      eventList: state.eventList.filter((item) => item.drop_event_id !== payload.data.data)
    })),

    DELETE_EVENT_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),
  })
);

export default eventReducer;
