import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../../../redux/createReducer';
import initialState from './store';

const educationBlogsLibraryReducer = persistReducer(
  { storage, key: 'educationBlogsLibrary', whiteList: [] },
  createReducer(initialState, {
    GET_ALL_EDUCATION_BLOGS_LIBRARY_REQUEST: (state) => Immutable({
      ...state,
      loading: true,
      error: null
    }),
    GET_ALL_EDUCATION_BLOGS_LIBRARY_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      ...payload.data,
      loading: false,
    }),
    GET_ALL_EDUCATION_BLOGS_LIBRARY_FAIL: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      error: payload,
    }),
  })
);

export default educationBlogsLibraryReducer;
