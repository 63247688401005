import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '../../../components';

import {
  Portlet, PortletBody, PortletHeader,
} from '../../../../../_metronic/partials/content/Portlet';

const useStyles = makeStyles(() => ({
  tableHeader: {
    backgroundColor: '#3398fc',
    marginRight: 0,
    marginLeft: 0,
    marginTop: '0 !important',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: '8px 0',
    minHeight: 72,
  },
  tableHeaderFont: {
    color: '#FFF'
  },
  tableRow: {
    borderBottom: '1px solid lightgrey',
    borderRight: '1px solid lightgrey',
    borderLeft: '1px solid lightgrey',
    marginLeft: 0,
    marginRight: 0,
  },
  materialsTable: {
    borderRadius: '0 !important',
    boxShadow: 'none !important',
  },
  tableLabel: {
    borderBottom: 'none !important',
    borderRadius: '0 !important',
    padding: '0 !important',
  },
  tableBody: {
    padding: '0 !important'
  },
  pollTableRow: {
    borderBottom: 0
  },
  pollHideQuestion: {
    visibility: 'hidden'
  },

}));

const PollTable = ({
  data,
  thisYear,
  lastYear,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Portlet className={`h-100 position-relative ${classes.materialsTable}`}>
        <PortletHeader
          className={`${classes.tableLabel}`}
          title={(
            <div className="d-flex align-items-center">
              <Typography variant="h1" color="#6C7293">
                Poll
              </Typography>

            </div>
          )}
        />
        <PortletBody className={`${classes.tableBody}`}>
          <div className={`row d-flex align-items-center mt-4 ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableHeader}`}>
            <div className="col-3">
              <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                Statement
              </Typography>
            </div>
            <div className="col ml-3">
              <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                Answer
              </Typography>
            </div>
            <div className="col ml-2">
              <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                # of Responses
              </Typography>
            </div>
            <div className="col ml-2">
              <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                % {lastYear}
              </Typography>
            </div>
            <div className="col ml-2">
              <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                % {thisYear}
              </Typography>
            </div>
            <div className="col ml-2">
              <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                % National ({lastYear})
              </Typography>
            </div>
            <div className="col ml-2">
              <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                % National ({thisYear})
              </Typography>
            </div>
          </div>
          <div className={classes.dataTable}>
            {
              data.poll_questions.map((poll_question) => (
                <div>
                  {
                    poll_question.options.map((item, i, arr) => (
                      <div
                        key={`${poll_question.question + i}`}
                        className={`row d-flex align-items-center pt-4 pb-4 ${classes.border} ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableRow} ${i + 1 < arr.length ? classes.pollTableRow : ''}`}
                      >
                        <div className="col-3">
                          <Typography variant="bodySemiBold" className={i > 0 ? `${classes.pollHideQuestion}` : null}>
                            {poll_question.question}
                          </Typography>
                        </div>
                        <div className="col row d-flex align-items-center ml-3">
                          <Typography className="col-3" variant="bodySemiBold">
                            {item.text.toString()}
                          </Typography>
                        </div>
                        <div className="col row d-flex align-items-center ml-2">
                          <Typography variant="bodySemiBold" className={`col ${classes.ranking}`}>
                            {item.num_responses}
                          </Typography>
                        </div>
                        <div className="col row d-flex align-items-center ml-2">
                          <Typography className="col-3" variant="bodySemiBold">
                            {item.percentage_last_yr}

                          </Typography>
                        </div>
                        <div className="col row d-flex align-items-center ml-2">
                          <Typography variant="bodySemiBold" className={`col ${classes.ranking}`}>
                            {item.percentage_this_yr}
                          </Typography>
                        </div>
                        <div className="col row d-flex align-items-center ml-2">
                          <Typography className="col-3" variant="bodySemiBold">
                            {item.percentage_last_yr_national}
                          </Typography>
                        </div>
                        <div className="col row d-flex align-items-center ml-2">
                          <Typography variant="bodySemiBold" className={`col ${classes.ranking}`}>
                            {item.percentage_this_yr_national}
                          </Typography>
                        </div>
                      </div>

                    ))
                  }
                </div>
              ))
            }
          </div>
        </PortletBody>
      </Portlet>
    </div>
  );
};

export default injectIntl(PollTable);
