import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import actionTypes from './actionTypes';
import { getBlogDetailsData, blogRead, blogUnread, blogFeedback } from '../apis';

function* getBlogDetailsSaga({ payload: { blogId } }) {
  const response = yield call(getBlogDetailsData, { blogId });
  try {
    yield put(actions.getBlogDetailsReceive(response.data));
  } catch (error) {
    yield put(actions.getBlogDetailsFail(response.error.detail.data.message));
  }
}

function* blogReadSaga({ payload: { blogId } }) {
  const response = yield call(blogRead, { blogId });
  try {
    yield put(actions.blogReadReceive(response.data));
  } catch (error) {
    yield put(actions.blogReadFail(response.error));
  }
}

function* blogUnReadSaga({ payload: { blogId } }) {
  const response = yield call(blogUnread, { blogId });
  try {
    yield put(actions.blogUnreadReceive(response.data));
  } catch (error) {
    yield put(actions.blogUnreadFail(response.error));
  }
}

function* blogFeedbackSaga({ payload: { meta, feedback, type } }) {
  const response = yield call(blogFeedback, { meta, feedback, type });
  try {
    yield put(actions.blogUnreadReceive(response.data));
  } catch (error) {
    yield put(actions.blogUnreadFail(response.error));
  }
}

function* EducationCommonSagas() {
  yield takeLatest(actionTypes.GET_BLOG_DETAILS_REQUEST, getBlogDetailsSaga);
  yield takeLatest(actionTypes.BLOG_READ_REQUEST, blogReadSaga);
  yield takeLatest(actionTypes.BLOG_UNREAD_REQUEST, blogUnReadSaga);
  yield takeLatest(actionTypes.BLOG_FEEDBACK_REQUEST, blogFeedbackSaga);

}

export default EducationCommonSagas;
