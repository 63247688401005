/* eslint-disable import/prefer-default-export */
import cts from '../../crud/cts';

/**
 * TODO: Insert comment
 */

export const getWasteTypes = async ({ locality }) => cts(
  'post',
  'waste-type/get-all',
  {
    cms_locality_id: locality,
  }
);

/**
 * TODO: Insert comment
 */

export const createWasteType = async ({ locality, name }) => cts(
  'post',
  'waste-type/add',
  {
    cms_locality_id: locality,
    waste_type_admin_nm: name,
  }
);

/**
 * TODO: Insert comment
 */

export const deleteWasteType = async ({ locality, wasteTypeId }) => cts(
  'post',
  'waste-type/remove',
  {
    cms_locality_id: locality,
    waste_type_id: wasteTypeId,
  }
);
