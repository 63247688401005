import { all } from 'redux-saga/effects';

import loginSagas from '../Login/redux/sagas';
import loginAsSagas from '../LoginAs/redux/sagas';
import mfaSagas from '../MFA/redux/sagas';
import otpSagas from '../OTP/redux/sagas';
import forgotPasswordSagas from '../ForgotPassword/redux/sagas';
import resetPasswordSagas from '../ResetPassword/redux/sagas';
import logoutSagas from '../Logout/redux/sagas';
import profileSagas from '../Profile/redux/sagas';

function* authSagas() {
  yield all([
    loginSagas(),
    loginAsSagas(),
    mfaSagas(),
    otpSagas(),
    forgotPasswordSagas(),
    resetPasswordSagas(),
    logoutSagas(),
    profileSagas(),
  ]);
}

export default authSagas;
