import React, { useEffect } from 'react';
import moment from 'moment';
import { injectIntl } from 'react-intl';



import { connect } from 'react-redux';

import * as actions from '../../../Dashboard/redux/actions';


import Main from './components/Main';



const RecyclingForKids = ({
  csm,
  getDashboardDataRequestAction,

}) => {

  useEffect(() => {
    if (!csm) {
      const fromDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
      const toDate = moment().format('YYYY-MM-DD');
      getDashboardDataRequestAction(fromDate, toDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <>
      <Main />
    </>
  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  loading: store.RecyclingForKids.loading,
  error: store.RecyclingForKids.error,
  data: store.RecyclingForKids,
  isFirstLoading: store.dashboard.isFirstLoading,
  isUserPro: store.common.isUserPro,
  stage: store.common.district.stage,
  csm: store.dashboard.csm,

});

export default injectIntl(connect(mapStateToProps, actions)(RecyclingForKids));

