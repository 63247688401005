import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { CustomButton, Typography } from '../../components';
import { Portlet, PortletHeader } from '../../../../_metronic/partials/content/Portlet';
import ProfileImage from '../../Auth/Profile/components/ProfileImage';
import handIcon from '../../../assets/images/hand-dashboard.png';

const useStyles = makeStyles({
  handIcon: {
    width: 40,
    height: 40,
    border: 'none',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    transform: 'translateX(25px)'
  },
  letterSpacing: {
    letterSpacing: '1px'
  },
  emailText: {
    color: process.env.REACT_APP_COLOR_PRIMARY,
    textDecoration: 'underline'
  },
});

const ProfileDropdown = ({
  intl,
  isCsm = false, // resuable csm and profile info card component
  csm,
  user_info
}) => {
  const classes = useStyles();

  return (
    <Portlet>
      <PortletHeader title={(
        <div className="d-flex align-items-center justify-space-between py-2">
          <Typography className="mr-4" variant="h1">
            {intl.formatMessage({
              id: isCsm
                ? 'DASHBOARD.USER.INFO.CARD.TITLE' : 'DASHBOARD.WELCOME.BACK'
            })}
            {!isCsm && `, ${user_info.first_name} ${user_info.last_name}!`}
          </Typography>
          {!isCsm && (
            <div
              role="img"
              title="hand"
              className={classes.handIcon}
              style={{ backgroundImage: `url(${handIcon})` }}
            />
          )}
        </div>
      )}
      />
      <div className="py-4 pl-4">
        <div className="row ml-2">
          <ProfileImage isCsm={isCsm} width={70} height={70} variant="numberSmall" />
          <div className="col d-flex flex-column justify-content-center ml-2">
            <Typography className={classes.letterSpacing} variant="h2">
              {!isCsm && `${user_info.first_name} ${user_info.last_name}`}
              {isCsm && `${csm?.name}`}
            </Typography>
            {!isCsm && (
              <Typography className={classes.letterSpacing} variant="subText">
                {user_info.job_title?.toUpperCase()}
              </Typography>
            )}
            {isCsm && (
              <div className="mt-1">
                T:
                {' '}
                <Typography component="span" variant="body">
                  {`${csm?.phone} ext.${csm?.phone_ext}`}
                </Typography>
              </div>
            )}
            <div className="mt-1">
              E:
              {' '}
              <Typography component="span" variant="body" className={classes.emailText}>
                {isCsm ? `${csm?.email}` : user_info.email}
              </Typography>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end w-100 mt-4 pt-1 pr-4">
          {!isCsm && (
            <Link to="/profile">
              <CustomButton
                size="large"
                variant="contained"
                fontSize={14}
                paddingVertical={2}
                label={(
                  <FormattedMessage
                    id="DASHBOARD.USER.INFO.CARD.ACCOUNT.SETTINGS"
                  />
                )}
              />
            </Link>
          )}
          {isCsm && (
            <a target="_blank" href={csm?.calendly_link} rel="noreferrer">
              <CustomButton
                size="large"
                variant="contained"
                fontSize={14}
                paddingVertical={2}
                label={(
                  <FormattedMessage
                    id="DASHBOARD.USER.INFO.CARD.BOOK.AN.APPOINTMENT"
                  />
                )}
              />
            </a>
          )}
        </div>
      </div>
    </Portlet>

  );
};

const mapStateToProps = (store) => ({
  csm: store.dashboard.csm,
  user_info: store.auth.user_info,
});

export default injectIntl(connect(mapStateToProps, null)(ProfileDropdown));
