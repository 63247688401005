import actionTypes from './actionTypes';

// Set district
export const setDistrict = (district) => ({
  type: actionTypes.SET_DASHBOARD_DISTRICT_ACTION,
  payload: district
});

// Dashboard actions for setting from date
export const setFromDateAction = (fromDate) => ({
  type: actionTypes.SET_DASHBOARD_FROM_DATE_ACTION,
  payload: fromDate
});

// Dashboard actions for setting to date
export const setToDateAction = (toDate) => ({
  type: actionTypes.SET_DASHBOARD_TO_DATE_ACTION,
  payload: toDate
});

// Get Report Dashboard data
export const getReportDashboardRequest = (payload) => ({
  type: actionTypes.GET_REPORT_DASHBOARD_REQUEST,
  payload
});

// Report Dashboard receive
export const getReportDashboardReceive = (payload) => ({
  type: actionTypes.GET_REPORT_DASHBOARD_RECEIVE,
  payload
});

// Report Dashboard fail
export const getReportDashboardFail = (payload) => ({
  type: actionTypes.GET_REPORT_DASHBOARD_FAIL,
  payload
});

// Get Report Dashboard data details
export const getReportDashboardDetailsRequest = (payload) => ({
  type: actionTypes.GET_REPORT_DASHBOARD_DETAILS_REQUEST,
  payload
});


// Report Dashboard receive
export const getReportDashboardDetailsReceive = (payload) => ({
  type: actionTypes.GET_REPORT_DASHBOARD_DETAILS_RECEIVE,
  payload
});

// Report Dashboard fail
export const getReportDashboardDetailsFail = (payload) => ({
  type: actionTypes.GET_REPORT_DASHBOARD_DETAILS_FAIL,
  payload
});


// Get Report Dashboard data details
export const getMaterialDetailsRequest = (payload) => ({
  type: actionTypes.GET_MATERIAL_DETAILS_REQUEST,
  payload
});


// Report Dashboard receive
export const getMaterialDetailsReceive = (payload) => ({
  type: actionTypes.GET_MATERIAL_DETAILS_RECEIVE,
  payload
});

// Report Dashboard fail
export const getMaterialDetailsFail = (payload) => ({
  type: actionTypes.GET_MATERIAL_DETAILS_FAIL,
  payload
});



// Get Report Dashboard data details
export const getMajorCampaignDetailsRequest = (payload) => ({
  type: actionTypes.GET_MAJOR_CAMPAIGN_DETAILS_REQUEST,
  payload
});


// Report Dashboard receive
export const getMajorCampaignDetailsReceive = (payload) => ({
  type: actionTypes.GET_MAJOR_CAMPAIGN_DETAILS_RECEIVE,
  payload
});

// Report Dashboard fail
export const getMajorCampaignDetailsFail = (payload) => ({
  type: actionTypes.GET_MAJOR_CAMPAIGN_DETAILS_FAIL,
  payload
});



// Get Report Dashboard data details
export const getBulkPickupDetailsRequest = (payload) => ({
  type: actionTypes.GET_BULK_PICKUP_DETAILS_REQUEST,
  payload
});


// Report Dashboard receive
export const getBulkPickupDetailsReceive = (payload) => ({
  type: actionTypes.GET_BULK_PICKUP_DETAILS_RECEIVE,
  payload
});

// Report Dashboard fail
export const getBulkPickupDetailsFail = (payload) => ({
  type: actionTypes.GET_BULK_PICKUP_DETAILS_FAIL,
  payload
});

// Get Report BP Dashboard data details
export const getBulkPickupDashboardRequest = (payload) => ({
  type: actionTypes.GET_BULK_PICKUP_DASHBOARD_REQUEST,
  payload
});


// Report BP Dashboard receive
export const getBulkPickupDashboardReceive = (payload) => ({
  type: actionTypes.GET_BULK_PICKUP_DASHBOARD_RECEIVE,
  payload
});

// Report BP Dashboard fail
export const getBulkPickupDashboardFail = (payload) => ({
  type: actionTypes.GET_BULK_PICKUP_DASHBOARD_FAIL,
  payload
});

// Get WGW Material Report data details
export const getWGWMaterialDetailsRequest = (payload) => ({
  type: actionTypes.GET_WGW_MATERIAL_DETAILS_REQUEST,
  payload
});


// Get WGW Material Report receive
export const getWGWMaterialDetailsReceive = (payload) => ({
  type: actionTypes.GET_WGW_MATERIAL_DETAILS_RECEIVE,
  payload
});

// Get WGW Material Report fail
export const getWGWMaterialDetailsFail = (payload) => ({
  type: actionTypes.GET_WGW_MATERIAL_DETAILS_FAIL,
  payload
});
