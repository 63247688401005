import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import Typography from './Typography';

const useStyles = makeStyles({
  container: {
    borderRadius: 8,
    margin: '20px 0',
    width: '100%',
    padding: '15px 30px'
  },
  text: {
    color: 'white'
  }
});

const Status = ({
  children,
  color
}) => {
  const { container, text } = useStyles();

  return (
    <div
      className={container}
      style={{ backgroundColor: color || process.env.REACT_APP_COLOR_BACKGROUND_FILL }}
    >
      <Typography variant="bodySemiBold" className={text}>
        {children}
      </Typography>
    </div>
  );
};

export default injectIntl(Status);
