import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { addCommasToNumber } from '../../../../utils';
import { InfoTooltip, IconButton, Typography } from '../../../components';
import { ExpandIcon, CollapseIcon } from '../../../../assets/icons';

const useStyles = makeStyles((theme) => ({
  border: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
  },
  value: {
    color: '#3e95cd'
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: '#3e95cd'
  },
  icon: {
    width: 30,
    height: 30
  },
  smallerThanMdHide: {
    [theme.breakpoints.down(1200)]: {
      display: 'none !important'
    }
  },
  smallerThanMdShow: {
    [theme.breakpoints.up(1200)]: {
      display: 'none !important'
    },
  },
}));

const UserReportMobileDetail = ({
  intl,
  data,
  isDashboard,
  expanded,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.border}>
      <div className="row d-flex align-items-center pb-4 pr-4 pl-4">
        <div className={`col-1 mr-3 mr-lg-0 ${classes.smallerThanMdHide}`}>
          <div className={classes.circle} />
        </div>
        <div className={`col-10 col-lg ${classes.smallerThanMdHide}`}>
          <Typography variant="h2">
            {intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.MOBILE.TITLE' })}
            <InfoTooltip tooltip={intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.MOBILE.DESCRIPTION' })} />
          </Typography>
        </div>
        <div className={`col-11 col-lg-4 mt-3 mt-lg-0 ${classes.smallerThanMdHide}`}>
          <Typography className={classes.value} variant="numberLarge">
            {addCommasToNumber(data.total)}
          </Typography>
        </div>
        {!isDashboard
        && (
        <div className={`col-1 mt-3 mt-lg-0 ${classes.smallerThanMdHide}`}>
          {Boolean(data && (data.android || data.iOS)) && (
          <>
            {expanded
              ? (
                <IconButton onClick={onClick}>
                  <CollapseIcon className={classes.icon} />
                </IconButton>
              )
              : (
                <IconButton onClick={onClick}>
                  <ExpandIcon className={classes.icon} />
                </IconButton>
              )}
          </>
          )}
        </div>
        )}

        <div className={`d-flex w-100 p-0 ${classes.smallerThanMdShow}`}>
          <div className={`mr-5 ${classes.circle}`} />
          <div className="row p-0 w-100 justify-content-between">
            <div className="d-flex flex-column justify-content-start">
              <div className="row pl-3">
                <Typography variant="h2">
                  {intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.MOBILE.TITLE' })}
                  <InfoTooltip tooltip={intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.MOBILE.DESCRIPTION' })} />
                </Typography>
              </div>
              <Typography className={`${classes.value}`} variant="numberLarge">
                {addCommasToNumber(data.total)}
              </Typography>
            </div>
            {!isDashboard
        && (
        <div className="d-flex mt-3">
          {Boolean(data && (data.detail || data.iOS)) && (
          <>
            { expanded
              ? (
                <IconButton onClick={onClick}>
                  <CollapseIcon className={classes.icon} />
                </IconButton>
              )
              : (
                <IconButton onClick={onClick}>
                  <ExpandIcon className={classes.icon} />
                </IconButton>
              )}
          </>
          )}
        </div>
        )}
          </div>
        </div>

      </div>
      {expanded && (
        <>
          <div className={`row d-flex justify-content-end ${classes.smallerThanMdHide} `}>
            <div className={`col-11 row d-flex align-items-start pb-4 pr-4 pl-4 ${classes.border}`}>
              <div className="col-6 pl-0">
                <Typography variant="h2">
                  {intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.MOBILE.IOS' })}
                </Typography>
              </div>
              <div className="col ml-4">
                {!Array.isArray(data.iOS)
                  ? (
                    <Typography variant="h2">
                      {addCommasToNumber(data.iOS)}
                    </Typography>
                  )
                  : (
                    <>
                      {data.iOS.map((item) => (
                        <div key={item.title} className="row mb-2">
                          <div className="col-11 col-lg-7 mt-3 mt-lg-0 pl-0">
                            <Typography variant="body">
                              {item.district_name}
                              {':'}
                            </Typography>
                          </div>
                          <div className="col ml-4 mt-3 mt-lg-0">
                            <Typography variant="bodySemiBold">
                              {addCommasToNumber(item.value)}
                            </Typography>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
              </div>
            </div>

            <div className="col-11 row d-flex align-items-start pb-4 pr-4 pl-4 mt-4">
              <div className="col-6 pl-0">
                <Typography variant="h2">
                  {intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.MOBILE.ANDROID' })}
                </Typography>
              </div>
              <div className="col ml-4">
                {!Array.isArray(data.android)
                  ? (
                    <Typography variant="h2">
                      {addCommasToNumber(data.android)}
                    </Typography>
                  )
                  : (
                    <>
                      {data.android.map((item) => (
                        <div key={item.title} className="row mb-2">
                          <div className="col-11 col-lg-7 mt-3 mt-lg-0 pl-0">
                            <Typography variant="body">
                              {item.district_name}
                              {':'}
                            </Typography>
                          </div>
                          <div className="col ml-4 mt-3 mt-lg-0">
                            <Typography variant="bodySemiBold">
                              {addCommasToNumber(item.value)}
                            </Typography>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
              </div>
            </div>
          </div>

          <div className={`row d-flex justify-content-center ${classes.smallerThanMdShow}`}>
            <div className={`col-11 row d-flex align-items-start pb-4 pr-4 pl-4 ${classes.border}`}>
              <div className="col-12 pl-0">
                <Typography variant="h2">
                  {intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.MOBILE.IOS' })}
                </Typography>
              </div>
              <div className="col">
                {!Array.isArray(data.iOS)
                  ? (
                    <Typography variant="h2">
                      {addCommasToNumber(data.iOS)}
                    </Typography>
                  )
                  : (
                    <>
                      {data.iOS.map((item) => (
                        <div key={item.title} className="row mb-2 p-0">
                          <div className="col mt-3 p-0">
                            <Typography variant="body">
                              {item.district_name}
                              {':'}
                            </Typography>
                          </div>
                          <div className="col ml-4">
                            <Typography variant="bodySemiBold">
                              {addCommasToNumber(item.value)}
                            </Typography>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
              </div>
            </div>

            <div className="col-11 row d-flex align-items-start pb-4 pr-4 pl-4 mt-4">
              <div className="col-12 pl-0">
                <Typography variant="h2">
                  {intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.MOBILE.ANDROID' })}
                </Typography>
              </div>
              <div className="col">
                {!Array.isArray(data.android)
                  ? (
                    <Typography variant="h2">
                      {addCommasToNumber(data.android)}
                    </Typography>
                  )
                  : (
                    <>
                      {data.android.map((item) => (
                        <div key={item.title} className="row mb-2 p-0">
                          <div className="col mt-3 p-0">
                            <Typography variant="body">
                              {item.district_name}
                              {':'}
                            </Typography>
                          </div>
                          <div className="col ml-4">
                            <Typography variant="bodySemiBold">
                              {addCommasToNumber(item.value)}
                            </Typography>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
              </div>
            </div>
          </div>

        </>
      )}
    </div>
  );
};

export default injectIntl(UserReportMobileDetail);
