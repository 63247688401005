import cts from '../../../crud/cts';

// const getBlogDetailsData = ({
//   blogId
// }) => cts(
//   'get',
//   `blog?blog_id=${blogId}`
// );

// export { getBlogDetailsData, getBlogDetailsData as default };

/**
 * Blog API which gets the blog details data
 */
export const getBlogDetailsData = async ({ blogId }) => cts(
  'get',
  `blog?blog_id=${blogId}`
);

/**
 * Blog API to mark a blog post a read
 */
export const blogRead = async ({ blogId }) => cts(
  'post',
  `blog-read?blog_id=${blogId}`,
  {
    blog_id: blogId,
  }
);

/**
 * Blog API to unead a blog post
 */
export const blogUnread = async ({ blogId }) => cts(
  'post',
  `blog-unread?blog_id=${blogId}`,
  {
    blog_id: blogId,
  }
);

/**
 * Blog API to leave feedback
 */
export const blogFeedback = async ({ meta, feedback, type }) => cts(
  'post',
  'feedback',
  {
    type,
    meta,
    feedback
  }
);
