import * as React from 'react';

const CorrectAnswerIcon = ({ width = 15, height = 15, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    className={className}
  >
    <path
      d="M10 0A10 10 0 000 10a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0010 0zM8.958 13.761l-3.75-3.6 1.163-1.2 2.565 2.448 5.088-5.173 1.184 1.174z"
      fill={process.env.REACT_APP_COLOR_POSITIVE}
    />
  </svg>
);
export default CorrectAnswerIcon;
