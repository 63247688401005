import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '../../../components';
import ColorLegend from './ColorLegend';

const useStyles = makeStyles(() => ({
  graph: {
    display: 'inline-flex',
    flexDirection: 'column'
  },
  graph__2items: {
    marginRight: 20,
    marginLeft: 20,
  },
  graph__wrapper: {
    display: 'flex',
  },
  graph__item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 200,
    margin: '0 8px'
  },
  graph__number: {
    marginBottom: 6,
    fontSize: 12,
    fontSeight: 600,
    fontFamily: 'sans-serif'
  },
  graph__pillar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    maxHeight: '100%',
    width: '100%',
    borderRadius: 13
  },
  graph__ball: {
    display: 'block',
    position: 'relative',
    top: 1,
    height: 19,
    width: 19,
    backgroundColor: 'white',
    borderRadius: '50%'
  },
  graph__pillar_lastYear: {
    backgroundColor: '#79cbc4'
  },
  graph__pillar_thisYear: {
    backgroundColor: '#3398fc'
  },
  graph__pillar_national: {
    backgroundColor: '#1487c0'
  },
  layout__graphWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  layout__topLeft: {
    width: '40%'
  },
  layout__topRight: {
    width: '60%',
    marginRight: 25
  },
  layout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  layout__top: {
    display: 'flex',
    width: '100%',
    marginBottom: 40
  },
  layout__bottom: {
    display: 'flex',
    width: '100%',
    marginBottom: 40
  },
  materialsItemTitle: {
    textAlign: 'center',
    maxWidth: 100,
    fontWeight: 500,
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },

}));

const CorrectSummary = ({
  data,
  intl,
  thisYear,
  lastYear,
}) => {
  const classes = useStyles();

  return (
    <>
      <div>
        <Typography className="mr-4" variant="h1">
          {intl.formatMessage({ id: 'REPORT.NATIONAL_SURVEY.SUMMARY.HEADER' })}
        </Typography>
        <ColorLegend displayNatonal thisYear={thisYear} lastYear={lastYear} />
      </div>

      <div className={`${classes.layout__graphWrapper}`}>
        {
          Object.keys(data.correct_answer).map((item, i) => (
            <div className={`${classes.graph}`}>
              <div className={`${classes.graph__wrapper}`}>
                <div className={`${classes.graph__item}`}>
                  <div className={`${classes.graph__number}`}>
                    {data.correct_answer[item].last_yr}
                    %
                  </div>
                  <div className={`${classes.graph__pillar} ${classes.graph__pillar_lastYear}`} style={{ height: `${data.correct_answer[item].last_yr}%` }}>
                    <div className={`${classes.graph__ball}`} />
                  </div>
                </div>
                <div key={`${item + i}`} className={`${classes.graph__item}`}>
                  <div className={`${classes.graph__number}`}>
                    {data.correct_answer[item].this_yr}
                    %
                  </div>
                  <div className={`${classes.graph__pillar} ${classes.graph__pillar_thisYear}`} style={{ height: `${data.correct_answer[item].this_yr}%` }}>
                    <div className={`${classes.graph__ball}`} />
                  </div>
                </div>
                <div className={`${classes.graph__item}`}>
                  <div className={`${classes.graph__number}`}>
                    {data.correct_answer[item].national}
                    %
                  </div>
                  <div className={`${classes.graph__pillar} ${classes.graph__pillar_national}`} style={{ height: `${data.correct_answer[item].national}%` }}>
                    <div className={`${classes.graph__ball}`} />
                  </div>
                </div>

              </div>
              <br />
              <div className={`${classes.materialsItemTitle}`}>
                {item}
                {' '}
                Materials
              </div>
            </div>
          ))
        }
      </div>
    </>

  );
};

export default injectIntl(CorrectSummary);
