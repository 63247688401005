import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';

const TextField = ({
  label,
  type = 'text',
  className,
  value,
  onChange,
  isMulti,
  defaultRow = 1,
  maxRow = 1,
  isDisable,
  invalidText,
  onBlur,
  onFocus,
  inputRef,
  invalidTextBackgroundColor = 'transparent',
  onKeyDown = true,
  hasClear = false
}) => {
  const useStyles = makeStyles(() => ({
    root: {
      position: 'relative',
      backgroundColor: process.env.REACT_APP_COLOR_SECONDARY,
      margin: 0,
      '& label': {
        transform: 'translate(14px, -6px) scale(0.75)',
        backgroundColor: process.env.REACT_APP_COLOR_SECONDARY,
        zIndex: 2
      },
      '& legend': {
        maxWidth: '1000px',
        transition: 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms'
      }
    },
    helperText: {
      width: '100%',
      marginRight: 0,
      marginLeft: 0,
      backgroundColor: invalidTextBackgroundColor
    }
  }));

  const [showError, setShowError] = useState(false);
  const classes = useStyles();

  return (
    <MuiTextField
      label={label}
      type={type}
      className={`w-100 ${className}`}
      classes={{
        root: classes.root
      }}
      inputRef={inputRef}
      value={value}
      margin="normal"
      variant="outlined"
      rows={defaultRow}
      multiline={isMulti}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && onKeyDown) {
          event.target.blur();
        }
      }}
      rowsMax={maxRow}
      onChange={(event) => {
        event.stopPropagation();
        onChange(event.target.value)
      }}
      onFocus={() => {
        setShowError(true);
        if (onFocus) {
          onFocus();
        }
      }}
      onBlur={onBlur || null}
      error={showError && !!invalidText}
      helperText={showError && !!invalidText ? invalidText : null}
      FormHelperTextProps={{
        className: classes.helperText
      }}
      disabled={isDisable}
      inputProps={hasClear ? { type: 'search' } : {}}
    />
  );
};
export default TextField;
