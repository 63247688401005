import React, { useEffect } from 'react';
import { Chart } from 'chart.js';
import { makeStyles } from '@material-ui/core/styles';
import { ChartLoading } from '../../components/loading';

import { Typography } from '../../components';

const useStyles = makeStyles((theme) => ({
  container: {
    height: ({ isDashboardChart }) => (isDashboardChart ? 250 : 'auto')
  },
  valueDescription: {
    width: ({ isDashboardChart }) => (isDashboardChart ? '100%' : '20%'),
    fontySize: '11',
    fontWeight: '500',
  },
  canvas: {
    zIndex: 1,
  },
  value: {
    width: ({ isDashboardChart }) => (isDashboardChart ? '100%' : '24%'),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  mediumValue: {
    width: ({ isDashboardChart }) => (isDashboardChart ? '100%' : '27%'),
    fontSize: ({ isDashboardChart }) => (isDashboardChart ? '21px' : '30px'),
  },
  smallValue: {
    width: ({ isDashboardChart }) => (isDashboardChart ? '100%' : '27%'),
    fontSize: '23px',
    [theme.breakpoints.between('1200', '1300')]: {
      fontSize: '20px',
    }
  }
}));

const DoughnutChart = ({
  total = '',
  insideText = 'Text',
  labels = ['Label 1', 'Label 2', 'Label 3', 'Label 4'],
  values = [15, 20, 22, 30],
  colors = ['#3e95cd', '#2e75cf', '#3cbacd', '#2a95ff'],
  isLoading,
  isDashboardChart = false,
  chartID = 'myChart'
}) => {
  const classes = useStyles({ isDashboardChart });

  const chartOptions = {
    type: 'doughnut',
    data: {
      labels,
      datasets: [{
        data: values,
        backgroundColor: colors,
        borderColor: colors
      }],
    },
    options: {
      cutoutPercentage: 65,
      maintainAspectRatio: !isDashboardChart,
      tooltips: {
        intersect: true,
        mode: 'nearest',
        xPadding: 10,
        yPadding: 10,
        caretPadding: 10,
      },
      legend: {
        display: false
      },
      responsive: true,
      animation: {
        duration: 2500,
      }
    }
  };

  useEffect(() => {
    if (!isLoading) {
      const promisedDeliveryChart = new Chart(document.getElementById(chartID), chartOptions);

      return () => {
        promisedDeliveryChart.destroy();
      };
    }
  }, [values]);

  return (
    <div className={`position-relative d-flex flex-row-reverse align-items-center justify-content-center ${classes.container}`}>
      { isLoading
        ? <ChartLoading />
        : (
          <>
            <canvas id={chartID} className={classes.canvas} />
            <div className="position-absolute w-100 top-0 d-flex flex-column">
              <Typography className={`text-center m-auto ${classes.value} ${total.length > 5 ? classes.mediumValue : null} ${total.length > 8 ? classes.smallValue : null}`} variant="numberSmall">
                {total}
              </Typography>
              <Typography className={`text-center m-auto ${classes.valueDescription}`} as variant="h2">
                {insideText}
              </Typography>
            </div>
          </>
        )}
    </div>
  );
};

export default DoughnutChart;
