import React from 'react';
import { connect } from 'react-redux';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  FacebookIcon,
  EmailIcon,
  TwitterIcon,
  LinkedinIcon,
  TwitterShareButton,
} from 'react-share';
import { Portlet } from '../../../../_metronic/partials/content/Portlet';
import { MunicipalitySelector, Typography } from '../../components';
import * as actions from '../../redux/actions';
import { socialMediaAssestsLink } from '../apis';

const NationalSurvey = ({
  district, setDistrictAction, municipalities
}) => {
  const { district_id: districtData } = district.district_id === '#all-districts#' ? municipalities[0] : district;
  const [projectId, districtId] = districtData.split('#$#');

  const encodedNSQueryString = btoa(`${projectId}!${districtId}`);

  const url = `https://s.recyclecoach.com/?q=${encodedNSQueryString}`;

  return (
    <Portlet className="p-4">
      <Typography
        style={{ fontSize: '13px' }}
        variant="bodySemibold"
        className="mb-4"
      >
        Our Annual Recycling Survey is live now until October 31<sup>st </sup>! You can find <u>social media assets</u> in the Marketing Support tab to post on your channels. Please share this link with your residents to encourage them to participate:
      </Typography>

      <div className="d-flex align-items-center mt-2">
        {municipalities.length > 1
          && (
            <div style={{ width: 350 }}>
              <MunicipalitySelector
                title="Reporting District"
                onChange={setDistrictAction}
                value={district.district_id === '#all-districts#' ? municipalities[0] : district}
                hasAllDistrictOption={false}
                isCountyEnable={false}
                disbleSingleCountyDropdown
              />
            </div>
          )}
        <div className={municipalities.length > 1 && 'ml-4'}>
          <Typography variant="h2">
            National Survey Link:
          </Typography>
          <a target="_blank" href={url} rel="noreferrer">
            {url}
          </a>
        </div>

        <div className="ml-4 mb-2">
          <Typography variant="h2">
            Share
          </Typography>
          <div className="d-flex">
            <FacebookShareButton style={{ marginRight: '3px' }} url={url}>
              <FacebookIcon size={28} round />
            </FacebookShareButton>
            <TwitterShareButton style={{ marginRight: '3px' }} url={url}>
              <TwitterIcon size={28} round />
            </TwitterShareButton>
            <LinkedinShareButton style={{ marginRight: '3px' }} url={url}>
              <LinkedinIcon size={28} round />
            </LinkedinShareButton>
            <EmailShareButton style={{ marginRight: '3px' }} url={url}>
              <EmailIcon size={28} round />
            </EmailShareButton>
          </div>
        </div>
      </div>

    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  municipalities: store.common.municipalities,
});

export default connect(mapStateToProps, actions)(NationalSurvey);
