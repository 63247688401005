import MockAdapter from 'axios-mock-adapter';

import {
  // specificCollections,
  // displayMaterial,
  // reminderList,
  // usersList,
  // campaignList,
  // userReport,
  // interactionReport,
  // materialReport,
  // newSubscribersReport,
  // pageViewsReport,
  // problemReport,
  // campaignReportData,
  // DetailCampaignReportData,
  CategoryData,
} from './mockData';

const TestEnv = process.env.REACT_APP_TEST_ENV ? process.env.REACT_APP_TEST_ENV : false;

const MockConfigs = {
  delayResponse: 1500
};

/**
 * WithMock wrapper
 *
 * Wrap all the apis to mock instance
 * @param {object} axiosInstance Axios instance
 */
const withMock = (axiosInstance, method) => async (...args) => {
  try {
    const validArgs = args.filter((arg) => arg !== null);
    if (validArgs.length < axiosInstance.length) {
      throw new TypeError('Argument cannot be null or undefined.');
    }
    if (TestEnv === 'true') {
      applyMockApis(new MockAdapter(axiosInstance, MockConfigs));
    }

    const { data, status } = await axiosInstance[method](...validArgs);

    return { data, status, error: null };
  } catch (error) {
    return { data: null, error, detail: error.response };
  }
};

/**
 * Apply request mocks with fake apis and responses
 *
 * @param {object} instance MockAdapter instance
 */
const applyMockApis = (instance) => {
  instance
    // .onPost('reminders/get-collection').reply(() => [200, { ...specificCollections }])
    // .onPost('reminders/get-materials')
    // .reply(() => [200, { ...displayMaterial }])
    // .onPost('reminders/get-all')
    // .reply(() => [200, reminderList])
    // .onPost('users/get-all')
    // .reply(() => [200, { ...usersList }])

    // .onPost('campaigns/get-all')
    // .reply(200, campaignList)

    // .onPost('reminders/get-district-collections')
    // .reply(() => [200, { ...specificCollections }])

    // .onPost('reminders/get-materials')
    // .reply(() => [200, { ...displayMaterial }])

    // .onPost('reminders/get-all')
    // .reply(() => [200, reminderList])

    // .onPost('users/get-all')
    // .reply(() => [200, usersList])

    // .onPost('users/get-all')
    // .reply(() => [200, { ...usersList }])

    // .onGet('campaigns/get-all')
    // .reply(() => [200, { ...campaignList }])

    // .onGet('/reports/users?municipality=&from=2020-06-09&to=2020-07-09')
    // .reply(() => ([200, { message: 'OK', status: 'success', data: userReport }]))

    // .onGet(/\/reports\/interactions\?municipality=.*&from=.*&to=.*/)
    // .reply(() => ([200, { message: 'OK', status: 'success', data: interactionReport }]))

    // .onGet(/\/reports\/materials\?municipality=.*&from=.*&to=.*/)
    // .reply(() => ([200, { message: 'OK', status: 'success', data: materialReport }]))

    // .onGet(/\/reports\/new-subscribers\?municipality=.*&from=.*&to=.*/)
    // .reply(() => ([200, { message: 'OK', status: 'success', data: newSubscribersReport }]))

    // .onGet(/\/reports\/page-views\?municipality=.*&from=.*&to=.*/)
    // .reply(() => ([200, { message: 'OK', status: 'success', data: pageViewsReport }]))

    // .onGet(/\/reports\/problems\?municipality=.*&from=.*&to=.*/)
    // .reply(() => ([200, { message: 'OK', status: 'success', data: problemReport }]))

    // .onGet(/\/reports\/campaigns\?municipality=.*&from=.*&to=.*/)
    // .reply(() => ([200, { message: 'OK', status: 'success', data: campaignReportData }]))

    // .onGet(/\/reports\/detail-campaigns\?id=.*&from=.*&to=.*/)
    // .reply(() => ([200, { message: 'OK', status: 'success', data: DetailCampaignReportData }]))

    .onPost('cms/get-all')
    .reply(() => ([200, { message: 'OK', status: 'success', data: CategoryData }]))

    .onAny()
    .passThrough();

  return instance;
};

export default withMock;
