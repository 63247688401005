const actionTypes = {
  // Discovery zone report actionTypes for setting loading status
  SET_DISCOVERY_ZONE_REPORT_LOADING_ACTION: 'SET_DISCOVERY_ZONE_REPORT_LOADING_ACTION',

  // Discovery zone report actionTypes for setting all time mode
  SET_DISCOVERY_ZONE_REPORT_ALL_TIME_ACTION: 'SET_DISCOVERY_ZONE_REPORT_ALL_TIME_ACTION',

  // Discovery zone report actionTypes for fetching report data
  GET_DISCOVERY_ZONE_REPORT_REQUEST: 'GET_DISCOVERY_ZONE_REPORT_REQUEST',
  GET_DISCOVERY_ZONE_REPORT_RECEIVE: 'GET_DISCOVERY_ZONE_REPORT_RECEIVE',
  GET_DISCOVERY_ZONE_REPORT_FAIL: 'GET_DISCOVERY_ZONE_REPORT_FAIL',

};

export default actionTypes;
