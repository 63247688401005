const actionTypes = {
  // Waste and recycling rules actionTypes for setting loading status
  SET_WASTE_RULES_LOADING_ACTION: 'SET_WASTE_RULES_LOADING_ACTION',

  // Waste and recycling rules actionTypes for fetching data
  GET_WASTE_RULES_REQUEST: 'GET_WASTE_RULES_REQUEST',
  GET_WASTE_RULES_RECEIVE: 'GET_WASTE_RULES_RECEIVE',
  GET_WASTE_RULES_FAIL: 'GET_WASTE_RULES_FAIL',

  // Waste and recycling rules actionTypes for editing data
  EDIT_WASTE_RULES_REQUEST: 'EDIT_WASTE_RULES_REQUEST',
  EDIT_WASTE_RULES_RECEIVE: 'EDIT_WASTE_RULES_RECEIVE',
  EDIT_WASTE_RULES_FAIL: 'EDIT_WASTE_RULES_FAIL',

};

export default actionTypes;
