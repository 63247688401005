import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Dropdown } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';

import { Portlet, PortletBody } from '../../../../_metronic/partials/content/Portlet';
import {
  AutoComplete, TextField, Typography, CustomButton
} from '../../components';
import * as actions from '../redux/actions';
import * as sharedActions from '../../redux/actions';

const useStyles = makeStyles(() => ({
  dropdown: {
    '& button': {
      padding: 0
    }
  },
  dropdownIcon: {
    width: '100%',
    '&::after': {
      display: 'none !important'
    },
  },
  dropdownMenu: {
    width: '100%',
    borderRadius: 0,
    maxHeight: 300,
    overflow: 'auto',
    '& span': {
      justifyContent: 'flex-start'
    }
  },
  dropdownItem: {
    padding: '5px 15px !important',
  },
  noMaterial: {
    padding: '14px !important',
  }
}));

const Header = ({
  intl,
  localityList,
  locality,
  search,
  setWasteTypeAction,
  getWasteTypesListRequest,
  getDepotsRequest,
  getEventsRequest,
  getCollectionsRequest,
  setLocalityAction,
  getAllCategoriesRequest,
  searchMaterialsRequest,
  getSearchResultsRequest,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);
  const [materialSearch, setMaterialSearch] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useEffect(() => {
    if (locality.cms_locality_id) {
      if (!queryParams.get('id')) {
        setWasteTypeAction({});
      } else {
        history.push('/category');
      }
      getWasteTypesListRequest({ locality: locality.cms_locality_id });
      getDepotsRequest({ locality: locality.cms_locality_id });
      getEventsRequest({ locality: locality.cms_locality_id });
      getCollectionsRequest({ locality: locality.cms_locality_id });
      getAllCategoriesRequest({ locality: locality.cms_locality_id });
    }
  }, [locality.cms_locality_id]);

  const handleLocalityChange = (value) => {
    setLocalityAction({ locality: value });
  };

  const handleSearch = (value) => {
    setMaterialSearch(value);
    if (value.length > 2) {
      searchMaterialsRequest({
        locality: locality.cms_locality_id,
        search: value,
      });
    }
  };

  const handleSearchClick = (item) => {
    setMaterialSearch(item.display);
    getSearchResultsRequest({
      locality: locality.cms_locality_id,
      material: item.mat_id,
    });
  };

  return (
    <Portlet>
      <PortletBody>
        <div className="row">
          <div className="col-8">
            <Dropdown
              className={classes.dropdown}
              drop="down"
              show={isSearchOpen}
            >
              <Dropdown.Toggle
                variant="transparent"
                className={classes.dropdownIcon}
              >
                <TextField
                  label={intl.formatMessage({ id: 'CATEGORY.HEADER.MATERIAL_SEARCH' })}
                  value={materialSearch}
                  onChange={handleSearch}
                  onFocus={() => setIsSearchOpen(true)}
                  onBlur={() => setTimeout(() => setIsSearchOpen(false), 300)}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className={`dropdown-menu-fit dropdown-menu-right ${classes.dropdownMenu}`}>
                {materialSearch.length > 2 && search.length > 0 ? search.map((item) => (
                  <CustomButton
                    key={item.mat_id}
                    className={classes.dropdownItem}
                    onClick={() => handleSearchClick(item)}
                    label={(
                      <Typography variant="bodySemiBold">
                        {item.display}
                      </Typography>
                    )}
                  />
                )) : (
                  <Typography className={classes.noMaterial} variant="bodySemiBold">
                    {intl.formatMessage({ id: 'CATEGORY.WGW.DROP_DOWN.EMPTY_MATERIAL' })}
                  </Typography>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-4">
            <AutoComplete
              title={intl.formatMessage({ id: 'GENERAL.MUNICIPALITY' })}
              options={localityList}
              optionTitle="cms_locality_nm"
              optionId="cms_locality_id"
              disableClearable
              onChange={handleLocalityChange}
              value={locality}
            />
          </div>
        </div>
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  localityList: store.common.localityList,
  locality: store.common.locality,
  search: store.category.search,
});

export default injectIntl(connect(mapStateToProps,
  { ...actions, ...sharedActions })(Header));
