/**
 * Initial state for auth pages
 * @param {string} fetchState Determines the state of auth pages during API requests.
 * There are four states:
 * *'no-request': No request has been sent to server yet.
 * *'send-request': A request is sent and is waiting for the result.
 * *'succeed-request': The result is received and contains data.
 * *'failed-request': The result contains error.
 * @param {boolean} isAuthenticated Determines whether user is authenticated and should redirect to
 * dashboard or not.
 */
const initialState = {
  fetchState: 'no-request',
  isAuthenticated: false,
  responseMessage: null,
  user_info: {
    permission_list: [],
    manages_schedules: null
  },
  advocateAdmin: false
};

export default initialState;
