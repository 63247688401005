import actionTypes from './actionTypes';
// get holidays

export const getHolidaysRequest = (project_id) => ({
  type: actionTypes.GET_HOLIDAYS_SCHEDULE_REQUEST,
  payload: { project_id }
});

export const getHolidaysReceive = (data) => ({
  type: actionTypes.GET_HOLIDAYS_SCHEDULE_RECEIVE,
  payload: { ...data }
});

export const getHolidaysFail = (error) => ({
  type: actionTypes.GET_HOLIDAYS_SCHEDULE_FAIL,
  payload: { ...error }
});

// save holidays

export const saveHolidaysRequest = (project_id, district_id, selectedHolidays, holidayImpacts, multiDistricts) => ({
  type: actionTypes.SAVE_HOLIDAYS_SCHEDULE_REQUEST,
  payload: {
    project_id, district_id, selectedHolidays, holidayImpacts, multiDistricts
  }
});

export const saveHolidaysReceive = (data) => ({
  type: actionTypes.SAVE_HOLIDAYS_SCHEDULE_RECEIVE,
  payload: { ...data }
});

export const saveHolidaysFail = (error) => ({
  type: actionTypes.SAVE_HOLIDAYS_SCHEDULE_FAIL,
  payload: { ...error }
});

// get perpetual schedules

export const getPerpetualSchedulesRequest = (districts) => ({
  type: actionTypes.GET_PERPETUAL_SCHEDULES_REQUEST,
  payload: { districts }
});

export const getPerpetualSchedulesReceive = (data) => ({
  type: actionTypes.GET_PERPETUAL_SCHEDULES_RECEIVE,
  payload: { ...data }
});

export const getPerpetualSchedulesFail = (error) => ({
  type: actionTypes.GET_PERPETUAL_SCHEDULES_FAIL,
  payload: { ...error }
});

// manage perpetual schedules
export const managePerpetualSchedulesRequest = (manages_schedules) => ({
  type: actionTypes.MANAGE_PERPETUAL_SCHEDULES_REQUEST,
  payload: { manages_schedules }
});

export const managePerpetualSchedulesReceive = (data) => ({
  type: actionTypes.MANAGE_PERPETUAL_SCHEDULES_RECEIVE,
  payload: { ...data }
});

export const managePerpetualSchedulesFail = (error) => ({
  type: actionTypes.MANAGE_PERPETUAL_SCHEDULES_FAIL,
  payload: { ...error }
});

export const setMultiDistricts = (districts) => ({
  type: actionTypes.SET_MULTI_DISTRICTS,
  payload: districts
});

export const removeFromMultiDistricts = (districts) => ({
  type: actionTypes.REMOVE_FROM_MULTI_DISTRICTS,
  payload: districts
});

export const getSeasonalListRequest = (multiDistricts) => ({
  type: actionTypes.GET_SEASONAL_LIST_REQUEST,
  payload: { multiDistricts }
});

export const getSeasonalListReceive = (data) => ({
  type: actionTypes.GET_SEASONAL_LIST_RECEIVE,
  payload: { ...data }
});

export const getSeasonalListFail = (error) => ({
  type: actionTypes.GET_SEASONAL_LIST_FAIL,
  payload: { ...error }
});

export const resetSeasonalList = () => ({
  type: actionTypes.RESET_SEASONAL_LIST,
});


export const saveSeasonalCollectionRequest = (project_id, district_id, col_type_id, season_start_config, season_end_config) => ({
  type: actionTypes.SAVE_SEASONAL_COLLECTION_REQUEST,
  payload: {
    project_id, district_id, col_type_id, season_start_config, season_end_config
  }
});

export const saveSeasonalCollectionReceive = (data) => ({
  type: actionTypes.SAVE_SEASONAL_COLLECTION_RECEIVE,
  payload: { ...data }
});

export const saveSeasonalCollectionFail = (error) => ({
  type: actionTypes.SAVE_SEASONAL_COLLECTION_FAIL,
  payload: { ...error }
});

export const setCurrentCollection = (collection) => ({
  type: actionTypes.SET_CURRENT_COLLECTION,
  payload: collection
});

export const updateSeasonalCollection = (seasonalCollection) => ({
  type: actionTypes.UPDATE_SEASONAL_COLLECTION,
  payload: seasonalCollection
});

export const setCompletedSetup = (completedSetup) => ({
  type: actionTypes.SET_COMPLETED_SETUP,
  payload: completedSetup
});
