import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import {
  Typography, TextField, WysiwygEditor, CheckBox
} from '../../components';
import SetupWizardWeekDayHours from './SetupWizardWeekDayHours';
import * as actions from '../redux/actions';
import daysOfTheWeek from '../helper';

const SetupWizardOperationHoursTab = ({
  intl,
  newDepot,
  setNewDepotAction,
  tab,
  initialSeason
}) => {
  const [days, setDays] = useState([]);
  const [allDaysOfTheWeek, setAllDaysOfTheWeek] = useState(daysOfTheWeek);

  const handleChange = (data, propertyName) => {
    setNewDepotAction({
      data,
      propertyName
    });
  };

  const handleChangeDate = (data, index, key) => {
    const AllSeason = [...newDepot.seasons];
    let seasons = AllSeason[tab];
    const temp = [...seasons.times];
    let time = temp[index];
    time = {
      ...time,
      [key]: data ? moment(data).format('HH:mm') : null
    };
    temp[index] = time;
    seasons = { ...seasons, times: temp };
    AllSeason[tab] = seasons;
    handleChange(AllSeason, 'seasons');
  };
  useEffect(() => {
    const initialTimes = [...initialSeason.times];
    const secondaryTime = [...newDepot.seasons[tab].times ? newDepot.seasons[tab].times : []];
    if (secondaryTime.length < initialTimes.length) {
      const result = [];
      initialTimes.map((item) => {
        const temp = secondaryTime.find((ele) => String(ele.dow_nbr) === String(item.dow_nbr));
        result.push({
          dow_nbr: item.dow_nbr,
          hours_upper_tm: temp ? temp.hours_upper_tm : null,
          hours_lower_tm: temp ? temp.hours_lower_tm : null,
          add_hours_upper_tm: temp ? temp.add_hours_upper_tm : null,
          add_hours_lower_tm: temp ? temp.add_hours_lower_tm : null
        });
        return null;
      });

      setDays(result);

      result.map((item, index) => {
        if (item.hours_upper_tm && item.hours_lower_tm) {
          const tempDays = [...allDaysOfTheWeek];
          tempDays[index] = { ...tempDays[index], value: true };
          setAllDaysOfTheWeek(tempDays);
        }
        return null;
      });

      const AllSeason = [...newDepot.seasons];
      let seasons = AllSeason[tab];
      seasons = { ...seasons, times: result };
      AllSeason[tab] = seasons;
      handleChange(AllSeason, 'seasons');
    } else {
      setDays(secondaryTime);

      const tempDays = [...allDaysOfTheWeek];
      secondaryTime.map((item) => {
        if (item.hours_upper_tm && item.hours_lower_tm) {
          tempDays[item.dow_nbr] = { ...tempDays[item.dow_nbr], value: true };
        } else {
          tempDays[item.dow_nbr] = { ...tempDays[item.dow_nbr], value: false };
        }
        return null;
      });
      setAllDaysOfTheWeek(tempDays);
    }
  }, [newDepot.seasons[tab], tab]);

  return (
    <div className="w-100 d-flex flex-column">
      <TextField
        label={intl.formatMessage({ id: 'DEPOT.HOURS.SCHEDULE_NAME' })}
        className="my-3 mt-5"
        onChange={(e) => {
          const AllSeason = [...newDepot.seasons];
          let seasons = AllSeason[tab];
          seasons = { ...seasons, season_nm: e };
          AllSeason[tab] = seasons;
          handleChange(AllSeason, 'seasons');
        }}
        value={newDepot.seasons[tab].season_nm}
      />

      <Typography className="mt-4" variant="bodySemiBold">
        {intl.formatMessage({ id: 'DEPOT.HOURS.SCHEDULE_NOTE' })}
      </Typography>
      <div className="mt-3 w-100 h-100">
        <WysiwygEditor
          value={newDepot.seasons[tab].season_notes}
          onChange={(e) => {
            const AllSeasons = [...newDepot.seasons];
            let season = AllSeasons[tab];
            season = {
              ...season,
              season_notes: e
            };
            AllSeasons[tab] = season;
            handleChange(AllSeasons, 'seasons');
          }}
        />
      </div>

      <div className="w-100 d-flex flex-row mt-5 mb-5 justify-content-between">
        {allDaysOfTheWeek
          .map((time, index) => (
            <CheckBox
              key={`depot_setup_wizard_checkbox_${Math.random()}`}
              label={(
                <Typography variant="bodySemiBold">
                  {time.name}
                </Typography>
             )}
              checked={time.value || false}
              onChange={() => {
                const temp = [...allDaysOfTheWeek];
                temp[index] = { ...temp[index], value: !temp[index].value };

                const AllSeason = [...newDepot.seasons];
                let seasons = AllSeason[tab];
                const tempTime = seasons.times.map((item) => (temp[item.dow_nbr].value
                  ? {
                    ...item,
                    hours_lower_tm: item.hours_lower_tm || '09:00',
                    hours_upper_tm: item.hours_upper_tm || '17:00',
                    add_hours_lower_tm: item.add_hours_lower_tm || null,
                    add_hours_upper_tm: item.add_hours_upper_tm || null
                  }
                  : {
                    ...item,
                    hours_lower_tm: null,
                    hours_upper_tm: null,
                    add_hours_lower_tm: null,
                    add_hours_upper_tm: null
                  }));
                console.log(tempTime);
                seasons = { ...seasons, times: tempTime };
                AllSeason[tab] = seasons;

                setAllDaysOfTheWeek(temp);
                handleChange(AllSeason, 'seasons');
              }}
            />
          ))}

      </div>

      {allDaysOfTheWeek.find((item) => item.value)
      && (
      <div className="row">
        <div className="col-6">
          <Typography variant="bodySemiBold" className="mt-4">
            {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.HOURS.OPERATIONAL_HOURS' })}
          </Typography>
        </div>
        <div className="col-6">
          <Typography variant="bodySemiBold" className="mt-4">
            {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.HOURS.ADDITIONAL_HOURS' })}
          </Typography>
        </div>
      </div>
      )}

      <div className="mb-5">
        {
          days
          && days.length
          && days.map((item, index) => {
            if (allDaysOfTheWeek[index].value) {
              return (
                <SetupWizardWeekDayHours
                  key={`hours_${String(index)}`}
                  title={allDaysOfTheWeek[item.dow_nbr].name}
                  firstValue={item.hours_lower_tm}
                  secondValue={item.hours_upper_tm}
                  additionalFirstValue={item.add_hours_lower_tm}
                  additionalSecondValue={item.add_hours_upper_tm}
                  onChangeFirst={(data) => handleChangeDate(data, index, 'hours_lower_tm')}
                  onChangeSecond={(data) => { handleChangeDate(data, index, 'hours_upper_tm'); }}
                  onChangeAdditionalFirst={(data) => {
                    handleChangeDate(data, index, 'add_hours_lower_tm');
                  }}
                  onChangeAdditionalSecond={(data) => {
                    handleChangeDate(data, index, 'add_hours_upper_tm');
                  }}
                />
              );
            }
            return null;
          })
        }
      </div>
    </div>

  );
};

const mapStateToProps = (store) => ({
  newDepot: store.depot.newDepot,
  initialSeason: store.depot.initialSeason,
});

export default injectIntl(connect(mapStateToProps, actions)(SetupWizardOperationHoursTab));
