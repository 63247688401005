import React from 'react';

const CollapseIcon = ({
  color,
  className,
}) => (
  <svg className={`prefix__bi prefix__bi-chevron-up ${className}`} width="1em" height="1em" viewBox="0 0 16 16" fill={color}>
    <path
      fillRule="evenodd"
      d="M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z"
    />
  </svg>
);

export default CollapseIcon;
