import React from 'react';
import { connect } from 'react-redux';

import { Summary, Detail } from './components';
import * as actions from './redux/actions';

const UserReport = ({
  data,
}) => (
  <div id="user-report">
    <Summary
      data={data.summary}
    />
    <Detail
      data={data.detail}
      peerData={data.detail_peer}
      peerRating={data.summary ? data.summary.rating : 0}
    />
  </div>
);

const mapStateToProps = (store) => ({
  data: store.userReport.data,
});

export default connect(mapStateToProps, actions)(UserReport);
