import * as React from 'react';

const ClockIcon = ({
  color,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill={color}
    className={className}
  >
    <path d="M12.672 8.825l-2.091-1.569V4.061a.58.58 0 10-1.162 0v3.485a.58.58 0 00.232.465l2.324 1.743a.578.578 0 00.814-.117.58.58 0 00-.117-.812z" />
    <path d="M10 0C5.864 0 2.5 3.364 2.5 7.5S5.864 15 10 15s7.5-3.364 7.5-7.5S14.136 0 10 0zm0 13.838A6.346 6.346 0 013.662 7.5 6.346 6.346 0 0110 1.162 6.346 6.346 0 0116.338 7.5 6.346 6.346 0 0110 13.838z" />
  </svg>
);

export default ClockIcon;
