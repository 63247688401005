import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { InfoTooltip, Typography } from '../../components';
import PermissionSwitch from './PermissionSwitch';

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.down('400')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  permission: {
    [theme.breakpoints.down('400')]: {
      width: '100%',
    },
  },
}));

const Permission = ({
  title,
  tooltipText,
  onChange,
  isActive
}) => {
  const classes = useStyles();
  return (
    <div className={`d-flex justify-content-between align-items-center ${classes.content}`}>
      <div className={`d-flex align-items-center ${classes.permission}`}>
        <div className="mr-1 mt-2">
          <Typography variant="bodySemiBold">
            {title}
          </Typography>
        </div>
        <div className="mt-2">
          <InfoTooltip tooltip={tooltipText} />
        </div>
      </div>
      <div className={`d-flex ${classes.permission}`}>
        <div className="float-right">
          <PermissionSwitch
            onChange={(e) => onChange(e)}
            leftLabel="GENERAL.OFF"
            rightLabel="GENERAL.ON"
            isActive={isActive}
          />
        </div>
      </div>
    </div>
  );
};
export default Permission;
