const actionTypes = {
  // Reminder actionTypes for setting loading status
  SET_LOADING: 'SET_LOADING',
  RESET_LOADING: 'RESET_LOADING',
  SET_FORM_LOADING: 'SET_FORM_LOADING',

  // Reminder actionTypes for setting form data
  REMINDER_SET_VALUE_ACTION: 'REMINDER_SET_VALUE_ACTION',
  REMINDER_RESET_VALUE_ACTION: 'REMINDER_RESET_VALUE_ACTION',

  // Reminder actionTypes for fetching reminder list
  REMINDER_LIST_REQUEST: 'REMINDER_LIST_REQUEST',
  REMINDER_LISTـRECEIVE: 'REMINDER_LISTـRECEIVE',
  REMINDER_LISTـFAIL: 'REMINDER_LISTـFAIL',

  // Reminder actionTypes for deleting a reminder
  DELETE_REMINDER_REQUEST: 'DELETE_REMINDER_REQUEST',
  DELETE_REMINDER_RECEIVE: 'DELETE_REMINDER_RECEIVE',
  DELETE_REMINDER_FAIL: 'DELETE_REMINDER_FAIL',

  // Reminder actionTypes for loading a reminder into the form
  LOAD_REMINDER_REQUEST: 'LOAD_REMINDER_REQUEST',
  LOAD_REMINDER_RECEIVE: 'LOAD_REMINDER_RECEIVE',
  LOAD_REMINDER_FAIL: 'LOAD_REMINDER_FAIL',

  // Reminder actionTypes for fetching collection list
  GET_COLLECTION_REQUEST: 'GET_COLLECTION_REQUEST',
  GET_COLLECTION_RECEIVE: 'GET_COLLECTION_RECEIVE',
  GET_COLLECTION_FAIL: 'GET_COLLECTION_FAIL',

  // Reminder actionTypes for creating a new reminder
  NEW_REMINDER_REQUEST: 'NEW_REMINDER_REQUEST',
  NEW_REMINDER_RECEIVE: 'NEW_REMINDER_RECEIVE',
  NEW_REMINDER_FAIL: 'NEW_REMINDER_FAIL',

  // Reminder actionTypes for editing an existing reminder
  EDIT_REMINDER_REQUEST: 'EDIT_REMINDER_REQUEST',
  EDIT_REMINDER_RECEIVE: 'EDIT_REMINDER_RECEIVE',
  EDIT_REMINDER_FAIL: 'EDIT_REMINDER_FAIL',

};

export default actionTypes;
