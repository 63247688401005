import React from 'react';
import ContentLoader from 'react-content-loader';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    height: '350px',
  },
}));

const CalendarLoading = ({
  width = 1150,
  height = 625
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <ContentLoader
          speed={2}
          style={{ width, height }}
          backgroundColor={process.env.REACT_APP_COLOR_LOADING_BACKGROUND}
          foregroundColor={process.env.REACT_APP_COLOR__LOADING_FOREGROUND}
        >

          {/* Table header */}

          <rect x="0" y="0" rx="6" ry="6" width="31%" height="0.3%" />

          {/* left border */}
          <rect x="0" y="2" rx="0" ry="0" width="0.2%" height="310" />
          {/* right border */}
          <rect x="31%" y="2" rx="0" ry="0" width="0.2%" height="310" />

          <rect x="10%" y="15" rx="6" ry="6" width="12%" height="18" />

          <rect x="0" y="45" rx="6" ry="6" width="31%" height="0.3%" />

          <rect x="14.1%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="18.1%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="22.1%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="26.1%" y="59" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="2.1%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="6.1%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="10.1%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="14.1%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="18.1%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="22.1%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="26.1%" y="110" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="2.1%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="6.1%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="10.1%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="14.1%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="18.1%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="22.1%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="26.1%" y="161" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="2.1%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="6.1%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="10.1%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="14.1%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="18.1%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="22.1%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="26.1%" y="211" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="2.1%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="6.1%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="10.1%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="14.1%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="18.1%" y="262" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="0" y="310" rx="6" ry="6" width="31%" height="0.3%" />


          <rect x="33.5%" y="0" rx="6" ry="6" width="31%" height="0.3%" />
          {/* left border */}
          <rect x="33.5%" y="2" rx="0" ry="0" width="0.2%" height="310" />
          {/* right border */}
          <rect x="64.5%" y="2" rx="0" ry="0" width="0.2%" height="310" />

          <rect x="43%" y="15" rx="6" ry="6" width="12%" height="18" />

          <rect x="33.5%" y="45" rx="6" ry="6" width="31%" height="0.3%" />

          <rect x="48%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="52%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="56%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="60%" y="59" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="36%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="40%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="44%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="48%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="52%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="56%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="60%" y="110" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="36%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="40%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="44%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="48%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="52%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="56%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="60%" y="161" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="36%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="40%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="44%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="48%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="52%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="56%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="60%" y="211" rx="6" ry="6" width="2.7%" height="30" />


          <rect x="36%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="40%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="44%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="48%" y="262" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="33.5%" y="310" rx="6" ry="6" width="31%" height="0.3%" />


          <rect x="67.4%" y="0" rx="6" ry="6" width="31%" height="0.3%" />
          {/* left border */}
          <rect x="67.4%" y="2" rx="0" ry="0" width="0.2%" height="310" />
          {/* right border */}
          <rect x="98.5%" y="2" rx="0" ry="0" width="0.2%" height="310" />

          <rect x="77%" y="15" rx="6" ry="6" width="12%" height="18" />

          <rect x="67.4%" y="45" rx="6" ry="6" width="31%" height="0.3%" />

          <rect x="82%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="86%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="90%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="94%" y="59" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="70%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="74%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="78%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="82%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="86%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="90%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="94%" y="110" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="70%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="74%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="78%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="82%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="86%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="90%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="94%" y="161" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="70%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="74%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="78%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="82%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="86%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="90%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="94%" y="211" rx="6" ry="6" width="2.7%" height="30" />


          <rect x="70%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="74%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="78%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="82%" y="262" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="67.4%" y="310" rx="6" ry="6" width="31%" height="0.3%" />


        </ContentLoader>
      </div>
      <div className={classes.container}>
        <ContentLoader
          speed={2}
          style={{ width, height }}
          backgroundColor={process.env.REACT_APP_COLOR_LOADING_BACKGROUND}
          foregroundColor={process.env.REACT_APP_COLOR__LOADING_FOREGROUND}
        >

          {/* Table header */}

          <rect x="0" y="0" rx="6" ry="6" width="31%" height="0.3%" />

          {/* left border */}
          <rect x="0" y="2" rx="0" ry="0" width="0.2%" height="310" />
          {/* right border */}
          <rect x="31%" y="2" rx="0" ry="0" width="0.2%" height="310" />

          <rect x="10%" y="15" rx="6" ry="6" width="12%" height="18" />

          <rect x="0" y="45" rx="6" ry="6" width="31%" height="0.3%" />

          <rect x="14.1%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="18.1%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="22.1%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="26.1%" y="59" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="2.1%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="6.1%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="10.1%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="14.1%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="18.1%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="22.1%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="26.1%" y="110" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="2.1%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="6.1%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="10.1%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="14.1%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="18.1%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="22.1%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="26.1%" y="161" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="2.1%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="6.1%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="10.1%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="14.1%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="18.1%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="22.1%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="26.1%" y="211" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="2.1%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="6.1%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="10.1%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="14.1%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="18.1%" y="262" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="0" y="310" rx="6" ry="6" width="31%" height="0.3%" />


          <rect x="33.5%" y="0" rx="6" ry="6" width="31%" height="0.3%" />
          {/* left border */}
          <rect x="33.5%" y="2" rx="0" ry="0" width="0.2%" height="310" />
          {/* right border */}
          <rect x="64.5%" y="2" rx="0" ry="0" width="0.2%" height="310" />

          <rect x="43%" y="15" rx="6" ry="6" width="12%" height="18" />

          <rect x="33.5%" y="45" rx="6" ry="6" width="31%" height="0.3%" />

          <rect x="48%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="52%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="56%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="60%" y="59" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="36%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="40%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="44%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="48%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="52%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="56%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="60%" y="110" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="36%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="40%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="44%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="48%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="52%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="56%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="60%" y="161" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="36%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="40%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="44%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="48%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="52%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="56%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="60%" y="211" rx="6" ry="6" width="2.7%" height="30" />


          <rect x="36%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="40%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="44%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="48%" y="262" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="33.5%" y="310" rx="6" ry="6" width="31%" height="0.3%" />


          <rect x="67.4%" y="0" rx="6" ry="6" width="31%" height="0.3%" />
          {/* left border */}
          <rect x="67.4%" y="2" rx="0" ry="0" width="0.2%" height="310" />
          {/* right border */}
          <rect x="98.5%" y="2" rx="0" ry="0" width="0.2%" height="310" />

          <rect x="77%" y="15" rx="6" ry="6" width="12%" height="18" />

          <rect x="67.4%" y="45" rx="6" ry="6" width="31%" height="0.3%" />

          <rect x="82%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="86%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="90%" y="59" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="94%" y="59" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="70%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="74%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="78%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="82%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="86%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="90%" y="110" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="94%" y="110" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="70%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="74%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="78%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="82%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="86%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="90%" y="161" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="94%" y="161" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="70%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="74%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="78%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="82%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="86%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="90%" y="211" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="94%" y="211" rx="6" ry="6" width="2.7%" height="30" />


          <rect x="70%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="74%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="78%" y="262" rx="6" ry="6" width="2.7%" height="30" />
          <rect x="82%" y="262" rx="6" ry="6" width="2.7%" height="30" />

          <rect x="67.4%" y="310" rx="6" ry="6" width="31%" height="0.3%" />


        </ContentLoader>
      </div>
    </>
  );
};

export default CalendarLoading;
