import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import moment from 'moment';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';


import { makeStyles } from '@material-ui/core/styles';
import { Opacity, Typography, Pagination } from '../../../components';
import * as sharedActions from '../../../redux/actions';
import { FeaturedLessonCard } from './components';

import * as actions from './redux/actions';

import { LoadingSpinner, ErrorContainer } from '../../../components/loading';
import { ProUser } from '../components';
import { CrownIcon } from '../../../../assets/icons';

import { changeCurrentMonthsIndexHandler, findCurrentMonthsDataIndexHandler } from '../../../../utils';


const useStyles = makeStyles(() => ({
  container: {

  },
}));
const FeaturedLessons = ({
  intl,
  district,
  municipalities,
  setDistrictAction,
  getFeaturedLessonsDataRequestAction,
  featuredLesson,
  loading,
  error,
  isUserPro,
  stage,
  getDistrictProPackagesRequest,
  district_pro_packages,
  municipality,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [hasfeaturedLesson, setHasFeaturedLesson] = useState(false);

  useEffect(() => {
    if (district.district_id === '#all-districts#') {
      setDistrictAction(municipalities[0]);
    } else {
      const [projectId, districtId] = district.district_id.split('#$#');
      getFeaturedLessonsDataRequestAction(projectId, districtId);
    }
  }, [district]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  // useEffect(() => {
  //   setCurrentLessonIndex(findCurrentMonthsDataIndexHandler(featuredLesson));
  // }, [featuredLesson]);



  // const currentDate = moment().format('YYYY-MM');
  // const [currentLessonIndex, setCurrentLessonIndex] = useState(findCurrentMonthsDataIndexHandler(featuredLesson));



  // let date_from, month, campaigns;

  // if (!loading && featuredLesson.length > 0) {
  //   date_from = featuredLesson[currentLessonIndex].date_from;
  //   month = featuredLesson[currentLessonIndex].month;
  //   campaigns = featuredLesson[currentLessonIndex].campaigns;

  // }

  // const changeBlogsMonthHandler = (direction) => {
  //   setCurrentLessonIndex(
  //     changeCurrentMonthsIndexHandler(direction, currentLessonIndex, featuredLesson.length)
  //   );
  // };

  // const isDeletable = date_from.slice(0, 7) === currentDate;


  // const dateDiff = moment().diff(moment(month), 'months');
  useEffect(() => {
    if (stage !== '4') {
      getDistrictProPackagesRequest();
    }
    
  }, [stage]);

  useEffect(() => {
    if (district_pro_packages && district_pro_packages[0] && district_pro_packages[0].has_featured_lesson) {
      setHasFeaturedLesson(true);
    }

  }, [district_pro_packages]);

  return (
    <>
      {
        !isUserPro && stage < 4 && !hasfeaturedLesson && (
          <ProUser
            title={intl.formatMessage({ id: 'EDUCATION.FEATURED.LESSONS' })}
            description="Featured Lessons is a PRO feature that helps you target specific behaviors or issues. Every Featured lesson is composed of various interactive features such as videos, quiz and poll questions, picture-tap activities, or infographics. "
            subtitle="These lessons are developed and managed by a professional recycling educator here at Recycle Coach."
            alternateRightTitle={intl.formatMessage({ id: 'GENERAL.WHAT.BENEFITS' })}
            benefits={['Increase participation through weekly push notifications', 'Target specific materials or issues in your program ', 'Increase residents’ recycling knowledge', 'Provide interactive learning components', 'Promote behavior change ']}
          />
        )
      }

      <Opacity slide>
        {
          hasfeaturedLesson || isUserPro && stage >= 4 && (
            <>
              <Typography className="mb-2" variant="h1">
                {intl.formatMessage({ id: 'EDUCATION.FEATURED.LESSONS' })}
                <CrownIcon style={{ marginLeft: 7 }} width={15} height={15} color="#FFC611" />
              </Typography>

              <Typography color="#404040" className="mb-4" variant="educationBody">
                Featured Lessons are a PRO feature that helps you target specific behaviors or issues. Every Featured Lesson is composed of various activities like educational videos, quiz and poll questions, picture-tap activities and infographics. These lessons are developed and managed by a professional recycling educator here at Recycle Coach.
              </Typography>

              <Typography color="#404040" className="mb-4" variant="educationBody">
                Whenever a Featured Lesson is available, residents of PRO municipalities will receive a push notification to view the lesson. The feature has a proven record of increasing residents’ knowledge of proper recycling.
              </Typography>
            </>
          )
        }



        <Typography color="#6C7293" variant="h2">
          List of lessons
          {' '}
          {!isUserPro && stage < 4 && !hasfeaturedLesson && 'which are displayed to PRO Users '}
        </Typography>


        {loading ? <LoadingSpinner height={430} /> : error ? (
          <ErrorContainer
            small
            reportName="FeaturedLessons"
            height={430}
            error={error}
          />
        ) : (

          <>
            {/* <Typography color="#6C7293" variant="h2">
              {dateDiff === 0 ?
                intl.formatMessage({ id: 'EDUCATION.CURRENT.CONTENT' })
                : dateDiff < 0 ?
                  intl.formatMessage({ id: 'EDUCATION.UPCOMING.CONTENT' })
                  : intl.formatMessage({ id: 'EDUCATION.PRIOR.CONTENT' })
              }

            </Typography>
            <div className="d-flex mt-1 align-items-center">
              <NavigateBeforeIcon
                onClick={() => changeBlogsMonthHandler('backward')}
                fontSize="large"
                style={{
                  color: '#757575',
                  transition: 'all .3s',
                  cursor: currentLessonIndex === featuredLesson.length - 1 ? 'not-allowed' : 'pointer',
                  opacity: currentLessonIndex === featuredLesson.length - 1 ? 0.5 : 1,
                }}
                disabled={currentLessonIndex === featuredLesson.length - 1}
              />
              <Typography style={{ textAlign: 'center' }} color="#404040" variant="bodySemiBold">
                {month}
              </Typography>
              <NavigateNextIcon
                onClick={() => changeBlogsMonthHandler('forward')}
                fontSize="large"
                style={{
                  color: '#757575',
                  transition: 'all .3s',
                  cursor: currentLessonIndex === 0 ? 'not-allowed' : 'pointer',
                  opacity: currentLessonIndex === 0 ? 0.5 : 1,
                }}
                disabled={currentLessonIndex === 0}
              />
            </div> */}

            <Opacity>
              <div className="d-flex flex-wrap mt-3">
                {/* {campaigns.map((item) => (
                  <FeaturedLessonCard key={item.id} isDeletable={false} data={item} />
                ))} */}
                {featuredLesson && featuredLesson.slice((page - 1) * 3, (page * 3)).map((item) => (
                  <FeaturedLessonCard key={item.id} isDeletable={false} data={item} />
                ))}
                <div className={`mt-5 mb-4 d-flex justify-content-end align-items-center w-100 ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
                  <Pagination
                    page={page}
                    rowsPerPage={3}
                    handlePageChange={(value) => handleChangePage(value)}
                    dataLength={featuredLesson && featuredLesson.length}
                    hideRowsPerPage
                  />
                </div>

              </div>
            </Opacity>
          </>
        )}


      </Opacity>
    </>

  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  municipalities: store.common.municipalities,
  loading: store.featuredLesson.loading,
  error: store.featuredLesson.error,
  featuredLesson: store.featuredLesson.campaigns,
  isUserPro: store.common.isUserPro,
  stage: store.common.district.stage,
  district_pro_packages: store.common.district_pro_packages,
  municipality: store.common.district.district_id,
});
export default injectIntl(connect(mapStateToProps, { ...actions, ...sharedActions })(FeaturedLessons));

