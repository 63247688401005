import { put, call, takeLatest } from 'redux-saga/effects';
import actionTypes from './actionTypes';
import * as actions from './actions';
import getDashboardData from '../apis';

function* getDashboardDataSaga({ payload: { fromDate, toDate } }) {
  const response = yield call(getDashboardData, { fromDate, toDate });
  try {
    yield put(actions.getDashboardDataReceiveAction(response.data));
  } catch (error) {
    yield put(actions.getDashboardDataFailAction(response.error.detail.data.data));
  }
}

function* dashboardSaga() {
  yield takeLatest(actionTypes.GET_DASHBOARD_DATA_REQUEST, getDashboardDataSaga);
}

export default dashboardSaga;
