import React, { useState } from 'react';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Menu, MenuItem } from '@material-ui/core';

import Typography from './Typography';

const CustomSelect = ({
  options,
  onSelect,
  selectedValue,
  customPickerContent = null,
  style = {},
  textVariant = 'body',
  textStyle = {},
  placeholder = 'Select',
}) => {
  const index = options.indexOf(selectedValue) === -1
    ? null
    : options.indexOf(selectedValue);

  const isSelect = selectedValue === 'Select';
  const [selectedIndex, setSelectedIndex] = useState(index);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (index, option) => {
    onSelect(option);
    setSelectedIndex(index);
    setAnchorEl(null);
  };
  return (
    <div style={style}>
      <div
        style={{
          cursor: 'pointer', padding: '5px 0', display: 'flex', flexDirection: 'row', alignItems: 'center'
        }}
        onClick={handleClickListItem}
      >
        {customPickerContent || (
          <>
            <Typography variant={textVariant} style={textStyle}>
              {(selectedIndex !== null && !isSelect
                ? options[selectedIndex]
                : placeholder)}
            </Typography>
            <div style={{ transform: 'translateY(-2px)' }}>
              {!anchorEl ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            </div>
          </>
        )}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        style={{ maxHeight: 400 }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={() => handleMenuItemClick(index, option)}
            style={{ fontWeight: '600', minWidth: 80 }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CustomSelect;
