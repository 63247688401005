import moment from "moment";

const CustomDate = ({ date, children }) => {
  return (
    <div className="rbc-date-cell">
      {moment(date).format('D')}
      {children}
    </div>
  );
};

export default CustomDate;
