import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../../redux/createReducer';
import initialState from './store';

const annualSurveyReportReducer = persistReducer(
  { storage, key: 'annualSurveyReport', whitelist: [] },
  createReducer(initialState, {
    GET_ANNUAL_SURVEY_REQUEST: (state) => Immutable({
      ...state,
      loading: true,
      error: false
    }),

    GET_ANNUAL_SURVEY_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      data: payload.data.data,
      loading: false,
      error: false
    }),

    GET_ANNUAL_SURVEY_FAIL: (state, { payload }) => Immutable({
      ...state,
      loading: false,
      error: payload.data
    })
  })
);

export default annualSurveyReportReducer;
