import actionTypes from './actionTypes';

// Notification actions for setting loading status
export const setLoadingAction = () => ({
  type: actionTypes.SET_LOADING
});

export const resetLoadingAction = () => ({
  type: actionTypes.RESET_LOADING
});

export const setFormLoading = (loading) => ({
  type: actionTypes.SET_FORM_LOADING,
  payload: loading
});

// Notification actions for resetting responseMessage
export const resetMessageAction = () => ({
  type: actionTypes.RESET_MESSAGE
});

// Notification actions for setting form data
export const setFormDataAction = ({ data, propertyName }) => ({
  type: actionTypes.SET_FORM_DATA,
  payload: { data, propertyName }
});

// Notification actions for updating form
export const setFormAction = (data) => ({
  type: actionTypes.SET_FORM,
  payload: { ...data }
});

export const resetFormAction = () => ({
  type: actionTypes.RESET_FORM,
});

// Notification actions for setting tab in notification history
export const setTabAction = (tab) => ({
  type: actionTypes.SET_NOTIFICATION_TAB,
  payload: { tab }
});

// Notification actions for fetching notification list
export const notificationListRequest = ({ page, type }) => ({
  type: actionTypes.NOTIFICATION_LIST_REQUEST,
  payload: { page, type }
});

export const notificationListReceive = (data) => ({
  type: actionTypes.NOTIFICATION_LISTـRECEIVE,
  payload: { ...data }
});

export const notificationListFail = (error) => ({
  type: actionTypes.NOTIFICATION_LISTـFAIL,
  payload: { ...error }
});

// Notification actions for deleting a notification
export const deleteNotificationRequest = ({ id, type, page }) => ({
  type: actionTypes.DELETE_NOTIFICATION_REQUEST,
  payload: { id, type, page }
});

export const deleteNotificationReceive = (data) => ({
  type: actionTypes.DELETE_NOTIFICATION_RECEIVE,
  payload: { ...data }
});

export const deleteNotificationFail = (error) => ({
  type: actionTypes.DELETE_NOTIFICATION_FAIL,
  payload: { ...error }
});

// Notification actions for loading a notification into the form
export const loadNotificationRequest = (id) => ({
  type: actionTypes.LOAD_NOTIFICATION_REQUEST,
  payload: { id }
});

export const loadNotificationReceive = (data) => ({
  type: actionTypes.LOAD_NOTIFICATION_RECEIVE,
  payload: { ...data }
});

export const loadNotificationFail = (error) => ({
  type: actionTypes.LOAD_NOTIFICATION_FAIL,
  payload: { ...error }
});

// Notification actions for sending a notification right now
export const sendNowRequest = ({ id, type, page }) => ({
  type: actionTypes.SEND_NOW_REQUEST,
  payload: { id, type, page }
});

export const sendNowReceive = (data) => ({
  type: actionTypes.SEND_NOW_RECEIVE,
  payload: { ...data }
});

export const sendNowFail = (error) => ({
  type: actionTypes.SEND_NOW_FAIL,
  payload: { ...error }
});

// Notification actions for editing the delete date
export const editDeleteDateRequest = ({
  id, time, date, type, page
}) => ({
  type: actionTypes.EDIT_DELETE_DATE_REQUEST,
  payload: {
    id, time, date, type, page
  }
});

export const editDeleteDateReceive = (data) => ({
  type: actionTypes.EDIT_DELETE_DATE_RECEIVE,
  payload: { ...data }
});

export const editDeleteDateFail = (error) => ({
  type: actionTypes.EDIT_DELETE_DATE_FAIL,
  payload: { ...error }
});

// Notification actions for fetching zone list
export const getZoneRequest = (district_id) => ({
  type: actionTypes.GET_ZONE_REQUEST,
  payload: { district_id }
});

export const getZoneReceive = (data) => ({
  type: actionTypes.GET_ZONE_RECEIVE,
  payload: { ...data }
});

export const getZoneFail = (error) => ({
  type: actionTypes.GET_ZONE_FAIL,
  payload: { ...error }
});

// Notification action for creating a draft notification
export const newDraftRequest = (data) => ({
  type: actionTypes.NEW_DRAFT_REQUEST,
  payload: data
});

export const newDraftReceive = (data) => ({
  type: actionTypes.NEW_DRAFT_RECEIVE,
  payload: { ...data }
});

export const newDraftFail = (error) => ({
  type: actionTypes.NEW_DRAFT_FAIL,
  payload: { ...error }
});

// Notification actions for creating a scheduled notification
export const newScheduledRequest = (data) => ({
  type: actionTypes.NEW_SCHEDULED_REQUEST,
  payload: data
});

export const newScheduledReceive = (data) => ({
  type: actionTypes.NEW_SCHEDULED_RECEIVE,
  payload: { ...data }
});

export const newScheduledFailed = (error) => ({
  type: actionTypes.NEW_SCHEDULED_FAIL,
  payload: { ...error }
});

// Notification actions for creating a immediately notification
export const newImmediatelyRequest = (data) => ({
  type: actionTypes.NEW_IMMEDIATELY_REQUEST,
  payload: data
});

export const newImmediatelyReceive = (data) => ({
  type: actionTypes.NEW_IMMEDIATELY_RECEIVE,
  payload: { ...data }
});

export const newImmediatelyFailed = (error) => ({
  type: actionTypes.NEW_IMMEDIATELY_FAIL,
  payload: { ...error }
});

// Notification actions for editing a draft notification
export const editDraftRequest = (data) => ({
  type: actionTypes.EDIT_DRAFT_REQUEST,
  payload: data
});

export const editDraftReceive = (data) => ({
  type: actionTypes.EDIT_DRAFT_RECEIVE,
  payload: { ...data }
});

export const editDraftFail = (error) => ({
  type: actionTypes.EDIT_DRAFT_FAILED,
  payload: { ...error }
});

// Notification actions for editing a scheduled notification
export const editScheduledRequest = (data) => ({
  type: actionTypes.EDIT_SCHEDULED_REQUEST,
  payload: data
});

export const editScheduledReceive = (data) => ({
  type: actionTypes.EDIT_SCHEDULED_RECEIVE,
  payload: { ...data }
});

export const editScheduledFail = (error) => ({
  type: actionTypes.EDIT_SCHEDULED_FAIL,
  payload: { ...error }
});

// Notification actions for editing a scheduled notification
export const editImmediatelyRequest = (data) => ({
  type: actionTypes.EDIT_IMMEDIATELY_REQUEST,
  payload: data
});

export const editImmediatelyReceive = (data) => ({
  type: actionTypes.EDIT_IMMEDIATELY_RECEIVE,
  payload: { ...data }
});

export const editImmediatelyFail = (error) => ({
  type: actionTypes.EDIT_IMMEDIATELY_FAIL,
  payload: { ...error }
});

export const setNotificationCat = (data) => ({
  type: actionTypes.SET_NOTIFICATION_CAT,
  payload: data
});

// Notification actions for update a notification
export const updateNotificationRequest = (data) => ({
  type: actionTypes.NOTIFICATION_UPDATE_REQUEST,
  payload: data
});

export const updateNotificationReceive = (data) => ({
  type: actionTypes.NOTIFICATION_UPDATE_RECEIVE,
  payload: { ...data }
});

export const updateNotificationFail = (error) => ({
  type: actionTypes.NOTIFICATION_UPDATE_FAIL,
  payload: { ...error }
});
