/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../../../../_metronic/partials/content/Portlet';
import { DoughnutChart } from '../../components';
import { DetailRowLoading } from '../../../components/loading';
import { Switch, Typography } from '../../../components';
import UserReportDetail from './UserReportDetail';
import PeerReportDetail from './PeerReportDetail';
import { addCommasToNumber } from '../../../../utils';
import * as actions from '../redux/actions';

const Detail = ({
  intl,
  data,
  peerData,
  peerRating,
  isDashboard = false,
  district,
  fromDate,
  toDate,
  fetchState,
  advocateAdmin
}) => {
  const [isPeerReport, setIsPeerReport] = useState(false);
  const isSendRequest = fetchState === 'send-request';

  useEffect(() => {
    if (district.district_id === '#all-districts#') {
      setIsPeerReport(false);
    }
  }, [district]);
  return (
    <Portlet className="h-100 position-relative">
      <div className="w-100 p-0">
        <PortletHeader
          toolbar={(
            <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start">
              <div className="row col-xs-12 col-lg-6 align-items-center">
                <div className="align-items-center pl-3">
                  <Typography variant="h1">
                    {isPeerReport
                      ? intl.formatMessage({ id: 'REPORT.USERS.DETAIL.HEADER_PEER' })
                      : intl.formatMessage({ id: 'REPORT.USERS.DETAIL.HEADER_USER' })}
                  </Typography>
                </div>
                <div className="col-sm-12 col-lg justify-content-start align-items-center mt-3 mt-lg-0 ml-lg-3">
                  <Typography variant="body">
                    {`${moment(fromDate).format('MMMM Do, YYYY')} - ${moment(toDate).format('MMMM Do, YYYY')}`}
                  </Typography>
                </div>
              </div>
              {district.district_id !== '#all-districts#'
              && (
              <div className="d-flex col-sm-12 col-lg-6 p-0 justify-content-lg-end justify-content-sm-start justify-content-lg-end">
                <PortletHeaderToolbar>
                  { peerData && advocateAdmin !== true
                  && (
                  <Switch
                    isActive={isPeerReport}
                    onChange={() => setIsPeerReport(!isPeerReport)}
                    leftLabel={intl.formatMessage({ id: 'REPORT.USERS.DETAIL.SWITCH_USER_REPORT' })}
                    rightLabel={intl.formatMessage({ id: 'REPORT.USERS.DETAIL.SWITCH_PEER_REPORT' })}
                  />
                  )}
                </PortletHeaderToolbar>
              </div>
              )}
            </div>
          )}
        />
      </div>
      <PortletBody>

        <div className="row mt-5">
          {isPeerReport ? (
            <>
              <div className="col-12 col-xl-5">
                <DoughnutChart
                  isLoading={isSendRequest}
                  total={peerData && addCommasToNumber(peerRating)}
                  insideText={`${intl.formatMessage({ id: 'REPORT.GENERAL.USERS' })} ${intl.formatMessage({ id: 'REPORT.GENERAL.PER_1000_HOUSEHOLDS' })}`}
                  labels={[
                    intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.MOBILE.TITLE' }),
                    intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.WEB_APP.TITLE' }),
                    intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.COMMUNICATION.TITLE' }),
                    intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.CALENDAR.TITLE' }),
                  ]}
                  values={peerData && [
                    peerData.mobile.rating,
                    peerData.web_app.rating,
                    peerData.communications.rating,
                    peerData.calendars.rating
                  ]}
                  colors={['#3e95cd', '#2e75cf', '#3cbacd', '#2a95ff']}
                />
              </div>
              <div id="parent" className="col-12 col-xl-7 pr-5 pr-xl-0 mt-5 mt-xl-0">
                { isSendRequest
                  ? (
                    <div className="d-flex flex-column w-100 align-items-center justify-content-between pl-5 pl-xl-0">
                      <DetailRowLoading width="100%" />
                    </div>
                  ) : (
                    <>
                      {data && <PeerReportDetail data={peerData} />}
                    </>
                  )}
              </div>
            </>
          ) : (
            <>
              <div className="col-12 col-xl-5">
                <DoughnutChart
                  isLoading={isSendRequest}
                  total={data && advocateAdmin !== true ? addCommasToNumber(
                    (data.mobile ? data.mobile.total : 0)
                        + (data.web_app ? data.web_app.total : 0)
                        + (data.communications ? data.communications.total : 0)
                        + (data.calendars ? data.calendars.total : 0) 
                  ) : addCommasToNumber(data
                    ? data.mobile ? data.mobile.total : 0
                        + data.web_app ? data.web_app.total : 0 : 0)}
                  insideText={intl.formatMessage({ id: 'REPORT.GENERAL.USERS' })}
                  labels={advocateAdmin !== true ? [
                    intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.MOBILE.TITLE' }),
                    intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.WEB_APP.TITLE' }),
                    intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.COMMUNICATION.TITLE' }),
                    intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.CALENDAR.TITLE' }),
                  ]
                    : [
                      intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.MOBILE.TITLE' }),
                      intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.WEB_APP.TITLE' }),
                    ]}
                  values={data && advocateAdmin !== true ? [
                    data.mobile ? data.mobile.total : 0,
                    data.web_app ? data.web_app.total : 0,
                    data.communications ? data.communications.total : 0,
                    data.calendars ? data.calendars.total : 0
                  ] : [
                    data ? data.mobile.total : 0,
                    data ? data.web_app ? data.web_app.total : 0 : 0,
                  ]}
                  colors={['#3e95cd', '#2e75cf', '#3cbacd', '#2a95ff']}
                />
              </div>
              <div id="parent" className="col-12 col-xl-7 pr-5 pr-xl-0 mt-5 mt-xl-0">
                { isSendRequest
                  ? (
                    <div className="d-flex flex-column w-100 align-items-center justify-content-between pl-5 pl-xl-0">
                      <DetailRowLoading width="100%" />
                    </div>
                  )
                  : (
                    <>
                      { data
                      && <UserReportDetail data={data} isDashboard={isDashboard} />}
                    </>
                  )}
              </div>
            </>
          )}
        </div>

      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
  fetchState: store.userReport.fetchState,
  advocateAdmin: store.auth.advocateAdmin
});

export default injectIntl(connect(mapStateToProps, actions)(Detail));
