import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';

import { Portlet, PortletHeader } from '../../../../../_metronic/partials/content/Portlet';
import { Typography, IconButton } from '../../../components';
import { CloseIcon } from '../../../../assets/icons';


const useStyles = makeStyles(() => ({

  modalContainer: {
    width: 1020,
    height: '100%',
    margin: 0,
    padding: 0,
    paddingBottom: 40
  },
  title: {
    textTransform: 'uppercase',
  },
  dialogOverride: {
    display: 'block',
    overflow: 'hidden',
    width: 1020,
    maxWidth: '100%',
    borderRadius: '10px 15px 15px 10px'
  },
  modalCloseButton: {
    position: 'absolute',
    right: 16,
    top: 18
  }
}));
const PopularFeaturesModal = ({
  intl, open, onClose, content, title,
}) => {
  const classes = useStyles({});
  return (
    <>
      {open && (
        <Dialog 
          classes={{
            paperScrollPaper: classes.dialogOverride,
          }}
          open={open}
          onClose={onClose}>
          <Portlet className={`d-flex align-items-center text-center justify-content-center ${classes.modalContainer}`}>
            <div className="w-100 p-0">
              <PortletHeader
                toolbar={(
                  <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start">
                    <div className="row col-xs-12">
                      <div className="align-items-center pl-3">
                        <Typography variant="h1" className={classes.title}>
                          {title}
                        </Typography>
                        <IconButton tooltip={intl.formatMessage({ id: 'GENERAL.CLOSE' })} className={classes.modalCloseButton} onClick={() => onClose()} style={{ top: '-30px' }}>
                          <CloseIcon width="1em" height="1em" />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="w-100 mt-3 pl-3 pr-3">
              {content}
            </div>
          </Portlet>
        </Dialog>
      )}
    </>
  );
};

export default injectIntl(PopularFeaturesModal);
