import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import { Opacity } from '../../../../components';
import * as actions from '../redux/actions';

import * as sharedActions from '../../../../redux/actions';
import * as dashboardActions from '../../../../Dashboard/redux/actions';

import {
  MajorCampaignSpecialOffer,
  // MajorCampaignsTable,
  MajorCampaignsPromo,
  MajorCampaignCaseStudy,
  MajorCampaignRangeSelector,
  MajorCampaignDetails,
} from './index';

const useStyles = makeStyles(() => ({
  topContainer: {
    display: 'flex',
    gap: 50,
  },
}));

const Main = ({
  intl,
  stage,
  setAllocationCodeAction,
  setMajorCampaignAction,
  majorCampaign,
  district,
  municipalities,
  setDistrictAction,
  // getMajorCampaignsDataRequestAction,
  // majorCampaignsDetails,
  getMajorCampaignsReportDataRequestAction,
  csm,
  getDashboardDataRequestAction,
}) => {
  const classes = useStyles();
  const [viewedCampaign, setViewedCampaign] = React.useState(null);
  const [displayStage4, setDisplayStage4] = React.useState(false);

  useEffect(() => {
    if (!csm) {
      const fromDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
      const toDate = moment().format('YYYY-MM-DD');
      getDashboardDataRequestAction(fromDate, toDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (district.district_id === '#all-districts#') {
      setDistrictAction(municipalities[0]);
    } else {
      const [projectId, districtId] = district.district_id.split('#$#');
      if (stage < 4) {
        getMajorCampaignsReportDataRequestAction(projectId, districtId);
      }
    }
  }, [district]);

  const displayContent = (campaign) => {
    setAllocationCodeAction(campaign.allocation_code);
    setMajorCampaignAction(campaign);
    setViewedCampaign(campaign);
  };

  const handleViewCampaigns = () => {
    setDisplayStage4(true);
  };

  useEffect(() => {
    if (stage >= 4) {
      setDisplayStage4(true);
    }
  }, [stage]);

  return (
    <Opacity slide>
      {displayStage4 ? (
        <div>
          {majorCampaign ? (
            <div>
              <MajorCampaignDetails campaign={viewedCampaign || majorCampaign} />
            </div>
          ) : (
            <MajorCampaignRangeSelector
              viewContentCallBack={displayContent}
            />
          )}
        </div>
      ) : (
        <>
          <div className={classes.topContainer}>
            <MajorCampaignsPromo handleViewCampaigns={handleViewCampaigns} />
            <MajorCampaignSpecialOffer csm={csm} />
          </div>
          <MajorCampaignCaseStudy
            title={intl.formatMessage({
              id: 'EDUCATION.MAJOR.CAMPAIGNS.CASE.STUDY',
            })}
            coverImage="/media/icons/Education/MajorCampaigns/case-study.svg"
            caseStudyFile="/media/pdfs/NewarkDE_Pilot_Study_2021.pdf"
          />
          {/* Comment out for now */}
          {/* <MajorCampaignsTable majorCampaignsDetails={majorCampaignsDetails} /> */}
        </>
      )}
    </Opacity>
  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  municipalities: store.common.municipalities,
  majorCampaign: store.majorCampaigns.major_campaign,
  majorCampaigns: store.majorCampaigns.major_campaigns,
  majorCampaignsDetails: store.majorCampaigns.major_campaigns_details,
  csm: store.dashboard.csm,
});

export default injectIntl(connect(mapStateToProps, { ...actions, ...sharedActions, ...dashboardActions })(Main));
