/**
 *
 */
const initialState = {
  fetchState: 'no-request',
  responseMessage: null,
  responseStatus: '',
  list: [],
  collectionList: [],
  materialList: [],
  newReminder: {
    id: null,
    district: [],
    dateFrom: '',
    dateTo: '',
    collectionType: 'all_collections',
    collections: [],
    message: '',
    mat_id: ''
  },
  active: 'false',
  formLoading: false,
};

export default initialState;
