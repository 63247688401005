import React from 'react';
import ContentLoader from 'react-content-loader';

const ListLoading = ({
  width = '100%',
  height = 900
}) => (
  <ContentLoader
    speed={2}
    style={{ width, height }}
    backgroundColor={process.env.REACT_APP_COLOR_LOADING_BACKGROUND}
    foregroundColor={process.env.REACT_APP_COLOR__LOADING_FOREGROUND}
  >
    <rect x="0" y="0" rx="6" ry="6" width="15%" height="25" />
    <rect x="0" y="45" rx="6" ry="6" width="10%" height="15" />
    <rect x="0" y="75" rx="6" ry="6" width="15%" height="15" />
    <rect x="0" y="105" rx="6" ry="6" width="30%" height="15" />
    <rect x="0" y="135" rx="6" ry="6" width="50%" height="15" />
    <rect x="0" y="165" rx="6" ry="6" width="75%" height="15" />
    <rect x="0" y="210" rx="3" ry="3" width="100%" height="2" />

    <rect x="0" y="242" rx="6" ry="6" width="15%" height="25" />
    <rect x="0" y="287" rx="6" ry="6" width="10%" height="15" />
    <rect x="0" y="317" rx="6" ry="6" width="15%" height="15" />
    <rect x="0" y="347" rx="6" ry="6" width="30%" height="15" />
    <rect x="0" y="377" rx="6" ry="6" width="50%" height="15" />
    <rect x="0" y="407" rx="6" ry="6" width="75%" height="15" />
    <rect x="0" y="453" rx="3" ry="3" width="100%" height="2" />

    <rect x="0" y="483" rx="6" ry="6" width="15%" height="25" />
    <rect x="0" y="522" rx="6" ry="6" width="10%" height="15" />
    <rect x="0" y="552" rx="6" ry="6" width="15%" height="15" />
    <rect x="0" y="582" rx="6" ry="6" width="30%" height="15" />
    <rect x="0" y="612" rx="6" ry="6" width="50%" height="15" />
    <rect x="0" y="642" rx="6" ry="6" width="75%" height="15" />
    <rect x="0" y="687" rx="3" ry="3" width="100%" height="2" />

    <rect x="0" y="719" rx="6" ry="6" width="15%" height="25" />
    <rect x="0" y="764" rx="6" ry="6" width="10%" height="15" />
    <rect x="0" y="794" rx="6" ry="6" width="15%" height="15" />
    <rect x="0" y="824" rx="6" ry="6" width="30%" height="15" />
    <rect x="0" y="854" rx="6" ry="6" width="50%" height="15" />
    <rect x="0" y="884" rx="6" ry="6" width="75%" height="15" />

    {/* <rect x="0" y="232" rx="6" ry="6" width="15%" height="15" />
    <rect x="0" y="180" rx="6" ry="6" width="10%" height="10" />
    <rect x="0" y="200" rx="6" ry="6" width="15%" height="10" />
    <rect x="0" y="220" rx="6" ry="6" width="30%" height="10" />
    <rect x="0" y="240" rx="6" ry="6" width="50%" height="10" />
    <rect x="0" y="260" rx="6" ry="6" width="75%" height="10" />
    <rect x="0" y="290" rx="6" ry="6" width="100%" height="2" />

    <rect x="0" y="310" rx="6" ry="6" width="15%" height="15" />
    <rect x="0" y="335" rx="6" ry="6" width="10%" height="10" />
    <rect x="0" y="355" rx="6" ry="6" width="15%" height="10" />
    <rect x="0" y="375" rx="6" ry="6" width="30%" height="10" />
    <rect x="0" y="395" rx="6" ry="6" width="50%" height="10" />
    <rect x="0" y="415" rx="6" ry="6" width="75%" height="10" />
    <rect x="0" y="445" rx="6" ry="6" width="100%" height="2" />

    <rect x="0" y="465" rx="6" ry="6" width="15%" height="15" />
    <rect x="0" y="490" rx="6" ry="6" width="10%" height="10" />
    <rect x="0" y="515" rx="6" ry="6" width="15%" height="10" />
    <rect x="0" y="535" rx="6" ry="6" width="30%" height="10" />
    <rect x="0" y="555" rx="6" ry="6" width="50%" height="10" />
    <rect x="0" y="575" rx="6" ry="6" width="75%" height="10" />
    <rect x="0" y="605" rx="6" ry="6" width="100%" height="2" />

    <rect x="0" y="625" rx="6" ry="6" width="15%" height="15" />
    <rect x="0" y="650" rx="6" ry="6" width="10%" height="10" />
    <rect x="0" y="670" rx="6" ry="6" width="15%" height="10" />
    <rect x="0" y="690" rx="6" ry="6" width="30%" height="10" />
    <rect x="0" y="710" rx="6" ry="6" width="50%" height="10" />
    <rect x="0" y="730" rx="6" ry="6" width="75%" height="10" /> */}
    {/* <rect x="0" y="760" rx="6" ry="6" width="100%" height="2" /> */}

  </ContentLoader>
);

export default ListLoading;
