import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as actions from '../redux/actions';

/**
 * Components
 */
import Description from './Description';
import Title from './Title';
import Image from './Image';
import ThumbActivity from './ThumbActivity';
import Date from './Date';
import Active from './Active';
import Reject from './Reject';
import Preview from './Preview';
import SwitchOut from './SwitchOut';
import Pullback from './Pullback';
import Stats from './Stats';
import LocalRequirements from './LocalRequirements';
import SwitchBadge from './SwitchBadge';

/**
 * Apis
 */
import {
  rejectCampaign,
  restoreCampaign,
  rejectTemplate,
  restoreTemplate,
  campaignPreviewPrefix
} from '../apis';


/**
 * Styles
 */
const useStyles = makeStyles({
  container: {
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: '2px solid #eee',
    padding: 20,
    marginLeft: -20,
    marginRight: -20,
    '&.switch-item': {
      backgroundColor: '#edfff8'
    }
  },
  content: {
    display: 'flex'
  },
  imageContainer: {
    flex: 1,
    paddingRight: 20,
    maxWidth: 370
  },
  detailsContainer: {
    flex: 2,
    flexGrow: 2,
  },
  actionsContainer: {
    flex: 1,
    maxWidth: 200,
    justifyContent: 'space-between',
    flexDirection: 'column',
    // paddingLeft: 25
  },
  activitiesContainer: {
    paddingTop: 5,
    paddingBottom: 11,
    display: 'flex',
    alignItems: 'center'
  },
  dateContainer: {
    paddingBottom: 15,
  },
  moreBtn: {
    border: 'none',
    color: process.env.REACT_APP_COLOR_PRIMARY,
    backgroundColor: 'transparent',
    marginLeft: 5
  },
  topActions: {
    flex: 2,
    flexGrow: 2
  },
  bottomActions: {
    flex: 1,
    alignSelf: 'flex-end',
    // paddingTop: 12,
    textAlign: 'center'
  }
});


const CampaignItem = ({
  data,
  intl,
  switchItem,
  setSwitchItem,
  filter,
  district,
  campPage = null,
  switchedItem = null,
  onUpdate,
  uiOptions
}) => {
  const {
    title,
    activities,
    description,
    shortDescription,
    active,
    date,
    id,
    campaign_cover,
    preview,
    total_participation,
    average_rate,
    schedule_date,
    localRequirements
  } = data;
  const {
    container,
    imageContainer,
    detailsContainer,
    actionsContainer,
    activitiesContainer,
    content,
    moreBtn,
    topActions,
    bottomActions
  } = useStyles();


  const [desc, setDesc] = useState(shortDescription);
  const [descBtn, setDescBtn] = useState(intl.formatMessage({ id: 'GENERAL.MORE' }));
  const history = useHistory();

  /**
   * This method renders the more button after the
   * trimmed description text. In case that description of a campaign
   * is more than 255 characters.
   */
  const getDescription = () => {
    if (shortDescription) {
      return (
        <Description>
          {desc}
          <button
            type="button"
            className={moreBtn}
            onClick={() => {
              const isLess = descBtn === intl.formatMessage({ id: 'GENERAL.LESS' });
              setDescBtn(isLess ? intl.formatMessage({ id: 'GENERAL.MORE' }) : intl.formatMessage({ id: 'GENERAL.LESS' }));
              setDesc(isLess ? shortDescription : description);
            }}
          >
            {descBtn}
          </button>
        </Description>
      );
    }
    return (
      <Description>
        {description}
      </Description>
    );
  };


  /**
   * Open a page with an iPhone frame to preview the campaign
   */
  const previewCampaign = () => {
    window.open(`${campaignPreviewPrefix}${preview}`);
  };


  /**
   * Switchout the campaign with a template
   */
  const switchCampaign = () => {
    // Head over to switch process
    const swItem = {
      item: data,
      path: `/campaigns/switch/${id}`
    };
    if (switchItem.length) {
      const newSwitchItems = [...switchItem, swItem];
      setSwitchItem(newSwitchItems);
    } else {
      setSwitchItem([swItem]);
    }
    history.replace(
      (switchItem.length) ? `${switchItem[0].path}/${id}` : `/campaigns/switch/${id}`
    );
  };


  /**
   * Navigate to a page to create new campaign
   * User selects a start and end date
   * to create a new campaign
   */
  const goToCreateCampaign = () => {
    history.push('/campaigns/create_campaign', {
      template: data
    });
  };


  /**
   * Reject campaign or template
   */
  const reject = async () => {
    try {
      const data = {
        campaign_id: id,
        district_id: district.district_id
      };
      if (campPage === 'library') {
        // If it was the list of templates
        const { error } = await rejectTemplate(data);
        if (error) throw new Error(error);
      } else {
        // Default API
        const { error } = await rejectCampaign(data);
        if (error) throw new Error(error);
      }
      onUpdate({
        status: 'success',
        message: 'Successful!'
      });
    } catch (e) {
      onUpdate({
        status: 'error',
        message: 'There was a problem'
      });
    }
  };


  /**
   * Pull back when items are filtered: rejected
   */
  const pullback = async () => {
    try {
      const data = {
        campaign_id: id,
        district_id: district.district_id
      };
      if (campPage === 'library') {
        // If it was the list of templates
        const { error } = await restoreTemplate(data);
        if (error) throw new Error(error);
      } else {
        // Default API
        const { error } = await restoreCampaign(data);
        if (error) throw new Error(error);
      }
      onUpdate({
        status: 'success',
        message: 'Successful!'
      });
    } catch (E) {
      onUpdate({
        status: 'error',
        message: 'There was a problem'
      });
    }
  };


  /**
   * @reutrns true|false weather item was switched or not
   */
  const isSwitched = () => switchedItem && switchedItem === preview;


  /** Styles */
  let classes = container;
  if (isSwitched()) {
    classes = classNames(container, 'switch-item');
  }


  /**
   * Configuration object
   * This is used when we want to hide or show
   * elements in each campaign item
   * based on the page we're working with it
   */
  const {
    borderBottom: hasBorderBottom = true, // Show bottom borders
    borderTop: hasBorderTop = false, // Show top borders
    padding: hasPadding = true, // Has paddings
    preview: hasPreview = true, // Preview button
    reject: hasReject = true, // Reject button
    switch: hasSwitch = false, // Switch button
    pullback: hasPullback = (filter && filter === 'reject') || false, // Pullback button
    stat: hasStats = false, // Stats table
    use: hasUse = false, // Use button
    date: hasDate = true, // Show date
    createCampaign: hasCreateCampaign = false // Create campaign
  } = uiOptions;

  return (
    <div
      className={classes}
      style={{
        borderBottom: hasBorderBottom ? '2px solid #eee' : 'none',
        borderTop: hasBorderTop ? '2px solid #eee' : 'none',
        paddingTop: hasPadding ? 25 : 20
      }}
    >
      <div>
        {
          hasDate
            ? (
              <Date>
                {
                  date || ''
                }
                {
                  active
                  && date
                    ? (
                      <Active />
                    ) : null
                }
              </Date>
            ) : null
        }
      </div>
      <div className={classNames(content, 'row')}>
        <div className={classNames(imageContainer, 'col-sm-12 col-md-4')}>
          <Image src={campaign_cover} title={title} />
        </div>
        <div className={classNames(detailsContainer, 'col-sm-12 col-md-8')}>
          <Title>
            {title}
            {
              isSwitched()
                ? (
                  <SwitchBadge switchItem />
                ) : null
            }
          </Title>
          <div className={classNames(activitiesContainer, 'clearfix')}>
            {
              activities
              && activities.length
                ? (
                  activities.map(({ id, type: activity }) => (
                    <ThumbActivity activity={activity} key={id} />
                  ))
                ) : null
            }
          </div>
          {
            getDescription()
          }
          {
            localRequirements
              ? (
                <LocalRequirements>
                  {localRequirements}
                </LocalRequirements>
              ) : null
          }
        </div>
        <div className={classNames(actionsContainer, 'pl-md-3 px-sm-4')}>
          <div className={classNames(topActions, 'px-sm-2')}>
            {
              hasPreview
                ? (
                  <Preview onClick={previewCampaign} />
                ) : null
            }
            {
              hasSwitch
                ? (
                  <SwitchOut
                    onClick={() => {
                      if (hasCreateCampaign) return goToCreateCampaign();
                      return switchCampaign();
                    }}
                    use={hasUse}
                  />
                ) : null
            }
            {
              hasStats
              && !hasPullback
                ? (
                  <Stats
                    participate={total_participation}
                    avgrate={average_rate}
                    scheduled={schedule_date}
                  />
                ) : null
            }
            {
              hasPullback
                ? (
                  <Pullback onClick={pullback} />
                ) : null
            }
          </div>
          <div className={bottomActions}>
            {
              hasReject
              && !hasPullback
              && !active
                ? (
                  <Reject onClick={reject} />
                ) : null
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  switchItem: store.campaign.switchItem,
  district: store.common.district
});

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(CampaignItem)
);
