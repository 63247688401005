import React from 'react';

const CampaignPicture = ({
  color,
  className,
}) => (
  <svg width="1.5em" height="1.5em" viewBox="0 -61 512.00001 512" className={className}>
    <g>
      <path d="m220.960938 279.902344 170.949218 90.074218v-369.976562l-170.949218 90.074219zm0 0" data-original="#000000" className="active-path" data-old_color="#000000" fill={color} />
      <path d="m451.953125 169.988281h60.046875v30h-60.046875zm0 0" data-original="#000000" className="active-path" data-old_color="#000000" fill={color} />
      <path d="m486.78125 73.171875 11.480469 27.714844-55.480469 22.984375-11.480469-27.71875zm0 0" data-original="#000000" className="active-path" data-old_color="#000000" fill={color} />
      <path d="m486.769531 296.804688-55.480469-22.980469 11.480469-27.71875 55.480469 22.980469zm0 0" data-original="#000000" className="active-path" data-old_color="#000000" fill={color} />
      <path d="m190.960938 99.933594h-130.914063v30.023437h-5.015625c-30.34375 0-55.03125 24.6875-55.03125 55.03125s24.6875 55.03125 55.03125 55.03125h5.015625v30.027344h33.589844l33.027343 120.230469h97.324219l-33.027343-115.308594zm0 0" data-original="#000000" className="active-path" data-old_color="#000000" fill={color} />
    </g>
  </svg>
);

export default CampaignPicture;
