import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  useLocation, useHistory, Link
} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { isValidDate, checkValidDistrict } from '../../../../utils';
import ReportPDF from './ReportPDF';
import {
  AutoComplete,
  CheckBox,
  Typography,
  IconButton
} from '../../../components';
import {
  PdfIcon,
  BackIcon,
  LineThroughDotIcon,
  DisplayColorIcon,
  InfoIcon
} from '../../../../assets/icons';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../../../../_metronic/partials/content/Portlet';
import { SpiderChart, SummaryHeader, RCSpinner } from '../../components';
import * as actions from '../redux/actions';
import * as sharedActions from '../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_PRIMARY,
  },
  border: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
  },
  description: {
    textAlign: 'right'
  },
  smallerThanMdHide: {
    [theme.breakpoints.between('xs', 'md')]: {
      display: 'none !important'
    }
  },
  smallerThanMdShow: {
    [theme.breakpoints.between('md', 'xl')]: {
      display: 'none !important'
    },
  },
  onlyMd: {
    [theme.breakpoints.only('md')]: {
      display: 'block !important'
    },
  },
  currentPeriod: {
    color: process.env.REACT_APP_COLOR_REPORT_1
  },
  previousPeriod: {
    color: process.env.REACT_APP_COLOR_PRIMARY_LIGHT
  },
  lastYear: {
    color: process.env.REACT_APP_COLOR_DELETE
  },
  noData: {
    minHeight: '300px'
  },
  noDataColor: {
    color: process.env.REACT_APP_COLOR_GRAY_DARK
  },
  pdfDisable: {
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_GRAY_DARK,
  }

}));

const Summary = ({
  intl,
  fetchState,
  pageViewsReportRequest,
  data,
  pageType,
  district,
  setDistrictAction,
  fromDate,
  toDate,
  setDateRangeAction,
  setPageType,
  detailData,
  municipalities,
  county,
  counties,
  setCountyAction,
  isCounty,
  setIsCountyAction,
  hasCounty
}) => {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);
  const [displayCurrentPeriod, setDisplayCurrentPeriod] = useState(true);
  const [displayPreviousPeriod, setDisplayPreviousPeriod] = useState(true);
  const [displayLastYear, setDisplayLastYear] = useState(true);
  const [current, setCurrent] = useState([]);
  const [previous, setPrevious] = useState([]);
  const [last, setLast] = useState([]);
  const [wasteType, setWasteType] = useState([]);
  const [chartLabel, setChartLabel] = useState([]);
  const isSendRequest = fetchState === 'send-request';
  const [pdfSpinner, setPdfSpinner] = useState(false);

  useEffect(() => {
    if (history.location.search) {
      if (queryParams.get('district') && checkValidDistrict(
        queryParams.get('district').replace('-', '#$#'),
        municipalities,
        'district_id'
      )) {
        setDistrictAction({
          district_id: queryParams.get('district').replace('-', '#$#'),
          district_nm: municipalities.filter((item) => item.district_id === queryParams.get('district').replace('-', '#$#'))[0].district_nm,
        });
        setIsCountyAction(false);
      } else if (municipalities.length === 1) {
        setDistrictAction(municipalities[0]);
      } else {
        setDistrictAction({
          district_id: '#all-districts#',
          district_nm: 'All Districts'
        });
      }
      if (queryParams.get('county') && checkValidDistrict(
        queryParams.get('county'),
        counties,
        'county_id'
      ) && hasCounty !== null) {
        setCountyAction({
          county_id: queryParams.get('county'),
          county_nm: counties.filter((item) => item.county_id === queryParams.get('county'))[0].county_nm,
        });
        setIsCountyAction(true);
      } else {
        setCountyAction(counties[0]);
      }
      if (queryParams.get('from') && queryParams.get('to') && isValidDate(queryParams.get('from')) && isValidDate(queryParams.get('to'))) {
        setDateRangeAction({
          fromDate: queryParams.get('from'),
          toDate: queryParams.get('to'),
        });
      }
    } else if (municipalities.length === 1) {
      setDistrictAction(municipalities[0]);
    } else {
      setDistrictAction({
        district_id: '#all-districts#',
        district_nm: 'All Districts'
      });
    }
  }, []);

  useEffect(() => {
    if (isCounty) {
      history.replace({
        pathname: '/report/page-views',
        search: `?county=${county.county_id}&from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
      });
    } else {
      history.replace({
        pathname: '/report/page-views',
        search: `?district=${district.district_id === '#all-districts#' ? 'all-districts' : district.district_id.replace('#$#', '-')}&from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
      });
    }
  }, [fromDate, toDate, district, county, isCounty]);

  useEffect(() => {
    if (data.detail) {
      const tempCurrent = [];
      const tempPrevious = [];
      const tempLast = [];
      const temp = [];

      const tempWaste = Object.keys(data.detail);
      Object.values(data.detail).map((item, index) => {
        tempCurrent.push(item.total.current_period_percentage < 60
          ? item.total.current_period_percentage : 60);
        tempPrevious.push(item.total.previous_period_percentage < 60
          ? item.total.previous_period_percentage : 60);
        tempLast.push(item.total.last_year_percentage < 60 ? item.total.last_year_percentage : 60);
        temp.push({
          id: tempWaste[index],
          current: item.total.current_period_percentage,
          previous: item.total.previous_period_percentage,
          last: item.total.last_year_percentage
        });
      });
      setChartLabel(tempWaste);
      setCurrent(tempCurrent);
      setPrevious(tempPrevious);
      setLast(tempLast);
      setWasteType(temp);
    }
  }, [data]);

  const generatePDF = async () => {
    setPdfSpinner(true);
    const blob = await pdf((
      <ReportPDF
        interactionAtAGlance={intl.formatMessage({ id: 'REPORT.PAGE_VIEWS.SUMMARY.HEADER' })}
        currentPeriod={`${intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_PERIOD' })}:`}
        date={`${fromDate.slice(0, 13)} - ${toDate.slice(0, 13)}`}
        reportingDistrict={isCounty ? `Reporting County : ${county.county_nm}`
          : `Reporting District : ${district.district_id !== '#all-districts#' ? district.district_nm : 'All Districts'}`}
        detailData={detailData}
        isCounty={isCounty}
      />
    )).toBlob();
    saveAs(blob, 'Page Views Report.pdf');
    setPdfSpinner(false);
  };

  return (
    <Portlet>
      <PortletHeader
        title={(
          <Link to="/report/dashboard">
            <Button
              classes={{
                label: classes.label,
              }}
              startIcon={<BackIcon color={process.env.REACT_APP_COLOR_PRIMARY} />}
            >
              {intl.formatMessage({ id: 'REPORT.GENERAL.BACK' })}
            </Button>
          </Link>
        )}
        toolbar={(
          <PortletHeaderToolbar>
            <Button
              disabled={!Object.values(detailData).length}
              classes={{
                label: !Object.values(detailData).length ? classes.pdfDisable : classes.label,
              }}
              startIcon={(
                <PdfIcon
                  color={!Object.values(detailData).length ? process.env.REACT_APP_COLOR_GRAY_DARK
                    : process.env.REACT_APP_COLOR_PRIMARY}
                />
              )}
              onClick={generatePDF}
            >
              {intl.formatMessage({ id: 'REPORT.GENERAL.PDF' })}
            </Button>
            <RCSpinner isActive={pdfSpinner} />
          </PortletHeaderToolbar>
        )}
      />
      <PortletBody>
        <SummaryHeader
          title={intl.formatMessage({ id: 'REPORT.PAGE_VIEWS.SUMMARY.HEADER' })}
          handleDataRequest={pageViewsReportRequest}
        />
        <div className={`d-flex align-items-center ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
          <CheckBox
            checked={displayCurrentPeriod}
            onChange={setDisplayCurrentPeriod}
            label={(
              <div className="d-flex align-items-center ml-2">
                <LineThroughDotIcon color={process.env.REACT_APP_COLOR_REPORT_1} />
                <Typography className="ml-2 mr-5" variant="bodySemiBold">
                  {intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_PERIOD' })}
                </Typography>
              </div>
            )}
          />
          <CheckBox
            checked={displayPreviousPeriod}
            onChange={setDisplayPreviousPeriod}
            label={(
              <div className="d-flex align-items-center ml-2">
                <LineThroughDotIcon color={process.env.REACT_APP_COLOR_PRIMARY_LIGHT} />
                <Typography className=" ml-2 mr-5" variant="bodySemiBold">
                  {intl.formatMessage({ id: 'REPORT.GENERAL.PREVIOUS_PERIOD' })}
                  <IconButton
                    tooltip={intl.formatMessage({ id: 'REPORT.GENERAL.PREVIOUS_PERIOD.TOOLTIP' })}
                  >
                    <InfoIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                  </IconButton>
                </Typography>
              </div>
            )}
          />
          <CheckBox
            checked={displayLastYear}
            onChange={setDisplayLastYear}
            label={(
              <div className="d-flex align-items-center ml-2">
                <LineThroughDotIcon color={process.env.REACT_APP_COLOR_DELETE} />
                <Typography className=" ml-2 mr-5" variant="bodySemiBold">
                  {intl.formatMessage({ id: 'REPORT.GENERAL.LAST_YEAR' })}
                </Typography>
              </div>
            )}
          />
        </div>
        <div className={`w-50 m-auto pt-5 pb-5 d-flex justify-content-center align-items-center ${classes.smallerThanMdHide} ${classes.onlyMd}`}>

          <SpiderChart
            sixtyPercent
            isLoading={isSendRequest}
            labels={chartLabel}
            values={[
              displayCurrentPeriod ? current : null,
              displayPreviousPeriod ? previous : null,
              displayLastYear ? last : null,
            ]}
            colors={[
              process.env.REACT_APP_COLOR_REPORT_1,
              process.env.REACT_APP_COLOR_PRIMARY_LIGHT,
              process.env.REACT_APP_COLOR_DELETE,
            ]}
          />
        </div>

        <div className={`mt-3 ${classes.smallerThanMdShow}`}>
          {data.detail
            ? (
              <AutoComplete
                optionTitle="type"
                title={intl.formatMessage({ id: 'REPORT.MATERIALS.MOBILE.WASTE_TYPE' })}
                isClearable={false}
                options={[
                  { type: 'Depots', id: 'Depots' }, { type: 'Events', id: 'Events' },
                  { type: 'General Pages', id: 'Pages' }, { type: 'Material Searches', id: 'Material Searches' },
                  { type: 'Report a Problem', id: 'Report a Problem' }, { type: 'News', id: 'News' },
                  { type: 'Campaigns', id: 'Campaigns' }, { type: 'Calendar', id: 'Calendar' }, { type: 'Collection Requirements', id: 'Collection Requirements' }
                ]}
                onChange={(data) => {
                  setPageType(data);
                }}
                value={pageType}
              />
            ) : null}
          <Typography className="mt-3 ml-1" variant="body">
            {pageType.type}
          </Typography>

          <div className={`mt-3 ml-1 pr-3 pl-0 pb-3  ${classes.border} ${classes.smallerThanMdShow}`}>
            <Typography className={`mt-3 ${classes.currentPeriod}`} variant="numberSmall">
              {/* {current[pageType.id]} */}
              {wasteType.length > 0 && (wasteType.filter((item) => item.id === pageType.id)[0].current || '0')}
            </Typography>
            <div className="d-flex justify-content-start align-items-center mt-2">
              <DisplayColorIcon color={process.env.REACT_APP_COLOR_REPORT_1} />
              <Typography className="text-center" variant="bodySemiBold">
                {intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_PERIOD' })}
              </Typography>
            </div>
          </div>

          <div className={`mt-3 ml-1 pr-3 pl-0 pb-3 ${classes.border} ${classes.smallerThanMdShow}`}>
            <Typography className={`mt-3 ${classes.previousPeriod}`} variant="numberSmall">
              {/* {data.previous_period} */}
              {wasteType.length > 0 && (wasteType.filter((item) => item.id === pageType.id)[0].previous || '0')}
            </Typography>
            <div className="d-flex justify-content-start align-items-center mt-2">
              <DisplayColorIcon color={process.env.REACT_APP_COLOR_PRIMARY_LIGHT} />
              <Typography className="text-center" variant="bodySemiBold">
                {intl.formatMessage({ id: 'REPORT.GENERAL.PREVIOUS_PERIOD' })}
                <IconButton
                  tooltip={intl.formatMessage({ id: 'REPORT.GENERAL.PREVIOUS_PERIOD.TOOLTIP' })}
                >
                  <InfoIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                </IconButton>
              </Typography>
            </div>
          </div>

          <div className={`mt-3 ml-1 pr-3 pl-0 pb-3 ${classes.smallerThanMdShow}`}>
            <Typography className={`mt-3 ${classes.lastYear}`} variant="numberSmall">
              {/* {data.last_year} */}
              {wasteType.length > 0 && (wasteType.filter((item) => item.id === pageType.id)[0].last || '0')}
            </Typography>
            <div className="d-flex justify-content-start align-items-center mt-2">
              <DisplayColorIcon color={process.env.REACT_APP_COLOR_DELETE} />
              <Typography className="text-center" variant="bodySemiBold">
                {intl.formatMessage({ id: 'REPORT.GENERAL.LAST_YEAR' })}
              </Typography>
            </div>
          </div>

        </div>

      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.pageViewsReport.fetchState,
  district: store.common.district,
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
  pageType: store.pageViewsReport.pageType,
  detailData: store.pageViewsReport.data,
  municipalities: store.common.municipalities,
  county: store.common.county,
  counties: store.common.counties,
  isCounty: store.common.isCounty,
  hasCounty: store.auth.user_info.counties,
});

export default injectIntl(
  connect(
    mapStateToProps,
    { ...actions, ...sharedActions }
  )(Summary)
);
