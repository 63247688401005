import actionTypes from './actionTypes';

export const getWWtKDataRequestAction = (project_id, district_id) => ({
  type: actionTypes.GET_WWTK_DATA_REQUEST,
  payload: { project_id, district_id }
});

export const getWWtKDataReceiveAction = (data) => ({
  type: actionTypes.GET_WWTK_DATA_RECEIVE,
  payload: data
});

export const getWWtKDataFailAction = (error) => ({
  type: actionTypes.GET_WWTK_DATA_FAIL,
  payload: error
});

export const setCurrentQuestionAction = (index) => ({
  type: actionTypes.SET_CURRENT_QUESTION_INDEX,
  index
});

export const setShowCASySettingsAction = (value) => ({
  type: actionTypes.SET_SHOW_CASY_SETTINGS,
  value
});

export const feedbackRequest = (meta, feedback, type = 'wwtk') => ({
  type: actionTypes.FEEDBACK_REQUEST_WWTK,
  payload: { meta, feedback, type }
});

export const feedbackReceive = (data) => ({
  type: actionTypes.FEEDBACK_RECEIVE_WWTK,
  payload: data
});

export const feedbackFail = (error) => ({
  type: actionTypes.FEEDBACK_FAIL_WWTK,
  payload: error
});
