import actionTypes from './actionTypes';

// Event actions for setting loading status
export const setLoadingAction = (status) => ({
  type: actionTypes.SET_EVENT_LOADING_ACTION,
  payload: status
});

export const setSnackbarAction = (status) => ({
  type: actionTypes.SET_EVENT_SNACKBAR_ACTION,
  payload: status
});

// Event actions for setting the stage of event wizard
export const setWizardStageAction = (stage) => ({
  type: actionTypes.SET_EVENT_WIZARD_STAGE_ACTION,
  payload: { stage }
});

// Event actions for setting event wizard
export const setEventWizardIdAction = (id) => ({
  type: actionTypes.SET_EVENT_WIZARD_ID_ACTION,
  payload: { id }
});

// Event actions for fetching all events
export const getEventsListRequest = ({ locality }) => ({
  type: actionTypes.GET_EVENTS_LIST_REQUEST,
  payload: { locality }
});

export const getEventsListReceive = (data) => ({
  type: actionTypes.GET_EVENTS_LIST_RECEIVE,
  payload: data
});

export const getEventsListFail = (error) => ({
  type: actionTypes.GET_EVENTS_LIST_FAIL,
  payload: { ...error }
});

// Event actions for Creating an event
export const createEventRequest = (event) => ({
  type: actionTypes.CREATE_EVENT_REQUEST,
  payload: event
});

export const createEventReceive = (data) => ({
  type: actionTypes.CREATE_EVENT_RECEIVE,
  payload: data
});

export const createEventFail = (error) => ({
  type: actionTypes.CREATE_EVENT_FAIL,
  payload: { ...error }
});

// Event actions for editing an event
export const editEventRequest = (event) => ({
  type: actionTypes.EDIT_EVENT_REQUEST,
  payload: event
});

export const editEventReceive = (data) => ({
  type: actionTypes.EDIT_EVENT_RECEIVE,
  payload: data
});

export const editEventFail = (error) => ({
  type: actionTypes.EDIT_EVENT_FAIL,
  payload: { ...error }
});

// Event actions for fetching google address suggestions
export const getAddressSuggestionsRequest = (address) => ({
  type: actionTypes.GET_EVENT_ADDRESS_SUGGESTIONS_REQUEST,
  payload: address
});

export const getAddressSuggestionsReceive = (data) => ({
  type: actionTypes.GET_EVENT_ADDRESS_SUGGESTIONS_RECEIVE,
  payload: { data }
});

export const getAddressSuggestionsFail = (error) => ({
  type: actionTypes.GET_EVENT_ADDRESS_SUGGESTIONS_FAIL,
  payload: { ...error }
});

// Event actions for removing an event
export const deleteEventRequest = ({ locality, event }) => ({
  type: actionTypes.DELETE_EVENT_REQUEST,
  payload: { locality, event }
});

export const deleteEventReceive = (data) => ({
  type: actionTypes.DELETE_EVENT_RECEIVE,
  payload: { data }
});

export const deleteEventFail = (error) => ({
  type: actionTypes.DELETE_EVENT_FAIL,
  payload: { ...error }
});
