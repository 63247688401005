import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, CustomButton, TextField, IconButton } from '../../../components';
import {
  Portlet, PortletBody, PortletHeader,
} from '../../../../../_metronic/partials/content/Portlet';
import { utils, writeFile } from 'xlsx-js-style'
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: '#3398fc',
    marginRight: 0,
    marginLeft: 0,
    marginTop: '0 !important',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: '8px 0',
    minHeight: 72,
  },
  tableHeaderFont: {
    color: '#FFF'
  },
  tableMaterialsItem: {
    color: '#3398fc',
    textDecoration: 'underline'
  },
  tableRow: {
    borderBottom: '1px solid lightgrey',
    borderRight: '1px solid lightgrey',
    borderLeft: '1px solid lightgrey',
    marginLeft: 0,
    marginRight: 0,
  },
  materialsTable: {
    // margin: '0 16px',
    borderRadius: '0 !important',
    boxShadow: 'none !important',
  },
  tableLabel: {
    borderBottom: 'none !important',
    borderRadius: '0 !important',
    padding: '0 !important',
    marginBottom: 15,
  },
  tableBody: {
    padding: '0 !important'
  },
  materialsLabel: {
    color: '#6C7293',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },
  detailReportButton: {
    width: '85%',
  },
  modalRow: {
    margin: '5px 10px',
  },
  downloadButton: {
    width: 180,
    justifyContent: 'flex-end',

  },
  subHeaderRow: {
    margin: '15px 0px',
    padding: 'unset',
  },
  subTableHeader: {
    width: '100%',
    margin: 'unset',
    padding: '5px 0px',
    alignContent: 'center',
    backgroundColor: '#E5F2FF',
  },
  subTableHeaderFont: {
    color: '#2A95FF'
  },
  topButtonContainer: {
    display: 'flex',
    gap: 15,
  },
  searchBox: {
    flex: 1,
    width: 400,
    [theme.breakpoints.down('800')]: {
      // width: '100%',
      marginBottom: '20px'
    },
  },
  icons: {
    color: process.env.REACT_APP_COLOR_PRIMARY,
    fontSize: 'large'
  },
  searchedMaterials: {
    backgroundColor: '#E5F2FF',
  },
  depot: {
    '&:hover': {
      textDecoration: 'underline',
    }
  }
}));

const MaterialsTable = ({
  intl,
  data,
  thisYear,
  lastYear,
  searchTerm,
  onSearchTermChange,
  handleEditClick,
  handleCollectionTypeEditClick,
  handleCollectionTypeViewClick,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [material, setMaterial] = useState(null);
  const [searchedMaterials, setSearchedMaterials] = useState([]);
  const [materialData, setMaterialData] = useState(null);
  const [searchButtonClicked, setSearchButtonClicked] = useState(false);
  const [numOfSearchResults, setNumOfSearchResults] = useState(0);


  useEffect(() => {
    const groupedData = data.reduce((acc, item) => {
      const key = item.waste_type_admin_nm;

      if (!acc[key]) {
        acc[key] = [];
      }
      item = JSON.parse(JSON.stringify(item));

      item.materials = item.mats.split(',');

      acc[key].push(item);

      return acc;
    }, {});

    setMaterialData(groupedData);

  }, []);

  useEffect(() => {
    if (!searchTerm.length && searchedMaterials.length) {
      setSearchedMaterials([]);
    }
    if (searchTerm.length && numOfSearchResults === 0) {
      setSearchButtonClicked(false);
    }

  }, [searchTerm]);

  useEffect(() => {
    if (searchTerm.length && searchedMaterials && searchedMaterials.length && searchButtonClicked && numOfSearchResults > 0) {
      document.querySelector(`.${classes.searchedMaterials}`).scrollIntoView({ behavior: 'smooth', block: 'center' });
      setSearchButtonClicked(false);
    }
  }, [searchedMaterials]);


  const handleEdit = (item) => {
    handleEditClick(item);
  };

  const handleCollectionTypeEdit = (item) => {
    handleCollectionTypeEditClick(item);
  };

  data = JSON.parse(JSON.stringify(data));

  data.forEach((item) => {
    item.materials = item.mats.split(',');
  })

  const handleSearch = () => {
    if (searchTerm && searchTerm.length) {
      setSearchButtonClicked(false);
      const searchResults = [];
      const res = data.map((item) => {
        if (item.materials.some((mat) => mat.toLowerCase().includes(searchTerm.toLowerCase()))) {
          item.searchResult = true;
          searchResults.push(item);
          return item;
        }
        return item;
      });
      if (searchResults && searchResults.length) {
        setNumOfSearchResults(searchResults.length);
        const groupedData = res.reduce((acc, item) => {
          const key = item.waste_type_admin_nm;
  
          if (!acc[key]) {
            acc[key] = [];
          }
          item = JSON.parse(JSON.stringify(item));
  
          item.materials = item.mats.split(',');
  
          acc[key].push(item);
  
          return acc;
        }, {});
  
        setMaterialData(groupedData);
  
  
        setSearchedMaterials([...new Set(searchResults.map((item) => {
          return item.waste_type_admin_nm;
        }))])
        setSearchButtonClicked(true);
      } else {
        setNumOfSearchResults(0);
        setSearchButtonClicked(true);
      }

    }

  }

  const exportData = () => {
    // STEP 1: Create a new workbook
    const wb = utils.book_new();

    // STEP 2: Create data rows and styles

    const ws = utils.json_to_sheet([]);
    let row = [];
    let headers = [];
    data.forEach((item, index) => {
      if (!headers.includes(item.waste_type_admin_nm)) {
        // add new row for new waste type
        utils.sheet_add_aoa(ws, [[
          { v: `Waste Type: ${item.waste_type_admin_nm}`, t: "s", s: { font: { name: "Calibri", sz: 18 }, alignment: { wrapText: true }, fill: { fgColor: { rgb: "bec3c6" } } } },
          { v: ``, t: "s", s: { font: { name: "Calibri", sz: 18 }, alignment: { wrapText: true }, fill: { fgColor: { rgb: "bec3c6" } } } },
          { v: ``, t: "s", s: { font: { name: "Calibri", sz: 18 }, alignment: { wrapText: true }, fill: { fgColor: { rgb: "bec3c6" } } } },
          { v: ``, t: "s", s: { font: { name: "Calibri", sz: 18 }, alignment: { wrapText: true }, fill: { fgColor: { rgb: "bec3c6" } } } },
          { v: ``, t: "s", s: { font: { name: "Calibri", sz: 18 }, alignment: { wrapText: true }, fill: { fgColor: { rgb: "bec3c6" } } } },
          { v: ``, t: "s", s: { font: { name: "Calibri", sz: 18 }, alignment: { wrapText: true }, fill: { fgColor: { rgb: "bec3c6" } } } },
        ]], { origin: -1 });
        utils.sheet_add_aoa(ws, [[
          { v: `Collection: ${item.coll_admin_nm ? item.coll_admin_nm : ''}`, t: "s", s: { font: { name: "Calibri", sz: 18 }, alignment: { wrapText: true }, fill: { fgColor: { rgb: "bec3c6" } } } },
          { v: ``, t: "s", s: { font: { name: "Calibri", sz: 18 }, alignment: { wrapText: true }, fill: { fgColor: { rgb: "bec3c6" } } } },
          { v: ``, t: "s", s: { font: { name: "Calibri", sz: 18 }, alignment: { wrapText: true }, fill: { fgColor: { rgb: "bec3c6" } } } },
          { v: ``, t: "s", s: { font: { name: "Calibri", sz: 18 }, alignment: { wrapText: true }, fill: { fgColor: { rgb: "bec3c6" } } } },
          { v: ``, t: "s", s: { font: { name: "Calibri", sz: 18 }, alignment: { wrapText: true }, fill: { fgColor: { rgb: "bec3c6" } } } },
          { v: ``, t: "s", s: { font: { name: "Calibri", sz: 18 }, alignment: { wrapText: true }, fill: { fgColor: { rgb: "bec3c6" } } } },

        ]], { origin: -1 });
        utils.sheet_add_aoa(ws, [[
          { v: `Category`, t: "s", s: { alignment: { wrapText: true }, font: { bold: true, color: { rgb: "000000" }, name: "Calibri", sz: 15 }, fill: { fgColor: { rgb: "d8843b" } } } },
          { v: `Materials`, t: "s", s: { alignment: { wrapText: true }, font: { bold: true, color: { rgb: "000000" }, name: "Calibri", sz: 15 }, fill: { fgColor: { rgb: "d8843b" } } } },
          { v: `Single Family (SF) Instructions`, t: "s", s: { alignment: { wrapText: true }, font: { bold: true, color: { rgb: "000000" }, name: "Calibri", sz: 15 }, fill: { fgColor: { rgb: "d8843b" } } } },
          { v: `Depots`, t: "s", s: { alignment: { wrapText: true }, font: { bold: true, color: { rgb: "000000" }, name: "Calibri", sz: 15 }, fill: { fgColor: { rgb: "d8843b" } } } },
          { v: `Events`, t: "s", s: { alignment: { wrapText: true }, font: { bold: true, color: { rgb: "000000" }, name: "Calibri", sz: 15 }, fill: { fgColor: { rgb: "d8843b" } } } },

        ]], { origin: -1 });

        headers.push(item.waste_type_admin_nm)
      }
      // material category rows
      utils.sheet_add_aoa(ws, [[
        { v: `${item.mat_cat_admin_nm}`, t: "s", s: { font: { name: "Calibri", sz: 15 }, alignment: { wrapText: true } } },
        { v: `${item.mats ? item.mats.replace(/,/g, "\n") : ''}`, t: "s", s: { font: { name: "Calibri", sz: 15 }, alignment: { wrapText: true } } },
        { v: `${item.disposal_body ? item.disposal_body.replace(/<[^>]+>/g, '') : ''}`, t: "s", s: { font: { name: "Calibri", sz: 15 }, alignment: { wrapText: true } } },
        { v: `${item.depotnm ? item.depotnm.replace(/,/g, "\n") : ''}`, t: "s", s: { font: { name: "Calibri", sz: 15 }, alignment: { wrapText: true } } },
        { v: `${item.eventnm ? item.eventnm.replace(/,/g, "\n") : ''}`, t: "s", s: { font: { name: "Calibri", sz: 15 }, alignment: { wrapText: true } } },
      ]], { origin: -1 });
    });

    const wscols = [
      { wch: 40 },
      { wch: 50 },
      { wch: 80 },
      { wch: 80 },
      { wch: 40 },
      { wch: 40 }
    ];

    ws['!cols'] = wscols;
    utils.book_append_sheet(wb, ws, 'Sheet1');

    writeFile(wb, 'MaterialReport.xlsx');
  };

  return (
    <div>
      {
        <Portlet className={`h-100 position-relative ${classes.materialsTable}`}>
          <PortletHeader
            className={`${classes.tableLabel} w-100`}
            title={(
              <div className="d-flex align-items-center w-100 justify-content-between">
                <Typography variant="h1" className={`${classes.materialsLabel}`}>
                  {/* Bulk Items Pick-ups - Detail Report */}
                  Material Report
                </Typography>
                <div className={classes.topButtonContainer}>
                  <div className={`${classes.searchBox}`}>
                    <TextField
                      label={intl.formatMessage({ id: 'CATEGORY.HEADER.MATERIAL_SEARCH' })}
                      value={searchTerm}
                      onChange={onSearchTermChange}
                      hasClear
                      invalidText={searchTerm && numOfSearchResults === 0 && searchButtonClicked ? 'No materials found' : ''}
                    />
                  </div>
                  <div className={classes.topButtonContainer} style={{ maxHeight: '52.44px' }}>
                    <CustomButton
                      paddingVertical={4}
                      className={classes.downloadButton}
                      variant={'outlined'}
                      label={'Search'}
                      onClick={() => handleSearch()}
                    />
                    <CustomButton
                      paddingVertical={4}
                      className={classes.downloadButton}
                      variant={'contained'}
                      label={'Download Report'}
                      onClick={() => {
                        exportData();
                      }}
                    />
                  </div>
                </div>

              </div>
            )}
          />
          {
            materialData && (
              <PortletBody className={`${classes.tableBody}`}>
                <div className={`row d-flex align-items-center mt-4 ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableHeader}`}>
                  <div className="col-3">
                    <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                      Waste Type
                    </Typography>
                  </div>
                  <div className="col-3">
                    <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                      Collection Type
                    </Typography>
                  </div>
                  <div className="col-2">
                    <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                      # of Categories
                    </Typography>
                  </div>
                  <div className="col-2">
                    <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                      # of Materials
                    </Typography>
                  </div>
                  <div className="col-1">
                    <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                    </Typography>
                  </div>
                </div>
                <div className={classes.dataTable}>
                  {
                    Object.keys(materialData).map((item, index) => (
                      <div
                        key={item.problem_id}
                        className={`row d-flex align-items-center pt-4 pb-4 ${classes.border} ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableRow}`}
                      >
                        <div className="col-3">
                          <div className='d-flex'>
                            <Typography variant="bodyTableSemiBold" className={`${classes.ranking}`}>
                              {materialData[item][0].waste_type_admin_nm}
                            </Typography>
                            {/* <IconButton
                              tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
                              onClick={() => handleEdit(materialData[item][0])}

                            >
                              <EditIcon className={classes.icons} />
                            </IconButton> */}
                          </div>
                        </div>
                        <div className="col-3">
                          <div className='d-flex'>
                            <Typography variant="bodyTableSemiBold" className={`${classes.ranking}`}>
                              {materialData[item][0].coll_admin_nm}
                            </Typography>
                            {
                              materialData[item][0].coll_admin_nm && (
                                <div>
                                  {/* <IconButton
                                    tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
                                    onClick={() => handleCollectionTypeEdit(materialData[item][0])}
                                  >
                                    <EditIcon className={classes.icons} />

                                  </IconButton> */}
                                </div>
                              )
                            }
                          </div>
                        </div>
                        <div className="col-2">
                          <Typography variant="bodyTableSemiBold" className={`${classes.ranking}`}>
                            {materialData[item].length}
                          </Typography>
                        </div>
                        <div className="col-2">
                          <Typography variant="bodyTableSemiBold" className={`${classes.ranking}`}>
                            {materialData[item].reduce((acc, mat) => acc + mat.mats.split(',').length, 0)}
                          </Typography>
                        </div>
                        <div className="col row d-flex align-items-center ml-2">
                          <CustomButton
                            paddingVertical={4}
                            className={classes.detailReportButton}
                            variant={`${searchedMaterials.includes(item) ? 'outlined' : 'contained'}`}
                            label={`${searchedMaterials.includes(item) ? 'Hide Details' : 'Details'}`}
                            onClick={() => {
                              if (searchedMaterials.includes(item)) {
                                setSearchedMaterials(searchedMaterials.filter((mat) => mat !== item));
                              } else {
                                setMaterial(item);
                                setSearchedMaterials([...searchedMaterials, item])
                              }


                            }}
                          />
                        </div>
                        {
                          searchedMaterials.includes(item) && (
                            <>
                              <div className={`col-12 row d-flex ${classes.subHeaderRow}`}>
                                <div className={`row d-flex align-items-center mt-4 ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableHeader} ${classes.subTableHeader}`}>
                                  <div className="col-2">
                                    <Typography variant="h2" className={`${classes.subTableHeaderFont}`}>
                                      Category
                                    </Typography>
                                  </div>
                                  <div className="col-2">
                                    <Typography variant="h2" className={`${classes.subTableHeaderFont}`}>
                                      Materials
                                    </Typography>
                                  </div>
                                  <div className="col-3">
                                    <Typography variant="h2" className={`${classes.subTableHeaderFont}`}>
                                      Description
                                    </Typography>
                                  </div>
                                  <div className="col-2">
                                    <Typography variant="h2" className={`${classes.subTableHeaderFont}`}>
                                      Drop-off Locations
                                    </Typography>
                                  </div>
                                  <div className="col-2">
                                    <Typography variant="h2" className={`${classes.subTableHeaderFont}`}>
                                      Events
                                    </Typography>
                                  </div>
                                  <div className="col-2">
                                    <Typography variant="h2" className={`${classes.subTableHeaderFont}`}>
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                              <div className={classes.dataTable}>
                                {
                                  materialData[item].map((item, index) => (
                                    <div
                                      key={item.problem_id}
                                      className={`row d-flex align-items-start pt-4 pb-4 ${classes.border} ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableRow}`}
                                    >
                                      <div className="col-2" style={{ width: '100%', height: '100%' }}>
                                        <Typography variant="bodyTableSemiBold" className={`${classes.ranking}`}>
                                          {item.mat_cat_admin_nm}
                                        </Typography>
                                      </div>
                                      <div className="col-2" style={{ width: '100%', height: '100%' }}>
                                        {
                                          item.materials.map((mat, index) => (
                                            <>
                                              {
                                                searchTerm.length && mat.toLowerCase().includes(searchTerm.toLowerCase()) ? (
                                                  <Typography variant={`${index % 2 === 0 ? "bodyTable" : "bodyTableSemiBold"}`} className={`${classes.ranking} ${classes.searchedMaterials}`}>
                                                    {mat} <br />
                                                  </Typography>
                                                ) : (
                                                  <Typography variant={`${index % 2 === 0 ? "bodyTable" : "bodyTableSemiBold"}`}>
                                                    {mat} <br />
                                                  </Typography>
                                                )
                                              }
                                            </>
                                          ))
                                        }
                                      </div>
                                      <div className="col-3" style={{ width: '100%', height: '100%' }}>
                                        <Typography variant="bodyTable" className={`${classes.ranking}`}>
                                          <div
                                            style={{ minHeight: '20px' }} // Set a minimum height
                                            dangerouslySetInnerHTML={{
                                              __html: item.disposal_body ? item.disposal_body : '<p style="min-width: calc(250px + 100vw - 1024px)"></p>'
                                            }}
                                          />
                                        </Typography>
                                      </div>
                                      <div className="col-2" style={{ width: '100%', height: '100%' }}>
                                        <>
                                          {
                                            item.depotnm ? (
                                              <>
                                                {item.depotnm && item.depotnm.split(', ').map((depot, index) => (
                                                  <Link to={`/depot?search=${encodeURIComponent(depot)}`}>
                                                    <Typography variant={`${index % 2 === 0 ? "bodyTable" : "bodyTableSemiBold"}`} className={`${classes.depot}`}>
                                                      {depot} <br />
                                                    </Typography>
                                                  </Link>

                                                ))}
                                              </>
                                            ) : (
                                              <div style={{minWidth: 'calc(250px + 100vw - 1024px)'}} /> // Set a minimum width to prevent the row from collapsing when there is no data
                                            )
                                          }
                                        </>

                                      </div>
                                      <div className="col-2" style={{ width: '100%', height: '100%' }}>
                                        <Typography variant="bodyTableSemiBold" className={`${classes.ranking}`}>
                                          {item.eventnm && item.eventnm.split(', ').map((event, index) => (
                                            <Link to={`/event?search=${encodeURIComponent(event)}`}>
                                              <Typography variant={`${index % 2 === 0 ? "bodyTable" : "bodyTableSemiBold"}`} className={`${classes.depot}`}>
                                                {event} <br />
                                              </Typography>
                                            </Link>
                                          ))}
                                        </Typography>
                                      </div>
                                      <div className="col-1" style={{ width: '100%', height: '100%' }}>
                                        <IconButton onClick={() => handleEditClick(item)}>

                                          <Typography
                                            color={process.env.REACT_APP_COLOR_PRIMARY}
                                            variant="bodyTableSemiBold"
                                          >
                                            {intl.formatMessage({ id: 'WASTE_TYPE.EDIT' })}
                                          </Typography>
                                        </IconButton>

                                      </div>
                                    </div>
                                  ))
                                }
                              </div>
                            </>
                          )
                        }
                      </div>
                    ))
                  }
                </div>
              </PortletBody>
            )
          }

        </Portlet>
      }
    </div>
  );
};

export default injectIntl(MaterialsTable);
