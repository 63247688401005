import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';


import { Typography } from '../../../components';

import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: 'white',
    marginRight: 0,
    marginLeft: 0,
    marginTop: '0 !important',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: '8px 0',
    minHeight: 72,
    textTransform: 'uppercase',
    borderBottom: '1px solid lightgrey',


  },
  tableHeaderFont: {
    color: '#404040',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    fontSize: '13px',
  },
  tableRow: {
    borderBottom: '1px solid lightgrey',
    marginLeft: 0,
    marginRight: 0,
    '&::after': {
      borderBottom: '1px solid lightgrey',
    }
  },
  tableLabel: {
    borderBottom: 'none !important',
    borderRadius: '0 !important',
    padding: '0 !important',
  },
  tableBody: {
    padding: '0 !important',
    textAlign: 'left'
  },
  filter: {
    display: "inline-flex",
    border: "1px solid #0086C2",
    borderRadius: "16px"
  },
  filterItem: {
    padding: "6px 10px",
    borderRight: "1px solid #0086C2",
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "20px",
    color: "#0086C2",
    textAlign: "center",
    letterSpacing: "0.04em",
    cursor: "pointer",
    "&:first-of-type": {
      borderTopLeftRadius: "16px",
      borderBottomLeftRadius: "16px"
    },
    "&:last-of-type": {
      borderTopRightRadius: "16px",
      borderBottomRightRadius: "16px",
      borderRight: "0"
    }
  },
  filterItemFirstOfType: {
    borderTopLeftRadius: "16px",
    borderBottomLeftRadius: "16px"
  },
  filterItemLastOfType: {
    borderTopRightRadius: "16px",
    borderBottomRightRadius: "16px",
    borderRight: "0"
  },
  filterItemSelected: {
    backgroundColor: 'rgba(0, 134, 194, 0.12)',
    fontWeight: '600',
  },
  arrow: {
    marginRight: "6px"
  },
  changePositive: {
    marginLeft: '8px',
    color: '#409A5F'
  },
  changeNegative: {
    marginLeft: '8px',
    color: '#FF1E1E'
  },
  backArrow: {
    fontSize: '2rem',
    color: '#404040',
    '&:hover': {
      color: '#0086C2',
    }
  }

}));

const MaterialsDetailsTable = ({
  intl,
  data,
  month,
  year,
  handleMonthsFetch,
  monthFilterParent

}) => {
  const classes = useStyles();

  const [monthFilter, setMonthFilter] = useState(monthFilterParent);
  const history = useHistory();


  const filterBy = (months) => () => {
    handleMonthsFetch(months);
    setMonthFilter(months);
  };

  const percentDiffFromlastYear = (lastYear, thisYear) => {
    return ((thisYear - lastYear) / lastYear) * 100;
  };

  const goBack = () => {
    history.goBack();
  };


  return (
    <>
      <Portlet>
        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start">
                <div className="row col-xs-12 col-lg-12 align-items-center" style={{ justifyContent: 'space-between' }} >
                  <div className="align-items-center pl-3 d-flex">
                    <div
                      role="button"
                      style={{ marginRight: 10 }}
                    >
                      <ArrowBackIcon className={classes.backArrow} onClick={goBack} />
                    </div>
                  </div>
                  <div className={`${classes.filter}`}>
                    <div className={`${classes.filterItem} ${monthFilter === 1 ? classes.filterItemSelected : ''}`} onClick={filterBy(1)}>
                      {month} {year}
                    </div>
                    <div className={`${classes.filterItem} ${monthFilter === 3 ? classes.filterItemSelected : ''}`} onClick={filterBy(3)}>
                      Last 3 Months
                    </div>
                    <div className={`${classes.filterItem} ${monthFilter === 6 ? classes.filterItemSelected : ''}`} onClick={filterBy(6)}>
                      Last 6 Months
                    </div>
                    <div className={`${classes.filterItem} ${monthFilter === 12 ? classes.filterItemSelected : ''}`} onClick={filterBy(12)}>
                      Last 12 Months
                    </div>
                  </div>
                </div>
              </div>
            )}
          />
        </div>
        <PortletBody style={{ padding: '25px 0px' }}>
          <div className="row" style={{ margin: '0px' }}>

            {
              data && data.length && (
                <div style={{ width: '100%' }}>
                  <PortletBody className={`${classes.tableBody}`}>
                    <div className={`row d-flex align-items-center mt-4 ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableHeader}`}>
                      <div className="col-2 row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Material Name
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Impressions Last Year
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Impressions This Year
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Engagements Last Year
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Engagements This Year
                        </Typography>
                      </div>
                      <div className="col-4 row d-flex align-items-center ml-2" />
                    </div>


                    <div className={classes.dataTable}>
                      {
                        data.map((item) => (
                          <div
                            key={item.id}
                            className={`row d-flex align-items-center pt-4 pb-4 ${classes.border} ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableRow}`}
                          >
                            <div className="col-2 row d-flex ml-2">
                              <Typography variant="bodySemiBold" className="col">
                                {item.title}
                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography className="col-6" variant="bodySemiBold">
                                {item.last_exposure}

                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography variant="bodySemiBold" className="col d-flex">
                                {item.exposure.value}
                                {'   '}
                                <div className={`${percentDiffFromlastYear(item.last_exposure, item.exposure.value) > 0 ? classes.changePositive : classes.changeNegative}`}>
                                  <span className={classes.arrow}>{percentDiffFromlastYear(item.last_exposure, item.exposure.value) > 0 ? '↑' : '↓'}</span>
                                  {/* {percentDiffFromlastYear(item.last_exposure, item.exposure.value)}% */}
                                  {Number(item.exposure.diff) > 999 ? '' : `${item.exposure.diff}%`}

                                </div>
                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography className="col-3" variant="bodySemiBold">
                                {item.last_engagement}

                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography variant="bodySemiBold" className="col d-flex">
                                {item.engagement.value}
                                {'   '}
                                <div className={`${percentDiffFromlastYear(item.last_engagement, item.engagement.value) > 0 ? classes.changePositive : classes.changeNegative}`}>
                                  <span className={classes.arrow}>{percentDiffFromlastYear(item.last_engagement, item.engagement.value) > 0 ? '↑' : '↓'}</span>
                                  {/* {percentDiffFromlastYear(item.engagements_last_yr, item.engagements_this_yr)}% */}
                                  {Number(item.engagement.diff) > 999 ? '' : `${item.engagement.diff}%`}


                                </div>
                              </Typography>
                            </div>
                            <div className="col-4 row d-flex align-items-center ml-2" />
                          </div>

                        ))
                      }
                    </div>
                  </PortletBody>
                </div>
              )
            }

          </div>
        </PortletBody>
      </Portlet>
    </>
  );
};

export default injectIntl(MaterialsDetailsTable);
