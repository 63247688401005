import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Radio } from '@material-ui/core';
import { CheckOutlined } from '@material-ui/icons';
import moment from 'moment';
import * as actions from '../redux/actions';
import { PortletBody } from '../../../../../../_metronic/partials/content/Portlet';
import {
  CustomButton, TextField, Typography, Opacity,
} from '../../../../components';
import { changeCurrentMonthsIndexHandler } from '../../../../../utils';
import { LoadingSpinner } from '../../../../components/loading';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    border: '2px solid #2A95FF',
    borderRadius: 5,
    borderTop: 'none',
    position: 'relative',
    top: '-5px',
  },
  iconContainerWrapper: {
    margin: '10.25px 0'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 17,
    height: 17,
    background: '#00C8BC',
    borderRadius: '50%',
    marginRight: 11,
    marginLeft: 10,
    '& svg': {
      fontSize: '1rem'
    }
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  }
}));

const MajorCampaignWwtk = ({
  intl,
  loading,
  WWtKs,
  currentQuestionIndex,
  setCurrentQuestionAction,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [hideResponseData, setHideResponseData] = useState(false);
  const [isSubmittinAFeedback, setIsSubmittinAFeedback] = useState();
  const [feedback, setFeedback] = useState('');

  useEffect(() => {
    if (WWtKs && WWtKs.length >= currentQuestionIndex && WWtKs[currentQuestionIndex]) {
      const endDate = moment(WWtKs[currentQuestionIndex].date_to);
      const currentDate = moment();
      if (currentDate.isAfter(endDate)) {
        setHideResponseData(true);
      } else {
        setHideResponseData(false);
      }
    }
  }, [currentQuestionIndex, WWtKs]);

  useEffect(() => {
    if (WWtKs && WWtKs.length) {
      // set current question index to 0
      setCurrentQuestionAction(0);
    }
  }, [WWtKs, setCurrentQuestionAction]);

  let type;
  let question;
  let options;
  let quiz_correct_answer;
  let correct_answer_feedback;
  let wrong_answer_feedback;
  let message;

  if (WWtKs && WWtKs.length >= currentQuestionIndex && WWtKs[currentQuestionIndex]) {
    ({
      type,
      question,
      options,
      quiz_correct_answer,
      correct_answer_feedback,
      wrong_answer_feedback,
      message,
    } = WWtKs[currentQuestionIndex]);
  }

  const changeCurrentQuestionIndexHandler = (direction) => {
    setCurrentQuestionAction(
      changeCurrentMonthsIndexHandler(direction, currentQuestionIndex, WWtKs.length)
    );
  };

  const handleSubmittingFeedback = () => {
    // feedbackRequest(WWtKs[currentQuestionIndex], feedback);
    setIsSubmittinAFeedback(false);
    setFeedback('');
  };

  return (
    <div className={classes.content}>
      {
        WWtKs && WWtKs.length && !loading ? (
          <PortletBody style={{
            width: '100%',
          }}
          >
            <div>
              <>
                {/* question */}
                <Opacity key={question}>
                  <Typography
                    color={process.env.REACT_APP_COLOR_TEXT}
                    className="ml-1 mb-3 mt-3"
                    variant="pdd"
                    dangerouslySetInnerHTML={{ __html: question }}
                  />
                </Opacity>

                {/* answers */}
                <Opacity key={currentQuestionIndex}>
                  <div className="mb-5">
                    {options && options.length && options.map((item, index) => (
                      <div className="d-flex align-items-center mb-2" key={index}>
                        {+quiz_correct_answer === index + 1 && type === 'quiz' ? (
                          <div className={classes.iconContainerWrapper}>
                            <div className={classes.iconContainer}>
                              <CheckOutlined
                                fontSize="small"
                                style={{ color: '#fff' }}
                              />
                            </div>
                          </div>
                        ) : (
                          <Radio color="primary" checked={false} />
                        )}
                        <Typography
                          color={process.env.REACT_APP_COLOR_TEXT}
                          variant="bodySemiBold"
                        >
                          {item}
                        </Typography>
                      </div>
                    ))}
                  </div>

                  {/* quiz correct and wrong asnwers */}
                  {type === 'quiz' && (
                    <>
                      <Typography color="#00C8BC" className="mb-2" variant="pdd">
                        {intl.formatMessage({
                          id: 'GENERAL.CORRECT.ANSWER.MESSAGE',
                        })}
                      </Typography>
                      <i>
                        <Typography
                          color={process.env.REACT_APP_COLOR_TEXT}
                          className="mb-4"
                          variant="bodySemiBold"
                          dangerouslySetInnerHTML={{
                            __html: correct_answer_feedback,
                          }}
                        />
                      </i>
                      <Typography color="#FF1E1E" className="mb-2" variant="pdd">
                        {intl.formatMessage({ id: 'GENERAL.WRONG.ANSWER.MESSAGE' })}
                      </Typography>
                      <i>
                        <Typography
                          color={process.env.REACT_APP_COLOR_TEXT}
                          className="mb-5"
                          variant="bodySemiBold"
                          dangerouslySetInnerHTML={{
                            __html: wrong_answer_feedback,
                          }}
                        />
                      </i>
                    </>
                  )}
                </Opacity>

                {/* poll message */}
                {type === 'poll' && (
                  <>
                    <div className="d-flex align-items-center mb-3 mt-3">
                      <div
                        style={{ marginLeft: 0 }}
                        className={classes.iconContainer}
                      >
                        <CheckOutlined fontSize="small" style={{ color: '#fff' }} />
                      </div>
                      <Typography color="#00C8BC" className="mb-2" variant="pdd">
                        {intl.formatMessage({ id: 'EDUCATION.POLL.MESSAGE' })}
                      </Typography>
                    </div>
                    <Typography
                      color={process.env.REACT_APP_COLOR_TEXT}
                      className="mb-5"
                      variant="bodySemiBold"
                      dangerouslySetInnerHTML={{ __html: message }}
                    />
                  </>
                )}
                <div className={classes.bottomContainer}>
                  <div>
                    {/* submit a feedback */}
                    <div
                      className="mb-4"
                      role="button"
                      onClick={() => setIsSubmittinAFeedback((prevState) => !prevState)}
                    >
                      <Typography
                        style={{ cursor: 'pointer' }}
                        color="#0086C2"
                        variant="pdd"
                      >
                        {intl.formatMessage({ id: 'GENERAL.PROVIDE.FEEDBACK' })}
                      </Typography>
                    </div>

                    {isSubmittinAFeedback && (
                      <Opacity>
                        <div className="d-flex flex-column mb-3">
                          <TextField
                            isMulti
                            maxRow={3}
                            defaultRow={3}
                            onChange={setFeedback}
                            value={feedback}
                          />
                          <div style={{ marginLeft: 'auto', marginTop: 15 }}>
                            <CustomButton
                              onClick={() => handleSubmittingFeedback()}
                              variant="contained"
                              disabled={false}
                              fontSize={13}
                              paddingVertical={1}
                              label={
                                <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                              }
                            />
                          </div>
                        </div>
                      </Opacity>
                    )}

                    {/* residents respond data */}
                    {hideResponseData && (
                      <div
                        className="mb-4"
                        style={{ cursor: 'pointer' }}
                        role="button"
                        onClick={() => history.push('/report/resident-knowledge-indicator')}
                      >
                        <Typography color="#0086C2" variant="pdd">
                          {intl.formatMessage({
                            id: 'EDUCATION.VIEW.RESIDENTS.RESPONSE.DATA',
                          })}
                        </Typography>
                      </div>
                    )}
                  </div>
                  {/* navigation buttons */}
                  <div style={{ transform: 'translateX(-10px)' }} className="d-flex align-items-center mt-2">
                    <CustomButton
                      paddingVertical={4}
                      variant="outlined"
                      label="Previous"
                      onClick={() => changeCurrentQuestionIndexHandler('forward')}
                      className="mr-2"
                      disabled={currentQuestionIndex === 0}
                    />
                    <CustomButton
                      paddingVertical={4}
                      variant="contained"
                      label="Next"
                      onClick={() => changeCurrentQuestionIndexHandler('backward')}
                      className="ml-2"
                      disabled={currentQuestionIndex === WWtKs.length - 1}
                    />
                  </div>
                </div>

              </>
            </div>
          </PortletBody>
        ) : (
          loading && (
            <LoadingSpinner height={307} />
          )
        )
      }

    </div>
  );
};

const mapStateToProps = (store) => ({
  loading: store.majorCampaigns.loading,
  error: store.majorCampaigns.error,
  WWtKs: store.majorCampaigns.WWtKs,
  currentQuestionIndex: store.majorCampaigns.currentQuestionIndex,
});

export default injectIntl(
  connect(mapStateToProps, actions)(MajorCampaignWwtk)
);
