import cts from '../../crud/cts';

/**
 * users list API
 */
export const getRules = async ({ cms_locality_id }) => cts(
  'post',
  'rules/get-all',
  {
    cms_locality_id
  }
);
/**
 * Suggested Username API
 */
export const editRules = async ({
  cms_locality_id,
  waste_recycling_rule_id,
  rip_,
  waste_recycling_rule_value
}) => cts(
  'post',
  'rules/edit',
  {
    cms_locality_id,
    waste_recycling_rule_id,
    rip_,
    waste_recycling_rule_value
  }
);
