import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import qs from 'querystring';
import moment from 'moment';


import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import MenuItem from '@material-ui/core/MenuItem';

import * as actions from './redux/actions';
import * as sharedActions from '../../redux/actions';
import * as dashboardActions from '../../Dashboard/redux/actions';


import { LoadingSpinner, ErrorContainer } from '../../components/loading';


import { Education, Top5Topics, OtherMetrics, AdvertisingEquivalentValue, PopularFeatures, MaterialExposure, WeWantedToKnow, MajorCampaigns, AnswerRate, EducationDetails, UsersByZones, ProblemReports, Users, ParticipationRate, MostSearchMaterials, WhatGoesWhereSearches, ParticipationMethod, WereHereToHelp, BulkItemPickUp, BulkPickUpOverlay } from './components'
import { Typography, MunicipalitySelector } from '../../components';
import { CrownIcon } from '../../../assets/icons';
import { validation } from '../../../utils';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const useStyles = makeStyles((theme) => ({
  container: {
    // width: '90%',
    margin: '0 auto'
  },
  dateSelector: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  dateLabel: {
    color: '#2A95FF',
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 16,
  },
  topSection: {
    display: 'flex',
    alignContent: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  sectionTitle: {
    color: '#404040',
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 22,
    letterSpacing: '0.05em'
  },
  userRow: {
    marginBottom: 20,
  },
}));


const ReportDashboard = ({
  intl,
  getReportDashboardRequest,
  reportData,
  loading,
  error,
  municipality,
  isUserPro,
  stage,
  district,
  municipalities,
  setDistrictAction,
  hasCounty,
  counties,
  county,
  isCounty,
  setCountyAction,
  csm,
  getDashboardDataRequestAction,
  user,
  bulkPickUpDashboard,
  getBulkPickupDashboardRequest,
  getDistrictProPackagesRequest,
  district_pro_packages,
}) => {
  const history = useHistory();

  const classes = useStyles();
  const [datePeriod, setDatePeriod] = useState({ month: 0, year: 0 });
  const [dateOptions, setDateOptions] = useState([]);
  const [displayEducationDetail, setdisplayEducationDetail] = useState(false);
  const [displayPhase2, setDisplayPhase2] = useState(true);

  const handleChange = (event, data) => {
    setDatePeriod(data.props.item);
    getReportDashboardRequest({
      municipality,
      month: data.props.item.month,
      year: data.props.item.year,
      county,
      isCounty,
    });
    getBulkPickupDashboardRequest({
      municipality,
      month: data.props.item.month,
      year: data.props.item.year,
      county,
      isCounty,
    });
  };

  useEffect(() => {
    const options = generateLast24Months();
    setDateOptions(options);
    setDatePeriod(options[0]);
    const queryString = qs.parse(history.location.search.replace('?', ''));
    // setDisplayPhase2(queryString.displayPhase2);

    // getReportDashboardRequest({
    //   municipality,
    //   month: options[0].month,
    //   year: options[0].year,
    //   county,
    //   isCounty,
    // });
    // setDistrictAction({
    //   district_id: '#all-districts#',
    //   district_nm: 'All Districts'
    // });
  }, []);

  useEffect(() => {
    // if (district.district_id === '#all-districts#') {

    //   const options = generateMonthYear();
    //   getReportDashboardRequest({
    //     municipality,
    //     month: options[0].month,
    //     year: options[0].year,
    //   });
    // } else {
    //   const options = generateMonthYear();
    //   getReportDashboardRequest({
    //     municipality,
    //     month: options[0].month,
    //     year: options[0].year,
    //   });
    // }
    const options = generateLast24Months();
    if (loading) return;

    getReportDashboardRequest({
      municipality,
      month: options[0].month,
      year: options[0].year,
      county,
      isCounty,
    });
    getBulkPickupDashboardRequest({
      municipality,
      month: options[0].month,
      year: options[0].year,
      county,
      isCounty,
    });

  }, [district, county, isCounty]);

  useEffect(() => {
    if (user) {
      Bugsnag.start({
        apiKey: process.env.REACT_APP_BUGSNAG_KEY,
        plugins: [new BugsnagPluginReact()],
        user: {
          id: user.user_name,
          name: `${user.first_name} ${user.last_name}`,
          email: user.email
        }
      })
      Bugsnag.setUser(user.user_name, user.email, `${user.first_name} ${user.last_name}`)

    }

  }, [user]);

  useEffect(() => {
    if (stage !== '4') {
      getDistrictProPackagesRequest();
    }
  }, [stage]);


  // useEffect(() => {
  //   console.log('datePeriod', datePeriod)
  //   getReportDashboardRequest({
  //     municipality,
  //     month: datePeriod.month,
  //     year: datePeriod.year,
  //   });
  // }, [datePeriod]);

  // function that generates the last 24 months based on a given date
  const generateLast24Months = (date) => {
    const months = [];
    for (let i = 0; i < 24; i++) {
      const month = moment(date).subtract(i, 'months');
      months.push({
        month: month.month() ? month.month() : 12,
        year: month.month() ? month.year() : month.year() - 1,
      });
    }
    return months;
  };


  const generateMonthYear = () => {
    const date = new Date();
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();
    const monthYear = [];
    for (let i = 0; i < 24; i++) {
      if (currentMonth - i <= 0) {
        monthYear.push({
          month: Math.abs(12 + currentMonth - i),
          year: currentYear - 1,
        });
      } else {
        monthYear.push({
          month: currentMonth - i,
          year: currentYear,
        });
      }
    }
    return monthYear;
  };

  // functuion that returns monmth name based on month number
  const getMonthName = (month, type) => {
    const monthNames = [
      { short: '', long: '' },
      { short: 'Jan', long: 'January' },
      { short: 'Feb', long: 'February' },
      { short: 'Mar', long: 'March' },
      { short: 'Apr', long: 'April' },
      { short: 'May', long: 'May' },
      { short: 'Jun', long: 'June' },
      { short: 'Jul', long: 'July' },
      { short: 'Aug', long: 'August' },
      { short: 'Sep', long: 'September' },
      { short: 'Oct', long: 'October' },
      { short: 'Nov', long: 'November' },
      { short: 'Dec', long: 'December' },
    ];
    if (type === 'long') {
      return monthNames[month].long;
    }
    return monthNames[month].short;
  };

  const handleDetailView = (e) => {
    // history.push('/report/education/detail');
    setdisplayEducationDetail(true);
  };

  useEffect(() => {
    if (!csm) {
      const fromDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
      const toDate = moment().format('YYYY-MM-DD');
      getDashboardDataRequestAction(fromDate, toDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const educationDetailsData = [
    {
      title: 'WWtK',
      unique_user: 100,
      exposure: 100,
      engagement: 100,
      engagement_rate: 100,
      knowledge_measurement_eng: 100,
      correct_answer_rate: 100,
      time_spent: 100,
      campaign_replacement: 100,
    },
    {
      title: 'Material Page and any other CMS page (general page, depot, event, collection requirement)',
      unique_user: 100,
      exposure: 100,
      engagement: 100,
      engagement_rate: 100,
      knowledge_measurement_eng: 100,
      correct_answer_rate: 100,
      time_spent: 100,
      campaign_replacement: 100,
    },
    {
      title: 'Campaign (Featured Lesson)',
      unique_user: 100,
      exposure: 100,
      engagement: 100,
      engagement_rate: 100,
      knowledge_measurement_eng: 100,
      correct_answer_rate: 100,
      time_spent: 100,
      campaign_replacement: 100,
    },
    {
      title: 'Spotlight Materials on the home screen',
      unique_user: 100,
      exposure: 100,
      engagement: 100,
      engagement_rate: 100,
      knowledge_measurement_eng: 100,
      correct_answer_rate: 100,
      time_spent: 100,
      campaign_replacement: 100,
    },
    {
      title: 'Recycling Fundamentals',
      unique_user: 100,
      exposure: 100,
      engagement: 100,
      engagement_rate: 100,
      knowledge_measurement_eng: 100,
      correct_answer_rate: 100,
      time_spent: 100,
      campaign_replacement: 100,
    },
    {
      title: 'Recycling Fundamental Quiz',
      unique_user: 100,
      exposure: 100,
      engagement: 100,
      engagement_rate: 100,
      knowledge_measurement_eng: 100,
      correct_answer_rate: 100,
      time_spent: 100,
      campaign_replacement: 100,
    },
    {
      title: 'Misunderstood Materials',
      unique_user: 100,
      exposure: 100,
      engagement: 100,
      engagement_rate: 100,
      knowledge_measurement_eng: 100,
      correct_answer_rate: 100,
      time_spent: 100,
      campaign_replacement: 100,
    },
    {
      title: 'Misunderstood Materials Quiz',
      unique_user: 100,
      exposure: 100,
      engagement: 100,
      engagement_rate: 100,
      knowledge_measurement_eng: 100,
      correct_answer_rate: 100,
      time_spent: 100,
      campaign_replacement: 100,
    },
    {
      title: 'Blogs',
      unique_user: 100,
      exposure: 100,
      engagement: 100,
      engagement_rate: 100,
      knowledge_measurement_eng: 100,
      correct_answer_rate: 100,
      time_spent: 100,
      campaign_replacement: 100,
    }
  ];


  return (
    <>
      <div className={classes.container}>
        <div className="row">
          {error ? (
            <ErrorContainer
              small
              reportName="Report Dashboard"
              height={430}
              error={error.message || error || ''}
            />
          ) : (
            <div className="col-xl-12 col-centered">
              <div className={classes.topSection}>
                <Typography variant="h2" className={classes.sectionTitle}>
                  Learning Activities
                </Typography>
                <div className='d-flex' style={{ gap: 10 }}>
                  {
                    municipalities && municipalities.length
                      ? (
                        <div style={{ width: 300, transform: 'translateY(-10px)' }}>
                          <MunicipalitySelector
                            hasAllDistrictOption={true}
                            isDisable={loading}

                            isCountyEnable
                            title={
                              (hasCounty !== null && isCounty && counties)
                                ? 'County'
                                : intl.formatMessage({ id: 'REPORT.GENERAL.REPORTING_DISTRICT' })
                            }
                            onChange={
                              (hasCounty !== null && isCounty)
                                ? setCountyAction
                                : setDistrictAction
                            }
                            value={(hasCounty !== null && isCounty) ? county : district}
                            invalidText={
                              !validation(district || '', 'required')
                                ? intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
                                : null
                            }
                          />
                        </div>
                      ) : null
                  }
                  {
                    datePeriod && dateOptions.length > 0 && datePeriod.month > 0 && datePeriod.year > 0 && (
                      <FormControl className={classes.dateSelector}>
                        <Select
                          value={`${getMonthName(datePeriod.month)} ${datePeriod.year}`}
                          onChange={handleChange}
                          disableUnderline
                          disabled={loading}
                          className={classes.selectEmpty}
                          options={dateOptions}
                          inputProps={{
                            classes: {
                              root: classes.dateLabel,
                            },
                          }}
                        >
                          {dateOptions.length > 0 && dateOptions.map((item, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={`${getMonthName(item.month)} ${item.year}`}
                                item={item}
                              >
                                {getMonthName(item.month)} {item.year}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )
                  }
                </div>
              </div>
              {
                !displayEducationDetail && datePeriod.month && datePeriod.year > 0 && (
                  <>
                    <div className={`row ${classes.educationRow}`}>
                      <div className="col-xl-9 col-md-12 col-centered">
                        <Education reportData={reportData} reportMonth={datePeriod.month} reportYear={datePeriod.year} isLoading={loading} />
                      </div>
                      <div className="col-xl-3 col-md-12 col-centered">
                        <WereHereToHelp reportData={reportData} isLoading={loading} csm={csm} />
                      </div>
                    </div>
                    <div>
                      {
                        displayPhase2 && (
                          <>
                            <Typography variant="h2" className={`${classes.sectionTitle} mt-2 mb-3`}>
                              Users
                            </Typography>
                            <div className={`row`}>
                              <div className={`col-xl-3 col-md-12 col-centered ${classes.userRow}`}>
                                <ParticipationRate reportData={reportData} isLoading={loading} />
                              </div>
                              <div className={`col-xl-9 col-md-12 col-centered ${classes.userRow}`}>
                                <Users reportData={reportData} isLoading={loading} csm={csm} />
                              </div>
                            </div>
                            <ParticipationMethod reportData={reportData} reportMonth={datePeriod.month} reportYear={datePeriod.year} isLoading={loading} csm={csm} onReportDashboard={true} />

                            <div className='row'>
                              <div className={`col-xl-6 col-centered ${classes.userRow}`}>
                                <UsersByZones reportData={reportData} isLoading={loading} />
                              </div>
                              <div className={`col-xl-6 col-centered ${classes.userRow}`}>
                                <ProblemReports reportData={reportData} isLoading={loading} />
                              </div>
                            </div>
                          </>
                        )
                      }
                    </div>
                    <div className={classes.topSection}>
                      <Typography variant="h2" className={classes.sectionTitle}>
                        Material Engagements & Insights
                      </Typography>
                    </div>
                    <div className='row'>
                      <div className="col-xl-12 col-centered">
                        <MaterialExposure reportData={reportData} isLoading={loading} />
                      </div>
                      <div className="col-xl-12 col-centered">
                        <AnswerRate answerRateData={reportData.material_crr || []} title='Materials Correct Response Rate' isLoading={loading} />
                      </div>
                    </div>
                    {
                      displayPhase2 && (
                        <>
                          <WhatGoesWhereSearches reportData={reportData} isLoading={loading} reportMonth={datePeriod.month} reportYear={datePeriod.year} />
                          <MostSearchMaterials reportData={reportData} isLoading={loading} />

                        </>
                      )
                    }
                    <div>
                    </div>
                    <div className={classes.topSection}>
                      <Typography variant="h2" className={classes.sectionTitle}>
                        Engagements
                      </Typography>
                    </div>
                    {/* <MajorCampaigns reportData={reportData} isUserPro={isUserPro} stage={stage} csm={csm} isLoading={loading} /> */}
                    <PopularFeatures reportData={reportData} isUserPro={isUserPro} stage={stage} csm={csm} isLoading={loading} district_pro_packages={district_pro_packages}/>
                    {/* <div className={classes.topSection}>
                      <Typography variant="h2" className={classes.sectionTitle}>
                        Engagements
                      </Typography>
                    </div> */}
                    <WeWantedToKnow reportData={reportData} isLoading={loading} />
                    <Top5Topics reportData={reportData} isLoading={loading} />
                    <div>
                      {
                        bulkPickUpDashboard && bulkPickUpDashboard.data && (
                          <>
                            <BulkItemPickUp reportData={bulkPickUpDashboard.data} isLoading={loading} reportMonth={datePeriod.month} reportYear={datePeriod.year} csm={csm} />
                          </>
                        )
                      }

                    </div>
                    <OtherMetrics reportData={reportData} isLoading={loading} />
                  </>
                )
              }
              {
                displayEducationDetail && (
                  <EducationDetails data={educationDetailsData} month={getMonthName(datePeriod.month, 'long')} year={datePeriod.year} isLoading={loading} />
                )
              }
            </div>
          )}
        </div>
      </div>
    </>
  );
};

/**
 * Map state to props
 */
const mapStateToProps = (store) => ({
  reportData: store.reportsDashboardReducer,
  bulkPickUpDashboard: store.reportsDashboardReducer.bulkPickUpDashboard,
  loading: store.reportsDashboardReducer.loading,
  error: store.reportsDashboardReducer.error,
  municipality: store.common.district.district_id,
  isUserPro: store.common.isUserPro,
  stage: store.common.district.stage,
  district: store.common.district,
  municipalities: store.common.municipalities,
  hasCounty: store.auth.user_info.counties,
  counties: store.common.counties,
  county: store.common.county,
  isCounty: store.common.isCounty,
  csm: store.dashboard.csm,
  user: store.auth.user_info,
  district_pro_packages: store.common.district_pro_packages,
});

export default injectIntl(
  connect(
    mapStateToProps, { ...actions, ...sharedActions, ...dashboardActions }
  )(ReportDashboard)
);
