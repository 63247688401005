import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';



import { Typography } from '../../../components';
import { toAbsoluteUrl } from '../../../../../_metronic';

import { SummaryCellLoading } from '../../../components/loading';

import { DropdownMenu } from '../../../Education/EducationalContent/components';

import { EducationModal } from '../components';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '1300px',
    margin: '0 auto'
  },
  group: {
    display: "flex",
    gap: "10px",
    '@media (max-width: 1060px)': {
      flexWrap: "wrap",
    }
  },
  groupItem: {
    display: "flex",
    flexDirection: "column",
    border: "2px solid #E7ECEF",
    borderRadius: 9,
    ChangeTheColorBorderRadius: "8px",
    padding: "12px",
    // width: "25%",
    width: 235,
    // maxWidth: "200px",
    MightNeedFullWidthSoTheItemsDontGetTooBigOnLargeScreenColor: "#63666A",
    ChangeTheColorFontFamily: "sans-serif",
    color: '#404040',

  },
  groupTitle: {
    fontWeight: "600",
    fontSize: 14,

  },
  groupNumber: {
    fontSize: '24px',
    fontWeight: '700',
    marginTop: '6px',
    marginBottom: '6px'
  },
  //.group__change
  groupChange: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontWeight: '500',
    fontSize: 16,
  },
  //.group__arrow
  groupArrow: {
    marginRight: "6px"
  },
  ///* Item shows increase */
  groupChangePositive: {
    color: '#409A5F'
  },
  ///* Item shows decrease */
  groupChangeNegativet: {
    color: '#FF1E1E'
  },
}));

const OtherMetrics = ({
  intl,
  reportData,
  isLoading,
}) => {
  const classes = useStyles();

  /**
* Menu and menuitem functions.
*/

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [metricSelection, setMetricSelection] = useState(null);
  const [anchorElTitle, setAnchorElTitle] = useState(null);
  const [openTitle, setOpenTitle] = useState(false);
  const [modalDescriptionSelection, setModalDescriptionSelection] = useState('');


  const handleClick = (section, event) => {
    console.log('section', section);

    setModalDescriptionSelection(section);
    setAnchorEl(event.currentTarget);
  };
  const handleClickTitle = (event) => {
    setAnchorElTitle(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElTitle(null);

  };

  const descriptionHandler = (e) => {
    console.log('descriptionHandler', e, anchorEl)

    setOpen(true);
  };
  const descriptionHandlerTitle = (e) => {
    setOpenTitle(true);
  };

  useEffect(() => {
    console.log('metricSelection', metricSelection);
  }, [metricSelection]);


  /**
  * Data for dropdown menu items.
  */
  const menuItemData = [
    {
      id: 1,
      name: 'Description',
      method: descriptionHandler,
      disabled: false
    },

  ];

  const metricDescription = {
    kids_activity_pack: {
      title: 'Kids Activity Packets',
      desciption: 'These are mini booklets that engage children in recycling and sustainability. They target three age groups from 3-12 years and are downloaded through the Recycle Coach app and also via the web app on your website. The number shown is the number of booklets downloaded in the latest month, which is identified in the upper right corner of this page. The percentage change is a comparison to the number of downloads for the same month last year.'
    },
    pdf_calendar_add_on: {
      title: 'PDF Calendar Add-Ons',
      desciption: 'Add-ons are pages that are included when residents choose to download a PDF version of their pick-up schedule; they target proper recycling practices and contamination. PRO clients are able to supply custom pages to be included in each download.  The number shown is the number of pages downloaded in the latest month, which is identified in the upper right corner of this page. The percentage change is a comparison to the same month last year.'
    },
    collection_requirement: {
      title: 'Collection Requirements',
      desciption: 'Using the Recycle Coach mobile app or the web app on your website, residents can access Collection Requirements when they perform What Goes Where searches. The number shown is the number of times these pages were visited in the latest month, which is identified in the upper right corner of this page. The percentage change is a comparison to the same month last year.'
    },
    event: {
      title: 'Events',
      desciption: 'Providing information about events is a key function of the Recycle Coach platform. The number shown is the number of times residents accessed this information in the latest month, which is identified in the upper right corner of this page. The percentage change is a comparison to the same month last year.'
    },
    reminder_add_on_message: {
      title: 'Reminder Add-On Messages',
      desciption: 'Getting pick-up reminders is the number one reason residents download the Recycle Coach app. Many clients leverage these interactions by adding an educational message to each reminder sent. The number shown is the number of educational messages that were sent in the latest month, which is identified in the upper right corner of this page. The percentage change is a comparison to the same month last year. To learn more about sending Reminder Add-on Messages, contact your Customer Success Manager.',
    },
    blog: {
      title: 'Blogs',
      desciption: 'Residents can access informative articles through the Recycle Coach mobile app and via the web app on your website. These articles inform and engage residents about waste & recycling issues and practices. The number shown is the number of times residents accessed blog articles in the latest month, which is identified in the upper right corner of this page. The percentage change is a comparison to the same month last year.'
    },
    drop_off_location: {
      title: 'Drop-Off Locations',
      desciption: 'This is the number of times residents accessed information about your drop-off locations using the Recycle Coach mobile app or the web app on your website in the latest month. The latest month is shown in the upper right corner of this page. The percentage change is a comparison to the same month last year.'
    },
    general_page: {
      title: 'General Pages',
      desciption: 'This is the number of times residents accessed information on the general pages of the Recycle Coach mobile app or the web app on your website in the latest month. The latest month is shown in the upper right corner of this page. The percentage change is a comparison to the same month last year.'
    },
  }


  const otherMetricsTopRow = [];
  const otherMetricsBottomRow = [];
  const otherMetricsTopRowKeys = ['kids_activity_pack', 'pdf_calendar_add_on', 'collection_requirement', 'event'];
  const otherMetricsBottomRowKeys = ['reminder_add_on_message', 'blog', 'drop_off_location', 'general_page'];

  if (reportData && reportData.other_metrics && reportData.other_metrics.other_metrics) {
    Object.keys(reportData.other_metrics.other_metrics).forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      const otherMetric = {
        title: item.replace(/_/g, ' ')
      }
      if (otherMetricsTopRowKeys.includes(item)) {
        otherMetricsTopRow.push({ ...reportData.other_metrics.other_metrics[item], ...otherMetric });
      } else if (otherMetricsBottomRowKeys.includes(item)) {
        otherMetricsBottomRow.push({ ...reportData.other_metrics.other_metrics[item], ...otherMetric });
      }
    });
  }

  return (
    <>
      {
        reportData && reportData.other_metrics && (
          <Portlet>
            <div className="w-100 p-0">
              <PortletHeader
                toolbar={(
                  <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start">
                    <div className="row col-xs-12 col-lg-6 align-items-center">
                      <div className="align-items-center pl-3">
                        <Typography variant="h1">
                          Other Metrics
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
            <PortletBody>
              <div className="row mt-5">
                <div className="col-sm-12 col-md-2 col-xl-2">
                  <img
                    alt="Download"
                    src={toAbsoluteUrl('/media/icons/othermetric.svg')}
                    className={`${classes.packImage}`}
                  />
                </div>
                {
                  isLoading ?
                    <>
                      <div className="col-sm-12 col-lg-12 col-xl-10">
                        <div className="row" style={{ marginBottom: 10 }}>
                          <div className={classes.group}></div>
                          <div className={classes.groupItem}>
                            <SummaryCellLoading />
                          </div>
                          <div className={classes.groupItem}>
                            <SummaryCellLoading />
                          </div>
                          <div className={classes.groupItem}>
                            <SummaryCellLoading />
                          </div>
                          <div className={classes.groupItem}>
                            <SummaryCellLoading />
                          </div>
                        </div>
                        <div className="row" style={{ marginBottom: 10 }}>
                          <div className={classes.group}></div>
                          <div className={classes.groupItem}>
                            <SummaryCellLoading />
                          </div>
                          <div className={classes.groupItem}>
                            <SummaryCellLoading />
                          </div>
                          <div className={classes.groupItem}>
                            <SummaryCellLoading />
                          </div>
                          <div className={classes.groupItem}>
                            <SummaryCellLoading />
                          </div>
                        </div>
                      </div>
                    </> :
                    <div>
                      {
                        reportData && reportData.other_metrics && (
                          <div className="col-sm-12 col-lg-12 col-xl-10">
                            <div className="row" style={{ marginBottom: 10 }}>
                              <div className={classes.group}>
                                {
                                  otherMetricsTopRow.length && otherMetricsTopRow.map((item) => (
                                    <div className={classes.groupItem} key={item.title} data-key={item.title} onClick={() => setMetricSelection(item.title.replace(/ /g, "_"))}>
                                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className={classes.groupTitle}>{item.title.replace(/kids activity pack/g, 'Kid’s Activity Packets').replace(/pdf calendar add on/g, 'PDF Calendar Add-Ons').replace(/collection requirement/g, 'Collection Requirements').replace(/event/g, 'Events')}</div>
                                        <DropdownMenu
                                          handleClick={((e) => { handleClick(item.title.replace(/ /g, "_"), e) })}
                                          anchorEl={anchorEl}
                                          handleClose={handleClose}
                                          menuItemData={menuItemData}
                                        />
                                      </div>
                                      <div className={classes.groupNumber}>{item.download >= 0 ? item.download.toLocaleString() : item.value === 'N/A' ? item.value : item.value >= 0 ? item.value.toLocaleString() : item.diff === 'N/A' ? item.diff : item.diff <= 0 ? 'N/A' : item.diff.toLocaleString()}</div>
                                      {
                                        <div className={`${classes.groupChange} ${item.diff === 'N/A' ? '' : item.diff > 0 ? classes.groupChangePositive : item.diff === 0 ? '' : classes.groupChangeNegativet}`}>
                                          <div className={classes.groupArrow}>{item.diff === 'N/A' ? '' : item.diff > 0 ? '↑' : item.diff === 0 ? '' : '↓'}</div>
                                          <div className={classes.group__percentag}>{item.diff === 0 || item.diff === 'N/A' ? '' : item.diff} {item.diff > 0 || item.diff < 0 ? '%' : ''}</div>
                                        </div>
                                      }
                                    </div>
                                  ))
                                }

                              </div>
                            </div>
                            <div className="row" style={{ marginBottom: 10 }}>
                              <div className={classes.group}>
                                {
                                  otherMetricsBottomRow.length && otherMetricsBottomRow.map((item) => (
                                    <div className={classes.groupItem} key={item.title} data-key={item.title} onClick={(e) => {
                                      setMetricSelection(item.title.replace(/ /g, "_"))
                                    }}>
                                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                        <div className={classes.groupTitle}>{item.title.replace(/reminder add on message/g, 'Reminder Add-On Messages').replace(/blog/g, 'Blogs').replace(/drop off location/g, 'Drop-Off Locations').replace(/general page/g, 'General Pages')}</div>
                                        <DropdownMenu
                                          handleClick={((e) => { handleClick(item.title.replace(/ /g, "_"), e) })}
                                          anchorEl={anchorEl}
                                          handleClose={handleClose}
                                          menuItemData={menuItemData}
                                        />
                                      </div>
                                      <div className={classes.groupNumber}>{item.download || item.value.toLocaleString()}</div>
                                      {
                                        <div className={`${classes.groupChange} ${item.diff === 'N/A' ? '' : item.diff > 0 ? classes.groupChangePositive : item.diff === 0 ? '' : classes.groupChangeNegativet}`}>
                                          <div className={classes.groupArrow}>{item.diff === 'N/A' ? '' : item.diff > 0 ? '↑' : item.diff === 0 ? '' : '↓'}</div>
                                          <div className={classes.group__percentag}>{item.diff === 0 || item.diff === 'N/A' ? '' : item.diff} {item.diff > 0 || item.diff < 0 ? '%' : ''}</div>
                                        </div>
                                      }

                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                            <EducationModal
                              open={open}
                              onClose={() => setOpen(false)}
                              title={'Description'}
                              content={(
                                <Typography variant="body1" style={{ textAlign: 'left' }}>
                                  {
                                    metricSelection && modalDescriptionSelection && metricDescription[modalDescriptionSelection].title && (
                                      <div>
                                        <strong>{metricDescription[modalDescriptionSelection].title}</strong>
                                        <br />
                                        {metricDescription[modalDescriptionSelection].desciption}
                                      </div>
                                    )
                                  }


                                </Typography>
                              )}
                            />
                          </div>
                        )
                      }
                    </div>
                }

              </div>
            </PortletBody>
          </Portlet>
        )

      }

    </>
  );

};

export default injectIntl(OtherMetrics);
