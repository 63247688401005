import { put, call, takeLatest } from 'redux-saga/effects';

import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  getCollections,
  createCollection,
  editCollection,
} from '../apis';

function* getCollectionsListSaga({ payload: { locality } }) {
  try {
    yield put(actions.setLoadingAction('send-request'));
    const res = yield call(getCollections, { locality });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getCollectionsListReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getCollectionsListFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* createCollectionSaga({ payload: { data } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(createCollection, { data });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.createCollectionReceive({ id: res.data, data }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.createCollectionFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* editCollectionSaga({ payload: { data } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(editCollection, { data });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.editCollectionReceive({ data }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.editCollectionFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

/**
 * Saga watcher function
 */
function* collectionRequirementSagas() {
  yield takeLatest(actionTypes.GET_COLLECTIONS_LIST_REQUEST, getCollectionsListSaga);
  yield takeLatest(actionTypes.CREATE_COLLECTION_REQUEST, createCollectionSaga);
  yield takeLatest(actionTypes.EDIT_COLLECTION_REQUEST, editCollectionSaga);
}

export default collectionRequirementSagas;
