import React from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

const Submit = ({ title, onSubmit, isSubmitting }) => {
  if (onSubmit) {
    return (
      <button
        type="button"
        disabled={isSubmitting}
        className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
          {
            'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': isSubmitting
          }
        )}`}
        style={{ paddingRight: isSubmitting ? '3.5rem' : '2.5rem' }}
        onClick={onSubmit}
      >
        <FormattedMessage id={title} />
      </button>
    );
  }
  return (
    <button
      type="submit"
      disabled={isSubmitting}
      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
        {
          'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': isSubmitting
        }
      )}`}
      style={{ paddingRight: isSubmitting ? '3.5rem' : '2.5rem' }}
    >
      <FormattedMessage id={title} />
    </button>
  );
};

export default Submit;
