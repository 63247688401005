import actionTypes from './actionTypes';

export const getRecyclingFundamentalsRequestAction = (project_id, district_id) => ({
  type: actionTypes.GET_RECYCLING_FUNDAMENTALS_DATA_REQUEST,
  payload: { project_id, district_id }
});

export const getRecyclingFundamentalsDataReceiveAction = (data) => ({
  type: actionTypes.GET_RECYCLING_FUNDAMENTALS_DATA_RECEIVE,
  payload: data
});

export const getRecyclingFundamentalsFailAction = (error) => ({
  type: actionTypes.GET_RECYCLING_FUNDAMENTALS_DATA_FAIL,
  payload: error
});

export const getMisunderstoodMaterialsRequestAction = (project_id, district_id) => ({
  type: actionTypes.GET_MISUNDERSTOOD_MATERIALS_DATA_REQUEST,
  payload: { project_id, district_id }
});

export const getMisunderstoodMaterialsDataReceiveAction = (data) => ({
  type: actionTypes.GET_MISUNDERSTOOD_MATERIALS_DATA_RECEIVE,
  payload: data
});

export const getMisunderstoodMaterialsFailAction = (error) => ({
  type: actionTypes.GET_MISUNDERSTOOD_MATERIALS_DATA_FAIL,
  payload: error
});

export const setCurrentQuestionAction = (index) => ({
  type: actionTypes.SET_CURRENT_QUESTION_INDEX,
  index
});
