/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

import { Typography } from '../../components';
import { addCommasToNumber } from '../../../utils';
import { SummaryCellLoading } from '../../components/loading';

const useStyles = makeStyles((theme) => ({
  content: {
    borderRight: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    paddingLeft: 30,
    [theme.breakpoints.down('1200')]: {
      paddingTop: 30,
      paddingBottom: 10,
      paddingLeft: 10,
    },
    [theme.breakpoints.down('992')]: {
      borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
      borderRight: 'none',
    },
  },
  title: {
    minHeight: 30,
  },
  data: {
    minHeight: 80,
  },
}));

const SummaryUserRating = ({
  intl,
  title,
  data,
  description = `# ${intl.formatMessage({ id: 'REPORT.GENERAL.PER_1000_HOUSEHOLDS' })}`,
  isLoading = true,
  className,
  hasUserText = true,
  keyword = 'Users'
}) => {
  const classes = useStyles();

  return (
    <div className={`col-12 col-lg-6 col-xl-3 ${classes.content} ${className}`}>
      {isLoading ? (
        <SummaryCellLoading />
      ) : (
        <div className="d-flex flex-column align-items-between">
          <Typography className={classes.title} variant="h2">
            {title}
          </Typography>
          <div className={`d-flex align-items-center ${classes.data}`}>
            <Typography variant="numberSmall">
              {data ? addCommasToNumber(data) : '-'}
            </Typography>
            {hasUserText && (
              <Typography component="span" variant="h2Medium" className="ml-3">
                {keyword}
              </Typography>
            )}
          </div>
          <Typography variant="h2Medium">
            {description}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default injectIntl(SummaryUserRating);
