const actionTypes = {
  // Schedule actionTypes for setting loading status
  SET_SCHEDULE_LOADING_ACTION: 'SET_SCHEDULE_LOADING_ACTION',

  GET_SCHEDULE_REQUEST_ACTION: 'GET_SCHEDULE_REQUEST_ACTION',
  GET_SCHEDULE_RECEIVE_ACTION: 'GET_SCHEDULE_RECEIVE_ACTION',
  GET_SCHEDULE_FAIL_ACTION: 'GET_SCHEDULE_FAIL_ACTION',

  GET_ZONE_SCHEDULE_REQUEST: 'GET_ZONE_SCHEDULE_REQUEST',
  GET_ZONE_SCHEDULE_RECEIVE: 'GET_ZONE_SCHEDULE_RECEIVE',
  GET_ZONE_SCHEDULE_FAIL: 'GET_ZONE_SCHEDULE_FAIL',

  GET_PERPETUAL_SCHEDULES_HOLIDAYS_REQUEST: 'GET_PERPETUAL_SCHEDULES_HOLIDAYS_REQUEST',
  GET_PERPETUAL_SCHEDULES_HOLIDAYS_RECEIVE: 'GET_PERPETUAL_SCHEDULES_HOLIDAYS_RECEIVE',
  GET_PERPETUAL_SCHEDULES_HOLIDAYS_FAIL: 'GET_PERPETUAL_SCHEDULES_HOLIDAYS_FAIL'
};

export default actionTypes;
