import { put, call, takeLatest } from 'redux-saga/effects';

import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  getAnnualSurveyReport
} from '../apis';

function* annualSurveySaga({ payload: { municipality, year } }) {
  try {
    const res = yield call(getAnnualSurveyReport, { municipality, year });

    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.annualSurveyReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.annualSurveyFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

/**
 * Saga watcher function
 */
function* annualSurveySagas() {
  yield takeLatest(actionTypes.GET_ANNUAL_SURVEY_REQUEST, annualSurveySaga);
}

export default annualSurveySagas;
