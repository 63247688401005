import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  useLocation, useHistory, Link
} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import moment from 'moment';

import { Typography } from '../../../components';
import ReportPDF from './ReportPDF';
import {
  addCommasToNumber, dataChangeTracking, isValidDate, checkValidDistrict
} from '../../../../utils';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../../../../_metronic/partials/content/Portlet';
import {
  SummaryCurrentPeriod,
  SummaryPreviousPeriod,
  SummaryHeader,
  RCSpinner
} from '../../components';
import SummaryOtherTotals from '../../components/SummaryOtherTotals';
import { SummaryOtherLoading } from '../../../components/loading';
import { PdfIcon, BackIcon } from '../../../../assets/icons';
import * as actions from '../redux/actions';
import * as sharedActions from '../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_PRIMARY,
  },
  border: {
    borderRight: window.innerWidth > window.innerHeight ? `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}` : '0px',
    borderBottom: window.innerWidth < window.innerHeight ? `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}` : '0px',
  },
  description: {
    textAlign: 'right'
  },
  visibleUp: {
    [theme.breakpoints.up('990')]: {
      display: 'flex !important',
    },
    [theme.breakpoints.down('989')]: {
      display: 'none !important',
    },
  },
  VisibleDown: {
    [theme.breakpoints.up('990')]: {
      display: 'none !important',
    },
    [theme.breakpoints.down('990')]: {
      display: 'flex !important',
    },
  },
  borderBottom: {
    '@media (max-width:990px)': {
      borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
      borderRight: '0px',
    },
  },
  mobileBorder: {
    '@media (max-width:990px)': {
      borderRight: '0px',
    },
  },
  noData: {
    minHeight: '130px'
  },
  noDataMobile: {
    minHeight: '100px'
  },
  noDataColor: {
    color: process.env.REACT_APP_COLOR_GRAY_DARK
  },
  pdfDisable: {
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_GRAY_DARK,
  }
}));

const Summary = ({
  intl,
  problemReportSummaryRequest,
  problemReportRequest,
  data,
  district,
  setDistrictAction,
  fromDate,
  toDate,
  setDateRangeAction,
  detailSummaryData,
  detailData,
  fetchState,
  municipalities,
  county,
  counties,
  setCountyAction,
  isCounty,
  setIsCountyAction,
  hasCounty,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);
  const isSendRequest = fetchState === 'send-request';
  const [pdfSpinner, setPdfSpinner] = useState(false);

  useEffect(() => {
    if (history.location.search) {
      if (queryParams.get('district') && checkValidDistrict(
        queryParams.get('district').replace('-', '#$#'),
        municipalities,
        'district_id'
      )) {
        setDistrictAction({
          district_id: queryParams.get('district').replace('-', '#$#'),
          district_nm: municipalities.filter((item) => item.district_id === queryParams.get('district').replace('-', '#$#'))[0].district_nm,
        });
        setIsCountyAction(false);
      } else if (municipalities.length === 1) {
        setDistrictAction(municipalities[0]);
      } else {
        setDistrictAction({
          district_id: '#all-districts#',
          district_nm: 'All Districts'
        });
      }
      if (queryParams.get('county') && checkValidDistrict(
        queryParams.get('county'),
        counties,
        'county_id'
      ) && hasCounty !== null) {
        setCountyAction({
          county_id: queryParams.get('county'),
          county_nm: counties.filter((item) => item.county_id === queryParams.get('county'))[0].county_nm,
        });
        setIsCountyAction(true);
      } else {
        setCountyAction(counties[0]);
      }
      if (queryParams.get('from') && queryParams.get('to') && isValidDate(queryParams.get('from')) && isValidDate(queryParams.get('to'))) {
        setDateRangeAction({
          fromDate: queryParams.get('from'),
          toDate: queryParams.get('to'),
        });
      }
    } else if (municipalities.length === 1) {
      setDistrictAction(municipalities[0]);
    } else {
      setDistrictAction({
        district_id: '#all-districts#',
        district_nm: 'All Districts'
      });
    }
  }, []);

  useEffect(() => {
    if (isCounty) {
      history.replace({
        pathname: '/report/problem',
        search: `?county=${county.county_id}&from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
      });
    } else {
      history.replace({
        pathname: '/report/problem',
        search: `?district=${district.district_id === '#all-districts#' ? 'all-districts' : district.district_id.replace('#$#', '-')}&from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
      });
    }
  }, [fromDate, toDate, district, county, isCounty]);

  const generatePDF = async () => {
    setPdfSpinner(true);
    const blob = await pdf((
      <ReportPDF
        district={district.district_id}
        usersAtAGlance={intl.formatMessage({ id: 'REPORT.PROBLEM.SUMMARY.HEADER' })}
        currentPeriod={`${intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_PERIOD' })}:`}
        date={`${fromDate.slice(0, 13)} - ${toDate.slice(0, 13)}`}
        reportingDistrict={isCounty ? `Reporting County : ${county.county_nm}`
          : `Reporting District : ${district.district_id !== '#all-districts#' ? district.district_nm : 'All Districts'}`}
        currentPeriodType={dataChangeTracking(
          data && Object.keys(data).length > 0 ? data.current_period.total : 0,
          data && Object.keys(data).length > 0 ? data.last_period.total : 0
        )}
        detailData={detailData.problems}
        detailSummaryData={detailSummaryData}
        isCounty={isCounty}
      />
    )).toBlob();
    saveAs(blob, 'Problem Report.pdf');
    setPdfSpinner(false);
  };

  return (
    <Portlet>
      <PortletHeader
        title={(
          <Link to="/report/dashboard">
            <Button
              classes={{
                label: classes.label,
              }}
              startIcon={<BackIcon color={process.env.REACT_APP_COLOR_PRIMARY} />}
            >
              {intl.formatMessage({ id: 'REPORT.GENERAL.BACK' })}
            </Button>
          </Link>
        )}
        toolbar={(
          <PortletHeaderToolbar>
            <Button
              disabled={detailData.problems && !detailData.problems.length}
              classes={{
                label: detailData.problems && detailData.problems.length
                  ? classes.label
                  : classes.pdfDisable,
              }}
              startIcon={(
                <PdfIcon
                  color={detailData.problems && detailData.problems.length
                    ? process.env.REACT_APP_COLOR_PRIMARY
                    : process.env.REACT_APP_COLOR_GRAY_DARK}
                />
              )}
              onClick={generatePDF}
            >
              {intl.formatMessage({ id: 'REPORT.GENERAL.PDF' })}
            </Button>
            <RCSpinner isActive={pdfSpinner} />
          </PortletHeaderToolbar>
        )}
      />
      <PortletBody>
        <SummaryHeader
          title={intl.formatMessage({ id: 'REPORT.PROBLEM.SUMMARY.HEADER' })}
          handleDataRequest={problemReportSummaryRequest}
          handleSecondDataRequest={problemReportRequest}
        />
        <div className="row mb-4">
          <SummaryCurrentPeriod
            type={dataChangeTracking(
              data && Object.keys(data).length > 0 ? data.current_period.total : 0,
              data && Object.keys(data).length > 0 ? data.last_period.total : 0
            )}
            data={data && Object.keys(data).length > 0 ? data.current_period.total : 0}
            isLoading={isSendRequest}
          />
          <SummaryPreviousPeriod
            data={data && Object.keys(data).length > 0 ? data.last_period.total : 0}
            isLoading={isSendRequest}
          />
          <div className={`col-12 col-md-6 pb-2 pl-xl-5 d-flex flex-column ${classes.visibleUp}`}>
            <SummaryOtherTotals
              data={data && Object.keys(data).length > 0 && data.top_problem_type}
              isLoading={isSendRequest}
            />

          </div>
          <div className={`col-12 col-xl-6 pb-2 pl-xl-5 d-flex flex-column ${classes.VisibleDown} ${classes.border} ${classes.padding} ${classes.mobileBorder}`}>
            {!isSendRequest ? (
              <div className="h-100 d-flex flex-column justify-content-between mt-4 mt-lg-0">
                {data && Object.keys(data).length > 0
                  && data.top_problem_type.map((item, index) => (
                    <div key={String(index)} className="d-flex flex-column justify-content-center align-items-start">
                      <Typography className="mb-3" variant="h2">
                        {item.problem_type_label}
                      </Typography>
                      <Typography className="mb-5" variant="numberSmall">
                        {addCommasToNumber(item.count)}
                      </Typography>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="d-flex justify-content-start align-items-center w-100 h-100">
                <SummaryOtherLoading />
              </div>
            )}
          </div>
        </div>
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.problemReport.fetchState,
  district: store.common.district,
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
  detailSummaryData: store.problemReport.summaryData,
  detailData: store.problemReport.data,
  municipalities: store.common.municipalities,
  county: store.common.county,
  counties: store.common.counties,
  isCounty: store.common.isCounty,
  hasCounty: store.auth.user_info.counties,
});

export default injectIntl(
  connect(
    mapStateToProps,
    { ...actions, ...sharedActions }
  )(Summary)
);
