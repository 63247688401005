import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { Portlet, PortletBody } from '../../../../_metronic/partials/content/Portlet';
import { AutoComplete, CustomButton, TextField } from '../../components';
import { PlusCircleIcon } from '../../../assets/icons';
import * as actions from '../redux/actions';
import * as sharedActions from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('800')]: {
      flexDirection: 'column',
    },
  },
  searchBox: {
    flex: 1,
    [theme.breakpoints.down('800')]: {
      width: '100%',
      marginBottom: '20px'
    },
  },
  municipalities: {
    flex: 1,
    marginLeft: '60px',
    [theme.breakpoints.down('800')]: {
      width: '100%',
      marginBottom: '20px',
      marginLeft: 0,
    },
  },
  addButton: {
    width: '250px',
    marginLeft: '20px',
    [theme.breakpoints.down('992')]: {
      width: '200px',
    },
    [theme.breakpoints.down('800')]: {
      width: '100%',
      marginLeft: 0,
    },
  }
}));

const Header = ({
  intl,
  searchTerm,
  onSearchTermChange,
  localityList,
  locality,
  eventWizardStage,
  setLocalityAction,
  setWizardStageAction,
  getEventsListRequest,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (locality && locality.cms_locality_id) {
      getEventsListRequest({
        locality: locality.cms_locality_id,
      });
    }
  }, [locality]);

  return (
    <Portlet>
      <PortletBody className="flex-row justify-content-between">
        <div className={`${classes.container}`}>
          <div className={`${classes.searchBox}`}>
            <TextField
              label={intl.formatMessage({ id: 'EVENT.HEADER.SEARCH' })}
              value={searchTerm}
              onChange={onSearchTermChange}
              hasClear
            />
          </div>

          <div className={`${classes.municipalities}`}>
            <AutoComplete
              title={intl.formatMessage({ id: 'GENERAL.LOCALITY' })}
              options={localityList}
              optionTitle="cms_locality_nm"
              optionId="cms_locality_id"
              disableClearable
              onChange={(value) => setLocalityAction({ locality: value })}
              value={locality}
            />
          </div>

          <div className={`${classes.addButton}`}>
            <CustomButton
              label={(
                <>
                  <PlusCircleIcon
                    className="mr-2"
                    color={process.env.REACT_APP_COLOR_SECONDARY}
                  />
                  {intl.formatMessage({ id: 'EVENT.HEADER.CREATE_EVENT_BUTTON' })}
                </>
              )}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                if (eventWizardStage === 0) {
                  setWizardStageAction(1);
                }
              }}
            />
          </div>

        </div>
        {/* <div className={`${classes.container}`}>
          <div className={`${classes.addButton}`}>
            <CustomButton
              label={(
                <>
                  <PlusCircleIcon
                    className="mr-2"
                    color={process.env.REACT_APP_COLOR_SECONDARY}
                  />
                  {intl.formatMessage({ id: 'EVENT.HEADER.CREATE_EVENT_BUTTON' })}
                </>
              )}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                if (eventWizardStage === 0) {
                  setWizardStageAction(1);
                }
              }}
            />
          </div>

          <div className={`${classes.municipalities}`}>
            <AutoComplete
              title={intl.formatMessage({ id: 'GENERAL.LOCALITY' })}
              options={localityList}
              optionTitle="cms_locality_nm"
              optionId="cms_locality_id"
              disableClearable
              onChange={(value) => setLocalityAction({ locality: value })}
              value={locality}
            />
          </div>

        </div> */}
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  localityList: store.common.localityList,
  locality: store.common.locality,
  eventWizardStage: store.event.eventWizardStage,
});

export default injectIntl(connect(mapStateToProps,
  { ...actions, ...sharedActions })(Header));
