import actionTypes from './actionTypes';

export const setAnnualSurveyLoadingAction = (status) => ({
  type: actionTypes.SET_ANNUAL_SURVEY_LOADING_ACTION,
  payload: status
});

export const annualSurveyRequest = ({
  municipality,
  year
}) => ({
  type: actionTypes.GET_ANNUAL_SURVEY_REQUEST,
  payload: { municipality, year }
});

export const annualSurveyReceive = (data) => ({
  type: actionTypes.GET_ANNUAL_SURVEY_RECEIVE,
  payload: { data }
});

export const annualSurveyFail = (error) => ({
  type: actionTypes.GET_ANNUAL_SURVEY_FAIL,
  payload: { ...error }
});
