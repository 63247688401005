import React from 'react';
import {
  Document, Page, Text, View, StyleSheet, Image
} from '@react-pdf/renderer';
import { toAbsoluteUrl } from '../../../../../_metronic';
import SummaryPDF from './SummaryPDF';
import RFLessonsPDF from './RFLessonsPDF';
import MMLessonsPDF from './MMLessonsPDF';
import BlogPDF from './BlogPDF';
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    paddingVertical: 5
  },
  summaryHead: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 10,
    marginBottom: 0,
    padding: 10,
  },
  headerText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'black',
  },
  subTitleText: {
    fontSize: 12,
    color: 'black'
  },
  marginLeft: {
    marginLeft: 15
  },
  image: {
    width: '110px',
    height: '40px',
    marginLeft: '13px',
    marginTop: '5px',
  }
});

// Create Document Component
const ReportPDF = ({
  title,
  currentPeriod,
  date,
  district,
  uniqueVisitorsTitle,
  uniqueVisitorsValue,
  RFLessonsTitle,
  RFLessonsValue,
  MMLessonsTitle,
  MMLessonsValue,
  RFQuestion,
  RFAnswer,
  RFResponse,
  RFSelected,
  RFComparison,
  data,
  MMName,
  MMDesc,
  MMAnswer,
  MMResponse,
  MMCorrect,
  blogTitle,
  blogSourceLink,
  blogResponse,
  blogFirstType,
  blogSecondType,
  isCounty,
}) => (
  <Document>
    {/* summary 's PDF */}
    <Page size="A4" orientation="landscape" style={styles.page}>
      <>
        <Image
          style={styles.image}
          src={`${toAbsoluteUrl('/media/logos/RCLogo.png')}`}
        />
        <View style={styles.summaryHead}>
          <Text style={styles.headerText}>
            {title}
          </Text>

          <Text style={[styles.subTitleText, styles.marginLeft,
            {
              color: process.env.REACT_APP_COLOR_DARK_GRAY,
            }
          ]}
          >
            {currentPeriod}
          </Text>

          <Text style={[styles.subTitleText, styles.marginLeft,
            { color: process.env.REACT_APP_COLOR_PRIMARY }]}
          >
            {date}
          </Text>

          <Text style={[styles.subTitleText, {
            marginLeft: isCounty ? 100 : 150
          }]}
          >
            {district}
          </Text>

        </View>

        {/* start summary */}
        <SummaryPDF
          uniqueVisitorsTitle={uniqueVisitorsTitle}
          uniqueVisitorsValue={uniqueVisitorsValue}
          RFLessonsTitle={RFLessonsTitle}
          RFLessonsValue={RFLessonsValue}
          MMLessonsTitle={MMLessonsTitle}
          MMLessonsValue={MMLessonsValue}
        />
        {/* end summary */}

        {/* detail  */}
        {/* Start Recycling Fundamentals Lessons */}
        <View style={{
          width: '100%',
          minHeight: '30px',
          marginRight: '20pt',
          flexDirection: 'column',
        }}
        >
          <RFLessonsPDF
            data={data.rf}
            RFQuestion={RFQuestion}
            RFAnswer={RFAnswer}
            RFResponse={RFResponse}
            RFSelected={RFSelected}
            RFComparison={RFComparison}
          />
        </View>
        {/* End Recycling Fundamentals Lessons */}

        {/* Start Misunderstood Materials Lessons */}
        <View style={{
          width: '100%',
          minHeight: '30px',
          marginRight: '20pt',
          flexDirection: 'column',
        }}
        >
          <MMLessonsPDF
            data={data.mm}
            MMName={MMName}
            MMDesc={MMDesc}
            MMAnswer={MMAnswer}
            MMResponse={MMResponse}
            MMCorrect={MMCorrect}
          />
        </View>
        {/* End Misunderstood Materials Lessons */}

        {/* Start Blog */}
        <View style={{
          width: '100%',
          minHeight: '30px',
          marginRight: '20pt',
          flexDirection: 'column',
        }}
        >
          <BlogPDF
            data={data.blog}
            blogTitle={blogTitle}
            blogSourceLink={blogSourceLink}
            blogResponse={blogResponse}
            blogFirstType={blogFirstType}
            blogSecondType={blogSecondType}
          />
        </View>
        {/* End Blog */}

      </>
    </Page>
  </Document>
);
export default ReportPDF;
