import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { PortletBody } from '../../../../../../_metronic/partials/content/Portlet';

import { MajorCampaignBlogCard } from './index';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    border: '2px solid #2A95FF',
    borderRadius: 5,
    borderTop: 'none',
    position: 'relative',
    top: '-5px',
  },
}));
const MajorCampaignBlogs = ({ intl, blogs }) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <PortletBody>
        <div className="d-flex flex-wrap mt-3">
          {blogs
            && blogs.length
            && blogs.map((item) => (
              <MajorCampaignBlogCard key={item.id} data={item} />
            ))}
        </div>
      </PortletBody>
    </div>
  );
};

const mapStateToProps = (store) => ({
  blogs: store.majorCampaigns.blogs,
});

export default injectIntl(
  connect(mapStateToProps, null)(MajorCampaignBlogs)
);
