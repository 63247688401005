import { put, call, takeLatest } from 'redux-saga/effects';
import actionTypes from './actionTypes';
import * as actions from './actions';
import { getAllLessonData, feedbackRequest } from '../api';

function* getAllLessonDataSaga({ payload: { project_id, district_id } }) {
  const response = yield call(getAllLessonData, { project_id, district_id });

  try {
    yield put(actions.getLessonsLibraryDataReceiveAction(response.data));
  } catch (error) {
    yield put(actions.getLessonsLibraryDataFailAction(response.error.detail.data));
  }
}

function* feedbackSaga({ payload: { meta, feedback, type } }) {
  const response = yield call(feedbackRequest, { meta, feedback, type });
  try {
    yield put(actions.featuredLessonFeedbackdReceive(response.data));
  } catch (error) {
    yield put(actions.featuredLessonFeedbackFail(response.error));
  }
}

function* featuredLessonSaga() {
  yield takeLatest(actionTypes.GET_LESSONS_LIBRARY_DATA_REQUEST, getAllLessonDataSaga);
  yield takeLatest(actionTypes.FEATURED_LESSON_FEEDBACK_REQUEST, feedbackSaga);
}

export default featuredLessonSaga;
