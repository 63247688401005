import React from 'react';
import { Text, View, StyleSheet, } from '@react-pdf/renderer';
import { addCommasToNumber } from '../../../../utils';

// Create styles
const styles = StyleSheet.create({
  h2: {
    fontSize: '20pt'
  },
  h6: {
    fontSize: '12pt'
  },
  marginLeft: {
    marginLeft: 15
  },
  body: {
    fontSize: '10pt'
  },
});

// Create Document Component
const BlogPDF = ({
  data,
  blogTitle,
  blogSourceLink,
  blogResponse,
  blogFirstType,
  blogSecondType,
}) => (
  <>
    <View style={{
      width: '100%',
      marginRight: '20pt',
      minHeight: '40px',
      borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
      paddingTop: '10px',
      paddingBottom: '10px',
      justifyContent: 'center',
      alignItems: 'center'
    }}
    >
      <Text style={styles.h2}>
        Blog Articles Accessed
      </Text>
    </View>

    <View style={{
      width: '100%',
      marginRight: '20pt',
      minHeight: '25px',
      flexDirection: 'row',
      borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
      marginTop: '10px'
    }}
    >

      <View style={{
        width: '60%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingBottom: '10px',
        paddingLeft: '5px'
      }}
      >
        <Text style={styles.h6}>
          {blogTitle}
        </Text>
      </View>

      <View style={{
        width: '20%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '10px',
      }}
      >
        <Text style={styles.h6}>
          {blogSourceLink}
        </Text>
      </View>
      <View
        style={{
          width: '20%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: '10px',
        }}
      >
        <Text style={styles.h6}>
          {blogResponse}
        </Text>
      </View>

    </View>
    { data && data.map((item, index) => (
      <View
        key={`blog_pdf_item_${String(index)}`}
        style={{
          flexGrow: 1,
          width: '100%',
          minHeight: '40px',
          flexDirection: 'row',
          borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
          backgroundColor: index % 2 ? process.env.REACT_APP_COLOR_PRIMARY_BG : null,
          paddingTop: '5px',
          paddingBottom: '5x'
        }}
      >
        <View style={{
          width: '60%',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingRight: '5pt',
          paddingLeft: '5pt',
        }}
        >
          <Text style={styles.body}>
            {item.title ? item.title : ''}
          </Text>
        </View>

        <View style={{
          width: '20%',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        >
          <Text style={styles.body}>
            { item.type === 'hs'
              ? blogFirstType
              : blogSecondType}
          </Text>
        </View>

        <View style={{
          width: '20%',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        >
          <Text style={styles.body}>
            {item.visits ? addCommasToNumber(item.visits) : ''}
          </Text>
        </View>

      </View>
    ))}
  </>
);
export default BlogPDF;
