const actionTypes = {
  // User list actionTypes
  USER_LIST_REQUEST: 'USER_LIST_REQUEST',
  USER_LIST_RECEIVE: 'USER_LIST_RECEIVE',
  USER_LIST_FAIL: 'USER_LIST_FAIL',

  // Add User actionTypes
  ADD_USER_REQUEST: 'ADD_USER_REQUEST',
  ADD_USER_RECEIVE: 'ADD_USER_RECEIVE',
  ADD_USER_FAIL: 'ADD_USER_FAIL',
  ADD_USER_ACTION: 'ADD_USER_ACTION',

  // Edit User actionTypes
  EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
  EDIT_USER_RECEIVE: 'EDIT_USER_RECEIVE',
  EDIT_USER_FAIL: 'EDIT_USER_FAIL',

  // Delete User actionTypes
  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_RECEIVE: 'DELETE_USER_RECEIVE',
  DELETE_USER_FAIL: 'DELETE_USER_FAIL',

  // Activation User actionTypes
  ACTIVATION_USER_REQUEST: 'ACTIVATION_USER_REQUEST',
  ACTIVATION_USER_RECEIVE: 'ACTIVATION_USER_RECEIVE',
  ACTIVATION_USER_FAIL: 'ACTIVATION_USER_FAIL',

  // Get one User actionTypes
  GET_ONE_USER_REQUEST: 'GET_ONE_USER_REQUEST',
  GET_ONE_USER_RECEIVE: 'GET_ONE_USER_RECEIVE',
  GET_ONE_USER_FAIL: 'GET_ONE_USER_FAIL',

  // actionTypes for check username duplicate
  CHECK_USERNAME_REQUEST: 'CHECK_USERNAME_REQUEST',
  CHECK_USERNAME_RECEIVE: 'CHECK_USERNAME_RECEIVE',
  CHECK_USERNAME_FAIL: 'CHECK_USERNAME_FAIL',

  // actionTypes for check email duplicate
  CHECK_EMAIL_REQUEST: 'CHECK_EMAIL_REQUEST',
  CHECK_EMAIL_RECEIVE: 'CHECK_EMAIL_RECEIVE',
  CHECK_EMAIL_FAIL: 'CHECK_EMAIL_FAIL',

  // Set manually user list
  SET_USER_LIST_MANUALLY: 'SET_USER_LIST_MANUALLY',

  // Reset new user action
  RESET_NEW_USER_ACTION: 'RESET_NEW_USER_ACTION',

  // Set permissions
  SET_PERMISSIONS_ACTION: 'SET_PERMISSIONS_ACTION',

  // Reset navigateTOList variable
  RESET_NAVIGATE_TO_LIST: 'RESET_NAVIGATE_TO_LIST',

  // Reset API 's response message
  RESET_RESPONSE_MESSAGE: 'RESET_RESPONSE_MESSAGE',

  // actionTypes for suggest username
  SUGGEST_USERNAME_REQUEST: 'SUGGEST_USERNAME_REQUEST',
  SUGGEST_USERNAME_RECEIVE: 'SUGGEST_USERNAME_RECEIVE',
  SUGGEST_USERNAME_FAIL: 'SUGGEST_USERNAME_FAIL',

  // Reset suggested user name
  RESET_SUGGESTED_USERNAME: 'RESET_SUGGESTED_USERNAME',
};

export default actionTypes;
