import actionTypes from './actionTypes';

export const getAllEducationBlogsRequest = (project_id, district_id) => ({
  type: actionTypes.GET_ALL_EDUCATION_BLOGS_REQUEST,
  payload: { project_id, district_id }
});

export const getAllEducationBlogsReceive = (data) => ({
  type: actionTypes.GET_ALL_EDUCATION_BLOGS_RECEIVE,
  payload: data
});

export const getAllEducationBlogsFail = (error) => ({
  type: actionTypes.GET_ALL_EDUCATION_BLOGS_FAIL,
  payload: error
});

export const setCurrentBlogAction = (index) => ({
  type: actionTypes.SET_CURRENT_BLOG_INDEX,
  index
});
