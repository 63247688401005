import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
  Portlet,
  PortletBody,
  PortletHeader,
} from '../../../../_metronic/partials/content/Portlet';
import { CustomButton, Typography } from '../../components';

const useStyles = makeStyles((theme) => ({
  content: {
    flexDirection: 'row',
    [theme.breakpoints.down('500')]: {
      display: 'flex',
      flexDirection: 'column',
    }
  },
  text: {
    [theme.breakpoints.down('860')]: {
      width: '75% !important',
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.down('700')]: {
      width: '70% !important',
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.down('590')]: {
      width: '60% !important',
      marginBottom: '10px',
    },
    [theme.breakpoints.down('500')]: {
      width: '100% !important',
      marginBottom: '10px',
    }
  },
  button: {
    minWidth: 152,
    [theme.breakpoints.down('500')]: {
      width: '100% !important',
    }
  }
}));

const ListHeader = ({
  title,
  content,
  secondaryContent
}) => {
  const classes = useStyles();
  const history = useHistory();
  const navigateToForm = () => {
    history.push('/users/form');
  };

  return (
    <div className="row mb-4">
      <div className="col-12">
        <Portlet>
          <PortletHeader
            title={title}
          />
          <PortletBody>
            <div className={`d-flex justify-content-between ${classes.content}`}>
              <div className={classes.text}>
                <Typography variant="body">
                  <FormattedMessage id={content} />
                </Typography>
                { secondaryContent }
              </div>
              <div className={classes.button}>
                <CustomButton
                  variant="contained"
                  label={<FormattedMessage id="USERS.HEADER.LIST.BUTTON" />}
                  onClick={navigateToForm}
                />
              </div>
            </div>
          </PortletBody>
        </Portlet>
      </div>
    </div>
  );
};

export default ListHeader;
