import cts from '../../crud/cts';

/**
 * Reminder API which fetches reminder messages list
 */
export const getList = async ({ type, page }) => cts(
  'post',
  'reminders/get-all',
  {
    state: type,
    offset: (page - 1) * 5,
    length: 5,
  }
);

/**
 * Reminder API which removes the selected reminder from messages list
 */
export const deleteReminder = async ({ id }) => cts(
  'post',
  'reminders/delete',
  {
    reminder_id: id,
  }
);

/**
 * Reminder API which loads the selected reminder into form
 */
export const loadReminder = async ({ id }) => cts(
  'post',
  'reminders/get-one',
  {
    reminder_id: id,
  }
);

/**
 * collections API which fetches collection list for selected districts
 */
export const getCollections = async ({ district_id }) => cts(
  'post',
  'reminders/get-district-collections',
  {
    district_list: district_id
  }
);

/**
 * reminders API which add a new reminder
 */
export const newReminder = async ({ reminder }) => cts(
  'post',
  'reminders/new',
  {
    message_body: reminder.message,
    message_title: 'test',
    schedule_from: reminder.dateFrom,
    schedule_to: reminder.dateTo,
    check_col: reminder.collectionType,
    col_type_list: reminder.collections,
    district_list: reminder.district,
    mat_id: ''
  }
);

/**
 * reminders API which edit an existing reminder
 */
export const editReminder = async ({ reminder }) => cts(
  'post',
  'reminders/edit',
  {
    reminder_id: reminder.id,
    message_body: reminder.message,
    message_title: 'test',
    schedule_from: reminder.dateFrom,
    schedule_to: reminder.dateTo,
    check_col: reminder.collectionType,
    col_type_list: reminder.collections,
    district_list: reminder.district,
    mat_id: ''
  }
);
