/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import {
  Portlet,
  PortletBody,
  PortletHeader,
} from '../../../../../_metronic/partials/content/Portlet';
import { Typography } from '../../../components';
import { DoughnutChart } from '../../components';
import { DetailRowLoading } from '../../../components/loading';
import CampaignReportDetail from './CampaignReportDetail';
import { addCommasToNumber } from '../../../../utils';

const useStyles = makeStyles((theme) => ({
  detailLoading: {
    height: '20vh'
  },
  margin: {
    '@media (max-width: 478px)': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  },
  noData: {
    minHeight: '130px'
  },
  noDataMobile: {
    minHeight: '50px'
  },
  noDataColor: {
    color: process.env.REACT_APP_COLOR_GRAY_DARK
  }
}));

const Detail = ({
  intl,
  data,
  allTime,
  fromDate,
  toDate,
  fetchState
}) => {
  const classes = useStyles();
  const isSendRequest = fetchState === 'send-request';

  return (
    <Portlet className="position-relative">
      <PortletHeader
        toolbar={(
          <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start">
            <div className="row col-12 col-lg-7 align-items-center">
              <div className="align-items-center pl-3">
                <Typography variant="h1">
                  { intl.formatMessage({ id: 'REPORT.CAMPAIGN.DETAIL.HEADER' })}
                </Typography>
              </div>
              <div className={`justify-content-start align-items-center ml-3 ${classes.margin}`}>
                <Typography variant="bodySemiBold">
                  {allTime
                    ? intl.formatMessage({ id: 'REPORT.GENERAL.ALL_TIME' })
                    : `${moment(fromDate).format('MMMM Do, YYYY')} - ${moment(toDate).format('MMMM Do, YYYY')}`}
                </Typography>
              </div>
            </div>
          </div>
    )}
      />
      <PortletBody>

        <div className="row mt-5">
          <div className="col-xl-6 col-sm-12 col-xs-12">
            <DoughnutChart
              isLoading={isSendRequest}
              total={data && addCommasToNumber(data.reduce((a, b) => a + (Number(b.invited_users) || 0), 0))}
              insideText={intl.formatMessage({ id: 'REPORT.CAMPAIGN.CHART.INSIDE_TEXT' })}
              labels={data && data.map((item) => item.name)}
              values={data && data.map((item) => Number(item.invited_users))}
              colors={['#58cbd1', '#2ba0a6', '#1f7b80', '#155659', '#76d3e8', '#4eabbf', '#2e75cf', '#294d99', '#183573', '#191970', '#10244f', '#6067cc', '#4148b0', '#282f99', '#131975', '#030742']}
            />
            <div className="row justify-content-center">
              <div className="col-6" />
              <Typography variant="body">
                {intl.formatMessage({ id: 'REPORT.CAMPAIGN.CHART.YEAR_TO_DATE' })}
              </Typography>
            </div>
          </div>
          <div className="col-xl-6 col-sm-12 col-xs-12 pr-5 pr-xl-0 mt-5 mt-xl-0">
            { isSendRequest
              ? (
                <div className="d-flex flex-column w-100 align-items-center justify-content-center pl-5 pl-xl-0">
                  <DetailRowLoading width="100%" />
                </div>
              ) : (
                <>
                  {data && (
                  <CampaignReportDetail
                    colors={['#58cbd1', '#2ba0a6', '#1f7b80', '#155659', '#76d3e8', '#4eabbf', '#2e75cf', '#294d99', '#183573', '#191970', '#10244f', '#6067cc', '#4148b0', '#282f99', '#131975', '#030742']}
                    data={data}
                  />
                  )}
                </>
              )}

          </div>
        </div>

      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  allTime: store.campaignReport.allTime,
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
  fetchState: store.campaignReport.fetchState,
});

export default injectIntl(connect(mapStateToProps, null)(Detail));
