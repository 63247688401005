import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker as MuiTimePicker } from '@material-ui/pickers';

const TimePicker = ({
  label,
  className = '',
  ampm = false,
  value,
  onChange,
  clearable = true,
  disabled = false,
  invalidText,
  invalidTextBackgroundColor = 'transparent'
}) => {
  const useStyles = makeStyles(() => ({
    root: {
      position: 'relative',
      backgroundColor: process.env.REACT_APP_COLOR_SECONDARY,
      '& label': {
        transform: 'translate(14px, -6px) scale(0.75)',
        backgroundColor: process.env.REACT_APP_COLOR_SECONDARY,
        zIndex: 2
      },
      '& legend': {
        maxWidth: '1000px',
        transition: 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms'
      },
    },
    helperText: {
      width: '100%',
      marginRight: 0,
      marginLeft: 0,
      backgroundColor: invalidTextBackgroundColor
    }
  }));
  const [showError, setShowError] = useState(false);
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>

      <MuiTimePicker
        margin="normal"
        className={className}
        classes={{
          root: classes.root
        }}
        label={label}
        ampm={ampm}
        inputVariant="outlined"
        clearable={clearable}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onClose={() => setShowError(true)}
        TextFieldComponent={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            error={showError && !disabled && !!invalidText}
            helperText={showError && !disabled && !!invalidText ? invalidText : null}
            FormHelperTextProps={{
              className: classes.helperText
            }}
          />
        )}
      />
    </MuiPickersUtilsProvider>
  );
};
export default TimePicker;
