import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import MuiIconButton from '@material-ui/core/IconButton';

/**
 * Custom Icon Button based on material-ui IconButton.
 * @param {string} tooltip The icon's tooltip.
 * @param {function} onClick It is called when user clicks on Icon.
 * @param {ReactNode} children The icon that is wrapped in our component.
 * @example
 *  <CustomIcon‌Button
 *    tooltip="Delete"
      onClick={handleClick}
    >
      <DeleteButton />
    </CustomIcon‌Button>
 */
const IconButton = ({
  tooltip,
  onClick,
  children,
  className
}) => {
  const useStyles = makeStyles((theme) => ({
    tooltip: {
      backgroundColor: process.env.REACT_APP_COLOR_SECONDARY,
      color: process.env.REACT_APP_COLOR_TEXT,
      boxShadow: theme.shadows[3],
      fontSize: 14,
    },
    arrow: {
      color: process.env.REACT_APP_COLOR_SECONDARY,
    },
    root: {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
  }));

  const classes = useStyles();
  if (tooltip) {
    return (
      <Tooltip
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.arrow,
        }}
        title={tooltip}
        placement="top"
        arrow
        leaveDelay={100}
      >
        <MuiIconButton
          className={`p-0 ml-3 ${className}`}
          classes={{
            root: classes.root
          }}
          onClick={onClick}
        >
          {children}
        </MuiIconButton>
      </Tooltip>
    );
  }
  return (
    <MuiIconButton
      className={`p-0 ml-3 ${className}`}
      classes={{
        root: classes.root
      }}
      onClick={onClick}
    >
      {children}
    </MuiIconButton>
  );
};

export default IconButton;
