import { put, call, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import actionTypes from './actionTypes';
import { logout } from '../../apis';

function* logoutSaga() {
  const res = yield call(logout);
  yield put(actions.logoutReceive(res));
}

function* sagas() {
  yield takeLatest(actionTypes.LOGOUT_REQUEST, logoutSaga);
}

export default sagas;
