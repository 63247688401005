import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '../../../components';

const useStyles = makeStyles(() => ({
  profilePicture: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    border: 'none',
    backgroundColor: '#ebebeb',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
  },
  letterSpacing: {
    letterSpacing: '1px'
  }
}));

const ProfileImage = ({
  user_info,
  csm,
  width = 40,
  height = 40,
  variant = 'h2',
  isCsm = false
}) => {
  const classes = useStyles();

  /**
   * Checks if profile image is updated.
   * https://stg-api-client.recyclecoach.com/resources/image-profile/
   * https://stg-api-client.recyclecoach.com/resources/image-profile/67f1a7ee29ac9467e749a4f1b42ae055.png
   * https://api-clients.recyclecoach.com/resources/image-profile/67f1a7ee29ac9467e749a4f1b42ae055.png?102
   */

  const isProfileImageUpdated = user_info.image_profile.split('/').slice(-1)[0].length > 0;
  const isCsmImageUpdated = csm?.avatar?.split('/').slice(-1)[0].length > 0;

  /**
  * Modify cms name for displaying first and last name
  */

  let cmsFistNameFirstLetter;
  let cmsLastNameFirstLetter;

  if (csm?.has_csm) {
    cmsFistNameFirstLetter = csm?.name[0];
    const csmNameArray = csm?.name.split(' ');
    // eslint-disable-next-line prefer-destructuring
    cmsLastNameFirstLetter = csmNameArray[csmNameArray.length - 1][0];
  }

  // data is coming from backend is not complete sometimes
  let firstName = '';
  let lastName = '';

  if (user_info?.first_name) {
    firstName = user_info.first_name;
  }

  if (user_info?.last_name) {
    lastName = user_info.last_name;
  }

  let content = (
    <div
      role="img"
      className={classes.profilePicture}
      style={{ width, height }}
    >
      <Typography className={classes.letterSpacing} variant={variant}>
        {isCsm ? cmsFistNameFirstLetter : firstName.toString()[0]}
      </Typography>
      <Typography className={classes.letterSpacing} variant={variant}>
        {isCsm ? cmsLastNameFirstLetter : lastName.toString()[0]}
      </Typography>
    </div>
  );

  if (isProfileImageUpdated && !isCsm) {
    content = (
      <div
        role="img"
        className={classes.profilePicture}
        style={{ width, height, backgroundImage: `url(${user_info.image_profile}?${new Date().getMilliseconds()})` }}
        title="Profile Image"
      />
    );
  }

  if (isCsmImageUpdated && isCsm) {
    content = (
      <div
        role="img"
        className={classes.profilePicture}
        style={{ width, height, backgroundImage: `url(${csm?.avatar}?${new Date().getMilliseconds()})` }}
        title="Csm Image"
      />
    );
  }

  return <>{content}</>;
};

const mapStateToProps = (store) => ({
  user_info: store.auth.user_info,
  csm: store.dashboard.csm,

});

export default connect(mapStateToProps)(ProfileImage);
