import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PictureTapDialogContent from './PictureTapDialogContent';

import { addCommasToNumber } from '../../../../utils';
import {
  IconButton, Dialog, Typography, InfoTooltip
} from '../../../components';
import {
  ExpandIcon,
  CollapseIcon,
} from '../../../../assets/icons';

const useStyles = makeStyles((theme) => ({
  border: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
  },
  value: {
    color: '#2e75cf'
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: '#2e75cf'
  },
  icon: {
    width: 30,
    height: 30
  },
  oneColumn: {
    [theme.breakpoints.up('570')]: {
      display: 'none !important'
    },
  },
  oneColumn2: {
    [theme.breakpoints.up('1199')]: {
      display: 'none !important'
    },
  },
}));

const CampaignReportDetailCell = ({
  intl,
  data,
  expanded,
  onClick,
  backColor
}) => {
  const classes = useStyles();

  return (
    <div>
      <div className="row d-flex align-items-center p-4">
        <div className="col-1 mr-3 mr-xl-0 pt-2">
          <div className={`${classes.circle} `} style={{ backgroundColor: backColor }} />
        </div>
        <div className="col-10 col-xl-5">
          <Typography variant="h2">
            {data.name}
          </Typography>
          <Typography variant="body">
            {moment(data.start_date).format('MMMM Do, YYYY')}
            {' - '}
            {moment(data.end_date).format('MMMM Do, YYYY')}
          </Typography>
        </div>
        <div className={`col-1 ${classes.oneColumn2}`} />
        <div className="col-10 col-xl-5 pl-4 mt-3 mt-xl-0">
          <Typography className={classes.value} variant="numberLarge" color={backColor}>
            {addCommasToNumber(data.invited_users)}
          </Typography>
        </div>
        <div className="col-1 mt-3 mt-xl-0">
          { data && data.activities && data.activities.length > 0 && (
          <>
            {expanded
              ? (
                <IconButton onClick={onClick}>
                  <CollapseIcon className={classes.icon} />
                </IconButton>
              )
              : (
                <IconButton onClick={onClick}>
                  <ExpandIcon className={classes.icon} />
                </IconButton>
              )}
          </>
          )}
        </div>
      </div>
      {expanded && (
        <div className="row d-flex flex-column justify-content-center p-4">
          {data.activities && data.activities.map((item, index) => (
            <div
              key={item.id}
              className={`row align-items-center p-4 ${index !== 0 ? 'pt-4' : null} ${index !== data.activities.length - 1 ? classes.border : null}`}
            >
              <div className="col-1" />
              <div className="col-11 col-sm-5 d-flex justify-content-start align-items-center">
                <Typography variant="h2">
                  {item.type}
                </Typography>
                <div className="col">
                  {item.question
                  && (
                  <Dialog
                    disableBackdropClick={false}
                    hasButtons={false}
                    button={(
                      <InfoTooltip />
                    )}
                    title={(
                      <div className="d-flex align-items-center">
                        {
                          item.type !== 'picture-tap'
                          && (
                          <Typography className="align-items-center" variant="h1">
                            {data.name}
                          </Typography>
                          )
                        }
                        <Typography className="ml-3" variant="body">
                          {item.type}
                        </Typography>
                      </div>
                    )}
                    content={(
                      <div>
                        {item.question && (
                        <div className="mb-4">
                          {
                            item.type !== 'picture-tap'
                            && (
                            <Typography className="mb-2" variant="body">
                              {intl.formatMessage({ id: 'REPORT.CAMPAIGN.DETAIL.DESCRIPTION' })}
                              {' '}
                              {':'}
                            </Typography>
                            )
                          }
                          <Typography variant="h2">
                            <div
                              className="text-justify"
                              dangerouslySetInnerHTML={{ __html: item.question }}
                            />
                          </Typography>
                        </div>
                        )}
                        {item.options && item.type !== 'picture-tap' && (
                        <div className="mb-4">
                          <Typography variant="h2">
                            {intl.formatMessage({ id: 'REPORT.CAMPAIGN.DETAIL.OPTIONS' })}
                            {' '}
                            {':'}
                          </Typography>
                          {item.options.map((option, index) => (
                            <div key={String(index)} className="d-flex align-items-center">
                              <Typography variant="h2">
                                {typeof option === 'object' ? `${option.title}: ` : option}
                              </Typography>
                              {typeof option === 'object' && (
                              <Typography className="ml-3" variant="h2">
                                {option.value}
                              </Typography>
                              )}
                            </div>
                          ))}
                        </div>
                        )}
                        {
                          item.options && item.type === 'picture-tap'
                          && (
                          <div className="mb-4">
                            {item.options.map((option, index) => (
                              <PictureTapDialogContent
                                key={`picture_tap_items${String(index)}`}
                                data={option}
                              />
                            ))}
                          </div>
                          )
                        }
                        {item.type === 'quiz' && (
                        <div className="row mt-5 mb-4">
                          <Typography className="col-4" variant="body">
                            {intl.formatMessage({ id: 'REPORT.CAMPAIGN.DETAIL.CORRECT_ANSWER' })}
                            {' '}
                            {':'}
                          </Typography>
                          <Typography className="col" variant="h2">
                            {item.quiz_correct}
                          </Typography>
                        </div>
                        )}
                        {item.type === 'quiz' && (
                        <div className="row mb-4">
                          <Typography className="col-4" variant="body">
                            {intl.formatMessage({ id: 'REPORT.CAMPAIGN.DETAIL.WRONG_ANSWER' })}
                            {' '}
                            {':'}
                          </Typography>
                          <Typography className="col" variant="h2">
                            {item.quiz_wrong}
                          </Typography>
                        </div>
                        )}
                      </div>
                    )}
                  />
                  )}
                </div>
              </div>
              <div className={`col-1 ${classes.oneColumn}`} />
              <div className="col-10 col-sm-5 mt-3 mt-md-0">
                <Typography variant="bodySemiBold">
                  {intl.formatMessage({ id: 'REPORT.CAMPAIGN.DETAIL.ENTERED' })}
                </Typography>
                <Typography variant="bodySemiBold">
                  {intl.formatMessage({ id: 'REPORT.CAMPAIGN.DETAIL.PARTICIPATED' })}
                </Typography>
              </div>
              <div className="col-1 mt-3 mt-md-0">
                <Typography className="ml-3" variant="bodySemiBold">
                  {addCommasToNumber(item.open)}
                </Typography>
                <Typography className="ml-3" variant="bodySemiBold">
                  {addCommasToNumber(item.participation)}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default injectIntl(CampaignReportDetailCell);
