import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { addCommasToNumber, nameOfMaterialCategory } from '../../../../utils';
import { Typography, Pagination } from '../../../components';
import { CorrectAnswerIcon } from '../../../../assets/icons';

const useStyles = makeStyles((theme) => ({
  standaredView: {
    display: 'flex',
    // [theme.breakpoints.down(992)]: {
    //   display: 'none !important'
    // }
  },
  tabsTitle: {
    marginTop: '28px',
    marginBottom: '15px',
    [theme.breakpoints.down(992)]: {
      marginLeft: '15px',
      marginTop: '15px',
      marginBottom: '0px',
    }
  },
  tableRow: {
    minHeight: '45px',
    borderTop: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  tableHeader: {
    minHeight: '55px',
  },
  tableDatas: {
    minHeight: '45px',
  },
  tableBorder: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  tablePadding: {
    paddingTop: '20px'
  },
  responsiveMode: {
    display: 'none !important',
    [theme.breakpoints.down(992)]: {
      display: 'flex !important'
    }
  },
}));

const MisunderstoodMaterialsTable = ({
  intl,
  data,
  isAllDistricts
}) => {
  const initialChoiceData = [
    {
      name: intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.MM_TABLE.CORRECT' }),
      value: 'correct'
    },
    {
      name: intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.MM_TABLE.OF_USER' }),
      value: 'of_user'
    }
  ];
  const classes = useStyles();
  const [choice, setChoice] = useState(initialChoiceData[0]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <div className={`row d-flex flex-column m-3 mb-0 ${classes.standaredView}`}>

        <Typography className={classes.tabsTitle} variant="h1">
          {intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.MM_TITLE' })}
        </Typography>
        <div className={`row w-100 d-flex align-items-center ${classes.tableHeader}`}>
          <Typography
            className="d-flex col-3 justify-content-start"
            variant="bodySemiBold"
          >
            {intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.MM_TABLE.TITLE' })}
          </Typography>

          <Typography
            className="d-flex pl-0 col-4 justify-content-start"
            variant="bodySemiBold"
          >
            {intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.MM_TABLE.DESCRIPTION' })}
          </Typography>

          <div className="row col-5 d-flex">
            <Typography
              className="d-flex col-4 justify-content-center"
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.MM_TABLE.ANSWER' })}
            </Typography>

            <Typography
              className="d-flex col-4 justify-content-center"
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.MM_TABLE.OF_USER' })}
            </Typography>
            <Typography
              className="d-flex col-4 justify-content-center"
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.MM_TABLE.CORRECT' })}
            </Typography>

          </div>
        </div>

        { data && data.slice((page - 1) * rowsPerPage, (page * rowsPerPage)).map((item, id) => {
          let sum = 0;
          /* eslint-disable no-unused-expressions */
          item.all_opt && item.all_opt.map((it) => {
            sum += Number(it.value);
            return null;
          });
          return (
            <div
              key={`MM_REPORT_${item.mat_nm} - ${String(id)}`}
              className={`row w-100 d-flex h-100 ${classes.tableRow}`}
            >

              <Typography className={`col-3 h-100 d-flex align-items-start ${classes.tablePadding}`} variant="body">
                {item.mat_nm ? item.mat_nm : ''}
              </Typography>

              <Typography className={`col-4 h-100 d-flex flex-column align-items-start justify-content-start pl-0 pb-3 ${classes.tablePadding}`} variant="bodySemiBold">
                {!isAllDistricts && item.disposal_header
                && (
                  <Typography
                    variant="bodySemiBold"
                    className={`pb-2 mb-2 w-100 ${classes.tableBorder}`}
                  >
                    { item.disposal_header }
                  </Typography>
                )}
                { !isAllDistricts && item.disposal_body ? item.disposal_body
                  : intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.DETAIL.NO_DESCRIPTION' })}
              </Typography>

              <div className={`col-5 h-100 d-flex flex-column ${classes.tableDatas}`}>
                { item.all_opt && item.all_opt.map((ans, index) => (
                  <div
                    className={`row d-flex align-items-center pl-0 pr-3 ${classes.tableDatas} ${item.all_opt.length - 1 !== index ? classes.tableBorder : null}`}
                    key={`MM_ANSWER_${nameOfMaterialCategory(ans)} - ${String(index)}`}
                  >
                    <Typography
                      className={`col-4 d-flex justify-content-center align-items-center ${classes.tableDatas}`}
                      variant="bodySemiBold"
                      color={ans.name === item.correct_id
                        ? process.env.REACT_APP_COLOR_POSITIVE : process.env.REACT_APP_COLOR_TEXT}
                    >
                      {
                        !isAllDistricts && (ans.name === item.correct_id) && <CorrectAnswerIcon className="mr-2" />
                      }
                      {nameOfMaterialCategory(ans.name) || ''}
                    </Typography>
                    <Typography className={`col-4 h-100 d-flex pl-0 justify-content-center align-items-center ${classes.tableDatas}`} variant="bodySemiBold">
                      {ans.value ? `${addCommasToNumber(ans.value)}` : '0'}
                    </Typography>
                    <Typography className={`col-4 d-flex pl-0 justify-content-center align-items-center ${classes.tableDatas}`} variant="bodySemiBold">
                      {ans.value
                        ? `${((Number(ans.value) * 100) / sum).toFixed(2)}%`
                        : '0%'}
                    </Typography>
                  </div>
                ))}
              </div>

            </div>
          );
        })}
        { Array.isArray(data)
        && (
        <div className={`mt-5 mb-4 d-flex justify-content-end align-items-center ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
          <Pagination
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={(value) => handleChangePage(value)}
            handleRowsPerPageChange={(value) => setRowsPerPage(value)}
            dataLength={data && data.length}
          />
        </div>
        )}
      </div>

      {/* <div className={`row d-flex flex-column m-3 mb-0 ${classes.responsiveMode}`}>
        <AutoComplete
          className="mt-2 mb-3"
          options={initialChoiceData}
          value={choice}
          onChange={setChoice}
          optionTitle="name"
          optionId="value"
        />
        { data && data.map((item, id) => (
          <div key={`RF_REPORT_${item.title} - ${String(id)}`}>
            <Typography
              className={`row w-100 d-flex align-items-center ${id === 0 ? 'mt-3' : 'mt-5'}
             ${classes.tableDatas} ${classes.tableBorder}`}
              variant="body"
            >
              {item.title || ''}
            </Typography>
            <Typography
              className={`row w-100 d-flex align-items-center
             ${classes.tableDatas} ${classes.tableBorder}`}
              variant="bodySemiBold"
            >
              {item.description || ''}
            </Typography>

            { item.answers && item.answers.map((ans, index) => (
              <div
                key={`RF_Report_sub_${ans.name} - ${String(index)}`}
                className={`row w-100 d-flex align-items-center pl-3 ${classes.tableDatas}
                ${index === item.answers.length - 1 && id === data.length - 1 ? null : classes.tableBorder}`}
              >
                <Typography
                  className={`col-6 d-flex align-items-center ${classes.tableDatas}`}
                  variant="bodySemiBold"
                  color={ans.is_trust === 't' ? process.env.REACT_APP_COLOR_POSITIVE : process.env.REACT_APP_COLOR_TEXT}
                >
                  {
                      ans.is_trust === 't' && <CorrectAnswerIcon className="mr-2" />
                  }
                  {ans.name || ''}
                </Typography>

                <div className="col-6 h-100 d-flex justify-content-center align-items-center">
                  <Typography
                    variant="bodySemiBold"
                  >
                    {choice.value === 'correct'
                      ? `${ans[choice.value]} %`
                      : addCommasToNumber(ans[choice.value])}
                  </Typography>
                </div>

              </div>
            ))}
          </div>
        ))}
      </div> */}
    </>
  );
};

export default injectIntl(MisunderstoodMaterialsTable);
