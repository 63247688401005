import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { removeZeroFromTime, transformToAmpm } from '../../../utils';
import { Typography } from '../../components';
import * as actions from '../redux/actions';

const useStyles = makeStyles(() => ({
  borderBottom: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  }
}));

const SetupWizardPreview = ({
  intl,
  newDepot,
}) => {
  const classes = useStyles();
  const days = [
    intl.formatMessage({ id: 'GENERAL.SUN' }),
    intl.formatMessage({ id: 'GENERAL.MON' }),
    intl.formatMessage({ id: 'GENERAL.TUE' }),
    intl.formatMessage({ id: 'GENERAL.WED' }),
    intl.formatMessage({ id: 'GENERAL.THU' }),
    intl.formatMessage({ id: 'GENERAL.FRI' }),
    intl.formatMessage({ id: 'GENERAL.SAT' }),
  ];
  return (
    <div className="row col-12 col-md-6 d-flex flex-column justify-content-start align-items-start">
      <Typography variant="h2" className="mb-3">
        {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.PREVIEW' })}
      </Typography>

      {
        (newDepot.address || newDepot.instruction) && (
          <div className={`w-100 pb-2 ${classes.borderBottom}`}>
            {
              newDepot.address && (
                <Typography variant="body" className="mb-3">
                  {newDepot.address}
                </Typography>
              )
            }
            {
              newDepot.instruction && (
                <Typography variant="body" className="mb-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: newDepot.instruction
                    }}
                  />
                </Typography>
              )
            }
          </div>
        )
      }

      {
        newDepot.seasons.map((item, index) => (
          <div
            key={`Preview_Season_${String(index)}`}
            className={`w-100 py-4 ${classes.borderBottom}`}
          >
            {item.season_nm
              && (
                <Typography
                  variant="bodySemiBold"
                  className="mb-3"
                >
                  {item.season_nm}
                </Typography>
              )}
            {item.season_notes && item.season_notes.length
              && (
                <Typography
                  variant="body"
                  className="mb-3"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.season_notes
                    }}
                  />
                </Typography>
              )}
            {
              item.times.map((member, index) => {
                if (member.hours_lower_tm || member.hours_upper_tm) {
                  return (
                    <div
                      key={`Preview_times_${String(index)}`}
                      className="w-100 d-flex mt-2 justify-content-between"
                    >
                      <Typography variant="body">
                        {days[member.dow_nbr]}
                      </Typography>
                      <div className="d-flex flex-column">
                        <Typography variant="bodySemiBold">
                          {member.hours_lower_tm ? removeZeroFromTime(transformToAmpm(member.hours_lower_tm)) : ''}
                          {member.hours_lower_tm && member.hours_upper_tm && ' - '}
                          {member.hours_upper_tm ? removeZeroFromTime(transformToAmpm(member.hours_upper_tm)) : ''}
                        </Typography>
                        {
                      (member.add_hours_lower_tm || member.add_hours_upper_tm) && (
                        <Typography className="mt-2" variant="bodySemiBold">
                          {member.add_hours_lower_tm ? removeZeroFromTime(transformToAmpm(member.add_hours_lower_tm)) : ''}
                          {member.add_hours_lower_tm && member.add_hours_upper_tm && ' - '}
                          {member.add_hours_upper_tm ? removeZeroFromTime(transformToAmpm(member.add_hours_upper_tm)) : ''}
                        </Typography>
                      )
                    }
                      </div>
                    </div>
                  );
                } return null;
              })
            }
          </div>
        ))
      }

      <div className="w-100 d-flex mt-4 justify-content-between mb-2">
        <Typography variant="body">
          {intl.formatMessage({ id: 'DEPOT.CONTACT.TELEPHONE' })}
        </Typography>
        <Typography variant="bodySemiBold">
          {newDepot.phone}
        </Typography>
      </div>

      <div className="w-100 d-flex justify-content-between mb-2">
        <Typography variant="body">
          {intl.formatMessage({ id: 'DEPOT.CONTACT.ALT_TELEPHONE' })}
        </Typography>
        <Typography variant="bodySemiBold">
          {newDepot.altPhone}
        </Typography>
      </div>

      <div className="w-100 d-flex justify-content-between mb-2">
        <Typography variant="body">
          {intl.formatMessage({ id: 'DEPOT.CONTACT.EMAIL' })}
        </Typography>
        <Typography variant="bodySemiBold">
          {newDepot.email}
        </Typography>
      </div>

      <div className="w-100 d-flex justify-content-between mb-2">
        <Typography variant="body">
          {intl.formatMessage({ id: 'DEPOT.CONTACT.WEBSITE' })}
        </Typography>
        <Typography variant="bodySemiBold">
          {newDepot.website}
        </Typography>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  newDepot: store.depot.newDepot,
});

export default injectIntl(connect(mapStateToProps, actions)(SetupWizardPreview));
