import React from 'react';
import {
  Document, Page, Text, View, StyleSheet, Image
} from '@react-pdf/renderer';
import { addCommasToNumber } from '../../../../utils';
import { toAbsoluteUrl } from '../../../../../_metronic';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    paddingVertical: 5
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  summaryHead: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 10,
    marginBottom: 0,
    padding: 10,
  },
  headerText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'black',
  },
  subTitleText: {
    fontSize: 12,
    color: 'black'
  },
  marginLeft: {
    marginLeft: 15
  },
  h1: {
    fontSize: '22pt'
  },
  h2: {
    fontSize: '20pt'
  },
  h3: {
    fontSize: '18pt'
  },
  h4: {
    fontSize: '18pt'
  },
  h5: {
    fontSize: '16pt'
  },
  h6: {
    fontSize: '14pt'
  },
  body: {
    fontSize: '12pt'
  },
  circle: {
    width: '10pt',
    height: '10pt',
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY,
    borderRadius: '100pt',
    marginRight: '10pt',
  },
  image: {
    width: '110px',
    height: '40px',
    marginLeft: '13px',
    marginTop: '5px',
  }
});

// Create Document Component
const ReportPDF = ({
  usersAtAGlance,
  currentPeriod,
  date,
  reportingDistrict,
  currentPeriodType,
  detailData,
  detailSummaryData,
  isCounty
}) => {
  const whatIsColor = () => {
    if (currentPeriodType === 'positive') {
      return (process.env.REACT_APP_COLOR_POSITIVE);
    }
    if (currentPeriodType === 'negative') {
      return (process.env.REACT_APP_COLOR_NEGATIVE);
    }
    return (process.env.REACT_APP_COLOR_NEUTRAL);
  };

  return (
    <Document>
      {/* summary 's PDF */}
      <Page size="A4" orientation="landscape" style={styles.page}>
        <>
          <Image
            style={styles.image}
            src={`${toAbsoluteUrl('/media/logos/RCLogo.png')}`}
          />
          <View style={styles.summaryHead}>
            <Text style={styles.headerText}>
              {usersAtAGlance}
            </Text>

            <Text style={[styles.subTitleText, styles.marginLeft,
              {
                color: process.env.REACT_APP_COLOR_DARK_GRAY,
              }
            ]}
            >
              {currentPeriod}
            </Text>

            <Text style={[styles.subTitleText, styles.marginLeft,
              { color: process.env.REACT_APP_COLOR_PRIMARY }]}
            >
              {date}
            </Text>

            <Text style={[styles.subTitleText, {
              marginLeft: isCounty ? 100 : 150
            }]}
            >
              {reportingDistrict}
            </Text>

          </View>

          <View style={{
            flexDirection: 'row',
            width: '100%',
            maxHeight: 140,
            margin: 10,
            padding: 10,
          }}
          >
            <View style={{
              flexGrow: 1,
              height: '100%',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              borderRight: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
            }}
            >
              <Text style={[{
                fontSize: '40pt',
                fontWeight: 'heavy',
                color: whatIsColor(),
                marginBottom: '10pt'
              }]}
              >
                {addCommasToNumber(detailSummaryData.current_period.total)}
              </Text>
              <Text style={[styles.subTitleText, { fontSize: '14pt', fontWeight: 'bold' }]}>
                Current Period
              </Text>
            </View>
            <View style={{
              flexGrow: 1,
              height: '100%',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              paddingHorizontal: 10,
              borderRight: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
            }}
            >
              <Text style={[{
                fontSize: '40pt',
                fontWeight: 'heavy',
                marginBottom: '10pt'
              }]}
              >
                {addCommasToNumber(detailSummaryData.last_period.total)}
              </Text>
              <Text style={[styles.subTitleText, { fontSize: '14pt', fontWeight: 'bold' }]}>
                Previous Period
              </Text>
            </View>
            <View style={{
              flexGrow: 2,
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              paddingHorizontal: 20,
              borderRight: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
            }}
            >
              { detailSummaryData
               && detailSummaryData.top_problem_type
               && detailSummaryData.top_problem_type.length > 0
               && detailSummaryData.top_problem_type.map((item, index) => (
                 <View
                   key={String(index)}
                   style={{
                     flexGrow: 1,
                     width: '100%',
                     justifyContent: 'space-between',
                     alignItems: 'center',
                     flexDirection: 'row'
                   }}
                 >
                   <Text style={styles.h6}>
                     {item.problem_type_label}
                   </Text>
                   <Text style={styles.h6}>
                     {item.count}
                   </Text>
                 </View>
               ))}
            </View>

          </View>
          {/* detail  */}
          <View style={{
            flexDirection: 'column',
            width: '100%',
            minHeight: 140,
            margin: 10,
            padding: 10,
          }}
          >
            {
              detailData.map((item, index) => (
                <View
                  key={String(index)}
                  style={{
                    width: '98%',
                    padding: 10,
                    marginTop: 5,
                    paddingBottom: 15,
                    flexDirection: 'column',
                    borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_GRAY_DARK}`,
                    backgroundColor: index % 2 ? process.env.REACT_APP_COLOR_GRAY_LIGHT
                      : process.env.REACT_APP_COLOR_BACKGROUND_FILL
                  }}
                >
                  <Text style={styles.h6}>
                    Date :
                    {' '}
                    {item.date}
                  </Text>
                  <Text style={[styles.h6, { marginTop: 5 }]}>
                    Problem Type :
                    {' '}
                    {item.problem_type_label}
                  </Text>
                  <Text style={[styles.h6, { marginTop: 5 }]}>
                    Name :
                    {' '}
                    {item.contact_full_nm || 'No Name'}
                  </Text>
                  <Text style={[styles.h6, { marginTop: 5 }]}>
                    Address :
                    {' '}
                    {item.address || 'No Address'}
                  </Text>
                  <Text style={[styles.h6, { marginTop: 5 }]}>
                    Detail :
                  </Text>
                  <View style={{
                    width: '100%',
                    padding: 20,
                    marginTop: 10,
                    flexDirection: 'column'
                  }}
                  >
                    <Text style={[styles.h6, { marginTop: 5 }]}>
                      Description :
                      {' '}
                      {item.problem_dscr || 'No Description'}
                    </Text>

                    <Text style={[styles.h6, { marginTop: 5 }]}>
                      Phone Number :
                      {' '}
                      {item.contact_phone_nbr || 'No Phone Number'}
                    </Text>
                    <Text style={[styles.h6, { marginTop: 5 }]}>
                      Email :
                      {' '}
                      {item.contact_email_addr || 'No Email'}
                    </Text>
                    <Text style={[styles.h6, { marginTop: 5 }]}>
                      District :
                      {' '}
                      {item.district_nm || 'No District Name'}
                    </Text>

                  </View>
                </View>
              ))
            }

          </View>
        </>
      </Page>
    </Document>
  );
};
export default ReportPDF;
