import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import * as sharedActions from '../../redux/actions';

import { Portlet } from '../../../../_metronic/partials/content/Portlet';
import { Typography } from '../../components';
import { DoughnutChart } from '../../Report/components';
import { addCommasToNumber } from '../../../utils';
import { LoadingSpinner, ErrorContainer } from '../../components/loading';

const useStyles = makeStyles({
  header: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    padding: '15px 0'
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 7,
    background: '#000'
  },
  wideText: {
    width: 200,
    margin: '7px 0'
  },
  viewReportText: {
    color: process.env.REACT_APP_COLOR_PRIMARY
  }
});

const Reports = ({
  intl, reports, error, loading, advocateAdmin
}) => {
  const classes = useStyles();

  let content = (
    <div className="mx-5 py-4">
      <div className="row">
        <div className="col-6">
          <DoughnutChart
            isDashboardChart
            total={addCommasToNumber(reports?.users.total)}
            insideText={intl.formatMessage({ id: 'REPORT.USERS_TOTAL_USERS' })}
            labels={[
              intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.MOBILE.TITLE' }),
              intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.WEB_APP.TITLE' }),
              intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.COMMUNICATION.TITLE' }),
              intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.CALENDAR.TITLE' }),
            ]}
            values={advocateAdmin !== true ? [
              reports?.users.mobile,
              reports?.users.webapp,
              reports?.users.communication,
              reports?.users.calendar
            ] : [
              reports?.users.mobile,
              reports?.users.webapp,
            ]}
          />
        </div>
        <div className="col-6 d-flex justify-content-center flex-column">

          <div className="d-flex align-items-center">
            <div className={classes.circle} />
            <Typography className={classes.wideText} variant="h1">
              {intl.formatMessage({ id: 'MENU.REPORTS.NEW_SUBSCRIBERS' })}
            </Typography>
            <Typography color="#6C7293" variant="h1">
              {addCommasToNumber(reports?.new_subscribers)}
            </Typography>
          </div>

          <div className="d-flex align-items-center">
            <div className={classes.circle} />
            <Typography className={classes.wideText} variant="h1">
              {intl.formatMessage({ id: 'REPORT.GENERAL.INTERACTIONS' })}
            </Typography>
            <Typography color="#6C7293" variant="h1">
              {addCommasToNumber(reports?.interactions)}
            </Typography>
          </div>

          <div className="d-flex align-items-center">
            <div className={classes.circle} />
            <Typography className={classes.wideText} variant="h1">
              {intl.formatMessage({ id: 'REPORT.MATERIALS.SUMMARY.HEADER' })}
            </Typography>
            <Typography color="#6C7293" variant="h1">
              {addCommasToNumber(reports?.material_searches)}
            </Typography>
          </div>

        </div>
      </div>

      <Link to="/report/dashboard">
        <Typography
          className={`d-flex justify-content-end ${classes.viewReportText}`}
          variant="bodySemiBold"
        >
          {intl.formatMessage({ id: 'GENERAL.REPORTS.HOME.PAGE' })}
        </Typography>
      </Link>
    </div>
  );

  if (loading) {
    content = <LoadingSpinner height={307} />;
  }

  if (error) {
    content = <ErrorContainer reportName="Dashboard" height={307} error={error} />;
  }

  return (
    <Portlet>
      <div className={`d-flex justify-content-between align-items-center px-4 ${classes.header}`}>
        <Typography variant="h1">
          {intl.formatMessage({ id: 'DASHBOARD.REPORTS.HEADER.REPORTS' })}
        </Typography>
        <Typography style={{ color: '#696969' }} variant="bodySemiBold">
          {intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_30_DAYS' })}
        </Typography>
      </div>
      {content}
    </Portlet>
  );
};

export default injectIntl(connect(
  (store) => ({
    loading: store.dashboard.loading,
    reports: store.dashboard.reports,
    error: store.dashboard.error,
    advocateAdmin: store.auth.advocateAdmin
  }),
  { ...sharedActions }
)(Reports));
