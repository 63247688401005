import React from 'react';

import {
  Portlet,
  PortletBody,
  PortletHeader,
} from '../../../../_metronic/partials/content/Portlet';

const NewMessage = ({ title, children }) => (
  <div className="col-12 col-xl-6">
    <div className="ml-2 h-100 position-relative">
      <Portlet fluidHeight>
        <PortletHeader title={title} />
        <PortletBody>
          {children}
        </PortletBody>
      </Portlet>
    </div>
  </div>
);

export default NewMessage;
