/**
 *
 */
const initialState = {
  fetchState: 'no-request',
  snackbarState: 'no-request',
  responseStatus: 'success',
  responseMessage: null,
  wizardStatus: false,
  collectionsList: [],
  collectionWizardId: null,
};

export default initialState;
