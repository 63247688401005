import React from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme as createMuiTheme } from '@material-ui/core/styles';

const textColor = '#404040';

const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    typography: {
      fontFamily: 'Poppins',
      h3: {
        color: textColor,
        fontSize: '4.3333rem',
        fontWeight: 600,
      },
      h4: {
        color: textColor,
        fontSize: '3.5rem',
        fontWeight: 600,
      },
      h5: {
        color: textColor,
        fontWeight: 700,
      },
      h6: {
        color: textColor,
        fontWeight: 600,
        fontSize: '1.1rem',
      },
      body1: {
        color: textColor,
        fontWeight: 500
      },
      body2: {
        color: textColor,
        fontWeight: 700
      },
      subtitle1: {
        color: textColor,
        fontWeight: 700,
        fontSize: 13,
        lineHeight: 1.90,
      },
      subtitle2: {
        color: textColor,
        fontWeight: 500,
        fontSize: 11,
        lineHeight: 1.90,
      },
    },

    palette: {
      contrastThreshold: 3,
      primary: {
        main: '#0086C2',
      },
      secondary: {
        main: '#00AE8D',
        contrastText: '#ffffff'
      },
      error: {
        main: '#e32945'
      },
      warning: {
        main: '#FFDF00'
      },
      info: {
        main: '#11A9CE'
      },
      success: {
        main: '#11CEC4'
      }
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1
      }
    }
  }
);

export default function ThemeProvider(props) {
  const { children } = props;

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
