import React from 'react';

const ViewDetailsIcon = ({
  color,
  className,
}) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    className={`prefix__bi prefix__bi-box-arrow-up-right ${className}`}
    fill={color}
  >
    <path
      fillRule="evenodd"
      d="M1.5 13A1.5 1.5 0 003 14.5h8a1.5 1.5 0 001.5-1.5V9a.5.5 0 00-1 0v4a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h4a.5.5 0 000-1H3A1.5 1.5 0 001.5 5v8zm7-11a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v5a.5.5 0 01-1 0V2.5H9a.5.5 0 01-.5-.5z"
    />
    <path
      fillRule="evenodd"
      d="M14.354 1.646a.5.5 0 010 .708l-8 8a.5.5 0 01-.708-.708l8-8a.5.5 0 01.708 0z"
    />
  </svg>
);

export default ViewDetailsIcon;
