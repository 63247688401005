// eslint-disable-next-line import/no-cycle
import cts from '../../../crud/cts';

const getDashboardReport = ({
  from,
  to,
  municipality,
  county
}) => cts(
  'get',
  `reports/dashboard?from=${from}&to=${to}&county=${county}&municipality=${municipality}`
);

export {
  getDashboardReport,
  getDashboardReport as default
};
