/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import {
  Portlet,
  PortletBody,
  PortletHeader,
} from '../../../../../_metronic/partials/content/Portlet';
import {
  DisplayColorIcon,
  CollapseIcon,
  ExpandIcon,
  InfoIcon
} from '../../../../assets/icons';
import {
  IconButton, AutoComplete, Typography
} from '../../../components';
import { addCommasToNumber } from '../../../../utils';
import * as actions from '../redux/actions';
import { TableLoading } from '../../../components/loading';

const useStyles = makeStyles((theme) => ({
  detailLoading: {
    height: '50vh'
  },
  currentPeriodTotal: {
    color: process.env.REACT_APP_COLOR_REPORT_1
  },
  previousPeriodTotal: {
    color: process.env.REACT_APP_COLOR_PRIMARY_LIGHT
  },
  lastYearTotal: {
    color: process.env.REACT_APP_COLOR_DELETE
  },
  ranking: {
    color: process.env.REACT_APP_COLOR_TERTIARY,
    fontStyle: 'italic'
  },
  dataTable: {
    '& > div:last-child': {
      borderBottom: '0px !important',
    }
  },
  border: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  icon: {
    width: 30,
    height: 30
  },
  smallerThanMdHide: {
    [theme.breakpoints.between('xs', 'md')]: {
      display: 'none !important'
    }
  },
  smallerThanMdShow: {
    [theme.breakpoints.between('md', 'xl')]: {
      display: 'none !important'
    },
  },
  onlyMd: {
    [theme.breakpoints.only('md')]: {
      display: 'flex !important'
    },
  },
}));

const Detail = ({
  intl,
  data,
  fromDate,
  toDate,
  fetchState,
  setReport,
  reportType
}) => {
  const classes = useStyles();
  const [expandItems, setExpandItems] = useState(Array(10).fill(false));
  const [expandItemsMobile, setExpandItemsMobile] = useState(Array(10).fill(false));
  const isSendRequest = fetchState === 'send-request';

  const compare = (a, b) => {
    const current_periodA = a.current_period;
    const current_periodB = b.current_period;
    return current_periodB - current_periodA;
  };

  return (
    <Portlet className="h-100 position-relative">
      <PortletHeader
        title={(
          <div className="d-flex align-items-center">
            <Typography variant="h1">
              {intl.formatMessage({ id: 'REPORT.PAGE_VIEWS.DETAIL.HEADER' })}
            </Typography>
            <Typography className="ml-4" variant="bodySemiBold">
              {`${moment(fromDate).format('MMMM Do, YYYY')} - ${moment(toDate).format('MMMM Do, YYYY')}`}
            </Typography>
          </div>
        )}
      />
      <PortletBody>
        <div className={`mt-3 pb-3 ${classes.smallerThanMdShow}`}>
          <AutoComplete
            optionTitle="name"
            title={intl.formatMessage({ id: 'REPORT.MATERIALS.MOBILE.REPORT_PERIOD' })}
            isClearable={false}
            options={
                    [{ name: 'Current Period', value: 0 }, { name: 'Previous Period', value: 1 }, { name: 'Last Year', value: 2 }]
                  }
            onChange={(data) => { setReport(data); }}
            value={reportType}
          />
        </div>
        { isSendRequest ? (
          <div className="d-flex overflow-hidden flex-column w-100 justify-content-between align-items-center">
            <TableLoading width="100%" />
          </div>
        ) : (
          <>
            {data && (
              <div className="mt-2">
                <div className={`row ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
                  <div className="col-4" />
                  <div className="col d-flex align-items-center">
                    <DisplayColorIcon color={process.env.REACT_APP_COLOR_REPORT_1} />
                    <Typography variant="h2">
                      {intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_PERIOD' })}
                    </Typography>
                  </div>
                  <div className="col d-flex align-items-center">
                    <DisplayColorIcon color={process.env.REACT_APP_COLOR_PRIMARY_LIGHT} />
                    <Typography variant="h2">
                      {intl.formatMessage({ id: 'REPORT.GENERAL.PREVIOUS_PERIOD' })}
                      <IconButton
                        tooltip={intl.formatMessage({ id: 'REPORT.GENERAL.PREVIOUS_PERIOD.TOOLTIP' })}
                      >
                        <InfoIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                      </IconButton>
                    </Typography>
                  </div>
                  <div style={{ whiteSpace: 'nowrap' }} className="col d-flex align-items-center">
                    <DisplayColorIcon color={process.env.REACT_APP_COLOR_DELETE} />
                    <Typography variant="h2">
                      {intl.formatMessage({ id: 'REPORT.GENERAL.LAST_YEAR' })}
                    </Typography>
                  </div>
                  <div className="col-1" />
                </div>
                <div className={`row d-flex align-items-center mt-4 ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
                  <div className="col-4">
                    <Typography variant="h2">
                      {intl.formatMessage({ id: 'REPORT.PAGE_VIEWS.DETAIL.VIEWS_TOTALS' })}
                    </Typography>
                  </div>
                  <div className="col ml-4">
                    <Typography variant="numberSmall" className={classes.currentPeriodTotal}>
                      {addCommasToNumber(data.summary ? data.summary.current_period : 0)}
                    </Typography>
                  </div>
                  <div className="col ml-4">
                    <Typography variant="numberSmall" className={classes.previousPeriodTotal}>
                      {addCommasToNumber(data.summary ? data.summary.previous_period : 0)}
                    </Typography>
                  </div>
                  <div className="col ml-4">
                    <Typography variant="numberSmall" className={classes.lastYearTotal}>
                      {addCommasToNumber(data.summary ? data.summary.last_year : 0)}
                    </Typography>
                  </div>
                  <div className="col-1" />
                </div>
                <div className="d-flex align-items-center mt-4 mb-3">
                  <Typography className="mr-2" variant="h2">
                    {intl.formatMessage({ id: 'REPORT.PAGE_VIEWS.DETAIL.PAGES' })}
                  </Typography>
                  <Typography className={classes.ranking} variant="bodySemiBold">
                    {'('}
                    {intl.formatMessage({ id: 'REPORT.PAGE_VIEWS.DETAIL.SEARCH_RANKING' })}
                    {')'}
                  </Typography>
                </div>
                <div className={classes.dataTable}>
                  {data.detail && Object.values(data.detail).map((item, index) => {
                    const titles = Object.keys(data.detail);
                    return (
                      <React.Fragment key={`dataTable-${String(index)}`}>
                        <div className={`row align-items-center py-4 ${classes.border} ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
                          <div className="col-4">
                            <Typography variant="bodySemiBold">
                              {titles[index]}
                            </Typography>
                          </div>
                          <div className="col row align-items-center ml-4">
                            <Typography className="col-3 text-right" variant="bodySemiBold">
                              { addCommasToNumber(item.total.current_period) }
                            </Typography>
                            <Typography variant="body" className={`col pl-5 ${classes.ranking}`}>
                              {'('}
                              {addCommasToNumber(item.total.current_period_rank) }
                              {')'}
                            </Typography>
                          </div>
                          <div className="col row align-items-center ml-4">
                            <Typography className="col-3 text-right" variant="bodySemiBold">
                              { addCommasToNumber(item.total.previous_period) }
                            </Typography>
                            <Typography variant="body" className={`col pl-5 ${classes.ranking}`}>
                              {'('}
                              { addCommasToNumber(item.total.previous_period_rank) }
                              {')'}
                            </Typography>
                          </div>
                          <div className="col row align-items-center ml-4">
                            <Typography className="col-3 text-right" variant="bodySemiBold">
                              { addCommasToNumber(item.total.last_year) }
                            </Typography>
                            <Typography variant="body" className={`col pl-5 ${classes.ranking}`}>
                              {'('}
                              { addCommasToNumber(item.total.last_year_rank) }
                              {')'}
                            </Typography>
                          </div>
                          {item.items && item.items.length > 0
                            ? (
                              <div className="col-1">

                                {expandItems[index] ? (
                                  <IconButton
                                    onClick={() => {
                                      const temp = [...expandItems];
                                      temp[index] = false;
                                      setExpandItems(temp);
                                    }}
                                  >
                                    <CollapseIcon className={classes.icon} />
                                  </IconButton>
                                )
                                  : (
                                    <IconButton
                                      onClick={() => {
                                        const temp = [...expandItems];
                                        temp[index] = true;
                                        setExpandItems(temp);
                                      }}
                                    >
                                      <ExpandIcon className={classes.icon} />
                                    </IconButton>
                                  )}
                              </div>
                            ) : <div className="col-1" />}
                        </div>
                        {expandItems[index] && (
                        <>
                          {item.items && [...item.items].sort(compare)
                            .map((detailItem, index) => (
                              <div key={`subTitle-${String(index)}`} className={`row align-items-center pt-3 pb-3 ${classes.border}`}>
                                <div className="col-4">
                                  <Typography className="ml-4" variant="body">
                                    {detailItem.title}
                                  </Typography>
                                </div>
                                <div className="col row align-items-center ml-4">
                                  <Typography className="col-3 text-right" variant="bodySemiBold">
                                    { addCommasToNumber(detailItem.current_period) }
                                  </Typography>
                                </div>
                                <div className="col row align-items-center ml-4">
                                  <Typography className="col-3 text-right" variant="bodySemiBold">
                                    { addCommasToNumber(detailItem.previous_period) }
                                  </Typography>
                                </div>
                                <div className="col row align-items-center ml-4">
                                  <Typography className="col-3 text-right" variant="bodySemiBold">
                                    {addCommasToNumber(detailItem.last_year) }
                                  </Typography>
                                </div>
                                <div className="col-1" />
                              </div>
                            ))}
                        </>
                        )}
                      </React.Fragment>
                    );
                  })}
                  <div className={`mt-3 pb-3 ${classes.smallerThanMdShow}`}>
                    {data.detail && Object.values(data.detail).map((item, index) => {
                      const titles = Object.keys(data.detail);
                      return (
                        <React.Fragment key={`mobileView-${String(index)}`}>
                          <div className={`row p-4 justify-content-between align-items-center ${classes.border}`}>
                            <div className="col">
                              <Typography className={classes.materialItem} variant="h2">
                                {titles[index]}
                              </Typography>
                            </div>
                            <div className="col row justify-content-between align-items-center">
                              <div className="col d-flex justify-content-between align-items-center ml-4">
                                <Typography variant="body" className={`col-3 text-center ${classes.ranking}`}>
                                  {'('}
                                  { (reportType.value === 0
                                  ) && addCommasToNumber(
                                    item.total.current_period_rank
                                  )}
                                  { (reportType.value === 1) && addCommasToNumber(
                                    item.total.previous_period_rank
                                  )}
                                  { (reportType.value === 2
                                  )
                                          && addCommasToNumber(item.total.last_year_rank) }
                                  {')'}
                                </Typography>
                                <Typography className="col-3 text-center" variant="bodySemiBold">
                                  { (reportType.value === 0
                                  )
                                          && addCommasToNumber(item.total.current_period) }
                                  { (reportType.value === 1
                                  )
                                           && addCommasToNumber(item.total.previous_period) }
                                  {(reportType.value === 2
                                  )
                                          && addCommasToNumber(item.total.last_year) }
                                </Typography>
                              </div>
                              {
                                   item.items && item.items.length > 0
                                     ? (
                                       <div className="col ml-3">
                                         {expandItemsMobile[index]
                                           ? (
                                             <IconButton
                                               onClick={() => {
                                                 const temp = [...expandItemsMobile];
                                                 temp[index] = false;
                                                 setExpandItemsMobile(temp);
                                               }}
                                             >
                                               <CollapseIcon className={classes.icon} />
                                             </IconButton>
                                           )
                                           : (
                                             <IconButton
                                               onClick={() => {
                                                 const temp = [...expandItemsMobile];
                                                 temp[index] = true;
                                                 setExpandItemsMobile(temp);
                                               }}
                                             >
                                               <ExpandIcon className={classes.icon} />
                                             </IconButton>
                                           )}
                                       </div>
                                     )
                                     : <div className="col ml-3" />
                                    }
                            </div>
                          </div>
                          {expandItemsMobile[index] && (
                          <>
                            {item.items && item.items.map((detailItem, index) => (
                              <div key={`normalView-${String(index)}`} className={`row pt-3 pb-3 justify-content-between align-items-center ${classes.border}`}>
                                <div className="col">
                                  <Typography className="ml-4" variant="bodySemiBold">
                                    {detailItem.title}
                                  </Typography>
                                </div>
                                <div className="col row justify-content-center align-items-center ml-4">
                                  <Typography className="col-3 text-right" variant="bodySemiBold">
                                    {(reportType.value === 0)
                                            && addCommasToNumber(detailItem.current_period) }
                                    {(reportType.value === 1)
                                            && addCommasToNumber(detailItem.previous_period) }
                                    {(reportType.value === 2)
                                            && addCommasToNumber(detailItem.last_year) }
                                  </Typography>
                                </div>
                                <div className="col-1" />
                              </div>
                            ))}
                          </>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </>
        )}

      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
  fetchState: store.pageViewsReport.fetchState,
  reportType: store.materialReport.reportType
});

export default injectIntl(connect(mapStateToProps, actions)(Detail));
