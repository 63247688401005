import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { Portlet, PortletBody, PortletHeader } from '../../../../_metronic/partials/content/Portlet';
import { EditIcon, CollapseIcon, ExpandIcon } from '../../../assets/icons';
import { removeZeroFromTime, transformToAmpm, formatAddress } from '../../../utils';
import {
  Typography, IconButton, CustomButton, InfoTooltip
} from '../../components';
import DepotMenu from './DepotMenu';
import DepotDrawerLinks from './DepotDrawerLinks';
import DepotAssignedCategory from './DepotAssignedCategory';
import * as actions from '../redux/actions';

const maxInstructionHeight = 100;
const maxHoursHeight = 120;
const maxContactHeight = 60;

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('800')]: {
      marginRight: '5px !important'
    },
  },
  borderBottom: {
    paddingBottom: '20px',
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
  },
  drawerLink: {
    paddingTop: '40px',
    paddingBottom: '20px',
  },
  assignCategory: {
    paddingTop: '30px',
    paddingBottom: '30px',
  },
  assignCategoryBorder: {
    borderBottom: 'none !important',
  },
  depotMore: {
    maxHeight: 9999,
    wordBreak: 'break-word',
  },
  instructionsLess: {
    maxHeight: maxInstructionHeight,
    overflow: 'hidden',
  },
  hoursLess: {
    maxHeight: maxHoursHeight,
    overflow: 'hidden',
  },
  contactLess: {
    maxHeight: maxContactHeight,
    overflow: 'hidden',
  },
}));

const Depot = ({
  intl,
  data,
  setAddDepotAction,
  getDepotDetailsRequest,
  locality,
}) => {
  const classes = useStyles();
  const [showInstructionMore, setShowInstructionMore] = useState(false);
  const [isInstructionMore, setIsInstructionMore] = useState(false);
  const [showHoursMore, setShowHoursMore] = useState(false);
  const [isHoursMore, setIsHoursMore] = useState(false);
  const [showContactMore, setShowContactMore] = useState(false);
  const [isContactMore, setIsContactMore] = useState(false);
  const [drawerLinkIsOpen, setDrawerLinkIsOpen] = useState(false);
  const [assignCategoryIsOpen, setAssignCategoryIsOpen] = useState(false);

  useEffect(() => {
    if (document.getElementById(`instructions_${data.drop_depot_id}`)) {
      setIsInstructionMore(document.getElementById(`instructions_${data.drop_depot_id}`).clientHeight >= maxInstructionHeight
      && document.getElementById(`instructions_${data.drop_depot_id}`).innerText.length > 120);
    }
    setIsHoursMore(document.getElementById(`hours_${data.drop_depot_id}`).clientHeight >= maxHoursHeight
    && document.getElementById(`hours_${data.drop_depot_id}`).innerText.length > 100);
    setIsContactMore(document.getElementById(`contact_${data.drop_depot_id}`).clientHeight >= maxContactHeight
    && document.getElementById(`contact_${data.drop_depot_id}`).innerText.length > 100);
  }, [
    data.depot_details,
    data.drop_depot_seasons,
    data.phone_nbr,
    data.alt_phone_nbr,
    data.email_addr,
    data.web_addr,
  ]);

  const days = [
    intl.formatMessage({ id: 'GENERAL.SUN' }),
    intl.formatMessage({ id: 'GENERAL.MON' }),
    intl.formatMessage({ id: 'GENERAL.TUE' }),
    intl.formatMessage({ id: 'GENERAL.WED' }),
    intl.formatMessage({ id: 'GENERAL.THU' }),
    intl.formatMessage({ id: 'GENERAL.FRI' }),
    intl.formatMessage({ id: 'GENERAL.SAT' }),
  ];

  const handleClickEdit = (index) => {
    window.scrollTo(0, 0);
    getDepotDetailsRequest({
      locality: locality.cms_locality_id,
      depotId: data.drop_depot_id,
    });
    setAddDepotAction(index);
  };

  return (
    <Portlet className={`mr-4 ${classes.container}`}>
      <PortletHeader
        className="p-0"
        toolbar={(
          <div className="w-100 pt-0 px-4 d-flex justify-content-between align-items-center">
            {data.drop_depot_admin_nm && (
              <Typography variant="h1">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.drop_depot_admin_nm
                  }}
                />
              </Typography>
            )}
            <DepotMenu
              depot={data}
            />
          </div>
        )}
      />
      <PortletBody className="p-0">
        <div className="pt-0 px-4">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between pt-4">
              <Typography variant="h2Medium">
                {intl.formatMessage({ id: 'DEPOT.NAME' })}
              </Typography>
              <IconButton
                tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
                onClick={() => {
                  handleClickEdit(1);
                }}
              >
                <EditIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
              </IconButton>
            </div>
          </div>
          <div className="mt-3">
            {data.depot_nm && (
              <Typography variant="body">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.depot_nm
                  }}
                />
              </Typography>
            )}
          </div>

          <div className="d-flex justify-content-between mt-5">
            <Typography variant="h2Medium">
              {intl.formatMessage({ id: 'DEPOT.INSTRUCTION' })}
            </Typography>
            <IconButton
              tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
              onClick={() => {
                handleClickEdit(1);
              }}
            >
              <EditIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
            </IconButton>
          </div>

          <div className="mt-3">
            {data.depot_details && (
              <Typography
                variant="body"
              >
                <div
                  id={`instructions_${data.drop_depot_id}`}
                  className={showInstructionMore ? classes.depotMore : classes.instructionsLess}
                  dangerouslySetInnerHTML={{
                    __html: data.depot_details
                  }}
                />
              </Typography>
            )}
            {
              isInstructionMore && (
                <div className="d-flex justify-content-center align-items-center">
                  <CustomButton
                    label={(
                      <Typography variant="bodySemiBold" color={process.env.REACT_APP_COLOR_PRIMARY}>
                        {showInstructionMore ? intl.formatMessage({ id: 'GENERAL.VIEW.LESS' }) : intl.formatMessage({ id: 'GENERAL.VIEW.MORE' })}
                      </Typography>
                    )}
                    onClick={() => setShowInstructionMore(!showInstructionMore)}
                  />
                </div>
              )
            }
          </div>

          <div className="d-flex justify-content-between mt-5">
            <Typography variant="h2Medium">
              {intl.formatMessage({ id: 'DEPOT.ADDRESS' })}
            </Typography>
            <IconButton
              tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
              onClick={() => {
                handleClickEdit(2);
              }}
            >
              <EditIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
            </IconButton>
          </div>

          <div className={`mt-3 ${classes.borderBottom}`}>
            {data.display_addr && (
              <Typography variant="body">
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatAddress(data.display_addr)
                  }}
                />
              </Typography>
            )}
          </div>

          <div>
            <div className="d-flex justify-content-between pt-3">
              <Typography variant="h2Medium">
                {intl.formatMessage({ id: 'DEPOT.HOURS_OF_OPERATION' })}
              </Typography>
              <IconButton
                tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
                onClick={() => handleClickEdit(4)}
              >
                <EditIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
              </IconButton>
            </div>
            <div
              id={`hours_${data.drop_depot_id}`}
              className={showHoursMore ? classes.depotMore : classes.hoursLess}
            >
              {
              data.drop_depot_seasons
              && !!data.drop_depot_seasons.length
              && data.drop_depot_seasons
                .map((item, index) => (
                  <React.Fragment key={`${item}_plans_${String(index)}`}>
                    <div className="my-3">
                      <Typography variant="bodySemiBold">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.season_nm
                          }}
                        />
                      </Typography>
                    </div>
                    <div className="my-3">
                      {item.season_notes && (
                        <Typography variant="body">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.season_notes
                            }}
                          />
                        </Typography>
                      )}
                    </div>

                    {
                      item.times && !!item.times.length
                      && item.times.map((time, index) => (
                        <React.Fragment key={`${time}_is_${String(index)}`}>
                          {
                            time.hours_lower_tm && (
                              <div className="d-flex mt-2 justify-content-between">
                                <Typography variant="body">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: days[time.dow_nbr]
                                    }}
                                  />
                                </Typography>
                                <div className="d-flex flex-column">
                                  <Typography variant="bodySemiBold">
                                    {time.hours_lower_tm ? removeZeroFromTime(transformToAmpm(time.hours_lower_tm)) : ''}
                                    {time.hours_lower_tm && time.hours_upper_tm && ' - '}
                                    {time.hours_upper_tm ? removeZeroFromTime(transformToAmpm(time.hours_upper_tm)) : ''}
                                  </Typography>
                                  {
                                    (time.add_hours_lower_tm || time.add_hours_upper_tm) && (
                                      <Typography className="mt-2" variant="bodySemiBold">
                                        {time.add_hours_lower_tm ? removeZeroFromTime(transformToAmpm(time.add_hours_lower_tm)) : ''}
                                        {time.add_hours_lower_tm && time.add_hours_upper_tm && ' - '}
                                        {time.add_hours_upper_tm ? removeZeroFromTime(transformToAmpm(time.add_hours_upper_tm)) : ''}
                                      </Typography>
                                    )
                                  }
                                </div>
                              </div>
                            )
                          }
                        </React.Fragment>
                      ))
                    }
                    <div className={`w-100 ${classes.borderBottom}`} />
                  </React.Fragment>
                ))
            }
            </div>
          </div>
          {
            isHoursMore && (
              <div className="d-flex justify-content-center align-items-center">
                <CustomButton
                  label={(
                    <Typography variant="bodySemiBold" color={process.env.REACT_APP_COLOR_PRIMARY}>
                      {showHoursMore ? intl.formatMessage({ id: 'GENERAL.VIEW.LESS' }) : intl.formatMessage({ id: 'GENERAL.VIEW.MORE' })}
                    </Typography>
                  )}
                  onClick={() => setShowHoursMore(!showHoursMore)}
                />
              </div>
            )
          }

          <div className="d-flex justify-content-between pt-3">
            <Typography variant="h2Medium">
              {intl.formatMessage({ id: 'DEPOT.CONTACT' })}
            </Typography>
            <IconButton
              tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
              onClick={() => handleClickEdit(3)}
            >
              <EditIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
            </IconButton>
          </div>

          <div
            id={`contact_${data.drop_depot_id}`}
            className={`mt-4 ${showContactMore ? classes.depotMore : classes.contactLess}`}
          >
            {
              data.phone_nbr && (
                <div className="d-flex mt-2 justify-content-between">
                  <Typography variant="body">
                    {intl.formatMessage({ id: 'DEPOT.CONTACT.TELEPHONE' })}
                  </Typography>
                  <Typography variant="bodySemiBold">

                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.phone_nbr
                      }}
                    />
                  </Typography>
                </div>
              )
            }
            {
              data.alt_phone_nbr && (
                <div className="d-flex mt-2 justify-content-between">
                  <Typography variant="body">
                    {intl.formatMessage({ id: 'DEPOT.CONTACT.ALT_TELEPHONE' })}
                  </Typography>
                  <Typography variant="bodySemiBold">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.alt_phone_nbr
                      }}
                    />
                  </Typography>
                </div>
              )
            }
            {
              data.email_addr && (
                <div className="d-flex mt-2 justify-content-between">
                  <Typography variant="body">
                    {intl.formatMessage({ id: 'DEPOT.CONTACT.EMAIL' })}
                  </Typography>
                  <Typography variant="bodySemiBold">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.email_addr
                      }}
                    />
                  </Typography>
                </div>
              )
            }
            {
              data.web_addr && (
                <div className="d-flex mt-2 justify-content-between">
                  <Typography className="w-25" variant="body">
                    {intl.formatMessage({ id: 'DEPOT.CONTACT.WEBSITE' })}
                  </Typography>

                  <Typography
                    className="w-50 d-flex justify-content-end"
                    variant="bodySemiBold"
                    color={process.env.REACT_APP_COLOR_PRIMARY}
                  >

                    <a href={`${data.web_addr}`}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.web_addr
                        }}
                      />
                    </a>
                  </Typography>
                </div>
              )
            }
          </div>
          {
            isContactMore && (
              <div className="d-flex justify-content-center align-items-center">
                <CustomButton
                  label={(
                    <Typography variant="bodySemiBold" color={process.env.REACT_APP_COLOR_PRIMARY}>
                      {showContactMore ? intl.formatMessage({ id: 'GENERAL.VIEW.LESS' }) : intl.formatMessage({ id: 'GENERAL.VIEW.MORE' })}
                    </Typography>
                  )}
                  onClick={() => setShowContactMore(!showContactMore)}
                />
              </div>
            )
          }
        </div>
      </PortletBody>

      <PortletHeader
        className="p-0"
        toolbar={(
          <div className={`w-100 px-4 d-flex justify-content-between align-items-center ${classes.drawerLink}`}>
            <Typography variant="h1">
              {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.DRAWER_LINKS.TITLE' })}
              <InfoTooltip tooltip={intl.formatMessage({ id: 'CATEGORY.CATEGORIES.DRAWER_LINKS.TOOLTIP' })} />
            </Typography>
            {
              data.drawers && !!data.drawers.length && (
                <IconButton onClick={() => setDrawerLinkIsOpen(!drawerLinkIsOpen)}>
                  {
                    drawerLinkIsOpen ? <CollapseIcon /> : <ExpandIcon />
                  }
                </IconButton>
              )
            }
          </div>
        )}
      />
      <PortletBody className="p-0">
        {!!data.drawers.length
          && drawerLinkIsOpen && (
            <DepotDrawerLinks drawerLinks={data.drawers} />
        )}
      </PortletBody>

      <PortletHeader
        className="p-0"
        toolbar={(
          <div className={`w-100 px-4 d-flex justify-content-between align-items-center ${classes.assignCategory} ${!assignCategoryIsOpen ? classes.assignCategoryBorder : null}`}>
            <Typography variant="h1">
              {intl.formatMessage({ id: 'DEPOT.ASSIGN_CATEGORY' })}
              <InfoTooltip tooltip={intl.formatMessage({ id: 'DEPOT.ASSIGN_CATEGORY_TOOLTIP' })} />
            </Typography>
            { data.category_assigns && !!data.category_assigns.length && (
              <IconButton onClick={() => setAssignCategoryIsOpen(!assignCategoryIsOpen)}>
                {
                  assignCategoryIsOpen ? (
                    <CollapseIcon />
                  ) : (
                    <ExpandIcon />
                  )
                }
              </IconButton>
            )}
          </div>
        )}
      />
      <PortletBody className="p-0">
        {!!data.category_assigns.length
          && assignCategoryIsOpen && (
            <DepotAssignedCategory categoryLink={data.category_assigns} />
        )}
      </PortletBody>

    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  locality: store.common.locality,
  addDepot: store.depot.addDepot,
});

export default injectIntl(connect(mapStateToProps, actions)(Depot));
