import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import moment from 'moment';
import ReportPDF from './ReportPDF';
import { PdfIcon, BackIcon } from '../../../../assets/icons';
import {
  Portlet, PortletBody, PortletHeader, PortletHeaderToolbar,
} from '../../../../../_metronic/partials/content/Portlet';
import {
  SummaryHeader, SummaryUserRating, RCSpinner
} from '../../components';
import * as actions from '../redux/actions';
import * as sharedActions from '../../../redux/actions';
import { isValidDate, checkValidDistrict } from '../../../../utils';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_PRIMARY,
  },
  border: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    paddingBottom: '20px',
    marginBottom: '40px',
    [theme.breakpoints.down(1200)]: {
      borderBottom: 'none',
      marginBottom: '0px',
      paddingBottom: '0px',
    }
  },
  summaryCell: {
    [theme.breakpoints.down(1200)]: {
      minWidth: '100% !important',
      maxWidth: '100% !important',
      borderRight: 'none',
      borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    }
  },
  pdfDisable: {
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_GRAY_DARK,
  }
}));

const Summary = ({
  intl,
  data,
  municipalities,
  district,
  setDistrictAction,
  fromDate,
  toDate,
  setDateRangeAction,
  allTime,
  county,
  isCounty,
  fetchState,
  setIsCountyAction,
  setResidentKnowledgeIndicatorReportAllTimeAction,
  ResidentKnowledgeIndicatorReportRequest,
  PDFDataDetail
}) => {
  const classes = useStyles();
  const history = useHistory();
  const isSendRequest = fetchState === 'send-request';
  const queryParams = new URLSearchParams(useLocation().search);
  const { isDashboardWidget } = useLocation();
  const [pdfSpinner, setPdfSpinner] = useState(false);

  useEffect(() => {
    setIsCountyAction(false);
    if (history.location.search) {
      if (queryParams.get('district') && checkValidDistrict(
        queryParams.get('district').replace('-', '#$#'),
        municipalities,
        'district_id'
      )) {
        setDistrictAction({
          district_id: queryParams.get('district').replace('-', '#$#'),
          district_nm: municipalities.filter((item) => item.district_id === queryParams.get('district').replace('-', '#$#'))[0].district_nm,
        });
        setIsCountyAction(false);
      } else if (municipalities.length === 1) {
        setDistrictAction(municipalities[0]);
      } else {
        setDistrictAction({
          district_id: '#all-districts#',
          district_nm: 'All Districts'
        });
      }
      if (queryParams.get('from') && queryParams.get('to') && isValidDate(queryParams.get('from')) && isValidDate(queryParams.get('to'))) {
        setDateRangeAction({
          fromDate: queryParams.get('from'),
          toDate: queryParams.get('to'),
        });
      }
    } else if (municipalities.length === 1) {
      setDistrictAction(municipalities[0]);
    } else {
      setDistrictAction({
        district_id: '#all-districts#',
        district_nm: 'All Districts'
      });
    }
  }, []);

  const generatePDF = async () => {
    setPdfSpinner(true);
    const blob = await pdf((
      <ReportPDF
        title={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SUMMARY.TITLE' })}
        currentPeriod={`${intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_PERIOD' })}:`}
        date={`${allTime ? '2021-01-01' : moment(fromDate).format('YYYY-MM-DD')} - ${moment(toDate).format('YYYY-MM-DD')}`}
        district={isCounty ? `Reporting County: ${county.county_nm}`
          : `Reporting District: ${district.district_id !== '#all-districts#' ? district.district_nm : 'All Districts'}`}
        uniqueUserTitle={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SUMMARY.TITLE.WWTK.CORRECT' })}
        uniqueUserValue={
          data
            && data.users_correct_ans_wwtk.length > 1
            ? data.users_correct_ans_wwtk[1].total - data.users_correct_ans_wwtk[0].total
            : 0
        }
        wwtkAnswerTitle={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SUMMARY.TITLE.WWTK' })}
        wwtkAnswerValue={data ? data.wwtk.sum : 0}
        correctAnswerTitle={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SUMMARY.TITLE.WWTK.CORRECT.PERCENT' })}
        correctAnswerValue={data ? `${Number(data.correct_answers_quiz_type).toFixed(1)} %` : 0}
        appInstallTitle={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SUMMARY.TITLE.SURVEY.APP_INSTALL' })}
        appInstallValue={data && Array.isArray(data.app_install) ? data.app_install[0].count : 0}
        onboardingSurveyTitle={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SUMMARY.TITLE.SURVEY.COMPLETE' })}
        onboardingSurveyValue={data ? data.users_complete_survey.users : 0}
        userCompleteSurveyTitle={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SUMMARY.TITLE.SURVEY.COMPLETE.PERCENT' })}
        userCompleteSurveyValue={
          data
            && data.users_complete_survey
            && data.users_complete_survey.users !== null
            && data.app_install && Array.isArray(data.app_install)
            && data.app_install[0].count !== null
            ? `${((Number(data.users_complete_survey.users) * 100) / Number(data.app_install[0].count)).toFixed(1)} %`
            : 0
        }
        wwtkDetailStartDate={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.WWTK.START_DATE' })}
        wwtkDetailQuestion={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.WWTK.TITLE' })}
        wwtkDetailType={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.WWTK.TYPE' })}
        wwtkDetailAnswer={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.WWTK.OPTIONS' })}
        wwtkDetailResponse={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.WWTK.RESPONSE' })}
        wwtkDetailPercent={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.WWTK.OF_USERS' })}
        PDFDataDetail={PDFDataDetail}
        surveyDetailQuestion={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SURVEY.TITLE' })}
        surveyDetailAnswers={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SURVEY.OPTIONS' })}
        surveyDetailResponse={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SURVEY.USERS' })}
        surveyDetailPercent={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SURVEY.USERS_PERCENTAGE' })}
        spMaterialName={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.TAB.MATERIAL_SPOTLIGHT.TITLE' })}
        spMaterialView={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.MATERIAL_SPOTLIGHT.USERS_VIEWED' })}
      />
    )).toBlob();
    saveAs(blob, 'Knowledge Measurement Report.pdf');
    setPdfSpinner(false);
  };

  useEffect(() => {
    if (isCounty) {
      history.replace({
        pathname: '/report/resident-knowledge-indicator',
        search: !allTime ? `?county=${county.county_id}&from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
          : `?county=${county.county_id}`,
        isDashboardWidget
      });
    } else {
      history.replace({
        pathname: '/report/resident-knowledge-indicator',
        search: !allTime ? `?district=${district.district_id === '#all-districts#' ? 'all-districts' : district.district_id.replace('#$#', '-')}&from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
          : `?district=${district.district_id.replace('#$#', '-')}`,
        isDashboardWidget
      });
    }
  }, [fromDate, toDate, district, county, isCounty]);

  return (
    <Portlet>
      <PortletHeader
        title={(
          <Link to="/report/dashboard">
            <Button
              classes={{
                label: classes.label,
              }}
              startIcon={<BackIcon color={process.env.REACT_APP_COLOR_PRIMARY} />}
            >
              {intl.formatMessage({ id: 'REPORT.GENERAL.BACK' })}
            </Button>
          </Link>
        )}
        toolbar={(
          <PortletHeaderToolbar>
            <Button
              disabled={isSendRequest}
              classes={{
                label: isSendRequest ? classes.pdfDisable : classes.label,
              }}
              startIcon={(
                <PdfIcon
                  color={isSendRequest ? process.env.REACT_APP_COLOR_GRAY_DARK
                    : process.env.REACT_APP_COLOR_PRIMARY}
                />
              )}
              onClick={generatePDF}

            >
              {intl.formatMessage({ id: 'REPORT.GENERAL.PDF' })}
            </Button>
            <RCSpinner isActive={pdfSpinner} />
          </PortletHeaderToolbar>
        )}
      />
      <PortletBody>
        <SummaryHeader
          title={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SUMMARY.TITLE' })}
          handleDataRequest={ResidentKnowledgeIndicatorReportRequest}
          allTime={allTime}
          handleAllTimeChange={setResidentKnowledgeIndicatorReportAllTimeAction}
          hasAllTimeOption
          startOfAllTime="2021-01-01"
          hasAllDistrictOption
          isCountyEnable
        />
        <div className={`row ${classes.border}`}>
          <SummaryUserRating
            title={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SUMMARY.TITLE.WWTK.CORRECT' })}
            data={
              data
                && data.users_correct_ans_wwtk.length > 1
                ? data.users_correct_ans_wwtk[1].total - data.users_correct_ans_wwtk[0].total
                : 0
            }
            className={`col-xl-4 ${classes.summaryCell}`}
            isLoading={isSendRequest}
            description=""
          />
          <SummaryUserRating
            title={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SUMMARY.TITLE.WWTK' })}
            data={data ? data.wwtk.sum : 0}
            className={`col-xl-4 ${classes.summaryCell}`}
            isLoading={isSendRequest}
            description=""
          />
          <SummaryUserRating
            title={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SUMMARY.TITLE.WWTK.CORRECT.PERCENT' })}
            data={data ? `${Number(data.correct_answers_quiz_type).toFixed(1)} %` : 0}
            className={`col-xl-4 border-right-0 ${classes.summaryCell}`}
            isLoading={isSendRequest}
            description=""
            hasUserText={false}
          />
        </div>
        <div className="row mb-4">
          <SummaryUserRating
            title={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SUMMARY.TITLE.SURVEY.APP_INSTALL' })}
            data={data && Array.isArray(data.app_install) ? data.app_install[0].count : 0}
            className={`col-xl-4 ${classes.summaryCell}`}
            isLoading={isSendRequest}
            description=""
          />
          <SummaryUserRating
            title={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SUMMARY.TITLE.SURVEY.COMPLETE' })}
            data={data ? data.users_complete_survey.users : 0}
            className={`col-xl-4 ${classes.summaryCell}`}
            isLoading={isSendRequest}
            description=""
          />
          <SummaryUserRating
            title={intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SUMMARY.TITLE.SURVEY.COMPLETE.PERCENT' })}
            data={data && data.users_complete_survey
              && data.users_complete_survey.users !== null
              && data.app_install && Array.isArray(data.app_install)
              && data.app_install[0].count !== null
              ? `${((Number(data.users_complete_survey.users) * 100) / Number(data.app_install[0].count)).toFixed(1)} %` : 0}
            className={`col-xl-4 border-right-0 border-bottom-0 ${classes.summaryCell}`}
            isLoading={isSendRequest}
            description=""
            hasUserText={false}
          />
        </div>
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
  detailData: store.userReport.data,
  municipalities: store.common.municipalities,
  county: store.common.county,
  counties: store.common.counties,
  isCounty: store.common.isCounty,
  hasCounty: store.auth.user_info.counties,
  allTime: store.residentKnowledgeIndicatorReport.allTime,
});

export default injectIntl(
  connect(
    mapStateToProps,
    { ...actions, ...sharedActions }
  )(Summary)
);
