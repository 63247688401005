import React, { useState } from 'react';
import InteractionReportReminderDetail from './InteractionReportReminderDetail';
import InteractionReportNotificationDetail from './InteractionReportNotificationDetail';
import InteractionReportMaterialDetail from './InteractionReportMaterialDetail';
import InteractionReportPageDetail from './InteractionReportPageDetail';
import InteractionReportCalendarDetail from './InteractionReportCalendarDetail';

const InteractionReportDetail = ({
  data,
  advocateAdmin
}) => {
  const [reminderExpanded, setReminderExpanded] = useState(false);
  const [notificationExpanded, setNotificationExpanded] = useState(false);

  return (
    <>
      { advocateAdmin !== true && (
        <InteractionReportReminderDetail
          data={data.reminder}
          expanded={reminderExpanded}
          onClick={() => setReminderExpanded(!reminderExpanded)}
        />
      )}
      <InteractionReportNotificationDetail
        data={data.notification}
        expanded={notificationExpanded}
        onClick={() => setNotificationExpanded(!notificationExpanded)}
      />
      <InteractionReportMaterialDetail
        data={data.material}
      />
      <InteractionReportPageDetail
        data={data.page}
        className={`${advocateAdmin === true ? 'border-bottom-0' : null}`}
      />
      { advocateAdmin !== true && (
        <InteractionReportCalendarDetail
          data={data.calendar}
        />
      )}
    </>
  );
};

export default InteractionReportDetail;
