import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

import Typography from './Typography';

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
  },
  disable: {
    color: process.env.REACT_APP_COLOR_BACKGROUND_FILL
  },
}));

const CheckBox = ({
  color = 'primary',
  checked,
  onChange,
  label = 'label',
  className,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      classes={{
        root: classes.root
      }}
      control={(
        <MuiCheckbox
          className={`p-0 ${className}`}
          checked={checked}
          onChange={() => onChange(!checked)}
          color={color}
          {...props}
        />
      )}
      label={(
        <Typography
          variant="bodySemiBold"
          className={`m-0 ${!checked ? classes.disable : null}`}
        >
          {label}
        </Typography>
      )}
    />
  );
};

export default CheckBox;
