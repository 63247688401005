import cts from '../../crud/cts';

/**
 * TODO: Insert comment
 */

export const getSchedules = async (
  data
) => cts(
  'post',
  'schedule/get-all',
  {
    project_id: data.project_id,
    district_id: data.district_id,
    zones: data.zones,
  }
);

export const editSchedules = async ({
  project_id,
  district_id,
  prompt_id,
  zone_id
}) => cts(
  'post',
  'schedule/edit',
  {
    project_id,
    district_id,
    prompt_id,
    zone_id
  }
);

/**
 * Zone API which fetches zones that are connected to selected district
 */
export const getZone = async ({
  district_id
}) => cts(
  'post',
  'districts/get-zones',
  {
    district_id,
  }
);

/**
 * API to fetch perpetual schedules holidays
 */
export const getPerpetualSchedulesHolidays = async (
  project_id,
  district_id
) => cts(
  'get',
  `schedule/perpetual-schedule/get-district-holidays?project_id=${project_id}&district_id=${district_id}`
);
