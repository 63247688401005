import actionTypes from './actionTypes';

// Auth actions for setting loading status
export const setLoadingAction = () => ({
  type: actionTypes.SET_LOADING
});

export const resetLoadingAction = () => ({
  type: actionTypes.RESET_LOADING
});

// MFA actions
export const MFARequest = (type) => ({
  type: actionTypes.MFAـREQUEST,
  payload: { type }
});

export const MFAReceive = (data) => ({
  type: actionTypes.MFAـRECEIVE,
  payload: { ...data }
});

export const MFAFail = (error) => ({
  type: actionTypes.MFAـFAIL,
  payload: { ...error }
});
