import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

import moment from 'moment';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import ReportPDF from './ReportPDF';
import { PdfIcon, BackIcon } from '../../../../assets/icons';
import {
  Portlet, PortletBody, PortletHeader, PortletHeaderToolbar,
} from '../../../../../_metronic/partials/content/Portlet';
import {
  SummaryHeader, SummaryUserRating, RCSpinner
} from '../../components';
import * as actions from '../redux/actions';
import * as sharedActions from '../../../redux/actions';
import { isValidDate, checkValidDistrict } from '../../../../utils';
import SummaryWithTwoValueCell from './SummaryWithTwoValueCell';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_PRIMARY,
  },
  border: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    paddingBottom: '20px',
    marginBottom: '40px',
    [theme.breakpoints.down(1200)]: {
      borderBottom: 'none',
      marginBottom: '0px',
      paddingBottom: '0px',
    }
  },
  summaryCell: {
    [theme.breakpoints.down(1200)]: {
      minWidth: '100% !important',
      maxWidth: '100% !important',
      borderRight: 'none',
      borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    }
  },
  pdfDisable: {
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_GRAY_DARK,
  }
}));

const Summary = ({
  intl,
  data,
  municipalities,
  district,
  setDistrictAction,
  fromDate,
  toDate,
  setDateRangeAction,
  allTime,
  county,
  isCounty,
  fetchState,
  setIsCountyAction,
  setDiscoveryZoneReportAllTimeAction,
  DiscoveryZoneReportRequest,
  PDFDetailData
}) => {
  const classes = useStyles();
  const history = useHistory();
  const isSendRequest = fetchState === 'send-request';
  const queryParams = new URLSearchParams(useLocation().search);
  const [pdfSpinner, setPdfSpinner] = useState(false);

  useEffect(() => {
    setIsCountyAction(false);
    if (history.location.search) {
      if (queryParams.get('district') && checkValidDistrict(
        queryParams.get('district').replace('-', '#$#'),
        municipalities,
        'district_id'
      )) {
        setDistrictAction({
          district_id: queryParams.get('district').replace('-', '#$#'),
          district_nm: municipalities.filter((item) => item.district_id === queryParams.get('district').replace('-', '#$#'))[0].district_nm,
        });
        setIsCountyAction(false);
      } else if (municipalities.length === 1) {
        setDistrictAction(municipalities[0]);
      } else {
        setDistrictAction({
          district_id: '#all-districts#',
          district_nm: 'All Districts'
        });
      }
      if (queryParams.get('from') && queryParams.get('to') && isValidDate(queryParams.get('from')) && isValidDate(queryParams.get('to'))) {
        setDateRangeAction({
          fromDate: queryParams.get('from'),
          toDate: queryParams.get('to'),
        });
      }
    } else if (municipalities.length === 1) {
      setDistrictAction(municipalities[0]);
    } else {
      setDistrictAction({
        district_id: '#all-districts#',
        district_nm: 'All Districts'
      });
    }
  }, []);

  useEffect(() => {
    if (isCounty) {
      history.replace({
        pathname: '/report/discovery-zone',
        search: !allTime ? `?county=${county.county_id}&from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
          : `?county=${county.county_id}`
      });
    } else {
      history.replace({
        pathname: '/report/discovery-zone',
        search: !allTime ? `?district=${district.district_id === '#all-districts#' ? 'all-districts' : district.district_id.replace('#$#', '-')}&from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
          : `?district=${district.district_id.replace('#$#', '-')}`
      });
    }
  }, [fromDate, toDate, district, county, isCounty]);

  const generatePDF = async () => {
    setPdfSpinner(true);
    const blob = await pdf((
      <ReportPDF
        title={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.HEADER' })}
        currentPeriod={`${intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_PERIOD' })}:`}
        date={`${allTime ? '2021-01-01' : moment(fromDate).format('YYYY-MM-DD')} - ${moment(toDate).format('YYYY-MM-DD')}`}
        district={isCounty ? `Reporting County: ${county.county_nm}`
          : `Reporting District: ${district.district_id !== '#all-districts#' ? district.district_nm : 'All Districts'}`}
        data={PDFDetailData}
        uniqueVisitorsTitle={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TITLE.TOTAL_VISIT' })}
        uniqueVisitorsValue={data ? data.total_visits : ''}
        RFLessonsTitle={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TITLE.USER_FINISHED_FUNDAMENTAL_LESSON' })}
        RFLessonsValue={data ? data.participated_rf_lessons : ''}
        MMLessonsTitle={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TITLE.USER_FINISHED_MISUNDERSTOOD_LESSON' })}
        MMLessonsValue={data ? data.participated_mm_lessons : ''}
        RFQuestion={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.RF_TABLE.QUIZ.TITLE' })}
        RFAnswer={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.RF_TABLE.ANSWER' })}
        RFResponse={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.RF_TABLE.SELECTED' })}
        RFSelected={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.RF_TABLE.SELECTED_PERSENTAGE' })}
        RFComparison={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.RF_TABLE.OTHER' })}
        MMName={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.MM_TABLE.TITLE' })}
        MMDesc={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.MM_TABLE.DESCRIPTION' })}
        MMAnswer={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.MM_TABLE.ANSWER' })}
        MMResponse={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.MM_TABLE.OF_USER' })}
        MMCorrect={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.MM_TABLE.CORRECT' })}
        blogTitle={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.BLOG.TITLE' })}
        blogSourceLink={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.BLOG.SOURCE' })}
        blogResponse={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TAB.LESSON.MM_TABLE.OF_USER' })}
        blogFirstType={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.TABLE.TYPE.HOMESCREEN' })}
        blogSecondType={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.TABLE.TYPE.DISCOVERY_ZONE' })}
      />
    )).toBlob();
    saveAs(blob, 'Enhanced Education Report.pdf');
    setPdfSpinner(false);
  };

  return (
    <Portlet>
      <PortletHeader
        title={(
          <Link to="/report/dashboard">
            <Button
              classes={{
                label: classes.label,
              }}
              startIcon={<BackIcon color={process.env.REACT_APP_COLOR_PRIMARY} />}
            >
              {intl.formatMessage({ id: 'REPORT.GENERAL.BACK' })}
            </Button>
          </Link>
        )}
        toolbar={(
          <PortletHeaderToolbar>
            <Button
              disabled={isSendRequest}
              classes={{
                label: isSendRequest ? classes.pdfDisable : classes.label,
              }}
              startIcon={(
                <PdfIcon
                  color={isSendRequest ? process.env.REACT_APP_COLOR_GRAY_DARK
                    : process.env.REACT_APP_COLOR_PRIMARY}
                />
              )}
              onClick={generatePDF}
            >
              {intl.formatMessage({ id: 'REPORT.GENERAL.PDF' })}
            </Button>
            <RCSpinner isActive={pdfSpinner} />
          </PortletHeaderToolbar>
        )}
      />
      <PortletBody>
        <SummaryHeader
          title={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.HEADER' })}
          handleDataRequest={DiscoveryZoneReportRequest}
          allTime={allTime}
          handleAllTimeChange={setDiscoveryZoneReportAllTimeAction}
          hasAllTimeOption
          startOfAllTime="2021-01-01"
          hasAllDistrictOption
          isCountyEnable
        />
        <div className="row">
          <SummaryUserRating
            title={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TITLE.TOTAL_VISIT' })}
            data={data ? data.total_visits : ''}
            className={`col-xl-4 ${classes.summaryCell}`}
            isLoading={isSendRequest}
            description=""
            hasUserText={false}
          />
          <SummaryUserRating
            title={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TITLE.USER_FINISHED_FUNDAMENTAL_LESSON' })}
            data={data ? data.participated_rf_lessons : ''}
            className={`col-xl-4 ${classes.summaryCell}`}
            isLoading={isSendRequest}
            description=""
            hasUserText={false}
          />
          {/* <SummaryWithTwoValueCell
            title={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TITLE.USER_FINISHED_FUNDAMENTAL_QUIZ' })}
            data={data ? [data.attempt_rf_quiz, data.pass_rf_quiz] : ''}
            className={`col-xl-4 ${classes.summaryCell}`}
            isLoading={isSendRequest}
            description=""
            hasUserText={false}
          /> */}

          <SummaryUserRating
            title={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TITLE.USER_FINISHED_MISUNDERSTOOD_LESSON' })}
            data={data ? data.participated_mm_lessons : ''}
            className={`col-xl-4 border-right-0 border-bottom-0 ${classes.summaryCell}`}
            isLoading={isSendRequest}
            description=""
            hasUserText={false}
          />
        </div>
        {/* <div className="row mb-4">
          <SummaryWithTwoValueCell
            title={intl.formatMessage({ id: 'REPORT.DISCOVERY_ZONE.SUMMARY.TITLE.USER_FINISHED_MISUNDERSTOOD_QUIZ' })}
            data={data ? [data.attempt_mm_quiz, data.pass_mm_quiz] : ''}
            className={`col-xl-4 ${classes.summaryCell}`}
            isLoading={isSendRequest}
            description=""
            hasUserText={false}
          />
        </div> */}
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
  municipalities: store.common.municipalities,
  county: store.common.county,
  counties: store.common.counties,
  isCounty: store.common.isCounty,
  hasCounty: store.auth.user_info.counties,
  allTime: store.discoveryZoneReport.allTime,
});

export default injectIntl(
  connect(
    mapStateToProps,
    { ...actions, ...sharedActions }
  )(Summary)
);
