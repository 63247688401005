import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Formik } from 'formik';

import * as actions from './redux/actions';
import {
  Body,
  Title,
  Form,
  InputField,
  Action,
  Submit,
  Back,
} from '../components';

const ForgotPassword = ({
  intl,
  fetchState,
  resetLoadingAction,
  EmailVerificationRequest,
  responseMessage
}) => {
  const isNoRequest = fetchState === 'no-request';
  const isSendRequest = fetchState === 'send-request';

  if (!isNoRequest && responseMessage) {
    resetLoadingAction();
    return <Redirect to="/auth/login" />;
  }

  return (
    <Body>
      <Title text="AUTH.FORGOT.TITLE" />
      <Formik
        initialValues={{ email: '' }}
        validate={(values) => {
          const errors = {};

          if (!values.email) {
            errors.email = intl.formatMessage({
              id: 'VALIDATION.REQUIRED_FIELD'
            });
          } else if (
            !values.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
          ) {
            errors.email = intl.formatMessage({
              id: 'AUTH.VALIDATION.INVALID_FIELD'
            });
          }

          return errors;
        }}
        onSubmit={(values) => EmailVerificationRequest(values.email)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            <InputField
              type="email"
              label={intl.formatMessage({ id: 'GENERAL.EMAIL' })}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              helperText={touched.email && errors.email}
              error={Boolean(touched.email && errors.email)}
            />
            <Action>
              <Link to="/auth">
                <Back title="GENERAL.BACK" onClick={resetLoadingAction} />
              </Link>
              <Submit title="AUTH.GENERAL.SUBMIT_BUTTON" isSubmitting={isSendRequest} />
            </Action>
          </Form>
        )}
      </Formik>
    </Body>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.auth.fetchState,
  responseMessage: store.auth.responseMessage
});

export default injectIntl(connect(mapStateToProps, actions)(ForgotPassword));
