import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(() => ({
  menuItem: {
    background: '#fff !important',
    '&:hover': {
      background: '#F5F5F5 !important',
    }
  },
}));

const DropdownMenu = ({
  intl, handleClick, anchorEl, handleClose, menuItemData
}) => {
  const classes = useStyles();

  return (
    <div style={{ marginLeft: 10 }}>
      <MoreVertIcon
        style={{ color: '#BABABA', cursor: 'pointer' }}
        fontSize="large"
        onClick={handleClick}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        TransitionComponent={Fade}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {menuItemData.map((menuItem) => (
          <MenuItem
            key={menuItem.id}
            disabled={menuItem.disabled}
            className={classes.menuItem}
            onClick={menuItem.method}
          >
            {intl.formatMessage({ id: menuItem.name })}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default injectIntl(DropdownMenu);
