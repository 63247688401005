import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';

import { connect } from 'react-redux';


import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import moment, { months } from 'moment';
import { Typography, CustomButton } from '../../../components';

import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';
import { ListLoading } from '../../../components/loading';
import { DropdownMenu } from '../../../Education/EducationalContent/components';
import { EducationModal } from '.';
import { report } from 'process';
import * as actions from '../../ReportDashboardNew/redux/actions';
import * as sharedActions from '../../../redux/actions';
import { BulkPickUpOverlay, ProFeaturesOverlay } from './index';


const useStyles = makeStyles((theme) => ({
  groupChange: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: '500',
    fontSize: 24,
  },
  groupArrow: {
    marginRight: '6px'
  },
  groupChangePositive: {
    color: '#409A5F'
  },
  groupChangeNegativet: {
    color: 'red'
  },
  graph: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    fontFamily: "sans-serif"
  },
  graphRowClass: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
    marginBottom: "16px",
    '&:last-of-type': {
      marginBottom: "0"
    }
  },
  graphTitle: {
    fontWeight: "600",
    fontSize: "16px",
    color: "#404040",
    lineHeight: "24px",
    maxWidth: "280px",
    minWidth: "280px",
    letterSpacing: '0.04em',
  },
  graphBar: {
    width: "100%",
    height: "40px",
    backgroundColor: "rgba(108, 114, 147, 0.06)"
  },
  graphPercentage: {
    backgroundColor: "#2A95FF",
    height: "100%"
  },
  graphPercentageQuiz: {
    backgroundColor: "#97CCFF",
  },
  graphType: {
    fontWeight: "600",
    fontSize: "16px",
    color: "#404040",
    lineHeight: "24px",
    width: "95px",
    letterSpacing: '0.04em',
    textTransform: "uppercase",
  },
  graphNumber: {
    color: "#6C7293",
    width: "120px",
    textAlign: "center",
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 18,
  },
  engagementsRateSection: {
    width: "100%",
  },
  engagementsRateTitle: {
    color: '#404040',
    fontWeight: 600,
    fontSize: 28,
    letterSpacing: '0.04em'
  },
  engagementsRateNumber: {
    color: '#2A95FF',
    fontWeight: 700,
    fontSize: 32,
    letterSpacing: '0.05em',
    // margin: '28px 0'
  },
  engagementsRatePercent: {
    color: '#404040',
    fontWeight: 600,
    fontSize: 28,
    letterSpacing: '0.04em'
  },
  legendContainer: {
    display: 'flex',
    width: '100%',
    margin: '25px 0',
  },
  legend_item: {
    display: 'flex',
    margin: '0 16px',
    color: '#404040',
    fontWeight: 400,
    fontSize: 13,
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    alignItems: 'center',
    '&::before': {
      content: '""',
      display: 'block',
      height: 10,
      width: 10,
      borderRadius: '50%',
      backgroundColor: 'red',
      marginRight: 4
    }
  },
  legend__item_Engagement: {
    '&::before': {
      backgroundColor: '#2A95FF'
    }
  },
  legend__item_Correct: {
    '&::before': {
      backgroundColor: '#97CCFF'
    }
  },
  divider: {
    border: 'none',
    '@media (min-width: 991px)': {
      borderLeft: `1px solid #dee2e6 !important`
    }
  },
  detailReportButton: {
    width: '20%',
    // marginLeft: '50%',
    // marginTop: 5,
  },
  downloadReportButton: {
    width: '20%',
    // marginLeft: '50%',
    // marginTop: 5,
  },
  bottomRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    gap: '16px',
    marginTop: '16px'
  },
  group: {
    display: "flex",
    gap: "25px",
    justifyContent: "center",
    width: "100%",
    '@media (max-width: 1060px)': {
      flexWrap: "wrap",
    }

  },
  groupItem: {
    display: "flex",
    flexDirection: "column",
    border: "2px solid #E7ECEF",
    borderRadius: 9,
    ChangeTheColorBorderRadius: "8px",
    padding: "12px",
    // width: "25%",
    width: 250,
    // maxWidth: "200px",
    MightNeedFullWidthSoTheItemsDontGetTooBigOnLargeScreenColor: "#63666A",
    ChangeTheColorFontFamily: "sans-serif",
    color: '#404040',
    maxHeight: 100,
    '@media (max-width: 1060px)': {
      width: '210px!important'
    }
  },
  groupTitle: {
    fontWeight: "600",
    fontSize: 14,
    '@media (max-width: 1060px)': {
      fontSize: '11px!important'
    }

  },
  groupNumber: {
    fontSize: '24px',
    fontWeight: '700',
    marginTop: '6px',
    marginBottom: '6px'
  },
  //.group__change
  groupChange: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontWeight: '500',
    fontSize: 16,
  },
  //.group__arrow
  groupArrow: {
    marginRight: "6px"
  },
  ///* Item shows increase */
  groupChangePositive: {
    color: '#409A5F'
  },
  ///* Item shows decrease */
  groupChangeNegativet: {
    color: '#FF1E1E'
  },
  link: {
    color: '#2A95FF',
    textDecoration: 'underline',
    fontWeight: 600
  }

}));

const BulkItemPickUp = ({
  intl,
  reportData,
  isLoading,
  reportMonth,
  reportYear,
  getBulkPickupDetailsRequest,
  bulkPickUpDetails,
  loading,
  error,
  municipality,
  isUserPro,
  stage,
  district,
  municipalities,
  setDistrictAction,
  hasCounty,
  counties,
  county,
  isCounty,
  setCountyAction,
  csm,
}) => {
  const classes = useStyles();
  const history = useHistory();

  /**
 * Menu and menuitem functions.
 */
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const [bulkPickData, setBulkPickData] = useState(null);

  useEffect(() => {
    if (bulkPickUpDetails && bulkPickUpDetails.status === 'success' && !isLoading) {
      handleDownloadUpcomingBookings();
    }
  }, [bulkPickUpDetails]);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const descriptionHandler = () => {
    setOpen(true);

  };

  const viewDetailsHandler = (e) => {
    history.push('/report/bulk-pickup/detail');
    // setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    handleClose();
  }

  const fetchDownloadUpcomingBookings = () => {
    const startDate = moment(`${reportYear}-${reportMonth}-01`).subtract(1, 'year').format('YYYY-MM-DD');
    const endDate = moment(`${reportYear}-${reportMonth}-01`).format('YYYY-MM-DD');

    getBulkPickupDetailsRequest({
      municipality,
      month: reportMonth,
      year: reportYear,
      county,
      isCounty,
      start_date: startDate,
      end_date: moment(endDate).add(1, 'months').format('YYYY-MM-DD')
    })
  }

  const handleDownloadUpcomingBookings = () => {
    const data = bulkPickUpDetails.data;

  }

  /**
  * Data for dropdown menu items.
  */
  const menuItemData = [
    {
      id: 1,
      name: 'Description',
      method: descriptionHandler,
      disabled: false
    },
    {
      id: 2,
      name: 'View Detail Report',
      method: viewDetailsHandler,
      disabled: false
    },

  ];


  const maxValueNewBookings = reportData.bulk_pickup ? Math.round(Math.max(...Object.values(reportData.bulk_pickup.new_bookings))
    * 1.05) : 0;

  const maxValueScheduledPickups = reportData.bulk_pickup ? Math.round(Math.max(...Object.values(reportData.bulk_pickup.scheduled_pickups
  ))
    * 1.05) : 0;

  const maxItemsScheduledBulkPickups = reportData.bulk_pickup ? Math.round(Math.max(...Object.values(reportData.bulk_pickup.number_of_items_scheduled_for_bulk_pickups
  ))
    * 1.05) : 0;

  // let maxItemsFeesBulkPickups;
  // if (reportData.bulk_pickup) {
  //   const fees = []
  //   fees.push(reportData.bulk_pickup.number_of_scheduled_fees.last_yr ? Number(reportData.bulk_pickup.number_of_scheduled_fees.last_yr.replace(/[^0-9.-]+/g, "")) : 0)
  //   fees.push(reportData.bulk_pickup.number_of_scheduled_fees.this_yr ? Number(reportData.bulk_pickup.number_of_scheduled_fees.this_yr.replace(/[^0-9.-]+/g, "")) : 0)
  //   if (fees.length) {
  //     maxItemsFeesBulkPickups = reportData.bulk_pickup ? (Math.max(...fees)
  //       * 1.05) : 0;
  //   }
  // }


  return (
    <>
      <Portlet>
        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start" style={{ justifyContent: 'space-between' }}>
                <div className="row col-xs-12 col-lg-6 align-items-center">
                  <div className="align-items-center pl-3">
                    <Typography variant="h1">
                      Bulk Item Pick-ups
                    </Typography>
                  </div>
                </div>
                <DropdownMenu
                  handleClick={handleClick}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  menuItemData={menuItemData}
                />
              </div>
            )}
          />
          <EducationModal
            open={open}
            onClose={() => closeModal()}
            title={'Description'}
            content={(
              <Typography variant="body1" style={{ textAlign: 'left' }}>
                This is a special service for jurisdictions that pick-up bulky items by appointment. It modernizes the booking process by allowing residents to book their own appointments online, according to your rules and accepted items list. Each week you or your hauler receives a file with the following weeks appointments – the file can be uploaded into routing software to create further efficiencies. To learn more about this cool feature,                         {
                  csm && csm.calendly_link && (
                    <a href={csm.calendly_link} target="_blank" className={classes.link}>contact your Customer Success Manager</a>
                  )
                }
                {
                  !csm || !csm.calendly_link && (
                    <>contact your Customer Success Manager</>
                  )
                }. </Typography>
            )}
          />
        </div>
        <PortletBody>
          {
            isLoading ?
              <ListLoading /> :
              <div className="row" style={{ height: reportData && reportData.bulk_pickup_status && reportData.bulk_pickup_status[0].has_bp !== 't' ? 370 : '100%' }}>
                <div className="row col-xs-12 col-lg-12">
                  <>

                    <>
                      {
                        reportData && reportData.bulk_pickup && (
                          <>
                            <div className="row" style={{ marginBottom: 10, width: '100%' }}>
                              {
                                reportData && reportData.bulk_pickup_status && reportData.bulk_pickup_status[0].has_bp !== 't' && (
                                  <div style={{ width: '100%' }}>
                                    <BulkPickUpOverlay csm={csm} displayMore={true} />
                                  </div>

                                )
                              }
                              <div className={classes.group}>
                                {
                                  Object.keys(reportData.bulk_pickup).map((item) => (
                                    <>
                                      <>
                                        {
                                          item === 'number_of_scheduled_fees' ? (
                                            // item === 'number_of_scheduled_fees' && reportData.bulk_pickup[item].this_yr && reportData.bulk_pickup[item].this_yr !== '0.00' && reportData.bulk_pickup[item].last_yr && reportData.bulk_pickup[item].last_yr !== '0.00' ? (
                                            <>
                                            {
                                              reportData.bulk_payment_has_payment && reportData.bulk_payment_has_payment[0].has_payment === 't' && (
                                                <div className={classes.groupItem}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                  <div className={classes.groupTitle}>{item.replace(/new_bookings/g, 'New bookings').replace(/scheduled_pickups/g, 'Closed bookings').replace(/number_of_items_scheduled_for_bulk_pickups/g, 'Closed booking material count').replace(/number_of_scheduled_fees/g, 'Closed booking revenue')}</div>

                                                </div>
                                                <div className={classes.groupNumber}>{reportData.bulk_pickup[item].this_yr ? reportData.bulk_pickup[item].this_yr : '$0.00'}</div>
                                              </div>
                                              )

                                            }
                                            </>

                                          ) : (
                                            <div className={classes.groupItem}>
                                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div className={classes.groupTitle}>{item.replace(/new_bookings/g, 'New bookings').replace(/scheduled_pickups/g, 'Closed bookings').replace(/number_of_items_scheduled_for_bulk_pickups/g, 'Closed booking material count').replace(/number_of_scheduled_fees/g, 'Closed booking revenue')}</div>

                                              </div>
                                              <div className={classes.groupNumber}>{reportData.bulk_pickup[item].this_yr ? reportData.bulk_pickup[item].this_yr : 0}</div>
                                            </div>
                                          )
                                        }
                                      </>
                                    </>

                                  ))
                                }

                              </div>
                            </div>
                          </>
                        )
                      }
                    </>



                  </>



                </div>
              </div>
          }

        </PortletBody>
      </Portlet>
    </>
  );
};

/**
 * Map state to props
 */
const mapStateToProps = (store) => ({
  // majorDetails: store.reportsDashboardReducer.majorDetails,
  bulkPickUpDetails: store.reportsDashboardReducer.bulkPickUpDetails,
  loading: store.reportsDashboardReducer.loading,
  error: store.reportsDashboardReducer.error,
  municipality: store.common.district.district_id,
  isUserPro: store.common.isUserPro,
  stage: store.common.district.stage,
  district: store.common.district,
  municipalities: store.common.municipalities,
  hasCounty: store.auth.user_info.counties,
  counties: store.common.counties,
  county: store.common.county,
  isCounty: store.common.isCounty,

});


// export default injectIntl(BulkItemPickUp);



export default injectIntl(
  connect(
    mapStateToProps, { ...actions, ...sharedActions }
  )(BulkItemPickUp)
);
