import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import {
  ListHeader,
  UsersList,
} from '../components';
import { Typography } from '../../components';
import * as actions from '../redux/actions';


const List = ({
  intl,
}) => (
  <>
    <ListHeader
      title={intl.formatMessage({ id: 'USERS.HEADER.LIST.TITLE' })}
      content="USERS.HEADER.LIST.CONTENT"
      secondaryContent={(
        <>
          <Typography className="mb-4 mt-4" variant="bodySemiBold">
            Recycle Coach is intended to be used by the contracting government entity. Please do not invite users outside of your organization or users with a personal e-mail address. If you have any questions, please contact <a href = "mailto: successgroup@recyclecoach.com">successgroup@recyclecoach.com</a>.
          </Typography>
        </>
      )}
    />
    <UsersList />
  </>
);


export default injectIntl(
  connect(
    null,
    actions
  )(List)
);
