import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  Switch,
  Route,
  useLocation,
  useRouteMatch,
  useHistory,
  Redirect,
} from 'react-router-dom';
import { Tab } from '../../../../components';
import * as actions from '../redux/actions';
import {
  MajorCampaignWwtk, MajorCampaignFeaturedLessons, MajorCampaignBlogs, MajorCampaignMaterials, MajorCampaignLearningModules
} from './index';

/**
 * Tabs map
 */
// const pageTabs = {
//   'WWtk Questions': 'wwtk_questions',
//   'Featured Lessons': 'featured_lessons',
//   'Learning Modules': 'learning_modules',
//   'Spotlight Materials': 'spotlight_materials',
//   'Blogs': 'blogs',
// };

const useStyles = makeStyles(() => ({
  tab: {
    backgroundColor: 'rgba(42, 149, 255, 0.12)',
  },
}));

const MajorCampaignTabs = ({
  intl,
  setTabAction,
  tab,
  district,
  // loading,
  error,
  allocation_code,
  getMajorCampaignsDetailsDataRequestAction,
  majorCampaignsData
}) => {
  const [loading, setLoading] = useState(true);
  const tabMappings = [
    { key: 'WWtKs', name: 'WWtk Questions', value: 'wwtk_questions' },
    { key: 'campaigns', name: 'Featured Lessons', value: 'featured_lessons' },
    { key: 'learning_modules', name: 'Learning Modules', value: 'learning_modules' },
    { key: 'materials', name: 'Spotlight Materials', value: 'spotlight_materials' },
    { key: 'blogs', name: 'Blogs', value: 'blogs' },
  ];

  const pageTabs = {};

  tabMappings.forEach((mapping) => {
    if (majorCampaignsData[mapping.key] && majorCampaignsData[mapping.key].length) {
      pageTabs[mapping.name] = mapping.value;
    }
  });

  const path = useLocation().pathname.substring(1).split('/')[2] || '';

  const { path: routePath } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  useEffect(() => {
    // Change the tab accordingly
    setTabAction(path);
  }, [path, setTabAction]);

  useEffect(() => {
    if (district.district_id !== '#all-districts#' && allocation_code) {
      const [projectId, districtId] = district.district_id.split('#$#');
      getMajorCampaignsDetailsDataRequestAction(projectId, districtId, allocation_code);
    }
  }, [district, allocation_code]);

  const selectActiveTab = (tabName) => {
    if (typeof tabName === 'number') return tabName;
    return Object.values(pageTabs).indexOf(tabName);
  };

  const handleChange = (event, newValue) => {
    setTabAction(newValue);
    history.replace(
      `/education/major-campaigns/${Object.values(pageTabs)[newValue]}`
    );
  };

  return (
    <>
      {
        !loading && Object.keys(pageTabs).length
          ? (
            <div style={{ padding: '20px 0px' }}>
              <Tab
                selectedTab={((t) => selectActiveTab(t))(tab)}
                onChange={handleChange}
                labels={Object.keys(pageTabs)}
                displayBlueTabs
                hideHr
                widthValue={`${100 / Object.keys(pageTabs).length}%`}
              />
              <Switch>
                <Route
                  path={`${routePath}/wwtk_questions`}
                  exact
                  render={() => <MajorCampaignWwtk />}
                />
                <Route
                  path={`${routePath}/featured_lessons`}
                  exact
                  render={() => <MajorCampaignFeaturedLessons />}
                />
                <Route
                  path={`${routePath}/learning_modules`}
                  exact
                  render={() => <MajorCampaignLearningModules />}
                />
                <Route
                  path={`${routePath}/spotlight_materials`}
                  exact
                  render={() => <MajorCampaignMaterials />}
                />
                <Route
                  path={`${routePath}/blogs`}
                  exact
                  render={() => <MajorCampaignBlogs />}
                />
                <Redirect
                  from={`${routePath}`}
                  exact
                  to={`${routePath}/${pageTabs[Object.keys(pageTabs)[0]]}`}
                />
              </Switch>
            </div>
          )
          : <> </>
      }
    </>
  );
};

const mapStateToProps = (store) => ({
  tab: store.majorCampaigns.tab,
  district: store.common.district,
  loading: store.majorCampaigns.loading,
  error: store.majorCampaigns.error,
  allocation_code: store.majorCampaigns.allocation_code,
  majorCampaignsData: store.majorCampaigns
});

export default injectIntl(connect(mapStateToProps, actions)(MajorCampaignTabs));
