import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

/**
 * Components
 */
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from '../../../../_metronic/partials/content/Portlet';
import {
  DatePicker,
  TimePicker,
  CustomButton,
  Snackbar,
  Typography,
} from '../../components';
import CampaignItem from '../components/CampaignItem';

/**
 * APIs
 */
import { createCampaignApi } from '../apis';

import { validation } from '../../../utils';

const useStyles = makeStyles({
  templatePreview: {
    marginBottom: 25
  }
});

const CreateCampaign = ({
  intl,
  district
}) => {
  const [newCampaign, setNewCampaign] = useState({});
  const [snackPayload, setSnackPayload] = useState({});
  const [valid, setValid] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { template } = location.state || {};
  const { templatePreview } = useStyles();

  useEffect(() => {
    // TODO: Use this effect to get the template item if there was an id in the url parameters
  }, []);

  useEffect(() => {
    /**
     * Check validation of the form
     */
    const isValid = () => {
      const { publish_dt, end_dt, send_time } = newCampaign;
      return publish_dt && end_dt && send_time;
    };

    setValid(isValid());
  }, [newCampaign]);

  const handleFormChange = (val, property) => {
    setNewCampaign({
      ...newCampaign,
      [property]: val
    });
  };

  /**
   * Proceed to create campaign
   */
  const proceed = async () => {
    try {
      const { data, error } = await createCampaignApi({
        district_id: district.district_id,
        publish_dt: newCampaign.publish_dt,
        publish_hour: newCampaign.send_time,
        template_id: template.id,
        end_dt: newCampaign.end_dt
      });
      if (error && error.detail && error.detail.data.status !== 'success' && error.detail.data.message === 'This time have overlaps with other campaigns.') {
        throw new Error(`
          This time overlaps with "${error.detail.data.data.campaign_nm}" campaign.
          The "${error.detail.data.data.campaign_nm}" is between ${error.detail.data.data.publish_dt} and ${error.detail.data.data.end_dt}
        `);
      }
      if (error) throw new Error('Could not create the campaign');
      if (data.status === 'success' && data.data.campaign_id) {
        setSnackPayload({
          key: new Date(),
          open: true,
          status: 'success',
          message: 'Successful!'
        });
        history.replace('/campaigns/tab/current_schedule');
      }
    } catch (e) {
      setSnackPayload({
        key: new Date(),
        open: true,
        status: 'error',
        message: e.message
      });
    }
  };

  /**
   * Cancel the operation and turn back to previous page
   */
  const cancel = () => {
    history.goBack();
  };

  return (
    <div>
      <Portlet>
        <PortletHeader title={intl.formatMessage({ id: 'CAMPAIGN.SWITCH_ITEM_TITLE' })} />
        <PortletBody>
          <Snackbar
            key={snackPayload.key}
            open={snackPayload.open}
            message={snackPayload.message}
            status={snackPayload.status}
            closeAction={null}
          />
          {
            (template)
              ? (
                <div className={templatePreview}>
                  <div className="w-100 pb-2 mb-4">
                    <Typography variant="body">
                      {/* TODO: Add this to i18n */}
                      Selected template
                    </Typography>
                  </div>
                  <div style={{ marginTop: -25 }}>
                    <CampaignItem
                      data={template}
                      uiOptions={{
                        preview: true,
                        switch: false,
                        reject: false,
                        date: false,
                        borderBottom: true,
                        padding: false
                      }}
                    />
                  </div>
                </div>
              ) : null
          }
          <div className="w-100 pb-2 mb-4">
            <Typography variant="body">
              {/* TODO: Add this to i18n */}
              Specify the date and time
            </Typography>
          </div>
          <div className="row mb-2">
            <div className="col-6">
              <DatePicker
                label={intl.formatMessage({ id: 'GENERAL.START_DATE' })}
                value={
                  (newCampaign.publish_dt && newCampaign.publish_dt !== '')
                    ? moment(newCampaign.publish_dt)
                    : null
                }
                onChange={(data) => {
                  handleFormChange(data ? moment(data).format('YYYY-MM-DD') : '', 'publish_dt');
                }}
                invalidText={
                  !validation(newCampaign.publish_dt, 'required')
                    ? intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
                    : null
                }
              />
            </div>
            <div className="col-6">
              <TimePicker
                label={intl.formatMessage({ id: 'GENERAL.TIME' })}
                value={
                  // eslint-disable-next-line no-nested-ternary
                  (!newCampaign.send_time)
                    ? null
                    : (!newCampaign.publish_dt) ? moment(`${'2020-12-12'}T${newCampaign.send_time}`) : moment(`${newCampaign.publish_dt}T${newCampaign.send_time}`)
                }
                onChange={(data) => {
                  handleFormChange(data ? moment(data).format('HH:mm:ss') : '', 'send_time');
                }}
                invalidText={
                  !validation(newCampaign.send_time, 'required')
                    ? intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
                    : null
                }
              />
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-6">
              <DatePicker
                label={intl.formatMessage({ id: 'GENERAL.END_DATE' })}
                value={
                  (newCampaign.end_dt && newCampaign.end_dt !== '')
                    ? moment(newCampaign.end_dt)
                    : null
                }
                onChange={(data) => {
                  handleFormChange(data ? moment(data).format('YYYY-MM-DD') : '', 'end_dt');
                }}
                invalidText={
                  !validation(newCampaign.end_dt, 'required')
                    ? intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
                    : null
                }
                minDate={newCampaign.publish_dt}
              />
            </div>
          </div>
          <div className="row justify-content-end mt-3 mb-3">
            <div className="row col-4 col-xl-4">
              <div className="col-6 pr-2">
                <CustomButton
                  label={intl.formatMessage({ id: 'GENERAL.CANCEL' })}
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={() => cancel()}
                />
              </div>
              <div className="col-6 pl-2 pr-2">
                <CustomButton
                  label={intl.formatMessage({ id: 'GENERAL.CONTINUE' })}
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={proceed}
                  disabled={!valid}
                />
              </div>
            </div>
          </div>
        </PortletBody>
      </Portlet>
    </div>
  );
};

const mapStateToProps = (store) => ({
  district: store.common.district
});

export default injectIntl(connect(mapStateToProps)(CreateCampaign));
