/* eslint-disable react/no-array-index-key */
import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { removeZeroFromTime, transformToAmpm } from '../../../utils';
import { Typography } from '../../components';

const useStyles = makeStyles(() => ({
  container: {
    width: '40%'
  },
  section: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    marginBottom: 20,
    paddingBottom: 20,
  }
}));

const SetupWizardPreview = ({
  intl,
  name,
  dates,
  description,
  locations,
}) => {
  const classes = useStyles();
  const days = [
    intl.formatMessage({ id: 'GENERAL.SUN' }),
    intl.formatMessage({ id: 'GENERAL.MON' }),
    intl.formatMessage({ id: 'GENERAL.TUE' }),
    intl.formatMessage({ id: 'GENERAL.WED' }),
    intl.formatMessage({ id: 'GENERAL.THU' }),
    intl.formatMessage({ id: 'GENERAL.FRI' }),
    intl.formatMessage({ id: 'GENERAL.SAT' }),
  ];

  return (
    <div className={classes.container}>
      <Typography variant="h1" className=" mt-3 mb-4">
        {intl.formatMessage({ id: 'EVENT.SETUP_WIZARD.PREVIEW.TITLE' })}
      </Typography>

      <div className={classes.section}>
        {
          name && (
            <Typography variant="h2" className="mb-3">
              {name}
            </Typography>
          )
        }
        {
          dates[0]
            ? dates.map((item, index) => (
              <React.Fragment key={`event_dates_${index}`}>
                {item.date && (
                  <Typography key={index} variant="bodySemiBold" className="mb-2">
                    {moment(item.date).format('MMMM DD, YYYY')}
                  </Typography>
                )}
              </React.Fragment>
            )) : null
        }
        {
          description && (
            <Typography variant="bodySemiBold" className="mt-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: description
                }}
              />
            </Typography>
          )
        }
      </div>

      {
        locations.map((location, index) => (
          <div key={`event_loc_${index}`} className={classes.section}>
            {
              location.event_loc_admin_nm && (
                <Typography variant="h2" className="mb-3">
                  {location.event_loc_admin_nm}
                </Typography>
              )
            }
            {
              location.display_addr && (
                <Typography variant="bodySemiBold" className="mb-3">
                  {location.display_addr}
                </Typography>
              )
            }
            {
              location.drop_event_loc_hours
              && location.drop_event_loc_hours.map((time, index) => {
                if (location.daysOfTheWeek[index].value) {
                  return (
                    <React.Fragment key={`event_loc_hours_${index}`}>
                      {
                    time.hours_lower_tm && time.hours_upper_tm && (
                      <div className="d-flex justify-content-between mb-2">
                        <Typography variant="bodySemiBold">
                          {days[time.dow_nbr]}
                        </Typography>
                        <div className="d-flex flex-column">
                          <Typography className="text-right" variant="bodySemiBold">
                            {removeZeroFromTime(transformToAmpm(time.hours_lower_tm))}
                            {' - '}
                            {removeZeroFromTime(transformToAmpm(time.hours_upper_tm))}
                          </Typography>
                          {
                            time.add_hours_lower_tm && time.add_hours_upper_tm && (
                              <Typography className="mt-2 text-right" variant="bodySemiBold">
                                {removeZeroFromTime(transformToAmpm(time.add_hours_lower_tm))}
                                {' - '}
                                {removeZeroFromTime(transformToAmpm(time.add_hours_upper_tm))}
                              </Typography>
                            )
                          }
                        </div>
                      </div>
                    )
                  }
                    </React.Fragment>
                  );
                }
                return null;
              })
            }
            {
              location.phone_nbr && (
                <div className="d-flex justify-content-between mt-3">
                  <Typography variant="bodySemiBold">
                    {intl.formatMessage({ id: 'DEPOT.CONTACT.TELEPHONE' })}
                  </Typography>
                  <Typography variant="bodySemiBold">
                    {location.phone_nbr}
                  </Typography>
                </div>
              )
            }
            {
              location.alt_phone_nbr && (
                <div className="d-flex justify-content-between mt-2">
                  <Typography variant="bodySemiBold">
                    {intl.formatMessage({ id: 'DEPOT.CONTACT.ALT_TELEPHONE' })}
                  </Typography>
                  <Typography variant="bodySemiBold">
                    {location.alt_phone_nbr}
                  </Typography>
                </div>
              )
            }
            {
              location.email_addr && (
                <div className="d-flex justify-content-between mt-2">
                  <Typography variant="bodySemiBold">
                    {intl.formatMessage({ id: 'DEPOT.CONTACT.EMAIL' })}
                  </Typography>
                  <Typography variant="bodySemiBold">
                    {location.email_addr}
                  </Typography>
                </div>
              )
            }
            {
              location.web_addr && (
                <div className="d-flex justify-content-between mt-2">
                  <Typography variant="bodySemiBold">
                    {intl.formatMessage({ id: 'DEPOT.CONTACT.WEBSITE' })}
                  </Typography>
                  <Typography variant="bodySemiBold">
                    {location.web_addr}
                  </Typography>
                </div>
              )
            }
          </div>
        ))
      }
    </div>
  );
};

export default injectIntl(SetupWizardPreview);
