/**
 *
 */
const initialState = {
  fetchState: 'no-request',
  data: [],
  allTime: true,
};

export default initialState;
