const actionTypes = {
  GET_RECYCLING_FUNDAMENTALS_DATA_REQUEST: 'GET_RECYCLING_FUNDAMENTALS_DATA_REQUEST',
  GET_RECYCLING_FUNDAMENTALS_DATA_RECEIVE: 'GET_RECYCLING_FUNDAMENTALS_DATA_RECEIVE',
  GET_RECYCLING_FUNDAMENTALS_DATA_FAIL: 'GET_RECYCLING_FUNDAMENTALS_DATA_FAIL',
  GET_MISUNDERSTOOD_MATERIALS_DATA_REQUEST: 'GET_MISUNDERSTOOD_MATERIALS_DATA_REQUEST',
  GET_MISUNDERSTOOD_MATERIALS_DATA_RECEIVE: 'GET_MISUNDERSTOOD_MATERIALS_DATA_RECEIVE',
  GET_MISUNDERSTOOD_MATERIALS_DATA_FAIL: 'GET_MISUNDERSTOOD_MATERIALS_FAIL',
  SET_CURRENT_QUESTION_INDEX: 'SET_CURRENT_QUESTION_INDEX',
};

export default actionTypes;
