import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import { Opacity, Typography, Pagination } from '../../../components';
import * as sharedActions from '../../../redux/actions';
import { LessonCard } from './components';

import * as actions from './redux/actions';

import { LoadingSpinner, ErrorContainer } from '../../../components/loading';
import { CrownIcon } from '../../../../assets/icons';
import { ProUser } from '../components';



const useStyles = makeStyles(() => ({
  container: {

  },
}));
const LessonLibrary = ({
  intl,
  district,
  municipalities,
  setDistrictAction,
  getLessonsLibraryDataRequestAction,
  lessonLibrary,
  loading,
  error,
  isUserPro,
  stage,
  getDistrictProPackagesRequest,
  district_pro_packages,
}) => {
  const classes = useStyles();

  const [hasfeaturedLesson, setHasFeaturedLesson] = useState(false);

  useEffect(() => {
    if (district.district_id === '#all-districts#') {
      setDistrictAction(municipalities[0]);
    } else {
      const [projectId, districtId] = district.district_id.split('#$#');
      getLessonsLibraryDataRequestAction(projectId, districtId);
    }
  }, [district]);

  useEffect(() => {
    if (stage !== '4') {
      getDistrictProPackagesRequest();
    }
    
  }, [stage]);

  useEffect(() => {
    if (district_pro_packages && district_pro_packages[0] && district_pro_packages[0].has_featured_lesson) {
      setHasFeaturedLesson(true);
    }
  }, [district_pro_packages]);

  return (
    <>
      {
        !isUserPro && stage < 4 && !hasfeaturedLesson &&  (
          <ProUser
            title={intl.formatMessage({ id: 'EDUCATION.LESSON.LIBRARY' })}
            description={intl.formatMessage({ id: 'EDUCATION.LESSON.LIBRARY.PRO.DESCRIPTION' })}
            subtitle="These lessons are developed and managed by a professional recycling educator here at Recycle Coach."
            alternateRightTitle={intl.formatMessage({ id: 'GENERAL.WHAT.BENEFITS' })}
            imagePath="lesson-library-non-pro.png"
            benefits={['Increase participation through weekly push notifications', 'Target specific materials or issues in your program ', 'Increase residents’ recycling knowledge', 'Provide interactive learning components', 'Promote behavior change ']}

          />
        )
      }
      <Opacity slide>
        {
          hasfeaturedLesson || isUserPro && stage >= 4  && (
            <>
              <Typography className="mb-2" variant="h1">
                {intl.formatMessage({ id: 'EDUCATION.LESSON.LIBRARY' })}
                <CrownIcon style={{ marginLeft: 7 }} width={15} height={15} color="#FFC611" />
              </Typography>

              <Typography color="#404040" className="mb-4" variant="educationBody">
                {intl.formatMessage({ id: 'EDUCATION.LESSON.LIBRARY.SUBTITLE' })}
              </Typography>

            </>
          )
        }

        <Typography color="#6C7293" variant="h2">
          List of lessons
          {' '}
          {!isUserPro && stage < 4 && !hasfeaturedLesson &&  'which are displayed to PRO Users '}
        </Typography>


        {loading ? <LoadingSpinner height={430} /> : error ? (
          <ErrorContainer
            small
            reportName="lessonLibrary"
            height={430}
            error={error}
          />
        ) : (
          <Opacity>
            <div className="d-flex flex-wrap mt-3">
              {lessonLibrary && lessonLibrary.map((item) => (
                <LessonCard key={item.campaign_id} isDeletable={false} data={item} />
              ))}
            </div>
          </Opacity>
        )}


      </Opacity>
    </>

  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  municipalities: store.common.municipalities,
  loading: store.lessonLibrary.loading,
  error: store.lessonLibrary.error,
  lessonLibrary: store.lessonLibrary.campaigns,
  isUserPro: store.common.isUserPro,
  stage: store.common.district.stage,
  district_pro_packages: store.common.district_pro_packages,
});
export default injectIntl(connect(mapStateToProps, { ...actions, ...sharedActions })(LessonLibrary));

