import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import CheckIcon from '@material-ui/icons/Check';


import * as actions from '../../../Dashboard/redux/actions';


import Main from './components/Main';

import { ProUser } from '../components';


const useStyles = makeStyles(() => ({
  container: {

  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 17,
    height: 17,
    background: '#00C8BC',
    borderRadius: '50%',
    marginRight: 5,
    '& svg': {
      fontSize: '1rem'
    }
  }
}));
const PdfAddOns = ({
  intl,
  isUserPro,
  csm,
  getDashboardDataRequestAction,
  district,
  stage
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!csm) {
      const fromDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
      const toDate = moment().format('YYYY-MM-DD');
      getDashboardDataRequestAction(fromDate, toDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const project_id_value = (district.district_id).split('#$#')[0];
  const district_id_value = (district.district_id).split('#$#')[1];

  return (
    <>
      {!isUserPro && stage < 4 && (
        <ProUser
          title={intl.formatMessage({ id: 'EDUCATION.PDF.ADD.ONS' })}
          description="When residents download their recycling calendars from your website via the Recycle Coach web app, these PDF flyers will be added to their printable calendar. "
          subtitle="These informative PDFs are available to PRO clients to enhance recycling education for web app users. "
          alternateRightTitle={intl.formatMessage({ id: 'GENERAL.WHAT.BENEFITS' })}
          benefits={['Help recycling education reach more residents', 'Target specific materials or issues in your program', 'Increase residents’ recycling knowledge ', 'Highlight the Recycling Fundamentals']}
        />
      )}
      <Main district_id_value={district_id_value} project_id_value={project_id_value} isUserPro={isUserPro} stage={stage} />

    </>
  );
};


const mapStateToProps = (store) => ({
  district: store.common.district,
  loading: store.pdfAddOns.loading,
  error: store.pdfAddOns.error,
  isUserPro: store.common.isUserPro,
  stage: store.common.district.stage,
  csm: store.dashboard.csm,
});

export default injectIntl(connect(mapStateToProps, actions)(PdfAddOns));
