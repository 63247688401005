import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import { Typography } from '../../../components';
import { AlertIcon } from '../../../../assets/icons';

const useStyles = makeStyles({
  container: {
    backgroundColor: process.env.REACT_APP_COLOR_TERTIARY,
    borderRadius: 10,
    padding: '20px 25px',
    marginBottom: 20,
    maxHeight: 176.44,
    minHeight: 176.44,
    overflow: 'auto',
    position: 'relative',
  },
  textStyle: {
    color: 'white',
  },
  titleStyle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  showMoreButtonStyle: {
    border: 'none',
    background: 'transparent',
    marginLeft: 10,
    color: 'white',
    textDecoration: 'underline'
  }
});

const MessageCard = ({
  title,
  description,
  linkDescription,
  linkUrl,
}) => {
  const {
    container,
    textStyle,
    titleStyle
  } = useStyles();

  const classes = container;

  return (
    <div className={classes}>
      <Typography
        variant="h2"
        title={title}
        className={classNames(textStyle, titleStyle)}
      >
        <AlertIcon color="white" style={{ padding: '0 10px 0 0', fontSize: 35 }} />
        {title}
      </Typography>
      <div className="p-0" style={{ marginTop: 10 }}>
        <Typography variant="body" className={textStyle}>
          {description}
          <a href={linkUrl} target="_blank" rel="noreferrer">{linkDescription}</a>
        </Typography>
      </div>
    </div>
  );
};

export default injectIntl(MessageCard);
