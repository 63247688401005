import { put, call, takeLatest } from 'redux-saga/effects';

import { setCookie } from '../../../../utils';
import actionTypes from './actionTypes';
import * as actions from './actions';
import * as sharedActions from '../../../redux/actions';
import { login } from '../../apis';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

function* loginSaga({ payload: { username, password } }) {
  try {
    yield put(actions.setLoadingAction());
    const res = yield call(login, { username, password });
    if (res.data) {
      if (res.data.status === 'success') {
        const userInfo = res.data.data.user_info
        Bugsnag.start({
          apiKey: process.env.REACT_APP_BUGSNAG_KEY,
          plugins: [new BugsnagPluginReact()],
          user: {
            id: userInfo ? userInfo.user_name : username,
            name: userInfo ? `${userInfo.first_name} ${userInfo.last_name}` : '',
            email: userInfo ? userInfo.email : ''
          }
        })
        // Bugsnag.setUser(userInfo.user_name, userInfo.email, `${userInfo.first_name} ${userInfo.last_name}`)

        setCookie('_rc_access', res.data.data.access_token);
        yield put(sharedActions.setShowTutorialVideo(true));
        yield put(sharedActions.setLoadingAction('no-request'));
        yield put(actions.loginReceive(res.data));
        yield put(sharedActions.getMunicipalityRequest());
        yield put(sharedActions.getLocalitiesRequest());
        if (res.data.data?.user_info && res.data.data.user_info?.counties !== null) {
          yield put(sharedActions.getCountyRequest());
        }
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.loginFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

/**
 * Saga watcher function
 */
function* sagas() {
  yield takeLatest(actionTypes.LOGIN_REQUEST, loginSaga);
}

export default sagas;
