import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import CustomButton from '../../components/CustomButton';
import { SwitchIcon } from '../../../assets/icons';

const textColor = process.env.REACT_APP_COLOR_PRIMARY;

const useStyles = makeStyles({
  container: {
    border: `1px solid ${textColor}`
  }
});

const SwitchOut = ({ onClick, intl, use }) => {
  const { container } = useStyles();
  return (
    <CustomButton
      variant="outline"
      className={container}
      label={(() => (
        <>
          <SwitchIcon color={textColor} style={{ fontSize: 20 }} />
          <span style={{ paddingLeft: 10, color: textColor }}>
            {
              use
                ? (
                  intl.formatMessage({ id: 'CAMPAIGN.ITEM.USE' })
                ) : (
                  intl.formatMessage({ id: 'CAMPAIGN.ITEM.SWITCH' })
                )
            }
          </span>
        </>
      ))()}
      onClick={onClick}
    />
  );
};

export default injectIntl(SwitchOut);
