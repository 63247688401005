/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { TableLoading } from '../../../components/loading';

import {
  Portlet,
  PortletBody,
  PortletHeader,
} from '../../../../../_metronic/partials/content/Portlet';
import { DisplayColorIcon, InfoIcon } from '../../../../assets/icons';
import {
  Pagination, AutoComplete, Typography, IconButton
} from '../../../components';
import { addCommasToNumber } from '../../../../utils';
import * as actions from '../redux/actions';

const useStyles = makeStyles((theme) => ({
  detailLoading: {
    height: '50vh'
  },
  currentPeriodTotal: {
    color: process.env.REACT_APP_COLOR_REPORT_1
  },
  previousPeriodTotal: {
    color: process.env.REACT_APP_COLOR_PRIMARY_LIGHT
  },
  lastYearTotal: {
    color: process.env.REACT_APP_COLOR_DELETE
  },
  ranking: {
    color: process.env.REACT_APP_COLOR_TERTIARY,
    fontStyle: 'italic'
  },
  materialItem: {
    fontWeight: 500,
  },
  dataTable: {
    '& > div:last-child': {
      borderBottom: '0px !important',
    }
  },
  border: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  smallerThanMdHide: {
    [theme.breakpoints.down('md')]: {
      display: 'none !important'
    }
  },
  smallerThanMdShow: {
    [theme.breakpoints.up('md')]: {
      display: 'none !important'
    },
  },
  onlyMd: {
    [theme.breakpoints.only('md')]: {
      display: 'flex !important',
    },
  },
}));

const Detail = ({
  intl,
  data,
  fetchState,
  fromDate,
  toDate,
  setReport,
  reportType
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isSendRequest = fetchState === 'send-request';

  useEffect(() => {
    setPage(1);
  }, [rowsPerPage]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  return (
    <Portlet className="h-100 position-relative">
      <PortletHeader
        title={(
          <div className="d-flex align-items-center">
            <Typography variant="h1">
              {intl.formatMessage({ id: 'REPORT.MATERIALS.DETAIL.HEADER' })}
            </Typography>
            <Typography className="ml-4" variant="bodySemiBold">
              {`${moment(fromDate).format('MMMM Do, YYYY')} - ${moment(toDate).format('MMMM Do, YYYY')}`}
            </Typography>
          </div>
        )}

      />
      <PortletBody>

        {isSendRequest ? (
          <div className="d-flex overflow-hidden flex-column w-100 justify-content-between align-items-center">
            <TableLoading width="100%" />
          </div>
        ) : (
          <>
            {data && (
              <div className="mt-2">
                <div className={`mt-3 pb-3 ${classes.smallerThanMdShow}`}>
                  <AutoComplete
                    optionTitle="name"
                    title={intl.formatMessage({ id: 'REPORT.MATERIALS.MOBILE.REPORT_PERIOD' })}
                    isClearable={false}
                    options={
                      [{ name: 'Current Period', value: 0 }, { name: 'Previous Period', value: 1 }, { name: 'Last Year', value: 2 }]
                    }
                    onChange={(data) => { setReport(data); }}
                    value={reportType}
                  />
                </div>
                <div className={`row ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
                  <div className="col-4" />
                  <div className="col d-flex align-items-center">
                    <DisplayColorIcon color={process.env.REACT_APP_COLOR_REPORT_1} />
                    <Typography variant="h2">
                      {intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_PERIOD' })}
                    </Typography>
                  </div>
                  <div className="col d-flex align-items-center">
                    <DisplayColorIcon color={process.env.REACT_APP_COLOR_PRIMARY_LIGHT} />
                    <Typography variant="h2">
                      {intl.formatMessage({ id: 'REPORT.GENERAL.PREVIOUS_PERIOD' })}
                      <IconButton
                        tooltip={intl.formatMessage({ id: 'REPORT.GENERAL.PREVIOUS_PERIOD.TOOLTIP' })}
                      >
                        <InfoIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                      </IconButton>
                    </Typography>
                  </div>

                  <div style={{ whiteSpace: 'nowrap' }} className="col d-flex align-items-center">
                    <DisplayColorIcon color={process.env.REACT_APP_COLOR_DELETE} />
                    <Typography variant="h2">
                      {intl.formatMessage({ id: 'REPORT.GENERAL.LAST_YEAR' })}
                    </Typography>
                  </div>
                </div>
                <div className={`row d-flex align-items-center mt-4 ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
                  <div className="col-4">
                    <Typography variant="h2">
                      {intl.formatMessage({ id: 'REPORT.MATERIALS.DETAIL.SEARCH_TOTALS' })}
                    </Typography>
                  </div>
                  <div className="col ml-4">
                    <Typography variant="numberSmall" className={classes.currentPeriodTotal}>
                      {addCommasToNumber(data.total.current_period)}
                    </Typography>
                  </div>
                  <div className="col ml-4">
                    <Typography variant="numberSmall" className={classes.previousPeriodTotal}>
                      {addCommasToNumber(data.total.previous_period)}
                    </Typography>
                  </div>
                  <div className="col ml-4">
                    <Typography variant="numberSmall" className={classes.lastYearTotal}>
                      {addCommasToNumber(data.total.last_year)}
                    </Typography>
                  </div>
                </div>
                <div className="align-items-center mt-4">
                  <div className={`d-flex flex-column justify-content-start mt-3 pb-3 ${classes.smallerThanMdShow}`}>
                    <div className="d-flex justify-content-start align-items-center p-0">
                      {reportType.value === 0 && <DisplayColorIcon className="p-0" color={process.env.REACT_APP_COLOR_REPORT_1} />}
                      {reportType.value === 1 && <DisplayColorIcon className="p-0" color={process.env.REACT_APP_COLOR_PRIMARY_LIGHT} />}
                      {reportType.value === 2 && <DisplayColorIcon className="p-0" color={process.env.REACT_APP_COLOR_DELETE} />}
                      <Typography variant="h2">
                        {reportType.value === 0 && intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_PERIOD' })}
                        {reportType.value === 1 && intl.formatMessage({ id: 'REPORT.GENERAL.PREVIOUS_PERIOD' })}
                        {reportType.value === 2 && intl.formatMessage({ id: 'REPORT.GENERAL.LAST_YEAR' })}
                        {reportType.value === 1
                          && (
                            <IconButton
                              tooltip={intl.formatMessage({ id: 'REPORT.GENERAL.PREVIOUS_PERIOD.TOOLTIP' })}
                            >
                              <InfoIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                            </IconButton>
                          )}

                      </Typography>
                    </div>
                    {reportType.value === 0 && (
                      <Typography variant="numberSmall" className={`pl-1 ${classes.currentPeriodTotal}`}>
                        {addCommasToNumber(data.total.current_period)}
                      </Typography>
                    )}
                    {reportType.value === 1 && (
                      <Typography variant="numberSmall" className={`pl-1 ${classes.previousPeriodTotal}`}>
                        {addCommasToNumber(data.total.previous_period)}
                      </Typography>
                    )}
                    {reportType.value === 2 && (
                      <Typography variant="numberSmall" className={`pl-1 ${classes.lastYearTotal}`}>
                        {addCommasToNumber(data.total.last_year)}
                      </Typography>
                    )}
                  </div>
                  <div className="d-flex align-items-center mt-4 mb-3">
                    <Typography className="mr-2" variant="h2">
                      {intl.formatMessage({ id: 'REPORT.MATERIALS.DETAIL.MATERIALS' })}
                    </Typography>
                    <Typography className={classes.ranking} variant="body">
                      {'('}
                      {intl.formatMessage({ id: 'REPORT.MATERIALS.DETAIL.SEARCH_RANKING' })}
                      {')'}
                    </Typography>
                  </div>
                </div>
                <div className={classes.dataTable}>
                  {[...data.materials]
                    .slice((page - 1) * rowsPerPage, (page * rowsPerPage)).map((item) => (
                      <div
                        key={item.title}
                        className={`row d-flex align-items-center pt-4 pb-4 ${classes.border} ${classes.smallerThanMdHide} ${classes.onlyMd}`}
                      >
                        <div className="col-4">
                          <Typography variant="bodySemiBold">
                            {item.title}
                          </Typography>
                        </div>
                        <div className="col row d-flex align-items-center ml-4">
                          <Typography className="col-3" variant="bodySemiBold">
                            {addCommasToNumber(item.current_period)}
                          </Typography>
                          <Typography variant="body" className={`col ${classes.ranking}`}>
                            {'('}
                            {addCommasToNumber(item.current_period_rank)}
                            {')'}
                          </Typography>
                        </div>
                        <div className="col row d-flex align-items-center ml-4">
                          <Typography className="col-3" variant="bodySemiBold">
                            {addCommasToNumber(item.previous_period)}
                          </Typography>
                          <Typography variant="body" className={`col ${classes.ranking}`}>
                            {'('}
                            {addCommasToNumber(item.previous_period_rank)}
                            {')'}
                          </Typography>
                        </div>
                        <div className="col row d-flex align-items-center ml-4">
                          <Typography className="col-3" variant="bodySemiBold">
                            {addCommasToNumber(item.last_year)}
                          </Typography>
                          <Typography variant="body" className={`col ${classes.ranking}`}>
                            {'('}
                            {addCommasToNumber(item.last_year_rank)}
                            {')'}
                          </Typography>
                        </div>
                      </div>
                    ))}

                  <div className={`mt-3 pb-3 ${classes.smallerThanMdShow}`}>
                    {data.materials.slice((page - 1) * rowsPerPage, (page * rowsPerPage)).map((item) => (
                      <div
                        key={item.title}
                        className={`row d-flex align-items-center pt-4 pb-4 ${classes.border}`}
                      >
                        <div className="col-6">
                          <Typography variant="bodySemiBold">
                            {item.title}
                          </Typography>
                        </div>
                        <div className="col row d-flex align-items-center ml-4">
                          <Typography variant="bodySemiBold" className={`col-3 ${classes.ranking}`}>
                            {'('}
                            {reportType.value === 0 && addCommasToNumber(item.current_period_rank)}
                            {reportType.value === 1 && addCommasToNumber(item.previous_period_rank)}
                            {reportType.value === 2 && addCommasToNumber(item.last_year_rank)}
                            {')'}
                          </Typography>
                          <Typography className="col-3 ml-5" variant="bodySemiBold">
                            {reportType.value === 0 && addCommasToNumber(item.current_period)}
                            {reportType.value === 1 && addCommasToNumber(item.previous_period)}
                            {reportType.value === 2 && addCommasToNumber(item.last_year)}
                          </Typography>
                        </div>

                      </div>
                    ))}
                  </div>
                </div>

                <div className={`mt-3 mb-3 ${classes.smallerThanMdShow}`}>
                  <div className="d-flex justify-content-center align-items-center">
                    <Pagination
                      pager={false}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      handlePageChange={(value) => handleChangePage(value)}
                      handleRowsPerPageChange={(value) => setRowsPerPage(value)}
                      dataLength={data.materials.length}
                    />
                  </div>
                </div>

                <div className={`mt-5 mb-4 d-flex justify-content-end align-items-center ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
                  <Pagination
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handlePageChange={(value) => handleChangePage(value)}
                    handleRowsPerPageChange={(value) => setRowsPerPage(value)}
                    dataLength={data.materials.length}
                  />
                </div>
              </div>
            )}
          </>
        )}

      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.materialReport.fetchState,
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
  reportType: store.materialReport.reportType
});

export default injectIntl(connect(mapStateToProps, actions)(Detail));
