import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Portlet,
  PortletHeader,
  PortletBody,
  PortletHeaderToolbar
} from '../../../../../_metronic/partials/content/Portlet';
import CampaignItem from '../../../Campaign/components/CampaignItem';

const Campaign = ({
  intl,
  campaignInfo
}) => (
  <div className="row">
    <div className="col-xl-12">
      <Portlet>
        <PortletHeader
          title={intl.formatMessage({ id: 'CAMPAIGN.HEADER.TITLE' })}
          toolbar={(
            <PortletHeaderToolbar>
              <Link to="/campaign">
                {intl.formatMessage({ id: 'CAMPAIGN.VIEW_CAMPAIGN_SCHEDULE' })}
              </Link>
            </PortletHeaderToolbar>
          )}
        />
        <PortletBody>
          <CampaignItem
            campPage="schedule"
            data={campaignInfo}
            uiOptions={{
              preview: true,
              switch: false,
              reject: false,
              date: true,
              borderTop: false,
              borderBottom: false,
              padding: false
            }}
          />
        </PortletBody>
      </Portlet>
    </div>
  </div>
);

export default injectIntl(Campaign);
