import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Dropdown } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';

import { ThreeDotMenuIcon } from '../../../assets/icons';
import { Dialog, CustomButton, Typography } from '../../components';
import Preview from './Preview';
import * as actions from '../redux/actions';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    maxHeight: '500px',
    overflow: 'auto',
    flexDirection: 'column',
  },
  dropdown: {
    '& button': {
      padding: 0
    }
  },
  dropdownIcon: {
    '&::after': {
      display: 'none !important'
    },
    '& svg': {
      width: '1.3em',
      height: '1.3em',
    }
  },
  dropdownMenu: {
    maxHeight: 300,
    overflow: 'auto'
  },
  dropdownItem: {
    padding: '0 !important',
  },
}));

const EventMenu = ({
  intl,
  event,
  locality,
  deleteEventRequest,
}) => {
  const classes = useStyles();
  const [dropdownMode, setDropdownMode] = useState('default');

  return (
    <Dropdown
      alignRight
      className={classes.dropdown}
      drop="down"
      onToggle={() => setDropdownMode('default')}
    >
      <Dropdown.Toggle
        variant="transparent"
        className={classes.dropdownIcon}
      >
        <ThreeDotMenuIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu className={`dropdown-menu-fit dropdown-menu-right ${classes.dropdownMenu}`}>

        <Preview event={event} />

        <Dialog
          disableBackdropClick={false}
          button={(
            <CustomButton
              className={classes.dropdownItem}
              label={(
                <Typography variant="bodySemiBold">
                  {intl.formatMessage({ id: 'CATEGORY.MENU.DELETE' })}
                </Typography>
              )}
            />
          )}
          title={(
            <Typography variant="h1">
              {intl.formatMessage({ id: 'GENERAL.DELETE' })}
            </Typography>
          )}
          content={(
            <Typography variant="body">
              {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.DELETE_TEXT' })}
            </Typography>
          )}
          handleSubmit={() => {
            deleteEventRequest({
              locality: locality.cms_locality_id,
              event: event.drop_event_id
            });
          }}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = (store) => ({
  locality: store.common.locality,
});

export default injectIntl(connect(mapStateToProps, actions)(EventMenu));
