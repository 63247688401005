import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { addCommasToNumber } from '../../../../utils';
import { IconButton, InfoTooltip, Typography } from '../../../components';
import {
  ExpandIcon,
  CollapseIcon,
} from '../../../../assets/icons';

const useStyles = makeStyles((theme) => ({
  border: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
  },
  value: {
    color: '#2e75cf'
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: '#2e75cf'
  },
  icon: {
    width: 30,
    height: 30
  },
  smallerThanMdHide: {
    [theme.breakpoints.down(1200)]: {
      display: 'none !important'
    }
  },
  smallerThanMdShow: {
    [theme.breakpoints.up(1200)]: {
      display: 'none !important'
    },
  },
}));

const InteractionReportNotificationDetail = ({
  intl,
  advocateAdmin,
  data,
  expanded,
  onClick,
}) => {
  const classes = useStyles();
  console.log(advocateAdmin);

  return (
    <div className={classes.border}>
      <div className="row d-flex align-items-center p-4">
        <div className={`col-1 mr-3 mr-xl-0 ${classes.smallerThanMdHide}`}>
          <div className={classes.circle} />
        </div>
        <div className={`col-10 col-xl ${classes.smallerThanMdHide}`}>
          <Typography className="d-flex align-items-center" variant="h2">
            {intl.formatMessage({ id: 'REPORT.INTERACTIONS.DETAIL.CATEGORIES.NOTIFICATION.TITLE' })}
            <InfoTooltip tooltip={intl.formatMessage({ id: 'REPORT.INTERACTIONS.DETAIL.CATEGORIES.NOTIFICATION.DESCRIPTION' })} />
          </Typography>
        </div>
        <div className={`col-11 col-xl-5 mt-3 mt-xl-0 ${classes.smallerThanMdHide}`}>
          <Typography className={classes.value} variant="numberLarge">
            {addCommasToNumber(data.total)}
          </Typography>
        </div>
        <div className={`col-1 mt-3 mt-xl-0 ${classes.smallerThanMdHide}`}>
          {data && data.detail && data.detail.length !== 0 && (
          <>
            {expanded
              ? (
                <IconButton onClick={onClick}>
                  <CollapseIcon className={classes.icon} />
                </IconButton>
              )
              : (
                <IconButton onClick={onClick}>
                  <ExpandIcon className={classes.icon} />
                </IconButton>
              )}
          </>
          )}
        </div>

        <div className={`d-flex w-100 p-0 ${classes.smallerThanMdShow}`}>
          <div className={`mr-5 ${classes.circle}`} />
          <div className="row p-0 w-100 justify-content-between">
            <div className="d-flex flex-column justify-content-start">
              <div className="row pl-3">
                <Typography className="d-flex align-items-center" variant="h2">
                  {intl.formatMessage({ id: 'REPORT.INTERACTIONS.DETAIL.CATEGORIES.NOTIFICATION.TITLE' })}
                  <InfoTooltip tooltip={intl.formatMessage({ id: 'REPORT.INTERACTIONS.DETAIL.CATEGORIES.NOTIFICATION.DESCRIPTION' })} />
                </Typography>
              </div>
              <Typography className={classes.value} variant="numberLarge">
                {addCommasToNumber(data.total)}
              </Typography>
            </div>
            <div className="d-flex mt-3">
              {data && data.detail && data.detail.length !== 0 && (
              <>
                {expanded
                  ? (
                    <IconButton onClick={onClick}>
                      <CollapseIcon className={classes.icon} />
                    </IconButton>
                  )
                  : (
                    <IconButton onClick={onClick}>
                      <ExpandIcon className={classes.icon} />
                    </IconButton>
                  )}
              </>
              )}
            </div>
          </div>
        </div>

      </div>
      {expanded && (
        <div className="row d-flex justify-content-end">
          {Array.isArray(data.detail) ? data.detail.map((item, index) => (
            <div key={item.title} className={`col-11 row d-flex align-items-start pb-4 pr-4 pl-4 ${index !== 0 ? 'pt-4' : null} ${index !== data.detail.length - 1 ? classes.border : null}`}>
              <div className="col-5 pl-0">
                <Typography variant="h2">
                  {item.title}
                </Typography>
              </div>
              <div className="col ml-4">
                <Typography variant="h2">
                  {addCommasToNumber(item.value)}
                </Typography>
              </div>
            </div>
          ))
            : (
              <>
                { !advocateAdmin && (
                  <div className={`col-11 row d-flex align-items-start pb-4 pr-4 pl-4 ${classes.border}`}>
                    <div className="col-5 pl-0">
                      <Typography variant="h2">
                        {intl.formatMessage({ id: 'REPORT.INTERACTIONS.DETAIL.CATEGORIES.NOTIFICATION.CAMPAIGN' })}
                      </Typography>
                    </div>
                    <div className="col ml-4">
                      <Typography variant="h2">
                        {addCommasToNumber(data.detail.Campaign)}
                      </Typography>
                    </div>
                  </div>
                )}
                <div className={`col-11 row d-flex align-items-start p-4 ${classes.border}`}>
                  <div className="col-5 pl-0">
                    <Typography variant="h2">
                      {intl.formatMessage({ id: 'REPORT.INTERACTIONS.DETAIL.CATEGORIES.NOTIFICATION.EMERGENCY' })}
                    </Typography>
                  </div>
                  <div className="col ml-4">
                    <Typography variant="h2">
                      {addCommasToNumber(data.detail.Emergency)}
                    </Typography>
                  </div>
                </div>
                <div className={`col-11 row d-flex align-items-start p-4 ${classes.border}`}>
                  <div className="col-5 pl-0">
                    <Typography variant="h2">
                      {intl.formatMessage({ id: 'REPORT.INTERACTIONS.DETAIL.CATEGORIES.NOTIFICATION.GENERAL' })}
                    </Typography>
                  </div>
                  <div className="col ml-4">
                    <Typography variant="h2">
                      {addCommasToNumber(data.detail.General)}
                    </Typography>
                  </div>
                </div>
                <div className="col-11 row d-flex align-items-start p-4">
                  <div className="col-5 pl-0">
                    <Typography variant="h2">
                      {intl.formatMessage({ id: 'REPORT.INTERACTIONS.DETAIL.CATEGORIES.NOTIFICATION.PROBLEM_REPORT' })}
                    </Typography>
                  </div>
                  <div className="col ml-4">
                    <Typography variant="h2">
                      {addCommasToNumber(data.detail['Problem Report'])}
                    </Typography>
                  </div>
                </div>
              </>
            )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  advocateAdmin: store.auth.advocateAdmin,
});

export default injectIntl(connect(mapStateToProps, null)(InteractionReportNotificationDetail));
