import React, { useState } from 'react';
import { connect } from 'react-redux';

import { injectIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { Tooltip } from '@material-ui/core';
import { CustomButton } from '../../../../components';
import { EducationModal, DropdownMenu } from '../../components';
import { PreviewPDFAddOnModal } from '../components';
import * as actions from '../redux/actions';


const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',

    // width: '32.333%',
    margin: '10px 0.5%',
    transition: 'all .3s',
    borderRadius: 7,
    '&:hover': {
      boxShadow: 'rgba(212, 190, 190, 0.1) 0px 1px 4px 8px'
    },
    '& .MuiButton-label': {
      fontWeight: ({ campaign_is_read }) => (campaign_is_read ? 400 : 'bold')
    }
  },
  title: {
    fontSize: '15px',
    height: 45,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  titleHeader: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '27px',
    color: '#404040'
  },
  imageContainer: {
    // backgroundColor: '#FFFAFA',
    // borderRadius: 5,
    // boxShadow: 'rgba(212, 190, 190, 0.1) 0px 1px 2px 2px'
    flexDirection: 'row',
  },
  campaignImage: {
    width: '100%',
    height: 'auto',
  },
  summary: {
    height: 108,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 6,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textarea: {
    resize: 'none',
    width: '95%',
    padding: 5,
    height: 100,
    marginTop: 15,
    borderRadius: 5,
    '&:focus': {
      outline: 'none'
    }
  },
  tooltip: {
    backgroundColor: process.env.REACT_APP_COLOR_SECONDARY,
    color: process.env.REACT_APP_COLOR_TEXT,
    boxShadow: theme.shadows[3],
    fontSize: 14,
  },
  arrow: {
    color: process.env.REACT_APP_COLOR_SECONDARY,
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  dateRange: {
    fontSize: 12,
    fontWeight: 400,
    color: '#404040CC',
    lineHeight: '18px',
    letterSpacing: '0.04em',
    height: 18,
    marginBottom: 10
  }
}));
const PDFAddOnCard = ({
  intl,
  data,
  feedbackRequest,
  error,
  showPreview
}) => {
  const classes = useStyles({});


  /**
  * Feedback modal states and functions.
  */

  const [feedbackText, setFeedbackText] = useState('');
  const setFeedbackTextHandler = (e) => {
    setFeedbackText(e.target.value);
  };

  /**
   * Menu and menuitem functions.
   */

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [isPreviewingFeaturedLesson, setIsPreviewingFeaturedLesson] = useState(false);


  const [isSubmittingAFeedback, setIsSubmittingAFeedback] = useState();
  const isSubmittingAFeedbackHandler = () => {
    setAnchorEl(null);
    setIsSubmittingAFeedback(true);
  };

  /**
   * Data for dropdown menu items.
   */
  const menuItemData = [
    {
      id: 1,
      name: 'EDUCATUION.SUBMIT.A.FEEDBACK',
      method: isSubmittingAFeedbackHandler,
      disabled: false
    },
  ];

  const feedbackHandler = () => {
    feedbackRequest(data, feedbackText);
    setIsSubmittingAFeedback(false);
  };

  return (
    <div className={`d-flex flex-wrap p-2 ${classes.container}`}>
      <div style={{ width: '100%' }} className="d-flex justify-content-between mb-3">
        <Tooltip
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrow,
          }}
          title={data.title}
          placement="top"
          arrow
          leaveDelay={100}
        >
          <div
            className={classes.titleHeader}
          >
            {data.title}
          </div>
        </Tooltip>

        <DropdownMenu
          handleClick={handleClick}
          anchorEl={anchorEl}
          handleClose={handleClose}
          menuItemData={menuItemData}
        />
      </div>
      <div
        className={classes.dateRange}
      >
        {
          data.date_from && data.date_from ? (
            <>
              {moment(data.date_from).format('MMM D, YYYY')}
              {' '}
              -
              {' '}
              {moment(data.date_to).format('MMM D, YYYY')}
            </>
          ) : 'UNASSIGNED'
        }


      </div>
      <div className={`${classes.imageContainer} d-flex justify-content-center w-100 mb-3`}>
        <img
          src={data.image ? `${data.image}` : null}
          className={classes.campaignImage}
          alt={data.image}
        />
      </div>

      {
        showPreview && (
          <CustomButton
            paddingVertical={4}
            variant={'contained'}
            label={<FormattedMessage id="GENERAL.PREVIEW" />}
            onClick={() => {
              setIsPreviewingFeaturedLesson(true)
            }}
          />
        )
      }




      {/**
       * Submit a feedback modal.
       */}

      <EducationModal
        open={isSubmittingAFeedback}
        onClose={() => setIsSubmittingAFeedback()}
        title={data.campaign_nm}
        content={<textarea onChange={setFeedbackTextHandler} className={classes.textarea} />}
        buttonApply={intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}
        disabled={!feedbackText.length}
        onClickHandler={() => feedbackHandler()}
      />


      {/**
       * Submit a preview modal.
       */}

      <PreviewPDFAddOnModal
        open={isPreviewingFeaturedLesson}
        onClose={() => setIsPreviewingFeaturedLesson()}
        title={data.campaign_nm}
        content={<textarea onChange={setFeedbackTextHandler} className={classes.textarea} />}
        buttonApply={intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}
        disabled={!feedbackText.length}
        previewID={data.pdf_link}
      />

    </div>
  );
};
export default injectIntl(connect(null, actions)(PDFAddOnCard));

