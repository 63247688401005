import React from 'react';

const Form = ({ children, onSubmit }) => (
  <form
    noValidate
    autoComplete="off"
    className="kt-form"
    onSubmit={onSubmit || null}
  >
    {children}
  </form>
);

export default Form;
