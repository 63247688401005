import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

import { Typography } from '../../components';

const useStyles = makeStyles((theme) => ({
  container: {
    color: process.env.REACT_APP_COLOR_BACKGROUND_FILL,
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10)
  }
}));

const NoCampaign = ({ intl }) => {
  const { container } = useStyles();
  return (
    <div className="h-100 d-flex justify-content-center">
      <Typography className={container} variant="numberSmall">
        {intl.formatMessage({ id: 'CAMPAIGN.NO_CAMPAIGN' })}
      </Typography>
    </div>
  );
};

export default injectIntl(NoCampaign);
