import React, { useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';


import { CustomButton, Typography } from '../../../components';
import { LoadingSpinner, ErrorContainer } from '../../../components/loading';

import * as actions from '../../../Dashboard/redux/actions';

import { CrownIcon } from '../../../../assets/icons';


const useStyles = makeStyles((theme, borderColor = process.env.REACT_APP_COLOR_BACKGROUND_FILL
) => ({
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 17,
    height: 17,
    background: '#00C8BC',
    borderRadius: '50%',
    marginRight: 5,
    '& svg': {
      fontSize: '1rem'
    }
  },
  contentOnlyHeader: {
    fontSize: 18,
    color: '#0789C4',
  },
  hr: {
    width: '100%',
    margin: 0,
    position: 'relative',
    top: '-2px',
    borderTop: `2px solid ${borderColor}`,
  }
}));
const ProUser = ({
  intl,
  error,
  isUserPro,
  loading,
  isFirstLoading,
  csm,
  title,
  subtitle,
  subtitle2,
  sidePanel = false,
  imagePath,
  description,
  alternateRightTitle,
  benefits,
  getDashboardDataRequestAction,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!csm) {
      const fromDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
      const toDate = moment().format('YYYY-MM-DD');
      getDashboardDataRequestAction(fromDate, toDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-2" variant="h1">
        {title}
        <CrownIcon style={{ marginLeft: 7 }} width={15} height={15} color="#FFC611" />
      </Typography>
      {description && (
        <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="educationBody">
          {description}
        </Typography>
      )}
      <Typography className={`mb-3 ${classes.contentOnlyHeader}`} variant="h2" >
        {intl.formatMessage({ id: 'NON.PRO.TITLE' })}
      </Typography>
      <div className={`d-flex ${sidePanel ? 'flex-column' : 'flex-row'}`}>
        {
          imagePath ? <img width="370px" src={require(`../../../../assets/images/${imagePath}`)} alt="non-pro-user" />
            : <img width="370px" src={require('../../../../assets/images/non-pro-user.png')} alt="non-pro-user" />
        }
        <div>
          <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="pdd">
            {alternateRightTitle || intl.formatMessage({ id: 'GENERAL.HOW.TO.USE' })}
          </Typography>
          <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="educationBody">
            {subtitle}
          </Typography>
          <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="educationBody">
            {subtitle2}
          </Typography>
          {
            benefits && benefits.map((item) => (
              <div className="d-flex mb-4">
                <div className={classes.iconContainer}>
                  <CheckIcon fontSize="small" style={{ color: '#fff' }} />
                </div>
                <Typography variant="bodySemiBold">
                  {item}
                </Typography>
              </div>
            ))
          }
          {!benefits
            ? [...Array(5)].map((item, index) => (
              <div className="d-flex mb-4">
                <div className={classes.iconContainer}>
                  <CheckIcon fontSize="small" style={{ color: '#fff' }} />
                </div>
                <Typography variant="bodySemiBold">
                  {intl.formatMessage({ id: `SURVEY.AND.POLLS.NON.PRO.STEP.${index + 1}` })}
                </Typography>
              </div>
            )) : null}
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <Typography color="#0086C2" style={{ fontSize: 15 }} variant="pdd">
          Learn more about PRO features
        </Typography>
        {loading && isFirstLoading ? <div className="pr-5 mr-3"><LoadingSpinner height={40} /></div>
          : error ? <ErrorContainer small reportName="CSM" height={40} error={error} />
            : csm?.has_csm ? (
              <a style={{ marginTop: 'auto' }} target="_blank" href={csm?.calendly_link} rel="noreferrer">
                <CustomButton
                  size="large"
                  variant="contained"
                  fontSize={14}
                  paddingVertical={2}
                  label={(
                    <FormattedMessage
                      id="DASHBOARD.USER.INFO.CARD.BOOK.AN.APPOINTMENT"
                    />
                  )}
                />
              </a>
            ) : null}
      </div>
      <hr className={`${classes.hr} mb-5`} />

    </>
  );
};

const mapStateToProps = (store) => ({
  error: store.dashboard.error,
  loading: store.dashboard.loading,
  isFirstLoading: store.dashboard.isFirstLoading,
  isUserPro: store.common.isUserPro,
  csm: store.dashboard.csm,
});


export default injectIntl(connect(mapStateToProps, actions)(ProUser));

