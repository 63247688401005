import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../../redux/createReducer';
import initialState from './store';

const residentKnowledgeIndicatorReducer = persistReducer(
  { storage, key: 'residentKnowledgeIndicatorReport', whitelist: [] },
  createReducer(initialState, {
    SET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_LOADING_ACTION: (state, { payload }) => Immutable({
      ...state,
      fetchState: payload,
    }),

    SET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_ALL_TIME_ACTION: (state, { payload }) => Immutable({
      ...state,
      allTime: payload,
    }),

    GET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      data: payload.data.data,
      fetchState: 'succeed-request'
    }),

    GET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_FAIL: (state) => Immutable({
      ...state,
      fetchState: 'failed-request'
    }),
  })
);

export default residentKnowledgeIndicatorReducer;
