import React, { forwardRef } from 'react';

import CloseIcon from '@material-ui/icons/Close'; // Import the close icon
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';

import { CustomButton, Typography } from '.';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const CustomAlert = ({
  open,
  setOpen,
  onSubmit,
  onCancel,
  buttonNames = ['Go back anyway', 'Cancel'],
  title,
  description = 'Are you sure you wish to go back? If you do, any edits you made will be lost.',
  hideCloseButton = false,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby='unsaved-changes-alert'
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '600px',
              padding: 1,
            },
          },
          '& .MuiDialogTitle-root': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
          '& .MuiIconButton-root': {
            position: 'absolute',
            top: '8px',
            right: '18px',
          },
        }}
      >
        <DialogTitle>
          {title}
          {!hideCloseButton && (
            <IconButton
              edge='end'
              color='inherit'
              onClick={() => setOpen(false)}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='unsaved-changes-alert'>
            <Typography style={{ textAlign: 'center' }} variant='h2'>
              {description}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '16px', padding: '16px' }}>
          <CustomButton
            onClick={onSubmit}
            variant='contained'
            label={buttonNames[0]}
            size='sm'
          />
          {onCancel && (
            <CustomButton
              onClick={onCancel}
              variant='outlined'
              label={buttonNames[1]}
              size='sm'
            />
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default CustomAlert;
