import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { injectIntl } from 'react-intl';
import { Typography } from '../../../components';
import { CorrectAnswerIcon, WrongAnswerIcon } from '../../../../assets/icons';
import { campaignCdnAddress } from '../apis';


const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: '20px',
    marginBottom: '10px',
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  images: {
    width: '170px',
    height: '120px',
    margin: 'auto',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  sum: {
    marginRight: '5px',
    marginLeft: '5px',
    fontWeight: 'bold'
  }
}));

const PictureTapDialogContent = ({
  intl,
  data
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div
        role="img"
        className={classes.images}
        style={{ backgroundImage: `url(${campaignCdnAddress}/${data.path})` }}
      />
      <Typography className="d-flex" variant="body">
        {intl.formatMessage({ id: 'REPORT.CAMPAIGN.DETAIL.PARTICIPATION' },
          {
            sum: <span className={classes.sum}>{data.sum}</span>,
          })}
      </Typography>
      <Typography className="d-flex align-items-center" variant="body">
        {
          data.is_correct
            ? <CorrectAnswerIcon className="mr-2" />
            : <WrongAnswerIcon className="mr-2" />
        }
        {
        data.is_correct
          ? intl.formatMessage({ id: 'REPORT.CAMPAIGN.DETAIL.CORRECT_OPTION' })
          : intl.formatMessage({ id: 'REPORT.CAMPAIGN.DETAIL.INCORRECT_OPTION' })
        }
      </Typography>
    </div>
  );
};

export default injectIntl(PictureTapDialogContent);
