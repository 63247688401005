const actionTypes = {
  // Page Views report actionTypes for setting loading status
  SET_PAGE_VIEWS_REPORT_LOADING_ACTION: 'SET_PAGE_VIEWS_REPORT_LOADING_ACTION',

  // Page Views report actionTypes for fetching report data
  GET_PAGE_VIEWS_REPORT_REQUEST: 'GET_PAGE_VIEWS_REPORT_REQUEST',
  GET_PAGE_VIEWS_REPORT_RECEIVE: 'GET_PAGE_VIEWS_REPORT_RECEIVE',
  GET_PAGE_VIEWS_REPORT_FAIL: 'GET_PAGE_VIEWS_REPORT_FAIL',

  // Set page type
  SET_PAGE_TYPE: 'SET_PAGE_TYPE',

  // Set Report type
  SET_REPORT_TYPE: 'SET_REPORT_TYPE'
};

export default actionTypes;
