import React from 'react';
import { injectIntl } from 'react-intl';

import { Typography } from '..';
import { Portlet } from '../../../../_metronic/partials/content/Portlet';

const ErrorContainer = ({
  intl, height, error, portlet, reportName, small = false
}) => {
  let content = (
    <div style={{ height }} className="d-flex justify-content-center align-items-center">
      <div className="text-center">
        <Typography variant={small ? 'h2' : 'h1'}>
          {intl.formatMessage({ id: 'GENERAL.ERROR.CONTENT' }, {
            reportName
          })}
        </Typography>
        <Typography variant={small ? 'h2' : 'h1'}>
          {intl.formatMessage({ id: 'GENERAL.ERROR.TITLE' })}
          :
          {' '}
          {error}
        </Typography>
      </div>
    </div>
  );

  if (portlet) {
    content = (
      <Portlet>
        <div style={{ height }} className="d-flex justify-content-center align-items-center">
          <div className="text-center">
            <Typography variant={small ? 'h2' : 'h1'}>
              {intl.formatMessage({ id: 'GENERAL.ERROR.CONTENT' }, {
                reportName
              })}
            </Typography>
            <Typography variant={small ? 'h2' : 'h1'}>
              {intl.formatMessage({ id: 'GENERAL.ERROR.TITLE' })}
              :
              {' '}
              {error}
            </Typography>
          </div>
        </div>
      </Portlet>
    );
  }

  return <>{content}</>;
};

export default injectIntl(ErrorContainer);
