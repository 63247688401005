import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';

import { Tooltip as BSTooltip } from '@material-ui/core/';
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { makeStyles, styled } from '@material-ui/core/styles';
import { report } from 'process';
import {
  Portlet,
  PortletHeader,
  PortletBody,
  PortletHeaderToolbar
} from '../../../../../_metronic/partials/content/Portlet';
import CampaignItem from '../../../Campaign/components/CampaignItem';
import { DoughnutChart } from '../../components';

import { ListLoading, SummaryOtherLoading } from '../../../components/loading';
import { DropdownMenu } from '../../../Education/EducationalContent/components';
import { EducationModal } from '.';
import ColorLegend from '../../AnnualSurvey/components/ColorLegend';

import { Switch, Typography } from '../../../components';

const useStyles = makeStyles((theme) => ({
  container: {
    // width: '1300px',
    // margin: '0 auto',
    height: '100%',
  },
  // .group
  group: {
    display: 'flex',
    gap: '10px'
  },
  // .groupItem
  groupItem: {
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid #E7ECEF',
    borderRadius: 9,
    ChangeTheColorBorderRadius: '8px',
    padding: '12px',
    // width: "25%",
    width: 235,
    // maxWidth: "200px",
    MightNeedFullWidthSoTheItemsDontGetTooBigOnLargeScreenColor: '#63666A',
    ChangeTheColorFontFamily: 'sans-serif'
  },
  // .group__title
  groupTitle: {
    fontWeight: '600'
  },
  // .group__number
  groupNumber: {
    fontSize: '20px',
    fontWeight: '800',
    marginTop: '6px',
    marginBottom: '6px'
  },
  // .group__change
  groupChange: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: '600'
  },
  // .group__arrow
  groupArrow: {
    marginRight: '6px'
  },
  /// * Item shows increase */
  groupChangePositive: {
    color: 'green'
  },
  /// * Item shows decrease */
  groupChangeNegativet: {
    color: 'red'
  },
  // .group__change--negative .group__arrow
  groupChangeNegativeGroupArrow: {
    transform: 'rotate(180deg)'
  },
  /// * Blue item */
  groupItemHighlighted: {
    backgroundColor: '#2A95FF',
    color: 'white'
  },
  lists: {
    display: 'flex',
    flexDirection: 'row',
    gap: '80px',
    fontSize: '14px',
  },
  list__heading: {
    color: '#63666A', /* Change the color */
    fontFamily: 'sans-serif',
    textTransform: 'uppercase',
    fontSize: '0.8rem', /* Change as needed */
    marginBottom: '16px',
    fontSize: '14px',
  },
  list__rows: {
    display: 'flex',
    flexDirection: 'column',
    color: '#63666A', /* Change the color */
    fontFamily: 'sans-serif'
  },
  list__row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    height: '2rem'
  },
  list__number: {
    marginRight: '6px',
    fontWeight: 600 /* Change as needed */
  },
  list__arrow: {
    marginRight: 6
  },
  list__change: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24
  },

  /* Item shows increase */
  list__changePositive: {
    color: '#409A5F'
  },
  /* Item shows decrease */
  list__changeNegative: {
    color: '#FF1E1E'
  },
  list__changeNegativeList__arrow: {
    transform: 'rotate(180deg)'
  },

  /* Legend for the circle graph */
  list__legend: {
    backgroundColor: 'grey',
    color: 'white',
    borderRadius: 4,
    padding: '4px 10px',
    marginRight: 20
  },
  list__legend1: {
    backgroundColor: '#2A95FF' /* Colors correspond to sections of the graph */
  },
  list__legend2: {
    backgroundColor: '#A663FF' /* Colors correspond to sections of the graph */
  },
  list__legend3: {
    backgroundColor: '#97CCFF' /* Colors correspond to sections of the graph */
  },
  list__legend4: {
    backgroundColor: '#00C192' /* Colors correspond to sections of the graph */
  },
  list__legend5: {
    backgroundColor: '#FFCD5B' /* Colors correspond to sections of the graph */
  },
  list__topic: {
    fontWeight: 600,
    fontSize: 14,
  },
  legendContainer: {
    position: 'relative',
    left: 0,
    right: 0,
    // marginLeft: 'auto',
    // marginRight: 'auto',
    width: 100,
    display: 'flex',
    gap: '16px',
    marginTop: 25,
  },
  legend: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontFamily: 'sans-serif'
  },
  legendCircle: {
    content: "''",
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    border: '2px solid rgba(118, 203, 196, 1)'
  },
  legendLine: {
    position: 'relative',
    top: '0px',
    width: '20px',
    margin: '0 6px 0 0',
    height: '2px',
    left: '1px'
  },
  legendDottedLegendLine: {

    borderTop: '2px dashed rgba(118, 203, 196, 1)'
  },
  legendDottedLegendCircle: {
    content: "''",
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    border: '2px solid rgba(118, 203, 196, 1)'
  },
  legendSolidLegendLine: {
    borderTop: '2px solid rgba(42, 149, 255, 1)'
  },
  legendSolidLegendCircle: {
    content: "''",
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    border: '2px solid rgba(42, 149, 255, 1)'
  },
  chart: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  chartTop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  chartTotal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  chartLegend: {
    display: 'flex',
    flexDirection: 'row'
  },
  chart__legendchartTop: {
    gap: '16px'
  },
  chartLegend: {
    gap: '16px'
  },
  chartMain: {
    position: 'relative',
    height: 'calc( 40000px / 200 )'
  },
  // .chart__grid
  chartGrid: {
    display: 'flex',
    flexDirection: 'column-reverse',
    height: '100%'
  },
  // .chart__increment
  chartIncrement: {
    display: 'flex',
    flexDirection: 'row',
    height: '25%',
    '&:first-of-type': {
      top: '0'
    },
    '&:last-of-type': {
      '& $chartLine': {
        '&::after': {
          display: 'none'
        }
      },
    },
  },
  // .chart__y
  chartY: {
    width: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative'
  },
  // .chart__yNumber
  chartYNumber: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '20px',
    top: '10px'
  },

  // .chart__increment:first-of-type .chart__yNumber
  chartIncrementFirstOfType: {
    top: '0'
  },
  /// * Removes top grey line without a Y axis number */
  // chart__increment:last-of-type .chart__line:after
  // removestopgreylinewithoutayaxisnumberChartIncrementLastOfTypeChartLineAfterElement: {
  //   display: "none"
  // },
  // .chart__line
  chartLine: {
    width: '100%',
    position: 'relative',
    '&::after': {
      content: "''",
      position: 'absolute',
      height: '1px',
      width: '100%',
      backgroundColor: 'lightgrey'
    }
  },
  // .chart__line::after
  chartLineAfter: {
    content: "''",
    position: 'absolute',
    height: '1px',
    width: '100%',
    backgroundColor: 'lightgrey'
  },
  /// * Chart data layer */
  // .chart__data
  chartData: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    display: 'flex'
  },
  chartDataY: {
    width: '50px'
  },
  chartDataItems: {
    width: '100%',
    display: 'flex',
    gap: '16px',
    justifyContent: 'space-between',
  },
  chartDataItemGraphWrap: {
    height: 'calc( 100% - 20px )'
  },
  chartDataItem: {
    maxWidth: 'calc( 100% / 12 )',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  chartDataItemText: {
    height: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  graph: {
    display: 'inline-flex',
    flexDirection: 'column',
    height: '100%',
  },
  graph__2items: {
    marginRight: 20,
    marginLeft: 20,
  },
  graph__wrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  graph__item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
    margin: '0 2px',
    '&:first-of-type': {
      marginLeft: 0
    },
  },
  graph__number: {
    marginBottom: 6,
    fontSize: 12,
    fontSeight: 600,
    fontFamily: 'sans-serif'
  },
  graph__pillar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    maxWidth: 21,
    maxHeight: '100%',
    width: '100%',
    borderRadius: 13,
    minHeight: 20,
  },
  graph__ball: {
    display: 'block',
    position: 'relative',
    top: 1,
    height: 25,
    width: 25,
    backgroundColor: 'white',
    borderRadius: '50%'
  },
  graph__pillar_lastYear: {
    backgroundColor: '#79cbc4'
  },
  graph__pillar_thisYear: {
    backgroundColor: '#3398fc'
  },
  chartTopNumber: {
    fontWeight: '600',
    fontSize: 16,
    color: '#404040',
    letterSpacing: '0.04em'
  },
  groupChange: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: '500',
    fontSize: 24,
  },
  groupArrow: {
    marginRight: '6px'
  },
  groupChangePositive: {
    color: '#409A5F'
  },
  groupChangeNegativet: {
    color: 'red'
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    width: '55%',
    justifyContent: 'space-between',
  }

}));

const Users = ({
  intl,
  reportData,
  isLoading,
  csm,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <BSTooltip arrow classes={classes} {...props} />;
  }

  let labels;
  let totalTop5;
  let top5Values;

  /**
* Menu and menuitem functions.
*/
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const descriptionHandler = () => {
    setOpen(true);
  };

  const viewDetailsHandler = (e) => {
    history.push('/report/user');
    // setOpen(true);
  };

  const viewNewSubscribersHandler = (e) => {
    history.push('/report/new-subscribers');
  };

  const closeModal = () => {
    setOpen(false);
    handleClose();
  };

  /**
  * Data for dropdown menu items.
  */
  const menuItemData = [
    {
      id: 1,
      name: 'Description',
      method: descriptionHandler,
      disabled: false
    },
    // {
    //   id: 2,
    //   name: 'View User Report',
    //   method: viewDetailsHandler,
    //   disabled: false
    // },
    {
      id: 2,
      name: 'View New Subscribers Report',
      method: viewNewSubscribersHandler,
      disabled: false
    },

  ];

  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const usersLabels = [];
  let userTotal = 0;
  let lastYearUserTotal = 0;
  let percentageDiff;
  let userData = [];
  let gridNumbers;
  let highestValue;
  let thisMonthValue;
  let lastYearValue;

  if (reportData.user && reportData.user.length && !isLoading) {
    for (let i = 0; i < reportData.user.length; i++) {
      if (i <= 11) {
        userTotal += Number(reportData.user[i].value);
        usersLabels.push(`${month[reportData.user[i].month - 1]}`);
      } else {
        lastYearUserTotal += Number(reportData.user[i].value);
      }
    }
    function calculatePercentageDifference(oldValue, newValue) {
      if (oldValue === 0) {
        return 100; // Avoid division by zero
      }

      const percentageDifference = Math.abs((newValue - oldValue) / ((oldValue + newValue) / 2)) * 100;
      return percentageDifference;
    }

    function generateGridNumbers(number) {
      const result = [];

      // Add 10000, 20000,and 30000
      // result.push(10000, 20000, 30000);

      for (let i = 0; i <= number; i += 10000) {
        result.push(i);
      }
      if (result.length >= 3) {
        result.push(result[result.length - 1] + 10000);
      }
      if (Math.round((100 * number) / result[result.length - 1]) >= 75) {
        result.push(result[result.length - 1] + 10000);
      }

      return result;
    }

    highestValue = reportData.user.reduce((max, item) => (item.value > max ? item.value : max), reportData.user[0].value);
    gridNumbers = generateGridNumbers(Number(highestValue));

    thisMonthValue = Number(reportData.user[0].value);
    lastYearValue = Number(reportData.user[12].value);

    percentageDiff = calculatePercentageDifference(lastYearValue, thisMonthValue);
    userData = [...reportData.user].reverse();
  }

  ChartJS.elements.Rectangle.prototype.draw = function ReDraw() {
    const { ctx } = this._chart;
    const vm = this._view;
    let left; let right; let top; let bottom; let signX; let signY; let borderSkipped;
    let { borderWidth } = vm;

    // If radius is less than 0 or is large enough to cause drawing errors a max
    // radius is imposed. If cornerRadius is not defined set it to 0.
    let { cornerRadius } = this._chart.config.options;

    if (cornerRadius < 0) { cornerRadius = 0; }
    if (typeof cornerRadius === 'undefined') { cornerRadius = 0; }

    if (!vm.horizontal) {
      left = vm.x - vm.width / 2;
      right = vm.x + vm.width / 2;
      top = vm.y;
      bottom = vm.base;
      signX = 1;
      signY = bottom > top ? 1 : -1;
      borderSkipped = vm.borderSkipped || 'bottom';
    }

    // Canvas doesn't allow us to stroke inside the width so we can
    // adjust the sizes to fit if we're setting a stroke on the line
    if (borderWidth) {
      // borderWidth shold be less than bar width and bar height.
      const barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
      borderWidth = borderWidth > barSize ? barSize : borderWidth;
      const halfStroke = borderWidth / 2;
      // Adjust borderWidth when bar top position is near vm.base(zero).
      const borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
      const borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
      const borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
      const borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
      // not become a vertical line?
      if (borderLeft !== borderRight) {
        top = borderTop;
        bottom = borderBottom;
      }
      // not become a horizontal line?
      if (borderTop !== borderBottom) {
        left = borderLeft;
        right = borderRight;
      }
    }

    ctx.beginPath();
    ctx.fillStyle = vm.backgroundColor;
    ctx.strokeStyle = vm.borderColor;
    ctx.lineWidth = borderWidth;

    // Corner points, from bottom-left to bottom-right clockwise
    // | 1 2 |
    // | 0 3 |
    const corners = [
      [left, bottom],
      [left, top],
      [right, top],
      [right, bottom],
    ];

    // Find first (starting) corner with fallback to 'bottom'
    const borders = ['bottom', 'left', 'top', 'right'];
    let startCorner = borders.indexOf(borderSkipped, 0);
    if (startCorner === -1) {
      startCorner = 0;
    }

    function cornerAt(index) {
      return corners[(startCorner + index) % 4];
    }

    // Draw rectangle from 'startCorner'
    let corner = cornerAt(0);
    ctx.moveTo(corner[0], corner[1]);

    for (let i = 1; i < 4; i++) {
      corner = cornerAt(i);
      let nextCornerId = i + 1;
      if (nextCornerId === 4) {
        nextCornerId = 0;
      }

      const width = corners[2][0] - corners[1][0];
      const height = corners[0][1] - corners[1][1];
      const x = corners[1][0];
      const y = corners[1][1];

      let radius = cornerRadius;

      // Fix radius being too large
      if (radius > Math.abs(height) / 1.5) {
        radius = Math.floor(Math.abs(height) / 1.5);
      }
      if (radius > Math.abs(width) / 1.5) {
        radius = Math.floor(Math.abs(width) / 1.5);
      }

      if (height < 0) {
        // Negative values in a standard bar chart
        const x_tl = x; const x_tr = x + width;
        const y_tl = y + height; const y_tr = y + height;

        const x_bl = x; const x_br = x + width;
        const y_bl = y; const y_br = y;

        // Draw
        ctx.moveTo(x_bl + radius, y_bl);
        ctx.lineTo(x_br - radius, y_br);
        ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius);
        ctx.lineTo(x_tr, y_tr + radius);
        ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr);
        ctx.lineTo(x_tl + radius, y_tl);
        ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius);
        ctx.lineTo(x_bl, y_bl - radius);
        ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);
      } else {
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
      }
    }

    ctx.fill();
    if (borderWidth) {
      ctx.stroke();
    }
  };

  let userGraphData;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cornerRadius: 15,
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        title(tooltipItem, data) {
          return `${moment(tooltipItem[0].xLabel, 'MMM').format('MMMM')} ${tooltipItem[0].datasetIndex > 0 ? userData[tooltipItem[0].index + 11].year : userData[tooltipItem[0].index].year} `;
        },
        label(tooltipItem, data) {
          return tooltipItem.yLabel.toLocaleString();
        }
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          // display: false
        },
        ticks: {
          callback(value, index, ticks) {
            return value.substr(0, 3);
          }
        }
      }],
      yAxes: [{
        gridLines: {
          // display: false
        },
        ticks: {
          callback(value, index, ticks) {
            return value.toLocaleString();
          }
        }
      }]
    }
  };

  if (reportData.user && reportData.user && !isLoading) {
    userGraphData = {
      labels: usersLabels.reverse(),
      datasets: [
        {
          label: 'Last Year',
          data: userData.map((item, index) => { if (index <= 11) { return item.value; } }).filter((notUndefined) => notUndefined !== undefined),
          backgroundColor: '#79cbc4',
          fill: true,
          borderRadius: 15,
        },
        {
          label: 'This Year',
          data: userData.map((item, index) => { if (index >= 12) { return item.value; } }).filter((notUndefined) => notUndefined !== undefined),
          backgroundColor: '#3398fc',
          fill: true,
          borderRadius: 15,
        },

      ],
    };
  }

  return (
    <>
      <Portlet className={classes.container}>
        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start" style={{ justifyContent: 'space-between' }}>
                <div className="row col-xs-12 col-lg-6 align-items-center">
                  <div className="align-items-center pl-3">
                    <Typography variant="h1">
                      Users
                    </Typography>
                  </div>
                </div>
                <DropdownMenu
                  handleClick={handleClick}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  menuItemData={menuItemData}
                />
              </div>
            )}
          />
          <EducationModal
            open={open}
            onClose={() => closeModal()}
            title="Description"
            content={(
              <div>
                {
                  csm && csm.calendly_link && (
                    <Typography variant="body1" style={{ textAlign: 'left' }}>
                      Users are the lifeblood of any communication and education program. These reports give you insights into how your residents are participating in your program. Numbers are for the latest month, which is shown in the upper right corner of this page. The percentage change is a comparison to the same month last year.
                      <br />
                      <br />

                      The #1 way to get more users is to optimize web app placements on your website. To request a free web app optimization audit, contact your
                      <a href={csm.calendly_link} target="_blank" rel="noreferrer"> Customer Success Manager</a>
                      .
                    </Typography>
                  )
                }
              </div>
            )}
          />
        </div>
        <PortletBody>
          {
            isLoading
              ? <SummaryOtherLoading />
              : (
                <div className="row">
                  <div className={classes.chart}>
                    <div className={classes.top}>
                      <div className={classes.chartTop}>
                        <div className={classes.chartTopNumber}>{thisMonthValue?.toLocaleString()}</div>
                        {
                          typeof percentageDiff !== 'undefined' && (
                            <div className={` ml-2 ${classes.groupChange} ${percentageDiff > 0 ? classes.groupChangePositive : classes.groupChangeNegativet}`}>
                              <div className={classes.groupArrow}>{percentageDiff > 0 ? '↑' : '↓'}</div>
                              <div className={classes.group__percentage}>
                                {percentageDiff?.toFixed(2)}
                                %
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-12" style={{ height: 225, marginTop: 10 }}>

                    <Bar options={options} data={userGraphData} />

                  </div>
                  <div className="col-12">
                    <div className={classes.legendContainer}>
                      <ColorLegend thisYear="This Year" lastYear="Last Year" textWidth />
                    </div>
                  </div>
                </div>
              )
          }

        </PortletBody>
      </Portlet>

    </>
  );
};

export default injectIntl(Users);
