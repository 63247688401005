/**
 *
 */
const initialState = {
  fetchState: 'no-request',
  responseMessage: null,
  responseStatus: 'success',
  formLoading: false,
  list: [],
  zones: [],
  prompts: [],
  tab: 0,
  cat: true,
  newMessage: {
    notif_id: null,
    notif_type: 'General',
    send_date: '',
    send_time: '',
    district_id: [],
    check_zone: 'all-zones',
    pickup_type: [],
    zones: [],
    title: '',
    description: '',
    schedule: 'immediately',
  },
};

export default initialState;
