import cts from '../../crud/cts';


export const {
  REJECT, PAST, ONGOING, ACTIVE
} = {
  ONGOING: 'ongoing',
  PAST: 'past',
  REJECT: 'reject',
  ACTIVE: 'active'
};

export const campaignCdnAddress = 'https://cdn.recyclecoach.com/campaigns/';

export const campaignPreviewPrefix = 'http://cdn.recyclecoach.com/mobile-view/?campaign_id=';

export const defaultImageAddress = 'https://cdn.recyclecoach.com/webapp-resources/img/campaign-banner.png';

/**
 * Get all campaigns api
 */
const getAllCampaignApi = ({
  district_id, // * combination of projectId and districtId
  state = ONGOING, // * default
  offset = 0, // default
  length = 100 // default
}) => cts(
  'post',
  'campaigns/get-all',
  {
    // district_id: 'master#$#StandAloneTestDistrict',
    district_id,
    state,
    offset,
    length
  }
);


/**
 * Get Campaign templates
 */
const getCampaignLibraryApi = ({
  district_id, // * combination of projectId and districtId
  state = ACTIVE, // * default
  offset = 0, // default
  length = 100 // default
}) => cts(
  'post',
  'campaigns/get-libraries',
  {
    // district_id: 'master#$#StandAloneTestDistrict',
    district_id,
    state,
    offset,
    length
  }
);


/**
 * Reject campaign
 */
const rejectCampaign = ({
  campaign_id
}) => cts(
  'post',
  'campaigns/reject',
  {
    campaign_id
  }
);


/**
 * Reject template
 */
const rejectTemplate = ({
  campaign_id,
  district_id
}) => cts(
  'post',
  'campaigns/reject-template',
  {
    campaign_id,
    // district_id: 'master#$#StandAloneTestDistrict'
    district_id
  }
);


/**
 * Restore campaign
 */
const restoreCampaign = ({
  campaign_id
}) => cts(
  'post',
  'campaigns/pull-back',
  {
    campaign_id
  }
);


/**
 * Restore template
 */
const restoreTemplate = ({
  campaign_id,
  district_id
}) => cts(
  'post',
  'campaigns/pull-back-template',
  {
    campaign_id,
    // district_id: 'master#$#StandAloneTestDistrict'
    district_id
  }
);


/**
 * Switch Campaign
 */
const switchCampaignApi = ({
  campaign_id,
  template_id
}) => cts(
  'post',
  'campaigns/switch-out',
  {
    campaign_id,
    template_id
  }
);


const createCampaignApi = ({
  district_id,
  template_id,
  publish_hour,
  publish_dt,
  end_dt
}) => cts(
  'post',
  'campaigns/use-template',
  {
    district_id,
    template_id,
    publish_hour,
    publish_dt,
    end_dt
  }
);


export {
  getAllCampaignApi,
  getCampaignLibraryApi,
  rejectCampaign,
  restoreCampaign,
  restoreTemplate,
  rejectTemplate,
  switchCampaignApi,
  createCampaignApi
};
