const actionTypes = {
  // Auth actionTypes for setting loading status
  SET_LOADING: 'SET_LOADING',
  RESET_LOADING: 'RESET_LOADING',

  // Login actionTypes
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGINـRECEIVE: 'LOGINـRECEIVE',
  LOGINـFAIL: 'LOGINـFAIL',

  UPDATE_USER_INFO_MANAGES_SCHEDULES: 'UPDATE_USER_INFO_MANAGES_SCHEDULES',

};

export default actionTypes;
