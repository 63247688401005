import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../redux/createReducer';
import initialState from './store';

const campaignReducer = persistReducer(
  { storage, key: 'campaign', blacklist: ['switchItem', 'district'] },
  createReducer(initialState, {
    SET_DISTRICT: (state, { payload }) => Immutable({
      ...state,
      district: payload
    }),
    SET_LOADING: (state) => Immutable({
      ...state,
      fetchState: 'send-request',
    }),
    RESET_LOADING: (state) => Immutable({
      ...state,
      fetchState: 'no-request'
    }),
    RESET_MESSAGE: (state) => Immutable({
      ...state,
      responseMessage: null
    }),
    SET_TAB: (state, { payload }) => Immutable({
      ...state,
      tab: payload.tab
    }),
    SET_SWITCH_ITEM: (state, { payload }) => Immutable({
      ...state,
      switchItem: payload.item
    })
  })
);

export default campaignReducer;
