import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import axios from 'axios';
import moment from 'moment';

import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { toAbsoluteUrl } from '../../../../../../_metronic';
import { LoadingSpinner, ErrorContainer } from '../../../../components/loading';

import {
  Opacity, Typography, CustomButton
} from '../../../../components';

import { changeCurrentMonthsIndexHandler } from '../../../../../utils';
import * as actions from '../redux/actions';

import { EducationModal } from '../../components/index';



const useStyles = makeStyles(() => ({
  packImage: {
    display: 'flex',
    margin: 'auto'
  },
  downloadButton: {
    width: '100%',
    height: 48,
    left: 912,
    top: 464,
    background: '#0086C2',
    borderRadius: 4,
    color: '#fff',
    fontWeight: '700'
  },
  feedbackContainer: {
    marginTop: 25,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  downloadPacketContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textAlign: 'center'
  },
  textarea: {
    resize: 'none',
    width: '95%',
    padding: 5,
    height: 100,
    marginTop: 15,
    borderRadius: 5,
    '&:focus': {
      outline: 'none'
    }
  },

}));


const Main = ({
  intl,
  currentQuestionIndex,
  setCurrentQuestionAction,
  feedbackRequest,
}) => {
  const classes = useStyles();
  const [recyclingForKids, setRecyclingForKids] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const endpoint =
    `https://cdn.recyclecoach.com/resources/kids-recycle-kit/education-recycling-for-kids.json?v=${Date.now()}`;
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios(endpoint);
        setRecyclingForKids(response.data.items);
        setIsLoading(false);

      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData()


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
* Feedback modal states and functions.
*/


  const [feedbackText, setFeedbackText] = useState('');
  const setFeedbackTextHandler = (e) => {
    setFeedbackText(e.target.value);
  };

  const [isSubmittingAFeedback, setIsSubmittingAFeedback] = useState(false);


  const changeCurrentQuestionIndexHandler = (direction) => {
    setCurrentQuestionAction(
      changeCurrentMonthsIndexHandler(direction, currentQuestionIndex, recyclingForKids.length)
    );
  };

  const feedbackHandler = () => {

    feedbackRequest(recyclingForKids[currentQuestionIndex], feedbackText);
    setIsSubmittingAFeedback(false);
  };

  const downloadPacket = (url) => {
    window.open(url, '_blank').focus();
  }


  return (
    <div>
      {
        isLoading
          ? <LoadingSpinner height={350} />
          : error || recyclingForKids.length === 0 ? (
            <ErrorContainer
              small
              reportName="recyclingForKids"
              height={350}
              error={'error'}
            />
          ) : (
            <Opacity slide>
              <>
                <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="h1">
                  {intl.formatMessage({ id: 'EDUCATION.RECYCLING.FOR.KIDS' })}

                </Typography>
                <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="educationBody">
                  {intl.formatMessage({ id: 'EDUCATION.RECYCLING.FOR.KIDS.SUBTITLE' })}
                </Typography>
              </>
              <Typography className={`mb-4`} variant="h2">
                {recyclingForKids[currentQuestionIndex].title}
              </Typography>

              {/* navigation buttons */}
              <div style={{ transform: 'translateX(-10px)', marginBottom: 10 }} className="d-flex align-items-center mt-2">
                <NavigateBeforeIcon
                  onClick={() => changeCurrentQuestionIndexHandler('backward')}
                  fontSize="large"
                  style={{
                    transition: 'all .3s',
                    cursor: currentQuestionIndex === recyclingForKids.length - 1 ? 'not-allowed' : 'pointer',
                    opacity: currentQuestionIndex === recyclingForKids.length - 1 ? 0.5 : 1,
                    color: '#757575'
                  }}
                  disabled={currentQuestionIndex === recyclingForKids.length - 1}
                />
                <Opacity key={currentQuestionIndex}>
                  <Typography style={{ width: '100%', textAlign: 'center' }} color="#404040" variant="bodySemiBold">
                    {moment(recyclingForKids[currentQuestionIndex].date_from).format('MMM D, YYYY')} - {moment(recyclingForKids[currentQuestionIndex].date_to).format('MMM D, YYYY')}
                  </Typography>
                </Opacity>
                <NavigateNextIcon
                  onClick={() => changeCurrentQuestionIndexHandler('forward')}
                  fontSize="large"
                  style={{
                    transition: 'all .3s',
                    cursor: currentQuestionIndex === 0 ? 'not-allowed' : 'pointer',
                    opacity: currentQuestionIndex === 0 ? 0.5 : 1,
                    color: '#757575'
                  }}
                  disabled={currentQuestionIndex === 0}

                />
              </div>

              <div>
                <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="educationBody">
                  {recyclingForKids[currentQuestionIndex].message}
                </Typography>
                <img
                  alt="Download"
                  src={toAbsoluteUrl('/media/icons/RecyclingForKids.png')}
                  className={`${classes.packImage}`}
                />

                <div className={`${classes.downloadPacketContainer}`}>
                  <div style={{ minWidth: 225 }}>
                    <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="educationBody">
                      Download activity packet for
                    </Typography>
                    <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="h2">
                      Ages 3-5
                    </Typography>
                    <CustomButton
                      variant="contained"
                      label="Download"
                      onClick={() => downloadPacket(recyclingForKids[currentQuestionIndex].pdf_3_5)}
                    />
                  </div>
                  <div style={{ minWidth: 225 }}>
                    <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="educationBody">
                      Download activity packet for
                    </Typography>
                    <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="h2">
                      Ages 6-8
                    </Typography>
                    <CustomButton
                      variant="contained"
                      label="Download"
                      onClick={() => downloadPacket(recyclingForKids[currentQuestionIndex].pdf_6_8)}
                    />
                  </div>
                  <div style={{ minWidth: 225 }}>
                    <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="educationBody">
                      Download activity packet for
                    </Typography>
                    <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="h2">
                      Ages 9-12
                    </Typography>
                    <CustomButton
                      variant="contained"
                      label="Download"
                      onClick={() => downloadPacket(recyclingForKids[currentQuestionIndex].pdf_9_12)}
                    />

                  </div>
                </div>

                <div className={`${classes.feedbackContainer}`}>
                  <p onClick={() => {
                    setIsSubmittingAFeedback(true)
                  }}>
                    <Typography color={process.env.REACT_APP_COLOR_PRIMARY} className="mb-4" variant="educationBody">
                      Provide Feedback
                    </Typography>
                  </p>
                </div>
                {/**
             * Submit a feedback modal.
             */}
                <EducationModal
                  open={isSubmittingAFeedback}
                  onClose={() => setIsSubmittingAFeedback()}
                  title={recyclingForKids[currentQuestionIndex].title}
                  content={<textarea onChange={setFeedbackTextHandler} className={classes.textarea} />}
                  buttonApply={intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}
                  disabled={!feedbackText.length}
                  onClickHandler={() => feedbackHandler()}
                />
              </div>
            </Opacity>
          )
      }

    </div>
  );
};

const mapStateToProps = (store) => ({
  currentQuestionIndex: store.RecyclingForKids.currentRFKQuestionIndex,
});

export default injectIntl(connect(mapStateToProps, actions)(Main));
