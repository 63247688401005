import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { Portlet, PortletBody, PortletHeader } from '../../../../_metronic/partials/content/Portlet';
import {
  Typography, IconButton, InfoTooltip, Dialog, AutoComplete
} from '../../components';
import { PlusIcon, CollapseIcon, ExpandIcon } from '../../../assets/icons';
import CategoryMenu from './CategoryMenu';
import CategoryMaterials from './CategoryMaterials';
import CategoryDepots from './CategoryDepots';
import CategoryEvents from './CategoryEvents';
import CategoryDrawerLinks from './CategoryDrawerLinks';
import CategoryDescription from './CategoryDescription';
import * as actions from '../redux/actions';

const useStyles = makeStyles(() => ({
  drawerLink: {
    borderBottom: 'none !important',
  },
}));

const Category = ({
  intl,
  data,
  depotList,
  eventList,
  locality,
  assignDepotRequest,
  assignEventRequest,
}) => {
  const classes = useStyles();
  const [drawerLinkIsOpen, setDrawerLinkIsOpen] = useState(false);
  const [newDepot, setNewDepot] = useState('');
  const [newEvent, setNewEvent] = useState('');
  const [selectedMaterials, setSelectedMaterials] = useState([]);

  return (
    <Portlet className="mr-4">
      <PortletHeader
        toolbar={(
          <div className="w-100 d-flex justify-content-between align-items-center">
            <Typography variant="h1">
              {data.mat_cat_admin_nm}
            </Typography>
            <CategoryMenu
              data={data}
              selectedMaterials={selectedMaterials}
              setSelectedMaterials={setSelectedMaterials}
            />
          </div>
        )}
      />
      <PortletBody className="pt-0 pr-0">
        <CategoryMaterials
          materials={data.mats}
          selectedMaterials={selectedMaterials}
          setSelectedMaterials={setSelectedMaterials}
        />
      </PortletBody>

      <PortletHeader
        toolbar={(
          <div className="w-100 d-flex justify-content-between align-items-center">
            <Typography variant="h1">
              {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.DEPOT.TITLE' })}
              <InfoTooltip tooltip={intl.formatMessage({ id: 'CATEGORY.CATEGORIES.DEPOT.TOOLTIP' })} />
            </Typography>
            <Dialog
              disableBackdropClick={false}
              button={(
                <IconButton>
                  <PlusIcon />
                </IconButton>
              )}
              title={(
                <Typography variant="h1">
                  {intl.formatMessage({ id: 'GENERAL.ADD' })}
                </Typography>
              )}
              content={(
                <>
                  <Typography className="mb-4" variant="body">
                    {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.ADD_TEXT' })}
                  </Typography>
                  <AutoComplete
                    className="mb-3"
                    options={depotList}
                    value={newDepot}
                    onChange={setNewDepot}
                    optionTitle="drop_depot_admin_nm"
                    optionId="drop_depot_id"
                  />
                </>
              )}
              handleSubmit={() => {
                assignDepotRequest({
                  locality: locality.cms_locality_id,
                  category: data.mat_cat_id,
                  depot: newDepot,
                });
              }}
            />
          </div>
        )}
      />
      <PortletBody className="pt-0 pr-0">
        <CategoryDepots
          depots={data.depots}
          category={data.mat_cat_id}
        />
      </PortletBody>

      <PortletHeader
        toolbar={(
          <div className="w-100 d-flex justify-content-between align-items-center">
            <Typography variant="h1">
              {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.EVENTS.TITLE' })}
              <InfoTooltip tooltip={intl.formatMessage({ id: 'CATEGORY.CATEGORIES.EVENTS.TOOLTIP' })} />
            </Typography>
            <Dialog
              disableBackdropClick={false}
              button={(
                <IconButton>
                  <PlusIcon />
                </IconButton>
              )}
              title={(
                <Typography variant="h1">
                  {intl.formatMessage({ id: 'GENERAL.ADD' })}
                </Typography>
              )}
              content={(
                <>
                  <Typography className="mb-4" variant="body">
                    {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.ADD_TEXT' })}
                  </Typography>
                  <AutoComplete
                    className="mb-3"
                    options={eventList}
                    value={newEvent}
                    onChange={setNewEvent}
                    optionTitle="event_admin_nm"
                    optionId="drop_event_id"
                  />
                </>
              )}
              handleSubmit={() => {
                assignEventRequest({
                  locality: locality.cms_locality_id,
                  category: data.mat_cat_id,
                  event: newEvent,
                });
              }}
            />
          </div>
        )}
      />
      <PortletBody className="pt-0 pr-0">
        <CategoryEvents
          events={data.events}
          category={data.mat_cat_id}
        />
      </PortletBody>

      <PortletHeader
        toolbar={(
          <div className="w-100 d-flex justify-content-between align-items-center">
            <Typography variant="h1">
              {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.CATEGORY_DESCRIPTION.TITLE' })}
            </Typography>
          </div>
        )}
      />
      <PortletBody className="pt-0">
        <CategoryDescription
          data={data}
        />
      </PortletBody>

      <PortletHeader
        className={!drawerLinkIsOpen ? classes.drawerLink : null}
        toolbar={(
          <div className="w-100 d-flex justify-content-between align-items-center">
            <Typography variant="h1">
              {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.DRAWER_LINKS.TITLE' })}
              <InfoTooltip tooltip={intl.formatMessage({ id: 'CATEGORY.CATEGORIES.DRAWER_LINKS.TOOLTIP' })} />
            </Typography>
            {
              data.drawers && data.drawers.length !== 0 && (
                <IconButton onClick={() => setDrawerLinkIsOpen(!drawerLinkIsOpen)}>
                  {
                    drawerLinkIsOpen ? (
                      <CollapseIcon />
                    ) : (
                      <ExpandIcon />
                    )
                  }
                </IconButton>
              )
            }
          </div>
        )}
      />
      <PortletBody className="pt-0">
        {
          drawerLinkIsOpen && (
            <CategoryDrawerLinks drawerLinks={data.drawers} />
          )
        }
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  depotList: store.category.depotList,
  eventList: store.category.eventList,
  locality: store.common.locality,
});

export default injectIntl(connect(mapStateToProps, actions)(Category));
