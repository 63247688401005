import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Portlet,
  PortletHeader,
  PortletBody,
  PortletHeaderToolbar
} from '../../../../../_metronic/partials/content/Portlet';

import Backdrop from '@material-ui/core/Backdrop';


import { makeStyles } from '@material-ui/core/styles';


import { Switch, Typography, CustomButton } from '../../../components';

import LockIcon from '@material-ui/icons/Lock';




const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    // marginLeft: 72,
  },
  smallContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '10px',
    width: '100%',
    marginLeft: 72,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    position: "absolute",
  },
  root: {
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
    borderRadius: '10px',
    backdropFilter: 'blur(3px)',
    top: 25,
  },
  button: {
    width: '100%',
  },
  displayMoreText: {
    width: 500,
    textAlign: 'center',
  },
  TurnOnButton: {
    width: 400
  },
  lockContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  lockContainerSmall: {
    width: 143,
  }

}));

const ProFeaturesOverlay = ({
  intl,
  displayMore,
  csm,
}) => {
  const classes = useStyles();

  return (
    <Backdrop
      className={classes.backdrop}
      classes={{
        root: classes.root
      }}
      open={open}>
      <div className={displayMore ? classes.container : classes.smallContainer}
      >
        <div className={`${classes.lockContainer} ${displayMore ? '' : classes.lockContainerSmall}`}>
          <div>
            <LockIcon
              style={{ color: '#67ccc5' }}
              fontSize="large"
            />
          </div>
          <div>
            <Typography variant="h1">
              {displayMore ? 'Sorry, this is a PRO feature!' : 'PRO Feature '}
            </Typography>
          </div>
        </div>

        {
          displayMore && (
            <div className={classes.displayMoreText}>
              <Typography variant="body">
                Empower your residents to recycle better with Recycle Coach PRO. This upgraded package features a suite of enhanced education modules and featured lessons to deepen your community’s recycling know-how, from contamination basics to problem materials. You’ll also get access to our interactive swipe game, Recycle RIGHT!
              </Typography>
            </div>
          )
        }
        <div className={`${displayMore ? classes.TurnOnButton : ''}`}>
          <CustomButton
            paddingVertical={4}
            className={`${classes.button}`}
            variant={'contained'}
            label={'Book a demo'}
            onClick={() => {
              if (csm?.calendly_link) {
                window.open(csm?.calendly_link, "_blank")
              }
            }} />
          {
            displayMore && (
              <CustomButton
                paddingVertical={4}
                className={classes.button}
                variant={'transparent'}
                label={'See PRO Package details'}
                onClick={() => { window.open('https://recyclingcalendars-my.sharepoint.com/:b:/g/personal/chooper_recyclecoach_com/EdtsN8jxkTVKlRGkTslfHZYBpSqxhc6vzn41y2VjGgYKMg?e=nJfEv9', "_blank") }}
              />
            )
          }
        </div>
      </div>

    </Backdrop>
  );
};

export default injectIntl(ProFeaturesOverlay);
