import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../redux/createReducer';
import initialState from './store';

const scheduleReducer = persistReducer(
  { storage, key: 'schedule', whitelist: [] },
  createReducer(initialState, {
    SET_SCHEDULE_LOADING_ACTION: (state, { payload }) => Immutable({
      ...state,
      fetchState: payload,
      responseMessage: initialState.responseMessage,
    }),

    GET_SCHEDULE_RECEIVE_ACTION: (state, { payload }) => Immutable({
      ...state,
      data: payload.data.DATA || [],
      years: payload.years || [],
      fetchState: 'succeed-request',
    }),
    GET_SCHEDULE_FAIL_ACTION: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data.message,
      responseStatus: 'error',
    }),
    GET_ZONE_SCHEDULE_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      zones: payload.data.zones,
      prompts: payload.data.prompts,
    }),
    GET_ZONE_SCHEDULE_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data.message,
      responseStatus: 'error',
    }),
    GET_PERPETUAL_SCHEDULES_HOLIDAYS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      district_holidays: payload.data.district_holidays,
    }),
    GET_PERPETUAL_SCHEDULES_HOLIDAYS_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data.message,
      responseStatus: 'error',
    }),
  })
);

export default scheduleReducer;
