import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../../redux/createReducer';
import initialState from './store';

const EducationCommonReducer = persistReducer(
  { storage, key: 'educationCommonReducer', whiteList: [] },
  createReducer(initialState, {
    GET_BLOG_DETAILS_REQUEST: (state) => Immutable({
      ...state,
      blogDetailsLoading: true,
      blogDetailsError: null
    }),
    GET_BLOG_DETAILS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      blogDetails: payload.data,
      blogDetailsLoading: false,
    }),
    GET_BLOG_DETAILS_FAIL: (state, { payload }) => Immutable({
      ...state,
      blogDetailsLoading: false,
      blogDetailsError: payload,
    }),
    BLOG_READ_REQUEST: (state) => Immutable({
      ...state,
      blogReadError: null
    }),
    BLOG_READ_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseStatus: payload.status,
    }),
    BLOG_READ_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseStatus: payload.status,
      blogReadError: payload,
    }),
    BLOG_UNREAD_REQUEST: (state) => Immutable({
      ...state,
      blogReadError: null,
    }),
    BLOG_UNREAD_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseStatus: payload.status,
    }),
    BLOG_UNREAD_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseStatus: payload.status,
      blogReadError: payload,
    }),
    BLOG_FEEDBACK_REQUEST: (state) => Immutable({
      ...state,
      blogFeedbackError: null,
    }),
    BLOG_FEEDBACK_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      blogDetailsLoading: false,
      responseStatus: payload.status,
    }),
    BLOG_FEEDBACK_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseStatus: payload.status,
      blogFeedbackError: payload,
    }),
  })
);

export default EducationCommonReducer;
