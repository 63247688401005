import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Typography, CustomButton } from '../../components';
import {
  Portlet,
  PortletBody,
} from '../../../../_metronic/partials/content/Portlet';
import { toAbsoluteUrl } from '../../../../_metronic';
import CompletedPerpetualSchedulesSetup from './CompletedPerpetualSchedulesSetup';
import * as actions from '../PerpetualSchedules/redux/actions';
import * as sharedActions from '../../redux/actions';
import * as loginActions from '../../Auth/Login/redux/actions';


const useStyles = makeStyles((theme) => ({
  headerText: {
    fontSize: 36,
    fontWeight: 700,
    letterSpacing: '1.8px',
    marginTop: 110,
  },
  subHeaderText: {
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: '0.8px',
    color: 'rgba(64, 64, 64, 0.80)',
    margin: '10px 0',
  },
  image: {
    margin: '72px 0',
  },
  learnMoreButton: {
    width: 138,
    height: 48,
    margin: '100px 0',
  },
  titleText: {
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: '1px',
  },
  subTitleText: {
    color: 'rgba(64, 64, 64, 0.80)',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '0.56px',
  },
  promoContainer: {
    marginBottom: 50,
  },
  buttonContainer: {
    display: 'flex',
    gap: 20,
  },
  setupButton: {
    maxWidth: 360,
    height: 48,
    margin: '100px 0',
  },
}));

const PerpetualSchedules = ({
  intl,
  district,
  municipalities,
  setDistrictAction,
  getPerpetualSchedulesRequest,
  loading,
  handleExitWizard,
  completedSetup,
  managePerpetualSchedulesRequest,
  cs,
  updateUserInfoManagesSchedules,
}) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (district.district_id === '#all-districts#') {
      setDistrictAction(municipalities[0]);
    } else {
      const districts = municipalities.map((municipality) => municipality.district_id);
      getPerpetualSchedulesRequest(districts);
    }
  }, [district]);

  const [displayLearnMore, setDisplayLearnMore] = useState(false);

  const handleLearnMore = () => {
    setDisplayLearnMore(true);
  };

  const handleBeginSetup = () => {
    history.push('/perpetual-schedules');
  };

  const exitWizard = () => {
    handleExitWizard();
  };


const handleDoNotManage = () => {
  managePerpetualSchedulesRequest('false');
  updateUserInfoManagesSchedules('f');
  exitWizard();
};

  return (
    <>
      <div className="d-flex flex-wrap mt-3">
        <Portlet>
          {
            !loading && (
              <PortletBody className="d-flex justify-content-center">
                {
                completedSetup || cs ? (
                  <CompletedPerpetualSchedulesSetup exitWizard={exitWizard}/>
                ) : (
                  <>
                    {
                  displayLearnMore ? (
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row">
                        <div style={{ width: '80%' }}>
                          <div className={classes.promoContainer}>
                            <Typography variant="h1" className={classes.titleText}>
                              {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES_WHAT' })}
                            </Typography>
                            <Typography variant="body" className={classes.subTitleText}>
                              {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES_WHAT_CONTENT' })}
                            </Typography>
                          </div>
                          <div className={classes.promoContainer}>
                            <Typography variant="h1" className={classes.titleText}>
                              {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES_WHY' })}
                            </Typography>
                            <Typography variant="body" className={classes.subTitleText}>
                              {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES_WHY_CONTENT' })}
                            </Typography>
                          </div>
                          <div className={classes.promoContainer}>
                            <Typography variant="h1" className={classes.titleText}>
                              {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES_ARE_ALL_SCHEDULES' })}
                            </Typography>
                            <Typography variant="body" className={classes.subTitleText}>
                              {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES_ARE_ALL_SCHEDULES_CONTENT' })}
                            </Typography>
                          </div>
                        </div>
                        <img
                          alt="Perpetual Schedules"
                          width={341}
                          height={341}
                          src={toAbsoluteUrl('/media/icons/PerpetualSchedules/Questions-pana.svg')}
                        />
                      </div>

                      <div>
                        <Typography variant="h1" className={classes.titleText}>
                          {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES_HOW' })}
                        </Typography>
                        <Typography variant="body" className={`${classes.subTitleText} mb-3`}>
                          {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES_HOW_CONTENT.1' })}
                        </Typography>
                        <Typography variant="body" className={`${classes.subTitleText} mb-3`}>
                          {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES_HOW_CONTENT.2' })}
                        </Typography>
                        <Typography variant="body" className={`${classes.subTitleText} mb-3`}>
                          {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES_HOW_CONTENT.3' })}
                        </Typography>
                        <Typography variant="body" className={`${classes.subTitleText}`}>
                          {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES_HOW_CONTENT.4' })}
                        </Typography>
                      </div>
                      <div className={classes.buttonContainer}>
                        <CustomButton
                          variant="contained"
                          label={(
                            <Typography variant="bodySemiBold" color="white">
                              {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES.SETUP' })}
                            </Typography>
                        )}
                          onClick={() => handleBeginSetup()}
                          className={classes.setupButton}
                        />
                        <CustomButton
                          variant="outlined"
                          label={(
                            <Typography variant="bodySemiBold" color={process.env.REACT_APP_COLOR_PRIMARY}>
                              {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES.I.DO.NOT.MANAGE' })}
                            </Typography>
                        )}
                          onClick={() => handleDoNotManage()}
                          className={classes.setupButton}
                        />
                      </div>

                    </div>
                  ) : (
                    <div className="d-flex justify-content-between align-items-center flex-column">
                      <Typography variant="h1" className={classes.headerText}>
                        New
                      </Typography>
                      <Typography variant="body" className={classes.subHeaderText}>
                        Perpetual Schedules are here!
                      </Typography>
                      <img
                        alt="Perpetual Schedules"
                        width={400}
                        height={400}
                        src={toAbsoluteUrl('/media/icons/PerpetualSchedules/A-whole year-pana.svg')}
                      />
                      <CustomButton
                        variant="contained"
                        label={(
                          <Typography variant="h2" color="white">
                            Learn more
                          </Typography>
                        )}
                        onClick={() => handleLearnMore()}
                        className={classes.learnMoreButton}
                      />
                    </div>
                  )
                }
                  </>
                )
              }
              </PortletBody>
            )
          }

        </Portlet>
      </div>
    </>
  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  municipalities: store.common.municipalities,
  loading: store.perpetualSchedules.loading,
  completedSetup: store.perpetualSchedules.completedSetup,
});

export default injectIntl(connect(mapStateToProps, { ...actions, ...sharedActions, ...loginActions })(PerpetualSchedules));
