import React from 'react';

const ExpandIcon = ({
  color,
  className,
}) => (
  <svg className={`prefix__bi prefix__bi-chevron-down ${className}`} width="1em" height="1em" viewBox="0 0 16 16" fill={color}>
    <path
      fillRule="evenodd"
      d="M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z"
    />
  </svg>
);

export default ExpandIcon;
