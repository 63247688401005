import React from 'react';
import {
  Document, Page, Text, View, StyleSheet, Image
} from '@react-pdf/renderer';
import { addCommasToNumber } from '../../../../utils';
import { toAbsoluteUrl } from '../../../../../_metronic';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    paddingVertical: 5
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  summaryHead: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 10,
    marginBottom: 0,
    padding: 10,
  },
  headerText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'black',
  },
  subTitleText: {
    fontSize: 12,
    color: 'black'
  },
  marginLeft: {
    marginLeft: 15
  },
  h1: {
    fontSize: '22pt'
  },
  h2: {
    fontSize: '20pt'
  },
  h3: {
    fontSize: '18pt'
  },
  h4: {
    fontSize: '18pt'
  },
  h5: {
    fontSize: '16pt'
  },
  h6: {
    fontSize: '14pt'
  },
  body: {
    fontSize: '12pt'
  },
  circle: {
    width: '10pt',
    height: '10pt',
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY,
    borderRadius: '100pt',
    marginRight: '10pt',
  },
  image: {
    width: '110px',
    height: '40px',
    marginLeft: '13px',
    marginTop: '5px',
  }
});

// Create Document Component
const ReportPDF = ({
  district,
  usersAtAGlance,
  currentPeriod,
  date,
  reportingDistrict,
  currentPeriodData,
  currentPeriodType,
  previousPeriodData,
  rating,
  peerRating,
  detailData,
  peerDetail,
  isCounty
}) => {
  const whatIsColor = () => {
    if (currentPeriodType === 'positive') {
      return (process.env.REACT_APP_COLOR_POSITIVE);
    }
    if (currentPeriodType === 'negative') {
      return (process.env.REACT_APP_COLOR_NEGATIVE);
    }
    return (process.env.REACT_APP_COLOR_NEUTRAL);
  };

  return (
    <Document>
      {/* summary 's PDF */}
      <Page size="A4" orientation="landscape" style={styles.page}>
        <>
          <Image
            style={styles.image}
            src={`${toAbsoluteUrl('/media/logos/RCLogo.png')}`}
          />
          <View style={styles.summaryHead}>
            <Text style={styles.headerText}>
              {usersAtAGlance}
            </Text>

            <Text style={[styles.subTitleText, styles.marginLeft,
              {
                color: process.env.REACT_APP_COLOR_DARK_GRAY,
              }
            ]}
            >
              {currentPeriod}
            </Text>

            <Text style={[styles.subTitleText, styles.marginLeft,
              { color: process.env.REACT_APP_COLOR_PRIMARY }]}
            >
              {date}
            </Text>

            <Text style={[styles.subTitleText, {
              marginLeft: 150
            }]}
            >
              {reportingDistrict}
            </Text>

          </View>

          <View style={{
            flexDirection: 'row',
            width: '100%',
            maxHeight: 140,
            margin: 10,
            padding: 10,
          }}
          >
            <View style={{
              flexGrow: 1,
              height: '100%',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              borderRight: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
            }}
            >
              <Text style={[{
                fontSize: '40pt',
                fontWeight: 'heavy',
                color: whatIsColor(),
                marginBottom: '10pt'
              }]}
              >
                {addCommasToNumber(currentPeriodData)}
              </Text>
              <Text style={[styles.subTitleText, { fontSize: '14pt', fontWeight: 'bold' }]}>
                Current Period
              </Text>
            </View>
            <View style={{
              flexGrow: 1,
              height: '100%',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              paddingHorizontal: 10,
              borderRight: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
            }}
            >
              <Text style={[{
                fontSize: '40pt',
                fontWeight: 'heavy',
                marginBottom: '10pt'
              }]}
              >
                {addCommasToNumber(previousPeriodData)}
              </Text>
              <Text style={[styles.subTitleText, { fontSize: '14pt', fontWeight: 'bold' }]}>
                Previous Period
              </Text>
            </View>
            <View style={{
              flexGrow: 1,
              height: '100%',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              paddingHorizontal: 10,
              borderRight: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
            }}
            >
              <Text style={[{
                fontSize: '12pt',
                fontWeight: 'bold',
              }]}
              >
                Your Rating
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginVertical: 10,
                }}
              >
                <Text style={{
                  fontSize: '40pt',
                  fontWeight: 'heavy',
                  marginRight: '10pt'
                }}
                >
                  {rating || '-'}
                </Text>
                <Text
                  style={[styles.subTitleText, {
                    fontSize: '14pt',
                    marginTop: 15
                  }]}
                >
                  Users
                </Text>
              </View>
              <Text
                style={[styles.subTitleText, { fontSize: '14pt' }]}
              >
                # Per 1,000 households
              </Text>
            </View>

            <View
              style={{
                flexGrow: 1,
                height: '100%',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                paddingHorizontal: 10,
              }}
            >
              <Text
                style={[{
                  fontSize: '12pt',
                  fontWeight: 'bold',
                }]}
              >
                Peer Group Rating
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginVertical: 10
                }}
              >
                <Text
                  style={{
                    fontSize: '40pt',
                    fontWeight: 'heavy',
                    marginRight: '10pt',
                    color: process.env.REACT_APP_COLOR_GRAY_DARK
                  }}
                >
                  {peerRating || '-'}
                </Text>
                <Text style={[styles.subTitleText, {
                  fontSize: '14pt',
                  marginTop: 15
                }]}
                >
                  Users
                </Text>
              </View>
              <Text
                style={[styles.subTitleText, {
                  fontSize: '14pt'
                }]}
              >
                # Per 1,000 households
              </Text>
            </View>
          </View>
          {/* detail for 1 district */}
          { district !== '#all-districts#' && peerDetail && !isCounty
            ? (
              <View
                style={{ flexGrow: 1, flexDirection: 'row', padding: 10 }}
              >
                <View
                  style={{
                    flexGrow: 1,
                    flexDirection: 'column',
                    minHeight: 100,
                    borderRight: `2pt solid ${process.env.REACT_APP_COLOR_DARK_GRAY}`,
                    paddingRight: 10,
                    paddingTop: 10,
                  }}
                >
                  <View
                    style={[{
                      width: '100%',
                      padding: '5p',
                      marginTop: 5,
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_DARK_GRAY}`,
                      fontSize: '25pt',
                      fontWeight: 'bold',
                    }, styles.h1]}
                  >
                    <Text style={styles.h4}>
                      User Report
                    </Text>
                  </View>
                  {/* MOBILE */}
                  <View
                    style={[{
                      width: '100%',
                      paddingLeft: '10pt',
                      marginTop: 5,
                      fontSize: '20pt',
                      fontWeight: 'bold',
                    }, styles.h5]}
                  >
                    <Text style={styles.h5}>
                      Mobile Users
                    </Text>
                  </View>
                  {/* IOS */}
                  <View
                    style={{
                      width: '100%',
                      paddingLeft: '30pt',
                      marginTop: 5,
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <View
                      style={[styles.circle, {
                        backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_LIGHT,
                      }]}
                    />
                    <Text style={styles.h6}>
                      IOS :
                      {' '}
                      {addCommasToNumber(detailData.mobile.iOS)}
                    </Text>
                  </View>

                  {/* ANDROID */}
                  <View
                    style={{
                      width: '100%',
                      paddingLeft: '30pt',
                      marginTop: 5,
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >

                    <View
                      style={[styles.circle, {
                        backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_LIGHT,
                      }]}
                    />
                    <Text style={styles.h6}>
                      Android :
                      {' '}
                      {addCommasToNumber(detailData.mobile.android)}
                    </Text>
                  </View>
                  {/* WEB APP */}
                  <View
                    style={{
                      width: '100%',
                      paddingLeft: '10pt',
                      marginTop: 5,
                      fontSize: '20pt',
                      fontWeight: 'bold'
                    }}
                  >
                    { detailData.web_app.detail.length === 0
                      ? (
                        <Text style={styles.h5}>
                          Web App Users :
                          {' '}
                          {detailData.web_app.total}
                        </Text>
                      )
                      : (
                        <Text style={styles.h5}>
                          Web App Users
                        </Text>
                      )}
                  </View>
                  {detailData.web_app.detail
                  && detailData.web_app.detail.length
                  && detailData.web_app.detail.map((item, index) => (
                    <View
                      key={String(index)}
                      style={{
                        width: '100%',
                        paddingLeft: '30pt',
                        marginTop: 5,
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <View
                        style={[styles.circle, {
                          backgroundColor: '#3cbacd',
                        }]}
                      />
                      <Text style={styles.h6}>
                        {item.title}
                        {' '}
                        :
                        {' '}
                        {addCommasToNumber(item.value)}
                      </Text>
                    </View>
                  ))}

                  {/* Communication Users */}
                  <View
                    style={{
                      width: '100%',
                      paddingLeft: '10pt',
                      marginTop: 5,
                      fontSize: '20pt',
                      fontWeight: 'bold',
                    }}
                  >
                    <Text style={styles.h5}>
                      Communication Users
                    </Text>
                  </View>
                  {detailData.communications.detail
                  && detailData.communications.detail.length
                  && detailData.communications.detail.map((item, index) => (
                    <View
                      key={String(index)}
                      style={{
                        width: '100%',
                        paddingLeft: '30pt',
                        marginTop: 5,
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <View
                        style={[styles.circle, {
                          backgroundColor: '#3cbacd',
                        }]}
                      />
                      <Text style={styles.h6}>
                        {item.title}
                        {' '}
                        :
                        {' '}
                        {addCommasToNumber(item.value)}
                      </Text>
                    </View>
                  ))}
                  {/* Calendar Users */}
                  <View
                    style={{
                      width: '100%',
                      paddingLeft: '10pt',
                      marginTop: 5,
                      fontSize: '20pt',
                      fontWeight: 'bold',
                    }}
                  >
                    <Text style={styles.h5}>
                      Calendar Users
                    </Text>
                  </View>
                  {detailData.calendars.detail
                  && detailData.calendars.detail
                  && detailData.calendars.detail.map((item, index) => (
                    <View
                      key={String(index)}
                      style={{
                        width: '100%',
                        paddingLeft: '30pt',
                        marginTop: 5,
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <View
                        style={[styles.circle, {
                          backgroundColor: process.env.REACT_APP_COLOR_PRIMARY,
                        }]}
                      />
                      <Text style={styles.h6}>
                        {item.title}
                        {' '}
                        :
                        {' '}
                        {addCommasToNumber(item.value)}
                      </Text>
                    </View>
                  ))}
                </View>

                {/* Peer Detail */}
                <View
                  style={{
                    flexGrow: 1,
                    flexDirection: 'column',
                    padding: 10,
                    minHeight: 100,
                  }}
                >
                  <View
                    style={{
                      width: '100%',
                      padding: '5pt',
                      marginTop: 5,
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_DARK_GRAY}`,
                      fontSize: '25pt',
                      fontWeight: 'bold',
                    }}
                  >
                    <Text style={styles.h4}>
                      Peer Report
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      paddingLeft: '10pt',
                      marginTop: 5,
                      fontSize: '20pt',
                      fontWeight: 'bold',
                    }}
                  >
                    <Text style={styles.h5}>
                      Mobile Users
                    </Text>
                  </View>
                  {/* Your Rating */}
                  <View
                    style={{
                      width: '100%',
                      paddingLeft: '30pt',
                      marginTop: 5,
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <View
                      style={[styles.circle, {
                        backgroundColor: process.env.REACT_APP_COLOR_PRIMARY,
                      }]}
                    />
                    <Text style={styles.h6}>
                      Your Rating  :
                      {' '}
                      {addCommasToNumber(peerDetail.mobile.rating)}
                    </Text>
                  </View>

                  {/* Peer Group Rating */}
                  <View
                    style={{
                      width: '100%',
                      paddingLeft: '30pt',
                      marginTop: 5,
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <View
                      style={[styles.circle, {
                        backgroundColor: process.env.REACT_APP_COLOR_PRIMARY,
                      }]}
                    />
                    <Text style={styles.h6}>
                      Peer Group Rating :
                      {' '}
                      {addCommasToNumber(peerDetail.mobile.peer_rating)}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      paddingLeft: '10pt',
                      marginTop: 5,
                      fontSize: '20pt',
                      fontWeight: 'bold',
                    }}
                  >
                    <Text style={styles.h5}>
                      Web App Users
                    </Text>
                  </View>
                  {/* Your Rating */}
                  <View
                    style={{
                      width: '100%',
                      paddingLeft: '30pt',
                      marginTop: 5,
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <View
                      style={[styles.circle, {
                        backgroundColor: '#2e75cf',
                      }]}
                    />
                    <Text style={styles.h6}>
                      Your Rating  :
                      {' '}
                      {addCommasToNumber(peerDetail.web_app.rating)}
                    </Text>
                  </View>

                  {/* Peer Group Rating */}
                  <View
                    style={{
                      width: '100%',
                      paddingLeft: '30pt',
                      marginTop: 5,
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <View
                      style={[styles.circle, {
                        backgroundColor: '#2e75cf',
                      }]}
                    />
                    <Text style={styles.h6}>
                      Peer Group Rating :
                      {' '}
                      {addCommasToNumber(peerDetail.web_app.peer_rating)}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      paddingLeft: '10pt',
                      marginTop: 5,
                      fontSize: '20pt',
                      fontWeight: 'bold',
                    }}
                  >
                    <Text style={styles.h5}>
                      Communication Users
                    </Text>
                  </View>
                  {/* Your Rating */}
                  <View
                    style={{
                      width: '100%',
                      paddingLeft: '30pt',
                      marginTop: 5,
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <View
                      style={[styles.circle, {
                        backgroundColor: process.env.REACT_APP_COLOR_PRIMARY,
                      }]}
                    />
                    <Text style={styles.h6}>
                      Your Rating  :
                      {' '}
                      {addCommasToNumber(peerDetail.communications.rating)}
                    </Text>
                  </View>

                  {/* Peer Group Rating */}
                  <View
                    style={{
                      width: '100%',
                      paddingLeft: '30pt',
                      marginTop: 5,
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <View
                      style={[styles.circle, {
                        backgroundColor: process.env.REACT_APP_COLOR_PRIMARY,
                      }]}
                    />
                    <Text style={styles.h6}>
                      Peer Group Rating :
                      {' '}
                      {addCommasToNumber(peerDetail.communications.peer_rating)}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      paddingLeft: '10pt',
                      marginTop: 5,
                      fontSize: '20pt',
                      fontWeight: 'bold',
                    }}
                  >
                    <Text style={styles.h5}>
                      Calendar Users
                    </Text>
                  </View>
                  {/* Your Rating */}
                  <View
                    style={{
                      width: '100%',
                      paddingLeft: '30pt',
                      marginTop: 5,
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <View
                      style={[styles.circle, {
                        backgroundColor: process.env.REACT_APP_COLOR_PRIMARY,
                      }]}
                    />
                    <Text style={styles.h6}>
                      Your Rating  :
                      {' '}
                      {addCommasToNumber(peerDetail.calendars.rating)}
                    </Text>
                  </View>

                  {/* Peer Group Rating */}
                  <View
                    style={{
                      width: '100%',
                      paddingLeft: '30pt',
                      marginTop: 5,
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <View
                      style={[styles.circle, {
                        backgroundColor: process.env.REACT_APP_COLOR_PRIMARY,
                      }]}
                    />
                    <Text style={styles.h6}>
                      Peer Group Rating :
                      {' '}
                      {addCommasToNumber(peerDetail.calendars.peer_rating)}
                    </Text>
                  </View>
                </View>
              </View>
            )
            : ( // multi districts
              <View style={{ flexGrow: 1, flexDirection: 'row', maxWidth: '100%' }}>
                <View style={{ flexGrow: 1, maxWidth: '26%' }}>
                  <View
                    style={{ flexGrow: 1, flexDirection: 'column', padding: 10 }}
                  >

                    <View
                      style={{
                        width: '100%',
                        borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_DARK_GRAY}`,
                        paddingBottom: '5pt',
                        marginTop: 5,
                        fontSize: '20pt',
                        fontWeight: 'bold',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Text style={styles.h5}>
                        Mobile Users
                      </Text>
                    </View>

                    <View
                      style={{
                        flexGrow: 1,
                        flexDirection: 'column',
                      }}
                    >
                      <View
                        style={{
                          flexGrow: 1,
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                        }}
                      >
                        <View
                          style={{
                            width: '100%',
                            // paddingBottom: '10pt',
                            marginTop: 5,
                            fontWeight: 'bold',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start'
                          }}
                        >
                          <Text style={styles.h5}>
                            ios
                          </Text>
                        </View>
                        {((district === '#all-districts#' || isCounty) && (detailData.mobile.iOS.length > 0))
              && (detailData.mobile.iOS.map((item, index) => (
                <View
                  key={String(index)}
                  style={{
                    marginTop: 5,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'row',
                    paddingLeft: '15pt'
                  }}
                >
                  <View
                    style={[styles.circle, {
                      backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_LIGHT,
                    }]}
                  />
                  <Text
                    style={styles.body}
                  >
                    {item.district_name}
                    {':'}
                    { ' '}
                    {addCommasToNumber(item.value)}
                  </Text>
                </View>
              )))}
                      </View>

                      <View
                        style={{
                          flexGrow: 1,
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                        }}
                      >
                        <View
                          style={{
                            width: '100%',
                            paddingBottom: '5pt',
                            marginTop: 5,
                            fontWeight: 'bold',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                          }}
                        >
                          <Text style={styles.h5}>
                            Android
                          </Text>
                        </View>
                        {((district === '#all-districts#' || isCounty) && (detailData.mobile.android.length > 0))
              && (detailData.mobile.android.map((item, index) => (
                <View
                  key={String(index)}
                  style={{
                    marginTop: 5,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'row',
                    paddingLeft: '15pt'
                  }}
                >
                  <View
                    style={[styles.circle, {
                      backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_LIGHT,
                    }]}
                  />
                  <Text
                    style={styles.body}
                  >
                    {item.district_name}
                    {':'}
                    { ' '}
                    {addCommasToNumber(item.value)}
                  </Text>
                </View>
              )))}
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ flexGrow: 1, maxWidth: '24%' }}>
                  <View
                    style={{ flexGrow: 1, flexDirection: 'column', padding: 10 }}
                  >
                    <View
                      style={{
                        width: '100%',
                        borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_DARK_GRAY}`,
                        paddingBottom: '5pt',
                        marginTop: 5,
                        fontWeight: 'bold',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Text style={styles.h5}>
                        Web App Users
                      </Text>
                    </View>

                    <View
                      style={{
                        flexGrow: 1,
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                      }}
                    >
                      {((district === '#all-districts#' || isCounty) && (detailData.web_app.detail.length > 0))
              && (detailData.web_app.detail.map((item, index) => (
                <View
                  key={String(index)}
                  style={{
                    marginTop: 5,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'row',
                    paddingLeft: '15pt'
                  }}
                >
                  <View
                    style={[styles.circle, {
                      backgroundColor: '#2e75cf',
                    }]}
                  />
                  <Text
                    style={styles.body}
                  >
                    {item.title}
                    {':'}
                    { ' '}
                    {addCommasToNumber(item.value)}
                  </Text>
                </View>
              )))}
                    </View>
                  </View>
                </View>

                <View style={{ flexGrow: 1, maxWidth: '24%' }}>
                  <View
                    style={{ flexGrow: 1, flexDirection: 'column', padding: 10 }}
                  >
                    <View
                      style={{
                        width: '100%',
                        borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_DARK_GRAY}`,
                        paddingBottom: '5pt',
                        marginTop: 5,
                        fontWeight: 'bold',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Text style={styles.h5}>
                        Communication Users
                      </Text>
                    </View>

                    <View
                      style={{
                        flexGrow: 1,
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                      }}
                    >
                      {((district === '#all-districts#' || isCounty) && (detailData.communications.detail.length > 0))
              && (detailData.communications.detail.map((item, index) => (
                <View
                  key={String(index)}
                  style={{
                    marginTop: 5,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'row',
                    paddingLeft: '15pt'
                  }}
                >
                  <View
                    style={[styles.circle, {
                      backgroundColor: '#3cbacd',
                    }]}
                  />
                  <Text
                    style={styles.body}
                  >
                    {item.title}
                    {':'}
                    { ' '}
                    {addCommasToNumber(item.value)}
                  </Text>
                </View>
              )))}
                    </View>
                  </View>
                </View>

                <View style={{ flexGrow: 1, maxWidth: '26%' }}>
                  <View
                    style={{ flexGrow: 1, flexDirection: 'column', padding: 10 }}
                  >
                    <View
                      style={{
                        width: '100%',
                        borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_DARK_GRAY}`,
                        paddingBottom: '5pt',
                        marginTop: 5,
                        fontWeight: 'bold',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Text style={styles.h5}>
                        Calendar Users
                      </Text>
                    </View>

                    <View
                      style={{
                        flexGrow: 1,
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                      }}
                    >
                      {((district === '#all-districts#' || isCounty) && (detailData.calendars.detail.length > 0))
              && (detailData.calendars.detail.map((item, index) => (
                <View
                  key={String(index)}
                  style={{
                    marginTop: 5,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'row',
                    paddingLeft: '15pt'
                  }}
                >
                  <View
                    style={[styles.circle, {
                      backgroundColor: '#3e95cd'
                    }]}
                  />
                  <Text
                    style={styles.body}
                  >
                    {item.title}
                    {':'}
                    { ' '}
                    {addCommasToNumber(item.value)}
                  </Text>
                </View>
              )))}
                    </View>
                  </View>
                </View>

              </View>
            )}
        </>
      </Page>
    </Document>
  );
};
export default ReportPDF;
