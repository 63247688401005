const actionTypes = {
  GET_WWTK_DATA_REQUEST: 'GET_WWTK_DATA_REQUEST',
  GET_WWTK_DATA_RECEIVE: 'GET_WWTK_DATA_RECEIVE',
  GET_WWTK_DATA_FAIL: 'GET_WWTK_DATA_FAIL',
  SET_CURRENT_QUESTION_INDEX: 'SET_CURRENT_QUESTION_INDEX',
  SET_SHOW_CASY_SETTINGS: 'SET_SHOW_CASY_SETTINGS',
  FEEDBACK_REQUEST_WWTK: 'FEEDBACK_REQUEST_WWTK',
  FEEDBACK_RECEIVE_WWTK: 'FEEDBACK_RECEIVE_WWTK',
  FEEDBACK_FAIL_WWTK: 'FEEDBACK_FAIL_WWTK',
};

export default actionTypes;
