import { put, call, takeLatest } from 'redux-saga/effects';

import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  getNewSubscribersReport
} from '../apis';

function* newSubscribersReportSaga({
  payload: {
    municipality, from, to, county
  }
}) {
  try {
    yield put(actions.setNewSubscribersReportLoadingAction('send-request'));
    const res = yield call(getNewSubscribersReport, {
      municipality, from, to, county
    });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.newSubscribersReportReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.newSubscribersReportFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

/**
 * Saga watcher function
 */
function* newSubscribersReportSagas() {
  yield takeLatest(actionTypes.GET_NEW_SUBSCRIBERS_REPORT_REQUEST, newSubscribersReportSaga);
}

export default newSubscribersReportSagas;
