import actionTypes from './actionTypes';

export const setTabAction = (tab) => ({
  type: actionTypes.SET_TAB,
  payload: { tab }
});

export const setAllocationCodeAction = (allocation_code) => ({
  type: actionTypes.SET_ALLOCATION_CODE,
  payload: { allocation_code }
});

export const setMajorCampaignAction = (major_campaign) => ({
  type: actionTypes.SET_MAJOR_CAMPAIGN,
  payload: { major_campaign }
});




export const getMajorCampaignsDataRequestAction = (project_id, district_id, from, to) => ({
  type: actionTypes.GET_MAJOR_CAMPAIGNS_DATA_REQUEST,
  payload: { project_id, district_id, from, to }
});

export const getMajorCampaignsDataReceiveAction = (data) => ({
  type: actionTypes.GET_MAJOR_CAMPAIGNS_DATA_RECEIVE,
  payload: data
});

export const getMajorCampaignsDataFailAction = (error) => ({
  type: actionTypes.GET_MAJOR_CAMPAIGNS_DATA_FAIL,
  payload: error
});

export const setCurrentQuestionAction = (index) => ({
  type: actionTypes.SET_CURRENT_QUESTION_INDEX,
  index
});

// Major Campaign Reports

export const getMajorCampaignsReportDataRequestAction = (project_id, district_id) => ({
  type: actionTypes.GET_MAJOR_CAMPAIGNS_REPORTS_DATA_REQUEST,
  payload: { project_id, district_id }
});

export const getMajorCampaignsReportDataReceiveAction = (data) => ({
  type: actionTypes.GET_MAJOR_CAMPAIGNS_REPORTS_DATA_RECEIVE,
  payload: data
});

export const getMajorCampaignsReportDataFailAction = (error) => ({
  type: actionTypes.GET_MAJOR_CAMPAIGNS_REPORTS_DATA_FAIL,
  payload: error
});


// Major Campaign Details

export const getMajorCampaignsDetailsDataRequestAction = (project_id, district_id, allocation_code) => ({
  type: actionTypes.GET_MAJOR_CAMPAIGNS_DETAILS_DATA_REQUEST,
  payload: { project_id, district_id, allocation_code }
});

export const getMajorCampaignsDetailsDataReceiveAction = (data) => ({
  type: actionTypes.GET_MAJOR_CAMPAIGNS_DETAILS_DATA_RECEIVE,
  payload: data
});

export const getMajorCampaignsDetailsDataFailAction = (error) => ({
  type: actionTypes.GET_MAJOR_CAMPAIGNS_DETAILS_DATA_FAIL,
  payload: error
});
