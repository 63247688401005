import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';

import { Typography, IconButton } from '../../../components';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { CloseIcon } from '../../../../assets/icons';


const useStyles = makeStyles(() => ({

  applyButton: {
    fontSize: 14,
    fontWeight: 700,
    color: ({ disabled }) => (disabled
      ? 'rgba(255, 30, 30, 0.4)' : 'rgba(255, 30, 30, 1)'),
    marginRight: 20,
    transition: 'all 0.3s'
  },
  iframeOverride: {
    transform: 'scale( 0.75, 0.75)',
    position: 'relative',
    top: '-50px',
    border: 0,
    '@media (max-height: 820px)': {
      top: '-130px',
    },
  },
  dialogOverride: {
    display: 'block',
    overflow: 'hidden',
    // maxWidth: 'unset',
    // width: 1000,
    width: 800,
    maxWidth: '100%',
    borderRadius: '10px 15px 15px 10px'
  },
  modalContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: 425,
    margin: '0 auto'
  },
  backgroundImage: {
    position: 'absolute',
    width: 420,
    top: 170,
    // right: 120
  },
  characterLeftmage: {
    position: 'absolute',
    // right: -50,
    left: '-90px',
    top: 360
  },
  characterRightImage: {
    position: 'absolute',
    top: 360,
    order: 2,
    // left: -50
    right: '-48px'
  },
  plantImage: {
    top: 90,
    position: 'absolute',
    left: 0
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'center',
    top: 40,
  },
  headerContainer: {
    position: 'relative',
    top: 40,
  },
  modalCloseButton: {
    position: 'absolute',
    right: 16,
    top: 2
  },
  disclaimerText: {
    color: '#37474F',
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 50,
    paddingTop: 5,
    fontWeight: 800,
  }

}));
const PreviewLessonModal = ({
  intl, open, onClose, title, disabled = false, previewID, disclaimerText, modalTitle = 'Now playing on your phone'
}) => {
  const classes = useStyles({ disabled });
  return (
    <>
      {open && (
        <Dialog classes={{
          paperScrollPaper: classes.dialogOverride,
        }}
          open={open} onClose={onClose}>
          <div className={classes.headerContainer}>
            <Typography color="#37474F" className={classes.modalTitle} variant="h2">
              {modalTitle}
            </Typography>
            <IconButton tooltip={intl.formatMessage({ id: 'GENERAL.CLOSE' })} className={classes.modalCloseButton} onClick={() => onClose()} style={{ top: '-30px' }}>
              <CloseIcon width="3em" height="3em" />
            </IconButton>
          </div>

          <div className={classes.modalContentContainer}>

            <img
              alt="background"
              src={toAbsoluteUrl('/media/icons/Education/freepik--background-simple--inject-1.png')}
              className={`${classes.backgroundImage}`}
            />
            <img
              alt="character1"
              src={toAbsoluteUrl('/media/icons/Education/freepik--character-1--inject-1.png')}
              className={`${classes.characterLeftmage}`}
            />
            <img
              alt="character2"
              src={toAbsoluteUrl('/media/icons/Education/freepik--character-2--inject-1.png')}
              className={`${classes.characterRightImage}`}
            />
            <img
              alt="plant"
              src={toAbsoluteUrl('/media/icons/Education/freepik--Plant--inject-1.png')}
              className={`${classes.plantImage}`}
            />
            <iframe className={classes.iframeOverride} scrolling="no" src={`https://cdn.recyclecoach.com/mobile-view/?campaign_id=${previewID}`} title={title} width="425" height="900" />
          </div>
          { disclaimerText && <Typography variant="h4" className={classes.disclaimerText}>{disclaimerText}</Typography> }

        </Dialog>
      )}
    </>
  );
};

export default injectIntl(PreviewLessonModal);
