import actionTypes from './actionTypes';

// Depot actions for setting loading status
export const setLoadingAction = (status) => ({
  type: actionTypes.SET_DEPOT_LOADING_ACTION,
  payload: status
});

export const setSnackbarAction = (status) => ({
  type: actionTypes.SET_DEPOT_SNACKBAR_ACTION,
  payload: status
});


// Depot actions for setting a ADD_Depot flag
export const setAddDepotAction = (flag) => ({
  type: actionTypes.SET_ADD_DEPOT_ACTION,
  payload: { flag }
});

// Depot actions for setting a new depot
export const setNewDepotAction = ({ data, propertyName }) => ({
  type: actionTypes.SET_NEW_DEPOT_ACTION,
  payload: { data, propertyName }
});

// Depot actions for setting a new depot
export const resetNewDepotAction = () => ({
  type: actionTypes.RESET_NEW_DEPOT_ACTION,
});

// Depot actions for fetching all Depots
export const getDepotsRequest = ({ locality }) => ({
  type: actionTypes.GET_ALL_DEPOTS_REQUEST,
  payload: { locality }
});

export const getDepotsReceive = (data) => ({
  type: actionTypes.GET_ALL_DEPOTS_RECEIVE,
  payload: data
});

export const getDepotsFail = (error) => ({
  type: actionTypes.GET_ALL_DEPOTS_FAIL,
  payload: { ...error }
});

// depots actions for fetching depot details
export const getDepotDetailsRequest = ({ locality, depotId }) => ({
  type: actionTypes.GET_DEPOTS_DETAILS_REQUEST,
  payload: { locality, depotId }
});

export const getDepotDetailsReceive = (data) => ({
  type: actionTypes.GET_DEPOTS_DETAILS_RECEIVE,
  payload: { data }
});

export const getDepotDetailsFail = (error) => ({
  type: actionTypes.GET_DEPOTS_DETAILS_FAIL,
  payload: { ...error }
});

// depot actions for removing a depot
export const deleteDepotRequest = ({ locality, depotId }) => ({
  type: actionTypes.DELETE_DEPOT_REQUEST,
  payload: { locality, depotId }
});

export const deleteDepotReceive = (data) => ({
  type: actionTypes.DELETE_DEPOT_RECEIVE,
  payload: { data }
});

export const deleteDepotFail = (error) => ({
  type: actionTypes.DELETE_DEPOT_FAIL,
  payload: { ...error }
});

// depot actions for add a depot
export const addDepotRequest = (newDepot) => ({
  type: actionTypes.ADD_DEPOT_REQUEST,
  payload: newDepot
});

export const addDepotReceive = (data) => ({
  type: actionTypes.ADD_DEPOT_RECEIVE,
  payload: data
});

export const addDepotFail = (error) => ({
  type: actionTypes.ADD_DEPOT_FAIL,
  payload: { ...error }
});

// depot actions for edit a depot
export const editDepotRequest = (newDepot) => ({
  type: actionTypes.EDIT_DEPOT_REQUEST,
  payload: newDepot
});

export const editDepotReceive = (data) => ({
  type: actionTypes.EDIT_DEPOT_RECEIVE,
  payload: { data }
});

export const editDepotFail = (error) => ({
  type: actionTypes.EDIT_DEPOT_FAIL,
  payload: { ...error }
});

// google actions for suggestion location
export const getAddressRequest = (address) => ({
  type: actionTypes.GET_GOOGLE_ADDRESS_REQUEST,
  payload: address
});

export const getAddressReceive = (data) => ({
  type: actionTypes.GET_GOOGLE_ADDRESS_RECEIVE,
  payload: { data }
});

export const getAddressFail = (error) => ({
  type: actionTypes.GET_GOOGLE_ADDRESS_FAIL,
  payload: { ...error }
});

// google actions for suggestion location
export const getLatLngRequest = (latlng) => ({
  type: actionTypes.GET_GOOGLE_LATLANG_REQUEST,
  payload: latlng
});

export const getLatLngReceive = (data) => ({
  type: actionTypes.GET_GOOGLE_LATLANG_RECEIVE,
  payload: { data }
});

export const getLatLngFail = (error) => ({
  type: actionTypes.GET_GOOGLE_LATLANG_FAIL,
  payload: { ...error }
});

export const setInitialSeason = () => ({
  type: actionTypes.SET_INITIAL_SEASON,
});
