/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { PortletBody, PortletHeader } from '../../../../_metronic/partials/content/Portlet';
import { Typography, IconButton, InfoTooltip, } from '../../components';
import { CollapseIcon, ExpandIcon } from '../../../assets/icons';

const useStyles = makeStyles(() => ({
  items: {
    maxHeight: '170px',
    overflow: 'scroll',
    paddingRight: 25,
    paddingLeft: 25,
  },
  item: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
}));

const CollectionDropdown = ({
  title,
  tooltip,
  data,
  itemProperty,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <PortletHeader
        toolbar={(
          <div className="w-100 d-flex justify-content-between align-items-center">
            <Typography variant="h1">
              {title}
              <InfoTooltip tooltip={tooltip} />
            </Typography>
            {
              data && !!data.length && (
                <IconButton onClick={() => setIsOpen(!isOpen)}>
                  {
                    isOpen ? (
                      <CollapseIcon />
                    ) : (
                      <ExpandIcon />
                    )
                  }
                </IconButton>
              )
            }
          </div>
        )}
      />
      <PortletBody className="p-0">
        {isOpen && (
          <div className={classes.items}>
            {
              data.map((item, index) => (
                <div
                  key={index}
                  className={index !== data.length - 1 ? classes.item : null}
                >
                  <Typography className="py-4" variant="body">
                    {item[itemProperty]}
                  </Typography>
                </div>
              ))
            }
          </div>
        )}
      </PortletBody>
    </>
  );
};

export default CollectionDropdown;
