import React from 'react';
import { connect } from 'react-redux';
import Detail from './components/Detail';
import Summary from './components/Summary';

const ResidentKnowledgeIndicator = ({
  data,
  fetchState,
  district
}) => (
  <div id="resident-knowledge-report">
    <Summary
      data={data.summary}
      fetchState={fetchState}
      PDFDataDetail={data.detail}
    />
    <Detail
      data={data.detail}
      fetchState={fetchState}
      isAllDistricts={district.district_id === '#all-districts#'}
    />
  </div>
);

const mapStateToProps = (store) => ({
  data: store.residentKnowledgeIndicatorReport.data,
  fetchState: store.residentKnowledgeIndicatorReport.fetchState,
  district: store.common.district,
});

export default connect(mapStateToProps, null)(ResidentKnowledgeIndicator);
