import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  legendContainer: {
    display: 'flex',
    width: 400,
    marginBottom: 20
  },
  legend_item: {
    display: 'flex',
    margin: '0 16px',
    alignItems: 'center',
    '&::before': {
      content: '""',
      display: 'block',
      height: 8,
      width: 8,
      borderRadius: '50%',
      backgroundColor: 'red',
      marginRight: 4
    }
  },
  legend__item_lastYear: {
    '&::before': {
      backgroundColor: '#79cbc4'
    }
  },
  legend__item_thisYear: {
    '&::before': {
      backgroundColor: '#3398fc'
    }
  },
  legend__item_national: {
    '&::before': {
      backgroundColor: '#1487c0'
    }
  },
  textWidth: {
    width: 89
  },

}));

const ColorLegend = ({
  legendSubtitle,
  displayNatonal,
  thisYear,
  lastYear,
  textWidth
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.legendContainer}`}>
      {
        legendSubtitle && (
          <div className={`${classes.legend__subtitle}`}>{`${legendSubtitle}`}</div>
        )
      }
      <div className={`${classes.legend_item} ${classes.legend__item_lastYear} ${textWidth ? classes.textWidth : ''}`}>
        {lastYear}
      </div>
      <div className={`${classes.legend_item} ${classes.legend__item_thisYear} ${textWidth ? classes.textWidth : ''}`}>
        {thisYear}
      </div>
      {
        displayNatonal && (
          <div className={`${classes.legend_item} ${classes.legend__item_national}`}>
            National ({thisYear})
          </div>
        )

      }

    </div>
  );
};

export default injectIntl(ColorLegend);
