import React from 'react';
import { FormattedMessage } from 'react-intl';

const dizyReportTabName = [
  <FormattedMessage id="REPORT.DISCOVERY_ZONE.SUMMARY.TAB.RF_TITLE" />,
  <FormattedMessage id="REPORT.DISCOVERY_ZONE.SUMMARY.TAB.MM_TITLE" />,
  <FormattedMessage id="REPORT.DISCOVERY_ZONE.SUMMARY.TAB.BLOG_TITLE" />,
];

export { dizyReportTabName as default };
