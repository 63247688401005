/**
 *
 */
const initialState = {
  fetchState: 'no-request',
  data: {},
  loading: true,
  error: false
};

export default initialState;
