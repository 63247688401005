import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

const useStyles = makeStyles({
  container: {
    color: 'white',
    borderRadius: 50,
    backgroundColor: process.env.REACT_APP_COLOR_TERTIARY,
    padding: '2px 12px',
    marginLeft: 12,
    fontSize: 15
  }
});

const Badge = ({ intl }) => {
  const { container } = useStyles();

  return (
    <span className={container}>
      {intl.formatMessage({ id: 'GENERAL.ACTIVE' }).toUpperCase()}
    </span>
  );
};

export default injectIntl(Badge);
