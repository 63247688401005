/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

import { Typography, IconButton } from '../../components';
import { addCommasToNumber } from '../../../utils';
import { InfoIcon } from '../../../assets/icons';
import { SummaryCellLoading } from '../../components/loading';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: 30,
    [theme.breakpoints.down('1200')]: {
      paddingTop: 30,
      paddingBottom: 10,
    },
    [theme.breakpoints.down('992')]: {
      paddingLeft: 10,
    },
  },
  title: {
    minHeight: 30,
  },
  data: {
    minHeight: 80,
  },
  dataColor: {
    color: process.env.REACT_APP_COLOR_GRAY_DARK
  },
}));

const SummaryPeerRating = ({
  intl,
  title,
  data,
  description = `# ${intl.formatMessage({ id: 'REPORT.GENERAL.PER_1000_HOUSEHOLDS' })}`,
  tooltip = intl.formatMessage({ id: 'REPORT.GENERAL.SUMMARY.DESCRIPTION' }),
  isLoading = true,
  className,
  hasUserText = true,
  keyword = 'Users'
}) => {
  const classes = useStyles();

  return (
    <div className={`col-12 col-lg-6 col-xl-3 ${classes.content} ${className}`}>
      {isLoading ? (
        <SummaryCellLoading />
      ) : (
        <div className="d-flex flex-column align-items-between">
          <Typography className={classes.title} variant="h2">
            {title}
          </Typography>
          <div className={`d-flex align-items-center ${classes.data}`}>
            <Typography variant="numberSmall" className={classes.dataColor}>
              {data ? addCommasToNumber(data) : '-'}
            </Typography>
            {hasUserText
              && (
                <Typography component="span" variant="h2Medium" className="ml-3">
                  {keyword}
                </Typography>
              )}
          </div>
          {description
            && (
              <Typography variant="h2Medium">
                {description}
                <IconButton
                  tooltip={tooltip}
                >
                  <InfoIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                </IconButton>
              </Typography>
            )}
        </div>
      )}
    </div>
  );
};

export default injectIntl(SummaryPeerRating);
