import { holidaysChangesInitial, seasonalListInitial } from '../initialData';
/**
 *
 */
const initialState = {
  holidays: [],
  loading: true,
  holidaysChanges: holidaysChangesInitial,
  multiDistrict: [],
  currentCollection: '',
  completedSetup: false,
  seasonalList: seasonalListInitial,
};

export default initialState;
