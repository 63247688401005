import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { Dialog } from '@material-ui/core';
import { CustomButton, Opacity, Typography } from '../../../../components';
import * as actions from '../redux/actions';

const data = {
  project_id: 'pid',
  district_id: 'did',
  WWtKs: [
    {
      date_label: 'Feb 4 - Feb 11',
      date_from: '2022-02-04',
      date_to: '2022-02-11',
      type: 'poll',
      question: 'WWtK Question 1',
      options: [
        'option 1',
        'option 2',
        'option 3'
      ],
      quiz_correct_answer: null,
      message: 'this is the feedback message for poll questions',
      correct_answer_feedback: null,
      wrong_answer_feedback: null,
      casy_settings: {
        has_casy_setting: true,
        material_items: [
          {
            name: 'Plastic bags',
            disposal_type: 'Not Recycling',
            value: true
          }
        ],
        rule_items: [
          {
            text: 'Plastic caps and sprays to be removed',
            value: true
          }
        ]
      }
    },
    {
      date_label: 'Feb 12 - Feb 19',
      date_from: '2022-02-12',
      date_to: '2022-02-19',
      type: 'quiz',
      question: 'WWtK Question 2',
      options: [
        'option 1',
        'option 2',
        'option 3',
        'option 4'
      ],
      quiz_correct_answer: 3,
      message: null,
      correct_answer_feedback: 'The text of correct answer feedback',
      wrong_answer_feedback: 'The text of wrong answer feedback',
      casy_settings: {
        has_casy_setting: true,
        material_items: [
          {
            name: 'Plastic bags',
            disposal_type: 'Not Recycling',
            value: false
          }
        ],
        rule_items: [
          {
            text: 'Plastic caps and sprays to be removed',
            value: true
          }
        ]
      }
    }

  ]
};

const useStyles = makeStyles(() => ({
  tableContainer: {
    borderRadius: 3,
    overflow: 'hidden',
    border: '1px solid #C4C4C4',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    background: '#2A95FF',
    letterSpacing: 1,
    height: 40,
    paddingLeft: 25
  },
  tableRow: {
    display: 'flex',
    alignItems: 'center',
    letterSpacing: 1,
    height: 50,
    paddingLeft: 25,
    borderBottom: '1px solid #C4C4C4'
  },
  tableRowItem: {
    width: 92,
    textAlign: 'center',
    textTransform: 'capitalize',
    border: ''
  }

}));

const CASySettings = ({
  intl,
  currentQuestionIndex,
  setShowCASySettingsAction,
  WWtKs
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [showModal, setShowModal] = useState();

  const {
    casy_settings,
  } = WWtKs[currentQuestionIndex];

  return (
    <Opacity slide>

      <div className="d-flex flex-column">
        <Typography color={process.env.REACT_APP_COLOR_TITLE} className="mb-3" variant="h2">
          {intl.formatMessage({ id: 'EDUCATION.CHECK.CASY.SETTINGS' })}
        </Typography>
        <Typography color={process.env.REACT_APP_COLOR_TEXT} className="align-items-center mb-3" variant="educationBody">
          {intl.formatMessage({ id: 'EDUCATION.CASY.SETTINGS.SUB' })}
          <HelpOutlineIcon style={{ color: '#757575' }} />
        </Typography>

        <div className={classes.tableContainer}>
          <div className={classes.tableHeader}>
            <Typography color="#fff" variant="h2Medium">
              {intl.formatMessage({ id: 'EDUCATION.CASY.CONTENT.STATEMENT' })}
            </Typography>
            <div className="d-flex ml-auto">
              <Typography className={classes.tableRowItem} color="#fff" variant="h2Medium">
                {intl.formatMessage({ id: 'GENERAL.TRUE.FALSE' })}
              </Typography>
              <Typography className={classes.tableRowItem} color="#fff" variant="h2Medium">
                {intl.formatMessage({ id: 'GENERAL.EDIT' })}
              </Typography>
            </div>
          </div>

          {casy_settings.material_items.map((item) => (
            <div key={item.name} className={classes.tableRow}>
              <Typography
                component="span"
                // className="d-flex align-items-center"
                color={process.env.REACT_APP_COLOR_TEXT}
                variant="educationBody"
              >
                <span>
                  The material
                  {' '}
                  <b>{item.name}</b>
                  {' '}
                  is classified as
                  {' '}
                  <b>{item.disposal_type}</b>
                  .
                </span>
                <HelpOutlineIcon style={{ color: '#757575' }} />
              </Typography>
              <div className="d-flex ml-auto">
                <Typography
                  className={classes.tableRowItem}
                  color={process.env.REACT_APP_COLOR_TEXT}
                  variant="bodySemiBold"
                >
                  {item.value ? item.value.toString() : 'True'}

                </Typography>
                <div className={classes.tableRowItem} role="button" onClick={() => setShowModal('material')}>
                  <BorderColorIcon style={{ color: '#757575', cursor: 'pointer' }} />
                </div>
              </div>
            </div>
          ))}

          {casy_settings.rule_items.map((item) => (
            <div key={item.text} className={classes.tableRow}>
              <span
                className="d-flex align-items-center"
                color={process.env.REACT_APP_COLOR_TEXT}
                variant="educationBody"
              >
                {item.text}
                <HelpOutlineIcon style={{ color: '#757575' }} />
              </span>
              <div className="d-flex ml-auto">
                <Typography
                  className={classes.tableRowItem}
                  color={process.env.REACT_APP_COLOR_TEXT}
                  variant="bodySemiBold"
                >
                  {item.value.toString()}
                </Typography>
                <div className={classes.tableRowItem} role="button" onClick={() => setShowModal('rule')}>
                  <BorderColorIcon style={{ color: '#757575', cursor: 'pointer' }} />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="ml-auto mt-4 mb-3">
          <CustomButton
            onClick={() => setShowCASySettingsAction(false)}
            variant="contained"
            disabled={false}
            fontSize={13}
            paddingVertical={1}
            label={<FormattedMessage id="GENERAL.OK" />}
          />
        </div>

        {showModal && (
          <Dialog open={showModal} onClose={() => setShowModal()}>
            <div className="p-4">
              <Typography variant="educationBody">
                {intl.formatMessage({
                  id: showModal === 'rule'
                    ? 'EDUCATION.RULE.MODAL.TEXT'
                    : 'EDUCATION.MATERIAL.MODAL.TEXT'
                })}
              </Typography>
              <div className="d-flex mt-4">
                <div className="d-flex ml-auto">
                  <CustomButton
                    style={{ marginRight: 10 }}
                    onClick={() => setShowModal()}
                    variant="outlined"
                    disabled={false}
                    fontSize={13}
                    paddingVertical={1}
                    label={<FormattedMessage id="GENERAL.CANCEL" />}
                  />
                  <CustomButton
                    onClick={
                      showModal === 'rule'
                        ? () => history.push('/waste-recycling-rules')
                        : () => history.push('/what-goes-where/home')
                    }
                    variant="contained"
                    disabled={false}
                    fontSize={13}
                    paddingVertical={1}
                    label={<FormattedMessage id="GENERAL.PROCEED" />}
                  />
                </div>
              </div>
            </div>
          </Dialog>
        )}

      </div>
    </Opacity>
  );
};

const mapStateToProps = (store) => ({
  currentQuestionIndex: store.WWtK.currentQuestionIndex,
  WWtKs: store.WWtK.WWtKs,
});
export default injectIntl(connect(mapStateToProps, actions)(CASySettings));
