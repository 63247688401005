// eslint-disable-next-line import/no-cycle
import cts from '../../crud/cts';

const getDashboardData = ({
  fromDate,
  toDate,
}) => cts(
  'get',
  `dashboard?from=${fromDate}&to=${toDate}`,
);

export { getDashboardData, getDashboardData as default };

export const socialMediaAssestsLink = 'https://recyclingcalendars-my.sharepoint.com/:f:/g/personal/jgalad_recyclecoach_com/EjYpcSS6Vk1JrWyTUFMLS2EBr9QyGHW5XotFr0DJqj9giA?e=A23zpD';
