import React from 'react';
import { injectIntl } from 'react-intl';

import { TextField } from '../../../components';

const SearchBar = ({
  intl,
  value,
  onChange
}) => (
  <TextField
    label={intl.formatMessage({ id: 'GENERAL.SEARCH' })}
    onChange={(value) => onChange(value)}
    value={value}
  />
);

export default injectIntl(SearchBar);
