import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import {
  CustomButton,
  Dialog,
  Typography
} from '../../components';
import * as actions from '../redux/actions';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('552')]: {
      flexDirection: 'column !important',
      justifyContent: 'center',
      alignItems: 'center',
    }
  },
  button: {
    width: '25%',
    [theme.breakpoints.down('1400')]: {
      width: '30%'
    },
    [theme.breakpoints.down('1200')]: {
      width: '20%'
    },
    [theme.breakpoints.down('900')]: {
      width: '25%'
    },
    [theme.breakpoints.down('650')]: {
      width: '30%'
    },
    [theme.breakpoints.down('552')]: {
      width: '100%',
    }
  },
  updateMode: {
    display: 'none !important'
  }
}));

const ButtonSection = ({
  intl,
  newMessage,
  cat,
  saveAction,
  draftAction,
  cancelAction,
  isDisable,
  isDisableDraft,
  scheduled
}) => {
  const classes = useStyles();
  const [showUpdateButton, setShowUpdateButton] = useState(false);

  useEffect(() => {
    if (newMessage.notif_id) {
      if (cat) {
        setShowUpdateButton(true);
      } else {
        setShowUpdateButton(false);
      }
    } else {
      setShowUpdateButton(false);
    }
  }, [newMessage.notif_id]);

  return (
    <div className={`d-flex flex-row-reverse mb-3 ${classes.container}`}>
      <div className={`m-1 ${classes.button} ${showUpdateButton ? classes.updateMode : null}`}>
        <Dialog
          isDisabled={isDisable}
          button={(
            <CustomButton
              label={intl.formatMessage({ id: 'NOTIFICATION.BUTTON_SECTION.SEND' })}
              color="primary"
              variant="contained"
              onClick={() => {}}
              disabled={isDisable}
            />
            )}
          title={
              (scheduled === 'immediately')
                ? intl.formatMessage({ id: 'NOTIFICATION.MODAL.IMMEDIATELY.SEND.TITLE' })
                : intl.formatMessage({ id: 'NOTIFICATION.MODAL.SCHEDULE.SEND.TITLE' })
            }
          content={(
            <Typography variant="bodySemiBold">
              {(scheduled === 'immediately')
                ? intl.formatMessage({ id: 'NOTIFICATION.MODAL.IMMEDIATELY.SEND.TEXT' })
                : intl.formatMessage({ id: 'NOTIFICATION.MODAL.SCHEDULE.SEND.TEXT' })}
            </Typography>
            )}
          submitText={intl.formatMessage({ id: 'GENERAL.SEND' })}
          handleSubmit={saveAction}
        />
      </div>

      <div className={`m-1 ${classes.button}`}>
        <CustomButton
          label={!showUpdateButton
            ? intl.formatMessage({ id: 'NOTIFICATION.BUTTON_SECTION.DRAFT' })
            : intl.formatMessage({ id: 'GENERAL.UPDATE' })}
          color={!showUpdateButton ? 'secondary' : 'primary'}
          variant="contained"
          onClick={draftAction}
          disabled={isDisableDraft}
        />
      </div>

      <div className={`m-1 ${classes.button}`}>
        <CustomButton
          label={intl.formatMessage({ id: 'GENERAL.CANCEL' })}
          color="primary"
          variant="outlined"
          onClick={cancelAction}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  newMessage: store.notification.newMessage,
  cat: store.notification.cat
});

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(ButtonSection)
);
