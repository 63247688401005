import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';


import { Typography } from '../../../components';

import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { DropdownMenu } from '../../../Education/EducationalContent/components';
import { DoughnutChart } from '../../components';
import { TableLoading } from '../../../components/loading';

import { UsersByZonesModal } from '../components/index';


const useStyles = makeStyles((theme) => ({
  container: {
    width: '1300px',
    margin: '0 auto'
  },


  graph: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    fontFamily: "sans-serif"
  },
  graphRowClass: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
    marginBottom: "16px",
    '&:last-of-type': {
      marginBottom: "0"
    }
  },
  graphRowLastOfTypeClass: {
    marginBottom: "0"
  },
  graphTitle: {
    fontWeight: "600",
    fontSize: "16px",
    color: "#404040",
    lineHeight: "24px",
    width: "400px",
    letterSpacing: '0.04em',
  },
  graphBar: {
    width: "100%",
    height: "40px",
    backgroundColor: "rgba(108, 114, 147, 0.06)"
  },
  graphPercentage: {
    backgroundColor: "#2A95FF",
    height: "100%"
  },
  graphNumber: {
    color: "#6C7293",
    width: "120px",
    textAlign: "center",
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 18,
  },
  sectionImage: {
    display: "flex",
    margin: "0 auto",
    marginBottom: "16px",
  },
  lists: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxHeight: 267,
  },
  list__heading: {
    color: '#404040', /* Change the color */
    fontFamily: 'sans-serif',
    textTransform: 'uppercase',
    fontSize: 13,
    marginBottom: '16px'
  },
  list__rows: {
    display: 'flex',
    flexDirection: 'column',
    color: '#63666A', /* Change the color */
    fontFamily: 'sans-serif'
  },
  list__row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    height: '2rem',
    fontSize: 18,

  },
  list__number: {
    marginRight: '6px',
    fontWeight: 600 /* Change as needed */
  },
  list__arrow: {
    marginRight: 6
  },
  list__change: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24
  },

  /* Item shows increase */
  list__changePositive: {
    color: '#409A5F'
  },
  /* Item shows decrease */
  list__changeNegative: {
    color: '#FF1E1E'
  },
  list__changeNegativeList__arrow: {
    transform: 'rotate(180deg)'
  },

  /* Legend for the circle graph */
  list__legend: {
    backgroundColor: 'grey',
    color: 'white',
    borderRadius: 4,
    padding: '4px 10px',
    marginRight: 20,
    // minWidth: 27,
    // minHeight: 27,
  },
  list__legend1: {
    backgroundColor: '#2A95FF' /* Colors correspond to sections of the graph */
  },
  list__legend2: {
    backgroundColor: '#A663FF' /* Colors correspond to sections of the graph */
  },
  list__legend3: {
    backgroundColor: '#97CCFF' /* Colors correspond to sections of the graph */
  },
  list__legend4: {
    backgroundColor: '#00C192' /* Colors correspond to sections of the graph */
  },
  list__legend5: {
    backgroundColor: '#FFCD5B' /* Colors correspond to sections of the graph */
  },
  list__legend6: {
    backgroundColor: '#F070FA' /* Colors correspond to sections of the graph */
  },
  list__topic: {
    fontWeight: 600,
    fontSize: 14,

  },
  chartContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

}));

const ProblemReports = ({
  intl,
  reportData,
  isLoading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);



  /**
* Menu and menuitem functions.
*/

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const viewProblemReportsHandler = (e) => {
    history.push('/report/problem');
  };

  const descriptionHandler = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    handleClose();
  }

  /**
* Data for dropdown menu items.
*/
  const menuItemData = [
    {
      id: 1,
      name: 'Description',
      method: descriptionHandler,
      disabled: false
    },
    {
      id: 2,
      name: 'View Detailed Report',
      method: viewProblemReportsHandler,
      disabled: false
    },
  ];

  const problem_reports = {
    "total": {
      "value": 343, "diff": "N/A"
    },
    "detail": [

    ]
  };

  if (!reportData || !reportData.problem_report) {
    reportData.problem_report = JSON.parse(JSON.stringify(problem_reports));
  }

  const problemReportsValues = reportData.problem_report.detail.map((item) => item.value);
  const problemReportsLabels = reportData.problem_report.detail.map((item) => item.title);
  // const problemReportsTotal = 1812



  const problemReportsData = [
    {
      id: 1,
      title: problemReportsLabels[0],
      value: problemReportsValues[0],
      diff: 25
    },
    {
      id: 2,
      title: problemReportsLabels[1],
      value: problemReportsValues[1],
      diff: 25
    },
    {
      id: 3,
      title: problemReportsLabels[2],
      value: problemReportsValues[2],

      diff: 25
    },
    {
      id: 4,
      title: problemReportsLabels[3],
      value: problemReportsValues[3],
      diff: 25
    },
    {
      id: 5,
      title: problemReportsLabels[4],
      value: problemReportsValues[4],
      diff: 25
    }
  ]



  return (
    <div style={{ height: '100%' }}>
      <Portlet style={{ marginBottom: 0, height: '100%' }}>
        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start" style={{ justifyContent: 'space-between' }}>
                <div className="row col-xs-12 col-lg-10 align-items-center">
                  <div className="align-items-center pl-3 d-flex justify-content-between">
                    <Typography variant="h1">
                      Problem Reports
                    </Typography>
                    <Typography variant="h1" className={`ml-5 mr-4 d-flex`}>
                      {reportData.problem_report.total.value.toLocaleString()}
                      <div className={` ml-4 ${classes.list__arrow} ${reportData.problem_report.total.diff >= 0 ? classes.list__changePositive : classes.list__changeNegative}`}>{reportData.problem_report.total.diff >= 0 ? '↑' : '↓'}</div>
                      <div className={`${classes.list__percentage} ${reportData.problem_report.total.diff >= 0 ? classes.list__changePositive : classes.list__changeNegative}`}>{reportData.problem_report.total.diff}%</div>
                    </Typography>
                  </div>
                </div>
                <DropdownMenu
                  handleClick={handleClick}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  menuItemData={menuItemData}
                />
              </div>
            )}
          />
        </div>
        <UsersByZonesModal
          open={open}
          onClose={() => closeModal()}
          title={'Problem Reports'}
          content={(
            <div style={{ width: '100%' }}>
              <PortletBody className={`${classes.tableBody}`}>
                <Typography variant="body" className="d-flex align-items-start text-left">
                  Report-a-Problem is a great feature that makes it easier for residents to submit complete reports that include all the information you need to act on them. Certain reports such as the one residents use to report a missing or damaged cart can be custom configured to gather additional details. The number of reports is for the latest month, which is shown in the upper right corner of this page. The percentage change is a comparison to the same month last year.
                </Typography>
              </PortletBody>
            </div>


          )}
        />
        <PortletBody>
          <div className="row">
            <div style={{ width: '100%', padding: 'unset' }}>
              <div className="col-12">
                <div className={`col-12 ${classes.chartContainer}`}>
                  <div style={{ width: 500 }}>
                    <DoughnutChart
                      chartID="ProblemReport"
                      isLoading={isLoading}
                      total={reportData.problem_report.total.value.toLocaleString()}
                      insideText="Total Reports"
                      labels={problemReportsLabels}
                      values={problemReportsValues}
                      colors={['#2A95FF', '#A663FF', '#97CCFF', '#00C192', '#FFCD5B']}
                    />
                  </div>
                </div>

              </div>
              {
                isLoading ?
                  <TableLoading /> :
                  <div className="col-12">
                    <div className={classes.lists} style={{ overflowY: reportData.problem_report.detail.length > 5 ? 'auto' : 'initial' }}>
                      <div className={classes.list}>
                        <div className={classes.list__heading} style={{ display: 'flex', justifyContent: 'center', marginRight: '-16px' }}>Problem Types</div>
                        <div className={classes.list__rows}>
                          {
                            problemReportsLabels.map((item, index) => (
                              <div className={classes.list__row}>
                                <div className={`${classes.list__legend} ${index + 1 === 1 ? classes.list__legend1 : index + 1 === 2 ? classes.list__legend2 : index + 1 === 3 ? classes.list__legend3 : index + 1 === 4 ? classes.list__legend4 : classes.list__legend5}`}>{index + 1}</div>
                                <div className={classes.list__topic}>{item}</div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      <div className={classes.list}>
                        <div className={classes.list__heading}># Of Reports</div>
                        <div className={classes.list__rows}>
                          {
                            reportData.problem_report.detail.map((item, index) => (
                              <div className={classes.list__row}>
                                <div className={classes.list__number}>{item.value.toLocaleString()}</div>
                                <div className={`${classes.list__change} ${Number(item.diff) >= 0 ? classes.list__changePositive : classes.list__changeNegative}`}>
                                  <div className={classes.list__arrow}>{item.diff >= 0 ? '↑' : '↓'}</div>
                                  <div className={classes.list__percentage}>{item.diff}%</div>
                                </div>
                              </div>
                            ))
                          }
                          {/* <div className={classes.list__row}>

                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
              }


            </div>
          </div>
        </PortletBody>
      </Portlet>
    </div>
  );
};

export default injectIntl(ProblemReports);
