import { put, call, takeLatest } from 'redux-saga/effects';

import actionTypes from './actionTypes';
import * as actions from './actions';
import { getUserFullName, resetPassword } from '../../apis';

function* getNameSaga({ payload: { token } }) {
  try {
    const res = yield call(getUserFullName, { token });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getNameReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getNameFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* resetPasswordSaga({ payload: { newPassword, newPasswordRepeat, token } }) {
  try {
    yield put(actions.setLoadingAction());
    const res = yield call(resetPassword, { newPassword, newPasswordRepeat, token });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.resetPasswordReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.resetPasswordFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

/**
 * Saga watcher function
 */
function* sagas() {
  yield takeLatest(actionTypes.GET_NAME_REQUEST, getNameSaga);
  yield takeLatest(actionTypes.RESET_PASSWORD_REQUEST, resetPasswordSaga);
}

export default sagas;
