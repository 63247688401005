import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import IdleTimer from 'react-idle-timer';

import Header from './header/Header';
import HeaderMobile from './header/HeaderMobile';
import Footer from './footer/Footer';
import HTMLClassService from './HTMLClassService';
import LayoutConfig from './LayoutConfig';
import MenuConfig from './MenuConfig';
import LayoutInitializer from './LayoutInitializer';
import KtContent from './KtContent';
import './assets/Base.scss';

import * as actions from '../../app/pages/Auth/Logout/redux/actions';
import ScrollToTop from '../partials/layout/ScrollToTop';

const htmlClassService = new HTMLClassService();

function Layout({
  children, selfLayout, layoutConfig, logoutRequest
}) {
  htmlClassService.setConfig(layoutConfig);

  const contentCssClasses = htmlClassService.classes.content.join(' ');

  const idleTimer = useRef();

  /**
   * Scroll state, function and listeners for ScrollToTop.js.
   */
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const scrollToTopHandler = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return selfLayout !== 'blank' ? (
    <>
      <IdleTimer
        ref={idleTimer}
        onIdle={logoutRequest}
        timeout={Number(process.env.REACT_APP_IDLE_TIME)}
        onAction={() => idleTimer.current.reset()}
        debounce={1000}
      />
      <LayoutInitializer
        menuConfig={MenuConfig}
        layoutConfig={LayoutConfig}
        htmlClassService={htmlClassService}
      >
        {/* <!-- begin:: Header Mobile --> */}
        <HeaderMobile />
        {/* <!-- end:: Header Mobile --> */}

        <div className="kt-grid kt-grid--hor kt-grid--root">
          {/* <!-- begin::Body --> */}
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            {/* <!-- begin:: Aside Left --> */}
            {/* {asideDisplay && (
              <>
                <AsideLeft />
              </>
            )} */}
            {/* <!-- end:: Aside Left --> */}
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              {/* <!-- begin:: Header READY --> */}

              <Header />
              {/* <!-- end:: Header --> */}

              {/* <!-- begin:: Content --> */}
              <div
                id="kt_content"
                className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
              >
                {/* <!-- begin:: Content Body --> */}
                {/* TODO: add class to animate  kt-grid--animateContent-finished */}
                <KtContent>{children}</KtContent>
                {/* <!-- end:: Content Body --> */}
              </div>
              {/* <!-- end:: Content --> */}
              <Footer />
            </div>
          </div>
          {/* <!-- end:: Body --> */}
        </div>
        {/* <!-- AutoScroll Container --> */}
        {scrollPosition > 150 && <ScrollToTop scrollToTopHandler={scrollToTopHandler} />}
      </LayoutInitializer>
    </>
  ) : (
    // BLANK LAYOUT
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <KtContent>{children}</KtContent>
    </div>
  );
}

const mapStateToProps = ({ builder: { layoutConfig } }) => ({
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, 'self.layout'),
  asideDisplay: objectPath.get(layoutConfig, 'aside.self.display'),
  subheaderDisplay: objectPath.get(layoutConfig, 'subheader.display'),
  desktopHeaderDisplay: objectPath.get(
    layoutConfig,
    'header.self.fixed.desktop'
  ),
  contentContainerClasses: ''
});

export default connect(mapStateToProps, actions)(Layout);
