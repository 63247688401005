import React from 'react';
import { connect } from 'react-redux';

import CollectionDetails from './CollectionDetails';
import Categories from './Categories';
import { CmsLoading } from '../../components/loading';

const Content = ({
  fetchState,
  localityFetchState,
}) => {
  const isSendRequest = fetchState === 'send-request' || localityFetchState === 'send-request';

  return (
    <div className="row">
      <div className="col-12 col-lg-4">
        <CollectionDetails />
      </div>
      <div className="col-12 col-lg-8">
        {isSendRequest ? (
          <CmsLoading />
        ) : (
          <Categories />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.category.fetchState,
  localityFetchState: store.common.fetchState,
});

export default connect(mapStateToProps, null)(Content);
