import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  loading: {
    width: '15px',
    height: '15px',
    border: `3px solid ${process.env.REACT_APP_COLOR_PRIMARY}`,
    borderRadius: '50%',
    borderTopColor: process.env.REACT_APP_COLOR_SECONDARY,
    animation: '$spin 1s ease-in-out infinite',
    marginLeft: '5px'
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(360deg)',
    },
    to: {
      transform: 'rotate(0deg)',
    }
  },
}));

const RCSpinner = ({
  isActive
}) => {
  const classes = useStyles();

  return (
    isActive
      ? <div className={classes.loading} />
      : null
  );
};

export default RCSpinner;
