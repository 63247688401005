import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, CustomButton, Dialog } from '../../components';
import { ClockIcon, ExpandIcon } from '../../../assets/icons';
import * as actions from '../redux/actions';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    maxHeight: '500px',
    overflow: 'auto',
    flexDirection: 'column',
  },
  dropdownItem: {
    padding: '0 !important',
  },
  header: {
    width: '100%',
    height: '110px',
    backgroundColor: process.env.REACT_APP_COLOR_PREVIEW_COLLECTION_HEADER,
    display: 'flex',
    alignItems: 'center',
    padding: '25px',
  },
  time: {
    width: '100%',
    maxHeight: '50px',
    padding: '25px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  ClockIcon: {
    width: '20px',
    height: '20px',
    marginRight: '10px',
  },
  mainContent: {
    width: '100%',
    padding: '20px',
    paddingBottom: '50px',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  drawers: {
    padding: '25px',
    paddingTop: '15px',
    paddingBottom: '15px',
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
}));

const Preview = ({ intl, collection }) => {
  const classes = useStyles();
  return (
    <Dialog
      hasButtons={false}
      disableBackdropClick={false}
      button={(
        <CustomButton
          className={classes.dropdownItem}
          onClick={() => {}}
          label={(
            <Typography variant="bodySemiBold">
              {intl.formatMessage({ id: 'CATEGORY.MENU.PREVIEW' })}
            </Typography>
          )}
        />
      )}
      title={(
        <Typography variant="h1">
          {intl.formatMessage({ id: 'GENERAL.PREVIEW' })}
        </Typography>
      )}
      contentClassName="p-0"
      content={(
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography
              color={process.env.REACT_APP_COLOR_SECONDARY}
              variant="h1"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: collection.coll_admin_nm
                }}
              />
            </Typography>
          </div>
          <div className={classes.time}>
            <div className="d-flex align-items-center">
              <ClockIcon className={classes.ClockIcon} />
              <Typography variant="body">
                <div
                  dangerouslySetInnerHTML={{
                    __html: collection.set_out_time
                  }}
                />
              </Typography>
            </div>
            <Typography variant="body">
              {intl.formatMessage({
                id: 'COLLECTION.PREVIEW.SEE_THE_CALENDAR',
              })}
            </Typography>
          </div>
          <div className={classes.mainContent}>
            <div className="d-flex align-items-center mb-4">
              <Typography variant="body">
                <div
                  dangerouslySetInnerHTML={{
                    __html: collection.coll_details
                  }}
                />
              </Typography>
            </div>
            <div className="d-flex align-items-center">
              <Typography variant="h2">
                {intl.formatMessage({
                  id: 'COLLECTION.COLLECTIONS.SET_OUT_DESCRIPTION',
                })}
              </Typography>
            </div>
            <Typography variant="body">
              <div
                dangerouslySetInnerHTML={{
                  __html: collection.set_out_time_dscr
                }}
              />
            </Typography>
          </div>
          {!!collection.drawers.length
            && collection.drawers.map((item) => (
              <div
                key={item.drawer_title}
                className={`w-100 d-flex justify-content-between align-items-center ${classes.drawers}`}
              >
                <Typography variant="bodySemiBold">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.drawer_title
                    }}
                  />
                </Typography>
                <ExpandIcon />
              </div>
            ))}
        </div>
      )}
    />
  );
};

export default injectIntl(connect(null, actions)(Preview));
