import React from 'react';

import { Typography } from '../../components';

const Description = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div {...props}>
    <Typography variant="body">
      {children}
    </Typography>
  </div>
);

export default Description;
