import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import objectPath from 'object-path';
import HMenuItem from './HMenuItem';
import * as builder from '../../../ducks/builder';
import { toAbsoluteUrl } from '../../..';
import KTMenu from '../../../_assets/js/menu';
import KTOffcanvas from '../../../_assets/js/offcanvas';

const offcanvasOptions = {
  overlay: true,
  baseClass: 'kt-header-menu-wrapper',
  closeBy: 'kt_header_menu_mobile_close_btn',
  toggleBy: {
    target: 'kt_header_mobile_toggler',
    state: 'kt-header-mobile__toolbar-toggler--active'
  }
};

class HMenu extends React.Component {
  offCanvasCommonRef = React.createRef();

  ktMenuCommonRef = React.createRef();

  componentDidMount() {
    // Canvas
    this.initOffCanvas();
    // Menu
    this.initKTMenu();
  }

  getHeaderLogo() {
    let result = 'logo-rc.svg';
    const { headerSelfSkin } = this.props;
    if (headerSelfSkin && headerSelfSkin !== 'dark') {
      result = 'logo-rc.svg';
    }
    return toAbsoluteUrl(`/media/logos/${result}`);
  }

  get currentUrl() {
    const { location } = this.props;
    return location.pathname.split(/[?#]/)[0].substring(1);
  }

  initOffCanvas = () => {
    // eslint-disable-next-line no-new
    new KTOffcanvas(this.offCanvasCommonRef.current, offcanvasOptions);
  };

  initKTMenu = () => {
    const menuOptions = {
      submenu: {
        desktop: 'dropdown',
        tablet: 'accordion',
        mobile: 'accordion'
      },
      accordion: {
        slideSpeed: 200, // accordion toggle slide speed in milliseconds
        expandAll: false // allow having multiple expanded accordions in the menu
      },
      dropdown: {
        timeout: 50
      }
    };

    let menuDesktopMode = 'accordion';
    if (
      this.ktMenuCommonRef.current.getAttribute('data-ktmenu-dropdown') === '1'
    ) {
      menuDesktopMode = 'dropdown';
    }

    if (typeof objectPath.get(menuOptions, 'submenu.desktop') === 'object') {
      menuOptions.submenu.desktop = {
        default: menuDesktopMode
      };
    }

    // eslint-disable-next-line no-new
    new KTMenu(this.ktMenuCommonRef.current, menuOptions);
  };

  render() {
    const {
      disabledAsideSelfDisplay,
      ktMenuClasses,
      ulClasses,
      rootArrowEnabled,
      menuConfig: { header: { items } },
      role_id,
      advocateAdmin,
      permission_list,
      municipalities
    } = this.props;

    return (
      <div
        className="kt-header-menu-wrapper w-100"
        id="kt_header_menu_wrapper"
        ref={this.offCanvasCommonRef}
      >
        {disabledAsideSelfDisplay && (
        <div className="kt-header-logo">
          <Link to="/">
            <img alt="logo" src={this.getHeaderLogo()} />
          </Link>
        </div>
        )}

        <div
          id="kt_header_menu"
          className={`kt-header-menu kt-header-menu-mobile ${ktMenuClasses}`}
          ref={this.ktMenuCommonRef}
        >
          <ul className={`kt-menu__nav ${ulClasses}`}>
            {items.map((item) => (
              <React.Fragment key={item.title}>
                { // menu items create when permission is true
                    (item.title === 'Users' && role_id === '3')
                    || ((advocateAdmin === true && item.title === 'Reminder Messages')
                      || (item.title === 'Reminder Messages'
                      && permission_list.filter((item) => item.group_id === 'reminder')[0].permission === 'f'))
                    || (item.title === 'Notifications'
                    && (permission_list.filter((item) => item.group_id === 'notification')[0].permission === 'f'))
                    || (item.title === 'Reports'
                    && (permission_list.filter((item) => item.group_id === 'report')[0].permission === 'f'))
                    || (item.title === 'Education'
                    && permission_list.filter((item) => item.group_id === 'education').length && (permission_list.filter((item) => item.group_id === 'education')[0].permission === 'f'))
                    || (item.title === 'Schedules'
                    && permission_list.filter((item) => item.group_id === 'schedules').length && (permission_list.filter((item) => item.group_id === 'schedules')[0].permission === 'f'))
                    || (item.title === 'Marketing Resources'
                    && permission_list.filter((item) => item.group_id === 'marketing_support').length &&(permission_list.filter((item) => item.group_id === 'marketing_support')[0].permission === 'f'))
                    || (item.title === 'What Goes Where'
                    && (permission_list.filter((item) => item.group_id === 'cms').length !== 0
                      && permission_list.filter((item) => item.group_id === 'cms')[0].permission === 'f'))
                    || (item.title === 'Campaigns' && advocateAdmin === true)
                    || (municipalities.every((item) => item.stage === '2')
                    && (item.title === 'Users' || item.title === 'Notifications' || item.title === 'What Goes Where'))
                      ? null : (
                        <HMenuItem
                          item={item}
                          currentUrl={this.currentUrl}
                          rootArrowEnabled={rootArrowEnabled}
                        />
                      )
                  }
              </React.Fragment>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  config: store.builder.layoutConfig,
  role_id: store.auth.user_info.role_id,
  permission_list: store.auth.user_info.permission_list,
  menuConfig: store.builder.menuConfig,
  advocateAdmin: store.auth.advocateAdmin,
  municipalities: store.common.municipalities,
  ktMenuClasses: builder.selectors.getClasses(store, {
    path: 'header_menu',
    toString: true
  }),
  rootArrowEnabled: builder.selectors.getConfig(
    store,
    'header.menu.self.root-arrow'
  ),
  headerSelfSkin: builder.selectors.getConfig(store, 'header.self.skin'),
  ulClasses: builder.selectors.getClasses(store, {
    path: 'header_menu_nav',
    toString: true
  }),
  disabledAsideSelfDisplay:
    objectPath.get(store.builder.layoutConfig, 'aside.self.display') === false
});

export default withRouter(connect(mapStateToProps)(HMenu));
