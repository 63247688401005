import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../redux/createReducer';
import initialState from './store';
import * as routerHelpers from '../../../router/routerHelpers';

const authReducer = persistReducer(
  { storage, key: 'auth', whitelist: ['isAuthenticated', 'user_info', 'advocateAdmin'] },
  createReducer(initialState, {
    SET_LOADING: (state) => Immutable({
      ...state,
      fetchState: 'send-request',
      responseMessage: null
    }),
    RESET_LOADING: (state) => Immutable({
      ...state,
      fetchState: 'no-request'
    }),
    LOGINـRECEIVE: (state, { payload }) => Immutable({
      ...state,
      ...payload.data,
      isAuthenticated: payload.message === 'OK',
      fetchState: 'succeed-request',
      advocateAdmin: payload.data.advocate_user === 't'
    }),
    LOGINـFAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseMessage: payload.data
    }),
    LOGIN_AS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      ...payload.data,
      isAuthenticated: payload.message === 'OK',
      fetchState: 'succeed-request',
      advocateAdmin: payload.data.advocate_user === 't'
    }),
    LOGIN_AS_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseMessage: payload.data
    }),
    MFAـRECEIVE: (state) => Immutable({
      ...state,
      fetchState: 'succeed-request'
    }),
    MFAـFAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseMessage: payload.data
    }),
    OTPـRECEIVE: (state, { payload }) => Immutable({
      ...state,
      ...payload.data,
      fetchState: 'succeed-request',
      isAuthenticated: true
    }),
    OTPـFAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseMessage: payload.data
    }),
    EMAIL_VERIFICATION_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'succeed-request',
      responseMessage: payload
    }),
    EMAIL_VERIFICATION_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseMessage: payload.data
    }),
    GET_NAME_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      ...payload.data
    }),
    GET_NAME_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data
    }),
    RESET_PASSWORD_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'succeed-request',
      responseMessage: payload
    }),
    RESET_PASSWORD_FAIL: (state) => Immutable({
      ...state,
      fetchState: 'failed-request'
    }),
    LOGOUTـRECEIVE: (state) => {
      routerHelpers.forgotLastLocation();
      return Immutable({
        ...state,
        ...initialState
      });
    },
    EDIT_PROFILE_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      user_info: {
        ...state.user_info,
        ...payload,
      },
      fetchState: 'succeed-request'
    }),
    EDIT_PROFILE_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data,
      fetchState: 'failed-request'
    }),
    EDIT_PROFILE_PICTURE_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      user_info: {
        ...state.user_info,
        image_profile: payload.data || null,
      },
      fetchState: 'succeed-request'
    }),
    EDIT_PROFILE_PICTURE_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data,
      fetchState: 'failed-request'
    }),
    UPDATE_USER_INFO_MANAGES_SCHEDULES: (state, { payload }) => Immutable({
      ...state,
      user_info: {
        ...state.user_info,
        manages_schedules: payload.manages_schedules
      }
    }),
  })
);

export default authReducer;
