/**
 *
 */
const initialState = {
  fetchState: 'no-request',
  data: [],
  updatedKey: null,
  responseMessage: '',
  responseStatus: ''
};

export default initialState;
