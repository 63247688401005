import { put, call, takeLatest } from 'redux-saga/effects';

import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  getDepots,
  getDepotDetails,
  deleteDepot,
  newDepot,
  getLatLng,
  getAddress,
  editDepot
} from '../apis';

function* getDepotSaga({ payload: { locality } }) {
  try {
    yield put(actions.setLoadingAction('send-request'));
    const res = yield call(getDepots, { locality });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getDepotsReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getDepotsFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* getDepotDetailsSaga({ payload: { locality, depotId } }) {
  try {
    yield put(actions.setSnackbarAction({
      status: 'success',
      message: null
    }));
    const res = yield call(getDepotDetails, { locality, depotId });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getDepotDetailsReceive(res.data));
        yield put(actions.setSnackbarAction(res.data));
        if (res.data.data.display_addr === null
          && res.data.data.geo_loc !== null
          && !!res.data.data.geo_loc.length) {
          const temp1 = res.data.data.geo_loc.slice(1, res.data.data.geo_loc.length);
          const temp2 = temp1.slice(0, temp1.length - 1);
          yield put(actions.getLatLngRequest(temp2));
        }
        yield put(actions.getAddressReceive({ results: [] }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getDepotDetailsFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* deleteDepotSaga({ payload: { locality, depotId } }) {
  try {
    yield put(actions.setSnackbarAction({
      status: 'success',
      message: null
    }));
    const res = yield call(deleteDepot, { locality, depotId });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.deleteDepotReceive(depotId));
        yield put(actions.setSnackbarAction(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.deleteDepotFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* addDepotSaga({ payload: data }) {
  try {
    yield put(actions.setSnackbarAction({
      status: 'success',
      message: null
    }));
    const res = yield call(newDepot, { data });
    if (res.data) {
      if (res.data.status === 'success') {
        const newItem = { ...data, drop_depot_id: res.data.data.drop_depot_id };
        yield put(actions.addDepotReceive({ newItem }));
        yield put(actions.setSnackbarAction(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.addDepotFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* editDepotSaga({ payload: data }) {
  try {
    yield put(actions.setSnackbarAction({
      status: 'success',
      message: null
    }));
    const res = yield call(editDepot, { data });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.editDepotReceive({ data }));
        yield put(actions.setSnackbarAction(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.editDepotFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* getSuggestionAddressSaga({ payload: address }) {
  try {
    const res = yield call(getLatLng, { address });
    if (res.data) {
      if (res.data.status === 'OK') {
        yield put(actions.getAddressReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getAddressFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* getAddressFromLatLngSaga({ payload: latlng }) {
  try {
    const res = yield call(getAddress, { latlng });
    if (res.data) {
      if (res.data.status === 'OK') {
        yield put(actions.getLatLngReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getLatLngFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

/**
 * Saga watcher function
 */
function* depotSagas() {
  yield takeLatest(actionTypes.GET_ALL_DEPOTS_REQUEST, getDepotSaga);
  yield takeLatest(actionTypes.GET_DEPOTS_DETAILS_REQUEST, getDepotDetailsSaga);
  yield takeLatest(actionTypes.DELETE_DEPOT_REQUEST, deleteDepotSaga);
  yield takeLatest(actionTypes.ADD_DEPOT_REQUEST, addDepotSaga);
  yield takeLatest(actionTypes.EDIT_DEPOT_REQUEST, editDepotSaga);
  yield takeLatest(actionTypes.GET_GOOGLE_ADDRESS_REQUEST, getSuggestionAddressSaga);
  yield takeLatest(actionTypes.GET_GOOGLE_LATLANG_REQUEST, getAddressFromLatLngSaga);
}

export default depotSagas;
