import { put, call, takeLatest } from 'redux-saga/effects';

import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  getList,
  deleteReminder,
  loadReminder,
  getCollections,
  newReminder,
  editReminder,
} from '../apis';

function* getListSaga({ payload: { type, page } }) {
  yield put(actions.setLoadingAction());
  const res = yield call(getList, { type, page });

  if (res.data) {
    yield put(actions.reminderListReceive(res.data));
  } else {
    yield put(actions.reminderListFail(res.error.detail));
  }
}

function* deleteReminderSaga({ payload: { id, page } }) {
  const res = yield call(deleteReminder, { id });

  if (res.data) {
    yield put(actions.deleteReminderReceive(res.data));
    yield put(actions.reminderListRequest({ page }));
  } else {
    yield put(actions.deleteReminderFail(res.error.detail));
  }
}

function* loadReminderSaga({ payload: { id } }) {
  yield put(actions.setFormLoading(true));
  const res = yield call(loadReminder, { id });

  if (res.data) {
    if (res.data.data.district_list.length !== 1 && res.data.data.district_list[0].district_id !== '#all-districts#') {
      yield getCollectionsSaga({ payload: { district_id: res.data.data.district_list } });
    }
    yield put(actions.loadReminderReceive(res.data));
  } else {
    yield put(actions.loadReminderFail(res.error.detail));
  }
  yield put(actions.setFormLoading(false));
}

// fetch all collections
function* getCollectionsSaga({ payload: { district_id } }) {
  const res = yield call(getCollections, { district_id });
  if (res.data) {
    yield put(actions.getCollectionReceive(res.data));
  } else {
    yield put(actions.getCollectionFail(res.error.detail));
  }
}

// Create a new reminder
function* newReminderSaga({ payload: { reminder } }) {
  const res = yield call(newReminder, { reminder });
  if (res.data) {
    yield put(actions.newReminderReceive(res.data));
    yield put(actions.resetValueAction());
    yield put(actions.reminderListRequest({
      type: 'active',
      page: 1
    }));
  } else {
    yield put(actions.newReminderFail(res.error.detail));
  }
}

// Edit an existing reminder
function* editReminderSaga({ payload: { reminder } }) {
  const res = yield call(editReminder, { reminder });
  if (res.data) {
    yield put(actions.editReminderReceive(res.data));
    yield put(actions.resetValueAction());
    yield put(actions.reminderListRequest({
      type: 'active',
      page: 1
    }));
  } else {
    yield put(actions.editReminderFail(res.error.detail));
  }
}

/**
 * Saga watcher function
 */
function* reminderSagas() {
  yield takeLatest(actionTypes.REMINDER_LIST_REQUEST, getListSaga);
  yield takeLatest(actionTypes.DELETE_REMINDER_REQUEST, deleteReminderSaga);
  yield takeLatest(actionTypes.LOAD_REMINDER_REQUEST, loadReminderSaga);
  yield takeLatest(actionTypes.GET_COLLECTION_REQUEST, getCollectionsSaga);
  yield takeLatest(actionTypes.NEW_REMINDER_REQUEST, newReminderSaga);
  yield takeLatest(actionTypes.EDIT_REMINDER_REQUEST, editReminderSaga);
}

export default reminderSagas;
