import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../redux/createReducer';
import initialState from './store';

const depotReducer = persistReducer(
  { storage, key: 'depot', whitelist: [] },
  createReducer(initialState, {
    SET_DEPOT_LOADING_ACTION: (state, { payload }) => Immutable({
      ...state,
      fetchState: payload,
      responseMessage: initialState.responseMessage,
    }),

    SET_DEPOT_SNACKBAR_ACTION: (state, { payload }) => Immutable({
      ...state,
      snackbarState: payload.status,
      responseMessage: payload.message,
    }),

    SET_ADD_DEPOT_ACTION: (state, { payload }) => Immutable({
      ...state,
      addDepot: payload.flag,
    }),

    SET_NEW_DEPOT_ACTION: (state, { payload }) => Immutable({
      ...state,
      newDepot: {
        ...state.newDepot,
        [payload.propertyName]: payload.data
      },
    }),

    RESET_NEW_DEPOT_ACTION: (state) => Immutable({
      ...state,
      newDepot: initialState.newDepot
    }),

    GET_ALL_DEPOTS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      depotList: payload.data || [],
      fetchState: 'succeed-request'
    }),
    GET_ALL_DEPOTS_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    GET_DEPOTS_DETAILS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      newDepot: {
        id: payload.data.data.drop_depot_id,
        name: payload.data.data.depot_nm || '',
        instruction: payload.data.data.depot_details,
        open24: payload.data.data.is_open_all_time === 't' ? true : false || false,
        address: payload.data.data.display_addr,
        geoLocation: payload.data.data.geo_loc,
        phone: payload.data.data.phone_nbr || '',
        altPhone: payload.data.data.alt_phone_nbr || '',
        email: payload.data.data.email_addr || '',
        website: payload.data.data.web_addr || '',
        seasons: Array.isArray(payload.data.data.drop_depot_seasons)
        && payload.data.data.drop_depot_seasons[0].times
          ? payload.data.data.drop_depot_seasons : initialState.newDepot.seasons,
        drawers: [],
        assignCategory: []
      } || [],
    }),
    GET_DEPOTS_DETAILS_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    DELETE_DEPOT_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      depotList: state.depotList.filter((item) => item.drop_depot_id !== payload.data),
    }),
    DELETE_DEPOT_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    ADD_DEPOT_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      addDepot: 0,
      suggestAddress: [],
      depotList: [{
        drop_depot_admin_nm: payload.newItem.adminName,
        depot_nm: payload.newItem.name,
        display_addr: payload.newItem.address,
        depot_details: payload.newItem.details,
        drop_depot_seasons: payload.newItem.seasons,
        phone_nbr: payload.newItem.phone,
        alt_phone_nbr: payload.newItem.altPhone,
        email_addr: payload.newItem.email,
        web_addr: payload.newItem.website,
        drop_depot_id: payload.newItem.drop_depot_id,
        drawers: [],
        category_assigns: []
      }, ...state.depotList],
    }),

    EDIT_DEPOT_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      addDepot: 0,
      suggestAddress: [],
      depotList: state.depotList.map((depot) => (
        depot.drop_depot_id === payload.data.data.id
          ? {
            ...depot,
            depot_nm: payload.data.data.name,
            display_addr: payload.data.data.address,
            depot_details: payload.data.data.details,
            drop_depot_seasons: payload.data.data.seasons,
            phone_nbr: payload.data.data.phone,
            alt_phone_nbr: payload.data.data.altPhone,
            email_addr: payload.data.data.email,
            web_addr: payload.data.data.website,
            drop_depot_id: payload.data.data.id,
          }
          : depot
      ))
    }),

    GET_GOOGLE_ADDRESS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      suggestAddress: payload.data.results,
    }),

    GET_GOOGLE_LATLANG_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      newDepot: { ...state.newDepot, address: payload.data.results[0].formatted_address },
    }),

    SET_INITIAL_SEASON: (state) => Immutable({
      ...state,
      initialSeason: initialState.newDepot.seasons[0]
    }),
  })
);

export default depotReducer;
