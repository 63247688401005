import React from 'react';
import {
  Document, Page, Text, View, StyleSheet, Image
} from '@react-pdf/renderer';
import { addCommasToNumber } from '../../../../utils';
import { toAbsoluteUrl } from '../../../../../_metronic';
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    paddingVertical: 5
  },
  summaryHead: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 10,
    marginBottom: 0,
    padding: 10,
  },
  headerText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'black',
  },
  subTitleText: {
    fontSize: 12,
    color: 'black'
  },
  h2: {
    fontSize: '20pt'
  },
  h6: {
    fontSize: '12pt',
    fontWeight: 'bold'
  },
  marginLeft: {
    marginLeft: 15
  },
  body: {
    fontSize: '10pt'
  },
  image: {
    width: '110px',
    height: '40px',
    marginLeft: '13px',
    marginTop: '5px',
  },
  row: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '30px',
    paddingLeft: '10px',
  }
});

// Create Document Component
const ReportPDF = ({
  title,
  currentPeriod,
  date,
  district,
  data,
  isCounty,
  badgeUserTitle,
  badgePass,
  badgePercent,
  badgeHouseHold,
  badgeMunicipality,
  badgeOtherMunicipality,
}) => (
  <Document>
    {/* summary 's PDF */}
    <Page size="A4" orientation="landscape" style={styles.page}>
      <>
        <Image
          style={styles.image}
          src={`${toAbsoluteUrl('/media/logos/RCLogo.png')}`}
        />
        <View style={styles.summaryHead}>
          <Text style={styles.headerText}>
            {title}
          </Text>

          <Text
            style={[styles.subTitleText, styles.marginLeft,
              {
                color: process.env.REACT_APP_COLOR_DARK_GRAY,
              }
            ]}
          >
            {currentPeriod}
          </Text>

          <Text
            style={[styles.subTitleText, styles.marginLeft,
              { color: process.env.REACT_APP_COLOR_PRIMARY }]}
          >
            {date}
          </Text>

          <Text
            style={[styles.subTitleText, {
              marginLeft: isCounty ? 100 : 150
            }]}
          >
            {district}
          </Text>

        </View>

        {/* Detail  */}

        <View style={{
          width: '100%',
          marginRight: '20pt',
          minHeight: '25px',
          flexDirection: 'row',
          borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
          marginTop: '30px'
        }}
        >

          <View style={{
            width: '40%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            paddingBottom: '10px',
            paddingLeft: '5px'
          }}
          />

          <View style={{
            width: '15%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: '10px',
          }}
          >
            <Text style={styles.h6}>
              {badgeUserTitle}
            </Text>
          </View>
          <View
            style={{
              width: '15%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: '10px',
            }}
          >
            <Text style={styles.h6}>
              {badgePass}
            </Text>
          </View>

          <View
            style={{
              width: '7%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: '10px',
            }}
          >
            <Text style={styles.h6}>
              {badgePercent}
            </Text>
          </View>

          <View
            style={{
              width: '21%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: '10px',
              marginLeft: '1%',
              marginRight: '1%',
            }}
          >
            <Text style={styles.h6}>
              {badgeHouseHold}
            </Text>
          </View>

        </View>

        { data && Object.values(data).map((item, index) => (
          <View
            key={`badge_pdf_item_${String(index)}`}
            style={{
              display: 'flex',
              width: '100%',
              minHeight: '40px',
              flexDirection: 'column',
              borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
              paddingTop: '5px',
              paddingBottom: '5px'
            }}
          >
            <View style={{
              display: 'flex',
              minHeight: '25px',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              paddingLeft: '10px',
              paddingBottom: '5pt',
              borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
            }}
            >
              <Text style={styles.h6}>
                {item.title ? item.title : ''}
              </Text>
            </View>

            <FirstRow item={item} badgeMunicipality={badgeMunicipality} />

            <SecondRow item={item} badgeOtherMunicipality={badgeOtherMunicipality} />

          </View>
        ))}

      </>
    </Page>
  </Document>
);
export default ReportPDF;

/**
 *
create component
 */

export const FirstRow = ({
  item,
  badgeMunicipality
}) => (
  <View style={{
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }}
  >
    <View style={[styles.row, {
      width: '40%',
      alignItems: 'flex-start',
      justifyContent: 'center'
    }]}
    >
      <Text style={styles.body}>
        {badgeMunicipality}
      </Text>
    </View>

    <View style={[styles.row, {
      width: '15%',
    }]}
    >
      <Text style={styles.body}>
        {item.sum}
      </Text>
    </View>

    <View style={[styles.row, {
      width: '15%',
    }]}
    >
      <Text style={styles.body}>
        {item.value}
      </Text>
    </View>

    <View style={[styles.row, {
      width: '7%',
      backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_BG
    }]}
    >
      <Text style={styles.body}>
        {item.pass_percent}
      </Text>
    </View>

    <View style={[styles.row, {
      width: '20%',
      marginLeft: '15px',
      backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_BG
    }]}
    >
      <Text style={styles.body}>
        {item.house_hold}
      </Text>
    </View>

  </View>
);

export const SecondRow = ({
  item,
  badgeOtherMunicipality
}) => (
  <View style={{
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }}
  >
    <View style={[styles.row, {
      width: '40%',
      alignItems: 'flex-start',
      justifyContent: 'center'
    }]}
    >
      <Text style={styles.body}>
        {badgeOtherMunicipality}
      </Text>
    </View>

    <View style={{ width: '15%', }} />
    <View style={{ width: '15%', }} />

    <View style={[styles.row, {
      width: '7%',
      backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_BG
    }]}
    >
      <Text style={styles.body}>
        {`${addCommasToNumber(Number(item.other_pass).toFixed(2))} %` || ''}
      </Text>
    </View>

    <View style={[styles.row, {
      width: '20%',
      marginLeft: '15px',
      backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_BG
    }]}
    >
      <Text style={styles.body}>
        {addCommasToNumber(Number(item.other_house_hold).toFixed(2)) || ''}
      </Text>
    </View>

  </View>
);
