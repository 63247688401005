import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Immutable from 'seamless-immutable';

/**
 * Custom AutoComplete input based on material-ui AutoComplete.
 * @param {string} title Title that we want to display in input.
 * @param {string} placeholder Optional placeholder that we want to display in input.
 * @param {array} options Options that user can select from them.
 * @param {array} optionTitle Determines which property in option must be displayed as label.
 * @param {boolean} isMulti Determines whether our input is a multiSelect or not.
 * @param {boolean} isDisable Determines whether our input is disabled or not.
 * @param {function} onChange It is called when input changes.
 * @param {any} value The value passed from redux or other sources.
 * @example
 *  <AutoComplete
      title="City"
      placeholder="Select one or more cities"
      options={[{ city_nm: 'London' }, { city_nm: 'Paris' }, { city_nm: 'Berlin' },]}
      optionTitle="city_nm"
      isMulti
      isDisable={false}
      onChange={setProject}
      value={value}
    />
 */
const AutoComplete = ({
  className,
  title,
  options,
  disabledOptions,
  optionTitle = 'title',
  optionId = 'id',
  isMulti = false,
  isDisable = false,
  disableClearable = true,
  onChange,
  onInputChange,
  value,
  invalidText,
  onFocus,
  groupBy
}) => {
  const useStyles = makeStyles(() => ({
    root: {
      position: 'relative',
      backgroundColor: process.env.REACT_APP_COLOR_SECONDARY,
      '& label': {
        transform: 'translate(14px, -6px) scale(0.75)',
        zIndex: 2
      },
      '& legend': {
        maxWidth: '1000px',
        transition: 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms'
      }
    },
  }));

  const [showError, setShowError] = useState(false);
  const classes = useStyles();

  return (
    <MuiAutocomplete
      className={className}
      classes={{
        root: classes.root
      }}
      value={value ? Immutable.asMutable(value) : value}
      getOptionSelected={(option, value) => (option[optionId] === value[optionId])}
      groupBy={groupBy ? (option) => groupBy(option) : null}
      getOptionDisabled={disabledOptions}
      options={options}
      getOptionLabel={(option) => option[optionTitle] || ''}
      disabled={isDisable}
      multiple={isMulti}
      disableCloseOnSelect={isMulti}
      openOnFocus
      autoHighlight
      disableClearable={disableClearable}
      onChange={(event, value) => onChange(value)}
      onInputChange={(event, value) => (onInputChange ? onInputChange(value) : null)}
      onFocus={() => {
        setShowError(true);
        if (onFocus) {
          onFocus();
        }
      }}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          variant="outlined"
          label={title}
          error={showError && !isDisable && !!invalidText}
          helperText={showError && !isDisable && !!invalidText ? invalidText : null}
        />
      )}
    />
  );
};

export default AutoComplete;
