import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  Document, Packer, Paragraph, TextRun, Header, HeadingLevel, ExternalHyperlink
} from 'docx';
import moment from 'moment';

import { Typography } from '../../../components';

import { toAbsoluteUrl } from '../../../../../_metronic';

const useStyles = makeStyles(() => ({
  downloadButton: {
    width: 478,
    height: 48,
    left: 912,
    top: 464,
    background: '#0086C2',
    borderRadius: 4,
    color: '#fff',
    fontWeight: '700'
  },
  downloadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 6
  },
  pressReleaseTitle: {
    marginTop: 20,
    marginBottom: 6
  },
  downloadIcon: {
    marginBottom: 25
  },
  headerHeight: {
    height: 75,
  },
  buttonContainer: {
    borderRadius: 16,
    marginRight: 6,
    marginBottom: 10,
    maxWidth: 153
  },
}));

const PressRelease = ({
  data,
  district,
  intl,
  selectedYear
}) => {
  const classes = useStyles();

  const createParagraph = (data) => new Paragraph({
    children: [
      new TextRun({
        text: data.text,
        bold: data.bold,
      }),
    ]
  });

  const createBullet = (data) => new Paragraph({
    text: data.text,
    bullet: {
      level: 0
    }
  });

  const downloadPressRelease = () => {
    const lastYearAll = data.correct_answer.all.last_yr;
    const thisYearAll = data.correct_answer.all.this_yr;
    const nationalAll = data.correct_answer.all.national;
    const percentChange = (thisYearAll - lastYearAll) / (thisYearAll * 100);
    const currentDate = moment().format('MMM. D, YYYY');
    let title = 'Residents Show Incredible Increase in Understanding of Local Recycling Procedures in Latest Annual Survey';
    if (percentChange >= 5) {
      title = 'Residents Show Incredible Increase in Understanding of Local Recycling Procedures in Latest Annual Survey';
    } else if (percentChange >= 1 && percentChange < 5) {
      title = 'Residents Show Some Improved Understanding of Local Recycling Procedures in Latest Annual Survey';
    } else if (percentChange >= 0 && percentChange < 1) {
      title = 'Residents Maintain Understanding of Local Recycling Procedures as in Latest Annual Survey';
    } else if (percentChange <= -1 && percentChange > -8) {
      title = 'Knowledge About Local Recycling Dips in Latest Annual Survey';
    } else {
      title = 'Knowledge About Local Recycling Drops in Latest Annual Survey';
    }

    const pressReleaseData = [
      {
        text: '',
        bold: false,
        bullets: []
      },
      {
        text: `${district.district_nm} residents show their level of understanding of local recycling procedures in Annual Survey from Recycle Coach.`,
        bold: true,
        bullets: []
      },
      {
        text: '',
        bold: false,
        bullets: []
      },
      {
        text: `${district.district_nm}, ${currentDate} – The ${district.district_nm}, partnering with Recycle Coach, announce today the local results from a nationwide recycling survey. Recycle Coach, a communication platform for residents to obtain information about their local recycling program, issued an Annual Recycling Knowledge Survey during the month of September ${selectedYear} meant to measure residents’ awareness of local recycling rules. `,
        bold: false,
        bullets: []
      },
      {
        text: '',
        bold: false,
        bullets: []
      },
      {
        text: 'The Survey Details:',
        bold: true,
        bullets: []
      },
      {
        text: '',
        bold: false,
        bullets: [
          'Residents identified the recyclability of 27 materials ranging from commonly accepted items to regularly misunderstood items.',
          'The survey was created to measure resident understanding of the specific local recycling requirements of their municipality.',
          `Over ${data.total_participating_cities} municipalities participated in the survey.`
        ]
      },
      {
        text: '',
        bold: false,
        bullets: []
      },
      {
        text: `Overall Results for the ${district.district_nm}`,
        bold: true,
        bullets: []
      },
      {
        text: '',
        bold: false,
        bullets: []
      },
      {
        text: `Last year the overall correct answer rate was ${lastYearAll}%, compared to ${thisYearAll}% this year.  The national average for correct answers was ${nationalAll}%.`,
        bold: false,
        bullets: []
      },
      {
        text: '',
        bold: false,
        bullets: []
      },
      {
        text: 'Materials Residents Know Best',
        bold: true,
        bullets: []
      },
      {
        text: 'While they excelled in many materials, the top three materials local residents knew best were:',
        bold: false,
        bullets: []
      },
      {
        text: '',
        bold: false,
        bullets: [
          `${data.mats_know_best[0] && data.mats_know_best[0].mat_nm
            ? `${data.mats_know_best[0].mat_nm} which ${data.mats_know_best[0].mat_nm.slice(-1) === 's'
              ? 'are'
              : 'is'} ${data.mats_know_best[0].accepted
              ? 'accepted' : 'not accepted'} for recycling.`
            : ''} `,
          `${data.mats_know_best[1] && data.mats_know_best[1].mat_nm
            ? `${data.mats_know_best[1].mat_nm} which ${data.mats_know_best[1].mat_nm.slice(-1) === 's'
              ? 'are'
              : 'is'} ${data.mats_know_best[1].accepted
              ? 'accepted' : 'not accepted'} for recycling.`
            : ''} `,
          `${data.mats_know_best[2] && data.mats_know_best[2].mat_nm
            ? `${data.mats_know_best[2].mat_nm} which ${data.mats_know_best[2].mat_nm.slice(-1) === 's'
              ? 'are'
              : 'is'} ${data.mats_know_best[2].accepted
              ? 'accepted' : 'not accepted'} for recycling.`
            : ''} `
        ]
      },
      {
        text: `${!data.mats_know_best.length ? 'Cannot Display Data' : ''}`,
        bold: true,
        bullets: []
      },
      {
        text: '',
        bold: false,
        bullets: []
      },
      {
        text: 'Misunderstood Materials',
        bold: true,
        bullets: []
      },
      {
        text: 'The three materials that had the highest error rates were:',
        bold: false,
        bullets: []
      },
      {
        text: '',
        bold: false,
        bullets: [
          `${data.mats_misunderstood[0] && data.mats_misunderstood[0].mat_nm
            ? `${data.mats_misunderstood[0].mat_nm} which ${data.mats_misunderstood[0].mat_nm.slice(-1) === 's'
              ? 'are'
              : 'is'} ${data.mats_misunderstood[0].accepted
              ? 'accepted' : 'not accepted'} for recycling.`
            : ''} `,
          `${data.mats_misunderstood[1] && data.mats_misunderstood[1].mat_nm
            ? `${data.mats_misunderstood[1].mat_nm} which ${data.mats_misunderstood[1].mat_nm.slice(-1) === 's'
              ? 'are'
              : 'is'} ${data.mats_misunderstood[1].accepted
              ? 'accepted' : 'not accepted'} for recycling.`
            : ''} `,
          `${data.mats_misunderstood[2] && data.mats_misunderstood[2].mat_nm
            ? `${data.mats_misunderstood[2].mat_nm} which ${data.mats_misunderstood[2].mat_nm.slice(-1) === 's'
              ? 'are'
              : 'is'} ${data.mats_misunderstood[2].accepted
              ? 'accepted' : 'not accepted'} for recycling.`
            : ''} `
        ]
      },
      {
        text: `${!data.mats_misunderstood.length ? 'Cannot Display Data' : ''}`,
        bold: true,
        bullets: []
      },
      {
        text: '',
        bold: false,
        bullets: []
      },
      {
        text: 'Most Improved',
        bold: true,
        bullets: []
      },
      {
        text: 'In possibly the most important classification, the three materials where residents improved their knowledge this year were:',
        bold: false,
        bullets: []
      },
      {
        text: '',
        bold: false,
        bullets: [
          `${data.mats_improved[0] && data.mats_improved[0].mat_nm
            ? `${data.mats_improved[0].mat_nm} which ${data.mats_improved[0].mat_nm.slice(-1) === 's'
              ? 'are'
              : 'is'} ${data.mats_improved[0].accepted
              ? 'accepted' : 'not accepted'} for recycling.`
            : ''} `,
          `${data.mats_improved[1] && data.mats_improved[1].mat_nm
            ? `${data.mats_improved[1].mat_nm} which ${data.mats_improved[1].mat_nm.slice(-1) === 's'
              ? 'are'
              : 'is'} ${data.mats_improved[1].accepted
              ? 'accepted' : 'not accepted'} for recycling.`
            : ''} `,
          `${data.mats_improved[2] && data.mats_improved[2].mat_nm
            ? `${data.mats_improved[2].mat_nm} which ${data.mats_improved[2].mat_nm.slice(-1) === 's'
              ? 'are'
              : 'is'} ${data.mats_improved[2].accepted
              ? 'accepted' : 'not accepted'} for recycling.`
            : ''} `
        ]
      },
      {
        text: `${!data.mats_improved.length ? 'Cannot Display Data' : ''}`,
        bold: true,
        bullets: []
      },
      {
        text: '',
        bold: false,
        bullets: []
      },
      {
        text: '',
        bold: false,
        bullets: []
      },
      {
        text: 'About Recycle Coach',
        bold: true,
        bullets: []
      },
      {
        text: '',
        bold: false,
        bullets: []
      },
      {
        text: 'Recycle Coach is dedicated to providing municipalities with the tools to make local waste and recycling program rules and regulations accessible to residents on every platform and every device. The Recycle Coach mobile app allows residents to view their personal recycling schedules, get pick-up reminders, search the ‘What Goes Where’ tool, and engage in additional recycling education activities. ',
        bold: false,
        bullets: []
      },
      {
        text: '',
        bold: false,
        bullets: []
      },
    ];

    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              text: title,
              heading: HeadingLevel.TITLE,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: '',
                  bold: false,
                }),
              ],
            }),
            ...pressReleaseData.map((PRDATA) => {
              const arr = [];
              if (PRDATA.bullets.length) {
                PRDATA.bullets.forEach((bullet) => {
                  if (bullet.trim().length) {
                    arr.push(createBullet({
                      text: bullet,
                    }));
                  }
                });
              }
              arr.push(createParagraph(PRDATA));
              return arr;
            })
              .reduce((prev, curr) => prev.concat(curr), []),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'LinkedIn: @RecycleCoach Facebook: @RecycleCoachApp ',
                }),
                new ExternalHyperlink({
                  children: [
                    new TextRun({
                      text: 'https://www.recyclecoach.com',
                      style: 'Hyperlink',
                    }),
                  ],
                  link: 'https://www.recyclecoach.com',
                }),
              ],
            }),
          ]
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      const a = document.createElement('a');
      a.download = 'Annual Survey Press Release.docx';
      a.href = URL.createObjectURL(blob);
      a.addEventListener('click', () => {
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
      });
      a.click();
    });
  };
  return (
    <div>
      {intl.formatMessage({ id: 'REPORT.NATIONAL_SURVEY.PRESS.RELEASE.TITLE' })}
      <Typography className={`${classes.pressReleaseTitle} mr-4`} variant="h1">
        {
          data.country_cd !== 'US' && (
            <div>Press Release</div>
          )
        }
        {
          data.country_cd === 'US' && (
            <div>America Recycles Day press release</div>
          )
        }
      </Typography>
      <p>{intl.formatMessage({ id: 'REPORT.NATIONAL_SURVEY.PRESS.RELEASE.SUBTITLE' })}</p>
      <div className={`${classes.downloadContainer}`}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
          alt="Download"
          src={toAbsoluteUrl('/media/icons/download.svg')}
          className={`${classes.downloadIcon}`}
          onClick={() => downloadPressRelease()}
        />
        <button
          type="button"
          className={`${classes.downloadButton}`}
          onClick={() => downloadPressRelease()}
        >
          Download a personalized draft press release
        </button>
      </div>

    </div>
  );
};

export default injectIntl(PressRelease);
