import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker as MuiDatePicker } from '@material-ui/pickers';

const DatePicker = ({
  label,
  className = '',
  format = 'yyyy/MM/dd',
  value,
  onChange,
  clearable = true,
  disabled = false,
  minDate,
  maxDate,
  invalidText,
  onFocus
}) => {
  const useStyles = makeStyles(() => ({
    root: {
      position: 'relative',
      backgroundColor: process.env.REACT_APP_COLOR_SECONDARY,
      '& label': {
        transform: 'translate(14px, -6px) scale(0.75)',
        backgroundColor: 'white',
        zIndex: 2
      },
      '& legend': {
        maxWidth: '1000px',
        transition: 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms'
      },
    },
  }));

  const [showError, setShowError] = useState(false);
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiDatePicker
        margin="normal"
        className={className}
        classes={{
          root: classes.root
        }}
        format={format}
        inputVariant="outlined"
        label={label}
        clearable={clearable}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        value={typeof value === 'string' ? value.replace('-', '/') : value}
        onChange={onChange}
        onClose={() => {
          setShowError(true);
          if (onFocus) {
            onFocus();
          }
        }}
        TextFieldComponent={(params) => (
          <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            error={showError && !disabled && !!invalidText}
            helperText={showError && !disabled && !!invalidText ? invalidText : null}
          />
        )}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
