/* eslint-disable import/no-cycle */
import { all } from 'redux-saga/effects';

import sharedSagas from '../pages/redux/sagas';
import authSagas from '../pages/Auth/redux/sagas';
import notificationSagas from '../pages/Notification/redux/sagas';
import reminderSagas from '../pages/Reminder/redux/sagas';
import userSagas from '../pages/Users/redux/sagas';
import userReportSagas from '../pages/Report/User/redux/sagas';
import annualSurveySagas from '../pages/Report/AnnualSurvey/redux/sagas';
import interactionReportSagas from '../pages/Report/Interaction/redux/sagas';
import materialReportSagas from '../pages/Report/Material/redux/sagas';
import newSubscribersReportSagas from '../pages/Report/NewSubscribers/redux/sagas';
import pageViewsReportSagas from '../pages/Report/PageViews/redux/sagas';
import problemReportSagas from '../pages/Report/Problem/redux/sagas';
import campaignReportSagas from '../pages/Report/Campaign/redux/sagas';
import categorySagas from '../pages/Category/redux/sagas';
import depotSagas from '../pages/Depot/redux/sagas';
import eventSagas from '../pages/Event/redux/sagas';
import wasteTypeReducerSagas from '../pages/WasteTypes/redux/sagas';
import collectionRequirementSagas from '../pages/CollectionRequirement/redux/sagas';
import discoveryZoneReportSaga from '../pages/Report/DiscoveryZone/redux/sagas';
import residentKnowledgeIndicatorReportSaga from '../pages/Report/ResidentKnowledgeIndicator/redux/sagas';
import BadgeReportSaga from '../pages/Report/Badge/redux/sagas';
import wasteAndRulesSagas from '../pages/WasteAndRecyclingRules/redux/sagas';
import scheduleSagas from '../pages/Schedule/redux/sagas';
import educationBlogsSagas from '../pages/Education/EducationalContent/Blogs/redux/sagas';
import dashboardSaga from '../pages/Dashboard/redux/sagas';
import WGWHomeSagas from '../pages/WGWHome/redux/sagas';
import WWtKSaga from '../pages/Education/EducationalContent/WeWantedToKnow/redux/sagas';
import EducationCommonSagas from '../pages/Education/EducationalContent/redux/sagas';
import whereDoesThisGoSaga from '../pages/Education/EducationalContent/WhereDoesThisGo/redux/sagas';
import recyclingForKidsGoSaga from '../pages/Education/EducationalContent/RecyclingForKids/redux/sagas';
import featuredLessonSaga from '../pages/Education/EducationalContent/FeaturedLessons/redux/sagas';
import pdfAddOnsSaga from '../pages/Education/EducationalContent/PdfAddOns/redux/sagas';
import recyclingFundamentalsSaga from '../pages/Education/SurveysAndMore/redux/sagas';
import lessonLibrarySaga from '../pages/Education/EducationalContent/LessonLibrary/redux/sagas';
import educationBlogsLibrarySagas from '../pages/Education/EducationalContent/BlogLibrary/redux/sagas';
import ReportDashboardSaga from '../pages/Report/ReportDashboardNew/redux/sagas';
import MajorCampaignsSaga from '../pages/Education/EducationalContent/MajorCampaigns/redux/sagas';
import PerpetualScheduleSagas from '../pages/Schedule/PerpetualSchedules/redux/sagas';


function* rootSaga() {
  yield all([
    sharedSagas(),
    authSagas(),
    notificationSagas(),
    reminderSagas(),
    userSagas(),
    userReportSagas(),
    annualSurveySagas(),
    interactionReportSagas(),
    materialReportSagas(),
    newSubscribersReportSagas(),
    pageViewsReportSagas(),
    problemReportSagas(),
    campaignReportSagas(),
    categorySagas(),
    depotSagas(),
    eventSagas(),
    wasteTypeReducerSagas(),
    collectionRequirementSagas(),
    discoveryZoneReportSaga(),
    residentKnowledgeIndicatorReportSaga(),
    BadgeReportSaga(),
    wasteAndRulesSagas(),
    scheduleSagas(),
    educationBlogsSagas(),
    dashboardSaga(),
    WGWHomeSagas(),
    WWtKSaga(),
    EducationCommonSagas(),
    whereDoesThisGoSaga(),
    recyclingForKidsGoSaga(),
    featuredLessonSaga(),
    pdfAddOnsSaga(),
    recyclingFundamentalsSaga(),
    lessonLibrarySaga(),
    educationBlogsLibrarySagas(),
    ReportDashboardSaga(),
    MajorCampaignsSaga(),
    PerpetualScheduleSagas(),
  ]);
}

export default rootSaga;
