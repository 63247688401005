/**
 *
 */
const initialState = {
  fetchState: 'no-request',
  data: {},
  summaryData: {},
  helpItems: [],
  firstOtherProblemTypeItems: ''
};

export default initialState;
