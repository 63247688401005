const actionTypes = {
  // Auth actionTypes for setting loading status
  SET_LOADING: 'SET_LOADING',
  RESET_LOADING: 'RESET_LOADING',

  // Reset Password actionTypes for getting user's full name
  GET_NAME_REQUEST: 'GET_NAME_REQUEST',
  GET_NAME_RECEIVE: 'GET_NAME_RECEIVE',
  GET_NAME_FAIL: 'GET_NAME_FAIL',

  // Reset Password actionTypes for changing password
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_RECEIVE: 'RESET_PASSWORD_RECEIVE',
  RESET_PASSWORD_FAIL: 'RESET_PASSWORD_FAIL',
};

export default actionTypes;
