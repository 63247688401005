import actionTypes from './actionTypes';

// Auth actions for setting loading status
export const setLoadingAction = () => ({
  type: actionTypes.SET_LOADING
});

export const resetLoadingAction = () => ({
  type: actionTypes.RESET_LOADING
});

// Login actions
export const loginRequest = (username, password) => ({
  type: actionTypes.LOGIN_REQUEST,
  payload: { username, password }
});

export const loginReceive = (data) => ({
  type: actionTypes.LOGINـRECEIVE,
  payload: { ...data }
});

export const loginFail = (error) => ({
  type: actionTypes.LOGINـFAIL,
  payload: { ...error }
});

export const updateUserInfoManagesSchedules = (manages_schedules) => ({
  type: actionTypes.UPDATE_USER_INFO_MANAGES_SCHEDULES,
  payload: { manages_schedules }
});
