import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReCAPTCHA from 'react-google-recaptcha';

import * as actions from './redux/actions';
import {
  Body,
  Title,
  Form,
  Alert,
  InputField,
  Action,
  Submit,
} from '../components';


const Login = ({
  intl,
  loginRequest,
  resetLoadingAction,
  fetchState,
  responseMessage
}) => {
  const recaptchaRef = React.createRef();

  const [failedLoginAttemptCounter, setFailedLoginAttemptCounter] = useState(0);
  const [resetRecaptchaCounter, setResetRecaptchaCounter] = useState(0);
  const [verifiedRecaptcha, setVerifiedRecaptcha] = useState(false);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);


  const isSucceedRequest = fetchState === 'succeed-request';
  const isSendRequest = fetchState === 'send-request';
  const maxedAllowedLoginAttempts = 2;
  const recaptchaResponseMessage = { status: 'error', message: intl.formatMessage({ id: 'AUTH.VALIDATION.RECAPTCHA' }) };

  useEffect(() => {
    if (responseMessage && responseMessage.status === 'error' && !isSucceedRequest && !isSendRequest) {
      setFailedLoginAttemptCounter(failedLoginAttemptCounter + 1);
      if (verifiedRecaptcha && resetRecaptchaCounter <= maxedAllowedLoginAttempts) {
        setResetRecaptchaCounter(resetRecaptchaCounter + 1);
      } else if (resetRecaptchaCounter >= maxedAllowedLoginAttempts) {
        if (recaptchaRef && recaptchaRef.current) recaptchaRef.current.reset();
        setResetRecaptchaCounter(0);
        setVerifiedRecaptcha(false);
      }
    }
  }, [responseMessage, isSucceedRequest, isSendRequest]);

  if (isSucceedRequest) {
    resetLoadingAction();
    return <Redirect to="/auth/mfa" />;
  }

  const onSubmit = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      setVerifiedRecaptcha(true);
      loginRequest(username, password);
    }
  };

  const submitLogin = (values) => {
    if ((failedLoginAttemptCounter <= maxedAllowedLoginAttempts && !verifiedRecaptcha) || (failedLoginAttemptCounter > maxedAllowedLoginAttempts && verifiedRecaptcha)) {
      loginRequest(values.username, values.password);
    }
  };

  return (
    <Body>
      <Title text="AUTH.LOGIN.TITLE" />
      <Formik
        initialValues={{
          username: '',
          password: ''
        }}
        validate={(values) => {
          const errors = {};

          if (!values.username) {
            errors.username = intl.formatMessage({
              id: 'VALIDATION.REQUIRED_FIELD'
            });
          }

          if (!values.password) {
            errors.password = intl.formatMessage({
              id: 'VALIDATION.REQUIRED_FIELD'
            });
          }
          setUsername(values.username);
          setPassword(values.password);
          return errors;
        }}
        onSubmit={(values) => submitLogin(values)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Alert condition={responseMessage} responseMessage={failedLoginAttemptCounter > maxedAllowedLoginAttempts && !verifiedRecaptcha ? recaptchaResponseMessage : responseMessage} />
            <InputField
              type="text"
              label={intl.formatMessage({ id: 'AUTH.INPUT.USERNAME' })}
              name="username"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.username}
              helperText={touched.username && errors.username}
              error={Boolean(touched.username && errors.username)}
            />
            <InputField
              type="password"
              label={intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              helperText={touched.password && errors.password}
              error={Boolean(touched.password && errors.password)}
            />
            {
              failedLoginAttemptCounter > maxedAllowedLoginAttempts && (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                  onChange={onSubmit}
                />
              )
            }

            <Action>
              <Link
                to="/auth/forgot-password"
                className="kt-link kt-login__link-forgot"
              >
                <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
              </Link>
              <Submit title="AUTH.LOGIN.BUTTON" isSubmitting={isSendRequest} />
            </Action>
          </Form>
        )}
      </Formik>
    </Body>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.auth.fetchState,
  responseMessage: store.auth.responseMessage
});

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(Login)
);
