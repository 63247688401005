import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import actionTypes from './actionTypes';
import { sendSubmittedPhoto, sendSuggestedNewTerm } from '../apis';

function* sendSubmittedPhotoSaga({ payload: { image, material_id } }) {
  const response = yield call(sendSubmittedPhoto, { image, material_id });
  try {
    yield put(actions.sendSubmittedPhotoReceiveAction(response.data));
  } catch (error) {
    yield put(actions.sendSubmittedPhotoFailAction(response.error.detail.data));
  }
}

function* sendSuggestedNewTermSaga({ payload: { synonym, additional_comments } }) {
  const response = yield call(sendSuggestedNewTerm, { synonym, additional_comments });
  try {
    yield put(actions.sendSuggestedNewTermReceiveAction(response.data));
  } catch (error) {
    yield put(actions.sendSuggestedNewTermFailAction(response.error.detail.data));
  }
}

function* WGWHomeSagas() {
  yield takeLatest(actionTypes.SEND_SUBMITTED_PHOTO_REQUEST, sendSubmittedPhotoSaga);
  yield takeLatest(actionTypes.SEND_SUGGESTED_NEW_TERM_REQUEST, sendSuggestedNewTermSaga);
}

export default WGWHomeSagas;
