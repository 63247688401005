/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import TelegramIcon from '@material-ui/icons/Telegram';
import clsx from 'clsx';
import { addCommasToNumber } from '../../../utils';

import * as actions from '../redux/actions';
import {
  Dialog,
  IconButton,
  Typography,
  Pagination
} from '../../components';
import { ListLoading } from '../../components/loading';
import EditDate from './EditDate';
import {
  DeleteIcon,
  EditIcon,
  EmergencyIcon,
  CloseIcon,
} from '../../../assets/icons';

const useStyles = makeStyles((theme) => ({
  expanded: {
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_BG,
    boxShadow: `-15px 0px 0px 0px ${process.env.REACT_APP_COLOR_PRIMARY_BG}, 15px 0px 0px 0px ${process.env.REACT_APP_COLOR_PRIMARY_BG}`,
  },
  root: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    }
  },
  header: {
    fontSize: '18px',
    fontWeight: 700,
    justifyContent: 'flex-start',
    color: process.env.REACT_APP_COLOR_PRIMARY,
    textAlign: 'left',
    textTransform: 'none',
  },
  close: {
    fontSize: '15px',
    fontWeight: 600,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_PRIMARY,
  },
  content: {
    fontSize: '12px',
    fontWeight: 400,
  },
  extra: {
    borderBottom: `1px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  primaryLight: {
    color: process.env.REACT_APP_COLOR_PRIMARYLight,
  },
  savedOn: {
    color: process.env.REACT_APP_COLOR_TERTIARY_DARK,
  },
  editLastBy: {
    color: process.env.REACT_APP_COLOR_NEUTRAL,
  },
  emptyList: {
    color: process.env.REACT_APP_COLOR_BACKGROUND_FILL,
    marginTop: theme.spacing(30)
  },
  pagination: {
    width: 'max-content',
    marginLeft: theme.spacing(5),
    '& ul': {
      justifyContent: 'flex-end',
      width: 'max-content',
    }
  },
}));

const CustomList = ({
  intl,
  type,
  fetchState,
  notificationListRequest,
  deleteNotificationRequest,
  editDeleteDateRequest,
  sendNowRequest,
  loadNotificationRequest,
  notificationList,
  notificationCount,
  municipalities
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);
  const [page, setPage] = useState(1);
  const isScheduled = type === 'SCHEDULE';
  const isSent = type === 'SENT';
  const isSendRequest = fetchState === 'send-request';

  useEffect(() => {
    notificationListRequest({
      type,
      page
    });
  }, [type, page]);

  const handlePaginationChange = (page) => {
    setPage(page);
    notificationListRequest({
      type,
      page
    });
  };
  return (
    <div className="h-100 d-flex flex-column justify-content-between">
      {
        isSendRequest
          ? (
            <div className="w-100" style={{ paddingTop: isSent ? 0 : 10 }}>
              <ListLoading width="100%" />
            </div>
          ) : (
            <>
              <div>
                {notificationCount ? notificationList.map((item, index) => (
                  <div
                    key={item.id}
                    className={expanded === item.id ? classes.expanded : null}
                  >
                    {!isSendRequest ? (
                      <>
                        <div
                          className={`row ${clsx({ 'pt-4': !isSent || index !== 0 })}`}
                        >
                          <Button
                            className="col-xl-10 pb-0"
                            classes={{
                              root: classes.root,
                              label: classes.header,
                            }}
                            onClick={() => setExpanded(item.id)}
                            startIcon={item.communication_type === 'Eme' && (
                            <EmergencyIcon color={process.env.REACT_APP_COLOR_DELETE} />
                            )}
                          >
                            {item.title}
                          </Button>
                          {expanded === item.id
                            ? (
                              <div className="col-xl-2 d-flex justify-content-end">
                                <Button
                                  className="pb-0"
                                  classes={{
                                    root: classes.root,
                                    label: classes.close,
                                  }}
                                  onClick={() => setExpanded(null)}
                                  endIcon={
                                    <CloseIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                                  }
                                >
                                  {intl.formatMessage({ id: 'GENERAL.CLOSE' })}
                                </Button>
                              </div>
                            ) : (
                              <div className="col-xl-2 d-flex justify-content-end align-items-center">
                                {isScheduled && (
                                <>
                                  <Dialog
                                    button={(
                                      <IconButton tooltip={intl.formatMessage({ id: 'GENERAL.SEND' })}>
                                        <TelegramIcon color="primary" />
                                      </IconButton>
                                    )}
                                    title={intl.formatMessage({ id: 'NOTIFICATION.MODAL.IMMEDIATELY.SEND.TITLE' })}
                                    content={(
                                      <Typography variant="body">
                                        {intl.formatMessage({ id: 'NOTIFICATION.MODAL.IMMEDIATELY.SEND.TEXT' })}
                                      </Typography>
                                    )}
                                    submitText={intl.formatMessage({ id: 'GENERAL.SEND' })}
                                    handleSubmit={() => sendNowRequest({
                                      id: item.id,
                                      type: 'SENT',
                                      page,
                                    })}
                                  />
                                </>
                                )}
                                <IconButton
                                  tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
                                  onClick={() => loadNotificationRequest(item.id)}
                                >
                                  <EditIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                                </IconButton>
                                <Dialog
                                  button={(
                                    <IconButton tooltip={intl.formatMessage({ id: 'GENERAL.DELETE' })}>
                                      <DeleteIcon color={process.env.REACT_APP_COLOR_DELETE} />
                                    </IconButton>
                                  )}
                                  title={intl.formatMessage({ id: 'GENERAL.DELETE' })}
                                  content={(
                                    <Typography variant="body">
                                      {intl.formatMessage({ id: 'NOTIFICATION.MODAL.DELETE' })}
                                    </Typography>
                                  )}
                                  submitText={intl.formatMessage({ id: 'GENERAL.DELETE' })}
                                  handleSubmit={() => deleteNotificationRequest({
                                    id: item.id,
                                    type,
                                    page,
                                  })}
                                />
                              </div>
                            )}
                        </div>
                        <Typography variant="cta">
                          {item.communication_type_name}
                        </Typography>
                        {expanded === item.id ? (
                          <>
                            <Typography variant="body">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.description
                                }}
                              />
                            </Typography>
                            {item.notif_msg && (
                            <>
                              <Typography className="mt-4" variant="body">
                                {intl.formatMessage({ id: 'NOTIFICATION.SMS_MESSAGE.TITLE' })}
                              </Typography>
                              <Typography className="mb-3" variant="body">
                                {item.notif_msg}
                              </Typography>
                            </>
                            )}
                          </>
                        ) : (
                          <Typography variant="body">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${item.description.substring(0, 120)} ${item.description.length > 120 ? ' ...' : ''}`
                              }}
                            />
                          </Typography>
                        )}
                        <div className={`${classes.extra} mt-3 pb-3`}>
                          <Typography variant="body">
                            <span>{intl.formatMessage({ id: 'NOTIFICATION.DISTRICT' })}</span>
                            {' '}
                            <strong>
                              {
                                  municipalities.length === 1
                                    ? municipalities[0].district_nm : item.district_nm
                              }
                            </strong>
                          </Typography>
                          <Typography variant="body">
                            <span>Zones:</span>
                            {' '}
                            <strong>
                              {
                              item.zone_nm
                              }
                            </strong>
                          </Typography>
                          {!isSent ? (
                            <>
                              <div className="d-flex justify-content-end">
                                <Typography variant="body">
                                  <span className={classes.savedOn}>
                                    { isScheduled ? 'Scheduled For: ' : 'Saved On: '}
                                  </span>
                                  <strong>
                                    {isScheduled && `${item.send_date}  at ${item.send_time.slice(0, 8)}`}
                                    {!isScheduled && `${item.save_date} at ${item.update_ts.slice(0, 8)}`}
                                  </strong>
                                </Typography>
                              </div>
                              <div className="d-flex justify-content-end">
                                <Typography variant="body">
                                  <span className={classes.editLastBy}>
                                    {' '}
                                    {isScheduled ? 'Scheduled By: ' : 'Saved by: ' }
                                  </span>
                                  <strong>
                                    {item.save_user_email}
                                  </strong>
                                </Typography>
                              </div>
                            </>
                          ) : (
                            <>
                              {/* showing how many people
                              the notification was sent to */}
                              <Typography variant="body">
                                <span className={classes.primaryLight}>Recipients :</span>
                                {' '}
                                <strong>
                                  {addCommasToNumber(item.recipients) || 'none'}
                                </strong>
                              </Typography>
                              <Typography variant="body">
                                <span className={classes.primaryLight}>Sent On :</span>
                                {' '}
                                <strong>
                                  {item.send_date}
                                  {' '}
                                  {'at '}
                                  {' '}
                                  {item.send_time && item.send_time.slice(0, 5)}
                                </strong>
                              </Typography>
                              <Typography variant="body">
                                <span className={classes.primaryLight}>Sent By :</span>
                                {' '}
                                <strong>
                                  {item.send_user_email}
                                </strong>
                              </Typography>
                              <Typography className="d-flex flex-row" variant="body">
                                <span className="mr-1">
                                  {intl.formatMessage({ id: 'NOTIFICATION.LIST.REMOVED.MESSAGE' })}
                                </span>
                                <strong>
                                  {item.expiry_date}
                                </strong>
                                <EditDate
                                  item={{
                                    id: item.id,
                                    type,
                                    page
                                  }}
                                  onClick={editDeleteDateRequest}
                                />
                              </Typography>
                            </>
                          )}
                        </div>
                      </>
                    ) : null}
                  </div>
                )) : (
                  <div className="h-100 d-flex justify-content-center">
                    <Typography className={classes.emptyList} variant="h2">
                      {intl.formatMessage({ id: 'GENERAL.NO' })}
                      {' '}
                      {type.charAt(0) + type.slice(1).toLowerCase()}
                      {' '}
                      {intl.formatMessage({ id: 'MENU.NOTIFICATION' })}
                    </Typography>
                  </div>
                )}
              </div>
              { notificationCount && (
                <div
                  className={`pt-2 pb-4 d-flex justify-content-end align-items-center ${clsx({ 'justify-content-center': window.innerWidth < 700 })}`}
                >
                  <Pagination
                    pager={false}
                    page={page}
                    rowsPerPage={5}
                    handlePageChange={(value) => handlePaginationChange(value)}
                    handleRowsPerPageChange={() => {}}
                    dataLength={notificationCount}
                    mobileView={window.innerWidth < 700}
                  />
                </div>
              )}
            </>
          )
      }
    </div>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.notification.fetchState,
  notificationList: store.notification.list,
  notificationCount: store.notification.listCount,
  municipalities: store.common.municipalities,
});

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(CustomList)
);
