import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';


import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import MenuItem from '@material-ui/core/MenuItem';

import { LoadingSpinner, ErrorContainer } from '../../components/loading';


import { MaterialTable } from './components'
import { Typography, CustomButton, DatePicker, TextField, AutoComplete } from '../../components';

import * as actions from '../ReportDashboardNew/redux/actions';
import * as sharedActions from '../../redux/actions';
import * as categoryActions from '../../Category/redux/actions';


import { TableLoading } from '../../components/loading';
import {
  Portlet, PortletBody, PortletHeader,
} from '../../../../_metronic/partials/content/Portlet';

import moment from 'moment';
import { set } from 'draft-js/lib/DefaultDraftBlockRenderMap';


const useStyles = makeStyles((theme) => ({
  container: {
    // width: '90%',
    margin: '0 auto'
  },
  dateSelector: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  dateLabel: {
    color: '#2A95FF',
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 16,
  },
  topSection: {
    display: 'flex',
    alignContent: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  sectionTitle: {
    color: '#404040',
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 22,
    letterSpacing: '0.05em'
  },
  downloadButton: {
    width: 150,
    justifyContent: 'flex-end',
  },
  bodyTop: {
    display: 'flex',
    // height: 52,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '85%',
    gap: 20,
  },
  textInput: {
    paddingTop: 8,
  },
  locality: {
    width: '40%',
    [theme.breakpoints.down(550)]: {
      width: '100%'
    },
  },
  porterHeader: {
    display: 'flex',
    width: '100%',
    height: 100,
  },
  materialsTable: {
    // margin: '0 16px',
    // borderRadius: '0 !important',
    boxShadow: 'none !important',
    width: '85%',
    margin: 'auto',
    [theme.breakpoints.down(900)]: {
      width: '100%'
    },
  },
}));


const WGWMaterial = ({
  intl,
  getWGWMaterialDetailsRequest,
  majorDetails,
  bulkPickUpDetails,
  loading,
  error,
  municipality,
  isUserPro,
  stage,
  district,
  municipalities,
  setDistrictAction,
  hasCounty,
  counties,
  county,
  isCounty,
  setCountyAction,
  localityList,
  locality,
  wgwMaterialDetails,
  setWasteTypeAction,
  setLocalityAction,
}) => {

  const classes = useStyles();
  const history = useHistory();
  const [datePeriod, setDatePeriod] = useState({ month: 0, year: 0 });
  const [dateOptions, setDateOptions] = useState([]);
  const [monthFilter, setMonthFilter] = useState(1);
  const [startOfPeriod, setStartOfPeriod] = useState(null);
  const [endOfPeriod, setEndOfPeriod] = useState(null);
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [searchTerm, setSearchTerm] = useState('');



  const handleChange = (event, data) => {
    setDatePeriod(data.props.item);

  };

  useEffect(() => {
    if (locality && locality.cms_locality_id) {
      const options = generateMonthYear();
      setDateOptions(options);
      setDatePeriod(options[0]);
      const endDate = moment(`${options[0].year}-${options[0].month}-01`).format('YYYY-MM-DD');
      getWGWMaterialDetailsRequest({
        month: options[0].month,
        year: options[0].year,
        start_date: moment(endDate).subtract(1, 'year').format('YYYY-MM-DD'),
        end_date: endDate,
        locality: locality.cms_locality_id
      })
    }
  }, [locality]);


  const handleMonthsFetch = (months) => {
    setMonthFilter(months);
    getWGWMaterialDetailsRequest({
      month: datePeriod.month,
      year: datePeriod.year,
      last_month: months,
      locality: locality.cms_locality_id
    })

  };

  const generateMonthYear = () => {
    const date = new Date();
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();
    const monthYear = [];
    for (let i = 0; i < 24; i++) {
      if (currentMonth - i < 0) {
        monthYear.push({
          month: Math.abs(12 + currentMonth - i),
          year: currentYear - 1,
        });
      } else {
        monthYear.push({
          month: currentMonth - i,
          year: currentYear,
        });
      }
    }
    return monthYear;
  };

  const getMonthName = (monthNumber, format = 'short') => {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', { month: format });
  };

  const handleSearch = () => {
    let start_date = startOfPeriod ? startOfPeriod : moment(`${datePeriod.year}-${datePeriod.month}-01`).subtract(1, 'year').format('YYYY-MM-DD');
    let end_date = endOfPeriod ? endOfPeriod : moment(`${datePeriod.year}-${datePeriod.month}-01`).add(1, 'months').format('YYYY-MM-DD')

    getWGWMaterialDetailsRequest({
      month: datePeriod.month,
      year: datePeriod.year,
      last_name: lastName,
      address,
      start_date: start_date,
      end_date: end_date,
      locality: locality.cms_locality_id
    })
  };

  const handleEditClick = (item) => {
    window.scrollTo(0, 0);
    history.push(`/category?id=${item.waste_type_id}`);
    setWasteTypeAction(item);
  };

  const handleCollectionTypeEditClick = (item) => {
    window.scrollTo(0, 0);
    history.push(`/collection-requirement?id=${item.waste_type_id}`);
  };

  const handleCollectionTypeViewClick = () => {
    window.scrollTo(0, 0);
    history.push(`/collection-requirement`);
  };

  return (
    <>
      <div className={classes.container}>
        {
          loading ?
            <TableLoading /> :
            <div className="row">
              {dateOptions && dateOptions.length > 0 && !bulkPickUpDetails && loading ? <LoadingSpinner height={350} /> : error ? (
                <ErrorContainer
                  small
                  reportName="Report Dashboard"
                  height={430}
                  error={error}
                />
              ) : (
                <div className="col-xl-12 col-centered">

                  <Portlet className={`h-100 position-relative ${classes.materialsTable}`}>
                    <PortletHeader
                      className={`${classes.porterHeader}`}
                      title={(
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <Typography variant="h1" className={`${classes.materialsLabel}`}>
                            What Goes Where
                          </Typography>
                          <AutoComplete
                            className={classes.locality}
                            title={intl.formatMessage({ id: 'GENERAL.LOCALITY' })}
                            options={localityList}
                            optionTitle="cms_locality_nm"
                            optionId="cms_locality_id"
                            disableClearable
                            onChange={(value) => setLocalityAction({ locality: value })}
                            value={locality}
                          />
                          {/* </div> */}
                        </div>
                      )}
                    />
                    <PortletBody className={`${classes.tableBody}`}>
                      {
                        wgwMaterialDetails && wgwMaterialDetails.data && (
                          <MaterialTable data={wgwMaterialDetails.data} month={getMonthName(datePeriod.month, 'long')} year={datePeriod.year} handleMonthsFetch={handleMonthsFetch} monthFilterParent={monthFilter} searchTerm={searchTerm} onSearchTermChange={setSearchTerm} handleEditClick={handleEditClick} handleCollectionTypeEditClick={handleCollectionTypeEditClick} handleCollectionTypeViewClick={handleCollectionTypeViewClick} />
                        )
                      }
                    </PortletBody>
                  </Portlet>


                </div>
              )}
            </div>}

      </div>
    </>
  );
};

/**
 * Map state to props
 */
const mapStateToProps = (store) => ({
  majorDetails: store.reportsDashboardReducer.majorDetails,
  bulkPickUpDetails: store.reportsDashboardReducer.bulkPickUpDetails,
  loading: store.reportsDashboardReducer.wgwLoading,
  error: store.reportsDashboardReducer.error,
  municipality: store.common.district.district_id,
  isUserPro: store.common.isUserPro,
  stage: store.common.district.stage,
  district: store.common.district,
  municipalities: store.common.municipalities,
  hasCounty: store.auth.user_info.counties,
  counties: store.common.counties,
  county: store.common.county,
  isCounty: store.common.isCounty,
  localityList: store.common.localityList,
  locality: store.common.locality,
  wgwMaterialDetails: store.reportsDashboardReducer.wgwMaterialDetails,

});


export default injectIntl(
  connect(
    mapStateToProps, { ...actions, ...sharedActions, ...categoryActions }
  )(WGWMaterial)
);
