import React from 'react';
import { connect } from 'react-redux';

import { Summary, Detail } from './components';

const PageViewsReport = ({
  data,
}) => (
  <div id="page-views-report">
    <Summary
      data={data}
    />
    <Detail
      data={data}
    />
  </div>
);

const mapStateToProps = (store) => ({
  data: store.pageViewsReport.data,
});

export default connect(mapStateToProps, null)(PageViewsReport);
