import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { TableLoading } from '../../../components/loading';

import {
  Portlet, PortletBody
} from '../../../../../_metronic/partials/content/Portlet';
import { Tab, Typography } from '../../../components';
import residentKnowledgeReportTabName from '../helper';
import SurveyDetail from './SurveyDetail';
import WWTKDetail from './WWTKDetail';
import SpotlightReport from './SpotlightReport';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_PRIMARY,
  },
  tabs: {
    marginTop: '28px',
    marginBottom: '15px',
    marginLeft: '5px',
  }
}));

const Detail = ({
  intl,
  fetchState,
  data
}) => {
  const classes = useStyles();
  const [tabNumber, setTabNumber] = useState(0);
  const isSendRequest = fetchState === 'send-request';

  const handleTabChange = (_, number) => {
    setTabNumber(number);
  };

  // scroll to this component if user navigates from dashbord widget
  const { isDashboardWidget } = useLocation();
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView({
    top: 'start',
    behavior: 'smooth',
  });

  useEffect(() => {
    if (isDashboardWidget) {
      executeScroll();
    }
  }, [isSendRequest, isDashboardWidget]);

  return (
    <Portlet
      style={{ scrollMargin: 74 }}
      ref={myRef}
    >
      <PortletBody className="pt-0">
        {
          isSendRequest ? (
            <div className="pt-4">
              <TableLoading width="100%" />
            </div>
          ) : (
            <div className="row w-100 mb-4 flex-column">
              <div className={classes.tabs}>
                <Tab
                  selectedTab={tabNumber}
                  onChange={handleTabChange}
                  labels={residentKnowledgeReportTabName}
                  unselectedColor={process.env.REACT_APP_COLOR_DISABLED_TEXT}
                  selectedBottomBorderColor={process.env.REACT_APP_COLOR_SECONDARY}
                />

                {tabNumber === 0 && <WWTKDetail data={data && data.wwtk} />}
                {tabNumber === 1 && <SurveyDetail data={data && data.survey} />}
                {tabNumber === 2 && <SpotlightReport data={data && data.sp_materials} />}

                {
                  tabNumber === 1 ? (
                    <Typography
                      variant="body"
                      className="font-italic"
                    >
                      {intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SUMMARY.TITLE.SURVEY.APP_INSTALL.COMMENT' })}
                    </Typography>
                  ) : null
                }

                {
                  tabNumber === 0 ? (
                    <Typography
                      variant="body"
                      className="font-italic"
                    >
                      {intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.WWTK.CORRECT_ANSWER' })}
                    </Typography>
                  ) : null
                }
              </div>

            </div>
          )
        }
      </PortletBody>
    </Portlet>
  );
};

export default injectIntl(Detail);
