import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { FormattedMessage } from 'react-intl';

import { Typography } from '../../components';

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#ffffff',
    '&$checked + $track': {
      backgroundColor: '#cccccc',
    },
  },
  checked: {},
  track: {
    backgroundColor: '#cccccc',
    opacity: '0.5'
  },
})(Switch);

const PermissionSwitch = ({
  isActive,
  onChange,
  rightLabel,
  leftLabel
}) => {
  const useStyles = makeStyles(() => ({
    disable: {
      color: process.env.REACT_APP_COLOR_GRAY_DARK
    },
    inAble: {
      color: process.env.REACT_APP_COLOR_PRIMARY
    }
  }));
  const classes = useStyles();

  return (
    <Grid component="label" container alignItems="center">
      <Grid item>
        <Typography className={classes.disable} variant="h2">
          <FormattedMessage id={leftLabel} />
        </Typography>
      </Grid>
      <Grid item>
        <PurpleSwitch
          checked={isActive}
          onChange={onChange}
          color="primary"
        />
      </Grid>
      <Grid item>
        <Typography
          variant="h2"
          className={!isActive ? classes.disable : classes.inAble}
        >
          <FormattedMessage id={rightLabel} />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PermissionSwitch;
