/**
 *
 */
const initialState = {
  fetchState: 'no-request',
  snackbarState: 'no-request',
  responseStatus: 'success',
  suggestAddress: [],
  addDepot: 0,
  responseMessage: null,
  depotList: [],
  initialSeason: {},
  newDepot: {
    id: null,
    name: '',
    instruction: '',
    open24: false,
    address: '',
    geoLocation: '',
    phone: '',
    altPhone: '',
    email: '',
    website: '',
    seasons: [
      {
        depot_season_slot: 0,
        season_nm: '',
        season_dscr: null,
        season_notes: null,
        times: [
          {
            dow_nbr: 0,
            hours_lower_tm: '09:00',
            hours_upper_tm: '17:00',
            add_hours_lower_tm: null,
            add_hours_upper_tm: null
          },
          {
            dow_nbr: 1,
            hours_lower_tm: '09:00',
            hours_upper_tm: '17:00',
            add_hours_lower_tm: null,
            add_hours_upper_tm: null
          },
          {
            dow_nbr: 2,
            hours_lower_tm: '09:00',
            hours_upper_tm: '17:00',
            add_hours_lower_tm: null,
            add_hours_upper_tm: null
          },
          {
            dow_nbr: 3,
            hours_lower_tm: '09:00',
            hours_upper_tm: '17:00',
            add_hours_lower_tm: null,
            add_hours_upper_tm: null
          },
          {
            dow_nbr: 4,
            hours_lower_tm: '09:00',
            hours_upper_tm: '17:00',
            add_hours_lower_tm: null,
            add_hours_upper_tm: null
          },
          {
            dow_nbr: 5,
            hours_lower_tm: '09:00',
            hours_upper_tm: '17:00',
            add_hours_lower_tm: null,
            add_hours_upper_tm: null
          },
          {
            dow_nbr: 6,
            hours_lower_tm: '09:00',
            hours_upper_tm: '17:00',
            add_hours_lower_tm: null,
            add_hours_upper_tm: null
          },
        ]
      },
    ],
    drawers: [],
    assignCategory: []
  },

};

export default initialState;
