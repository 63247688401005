import { put, call, takeLatest } from 'redux-saga/effects';

import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  getInteractionReport
} from '../apis';

function* interactionReportSaga({
  payload: {
    municipality, from, to, county
  }
}) {
  try {
    yield put(actions.setInteractionReportLoadingAction('send-request'));
    const res = yield call(getInteractionReport, {
      municipality, from, to, county
    });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.interactionReportReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.interactionReportFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

/**
 * Saga watcher function
 */
function* interactionReportSagas() {
  yield takeLatest(actionTypes.GET_INTERACTION_REPORT_REQUEST, interactionReportSaga);
}

export default interactionReportSagas;
