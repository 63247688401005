// eslint-disable-next-line import/no-cycle
import cts from '../../../crud/cts';

export const getDashboardReport = async ({
  from,
  to,
  municipality,
  county
}) => {
  return cts(
    'get',
    `reports/dashboard?from=${from}&to=${to}&county=${county}&municipality=${municipality}`
  );
};


export const getDashboardReportV2 = async ({
  municipality,
  month,
  year,
  county,
  isCounty
}) => {
  return cts(
    'get',
    isCounty ? `reports/v2/dashboard?county=${county.county_id}&month=${month}&year=${year}` :
      `reports/v2/dashboard?municipality=${encodeURIComponent(municipality)}&month=${month}&year=${year}`
    // `reports/v2/education_details?municipality=${encodeURIComponent(municipality)}&month=${month}&year=${year}`

  );
};

export const getDashboardReportDetail = async ({
  municipality,
  month,
  year,
  last_month,
  county,
  isCounty
}) => {
  return cts(
    'get',
    isCounty ?
      `reports/v2/education_details?county=${county.county_id}&month=${month}&year=${year}&last_month=${last_month}`
      :
      `reports/v2/education_details?municipality=${encodeURIComponent(municipality)}&month=${month}&year=${year}&last_month=${last_month}`

  );
};

export const getMaterialDetails = async ({
  municipality,
  month,
  year,
  last_month,
  county,
  isCounty
}) => {
  return cts(
    'get',
    isCounty ?
      `reports/v2/material_details?county=${county.county_id}&month=${month}&year=${year}&last_month=${last_month}`
      :
      `reports/v2/material_details?municipality=${encodeURIComponent(municipality)}&month=${month}&year=${year}&last_month=${last_month}`
  );
};

export const getMajorCampaignDetails = async ({
  municipality,
  month,
  year,
  major_campaign_id,
  county,
  isCounty
}) => {
  return cts(
    'get',
    isCounty ?
      `reports/v2/major_campaign_details?county=${county.county_id}&month=${month}&year=${year}&major_campaign_id=${major_campaign_id}`
      :
      `reports/v2/major_campaign_details?municipality=${encodeURIComponent(municipality)}&month=${month}&year=${year}&major_campaign_id=${major_campaign_id}`

  );
};

export const getBulkPickupDetails = async ({
  municipality,
  month,
  year,
  last_month,
  county,
  isCounty,
  last_name,
  address,
  start_date,
  end_date
}) => {
  let searchQuery = '';
  if (last_name) {
    searchQuery += `&last_name=${last_name}`;
  }
  if (address) {
    searchQuery += `&address=${address}`;
  }
  if (start_date) {
    searchQuery += `&start_date=${start_date}`;
  }
  if (end_date) {
    searchQuery += `&end_date=${end_date}`;
  }

  return cts(
    'get',
    isCounty ?
      `reports/v2/bulk_pickup_details?county=${county.county_id}${searchQuery}`
      :
      `reports/v2/bulk_pickup_details?municipality=${encodeURIComponent(municipality)}&month=${month}year=${year}&last_month=${last_month}${searchQuery}`
  );
};

export const getWGWMaterialDetails = async ({
  month,
  year,
  last_month,
  last_name,
  address,
  start_date,
  end_date,
  locality
}) => {
  let searchQuery = '';

  return cts(
    'post',
    `reports/v2/wgw_material`, 
    {
      cms_locality_id: locality,
    }
  );
};

export const getBulkPickupDashboard = async ({
  municipality,
  month,
  year,
  county,
  isCounty
}) => {
  return cts(
    'get',
    isCounty ? `reports/v2/bulk_pickup_dashboard?county=${county.county_id}&month=${month}&year=${year}` :
      `reports/v2/bulk_pickup_dashboard?municipality=${encodeURIComponent(municipality)}&month=${month}&year=${year}`

  );
};
