import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Typography, CustomButton } from '../../../components';
import { toAbsoluteUrl } from '../../../../../_metronic';

const useStyles = makeStyles(() => ({

  applyButton: {
    fontSize: 14,
    fontWeight: 700,
    color: ({ disabled }) => (disabled
      ? 'rgba(255, 30, 30, 0.4)' : 'rgba(255, 30, 30, 1)'),
    marginRight: 20,
    transition: 'all 0.3s'
  },
  iframeOverride: {
    transform: 'scale( 0.75, 0.75)',
    position: 'relative',
    top: '-50px',
    border: 0,
    '@media (max-height: 820px)': {
      top: '-98px',
    },
  },
  dialogOverride: {
    display: 'block',
    overflow: 'hidden',
    maxWidth: 'unset'
  },
  modalContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  backgroundImage: {
    position: 'absolute',
    width: 420,
    top: 170,
    right: 120
  },
  characterLeftmage: {
    position: 'relative',
    right: -50,
    top: 50,
    '@media (max-height: 820px)': {
      top: 'unset',
    },
  },
  characterRightImage: {
    position: 'relative',
    top: 50,
    order: 2,
    left: -50,
    '@media (max-height: 820px)': {
      top: 'unset',
    },
  },
  plantImage: {
    top: 90,
    position: 'absolute',
    left: 140
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'center'
  },
  lessonDescription: {
    // height: 350,
    overflow: 'scroll',
    margin: 8
  },
  previewButton: {
    marginTop: '45%',
    display: 'flex',
    left: 40,
    '@media (max-height: 820px)': {
      marginTop: '35%',
    },
  },
  nextButton: {
    marginTop: '45%',
    display: 'flex',
    left: 40,
    '@media (max-height: 820px)': {
      marginTop: '35%',
    },
  },
  quizContainer: {
    margin: '50px 20px',
  }
}));
const PreviewQuizModal = ({
  intl, open, onClose, disabled = false, rfQuiz, nextHandler, previewHandler, rfLessonTitle, currentRFQuizIndex, numberOfQuizes, misunderstoodMaterialsQuizOptions
}) => {
  const classes = useStyles({ disabled });
  const defaultMisunderstoodMaterialsQuizOptions = ['1000', '1001'];

  return (
    <>
      {open && (
        <Dialog classes={{
          paperScrollPaper: classes.dialogOverride,
        }}
          open={open} onClose={onClose}>
          <Typography color="#37474F" className={classes.modalTitle} variant="h2">
            Now playing on your phone
          </Typography>
          <div className={classes.modalContentContainer}>
            <CustomButton
              paddingVertical={4}
              className={classes.previewButton}
              variant={'contained'}
              fontSize={12}
              label={'Previous'}
              onClick={previewHandler}
              disabled={currentRFQuizIndex === 0}
            />

            <img
              alt="background"
              src={toAbsoluteUrl('/media/icons/Education/freepik--background-simple--inject-1.png')}
              className={`${classes.backgroundImage}`}
            />
            <img
              alt="character1"
              src={toAbsoluteUrl('/media/icons/Education/freepik--character-1--inject-1.png')}
              className={`${classes.characterLeftmage}`}
            />
            <img
              alt="character2"
              src={toAbsoluteUrl('/media/icons/Education/freepik--character-2--inject-1.png')}
              className={`${classes.characterRightImage}`}
            />
            <img
              alt="plant"
              src={toAbsoluteUrl('/media/icons/Education/freepik--Plant--inject-1.png')}
              className={`${classes.plantImage}`}
            />
            <div className={classes.iframeOverride}>
              <div className="marvel-device iphone-x">
                <div className="notch">
                  <div className="camera"></div>
                  <div className="speaker"></div>
                </div>
                <div className="top-bar"></div>
                <div className="sleep"></div>
                <div className="bottom-bar"></div>
                <div className="volume"></div>
                <div className="overflow">
                  <div className="shadow shadow--tr"></div>
                  <div className="shadow shadow--tl"></div>
                  <div className="shadow shadow--br"></div>
                  <div className="shadow shadow--bl"></div>
                </div>
                <div className="inner-shadow"></div>
                <div className="screen">
                  <div className={`${classes.quizContainer}`}>
                    <div style={{ display: 'flex' }}>
                      <Typography color="#0789C4" variant="h2" style={{ margin: 5, width: '50%' }}>
                        Quiz
                      </Typography>
                      <Typography color="#0789C4" variant="h2" style={{ margin: 5 }}>
                        Question {currentRFQuizIndex + 1} / {numberOfQuizes}
                      </Typography>
                    </div>

                    <Typography color="#6C7293" variant="h2" style={{ margin: 5 }}>
                      {rfLessonTitle}
                    </Typography>

                    <Typography
                      color={process.env.REACT_APP_COLOR_TEXT}
                      className={`${classes.lessonDescription}`}
                      variant="educationBody"
                      dangerouslySetInnerHTML={{ __html: rfQuiz.question }}
                    />
                    <FormControl component="fieldset" disabled={false} className="w-100">

                      <RadioGroup
                        // eslint-disable-next-line radix
                        value={misunderstoodMaterialsQuizOptions ? misunderstoodMaterialsQuizOptions[misunderstoodMaterialsQuizOptions.findIndex((option) => option.disposal_id === rfQuiz[currentRFQuizIndex].correct_disposal_id)].disposal_nm
                          : rfQuiz[currentRFQuizIndex].options[parseInt(rfQuiz[currentRFQuizIndex].answer) - 1]}
                        onChange={(event) => { }}
                      >
                        {
                          misunderstoodMaterialsQuizOptions ? misunderstoodMaterialsQuizOptions.filter((mmOption) => mmOption.disposal_id === rfQuiz[currentRFQuizIndex].correct_disposal_id || defaultMisunderstoodMaterialsQuizOptions.includes(mmOption.disposal_id)).map((option) => {
                            return (
                              <FormControlLabel
                                value={option.disposal_nm}
                                control={<Radio color="primary" />}
                                label={(
                                  <Typography variant="h2">
                                    {option.disposal_nm ? option.disposal_nm : option}
                                  </Typography>
                                )}
                                className="text-dark"
                              />
                            )
                          })
                            : (
                              <>
                                <Typography
                                  color={process.env.REACT_APP_COLOR_TEXT}
                                  className={`${classes.lessonDescription}`}
                                  variant="educationBody"
                                  dangerouslySetInnerHTML={{ __html: rfQuiz[currentRFQuizIndex].question }}
                                />
                                {
                                  rfQuiz[currentRFQuizIndex].options.map((option) => {
                                    return (
                                      <>
                                        <FormControlLabel
                                          value={option}
                                          control={<Radio color="primary" />}
                                          label={(
                                            <Typography variant="h2">
                                              {option}
                                            </Typography>
                                          )}
                                          className="text-dark"
                                        />
                                      </>
                                    )
                                  })
                                }

                              </>
                            )


                        }
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <CustomButton
              paddingVertical={4}
              className={classes.nextButton}
              variant={'contained'}
              fontSize={12}
              label={'Next'}
              onClick={nextHandler}
              disabled={currentRFQuizIndex === numberOfQuizes - 1}
            />
          </div>
        </Dialog>
      )}
    </>
  );
};

export default injectIntl(PreviewQuizModal);
