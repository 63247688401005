import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'querystring';


import { campaignNormalizer } from '../helpers';
import { getAllCampaignApi, ONGOING } from '../apis';

/**
 * Components
 */
import CampaignList from './CampaignList';
import { Snackbar } from '../../components';


const Schedules = ({
  district
}) => {
  const location = useLocation();
  const [campaigns, setCampaigns] = useState({
    campaigns: [],
    loading: false,
    error: null
  });
  const [update, setUpdate] = useState(false);
  const [snackPayload, setSnackPayload] = useState({});


  const getCampaign = async (params) => {
    try {
      setCampaigns({
        campaigns: [],
        loading: true,
        error: null
      });
      const { data, error } = await getAllCampaignApi(params);
      const { campaigns: camps } = data.data;
      if (!error) {
        setCampaigns({
          campaigns: camps.length ? campaignNormalizer(camps) : [],
          loading: false,
          error: null
        });
      }
      if (error) {
        throw new Error('Could not get campaign data');
      }
    } catch (e) {
      setCampaigns({
        campaigns: [],
        loading: false,
        error: e
      });
    }
  };


  const updateList = ({ status, message }) => {
    setUpdate(!update);
    setSnackPayload({
      status,
      message
    });
  };


  useEffect(() => {
    getCampaign({
      district_id: district.district_id,
      state: ONGOING
    });
  }, [district, update]);

  return (
    <div>
      <Snackbar
        message={snackPayload.message}
        status={snackPayload.status}
        closeAction={null}
      />
      <CampaignList
        campaigns={campaigns.campaigns}
        loading={campaigns.loading}
        err={campaigns.error}
        campPage="schedule"
        onUpdate={updateList}
        changeState={qs.parse(location.search.substring(1)).listState}
        uiOptions={{
          preview: true,
          switch: true,
          reject: true,
          date: true
        }}
      />
    </div>
  );
};


export default injectIntl(
  connect(
    (store) => ({
      district: store.common.district
    })
  )(Schedules)
);
