import { put, call, takeLatest } from 'redux-saga/effects';

import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  getEvents,
  createEvent,
  editEvent,
  getAddressSuggestions,
  // getEventCoordinates,
  deleteEvent,
} from '../apis';

function* getEventsListSaga({ payload: { locality } }) {
  try {
    yield put(actions.setLoadingAction('send-request'));
    const res = yield call(getEvents, { locality });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getEventsListReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getEventsListFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* createEventSaga({ payload: data }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(createEvent, { data });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.createEventReceive({
          data,
          id: res.data.data.drop_event_id,
          message: res.data.message
        }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.createEventFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* editEventSaga({ payload: data }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(editEvent, { data });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.editEventReceive({
          data,
          message: res.data.message
        }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.editEventFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* getAddressSuggestionsSaga({ payload: address }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(getAddressSuggestions, { address });
    if (res.data) {
      if (res.data.status === 'OK') {
        yield put(actions.getAddressSuggestionsReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getAddressSuggestionsFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* deleteEventSaga({ payload: { locality, event } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(deleteEvent, { locality, event });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.deleteEventReceive({
          data: event,
          message: res.data.message
        }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.deleteEventFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

/**
 * Saga watcher function
 */
function* eventSagas() {
  yield takeLatest(actionTypes.GET_EVENTS_LIST_REQUEST, getEventsListSaga);
  yield takeLatest(actionTypes.CREATE_EVENT_REQUEST, createEventSaga);
  yield takeLatest(actionTypes.EDIT_EVENT_REQUEST, editEventSaga);
  yield takeLatest(actionTypes.GET_EVENT_ADDRESS_SUGGESTIONS_REQUEST, getAddressSuggestionsSaga);
  yield takeLatest(actionTypes.DELETE_EVENT_REQUEST, deleteEventSaga);
}

export default eventSagas;
