import actionTypes from './actionTypes';

// Category actions for setting loading status
export const setLoadingAction = (status) => ({
  type: actionTypes.SET_CATEGORY_LOADING_ACTION,
  payload: status
});

export const setSnackbarAction = (status) => ({
  type: actionTypes.SET_CATEGORY_SNACKBAR_ACTION,
  payload: status
});

// Category actions for fetching waste types list
export const getWasteTypesListRequest = ({ locality }) => ({
  type: actionTypes.GET_WASTE_TYPES_REQUEST,
  payload: { locality }
});

export const getWasteTypesListReceive = (data) => ({
  type: actionTypes.GET_WASTE_TYPES_RECEIVE,
  payload: { data }
});

export const getWasteTypesListFail = (error) => ({
  type: actionTypes.GET_WASTE_TYPES_FAIL,
  payload: { ...error }
});

// Category actions for setting a waste type
export const setWasteTypeAction = (wasteType) => ({
  type: actionTypes.SET_WASTE_TYPE_ACTION,
  payload: { wasteType }
});

// Category actions for fetching categories of a waste type
export const getCategoriesRequest = ({ wasteType, locality }) => ({
  type: actionTypes.GET_CATEGORIES_REQUEST,
  payload: { wasteType, locality }
});

export const getCategoriesReceive = (data) => ({
  type: actionTypes.GET_CATEGORIES_RECEIVE,
  payload: { data }
});

export const getCategoriesFail = (error) => ({
  type: actionTypes.GET_CATEGORIES_FAIL,
  payload: { ...error }
});

// Category actions for fetching categories in all of the localities
export const getAllCategoriesRequest = ({ locality }) => ({
  type: actionTypes.GET_ALL_CATEGORIES_REQUEST,
  payload: { locality }
});

export const getAllCategoriesReceive = (data) => ({
  type: actionTypes.GET_ALL_CATEGORIES_RECEIVE,
  payload: { data }
});

export const getAllCategoriesFail = (error) => ({
  type: actionTypes.GET_ALL_CATEGORIES_FAIL,
  payload: { ...error }
});

// Category actions for removing a category of a waste type
export const deleteCategoryRequest = ({ locality, category }) => ({
  type: actionTypes.DELETE_CATEGORY_REQUEST,
  payload: { locality, category }
});

export const deleteCategoryReceive = (data) => ({
  type: actionTypes.DELETE_CATEGORY_RECEIVE,
  payload: { data }
});

export const deleteCategoryFail = (error) => ({
  type: actionTypes.DELETE_CATEGORY_FAIL,
  payload: { ...error }
});

// Category actions for removing a depot of a category
export const deleteDepotRequest = ({ locality, depot, category }) => ({
  type: actionTypes.UNASSIGN_DEPOT_REQUEST,
  payload: { locality, depot, category }
});

export const deleteDepotReceive = (data) => ({
  type: actionTypes.UNASSIGN_DEPOT_RECEIVE,
  payload: { data }
});

export const deleteDepotFail = (error) => ({
  type: actionTypes.UNASSIGN_DEPOT_FAIL,
  payload: { ...error }
});

// Category actions for removing an event of a category
export const deleteEventRequest = ({ locality, event, category }) => ({
  type: actionTypes.UNASSIGN_EVENT_REQUEST,
  payload: { locality, event, category }
});

export const deleteEventReceive = (data) => ({
  type: actionTypes.UNASSIGN_EVENT_RECEIVE,
  payload: { data }
});

export const deleteEventFail = (error) => ({
  type: actionTypes.UNASSIGN_EVENT_FAIL,
  payload: { ...error }
});

// Category actions for renaming a category
export const renameCategoryRequest = ({ locality, category, title }) => ({
  type: actionTypes.RENAME_CATEGORY_REQUEST,
  payload: { locality, category, title }
});

export const renameCategoryReceive = (data) => ({
  type: actionTypes.RENAME_CATEGORY_RECEIVE,
  payload: { data }
});

export const renameCategoryFail = (error) => ({
  type: actionTypes.RENAME_CATEGORY_FAIL,
  payload: { ...error }
});

// Category actions for fetching all depots
export const getDepotsRequest = ({ locality }) => ({
  type: actionTypes.GET_DEPOTS_REQUEST,
  payload: { locality }
});

export const getDepotsReceive = (data) => ({
  type: actionTypes.GET_DEPOTS_RECEIVE,
  payload: { data }
});

export const getDepotsFail = (error) => ({
  type: actionTypes.GET_DEPOTS_FAIL,
  payload: { ...error }
});

// Category actions for fetching all events
export const getEventsRequest = ({ locality }) => ({
  type: actionTypes.GET_EVENTS_REQUEST,
  payload: { locality }
});

export const getEventsReceive = (data) => ({
  type: actionTypes.GET_EVENTS_RECEIVE,
  payload: { data }
});

export const getEventsFail = (error) => ({
  type: actionTypes.GET_EVENTS_FAIL,
  payload: { ...error }
});

// Category actions for fetching all collections
export const getCollectionsRequest = ({ locality }) => ({
  type: actionTypes.GET_COLLECTIONS_REQUEST,
  payload: { locality }
});

export const getCollectionsReceive = (data) => ({
  type: actionTypes.GET_COLLECTIONS_RECEIVE,
  payload: { data }
});

export const getCollectionsFail = (error) => ({
  type: actionTypes.GET_COLLECTIONS_FAIL,
  payload: { ...error }
});

// Category actions for fetching waste type's collection details
export const getCollectionDetailsRequest = ({ locality, collection }) => ({
  type: actionTypes.GET_COLLECTION_DETAILS_REQUEST,
  payload: { locality, collection }
});

export const getCollectionDetailsReceive = (data) => ({
  type: actionTypes.GET_COLLECTION_DETAILS_RECEIVE,
  payload: { data }
});

export const getCollectionDetailsFail = (error) => ({
  type: actionTypes.GET_COLLECTION_DETAILS_FAIL,
  payload: { ...error }
});

// Category actions for assigning a depot to a category
export const assignDepotRequest = ({ locality, category, depot }) => ({
  type: actionTypes.ASSIGN_DEPOT_REQUEST,
  payload: { locality, category, depot }
});

export const assignDepotReceive = (data) => ({
  type: actionTypes.ASSIGN_DEPOT_RECEIVE,
  payload: { data }
});

export const assignDepotFail = (error) => ({
  type: actionTypes.ASSIGN_DEPOT_FAIL,
  payload: { ...error }
});

// Category actions for assigning an event to a category
export const assignEventRequest = ({ locality, category, event }) => ({
  type: actionTypes.ASSIGN_EVENT_REQUEST,
  payload: { locality, category, event }
});

export const assignEventReceive = (data) => ({
  type: actionTypes.ASSIGN_EVENT_RECEIVE,
  payload: { data }
});

export const assignEventFail = (error) => ({
  type: actionTypes.ASSIGN_EVENT_FAIL,
  payload: { ...error }
});

// Category actions for assigning a collection to a waste type
export const assignCollectionRequest = ({ locality, wasteType, collection }) => ({
  type: actionTypes.ASSIGN_COLLECTION_REQUEST,
  payload: { locality, wasteType, collection }
});

export const assignCollectionReceive = (data) => ({
  type: actionTypes.ASSIGN_COLLECTION_RECEIVE,
  payload: { data }
});

export const assignCollectionFail = (error) => ({
  type: actionTypes.ASSIGN_COLLECTION_FAIL,
  payload: { ...error }
});

// Category actions for moving category from one waste type to another
export const moveCategoryRequest = ({ locality, wasteType, category }) => ({
  type: actionTypes.MOVE_CATEGORY_REQUEST,
  payload: { locality, wasteType, category }
});

export const moveCategoryReceive = (data) => ({
  type: actionTypes.MOVE_CATEGORY_RECEIVE,
  payload: { data }
});

export const moveCategoryFail = (error) => ({
  type: actionTypes.MOVE_CATEGORY_FAIL,
  payload: { ...error }
});

// Category actions for moving materials from one category to another
export const moveMaterialsRequest = ({
  locality, category, materials, currentCategory
}) => ({
  type: actionTypes.MOVE_MATERIALS_REQUEST,
  payload: {
    locality, category, materials, currentCategory
  }
});

export const moveMaterialsReceive = (data) => ({
  type: actionTypes.MOVE_MATERIALS_RECEIVE,
  payload: { data }
});

export const moveMaterialsFail = (error) => ({
  type: actionTypes.MOVE_MATERIALS_FAIL,
  payload: { ...error }
});

// Category actions for searching through materials
export const searchMaterialsRequest = ({ locality, search }) => ({
  type: actionTypes.SEARCH_MATERIALS_REQUEST,
  payload: { locality, search }
});

export const searchMaterialsReceive = (data) => ({
  type: actionTypes.SEARCH_MATERIALS_RECEIVE,
  payload: { data }
});

export const searchMaterialsFail = (error) => ({
  type: actionTypes.SEARCH_MATERIALS_FAIL,
  payload: { ...error }
});

// Category actions for fetching material search results
export const getSearchResultsRequest = ({ locality, material }) => ({
  type: actionTypes.GET_SEARCH_RESULTS_REQUEST,
  payload: { locality, material }
});

export const getSearchResultsReceive = (data) => ({
  type: actionTypes.GET_SEARCH_RESULTS_RECEIVE,
  payload: { data }
});

export const getSearchResultsFail = (error) => ({
  type: actionTypes.GET_SEARCH_RESULTS_FAIL,
  payload: { ...error }
});

// Category actions for fetching waste type of the searched material
export const getSearchedWasteTypeRequest = ({ locality, wasteType }) => ({
  type: actionTypes.GET_SEARCHED_WASTE_TYPE_REQUEST,
  payload: { locality, wasteType }
});

export const getSearchedWasteTypeReceive = (data) => ({
  type: actionTypes.GET_SEARCHED_WASTE_TYPE_RECEIVE,
  payload: { data }
});

export const getSearchedWasteTypeFail = (error) => ({
  type: actionTypes.GET_SEARCHED_WASTE_TYPE_FAIL,
  payload: { ...error }
});

// Category actions for adding a new category to a waste type
export const addCategoryRequest = ({ locality, wasteType, categoryName, categoryHeader, categoryDescription  }) => ({
  type: actionTypes.ADD_CATEGORY_REQUEST,
  payload: { locality, wasteType, categoryName, categoryHeader, categoryDescription }
});

export const addCategoryReceive = (data) => ({
  type: actionTypes.ADD_CATEGORY_RECEIVE,
  payload: { data }
});

export const addCategoryFail = (error) => ({
  type: actionTypes.ADD_CATEGORY_FAIL,
  payload: { ...error }
});

// Category actions for updating a category's description
export const updateCategoryDescriptionRequest = ({
  locality, wasteType, category, descriptionId, descriptionHeader, descriptionBody, moreInfo
}) => ({
  type: actionTypes.UPDATE_CATEGORY_DESCRIPTION_REQUEST,
  payload: {
    locality, wasteType, category, descriptionId, descriptionHeader, descriptionBody, moreInfo
  }
});

export const updateCategoryDescriptionReceive = (data) => ({
  type: actionTypes.UPDATE_CATEGORY_DESCRIPTION_RECEIVE,
  payload: { data }
});

export const updateCategoryDescriptionFail = (error) => ({
  type: actionTypes.UPDATE_CATEGORY_DESCRIPTION_FAIL,
  payload: { ...error }
});
