import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import CustomButton from '../../components/CustomButton';
import { SwitchIcon } from '../../../assets/icons';

const textColor = process.env.REACT_APP_COLOR_PRIMARY;

const useStyles = makeStyles({
  container: {
    border: `1px solid ${textColor}`,
    marginTop: 10
  }
});

const Pullback = ({ onClick, intl }) => {
  const { container } = useStyles();
  return (
    <CustomButton
      variant="outline"
      className={container}
      label={(() => (
        <>
          <SwitchIcon color={textColor} />
          <span style={{ paddingLeft: 10, color: textColor }}>
            {intl.formatMessage({ id: 'CAMPAIGN.ITEM.PULLBACK' })}
          </span>
        </>
      ))()}
      onClick={onClick}
    />
  );
};

export default injectIntl(Pullback);
