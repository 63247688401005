import { put, call, takeLatest } from 'redux-saga/effects';

import { setCookie } from '../../../../utils';
import actionTypes from './actionTypes';
import * as actions from './actions';
import * as sharedActions from '../../../redux/actions';
import { sendOTP } from '../../apis';

function* OTPSaga({ payload: { otp } }) {
  try {
    yield put(actions.setLoadingAction());
    const res = yield call(sendOTP, { otp });
    if (res.data) {
      if (res.data.status === 'success') {
        setCookie('_rc_cookie', res.data.data.coockie_token);
        setCookie('_rc_access', res.data.data.access_token);
        yield put(actions.OTPReceive(res.data));
        yield put(sharedActions.getMunicipalityRequest());
        yield put(sharedActions.getLocalitiesRequest());
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.OTPFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

/**
 * Saga watcher function
 */
function* sagas() {
  yield takeLatest(actionTypes.OTPـREQUEST, OTPSaga);
}

export default sagas;
