import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Typography, TextField
} from '../../components';
import * as actions from '../redux/actions';
import { validation } from '../../../utils';

const SetupWizardContactDetails = ({
  intl,
  newDepot,
  setNewDepotAction,
}) => {
  const handleChange = (data, propertyName) => {
    setNewDepotAction({
      data,
      propertyName
    });
  };

  return (
    <div
      id="wizard-content1"
      className="w-100 d-flex flex-column justify-content-start align-items-start"
    >
      <Typography variant="h2">
        {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.CONTACT_DETAIL' })}
      </Typography>

      <Typography className="mt-3" variant="bodySemiBold">
        {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.CONTACT_PROVIDE_DETAIL' })}
      </Typography>
      <TextField
        label={intl.formatMessage({ id: 'DEPOT.CONTACT.TELEPHONE' })}
        className="my-3 mt-5"
        onChange={(e) => {
          handleChange(e, 'phone');
        }}
        value={newDepot.phone}
        invalidText={
        !validation(newDepot.phone, 'phone')
          ? ' '
          //  intl.formatMessage({ id: 'USERS.FORM.INPUT.PHONE.INVALID' })
          : null
        }
        invalidTextBackgroundColor={process.env.REACT_APP_COLOR_PRIMARY_BG}
      />
      <TextField
        label={intl.formatMessage({ id: 'DEPOT.CONTACT.ALT_TELEPHONE' })}
        className="my-3"
        onChange={(e) => {
          handleChange(e, 'altPhone');
        }}
        value={newDepot.altPhone}
        invalidText={
          !validation(newDepot.altPhone, 'phone')
            ? ' '
            // intl.formatMessage({ id: 'USERS.FORM.INPUT.PHONE.INVALID' })
            : null
        }
        invalidTextBackgroundColor={process.env.REACT_APP_COLOR_PRIMARY_BG}
      />
      <TextField
        label={intl.formatMessage({ id: 'DEPOT.CONTACT.EMAIL' })}
        className="my-3"
        onChange={(e) => {
          handleChange(e, 'email');
        }}
        value={newDepot.email}
        invalidText={
          !validation(newDepot.email, 'email')
            ? ' '
            //  intl.formatMessage({ id: 'USERS.FORM.INPUT.EMAIL.INVALID' })
            : null
        }
        invalidTextBackgroundColor={process.env.REACT_APP_COLOR_PRIMARY_BG}
      />
      <TextField
        label={intl.formatMessage({ id: 'DEPOT.CONTACT.WEBSITE' })}
        className="my-3"
        onChange={(e) => {
          handleChange(e, 'website');
        }}
        value={newDepot.website}
        invalidText={
          !validation(newDepot.website, 'web')
            ? ' '
            //  intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.CONTACT_DETAIL.WEBSITE.INVALID_TEXT' })
            : null
        }
        invalidTextBackgroundColor={process.env.REACT_APP_COLOR_PRIMARY_BG}
      />

    </div>

  );
};

const mapStateToProps = (store) => ({
  newDepot: store.depot.newDepot,
});

export default injectIntl(connect(mapStateToProps, actions)(SetupWizardContactDetails));
