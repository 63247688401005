import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography } from '../../components';

const NewReminderSubheader = ({ title }) => {
  const useStyles = makeStyles(() => ({
    header: {
      borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    },
  }));

  const classes = useStyles();

  return (
    <div className={`w-100 pb-2 mb-4 ${classes.header}`}>
      {title && (
      <Typography variant="h1">
        {title}
      </Typography>
      )}
    </div>
  );
};

export default NewReminderSubheader;
