/* eslint-disable import/prefer-default-export */
import cts from '../../crud/cts';

/**
 * TODO: Insert comment
 */

export const getCollections = async ({ locality }) => cts(
  'post',
  'collection/get-all',
  {
    cms_locality_id: locality,
  }
);

export const createCollection = async ({ data }) => cts(
  'post',
  'collection/add',
  {
    cms_locality_id: data.locality,
    coll_admin_nm: data.name,
    coll_nm: data.name,
    coll_details: data.description,
    set_out_time: data.setoutTime,
    set_out_time_dscr: data.setoutDescription,
  }
);

export const editCollection = async ({ data }) => cts(
  'post',
  'collection/edit',
  {
    coll_id: data.id,
    cms_locality_id: data.locality,
    coll_admin_nm: data.name,
    coll_nm: data.name,
    coll_details: data.description,
    set_out_time: data.setoutTime,
    set_out_time_dscr: data.setoutDescription,
  }
);
