import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Snackbar } from '../components';

import {
  Header,
  Content,
  History,
  CustomList,
  NewReminder,
  CustomForm
} from './components';
import * as actions from './redux/actions';

const Reminder = ({
  intl,
  resetMessageAction,
  responseMessage,
  responseStatus,
  active
}) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (active === false) {
      setIsActive(false);
    }
  }, [active]);

  return (
    <>
      <Snackbar
        message={responseMessage}
        status={responseStatus}
        closeAction={resetMessageAction}
      />
      <Header
        title={intl.formatMessage({ id: 'REMINDER.NEW_REMINDER.HISTORY.TITLE' })}
        content="REMINDER.HEADER.CONTENT"
      />
      <Content>
        <History
          title={intl.formatMessage({ id: 'REMINDER.NEW_REMINDER.HISTORY.TITLE' })}
          isActive={isActive}
          onChange={() => setIsActive(!isActive)}
        >
          {
            !isActive
              ? <CustomList type="active" />
              : <CustomList type="past" />
          }
        </History>
        <NewReminder
          title={intl.formatMessage({ id: 'REMINDER.NEW_REMINDER.TITLE' })}
        >
          <CustomForm />
        </NewReminder>
      </Content>
    </>
  );
};

const mapStateToProps = (store) => ({
  responseMessage: store.reminder.responseMessage,
  responseStatus: store.reminder.responseStatus,
  active: store.reminder.active
});

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(Reminder)
);
