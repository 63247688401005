/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import axiosInstance from './app/crud/axiosInstance';
import { setupAxios } from './_metronic';
import store, { persistor } from './app/redux/store';
import App from './App';
import './index.scss'; // Standard version
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './_metronic/_assets/plugins/line-awesome/css/line-awesome.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: [ 'production' ]
})
const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axiosInstance, store);


ReactDOM.render(
  <ErrorBoundary>
    <App store={store} persistor={persistor} />
  </ErrorBoundary>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
