import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EducationalContent from './EducationalContent';
import SurveyAndMore from './SurveysAndMore';
import { useLocation } from 'react-router-dom';


const useStyles = makeStyles({
  container: {
    margin: '0 auto',
    alignItems: 'start'
  }

});

/**
 * TODO's
 *
 * 1. Change to district only selector
 *
 */

const Education = () => {
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;
  const isMajorCampaigns = pathname.includes('major-campaigns');

  return (
    <div className={`d-flex ${classes.container}`}>
      <EducationalContent fullWidth={isMajorCampaigns} />
      {
        !isMajorCampaigns && <SurveyAndMore style={{ alignItems: 'start' }} />
      }
    </div>
  );
};

export default Education;
