const actionTypes = {
  // GET_RECYCLING_FOR_KIDS_DATA_REQUEST: 'GET_RECYCLING_FOR_KIDS_DATA_REQUEST',
  // GET_RECYCLING_FOR_KIDS_RECEIVE: 'GET_RECYCLING_FOR_KIDS_RECEIVE',
  // GET_RECYCLING_FOR_KIDS_FAIL: 'GET_RECYCLING_FOR_KIDS_FAIL',
  SET_CURRENT_QUESTION_INDEX: 'SET_CURRENT_QUESTION_INDEX',
  FEEDBACK_REQUEST: 'FEEDBACK_REQUEST',
  FEEDBACK_RECEIVE: 'FEEDBACK_RECEIVE',
  FEEDBACK_FAIL: 'FEEDBACK_FAIL',
};

export default actionTypes;
