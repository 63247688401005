import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

import { Portlet, PortletBody, PortletHeader } from '../../../_metronic/partials/content/Portlet';
import {
  Snackbar, AutoComplete, Pagination,
  //  Typography, TextField, CustomButton, Dialog
} from '../components';
import { List } from './components';
import * as actions from './redux/actions';
import * as sharedActions from '../redux/actions';

const useStyles = makeStyles((theme) => ({
  content: {
    width: '85%',
    margin: 'auto',
    [theme.breakpoints.down(900)]: {
      width: '100%'
    },
  },
  locality: {
    width: '40%',
    [theme.breakpoints.down(550)]: {
      width: '100%'
    },
  },
  list: {
    width: '100%',
    overflow: 'auto',
  },
}));

const WasteTypes = ({
  intl,
  localityList,
  locality,
  setLocalityAction,
  responseMessage,
  responseStatus,
  wasteTypesList,
  getWasteTypesListRequest,
  // createWasteTypeRequest,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [name, setName] = useState('');

  useEffect(() => {
    if (locality && locality.cms_locality_id) {
      getWasteTypesListRequest({
        locality: locality.cms_locality_id,
      });
    }
  }, [locality]);

  // const handleCreateClick = () => {
  //   createWasteTypeRequest({
  //     locality: locality.cms_locality_id,
  //     name
  //   });
  // };

  return (
    <Portlet className={classes.content}>
      <PortletHeader
        title={intl.formatMessage({ id: 'WASTE_TYPE.HEADER' })}
      />
      <PortletBody>
        <Snackbar
          message={responseMessage}
          status={responseStatus}
        />
        <div className="d-flex justify-content-end mt-2 mb-3 ">
          <AutoComplete
            className={classes.locality}
            title={intl.formatMessage({ id: 'GENERAL.LOCALITY' })}
            options={localityList}
            optionTitle="cms_locality_nm"
            optionId="cms_locality_id"
            disableClearable
            onChange={(value) => setLocalityAction({ locality: value })}
            value={locality}
          />
        </div>

        <div className={classes.list}>
          <List
            data={wasteTypesList}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <Pagination
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={setPage}
            handleRowsPerPageChange={setRowsPerPage}
            dataLength={wasteTypesList.length}
          />
        </div>

        {/* <div className="mt-5 mb-4 d-flex justify-content-end">
          <Dialog
            disableBackdropClick={false}
            button={(
              <CustomButton
                variant="contained"
                label={intl.formatMessage({ id: 'WASTE_TYPE.CREATE' })}
              />
            )}
            title={(
              <Typography variant="h1">
                {intl.formatMessage({ id: 'GENERAL.ADD' })}
              </Typography>
            )}
            content={(
              <>
                <Typography className="mb-4" variant="body">
                  {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.ADD_TEXT' })}
                </Typography>
                <TextField
                  value={name}
                  onChange={(e) => setName(e)}
                />
              </>
            )}
            handleSubmit={handleCreateClick}
          />
        </div> */}

      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  localityList: store.common.localityList,
  locality: store.common.locality,
  responseMessage: store.wasteType.responseMessage,
  responseStatus: store.wasteType.responseStatus,
  wasteTypesList: store.wasteType.wasteTypeList,
});

export default injectIntl(connect(mapStateToProps, { ...actions, ...sharedActions })(WasteTypes));
