import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import CampaignReportDetailCell from './CampaignReportDetailCell';
import * as actions from '../redux/actions';

const useStyles = makeStyles(() => ({
  border: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
  },
}));

const CampaignReportDetail = ({
  data,
  colors
}) => {
  const classes = useStyles();
  const [expandItems, setExpandItems] = useState(Array(data.length).fill(false));

  return (
    <>
      {
        data.map((item, index) => (
          <div
            key={String(index)}
            className={`${index !== data.length - 1 ? classes.border : null}`}
          >
            <CampaignReportDetailCell
              backColor={colors[index]}
              key={String(index)}
              data={item}
              expanded={expandItems[index]}
              onClick={() => {
                const temp = [...expandItems];
                temp[index] = !temp[index];
                setExpandItems(temp);
              }}
            />
          </div>
        ))
      }
    </>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.campaignReport.fetchState,
  detailCampaign: store.campaignReport.detailCampaign
});

export default injectIntl(connect(mapStateToProps, actions)(CampaignReportDetail));
