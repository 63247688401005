import Cookies from 'js-cookie';
import zxcvbn from 'zxcvbn';
import moment from 'moment';

/**
 * Add commas to number for every 3 digit. If number's digit is multiple of 3, the last comma
 * will be omitted.
 * @param {number} number A number (string or number)
 */
export const addCommasToNumber = (number) => {
  if (!number) return 0;
  const result = number.toString();
  return result.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

/**
 * Remove commas (if exist) from number.
 * @param {number} number A number (string or number)
 */
export const removeCommasFromNumber = (number) => {
  if (!number) return 0;
  const result = number.toString();
  return result.replace(',', '');
};

/**
 * Get the value of given key in user's cookie
 * @param {string} key The key part of (key ,value)
 */
export const getCookie = (key) => Cookies.get(key);

/**
 * Set the (key, value) pair in user's cookie
 * @param {string} key The key part of (key ,value)
 * @param {string} value The value part of (key ,value)
 */
export const setCookie = (key, value) => Cookies.set(key, value);

/**
 * Delete the (key, value) pair of given key in user's cookie
 * @param {string} key The key part of (key ,value)
 */
export const deleteCookie = (key) => Cookies.remove(key);

export const deleteLocalStorage = () => localStorage.clear();

export const validation = (input, type) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneRegex = /^(\+?\d{1,2}[\s.-]?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  const webAddress = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

  switch (type) {
    case 'required':
      if (typeof input === 'object' && input !== 'undefined') {
        return input && Object.keys(input).length !== 0;
      }
      return input && input.length !== 0;
    case 'email':
      return input === '' || emailRegex.test(input);
    case 'phone':
      return input === '' || phoneRegex.test(input);
    case 'atLeast4Characters':
      return input.length >= 4;
    case 'atMost75Characters':
      return input.length <= 75;
    case 'web':
      return input === '' || webAddress.test(input);
    default:
      return undefined;
  }
};

export const requiredValidation = (input) => {
  if (typeof input === 'object' && input !== 'undefined') {
    return !!(input && Object.keys(input).length !== 0);
  }
  return !!(input && input.length !== 0);
};

export const emailValidation = (input) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return input === '' || emailRegex.test(input);
};

export const phoneNumberValidation = (input) => {
  const phoneRegex = /^(\+?\d{1,2}[\s.-]?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  return input === '' || phoneRegex.test(input);
};

export const passwordStrengthValidation = (input) => input === '' || zxcvbn(input).score > 3;

export const confirmPasswordValidation = (password, confirmPassword) => password === '' || password === confirmPassword;

/**
 * Truncates the given text to desired length.
 *
 * @param {string} input Text
 * @param {number} len length to cut
 * @author Hesan
 * @private
 */
export const truncate = (input, len) => (input.length > len ? `${input.substring(0, len)} ...` : input);

/**
 * Compare current and previous data and returns the result.
 *
 * @param {number} current Current data
 * @param {number} previous Previous data
 */
export const dataChangeTracking = (current, previous) => {
  if (current > previous * 1.001) {
    return 'positive';
  } if (current < previous / 1.001) {
    return 'negative';
  }
  return 'neutral';
};

export const isValidDate = (dateString) => {
  const isDate = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(isDate)) return false; // Invalid format
  const d = new Date(dateString);
  const dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString;
};

export const checkValidDistrict = (name, inputArray, search_key) => {
  let x = false;
  if (inputArray.filter((item) => item[search_key] === name).length !== 0) {
    x = true;
  } else {
    x = false;
  }
  return x;
};

/**
 * Transform 24-hour format to am/pm format.
 *
 * @param {Time} time time input
 */
export const transformToAmpm = (time) => moment(`2000-01-01T${time}`).format('hh:mm a');

/**
 * Remove zero from time hour if hour is under 10.
 *
 * @param {Time} time time input
 */
export const removeZeroFromTime = (time) => {
  if (time[0] === '0') {
    return time.slice(1);
  }
  return time;
};

/**
 * Returns formatted address.
 *
 * @param {String} address address input
 */
export const formatAddress = (address) => {
  const result = address.replace(', USA', '').replace(', Canada', '');
  return result;
};

export const nameOfMaterialCategory = (key) => {
  let name = '';
  if (Number(key) !== 'NaN') {
    switch (key) {
      case '1000':
        name = 'Garbage';
        break;
      case '1001':
        name = 'Recycling';
        break;
      case '1002':
        name = 'Organics';
        break;
      case '1003':
        name = 'Yard Waste';
        break;
      case '1004':
        name = 'Bulk items';
        break;
      case '1005':
        name = 'E-Waste';
        break;
      case '1006':
        name = 'HHW';
        break;
      case '1007':
        name = 'Not Accepted';
        break;
      case '1008':
        name = 'Textiles';
        break;
      default:
        name = 'Unknown';
        break;
    }
  }
  return name;
};

/**
 * @author Ali
 * @param {array} data
 */

export const findCurrentMonthsDataIndexHandler = (data) => {
  const currentDate = moment().format('YYYY-MM');
  const totalMonths = data.length;

  const findCurrentBlogIndex = data
    .findIndex((item) => item.date_from.slice(0, 7) === currentDate);
  let initialBlogIndex = null;

  /**
  * if there is no blog for this month display the last one
  * initialBlogIndex = last elements of the blogs array
  */
  if (findCurrentBlogIndex === -1) {
    initialBlogIndex = 0;
  } else {
    /**
    * if there is a blog for this month display it
    */
    initialBlogIndex = findCurrentBlogIndex;
  }

  return initialBlogIndex;
};

/**
 * @author Ali
 * @param {string} direction either 'forward' or 'backward'
 * @param {number} currentItemIndex of item
 * @param {number} length of data array
 */

export const changeCurrentMonthsIndexHandler = (direction, currentItemIndex, length) => {
  let updatedCurrentItemIndex = currentItemIndex;
  if (direction === 'backward' && currentItemIndex < length - 1) {
    updatedCurrentItemIndex += 1;
  }

  if (direction === 'forward' && currentItemIndex > 0) {
    updatedCurrentItemIndex -= 1;
  }
  return updatedCurrentItemIndex;
};
