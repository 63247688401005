const excludedBCDistrictsFromNS = [
  {
    project_id: '563',
    district_id: 'MISS',
  },
  {
    project_id: '521',
    district_id: 'PTMOO',
  },
  {
    project_id: '611',
    district_id: 'NEWH',
  },
  {
    project_id: '581',
    district_id: 'LTF',
  },
  {
    project_id: '502',
    district_id: 'CORDDIST',
  },
  {
    project_id: '521',
    district_id: 'LANGL',
  },
  {
    project_id: '629',
    district_id: 'SAL',
  },
  {
    project_id: '523',
    district_id: 'VillageValemount',
  },
  {
    project_id: '504',
    district_id: 'CNV',
  },
  {
    project_id: '3153',
    district_id: 'FOR',
  },
  {
    project_id: '521',
    district_id: 'RICHM',
  },
  {
    project_id: '3184',
    district_id: 'SQUAM',
  },
  {
    project_id: '581',
    district_id: 'LOO',
  },
  {
    project_id: '581',
    district_id: 'PAUL',
  },
  {
    project_id: '581',
    district_id: 'ASP',
  },
  {
    project_id: '581',
    district_id: 'TNRD-44',
  },
  {
    project_id: '527',
    district_id: 'BRU',
  },
  {
    project_id: '523',
    district_id: 'ElectoralAreaH',
  },
  {
    project_id: '523',
    district_id: 'VillageMcBride',
  },
  {
    project_id: '3173',
    district_id: 'NANAI',
  },
  {
    project_id: '521',
    district_id: 'PITTM',
  },
  {
    project_id: '3187',
    district_id: 'LANG',
  },
  {
    project_id: '621',
    district_id: 'BLO',
  },
  {
    project_id: '665',
    district_id: 'IWMC',
  },
  {
    project_id: '521',
    district_id: 'COQUI',
  },
  {
    project_id: '3176',
    district_id: 'SUMMER',
  },
  {
    project_id: '3187',
    district_id: 'CSAAN',
  },
  {
    project_id: '3187',
    district_id: 'VIEWR',
  },
  {
    project_id: '3187',
    district_id: 'OAK',
  },
  {
    project_id: '3171',
    district_id: 'ROSSL',
  },
  {
    project_id: '581',
    district_id: 'SUN',
  },
  {
    project_id: '539',
    district_id: '539',
  },
  {
    project_id: '3184',
    district_id: 'PEM',
  },
  {
    project_id: '3176',
    district_id: 'OSOY',
  },
  {
    project_id: '3187',
    district_id: 'SIDN',
  },
  {
    project_id: '521',
    district_id: 'MAPLE',
  },
  {
    project_id: '521',
    district_id: 'LANGLE',
  },
  {
    project_id: '502',
    district_id: 'WestKelowna',
  },
  {
    project_id: '3176',
    district_id: 'OLIVE',
  },
  {
    project_id: '3178',
    district_id: 'FORT',
  },
  {
    project_id: '3173',
    district_id: 'LANTZ',
  },
  {
    project_id: '3165',
    district_id: 'GOLD',
  },
  {
    project_id: '3153',
    district_id: 'BUR',
  },
  {
    project_id: '521',
    district_id: 'LIONS',
  },
  {
    project_id: '3152',
    district_id: 'CVRD',
  },
  {
    project_id: '3153',
    district_id: 'FRA',
  },
  {
    project_id: '621',
    district_id: 'NORMAL_MF',
  },
  {
    project_id: '3175',
    district_id: 'ENDE',
  },
  {
    project_id: '581',
    district_id: 'LAC',
  },
  {
    project_id: '527',
    district_id: 'FRE',
  },
  {
    project_id: '3171',
    district_id: 'TRAIL',
  },
  {
    project_id: '581',
    district_id: 'PRI',
  },
  {
    project_id: '581',
    district_id: 'BAR',
  },
  {
    project_id: '3175',
    district_id: 'LUMB',
  },
  {
    project_id: '621',
    district_id: 'EAC',
  },
  {
    project_id: '504',
    district_id: 'DWV',
  },
  {
    project_id: '581',
    district_id: 'MER',
  },
  {
    project_id: '3187',
    district_id: 'JUAN',
  },
  {
    project_id: '523',
    district_id: 'ElectoralAreaD',
  },
  {
    project_id: '581',
    district_id: 'LYT',
  },
  {
    project_id: '686',
    district_id: 'KITIMAT',
  },
  {
    project_id: '3164',
    district_id: 'CHILL',
  },
  {
    project_id: '3165',
    district_id: 'REVEL',
  },
  {
    project_id: '3178',
    district_id: 'DAWS',
  },
  {
    project_id: '581',
    district_id: 'WES',
  },
  {
    project_id: '581',
    district_id: 'BLP',
  },
  {
    project_id: '581',
    district_id: 'VAV',
  },
  {
    project_id: '3167',
    district_id: 'CRAN',
  },
  {
    project_id: '501',
    district_id: 'SURREY',
  },
  {
    project_id: '521',
    district_id: 'ABBOT',
  },
  {
    project_id: '3187',
    district_id: 'HIGH',
  },
  {
    project_id: '611',
    district_id: 'STEWART',
  },
  {
    project_id: '581',
    district_id: 'AGB',
  },
  {
    project_id: '581',
    district_id: 'LWN',
  },
  {
    project_id: '523',
    district_id: 'ElectoralAreaE',
  },
  {
    project_id: '502',
    district_id: 'LakeCountry',
  },
  {
    project_id: '581',
    district_id: 'LOGL',
  },
  {
    project_id: '523',
    district_id: 'ElectoralAreaC',
  },
  {
    project_id: '581',
    district_id: 'BLUE',
  },
  {
    project_id: '621',
    district_id: 'NOR',
  },
  {
    project_id: '611',
    district_id: 'KITSU',
  },
  {
    project_id: '3187',
    district_id: 'METCH',
  },
  {
    project_id: '3187',
    district_id: 'SAAN',
  },
  {
    project_id: '523',
    district_id: 'DistrictMackenzie',
  },
  {
    project_id: '3187',
    district_id: 'NSAAN',
  },
  {
    project_id: '521',
    district_id: 'ANMO',
  },
  {
    project_id: '585',
    district_id: 'SARA',
  },
  {
    project_id: '3187',
    district_id: 'VICT',
  },
  {
    project_id: '3176',
    district_id: 'OLIV',
  },
  {
    project_id: '581',
    district_id: '70MH',
  },
  {
    project_id: '533',
    district_id: 'NWE',
  },
  {
    project_id: '3175',
    district_id: 'VERN',
  },
  {
    project_id: '581',
    district_id: 'ASH',
  },
  {
    project_id: '3173',
    district_id: 'PARKS',
  },
  {
    project_id: '3153',
    district_id: 'GRA',
  },
  {
    project_id: '3167',
    district_id: 'SPAR',
  },
  {
    project_id: '581',
    district_id: 'BRK',
  },
  {
    project_id: '581',
    district_id: 'CHU',
  },
  {
    project_id: '581',
    district_id: 'SAV',
  },
  {
    project_id: '629',
    district_id: 'REVELSTOKE',
  },
  {
    project_id: '3175',
    district_id: 'ARMS',
  },
  {
    project_id: '611',
    district_id: 'KIT',
  },
  {
    project_id: '3175',
    district_id: 'SPAM',
  },
  {
    project_id: '502',
    district_id: 'Peachland',
  },
  {
    project_id: '551',
    district_id: 'NELS',
  },
  {
    project_id: '581',
    district_id: 'LOU',
  },
  {
    project_id: '521',
    district_id: 'VANC',
  },
  {
    project_id: '3167',
    district_id: 'INVE',
  },
  {
    project_id: '3176',
    district_id: 'PENT',
  },
  {
    project_id: '3153',
    district_id: 'SMI',
  },
  {
    project_id: '523',
    district_id: 'PrinceGeorge',
  },
  {
    project_id: '3153',
    district_id: 'HOU',
  },
  {
    project_id: '504',
    district_id: 'DNV',
  },
  {
    project_id: '3187',
    district_id: 'ESQ',
  },
  {
    project_id: '527',
    district_id: 'MYE',
  },
  {
    project_id: '3165',
    district_id: 'SALMO',
  },
  {
    project_id: '551',
    district_id: 'CAST',
  },
  {
    project_id: '521',
    district_id: 'WHITE',
  },
  {
    project_id: '563',
    district_id: 'MISSION',
  },
  {
    project_id: '581',
    district_id: 'HEF',
  },
  {
    project_id: '502',
    district_id: 'Kelowna',
  },
  {
    project_id: '611',
    district_id: 'KITSELAS',
  },
  {
    project_id: '521',
    district_id: 'BOWEN',
  },
  {
    project_id: '581',
    district_id: 'CAC',
  },
  {
    project_id: '581',
    district_id: 'KAML',
  },
  {
    project_id: '581',
    district_id: 'TRQ',
  },
  {
    project_id: '581',
    district_id: 'UPN',
  },
  {
    project_id: '3167',
    district_id: 'FERN',
  },
  {
    project_id: '3173',
    district_id: 'QUALI',
  },
  {
    project_id: '3187',
    district_id: 'COLW',
  },
  {
    project_id: '511',
    district_id: 'Burnaby',
  },
  {
    project_id: '581',
    district_id: 'EGL',
  },
  {
    project_id: '611',
    district_id: 'TER',
  },
  {
    project_id: '3175',
    district_id: 'COLDS',
  },
  {
    project_id: '523',
    district_id: 'ElectoralAreaA',
  },
  {
    project_id: '3187',
    district_id: 'SOOK',
  },
  {
    project_id: '581',
    district_id: 'CLE',
  },
  {
    project_id: '523',
    district_id: 'ElectoralAreaG',
  },
  {
    project_id: '521',
    district_id: 'PittMeadows',
  },
  {
    project_id: '3167',
    district_id: 'KIMBE',
  },
  {
    project_id: '511',
    district_id: 'BUR',
  },
  {
    project_id: '684',
    district_id: '684',
  },
  {
    project_id: '611',
    district_id: 'RDKI',
  },
  {
    project_id: '523',
    district_id: 'ElectoralAreaF',
  },
  {
    project_id: '502',
    district_id: 'COWest',
  },
  {
    project_id: '581',
    district_id: 'SPB',
  },
  {
    project_id: '581',
    district_id: 'CHA',
  },
  {
    project_id: '3176',
    district_id: 'OSOYO-97',
  },
  {
    project_id: '521',
    district_id: 'PTCOQ',
  },
  {
    project_id: '581',
    district_id: 'CLI',
  },
  { project_id: 'CalWaste', district_id: 'BEAR' },
  { project_id: 'CalWaste', district_id: 'Calvares' },
  { project_id: 'CalWaste', district_id: 'Angels' },
  { project_id: 'CalWaste', district_id: 'Galt' },
  { project_id: 'CalWaste', district_id: 'Isleton' },
  { project_id: 'CalWaste', district_id: 'EPC' },
  { project_id: 'CalWaste', district_id: 'RanchoMurieta' },
  { project_id: 'CalWaste', district_id: 'Woodbridge' },
  { project_id: 'ATLAS', district_id: 'RANCH' },
  { project_id: 'MOMENTUM', district_id: 'MOMENTMR' },
  { project_id: '3008', district_id: 'GREEN' },
  { project_id: 'KINGCNTY', district_id: 'FDRLWAY' },
  { project_id: '529', district_id: 'SAR' },
];

export default excludedBCDistrictsFromNS;
