import React from 'react';
import { connect } from 'react-redux';

const SupportForm = ({

}) => (
  <div className="d-flex justify-content-center align-items-center">
    <iframe style={{ border: 'none', height: '100vh', width: '100%' }} scrolling="none" src={`https://share.hsforms.com/1h-CoCnsATAGdImsWWvJ12Qe4tiu`} title={'Form'}/>

  </div>
);


const mapStateToProps = (store) => ({
});

export default connect(null, null)(SupportForm);
