import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Portlet,
  PortletHeader,
  PortletBody,
  PortletHeaderToolbar
} from '../../../../../_metronic/partials/content/Portlet';

import Backdrop from '@material-ui/core/Backdrop';


import { makeStyles } from '@material-ui/core/styles';


import { Switch, Typography, CustomButton } from '../../../components';

import LockIcon from '@material-ui/icons/Lock';




const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    // marginLeft: 72,
  },
  smallContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '10px',
    width: '100%',
    marginLeft: 72,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    position: "absolute",
  },
  root: {
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
    borderRadius: '10px',
    backdropFilter: 'blur(3px)',
  },
  button: {
    width: '100%',
  },
  displayMoreText: {
    width: 500,
    textAlign: 'center',
  },
  TurnOnButton: {
    width: 400
  },
  lockContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  lockContainerSmall: {
    width: 143,
  }

}));

const BulkPickUpOverlay = ({
  intl,
  displayMore,
  csm,
}) => {
  const classes = useStyles();

  return (
    <Backdrop
      className={classes.backdrop}
      classes={{
        root: classes.root
      }}
      open={open}>
      <div className={displayMore ? classes.container : classes.smallContainer}
      >
        <div className={`${classes.lockContainer} ${displayMore ? '' : classes.lockContainerSmall}`}>
          <div>
            <LockIcon
              style={{ color: '#67ccc5' }}
              fontSize="large"
            />
          </div>
          <div>
            <Typography variant="h1">
              The Bulk Pick-up Appointment System has not been activated.
            </Typography>
          </div>
        </div>

        {
          displayMore && (
            <div className={classes.displayMoreText}>
              <Typography variant="body" style={{
                fontSize: 14,
              }}>
                This is a special service for locations that pick up bulky items by appointment.
                <br/>
                <br/>
                Free up staff time and simplify the process for your residents. By allowing residents to book their own appointments, you can save time and money – and it is fully compliant with your rules and accepted items list. Each week, you or your hauler will receive a file with the following week’s appointments.  
                <br/>
                <br/>
                The file can also be uploaded into routing software to create greater efficiencies. To learn more about the Bulk Item Pick-Up tool,  <a href={csm?.calendly_link} target="_blank">contact your Customer Success Manager.</a>
              </Typography>
            </div>
          )
        }
        <div className={`${displayMore ? classes.TurnOnButton : ''}`}>
          <CustomButton
            paddingVertical={4}
            className={`${classes.button}`}
            variant={'contained'}
            label={'Contact Customer Success Manager'}
            onClick={() => {
              if (csm?.calendly_link) {
                window.open(csm?.calendly_link, "_blank")
              }
            }} />
        </div>
      </div>

    </Backdrop>
  );
};

export default injectIntl(BulkPickUpOverlay);
