import React from 'react';

const PdfIcon = ({
  color,
  className,
}) => (
  <svg className={`bi bi-file-earmark ${className}`} width="1em" height="1em" viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M4 1h5v1H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6h1v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2z" />
    <path d="M9 4.5V1l5 5h-3.5A1.5 1.5 0 0 1 9 4.5z" />
  </svg>
);

export default PdfIcon;
