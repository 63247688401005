import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles({
  '@keyframes fadeUp': {
    '0%': { opacity: 0 },
    '50%': { opacity: 0.3, transform: 'translateY(-8px)' },
    '100%': { opacity: 0.3, transform: 'translateY(0)' },
  },
  autoScrollIconContainer: {
    position: 'fixed',
    right: 20,
    bottom: 20,
    cursor: 'pointer',
    width: 40,
    height: 40,
    opacity: 0.3,
    background: '#0086C2',
    borderRadius: 20,
    animation: '$fadeUp 0.3s ease-in-out',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',

    '&:hover': {
      opacity: 1,
    },

    '& svg': {
      color: '#fff',
      height: 30,
      width: 30
    }
  }
});

const ScrollToTop = ({ scrollToTopHandler }) => {
  const classes = useStyles();

  return (
    <div className={`d-flex align-items-center justify-content-center ${classes.autoScrollIconContainer}`}>
      <KeyboardArrowUpIcon onClick={scrollToTopHandler} />
    </div>
  );
};

export default ScrollToTop;
