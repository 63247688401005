import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { LoadingSpinner, ErrorContainer } from '../../../components/loading';
import { ProUser } from '../components';

import * as actions from './redux/actions';
import * as sharedActions from '../../../redux/actions';


import Main from './components/Main';

const WhereDoesThisGo = ({
  intl,
  district,
  loading,
  error,
  getWhereDoesThisGoDataRequestAction,
  whereDoesThisGo,
  isUserPro,
  stage,
  getDistrictProPackagesRequest,
  district_pro_packages,
}) => {
  const [hasSpotLightMaterial, sethasSpotLightMaterial] = useState(false);

  useEffect(() => {
    if (district.district_id !== '#all-districts#' && isUserPro && stage >= 4) {
      const [projectId, districtId] = district.district_id.split('#$#');
      getWhereDoesThisGoDataRequestAction(projectId, districtId);
    } else {
      getWhereDoesThisGoDataRequestAction('556', '556');
    }
  }, [district]);

  useEffect(() => {
    if (stage !== '4') {
      getDistrictProPackagesRequest();
    }
  }, [stage]);

  useEffect(() => {
    if (district_pro_packages && district_pro_packages[0] && district_pro_packages[0].has_spot_light_material) {
      sethasSpotLightMaterial(true);
    }
  }, [district_pro_packages]);

  return (
    <>
      {
        !isUserPro && stage < 4 && !hasSpotLightMaterial &&  (
          <ProUser
            title={intl.formatMessage({ id: 'EDUCATION.WHERE.DOES.THIS.GO.TITLE' })}
            description="Target specific materials with this monthly ‘Where does it go’ feature displayed prominently on the home screen of the app. "
            subtitle="Residents are shown the proper disposal of 5 spotlight materials per month in this photo gallery styled feature."
            alternateRightTitle={intl.formatMessage({ id: 'GENERAL.WHAT.BENEFITS' })}
            imagePath="where-does-it-go-non-pro.png"
            benefits={['Target seasonal materials ', 'Keep residents aware of contaminants ', 'Highlight new materials accepted in your program ']}
          />
        )
      }
      <div>
        {
          loading
            ? <LoadingSpinner height={350} />
            : error || whereDoesThisGo.length === 0 ? (
              <ErrorContainer
                small
                reportName="whereDoesThisGo"
                height={350}
                error={error}
              />
            ) : <Main isUserPro={isUserPro} stage={stage} hasSpotLightMaterial={hasSpotLightMaterial}/>
        }
      </div>
    </>

  );
};


const mapStateToProps = (store) => ({
  district: store.common.district,
  loading: store.whereDoesThisGo.loading,
  error: store.whereDoesThisGo.error,
  whereDoesThisGo: store.whereDoesThisGo.spotlight_materials,
  isUserPro: store.common.isUserPro,
  stage: store.common.district.stage,

});


export default injectIntl(connect(mapStateToProps, { ...actions, ...sharedActions })(WhereDoesThisGo));

