/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { ViewDetailsIcon } from '../../../../assets/icons';
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from '../../../../../_metronic/partials/content/Portlet';
import {
  Pagination,
  AutoComplete,
  Dialog,
  Typography,
} from '../../../components';
import { TableLoading } from '../../../components/loading';
import SearchBar from './SearchBar';
import DetailTable from './DetailTable';

const useStyles = makeStyles((theme) => ({
  detailLoading: {
    height: '50vh'
  },
  smallerThanSmHide: {
    [theme.breakpoints.down('830')]: {
      display: 'none !important'
    },
    [theme.breakpoints.up('831')]: {
      display: 'inline-block !important'
    },
  },
  smallerThanSmShow: {
    padding: '10px !important',
    [theme.breakpoints.up('830')]: {
      display: 'none !important',
    },
  },
  border: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
  },
  viewDetails: {
    color: process.env.REACT_APP_COLOR_PRIMARY
  },
  profilePicture: {
    width: '100%',
    height: '100%',
    border: 'none',
    backgroundColor: '#ebebeb',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'relative'
  },
  NoProblem: {
    color: process.env.REACT_APP_COLOR_BACKGROUND_FILL
  }
}));

const Detail = ({
  intl,
  data,
  fromDate,
  toDate,
  fetchState,
  helpItems,
  firstOtherProblemTypeItems
}) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);
  const [help, setHelp] = useState({ type: 'all', title: 'All' });
  const [sortValue, setSortValue] = useState({ title: 'Date', value: 'date' });
  const isSendRequest = fetchState === 'send-request';
  const [problemTypeOptions, setProblemTypeOptions] = useState([{ type: 'all', title: 'All' }].concat(helpItems));
  const [firstHelpItem, setFirstHelpItem] = useState('');

  useEffect(() => {
    setHelp({ type: 'all', title: 'All' });
    if (helpItems.length && problemTypeOptions.length === 1) {
      const temp = [...helpItems];
      const isOther = temp.findIndex((item) => item.type.toLowerCase() === 'other');
      if (isOther !== -1) {
        temp.splice(isOther, 1);
      }
      setFirstHelpItem(temp[0].title);
      setProblemTypeOptions([{ type: 'all', title: 'All' }].concat(temp));
    }
  }, [data, helpItems]);

  return (
    <Portlet className="h-100 position-relative">
      <PortletHeader
        toolbar={(
          <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start">
            <div className="row col-xs-12 col-lg-6 align-items-center">
              <div className="align-items-center pl-3">
                <Typography variant="h1">
                  {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.HEADER' })}
                </Typography>
              </div>
              <div className="col-sm-12 col-lg justify-content-start align-items-center mt-3 mt-lg-0 ml-lg-3">
                <Typography variant="bodySemiBold">
                  {`${moment(fromDate).format('MMMM Do, YYYY')} - ${moment(toDate).format('MMMM Do, YYYY')}`}
                </Typography>
              </div>
            </div>
          </div>
        )}
      />
      <PortletBody>
        <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center">
          <div className="col-12 col-lg-4">
            <SearchBar
              value={searchValue}
              onChange={setSearchValue}
            />
          </div>
          <div className="col-12 col-lg-4 mt-4 mt-lg-0">
            <AutoComplete
              title={intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.PROBLEM_TYPE' })}
              options={problemTypeOptions}
              optionTitle="title"
              optionId="type"
              onChange={(item) => { setHelp(item); }}
              value={help}
              groupBy={(option) => {
                if (option.title === firstHelpItem) {
                  return 'Help items';
                } if (option.title === firstOtherProblemTypeItems) {
                  return 'Problem types';
                }
                return null;
              }}
            />
          </div>
        </div>
        <div className={`mt-3 ${classes.smallerThanSmShow}`}>
          <AutoComplete
            title={intl.formatMessage({ id: 'GENERAL.SORT' })}
            placeholder="Select one or more cities"
            options={[
              { title: 'Date', value: 'date' },
              { title: 'Problem Type', value: 'problem_type' },
              { title: 'Name', value: 'name' },
              { title: 'Address', value: 'address' }
            ]}
            optionTitle="title"
            isDisable={false}
            onChange={(item) => { setSortValue(item); }}
            value={sortValue}
          />
        </div>
        <div className={`${classes.smallerThanSmHide}`}>
          { isSendRequest ? (
            <div className="d-flex overflow-hidden flex-column w-100 justify-content-between align-items-center">
              <TableLoading width="100%" />
            </div>
          ) : (
            <>
              {data && data.length ? (
                <DetailTable
                  searchValue={searchValue}
                  data={data}
                  help={help}
                />
              ) : (
                <div className="d-flex justify-content-center align-items-center py-5">
                  <Typography className={classes.NoProblem} variant="numberSmall">
                    {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.NO_PROBLEM' })}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
        {/* mobile style */}
        <div className={`mt-4 ${classes.smallerThanSmShow}`}>
          {
            isSendRequest ? (
              <div className="d-flex overflow-hidden flex-column w-100 justify-content-between align-items-center">
                <TableLoading width="100%" />
              </div>
            ) : (
              <>
                { data && data.length ? [...data]
                  .filter((item) => (((help && help.type === 'all') || !help) ? item : item.problem_type_cd === help.type))
                  .filter((item) => JSON.stringify(item)
                    .toLowerCase().includes(searchValue))
                  .sort((a, b) => ((a[sortValue.value] > b[sortValue.value]) ? 1 : -1))
                  .slice(page * 5, page * 5 + 5)
                  .map((item, index) => (
                    <div key={String(index)} className={`d-flex flex-column pl-5 pr-5 pb-3 pt-3 ${classes.border}`}>
                      <div className="row justify-content-between align-content-center mt-2">
                        <Typography className="col" variant="body">
                          {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.DATE' })}
                        </Typography>
                        <Typography className="col" variant="bodySemiBold">
                          {item.date}
                        </Typography>
                      </div>
                      <div className="row justify-content-between align-content-center mt-2">
                        <Typography className="col" variant="body">
                          {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.PROBLEM_TYPE' })}
                        </Typography>
                        <Typography className="col" variant="bodySemiBold">
                          {item.problem_type_label}
                        </Typography>
                      </div>
                      <div className="row justify-content-between align-content-center mt-2">
                        <Typography className="col" variant="body">
                          {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.NAME' })}
                        </Typography>
                        <Typography className="col" variant="bodySemiBold">
                          {item.contact_full_nm}
                        </Typography>
                      </div>
                      <div className="row justify-content-between align-content-center mt-2">
                        <Typography className="col" variant="body">
                          {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.ADDRESS' })}
                        </Typography>
                        <Typography className="col" variant="bodySemiBold">
                          {item.address}
                        </Typography>
                      </div>
                      <div className="row justify-content-center align-items-center mt-3">
                        <Dialog
                          disableBackdropClick={false}
                          maxWidth="md"
                          hasButtons={false}
                          button={(
                            <div className="d-flex justify-content-center align-items-center">
                              <Typography className={`mr-2 ${classes.viewDetails}`} variant="body">
                                {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.VIEW_DETAILS' })}
                              </Typography>
                              <ViewDetailsIcon
                                color={process.env.REACT_APP_COLOR_PRIMARY}
                                className={classes.viewDetailsIcon}
                              />
                            </div>
                        )}
                          title={(
                            <div className="d-flex flex-column">
                              <Typography variant="h5">
                                {item.problem_type_label}
                              </Typography>
                              <Typography variant="bodySemiBold">
                                {item.date}
                              </Typography>
                            </div>
                        )}
                          content={(
                            <div className="pb-5">
                              <Typography className="pl-4" variant="h6">
                                {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.VIEW_DETAILS.PROBLEM_DETAILS' })}
                              </Typography>
                              <div className="d-flex flex-column p-4 pb-5">
                                <div className="mb-4">
                                  <Typography variant="body">
                                    {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.VIEW_DETAILS.NAME' })}
                                  </Typography>
                                  <Typography className="mt-3" variant="h6">
                                    {item.contact_full_nm}
                                  </Typography>
                                </div>
                                <div className="mb-4">
                                  <Typography variant="body">
                                    {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.VIEW_DETAILS.PHONE_NUMBER' })}
                                  </Typography>
                                  <Typography className="mt-3" variant="h6">
                                    {item.contact_phone_nbr}
                                  </Typography>
                                </div>
                                <div className="mb-4">
                                  <Typography variant="body">
                                    {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.VIEW_DETAILS.ADDRESS' })}
                                  </Typography>
                                  <div className="d-flex flex-column mt-3">
                                    <Typography variant="h6">
                                      {item.address}
                                    </Typography>
                                    {item.location_latitude !== null && item.location_longitude !== null
                                      && (
                                      <a
                                        href={`https://www.google.com/maps/search/google+map+apis/@${item.location_latitude},${item.location_longitude},16z`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <Typography
                                          className={classes.viewDetails}
                                          variant="bodySemiBold"
                                        >
                                          {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.VIEW_ON_MAP' })}
                                        </Typography>
                                      </a>
                                      )}
                                  </div>

                                </div>
                                <div className="mb-4">
                                  <Typography variant="body">
                                    {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.VIEW_DETAILS.EMAIL' })}
                                  </Typography>
                                  <Typography className="mt-3" variant="h6">
                                    {item.contact_email_addr}
                                  </Typography>
                                </div>
                                <div className="mb-4">
                                  <Typography variant="body">
                                    {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.VIEW_DETAILS.DISTRICT' })}
                                  </Typography>
                                  <Typography className="mt-3" variant="h6">
                                    {item.district_nm}
                                  </Typography>
                                </div>
                                <div className="mb-3">
                                  <Typography variant="body">
                                    {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.VIEW_DETAILS.DESCRIPTION' })}
                                  </Typography>
                                  <Typography className="mt-3" variant="h6">
                                    {item.problem_dscr}
                                  </Typography>
                                </div>
                                <div
                                  className="mb-3 pb-3 bg-success d-flex justify-content-center align-items-center"
                                  style={{ height: '300px' }}
                                >
                                  {item.has_photo === 'f'
                                    ? (
                                      <Typography variant="subtitle2">
                                        {intl.formatMessage({ id: 'GENERAL.NO_PHOTO' })}
                                      </Typography>
                                    ) : (
                                      <div
                                        role="img"
                                        className={classes.profilePicture}
                                        style={{ backgroundImage: `img_path${item.img_path})` }}
                                        title="Problem Image"
                                      />
                                    )}
                                </div>
                              </div>
                            </div>
                        )}
                        />
                      </div>
                    </div>
                  ))
                  : (
                    <div className="d-flex justify-content-center align-items-center py-5">
                      <Typography className={classes.NoProblem} variant="numberSmall">
                        {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.NO_PROBLEM' })}
                      </Typography>
                    </div>
                  )}
              </>
            )
            }
        </div>
        {data && data.length
          ? (
            <div className={`d-flex flex-column mt-3 mb-3 justify-content-center align-items-center ${classes.smallerThanSmShow}`}>
              <Pagination
                pager={false}
                page={((page + 1) * (5)) - (4)}
                rowsPerPage={5}
                handlePageChange={(event, page) => setPage(page - 1)}
                handleRowsPerPageChange={() => {}}
                dataLength={data
                  .filter((item) => (((help && help.type === 'all') || !help) ? item : item.problem_type_cd === help.type))
                  .filter((item) => item.toString().toLowerCase().includes(searchValue.toLowerCase())).length}
              />

            </div>
          ) : null}
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
  fetchState: store.problemReport.fetchState,
});

export default injectIntl(connect(mapStateToProps, null)(Detail));
