import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import axios from 'axios';

import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { LoadingSpinner, ErrorContainer } from '../../../../components/loading';

import {
  Opacity, Typography, Pagination
} from '../../../../components';

import * as actions from '../redux/actions';

import { PDFAddOnCard } from '../components/';
import { CrownIcon } from '../../../../../assets/icons';



const useStyles = makeStyles(() => ({
  packImage: {
    display: 'flex',
    margin: 'auto'
  },
  downloadButton: {
    width: '100%',
    height: 48,
    left: 912,
    top: 464,
    background: '#0086C2',
    borderRadius: 4,
    color: '#fff',
    fontWeight: '700'
  },
  feedbackContainer: {
    marginTop: 25,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  title: {
    fontWeight: '600',
    fontSize: 18,
    lineHeight: '27px',
    letterSpacing: '0.04em',
    color: '#6C7293',
  }

}));


const Main = ({
  intl,
  district_id_value,
  project_id_value,
  isUserPro,
  stage
}) => {
  const classes = useStyles();
  const [pdfAddOns, setPDFAddOns] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [page, setPage] = useState(1);

  const endpoint = `https://cdn.recyclecoach.com/resources/pdf-add-ons/education-pdf-add-ons.json?v=${Date.now()}`;
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios(endpoint);

        let pdfAddOns = [];
        response.data.pdf_add_ons.forEach(pdf => {
          if (!pdf.only_for_districts.length && !pdf.excluded_districts.length) {
            pdfAddOns.push(pdf);
          } else if (pdf.only_for_districts.length && pdf.only_for_districts.find((c) => c.district_id === district_id_value && c.project_id_value === project_id_value)) {
            pdfAddOns.push(pdf);
          } else if (pdf.excluded_districts.length && pdf.excluded_districts.find((c) => c.district_id !== district_id_value && c.project_id_value !== project_id_value)) {
            pdfAddOns.push(pdf);
          }
        });

        setPDFAddOns(pdfAddOns);
        setIsLoading(false);

      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  return (
    <div>
      {
        isLoading
          ? <LoadingSpinner height={350} />
          : error || pdfAddOns.length === 0 ? (
            <ErrorContainer
              small
              reportName="pdfAddOns"
              height={350}
              error={'error'}
            />
          ) : (
            <Opacity slide>
              {isUserPro && stage >= 4 && (
                <>
                  <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-2" variant="h1">
                    {intl.formatMessage({ id: 'EDUCATION.PDF.ADD.ONS' })}
                    <CrownIcon style={{ marginLeft: 7 }} width={15} height={15} color="#FFC611" />
                  </Typography>
                  <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="educationBody">
                    {intl.formatMessage({ id: 'EDUCATION.PDF.ADD.ONS.SUBTITLE' })}
                  </Typography>
                </>
              )}

              <Typography variant="h2" className={classes.title}>
                {intl.formatMessage({ id: 'EDUCATION.PDF.ADD.ONS.LIST' })}
                {' '}
                {!isUserPro && stage < 4 && 'which are displayed to PRO Users '}
              </Typography>

              <div className="d-flex flex-wrap mt-2" style={{ flexDirection: 'row' }}>
                {
                  pdfAddOns && pdfAddOns.slice((page - 1) * 2, (page * 2)).map((item, index) => (
                    <>
                      <Typography className={`mb-4`} style={{ width: '50%' }} variant="h2">
                        <PDFAddOnCard key={index} isDeletable={false} data={item} showPreview={isUserPro && stage >= 4}/>
                      </Typography>
                    </>
                  ))
                }
              </div>
              <div className={`mt-5 mb-4 d-flex justify-content-end align-items-center w-100 ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
                <Pagination
                  page={page}
                  rowsPerPage={2}
                  handlePageChange={(value) => handleChangePage(value)}
                  dataLength={pdfAddOns && pdfAddOns.length}
                  hideRowsPerPage
                />
              </div>
            </Opacity>
          )
      }
    </div>
  );
};


export default injectIntl(connect(null, actions)(Main));
