import React from 'react';
import { connect } from 'react-redux';

import { Summary, Detail } from './components';

const CampaignReport = ({
  data,
}) => (
  <div>
    <Summary
      data={data}
    />
    <Detail
      data={data}
    />
  </div>
);

const mapStateToProps = (store) => ({
  data: store.campaignReport.data.campaigns,
});

export default connect(mapStateToProps, null)(CampaignReport);
