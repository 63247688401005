import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { toAbsoluteUrl } from '../../../../../../_metronic';
import { Typography } from '../../../../components';

const useStyles = makeStyles(() => ({
  caseStudy: {
    width: '100%',
    minHeight: 176,
    background: '#F2F3F8',
    margin: '20px 0',
  },
  content: {
    padding: 20,
    display: 'flex',
    gap: 10,
  },
  title: {
    fontWeight: 500,
    fontSize: 18,
    letterSpacing: '0.9px',
    color: '#404040',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    // justifyContent: 'space-between',
  },
  downloadText: {
    color: '#0086C2',
    fontWeight: 700,
    fontSize: 13,
    cursor: 'pointer',
  }
}));
const MajorCampaignCaseStudy = ({
  intl,
  title,
  coverImage,
  caseStudyFile,
}) => {
  const classes = useStyles();

  const handleDownload = () => {
    const url = toAbsoluteUrl(`${caseStudyFile}`);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'casestudy.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className={classes.caseStudy}>
      <div className={classes.content}>
        <img
          alt={title}
          width={200}
          height={200}
          src={toAbsoluteUrl(`${coverImage}`)}
        />
        <div className={classes.textContainer}>
          <Typography variant="h1" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="h1" className={classes.downloadText} onClick={handleDownload}>
            Download the Case Study
          </Typography>
        </div>

      </div>
    </div>
  );
};

export default injectIntl(MajorCampaignCaseStudy);
