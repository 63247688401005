import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  // slide while changin opacity
  '@keyframes opacitySlide': {
    '0%': {
      opacity: 0,
      transform: 'translateY(3px)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)'
    }
  },

  opacitySlide: {
    animation: '$opacitySlide 0.3s ease'
  },

  // change opacity only
  '@keyframes opacityOnly': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    }
  },

  opacityOnly: {
    animation: '$opacityOnly 0.3s ease'
  }
});

const Opacity = ({ children, className, slide }) => {
  const classes = useStyles();
  return (
    <div className={`${slide ? classes.opacitySlide : classes.opacityOnly} ${className}`}>{children}</div>
  );
};

export default Opacity;
