import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../redux/createReducer';
import initialState from './store';

const wasteAndRecyclingRulesReducer = persistReducer(
  { storage, key: 'wasteAndRecyclingRules', whitelist: [] },
  createReducer(initialState, {
    SET_WASTE_RULES_LOADING_ACTION: (state, { payload }) => Immutable({
      ...state,
      fetchState: payload,
      responseMessage: initialState.responseMessage,
    }),

    GET_WASTE_RULES_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      data: payload.data.data,
      fetchState: 'succeed-request'
    }),

    GET_WASTE_RULES_FAIL: (state) => Immutable({
      ...state,
      fetchState: 'failed-request'
    }),

    EDIT_WASTE_RULES_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      updatedKey: payload.data.data.waste_recycling_rule_id,
      fetchState: 'succeed-request',
      responseMessage: payload.data.message,
      responseStatus: 'success',
    }),

    EDIT_WASTE_RULES_FAIL: (state) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseMessage: 'Failed',
      responseStatus: 'error',
    }),
  })
);

export default wasteAndRecyclingRulesReducer;
