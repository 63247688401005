import React from 'react';

const ViewDetailsIcon = ({
  color,
  className,
}) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    className={`prefix__bi prefix__bi-plus-circle ${className}`}
    fill={color}
  >
    <path
      fillRule="evenodd"
      d="M8 3.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H4a.5.5 0 010-1h3.5V4a.5.5 0 01.5-.5z"
    />
    <path
      fillRule="evenodd"
      d="M7.5 8a.5.5 0 01.5-.5h4a.5.5 0 010 1H8.5V12a.5.5 0 01-1 0V8z"
    />
    <path
      fillRule="evenodd"
      d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z"
    />
  </svg>
);

export default ViewDetailsIcon;
