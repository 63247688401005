/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { shallowEqual, useSelector, connect } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import { injectIntl } from 'react-intl';
import {
  Redirect,
  Route,
  Switch,
  withRouter,
  useLocation
} from 'react-router-dom';

import { LayoutContextProvider } from '../../_metronic';
import Layout from '../../_metronic/layout/Layout';
import * as routerHelpers from './routerHelpers';
import * as actions from '../pages/redux/actions';
import { Dialog } from '../pages/components';
import AuthPage from '../pages/Auth';
import LoginAs from '../pages/Auth/LoginAs';
import Logout from '../pages/Auth/Logout';
import ErrorPage from '../pages/Error';
import Dashboard from '../pages/Dashboard';
import Notification from '../pages/Notification';
import Reminder from '../pages/Reminder';
import Users from '../pages/Users';
import ReportRoutes from '../pages/Report';
import Campaign from '../pages/Campaign';
import Profile from '../pages/Auth/Profile';
import Category from '../pages/Category';
import Depot from '../pages/Depot';
import Event from '../pages/Event';
import WasteTypes from '../pages/WasteTypes';
import CollectionRequirement from '../pages/CollectionRequirement';
import WasteAndRecyclingRules from '../pages/WasteAndRecyclingRules';
import Schedule from '../pages/Schedule';
import PerpetualSchedules from '../pages/Schedule/PerpetualSchedules';
import Education from '../pages/Education';
import { BlogDetails } from '../pages/Education/EducationalContent/components';
import WGWHome from '../pages/WGWHome';
import SupportForm from '../pages/SupportForm';

/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */
const Routes = withRouter(({
  intl,
  history,
  role_id,
  permission_list,
  numberOfLogins,
  showTutorialVideo,
  setNumberOfLogins,
  setShowTutorialVideo,
  advocateAdmin,
  municipalities
}) => {
  const lastLocation = useLastLocation();
  const location = useLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthenticated, menuConfig, userLastLocation } = useSelector(
    ({ auth, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthenticated: auth.isAuthenticated,
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  );

  const onSubmit = () => {
    setNumberOfLogins(-1);
    window.open(
      'https://vimeo.com/452273039/3d123230a8',
      '_blank'
    );
  };

  const onCancel = () => {
    setShowTutorialVideo(false);
    setNumberOfLogins(numberOfLogins + 1);
  };

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_title: location.pathname,
      page_location: location.pathname,
      page_path: location.pathname,
      send_to: 'G-VVT6YQ1T9L'
    });
  }, [location]);

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        <Route path="/auth/login-as" component={LoginAs} />
        <Route path="/error" component={ErrorPage} />
        <Route path="/logout" component={Logout} />
        {!isAuthenticated ? (
          <AuthPage />
        ) : (
          <Layout>
            {/* <Dialog
              forceOpen={showTutorialVideo && numberOfLogins !== -1 && numberOfLogins <= 3}
              title={intl.formatMessage({ id: 'GENERAL.TUTORIAL.TITLE' })}
              content={intl.formatMessage({ id: 'GENERAL.TUTORIAL.CONTENT' })}
              submitText={intl.formatMessage({ id: 'GENERAL.TUTORIAL.WATCH_NOW' })}
              cancelText={intl.formatMessage({ id: 'GENERAL.TUTORIAL.WATCH_LATER' })}
              handleSubmit={onSubmit}
              handleCancel={onCancel}
            /> */}
            <Switch>
              <Redirect from="/auth" to={userLastLocation} />
              <Redirect exact from="/" to="/home-page" />
              <Redirect exact from="/dashboard" to="/home-page" />
              {/* dashboard renamed to home\-page */}
              <Route path="/home-page" component={Dashboard} />
              <Route path="/profile" component={Profile} />
              {
                permission_list.find((item) => item.group_id === 'schedules')?.permission === 't'
                  ? <Route path="/schedules" component={Schedule} />
                  : null
              }
              {
                permission_list.find((item) => item.group_id === 'schedules')?.permission === 't'
                  ? <Route path="/perpetual-schedules/" component={PerpetualSchedules} />
                  : null
              }
              <Route path="/education/blog-details/:blogId" component={BlogDetails} />
              {
                permission_list.find((item) => item.group_id === 'education')?.permission === 't'
                  ? <Route path="/education" component={Education} />
                  : null
              }
              <Route path="/request-support" component={SupportForm} />
              {
                !advocateAdmin
                && (
                  <Route
                    path="/campaigns"
                    component={Campaign}
                  />
                )
              }

              {
                permission_list.find((item) => item.group_id === 'notification')?.permission === 't'
                  && municipalities.some((item) => item.stage !== '2')
                  ? <Route path="/notification" component={Notification} />
                  : null
              }
              {
                permission_list.find((item) => item.group_id === 'reminder')?.permission === 't'
                  ? advocateAdmin !== true && <Route path="/reminder" component={Reminder} />
                  : null
              }
              {
                permission_list.find((item) => item.group_id === 'report')?.permission === 't'
                  ? <Route path="/report" component={ReportRoutes} />
                  : null
              }
              {
                role_id !== '3'
                  && municipalities.some((item) => item.stage !== '2')
                  ? <Route path="/users" component={Users} />
                  : null
              }
              {
                permission_list.find((item) => item.group_id === 'cms')?.permission === 't'
                  && municipalities.some((item) => item.stage !== '2')
                  ? (
                    <>
                      <Route path="/what-goes-where" component={WGWHome} />
                      <Route path="/category" component={Category} />
                      <Route path="/depot" component={Depot} />
                      <Route path="/event" component={Event} />
                      <Route path="/waste-type" component={WasteTypes} />
                      <Route path="/collection-requirement" component={CollectionRequirement} />
                      <Route path="/waste-recycling-rules" component={WasteAndRecyclingRules} />
                    </>
                  ) : null
              }

              <Route path="/profile" component={Profile} />

              <Redirect to="/home-page" />
            </Switch>
          </Layout>
        )}
      </Switch>
    </LayoutContextProvider>
  );
});

const mapStateToProps = (store) => ({
  role_id: store.auth.user_info.role_id,
  permission_list: store.auth.user_info.permission_list,
  numberOfLogins: store.common.numberOfLogins,
  showTutorialVideo: store.common.showTutorialVideo,
  advocateAdmin: store.auth.advocateAdmin,
  municipalities: store.common.municipalities || []
});

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(Routes)
);
