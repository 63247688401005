import actionTypes from './actionTypes';

// User report actions for setting loading status
export const setUserReportLoadingAction = (status) => ({
  type: actionTypes.SET_USER_REPORT_LOADING_ACTION,
  payload: status
});

// User report actions for fetching report data
export const userReportRequest = ({
  county, municipality, from, to
}) => ({
  type: actionTypes.GET_USER_REPORT_REQUEST,
  payload: {
    county, municipality, from, to
  }
});

export const userReportReceive = (data) => ({
  type: actionTypes.GET_USER_REPORT_RECEIVE,
  payload: { data }
});

export const userReportFail = (error) => ({
  type: actionTypes.GET_USER_REPORT_FAIL,
  payload: { ...error }
});
