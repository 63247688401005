/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';

import {
  Typography,
  TextField,
  CheckBox,
  CustomButton
} from '../../components';
import SetupWizardLocationDetailsLocationHours from './SetupWizardLocationDetailsLocationHours';
import { validation } from '../../../utils';
import * as actions from '../redux/actions';

const useStyles = makeStyles(() => ({
  dropdown: {
    '& button': {
      padding: 0,
    },
  },
  dropdownIcon: {
    width: '100%',
    '&::after': {
      display: 'none !important',
    },
  },
  dropdownMenu: {
    width: '100%',
    borderRadius: 0,
    maxHeight: 300,
    overflow: 'auto',
    '& span': {
      justifyContent: 'flex-start',
    },
  },
  dropdownItem: {
    padding: '5px 15px !important',
  },
}));

const SetupWizardLocationDetailsLocation = ({
  intl,
  addressSuggestions,
  openLocationId,
  locationDetails,
  locations,
  handleLocationsChange,
  getAddressSuggestionsRequest,
}) => {
  const classes = useStyles();
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleChange = (property, value) => {
    handleLocationsChange(
      locations.map((item, index) => {
        if (openLocationId === index) {
          if (property === 'geo_loc') {
            return {
              ...item,
              geo_loc: `(${value.geometry.location.lat},${value.geometry.location.lng})`,
              display_addr: value.formatted_address,
            };
          }
          return { ...item, [property]: value };
        }
        return item;
      })
    );
  };

  const handleChangeDate = (property, value, day) => {
    handleLocationsChange(
      locations.map((location, index) => {
        if (openLocationId === index) {
          return {
            ...location,
            drop_event_loc_hours: location.drop_event_loc_hours.map((time) => {
              if (Number(time.dow_nbr) === day) {
                return {
                  ...time,
                  [property]: value ? moment(value).format('HH:mm') : null,
                };
              }
              return time;
            }),
          };
        }
        return location;
      })
    );
  };

  const handleLocationSearch = (value) => {
    handleChange('display_addr', value);
    getAddressSuggestionsRequest(value);
  };

  return (
    <div className="w-100">
      <Typography className="mt-4" variant="bodySemiBold">
        {intl.formatMessage({ id: 'EVENT.SETUP_WIZARD.LOCATION_DETAILS.NAME' })}
      </Typography>
      <TextField
        className="mt-3"
        onChange={(value) => handleChange('event_loc_admin_nm', value)}
        value={locationDetails.event_loc_admin_nm || ''}
      />

      <Typography className="mt-4" variant="bodySemiBold">
        {intl.formatMessage({
          id: 'EVENT.SETUP_WIZARD.LOCATION_DETAILS.ADDRESS',
        })}
      </Typography>
      <Dropdown className={classes.dropdown} drop="down" show={isSearchOpen}>
        <Dropdown.Toggle variant="transparent" className={classes.dropdownIcon}>
          <TextField
            className="mt-3"
            value={locationDetails.display_addr || ''}
            onChange={handleLocationSearch}
            onFocus={() => setIsSearchOpen(true)}
            onBlur={() => setTimeout(() => setIsSearchOpen(false), 300)}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu
          className={`dropdown-menu-fit dropdown-menu-right ${classes.dropdownMenu}`}
        >
          {addressSuggestions.map((item) => (
            <CustomButton
              key={item.formatted_address}
              className={classes.dropdownItem}
              onClick={() => {
                handleChange('geo_loc', item);
              }}
              label={(
                <Typography variant="bodySemiBold">
                  {item.formatted_address}
                </Typography>
              )}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>

      {/* <div className="mt-3">
        <CheckBox
          className="mr-2"
          checked={locationDetails.isManual || false}
          label={(
            <Typography variant="bodySemiBold">
              {intl.formatMessage({ id: 'EVENT.SETUP_WIZARD.LOCATION_DETAILS.MANUAL' })}
            </Typography>
          )}
          onChange={(value) => handleChange('isManual', value)}
        />
      </div> */}

      <div className="w-100 d-flex flex-row mt-5 mb-5 justify-content-between">
        {locationDetails.daysOfTheWeek
          .map((time, index) => (
            <CheckBox
              key={`event_setup_wizard_checkbox_${index}`}
              label={(
                <Typography variant="bodySemiBold">
                  {time.name}
                </Typography>
              )}
              checked={time.value || false}
              onChange={() => {
                const temp = [...locationDetails.daysOfTheWeek];
                temp[index] = { ...temp[index], value: !temp[index].value };
                handleChange('daysOfTheWeek', temp);
              }}
            />
          ))}

      </div>

      { locationDetails.daysOfTheWeek.find((item) => item.value)
      && (
      <div className="row mt-5">
        <div className="col-6">
          <Typography variant="bodySemiBold">
            {intl.formatMessage({
              id: 'DEPOT.SETUP_WIZARD.HOURS.OPERATIONAL_HOURS',
            })}
          </Typography>
        </div>
        <div className="col-6">
          <Typography variant="bodySemiBold">
            {intl.formatMessage({
              id: 'DEPOT.SETUP_WIZARD.HOURS.ADDITIONAL_HOURS',
            })}
          </Typography>
        </div>
      </div>
      )}

      <div className="mb-5">
        {locationDetails.daysOfTheWeek.map((time, index) => {
          if (time.value) {
            return (
              <SetupWizardLocationDetailsLocationHours
                key={`days_of-week_times_${index}_${Math.random()}`}
                title={time.name}
                data={
                  locationDetails.drop_event_loc_hours
                    ? locationDetails.drop_event_loc_hours.find(
                      (hour) => Number(hour.dow_nbr) === index
                    )
                    : null
                }
                onChangeFirst={(value) => handleChangeDate('hours_lower_tm', value, index)}
                onChangeSecond={(value) => handleChangeDate('hours_upper_tm', value, index)}
                onChangeAdditionalFirst={(value) => handleChangeDate('add_hours_lower_tm', value, index)}
                onChangeAdditionalSecond={(value) => handleChangeDate('add_hours_upper_tm', value, index)}
              />
            );
          }
          return null;
        })}
      </div>

      <TextField
        label={intl.formatMessage({ id: 'DEPOT.CONTACT.TELEPHONE' })}
        className="mb-4"
        onChange={(value) => handleChange('phone_nbr', value)}
        value={locationDetails.phone_nbr || ''}
        invalidTextBackgroundColor={process.env.REACT_APP_COLOR_PRIMARY_BG}
        invalidText={
          !validation(locationDetails.phone_nbr, 'phone')
            ? intl.formatMessage({ id: 'USERS.FORM.INPUT.PHONE.INVALID' })
            : null
        }
      />

      <TextField
        label={intl.formatMessage({ id: 'DEPOT.CONTACT.ALT_TELEPHONE' })}
        className="mb-4"
        onChange={(value) => handleChange('alt_phone_nbr', value)}
        value={locationDetails.alt_phone_nbr || ''}
        invalidTextBackgroundColor={process.env.REACT_APP_COLOR_PRIMARY_BG}
        invalidText={
          !validation(locationDetails.alt_phone_nbr, 'phone')
            ? intl.formatMessage({ id: 'USERS.FORM.INPUT.PHONE.INVALID' })
            : null
        }
      />

      <TextField
        label={intl.formatMessage({ id: 'DEPOT.CONTACT.EMAIL' })}
        className="mb-4"
        onChange={(value) => handleChange('email_addr', value)}
        value={locationDetails.email_addr || ''}
        invalidTextBackgroundColor={process.env.REACT_APP_COLOR_PRIMARY_BG}
        invalidText={
          !validation(locationDetails.email_addr, 'email')
            ? intl.formatMessage({ id: 'USERS.FORM.INPUT.EMAIL.INVALID' })
            : null
        }
      />

      <TextField
        label={intl.formatMessage({ id: 'DEPOT.CONTACT.WEBSITE' })}
        className="mb-4"
        onChange={(value) => handleChange('web_addr', value)}
        value={locationDetails.web_addr || ''}
        invalidTextBackgroundColor={process.env.REACT_APP_COLOR_PRIMARY_BG}
        invalidText={
          !validation(locationDetails.web_addr, 'web')
            ? intl.formatMessage({
              id: 'DEPOT.SETUP_WIZARD.CONTACT_DETAIL.WEBSITE.INVALID_TEXT',
            })
            : null
        }
      />
    </div>
  );
};

const mapStateToProps = (store) => ({
  addressSuggestions: store.event.addressSuggestions,
});

export default injectIntl(
  connect(mapStateToProps, actions)(SetupWizardLocationDetailsLocation)
);
