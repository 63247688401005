import React, { useEffect, useState } from 'react';
import {
  Document, Page, Text, View, StyleSheet, Image
} from '@react-pdf/renderer';
import { toAbsoluteUrl } from '../../../../../_metronic';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    paddingVertical: 5
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  summaryHead: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 10,
    marginBottom: 0,
    padding: 10,
  },
  headerText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'black',
  },
  subTitleText: {
    fontSize: 12,
    color: 'black'
  },
  marginLeft: {
    marginLeft: 15
  },
  h1: {
    fontSize: '22pt'
  },
  h2: {
    fontSize: '20pt'
  },
  h3: {
    fontSize: '18pt'
  },
  h4: {
    fontSize: '18pt'
  },
  h5: {
    fontSize: '16pt'
  },
  h6: {
    fontSize: '14pt'
  },
  body: {
    fontSize: '12pt'
  },
  circle: {
    width: '10pt',
    height: '10pt',
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY,
    borderRadius: '100pt',
    marginRight: '10pt',
  },
  image: {
    width: '110px',
    height: '40px',
    marginLeft: '13px',
    marginTop: '5px',
  }
});

// Create Document Component
const ReportPDF = ({
  district,
  usersAtAGlance,
  currentPeriod,
  date,
  reportingDistrict,
  currentPeriodData,
  currentPeriodType,
  previousPeriodData,
  rating,
  peerRating,
  detailData,
  peerDetail
}) => {
  const [color, setColor] = useState();
  const whatIsColor = () => {
    if (currentPeriodType === 'positive') {
      setColor(process.env.REACT_APP_COLOR_POSITIVE);
    }
    if (currentPeriodType === 'negative') {
      setColor(process.env.REACT_APP_COLOR_NEGATIVE);
    }
    if (currentPeriodType === 'neutral') {
      setColor(process.env.REACT_APP_COLOR_NEUTRAL);
    }
  };
  useEffect(() => {
    whatIsColor();
  }, [currentPeriodType]);

  return (
    <Document>
      {/* summary 's PDF */}
      <Page size="A4" orientation="landscape" style={styles.page}>
        <>
          <Image
            style={styles.image}
            src={`${toAbsoluteUrl('/media/logos/RCLogo.png')}`}
          />
          <View style={styles.summaryHead}>
            <Text style={styles.headerText}>
              {usersAtAGlance}
            </Text>

            <Text style={[styles.subTitleText, styles.marginLeft,
              {
                color: process.env.REACT_APP_COLOR_DARK_GRAY,
              }
            ]}
            >
              {currentPeriod}
            </Text>

            <Text style={[styles.subTitleText, styles.marginLeft,
              { color: process.env.REACT_APP_COLOR_PRIMARY }]}
            >
              {date}
            </Text>

            <Text style={[styles.subTitleText, {
              marginLeft: 150
            }]}
            >
              {reportingDistrict}
            </Text>

          </View>

          <View
            style={{
              width: '100%',
              minHeight: 100,
              padding: 10,
              marginTop: 10,
            }}
          >
            {detailData && detailData.length > 0
            && detailData.map((item, index) => (
              <View
                key={String(index)}
                style={{
                  width: '100%',
                  padding: 10,
                  marginTop: 5,
                  paddingBottom: 10,
                  flexDirection: 'column',
                  borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_GRAY_DARK}`,
                  backgroundColor: index % 2 ? '#ffffff' : process.env.REACT_APP_COLOR_BACKGROUND_FILL
                }}
              >
                <Text style={styles.h5}>
                  Name :
                  {' '}
                  {item.name}
                </Text>
                <View
                  style={{
                    width: '100%',
                    marginTop: 5,
                    flexDirection: 'column'
                  }}
                >
                  <Text style={[styles.h6, { marginTop: 5 }]}>
                    Current Period :
                    {' '}
                    {item.start_date}
                    {' '}
                    {'-'}
                    {' '}
                    {item.start_date}
                  </Text>

                  <Text style={[styles.h6, { marginTop: 5 }]}>
                    Invited Users :
                    {' '}
                    {item.invited_users}
                  </Text>

                  <Text style={[styles.h6, { marginTop: 5 }]}>
                    Participation Rate :
                    {' '}
                    {item.participation_rate}
                  </Text>

                </View>
                {item.activities
                  && item.activities.map((piece, id) => (
                    <View
                      key={String(id)}
                      style={{
                        width: '100%',
                        marginTop: 5,
                        paddingBottom: 10,
                        flexDirection: 'column',
                        borderTop: index % 2 ? `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}` : '2pt solid #ffffff',
                      }}
                    >
                      <Text style={[styles.h6, { marginTop: 5 }]}>
                        Type :
                        {' '}
                        {piece.type}
                      </Text>
                      <Text style={[styles.h6, { marginTop: 5 }]}>
                        Users entered this activity :
                        {' '}
                        {piece.open}
                      </Text>
                      <Text style={[styles.h6, { marginTop: 5 }]}>
                        Users participated in this activity :
                        {' '}
                        {piece.participation}
                      </Text>
                    </View>
                  ))}

              </View>
            ))}
          </View>

        </>
      </Page>
    </Document>
  );
};
export default ReportPDF;
