import React from 'react';
import {
  Switch, Route, useRouteMatch, Redirect
} from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import CampaignTabs from './tabs/CampaignTabs';
import CampaignSwitch from './tabs/CampaignSwitch';
import SelectTemplate from './tabs/SelectTemplate';
import CreateCampaign from './tabs/CreateCampaign';

/**
 * Redux stuff
 */
import * as actions from './redux/actions';
import * as sharedActions from '../redux/actions';

const Campaign = ({
  district
}) => {
  const { path: routePath } = useRouteMatch();
  return (
    <>
      <div className="col-12 col-xl-12">
        <div className="mr-2 h-100 position-relative">
          <Switch>
            <Route
              path={`${routePath}/tab/:tab?`}
              render={() => (
                <CampaignTabs />
              )}
            />
            <Route
              path={`${routePath}/switch/:firstitem/:seconditem?`}
              exact
              render={() => (
                <CampaignSwitch
                  loading={false}
                  onClick={() => {}}
                  districtId={district}
                />
              )}
            />
            <Route
              path={`${routePath}/select_template`}
              exact
              render={() => (
                <SelectTemplate />
              )}
            />
            <Route
              path={`${routePath}/create_campaign`}
              exact
              render={() => (
                <CreateCampaign />
              )}
            />
            <Redirect
              from={routePath}
              exact
              to={`${routePath}/tab`}
            />
          </Switch>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store) => ({
  tab: store.campaign.tab,
  districts: store.common.districts,
  district: store.common.district
});

export default injectIntl(
  connect(
    mapStateToProps,
    { ...actions, ...sharedActions }
  )(Campaign)
);
