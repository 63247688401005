import { put, call, takeLatest } from 'redux-saga/effects';
import actionTypes from './actionTypes';
import * as actions from './actions';
import { getDashboardReportV2, getDashboardReportDetail, getMaterialDetails, getMajorCampaignDetails, getBulkPickupDetails, getWGWMaterialDetails, getBulkPickupDashboard } from '../apis';

function* getDashboardReportnDataSaga({ payload: { municipality, month, year, county, isCounty } }) {

  const response = yield call(getDashboardReportV2, { municipality, month, year, county, isCounty });
  try {
    yield put(actions.getReportDashboardReceive(response.data));
  } catch (error) {
    yield put(actions.getReportDashboardFail(response.error.detail.data));
  }
}


function* getDashboardReportnDetailsSaga({ payload: { municipality, month, year, last_month = 1, county, isCounty } }) {
  const response = yield call(getDashboardReportDetail, { municipality, month, year, last_month, county, isCounty });

  try {
    yield put(actions.getReportDashboardDetailsReceive({educationDetails: response.data }));
  } catch (error) {
    yield put(actions.getReportDashboardDetailsFail(response.error.detail.data));
  }
}

function* getMaterialnDetailsSaga({ payload: { municipality, month, year, last_month = 1, county, isCounty } }) {

  const response = yield call(getMaterialDetails, { municipality, month, year, last_month, county, isCounty });

  try {
    yield put(actions.getMaterialDetailsReceive({majorDetails: response.data }));
  } catch (error) {
    yield put(actions.getMaterialDetailsFail(response.error.detail.data));
  }
}

function* getMajorCampaignDetailsSaga({ payload: { municipality, month, year, major_campaign_id, county, isCounty } }) {

  const response = yield call(getMajorCampaignDetails, { municipality, month, year, major_campaign_id, county, isCounty });


  try {
    yield put(actions.getMajorCampaignDetailsReceive({campaign: response.data}));
  } catch (error) {
    yield put(actions.getMajorCampaignDetailsFail(response.error.detail.data));
  }
}

function* getBulkPickupDetailsSaga({ payload: { municipality, month, year, last_month = 1, county, isCounty, last_name, address, start_date, end_date } }) {

  const response = yield call(getBulkPickupDetails, { municipality, month, year, last_month, county, isCounty, last_name, address, start_date, end_date });

  try {
    yield put(actions.getBulkPickupDetailsReceive({bulkPickUpDetails: response.data }));
  } catch (error) {
    yield put(actions.getBulkPickupDetailsFail(response.error.detail.data));
  }
}

function* getBulkPickupDashboardSaga({ payload: { municipality, month, year, last_month = 1, county, isCounty, last_name, address, start_date, end_date } }) {

  const response = yield call(getBulkPickupDashboard, { municipality, month, year, last_month, county, isCounty, last_name, address, start_date, end_date });

  try {
    yield put(actions.getBulkPickupDashboardReceive({bulkPickUpDashboard: response.data }));
  } catch (error) {
    yield put(actions.getBulkPickupDashboardFail(response.error.detail.data));
  }
}


function* getWGWMaterialDetailsSaga({ payload: { month, year, last_month = 1, last_name, address, start_date, end_date, locality } }) {

  const response = yield call(getWGWMaterialDetails, { month, year, last_month, last_name, address, start_date, end_date, locality });

  try {
    yield put(actions.getWGWMaterialDetailsReceive({wgwMaterialDetails: response.data }));
  } catch (error) {
    yield put(actions.getWGWMaterialDetailsFail(response.error.detail.data));
  }
}

function* ReportDashboardSaga() {
  yield takeLatest(actionTypes.GET_REPORT_DASHBOARD_REQUEST, getDashboardReportnDataSaga);
  yield takeLatest(actionTypes.GET_REPORT_DASHBOARD_DETAILS_REQUEST, getDashboardReportnDetailsSaga);
  yield takeLatest(actionTypes.GET_MATERIAL_DETAILS_REQUEST, getMaterialnDetailsSaga);
  yield takeLatest(actionTypes.GET_MAJOR_CAMPAIGN_DETAILS_REQUEST, getMajorCampaignDetailsSaga);
  yield takeLatest(actionTypes.GET_BULK_PICKUP_DETAILS_REQUEST, getBulkPickupDetailsSaga);
  yield takeLatest(actionTypes.GET_WGW_MATERIAL_DETAILS_REQUEST, getWGWMaterialDetailsSaga);
  yield takeLatest(actionTypes.GET_BULK_PICKUP_DASHBOARD_REQUEST, getBulkPickupDashboardSaga);




}

export default ReportDashboardSaga;
