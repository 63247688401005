import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { Snackbar } from '../components';
import { Header, Collections } from './components';

const useStyles = makeStyles((theme) => ({
  content: {
    width: '85%',
    margin: 'auto',
    [theme.breakpoints.down(1350)]: {
      width: '95%'
    },
    [theme.breakpoints.down(900)]: {
      width: '100%'
    },
  }
}));

const CollectionRequirement = ({
  responseMessage,
  responseStatus,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <Snackbar
        message={responseMessage}
        status={responseStatus}
      />
      <Header />
      <Collections />
    </div>
  );
};

const mapStateToProps = (store) => ({
  responseMessage: store.collection.responseMessage,
  responseStatus: store.collection.responseStatus,
});

export default connect(mapStateToProps, null)(CollectionRequirement);
