import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import * as actions from '../redux/actions';
import * as sharedActions from '../../../redux/actions';

import { Portlet } from '../../../../../_metronic/partials/content/Portlet';
import { MunicipalitySelector, Typography } from '../../../components';
import { DetailRowLoading } from '../../../components/loading';
import { DoughnutChart, CurrentPeriodDatePicker } from '../../components';
import { addCommasToNumber, validation } from '../../../../utils';
import UserDetailRow from './UserDetailRow';

const useStyles = makeStyles({
  header: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
});

const Users = ({
  intl,
  data,
  fromDate,
  toDate,
  setDateRangeAction,
  isLoading,
  advocateAdmin = false,
  setDistrictAction,
  municipalities,
  district,
  hasCounty,
  counties,
  county,
  isCounty,
  setCountyAction
}) => {
  const classes = useStyles();

  const {
    calendars,
    communications,
    web_app,
    mobile,
    total = calendars + communications + web_app + mobile
  } = data;

  if (!Object.keys(data)) {
    return null;
  }

  return (
    <Portlet>
      <div className={`d-flex justify-content-between align-items-center px-4 ${classes.header}`}>
        <div className="d-flex">
          <Typography className="mr-4" variant="h1">
            {intl.formatMessage({ id: 'REPORT.USERS.DETAIL.SWITCH_USER_REPORT' })}
          </Typography>
          <CurrentPeriodDatePicker
            fromDate={fromDate}
            toDate={toDate}
            handleDateRangeChange={setDateRangeAction}
          />
        </div>
        <div>
          {
            municipalities
              && municipalities.length
              ? (
                <div style={{ width: 300, marginTop: 15, marginBottom: 10 }}>
                  <MunicipalitySelector
                    disbleSingleCountyDropdown
                    isCountyEnable
                    title={
                      (hasCounty !== null && isCounty && counties)
                        ? 'County'
                        : intl.formatMessage({ id: 'REPORT.GENERAL.REPORTING_DISTRICT' })
                    }
                    onChange={
                      (hasCounty !== null && isCounty)
                        ? setCountyAction
                        : setDistrictAction
                    }
                    value={(hasCounty !== null && isCounty) ? county : district}
                    invalidText={
                      !validation(district || '', 'required')
                        ? intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
                        : null
                    }
                  />
                </div>
              ) : null
          }
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-6">
          <div style={{ padding: '20px 0', marginTop: 25 }}>
            <DoughnutChart
              isLoading={isLoading}
              total={addCommasToNumber(total)}
              insideText={intl.formatMessage({ id: 'REPORT.USERS_TOTAL_USERS' })}
              labels={[
                intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.MOBILE.TITLE' }),
                intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.WEB_APP.TITLE' }),
                intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.COMMUNICATION.TITLE' }),
                intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.CALENDAR.TITLE' }),
              ]}
              values={data && advocateAdmin !== true ? [
                mobile,
                web_app,
                communications,
                calendars
              ] : [
                mobile,
                web_app,
              ]}
              colors={['#3e95cd', '#2e75cf', '#3cbacd', '#2a95ff']}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-xl-6">
          <div style={{ padding: '20px 0' }}>
            {isLoading ? (
              <div
                className="d-flex flex-column align-items-center justify-content-between p-3"
              >
                <DetailRowLoading
                  height={90}
                  width="100%"
                />
              </div>
            )
              : (
                <>
                  <UserDetailRow
                    color="#3e95cd"
                    title="REPORT.USERS.DETAIL.CATEGORIES.MOBILE.TITLE"
                    description="REPORT.USERS.DETAIL.CATEGORIES.MOBILE.DESCRIPTION"
                    total={mobile}
                  />
                  <UserDetailRow
                    color="#2e75cf"
                    title="REPORT.USERS.DETAIL.CATEGORIES.WEB_APP.TITLE"
                    description="REPORT.USERS.DETAIL.CATEGORIES.WEB_APP.DESCRIPTION"
                    total={web_app}
                  />
                  {advocateAdmin !== true
                    && (
                      <UserDetailRow
                        color="#3cbacd"
                        title="REPORT.USERS.DETAIL.CATEGORIES.COMMUNICATION.TITLE"
                        description="REPORT.USERS.DETAIL.CATEGORIES.COMMUNICATION.DESCRIPTION"
                        total={communications}
                      />
                    )}
                  {advocateAdmin !== true
                    && (
                      <UserDetailRow
                        color="#2a95ff"
                        title="REPORT.USERS.DETAIL.CATEGORIES.CALENDAR.TITLE"
                        description="REPORT.USERS.DETAIL.CATEGORIES.CALENDAR.DESCRIPTION"
                        total={calendars}
                        borderNone
                      />
                    )}
                </>
              )}
          </div>
        </div>
      </div>
      {/* <div style={{ padding: '15px 0 25px', textAlign: 'center' }}>
        <Link
          to="/report/user"
          style={{ fontSize: 12, fontWeight: 'normal' }}
        >
          {intl.formatMessage({ id: 'GENERAL.VIEW_FULL_REPORT' })}
        </Link>
      </div> */}
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  municipalities: store.common.municipalities,
  district: store.common.district,
  username: `${store.auth.user_info.first_name} ${store.auth.user_info.last_name}`,
  hasCounty: store.auth.user_info.counties,
  counties: store.common.counties,
  county: store.common.county,
  isCounty: store.common.isCounty,
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
});

export default injectIntl(connect(
  mapStateToProps,
  { ...sharedActions, ...actions }
)(Users));
