const actionTypes = {
  // ActionTypes for setting loading status
  SET_LOADING_ACTION: 'SET_LOADING_ACTION',

  // ActionTypes for setting district
  SET_DISTRICT_ACTION: 'SET_DISTRICT_ACTION',

  // ActionTypes for setting county
  SET_COUNTY_ACTION: 'SET_COUNTY_ACTION',

  // ActionTypes for setting isCounty
  SET_IS_COUNTY_ACTION: 'SET_IS_COUNTY_ACTION',

  // ActionTypes for setting from and to date
  SET_DATE_RANGE_ACTION: 'SET_DATE_RANGE_ACTION',

  // ActionTypes for setting the number of times that user logged in
  SET_NUMBER_OF_LOGINS: 'SET_NUMBER_OF_LOGINS',

  // ActionTypes for setting the flag that indicates whether tutorial pop-up should be displayed
  SET_SHOW_TUTORIAL_VIDEO: 'SET_SHOW_TUTORIAL_VIDEO',

  // ActionTypes for fetching municipality list
  GET_MUNICIPALITY_LIST_REQUEST: 'GET_MUNICIPALITY_LIST_REQUEST',
  GET_MUNICIPALITY_LIST_RECEIVE: 'GET_MUNICIPALITY_LIST_RECEIVE',
  GET_MUNICIPALITY_LIST_FAIL: 'GET_MUNICIPALITY_LIST_FAIL',

  // County actionTypes
  GET_COUNTY_LIST_REQUEST: 'GET_COUNTY_LIST_REQUEST',
  GET_COUNTY_LIST_RECEIVE: 'GET_COUNTY_LIST_RECEIVE',
  GET_COUNTY_LIST_FAIL: 'GET_COUNTY_LIST_FAIL',

  // ActionTypes for fetching households of the given district
  GET_MUNICIPALITY_HOUSEHOLDS_REQUEST: 'GET_MUNICIPALITY_HOUSEHOLDS_REQUEST',
  GET_MUNICIPALITY_HOUSEHOLDS_RECEIVE: 'GET_MUNICIPALITY_HOUSEHOLDS_RECEIVE',
  GET_MUNICIPALITY_HOUSEHOLDS_FAIL: 'GET_MUNICIPALITY_HOUSEHOLDS_FAIL',

  // ActionTypes for updating district households
  SET_MUNICIPALITY_HOUSEHOLDS_REQUEST: 'SET_MUNICIPALITY_HOUSEHOLDS_REQUEST',
  SET_MUNICIPALITY_HOUSEHOLDS_RECEIVE: 'SET_MUNICIPALITY_HOUSEHOLDS_RECEIVE',
  SET_MUNICIPALITY_HOUSEHOLDS_FAIL: 'SET_MUNICIPALITY_HOUSEHOLDS_FAIL',

  // ActionTypes for fetching locality
  GET_LOCALITIES_REQUEST: 'GET_LOCALITIES_REQUEST',
  GET_LOCALITIES_RECEIVE: 'GET_LOCALITIES_RECEIVE',
  GET_LOCALITIES_FAIL: 'GET_LOCALITIES_FAIL',

  // actionTypes for setting a locality
  SET_LOCALITY_ACTION: 'SET_LOCALITY_ACTION',

  GET_DISTRICT_PRO_PACKAGES_REQUEST: 'GET_DISTRICT_PRO_PACKAGES_REQUEST',
  GET_DISTRICT_PRO_PACKAGES_RECEIVE: 'GET_DISTRICT_PRO_PACKAGES_RECEIVE',
  GET_DISTRICT_PRO_PACKAGES_FAIL: 'GET_DISTRICT_PRO_PACKAGES_FAIL',
};

export default actionTypes;
