/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { Portlet, PortletBody, PortletHeader } from '../../../../_metronic/partials/content/Portlet';
import { EditIcon, CollapseIcon, ExpandIcon } from '../../../assets/icons';
import { removeZeroFromTime, transformToAmpm, formatAddress } from '../../../utils';
import {
  Typography, IconButton, CustomButton, InfoTooltip
} from '../../components';
import EventDrawerLinks from './EventDrawerLinks';
import EventAssignedCategories from './EventAssignedCategories';
import EventMenu from './EventMenu';
import * as actions from '../redux/actions';

const maxDatesHeight = 55;
const maxDescriptionHeight = 55;
const maxHoursHeight = 150;

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('800')]: {
      marginRight: '0px !important'
    },
  },
  eventMore: {
    maxHeight: 9999,
  },
  datesLess: {
    maxHeight: maxDatesHeight,
    overflow: 'hidden'
  },
  descriptionLess: {
    maxHeight: maxDescriptionHeight,
    overflow: 'hidden'
  },
  hoursLess: {
    maxHeight: maxHoursHeight,
    overflow: 'hidden',
  },
  eventDetails: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    paddingBottom: 20,
    marginBottom: 20,
  },
  locations: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    paddingBottom: 10,
  },
  assignedCategories: {
    borderBottom: 'none !important',
  },
}));

const Event = ({
  intl,
  data,
  setWizardStageAction,
  setEventWizardIdAction,
}) => {
  const classes = useStyles();
  const [showDatesMore, setShowDatesMore] = useState(false);
  const [isDatesMore, setIsDatesMore] = useState(false);
  const [showDescriptionMore, setShowDescriptionMore] = useState(false);
  const [isDescriptionMore, setIsDescriptionMore] = useState(false);
  const [showHoursMore, setShowHoursMore] = useState(false);
  const [isHoursMore, setIsHoursMore] = useState(false);
  const [drawerLinkIsOpen, setDrawerLinkIsOpen] = useState(false);
  const [assignedCategoriesIsOpen, setAssignedCategories] = useState(false);
  const daysOfTheWeek = [
    intl.formatMessage({ id: 'GENERAL.SUN' }),
    intl.formatMessage({ id: 'GENERAL.MON' }),
    intl.formatMessage({ id: 'GENERAL.TUE' }),
    intl.formatMessage({ id: 'GENERAL.WED' }),
    intl.formatMessage({ id: 'GENERAL.THU' }),
    intl.formatMessage({ id: 'GENERAL.FRI' }),
    intl.formatMessage({ id: 'GENERAL.SAT' }),
  ];

  useEffect(() => {
    setIsDatesMore(document.getElementById(`dates_${data.drop_event_id}`).clientHeight >= maxDatesHeight);
    setIsDescriptionMore(document.getElementById(`description_${data.drop_event_id}`).clientHeight >= maxDescriptionHeight);
    setIsHoursMore(document.getElementById(`hours_${data.drop_event_id}`).clientHeight >= maxHoursHeight);
  }, [data.event_dates, data.event_details, data.drop_event_loc_ids, data.drop_event_id]);

  const handleClick = (stage) => {
    window.scrollTo(0, 0);
    setWizardStageAction(stage);
    setEventWizardIdAction(data.drop_event_id);
  };

  return (
    <Portlet className={`mr-4 ${classes.container}`}>
      <PortletHeader
        toolbar={(
          <div className="w-100 d-flex justify-content-between align-items-center">
            <Typography variant="h1">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.event_admin_nm
                }}
              />
            </Typography>
            <EventMenu
              event={data}
            />
          </div>
        )}
      />
      <PortletBody className="pt-0">
        <div className={classes.eventDetails}>
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between pt-4">
              <Typography variant="h2Medium">
                {intl.formatMessage({ id: 'EVENT.EVENTS.EVENT_NAME' })}
              </Typography>
              <IconButton
                tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
                onClick={() => handleClick(1)}
              >
                <EditIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
              </IconButton>
            </div>
          </div>
          <div className="mt-3">
            <Typography variant="body">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.event_nm
                }}
              />
            </Typography>
          </div>

          <div className="d-flex justify-content-between mt-4">
            <Typography variant="h2Medium">
              {intl.formatMessage({ id: 'EVENT.EVENTS.EVENT_DATE' })}
            </Typography>
            <IconButton
              tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
              onClick={() => handleClick(1)}
            >
              <EditIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
            </IconButton>
          </div>
          <div
            id={`dates_${data.drop_event_id}`}
            className={`mt-3 ${showDatesMore ? classes.eventMore : classes.datesLess}`}
          >
            {
              data.event_dates && data.event_dates.map((date, index) => (
                <Typography key={`date_events_${index}_${Math.random()}`} variant="body">
                  {moment(date.date).format('MMMM DD, YYYY')}
                </Typography>
              ))
            }
          </div>
          {
            isDatesMore && (
              <div className="d-flex justify-content-center align-items-center">
                <CustomButton
                  label={(
                    <Typography variant="bodySemiBold" color={process.env.REACT_APP_COLOR_PRIMARY}>
                      {showDatesMore ? intl.formatMessage({ id: 'GENERAL.VIEW.LESS' }) : intl.formatMessage({ id: 'GENERAL.VIEW.MORE' })}
                    </Typography>
                  )}
                  onClick={() => setShowDatesMore(!showDatesMore)}
                />
              </div>
            )
          }

          <div className="d-flex justify-content-between mt-4">
            <Typography variant="h2Medium">
              {intl.formatMessage({ id: 'EVENT.EVENTS.EVENT_DESCRIPTION' })}
            </Typography>
            <IconButton
              tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
              onClick={() => handleClick(1)}
            >
              <EditIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
            </IconButton>
          </div>
          <div
            id={`description_${data.drop_event_id}`}
            className={`mt-3 ${showDescriptionMore ? classes.eventMore : classes.descriptionLess}`}
          >
            <Typography variant="body">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.event_details
                }}
              />
            </Typography>
          </div>
          {
            isDescriptionMore && (
              <div className="d-flex justify-content-center align-items-center">
                <CustomButton
                  label={(
                    <Typography variant="bodySemiBold" color={process.env.REACT_APP_COLOR_PRIMARY}>
                      {showDescriptionMore ? intl.formatMessage({ id: 'GENERAL.VIEW.LESS' }) : intl.formatMessage({ id: 'GENERAL.VIEW.MORE' })}
                    </Typography>
                  )}
                  onClick={() => setShowDescriptionMore(!showDescriptionMore)}
                />
              </div>
            )
          }
        </div>
        <div
          id={`hours_${data.drop_event_id}`}
          className={showHoursMore ? classes.eventMore : classes.hoursLess}
        >
          <div className="d-flex justify-content-between pt-3">
            <Typography variant="h2Medium">
              {intl.formatMessage({ id: 'EVENT.EVENTS.HOURS_OF_OPERATION' })}
            </Typography>
            <IconButton
              tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
              onClick={() => handleClick(2)}
            >
              <EditIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
            </IconButton>
          </div>
          {
            data.drop_event_loc_ids
            && data.drop_event_loc_ids.map((item, index) => (
              <div
                key={`event_loc_ids_${index}_${Math.random()}`}
                className={index !== data.drop_event_loc_ids.length - 1 ? classes.locations : null}
              >
                <div className="mt-3 mb-4">
                  {
                    item.event_loc_admin_nm && (
                      <Typography variant="bodySemiBold">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: formatAddress(item.event_loc_admin_nm)
                          }}
                        />
                      </Typography>
                    )
                  }
                  {
                    item.display_addr && (
                      <Typography variant="body">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: formatAddress(item.display_addr)
                          }}
                        />
                      </Typography>
                    )
                  }
                </div>

                {
                  item.drop_event_loc_hours
                  && item.drop_event_loc_hours.map((hour) => (
                    <React.Fragment key={`${hour.dow_nbr}___${Math.random()}`}>
                      {hour.hours_lower_tm && (
                        <div className="d-flex mt-2 justify-content-between">
                          <Typography variant="body">
                            {daysOfTheWeek[hour.dow_nbr]}
                          </Typography>
                          <div className="d-flex flex-column">
                            <Typography variant="bodySemiBold">
                              {removeZeroFromTime(transformToAmpm(hour.hours_lower_tm))}
                              {' - '}
                              {removeZeroFromTime(transformToAmpm(hour.hours_upper_tm))}
                            </Typography>
                            {
                              hour.add_hours_lower_tm && hour.add_hours_upper_tm && (
                                <Typography className="mt-2 text-right" variant="bodySemiBold">
                                  {removeZeroFromTime(transformToAmpm(hour.add_hours_lower_tm))}
                                  {' - '}
                                  {removeZeroFromTime(transformToAmpm(hour.add_hours_upper_tm))}
                                </Typography>
                              )
                            }
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))
                }

                <div className="mt-4">
                  {
                    item.phone_nbr && (
                      <div className="d-flex mt-2 justify-content-between">
                        <Typography variant="body">
                          {intl.formatMessage({ id: 'DEPOT.CONTACT.TELEPHONE' })}
                        </Typography>
                        <Typography variant="bodySemiBold">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: formatAddress(item.phone_nbr)
                            }}
                          />
                        </Typography>
                      </div>
                    )
                  }
                  {
                    item.alt_phone_nbr && (
                      <div className="d-flex mt-2 justify-content-between">
                        <Typography variant="body">
                          {intl.formatMessage({ id: 'DEPOT.CONTACT.ALT_TELEPHONE' })}
                        </Typography>
                        <Typography variant="bodySemiBold">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: formatAddress(item.alt_phone_nbr)
                            }}
                          />
                        </Typography>
                      </div>
                    )
                  }
                  {
                    item.email_addr && (
                      <div className="d-flex mt-2 justify-content-between">
                        <Typography variant="body">
                          {intl.formatMessage({ id: 'DEPOT.CONTACT.EMAIL' })}
                        </Typography>
                        <Typography variant="bodySemiBold">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: formatAddress(item.email_addr)
                            }}
                          />
                        </Typography>
                      </div>
                    )
                  }
                  {
                    item.web_addr && (
                      <div className="d-flex mt-2 justify-content-between">
                        <Typography className="w-25" variant="body">
                          {intl.formatMessage({ id: 'DEPOT.CONTACT.WEBSITE' })}
                        </Typography>
                        <Typography
                          className="w-50 d-flex justify-content-end"
                          variant="bodySemiBold"
                          color={process.env.REACT_APP_COLOR_PRIMARY}
                        >
                          <a href={`${item.web_addr}`}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: formatAddress(item.web_addr)
                              }}
                            />
                          </a>
                        </Typography>
                      </div>
                    )
                  }
                </div>
              </div>
            ))
          }
        </div>
        {
          isHoursMore && (
            <div className="d-flex justify-content-center align-items-center">
              <CustomButton
                label={(
                  <Typography variant="bodySemiBold" color={process.env.REACT_APP_COLOR_PRIMARY}>
                    {showHoursMore ? intl.formatMessage({ id: 'GENERAL.VIEW.LESS' }) : intl.formatMessage({ id: 'GENERAL.VIEW.MORE' })}
                  </Typography>
                )}
                onClick={() => setShowHoursMore(!showHoursMore)}
              />
            </div>
          )
        }
      </PortletBody>

      <PortletHeader
        toolbar={(
          <div className="w-100 d-flex justify-content-between align-items-center">
            <Typography variant="h1">
              {intl.formatMessage({ id: 'EVENT.EVENTS.DRAWER_LINKS.TITLE' })}
              <InfoTooltip tooltip={intl.formatMessage({ id: 'EVENT.EVENTS.DRAWER_LINKS.TOOLTIP' })} />
            </Typography>
            {
              data.drawers && data.drawers.length !== 0 && (
                <IconButton onClick={() => setDrawerLinkIsOpen(!drawerLinkIsOpen)}>
                  {
                    drawerLinkIsOpen ? (
                      <CollapseIcon />
                    ) : (
                      <ExpandIcon />
                    )
                  }
                </IconButton>
              )
            }
          </div>
        )}
      />
      <PortletBody className="pt-0 pb-0 pr-0">
        {
          drawerLinkIsOpen && (
            <EventDrawerLinks drawerLinks={data.drawers} />
          )
        }
      </PortletBody>

      <PortletHeader
        className={!assignedCategoriesIsOpen ? classes.assignedCategories : null}
        toolbar={(
          <div className="w-100 d-flex justify-content-between align-items-center">
            <Typography variant="h1">
              {intl.formatMessage({ id: 'EVENT.EVENTS.ASSIGNED_CATEGORIES.TITLE' })}
              <InfoTooltip tooltip={intl.formatMessage({ id: 'EVENT.EVENTS.ASSIGNED_CATEGORIES.TOOLTIP' })} />
            </Typography>
            {
              data.category_assigns && data.category_assigns.length !== 0 && (
                <IconButton onClick={() => setAssignedCategories(!assignedCategoriesIsOpen)}>
                  {
                    assignedCategoriesIsOpen ? (
                      <CollapseIcon />
                    ) : (
                      <ExpandIcon />
                    )
                  }
                </IconButton>
              )
            }
          </div>
        )}
      />
      <PortletBody className="pt-0 pr-0">
        {
          assignedCategoriesIsOpen && (
            <EventAssignedCategories assignedCategories={data.category_assigns} />
          )
        }
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  locality: store.common.locality,
});

export default injectIntl(connect(mapStateToProps, actions)(Event));
