import React from 'react';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Custom Tabs based on material-ui Tabs and Tab.
 * @param {number} selectedTab The number of selected tab.
 * @param {function} onChange It is called when selectedTab changes.
 * @param {array} labels List of labels shown in tab header.
 * @example
 *  <Tab
      selectedTab=0
      onChange={handleChange}
      labels={['Schedule', 'Drafts']}
    />
 */
const Tab = ({
  selectedTab = 0,
  onChange,
  labels,
  selectedColor = process.env.REACT_APP_COLOR_PRIMARY,
  unselectedColor = process.env.REACT_APP_COLOR_DISABLED_TEXT,
  borderColor = process.env.REACT_APP_COLOR_BACKGROUND_FILL,
  selectedBottomBorderColor = process.env.REACT_APP_COLOR_SECONDARY,
  addButton = null,
  displayBlueTabs = false,
  hideHr = false,
  widthValue = '20%'
}) => {
  const useStyles = makeStyles(() => ({
    root: {
      position: 'relative',
      zIndex: 10,
      textTransform: 'none',
      color: unselectedColor,
      opacity: 1,
      fontSize: 14,
      '&$selected': {
        color: selectedColor,
        border: `2px solid ${borderColor}`,
        borderBottom: `3px solid ${selectedBottomBorderColor}`,
      },
    },
    blueTabsRoot: {
      position: 'relative',
      zIndex: 10,
      textTransform: 'none',
      color: '#2A95FF',
      opacity: 1,
      fontSize: 14,
      borderRadius: '5px 5px 0px 0px',
      backgroundColor: 'rgba(42, 149, 255, 0.12)',
      border: `2px solid #2A95FF`,
      borderBottom: 'none',
      width: widthValue,
      maxWidth: 'unset',
      '&$selected': {
        color: process.env.REACT_APP_COLOR_SECONDARY,
        // border: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
        borderBottom: `1px solid #2A95FF`,
        backgroundColor: '#2A95FF',
      },
    },
    selected: {},
    indicator: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    hr: {
      width: '100%',
      margin: 0,
      position: 'relative',
      top: '-2px',
      borderTop: `2px solid ${borderColor}`,
    }
  }));

  const classes = useStyles();

  return (
    <>
      <MuiTabs
        classes={{
          indicator: classes.indicator
        }}
        value={selectedTab}
        onChange={onChange}
      >
        {labels.map((label) => (
          <MuiTab
            key={`${label} - ${Math.random()}`}
            classes={{
              root: displayBlueTabs ? classes.blueTabsRoot : classes.root,
              selected: classes.selected
            }}
            label={label}
          />
        ))}
        {addButton}
      </MuiTabs>
      {
        !hideHr && (
          <hr className={classes.hr} />
        )
      }
    </>
  );
};

export default Tab;
