import { put, call, takeLatest } from 'redux-saga/effects';

import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  getWasteTypes,
  createWasteType,
  deleteWasteType,
} from '../apis';

function* getWasteTypesListSaga({ payload: { locality } }) {
  try {
    yield put(actions.setLoadingAction('send-request'));
    const res = yield call(getWasteTypes, { locality });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.getWasteTypesListReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getWasteTypesListFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* createWasteTypeSaga({ payload: { locality, name } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(createWasteType, { locality, name });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.createWasteTypeReceive({ name, id: res.data.data.waste_type_id }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.createWasteTypeFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* deleteWasteTypeSaga({ payload: { locality, wasteTypeId } }) {
  try {
    yield put(actions.setSnackbarAction('send-request'));
    const res = yield call(deleteWasteType, { locality, wasteTypeId });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.deleteWasteTypeReceive({ wasteTypeId }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.deleteWasteTypeFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

/**
 * Saga watcher function
 */
function* wasteTypeReducerSagas() {
  yield takeLatest(actionTypes.GET_WASTE_TYPES_LIST_REQUEST, getWasteTypesListSaga);
  yield takeLatest(actionTypes.CREATE_WASTE_TYPE_REQUEST, createWasteTypeSaga);
  yield takeLatest(actionTypes.DELETE_WASTE_TYPE_REQUEST, deleteWasteTypeSaga);
}

export default wasteTypeReducerSagas;
