/**
 *
 */
const initialState = {
  fetchState: 'no-request',
  snackbarState: 'no-request',
  responseStatus: 'success',
  responseMessage: null,
  wasteTypeList: [],
};

export default initialState;
