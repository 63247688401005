import { put, call, takeLatest } from 'redux-saga/effects';
import actionTypes from './actionTypes';
import * as actions from './actions';
import { getMajorCampaignsData, getMajorCampaignsReportData, getMajorCampaignsDetailsData } from '../apis';


function* getMajorCampaignsDataSaga({ payload: { project_id, district_id, from, to } }) {
  const response = yield call(getMajorCampaignsData, { project_id, district_id, from, to });

  try {
    response.data.data.major_campaigns.sort((a, b) => new Date(b.date_from) - new Date(a.date_from));
    yield put(actions.getMajorCampaignsDataReceiveAction(response.data));

  } catch (error) {
    yield put(actions.getMajorCampaignsDataFailAction(response.error));

  }
}


function* getMajorCampaignsReportDataSaga({ payload: { project_id, district_id } }) {
  const response = yield call(getMajorCampaignsReportData, { project_id, district_id });
  response.data.data.major_campaigns_details = response.data.data.major_campaigns;

  delete response.data.data.major_campigns;

  try {
    yield put(actions.getMajorCampaignsReportDataReceiveAction(response.data));

  } catch (error) {
    yield put(actions.getMajorCampaignsReportDataFailAction(response.error));
  }
}

function* getMajorCampaignsDetailsDataSaga({ payload: { project_id, district_id, allocation_code } }) {
  const response = yield call(getMajorCampaignsDetailsData, { project_id, district_id, allocation_code });
  response.data.data.major_campaigns_details = response.data.data.major_campaigns;

  delete response.data.data.major_campigns;

  try {
    yield put(actions.getMajorCampaignsDetailsDataReceiveAction(response.data));

  } catch (error) {
    yield put(actions.getMajorCampaignsDetailsDataFailAction(response.error));
  }
}


function* MajorCampaignsSaga() {
  yield takeLatest(actionTypes.GET_MAJOR_CAMPAIGNS_DATA_REQUEST, getMajorCampaignsDataSaga);
  yield takeLatest(actionTypes.GET_MAJOR_CAMPAIGNS_DETAILS_DATA_REQUEST, getMajorCampaignsDetailsDataSaga);
  yield takeLatest(actionTypes.GET_MAJOR_CAMPAIGNS_REPORTS_DATA_REQUEST, getMajorCampaignsReportDataSaga);
}

export default MajorCampaignsSaga;
