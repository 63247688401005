const actionTypes = {
  // Resident Knowledge Indicator report actionTypes for setting loading status
  SET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_LOADING_ACTION: 'SET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_LOADING_ACTION',

  // Resident Knowledge Indicator report actionTypes for setting all time mode
  SET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_ALL_TIME_ACTION: 'SET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_ALL_TIME_ACTION',

  // Resident Knowledge Indicator report actionTypes for fetching report data
  GET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_REQUEST: 'GET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_REQUEST',
  GET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_RECEIVE: 'GET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_RECEIVE',
  GET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_FAIL: 'GET_RESIDENT_KNOWLEDGE_INDICATOR_REPORT_FAIL',

};

export default actionTypes;
