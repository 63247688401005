import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';
import createReducer from '../../../../../redux/createReducer';
import initialStore from './initialStore';

const MajorCampaignsReducer = persistReducer({
  storage,
  key: 'MajorCampaigns',
}, createReducer(initialStore, {
  GET_MAJOR_CAMPAIGNS_DATA_REQUEST: (state) => Immutable({
    ...state,
    loading: true,
    error: false,
  }),
  GET_MAJOR_CAMPAIGNS_DATA_RECEIVE: (state, { payload }) => Immutable({
    ...state,
    loading: false,
    ...payload.data,
  }),
  GET_MAJOR_CAMPAIGNS_DATA_FAIL: (state, { payload }) => Immutable({
    ...state,
    loading: false,
    error: payload,
  }),
  SET_TAB: (state, { payload }) => Immutable({
    ...state,
    tab: payload.tab
  }),
  SET_ALLOCATION_CODE: (state, { payload }) => Immutable({
    ...state,
    allocation_code: payload.allocation_code
  }),
  SET_MAJOR_CAMPAIGN: (state, { payload }) => Immutable({
    ...state,
    major_campaign: payload.major_campaign
  }),
  SET_CURRENT_QUESTION_INDEX: (state, { index }) => Immutable({
    ...state,
    currentQuestionIndex: index
  }),
  GET_MAJOR_CAMPAIGNS_DETAILS_DATA_REQUEST: (state) => Immutable({
    ...state,
    loading: true,
    error: false,
  }),
  GET_MAJOR_CAMPAIGNS_DETAILS_DATA_RECEIVE: (state, { payload }) => Immutable({
    ...state,
    loading: false,
    ...payload.data,
  }),
  GET_MAJOR_CAMPAIGNS_DETAILS_DATA_FAIL: (state, { payload }) => Immutable({
    ...state,
    loading: false,
    error: payload,
  }),
  GET_MAJOR_CAMPAIGNS_REPORTS_DATA_REQUEST: (state) => Immutable({
    ...state,
    loading: true,
    error: false,
  }),
  GET_MAJOR_CAMPAIGNS_REPORTS_DATA_RECEIVE: (state, { payload }) => Immutable({
    ...state,
    loading: false,
    ...payload.data,
  }),
  GET_MAJOR_CAMPAIGNS_REPORTS_DATA_FAIL: (state, { payload }) => Immutable({
    ...state,
    loading: false,
    error: payload,
  })

}));

export default MajorCampaignsReducer;
