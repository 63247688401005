/* eslint-disable import/prefer-default-export */
import cts from '../../../../crud/cts';

export const getWhereDoesThisGoData = async ({
  project_id,
  district_id
}) => cts(
  'get',
  `/education-spotlight-materials?project_id=${project_id}&district_id=${district_id}`
);
