import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Typography } from '../../../../components';
import { PortletBody } from '../../../../../../_metronic/partials/content/Portlet';
import * as actions from '../redux/actions';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    border: '2px solid #2A95FF',
    borderRadius: 5,
    borderTop: 'none',
    position: 'relative',
    top: '-5px',
  },
  materialContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '20px 0',
    flex: '50%',
  },
  materialText: {
    marginLeft: 20,
  },
  materialHeader: {
    color: '#0789C4',
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: 0.72,
  },
  materialType: {
    color: '#404040',
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0.72,
    margin: '10px 0',
  },
  materialDesc: {
    color: 'rgba(64, 64, 64, 0.80)',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.56,
  },
}));

const MajorCampaignMaterials = ({
  intl, materials
}) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <PortletBody>
        {intl.formatMessage({
          id: 'EDUCATION.MAJOR.CAMPAIGNS.SPOTLIGHT.MATERIALS.PROMO',
        })}
        {
          materials && materials.length ? (
            <>
              <div className="d-flex justify-content-between w-100 flex-wrap">
                {materials.map((item) => (
                  <div className={classes.materialContainer}>
                    <img
                      src={item.image}
                      alt="Spotlight Materials"
                      style={{ width: 150, height: 150 }}
                    />
                    <div className={classes.materialText}>
                      <Typography className={classes.materialHeader} variant="h1">
                        {item.name}
                      </Typography>
                      <Typography className={classes.materialType} variant="h1">
                        {item.category_header}
                      </Typography>
                      <Typography
                        className={classes.materialDesc}
                        variant="body"
                        dangerouslySetInnerHTML={{ __html: item.category_body }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )
            : <div />
        }

      </PortletBody>
    </div>
  );
};

const mapStateToProps = (store) => ({
  materials: store.majorCampaigns.materials,
});

export default injectIntl(connect(mapStateToProps, actions)(MajorCampaignMaterials));
