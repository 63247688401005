import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import {
  MunicipalitySelector,
  DatePicker,
  InfoTooltip,
  AutoComplete,
  Typography,
  TextField
} from '../../components';
import CustomRadioButton from './CustomRadioButton';
import NewReminderSubheader from './NewReminderSubheader';
import ButtonSection from './ButtonSection';
import * as actions from '../redux/actions';
import { validation } from '../../../utils';
import { FormLoading } from '../../components/loading';

const useStyles = makeStyles(() => ({
  container: {
    '-webkit-justify-content': 'space-between',
    '-webkit-flex': 1,
    '-webkit-flex-direction': 'column',
  },
}));

const CustomForm = ({
  intl,
  municipalities,
  setValueAction,
  userName,
  formLoading,
  reminder,
  collectionList,
  getCollectionRequest,
  resetValueAction,
  editReminderRequest,
  newReminderRequest,
  fetchState
}) => {
  const classes = useStyles();
  const [descriptionInvalidText, setDescriptionInvalidText] = useState(true);
  const [invalidText, setInvalidText] = useState({
    dateFrom: true,
    dateTo: true,
    district_id: true,
    collections: true,
  });

  useEffect(() => {
    if (reminder.district.length === 1 && reminder.district[0].district_id !== '#all-districts#') {
      getCollectionRequest(reminder.district);
    }
  }, [reminder.district, getCollectionRequest]);

  const handleChange = (data, propertyName) => {
    setValueAction({
      data,
      propertyName
    });
  };

  useEffect(() => {
    if (municipalities.length === 1) {
      handleChange(municipalities, 'district');
    }
  }, [municipalities, userName, fetchState]);

  return (
    <>
      { formLoading
        ? <FormLoading />
        : (
          <div
            className={`h-100 d-flex flex-column justify-content-between ${classes.container}`}
          >
            <div>
              {municipalities.length > 1 && (
              <div className=" mb-4">
                <MunicipalitySelector
                  title={intl.formatMessage({ id: 'GENERAL.MUNICIPALITY' })}
                  isMulti
                  disableClearable={false}
                  onChange={(e) => {
                    handleChange(e, 'district');
                    handleChange([], 'collections');
                    handleChange(null, 'material');
                    if (e.length > 1) {
                      handleChange('all_collections', 'collectionType');
                      handleChange([], 'collections');
                    }
                    if (e.length === 1 && e[0].district_id === '#all-districts#') {
                      handleChange('all_collections', 'collectionType');
                      handleChange([], 'collections');
                    }
                  }}
                  onFocus={() => {
                    if (!invalidText.district_id) {
                      setInvalidText({ ...invalidText, district_id: true });
                    }
                  }}
                  value={reminder.district}
                  invalidText={
                    invalidText.district_id
                    && !validation(reminder.district, 'required')
                      ? intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
                      : null
                  }
                />
              </div>
              )}
              <div className="mb-4">
                <div className="d-flex">
                  <Typography variant="h2">
                    {intl.formatMessage({ id: 'REMINDER.SET_DATE' })}
                  </Typography>
                  <InfoTooltip tooltip={intl.formatMessage({ id: 'REMINDER.DATE_RANGE.TOOLTIP' })} />
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <DatePicker
                      label={intl.formatMessage({ id: 'GENERAL.FROM' })}
                      value={reminder.dateFrom !== '' ? reminder.dateFrom : null}
                      onChange={(e) => {
                        handleChange(moment(e).format('YYYY-MM-DD'), 'dateFrom');
                      }}
                      onFocus={() => {
                        if (!invalidText.dateFrom) {
                          setInvalidText({ ...invalidText, dateFrom: true });
                        }
                      }}
                      clearable={false}
                      invalidText={
              invalidText.dateFrom && !validation(reminder.dateFrom, 'required')
                ? intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
                : null
          }
                    />
                  </div>
                  <div className="col-xl-6">
                    <DatePicker
                      label={intl.formatMessage({ id: 'GENERAL.TO' })}
                      value={reminder.dateTo !== '' ? reminder.dateTo : null}
                      minDate={reminder.dateFrom}
                      disabled={!reminder.dateFrom}
                      onChange={(e) => {
                        handleChange(moment(e).format('YYYY-MM-DD'), 'dateTo');
                      }}
                      onFocus={() => {
                        if (!invalidText.dateTo) {
                          setInvalidText({ ...invalidText, dateTo: true });
                        }
                      }}
                      clearable={false}
                      invalidText={
                        invalidText.dateTo
                        && !validation(reminder.dateTo, 'required')
                          ? intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <NewReminderSubheader title={intl.formatMessage({ id: 'REMINDER.MESSAGE_TYPE.TITLE' })} />
                <CustomRadioButton
                  row
                  firstLabel={intl.formatMessage({ id: 'REMINDER.MESSAGE_TYPE.RADIO.ALL' })}
                  secondLabel={intl.formatMessage({ id: 'REMINDER.MESSAGE_TYPE.RADIO.SPECIFIC' })}
                  firstValue="all_collections"
                  secondValue="selected_collection"
                  onChange={(e) => {
                    handleChange(e, 'collectionType');
                    handleChange([], 'collections');
                  }}
                  value={reminder.collectionType}
                  isDisable={reminder.district.length > 1 || (reminder.district.length > 0 && reminder.district[0].district_id === '#all-districts#')}
                />
                { reminder.collectionType === 'selected_collection'
                  ? (
                    <AutoComplete
                      isMulti
                      optionTitle="col_type_title"
                      optionId="col_type_id"
                      title={intl.formatMessage({ id: 'REMINDER.MESSAGE_TYPE.COLLECTION_TYPES' })}
                      options={collectionList}
                      onChange={(e) => {
                        handleChange(e, 'collections');
                      }}
                      onFocus={() => {
                        if (!invalidText.collections) {
                          setInvalidText({ ...invalidText, collections: true });
                        }
                      }}
                      value={reminder.collections}
                      isDisable={reminder.district.length === 0 || collectionList.length === 0}
                      invalidText={
                        invalidText.collections
                        && !validation(reminder.collections, 'required')
                          ? intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
                          : null
                      }
                    />
                  ) : null}
              </div>

              <div className="mb-4">
                <NewReminderSubheader />
                <div className="mt-4 mb-2">
                  <TextField
                    defaultRow={3}
                    isMulti
                    maxRow={3}
                    onFocus={() => {
                      if (descriptionInvalidText === false) {
                        setDescriptionInvalidText(true);
                      }
                    }}
                    invalidText={
                      descriptionInvalidText && reminder.message === ''
                      && !validation(reminder.collections, 'required')
                        ? intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
                        : null
                    }
                    label={intl.formatMessage({ id: 'REMINDER.BODY.DESCRIPTION' })}
                    onChange={(e) => {
                      handleChange(e, 'message');
                    }}
                    value={reminder.message}
                  />
                </div>
              </div>

            </div>
            <ButtonSection
              disabled={
                reminder.district.length === 0
                || reminder.dateFrom === ''
                || reminder.dateTo === ''
                || (reminder.collectionType !== 'all_collections'
                && reminder.collections.length === 0)
                || reminder.message === ''
              }
              cancelAction={() => {
                resetValueAction();
                setInvalidText({
                  dateFrom: false,
                  dateTo: false,
                  district_id: false,
                  collections: false,
                });
                setDescriptionInvalidText(false);
                if (municipalities.length === 1) {
                  handleChange(municipalities, 'district');
                }
              }}
              scheduleAction={() => {
                if (reminder.id) {
                  editReminderRequest({ reminder });
                } else { newReminderRequest({ reminder }); }
                setInvalidText({
                  dateFrom: false,
                  dateTo: false,
                  district_id: false,
                  collections: false,
                });
                setDescriptionInvalidText(false);
              }}
            />
          </div>
        )}
    </>
  );
};

const mapStateToProps = (store) => ({
  municipalities: store.common.municipalities,
  userName: store.auth.user_info.user_name,
  formLoading: store.reminder.formLoading,
  reminder: store.reminder.newReminder,
  collectionList: store.reminder.collectionList,
  fetchState: store.reminder.fetchState,
});

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(CustomForm)
);
