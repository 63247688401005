import { put, call, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  getList,
  deleteNotification,
  loadNotification,
  sendNow,
  editDeleteDate,
  getZone,
  newDraft,
  newScheduled,
  editDraft,
  editScheduled,
  newImmediately,
  editImmediately,
  updateNotification,
} from '../apis';

function* getListSaga({ payload: { page, type } }) {
  yield put(actions.setLoadingAction());
  const res = yield call(getList, { page, type });

  if (res.data) {
    yield put(actions.notificationListReceive(res.data));
  } else {
    yield put(actions.notificationListFail(res.error.detail));
  }
}

function* deleteNotificationSaga({ payload: { id, type, page } }) {
  const res = yield call(deleteNotification, { id });

  if (res.data) {
    yield put(actions.deleteNotificationReceive(res.data));
    yield put(
      actions.notificationListRequest({
        type,
        page,
      })
    );
  } else {
    yield put(actions.deleteNotificationFail(res.error.detail));
  }
}

function* loadNotificationSaga({ payload: { id } }) {
  yield put(actions.setFormLoading(true));
  const res = yield call(loadNotification, { id });

  if (res.data) {
    yield put(actions.loadNotificationReceive(res.data));
  } else {
    yield put(actions.loadNotificationFail(res.error.detail));
  }
  yield put(actions.setFormLoading(false));
}

function* sendNowSaga({ payload: { id, type, page } }) {
  const res = yield call(sendNow, { id });

  if (res.data) {
    yield put(actions.setNotificationCat(true));
    yield put(actions.sendNowReceive(res.data));
    yield put(
      actions.notificationListRequest({
        type,
        page: 1,
      })
    );
  } else {
    yield put(actions.sendNowFail(res.error.detail));
  }
}
function* editDeleteDateSaga({
  payload: {
    id, time, date, type, page
  }
}) {
  const res = yield call(editDeleteDate, { id, time, date });

  if (res.data) {
    yield put(actions.editDeleteDateReceive(res.data));
    yield put(
      actions.notificationListRequest({
        type,
        page,
      })
    );
  } else {
    yield put(actions.editDeleteDateFail(res.error.detail));
  }
}

function* getZonesSaga({ payload: { district_id } }) {
  const res = yield call(getZone, { district_id });

  if (res.data) {
    yield put(actions.getZoneReceive(res.data));
  } else {
    yield put(actions.getZoneFail(res.error.detail));
  }
}

function* newDraftSaga({ payload: data }) {
  const res = yield call(newDraft, data);
  if (res.data) {
    yield put(actions.setNotificationCat(false));
    yield put(actions.newDraftReceive(res.data));
    yield put(actions.setTabAction(1));
    yield put(actions.resetFormAction());
    yield put(
      actions.notificationListRequest({
        type: 'DRAFT',
        page: 1,
      })
    );
  } else {
    yield put(actions.newDraftFail(res.error.detail));
  }
}

function* newScheduledSaga({ payload: data }) {
  const res = yield call(newScheduled, data);
  if (res.data) {
    if (data.send_date === '') {
      yield put(actions.setNotificationCat(true));
    } else {
      yield put(actions.setNotificationCat(false));
    }
    yield put(actions.newScheduledReceive(res.data));
    yield put(actions.setTabAction(0));
    yield put(actions.resetFormAction());
    yield put(
      actions.notificationListRequest({
        type: 'SCHEDULE',
        page: 1,
      })
    );
  } else {
    yield put(actions.newScheduledFailed(res.error.detail));
  }
}

function* newImmediatelySaga({ payload: data }) {
  const res = yield call(newImmediately, data);
  if (res.data) {
    yield put(actions.setNotificationCat(true));
    yield put(actions.newImmediatelyReceive(res.data));
    yield put(actions.resetFormAction());
    yield put(
      actions.notificationListRequest({
        type: 'SENT',
        page: 1,
      })
    );
  } else {
    yield put(actions.newImmediatelyFailed(res.error.detail));
  }
}

function* editDraftSaga({ payload: data }) {
  const res = yield call(editDraft, data);
  if (res.data) {
    yield put(actions.setNotificationCat(false));
    yield put(actions.editDraftReceive(res.data));
    yield put(actions.setTabAction(1));
    yield put(actions.resetFormAction());
    yield put(
      actions.notificationListRequest({
        type: 'DRAFT',
        page: 1,
      })
    );
  } else {
    yield put(actions.editDraftFail(res.error.detail));
  }
}

function* editScheduledSaga({ payload: data }) {
  const res = yield call(editScheduled, data);
  if (res.data) {
    if (moment(`${data.send_date}T${data.send_time}`).format() > moment().format()) {
      yield put(actions.setNotificationCat(false));
    } else {
      yield put(actions.setNotificationCat(true));
    }
    if (data.send_date === '') {
      yield put(actions.setNotificationCat(true));
    }
    yield put(actions.editScheduledReceive(res.data));
    yield put(actions.setTabAction(0));
    yield put(actions.resetFormAction());
    yield put(
      actions.notificationListRequest({
        type: 'SCHEDULE',
        page: 1,
      })
    );
  } else {
    yield put(actions.editScheduledFail(res.error.detail));
  }
}

function* editImmediatelySaga({ payload: data }) {
  const res = yield call(editImmediately, data);
  if (res.data) {
    yield put(actions.setNotificationCat(true));
    yield put(actions.editImmediatelyReceive(res.data));
    yield put(actions.resetFormAction());
    yield put(
      actions.notificationListRequest({
        type: 'SENT',
        page: 1,
      })
    );
  } else {
    yield put(actions.editImmediatelyFail(res.error.detail));
  }
}

function* updateNotificationSaga({ payload: data }) {
  const res = yield call(updateNotification, data);
  if (res.data) {
    yield put(actions.updateNotificationReceive(res.data));
    yield put(actions.setNotificationCat(true));
    yield put(actions.resetFormAction());
    yield put(
      actions.notificationListRequest({
        type: 'SENT',
        page: 1,
      })
    );
  } else {
    yield put(actions.updateNotificationFail(res.error.detail));
  }
}

/**
 * Saga watcher function
 */
function* notificationSagas() {
  yield takeLatest(actionTypes.NOTIFICATION_LIST_REQUEST, getListSaga);
  yield takeLatest(
    actionTypes.DELETE_NOTIFICATION_REQUEST,
    deleteNotificationSaga
  );
  yield takeLatest(actionTypes.SEND_NOW_REQUEST, sendNowSaga);
  yield takeLatest(actionTypes.LOAD_NOTIFICATION_REQUEST, loadNotificationSaga);
  yield takeLatest(actionTypes.EDIT_DELETE_DATE_REQUEST, editDeleteDateSaga);
  yield takeLatest(actionTypes.GET_ZONE_REQUEST, getZonesSaga);
  yield takeLatest(actionTypes.NEW_DRAFT_REQUEST, newDraftSaga);
  yield takeLatest(actionTypes.NEW_SCHEDULED_REQUEST, newScheduledSaga);
  yield takeLatest(actionTypes.EDIT_DRAFT_REQUEST, editDraftSaga);
  yield takeLatest(actionTypes.EDIT_SCHEDULED_REQUEST, editScheduledSaga);
  yield takeLatest(actionTypes.NEW_IMMEDIATELY_REQUEST, newImmediatelySaga);
  yield takeLatest(actionTypes.EDIT_IMMEDIATELY_REQUEST, editImmediatelySaga);
  yield takeLatest(
    actionTypes.NOTIFICATION_UPDATE_REQUEST,
    updateNotificationSaga
  );
}

export default notificationSagas;
