/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../../../../_metronic/partials/content/Portlet';
import { Switch, Typography } from '../../../components';
import { DoughnutChart } from '../../components';
import { DetailRowLoading } from '../../../components/loading';
import DetailPeer from './DetailPeer';
import DetailNewSubscribers from './DetailNewSubscribers';
import { addCommasToNumber } from '../../../../utils';

const useStyles = makeStyles((theme) => ({
  detailLoading: {
    height: '50vh'
  },
  noData: {
    minHeight: '300px'
  },
  noDataColor: {
    color: process.env.REACT_APP_COLOR_GRAY_DARK,
  },
  marginTop: {
    [theme.breakpoints.between('523', '580')]: {
      marginTop: '0 !important',
    },
  }
}));

const Detail = ({
  intl,
  data,
  peerData,
  peerRating,
  fromDate,
  toDate,
  fetchState,
  district,
  advocateAdmin
}) => {
  const classes = useStyles();
  const [isPeerReport, setIsPeerReport] = useState(false);
  const isSendRequest = fetchState === 'send-request';

  useEffect(() => {
    if (district.district_id === '#all-districts#') {
      setIsPeerReport(false);
    }
  }, [district]);

  return (
    <Portlet className="h-100 position-relative">
      <PortletHeader
        toolbar={(
          <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start">
            <div className="row col-xs-12 col-lg-6 align-items-center">
              <div className="align-items-center pl-3">
                <Typography variant="h1">
                  {isPeerReport
                    ? intl.formatMessage({ id: 'REPORT.NEW_SUBSCRIBERS.DETAIL.HEADER_PEER' })
                    : intl.formatMessage({ id: 'REPORT.NEW_SUBSCRIBERS.DETAIL.HEADER_NEW_SUBSCRIBERS' })}
                </Typography>
              </div>
              <div className={`justify-content-start align-items-center mt-3 mt-sm-0 ml-3 ${classes.marginTop}`}>
                <Typography className="ml-0" variant="bodySemiBold">
                  {`${moment(fromDate).format('MMMM Do, YYYY')} - ${moment(toDate).format('MMMM Do, YYYY')}`}
                </Typography>
              </div>
            </div>
            <div className="d-flex col-sm-12 col-lg-6 p-0 justify-content-lg-end justify-content-sm-start justify-content-lg-end">
              <PortletHeaderToolbar>
                {peerData && advocateAdmin !== true && (
                <Switch
                  isActive={isPeerReport}
                  onChange={() => setIsPeerReport(!isPeerReport)}
                  leftLabel={intl.formatMessage({ id: 'REPORT.NEW_SUBSCRIBERS.DETAIL.SWITCH_NEW_SUBSCRIBERS_REPORT' })}
                  rightLabel={intl.formatMessage({ id: 'REPORT.NEW_SUBSCRIBERS.DETAIL.SWITCH_PEER_REPORT' })}
                />
                )}
              </PortletHeaderToolbar>
            </div>
          </div>
        )}
      />
      <PortletBody>
        <div className="row mt-5">
          {isPeerReport ? (
            <>
              <div className="col-xl-6 col-sm-12 col-xs-12">

                <DoughnutChart
                  isLoading={isSendRequest}
                  total={addCommasToNumber(peerRating)}
                  insideText={`${intl.formatMessage({ id: 'REPORT.GENERAL.NEW_SUBSCRIBERS' })}`}
                  labels={[
                    intl.formatMessage({ id: 'REPORT.NEW_SUBSCRIBERS.DETAIL.CATEGORIES.MOBILE.TITLE' }),
                    intl.formatMessage({ id: 'REPORT.NEW_SUBSCRIBERS.DETAIL.CATEGORIES.EMAIL.TITLE' }),
                    intl.formatMessage({ id: 'REPORT.NEW_SUBSCRIBERS.DETAIL.CATEGORIES.SMS.TITLE' }),
                    intl.formatMessage({ id: 'REPORT.NEW_SUBSCRIBERS.DETAIL.CATEGORIES.CALL.TITLE' }),
                  ]}
                  values={peerData && peerRating > 0 && [
                    peerData.mobile.rating,
                    peerData.email.rating,
                    peerData.sms.rating,
                    peerData.phone.rating
                  ]}
                  colors={['#3e95cd', '#2e75cf', '#3cbacd', '#2a95ff']}
                />

              </div>
              <div className="col-xl-6 col-sm-12 col-xs-12 pr-5 pr-xl-0  mt-5 mt-xl-0">

                { isSendRequest
                  ? (
                    <div className="d-flex flex-column w-100 align-items-center justify-content-between pl-5 pl-xl-0">
                      <DetailRowLoading width="100%" />
                    </div>
                  ) : (
                    <>
                      {peerData && <DetailPeer data={peerData} />}
                    </>
                  )}
              </div>
            </>
          ) : (
            <>
              <div
                className="col-xl-6 col-sm-12 col-xs-12"
              >

                <DoughnutChart
                  isLoading={isSendRequest}
                  total={data && addCommasToNumber(
                    data.mobile.total
                        + data.email.total
                        + data.sms.total
                        + data.phone.total
                  )}
                  insideText={intl.formatMessage({ id: 'REPORT.GENERAL.NEW_SUBSCRIBERS' })}
                  labels={[
                    intl.formatMessage({ id: 'REPORT.NEW_SUBSCRIBERS.DETAIL.CATEGORIES.MOBILE.TITLE' }),
                    intl.formatMessage({ id: 'REPORT.NEW_SUBSCRIBERS.DETAIL.CATEGORIES.EMAIL.TITLE' }),
                    intl.formatMessage({ id: 'REPORT.NEW_SUBSCRIBERS.DETAIL.CATEGORIES.SMS.TITLE' }),
                    intl.formatMessage({ id: 'REPORT.NEW_SUBSCRIBERS.DETAIL.CATEGORIES.CALL.TITLE' }),
                  ]}
                  values={data && data.mobile.total
                        + data.email.total
                        + data.sms.total
                        + data.phone.total > 0 && [
                    data.mobile.total,
                    data.email.total,
                    data.sms.total,
                    data.phone.total
                  ]}
                  colors={['#3e95cd', '#2e75cf', '#3cbacd', '#2a95ff']}
                />

              </div>
              <div className="col-xl-6 col-sm-12 col-xs-12 pr-5 pr-xl-0 mt-5 mt-xl-0">
                { isSendRequest
                  ? (
                    <div className="d-flex flex-column w-100 align-items-center justify-content-between pl-5 pl-xl-0">
                      <DetailRowLoading width="100%" />
                    </div>
                  ) : (
                    <>
                      {data && <DetailNewSubscribers data={data} />}
                    </>
                  )}

              </div>
            </>
          )}
        </div>

      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
  fetchState: store.newSubscribersReport.fetchState,
  advocateAdmin: store.auth.advocateAdmin
});

export default injectIntl(connect(mapStateToProps, null)(Detail));
