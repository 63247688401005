import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';
import createReducer from '../../../../redux/createReducer';
import initialStore from './initialStore';

const RecyclingFundamentalsReducer = persistReducer({
  storage,
  key: 'recyclingFundamentals',
  blackList: ['currentQuestionIndex'],
  whitelist: []
}, createReducer(initialStore, {
  GET_RECYCLING_FUNDAMENTALS_DATA_REQUEST: (state) => Immutable({
    ...state,
    loading: true,
    error: false,
  }),
  GET_RECYCLING_FUNDAMENTALS_DATA_RECEIVE: (state, { payload }) => Immutable({
    ...state,
    loading: false,
    ...payload.data,
    error: false

  }),
  GET_RECYCLING_FUNDAMENTALS_DATA_FAIL: (state, { payload }) => Immutable({
    ...state,
    loading: false,
    error: payload.data,
  }),
  GET_MISUNDERSTOOD_MATERIALS_DATA_REQUEST: (state) => Immutable({
    ...state,
    misunderstood_materials_loading: true,
    error: false,
  }),
  GET_MISUNDERSTOOD_MATERIALS_DATA_RECEIVE: (state, { payload }) => Immutable({
    ...state,
    misunderstood_materials_loading: false,
    // ...payload.data,
    misunderstood_materials_data: payload.data,
    error: false

  }),
  GET_MISUNDERSTOOD_MATERIALS_DATA_FAIL: (state, { payload }) => Immutable({
    ...state,
    misunderstood_materials_loading: false,
    error: payload.data,
  }),

  SET_CURRENT_QUESTION_INDEX: (state, { index }) => Immutable({
    ...state,
    currentQuestionIndex: index
  }),
}));

export default RecyclingFundamentalsReducer;
