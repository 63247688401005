import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { Opacity, Typography } from '../../../components';
import * as actions from './redux/actions';
import * as sharedActions from '../../../redux/actions';
import { LoadingSpinner, ErrorContainer } from '../../../components/loading';
import BlogsContent from '../components/BlogsContent';

const Blogs = ({
  intl,
  setDistrictAction,
  getAllEducationBlogsRequest,
  district,
  blogs,
  loading,
  municipalities,
  error,
  blogIndex,
  setCurrentBlogAction
}) => {
  useEffect(() => {
    if (district.district_id === '#all-districts#') {
      setDistrictAction(municipalities[0]);
    } else {
      const [projectId, districtId] = district.district_id.split('#$#');
      getAllEducationBlogsRequest(projectId, districtId);
    }
  }, [district]);

  const saveCurrentBlogIndex = (currentBlogIndex) => {
    setCurrentBlogAction(currentBlogIndex);
  };


  // fucntion to sort blogs by the date_from value
  const sortBlogs = (blogsData) => {
    let blogs = JSON.parse(JSON.stringify(blogsData));
    return blogs.sort((a, b) => {
      return new Date(b.date_from) - new Date(a.date_from);
    });
  };
  

  return (
    <Opacity slide>
      <Typography className="mb-4" variant="h1">
        {intl.formatMessage({ id: 'EDUCATION.FEATURED.BLOG.ARTICLES' })}
      </Typography>

      <Typography color="#404040" className="mb-4" variant="educationBody">
        {intl.formatMessage({ id: 'EDUCATION.BLOG.PROMO.1' })}
      </Typography>

      <Typography color="#404040" className="mb-5" variant="educationBody">
        {intl.formatMessage({ id: 'EDUCATION.BLOG.PROMO.2' })}
      </Typography>

      {loading ? <LoadingSpinner height={430} /> : error ? (
        <ErrorContainer
          small
          reportName="Blogs"
          height={430}
          error={error}
        />
      ) : (
        <>
          {blogs && blogs.length && <BlogsContent data={sortBlogs(blogs)} saveCurrentBlogIndex={saveCurrentBlogIndex} blogIndex={blogIndex} />}
        </>
      )}
    </Opacity>
  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  municipalities: store.common.municipalities,
  blogs: store.educationBlogs.blogs,
  loading: store.educationBlogs.loading,
  error: store.educationBlogs.error,
  blogIndex: store.educationBlogs.currentBlogIndex,
});

export default injectIntl(connect(mapStateToProps, { ...actions, ...sharedActions })(Blogs));
