import cts from '../../crud/cts';

/**
 * users list API
 * @param {string} username User's username used for receive user's list
 */
export const userList = async ({ search, offset, length }) => cts(
  'post',
  'users/get-all',
  {
    search,
    offset,
    length
  }
);

/**
 * Add users API
 */
export const addUser = async ({
  user_name,
  first_name,
  last_name,
  email,
  phone,
  is_active,
  notes,
  job_title,
  permission_list,
  district_list
}) => cts(
  'post',
  'users/new',
  {
    user_name,
    first_name,
    last_name,
    email,
    phone,
    is_active,
    notes,
    job_title,
    permission_list,
    district_list
  }
);

/**
 * edit users API
 */
export const editUser = async ({
  user_id,
  user_name,
  first_name,
  last_name,
  email,
  phone,
  is_active,
  notes,
  job_title,
  permission_list,
  district_list
}) => cts(
  'post',
  'users/edit',
  {
    user_id,
    user_name,
    first_name,
    last_name,
    email,
    phone,
    is_active,
    notes,
    job_title,
    permission_list,
    district_list
  }
);

/**
 * Delete users API
 * @param {string} user_id delete User by username
 */
export const deleteUser = async ({ user_id }) => cts(
  'post',
  'users/delete',
  {
    user_id,
  }
);

/**
 * fetch one user details API
 * @param {string} user_id fetch one user
 */
export const getOneUser = async ({ user_id }) => cts(
  'post',
  'users/get-one',
  {
    user_id,
  }
);

/**
 * check username API which check duplicate username
 */
export const checkUsername = async ({ user_name }) => cts(
  'post',
  'users/check-username-duplicate',
  {
    user_name
  }
);

/**
 * check email API which check duplicate email
 */
export const checkEmail = async ({ user_name, email }) => cts(
  'post',
  'users/check-email-duplicate',
  {
    user_name,
    email
  }
);

/**
 * check email API which check duplicate email
 */
export const userActivation = async ({ user_id, is_active }) => cts(
  'post',
  'users/activation',
  {
    user_id,
    is_active
  }
);

/**
 * Suggested Username API
 */
export const suggestUsername = async ({ first_name, last_name }) => cts(
  'post',
  'users/generate-username',
  {
    first_name,
    last_name
  }
);
