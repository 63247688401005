/**
 *
 */
const initialState = {
  fetchState: 'no-request',
  data: {},
  reportType: { name: 'Current Period', value: 0 },
};

export default initialState;
