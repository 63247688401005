import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    color: process.env.REACT_APP_COLOR_TEXT,
  },
  error: {
    border: `2px solid ${process.env.REACT_APP_COLOR_DELETE}`,
    borderRadius: '4px'
  },
  editorClass: {
    padding: '1rem',
    border: 'none',
    top: '-5px',
    position: 'relative',
    minHeight: 200,
    maxHeight: 200,
    maxWidth: 400,
    overflowY: 'scroll',
    backgroundColor: '#fff',
    color: '#404040'
  },
  wrapperClass: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
  },
  fullWidth: {
    maxWidth: 'unset',
    width: '!important100%',
  }
}));



const WysiwygEditor = ({
  value,
  onChange,
  fullWidth
}) => {
  const classes = useStyles();

  const [editorState, setEditorState] = useState(() => EditorState.createWithContent(
    ContentState.createFromBlockArray(
      convertFromHTML(value || '')
    )
  ));

  useEffect(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    onChange(markup);
  }, [editorState]);

  useEffect(() => {
    if (editorState.getCurrentContent().getPlainText().length === 0) {
      EditorState.createEmpty();
      setEditorState(EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(value || '')
        )
      ));
    }
  }, [value]);


  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        stripPastedStyles
        toolbar={{
          options: ['inline', 'list', 'link'],
          inline: {
            options: ['bold', 'italic'],
          },
          list: {
            options: ['unordered', 'ordered'],
          },

        }}
        wrapperClassName={classes.wrapperClass}
        editorClassName={`${classes.editorClass} ${fullWidth ? classes.fullWidth : ''}`}
      />
    </div>
  );
};

export default WysiwygEditor;
