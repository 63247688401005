import actionTypes from './actionTypes';

// Interaction report actions for setting loading status
export const setInteractionReportLoadingAction = (status) => ({
  type: actionTypes.SET_INTERACTION_REPORT_LOADING_ACTION,
  payload: status
});

// Interaction report actions for fetching report data
export const interactionReportRequest = ({
  municipality, from, to, county
}) => ({
  type: actionTypes.GET_INTERACTION_REPORT_REQUEST,
  payload: {
    municipality, from, to, county
  }
});

export const interactionReportReceive = (data) => ({
  type: actionTypes.GET_INTERACTION_REPORT_RECEIVE,
  payload: { data }
});

export const interactionReportFail = (error) => ({
  type: actionTypes.GET_INTERACTION_REPORT_FAIL,
  payload: { ...error }
});
