import actionTypes from './actionTypes';

export const getWhereDoesThisGoDataRequestAction = (project_id, district_id) => ({
  type: actionTypes.GET_WHERE_DOES_THIS_GO_DATA_REQUEST,
  payload: { project_id, district_id }
});

export const getWhereDoesThisGoDataReceiveAction = (data) => ({
  type: actionTypes.GET_WHERE_DOES_THIS_GO_DATA_RECEIVE,
  payload: data
});

export const getWhereDoesThisGoDataFailAction = (error) => ({
  type: actionTypes.GET_WHERE_DOES_THIS_GO_DATA_FAIL,
  payload: error
});

export const setCurrentQuestionAction = (index) => ({
  type: actionTypes.SET_CURRENT_QUESTION_INDEX,
  index
});
