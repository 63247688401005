import { put, call, takeLatest } from 'redux-saga/effects';

import {
  setCookie,
  deleteCookie,
  deleteLocalStorage,
} from '../../../../utils';
import actionTypes from './actionTypes';
import * as actions from './actions';
import * as loginActions from '../../Login/redux/actions';
import * as sharedActions from '../../../redux/actions';
import { loginAs, countyName } from '../../apis';

function* loginAsSaga({ payload: { key } }) {
  try {
    yield deleteCookie('_rc_access');
    yield deleteLocalStorage();

    yield put(actions.setLoadingAction());
    const res = yield call(loginAs, { key });
    if (res.data) {
      if (res.data.status === 'success') {
        setCookie('_rc_access', res.data.data.access_token);
        yield put(sharedActions.setLoadingAction('no-request'));
        yield put(actions.loginAsReceive(res.data));
        yield put(sharedActions.getMunicipalityRequest());
        yield put(sharedActions.getLocalitiesRequest());
        if (res.data.data.user_info.counties !== null) {
          yield put(sharedActions.getCountyRequest());
        }
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.loginAsFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

/**
 * Saga watcher function
 */
function* sagas() {
  yield takeLatest(actionTypes.LOGIN_AS_REQUEST, loginAsSaga);
}

export default sagas;
