import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useLocation,
  useRouteMatch,
  useHistory,
  Redirect
} from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { validation } from '../../../utils';

import * as actions from '../redux/actions';
import * as sharedActions from '../../redux/actions';

/**
 * Components
 */
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from '../../../../_metronic/partials/content/Portlet';
import { Tab, MunicipalitySelector } from '../../components';

/**
 * Tabs and pages
 */
import Library from './Library';
import Schedules from './Schedules';
import History from './History';

/**
 * Tabs map
 */
const pageTabs = {
  'Current Schedule': 'current_schedule',
  'Campaign Library': 'campaign_library',
  'My Campaign History': 'my_campaign_history'
};

const CampaignTabs = ({
  setTabAction,
  municipalities,
  district,
  setDistrictAction,
  intl,
  tab
}) => {
  const path = useLocation().pathname.substring(1).split('/')[2] || 'current_schedule';
  const { path: routePath } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (district.district_id === '#all-districts#') {
      setDistrictAction({
        district_id: municipalities[0] ? municipalities[0].district_id.replace('-', '#$#') : '',
        district_nm: municipalities[0] ? municipalities[0].district_nm : '',
      });
    }
  }, []);

  useEffect(() => {
    // Change the tab accordingly
    setTabAction(path);
  }, [path, setTabAction]);

  const selectActiveTab = (tabName) => {
    if (typeof tabName === 'number') return tabName;
    return Object.values(pageTabs).indexOf(tabName);
  };

  const handleChange = (event, newValue) => {
    setTabAction(newValue);
    history.replace(`/campaigns/tab/${Object.values(pageTabs)[newValue]}`);
  };

  return (
    <div>
      {municipalities.length > 1
      && (
      <Portlet>
        <PortletHeader title={intl.formatMessage({ id: 'USERS.FORM.INPUT.MUNICIPALITY' })} />
        <PortletBody>
          <MunicipalitySelector
            title={intl.formatMessage({ id: 'GENERAL.MUNICIPALITY' })}
            onChange={setDistrictAction}
            value={district}
            hasAllDistrictOption={false}
            invalidText={
              !validation(district || '', 'required')
                ? intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
                : null
            }
          />
        </PortletBody>
      </Portlet>
      )}
      <Portlet>
        <PortletHeader title={intl.formatMessage({ id: 'CAMPAIGN.HEADER.TITLE' })} />
        <PortletBody>
          <Tab
            selectedTab={((t) => selectActiveTab(t))(tab)}
            onChange={handleChange}
            labels={Object.keys(pageTabs)}
          />
          <Switch>
            <Route
              path={`${routePath}/current_schedule`}
              exact
              render={() => (
                <Schedules />
              )}
            />
            <Route
              path={`${routePath}/campaign_library`}
              exact
              render={() => (
                <Library />
              )}
            />
            <Route
              path={`${routePath}/my_campaign_history`}
              exact
              render={() => (
                <History />
              )}
            />
            <Redirect
              from={`${routePath}`}
              exact
              to={`${routePath}/current_schedule`}
            />
          </Switch>
        </PortletBody>
      </Portlet>
    </div>
  );
};

const mapStateToProps = (store) => ({
  tab: store.campaign.tab,
  municipalities: store.common.municipalities || [],
  district: store.common.district
});

export default injectIntl(
  connect(
    mapStateToProps,
    { ...actions, ...sharedActions }
  )(CampaignTabs)
);
