import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { CustomButton, Typography } from '../../components';
import { Portlet, PortletHeader } from '../../../../_metronic/partials/content/Portlet';
import headIcon from '../../../assets/images/head-dashboard.png';

const useStyles = makeStyles({
  headIcon: {
    width: 35,
    height: 35,
    border: 'none',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
  },
});

const HowResidentsAnswered = ({
  intl,
  wwtk
}) => {
  const classes = useStyles();
  const history = useHistory();

  const buttonClickHandler = () => history.push({
    pathname: '/report/resident-knowledge-indicator',
    isDashboardWidget: true
  });

  return (
    <Portlet>
      <PortletHeader title={(
        <div className="d-flex align-items-center justify-space-between py-2">
          <Typography className="mr-4" variant="h1">
            {intl.formatMessage({ id: 'DASHBOARD.HOW.RESIDENTS.ANSWERED.TITLE' })}
          </Typography>
        </div>
      )}
      />
      <div className="ml-4 pl-2 pb-4">
        <div className="d-flex mt-1 pl-2">
          <div
            role="img"
            title="hand"
            className={classes.headIcon}
            style={{ backgroundImage: `url(${headIcon})` }}
          />
          <Typography className="mx-2" variant="body">
            {wwtk?.question}
          </Typography>
        </div>
        <div className="d-flex w-100 mt-4 pt-1 pr-4">
          <CustomButton
            size="large"
            variant="contained"
            fontSize={14}
            paddingVertical={2}
            label={(
              <FormattedMessage
                id="DASHBOARD.HOW.RESIDENTS.ANSWERED.VIEW.RESPONSES"
              />
            )}
            onClick={buttonClickHandler}
          />
        </div>
      </div>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  wwtk: store.dashboard.wwtk
});

export default injectIntl(connect(mapStateToProps, null)(HowResidentsAnswered));
