import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Portlet,
  PortletBody,
  PortletHeader,
} from '../../../../_metronic/partials/content/Portlet';
import { Typography } from '../../components';

const Header = ({
  title,
  content
}) => (
  <div className="row mb-4">
    <div className="col-xl-12">
      <Portlet>
        <PortletHeader
          title={title}
        />
        <PortletBody>
          <Typography variant="bodySemiBold">
            <FormattedMessage id={content} />
          </Typography>
        </PortletBody>
      </Portlet>
    </div>
  </div>
);

export default Header;
