import { put, call, takeLatest } from 'redux-saga/effects';

import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  getRules,
  editRules
} from '../apis';

function* getWasteAndRulesSaga({ payload: { cms_locality_id } }) {
  try {
    yield put(actions.setRuleLoadingAction('send-request'));
    const res = yield call(getRules, { cms_locality_id });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.wasteAndRulesReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.wasteAndRulesFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* editWasteAndRulesSaga({
  payload: {
    cms_locality_id,
    waste_recycling_rule_id,
    rip_,
    waste_recycling_rule_value
  }
}) {
  try {
    const res = yield call(editRules, {
      cms_locality_id,
      waste_recycling_rule_id,
      rip_,
      waste_recycling_rule_value
    });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.editWasteAndRulesReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.editWasteAndRulesFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}
/**
 * Saga watcher function
 */
function* wasteAndRulesSagas() {
  yield takeLatest(actionTypes.GET_WASTE_RULES_REQUEST, getWasteAndRulesSaga);
  yield takeLatest(actionTypes.EDIT_WASTE_RULES_REQUEST, editWasteAndRulesSaga);
}

export default wasteAndRulesSagas;
