import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MuiSwitch from '@material-ui/core/Switch';

import Typography from './Typography';

const CustomSwitch = withStyles({
  switchBase: {
    color: process.env.REACT_APP_COLOR_PRIMARY,
    '&$checked + $track': {
      backgroundColor: process.env.REACT_APP_COLOR_BACKGROUND_FILL,
      opacity: '1'
    },
  },
  checked: {},
  track: {
    backgroundColor: process.env.REACT_APP_COLOR_BACKGROUND_FILL,
    opacity: '1'
  },
})(MuiSwitch);

const Switch = ({
  isActive,
  onChange,
  rightLabel,
  leftLabel,
  children,
  className
}) => {
  const useStyles = makeStyles(() => ({
    unselected: {
      color: process.env.REACT_APP_COLOR_GRAY_DARK
    },
    selected: {
      color: process.env.REACT_APP_COLOR_PRIMARY
    }
  }));
  const classes = useStyles();

  return (
    <div className={className}>
      <Grid component="label" container alignItems="center">
        <Grid item>
          <Typography className={classes.disable} variant="h2">
            {children}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className={isActive ? classes.unselected : classes.selected}
            variant="h2"
          >
            {leftLabel}
          </Typography>
        </Grid>
        <Grid item>
          <CustomSwitch
            checked={isActive}
            onChange={onChange}
            color="primary"
          />
        </Grid>
        <Grid item>
          <Typography
            variant="h2"
            className={!isActive ? classes.unselected : classes.selected}
          >
            {rightLabel}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Switch;
