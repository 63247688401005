import React from 'react';

const CalendarIcon = ({
  className,
}) => (
  <svg className={className} width="1em" height="1em" viewBox="0 0 299.063 299.063">
    <path
      d="M258.343 26.972H40.72c-19.202 0-34.769 15.567-34.769 34.769v202.553c0 19.202 15.567 34.769 34.769 34.769h217.623c19.202 0 34.769-15.567 34.769-34.769V61.741c0-19.203-15.567-34.769-34.769-34.769z"
      fill="#ebf0f3"
    />
    <path
      d="M293.112 92.299V50.171c0-19.202-15.567-34.769-34.769-34.769H40.72c-19.202 0-34.769 15.567-34.769 34.769v42.128z"
      fill="#ce412d"
    />
    <path
      d="M293.112 100.159V61.741c0-19.202-15.567-34.769-34.769-34.769H40.72c-19.202 0-34.769 15.567-34.769 34.769v38.418z"
      fill="#e56353"
    />
    <path
      d="M62.902 45.645c-6.702 0-12.135 5.433-12.135 12.135S56.2 69.914 62.902 69.914s12.134-5.433 12.134-12.134c.001-6.702-5.432-12.135-12.134-12.135z"
      fill="#d15241"
    />
    <path
      d="M62.902 0a6.493 6.493 0 00-6.492 6.492v50.581a6.492 6.492 0 1012.984 0V6.492A6.492 6.492 0 0062.902 0z"
      fill="#4d5c7d"
    />
    <path
      d="M120.655 45.645c-6.702 0-12.135 5.433-12.135 12.135s5.433 12.134 12.135 12.134 12.134-5.433 12.134-12.134c.001-6.702-5.432-12.135-12.134-12.135z"
      fill="#d15241"
    />
    <path
      d="M120.655 0a6.493 6.493 0 00-6.492 6.492v50.581a6.492 6.492 0 1012.984 0V6.492A6.493 6.493 0 00120.655 0z"
      fill="#4d5c7d"
    />
    <path
      d="M178.408 45.645c-6.702 0-12.135 5.433-12.135 12.135s5.433 12.134 12.135 12.134 12.134-5.433 12.134-12.134c0-6.702-5.432-12.135-12.134-12.135z"
      fill="#d15241"
    />
    <path
      d="M178.408 0a6.493 6.493 0 00-6.492 6.492v50.581a6.492 6.492 0 1012.984 0V6.492A6.493 6.493 0 00178.408 0z"
      fill="#4d5c7d"
    />
    <path
      d="M236.161 45.645c-6.702 0-12.135 5.433-12.135 12.135s5.433 12.134 12.135 12.134 12.134-5.433 12.134-12.134c0-6.702-5.433-12.135-12.134-12.135z"
      fill="#d15241"
    />
    <path
      d="M236.16 0a6.493 6.493 0 00-6.492 6.492v50.581a6.492 6.492 0 1012.984 0V6.492A6.492 6.492 0 00236.16 0z"
      fill="#4d5c7d"
    />
    <g fill="#e56353">
      <path d="M111.907 148.197h26.245v26.245h-26.245zM160.911 148.197h26.245v26.245h-26.245zM209.916 148.197h26.245v26.245h-26.245zM62.902 189.34h26.245v26.245H62.902zM111.907 189.34h26.245v26.245h-26.245zM160.911 189.34h26.245v26.245h-26.245zM209.916 189.34h26.245v26.245h-26.245zM62.902 230.483h26.245v26.245H62.902zM111.907 230.483h26.245v26.245h-26.245zM160.911 230.483h26.245v26.245h-26.245z" />
    </g>
  </svg>
);

export default CalendarIcon;
