import actionTypes from './actionTypes';

// Auth actions for setting loading status
export const setLoadingAction = () => ({
  type: actionTypes.SET_LOADING
});

export const resetLoadingAction = () => ({
  type: actionTypes.RESET_LOADING
});

// Reset Password actions for email verification
export const EmailVerificationRequest = (email) => ({
  type: actionTypes.EMAIL_VERIFICATION_REQUEST,
  payload: { email }
});

export const EmailVerificationReceive = (data) => ({
  type: actionTypes.EMAIL_VERIFICATION_RECEIVE,
  payload: { ...data }
});

export const EmailVerificationFail = (error) => ({
  type: actionTypes.EMAIL_VERIFICATION_FAIL,
  payload: { ...error }
});
