import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';

import { Portlet } from '../../../../../_metronic/partials/content/Portlet';
import { Typography, CustomButton } from '../../../components';

const useStyles = makeStyles(() => ({

  modalContainer: {
    width: 600,
    margin: 0,
    padding: 25,
    paddingTop: 40
  },
  textarea: {
    resize: 'none',
    width: '95%',
    padding: 5,
    height: 100,
    marginTop: 15,
    borderRadius: 5,
    '&:focus': {
      outline: 'none'
    }
  },
  applyButton: {
    fontSize: 14,
    fontWeight: 700,
    color: ({ disabled }) => (disabled
      ? 'rgba(255, 30, 30, 0.4)' : 'rgba(255, 30, 30, 1)'),
    marginRight: 20,
    transition: 'all 0.3s'
  }
}));
const EducationModal = ({
  intl, open, onClose, content, buttonApply, title, icon, disabled = false, onClickHandler, buttonDirections = 'row', buttonApply2, buttonVariant1 = 'outlined', buttonVariant2 = 'contained'
}) => {
  const classes = useStyles({ disabled });

  
  return (
    <>
      {open && (
        <Dialog open={open} onClose={onClose}>
          <Portlet className={`d-flex align-items-center text-center justify-content-center ${classes.modalContainer}`}>
            {icon}
            <Typography color="#37474F" className="my-4" variant="h2">
              {title}
            </Typography>
            {content}
            <div style={{ cursor: 'pointer', marginLeft: 'auto', width: '100%', flexDirection: buttonDirections }} className="d-flex mt-5">
              {/* <div role="button" onKeyPress={onClickHandler} onClick={onClickHandler}>
                <Typography
                  className={classes.applyButton}
                  disabled={disabled}
                >
                  {buttonApply}
                </Typography>
              </div> */}
              <CustomButton
                paddingVertical={4}
                variant={buttonVariant1}
                label={buttonApply}
                onKeyPress={onClickHandler}
                onClick={onClickHandler}
                disabled={disabled}
                style={{ margin: 5 }} 
              />

              <CustomButton
                paddingVertical={4}
                variant={buttonVariant2}
                label={buttonApply2 || intl.formatMessage({ id: 'GENERAL.CANCEL' })}
                onClick={onClose}
                style={{ margin: 5 }} 

              />
              {/* <div role="button" tabIndex={0} onKeyPress={onClose} onClick={onClose}>
                <Typography fontSize={14} fontWeight={500} color="#404040" variant="custom">
                  {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
                </Typography>
              </div> */}
            </div>
          </Portlet>
        </Dialog>
      )}
    </>
  );
};

export default injectIntl(EducationModal);
