import React from 'react';

const AlertIcon = ({
  color,
  className,
  ...props
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width="1em" height="1em" viewBox="0 0 16 16" className={`bi bi-exclamation-circle-fill ${className}`} fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
  </svg>
);

export default AlertIcon;
