import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../redux/createReducer';
import initialState from './store';

const userReducer = persistReducer(
  { storage, key: 'user', whitelist: [] },
  createReducer(initialState, {
    USER_LIST_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseStatus: 'success',
      userList: payload.data.users,
      loadingList: false
    }),
    USER_LIST_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.message,
      responseStatus: 'error',
      loadingList: false
    }),
    ADD_USER_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.message,
      responseStatus: 'success',
      newUser: { ...state.newUser, user_id: payload.data.user_id },
      navigateToList: true
    }),
    ADD_USER_ACTION: (state, { payload }) => Immutable({
      ...state,
      newUser: {
        ...state.newUser,
        [payload.propertyName]: payload.data
      },
    }),
    ADD_USER_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload,
      responseStatus: 'error',
    }),
    EDIT_USER_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseStatus: 'success',
      responseMessage: payload,
      navigateToList: true
    }),
    EDIT_USER_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload,
      responseStatus: 'error',
    }),
    DELETE_USER_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseStatus: 'success',
      responseMessage: payload,
    }),
    DELETE_USER_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload,
      responseStatus: 'error',
    }),
    GET_ONE_USER_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'GET_ONE_USER_RECEIVE',
      editUser: 'OK',
      newUser: payload
    }),
    GET_ONE_USER_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload,
      responseStatus: 'error',
    }),
    SET_USER_LIST_MANUALLY: (state, payload) => Immutable({
      ...state,
      userList: Object.values(payload.payload)
    }),
    RESET_NEW_USER_ACTION: (state) => Immutable({
      ...state,
      newUser: initialState.newUser
    }),
    SET_PERMISSIONS_ACTION: (state, { payload }) => Immutable({
      ...state,
      permissions: {
        ...state.permissions,
        [payload.propertyName]: payload.data
      },
    }),
    CHECK_USERNAME_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      valid_username: payload,
    }),
    CHECK_EMAIL_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      valid_email: payload,
    }),
    ACTIVATION_USER_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseStatus: 'success',
      responseMessage: payload,
    }),
    RESET_RESPONSE_MESSAGE: (state) => Immutable({
      ...state,
      responseMessage: initialState.responseMessage,
    }),
    RESET_NAVIGATE_TO_LIST: (state) => Immutable({
      ...state,
      navigateToList: initialState.navigateToList,
    }),
    SUGGEST_USERNAME_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      suggestedUsername: payload,
    }),
    SUGGEST_USERNAME_FAIL: (state) => Immutable({
      ...state,
      responseStatus: 'error',
    }),
    RESET_SUGGESTED_USERNAME: (state) => Immutable({
      ...state,
      suggestedUsername: initialState.suggestedUsername,
    }),
  })
);
export default userReducer;
