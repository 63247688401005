import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { addCommasToNumber } from '../../../../utils';
import { Typography, Pagination } from '../../../components';

const useStyles = makeStyles((theme) => ({
  standardView: {
    display: 'flex',
  },
  tableRow: {
    minHeight: '45px',
    borderTop: `2px solid ${process.env.REACT_APP_COLOR_DISABLED_TEXT}`,
  },
  tableHeader: {
    minHeight: '55px',
    marginTop: '30px'
  },
  tableData: {
    minHeight: '45px',
  },
  tableBorder: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  tablePadding: {
    padding: '20px',
    paddingLeft: '0px',
    paddingRight: '50px',
    [theme.breakpoints.down(992)]: {
      padding: '20px',
      paddingTop: '0px',
      paddingBottom: '30px'
    }
  },
  responsiveMode: {
    display: 'none !important',
    [theme.breakpoints.down(992)]: {
      display: 'flex !important'
    }
  },
  rowBackground: {
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_BG
  }
}));

const SurveyDetail = ({
  intl,
  data,
}) => {
  const initialChoiceData = [
    {
      name: intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SURVEY.USERS_PERCENTAGE' }),
      value: 'user_persentage'
    },
    {
      name: intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SURVEY.USERS' }),
      value: 'of_user'
    }
  ];
  const classes = useStyles();
  const [choice, setChoice] = useState(initialChoiceData[1]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <div className={`row d-flex flex-column m-3 mb-0 ${classes.standardView}`}>
        <div className={`row w-100 d-flex align-items-center ${classes.tableHeader}`}>
          <Typography
            className="d-flex col-6 justify-content-start"
            variant="bodySemiBold"
          >
            {intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SURVEY.TITLE' })}
          </Typography>

          <Typography
            className="d-flex pl-0 col-2 justify-content-start"
            variant="bodySemiBold"
          >
            {intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SURVEY.OPTIONS' })}
          </Typography>

          <Typography
            className="d-flex pl-0 col-2 justify-content-center"
            variant="bodySemiBold"
          >
            {intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SURVEY.USERS' })}
          </Typography>

          <Typography
            className="d-flex pl-0 col-2 justify-content-center"
            variant="bodySemiBold"
          >
            {intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.SURVEY.USERS_PERCENTAGE' })}
          </Typography>

        </div>

        { data && data.slice((page - 1) * rowsPerPage, (page * rowsPerPage)).map((item, id) => (
          <div
            key={`MM_REPORT_${item.title} - ${String(id)}`}
            className={`row w-100 d-flex h-100 ${classes.tableRow} ${id % 2 ? null : classes.rowBackground}`}
          >

            <Typography
              className={`col-6 h-100 d-flex align-items-start ${classes.tablePadding}`}
              variant="body"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: item.poll_quiz_question
                }}
              />
            </Typography>

            <div className={`col-6 h-100 d-flex flex-column ${classes.tableData}`}>
              { item.options && item.options.map((opt, index) => (
                <div
                  className={`row d-flex align-items-center p-0 ${classes.tableData} 
                  ${item.options.length - 1 !== index ? classes.tableBorder : null}`}
                  key={`SURVEY_ANSWER_${opt.name} - ${Math.random()} - ${String(index)}`}
                >
                  <Typography
                    className={`col-4 d-flex justify-content-start align-items-center pl-0 ${classes.tableData}`}
                    variant="bodySemiBold"
                    color={process.env.REACT_APP_COLOR_TEXT}
                  >
                    {opt.name || ''}
                  </Typography>
                  <Typography
                    className={`col-4 d-flex justify-content-center align-items-center pl-0 ${classes.tableData}`}
                    variant="bodySemiBold"
                  >
                    {opt.value ? `${addCommasToNumber(opt.value)}` : '0'}
                  </Typography>
                  <Typography
                    className={`col-4 h-100 d-flex justify-content-center align-items-center pl-0 ${classes.tableData}`}
                    variant="bodySemiBold"
                  >
                    {opt.value ? `${((opt.value * 100) / item.sum).toFixed(2)} %` : '0%'}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        ))}
        { Array.isArray(data)
        && (
        <div className={`mt-5 mb-4 d-flex justify-content-end align-items-center ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
          <Pagination
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={(value) => handleChangePage(value)}
            handleRowsPerPageChange={(value) => setRowsPerPage(value)}
            dataLength={data.length}
          />
        </div>
        )}
      </div>

      {/* <div className={`row d-flex flex-column m-3 mb-0 ${classes.responsiveMode}`}>
        <AutoComplete
          className="mt-2 mb-3"
          options={initialChoiceData}
          value={choice}
          onChange={setChoice}
          optionTitle="name"
          optionId="value"
        />
        { data && data.map((item, id) => (
          <div key={`SURVEY_REPORT_${item.title} - ${String(id)}`}>
            <Typography
              className={`row w-100 d-flex align-items-center ${id === 0 ? 'mt-3' : 'mt-5'}
              ${classes.tableData} ${classes.tableBorder} ${classes.tablePadding}`}
              variant="body"
            >
              {item.title || ''}
            </Typography>

            { item.answers && item.answers.map((ans, index) => (
              <div
                key={`SURVEY_REPORT_sub_${ans.name} - ${String(index)}`}
                className={`row w-100 d-flex align-items-center pl-3 ${classes.tableData}
                ${index === item.answers.length - 1 && id === data.length - 1 ? null : classes.tableBorder}`}
              >
                <Typography
                  className={`col-6 d-flex align-items-center ${classes.tableData}`}
                  variant="bodySemiBold"
                  color={process.env.REACT_APP_COLOR_TEXT}
                >
                  {ans.name || ''}
                </Typography>

                <div className="col-6 h-100 d-flex justify-content-center align-items-center">
                  <Typography
                    variant="bodySemiBold"
                  >
                    {choice.value === 'user_persentage'
                      ? `${ans[choice.value]} %`
                      : addCommasToNumber(ans[choice.value])}
                  </Typography>
                </div>

              </div>
            ))}
          </div>
        ))}
      </div> */}
    </>
  );
};

export default injectIntl(SurveyDetail);
