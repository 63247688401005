const actionTypes = {
  // Material report actionTypes for setting loading status
  SET_MATERIAL_REPORT_LOADING_ACTION: 'SET_MATERIAL_REPORT_LOADING_ACTION',

  // Material report actionTypes for fetching report data
  GET_MATERIAL_REPORT_REQUEST: 'GET_MATERIAL_REPORT_REQUEST',
  GET_MATERIAL_REPORT_RECEIVE: 'GET_MATERIAL_REPORT_RECEIVE',
  GET_MATERIAL_REPORT_FAIL: 'GET_MATERIAL_REPORT_FAIL',

  // Set Report type
  SET_REPORT_TYPE: 'SET_REPORT_TYPE'
};

export default actionTypes;
