import React from 'react';
import { injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import { toAbsoluteUrl } from '../../../../_metronic';


import { Portlet, PortletHeader } from '../../../../_metronic/partials/content/Portlet';
import { Typography, CustomButton } from '../../components';


const useStyles = makeStyles({
  container: {
    width: 900,
    overflow: 'hidden',
    position: 'relative'
  },
  bodyContainer: {
    display: 'flex',
    margin: '28px 10px',
  },
  sponsorText: {
    fontFamily: 'inherit',
    fontStyle: 'italic',
    fontSize: 'inherit',
    color: 'inherit',
  },
  marketingAssetsButton: {
    position: 'absolute',
    width: 200,
    height: 50,
    top: 155,
    right: 25
  },
});

const SponsorBanner = ({ intl }) => {
  const classes = useStyles();
  const googleAnalyticsEvent = () => {
    window.gtag('event', 'Sonoco Marketing Assets Button Clicked', {
      'event_category': 'User',
      'event_label': 'Sonoco Marketing Assets Button Clicked',
    });
  }

  return (
    <Portlet style={{ position: 'relative', width: '100%' }}>
      <PortletHeader title={(
        <div className="d-flex align-items-center">
          <Typography className="mr-4" variant="h1">
            {intl.formatMessage({ id: 'DASHBOARD.SPIRAL.CARDBOARD.CANS' })}
          </Typography>
        </div>
      )}
      />
      <div className={`${classes.bodyContainer}`}>
        <img
          alt="Sonoco Logo"
          src={toAbsoluteUrl('/media/logos/Sonoco-Logo.png')}
          height={112.50}
          width={'auto'}
        />
        <Typography
          style={{ fontSize: '13px' }}
          variant="bodySemibold"
          className="mb-4"
        >
          Access materials and information about the recyclability of Spiral Cardboard Cans.
          <pre className={`${classes.sponsorText}`}>
            Sponsored by Sonoco
          </pre>
        </Typography>
        <a target="_blank" href={'https://recyclingcalendars-my.sharepoint.com/:f:/g/personal/jgalad_recyclecoach_com/EgnoY75NNH1JimhGqSWahuoB-IoVqjYL-LAioDpZHMyFrg?e=pxTThN'} rel="noreferrer">
          <CustomButton
            paddingVertical={4}
            className={classes.marketingAssetsButton}
            variant={'contained'}
            label={'Marketing Assets'}
            onClick={() => { googleAnalyticsEvent(); }}
          />
        </a>
      </div>

    </Portlet>
  );
};

export default injectIntl(SponsorBanner);
