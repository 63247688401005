import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Dropdown } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, CustomButton } from '../../components';
import * as actions from '../redux/actions';

const useStyles = makeStyles(() => ({
  dropdown: {
    '& button': {
      padding: 0
    }
  },
  dropdownIcon: {
    width: '100%',
    '&::after': {
      display: 'none !important'
    },
  },
  dropdownMenu: {
    width: '100%',
    borderRadius: 0,
    maxHeight: 300,
    overflow: 'auto',
    '& span': {
      justifyContent: 'flex-start'
    }
  },
  dropdownItem: {
    padding: '5px 15px !important',
  },
}));

const stripTags = (data) => (data === null ? data : data.replace(/(<([^>]+)>)/ig, ''));

const SetupWizardAddressSetup = ({
  intl,
  newDepot,
  setNewDepotAction,
  getAddressRequest,
  suggestAddress
}) => {
  const handleChange = (data, propertyName) => {
    setNewDepotAction({
      data,
      propertyName
    });
  };
  const classes = useStyles();
  const [show, setShow] = useState(true);

  return (
    <div
      id="wizard-content1"
      className="w-100 d-flex flex-column justify-content-start align-items-start"
    >
      <Typography variant="h2">
        {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.ADDRESS_SETUP' })}
      </Typography>

      <Typography className="mt-3" variant="bodySemiBold">
        {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.WHAT_IS_ADDRESS' })}
      </Typography>
      <div className="w-100">
        <Dropdown
          className={classes.dropdown}
          drop="down"
          show
        >
          <Dropdown.Toggle
            variant="transparent"
            className={classes.dropdownIcon}
          >
            <TextField
              label=""
              className="mt-3"
              onChange={(e) => {
                setShow(true);
                handleChange(e, 'address');
                getAddressRequest(e);
              }}
              value={stripTags(newDepot.address)}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className={`dropdown-menu-fit dropdown-menu-right ${classes.dropdownMenu}`}>
            { !!suggestAddress.length
            && show
            && suggestAddress.map((item) => (
              <CustomButton
                key={item.formatted_address}
                className={classes.dropdownItem}
                onClick={() => {
                  setShow(false);
                  handleChange(item.formatted_address, 'address');
                  handleChange(`(${item.geometry.location.lat},${item.geometry.location.lng})`, 'geoLocation');
                }}
                label={(
                  <Typography variant="bodySemiBold">
                    {item.formatted_address}
                  </Typography>
              )}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

    </div>
  );
};

const mapStateToProps = (store) => ({
  categoryList: store.depot.categoryList,
  addDepot: store.depot.addDepot,
  newDepot: store.depot.newDepot,
  suggestAddress: store.depot.suggestAddress,
});

export default injectIntl(
  connect(mapStateToProps, actions)(SetupWizardAddressSetup)
);
