/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

import { Portlet, PortletBody, PortletHeader } from '../../../../_metronic/partials/content/Portlet';
import { CloseIcon } from '../../../assets/icons';
import {
  Typography, IconButton, CustomButton, Dialog
} from '../../components';
import SetupWizardEventDetails from './SetupWizardEventDetails';
import SetupWizardLocationDetails from './SetupWizardLocationDetails';
import SetupWizardPreview from './SetupWizardPreview';
import * as actions from '../redux/actions';
import DefaultDaysOfTheWeek from '../helpers';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'max-content',
    backgroundColor: `${process.env.REACT_APP_COLOR_PRIMARY_BG}`,
  },
  header: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_PRIMARY_LIGHT} !important`,
  },
  wrapper: {
    [theme.breakpoints.down('800')]: {
      flexDirection: 'column !important'
    },
  },
  button: {
    width: '15%',
    [theme.breakpoints.down('1140')]: {
      width: '20%',
    },
    [theme.breakpoints.down('940')]: {
      width: '25%',
    },
    [theme.breakpoints.down('800')]: {
      width: '100%',
      marginTop: '10px'
    },
  },
  saveButton: {
    width: '25%',
    [theme.breakpoints.down('1340')]: {
      width: '30%',
    },
    [theme.breakpoints.down('1140')]: {
      width: '35%',
    },
    [theme.breakpoints.down('940')]: {
      width: '40%',
    },
    [theme.breakpoints.down('800')]: {
      width: '100%',
    },
  },
  progressTile: {
    minWidth: '3%',
    maxWidth: '18%',
    height: 5,
    margin: 10,
    marginTop: 30,
    borderRadius: 5,
    backgroundColor: process.env.REACT_APP_COLOR_GRAY_LIGHT,
  },
  currentProgressTile: {
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY,
  },
}));

const stripTags = (data) => (data === null ? data : data.replace(/(<([^>]+)>)/ig, ''));

const SetupWizard = ({
  intl,
  locality,
  eventWizardStage,
  eventWizardId,
  eventList,
  setWizardStageAction,
  setEventWizardIdAction,
  createEventRequest,
  editEventRequest,
}) => {
  const classes = useStyles();
  const pageCount = 3;
  const pages = [...Array(pageCount).keys()].map((item) => item + 1);
  const [eventName, setEventName] = useState('');
  const [eventDates, setEventDates] = useState([{ date: null }]);
  const [eventDescription, setEventDescription] = useState('');
  const [isListedOnCalendar, setIsListedOnCalendar] = useState(false);
  const [eventLocations, setEventLocations] = useState([{
    event_loc_admin_nm: '',
    display_addr: '',
    geo_loc: '',
    isManual: false,
    phone_nbr: '',
    alt_phone_nbr: '',
    email_addr: '',
    web_addr: '',
    daysOfTheWeek: DefaultDaysOfTheWeek,
    drop_event_loc_hours: [...Array(7).keys()].map((item) => (
      {
        dow_nbr: item,
        hours_lower_tm: '09:00',
        hours_upper_tm: '17:00',
        add_hours_lower_tm: null,
        add_hours_upper_tm: null
      }
    ))
  }]);

  useEffect(() => {
    if (eventWizardId) {
      const event = eventList.find((item) => item.drop_event_id === eventWizardId);
      setEventName(event.event_nm);
      setEventDates(event.event_dates || [{ date: null }]);
      setEventDescription(event.event_details);
      setEventLocations(event.drop_event_loc_ids
        ? event.drop_event_loc_ids.map((item) => (
          {
            ...item,
            display_addr: stripTags(item.display_addr),
            daysOfTheWeek: DefaultDaysOfTheWeek.map((day, index) => ({
              ...day,
              value: item.drop_event_loc_hours
                .filter((time) => String(time.dow_nbr) === String(index)).length
                && !!item.drop_event_loc_hours
                  .filter((time) => String(time.dow_nbr) === String(index))[0].hours_lower_tm
            })),
            drop_event_loc_hours: [...Array(7).keys()].map((hours) => {
              if (
                item.drop_event_loc_hours
                  .filter((time) => String(time.dow_nbr) === String(hours)).length
                    && !!item.drop_event_loc_hours
                      .filter((time) => String(time.dow_nbr) === String(hours))[0].hours_lower_tm
              ) {
                return item.drop_event_loc_hours
                  .filter((time) => String(time.dow_nbr) === String(hours))[0];
              }
              return (
                {
                  dow_nbr: hours,
                  hours_lower_tm: '09:00',
                  hours_upper_tm: '17:00',
                  add_hours_lower_tm: null,
                  add_hours_upper_tm: null
                }
              );
            })
          }
        )) : {
          event_loc_admin_nm: '',
          geo_loc: '',
          isManual: false,
          phone_nbr: '',
          alt_phone_nbr: '',
          email_addr: '',
          web_addr: '',
          daysOfTheWeek: DefaultDaysOfTheWeek,
          drop_event_loc_hours: [...Array(7).keys()].map((item) => (
            {
              dow_nbr: item,
              hours_lower_tm: '09:00',
              hours_upper_tm: '17:00',
              add_hours_lower_tm: null,
              add_hours_upper_tm: null
            }
          ))
        });
    }
  }, [eventWizardId]);

  const handleClick = () => {
    if (eventWizardStage < pageCount && !eventWizardId) {
      setWizardStageAction(eventWizardStage + 1);
    } else {
      setWizardStageAction(0);

      const tempEventLocations = eventLocations.map((item) => (
        {
          event_loc_admin_nm: item.event_loc_admin_nm,
          display_addr: item.display_addr,
          geo_loc: item.geo_loc,
          isManual: item.isManual,
          phone_nbr: item.phone_nbr,
          alt_phone_nbr: item.alt_phone_nbr,
          email_addr: item.email_addr,
          web_addr: item.web_addr,
          drop_event_loc_hours: item.drop_event_loc_hours.filter((time, index) => {
            if (item.daysOfTheWeek[index].value) {
              return time;
            }
            return null;
          })
        }
      ));

      if (eventWizardId) {
        editEventRequest({
          id: eventWizardId,
          locality: locality.cms_locality_id,
          name: eventName,
          dates: eventDates,
          description: eventDescription,
          locations: tempEventLocations,
        });
        setEventWizardIdAction(null);
      } else {
        createEventRequest({
          locality: locality.cms_locality_id,
          name: eventName,
          dates: eventDates,
          description: eventDescription,
          locations: tempEventLocations,
        });
      }
    }
  };

  return (
    <Portlet className={classes.container}>
      <PortletHeader
        className={classes.header}
        toolbar={(
          <div className="w-100 d-flex justify-content-between align-items-center">
            <Typography variant="h1">
              {intl.formatMessage({ id: 'EVENT.SETUP_WIZARD.TITLE' })}
            </Typography>

            <Dialog
              button={(
                <IconButton tooltip={intl.formatMessage({ id: 'GENERAL.CLOSE' })}>
                  <CloseIcon />
                </IconButton>
              )}
              title={intl.formatMessage({ id: 'GENERAL.EXIT' })}
              content={(
                <Typography variant="body">
                  {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.EXIT_TEXT' })}
                </Typography>
              )}
              handleSubmit={() => {
                setWizardStageAction(0);
                setEventWizardIdAction(null);
              }}
              submitText={intl.formatMessage({ id: 'GENERAL.EXIT' })}
              cancelText={intl.formatMessage({ id: 'GENERAL.DO_NOT_EXIT' })}
            />
          </div>
        )}
      />
      <PortletBody className="pt-3 w-100 h-100">
        <div className="w-100 h-100 d-flex flex-column justify-content-between align-items-start">
          {
            eventWizardStage === 1 && (
              <SetupWizardEventDetails
                name={eventName}
                handleNameChange={setEventName}
                dates={eventDates}
                handleDatesChange={setEventDates}
                description={eventDescription}
                handleDescriptionChange={setEventDescription}
                calendar={isListedOnCalendar}
                handleCalendarChange={setIsListedOnCalendar}
              />
            )
          }
          {
            eventWizardStage === 2 && (
              <SetupWizardLocationDetails
                locations={eventLocations}
                handleLocationsChange={setEventLocations}
              />
            )
          }
          {
            eventWizardStage === 3 && (
              <SetupWizardPreview
                name={eventName}
                dates={eventDates}
                description={eventDescription}
                locations={eventLocations}
              />
            )
          }

          <div className="w-100 pt-4 d-flex flex-column">
            <div className={`w-100 d-flex flex-row-reverse justify-content-between align-items-center ${classes.wrapper}`}>
              <CustomButton
                className={`${classes.button} ${eventWizardStage === pageCount || eventWizardId ? classes.saveButton : null}`}
                label={
                  eventWizardStage !== pageCount && !eventWizardId
                    ? intl.formatMessage({ id: 'GENERAL.NEXT' })
                    : intl.formatMessage({ id: 'GENERAL.SAVE_CHANGES' })
                }
                variant="contained"
                color="primary"
                size="large"
                disabled={
                  (eventWizardStage === 1
                  && eventName !== null && !eventName.trim().length)
                  || (eventWizardStage === 2
                    && (eventLocations.map(
                      (item) => item.event_loc_admin_nm !== null
                      && !!item.event_loc_admin_nm.trim().length
                    ).includes(false)
                  || eventLocations.map(
                    (item) => item.display_addr !== null
                    && !!item.display_addr.trim().length
                  ).includes(false)
                  || eventLocations.map((item) => item.geo_loc !== '').includes(false)))
                }
                onClick={handleClick}
              />
              {
                eventWizardStage !== 1 && !eventWizardId ? (
                  <div className={`bg-white ${classes.button}`}>
                    <CustomButton
                      label={intl.formatMessage({ id: 'GENERAL.BACK' })}
                      variant="outlined"
                      color="primary"
                      size="large"
                      onClick={() => setWizardStageAction(eventWizardStage - 1)}
                    />
                  </div>
                ) : eventWizardId ? (
                  <Dialog
                    className={`bg-white ${classes.button}`}
                    button={(
                      <CustomButton
                        label={intl.formatMessage({ id: 'GENERAL.EXIT' })}
                        variant="outlined"
                        color="primary"
                        size="large"
                      />
                    )}
                    title={intl.formatMessage({ id: 'GENERAL.EXIT' })}
                    content={(
                      <Typography variant="body">
                        {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.EXIT_TEXT' })}
                      </Typography>
                    )}
                    handleSubmit={() => {
                      setWizardStageAction(0);
                      setEventWizardIdAction(null);
                    }}
                    submitText={intl.formatMessage({ id: 'GENERAL.EXIT' })}
                  />
                )
                  : <div className={classes.button} />
              }
            </div>
            <div className="w-100 d-flex flex-row justify-content-center align-items-center">
              {
                pages.map((item) => (
                  <div
                    key={item}
                    className={`${classes.progressTile} ${item === eventWizardStage && classes.currentProgressTile}`}
                  />
                ))
              }
            </div>
          </div>
        </div>
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  locality: store.common.locality,
  eventWizardStage: store.event.eventWizardStage,
  eventWizardId: store.event.eventWizardId,
  eventList: store.event.eventList,
});

export default injectIntl(connect(mapStateToProps, actions)(SetupWizard));
