import actionTypes from './actionTypes';

// Campaign report actions for setting loading status
export const setCampaignReportLoadingAction = (status) => ({
  type: actionTypes.SET_CAMPAIGN_REPORT_LOADING_ACTION,
  payload: status
});

// Campaign report actions for setting all time mode
export const setCampaignReportAllTimeAction = (toDate) => ({
  type: actionTypes.SET_CAMPAIGN_REPORT_ALL_TIME_ACTION,
  payload: toDate
});

// Campaign report actions for fetching report data
export const campaignReportRequest = ({
  municipality, from, to, county
}) => ({
  type: actionTypes.GET_CAMPAIGN_REPORT_REQUEST,
  payload: {
    municipality, from, to, county
  }
});

export const campaignReportReceive = (data) => ({
  type: actionTypes.GET_CAMPAIGN_REPORT_RECEIVE,
  payload: { data }
});

export const campaignReportFail = (error) => ({
  type: actionTypes.GET_CAMPAIGN_REPORT_FAIL,
  payload: { ...error }
});
