import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { toAbsoluteUrl } from '../../../../_metronic';

/**
 * Each activity has an image
 * We map the images using a literal object
 */
const icons = {
  poll: toAbsoluteUrl('/media/icons/poll.png'),
  quiz: toAbsoluteUrl('/media/icons/poll.png'),
  comic: toAbsoluteUrl('/media/icons/comment.png'),
  announcement: toAbsoluteUrl('/media/icons/align.png'),
  video: toAbsoluteUrl('/media/icons/video-camera.png'),
  'info-graphic': toAbsoluteUrl('/media/icons/graph.png'),
  default: toAbsoluteUrl('/media/icons/photo.png')
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 25,
    marginBottom: 5,
    float: 'left'
  },
  imageStyle: {
    marginRight: 10,
    width: 15,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  textStyle: {
    color: '#c0c0c0',
    fontSize: 13,
    fontWeight: 500
  }
});

const ThumbActivity = ({ activity }) => {
  const iconPath = icons[activity] || icons.default;

  const { container, imageStyle, textStyle } = useStyles();

  return (
    <div className={container}>
      <span
        className={imageStyle}
        style={{ backgroundImage: `url(${iconPath})` }}
      />
      <span className={textStyle}>
        {activity}
      </span>
    </div>
  );
};

export default ThumbActivity;
