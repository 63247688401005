import { put, call, takeLatest } from 'redux-saga/effects';
import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  getHolidays,
  saveHolidays,
  getPerpetualSchedules,
  managePerpetualSchedules,
  getSeasonalList,
  saveSeasonalCollection
} from '../apis';

function* getHolidaysSaga({ payload: { project_id } }) {
  try {
    const response = yield call(getHolidays, project_id);
    if (response) {
      yield put(actions.getHolidaysReceive(response.data));
    } else if (response.error) {
      throw new Error(response.error.message);
    }
  } catch (error) {
    yield put(actions.getHolidaysFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* saveHolidaysSaga({
  payload: {
    project_id, district_id, selectedHolidays, holidayImpacts, multiDistricts
  }
}) {
  try {
    if (multiDistricts.length === 0) {
      multiDistricts.push(`${project_id}#$#${district_id}`);
    }
    const response = yield call(saveHolidays, selectedHolidays, holidayImpacts, multiDistricts);
    if (response && !response.error) {
      yield put(actions.saveHolidaysReceive(response.data));
    } else if (response.error) {
      const err = new Error(response.error.message);
      if (response.data) {
        err.data = response.data;
      }
      throw err;
    }
  } catch (error) {
    if (error.data) {
      if (error.data.data.failedRecords) {
        // remove failed records from multiDistricts
        yield put(actions.removeFromMultiDistricts(error.data.data.failedRecords));
      }
    }

    yield put(actions.saveHolidaysFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* getPerpetualSchedulesSaga({
  payload: {
    districts
  }
}) {
  try {
    const response = yield call(getPerpetualSchedules, districts);
    if (response) {
      yield put(actions.getPerpetualSchedulesReceive(response.data));
    } else if (response.error) {
      throw new Error(response.error.message);
    }
  } catch (error) {
    yield put(actions.getPerpetualSchedulesFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* managePerpetualSchedulesSaga({
  payload: {
    manages_schedules
  }
}) {
  try {
    const response = yield call(managePerpetualSchedules, manages_schedules);
    if (response) {
      yield put(actions.managePerpetualSchedulesReceive(response.data));
    } else if (response.error) {
      throw new Error(response.error.message);
    }
  } catch (error) {
    yield put(actions.managePerpetualSchedulesFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* getSeasonalListSaga({ payload: { multiDistricts } }) {
  try {
    const response = yield call(getSeasonalList, multiDistricts);
    if (response) {
      yield put(actions.getSeasonalListReceive(response.data));
    } else if (response.error) {
      throw new Error(response.error.message);
    }
  } catch (error) {
    yield put(actions.getSeasonalListFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* saveSeasonalCollectionSaga({
  payload: {
    project_id, district_id, col_type_id, season_start_config, season_end_config
  }
}) {
  try {
    const response = yield call(saveSeasonalCollection, project_id, district_id, col_type_id, season_start_config, season_end_config);
    if (response) {
      yield put(actions.saveSeasonalCollectionReceive(response.data));
    } else if (response.error) {
      throw new Error(response.error.message);
    }
  } catch (error) {
    yield put(actions.saveSeasonalCollectionFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

/**
 * Saga watcher function
 */
function* PerpetualScheduleSagas() {
  yield takeLatest(actionTypes.GET_HOLIDAYS_SCHEDULE_REQUEST, getHolidaysSaga);
  yield takeLatest(actionTypes.SAVE_HOLIDAYS_SCHEDULE_REQUEST, saveHolidaysSaga);
  yield takeLatest(actionTypes.GET_PERPETUAL_SCHEDULES_REQUEST, getPerpetualSchedulesSaga);
  yield takeLatest(actionTypes.MANAGE_PERPETUAL_SCHEDULES_REQUEST, managePerpetualSchedulesSaga);
  yield takeLatest(actionTypes.GET_SEASONAL_LIST_REQUEST, getSeasonalListSaga);
  yield takeLatest(actionTypes.SAVE_SEASONAL_COLLECTION_REQUEST, saveSeasonalCollectionSaga);
}

export default PerpetualScheduleSagas;
