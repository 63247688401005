import { put, call, takeLatest } from 'redux-saga/effects';

import actionTypes from './actionTypes';
import * as actions from './actions';
import { editProfile, editProfilePicture } from '../../apis';

function* editProfileSaga({ payload: { user } }) {
  try {
    yield put(actions.setLoadingAction());
    const res = yield call(editProfile, { user });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.editProfileReceive(user));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.editProfileFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

function* editProfilePictureSaga({ payload: { image } }) {
  try {
    yield put(actions.setLoadingAction());
    const res = yield call(editProfilePicture, { image });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.editProfilePictureReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.editProfilePictureFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

/**
 * Saga watcher function
 */
function* profileSagas() {
  yield takeLatest(actionTypes.EDIT_PROFILE_REQUEST, editProfileSaga);
  yield takeLatest(actionTypes.EDIT_PROFILE_PICTURE_REQUEST, editProfilePictureSaga);
}

export default profileSagas;
