/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

import { Portlet, PortletBody, PortletHeader } from '../../../../_metronic/partials/content/Portlet';
import { CloseIcon } from '../../../assets/icons';
import {
  Typography, IconButton, CustomButton, Dialog
} from '../../components';
import SetupWizardDropOffLocation from './SetupWizardDropOffLocation';
import SetupWizardAddressSetup from './SetupWizardAddressSetup';
import SetupWizardContactDetails from './SetupWizardContactDetails';
import SetupWizardOperationHours from './SetupWizardOperationHours';
import SetupWizardPreview from './SetupWizardPreview';
import { validation } from '../../../utils';
import * as actions from '../redux/actions';

const useStyles = makeStyles((theme) => ({
  backgroundColor: {
    backgroundColor: `${process.env.REACT_APP_COLOR_PRIMARY_BG}`,
    height: 'max-content'
  },
  borderBottom: {
    borderBottom: `1px solid ${process.env.REACT_APP_COLOR_PRIMARY} !important`,
  },
  wrapper: {
    [theme.breakpoints.down('800')]: {
      flexDirection: 'column !important'
    },
  },
  button: {
    width: '15%',
    [theme.breakpoints.down('1140')]: {
      width: '20%',
    },
    [theme.breakpoints.down('940')]: {
      width: '25%',
    },
    [theme.breakpoints.down('800')]: {
      width: '100%',
      marginTop: '10px'
    },
  },
  saveBtn: {
    width: '25%',
    [theme.breakpoints.down('1340')]: {
      width: '30%',
    },
    [theme.breakpoints.down('1140')]: {
      width: '35%',
    },
    [theme.breakpoints.down('940')]: {
      width: '40%',
    },
    [theme.breakpoints.down('800')]: {
      width: '100%',
    },
  },
  backBtn: {
    backgroundColor: process.env.REACT_APP_COLOR_SECONDARY,
  },
  square: {
    minWidth: '3%',
    maxWidth: '18%',
    height: '5px',
    margin: '10px',
    marginTop: '30px',
    borderRadius: '5px',
    backgroundColor: process.env.REACT_APP_COLOR_GRAY_LIGHT
  },
  squareBackground: {
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY
  }
}));

const steps = [1, 2, 3, 4, 5];

const SetupWizard = ({
  intl,
  addDepot,
  setAddDepotAction,
  resetNewDepotAction,
  addDepotRequest,
  locality,
  newDepot,
  editDepotRequest,
}) => {
  const classes = useStyles();

  return (
    <Portlet className={`mr-1 ${classes.backgroundColor}`}>
      <PortletHeader
        className={classes.borderBottom}
        toolbar={(
          <div className="w-100 d-flex justify-content-between align-items-center">
            <Typography variant="h1">
              {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.TITLE' })}
            </Typography>

            <Dialog
              button={(
                <IconButton tooltip={intl.formatMessage({ id: 'GENERAL.CLOSE' })}>
                  <CloseIcon />
                </IconButton>
              )}
              title={intl.formatMessage({ id: 'GENERAL.EXIT' })}
              content={(
                <Typography variant="body">
                  {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.EXIT_TEXT' })}
                </Typography>
              )}
              handleSubmit={() => {
                setAddDepotAction(0);
                resetNewDepotAction();
              }}
              submitText={intl.formatMessage({ id: 'GENERAL.EXIT' })}
              cancelText={intl.formatMessage({ id: 'GENERAL.DO_NOT_EXIT' })}
            />
          </div>
        )}
      />
      <PortletBody className="pt-3 w-100 h-100">
        <div className="w-100 h-100 d-flex flex-column justify-content-between align-items-start">
          {addDepot === 1
            && (
              <SetupWizardDropOffLocation />
            )}
          {addDepot === 2
            && <SetupWizardAddressSetup />}
          {addDepot === 3
            && <SetupWizardContactDetails />}
          {addDepot === 4
            && <SetupWizardOperationHours />}
          {addDepot === 5
            && <SetupWizardPreview />}

          <div className="w-100 d-flex flex-column">
            <div className={`w-100 mt-4 d-flex flex-row-reverse justify-content-between align-items-center ${classes.wrapper}`}>
              <CustomButton
                className={`${classes.button} ${addDepot === 5 || newDepot.id ? classes.saveBtn : null}`}
                label={addDepot !== 5 && !newDepot.id
                  ? intl.formatMessage({ id: 'GENERAL.NEXT' })
                  : intl.formatMessage({ id: 'GENERAL.SAVE_CHANGES' })}
                variant="contained"
                color="primary"
                size="large"
                disabled={
                  (newDepot.name !== null && !newDepot.name.trim().length)
                  || (
                    addDepot === 2
                    && newDepot.address !== null && !newDepot.address.trim().length
                    && newDepot.geoLocation !== null && newDepot.geoLocation.trim() === ''
                  )
                  || (
                    addDepot === 3
                    && (!validation(newDepot.phone, 'phone')
                      || !validation(newDepot.altPhone, 'phone')
                      || !validation(newDepot.email, 'email')
                      || !validation(newDepot.website, 'web')))
                  || (
                    addDepot === 4
                    && newDepot.seasons.map(
                      (item) => (item.season_nm !== null && !!item.season_nm.trim().length)
                    ).includes(false)
                  )
                }
                onClick={() => {
                  if (addDepot < 5 && !newDepot.id) {
                    setAddDepotAction(addDepot + 1);
                  } else if (addDepot === 5 || newDepot.id) {
                    let webAddress = newDepot.website;
                    if (webAddress.length) {
                      if (!(webAddress.includes('http://') || webAddress.includes('https://'))) {
                        webAddress = `http://${newDepot.website}`;
                      }
                    }
                    const tempDepot = {
                      locality: locality.cms_locality_id,
                      geoLocation: newDepot.geoLocation || null,
                      adminName: newDepot.name || null,
                      name: newDepot.name || null,
                      details: newDepot.instruction || null,
                      address: newDepot.address || null,
                      phone: newDepot.phone || null,
                      altPhone: newDepot.altPhone || null,
                      email: newDepot.email || null,
                      website: webAddress || null,
                      seasons: newDepot.seasons || null,
                      open24: newDepot.open24 ? 't' : 'f'
                    };
                    if (newDepot.id !== null) {
                      editDepotRequest({
                        ...tempDepot,
                        id: newDepot.id,
                      });
                    } else {
                      addDepotRequest(tempDepot);
                    }
                    setAddDepotAction(0);
                    resetNewDepotAction();
                  }
                }}
              />
              {
                addDepot !== 1 && !newDepot.id ? (
                  <CustomButton
                    className={`${classes.button} ${classes.backBtn}`}
                    label={intl.formatMessage({ id: 'GENERAL.BACK' })}
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={() => setAddDepotAction(addDepot - 1)}
                  />
                ) : newDepot.id ? (
                  <Dialog
                    className={`${classes.button} ${classes.backBtn}`}
                    button={(
                      <CustomButton
                        label={intl.formatMessage({ id: 'GENERAL.EXIT' })}
                        variant="outlined"
                        color="primary"
                        size="large"
                      />
                    )}
                    title={intl.formatMessage({ id: 'GENERAL.EXIT' })}
                    content={(
                      <Typography variant="body">
                        {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.EXIT_TEXT' })}
                      </Typography>
                    )}
                    submitText={intl.formatMessage({ id: 'GENERAL.EXIT' })}

                    handleSubmit={() => {
                      setAddDepotAction(0);
                      resetNewDepotAction();
                    }}
                  />
                )
                  : <div className={classes.button} />
              }
            </div>
            <div className="w-100 d-flex flex-row justify-content-center align-items-center">
              {
                steps.map((item, index) => (
                  <div
                    key={`steps_${String(index)}`}
                    className={`${classes.square} ${item === addDepot ? classes.squareBackground : null}`}
                  />
                ))
              }
            </div>
          </div>
        </div>
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  categoryList: store.depot.categoryList,
  addDepot: store.depot.addDepot,
  newDepot: store.depot.newDepot,
  locality: store.common.locality,
});

export default injectIntl(connect(mapStateToProps, actions)(SetupWizard));
