/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';

import { Typography } from '../../components';
import {
  PositiveIcon,
  NegativeIcon,
  NeutralIcon
} from '../../../assets/icons';
import { addCommasToNumber } from '../../../utils';
import { SummaryCellLoading } from '../../components/loading';

const useStyles = makeStyles((theme) => ({
  content: {
    borderRight: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    [theme.breakpoints.down('1200')]: {
      borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
      paddingBottom: 10,
    },
    [theme.breakpoints.down('992')]: {
      borderRight: 'none',
    },
  },
  title: {
    minHeight: 30,
  },
  data: {
    minHeight: 80,
  },
  positive: {
    color: process.env.REACT_APP_COLOR_POSITIVE,
  },
  negative: {
    color: process.env.REACT_APP_COLOR_NEGATIVE,
  },
  neutral: {
    color: process.env.REACT_APP_COLOR_NEUTRAL
  },
  icon: {
    width: 60,
    height: 50,
  },
}));

const SummaryCurrentPeriod = ({
  intl,
  type,
  title,
  data,
  description = intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_PERIOD' }),
  isLoading = true,
}) => {
  const classes = useStyles();
  const isPositive = type === 'positive';
  const isNegative = type === 'negative';
  const isNeutral = type === 'neutral';
  const icon = isPositive
    ? <PositiveIcon className={`align-self-start ${classes.icon}`} />
    : isNegative ? <NegativeIcon className={`align-self-center ${classes.icon}`} />
      : <NeutralIcon className={`align-self-center ${classes.icon}`} />;

  return (
    <div className={`col-12 col-lg-6 col-xl-3 ${classes.content}`}>
      {isLoading ? (
        <SummaryCellLoading />
      ) : (
        <div className="d-flex flex-column align-items-between">
          <Typography className={classes.title} variant="h2">
            {title}
          </Typography>
          <div className={`d-flex align-items-center ${classes.data}`}>
            <Typography
              className={clsx({
                'mr-5': true,
                [classes.positive]: isPositive,
                [classes.negative]: isNegative,
                [classes.neutral]: isNeutral,
              })}
              variant="numberLarge"
            >
              {addCommasToNumber(data)}
            </Typography>
            {icon}
          </div>
          <Typography variant="h2">
            {description}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default injectIntl(SummaryCurrentPeriod);
