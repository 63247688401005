const actionTypes = {
  // Auth actionTypes for setting loading status
  SET_LOADING: 'SET_LOADING',
  RESET_LOADING: 'RESET_LOADING',

  // Login As actionTypes
  LOGIN_AS_REQUEST: 'LOGIN_AS_REQUEST',
  LOGIN_AS_RECEIVE: 'LOGIN_AS_RECEIVE',
  LOGIN_AS_FAIL: 'LOGIN_AS_FAIL',
};

export default actionTypes;
