import React from 'react';
import { FormattedMessage } from 'react-intl';

const daysOfTheWeek = [
  {
    name: <FormattedMessage id="GENERAL.SUNDAY" />,
    value: false
  },
  {
    name: <FormattedMessage id="GENERAL.MONDAY" />,
    value: false
  },
  {
    name: <FormattedMessage id="GENERAL.TUESDAY" />,
    value: false
  },
  {
    name: <FormattedMessage id="GENERAL.WEDNESDAY" />,
    value: false
  },
  {
    name: <FormattedMessage id="GENERAL.THURSDAY" />,
    value: false
  },
  {
    name: <FormattedMessage id="GENERAL.FRIDAY" />,
    value: false
  },
  {
    name: <FormattedMessage id="GENERAL.SATURDAY" />,
    value: false
  },
];

export { daysOfTheWeek as default };
