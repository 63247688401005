import cts from '../../../../crud/cts';

const getEducationBlogsLibraryData = ({
  project_id,
  district_id
}) => cts(
  'get',
  `blogs-library?project_id=${project_id}&district_id=${district_id}`
);

export { getEducationBlogsLibraryData, getEducationBlogsLibraryData as default };
