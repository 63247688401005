const initialStore = {
  blogDetailsLoading: true,
  blogDetailsError: null,
  responseStatus: null,
  blogReadError: null,
  blogFeedbackError: null,
  blogFeedbackLoading: null
};

export default initialStore;
