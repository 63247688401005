import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as sharedActions from '../../../../redux/actions';
import * as actions from '../redux/actions';
import { InfoIcon } from '../../../../../assets/icons';
import { Typography } from '../../../../components';

const useStyles = makeStyles((theme) => ({
  caseStudy: {
    width: '100%',
    minHeight: 176,
    background: '#F2F3F8',
    margin: '20px 0',
  },
  content: {
    padding: 20,
    display: 'flex',
    gap: 10,
  },
  title: {
    fontWeight: 500,
    fontSize: 18,
    letterSpacing: '0.9px',
    color: '#404040',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    justifyContent: 'space-between',
  },
  downloadText: {
    color: '#0086C2',
    fontWeight: 700,
    fontSize: 13,
    cursor: 'pointer',
  },
  rangeText: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.56,
    textTransform: 'uppercase',
    color: '#6C7293',
    // width: 50,
    // textAlign: "center",
    paddingLeft: 3,
    textAlign: 'left',
  },
  range: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  campaign: {
    minHeight: 52,
    borderRadius: 4,
    background: 'rgba(42, 149, 255, 0.06)',
    boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: 'rgb(244 249 254)',
  },
  campaignText: {
    color: '#0086C2',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.56,
    padding: '0px 10px',
  },
  selectedCampaign: {
    position: 'absolute',
    background: '#FFF',
    borderRadius: 4,
    boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.15)',
    minWidth: 300,
    // minHeight: 188,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    // maxWidth: 320,
    zIndex: 999,
  },
  titleSection: {
    display: 'flex',
    height: 24,
    margin: '15px 5px',
    borderBottom: '1px solid #E7ECEF',
  },
  contentSection: {
    display: 'flex',
    margin: '15px 5px',
    flexDirection: 'column',
    gap: 20,
    position: 'absolute',
    top: 'calc(100% - 2px)',
    left: 0,
    right: 0,
    backgroundColor: '#FFF',
    boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.15)',
    margin: 0,
    padding: 16,
    clipPath: 'inset(0px -12px -12px -12px)',
  },
  contentText: {
    color: 'rgba(64, 64, 64, 0.80)',
    fontSize: 11,
    fontWeight: 400,
    letterSpacing: 0.44,
  },
  viewContentText: {
    color: '#0086C2',
    fontSize: 13,
    fontWeight: 700,
  },
  forwardArrow: {
    fontSize: '1.5rem',
  },
  ganttContainer: {
    minWidth: '700px',
    margin: '0 auto',
    paddingBottom: 40,
  },
  gantt: {
    display: 'grid',
    border: 0,
    position: 'relative',
  },
  ganttRow: {
    display: 'grid',
    '&--empty': {
      backgroundColor: theme.palette.warning.light,
      zIndex: 1,
    },
  },
  ganttRowLines: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'transparent',
    gridTemplateColumns: 'repeat(9, 1fr)',
    '& span': {
      display: 'block',
      borderLeft: 'solid 1px #0000003b',
    },
    '& span.marker': {
      backgroundColor: 'rgba(10, 52, 68, 0.13)',
      zIndex: 2,
    },
    '&:after': {
      gridRow: 1,
      gridColumn: 0,
      backgroundColor: '#1688b345',
      zIndex: 2,
      height: '100%',
    },
  },
  ganttRowMonths: {
    gridTemplateColumns: 'repeat(9, 1fr)',
    '& span': {
      textAlign: 'center',
      fontSize: '13px',
      alignSelf: 'center',
      fontWeight: 'bold',
      paddingBottom: 11,
      paddingTop: 5,
      fontSize: 13,
      // alignSelf: 'center',
      // textAlign: 'center',
      fontWeight: 'bold',
      paddingLeft: 3,
      borderBottom: 'solid 1px #0000003b',
    },
  },
  ganttRowCampaigns: {
    listStyle: 'none',
    display: 'grid',
    padding: '9px 0',
    margin: 0,
    gridTemplateColumns: 'repeat(9, 1fr)',
    gridGap: '8px 0',
    '& li': {
      minHeight: '15px',
      padding: '5px 12px',
      color: '#fff',
      position: 'relative',
      cursor: 'pointer',
      minHeight: 62,
      '&:before, &:after': {
        content: '""',
        height: '100%',
        top: 0,
        zIndex: 4,
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      },
      '&:before': {
        left: 0,
      },
      '&:after': {
        right: 0,
      },
    },
  },
  infoIcon: {
    fill: '#757575',
    // fontSize: 18,
    minWidth: 18,
    minHeight: 18,
    position: 'absolute',
    right: 20,
  },
  dateContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dateHeaderText: {
    color: 'rgba(64, 64, 64, 0.80)',
    fontSize: 10,
    fontWeight: 400,
  },
  dateContentText: {
    color: '#0086C2',
    fontSize: 12,
    fontWeight: 600,
  },
}));

const MajorCampaignRangeSelector = ({
  intl,
  viewContentCallBack,
  majorCampaigns,
  district,
  municipalities,
  getMajorCampaignsDataRequestAction,
  loading,
  setDistrictAction,
}) => {
  const classes = useStyles();
  const [range, setRange] = useState('2023-2024');
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [startDateRange, setStartDateRange] = useState(null);
  const [endDateEnd, setEndDateRange] = useState(null);
  const [months, setMonths] = useState([]);
  const [monthOffSet, setMonthOffSet] = useState(4);

  const history = useHistory();

  useEffect(() => {
    const currentPath = window.location.pathname;
    const pathsToRemove = [
      'wwtk_questions',
      'featured_lessons',
      'learning_modules',
      'spotlight_materials',
      'blogs',
    ];
    let newPath = currentPath;

    pathsToRemove.forEach((path) => {
      if (newPath.endsWith(path)) {
        // Remove the matched path from the URL
        newPath = newPath.substring(0, newPath.length - path.length - 1); // -1 for the slash before the path
      }
    });

    if (newPath !== currentPath) {
      history.push(newPath);
    }
  }, []); // Empty dependency array means this runs once on component mount

  useEffect(() => {
    if (district.district_id === '#all-districts#') {
      setDistrictAction(municipalities[0]);
    } else {
      const [projectId, districtId] = district.district_id.split('#$#');
      if (months.length > 0) {
        getMajorCampaignsDataRequestAction(
          projectId,
          districtId,
          firstDayFirstMonth,
          lastDayLastMonth
        );
      }
    }
  }, [district, months]);

  useEffect(() => {
    generateMonthRange();
  }, []);

  useEffect(() => {
    generateMonthRange();
  }, [monthOffSet]);

  const generateMonthRange = () => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - monthOffSet); // Set the date to 4 months prior to the current month
    const monthRange = Array.from({ length: 9 }, (_, i) => {
      const year = currentDate.getFullYear();
      const month = currentDate.toLocaleString('default', { month: 'short' });
      currentDate.setMonth(currentDate.getMonth() + 1); // Increment month after determining month and year
      return `${month} ${year}`;
    });
    setMonths(monthRange);
  };

  const changeYearHandler = (direction) => {
    if (direction === 'forward') {
      setMonthOffSet(monthOffSet - 3);
    } else if (direction === 'backward') {
      setMonthOffSet(monthOffSet + 3);
    }
  };

  const firstMonth = months[0];
  const lastMonth = months[months.length - 1];

  const formatDate = (date) => date.toISOString().split('T')[0];

  // Parse the first and last months to Date objects
  const firstMonthDate = new Date(`${firstMonth} 1`);
  const lastMonthDate = new Date(`${lastMonth} 1`);

  // Get the first day of the first month
  const firstDayFirstMonth = formatDate(firstMonthDate);

  // Get the last day of the last month
  lastMonthDate.setMonth(lastMonthDate.getMonth() + 1, 0);
  const lastDayLastMonth = formatDate(lastMonthDate);

  const handleSelectCampaign = (campaign) => {
    if (
      selectedCampaign
      && selectedCampaign.major_campaign_id === campaign.major_campaign_id
    ) {
      setSelectedCampaign(null);
    } else {
      setSelectedCampaign(campaign);
    }
  };

  const handleViewContent = (campaign) => {
    viewContentCallBack(campaign);
  };

  let campaigns = [];

  if (majorCampaigns && majorCampaigns.length) {
    const firstMonthDate = moment(months[0], 'MMM YYYY');
    const parseDate = (dateString) => {
      const [year, month, day] = dateString.split('-').map(Number);
      return new Date(year, month - 1, day);
    };

    campaigns = majorCampaigns.map((campaign) => {
      const startDate = moment(campaign.start_dt);
      const endDate = moment(campaign.end_dt);

      // Calculate the start and end months relative to the first month in the months array
      const startMonth = startDate.diff(firstMonthDate, 'months') + 1;
      let endMonth = endDate.diff(firstMonthDate, 'months') + 1;

      // If the endDate is after the middle of the month, add a month to the endMonth calculation
      if (endDate.date() > Math.floor(endDate.daysInMonth() / 2)) {
        endMonth += 1;
      }

      const duration = endMonth - startMonth;

      return {
        gridColumn: `${startMonth}/${endMonth}`,
        text: campaign.major_campaign_nm,
        content: campaign.clients_description,
        track_id: campaign.track_id,
        track_nm: campaign.track_nm,
        track_type: campaign.track_type,
        allocation_code: campaign.allocation_code,
        start_dt: campaign.start_dt,
        end_dt: campaign.end_dt,
        major_campaign_id: campaign.major_campaign_id,
        duration,
      };
    });
  }

  if (campaigns.length) {
    campaigns = JSON.parse(JSON.stringify(campaigns));
    campaigns = campaigns.sort(
      (a, b) => new Date(a.start_dt) - new Date(b.start_dt)
    );
  }

  let groupedCampaigns = campaigns.reduce((acc, campaign) => {
    if (!acc[campaign.track_id]) {
      acc[campaign.track_id] = [];
    }
    acc[campaign.track_id].push(campaign);
    return acc;
  }, {});

  // sort the grouped campaigns by track type
  groupedCampaigns = Object.entries(groupedCampaigns).sort((a, b) => {
    if (a[1][0].track_type < b[1][0].track_type) {
      return 1;
    }
    if (a[1][0].track_type > b[1][0].track_type) {
      return -1;
    }
    return 0;
  });

  const GanttRow = ({ name, campaigns }) => {
    const classes = useStyles();
    return (
      <div
        className={`${classes.ganttRow} ${
          campaigns.length === 0 ? 'gantt__row--empty' : ''
        }`}
      >
        <ul className={classes.ganttRowCampaigns}>
          {campaigns.map((campaign, index) => (
            <li
              key={campaign.major_campaign_id}
              style={{ gridColumn: campaign.gridColumn }}
            >
              {selectedCampaign
              && selectedCampaign.major_campaign_id
                === campaign.major_campaign_id
              && selectedCampaign.track_id === campaign.track_id ? (
                <div className={classes.selectedCampaign}>
                  <div
                    className={classes.titleSection}
                    onClick={() => handleSelectCampaign(campaign)}
                  >
                    <Typography
                      key={index}
                      className={classes.campaignText}
                      variant="body"
                    >
                      {campaign.text}
                    </Typography>
                  </div>
                  <div className={classes.contentSection}>
                    <Typography variant="body" className={classes.contentText}>
                      {campaign.content}
                    </Typography>
                    <div className={classes.dateContainer}>
                      <Typography
                        variant="body"
                        className={classes.dateHeaderText}
                      >
                        Start Date
                      </Typography>
                      <Typography
                        variant="body"
                        className={classes.dateContentText}
                      >
                        {moment(campaign.start_dt).format('MMM YYYY')}
                      </Typography>
                    </div>
                    <div className={classes.dateContainer}>
                      <Typography
                        variant="body"
                        className={classes.dateHeaderText}
                      >
                        Duration
                      </Typography>
                      <Typography
                        variant="body"
                        className={classes.dateContentText}
                        color="rgba(64, 64, 64, 0.80)"
                      >
                        {campaign.duration}
                        {' '}
                        months
                      </Typography>
                    </div>
                    <Typography
                      variant="body"
                      className={classes.viewContentText}
                      onClick={() => handleViewContent(campaign)}
                    >
                      View Content
                      <ArrowForwardIcon className={classes.forwardArrow} />
                    </Typography>
                  </div>
                </div>
                ) : (
                  <div
                    className={classes.campaign}
                    onClick={() => handleSelectCampaign(campaign)}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Typography
                        key={index}
                        className={classes.campaignText}
                        variant="body"
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                        }}
                      >
                        {campaign.text}
                      </Typography>
                      <InfoIcon className={classes.infoIcon} />
                    </div>
                  </div>
                )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const GanttChart = () => {
    const classes = useStyles();
    const ganttRows = [{}];
    ganttRows[0].campaigns = campaigns;

    return (
      <div className={classes.gantt}>
        <div className={`${classes.ganttRow} ${classes.ganttRowMonths}`}>
          {!loading
            && months.map((month, index) => (
              <span key={index}>
                <Typography
                  key={index}
                  className={classes.rangeText}
                  variant="body"
                >
                  {month.toUpperCase()}
                </Typography>
              </span>
            ))}
        </div>
        <div className={`${classes.ganttRow} ${classes.ganttRowLines}`}>
          {!loading
            && groupedCampaigns.map(() => (
              <>
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span style={{ borderRight: '1px solid #0000003b' }} />
              </>
            ))}
        </div>
        {!loading
          && groupedCampaigns.map(([track_id, campaigns]) => (
            <GanttRow key={track_id} name={track_id} campaigns={campaigns} />
          ))}
      </div>
    );
  };

  return (
    <>
      <div className="d-flex mt-1 mb-5 align-items-center">
        <NavigateBeforeIcon
          onClick={() => changeYearHandler('backward')}
          fontSize="large"
          style={{
            color: '#757575',
            transition: 'all .3s',
            cursor: 'pointer',
            opacity: 1,
          }}
        />
        <Typography
          style={{ textAlign: 'center', size: 14 }}
          color="#757575"
          variant="bodySemiBold"
        >
          Previous 3 months - Next 3 months
        </Typography>
        <NavigateNextIcon
          onClick={() => changeYearHandler('forward')}
          fontSize="large"
          style={{
            color: '#757575',
            transition: 'all .3s',
            cursor: 'pointer',
            opacity: 1,
          }}
        />
      </div>
      <div>
        <div className={classes.ganttContainer}>
          <GanttChart />
        </div>
        <Typography
          className="d-flex justify-content-start"
          variant="bodySemi"
        >
          {intl.formatMessage({ id: 'EDUCATION.MAJOR.CAMPAIGNS.MARKETING.SUPPORT' })}
        </Typography>
      </div>
    </>
  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  municipalities: store.common.municipalities,
  majorCampaigns: store.majorCampaigns.major_campaigns,
  loading: store.majorCampaigns.loading,
});

export default injectIntl(
  connect(mapStateToProps, { ...actions, ...sharedActions })(MajorCampaignRangeSelector)
);
