import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { CustomButton } from '../../components';
import * as actions from '../redux/actions';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('500')]: {
      flexDirection: 'column !important',
      justifyContent: 'center',
      alignItems: 'center',
    }
  },
  button: {
    width: '25%',
    [theme.breakpoints.down('1400')]: {
      width: '30%'
    },
    [theme.breakpoints.down('1200')]: {
      width: '20%'
    },
    [theme.breakpoints.down('900')]: {
      width: '25%'
    },
    [theme.breakpoints.down('500')]: {
      width: '100%',
    }
  },
}));

const ButtonSection = ({
  intl,
  scheduleAction,
  disabled,
  cancelAction
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={`d-flex flex-row-reverse mb-3 ${classes.container}`}>
        <div className={`m-1 ${classes.button}`}>
          <CustomButton
            label={intl.formatMessage({ id: 'GENERAL.SCHEDULE' })}
            variant="contained"
            color="primary"
            onClick={scheduleAction}
            fontSize={16}
            disabled={disabled}
          />
        </div>
        <div className={`m-1 ${classes.button}`}>
          <CustomButton
            label={intl.formatMessage({ id: 'GENERAL.CANCEL' })}
            variant="outlined"
            color="primary"
            onClick={cancelAction}
            fontSize={16}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store) => ({
  reminder: store.reminder.newReminder
});

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(ButtonSection)
);
