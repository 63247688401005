const actionTypes = {
  // Waste Types actionTypes for setting loading status
  SET_WASTE_TYPE_LOADING_ACTION: 'SET_WASTE_TYPE_LOADING_ACTION',
  SET_WASTE_TYPE_SNACKBAR_ACTION: 'SET_WASTE_TYPE_SNACKBAR_ACTION',

  // Waste Types actionTypes for fetching all Waste Types
  GET_WASTE_TYPES_LIST_REQUEST: 'GET_WASTE_TYPES_LIST_REQUEST',
  GET_WASTE_TYPES_LIST_RECEIVE: 'GET_WASTE_TYPES_LIST_RECEIVE',
  GET_WASTE_TYPES_LIST_FAIL: 'GET_WASTE_TYPES_LIST_FAIL',

  // Waste Types actionTypes for creating a new Waste Type
  CREATE_WASTE_TYPE_REQUEST: 'CREATE_WASTE_TYPE_REQUEST',
  CREATE_WASTE_TYPE_RECEIVE: 'CREATE_WASTE_TYPE_RECEIVE',
  CREATE_WASTE_TYPE_FAIL: 'CREATE_WASTE_TYPE_FAIL',

  // Waste Types actionTypes for deleting a Waste Type
  DELETE_WASTE_TYPE_REQUEST: 'DELETE_WASTE_TYPE_REQUEST',
  DELETE_WASTE_TYPE_RECEIVE: 'DELETE_WASTE_TYPE_RECEIVE',
  DELETE_WASTE_TYPE_FAIL: 'DELETE_WASTE_TYPE_FAIL',
};

export default actionTypes;
