import React from 'react';
import { injectIntl } from 'react-intl';

import { Typography } from '../../components';

const LocalRequirements = ({ children, intl }) => (
  <div style={{ marginTop: 25 }}>
    <Typography variant="subText">
      {intl.formatMessage({ id: 'CAMPAIGN.ITEM.LOCAL_REQUIREMENTS' })}
      :
    </Typography>
    <Typography variant="subText">
      {children}
    </Typography>
  </div>
);

export default injectIntl(LocalRequirements);
