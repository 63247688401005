import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import OpenWithIcon from '@material-ui/icons/OpenWith';


import { IconButton, Dialog, Typography } from '../../../components';
import { CloseIcon } from '../../../../assets/icons';
import { PopularFeaturesModal } from '../components/index';

import moment from 'moment';


const useStyles = makeStyles((theme) => ({
  group: {
    display: 'flex',
    gap: '10px'
  },
  groupItem: {
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid #E7ECEF',
    borderRadius: 9,
    padding: 5,
    width: 252,
  },
  iconButton: {
    left: '40%'
  },
  engagementText: {
    color: '#404040',
    fontGamily: 'Poppins',
    fontWeight: 400,
    fontSize: 13,
    letterSpacing: '0.04em'
  },
  topSection: {
    display: 'flex',
    margin: '5px',
  },
}));

const PopularFeaturesChart = ({
  intl,
  reportData,
  feature
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);


  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const educationLabels = [];
  let educationDatapointsCurrentYear = [];
  let educationDatapointsLastYear = [];

  let educationDatapointsCurrentYearFull = [];
  let educationDatapointsLastYearFull = [];



  let educationData;
  let educationDataFull;

  let maxValue;


  if (reportData && reportData.popular_feature) {
    // eslint-disable-next-line no-plusplus


    for (let i = 0; i < reportData.popular_feature[feature].length; i++) {
      if (i <= 11) {
        educationDatapointsCurrentYear.push(reportData.popular_feature[feature][i].value);
      } else {
        educationDatapointsLastYear.push(reportData.popular_feature[feature][i].value);
      }
    }

    for (let i = 0; i < reportData.popular_feature[feature].length; i++) {
      if (i <= 11) {
        educationLabels.push(`${month[reportData.popular_feature[feature][i].month - 1]} ${reportData.popular_feature[feature][i].year}`);
        educationDatapointsCurrentYearFull.push(reportData.popular_feature[feature][i].value);
      } else {
        educationDatapointsLastYearFull.push(reportData.popular_feature[feature][i].value);
      }
    }
  }


  if (educationDatapointsCurrentYear.length && educationLabels.length) {
    educationData = {
      labels: educationLabels.reverse().slice(-3),
      datasets: [
        {
          label: 'Last Year',
          data: educationDatapointsLastYear.reverse().slice(-3),
          borderColor: 'rgba(118, 203, 196, 1)',
          borderDash: [4, 4],
          fill: false,
        },
        {
          label: 'This Year',
          data: educationDatapointsCurrentYear.reverse().slice(-3),
          borderColor: 'rgba(42, 149, 255, 1)',
          backgroundColor: (context) => {
            const { ctx } = context.chart;
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, "rgba(42, 149, 255, 0.2)");
            gradient.addColorStop(1, "rgba(42, 149, 255, 0)");
            return gradient;
          },
        },

      ],
    };

    maxValue = Math.round(Math.max(...educationData.datasets[0].data.concat(educationData.datasets[1].data).map(f => Number(f)))/ 10) * 10;


    educationDataFull = {
      labels: educationLabels,
      datasets: [
        {
          label: 'Last Year',
          data: educationDatapointsLastYearFull.reverse(),
          borderColor: 'rgba(118, 203, 196, 1)',
          borderDash: [4, 4],
          fill: false,
        },
        {
          label: 'This Year',
          data: educationDatapointsCurrentYearFull.reverse(),
          borderColor: 'rgba(42, 149, 255, 1)',
          backgroundColor: (context) => {
            const { ctx } = context.chart;
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, "rgba(42, 149, 255, 0.2)");
            gradient.addColorStop(1, "rgba(42, 149, 255, 0)");
            return gradient;
          },
        },

      ],
    };
  }


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          return `${moment(tooltipItem[0].xLabel, 'MMM').format('MMMM')} ${tooltipItem[0].datasetIndex > 0 ? Number(tooltipItem[0].xLabel.substr(4, 7)) : Number(tooltipItem[0].xLabel.substr(4, 7)) -1} `;
          // return `${moment(tooltipItem[0].xLabel, 'MMM').format('MMMM')} ${tooltipItem[0].datasetIndex > 0 ? tooltipItem[0].xLabel.substr(4, 7) : reportYear - 1} `;

        },
        label: function (tooltipItem, data) {
          // return "$" + Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function(c, i, a) {
          //     return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
          // });
          return tooltipItem.yLabel.toLocaleString();
        },
      }
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          callback: function (value, index, ticks) {
            return value.substr(0, 3);
          }
        }
      }],
      yAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          stepSize: Math.round((maxValue / 5) / 10) * 10,
          callback: function (value, index, ticks) {
            return Math.round(value).toLocaleString();
          }
        }
      }]
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  const optionsFull = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          return `${moment(tooltipItem[0].xLabel, 'MMM').format('MMMM')} ${tooltipItem[0].datasetIndex > 0 ? Number(tooltipItem[0].xLabel.substr(4, 7)) : Number(tooltipItem[0].xLabel.substr(4, 7)) -1} `;
          // return `${moment(tooltipItem[0].xLabel, 'MMM').format('MMMM')} ${tooltipItem[0].datasetIndex > 0 ? tooltipItem[0].xLabel.substr(4, 7) : reportYear - 1} `;

        },
        label: function (tooltipItem, data) {
          // return "$" + Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function(c, i, a) {
          //     return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
          // });
          return tooltipItem.yLabel.toLocaleString();
        },
      }
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        gridLines: {
          // display: false
        },
        ticks: {
          callback: function (value, index, ticks) {
            return value.substr(0, 3);
          }
        }
      }],
      yAxes: [{
        gridLines: {
          // display: false,
        },
        ticks: {
          stepSize: 100,
          callback: function (value, index, ticks) {
            return value.toLocaleString();
          }
        }
      }]
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };




  return (
    <>
      <div className="row mt-2">
        <div className="col-12">
          <div className={classes.groupItem}>
            <div className={classes.topSection}>
              <Typography className={classes.engagementText}>
                Engagements
              </Typography>
              <IconButton
                className={classes.iconButton}
                onClick={() => setOpen(true)}
              >
                <OpenWithIcon />
              </IconButton>
            </div>
            <PopularFeaturesModal
              open={open}
              onClose={() => setOpen(false)}
              title={feature.replace(/_/g, ' ').replace(/engagement/g, '')}
              content={(
                <Line options={optionsFull} data={educationDataFull} />
              )}
            />
            <div>
              <Line options={options} data={educationData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(PopularFeaturesChart);
