import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

import { Typography } from '../../components';

const useStyles = makeStyles({
  container: {
    padding: '3px 0'
  },
  contentContainer: {
    padding: '6px 0 0 3px'
  }
});

const Stats = ({
  scheduled,
  participate,
  avgrate,
  intl
}) => {
  const { container, contentContainer } = useStyles();

  return (
    <div className={contentContainer}>
      <table>
        {
          participate
            ? (
              <tr>
                <td className={container}>
                  <Typography variant="subText">
                    {intl.formatMessage({ id: 'CAMPAIGN.ITEM.TOTAL_PARTICIPATION' })}
                    :
                  </Typography>
                </td>
                <td style={{ paddingLeft: 15 }}>
                  <Typography variant="subText">
                    {participate}
                  </Typography>
                </td>
              </tr>
            ) : null
        }
        {
          avgrate
            ? (
              <tr>
                <td className={container}>
                  <Typography variant="subText">
                    {intl.formatMessage({ id: 'CAMPAIGN.ITEM.AVERAGE_RATING' })}
                    :
                  </Typography>
                </td>
                <td style={{ paddingLeft: 15 }}>
                  <Typography variant="subText">
                    {avgrate}
                  </Typography>
                </td>
              </tr>
            ) : null
        }
        {
          scheduled
            ? (
              <tr>
                <td style={{ paddingTop: 7 }} colSpan="2">
                  <Typography variant="subText">
                    {scheduled}
                  </Typography>
                </td>
              </tr>
            ) : null
        }
      </table>
    </div>
  );
};

export default injectIntl(Stats);
