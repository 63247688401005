import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { addCommasToNumber } from '../../../../utils';
import { IconButton, Typography } from '../../../components';
import {
  ExpandIcon,
  CollapseIcon,
} from '../../../../assets/icons';

const useStyles = makeStyles((theme) => ({
  border: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
  },
  value: {
    color: '#3cbacd'
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: '#3cbacd'
  },
  icon: {
    width: 30,
    height: 30
  },
  smallerThanMdHide: {
    [theme.breakpoints.down('md')]: {
      display: 'none !important'
    }
  },
  smallerThanMdShow: {
    [theme.breakpoints.up('md')]: {
      display: 'none !important'
    },
  },
  onlyMd: {
    [theme.breakpoints.only('md')]: {
      display: 'flex !important',
    },
  },
}));

const DetailNewSubscribersSms = ({
  intl,
  data,
  expanded,
  onClick,
  district,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.border}>
      <div className="row d-flex align-items-center pb-4 pr-4 pl-4">
        <div className={`col-1 mr-3 mr-xl-0 ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
          <div className={classes.circle} />
        </div>
        <div className={`col-10 col-xl ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
          <Typography variant="h2">
            {intl.formatMessage({ id: 'REPORT.NEW_SUBSCRIBERS.DETAIL.CATEGORIES.SMS.TITLE' })}
          </Typography>
        </div>
        <div className={`col-11 col-xl-5 mt-3 mt-xl-0 ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
          <Typography className={classes.value} variant="numberLarge">
            {addCommasToNumber(data.total)}
          </Typography>
        </div>
        {(district.district_id === '0' || data.detail) && (
        <div className={`col-1 mt-3 mt-xl-0 ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
          { data.detail && (
          <>
            {expanded
              ? (
                <IconButton onClick={onClick}>
                  <CollapseIcon className={classes.icon} />
                </IconButton>
              )
              : (
                <IconButton onClick={onClick}>
                  <ExpandIcon className={classes.icon} />
                </IconButton>
              )}
          </>
          )}
        </div>
        )}

        <div className={`d-flex w-100 p-0 pt-4 ${classes.smallerThanMdShow}`}>
          <div className={`mr-5 ${classes.circle}`} />
          <div className="row p-0 w-100 justify-content-between">
            <div className="d-flex flex-column justify-content-start">
              <div className="row pl-3">
                <Typography variant="h2">
                  {intl.formatMessage({ id: 'REPORT.NEW_SUBSCRIBERS.DETAIL.CATEGORIES.SMS.TITLE' })}
                </Typography>
              </div>
              <Typography className={classes.value} variant="numberLarge">
                {addCommasToNumber(data.total)}
              </Typography>
            </div>
            {data.detail && (
            <>
              {expanded
                ? (
                  <IconButton onClick={onClick}>
                    <CollapseIcon className={classes.icon} />
                  </IconButton>
                )
                : (
                  <IconButton onClick={onClick}>
                    <ExpandIcon className={classes.icon} />
                  </IconButton>
                )}
            </>
            )}
          </div>
        </div>

      </div>
      {expanded && (
        <div className="row d-flex justify-content-center justify-content-lg-end">
          {data.detail.map((item, index) => (
            <div key={item.title} className={`col-11 row d-flex align-items-start pb-4 pr-4 pl-4 ${index !== 0 ? 'pt-4' : null} ${index !== data.detail.length - 1 ? classes.border : null}`}>
              <div className="col-5 pl-0">
                <Typography variant="h2">
                  {item.title}
                </Typography>
              </div>
              <div className="col ml-4">
                <Typography variant="h2">
                  {addCommasToNumber(item.value)}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
});

export default injectIntl(connect(mapStateToProps, null)(DetailNewSubscribersSms));
