import React, { useState } from 'react';
import Portal from '@material-ui/core/Portal';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Snackbar2 = ({
  message,
  status = 'success',
  closeAction,
  isOpen,
  setIsOpenHandler
}) => {
  const [open, setOpen] = useState(isOpen);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpenHandler(false);
    setOpen(false);
    if (closeAction) {
      closeAction();
    }
  };

  return (
    <Portal>
      {message && (
        <MuiSnackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity={status}
          >
            {message}
          </MuiAlert>
        </MuiSnackbar>
      )}
    </Portal>
  );
};

export default Snackbar2;
