import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';


import {
  Typography, StartAndEndDateSelector, CustomButton
} from '../../../components';
import {
  Portlet,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';

import * as actions from '../redux/actions';
import * as sharedActions from '../../../redux/actions';

import { startAndEndDateInitial } from '../../helper';

const initialState = {
  startDate: '',
  endDate: '',
  specificStartDate: { day: '', month: '' },
  specificEndDate: { day: '', month: '' },
  specificStartWeek: { week: '', month: '' },
  specificEndWeek: { week: '', month: '' },
  itHasAlreadyStarted: '',
  itHasAlreadyEnded: '',
  whenWillItStart: '',
  whenWillItEnd: '',
  approximateStartDate: '',
  approximateEndDate: '',
  otherStartDate: '',
  otherEndDate: '',
  canChangeEachYearStart: '',
  canChangeEachYearEnd: '',
};

const useStyles = makeStyles((theme) => ({
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: '50%',
    margin: '20px 0px'
  },
  button: {
    width: 150,
    height: 48,
    marginTop: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 20,
    gap: 20
  },
}));

const SeasonalCollections = ({
  intl,
  seasonalList,
  setCurrentCollection,
  currentCollection,
  saveSeasonalCollectionRequest,
  setCompletedSetup,
  updateSeasonalCollection,
}) => {
  const classes = useStyles();
  const [data, setData] = useState(initialState);
  const [isValid, setIsValid] = useState();

  const [showContent, setShowContent] = useState(true);
  const [title, setTitle] = useState('');
  const [seasonalCollection, setSeasonalCollection] = useState();
  const history = useHistory();


  useEffect(() => {
    if (seasonalList?.seasonal_list) {
      setCurrentCollection(seasonalList.seasonal_list[0]);
    }
  }, []);

  useEffect(() => {
    if (currentCollection) {
      const collection = seasonalList?.seasonal_list.find((i) => i.col_type_id === currentCollection.col_type_id && i.district_id === currentCollection.district_id);
      if (collection) {
        if (collection.season_start_config && Object.keys(collection.season_start_config).length && collection.season_start_config !== '{}' && collection.season_end_config && Object.keys(collection.season_end_config).length && collection.season_end_config !== '{}') {
          const startConfig = typeof collection.season_start_config === 'string'
            ? JSON.parse(collection.season_start_config)
            : collection.season_start_config;

          const endConfig = typeof collection.season_end_config === 'string'
            ? JSON.parse(collection.season_end_config)
            : collection.season_end_config;
          setData({
            ...startConfig,
            ...endConfig,
          });
        } else {
          setData(initialState);
        }
        setTitle(collection.col_type_title);
        setSeasonalCollection(collection);
      }
    }
  }, [currentCollection]);


  const goNextHandler = async () => {
    const season_start_config = {
      approximateStartDate: data.approximateStartDate,
      canChangeEachYearStart: data.canChangeEachYearStart,
      itHasAlreadyStarted: data.itHasAlreadyStarted,
      otherStartDate: data.otherStartDate,
      specificStartDate: data.specificStartDate,
      specificStartWeek: data.specificStartWeek,
      startDate: data.startDate,
      whenWillItStart: data.whenWillItStart,
    };
    const season_end_config = {
      approximateEndDate: data.approximateEndDate,
      canChangeEachYearEnd: data.canChangeEachYearEnd,
      itHasAlreadyEnded: data.itHasAlreadyEnded,
      otherEndDate: data.otherEndDate,
      specificEndDate: data.specificEndDate,
      specificEndWeek: data.specificEndWeek,
      endDate: data.endDate,
      whenWillItEnd: data.whenWillItEnd,
    };

    const itemsWithSameColTypeId = seasonalList.seasonal_list.filter(
      (item) => item.col_type_id === currentCollection.col_type_id
    );

    // Save the seasonal collection for all collections with the same col_type_id
    itemsWithSameColTypeId.forEach((item) => {
      saveSeasonalCollectionRequest(
        seasonalCollection.project_id,
        item.district_id,
        item.col_type_id,
        season_start_config,
        season_end_config
      );
    });

    // Update the seasonal collection
    updateSeasonalCollection({ col_type_id: currentCollection.col_type_id, season_start_config, season_end_config });

    // Find the index of the current collection
    const index = seasonalList.seasonal_list.findIndex(
      (i) => i.col_type_id === currentCollection.col_type_id && i.district_id === currentCollection.district_id
    );

    // Find the next item that has a different col_type_id
    let nextIndex = index + 1;
    while (
      nextIndex < seasonalList.seasonal_list.length &&
      seasonalList.seasonal_list[nextIndex].col_type_id === currentCollection.col_type_id
    ) {
      nextIndex++;
    }

    if (nextIndex >= seasonalList.seasonal_list.length) {
      setCompletedSetup(true);
      setTimeout(() => {
        history.push('/schedules?completedSetup=true');
      }, 500);
    } else {
      setCurrentCollection(seasonalList.seasonal_list[nextIndex]);
      setData(null);
    }
  };
  const goBackHandler = () => {
    const index = seasonalList.seasonal_list.findIndex((i) => i.col_type_id === currentCollection.col_type_id && i.district_id === currentCollection.district_id);
    if (index === 0) {
      history.push('/schedules');
    } else {
      setCurrentCollection(seasonalList.seasonal_list[index - 1]);
      setData(null);

    }
  };

  if (!showContent) return null;

  return (
    <>
      <div className="d-flex flex-wrap mt-3">
        <Portlet>
          <PortletBody className="d-flex justify-content-center" style={{ margin: '50px 10px' }}>
            <Typography variant="h1" style={{ fontSize: 24, fontWeight: 700 }}>{title}</Typography>

            <Typography variant="h3" style={{ margin: '10px 0 20px 0', fontSize: 16, fontWeight: 600 }}>
              Seasonal Collections – Start/Stop dates
              {' '}
            </Typography>
            {
              data && currentCollection && (
                <StartAndEndDateSelector
                  key={currentCollection}
                  initialState={data}
                  setStartAndEndDateCb={(val) => {
                    if (val.data) {
                      setData(val.data);
                    }
                    setIsValid(val.isValid);
                  }}
                />
              )
            }
            <div className={classes.buttonContainer}>
              <CustomButton
                variant="outlined"
                size="large"
                label={(
                  <Typography variant="h1" color={process.env.REACT_APP_COLOR_PRIMARY}>
                    Back
                  </Typography>
                )}
                onClick={() => goBackHandler()}
                className={classes.button}
              />
              <CustomButton
                variant="contained"
                size="large"
                label={(
                  <Typography variant="h1" color="white">
                    Continue
                  </Typography>
                )}
                onClick={() => goNextHandler()}
                className={classes.button}
                disabled={!isValid}
              />
            </div>
          </PortletBody>
        </Portlet>
      </div>
    </>
  );
};
const mapStateToProps = (store) => ({
  seasonalList: store.perpetualSchedules.seasonalList,
  currentCollection: store.perpetualSchedules.currentCollection,
});

export default injectIntl(connect(mapStateToProps, { ...actions, ...sharedActions })(SeasonalCollections));
