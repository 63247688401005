import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, TimePicker, CustomButton } from '../../components';
import { PlusCircleIcon } from '../../../assets/icons';
import * as actions from '../redux/actions';

const useStyles = makeStyles(() => ({
  textButton: {
    width: 'max-content',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
}));

const SetupWizardLocationDetailsLocationHours = ({
  intl,
  title = 'title',
  data,
  onChangeFirst,
  onChangeSecond,
  onChangeAdditionalFirst,
  onChangeAdditionalSecond
}) => {
  const classes = useStyles();
  const [additionalHours, setAdditionalHours] = useState(false);

  return (
    <>
      <Typography variant="body" className="mt-2">
        {title}
      </Typography>
      <div className="d-flex">
        <div className="d-flex w-50">
          <TimePicker
            label={intl.formatMessage({ id: 'GENERAL.OPEN' })}
            value={data && data.hours_lower_tm ? `2019-01-01T${data.hours_lower_tm}` : null}
            onChange={onChangeFirst}
          />

          <TimePicker
            className="ml-2 mr-1"
            label={intl.formatMessage({ id: 'GENERAL.CLOSED' })}
            value={data && data.hours_upper_tm ? `2019-01-01T${data.hours_upper_tm}` : null}
            onChange={onChangeSecond}
          />
        </div>
        <div className="d-flex w-50">
          {
            additionalHours || (data && data.add_hours_lower_tm)
              ? (
                <>
                  <TimePicker
                    className="ml-1"
                    label={intl.formatMessage({ id: 'GENERAL.OPEN' })}
                    value={data && data.add_hours_lower_tm ? `2019-01-01T${data.add_hours_lower_tm}` : null}
                    onChange={onChangeAdditionalFirst}
                  />

                  <TimePicker
                    className="ml-2"
                    label={intl.formatMessage({ id: 'GENERAL.CLOSED' })}
                    value={data && data.add_hours_upper_tm ? `2019-01-01T${data.add_hours_upper_tm}` : null}
                    onChange={onChangeAdditionalSecond}
                  />
                </>
              ) : (
                <CustomButton
                  className={classes.textButton}
                  label={(
                    <div className="d-flex justify-content-start align-items-center">
                      <PlusCircleIcon className="mr-2" color={process.env.REACT_APP_COLOR_PRIMARY} />
                      <Typography variant="h2" color={process.env.REACT_APP_COLOR_PRIMARY}>
                        {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.HOURS.ADD.ADDITIONAL_HOURS' })}
                      </Typography>
                    </div>
                  )}
                  onClick={() => { setAdditionalHours(!additionalHours); }}
                />
              )
          }
        </div>
      </div>
    </>
  );
};

export default injectIntl(connect(null, actions)(SetupWizardLocationDetailsLocationHours));
