import actionTypes from './actionTypes';

// waste and recycling rules actions for setting loading status
export const setRuleLoadingAction = (status) => ({
  type: actionTypes.SET_WASTE_RULES_LOADING_ACTION,
  payload: status
});

// waste and recycling rules actions for fetching data
export const wasteAndRulesRequest = ({ cms_locality_id }) => ({
  type: actionTypes.GET_WASTE_RULES_REQUEST,
  payload: { cms_locality_id }
});

export const wasteAndRulesReceive = (data) => ({
  type: actionTypes.GET_WASTE_RULES_RECEIVE,
  payload: { data }
});

export const wasteAndRulesFail = (error) => ({
  type: actionTypes.GET_WASTE_RULES_FAIL,
  payload: { ...error }
});


// waste and recycling rules actions for editing data
export const editWasteAndRulesRequest = ({
  cms_locality_id,
  waste_recycling_rule_id,
  rip_,
  waste_recycling_rule_value
}) => ({
  type: actionTypes.EDIT_WASTE_RULES_REQUEST,
  payload: {
    cms_locality_id,
    waste_recycling_rule_id,
    rip_,
    waste_recycling_rule_value
  }
});

export const editWasteAndRulesReceive = (data) => ({
  type: actionTypes.EDIT_WASTE_RULES_RECEIVE,
  payload: { data }
});

export const editWasteAndRulesFail = (error) => ({
  type: actionTypes.EDIT_WASTE_RULES_FAIL,
  payload: { ...error }
});
