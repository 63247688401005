import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { Portlet, PortletBody } from '../../../../_metronic/partials/content/Portlet';
import {
  AutoComplete, Typography, InfoTooltip, CustomButton, Dialog, TextField, WysiwygEditor
} from '../../components';
import { SwitchIcon, PlusCircleIcon } from '../../../assets/icons';
import * as actions from '../redux/actions';

const maxDescriptionHeight = 175;

const useStyles = makeStyles(() => ({
  content: {
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_BG,
  },
  change: {
    width: 'max-content',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  changeIcon: {
    width: '16px',
    height: '16px',
  },
  addCategoryButton: {
    backgroundColor: process.env.REACT_APP_COLOR_SECONDARY,
    marginTop: '30px !important',
  },
  description: {
    wordBreak: 'break-word',
  },
  descriptionMore: {
    maxHeight: 9999,
    wordBreak: 'break-word',
  },
  descriptionLess: {
    maxHeight: maxDescriptionHeight,
    overflow: 'hidden',
  },
  descriptionEditor: {
    height: '300px',
    overflow: 'auto',
    overflowX: 'hidden'
  }
}));

const CollectionDetails = ({
  intl,
  wasteTypeList,
  collectionDetails,
  collectionList,
  locality,
  wasteType,
  categoryList,
  getCategoriesRequest,
  getCollectionDetailsRequest,
  assignCollectionRequest,
  setWasteTypeAction,
  addCategoryRequest,
}) => {
  const classes = useStyles();
  const [collection, setCollection] = useState(collectionDetails);
  const [newCategory, setNewCategory] = useState('');
  const [newCategoryHeader, setNewCategoryHeader] = useState('');
  const [newCategoryDesc, setNewCategoryDesc] = useState('');
  const [showDescriptioMore, setShowDescriptioMore] = useState(false);
  const [isDescriptionMore, setIsDescriptionMore] = useState(false);


  useEffect(() => {
    if (
      (Object.entries(wasteType).length !== 0 && categoryList.length === 0)
      || (Object.entries(wasteType).length !== 0
        && wasteType.waste_type_id_front !== categoryList[0].waste_type_id_front)
    ) {
      getCollectionDetailsRequest({
        locality: locality.cms_locality_id,
        collection: wasteType.coll_id,
      });
      getCategoriesRequest({
        locality: locality.cms_locality_id,
        wasteType: wasteType.waste_type_id,
      });
    }
  }, [wasteType]);

  useEffect(() => {
    if (wasteTypeList.length !== 0 && Object.entries(wasteType).length === 0) {
      setWasteTypeAction(wasteTypeList[0]);
      getCollectionDetailsRequest({
        locality: locality.cms_locality_id,
        collection: wasteTypeList[0].coll_id,
      });
      getCategoriesRequest({
        locality: locality.cms_locality_id,
        wasteType: wasteTypeList[0].waste_type_id,
      });
    }
    if (wasteType && wasteTypeList && Object.entries(wasteType).length !== 0) {
      wasteTypeList.find((item) => {
        if (item.waste_type_id === wasteType.waste_type_id || item.coll_admin_nm === wasteType.coll_admin_nm) {
          getCollectionDetailsRequest({
            locality: locality.cms_locality_id,
            collection: item.coll_id,
          });
        }
      });
    }
  }, [wasteTypeList.length, wasteType]);

  useEffect(() => {
    setCollection(collectionDetails);
  }, [collectionDetails]);

  useEffect(() => {
    if (document.getElementById('Category_descriptions')) {
      setIsDescriptionMore(document.getElementById('Category_descriptions').clientHeight >= maxDescriptionHeight);
    }
  }, [document.getElementById('Category_descriptions')]);


  return (
    <Portlet>
      <PortletBody className={classes.content}>
        <AutoComplete
          title={intl.formatMessage({
            id: 'CATEGORY.COLLECTION_DETAILS.WASTE_TYPE',
          })}
          options={wasteTypeList}
          value={wasteType}
          onChange={setWasteTypeAction}
          optionTitle="waste_type_admin_nm"
          optionId="waste_type_id"
        />
        <Typography className="mt-4 d-flex align-items-center" variant="h1">
          {intl.formatMessage({ id: 'CATEGORY.COLLECTION_DETAILS.TITLE' })}
          <InfoTooltip
            tooltip={intl.formatMessage({
              id: 'CATEGORY.COLLECTION_DETAILS.TITLE_TOOLTIP',
            })}
          />
        </Typography>

        <Dialog
          disableBackdropClick={false}
          button={(
            <Typography
              className={`mt-2 d-flex align-items-center ${classes.change}`}
              component="span"
              color={process.env.REACT_APP_COLOR_PRIMARY}
              variant="bodySemiBold"
            >
              <SwitchIcon
                className={`mr-2 ${classes.changeIcon}`}
                color={process.env.REACT_APP_COLOR_PRIMARY}
              />
              {intl.formatMessage({ id: 'CATEGORY.COLLECTION_DETAILS.CHANGE' })}
            </Typography>
          )}
          title={(
            <Typography variant="h1">
              {intl.formatMessage({ id: 'GENERAL.ADD' })}
            </Typography>
          )}
          content={(
            <>
              <Typography className="mb-4" variant="body">
                {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.ADD_TEXT' })}
              </Typography>
              <AutoComplete
                className="mb-3"
                options={collectionList}
                value={collection}
                onChange={setCollection}
                optionTitle="coll_admin_nm"
                optionId="coll_id"
              />
            </>
          )}
          handleSubmit={() => {
            assignCollectionRequest({
              locality: locality.cms_locality_id,
              wasteType: wasteType.waste_type_id,
              collection: collection.coll_id,
            });
          }}
        />

        {collectionDetails && collectionDetails.coll_id && (
          <>
            <Typography className="mt-2" variant="h2">
              {collectionDetails.coll_admin_nm}
            </Typography>
            <div className="mt-2">
              <Typography className="mr-3" component="span" variant="body">
                {`${intl.formatMessage({
                  id: 'CATEGORY.COLLECTION_DETAILS.SET_OUT_TIME',
                })}:`}
              </Typography>
              <Typography component="span" variant="bodySemiBold">
                {collectionDetails.set_out_time
                && collectionDetails.set_out_time[0] === '0'
                  ? collectionDetails.set_out_time.slice(1)
                  : collectionDetails.set_out_time}
              </Typography>
            </div>
            <Typography
              className="mt-3 align-items-center"
              variant="bodySemiBold"
            >
              {intl.formatMessage({
                id: 'COLLECTION.COLLECTIONS.DESCRIPTION',
              })}
            </Typography>
            <div
              id="Category_descriptions"
            >
              <Typography
                className={`mt-2 ${classes.description}`}
                variant="body"
              >
                <div
                  className={showDescriptioMore ? classes.descriptionMore : classes.descriptionLess}
                  dangerouslySetInnerHTML={{
                    __html: collectionDetails.coll_details,
                  }}
                />
              </Typography>
            </div>

            {
                isDescriptionMore && (
                  <div className="d-flex justify-content-center align-items-center">
                    <CustomButton
                      label={(
                        <Typography variant="bodySemiBold" color={process.env.REACT_APP_COLOR_PRIMARY}>
                          {showDescriptioMore ? intl.formatMessage({ id: 'GENERAL.VIEW.LESS' }) : intl.formatMessage({ id: 'GENERAL.VIEW.MORE' })}
                        </Typography>
                      )}
                      onClick={() => setShowDescriptioMore(!showDescriptioMore)}
                    />
                  </div>
                )
            }

            <Typography
              className="mt-3 align-items-center"
              variant="bodySemiBold"
            >
              {intl.formatMessage({
                id: 'COLLECTION.COLLECTIONS.SET_OUT_DESCRIPTION',
              })}
            </Typography>
            <Typography
              className={`mt-2 ${classes.description}`}
              variant="body"
            >
              <div
                className="text-justify"
                dangerouslySetInnerHTML={{
                  __html: collectionDetails.set_out_time_dscr,
                }}
              />
            </Typography>
          </>
        )}
        <Dialog
          disableBackdropClick={false}
          submitDisable={
            (newCategory !== null && !newCategory.trim().length)
            || newCategoryHeader.trim().length === 0
            || newCategoryDesc.trim().length === 0
          }
          button={(
            <CustomButton
              label={(
                <>
                  <PlusCircleIcon
                    className="mr-2"
                    color={process.env.REACT_APP_COLOR_PRIMARY}
                  />
                  {intl.formatMessage({
                    id: 'CATEGORY.COLLECTION_DETAILS.ADD_CATEGORY',
                  })}
                </>
              )}
              className={classes.addCategoryButton}
              variant="outlined"
              color="primary"
            />
          )}
          title={(
            <Typography variant="h1">
              {intl.formatMessage({ id: 'GENERAL.ADD' })}
            </Typography>
          )}
          content={(
            <>
              <Typography className="mb-4 " variant="body">
                {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.ADD_TEXT' })}
              </Typography>
              <TextField
                value={newCategory}
                onChange={setNewCategory}
              />

              <Typography className="mb-4 mt-4" variant="body">
                {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.ADD_HEADER' })}
              </Typography>
              <TextField
                value={newCategoryHeader}
                onChange={setNewCategoryHeader}
              />

              <Typography className="mb-4 mt-4" variant="body">
                {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.ADD_DESCRIPTION' })}
              </Typography>
              <WysiwygEditor
                value={newCategoryDesc}
                onChange={setNewCategoryDesc}
              />
            </>
          )}
          handleSubmit={() => {
            addCategoryRequest({
              locality: locality.cms_locality_id,
              wasteType: wasteType.waste_type_id,
              categoryName: newCategory,
              categoryHeader: newCategoryHeader,
              categoryDescription: newCategoryDesc,
            });
            setNewCategory('');
            setNewCategoryHeader('');
            setNewCategoryDesc('');
          }}
        />
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  wasteTypeList: store.category.wasteTypeList,
  collectionDetails: store.category.collectionDetails,
  collectionList: store.category.collectionList,
  locality: store.common.locality,
  wasteType: store.category.wasteType,
  categoryList: store.category.categoryList,
});

export default injectIntl(connect(mapStateToProps, actions)(CollectionDetails));
