import actionTypes from './actionTypes';

// Auth actions for setting loading status
export const setLoadingAction = () => ({
  type: actionTypes.SET_LOADING
});

export const resetLoadingAction = () => ({
  type: actionTypes.RESET_LOADING
});

// Reset Password actions for getting user's full name
export const getNameRequest = (token) => ({
  type: actionTypes.GET_NAME_REQUEST,
  payload: { token }
});

export const getNameReceive = (data) => ({
  type: actionTypes.GET_NAME_RECEIVE,
  payload: { ...data }
});

export const getNameFail = (error) => ({
  type: actionTypes.GET_NAME_FAIL,
  payload: { ...error }
});

// Reset Password actions for changing password
export const resetPasswordRequest = (newPassword, newPasswordRepeat, token) => ({
  type: actionTypes.RESET_PASSWORD_REQUEST,
  payload: { newPassword, newPasswordRepeat, token }
});

export const resetPasswordReceive = (data) => ({
  type: actionTypes.RESET_PASSWORD_RECEIVE,
  payload: { ...data }
});

export const resetPasswordFail = (error) => ({
  type: actionTypes.RESET_PASSWORD_FAIL,
  payload: { ...error }
});
