import React from 'react';
import ContentLoader from 'react-content-loader';

const StatCardLoading = ({
  width = 350,
  height = 200
}) => (
  <ContentLoader
    speed={2}
    style={{ width, height }}
    backgroundColor={process.env.REACT_APP_COLOR_LOADING_BACKGROUND}
    foregroundColor={process.env.REACT_APP_COLOR__LOADING_FOREGROUND}
  >
    <rect x="50" y="130" rx="15" ry="15" width="250" height="60" />
    <circle cx="172" cy="50" r="50" />
  </ContentLoader>
);

export default StatCardLoading;
