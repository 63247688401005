import React from 'react';
import { FormattedMessage } from 'react-intl';

const residentKnowledgeReportTabName = [
  <FormattedMessage id="REPORT.RESIDENT_KNOWLEDGE_INDICATOR.TAB.WWTK" />,
  <FormattedMessage id="REPORT.RESIDENT_KNOWLEDGE_INDICATOR.TAB.SURVEY" />,
  <FormattedMessage id="REPORT.RESIDENT_KNOWLEDGE_INDICATOR.TAB.MATERIAL_SPOTLIGHT" />,
];

export { residentKnowledgeReportTabName as default };
