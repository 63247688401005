import { put, call, takeLatest } from 'redux-saga/effects';
import actionTypes from './actionTypes';
import * as actions from './actions';
import { getWWtKData, feedbackAPI } from '../apis';

function* getWeWantedToKnowDataSaga({ payload: { project_id, district_id } }) {
  const response = yield call(getWWtKData, { project_id, district_id });

  try {
    yield put(actions.getWWtKDataReceiveAction(response.data));
  } catch (error) {
    yield put(actions.getWWtKDataFailAction(response.error.detail.data.data));
  }
}

function* feedbackSaga({ payload: { meta, feedback, type } }) {
  const response = yield call(feedbackAPI, { meta, feedback, type });
  try {
    yield put(actions.feedbackReceive(response.data));
  } catch (error) {
    yield put(actions.feedbackFail(response.error));
  }
}

function* WWtKSaga() {
  yield takeLatest(actionTypes.GET_WWTK_DATA_REQUEST, getWeWantedToKnowDataSaga);
  yield takeLatest(actionTypes.FEEDBACK_REQUEST_WWTK, feedbackSaga);

}

export default WWtKSaga;
