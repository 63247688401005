const actionTypes = {
  // Category actionTypes for setting loading status
  SET_CATEGORY_LOADING_ACTION: 'SET_CATEGORY_LOADING_ACTION',
  SET_CATEGORY_SNACKBAR_ACTION: 'SET_CATEGORY_SNACKBAR_ACTION',

  // Category actionTypes for fetching waste types list
  GET_WASTE_TYPES_REQUEST: 'GET_WASTE_TYPES_REQUEST',
  GET_WASTE_TYPES_RECEIVE: 'GET_WASTE_TYPES_RECEIVE',
  GET_WASTE_TYPES_FAIL: 'GET_WASTE_TYPES_FAIL',

  // Category actionTypes for setting a waste type
  SET_WASTE_TYPE_ACTION: 'SET_WASTE_TYPE_ACTION',

  // Category actionTypes for fetching categories of a waste type
  GET_CATEGORIES_REQUEST: 'GET_CATEGORIES_REQUEST',
  GET_CATEGORIES_RECEIVE: 'GET_CATEGORIES_RECEIVE',
  GET_CATEGORIES_FAIL: 'GET_CATEGORIES_FAIL',

  // Category actionTypes for fetching categories in all of the localities
  GET_ALL_CATEGORIES_REQUEST: 'GET_ALL_CATEGORIES_REQUEST',
  GET_ALL_CATEGORIES_RECEIVE: 'GET_ALL_CATEGORIES_RECEIVE',
  GET_ALL_CATEGORIES_FAIL: 'GET_ALL_CATEGORIES_FAIL',

  // Category actionTypes for removing a category of a waste type
  DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
  DELETE_CATEGORY_RECEIVE: 'DELETE_CATEGORY_RECEIVE',
  DELETE_CATEGORY_FAIL: 'DELETE_CATEGORY_FAIL',

  // Category actionTypes for removing a depot of a category
  UNASSIGN_DEPOT_REQUEST: 'UNASSIGN_DEPOT_REQUEST',
  UNASSIGN_DEPOT_RECEIVE: 'UNASSIGN_DEPOT_RECEIVE',
  UNASSIGN_DEPOT_FAIL: 'UNASSIGN_DEPOT_FAIL',

  // Category actionTypes for removing an event of a category
  UNASSIGN_EVENT_REQUEST: 'UNASSIGN_EVENT_REQUEST',
  UNASSIGN_EVENT_RECEIVE: 'UNASSIGN_EVENT_RECEIVE',
  UNASSIGN_EVENT_FAIL: 'UNASSIGN_EVENT_FAIL',

  // Category actionTypes for renaming a category
  RENAME_CATEGORY_REQUEST: 'RENAME_CATEGORY_REQUEST',
  RENAME_CATEGORY_RECEIVE: 'RENAME_CATEGORY_RECEIVE',
  RENAME_CATEGORY_FAIL: 'RENAME_CATEGORY_FAIL',

  // Category actionTypes for fetching all depots
  GET_DEPOTS_REQUEST: 'GET_DEPOTS_REQUEST',
  GET_DEPOTS_RECEIVE: 'GET_DEPOTS_RECEIVE',
  GET_DEPOTS_FAIL: 'GET_DEPOTS_FAIL',

  // Category actionTypes for fetching all events
  GET_EVENTS_REQUEST: 'GET_EVENTS_REQUEST',
  GET_EVENTS_RECEIVE: 'GET_EVENTS_RECEIVE',
  GET_EVENTS_FAIL: 'GET_EVENTS_FAIL',

  // Category actionTypes for fetching all collections
  GET_COLLECTIONS_REQUEST: 'GET_COLLECTIONS_REQUEST',
  GET_COLLECTIONS_RECEIVE: 'GET_COLLECTIONS_RECEIVE',
  GET_COLLECTIONS_FAIL: 'GET_COLLECTIONS_FAIL',

  // Category actionTypes for fetching waste type's collection details
  GET_COLLECTION_DETAILS_REQUEST: 'GET_COLLECTION_DETAILS_REQUEST',
  GET_COLLECTION_DETAILS_RECEIVE: 'GET_COLLECTION_DETAILS_RECEIVE',
  GET_COLLECTION_DETAILS_FAIL: 'GET_COLLECTION_DETAILS_FAIL',

  // Category actionTypes for assigning a depot to a category
  ASSIGN_DEPOT_REQUEST: 'ASSIGN_DEPOT_REQUEST',
  ASSIGN_DEPOT_RECEIVE: 'ASSIGN_DEPOT_RECEIVE',
  ASSIGN_DEPOT_FAIL: 'ASSIGN_DEPOT_FAIL',

  // Category actionTypes for assigning an event to a category
  ASSIGN_EVENT_REQUEST: 'ASSIGN_EVENT_REQUEST',
  ASSIGN_EVENT_RECEIVE: 'ASSIGN_EVENT_RECEIVE',
  ASSIGN_EVENT_FAIL: 'ASSIGN_EVENT_FAIL',

  // Category actionTypes for assigning a collection to a waste type
  ASSIGN_COLLECTION_REQUEST: 'ASSIGN_COLLECTION_REQUEST',
  ASSIGN_COLLECTION_RECEIVE: 'ASSIGN_COLLECTION_RECEIVE',
  ASSIGN_COLLECTION_FAIL: 'ASSIGN_COLLECTION_FAIL',

  // Category actionTypes for moving category from one waste type to another
  MOVE_CATEGORY_REQUEST: 'MOVE_CATEGORY_REQUEST',
  MOVE_CATEGORY_RECEIVE: 'MOVE_CATEGORY_RECEIVE',
  MOVE_CATEGORY_FAIL: 'MOVE_CATEGORY_FAIL',

  // Category actionTypes for moving materials from one category to another
  MOVE_MATERIALS_REQUEST: 'MOVE_MATERIALS_REQUEST',
  MOVE_MATERIALS_RECEIVE: 'MOVE_MATERIALS_RECEIVE',
  MOVE_MATERIALS_FAIL: 'MOVE_MATERIALS_FAIL',

  // Category actionTypes for searching through materials
  SEARCH_MATERIALS_REQUEST: 'SEARCH_MATERIALS_REQUEST',
  SEARCH_MATERIALS_RECEIVE: 'SEARCH_MATERIALS_RECEIVE',
  SEARCH_MATERIALS_FAIL: 'SEARCH_MATERIALS_FAIL',

  // Category actionTypes for fetching material search results
  GET_SEARCH_RESULTS_REQUEST: 'GET_SEARCH_RESULTS_REQUEST',
  GET_SEARCH_RESULTS_RECEIVE: 'GET_SEARCH_RESULTS_RECEIVE',
  GET_SEARCH_RESULTS_FAIL: 'GET_SEARCH_RESULTS_FAIL',

  // Category actionTypes for fetching waste type of the searched material
  GET_SEARCHED_WASTE_TYPE_REQUEST: 'GET_SEARCHED_WASTE_TYPE_REQUEST',
  GET_SEARCHED_WASTE_TYPE_RECEIVE: 'GET_SEARCHED_WASTE_TYPE_RECEIVE',
  GET_SEARCHED_WASTE_TYPE_FAIL: 'GET_SEARCHED_WASTE_TYPE_FAIL',

  // Category actionTypes for adding a new category to a waste type
  ADD_CATEGORY_REQUEST: 'ADD_CATEGORY_REQUEST',
  ADD_CATEGORY_RECEIVE: 'ADD_CATEGORY_RECEIVE',
  ADD_CATEGORY_FAIL: 'ADD_CATEGORY_FAIL',

  // Category actionTypes for updating a category's description
  UPDATE_CATEGORY_DESCRIPTION_REQUEST: 'UPDATE_CATEGORY_DESCRIPTION_REQUEST',
  UPDATE_CATEGORY_DESCRIPTION_RECEIVE: 'UPDATE_CATEGORY_DESCRIPTION_RECEIVE',
  UPDATE_CATEGORY_DESCRIPTION_FAIL: 'UPDATE_CATEGORY_DESCRIPTION_FAIL',
};

export default actionTypes;
