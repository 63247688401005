import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '../../../../components';
import { MajorCampaignTabs } from './index';

const useStyles = makeStyles(() => ({
  campaignContent: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.56,
  },
}));

const MajorCampaignDetails = ({ intl, campaign }) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.campaignContent} variant="body">
        {campaign.content}
      </Typography>
      <MajorCampaignTabs />
    </>
  );
};

export default injectIntl(MajorCampaignDetails);
