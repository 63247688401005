import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { CustomButton, Typography } from '../../../../components';
import { EducationModal, DropdownMenu, PreviewLessonModal } from '../../components';
import * as actions from '../../../EducationalContent/FeaturedLessons/redux/actions';
import { toAbsoluteUrl } from '../../../../../../_metronic';


const useStyles = makeStyles((theme) => ({
  container: {
    width: '32.333%',
    margin: '10px 0.5%',
    transition: 'all .3s',
    borderRadius: 7,
    '&:hover': {
      boxShadow: 'rgba(212, 190, 190, 0.1) 0px 1px 4px 8px'
    },
    '& .MuiButton-label': {
      fontWeight: ({ campaign_is_read }) => (campaign_is_read ? 400 : 'bold')
    }
  },
  title: {
    minWidth: 200,
    minHeight: 48,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#0789C4',
    fontSize: 18,
  },
  imageContainer: {
    backgroundColor: '#FFFAFA',
    borderRadius: 5,
    boxShadow: 'rgba(212, 190, 190, 0.1) 0px 1px 2px 2px'
  },
  campaignImage: {
    maxWidth: 229,
    height: 180,
  },
  summary: {
    height: 108,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 6,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textarea: {
    resize: 'none',
    width: '95%',
    padding: 5,
    height: 100,
    marginTop: 15,
    borderRadius: 5,
    '&:focus': {
      outline: 'none'
    }
  },
  tooltip: {
    backgroundColor: process.env.REACT_APP_COLOR_SECONDARY,
    color: process.env.REACT_APP_COLOR_TEXT,
    boxShadow: theme.shadows[3],
    fontSize: 14,
  },
  arrow: {
    color: process.env.REACT_APP_COLOR_SECONDARY,
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
}));
const MajorCampaignLearningModulesCard = ({
  intl,
  data,
  featuredLessonReadRequest,
  featuredLessonFeedbackRequest,
  error
}) => {


  /**
  * Feedback modal states and functions.
  */

  const [feedbackText, setFeedbackText] = useState('');
  const setFeedbackTextHandler = (e) => {
    setFeedbackText(e.target.value);
  };

  /**
   * Menu and menuitem functions.
   */

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [isPreviewingFeaturedLesson, setIsPreviewingFeaturedLesson] = useState(false);


  const [isSubmittingAFeedback, setIsSubmittingAFeedback] = useState();
  const isSubmittingAFeedbackHandler = () => {
    setAnchorEl(null);
    setIsSubmittingAFeedback(true);
  };

  const [isMarkingAsRead, setIsMarkingAsRead] = useState(data.campaign_is_read === 't');
  const classes = useStyles({ campaign_is_read: isMarkingAsRead });


  const toggleMarkAsReadHandler = () => {
    if (localLessonRead) {
      featuredLessonReadRequest(data.preview);
      setIsMarkingAsRead(false);
    } else {
      featuredLessonReadRequest(data.preview);
      setIsMarkingAsRead(true);
    }
    setAnchorEl(null);
  };
  let localLessonRead = !!(isMarkingAsRead || (isMarkingAsRead && !error))

  /**
   * Data for dropdown menu items.
   */
  const menuItemData = [
    {
      id: 1,
      name: 'EDUCATUION.SUBMIT.A.FEEDBACK',
      method: isSubmittingAFeedbackHandler,
      disabled: false
    },
    {
      id: 2,
      name: isMarkingAsRead ? 'EDUCATUION.MARK.AS.UNREAD' : 'EDUCATUION.MARK.AS.READ',
      method: toggleMarkAsReadHandler,
      disabled: false
    },
  ];

  const feedbackHandler = () => {
    featuredLessonFeedbackRequest(data, feedbackText);
    setIsSubmittingAFeedback(false);
  };


  return (
    <div className={`d-flex flex-wrap p-2 ${classes.container}`}>
      <div style={{ width: '100%' }} className="d-flex justify-content-between mb-3">
        <Tooltip
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrow,
          }}
          title={data.title}
          placement="top"
          arrow
          leaveDelay={100}
        >
          <div
            color={isMarkingAsRead ? '#404040' : '#0789C4'}
            className={classes.title}
            style={{ fontWeight: isMarkingAsRead ? 500 : 600 }}
          >
            {data.campaign_nm}
          </div>
        </Tooltip>

        <DropdownMenu
          handleClick={handleClick}
          anchorEl={anchorEl}
          handleClose={handleClose}
          menuItemData={menuItemData}
        />
      </div>
      <div className={`${classes.imageContainer} d-flex justify-content-center w-100 mb-3`}>
        <img
          src={data.campaign_cover ? `https://cdn.recyclecoach.com/campaigns/${data.campaign_cover}` : toAbsoluteUrl('/media/icons/FeaturedLessonGenericCover.png')}
          className={classes.campaignImage}
          alt={data.campaign_cover}
        />
      </div>

      <Typography
        color="#404040"
        className={classes.summary}
        variant="educationBody"
        dangerouslySetInnerHTML={{ __html: data.notes }}
      />

      <CustomButton
        paddingVertical={4}
        variant={isMarkingAsRead ? 'outlined' : 'contained'}
        label={<FormattedMessage id="GENERAL.PREVIEW" />}
        onClick={() => {
          setIsPreviewingFeaturedLesson(true)
        }}
      />



      {/**
       * Submit a feedback modal.
       */}

      <EducationModal
        open={isSubmittingAFeedback}
        onClose={() => setIsSubmittingAFeedback()}
        title={data.campaign_nm}
        content={<textarea onChange={setFeedbackTextHandler} className={classes.textarea} />}
        buttonApply={intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}
        disabled={!feedbackText.length}
        onClickHandler={() => feedbackHandler()}
      />


      {/**
       * Submit a preview modal.
       */}

      <PreviewLessonModal
        open={isPreviewingFeaturedLesson}
        onClose={() => setIsPreviewingFeaturedLesson()}
        title={data.campaign_nm}
        content={<textarea onChange={setFeedbackTextHandler} className={classes.textarea} />}
        buttonApply={intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}
        disabled={!feedbackText.length}
        previewID={data.campaign_id}
      />

    </div>
  );
};
export default injectIntl(connect(null, actions)(MajorCampaignLearningModulesCard));

