import cts from '../../../crud/cts';

// Get Holidays
export const getHolidays = async (
  project_id
) => cts(
  'get',
  `schedule/get-holidays?project_id=${project_id}`,
);

// Save Holidays
export const saveHolidays = async (
  selectedHolidays,
  holidayImpacts,
  multiDistricts

) => cts(
  'post',
  'schedule/holidays',
  {
    selectedHolidays,
    holidayImpacts,
    districts: multiDistricts || [],
  },
  {},
  true,
  true
);

// Get Perpetual Schedules
export const getPerpetualSchedules = async (
  districts
) => {
  const encodedDistricts = districts.map(district => encodeURIComponent(district)).join(',');
  return cts(
    'get',
    `schedule/perpetual-schedule/get-districts?districts=${encodedDistricts}`
  );
};

// Manage Perpetual Schedules
export const managePerpetualSchedules = async (
  manages_schedules,
) => cts(
  'post',
  'schedule/perpetual-schedule/manage',
  {
    manages_schedules,
  }
);

// Get Seasonal List
export const getSeasonalList = async (
  multiDistricts
) => {
  const multiDistrictsParam = encodeURIComponent(multiDistricts.join(','));
  return cts(
    'get',
    `schedule/perpetual-schedule/get-seasonal-list?districts=${multiDistrictsParam}`
  );
};

// Save Seasonal Collection
export const saveSeasonalCollection = async (
  project_id,
  district_id,
  col_type_id,
  season_start_config,
  season_end_config
) => cts(
  'post',
  'schedule/perpetual-schedule/save-seasonal-config',
  {
    project_id,
    district_id,
    col_type_id,
    season_start_config,
    season_end_config
  }
);
