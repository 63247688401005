import cts from '../../crud/cts';

/**
 * TODO: Insert comment
 */

export const getEvents = async ({ locality }) => cts(
  'post',
  'event/get-all',
  {
    cms_locality_id: locality,
  }
);

export const createEvent = async ({
  data
}) => cts(
  'post',
  'event/new',
  {
    cms_locality_id: data.locality,
    event_admin_nm: data.name,
    event_nm: data.name,
    event_dates: data.dates,
    event_details: data.description,
    drop_event_loc_ids: data.locations,
  }
);

export const editEvent = async ({
  data
}) => cts(
  'post',
  'event/edit',
  {
    cms_locality_id: data.locality,
    drop_event_id: data.id,
    event_nm: data.name,
    event_details: data.description,
    event_dates: data.dates,
    drop_event_loc_ids: data.locations,
  }
);

export const getAddressSuggestions = async ({ address }) => cts(
  'get',
  `geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
  {},
  {},
  false
);

export const getEventAddress = async ({ coordinates }) => cts(
  'get',
  `geocode/json?latlng=${coordinates}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
  {},
  {},
  false
);

export const deleteEvent = async ({ locality, event }) => cts(
  'post',
  'event/remove',
  {
    cms_locality_id: locality,
    drop_event_id: event
  }
);
