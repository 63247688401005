import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { Portlet, PortletBody } from '../../../../_metronic/partials/content/Portlet';
import {
  MunicipalitySelector,
  TextField,
  CustomButton,
  Snackbar,
  IconButton,
  Typography
} from '../../components';
import * as actions from '../redux/actions';
import Permission from './Permission';
import { validation } from '../../../utils';
import {
  municipalityCheck,
  usernameCheck,
  emailCheck,
  phoneCheck
} from './CheckInputs';

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    [theme.breakpoints.down('1200')]: {
      width: '100%',
    },
    [theme.breakpoints.down('400')]: {
      flexDirection: 'column !important',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  bottom: {
    [theme.breakpoints.down('1200')]: {
      marginTop: theme.spacing(5)
    },
    [theme.breakpoints.down('400')]: {
      justifyContent: 'center !important',
      alignItems: 'center !important',
    }
  },
  button: {
    width: '25%',
    height: '60px',
    margin: '5px',
    [theme.breakpoints.down('1200')]: {
      width: '20% !important',
    },
    [theme.breakpoints.down('780')]: {
      width: '25% !important',
    },
    [theme.breakpoints.down('500')]: {
      width: '30% !important',
    },
    [theme.breakpoints.down('400')]: {
      width: '100% !important',
      margin: '0px !important',
      marginTop: '10px !important'
    }
  },
  suggested: {
    color: process.env.REACT_APP_COLOR_PRIMARY
  },
  errorColor: {
    color: process.env.REACT_APP_COLOR_DELETE
  }
}));

const UsersForm = ({
  intl,
  newUser,
  addUserAction,
  resetNewUserAction,
  municipalities,
  userList,
  setUserListManuallyAction,
  checkUsernameRequest,
  valid_username,
  checkEmailRequest,
  valid_email,
  addUserRequest,
  editUserRequest,
  responseMessage,
  responseStatus,
  resetMessageAction,
  navigationToList,
  resetNavigateToListAction,
  suggestUsernameRequest,
  suggestedUsername,
  resetSuggestUsername
}) => {
  const history = useHistory();
  const classes = useStyles();
  const navigateToList = () => {
    history.push('/users/list');
  };

  useEffect(() => {
    resetSuggestUsername();
    const tempList = [...userList];
    const tempIndex = tempList.findIndex(
      (item) => item.user_name === newUser.user_name
    );
    if (navigationToList === true && responseMessage === 'OK') {
      if (newUser.user_id) {
        tempList.push({
          user_name: newUser.user_name,
          first_name: newUser.first_name,
          last_name: newUser.last_name,
          email: newUser.email,
          phone: newUser.phone,
          is_active: newUser.is_active,
          user_id: newUser.user_id
        });
      } else {
        tempList[tempIndex] = {
          user_name: newUser.user_name,
          first_name: newUser.first_name,
          last_name: newUser.last_name,
          email: newUser.email,
          phone: newUser.phone,
          is_active: newUser.is_active,
          user_id: newUser.user_id
        };
      }
      setUserListManuallyAction(tempList);
      resetNavigateToListAction();
      resetNewUserAction();
      navigateToList();
    }
  }, [navigationToList]);

  useEffect(() => {
    if (municipalities.length === 1) {
      handleChangeForm(municipalities, 'district_list');
    }
  }, [municipalities]);

  const handleChangeForm = (data, propertyName) => {
    addUserAction({
      data,
      propertyName
    });
  };

  return (
    <div>
      <Snackbar
        message={responseMessage}
        status={responseStatus}
        closeAction={resetMessageAction}
      />
      <Portlet>
        <PortletBody>
          <div className="row pt-4">
            <div className="col-sm-12 col-xl-6 pr-5 pl-4 position-relative">
              {municipalities.length > 1
                && (
                  <div className="mb-3">
                    <MunicipalitySelector
                      title={intl.formatMessage({ id: 'GENERAL.MUNICIPALITY' })}
                      isMulti
                      disableClearable={false}
                      onChange={(e) => handleChangeForm(e, 'district_list')}
                      value={newUser.district_list}
                      invalidText={
                        municipalityCheck(newUser.district_list)
                      }
                    />
                  </div>
                )}
              <div className="mb-3">
                <TextField
                  label={intl.formatMessage({ id: 'GENERAL.FIRST_NAME' })}
                  value={newUser.first_name}
                  onChange={(e) => {
                    handleChangeForm(e, 'first_name');
                  }}
                  onBlur={() => {
                    if (newUser.first_name.length > 0
                      && newUser.last_name.length > 0
                      && (newUser.user_id === null)) {
                      suggestUsernameRequest(
                        newUser.first_name,
                        newUser.last_name
                      );
                    }
                  }}
                />
              </div>
              <div className="mb-3">
                <TextField
                  label={intl.formatMessage({ id: 'GENERAL.LAST_NAME' })}
                  value={newUser.last_name}
                  onChange={(e) => {
                    handleChangeForm(e, 'last_name');
                  }}
                  onBlur={() => {
                    if (newUser.first_name.length > 0
                      && newUser.last_name.length > 0
                      && (newUser.user_id === null)) {
                      suggestUsernameRequest(
                        newUser.first_name,
                        newUser.last_name
                      );
                    }
                  }}
                />
              </div>
              <div className="mb-3">
                <TextField
                  label={intl.formatMessage({ id: 'GENERAL.USERNAME' })}
                  value={newUser.user_name}
                  onChange={(e) => {
                    handleChangeForm(e, 'user_name');
                  }}
                  onBlur={() => checkUsernameRequest(newUser.user_name)}
                  invalidText={
                    usernameCheck(newUser.user_name, valid_username)
                  }
                  isDisable={!!newUser.user_id}
                />
                {(suggestedUsername !== null && newUser.user_id === null)
                  && (
                    <div className="d-flex">
                      <Typography variant="h2">
                        Suggested :
                        {' '}
                      </Typography>
                      <IconButton onClick={() => {
                        handleChangeForm(suggestedUsername, 'user_name');
                        checkUsernameRequest(suggestedUsername);
                      }}
                      >
                        <Typography className={classes.suggested} variant="bodySemiBold">
                          <span>
                            {suggestedUsername}
                          </span>
                        </Typography>
                      </IconButton>
                    </div>
                  )}
              </div>
              <div className="mb-3">
                <div className="position-relative">
                  <TextField
                    label={intl.formatMessage({ id: 'GENERAL.EMAIL' })}
                    value={newUser.email}
                    onChange={(e) => {
                      handleChangeForm(e, 'email');
                    }}
                    onBlur={() => checkEmailRequest(newUser.user_name, newUser.email)}

                    invalidText={emailCheck(newUser.email, valid_email)}
                  />
                </div>
              </div>
              <TextField
                label={intl.formatMessage({ id: 'GENERAL.PHONE' })}
                value={newUser.phone}
                onChange={(e) => {
                  handleChangeForm(e, 'phone');
                }}
                invalidText={phoneCheck(newUser.phone)}
              />
              <div className="d-inline-flex mt-4">
                <Typography variant="h2" className={classes.errorColor}>
                  *
                </Typography>
                <Typography variant="body" className={classes.errorColor}>

                  {intl.formatMessage({ id: 'USERS.FORM.REQUIRED_FIELD' })}
                </Typography>
              </div>
            </div>
            {/* user permissions section */}
            <div
              className="col-12 col-xl-6 pl-4 pt-4 pl-xl-0 pt-xl-0 d-flex flex-column justify-content-between"
            >
              <div>
                <Typography variant="h1">
                  {intl.formatMessage({ id: 'USERS.FORM.PERMISSION.TITLE' })}
                </Typography>
                {/* Education Permission */}
                <div className="mt-4">
                  <Permission
                    title={intl.formatMessage({ id: 'USERS.FORM.PERMISSION.EDUCATION' })}
                    tooltipText={intl.formatMessage({ id: 'USERS.FORM.PERMISSION.EDUCATION.TOOLTIPS' })}
                    onChange={() => {
                      let permission = newUser.permission_list;
                      console.log('newUser', newUser)
                      permission = { ...permission, education: !permission.education };
                      console.log('permission', permission)
                      handleChangeForm(permission, 'permission_list');
                    }}
                    isActive={newUser.permission_list.education}
                  />
                </div>
                {/* Notification Permission */}
                <div className="mt-4">
                  <Permission
                    title={intl.formatMessage({ id: 'USERS.FORM.PERMISSION.NOTIFICATION' })}
                    tooltipText={intl.formatMessage({ id: 'USERS.FORM.PERMISSION.NOTIFICATION.TOOLTIPS' })}
                    onChange={() => {
                      let permission = newUser.permission_list;
                      permission = { ...permission, notification: !permission.notification };
                      handleChangeForm(permission, 'permission_list');
                    }}
                    isActive={newUser.permission_list.notification}
                  />
                </div>
                {/* Reminder Permission */}
                <div className="mt-4">
                  <Permission
                    title={intl.formatMessage({ id: 'USERS.FORM.PERMISSION.REMINDER' })}
                    tooltipText={intl.formatMessage({ id: 'USERS.FORM.PERMISSION.REMINDER.TOOLTIPS' })}
                    onChange={() => {
                      let permission = newUser.permission_list;
                      permission = { ...permission, reminder: !permission.reminder };
                      handleChangeForm(permission, 'permission_list');
                    }}
                    isActive={newUser.permission_list.reminder}
                  />
                </div>
                {/* Report Permission */}
                <div className="mt-4">
                  <Permission
                    title={intl.formatMessage({ id: 'USERS.FORM.PERMISSION.REPORT' })}
                    tooltipText={intl.formatMessage({ id: 'USERS.FORM.PERMISSION.REPORT.TOOLTIPS' })}
                    onChange={() => {
                      let permission = newUser.permission_list;
                      permission = { ...permission, report: !permission.report };
                      handleChangeForm(permission, 'permission_list');
                    }}
                    isActive={newUser.permission_list.report}
                  />
                </div>
                {/* What goes where Permission */}
                <div className="mt-4">
                  <Permission
                    title={intl.formatMessage({ id: 'USERS.FORM.PERMISSION.WHAT_GOES_WHERE' })}
                    tooltipText={intl.formatMessage({ id: 'USERS.FORM.PERMISSION.WHAT_GOES_WHERE.TOOLTIPS' })}
                    onChange={() => {
                      let permission = newUser.permission_list;
                      permission = { ...permission, cms: !permission.cms };
                      handleChangeForm(permission, 'permission_list');
                    }}
                    isActive={newUser.permission_list.cms}
                  />
                </div>
                {/* Schedules Permission */}
                <div className="mt-4">
                  <Permission
                    title={intl.formatMessage({ id: 'USERS.FORM.PERMISSION.SCHEDULES' })}
                    tooltipText={intl.formatMessage({ id: 'USERS.FORM.PERMISSION.SCHEDULES.TOOLTIPS' })}
                    onChange={() => {
                      let permission = newUser.permission_list;
                      permission = { ...permission, schedules: !permission.schedules };
                      handleChangeForm(permission, 'permission_list');
                    }}
                    isActive={newUser.permission_list.schedules}
                  />
                </div>
                {/* Marketing Support Permission */}
                <div className="mt-4">
                  <Permission
                    title={intl.formatMessage({ id: 'USERS.FORM.PERMISSION.MARKETING_SUPPORT' })}
                    tooltipText={intl.formatMessage({ id: 'USERS.FORM.PERMISSION.MARKETING_SUPPORT.TOOLTIPS' })}
                    onChange={() => {
                      let permission = newUser.permission_list;
                      permission = { ...permission, marketing_support: !permission.marketing_support };
                      handleChangeForm(permission, 'permission_list');
                    }}
                    isActive={newUser.permission_list.marketing_support}
                  />
                </div>
              </div>
              <div className={`d-flex flex-column ${classes.bottom}`}>
                <div className="d-flex flex-row-reverse pt-2 mt-3 p-0 pr-3">
                  <Typography variant="body">
                    {intl.formatMessage({ id: 'USERS.FORM.PERMISSION.COMMENT' })}
                  </Typography>
                </div>
                <div className={`d-flex flex-row-reverse ${classes.buttonWrapper}`}>
                  <div className={`${classes.button}`}>
                    <CustomButton
                      label={intl.formatMessage({ id: 'GENERAL.SAVE' })}
                      variant="contained"
                      disabled={!validation(newUser.user_name, 'atLeast4Characters')
                        || newUser.district_list.length === 0
                        || emailCheck(newUser.email, valid_email)
                        || !validation(newUser.phone, 'phone')
                        || (newUser.first_name !== null && !newUser.first_name.trim().length)
                        || (newUser.last_name !== null && !newUser.last_name.trim().length)}
                      onClick={() => {
                        const RequestPermission = [];
                        const permissionName = ['notification', 'reminder', 'report', 'cms', 'education', 'schedules', 'marketing_support'];
                        permissionName.map((item) => RequestPermission.push(
                          {
                            feature_id: item,
                            permission: newUser.permission_list[item] === true ? 't' : 'f'
                          }
                        ));
                        if (newUser.user_id) {
                          editUserRequest({
                            user_id: newUser.user_id,
                            user_name: newUser.user_name,
                            first_name: newUser.first_name,
                            last_name: newUser.last_name,
                            email: newUser.email,
                            phone: newUser.phone,
                            is_active: newUser.is_active,
                            notes: '',
                            job_title: '',
                            permission_list: RequestPermission,
                            district_list: newUser.district_list
                          });
                        } else {
                          addUserRequest({
                            user_name: newUser.user_name,
                            first_name: newUser.first_name,
                            last_name: newUser.last_name,
                            email: newUser.email,
                            phone: newUser.phone,
                            is_active: newUser.is_active,
                            notes: '',
                            job_title: '',
                            permission_list: RequestPermission,
                            district_list: newUser.district_list
                          });
                        }
                        resetSuggestUsername();
                      }}
                    />
                  </div>
                  <div className={`${classes.button}`}>
                    <CustomButton
                      label={intl.formatMessage({ id: 'GENERAL.CANCEL' })}
                      variant="outlined"
                      onClick={() => {
                        resetNewUserAction();
                        resetSuggestUsername();
                        navigateToList();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PortletBody>
      </Portlet>
    </div>
  );
};
const mapStateToProps = (store) => ({
  newUser: store.user.newUser,
  municipalities: store.common.municipalities,
  userList: store.user.userList,
  valid_username: store.user.valid_username,
  valid_email: store.user.valid_email,
  responseMessage: store.user.responseMessage,
  responseStatus: store.user.responseStatus,
  navigationToList: store.user.navigateToList,
  suggestedUsername: store.user.suggestedUsername
});

export default injectIntl(
  connect(
    mapStateToProps,
    actions
  )(UsersForm)
);
