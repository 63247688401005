import React from 'react';
import { injectIntl } from 'react-intl';
import Status from '../../components/Status';

const SwitchSuccessful = ({
  from,
  to,
  intl
}) => (
  <Status color={process.env.REACT_APP_COLOR_TERTIARY}>
    { intl.formatMessage({ id: 'GENERAL.CONGRATS' }) }
    ,
    {
      intl.formatMessage(
        { id: 'CAMPAIGN.SWITCH_SUCCESSFUL' },
        {
          from,
          to
        }
      )
    }
  </Status>
);

export default injectIntl(SwitchSuccessful);
