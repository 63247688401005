import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import {
  Typography, CustomButton
} from '../../../components';
import {
  Portlet,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';
import { toAbsoluteUrl } from '../../../../../_metronic';

import * as actions from '../redux/actions';
import * as sharedActions from '../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: '50%',
    margin: '20px 0px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 20,
  },
}));

const SeasonalList = ({
  intl,
  seasonalList,
  goToSeasonalCollection
}) => {
  const classes = useStyles();


  return (
    <>
      <div className="d-flex flex-wrap mt-3">
        <Portlet>
          <PortletBody className="d-flex justify-content-center" style={{ alignItems: 'center', margin: '50px 0px' }}>
            <div className={classes.centerContainer}>
              <div>
                <Typography variant="h1" style={{ fontSize: 24, fontWeight: 700 }}>
                  {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES.THANKS' })}
                </Typography>
              </div>
              <img
                alt="Perpetual Schedules"
                width={341}
                height={341}
                src={toAbsoluteUrl('/media/icons/PerpetualSchedules/thanks-information.svg')}
              />
              <Typography variant="h3" style={{ fontSize: 16, fontWeight: 400 }}>
                {intl.formatMessage({ id: 'SCHEDULES.PERPETUAL_SCHEDULES.THANKS.CONTENT' })}
              </Typography>
            </div>
            {
              seasonalList && 
              [...new Map(seasonalList.seasonal_list.map(item => [item.col_type_id, item])).values()]
              .map((seasonal) => (
                <Typography
                  key={seasonal.col_type_id}
                  variant="h1"
                  style={{
                    fontSize: 18, color: '#2A95FF', fontWeight: 600, textDecorationLine: 'underline', marginBottom: 10
                  }}
                >
                  {seasonal.col_type_title}
                </Typography>
              ))
            }
            <div className={classes.buttonContainer}>
              <CustomButton
                variant="contained"
                size="large"
                label={(
                  <Typography variant="h1" color="white">
                    Continue
                  </Typography>
                        )}
                onClick={() => goToSeasonalCollection()}
                className={classes.nextButton}
              />
            </div>
          </PortletBody>
        </Portlet>
      </div>
    </>
  );
};
const mapStateToProps = (store) => ({
  seasonalList: store.perpetualSchedules.seasonalList,

});

export default injectIntl(connect(mapStateToProps, { ...actions, ...sharedActions })(SeasonalList));
