import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { addCommasToNumber } from '../../../../utils';
import { Typography, Pagination } from '../../../components';
import { CorrectAnswerIcon } from '../../../../assets/icons';

const useStyles = makeStyles((theme) => ({
  standaredView: {
    display: 'flex',
    // [theme.breakpoints.down(992)]: {
    //   display: 'none !important'
    // }
  },
  tableRow: {
    minHeight: '45px',
    borderTop: `2px solid ${process.env.REACT_APP_COLOR_DISABLED_TEXT}`,
  },
  tableHeader: {
    minHeight: '55px',
    marginTop: '30px'
  },
  tableDatas: {
    minHeight: '45px',
  },
  tableBorder: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  tablePadding: {
    padding: '20px',
    paddingLeft: '0px',
    paddingRight: '50px',
    [theme.breakpoints.down(992)]: {
      padding: '20px',
      paddingTop: '0px',
      paddingBottom: '30px'
    }
  },
  responsiveMode: {
    display: 'none !important',
    [theme.breakpoints.down(992)]: {
      display: 'flex !important'
    }
  },
  rowBackground: {
    backgroundColor: process.env.REACT_APP_COLOR_PRIMARY_BG
  },
  correctAnswerIcon: {
    minWidth: '25px',
    marginLeft: '10px'
  },
  answersComment: {
    fontSize: '10px',
    fontWeight: 500,
  }
}));

const WWTKDetail = ({
  intl,
  data,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <div className={`row d-flex flex-column m-3 mb-0 ${classes.standaredView}`}>
        <div className={`row w-100 d-flex align-items-center ${classes.tableHeader}`}>
          <Typography
            className="d-flex pl-0 col-1 justify-content-start"
            variant="bodySemiBold"
          >
            {intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.WWTK.START_DATE' })}
          </Typography>

          <Typography
            className="d-flex col-4 justify-content-start"
            variant="bodySemiBold"
          >
            {intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.WWTK.TITLE' })}
          </Typography>

          <Typography
            className="d-flex pl-0 col-1 justify-content-start"
            variant="bodySemiBold"
          >
            {intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.WWTK.TYPE' })}
          </Typography>
          {/*
          <Typography
            className="d-flex pl-0 col-1 justify-content-start"
            variant="bodySemiBold"
          >
            {intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.WWTK.TO_DATE' })}
          </Typography> */}

          <div className="d-flex pl-0 col-6 justify-content-center">

            <Typography
              className="d-flex flex-column pl-0 col-6 justify-content-start"
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.WWTK.OPTIONS' })}
              <span className={classes.answersComment}>
                {intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.WWTK.OPTIONS.COMMENT' })}
              </span>
            </Typography>

            <Typography
              className="d-flex pl-0 col-4 justify-content-center"
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.WWTK.RESPONSE' })}
            </Typography>

            <Typography
              className="d-flex pl-0 col-2 justify-content-center"
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.WWTK.OF_USERS' })}
            </Typography>
          </div>

        </div>

        { data && [...data]
          .sort((a, b) => new Date(b.from_dt) - new Date(a.from_dt))
          .slice((page - 1) * rowsPerPage, (page * rowsPerPage)).map((item, id) => (
            <div
              key={`MM_REPORT_${item.coachable_id} + ${item.campaign_id} - ${String(id)} - ${Math.random()}`}
              className={`row w-100 d-flex h-100 align-items-center ${classes.tableRow} ${id % 2 ? null : classes.rowBackground}`}
            >

              <Typography
                className="col-1 h-100 d-flex justify-content-start align-items-start"
                variant="body"
              >
                {item.from_dt ? item.from_dt.slice(0, 10) : ''}
              </Typography>

              <Typography
                className={`col-4 h-100 d-flex align-items-start justify-content-start ${classes.tablePadding}`}
                variant="body"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.poll_quiz_question
                  }}
                />

              </Typography>
              <Typography
                variant="bodySemiBold"
                component="span"
                className="col-1 h-100 d-flex justify-content-start align-items-center pl-0"
              >
                {item.type}
              </Typography>

              {/* <Typography
                className="col-1 h-100 d-flex justify-content-start align-items-center"
                variant="body"
              >
                {item.to_dt ? item.to_dt.slice(0, 10) : ''}
              </Typography> */}

              <div className={`row col-6 h-100 d-flex p-0 justify-content-center flex-row ${classes.tableDatas}`}>
                { item.options && item.options.map((opt, index) => (
                  <div
                    className={`row d-flex w-100 p-0 ${classes.tableDatas} 
                    ${item.options.length - 1 !== index ? classes.tableBorder : null}`}
                    key={`wwtk opt - ${Math.random()}`}
                  >
                    <Typography
                      className="col-6 d-flex align-items-center justify-content-start"
                      key={`WWTK_ANSWER_${opt.name} - ${Math.random()} - ${String(index)}`}
                      variant="bodySemiBold"
                      color={item.quiz_correct_answer !== String(index + 1)
                        ? process.env.REACT_APP_COLOR_TEXT
                        : process.env.REACT_APP_COLOR_POSITIVE}
                    >
                      {opt.name || ''}
                      {item.quiz_correct_answer === String(index + 1)
                      && <CorrectAnswerIcon className={classes.correctAnswerIcon} />}
                    </Typography>

                    <Typography
                      className="col-4 h-100 d-flex justify-content-center align-items-center"
                      variant="body"
                    >
                      {opt.value ? `${addCommasToNumber(opt.value)}` : 0}
                    </Typography>
                    <Typography
                      className="col-2 h-100 d-flex justify-content-center align-items-center"
                      variant="body"
                    >
                      {opt.value ? `${((opt.value * 100) / item.sum).toFixed(2)} %` : '0%'}
                    </Typography>
                  </div>
                ))}
              </div>

            </div>
          ))}
        <div className="mt-5 mb-4 d-flex justify-content-end align-items-center">
          <Pagination
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={(value) => handleChangePage(value)}
            handleRowsPerPageChange={(value) => setRowsPerPage(value)}
            dataLength={data && data.length}
          />
        </div>
      </div>

      {/* <div className={`row d-flex flex-column m-3 mb-0 ${classes.responsiveMode}`}>
        { data && data.map((item, id) => (
          <div key={`WWTK_ANSWER_${item.coachable_id} + ${item.campaign_id} - ${String(id)}`}>
            <div className={`row d-flex flex-row w-100 d-flex align-items-center ${id === 0 ? 'mt-3' : 'mt-5'}
             ${classes.tableDatas} ${classes.tableBorder} ${classes.tablePadding}`}
            >
              <Typography
                variant="body"
                className="col-10"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.poll_quiz_question
                  }}
                />
              </Typography>

              <Typography
                color={item.type === 'poll' ? process.env.REACT_APP_COLOR_TERTIARY_DARK : process.env.REACT_APP_COLOR_PRIMARY}
                variant="body"
                className="col-2 d-flex justify-content-center"
              >
                {item.type}
              </Typography>
            </div>

            <div className={`row d-flex flex-row w-100 d-flex align-items-center pl-4
             ${classes.tableDatas} ${classes.tableBorder}`}
            >
              <Typography
                variant="body"
                className="col-6 d-flex justify-content-start align-items-center"
              >
                <Typography
                  color={process.env.REACT_APP_COLOR_NEGATIVE}
                  variant="body"
                  className="mr-3"
                >
                  {`${intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.WWTK.FROM_DATE' })} : `}
                </Typography>
                {item.from_dt}
              </Typography>

              <Typography
                variant="body"
                className="col-6 d-flex justify-content-start align-items-center"
              >
                <Typography
                  color={process.env.REACT_APP_COLOR_NEGATIVE}
                  variant="body"
                  className="mr-3"
                >
                  {`${intl.formatMessage({ id: 'REPORT.RESIDENT_KNOWLEDGE_INDICATOR.WWTK.TO_DATE' })} : `}
                </Typography>
                {item.to_dt}
              </Typography>
            </div>


            { item.options && item.options.map((opt, index) => (
              <Typography
                key={`WWTK_Report_sub_${opt.name} - ${String(index)}`}
                className={`w-100 d-flex align-items-center pl-4 ${classes.tableDatas}
                  ${index === item.answers.length - 1 && id === data.length - 1 ? null : classes.tableBorder}`}
                variant="bodySemiBold"
                color={process.env.REACT_APP_COLOR_TEXT}
              >
                {opt.name || ''}
              </Typography>
            ))}
          </div>
        ))}
      </div> */}
    </>
  );
};

export default injectIntl(WWTKDetail);
