import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import MenuItem from '@material-ui/core/MenuItem';

import { LoadingSpinner, ErrorContainer } from '../../components/loading';


import { MaterialsDetailsTable, BulkPickUpTable } from './components'
import { Typography, CustomButton, DatePicker, TextField } from '../../components';
import { BulkPickUpOverlay } from '../ReportDashboardNew/components'

import * as actions from '../ReportDashboardNew/redux/actions';
import * as sharedActions from '../../redux/actions';
import * as dashboardActions from '../../Dashboard/redux/actions';


import { TableLoading } from '../../components/loading';
import {
  Portlet, PortletBody, PortletHeader,
} from '../../../../_metronic/partials/content/Portlet';

import moment from 'moment';
import { set } from 'draft-js/lib/DefaultDraftBlockRenderMap';

const useStyles = makeStyles((theme) => ({
  container: {
    // width: '90%',
    margin: '0 auto'
  },
  dateSelector: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  dateLabel: {
    color: '#2A95FF',
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 16,
  },
  topSection: {
    display: 'flex',
    alignContent: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  sectionTitle: {
    color: '#404040',
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 22,
    letterSpacing: '0.05em'
  },
  downloadButton: {
    width: 150,
    justifyContent: 'flex-end',
  },
  bodyTop: {
    display: 'flex',
    // height: 52,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '85%',
    gap: 20,
  },
  textInput: {
    marginTop: '21px!important',
  }
}));


const MajorCampaigns = ({
  intl,
  getBulkPickupDetailsRequest,
  majorDetails,
  bulkPickUpDetails,
  loading,
  error,
  municipality,
  isUserPro,
  stage,
  district,
  municipalities,
  setDistrictAction,
  hasCounty,
  counties,
  county,
  isCounty,
  setCountyAction,
  csm,
  getDashboardDataRequestAction,
}) => {

  const classes = useStyles();
  const [datePeriod, setDatePeriod] = useState({ month: 0, year: 0 });
  const [dateOptions, setDateOptions] = useState([]);
  const [monthFilter, setMonthFilter] = useState(1);
  const [startOfPeriod, setStartOfPeriod] = useState(null);
  const [endOfPeriod, setEndOfPeriod] = useState(null);
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [displayAll, setDisplayAll] = useState(false);


  const handleChange = (event, data) => {
    setDatePeriod(data.props.item);

  };

  useEffect(() => {
    const options = generateMonthYear();
    setDateOptions(options);
    setDatePeriod(options[0]);
    getBulkPickupDetailsRequest({
      municipality,
      month: options[0].month,
      year: options[0].year,
      county,
      isCounty,
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().add(1, 'year').format('YYYY-MM-DD')
    })

    if (!csm) {
      const fromDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
      const toDate = moment().format('YYYY-MM-DD');
      getDashboardDataRequestAction(fromDate, toDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleMonthsFetch = (months) => {
    setMonthFilter(months);
    getBulkPickupDetailsRequest({
      municipality,
      month: datePeriod.month,
      year: datePeriod.year,
      last_month: months,
      county,
      isCounty,
    })

  };

  const generateMonthYear = () => {
    const date = new Date();
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();
    const monthYear = [];
    for (let i = 0; i < 24; i++) {
      if (currentMonth - i < 0) {
        monthYear.push({
          month: Math.abs(12 + currentMonth - i),
          year: currentYear - 1,
        });
      } else {
        monthYear.push({
          month: currentMonth - i,
          year: currentYear,
        });
      }
    }
    return monthYear;
  };

  const getMonthName = (monthNumber, format = 'short') => {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', { month: format });
  };

  const handleSearch = () => {
    let start_date = startOfPeriod ? startOfPeriod : moment(`${datePeriod.year}-${datePeriod.month + 1}-01`).subtract(1, 'year').format('YYYY-MM-DD');
    let end_date = endOfPeriod ? endOfPeriod : moment(`${datePeriod.year}-${datePeriod.month + 1}-01`).add(1, 'months').format('YYYY-MM-DD');

    getBulkPickupDetailsRequest({
      municipality,
      month: datePeriod.month,
      year: datePeriod.year,
      county,
      isCounty,
      last_name: lastName,
      address,
      start_date: startOfPeriod ? moment(startOfPeriod).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      end_date: endOfPeriod ? moment(endOfPeriod).format('YYYY-MM-DD') : moment().add(1, 'year').format('YYYY-MM-DD')
    })
  };

  const handleGetData = () => {
    if(displayAll){
      setDisplayAll(false);
      getBulkPickupDetailsRequest({
        municipality,
        month: datePeriod.month,
        year: datePeriod.year,
        county,
        isCounty,
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().add(1, 'year').format('YYYY-MM-DD')
      })
    }else{
      setDisplayAll(true);
      getBulkPickupDetailsRequest({
        municipality,
        month: datePeriod.month,
        year: datePeriod.year,
        county,
        isCounty,
        last_name: lastName,
        address,
        // start_date: startOfPeriod ? startOfPeriod : moment(`${datePeriod.year}-${datePeriod.month + 1}-01`).subtract(1, 'year').format('YYYY-MM-DD'),
        // end_date: endOfPeriod ? endOfPeriod : moment(`${datePeriod.year}-${datePeriod.month + 1}-01`).add(1, 'months').format('YYYY-MM-DD'),
      })
    }

  }

  return (
    <>
      <div className={classes.container}>
        {
          loading ?
            <TableLoading /> :
            <div className="row">
              {dateOptions && dateOptions.length > 0 && !bulkPickUpDetails && loading ? <LoadingSpinner height={350} /> : error ? (
                <ErrorContainer
                  small
                  reportName="Report Dashboard"
                  height={430}
                  error={error}
                />
              ) : (
                <div className="col-xl-12 col-centered">
                  {/* <div className={classes.topSection}>
                    <Typography variant="h2" className={classes.sectionTitle}>
                      Bulky Items Pick-ups
                    </Typography>
                  </div> */}
                  <Portlet className={`h-100 position-relative ${classes.materialsTable}`}>
                    <PortletHeader
                      className={`${classes.tableLabel}`}
                      title={(
                        <div className="d-flex align-items-center">
                          <Typography variant="h1" className={`${classes.materialsLabel}`}>
                            Bulky Items Pick-ups
                          </Typography>
                        </div>
                      )}
                    />
                    <PortletBody className={`${classes.tableBody}`}>
                      <div className={`${classes.bodyTop}`}>
                        {
                          bulkPickUpDetails && bulkPickUpDetails.data && bulkPickUpDetails.data.bulk_pickup_status && bulkPickUpDetails.data.bulk_pickup_status[0].has_bp !== 't' ? (
                            <div style={{ width: '100%' }}>
                              <BulkPickUpOverlay csm={csm} displayMore={true} />
                            </div>
                          ) : null
                        }

                        <div className={`${classes.filterContainer}`}>
                          <TextField
                            className={`${classes.textInput} my-3`}
                            label={'Name'}
                            value={lastName}
                            onChange={(e) => {
                              setLastName(e)
                            }
                            }
                            hasClear
                          />
                          <TextField
                            className={`${classes.textInput} my-3`}
                            label={'Address'}
                            value={address}
                            onChange={(e) => {
                              setAddress(e)
                            }
                            }
                            hasClear
                          />
                          <DatePicker
                            className="mr-2"
                            label={'Pick-up date starting from'}
                            value={startOfPeriod && startOfPeriod !== '' ? startOfPeriod : null}
                            onChange={(date) => setStartOfPeriod(date ? moment(date).format('YYYY-MM-DD') : '')}
                            clearable={true}
                            // maxDate={moment(endOfPeriod).subtract(1, 'days').format('YYYY-MM-DD')}
                          />
                          <DatePicker
                            className="ml-2"
                            label={'Pick-up date ending at'}
                            minDate={startOfPeriod}
                            value={endOfPeriod && endOfPeriod !== '' ? endOfPeriod : null}
                            onChange={(date) => setEndOfPeriod(date ? moment(date).format('YYYY-MM-DD') : '')}
                            clearable={true}
                          />
                        </div>
                        <CustomButton
                          paddingVertical={4}
                          className={classes.downloadButton}
                          variant={'contained'}
                          label={'Search'}
                          onClick={() => {
                            handleSearch();

                          }}
                        />
                      </div>
                      {
                        bulkPickUpDetails && bulkPickUpDetails.data && bulkPickUpDetails.data.bulk_pickup_details && (
                          <BulkPickUpTable data={bulkPickUpDetails.data.bulk_pickup_details} month={getMonthName(datePeriod.month, 'long')} year={datePeriod.year} handleMonthsFetch={handleMonthsFetch} monthFilterParent={monthFilter} handleGetData={handleGetData} displayAll={displayAll}/>
                        )
                      }
                    </PortletBody>
                  </Portlet>


                </div>
              )}
            </div>}

      </div>
    </>
  );
};

/**
 * Map state to props
 */
const mapStateToProps = (store) => ({
  majorDetails: store.reportsDashboardReducer.majorDetails,
  bulkPickUpDetails: store.reportsDashboardReducer.bulkPickUpDetails,
  loading: store.reportsDashboardReducer.bpLoading,
  error: store.reportsDashboardReducer.error,
  municipality: store.common.district.district_id,
  isUserPro: store.common.isUserPro,
  stage: store.common.district.stage,
  district: store.common.district,
  municipalities: store.common.municipalities,
  hasCounty: store.auth.user_info.counties,
  counties: store.common.counties,
  county: store.common.county,
  isCounty: store.common.isCounty,
  csm: store.dashboard.csm,

});


export default injectIntl(
  connect(
    mapStateToProps, { ...actions, ...sharedActions, ...dashboardActions }
  )(MajorCampaigns)
);
