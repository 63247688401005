import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';

import { Typography } from '../../../components';

import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';


const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: 'white',
    marginRight: 0,
    marginLeft: 0,
    marginTop: '0 !important',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: '8px 0',
    minHeight: 72,
    textTransform: 'uppercase',
    borderBottom: '1px solid lightgrey',


  },
  tableHeaderFont: {
    color: '#404040',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    fontSize: '13px',
  },
  tableRow: {
    borderBottom: '1px solid lightgrey',
    marginLeft: 0,
    marginRight: 0,
    '&::after': {
      borderBottom: '1px solid lightgrey',
    }
  },
  tableLabel: {
    borderBottom: 'none !important',
    borderRadius: '0 !important',
    padding: '0 !important',
  },
  tableBody: {
    padding: '0 !important',
    textAlign: 'left'
  },
  //.filter
  filter: {
    display: "inline-flex",
    border: "1px solid #0086C2",
    borderRadius: "16px"
  },
  //.filter__item
  filterItem: {
    padding: "6px 10px",
    borderRight: "1px solid #0086C2",
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "20px",
    color: "#0086C2",
    textAlign: "center",
    letterSpacing: "0.04em",
    cursor: "pointer",
    "&:first-of-type": {
      borderTopLeftRadius: "16px",
      borderBottomLeftRadius: "16px"
    },
    "&:last-of-type": {
      borderTopRightRadius: "16px",
      borderBottomRightRadius: "16px",
      borderRight: "0"
    }
  },
  //.filter__item:first-of-type
  filterItemFirstOfType: {
    borderTopLeftRadius: "16px",
    borderBottomLeftRadius: "16px"
  },
  //.filter__item:last-of-type
  filterItemLastOfType: {
    borderTopRightRadius: "16px",
    borderBottomRightRadius: "16px",
    borderRight: "0"
  },
  //.filter__item--selected
  filterItemSelected: {
    backgroundColor: 'rgba(0, 134, 194, 0.12)',
    fontWeight: '600',

  }

}));

const EducationDetails = ({
  intl,
  data,
  month,
  year
}) => {
  const classes = useStyles();

  const [monthFilter, setMonthFilter] = useState(1);


  const getDateSeason = (date) => {
    const month = date.getMonth();
    if (month >= 2 && month <= 4) {
      return 'Spring';
    } else if (month >= 5 && month <= 7) {
      return 'Summer';
    } else if (month >= 8 && month <= 10) {
      return 'Fall';
    } else {
      return 'Winter';
    }
  };


  const filterBy = (months) => () => {
    setMonthFilter(months);
  };


  return (
    <>
      <Portlet>
        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start">
                <div className="row col-xs-12 col-lg-12 align-items-center" style={{ justifyContent: 'space-between' }} >
                  <div className="align-items-center pl-3">
                    <Typography variant="h1">
                      Learning Activities
                    </Typography>
                  </div>
                  <div className={`${classes.filter}`}>
                    <div className={`${classes.filterItem} ${monthFilter === 1 ? classes.filterItemSelected : ''}`} onClick={filterBy(1)}>
                      {month} {year}
                    </div>
                    <div className={`${classes.filterItem} ${monthFilter === 3 ? classes.filterItemSelected : ''}`} onClick={filterBy(3)}>
                      Last 3 Months
                    </div>
                    <div className={`${classes.filterItem} ${monthFilter === 8 ? classes.filterItemSelected : ''}`} onClick={filterBy(6)}>
                      Last 6 Months
                    </div>
                    <div className={`${classes.filterItem} ${monthFilter === 12 ? classes.filterItemSelected : ''}`} onClick={filterBy(12)}>
                      Last 12 Months
                    </div>
                  </div>
                </div>
              </div>
            )}
          />
        </div>
        <PortletBody style={{ padding: '25px 0px' }}>
          <div className="row" style={{ margin: '0px' }}>

            {
              data && data.length && (
                <div style={{ width: '100%' }}>
                  <PortletBody className={`${classes.tableBody}`}>
                    <div className={`row d-flex align-items-center mt-4 ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableHeader}`}>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Activity Name
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Unique Users
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Total Impressions
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Total Engagements
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Engagement Rate
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Knowledge Measurement Engagements
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Correct Answer Rate
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Time Spent
                        </Typography>
                      </div>
                      <div className="col row d-flex align-items-center ml-2">
                        <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                          Campaign Replacement Value
                        </Typography>
                      </div>
                    </div>


                    <div className={classes.dataTable}>
                      {
                        data.map((item) => (
                          <div
                            key={item.id}
                            className={`row d-flex align-items-center pt-4 pb-4 ${classes.border} ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableRow}`}
                          >
                            <div className="col row d-flex ml-2">
                              <Typography variant="bodySemiBold" className="col">
                                {item.title}
                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography className="col-6" variant="bodySemiBold">
                                {/* {getDateSeason(new Date(item.end_dt))}
                                {' '}
                                ({moment(new Date(item.start_dt)).format('MMM M')}
                                {' '}
                                -
                                {' '}
                                {moment(new Date(item.end_dt)).format('MMM M, YYYY')}
                                ) */}
                                {item.unique_user}

                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography variant="bodySemiBold" className="col">
                                {item.exposure}
                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography className="col-3" variant="bodySemiBold">
                                {item.engagement}

                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography variant="bodySemiBold" className="col">
                                {item.engagement_rate}
                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography className="col-3" variant="bodySemiBold">
                                {item.knowledge_measurement_eng}
                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography variant="bodySemiBold" className="col">
                                {item.correct_answer_rate}
                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography variant="bodySemiBold" className="col">
                                {item.time_spent}
                              </Typography>
                            </div>
                            <div className="col row d-flex align-items-center ml-2">
                              <Typography variant="bodySemiBold" className="col">
                                $
                                {item.campaign_replacement}
                              </Typography>
                            </div>
                          </div>

                        ))
                      }
                    </div>
                  </PortletBody>
                </div>
              )

            }

          </div>
        </PortletBody>
      </Portlet>
    </>
  );
};

export default injectIntl(EducationDetails);
