import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CircularProgress, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Dropdown } from 'react-bootstrap';

import {
  Typography, TextField, CustomButton
} from '../../components';

import * as actions from '../redux/actions';
import * as CategoryActions from '../../Category/redux/actions';
import * as sharedActions from '../../redux/actions';

const useStyles = makeStyles(() => ({
  modalContainer: {
    width: 600,
    margin: 0,
    padding: '5px 25px',
  },
  buttonsContainer: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    // transform: 'translateY(30px)'
  },
  dropdown: {
    '& button': {
      padding: 0
    }
  },
  dropdownIcon: {
    width: '100%',
    '&::after': {
      display: 'none !important'
    },
  },
  dropdownMenu: {
    width: '100%',
    borderRadius: 0,
    maxHeight: 180,
    overflow: 'auto',
    '& span': {
      justifyContent: 'flex-start'
    }
  },
  dropdownItem: {
    padding: '5px 15px !important',
  },
  noMaterial: {
    padding: '14px !important',
  },
  textFiled: {
    width: 345,
  }
}));
const Modal = ({
  intl,
  locality,
  search,
  open,
  onClose,
  submitAPhoto = false,
  title,
  searchMaterialsRequest,
  loading,
  sendSubmittedPhotoRequestAction,
  sendSuggestedNewTermRequestAction
}) => {
  const classes = useStyles();

  /**
   * Material search states and fucntions.
   */
  const [materialSearch, setMaterialSearch] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState('');
  const [selectedItemMatId, setSelectedItemMatId] = useState('');

  const handleSearch = (value) => {
    setMaterialSearch(value);
    if (value.length > 3) {
      searchMaterialsRequest({
        locality: locality.cms_locality_id,
        search: value,
      });
    }
  };

  const handleSearchClick = (item) => {
    setMaterialSearch(item.display);
    setSelectedItemName(item.display);
    setSelectedItemMatId(item.mat_id);
  };

  /**
   * Photo upload states, ref and functions.
   */
  const inputRef = useRef();
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  // opens chose photo tool
  const uploadAPhotoClickHandler = () => inputRef.current.click();

  // select photo function
  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  // set preview
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  // const [checkboxValue, setCheckboxValue] = useState(true);
  const [suggestedTerm, setSuggestedTerm] = useState('');
  const [additionalComments, setAdditionalComments] = useState('');

  /**
   * Variables for disabling/enabling "Submit" button.
   */
  // eslint-disable-next-line max-len
  const submitAPhotoDisabled = !selectedFile || !selectedItemName || !materialSearch.length;
  const suggestATermDisabled = !suggestedTerm.length;
  const disabled = submitAPhoto ? submitAPhotoDisabled : suggestATermDisabled;

  return (
    <>
      {open && (
        <Dialog open={open} onClose={onClose}>
          <div className={`d-flex align-items-center flex-column ${classes.modalContainer}`}>
            <Typography color="#37474F" className="my-4" variant="h2">
              {intl.formatMessage({ id: title })}
            </Typography>

            {/* for submit a photo modal */}
            {submitAPhoto ? (
              <>
                <div className="col-8">
                  <Dropdown
                    className={classes.dropdown}
                    drop="down"
                    show={isSearchOpen}
                  >
                    <Dropdown.Toggle
                      variant="transparent"
                      className={classes.dropdownIcon}
                    >
                      <TextField
                        label={intl.formatMessage({ id: 'WGW.CHOOSE.A.MATERIAL' })}
                        value={materialSearch}
                        onChange={handleSearch}
                        onFocus={() => setIsSearchOpen(true)}
                        onBlur={() => setTimeout(() => setIsSearchOpen(false), 300)}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={`dropdown-menu-fit dropdown-menu-right ${classes.dropdownMenu}`}>
                      {materialSearch.length > 3 && search.length > 0 ? search.map((item) => (
                        <CustomButton
                          key={item.mat_id}
                          className={classes.dropdownItem}
                          onClick={() => handleSearchClick(item)}
                          label={(
                            <Typography variant="bodySemiBold">
                              {item.display}
                            </Typography>
                          )}
                        />
                      )) : (
                        <Typography className={classes.noMaterial} variant="bodySemiBold">
                          {intl.formatMessage({ id: 'CATEGORY.WGW.DROP_DOWN.EMPTY_MATERIAL' })}
                        </Typography>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="d-flex flex-column align-items-center my-4">
                  <input
                    id="photo"
                    type="file"
                    ref={inputRef}
                    onChange={onSelectFile}
                    className="d-none"
                  />
                  {selectedFile
                    && (
                      <>
                        <img alt={selectedFile.name} style={{ borderRadius: 5, height: 120, marginBottom: 5 }} height="120px" src={preview} />
                        <div>
                          {selectedFile.name}
                        </div>
                      </>
                    )}

                  <CustomButton
                    onClick={uploadAPhotoClickHandler}
                    variant="contained"
                    fontSize={13}
                    paddingVertical={3}
                    style={{ width: 'max-content', marginTop: 25 }}
                    label={<FormattedMessage id={selectedFile ? 'GENERAL.REPLACE' : 'WGW.SUBMIT.A.PHOTO'} />}
                  />
                </div>
              </>
            ) : (
              // for suggesting a new term
              <>
                <div className={`${classes.textFiled} pb-3 mb-2`}>
                  <TextField
                    label={intl.formatMessage({ id: 'GENERAL.NEW.TERM' })}
                    onChange={(e) => {
                      setSuggestedTerm(e);
                    }}
                    value={suggestedTerm}
                  />
                </div>
                <div className={classes.textFiled}>
                  <TextField
                    defaultRow={3}
                    isMulti
                    maxRow={3}
                    label={intl.formatMessage({ id: 'WGW.ADDITIONAL.COMMENTS' })}
                    onChange={(e) => {
                      setAdditionalComments(e);
                    }}
                    value={additionalComments}
                  />
                </div>
              </>
            )}

            {/* <div>
              <CheckBox
                label={(
                  <Typography className={classes.checkboxLabel} variant="body">
                    {intl.formatMessage({
                      id: submitAPhoto
                        ? 'WGW.SUBMIT.A.PHOTO.CHECKBOX'
                        : 'WGW.SUGGEST.A.NEW.SEARCH.TERM.CHECKBOX'
                    })}
                  </Typography>
                )}
                checked={checkboxValue}
                onChange={() => setCheckboxValue((prevState) => !prevState)}
              />
            </div> */}

            {/* buttons */}
            <div className={`d-flex pt-4 pb-3 ${classes.buttonsContainer}`}>
              <CustomButton
                onClick={onClose}
                variant="outlined"
                fontSize={13}
                paddingVertical={1}
                label={<FormattedMessage id="GENERAL.CANCEL" />}
              />
              <CustomButton
                className="ml-3"
                onClick={submitAPhoto
                  ? () => sendSubmittedPhotoRequestAction(selectedFile, decodeURIComponent(selectedItemMatId))
                  : () => sendSuggestedNewTermRequestAction(suggestedTerm, additionalComments)}
                variant="contained"
                disabled={disabled}
                fontSize={13}
                paddingVertical={1}
                label={loading
                  ? <CircularProgress color="#fff" style={{ height: 25, width: 25 }} />
                  : <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />}
              />
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (store) => ({
  localityList: store.common.localityList,
  locality: store.common.locality,
  search: store.category.search,
  loading: store.WGWHome.loading,
});

export default injectIntl(
  connect(mapStateToProps,
    {
      ...actions,
      ...sharedActions,
      ...CategoryActions
    })(Modal)
);
