import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { campaignCdnAddress, defaultImageAddress } from '../apis';
import { CampaignPicture } from '../../../assets/icons';

const useStyles = makeStyles({
  imageStyle: {
    minWidth: 250,
    width: '100%',
    maxWidth: '100%',
    minHeight: 170,
    maxHeight: '100%',
    height: '100%',
    border: 'none',
    backgroundColor: '#ebebeb',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'relative'
  },
  defaultImage: {
    fontSize: 50,
    margin: 'auto',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'block'
  }
});

const Image = ({ src, title }) => {
  const { imageStyle, defaultImage } = useStyles();

  if (src) {
    return (
      <div
        role="img"
        className={imageStyle}
        style={{ backgroundImage: `url(${campaignCdnAddress}/${src})` }}
        title={title}
      />
    );
  }

  // Use Default Image
  return (
    <div
      role="img"
      className={imageStyle}
      title={title}
    >
      <CampaignPicture
        className={defaultImage}
        color="#fafafa"
      />
    </div>
  );
};

export default Image;
