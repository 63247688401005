const actionTypes = {
  // Badge report actionTypes for setting loading status
  SET_BADGE_REPORT_LOADING_ACTION: 'SET_BADGE_REPORT_LOADING_ACTION',

  // Badge report actionTypes for setting all time mode
  SET_BADGE_REPORT_ALL_TIME_ACTION: 'SET_BADGE_REPORT_ALL_TIME_ACTION',

  // Badge report actionTypes for fetching report data
  GET_BADGE_REPORT_REQUEST: 'GET_BADGE_REPORT_REQUEST',
  GET_BADGE_REPORT_RECEIVE: 'GET_BADGE_REPORT_RECEIVE',
  GET_BADGE_REPORT_FAIL: 'GET_BADGE_REPORT_FAIL',

};

export default actionTypes;
