/**
 *
 */
const initialState = {
  fetchState: 'no-request',
  data: {},
  pageType: { type: 'Collection Requirement', id: 'Collection Requirements' },
  reportType: { name: 'Current Period', value: 0 },
};

export default initialState;
