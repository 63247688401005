import { FormattedMessage, } from 'react-intl';
import React from 'react';
import { validation } from '../../../utils';


export const municipalityCheck = (data) => {
  if (!validation(data, 'required')) {
    return <FormattedMessage id="VALIDATION.REQUIRED_FIELD" />;
  }
  return 0;
};


export const usernameCheck = (data, payload) => {
  if (!validation(data, 'required')) {
    return <FormattedMessage id="VALIDATION.REQUIRED_FIELD" />;
  } if (!validation(data, 'atLeast4Characters')) {
    return <FormattedMessage id="VALIDATION.MIN_LENGTH" />;
  }
  if (validation(data, 'atLeast4Characters') && payload === true) {
    return <FormattedMessage id="USERS.FORM.USERNAME.DUPLICATE" />;
  }
  return 0;
};

export const emailCheck = (data, payload) => {
  if (!validation(data, 'required')) {
    return <FormattedMessage id="VALIDATION.REQUIRED_FIELD" />;
  }
  if (!validation(data, 'email')) {
    return <FormattedMessage id="USERS.FORM.INPUT.EMAIL.INVALID" />;
  }
  if (validation(data, 'email') && payload === true) {
    return <FormattedMessage id="USERS.FORM.EMAIL.DUPLICATE" />;
  }
  return 0;
};


export const phoneCheck = (data) => {
  if (!validation(data, 'phone')) {
    return <FormattedMessage id="USERS.FORM.INPUT.PHONE.INVALID" />;
  }
  return 0;
};
