import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { Typography } from '.';

const RadioButton = ({
  firstLabel,
  firstValue,
  secondLabel,
  secondValue,
  row,
  isDisable,
  onChange,
  value,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginBottom: 0,
    },
    label: {
      width: '100%',
    },
    nowrap: {
      whiteSpace: 'nowrap'
    },
    emergency: {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1)
    }
  }));

  const classes = useStyles();

  return (
    <FormControl component="fieldset" disabled={isDisable} className="w-100">
      <RadioGroup
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        {row ? (
          <div className="row">
            <div className="col-xl-4">
              <FormControlLabel
                value={firstValue}
                control={<Radio color="primary" />}
                label={(
                  <Typography variant="h2">
                    {firstLabel}
                  </Typography>
                )}
                className="text-dark"
              />
            </div>
            <div className="col-xl-4">
              <FormControlLabel
                value={secondValue}
                control={<Radio color="primary" />}
                label={(
                  <Typography variant="h2">
                    {secondLabel}
                  </Typography>
                )}
                className="text-dark"
              />
            </div>
          </div>
        )
          : (
            <div className="w-100">
              <FormControlLabel
                classes={{
                  root: classes.root,
                  label: classes.label,
                }}
                value={firstValue}
                control={<Radio color="primary" />}
                label={(
                  <div className="row d-flex align-items-center pl-3">
                    <Typography className={classes.nowrap} variant="h2">
                      {firstLabel}
                    </Typography>
                  </div>
                )}
              />
              <FormControlLabel
                className="w-100"
                classes={{
                  root: classes.root,
                  label: classes.label,
                }}
                value={secondValue}
                control={<Radio color="primary" />}
                label={
                  typeof (secondLabel) === 'string' ? (
                    <div className="row d-flex align-items-center pl-3">
                      <Typography className={classes.nowrap} variant="h2">
                        {secondLabel}
                      </Typography>
                    </div>
                  ) : secondLabel
              }
              />
            </div>
          )}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButton;
