import React from 'react';
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { connect } from 'react-redux';

import UserReport from './User';
import InteractionReport from './Interaction';
import MaterialReport from './Material';
import NewSubscribersReport from './NewSubscribers';
import PageViewsReport from './PageViews';
import ProblemReport from './Problem';
import CampaignReport from './Campaign';
import DiscoveryZoneReport from './DiscoveryZone';
import ResidentKnowledgeIndicator from './ResidentKnowledgeIndicator';
import BadgeReport from './Badge';
import ReportDashboard from './ReportDashboard';
import AnnualSurvey from './AnnualSurvey';
import ReportDashboardNew from './ReportDashboardNew';
import EducationDetail from './EducationDetail';
import MajorCampaignsDetail from './MajorCampaignsDetail';
import MaterialsDetail from './MaterialsDetails';
import BulkPickUpDetail from './BulkPickUpDetails';
import WGWMaterialDetail from './WGWMaterialDetails';




const ReportRoutes = (({
  isUserPro,
  advocateAdmin
}) => (
  <Switch>
    <Redirect exact from="/report" to="/report/home" />
    <Route path="/report/home" component={ReportDashboard} />
    <Route path="/report/user" component={UserReport} />
    <Route path="/report/interaction" component={InteractionReport} />
    <Route path="/report/material" component={MaterialReport} />
    <Route path="/report/new-subscribers" component={NewSubscribersReport} />
    <Route path="/report/page-views" component={PageViewsReport} />
    {advocateAdmin !== true && <Route path="/report/problem" component={ProblemReport} />}
    { advocateAdmin !== true && <Route path="/report/campaign" component={CampaignReport} />}
    {
      advocateAdmin !== true
      && <Route path="/report/discovery-zone" component={DiscoveryZoneReport} />
    }
    { advocateAdmin !== true && <Route path="/report/resident-knowledge-indicator" component={ResidentKnowledgeIndicator} />}
    { advocateAdmin !== true && <Route path="/report/badge" component={BadgeReport} />}
    { advocateAdmin !== true && <Route path="/report/annual-survey" component={AnnualSurvey} />}
    { advocateAdmin !== true && <Route path="/report/dashboard" component={ReportDashboardNew} />}
    { advocateAdmin !== true && <Route path="/report/education/detail" component={EducationDetail} />}
    { advocateAdmin !== true && <Route path="/report/major-campaign/detail" component={MajorCampaignsDetail} />}
    { advocateAdmin !== true && <Route path="/report/materials/detail" component={MaterialsDetail} />}
    { advocateAdmin !== true && <Route path="/report/bulk-pickup/detail" component={BulkPickUpDetail} />}
    { advocateAdmin !== true && <Route path="/report/wgw-material/detail" component={WGWMaterialDetail} />}






    <Redirect to="/error" />
  </Switch>
));

const mapStateToProps = (store) => ({
  isUserPro: store.common.isUserPro,
  advocateAdmin: store.auth.advocateAdmin
});

export default connect(
  mapStateToProps,
  null
)(ReportRoutes);
