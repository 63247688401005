import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '../../../components';
import { InfoIcon } from '../../../../assets/icons';
import { addCommasToNumber } from '../../../../utils';

const UserDetailRow = ({
  intl,
  title,
  color,
  description,
  total,
  borderNone = false
}) => {
  const useStyles = makeStyles({
    container: {
      padding: '25px 20px',
      display: 'flex',
      marginRight: 20
    },
    contentStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    circle: {
      width: 25,
      height: 25,
      borderRadius: '50%',
      marginRight: 15,
      display: 'inline-block'
    },
    numberStyle: {
      paddingTop: 5,
      color
    }
  });

  const {
    container,
    contentStyle,
    circle,
    numberStyle
  } = useStyles();

  return (
    <div className={container} style={{ borderBottom: borderNone ? 'none' : `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}` }}>
      <div>
        <div
          className={circle}
          style={{ backgroundColor: color }}
        />
      </div>
      <div className={contentStyle}>
        <div>
          <Typography variant="h2">
            {intl.formatMessage({ id: title })}
            <IconButton tooltip={intl.formatMessage({ id: description })}>
              <InfoIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
            </IconButton>
          </Typography>
          <Typography className={numberStyle} variant="numberLarge">
            {addCommasToNumber(total)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(UserDetailRow);
