import { put, call, takeLatest } from 'redux-saga/effects';
import actionTypes from './actionTypes';
import * as actions from './actions';
import { feedbackAPI } from '../apis';


function* feedbackSaga({ payload: { meta, feedback, type } }) {
  const response = yield call(feedbackAPI, { meta, feedback, type });
  try {
    yield put(actions.feedbackReceive(response.data));
  } catch (error) {
    yield put(actions.feedbackFail(response.error));
  }
}


function* pdfAddOnsSaga() {
  yield takeLatest(actionTypes.FEEDBACK_REQUEST, feedbackSaga);
}

export default pdfAddOnsSaga;
