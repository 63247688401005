import React from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { addCommasToNumber } from '../../../../utils';
import { InfoTooltip, IconButton, Typography } from '../../../components';
import { ExpandIcon, CollapseIcon } from '../../../../assets/icons';

const useStyles = makeStyles((theme) => ({
  border: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
  },
  value: {
    color: '#3cbacd'
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: '#3cbacd'
  },
  icon: {
    width: 30,
    height: 30
  },
  smallerThanMdHide: {
    [theme.breakpoints.down(1200)]: {
      display: 'none !important'
    }
  },
  smallerThanMdShow: {
    [theme.breakpoints.up(1200)]: {
      display: 'none !important'
    },
  },

}));

const UserReportCommunicationDetail = ({
  intl,
  data,
  isDashboard,
  expanded,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.border}>
      <div className="row d-flex align-items-center p-4">
        <div className={`col-1 mr-3 mr-xl-0 ${classes.smallerThanMdHide}`}>
          <div className={classes.circle} />
        </div>
        <div className={`col-10 col-xl ${classes.smallerThanMdHide}`}>
          <Typography className="d-flex align-items-center" variant="h2">
            {intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.COMMUNICATION.TITLE' })}
            <InfoTooltip tooltip={intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.COMMUNICATION.DESCRIPTION' })} />
          </Typography>
        </div>
        <div className={`col-11 col-xl-4 mt-3 mt-xl-0 ${classes.smallerThanMdHide}`}>
          <Typography className={classes.value} variant="numberLarge">
            {addCommasToNumber(data.total)}
          </Typography>
        </div>
        {!isDashboard
        && (
          <div className={`col-1 mt-3 mt-xl-0 ${classes.smallerThanMdHide}`}>
            {data && data.detail && data.detail.length !== 0 && (
            <>
              {expanded
                ? (
                  <IconButton onClick={onClick}>
                    <CollapseIcon className={classes.icon} />
                  </IconButton>
                )
                : (
                  <IconButton onClick={onClick}>
                    <ExpandIcon className={classes.icon} />
                  </IconButton>
                )}
            </>
            )}
          </div>
        )}

        <div className={`d-flex w-100 p-0 ${classes.smallerThanMdShow}`}>
          <div className={`mr-5 ${classes.circle}`} />
          <div className="row p-0 w-100 justify-content-between">
            <div className="d-flex flex-column justify-content-start">
              <div className="row pl-3">
                <Typography className="d-flex align-items-center" variant="h2">
                  {intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.COMMUNICATION.TITLE' })}
                  <InfoTooltip tooltip={intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.COMMUNICATION.DESCRIPTION' })} />
                </Typography>
              </div>
              <Typography className={`${classes.value}`} variant="numberLarge">
                {addCommasToNumber(data.total)}
              </Typography>
            </div>
            {!isDashboard
        && (
        <div className="d-flex mt-3">
          {Boolean(data && (data.detail || data.iOS)) && (
          <>
            {expanded
              ? (
                <IconButton onClick={onClick}>
                  <CollapseIcon className={classes.icon} />
                </IconButton>
              )
              : (
                <IconButton onClick={onClick}>
                  <ExpandIcon className={classes.icon} />
                </IconButton>
              )}
          </>
          )}
        </div>
        )}
          </div>
        </div>

      </div>
      {expanded && (
        <>
          <div className={`row d-flex justify-content-end ${classes.smallerThanMdHide}`}>
            {data.detail.map((item, index) => (
              <div key={item.title} className={`col-11 row d-flex align-items-start pb-4 pr-4 pl-4 ${index !== 0 ? 'pt-4' : null} ${index !== data.detail.length - 1 ? classes.border : null}`}>
                <div className="col-6 pl-0">
                  <Typography variant="h2">
                    {item.title}
                  </Typography>
                </div>
                <div className="col pl-5 mt-3 mt-xl-0">
                  <Typography variant="h2">
                    {addCommasToNumber(item.value)}
                  </Typography>
                </div>
              </div>
            ))}
          </div>

          <div className={`row d-flex justify-content-center ${classes.smallerThanMdShow}`}>
            {data.detail.map((item, index) => (
              <div key={item.title} className={`col-11 row d-flex align-items-start pb-4 pr-4 pl-4 ${index !== 0 ? 'pt-4' : null} ${index !== data.detail.length - 1 ? classes.border : null}`}>
                <div className="col-6 pl-0">
                  <Typography variant="h2">
                    {item.title}
                  </Typography>
                </div>
                <div className="col ml-4 mt-3 mt-xl-0">
                  <Typography variant="h2">
                    {addCommasToNumber(item.value)}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default injectIntl(UserReportCommunicationDetail);
