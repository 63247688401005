import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import { DropdownMenu } from '../../../Education/EducationalContent/components';

import moment from 'moment';
import { SummaryCellLoading, SummaryOtherLoading } from '../../../components/loading';


import { Typography, InfoTooltip } from '../../../components';

import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';

import { EducationModal } from '../components';

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 500,
    '@media (max-width: 1390px)': {
      height: '100%',
      maxHeight: 'unset !important',
    },
    '@media (max-width: 1200px)': {
      height: 'unset !important',
      maxHeight: 'unset !important',
    }
  },
  group: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
  },
  groupItem: {
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid #E7ECEF',
    borderRadius: 9,
    ChangeTheColorBorderRadius: '8px',
    padding: '12px',
    // width: "25%",
    width: 235,
    // maxWidth: "200px",
    MightNeedFullWidthSoTheItemsDontGetTooBigOnLargeScreenColor: '#63666A',
    ChangeTheColorFontFamily: 'sans-serif',
    cursor: 'pointer',
    color: '#404040',
  },
  groupTitle: {
    fontWeight: '600',
    fontSize: 14,
  },
  groupNumber: {
    fontSize: '24px',
    fontWeight: '700',
    marginTop: '6px',
    marginBottom: '6px'
  },
  groupChange: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: '500',
    fontSize: 16,
  },
  groupArrow: {
    marginRight: '6px'
  },
  groupChangePositive: {
    // color: '#27ED6A'
    color: '#409A5F'
  },
  groupChangePositiveActive: {
    color: '#27ED6A'
  },
  groupChangeNegativet: {
    color: '#FF1E1E'
  },
  groupChangeNegativeGroupArrowClass: {
    transform: 'rotate(180deg)'
  },
  groupItemHighlighted: {
    backgroundColor: '#2A95FF',
    color: 'white'
  },
  lists: {
    display: 'flex',
    flexDirection: 'row',
    gap: '80px'
  },
  list__heading: {
    color: '#63666A', /* Change the color */
    fontFamily: 'sans-serif',
    textTransform: 'uppercase',
    fontSize: '0.8rem', /* Change as needed */
    marginBottom: '16px'
  },
  list__rows: {
    display: 'flex',
    flexDirection: 'column',
    color: '#63666A', /* Change the color */
    fontFamily: 'sans-serif'
  },
  list__row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    height: '2rem'
  },
  list__number: {
    marginRight: '6px',
    fontWeight: 600 /* Change as needed */
  },
  list__arrow: {
    marginRight: 6
  },
  list__change: {
    display: 'flex',
    flexDirection: 'row'
  },

  /* Item shows increase */
  list__changePositive: {
    color: '#27ED6A'
  },
  /* Item shows decrease */
  list__changeNegative: {
    color: '#FF1E1E'
  },
  list__changeNegativeList__arrow: {
    transform: 'rotate(180deg)'
  },

  /* Legend for the circle graph */
  list__legend: {
    backgroundColor: 'grey',
    color: 'white',
    borderRadius: 4,
    padding: '4px 10px',
    marginRight: 20
  },
  list__legend1: {
    backgroundColor: '#2A95FF' /* Colors correspond to sections of the graph */
  },
  list__legend2: {
    backgroundColor: '#A663FF' /* Colors correspond to sections of the graph */
  },
  list__legend3: {
    backgroundColor: '#97CCFF' /* Colors correspond to sections of the graph */
  },
  list__legend4: {
    backgroundColor: '#00C192' /* Colors correspond to sections of the graph */
  },
  list__legend5: {
    backgroundColor: '#FFCD5B' /* Colors correspond to sections of the graph */
  },
  list__topic: {
    fontWeight: 600
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  legendContainer: {
    position: 'relative',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 100,
    display: "flex",
    gap: "16px"
  },
  legend: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    textTransform: "uppercase",
    fontFamily: "sans-serif"
  },
  legendCircle: {
    content: "''",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    border: "2px solid rgba(118, 203, 196, 1)"
  },
  legendLine: {
    position: "relative",
    top: "0px",
    width: "20px",
    margin: "0 6px 0 0",
    height: "2px",
    left: "1px"
  },
  legendDottedLegendLine: {

    borderTop: "2px dashed rgba(118, 203, 196, 1)"
  },
  legendDottedLegendCircle: {
    content: "''",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    border: "2px solid rgba(118, 203, 196, 1)"
  },
  legendSolidLegendLine: {
    borderTop: "2px solid rgba(42, 149, 255, 1)"
  },
  legendSolidLegendCircle: {
    content: "''",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    border: "2px solid rgba(42, 149, 255, 1)"
  }


}));

const Education = ({
  intl,
  reportData,
  reportMonth,
  reportYear,
  isLoading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openTitle, setOpenTitle] = useState(false);


  // useEffect(() => {
  //   getReportDashboardRequest({
  //     municipality,
  //     month: datePeriod.month,
  //     year: datePeriod.year,
  //   });
  // }, [datePeriod]);

  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const educationLabels = [];
  let educationDatapointsCurrentYear = [];
  let educationDatapointsLastYear = [];
  const educationExposureDatapointsCurrentYear = [];
  const educationExposureDatapointsLastYear = [];
  const educationEngagementDatapointsCurrentYear = [];
  const educationEngagementDatapointsLastYear = [];
  const educationCorrectAnswerRateDatapointsCurrentYear = [];
  const educationCorrectAnswerRateDatapointsLastYear = [];
  const educationTimeRateDatapointsCurrentYear = [];
  const educationTimeRateDatapointsLastYear = [];

  let educationExposureTotalCurrentYear = 0;
  let educationExposureTotalLastYear = 0;
  let educationEngagementTotalCurrentYear = 0;
  let educationEngagementTotalLastYear = 0;
  let educationCorrectAnswerRateTotalCurrentYear = 0;
  let educationCorrectAnswerRateTotalLastYear = 0;
  let educationTimeRateTotalCurrentYear = 0;
  let educationTimeRateTotalLastYear = 0;


  let educationData;
  const educationStats = [];

  const [educationSelection, setEducationSelection] = useState('exposure');
  const [modalDescriptionSelection, setModalDescriptionSelection] = useState('exposure');


  /**
 * Menu and menuitem functions.
 */

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElTitle, setAnchorElTitle] = useState(null);

  const handleClick = (section, event) => {
    setModalDescriptionSelection(section);
    setAnchorEl(event.currentTarget);
  };
  const handleClickTitle = (event) => {
    setAnchorElTitle(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElTitle(null);

  };

  const descriptionHandler = (e) => {
    console.log('descriptionHandler', e, anchorEl)

    setOpen(true);
  };

  const descriptionHandlerTitle = (e) => {
    setOpenTitle(true);
  };

  const viewDetailsHandler = (e) => {
    history.push('/report/education/detail');
  };

  const closeModal = () => {
    setOpen(false);
    setOpenTitle(false);
    handleClose();
  }

  /**
* Data for dropdown menu items.
*/
  const menuItemData = [
    {
      id: 1,
      name: 'Description',
      method: descriptionHandler,
      disabled: false
    },
    {
      id: 2,
      name: 'View Details',
      method: viewDetailsHandler,
      disabled: false
    },

  ];
  /**
* Data for dropdown menu items.
*/
  const menuItemDataTitle = [
    {
      id: 1,
      name: 'Description',
      method: descriptionHandlerTitle,
      disabled: false
    },

  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          return `${moment(tooltipItem[0].xLabel, 'MMM').format('MMMM')} ${tooltipItem[0].datasetIndex > 0 ? Number(tooltipItem[0].xLabel.substr(4, 7)) : Number(tooltipItem[0].xLabel.substr(4, 7)) - 1} `;
          // return `${moment(tooltipItem[0].xLabel, 'MMM').format('MMMM')} ${tooltipItem[0].datasetIndex > 0 ? tooltipItem[0].xLabel.substr(4, 7) : reportYear - 1} `;

        },
        label: function (tooltipItem, data) {
          return tooltipItem.yLabel.toLocaleString();
        }
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          // display: false
        },
        ticks: {
          callback: function (value, index, ticks) {
            return value.substr(0, 3);
          }
        }
      }],
      yAxes: [{
        gridLines: {
          // display: false
        },
        ticks: {
          callback: function (value, index, ticks) {
            return value.toLocaleString();
          }
        }
      }]
    }
  };

  if (educationSelection === 'correct_answer_rate') {
    options.scales.yAxes[0].ticks = {
      beginAtZero: true,
      suggestedMax: 100,

      callback: function (value, index, ticks) {
        return value + '%';
      }
    }
  } else if (educationSelection === 'time_spent') {
    const highestTimeSpent = reportData.education['time_spent'] ? Math.round(Math.max(...reportData.education['time_spent']?.map(o => Number(o.value)))) : 0;
    options.tooltips = {
      callbacks: {
        title: function (tooltipItem, data) {
          // return `${moment(tooltipItem[0].xLabel, 'MMM').format('MMMM')} ${tooltipItem[0].datasetIndex > 0 ? reportYear : reportYear - 1} `;
          return `${moment(tooltipItem[0].xLabel, 'MMM').format('MMMM')} ${tooltipItem[0].datasetIndex > 0 ? Number(tooltipItem[0].xLabel.substr(4, 7)) : Number(tooltipItem[0].xLabel.substr(4, 7)) - 1} `;

        },
        label: function (tooltipItem, data) {

          return getFormattedTime(tooltipItem.yLabel)
          // if (highestTimeSpent >= 600) {
          //   return Math.round(tooltipItem.yLabel / 60) + ' H';
          // } else {
          //   return tooltipItem.yLabel + ' M';
          // }
          // return tooltipItem.yLabel.toLocaleString();
        }
      }

    }
    options.scales.yAxes[0].ticks = {
      beginAtZero: true,
      callback: function (value, index, ticks) {
        return getFormattedTime(value, true)
        if (highestTimeSpent >= 600) {
          return Math.round(value / 60) + ' H';
        } else {
          return value + ' M';
        }

      }
    }
  }



  if (reportData && reportData.education && !isLoading) {
    // eslint-disable-next-line no-plusplus

    for (let i = 0; i < reportData.education[educationSelection]?.length; i++) {
      if (i <= 11) {
        educationLabels.push(`${month[reportData.education[educationSelection][i].month - 1]} ${reportData.education[educationSelection][i].year}`);
        educationDatapointsCurrentYear.push(reportData.education[educationSelection][i].value || reportData.education[educationSelection][i].minutes);
      } else {
        educationDatapointsLastYear.push(reportData.education[educationSelection][i].value || reportData.education[educationSelection][i].minutes);
      }
    }

    for (let i = 0; i < reportData.education['exposure']?.length; i++) {
      if (i <= 11) {
        // educationLabels.push(month[reportData.education['exposure'][i].month - 1]);
        educationExposureDatapointsCurrentYear.push(reportData.education['exposure'][i].value || reportData.education['exposure'][i].minutes);
      } else {
        educationExposureDatapointsLastYear.push(reportData.education['exposure'][i].value || reportData.education['exposure'][i].minutes);
      }
    }

    for (let i = 0; i < reportData.education['engagement']?.length; i++) {
      if (i <= 11) {
        // educationLabels.push(month[reportData.education['engagement'][i].month - 1]);
        educationEngagementDatapointsCurrentYear.push(reportData.education['engagement'][i].value || reportData.education['engagement'][i].minutes);
      } else {
        educationEngagementDatapointsLastYear.push(reportData.education['engagement'][i].value || reportData.education['engagement'][i].minutes);
      }
    }

    for (let i = 0; i < reportData.education['correct_answer_rate']?.length; i++) {
      if (i <= 11) {
        // educationLabels.push(month[reportData.education['correct_answer_rate'][i].month - 1]);
        educationCorrectAnswerRateDatapointsCurrentYear.push(reportData.education['correct_answer_rate'][i].value || reportData.education['correct_answer_rate'][i].minutes);
      } else {
        educationCorrectAnswerRateDatapointsLastYear.push(reportData.education['correct_answer_rate'][i].value || reportData.education['correct_answer_rate'][i].minutes);
      }
    }

    for (let i = 0; i < reportData.education['time_spent']?.length; i++) {
      if (i <= 11) {
        // educationLabels.push(month[reportData.education['time_spent'][i].month - 1]);
        educationTimeRateDatapointsCurrentYear.push(reportData.education['time_spent'][i].value || reportData.education['time_spent'][i].minutes);
      } else {
        educationTimeRateDatapointsLastYear.push(reportData.education['time_spent'][i].value || reportData.education['time_spent'][i].minutes);
      }
    }

    // eslint-disable-next-line radix
    educationExposureTotalCurrentYear = educationExposureDatapointsCurrentYear.reduce((acc, o) => acc + parseInt(o), 0);
    educationExposureTotalLastYear = educationEngagementDatapointsLastYear.reduce((acc, o) => acc + parseInt(o), 0);

    // eslint-disable-next-line radix
    educationEngagementTotalCurrentYear = educationEngagementDatapointsCurrentYear.reduce((acc, o) => acc + parseInt(o), 0);
    educationEngagementTotalLastYear = educationEngagementDatapointsLastYear.reduce((acc, o) => acc + parseInt(o), 0);

    // eslint-disable-next-line radix

    educationCorrectAnswerRateTotalCurrentYear = educationCorrectAnswerRateDatapointsCurrentYear.reduce((acc, o) => acc + parseInt(o), 0);
    educationCorrectAnswerRateTotalLastYear = educationCorrectAnswerRateDatapointsLastYear.reduce((acc, o) => acc + parseInt(o), 0);

    // eslint-disable-next-line radix
    educationTimeRateTotalCurrentYear = educationTimeRateDatapointsCurrentYear.reduce((acc, o) => acc + parseInt(o), 0);
    educationTimeRateTotalLastYear = educationTimeRateDatapointsLastYear.reduce((acc, o) => acc + parseInt(o), 0);

    const educationExposures = {
      title: 'Impressions',
      total: educationExposureTotalCurrentYear,
      percentChange: `${((educationExposureTotalLastYear / educationExposureTotalCurrentYear) * 100).toFixed(1)}%`,
      selectionKey: 'exposure',
      descripton: 'Similar to how digital advertising is measured, an Impression is counted when a piece of content is displayed to a user - either through the web app, the Recycle Coach mobile app or via a notification or reminder. The headline number is for the latest month, which is identified in the upper right corner of this page. The percentage change is a comparison to the same month last year.',
      currentMonthTotal: reportData.education['exposure']?.filter((item) => item.month === reportMonth.toString() && item.year === reportYear.toString())[0],
      lastYearCurrentMonthTotal: reportData.education['exposure']?.filter((item) => item.month === reportMonth.toString() && item.year === (reportYear - 1).toString())[0],
    }
    // educationExposures.reportingPercentChange = `${((Number(educationExposures.lastYearCurrentMonthTotal.value) / Number(educationExposures.currentMonthTotal.value)) * 100).toFixed(1)}%`
    educationExposures.reportingPercentChange = educationExposures.currentMonthTotal && educationExposures.currentMonthTotal ? `${(((Number(educationExposures.currentMonthTotal.value) - Number(educationExposures.lastYearCurrentMonthTotal.value)) / Number(educationExposures.currentMonthTotal.value)) * 100).toFixed(1)} %` : '0%'




    const educationEngagements = {
      title: 'Engagements',
      total: educationEngagementTotalCurrentYear,
      percentChange: `${((educationEngagementTotalLastYear / educationEngagementTotalCurrentYear) * 100).toFixed(1)}%`,
      selectionKey: 'engagement',
      descripton: 'An Engagement occurs when a user interacts with a piece of content - for example, if they click on a link or answer a quiz question. The headline number is for the latest month, which is identified in the upper right corner of this page. The percentage change is a comparison to the same month last year.',
      currentMonthTotal: reportData.education['engagement']?.filter((item) => item.month === reportMonth.toString() && item.year === reportYear.toString())[0],
      lastYearCurrentMonthTotal: reportData.education['engagement']?.filter((item) => item.month === reportMonth.toString() && item.year === (reportYear - 1).toString())[0],
    }
    educationEngagements.reportingPercentChange = educationEngagements.currentMonthTotal && educationEngagements.lastYearCurrentMonthTotal ? `${(((Number(educationEngagements.currentMonthTotal.value) - Number(educationEngagements.lastYearCurrentMonthTotal.value)) / Number(educationEngagements.currentMonthTotal.value)) * 100).toFixed(1)}%` : '0%'


    const educationCorrectAnswerRate = {
      title: 'Correct Response Rate',
      total: educationCorrectAnswerRateTotalCurrentYear,
      percentChange: `${((educationCorrectAnswerRateTotalLastYear / educationCorrectAnswerRateTotalCurrentYear) * 100).toFixed(1)}%`,
      selectionKey: 'correct_answer_rate',
      descripton: 'For some types of content the user is asked to provide a response that can be definitively classified as either correct or incorrect. Quiz questions, the Recycle RIGHT game and Recycling Fundamentals are examples. For these types of interactions the Correct Response Rate calculates the percentage of correct responses. The headline number is for the latest month, which is identified in the upper right corner of this page. The percentage change is a comparison to the same month last year.',
      currentMonthTotal: reportData.education['correct_answer_rate']?.filter((item) => item.month === reportMonth.toString() && item.year === reportYear.toString())[0],
      lastYearCurrentMonthTotal: reportData.education['correct_answer_rate']?.filter((item) => item.month === reportMonth.toString() && item.year === (reportYear - 1).toString())[0],
    }
    // educationCorrectAnswerRate.reportingPercentChange = `${((Number(educationCorrectAnswerRate.lastYearCurrentMonthTotal.value) / Number(educationCorrectAnswerRate.currentMonthTotal.value)) * 100).toFixed(1)}%`
    educationCorrectAnswerRate.reportingPercentChange = educationCorrectAnswerRate.currentMonthTotal && educationCorrectAnswerRate.lastYearCurrentMonthTotal ? `${(((Number(educationCorrectAnswerRate.currentMonthTotal.value) - Number(educationCorrectAnswerRate.lastYearCurrentMonthTotal.value)) / Number(educationCorrectAnswerRate.currentMonthTotal.value)) * 100).toFixed(1)}%` : '0%'



    const educationTimeSpent = {
      title: 'Time Spent',
      total: educationTimeRateTotalCurrentYear,
      percentChange: `${((educationTimeRateTotalLastYear / educationTimeRateTotalCurrentYear) * 100).toFixed(1)}%`,
      selectionKey: 'time_spent',
      descripton: 'Another important indicator of engagement, we track the amount of time users spend on the Recycle Coach platform. The amount of time shown is for the latest month, which is identified in the upper right corner of this page. The percentage change is a comparison to the same month last year.',
      currentMonthTotal: reportData.education['time_spent']?.filter((item) => item.month === reportMonth.toString() && item.year === reportYear.toString())[0],
      lastYearCurrentMonthTotal: reportData.education['time_spent']?.filter((item) => item.month === reportMonth.toString() && item.year === (reportYear - 1).toString())[0],
    }
    // educationTimeSpent.reportingPercentChange = `${((Number(educationTimeSpent.lastYearCurrentMonthTotal.value) / Number(educationTimeSpent.currentMonthTotal.value)) * 100).toFixed(1)}%`
    educationTimeSpent.reportingPercentChange = educationTimeSpent.currentMonthTotal && educationTimeSpent.lastYearCurrentMonthTotal ? `${(((Number(educationTimeSpent.currentMonthTotal.value) - Number(educationTimeSpent.lastYearCurrentMonthTotal.value)) / Number(educationTimeSpent.currentMonthTotal.value)) * 100).toFixed(1)}%` : '0%'

    educationStats.push(educationExposures, educationEngagements, educationCorrectAnswerRate, educationTimeSpent);
  }


  if (educationLabels.length) {
    educationData = {
      labels: educationLabels.reverse(),
      datasets: [
        {
          label: 'Last Year',
          data: educationDatapointsLastYear ? educationDatapointsLastYear.reverse() : [],
          borderColor: 'rgba(118, 203, 196, 1)',
          borderDash: [5, 3],
          dashedBorderWidth: 15,
          fill: false,
        },
        {
          label: 'This Year',
          data: educationDatapointsCurrentYear ? educationDatapointsCurrentYear.reverse() : [],
          borderColor: 'rgba(42, 149, 255, 1)',
          backgroundColor: (context) => {
            const { ctx } = context.chart;
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, "rgba(42, 149, 255, 0.2)");
            gradient.addColorStop(1, "rgba(42, 149, 255, 0)");
            return gradient;
          },

        },

      ],
    };
  }


  const getFormattedTime = (seconds, hourOnly) => {
    let d = Number(seconds);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";

    const hours = h + 'H';
    const minutes = m + 'M';
    if (hourOnly) return hours; // return only hours
    return `${hours} ${minutes}`;
  }

  return (
    <>
      {/* <Portlet style={{ height: 456 }}> */}
      <Portlet className={classes.container}>

        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start" style={{ justifyContent: 'space-between' }}>
                <div className="row col-xs-12 col-lg-6 align-items-center">
                  <div className="align-items-center pl-3">
                    <Typography variant="h1">
                      Resident Engagement and Education
                    </Typography>
                  </div>
                </div>
                <DropdownMenu
                  handleClick={handleClickTitle}
                  anchorEl={anchorElTitle}
                  handleClose={handleClose}
                  menuItemData={menuItemDataTitle}
                />
              </div>
            )}
          />
          <EducationModal
            open={openTitle}
            onClose={() => closeModal()}
            title={'Description'}
            content={(
              <Typography variant="body1" style={{ textAlign: 'left' }}>
                This Resident Engagement and Education Report provides a year-over-year comparison for key measurement metrics about how your residents engage with the Recycle Coach platform. Performance numbers shown above the graph are for the latest month, which is shown in the upper right corner of this page. To drill down to see the type of content your residents are interacting with, click on the 3 dots beside each metric name (e.g., “Impressions”) and select “View Details”.

              </Typography>
            )}
          />
        </div>
        <PortletBody>
          <div className="row">
            {
              isLoading ? <div className={classes.group}>
                <div className={`${classes.groupItem}`}>
                  <SummaryCellLoading />
                </div>
                <div className={`${classes.groupItem}`}>
                  <SummaryCellLoading />
                </div>
                <div className={`${classes.groupItem}`}>
                  <SummaryCellLoading />
                </div>
                <div className={`${classes.groupItem}`}>
                  <SummaryCellLoading />
                </div>
              </div> :
                <div className={classes.group}>
                  {
                    educationStats.length && educationStats.map((item) => (
                      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                      isLoading ? <SummaryOtherLoading /> :
                        <div className={`${classes.groupItem} ${educationSelection === item.selectionKey ? classes.groupItemHighlighted : null}`}>
                          <div className={classes.topSection} onClick={() => setEducationSelection(item.selectionKey)}>
                            <div className={classes.groupTitle} onClick={() => setEducationSelection(item.selectionKey)}>{item.title}</div>
                            <DropdownMenu
                              handleClick={((e) => { handleClick(item.selectionKey, e) })}
                              anchorEl={anchorEl}
                              handleClose={handleClose}
                              menuItemData={menuItemData}
                              section={item.selectionKey}
                            />
                          </div>
                          <div onClick={() => setEducationSelection(item.selectionKey)}>
                            {
                              item.currentMonthTotal && item.currentMonthTotal && item.reportingPercentChange && (
                                // <div className={classes.groupNumber}>{item.selectionKey === 'correct_answer_rate' ? item.currentMonthTotal + '%' : item.selectionKey === 'time_spent' ? getFormattedTime(item.value) : item.currentMonthTotal.toLocaleString()}</div>
                                <div className={classes.groupNumber}>{item.selectionKey === 'correct_answer_rate' ? item.currentMonthTotal.value + '%' : item.selectionKey === 'time_spent' ? getFormattedTime(item.currentMonthTotal.value) : Number(item.currentMonthTotal.value).toLocaleString()}</div>


                              )
                            }
                            <div className={`${classes.groupChange} ${parseInt(item.reportingPercentChange) > 0 ? classes.groupChangePositive : classes.groupChangeNegativet} ${educationSelection === item.selectionKey ? classes.groupChangePositiveActive : null}`}>
                              {/* <div className={classes.groupArrow}>↑</div> */}
                              <div className={classes.groupArrow}>{parseInt(item.reportingPercentChange) > 0 ? '↑' : '↓'}</div>

                              <div className={classes.group__percentag}>{item.reportingPercentChange}</div>
                            </div>
                          </div>
                        </div>
                    ))
                  }
                  <EducationModal
                    open={open}
                    onClose={() => closeModal()}
                    title={educationStats.map((item) => { if (item.selectionKey === modalDescriptionSelection) { return item.title; } })}
                    content={(
                      <Typography variant="body1" style={{ textAlign: 'left' }}>
                        {educationStats.map((item) => { if (item.selectionKey === modalDescriptionSelection) { return item.descripton; } })}
                      </Typography>
                    )}
                  />
                </div>
            }

          </div>
          <div className="row mt-3">
            {
              isLoading ? null : (
                <div className={classes.legendContainer}>
                  <div class={`${classes.legend}`}>
                    <div class={classes.legendDottedLegendCircle}></div>
                    <div class={`${classes.legendLine} ${classes.legendDottedLegendLine}`}></div>
                    <div style={{ width: 70 }}>Last Year</div>
                  </div>

                  <div class={`${classes.legend}`}>
                    <div class={classes.legendSolidLegendCircle}></div>
                    <div class={`${classes.legendLine} ${classes.legendSolidLegendLine}`}></div>
                    <div style={{ width: 70 }}>This Year</div>
                  </div>
                </div>
              )
            }

            <div className="col-12" style={{ height: 225 }}>
              {
                isLoading
                  ? <><SummaryOtherLoading /> <SummaryOtherLoading /></>
                  : (
                    educationData ? <Line options={options} data={educationData} /> : <></>
                  )
              }

            </div>

          </div>
        </PortletBody>
      </Portlet>
    </>
  );

};

export default injectIntl(Education);
