import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import UserReportMobileDetail from './UserReportMobileDetail';
import UserReportWebDetail from './UserReportWebDetail';
import UserReportCommunicationDetail from './UserReportCommunicationDetail';
import UserReportCalendarDetail from './UserReportCalendarDetail';

const UserReportDetail = ({
  data,
  isDashboard,
  fetchState,
  advocateAdmin
}) => {
  const [mobileExpanded, setMobileExpanded] = useState(false);
  const [webExpanded, setWebExpanded] = useState(false);
  const [communicationExpanded, setCommunicationExpanded] = useState(false);
  const [calendarExpanded, setCalendarExpanded] = useState(false);

  useEffect(() => {
    if (fetchState === 'send-request') {
      setMobileExpanded(false);
      setWebExpanded(false);
      setCommunicationExpanded(false);
      setCalendarExpanded(false);
    }
  }, [fetchState]);

  return (
    <>
      <UserReportMobileDetail
        data={data.mobile}
        isDashboard={isDashboard}
        expanded={mobileExpanded}
        onClick={() => setMobileExpanded(!mobileExpanded)}
      />
      <UserReportWebDetail
        data={data.web_app}
        isDashboard={isDashboard}
        expanded={webExpanded}
        onClick={() => setWebExpanded(!webExpanded)}
        className={`${advocateAdmin === true ? 'border-bottom-0' : null}`}
      />
      {advocateAdmin !== true
      && (
      <UserReportCommunicationDetail
        data={data.communications}
        isDashboard={isDashboard}
        expanded={communicationExpanded}
        onClick={() => setCommunicationExpanded(!communicationExpanded)}
      />
      )}
      {advocateAdmin !== true
      && (
      <UserReportCalendarDetail
        data={data.calendars}
        isDashboard={isDashboard}
        expanded={calendarExpanded}
        onClick={() => setCalendarExpanded(!calendarExpanded)}
      />
      )}
    </>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.userReport.fetchState,
  advocateAdmin: store.auth.advocateAdmin
});

export default connect(mapStateToProps, null)(UserReportDetail);
