import React from 'react';

const NegativeIcon = ({
  color,
  className,
}) => (
  <svg className={className} width="1em" height="1em" fill={color} viewBox="0 0 56.742 33.546">
    <path
      d="M44.739 33.542L23.374 21.665l3.68-6.626 8.762 4.871A12.3 12.3 0 0023.583 8.669H0V-.004h23.584A20.948 20.948 0 0144.32 18.058l.5-.142 5.311-9.458 6.609 3.715z"
      fill="#e60404"
    />
  </svg>
);

export default NegativeIcon;
