import actionTypes from './actionTypes';

// Waste Types actions for setting loading status
export const setLoadingAction = (status) => ({
  type: actionTypes.SET_WASTE_TYPE_LOADING_ACTION,
  payload: status
});

export const setSnackbarAction = (status) => ({
  type: actionTypes.SET_WASTE_TYPE_SNACKBAR_ACTION,
  payload: status
});


// Waste Types actions for fetching all Waste Types
export const getWasteTypesListRequest = ({ locality }) => ({
  type: actionTypes.GET_WASTE_TYPES_LIST_REQUEST,
  payload: { locality }
});

export const getWasteTypesListReceive = (data) => ({
  type: actionTypes.GET_WASTE_TYPES_LIST_RECEIVE,
  payload: data
});

export const getWasteTypesListFail = (error) => ({
  type: actionTypes.GET_WASTE_TYPES_LIST_FAIL,
  payload: { ...error }
});


// Waste Types actions for add a Waste Types
export const createWasteTypeRequest = ({ locality, name }) => ({
  type: actionTypes.CREATE_WASTE_TYPE_REQUEST,
  payload: { locality, name }
});

export const createWasteTypeReceive = (data) => ({
  type: actionTypes.CREATE_WASTE_TYPE_RECEIVE,
  payload: data
});

export const createWasteTypeFail = (error) => ({
  type: actionTypes.CREATE_WASTE_TYPE_FAIL,
  payload: { ...error }
});


// Waste Types actions for delete a Waste Types
export const deleteWasteTypeRequest = ({ locality, wasteTypeId }) => ({
  type: actionTypes.DELETE_WASTE_TYPE_REQUEST,
  payload: { locality, wasteTypeId }
});

export const deleteWasteTypeReceive = (data) => ({
  type: actionTypes.DELETE_WASTE_TYPE_RECEIVE,
  payload: data
});

export const deleteWasteTypeFail = (error) => ({
  type: actionTypes.DELETE_WASTE_TYPE_FAIL,
  payload: { ...error }
});
