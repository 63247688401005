import React from 'react';
import ContentLoader from 'react-content-loader';

const SummaryOtherLoading = ({
  width = 330,
  height = 140
}) => (
  <ContentLoader
    speed={2}
    style={{ width, height }}
    backgroundColor={process.env.REACT_APP_COLOR_LOADING_BACKGROUND}
    foregroundColor={process.env.REACT_APP_COLOR__LOADING_FOREGROUND}
  >
    <rect x="0" y="0" rx="3" ry="3" width="210" height="15" />
    <rect x="0" y="65" rx="3" ry="3" width="210" height="15" />
    <rect x="0" y="125" rx="3" ry="3" width="210" height="15" />
    <rect x="250" y="0" rx="6" ry="3" width="80" height="15" />
    <rect x="250" y="65" rx="6" ry="3" width="80" height="15" />
    <rect x="250" y="125" rx="6" ry="3" width="80" height="15" />
  </ContentLoader>
);

export default SummaryOtherLoading;
