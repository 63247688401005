import React from 'react';
import {
  Text, View, StyleSheet, Image
} from '@react-pdf/renderer';
import { addCommasToNumber, nameOfMaterialCategory } from '../../../../utils';
import { toAbsoluteUrl } from '../../../../../_metronic';

// Create styles
const styles = StyleSheet.create({
  h2: {
    fontSize: '20pt'
  },
  h6: {
    fontSize: '12pt',
    fontWeight: 'bold'
  },
  marginLeft: {
    marginLeft: 15
  },
  body: {
    fontSize: '10pt'
  },
  image: {
    width: '15px',
    height: '15px',
    padding: '3px',
  }
});

const stripTags = (data) => {
  let temp = data.replace(/(<([^>]+)>)/ig, '');
  temp = temp.replaceAll('&nbsp', ' ');
  return temp;
};

// Create Document Component
const MMLessonsPDF = ({
  data,
  MMName,
  MMDesc,
  MMAnswer,
  MMResponse,
  MMCorrect,
}) => (
  <>
    <View style={{
      width: '100%',
      marginRight: '20pt',
      minHeight: '40px',
      borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
      paddingTop: '10px',
      paddingBottom: '10px',
      justifyContent: 'center',
      alignItems: 'center'
    }}
    >
      <Text style={styles.h2}>
        Misunderstood Materials Quiz
      </Text>
    </View>

    <View style={{
      width: '100%',
      marginRight: '20pt',
      minHeight: '25px',
      flexDirection: 'row',
      borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
      marginTop: '10px'
    }}
    >

      <View style={{
        width: '30%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingBottom: '10px',
        paddingLeft: '5px'
      }}
      >
        <Text style={styles.h6}>
          {MMName}
        </Text>
      </View>

      <View style={{
        width: '30%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        paddingLeft: '5px',
        paddingBottom: '10px',
      }}
      >
        <Text style={styles.h6}>
          {MMDesc}
        </Text>
      </View>

      <View style={{
        width: '15%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '10px',
        paddingLeft: '15px'
      }}
      >
        <Text style={styles.h6}>
          {MMAnswer}
        </Text>
      </View>

      <View style={{
        width: '15%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '10px',
      }}
      >
        <Text style={styles.h6}>
          {MMResponse}
        </Text>
      </View>

      <View style={{
        width: '10%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '10px',
      }}
      >
        <Text style={[styles.h6]}>
          {MMCorrect}
        </Text>
      </View>

    </View>
    { data && data.map((item, id) => {
      let sum = 0;
      /* eslint-disable no-unused-expressions */
      item.all_opt && item.all_opt.map((it) => {
        sum += Number(it.value);
        return null;
      });

      return (
        <View
          key={`PDF_MM_REPORT_${item.mat_nm} - ${String(id)}`}
          style={{
            flexGrow: 1,
            width: '100%',
            minHeight: '40px',
            flexDirection: 'row',
            borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
            backgroundColor: id % 2 ? process.env.REACT_APP_COLOR_PRIMARY_BG : null,
            paddingTop: '5px',
            paddingBottom: '5x'
          }}
        >
          <View style={{
            width: '30%',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '5pt',
          }}
          >
            <Text style={styles.body}>
              {item.mat_nm ? stripTags(item.mat_nm) : ''}
            </Text>
          </View>
          <View style={{
            width: '30%',
            display: 'flex',
            flexDirection: 'column',
            paddingRight: '5pt',
            paddingLeft: '5pt',
          }}
          >
            <View style={{
              width: '100%',
              height: '27px',
              display: 'flex',
              padding: '5px',
              paddingLeft: '0px',
              flexDirection: 'row',
              borderBottom: `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
            }}
            >
              <Text style={styles.body}>
                {item.disposal_header ? stripTags(item.disposal_header) : ''}
              </Text>
            </View>
            <Text
              style={[styles.body, {
                paddingTop: '5pt',
                paddingBottom: '5tp',
              }]}
            >
              {item.disposal_body ? stripTags(item.disposal_body) : ''}
            </Text>
          </View>
          <View style={{
            width: '40%',
            display: 'flex',
            flexDirection: 'column',
          }}
          >
            { item.all_opt && item.all_opt.map((opt, index) => (
              <View
                key={`MM_PDF_REPORT_ALL_OPT_${String(index)}`}
                style={{
                  display: 'flex',
                  minHeight: '10pt',
                  padding: '5pt',
                  flexDirection: 'row',
                  borderBottom: index + 1 !== item.all_opt.length ? `2pt solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}` : null,
                }}
              >
                <Text style={[styles.body, {
                  width: '28%',
                  paddingLeft: '45px',
                  color: opt.name === item.correct_id ? '#60D008' : '#404040'
                }]}
                >
                  {opt.name ? nameOfMaterialCategory(opt.name) : ''}
                </Text>
                {opt.name === item.correct_id ? (
                  <Image
                    style={styles.image}
                    src={`${toAbsoluteUrl('/media/icons/correctAnswer.png')}`}
                  />
                )
                  : <View style={styles.image} />}
                <Text style={[styles.body, {
                  width: '45%',
                  paddingLeft: '80px'
                }]}
                >
                  {opt.value ? addCommasToNumber(opt.value) : ''}
                </Text>
                <Text style={[styles.body, {
                  width: '23%',
                  paddingLeft: '20px'
                }]}
                >
                  {opt.value ? `${((Number(opt.value) * 100) / sum).toFixed(2)}%` : '0%'}
                </Text>
              </View>
            ))}
          </View>
        </View>
      );
    })}
  </>
);
export default MMLessonsPDF;
