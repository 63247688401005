/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
  useParams,
  useHistory
} from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getAllCampaignApi, switchCampaignApi, PAST } from '../apis';

import * as actions from '../redux/actions';
import * as sharedActions from '../../redux/actions';


/**
 * Components
 */
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from '../../../../_metronic/partials/content/Portlet';
import CampaignItem from '../components/CampaignItem';
import { Description, SwitchBadge } from '../components';
import { CustomButton } from '../../components';


const CampaignSwitch = ({
  intl,
  district,
  switchItem,
  setSwitchItem
}) => {
  const history = useHistory();
  const { firstitem: firstItemId, seconditem: secondItemId } = useParams();

  const [campaignItem, setCampaignItem] = useState(switchItem.length ? switchItem[0].item : null);
  const templateItem = switchItem[1] ? switchItem[1].item : null;


  useEffect(() => {
    if (!switchItem.length) {
      history.replace('/campaigns/tab/current_schedule');
    }
  }, []);


  /**
   * Get Campaign item based on router id param
   */
  const getCampaignItem = async () => {
    try {
      const { data, error } = await getAllCampaignApi({
        district_id: district,
        state: PAST
      });
      if (!error) {
        const { campaigns } = data.data;
        const targetCampaign = campaigns.find((camp) => camp.id === firstItemId);
        if (targetCampaign) {
          setCampaignItem(targetCampaign);
        }
      }
    } catch (E) {
      console.log(E);
    }
  };


  /**
   * Calls to switch items api
   *
   * @param {object} items
   */
  const switchItems = async (items) => {
    try {
      const payload = {
        campaign_id: items[0].item.id, // campaign
        template_id: items[1].item.id // template
      };
      const { data, error } = await switchCampaignApi(payload);
      if (!error && data.status === 'success') {
        cancel({
          switchStatus: true,
          from: items[0].item,
          to: items[1].item,
          previewId: data.data.preview
        }); // return to campaign main page with the switched items
      }
    } catch (e) {
      console.log(e);
    }
  };


  /**
   * Cancel process and turn back
   */
  const cancel = (params = '') => {
    setSwitchItem([]); // empty switch slots
    history.replace('/campaigns/tab/current_schedule', params); // return to campaign page
  };


  /**
   * Continue to select template page
   */
  const proceed = () => {
    // if both switch slots are not full
    if (switchItem.length !== 2) {
      return history.push('/campaigns/select_template');
    }
    return switchItems(switchItem);
  };


  // If campaign item was not in navigation state
  if (!campaignItem) {
    getCampaignItem();
  }


  return (
    <div>
      <Portlet>
        <PortletHeader title={intl.formatMessage({ id: 'CAMPAIGN.SWITCH_ITEM_TITLE' })} />
        <PortletBody>
          {
            switchItem.length === 2
              ? (
                <Description style={{ marginBottom: 25 }}>
                  {intl.formatMessage(
                    { id: 'CAMPAIGN.SWITCH_PAGE_TITLE' },
                    {
                      from: <b>{campaignItem.title}</b>,
                      to: <b>{templateItem.title}</b>,
                    }
                  )}
                  {
                    intl.formatMessage({ id: 'CAMPAIGN.SWITCH_PAGE_CONTINUE' })
                  }
                </Description>
              ) : null
          }
          {
            campaignItem
              ? (
                <CampaignItem
                  data={campaignItem}
                  uiOptions={{
                    preview: false,
                    switch: false,
                    reject: false,
                    date: false,
                    borderBottom: false
                  }}
                />
              ) : null
          }
          {
            switchItem.length === 2
              ? (
                <div style={{ textAlign: 'center', margin: '25px auto' }}>
                  <SwitchBadge />
                </div>
              ) : null
          }
          {
            secondItemId
            && templateItem
              ? (
                <div>
                  <CampaignItem
                    data={templateItem}
                    uiOptions={{
                      preview: false,
                      switch: false,
                      reject: false,
                      date: false,
                      borderBottom: false
                    }}
                  />
                </div>
              ) : null
          }
          <div className="row justify-content-end mt-3 mb-3">
            <div className="row col-4 col-xl-4">
              <div className="col-6 pr-2">
                <CustomButton
                  label={intl.formatMessage({ id: 'GENERAL.CANCEL' })}
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={() => cancel()}
                />
              </div>
              <div className="col-6 pl-2 pr-2">
                <CustomButton
                  label={intl.formatMessage({ id: 'GENERAL.CONTINUE' })}
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={proceed}
                />
              </div>
            </div>
          </div>
        </PortletBody>
      </Portlet>
    </div>
  );
};


const mapStateToProps = (store) => ({
  switchItem: store.campaign.switchItem,
  district: store.common.district
});


export default injectIntl(
  connect(
    mapStateToProps,
    { ...actions, ...sharedActions }
  )(CampaignSwitch)
);
