import actionTypes from './actionTypes';

// Problem report actions for setting loading status
export const setProblemReportLoadingAction = (status) => ({
  type: actionTypes.SET_PROBLEM_REPORT_LOADING_ACTION,
  payload: status
});

// Problem report actions for fetching report data
export const problemReportRequest = ({
  municipality, from, to, county
}) => ({
  type: actionTypes.GET_PROBLEM_REPORT_REQUEST,
  payload: {
    municipality, from, to, county
  }
});

export const problemReportReceive = (data) => ({
  type: actionTypes.GET_PROBLEM_REPORT_RECEIVE,
  payload: { data }
});

export const problemReportFail = (error) => ({
  type: actionTypes.GET_PROBLEM_REPORT_FAIL,
  payload: { ...error }
});

// Problem report actions for fetching report summary data
export const problemReportSummaryRequest = ({
  municipality, from, to, county
}) => ({
  type: actionTypes.GET_PROBLEM_REPORT_SUMMARY_REQUEST,
  payload: {
    municipality, from, to, county
  }
});

export const problemReportSummaryReceive = (data) => ({
  type: actionTypes.GET_PROBLEM_REPORT_SUMMARY_RECEIVE,
  payload: { data }
});

export const problemReportSummaryFail = (error) => ({
  type: actionTypes.GET_PROBLEM_REPORT_SUMMARY_FAIL,
  payload: { ...error }
});
