import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../redux/createReducer';
import initialState from './store';

const collectionRequirementReducer = persistReducer(
  { storage, key: 'collection', whitelist: [] },
  createReducer(initialState, {
    SET_COLLECTION_LOADING_ACTION: (state, { payload }) => Immutable({
      ...state,
      fetchState: payload,
      responseMessage: initialState.responseMessage,
    }),

    SET_COLLECTION_SNACKBAR_ACTION: (state, { payload }) => Immutable({
      ...state,
      snackbarState: payload.status,
      responseMessage: payload.message,
    }),

    SET_COLLECTION_WIZARD_STATUS_ACTION: (state, { payload }) => Immutable({
      ...state,
      wizardStatus: payload.status,
    }),

    SET_COLLECTION_WIZARD_ID_ACTION: (state, { payload }) => Immutable({
      ...state,
      collectionWizardId: payload.id,
    }),

    GET_COLLECTIONS_LIST_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      collectionsList: payload.data || [],
      fetchState: 'succeed-request'
    }),

    GET_COLLECTIONS_LIST_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    CREATE_COLLECTION_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      collectionsList: [
        {
          coll_id: payload.id,
          cms_locality_id: payload.data.locality,
          coll_admin_nm: payload.data.name,
          coll_nm: payload.data.name,
          coll_details: payload.data.description,
          set_out_time: payload.data.setoutTime,
          set_out_time_dscr: payload.data.setoutDescription,
        },
        ...state.collectionsList
      ],
      snackbarState: 'succeed-request',
      responseStatus: 'success',
      responseMessage: 'Event has been successfully created.',
    }),

    CREATE_COLLECTION_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    EDIT_COLLECTION_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      collectionsList: state.collectionsList.map((item) => (
        item.coll_id === payload.data.id ? ({
          ...item,
          coll_id: payload.data.id,
          cms_locality_id: payload.data.locality,
          coll_nm: payload.data.name,
          coll_details: payload.data.description,
          set_out_time: payload.data.setoutTime,
          set_out_time_dscr: payload.data.setoutDescription,
        }) : item)),
      snackbarState: 'succeed-request',
      responseStatus: 'success',
      responseMessage: 'Collection Requirement has been successfully edited.',
    }),

    EDIT_COLLECTION_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),
  })
);

export default collectionRequirementReducer;
