import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../redux/createReducer';
import initialState from './store';

const notificationReducer = persistReducer(
  { storage, key: 'notification', whitelist: [] },
  createReducer(initialState, {
    SET_LOADING: (state) => Immutable({
      ...state,
      fetchState: 'send-request',
    }),
    RESET_LOADING: (state) => Immutable({
      ...state,
      fetchState: 'no-request',
    }),
    SET_FORM_LOADING: (state, payload) => Immutable({
      ...state,
      formLoading: payload.payload,
    }),
    RESET_MESSAGE: (state) => Immutable({
      ...state,
      responseMessage: null,
    }),
    SET_FORM_DATA: (state, { payload }) => Immutable({
      ...state,
      newMessage: {
        ...state.newMessage,
        [payload.propertyName]: payload.data,
      },
    }),
    SET_FORM: (state, { payload }) => Immutable({
      ...state,
      newMessage: {
        ...state.newMessage,
        ...payload,
      },
    }),
    RESET_FORM: (state) => Immutable({
      ...state,
      newMessage: {
        ...initialState.newMessage,
      },
    }),
    SET_NOTIFICATION_TAB: (state, { payload }) => Immutable({
      ...state,
      tab: payload.tab,
    }),
    NOTIFICATION_LISTـRECEIVE: (state, { payload }) => Immutable({
      ...state,
      list: payload.data.notifications,
      listCount: payload.data.total,
      fetchState: 'succeed-request',
    }),
    NOTIFICATION_LISTـFAIL: (state) => Immutable({
      ...state,
      fetchState: 'failed-request',
    }),
    DELETE_NOTIFICATION_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.message,
      responseStatus: 'success',
    }),
    DELETE_NOTIFICATION_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data.message,
      responseStatus: 'error',
    }),
    LOAD_NOTIFICATION_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      newMessage: {
        ...state.newMessage,
        notif_id: payload.data.id,
        notif_type: payload.data.communication_type,
        send_date: payload.data.send_date,
        send_time: payload.data.send_time,
        district_id: payload.data.districts,
        check_zone:
            payload.data.all_zones === 't' ? 'all-zones' : 'selected-zone',
        pickup_type: state.prompts.filter(
          (prompt) => payload.data.zones.filter(
            (item) => item.prompt_id === prompt.prompt_id
          ).length !== 0
        ),
        zones: state.zones.filter(
          (zone) => payload.data.zones.filter((item) => item.zone_id === zone.zone_id)
            .length !== 0
        ),
        title: payload.data.title,
        description: payload.data.description,
        schedule:
          payload.data.send_date !== '' || payload.data.send_time !== ''
            ? 'schedule'
            : 'immediately',
      },
    }),
    LOAD_NOTIFICATION_FAIL: (state) => Immutable({
      ...state,
    }),
    SEND_NOW_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.message,
      responseStatus: 'success',
    }),
    SEND_NOW_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data.message,
      responseStatus: 'error',
    }),
    EDIT_DELETE_DATE_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.message,
      responseStatus: 'success',
    }),
    EDIT_DELETE_DATE_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data.message,
      responseStatus: 'error',
    }),
    GET_ZONE_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      zones: payload.data.zones,
      prompts: payload.data.prompts,
    }),
    GET_ZONE_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data.message,
      responseStatus: 'error',
    }),
    NEW_DRAFT_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.message,
      responseStatus: 'success',
    }),
    NEW_DRAFT_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data.message,
      responseStatus: 'error',
    }),
    NEW_SCHEDULED_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.message,
      responseStatus: 'success',
    }),
    NEW_SCHEDULED_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data.message,
      responseStatus: 'error',
    }),
    NEW_IMMEDIATELY_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.message,
      responseStatus: 'success',
    }),
    NEW_IMMEDIATELY_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data.message,
      responseStatus: 'error',
    }),
    EDIT_DRAFT_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.message,
      responseStatus: 'success',
    }),
    EDIT_DRAFT_FAILED: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data.message,
      responseStatus: 'error',
    }),
    EDIT_SCHEDULED_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.message,
      responseStatus: 'success',
    }),
    EDIT_SCHEDULED_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data.message,
      responseStatus: 'error',
    }),
    EDIT_IMMEDIATELY_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.message,
      responseStatus: 'success',
    }),
    EDIT_IMMEDIATELY_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data.message,
      responseStatus: 'error',
    }),
    SET_NOTIFICATION_CAT: (state, { payload }) => Immutable({
      ...state,
      cat: payload,
    }),
    NOTIFICATION_UPDATE_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.message,
      responseStatus: payload.status,
    }),
    NOTIFICATION_UPDATE_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data.message,
      responseStatus: 'error',
    }),
  })
);

export default notificationReducer;
