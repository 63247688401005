/* eslint-disable max-len */
import React from 'react';
import MuiButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Custom AutoComplete input based on material-ui AutoComplete.
 * @param {string} label Label that we want to display in button.
 * @param {string} variant Specify the general style of button (values = 'transparent', 'contained', 'outlined').
 * @param {string} color Specify the main color of button (values = material ui color values).
 * @param {string} size Specify the size of button (values = material ui size values).
 * @param {number} fontSize Specify the font size of button text.
 * @param {number} paddingVertical Specify the vertical padding of the button.
 * @param {number} paddingHorizontal Specify the horizontal padding of the button.
 * @param {boolean} disabled Determines whether button is disabled or not.
 * @param {function} onClick It is called when user clicks on button.
 * @example
 *  <CustomButton
      label="Click Me!"
      variant="outlined"
      color="primary"
      size="large"
      isMulti
      disabled={false}
      onClick={handleClick}
    />
 */

const CustomButton = ({
  label,
  fontSize = 15,
  paddingVertical = 7,
  paddingHorizontal = 0,
  variant = 'transparent',
  color = 'primary',
  size = 'large',
  disabled = false,
  className,
  onClick,
  style,
  subLabel
}) => {
  const useStyles = makeStyles({
    root: {
      borderRadius: 5,
    },
    label: {
      textTransform: 'none',
      fontSize,
      fontWeight: 700,
      padding: `${paddingVertical}px ${paddingHorizontal}px`,
      display: 'flex',
      flexDirection: 'column',
    },
    transparent: {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
    },
  });

  const classes = useStyles();

  if (variant === 'transparent') {
    return (
      <MuiButton
        className={`pt-3 pb-3 ${classes.transparent} ${className}`}
        classes={{
          root: classes.root,
          label: classes.label,
        }}
        color={color}
        size={size}
        fullWidth
        onClick={onClick}
        disabled={disabled}
      >
        {label}
      </MuiButton>
    );
  }

  return (
    <MuiButton
      className={`pt-2 pb-2 ${className}`}
      classes={{
        root: classes.root,
        label: classes.label,
      }}
      style={style}
      variant={variant}
      color={color}
      size={size}
      fullWidth
      onClick={onClick}
      disabled={disabled}
    >
      {label}
      {subLabel && <><br></br><div style={{color: '#0086C2'}}>{subLabel}</div></>}
    </MuiButton>
  );
};

export default CustomButton;
