import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import Category from './Category';
import * as actions from '../redux/actions';

import {
  CustomButton, Typography
} from '../../components';

const useStyles = makeStyles((theme) => ({
  container: {
    transform: 'rotateX(180deg)',
    overflowX: 'auto',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    overflowX: 'visible',
    transform: 'rotateX(180deg)',
  },
  item: {
    minWidth: '45%',
    maxWidth: '45%',
    [theme.breakpoints.down(1380)]: {
      minWidth: '70%',
      maxWidth: '70%',
    },
    [theme.breakpoints.down(500)]: {
      minWidth: '85%',
      maxWidth: '85%',
    },
  },
  clearButton: {
    alignItems: 'start',
    height: 50,
    width: 162,
  }
}));

const Categories = ({
  categoryList,
  wasteType,
  wasteTypeList,
  setWasteTypeAction,
}) => {
  const classes = useStyles();

  const handleDispalyAll = () => {
    wasteTypeList.find((item) => {
      if (item.waste_type_id === wasteType.waste_type_id || item.waste_type_admin_nm === wasteType.waste_type_admin_nm) {
        setWasteTypeAction(item);
      }
    });
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        {
          wasteType && wasteType.mat_cat_id ? (
            <>
              {
                categoryList.map((item) => (
                  <>
                    {
                      item.mat_cat_id === wasteType.mat_cat_id || item.mat_cat_admin_nm === wasteType.mat_cat_admin_nm ? <div key={item.mat_cat_id} className={classes.item}>
                        <Category data={item} />
                      </div> : null
                    }
                  </>
                ))
              }
              <CustomButton
                label={(
                  <Typography variant="bodySemiBold" color={process.env.REACT_APP_COLOR_PRIMARY}>
                    Display all categories
                  </Typography>
                )}
                onClick={() => handleDispalyAll()}
                className={classes.clearButton}
              />
            </>
          ) : <>
            {
              categoryList.map((item) => (
                <div key={item.mat_cat_id} className={classes.item}>

                  <Category data={item} />
                </div>
              ))
            }
          </>

        }
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  categoryList: store.category.categoryList,
  wasteType: store.category.wasteType,
  wasteTypeList: store.category.wasteTypeList,

});

export default connect(mapStateToProps, actions)(Categories);
