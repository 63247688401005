import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import {
  Typography, TimePicker
} from '../../components';
import * as actions from '../redux/actions';
import { PlusCircleIcon } from '../../../assets/icons';

const useStyles = makeStyles(() => ({
  textButton: {
    color: process.env.REACT_APP_COLOR_PRIMARY
  },
}));

const SetupWizardWeekDayHours = ({
  intl,
  title = 'title',
  firstValue,
  secondValue,
  additionalFirstValue,
  additionalSecondValue,
  onChangeFirst,
  onChangeSecond,
  onChangeAdditionalFirst,
  onChangeAdditionalSecond
}) => {
  const classes = useStyles();
  const [additionalHours, setAdditionalHours] = useState(false);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-start align-items-center">
        <Typography variant="body" className="mt-4">
          {title}
        </Typography>
      </div>
      <div className="row">
        <div className="col-6 d-flex justify-content-between align-items-center p-0">
          <TimePicker
            className="ml-2"
            label={intl.formatMessage({ id: 'GENERAL.OPEN' })}
            value={(firstValue === null || firstValue === '')
              ? null : moment(`${'2020-12-12'}T${firstValue}`)}
            onChange={onChangeFirst}
          />

          <TimePicker
            className="ml-2"
            label={intl.formatMessage({ id: 'GENERAL.CLOSED' })}
            value={(secondValue === null || secondValue === '')
              ? null : moment(`${'2020-12-12'}T${secondValue}`)}
            onChange={onChangeSecond}
          />
        </div>
        <div className="col-6 d-flex justify-content-between align-items-center">
          {
             additionalHours || additionalFirstValue || additionalSecondValue
               ? (
                 <>
                   <TimePicker
                     className="ml-2"
                     label={intl.formatMessage({ id: 'GENERAL.OPEN' })}
                     value={(additionalFirstValue === null || additionalFirstValue === '')
                       ? null : moment(`${'2020-12-12'}T${additionalFirstValue}`)}
                     onChange={onChangeAdditionalFirst}
                   />

                   <TimePicker
                     className="ml-2"
                     label={intl.formatMessage({ id: 'GENERAL.CLOSED' })}
                     value={(additionalSecondValue === null || additionalSecondValue === '')
                       ? null : moment(`${'2020-12-12'}T${additionalSecondValue}`)}
                     onChange={onChangeAdditionalSecond}
                   />
                 </>
               )
               : (
                 <Button
                   onClick={() => { setAdditionalHours(!additionalHours); }}
                 >
                   <div className="d-flex justify-content-start align-items-center">
                     <PlusCircleIcon
                       className="mr-2"
                       color={process.env.REACT_APP_COLOR_PRIMARY}
                     />
                     <Typography variant="bodySemiBold" className={classes.textButton}>
                       {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.HOURS.ADD.ADDITIONAL_HOURS' })}
                     </Typography>
                   </div>
                 </Button>
               )
         }
        </div>
      </div>
    </div>

  );
};

export default injectIntl(connect(null, actions)(SetupWizardWeekDayHours));
