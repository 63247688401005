import React from 'react';
import {
  Switch, Route, Redirect
} from 'react-router-dom';
import '../../../_metronic/_assets/sass/pages/login/login-1.scss';
import List from './List';
import Form from './Form';

const AuthPage = () => (
  <div className="kt-grid kt-grid--ver kt-grid--root">
    <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
      <Switch>
        <Route path="/users/list" component={List} />
        <Route path="/users/form" component={Form} />
        <Redirect from="/users" exact to="/users/list" />
        <Redirect to="/users/list" />
      </Switch>
    </div>
  </div>
);

export default AuthPage;
