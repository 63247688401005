import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import { format } from 'date-fns'


import { Typography } from '../../../components';

import {
  Portlet,
  PortletHeader,
  PortletBody,
} from '../../../../../_metronic/partials/content/Portlet';
import { DropdownMenu } from '../../../Education/EducationalContent/components';
import { ProFeaturesOverlay } from '../components';
import { DiscoveryZoneReportReceive } from '../../DiscoveryZone/redux/actions';
import { TableLoading } from '../../../components/loading';

import { EducationModal } from '../components';


const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: 'white',
    marginRight: 0,
    marginLeft: 0,
    marginTop: '0 !important',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: '8px 0',
    minHeight: 72,
    textTransform: 'uppercase',
    borderBottom: '1px solid lightgrey',


  },
  tableHeaderFont: {
    color: '#404040',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.04em',
  },
  tableRow: {
    borderBottom: '1px solid lightgrey',
    marginLeft: 0,
    marginRight: 0,
    '&::after': {
      borderBottom: '1px solid lightgrey',
    }
  },
  tableLabel: {
    borderBottom: 'none !important',
    borderRadius: '0 !important',
    padding: '0 !important',
  },
  tableBody: {
    padding: '0 !important'
  },
  threeDotMenmu: {
    justifyContent: 'flex-end',
    marginLeft: '-28px',
  }

}));

const MajorCampaigns = ({
  intl,
  reportData,
  isUserPro,
  stage,
  csm,
  isLoading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  /**
 * Menu and menuitem functions.
 */

  const [anchorEl, setAnchorEl] = useState(null);
  const [campaignID, setCampaignID] = useState(null);
  const [open, setOpen] = useState(false);
  const [anchorElTitle, setAnchorElTitle] = useState(null);

  

  const handleClick = (id, event) => {
    setCampaignID(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClickTitle = (event) => {
    setAnchorElTitle(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElTitle(null);
  };

  const closeModal = () => {
    setOpen(false);
    handleClose();
  }

  const descriptionHandler = () => {
    setOpen(true);
  };


  const viewDetailsHandler = (e) => {
    history.push(`/report/major-campaign/detail?campaignID=${campaignID}`);
  };

  /**
* Data for dropdown menu items.
*/
  const menuItemData = [
    {
      id: 1,
      name: 'View Details',
      method: viewDetailsHandler,
      disabled: false
    },

  ];

    /**
* Data for dropdown menu items.
*/
const menuItemDataTitle = [
  {
    id: 1,
    name: 'Report Description',
    method: descriptionHandler,
    disabled: false
  },

];

const descriptionColumn = [
  {
    title: 'Active Period',
    description: 'The start and end dates for the campaign'
  },
  {
    title: 'Unique Users',
    description: 'The number of Unique Users that were impacted in some way by the campaign.'
  },
  {
    title: 'Total Impressions',
    description: 'Similar to how digital advertising is measured, an impression is counted when a piece of content is displayed to a user.'
  },
  {
    title: 'Total Engagements',
    description: 'An engagement occurs when a user interacts with a piece of content - for example, if they click on a link, read a blog article or answer a quiz question.'
  },
  {
    title: 'Knowledge Measurements Engagements',
    description: ' For some types of content the user is asked to provide a response that can be definitively classified as either correct or incorrect - these are called Knowledge Measurement Engagements (KME). Quiz questions, the Recycle RIGHT game and Recycling Fundamentals are examples.'
  },
  {
    title: 'Correct Responses',
    description: 'These are the number of KME’s that the user answered correctly.'
  },
  {
    title: 'Campaign Replacement Value',
    description: 'This is the estimated replacement value of the campaign, using typical advertising industry costs for impressions ($10/k) and engagements ($30/k). '
  },
]

  const getDateSeason = (date) => {
    const month = date.getMonth();
    if (month >= 2 && month <= 4) {
      return 'Spring';
    } else if (month >= 5 && month <= 7) {
      return 'Summer';
    } else if (month >= 8 && month <= 10) {
      return 'Fall';
    } else {
      return 'Winter';
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      {(!isUserPro && !stage) && (
        <ProFeaturesOverlay displayMore csm={csm} />
      )}
      {!isUserPro && Number(stage) < 4 && (
        <ProFeaturesOverlay displayMore csm={csm} />
      )}
      <Portlet>
        <div className="w-100 p-0">
          <PortletHeader
            toolbar={(
              <div className="row w-100 p-0 pt-2 align-items-center justify-content-xs-start" style={{ justifyContent: 'space-between' }}>
                <div className="row col-xs-12 col-lg-6 align-items-center">
                  <div className="align-items-center pl-3">
                    <Typography variant="h1">
                      Campaigns
                    </Typography>
                  </div>
                </div>
                <DropdownMenu
                  handleClick={handleClickTitle}
                  anchorEl={anchorElTitle}
                  handleClose={handleClose}
                  menuItemData={menuItemDataTitle}
                />
              </div>
            )}
          />
          <EducationModal
            open={open}
            onClose={() => closeModal()}
            title={'Description'}
            content={(
              <Typography variant="body1" style={{ textAlign: 'left' }}>
                A Campaign is a comprehensive educational effort to change resident behavior - examples of Campaigns are “Don't bag recyclables” and “Empty and Clean”. To get detailed activity for a specific campaign, click on the 3 dots at the end of its row. This summary report shows:
                <ul> 
                  {
                    descriptionColumn.map((item) => (
                      <li> <strong>{item.title}</strong> - {item.description} </li>
                    ))
                  }
                </ul>

              </Typography>
            )}
          />
        </div>
        {
          isLoading ?
            <div className='d-flex justify-content-center'><TableLoading /> </div> : (
              <PortletBody style={{ padding: '25px 0px' }}>
                <div className="row" style={{ margin: '0px' }}>

                  {
                    reportData.major_campaigns && reportData.major_campaigns.length && (
                      <div style={{ width: '100%' }}>
                        <PortletBody className={`${classes.tableBody}`}>
                          <div className={`row d-flex align-items-center mt-4 ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableHeader}`}>
                            <div className="col-2">
                              <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                                Campaign Name
                              </Typography>
                            </div>
                            <div className="col-2">
                              <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                                Active period
                              </Typography>
                            </div>
                            <div className="col-1">
                              <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                                Unique users
                              </Typography>
                            </div>
                            <div className="col-1">
                              <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                                Total impressions
                              </Typography>
                            </div>
                            <div className="col-1">
                              <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                                Total engagements
                              </Typography>
                            </div>
                            <div className="col-2">
                              <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                                Knowledge Measurement Engagements
                              </Typography>
                            </div>
                            <div className="col-1">
                              <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                                Correct Response Rate
                              </Typography>
                            </div>
                            <div className="col-1">
                              <Typography variant="h2" className={`${classes.tableHeaderFont}`}>
                                Campaign Replacement Value
                              </Typography>
                            </div>
                            <div className="col row d-flex" />
                          </div>


                          <div className={classes.dataTable}>
                            {
                              reportData.major_campaigns.map((item) => (
                                <div
                                  key={item.id}
                                  className={`row d-flex align-items-center pt-4 pb-4 ${classes.border} ${classes.smallerThanMdHide} ${classes.onlyMd} ${classes.tableRow}`}
                                >
                                  <div className="col-2">
                                    <Typography variant="bodySemiBold">
                                      {item.title}
                                    </Typography>
                                  </div>
                                  <div className="col-2">
                                    <Typography variant="bodySemiBold">

                                    {moment(new Date(item.start_dt)).utc().format('MMM D')}
                                      {' '}
                                      -
                                      {' '}
                                      {moment(new Date(item.end_dt)).utc().format('MMM D, YYYY')}

                                      
                                    </Typography>
                                  </div>
                                  <div className="col-1">
                                    <Typography variant="bodySemiBold">
                                      {item.unique_user === 'N/A' ? item.unique_user : Number(item.unique_user).toLocaleString()}
                                    </Typography>
                                  </div>
                                  <div className="col-1">
                                    <Typography variant="bodySemiBold">
                                      {item.exposure === 'N/A' ? item.exposure : Number(item.exposure).toLocaleString()}

                                    </Typography>
                                  </div>
                                  <div className="col-1">
                                    <Typography variant="bodySemiBold">
                                      {item.engagement === 'N/A' ? item.engagement : Number(item.engagement).toLocaleString()}
                                    </Typography>
                                  </div>
                                  <div className="col-2">
                                    <Typography variant="bodySemiBold">
                                      {Number(item.knowledge_measurement_eng).toLocaleString()}
                                    </Typography>
                                  </div>
                                  <div className="col-1">
                                    <Typography variant="bodySemiBold" >
                                      {item.correct_answer_rate === 'N/A' ? item.correct_answer_rate : `${Number(item.correct_answer_rate).toLocaleString()}%`}
                                    </Typography>
                                  </div>
                                  <div className="col-1">
                                    <Typography variant="bodySemiBold">
                                      {Number(item.campaign_replacement_value).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                      })}
                                    </Typography>
                                  </div>
                                  <div className={`col-1 d-flex ${classes.threeDotMenmu}`}>
                                    <DropdownMenu
                                      handleClick={((e) => { handleClick(item.id, e) })}
                                      anchorEl={anchorEl}
                                      handleClose={handleClose}
                                      menuItemData={menuItemData}
                                    />
                                  </div>
                                </div>

                              ))
                            }
                          </div>
                        </PortletBody>
                      </div>
                    )

                  }

                </div>
              </PortletBody>
            )
        }

      </Portlet>
    </div>
  );
};

export default injectIntl(MajorCampaigns);
