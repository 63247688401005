import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import { Portlet, PortletBody, PortletHeader } from '../../../../_metronic/partials/content/Portlet';
import {
  Typography, IconButton, CustomButton, TextField, TimePicker, WysiwygEditor, Dialog
} from '../../components';
import { CloseIcon } from '../../../assets/icons';
import * as actions from '../redux/actions';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'max-content',
    backgroundColor: `${process.env.REACT_APP_COLOR_PRIMARY_BG}`,
  },
  header: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_PRIMARY_LIGHT} !important`,
  },
  wrapper: {
    [theme.breakpoints.down('800')]: {
      flexDirection: 'column !important'
    },
  },
  timePicker: {
    width: '30%',
    backgroundColor: `${process.env.REACT_APP_COLOR_SECONDARY}`,
  },
  saveButton: {
    width: '25%',
    [theme.breakpoints.down('1340')]: {
      width: '30%',
    },
    [theme.breakpoints.down('1140')]: {
      width: '35%',
    },
    [theme.breakpoints.down('940')]: {
      width: '40%',
    },
    [theme.breakpoints.down('800')]: {
      width: '100%',
    },
  },
  backBtn: {
    backgroundColor: process.env.REACT_APP_COLOR_SECONDARY,
    width: '25%',
  },
}));

const SetupWizard = ({
  intl,
  locality,
  collectionWizardId,
  collectionsList,
  setCollectionWizardStatusAction,
  setCollectionWizardIdAction,
  createCollectionRequest,
  editCollectionRequest,
}) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [setoutTime, setSetoutTime] = useState(null);
  const [setoutDescription, setSetoutDescription] = useState('');

  useEffect(() => {
    if (collectionWizardId) {
      const collection = collectionsList.find((item) => item.coll_id === collectionWizardId);
      setSetoutTime(collection.set_out_time ? `2000-01-01T${collection.set_out_time}` : null);
      setSetoutDescription(collection.set_out_time_dscr);
      setDescription(collection.coll_details);
      setName(collection.coll_nm);
    }
  }, [collectionWizardId]);

  const handleClick = () => {
    setCollectionWizardStatusAction(false);
    if (collectionWizardId) {
      editCollectionRequest({
        id: collectionWizardId,
        locality: locality.cms_locality_id,
        name,
        description,
        setoutTime: moment(setoutTime).format('HH:mm'),
        setoutDescription
      });
      setCollectionWizardIdAction(null);
    } else {
      createCollectionRequest({
        locality: locality.cms_locality_id,
        name,
        description,
        setoutTime: moment(setoutTime).format('HH:mm'),
        setoutDescription
      });
    }
  };

  return (
    <Portlet className={classes.container}>
      <PortletHeader
        className={classes.header}
        toolbar={(
          <div className="w-100 d-flex justify-content-between align-items-center">
            <Typography variant="h1">
              {intl.formatMessage({ id: 'COLLECTION.SETUP_WIZARD.TITLE' })}
            </Typography>

            <Dialog
              button={(
                <IconButton tooltip={intl.formatMessage({ id: 'GENERAL.CLOSE' })}>
                  <CloseIcon />
                </IconButton>
              )}
              title={intl.formatMessage({ id: 'GENERAL.EXIT' })}
              content={(
                <Typography variant="body">
                  {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.EXIT_TEXT' })}
                </Typography>
              )}
              handleSubmit={() => {
                setCollectionWizardStatusAction(false);
                setCollectionWizardIdAction(null);
              }}
              submitText={intl.formatMessage({ id: 'GENERAL.EXIT' })}
              cancelText={intl.formatMessage({ id: 'GENERAL.DO_NOT_EXIT' })}
            />

            {/* <IconButton
              tooltip={intl.formatMessage({ id: 'GENERAL.CLOSE' })}
              onClick={() => {
                setCollectionWizardStatusAction(false);
                setCollectionWizardIdAction(null);
              }}
            >
              <CloseIcon />
            </IconButton> */}
          </div>
        )}
      />
      <PortletBody className="pt-3 w-100 h-100">
        <div className="w-100 h-100 d-flex flex-column justify-content-between align-items-start">
          <Typography variant="h1">
            {intl.formatMessage({ id: 'COLLECTION.SETUP_WIZARD.SUBTITLE' })}
          </Typography>

          <Typography className="mt-3 d-flex align-item-center" variant="bodySemiBold">
            {intl.formatMessage({ id: 'COLLECTION.SETUP_WIZARD.NAME' })}
          </Typography>
          <TextField
            className="mt-3"
            onChange={setName}
            value={name}
          />

          <Typography className="mt-4 d-flex align-item-center" variant="bodySemiBold">
            {intl.formatMessage({ id: 'COLLECTION.SETUP_WIZARD.DESCRIPTION' })}
          </Typography>
          <WysiwygEditor
            fullWidth
            value={description}
            onChange={setDescription}
          />

          <TimePicker
            className={`mt-5 ${classes.timePicker}`}
            label={intl.formatMessage({ id: 'COLLECTION.SETUP_WIZARD.SETOUT_TIME' })}
            value={setoutTime}
            onChange={setSetoutTime}
          />

          <Typography className="mt-4 d-flex align-item-center" variant="bodySemiBold">
            {intl.formatMessage({ id: 'COLLECTION.SETUP_WIZARD.SETOUT_DESCRIPTION' })}
          </Typography>
          <WysiwygEditor
            fullWidth
            value={setoutDescription}
            onChange={setSetoutDescription}
          />

          <div className="w-100 pt-5 d-flex flex-column">
            <div className={`w-100 d-flex flex-row-reverse justify-content-between align-items-center ${classes.wrapper}`}>

              <CustomButton
                className={classes.saveButton}
                label={intl.formatMessage({ id: 'GENERAL.SAVE_CHANGES' })}
                variant="contained"
                color="primary"
                size="large"
                disabled={name !== null && !name.trim().length}
                onClick={handleClick}
              />
              <Dialog
                className={`${classes.button} ${classes.backBtn}`}
                button={(
                  <CustomButton
                    label={intl.formatMessage({ id: 'GENERAL.EXIT' })}
                    variant="outlined"
                    color="primary"
                    size="large"
                  />
                )}
                title={intl.formatMessage({ id: 'GENERAL.EXIT' })}
                content={(
                  <Typography variant="body">
                    {intl.formatMessage({ id: 'DEPOT.SETUP_WIZARD.EXIT_TEXT' })}
                  </Typography>
                )}
                submitText={intl.formatMessage({ id: 'GENERAL.EXIT' })}

                handleSubmit={() => {
                  setCollectionWizardStatusAction(false);
                  setCollectionWizardIdAction(null);
                }}
              />
            </div>
          </div>
        </div>
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  locality: store.common.locality,
  collectionWizardId: store.collection.collectionWizardId,
  collectionsList: store.collection.collectionsList,
});

export default injectIntl(connect(mapStateToProps, actions)(SetupWizard));
