import React from 'react';

const NeutralIcon = ({
  color,
  className,
}) => (
  <svg className={className} width="1em" height="1em" fill={color} viewBox="0 0 38.587 8.673">
    <path d="M38.592 0H.005v8.673h38.587z" fill="#f5ba4a" />
  </svg>
);

export default NeutralIcon;
