import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

import DeleteIcon from '../../../assets/icons/DeleteIcon';
import { Typography, Dialog, IconButton } from '../../components';

const useStyles = makeStyles({
  deleteIcon: {
    height: 40
  },
  text: {
    color: '#F56665',
    paddingLeft: 5,
    whiteSpace: 'nowrap',
  }
});

const Reject = ({ onClick, intl }) => {
  const { deleteIcon, text } = useStyles();

  return (
    <Dialog
      button={(
        <div className="d-flex align-items-center">
          <IconButton tooltip={intl.formatMessage({ id: 'CAMPAIGN.ITEM.REJECT' })}>
            <DeleteIcon color="#F56665" className={deleteIcon} style={{ fontSize: 20 }} />
          </IconButton>
          <Typography variant="bodySemiBold" className={text}>
            {intl.formatMessage({ id: 'CAMPAIGN.ITEM.REJECT' })}
          </Typography>
        </div>
      )}
      title={intl.formatMessage({ id: 'CAMPAIGN.ITEM.REJECT' })}
      content={(
        <Typography variant="body">
          {intl.formatMessage({ id: 'CAMPAIGN.ITEM.REJECT_CONTENT' })}
        </Typography>
      )}
      submitText={intl.formatMessage({ id: 'GENERAL.YES' })}
      handleSubmit={onClick}
    />
  );
};

export default injectIntl(Reject);
