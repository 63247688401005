import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { Typography } from '../../components';
import WhatToDoCard from './WhatToDoCard';
import { Portlet, PortletHeader } from '../../../../_metronic/partials/content/Portlet';

const WhatToDo = ({ intl, permission_list, municipalities }) => {
  /*
   * Notification and what goes where card is displayed conditionally
   * depending on the user permissions and munucipality stage.
  */

  const isNotificationsAllowed = permission_list.find((item) => item.group_id === 'notification')?.permission === 't';
  const isWhatGoesWhereAllowed = permission_list.find((item) => item.group_id === 'cms')?.permission === 't';
  const isEducationAllowed = permission_list.find((item) => item.group_id === 'education')?.permission === 't';
  const isScheduleAllowed = permission_list.find((item) => item.group_id === 'schedules')?.permission === 't';


  const isAllowed = municipalities.some((item) => item.stage !== '2');

  const cardInfo = [
    {
      id: 1,
      link: '/report/wgw-material/detail',
      title: 'DASHBOARD.WHAT.TO.DO.CARD.TITLE.WHAT.GOES.WHERE',
      subTitle: 'GENERAL.MANAGE',
      disabled: !isWhatGoesWhereAllowed,
      notAllowed: !isAllowed
    },
    {
      id: 2,
      link: '/schedules',
      title: 'DASHBOARD.WHAT.TO.DO.CARD.TITLE.SCHEDULES',
      subTitle: 'GENERAL.MANAGE',
      disabled: false,
      notAllowed: !isScheduleAllowed
    },
    {
      id: 3,
      link: '/education',
      title: 'DASHBOARD.WHAT.TO.DO.CARD.TITLE.EDUCATIONAL.CONTENT',
      subTitle: 'GENERAL.CREATE',
      disabled: false,
      notAllowed: !isEducationAllowed
    },
    {
      id: 4,
      link: '/notification',
      title: 'DASHBOARD.WHAT.TO.DO.CARD.TITLE.NOTIFICATION',
      subTitle: 'GENERAL.APPROVE',
      disabled: !isNotificationsAllowed,
      notAllowed: !isAllowed
    },
  ];

  return (
    <Portlet>
      <PortletHeader title={(
        <Typography variant="h1">
          {intl.formatMessage({ id: 'DASHBOARD.WHAT.TO.DO.CARD.HEADER' })}
        </Typography>
      )}
      />
      <div className="d-flex align-items-center py-4">
        {cardInfo.map((data) => <WhatToDoCard key={data.id} data={data} />)}
      </div>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  permission_list: store.auth.user_info.permission_list,
  municipalities: store.common.municipalities || []
});
export default injectIntl(connect(mapStateToProps, null)(WhatToDo));
