import actionTypes from './actionTypes';

// Set user list manually
export const setUserListManuallyAction = (data) => ({
  type: actionTypes.SET_USER_LIST_MANUALLY,
  payload: { ...data }
});

// Reset new user by initial state
export const resetNewUserAction = () => ({
  type: actionTypes.RESET_NEW_USER_ACTION
});

// Set Permission action
export const setPermissionsAction = ({ data, propertyName }) => ({
  type: actionTypes.SET_PERMISSIONS_ACTION,
  payload: { data, propertyName }
});

// User list actions
export const userListRequest = (username, search, length) => ({
  type: actionTypes.USER_LIST_REQUEST,
  payload: { username, search, length }
});

export const userListReceive = (data) => ({
  type: actionTypes.USER_LIST_RECEIVE,
  payload: data
});

export const userListFail = (error) => ({
  type: actionTypes.USER_LIST_FAIL,
  payload: { ...error }
});

// Add user actions
export const addUserRequest = ({
  user_name,
  first_name,
  last_name,
  email,
  phone,
  is_active,
  notes,
  job_title,
  permission_list,
  district_list
}) => ({
  type: actionTypes.ADD_USER_REQUEST,
  payload: {
    user_name,
    first_name,
    last_name,
    email,
    phone,
    is_active,
    notes,
    job_title,
    permission_list,
    district_list
  }
});

export const addUserReceive = (data) => ({
  type: actionTypes.ADD_USER_RECEIVE,
  payload: data
});

export const addUserAction = ({ data, propertyName }) => ({
  type: actionTypes.ADD_USER_ACTION,
  payload: { data, propertyName }
});

export const addUserFail = (error) => ({
  type: actionTypes.ADD_USER_FAIL,
  payload: error
});

// Edit user actions
export const editUserRequest = ({
  user_id,
  user_name,
  first_name,
  last_name,
  email,
  phone,
  is_active,
  notes,
  job_title,
  permission_list,
  district_list
}) => ({
  type: actionTypes.EDIT_USER_REQUEST,
  payload: {
    user_id,
    user_name,
    first_name,
    last_name,
    email,
    phone,
    is_active,
    notes,
    job_title,
    permission_list,
    district_list
  }
});

export const editUserReceive = (data) => ({
  type: actionTypes.EDIT_USER_RECEIVE,
  payload: data
});

export const editUserFail = (error) => ({
  type: actionTypes.EDIT_USER_FAIL,
  payload: error
});

// Delete user actions
export const deleteUserRequest = (user_id) => ({
  type: actionTypes.DELETE_USER_REQUEST,
  payload: { user_id }
});

export const deleteUserReceive = (data) => ({
  type: actionTypes.DELETE_USER_RECEIVE,
  payload: data
});

export const deleteUserFail = (error) => ({
  type: actionTypes.DELETE_USER_FAIL,
  payload: error
});

// Activation user actions
export const activationUserRequest = (user_id, is_active) => ({
  type: actionTypes.ACTIVATION_USER_REQUEST,
  payload: { user_id, is_active }
});

export const activationUserReceive = (data) => ({
  type: actionTypes.ACTIVATION_USER_RECEIVE,
  payload: data
});

export const activationUserFail = (error) => ({
  type: actionTypes.DELETE_USER_FAIL,
  payload: { ...error }
});

// check username actions
export const checkUsernameRequest = (user_name) => ({
  type: actionTypes.CHECK_USERNAME_REQUEST,
  payload: { user_name }
});

export const checkUsernameReceive = (data) => ({
  type: actionTypes.CHECK_USERNAME_RECEIVE,
  payload: data
});

export const checkUsernameFail = (error) => ({
  type: actionTypes.CHECK_USERNAME_FAIL,
  payload: { ...error }
});

// check username actions
export const checkEmailRequest = (user_name, email) => ({
  type: actionTypes.CHECK_EMAIL_REQUEST,
  payload: { user_name, email }
});

export const checkEmailReceive = (data) => ({
  type: actionTypes.CHECK_EMAIL_RECEIVE,
  payload: data
});

export const checkEmailFail = (error) => ({
  type: actionTypes.CHECK_EMAIL_FAIL,
  payload: { ...error }
});

// Edit user actions
export const getOneUserRequest = (user_id) => ({
  type: actionTypes.GET_ONE_USER_REQUEST,
  payload: user_id
});

export const getOneUserReceive = (data) => ({
  type: actionTypes.GET_ONE_USER_RECEIVE,
  payload: data
});

export const getOneUserFail = (error) => ({
  type: actionTypes.GET_ONE_USER_FAIL,
  payload: error
});

export const resetMessageAction = () => ({
  type: actionTypes.RESET_RESPONSE_MESSAGE,
});

export const resetNavigateToListAction = () => ({
  type: actionTypes.RESET_NAVIGATE_TO_LIST,
});

// suggest username actions
export const suggestUsernameRequest = (first_name, last_name) => ({
  type: actionTypes.SUGGEST_USERNAME_REQUEST,
  payload: { first_name, last_name }
});

export const suggestUsernameReceive = (data) => ({
  type: actionTypes.SUGGEST_USERNAME_RECEIVE,
  payload: data
});

export const suggestUsernameFail = (error) => ({
  type: actionTypes.SUGGEST_USERNAME_FAIL,
  payload: { ...error }
});

export const resetSuggestUsername = () => ({
  type: actionTypes.RESET_SUGGESTED_USERNAME,
});
