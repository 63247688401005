import cts from '../../crud/cts';

/**
 * Notification API which fetches notification messages list
 */
export const getList = async ({ page, type }) => cts(
  'post',
  'notifications/get-all',
  {
    offset: (page - 1) * 5,
    length: 5,
    draw: page,
    state: type
  }
);

/**
 * Notification API which removes the selected notification from messages list
 */
export const deleteNotification = async ({ id }) => cts(
  'post',
  'notifications/delete',
  {
    notif_id: id,
  }
);

/**
 * Notification API which loads the selected notification to messages form
 */
export const loadNotification = async ({ id }) => cts(
  'post',
  'notifications/get-one',
  {
    notif_id: id,
  }
);

/**
 * Notification API which sends the selected notification
 */
export const sendNow = async ({ id }) => cts(
  'post',
  'notifications/send',
  {
    notif_id: id,
  }
);

/**
 * Notification API which Changes the expire Date for a notification
 */
export const editDeleteDate = async ({ id, time, date }) => cts(
  'post',
  'notifications/expire',
  {
    notif_id: id,
    expiry_date: date,
    expiry_time: time
  }
);

/**
 * Zone API which fetches zones that are connected to selected district
 */
export const getZone = async ({
  district_id
}) => cts(
  'post',
  'districts/get-zones',
  {
    district_id,
  }
);

export const newDraft = async ({
  notif_type,
  send_date,
  send_time,
  district_id,
  check_zone,
  pickup_types,
  zones,
  title,
  description,
  notif_msg
}) => cts(
  'post',
  'notifications/new/save',
  {
    notif_type,
    send_date,
    send_time,
    district_id,
    check_zone,
    pickup_types,
    zones,
    title,
    description,
    notif_msg
  }
);

export const editDraft = async ({
  notif_id,
  notif_type,
  send_date,
  send_time,
  district_id,
  check_zone,
  pickup_types,
  zones,
  title,
  description,
  notif_msg
}) => cts(
  'post',
  'notifications/edit/save',
  {
    notif_id,
    notif_type,
    send_date,
    send_time,
    district_id,
    check_zone,
    pickup_types,
    zones,
    title,
    description,
    notif_msg
  }
);

export const newScheduled = async ({
  notif_type,
  send_date,
  send_time,
  district_id,
  check_zone,
  pickup_types,
  zones,
  title,
  description,
  notif_msg
}) => cts(
  'post',
  'notifications/new/send',
  {
    notif_type,
    send_date,
    send_time,
    district_id,
    check_zone,
    pickup_types,
    zones,
    title,
    description,
    notif_msg
  }
);

export const newImmediately = async ({
  notif_type,
  send_date,
  send_time,
  district_id,
  check_zone,
  pickup_types,
  zones,
  title,
  description,
  notif_msg
}) => cts(
  'post',
  'notifications/new/send-immediately',
  {
    notif_type,
    send_date,
    send_time,
    district_id,
    check_zone,
    pickup_types,
    zones,
    title,
    description,
    notif_msg
  }
);

export const editImmediately = async ({
  notif_id,
  notif_type,
  send_date,
  send_time,
  district_id,
  check_zone,
  pickup_types,
  zones,
  title,
  description,
  notif_msg
}) => cts(
  'post',
  'notifications/edit/send-immediately',
  {
    notif_id,
    notif_type,
    send_date,
    send_time,
    district_id,
    check_zone,
    pickup_types,
    zones,
    title,
    description,
    notif_msg
  }
);

export const editScheduled = async ({
  notif_id,
  notif_type,
  send_date,
  send_time,
  district_id,
  check_zone,
  pickup_types,
  zones,
  title,
  description,
  notif_msg
}) => cts(
  'post',
  'notifications/edit/send',
  {
    notif_id,
    notif_type,
    send_date,
    send_time,
    district_id,
    check_zone,
    pickup_types,
    zones,
    title,
    description,
    notif_msg
  }
);

/**
 * Notification API which update a notification
 */
export const updateNotification = async ({ notif_id, description, title }) => cts(
  'post',
  'notifications/sended/update',
  {
    notif_id,
    description,
    title
  }
);
