import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Backdrop,
  Fade
} from '@material-ui/core';
import { toAbsoluteUrl } from '../../../../../_metronic';
import {
  Typography
} from '../../../components';


const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0 35px 0'
  },
  aboutCasyImage: {
    width: '100%',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  stepContainer: {
    display: 'flex'
  },
  stepImage: {
    height: 100,
    width: 100
  },
  stepContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flexStart',
    marginLeft: 20
  },
  stepBodyText: {
    marginTop: '0 !important',
    marginLeft: '0 !important',
    marginBottom: '0 !important',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundcolor: 'red'
    }
  },
  img: {
    outline: 'none'
  }
}));
const AboutCASy = ({ intl }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  // const handleImage = (value) => {
  //   setImage(value);
  //   setOpen(true);
  //   console.log(image);
  // };

  return (
    <>
      <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="h1">
        {intl.formatMessage({ id: 'EDUCATION.ABOUT.CASY' })}
      </Typography>
      <div className={`${classes.container}`}>
        <img
          alt="About CASy"
          src={toAbsoluteUrl('/media/icons/ABOUTCASy.png')}
          className={`${classes.aboutCasyImage}`}
          onClick={(e) => setOpen(true)}
        />
      </div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open} timeout={500} className={classes.img}>
          <img
            src={toAbsoluteUrl('/media/icons/ABOUTCASy.png')}
            alt="asd"
            style={{ maxHeight: '90%', maxWidth: '90%' }}
          />
        </Fade>
      </Modal>
      {/* <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-4" variant="h1">
        {intl.formatMessage({ id: 'EDUCATION.ABOUT.CASY.TUTORIAL.FULL' })}
      </Typography>
      <div className="mb-4">
        <Typography className={`mb-4 ${classes.materialTitle}`} variant="h2">
          Step 1
        </Typography>
        <div className={`${classes.stepContainer}`}>
          <img
            alt="About CASy"
            src={toAbsoluteUrl('/media/icons/step1.png')}
            className={`${classes.stepImage}`} />
          <div className={`${classes.stepContentContainer}`}>
            <Typography
              color={process.env.REACT_APP_COLOR_TEXT}
              className={`${classes.stepBodyText}`}
              variant="educationBody"
            >
              To add a Depot, go to the Depots page.  If you have county-level access and manage multiple municipalities, make sure the Municipalities selector is set correctly, showing the ‘level’ to where you want to add the Depot. For example, should the location appear as an option for only one municipality, or can all residents use the Depot you want to add. Click the blue Add a Depot button in the upper right, then follow the wizard to enter the details.

            </Typography>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <Typography className={`mb-4 ${classes.materialTitle}`} variant="h2">
          Step 2
        </Typography>
        <div className={`${classes.stepContainer}`}>
          <img
            alt="About CASy"
            src={toAbsoluteUrl('/media/icons/step2.png')}
            className={`${classes.stepImage}`} />
          <div className={`${classes.stepContentContainer}`}>
            <Typography
              color={process.env.REACT_APP_COLOR_TEXT}
              className={`${classes.stepBodyText}`}
              variant="educationBody"
            >
              To edit an existing Depot, use the Search Depot Name box at the top of the page. Enter the name (just the unique part of the name is best). Once you’ve located the Depot, scroll down and use the edit tool to update the field(s) that needs to be changed. Then click on Save Changes. Changes take about one hour to go live.
            </Typography>
          </div>
        </div>
      </div> */}
    </>


  );
};

export default injectIntl(AboutCASy);
