import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Radio } from '@material-ui/core';
import { CheckOutlined } from '@material-ui/icons';
import moment from 'moment';
import {
  CustomButton, Opacity, TextField, Typography
} from '../../../../components';

import { changeCurrentMonthsIndexHandler } from '../../../../../utils';
import * as actions from '../redux/actions';

const useStyles = makeStyles(() => ({
  iconContainerWrapper: {
    margin: '10.25px 0'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 17,
    height: 17,
    background: '#00C8BC',
    borderRadius: '50%',
    marginRight: 11,
    marginLeft: 10,
    '& svg': {
      fontSize: '1rem'
    }
  }
}));

const Main = ({
  intl,
  setShowCASySettingsAction,
  currentQuestionIndex,
  setCurrentQuestionAction,
  WWtKs = WWtKs.filter((item) => item.casy_settings.has_casy_settings),
  feedbackRequest,
  loading,
  error,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [isSubmittinAFeedback, setIsSubmittinAFeedback] = useState();
  const [hideResponseData, setHideResponseData] = useState(false);
  const [feedback, setFeedback] = useState('');

  useEffect(() => {
    if (WWtKs && WWtKs.length >= currentQuestionIndex && WWtKs[currentQuestionIndex]) {
      const endDate = moment(WWtKs[currentQuestionIndex].date_to);
      const currentDate = moment();
      if (currentDate.isAfter(endDate)) {
        setHideResponseData(true);
      } else {
        setHideResponseData(false);
      }
    }
  }, [currentQuestionIndex]);

  let type; let question; let options; let quiz_correct_answer; let correct_answer_feedback; let wrong_answer_feedback; let message; let
    casy_settings;

  if (WWtKs && WWtKs.length >= currentQuestionIndex && WWtKs[currentQuestionIndex]) {
    ({
      type,
      question,
      options,
      quiz_correct_answer,
      correct_answer_feedback,
      wrong_answer_feedback,
      message,
      casy_settings,
    } = WWtKs[currentQuestionIndex]);
  }

  const changeCurrentQuestionIndexHandler = (direction) => {
    setCurrentQuestionAction(
      changeCurrentMonthsIndexHandler(direction, currentQuestionIndex, WWtKs.length)
    );
  };

  const handleSubmittingFeedback = () => {
    feedbackRequest(WWtKs[currentQuestionIndex], feedback);
    setIsSubmittinAFeedback(false);
    setFeedback('');
  };

  return (
    <Opacity slide>
      <Typography color={process.env.REACT_APP_COLOR_TEXT} className="mb-5" variant="educationBody">
        {intl.formatMessage({ id: 'EDUCATION.WWTK.SUB' })}
      </Typography>
      {
        !loading && WWtKs && WWtKs.length >= currentQuestionIndex && WWtKs[currentQuestionIndex] && (
          <>
            {/* question type title */}
            <Opacity key={type}>
              <Typography
                style={{ textTransform: 'capitalize' }}
                // color={process.env.REACT_APP_COLOR_TITLE}
                variant="h2"
              >
                {intl.formatMessage({ id: 'EDUCATION.QUESTION.TYPE' }, { questionType: type })}
              </Typography>
            </Opacity>

            {/* navigation buttons */}
            <div style={{ transform: 'translateX(-10px)' }} className="d-flex align-items-center mt-2">
              <NavigateBeforeIcon
                onClick={() => changeCurrentQuestionIndexHandler('backward')}
                fontSize="large"
                style={{
                  transition: 'all .3s',
                  cursor: currentQuestionIndex === WWtKs.length - 1 ? 'not-allowed' : 'pointer',
                  opacity: currentQuestionIndex === WWtKs.length - 1 ? 0.5 : 1,
                  color: '#757575'
                }}
                disabled={currentQuestionIndex === WWtKs.length - 1}
              />
              <Opacity key={currentQuestionIndex}>
                <Typography style={{ width: 100, textAlign: 'center' }} color="#404040" variant="bodySemiBold">
                  {WWtKs[currentQuestionIndex]['date-label']}
                  {/* {WWtKs[currentQuestionIndex].date_from} - {WWtKs[currentQuestionIndex].date_to} */}
                </Typography>
              </Opacity>
              <NavigateNextIcon
                onClick={() => changeCurrentQuestionIndexHandler('forward')}
                fontSize="large"
                style={{
                  transition: 'all .3s',
                  cursor: currentQuestionIndex === 0 ? 'not-allowed' : 'pointer',
                  opacity: currentQuestionIndex === 0 ? 0.5 : 1,
                  color: '#757575'
                }}
                disabled={currentQuestionIndex === 0}

              />
            </div>

            {/* question */}
            <Opacity key={question}>
              <Typography
                color={process.env.REACT_APP_COLOR_TEXT}
                className="ml-1 mb-3 mt-3"
                variant="pdd"
                dangerouslySetInnerHTML={{ __html: question }}
              />
            </Opacity>

            {/* answers */}
            <Opacity key={currentQuestionIndex}>
              <div className="mb-5">
                {options.map((item, index) => (
                  <div className="d-flex align-items-center mb-2">
                    {+quiz_correct_answer === index + 1 && type === 'quiz'
                      ? (
                        <div className={classes.iconContainerWrapper}>
                          <div className={classes.iconContainer}>
                            <CheckOutlined fontSize="small" style={{ color: '#fff' }} />
                          </div>
                        </div>
                      )
                      : (
                        <Radio color="primary" checked={false} />
                      )}
                    <Typography color={process.env.REACT_APP_COLOR_TEXT} variant="bodySemiBold">
                      {item}
                    </Typography>
                  </div>
                ))}
              </div>

              {/* quiz correct and wrong asnwers */}
              {type === 'quiz' && (
                <>
                  <Typography color="#00C8BC" className="mb-2" variant="pdd">
                    {intl.formatMessage({ id: 'GENERAL.CORRECT.ANSWER.MESSAGE' })}
                  </Typography>
                  <i>
                    <Typography
                      color={process.env.REACT_APP_COLOR_TEXT}
                      className="mb-4"
                      variant="bodySemiBold"
                      dangerouslySetInnerHTML={{ __html: correct_answer_feedback }}
                    />
                  </i>
                  <Typography color="#FF1E1E" className="mb-2" variant="pdd">
                    {intl.formatMessage({ id: 'GENERAL.WRONG.ANSWER.MESSAGE' })}
                  </Typography>
                  <i>
                    <Typography
                      color={process.env.REACT_APP_COLOR_TEXT}
                      className="mb-5"
                      variant="bodySemiBold"
                      dangerouslySetInnerHTML={{ __html: wrong_answer_feedback }}
                    />
                  </i>
                </>
              )}
            </Opacity>

            {/* poll message */}
            {type === 'poll' && (
              <>
                <div className="d-flex align-items-center mb-3 mt-3">
                  <div style={{ marginLeft: 0 }} className={classes.iconContainer}>
                    <CheckOutlined fontSize="small" style={{ color: '#fff' }} />
                  </div>
                  <Typography color="#00C8BC" className="mb-2" variant="pdd">
                    {intl.formatMessage({ id: 'EDUCATION.POLL.MESSAGE' })}
                  </Typography>
                </div>
                <Typography
                  color={process.env.REACT_APP_COLOR_TEXT}
                  className="mb-5"
                  variant="bodySemiBold"
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              </>
            )}

            {/* submit a feedback */}
            <div className="mb-4" role="button" onClick={() => setIsSubmittinAFeedback((prevState) => !prevState)}>
              <Typography
                style={{ cursor: 'pointer' }}
                color="#0086C2"
                variant="pdd"
              >
                {intl.formatMessage({ id: 'GENERAL.PROVIDE.FEEDBACK' })}
              </Typography>
            </div>

            {isSubmittinAFeedback && (
              <Opacity>
                <div className="d-flex flex-column mb-3">
                  <TextField isMulti maxRow={3} defaultRow={3} onChange={setFeedback} value={feedback} />
                  <div style={{ marginLeft: 'auto', marginTop: 15 }}>
                    <CustomButton
                      onClick={() => handleSubmittingFeedback()}
                      variant="contained"
                      disabled={false}
                      fontSize={13}
                      paddingVertical={1}
                      label={<FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />}
                    />
                  </div>
                </div>
              </Opacity>
            )}

            {/* residents respond data */}
            {
              hideResponseData && (
                <div
                  className="mb-4"
                  style={{ cursor: 'pointer' }}
                  role="button"
                  onClick={() => history.push('/report/resident-knowledge-indicator')}
                >
                  <Typography color="#0086C2" variant="pdd">
                    {intl.formatMessage({ id: 'EDUCATION.VIEW.RESIDENTS.RESPONSE.DATA' })}
                  </Typography>
                </div>
              )
            }

            {/* casy */}
            {casy_settings.has_casy_settings && (
              <div role="button" onClick={() => setShowCASySettingsAction(true)}>
                <Typography color="#0086C2" variant="pdd">
                  {intl.formatMessage({ id: 'EDUCATION.CHECK.CASY.SETTINGS' })}
                </Typography>
              </div>
            )}
          </>
        )
      }

    </Opacity>
  );
};

const mapStateToProps = (store) => ({
  currentQuestionIndex: store.WWtK.currentQuestionIndex,
  WWtKs: store.WWtK.WWtKs,
  loading: store.WWtK.loading,
  error: store.WWtK.error,
});

export default injectIntl(connect(mapStateToProps, actions)(Main));
