import React from 'react';

import { Typography } from '../../components';

const Title = ({ children }) => (
  <div>
    <Typography variant="h2">
      {children}
    </Typography>
  </div>
);

export default Title;
