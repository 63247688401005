/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { Portlet, PortletBody, PortletHeader } from '../../../../_metronic/partials/content/Portlet';
import { removeZeroFromTime, transformToAmpm } from '../../../utils';
import { EditIcon } from '../../../assets/icons';
import { Typography, IconButton, CustomButton } from '../../components';
import CollectionDropdown from './CollectionDropdown';
import CollectionMenu from './CollectionMenu';
import * as actions from '../redux/actions';

import {
  useLocation, useHistory, Link
} from 'react-router-dom';

const maxDescriptionHeight = 70;

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('800')]: {
      marginRight: '5px !important'
    },
  },
  collectionMore: {
    maxHeight: 9999,
  },
  descriptionLess: {
    maxHeight: maxDescriptionHeight,
    overflow: 'hidden'
  },
}));

const Collection = ({
  intl,
  data,
  setCollectionWizardIdAction,
  setCollectionWizardStatusAction,
}) => {
  const classes = useStyles();
  const [showDescriptionMore, setShowDescriptionMore] = useState(false);
  const [isDescriptionMore, setIsDescriptionMore] = useState(false);
  const location = useLocation()
  const history = useHistory()
  const queryParams = new URLSearchParams(location.search);


  useEffect(() => {
    setIsDescriptionMore(document.getElementById(`description_${data.coll_id}`).clientHeight >= maxDescriptionHeight);
    const queryParamId = queryParams.get('id');
    if (queryParamId) {
      setCollectionWizardIdAction(queryParamId);
      setCollectionWizardStatusAction(true);
      queryParams.delete('id');
      history.replace({
        search: queryParams.toString(),
      })
    }
  }, []);


  const handleEditClick = (id) => {
    setCollectionWizardIdAction(id);
    setCollectionWizardStatusAction(true);
  };

  return (
    <Portlet className={`mr-4 ${classes.container}`}>
      <PortletHeader
        className="p-0"
        toolbar={(
          <div className="w-100 pt-0 px-4 d-flex justify-content-between align-items-center">
            {data.coll_admin_nm && (
              <Typography variant="h1">
                {data.coll_admin_nm}
              </Typography>
            )}
            <CollectionMenu
              collection={data}
            />
          </div>
        )}
      />
      <PortletBody className="pb-0">
        <div className="d-flex justify-content-between">
          <Typography variant="h2Medium">
            {intl.formatMessage({ id: 'COLLECTION.COLLECTIONS.NAME' })}
          </Typography>
          <IconButton
            tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
            onClick={() => handleEditClick(data.coll_id)}
          >
            <EditIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
          </IconButton>
        </div>
        <div className="mt-3">
          <Typography variant="body">
            {data.coll_nm}
          </Typography>
        </div>

        <div className="d-flex justify-content-between mt-4">
          <Typography variant="h2Medium">
            {intl.formatMessage({ id: 'COLLECTION.COLLECTIONS.DESCRIPTION' })}
          </Typography>
          <IconButton
            tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
            onClick={() => handleEditClick(data.coll_id)}
          >
            <EditIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
          </IconButton>
        </div>
        <div
          id={`description_${data.coll_id}`}
          className={`mt-3 ${showDescriptionMore ? classes.collectionMore : classes.descriptionLess}`}
        >
          <Typography variant="body">
            <div
              dangerouslySetInnerHTML={{
                __html: data.coll_details
              }}
            />
          </Typography>
        </div>
        {
          isDescriptionMore && (
            <div className="d-flex justify-content-center align-items-center">
              <CustomButton
                label={(
                  <Typography variant="bodySemiBold" color={process.env.REACT_APP_COLOR_PRIMARY}>
                    {showDescriptionMore ? intl.formatMessage({ id: 'GENERAL.VIEW.LESS' }) : intl.formatMessage({ id: 'GENERAL.VIEW.MORE' })}
                  </Typography>
                )}
                onClick={() => setShowDescriptionMore(!showDescriptionMore)}
              />
            </div>
          )
        }

        <div className="d-flex justify-content-between mt-4">
          <Typography variant="h2Medium">
            {intl.formatMessage({ id: 'COLLECTION.COLLECTIONS.SET_OUT_TIME' })}
          </Typography>
          <IconButton
            tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
            onClick={() => handleEditClick(data.coll_id)}
          >
            <EditIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
          </IconButton>
        </div>
        <div className="mt-3">
          <Typography variant="body">
            {removeZeroFromTime(transformToAmpm(data.set_out_time))}
          </Typography>
        </div>

        <div className="d-flex justify-content-between mt-4">
          <Typography variant="h2Medium">
            {intl.formatMessage({ id: 'COLLECTION.COLLECTIONS.SET_OUT_DESCRIPTION' })}
          </Typography>
          <IconButton
            tooltip={intl.formatMessage({ id: 'GENERAL.EDIT' })}
            onClick={() => handleEditClick(data.coll_id)}
          >
            <EditIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
          </IconButton>
        </div>
        <div className="mt-3">
          <Typography variant="body">
            <div
              dangerouslySetInnerHTML={{
                __html: data.set_out_time_dscr
              }}
            />
          </Typography>
        </div>
      </PortletBody>

      <CollectionDropdown
        title={intl.formatMessage({ id: 'COLLECTION.COLLECTIONS.WASTE_TYPE' })}
        tooltip={intl.formatMessage({ id: 'COLLECTION.COLLECTIONS.WASTE_TYPE.TOOLTIP' })}
        data={data.waste_types}
        itemProperty="waste_type_admin_nm"
      />

      <CollectionDropdown
        title={intl.formatMessage({ id: 'COLLECTION.COLLECTIONS.DRAWER_LINKS' })}
        tooltip={intl.formatMessage({ id: 'COLLECTION.COLLECTIONS.DRAWER_LINKS.TOOLTIP' })}
        data={data.drawers}
        itemProperty="drawer_title"
      />

    </Portlet>
  );
};

export default injectIntl(connect(null, actions)(Collection));
