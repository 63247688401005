import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Formik } from 'formik';

import * as actions from './redux/actions';
import {
  Body,
  Title,
  SubTitle,
  Form,
  Alert,
  InputField,
  Action,
  Submit,
  Back,
} from '../components';

const OTP = ({
  intl,
  fetchState,
  responseMessage,
  access_token,
  OTPRequest,
  resetLoadingAction
}) => {
  const isSucceedRequest = fetchState === 'succeed-request';
  const isSendRequest = fetchState === 'send-request';

  if (isSucceedRequest) {
    resetLoadingAction();
    return <Redirect to="/home-page" />;
  }

  if (!access_token || (responseMessage && responseMessage.status === 'error')) {
    resetLoadingAction();
    return <Redirect to="/auth/login" />;
  }

  return (
    <Body>
      <Title text="AUTH.MFA.TITLE" />
      <SubTitle text="AUTH.OTP.SUB_TITLE" />
      <Formik
        initialValues={{ otp: '' }}
        validate={(values) => {
          const errors = {};

          if (!values.otp) {
            errors.otp = intl.formatMessage({
              id: 'VALIDATION.REQUIRED_FIELD'
            });
          }

          return errors;
        }}
        onSubmit={(values) => OTPRequest(values.otp)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            <Alert
              condition={fetchState === 'failed-request'}
              responseMessage={{
                status: 'error',
                message: intl.formatMessage({
                  id: 'AUTH.OTP.WRONG_OTP'
                })
              }}
            />
            <InputField
              type="text"
              label={intl.formatMessage({ id: 'AUTH.INPUT.OTP_PASSWORD' })}
              name="otp"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.otp}
              helperText={touched.otp && errors.otp}
              error={Boolean(touched.otp && errors.otp)}
            />
            <Action>
              <Link to="/auth/mfa">
                <Back title="GENERAL.BACK" onClick={resetLoadingAction} />
              </Link>
              <Submit title="AUTH.GENERAL.SUBMIT_BUTTON" isSubmitting={isSendRequest} />
            </Action>
          </Form>
        )}
      </Formik>
    </Body>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.auth.fetchState,
  responseMessage: store.auth.responseMessage,
  access_token: store.auth.access_token,
});

export default injectIntl(connect(mapStateToProps, actions)(OTP));
