/* eslint-disable import/prefer-default-export */
import cts from '../../../../crud/cts';

/**
 *  API to leave feedback
 */
 export const feedbackAPI = async ({ meta, feedback, type }) => cts(
  'post',
  'feedback',
  {
    type,
    meta,
    feedback
  }
);