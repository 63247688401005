import actionTypes from './actionTypes';

// Set district
export const setDistrict = (district) => ({
  type: actionTypes.SET_DASHBOARD_DISTRICT_ACTION,
  payload: district
});

// Dashboard actions for setting from date
export const setFromDateAction = (fromDate) => ({
  type: actionTypes.SET_DASHBOARD_FROM_DATE_ACTION,
  payload: fromDate
});

// Dashboard actions for setting to date
export const setToDateAction = (toDate) => ({
  type: actionTypes.SET_DASHBOARD_TO_DATE_ACTION,
  payload: toDate
});
