import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { LogoutIcon } from '../../../../assets/icons';

import { Typography } from '../../../components';
import ProfileImage from './ProfileImage';

const useStyles = makeStyles({
  profilePicture: {
    borderRadius: '50%',
    width: 60,
    height: 60,
    border: 'none',
    backgroundColor: '#ebebeb',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'relative'
  },
  letterSpacing: {
    letterSpacing: '1px'
  },
  emailText: {
    color: process.env.REACT_APP_COLOR_PRIMARY,
    textDecoration: 'underline'
  },
  manageProfileText: {
    color: '#037CBE',
  },
  signOutText: {
    color: '#FD2310'
  }
});

const ProfileDropdown = ({
  intl,
  userInfo,
}) => {
  const classes = useStyles();

  return (
    <div className="py-4 px-5">
      <div className="row">
        <ProfileImage />
        <div className="col d-flex flex-column justify-content-center">
          <Typography className={classes.letterSpacing} variant="h2Medium">
            {userInfo.first_name}
            {' '}
            {userInfo.last_name}
          </Typography>
          <Typography className={classes.letterSpacing} variant="body">
            {userInfo.job_title?.toUpperCase()}
          </Typography>
        </div>
      </div>
      <div className="row">
        <div className="py-2 mt-1">
          E:
          {' '}
          <Typography component="span" variant="body" className={classes.emailText}>{userInfo.email}</Typography>
        </div>
        <div className="d-flex justify-content-between w-100 mt-3">
          <Link to="/profile">
            <Typography className={classes.manageProfileText} variant="pdd">
              {intl.formatMessage({ id: 'PROFILE.DROPDOWN.MANAGE_PROFILE' })}
            </Typography>
          </Link>
          <Link to="/logout">
            <span className="d-flex">
              <Typography className={classes.signOutText} variant="pdd">
                {intl.formatMessage({ id: 'PROFILE.DROPDOWN.SIGN_OUT' })}
              </Typography>
              <LogoutIcon style={{ fontSize: 18, marginLeft: '7px' }} color="#FD2310" />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  userInfo: store.auth.user_info,
});

export default injectIntl(connect(mapStateToProps, null)(ProfileDropdown));
