import * as React from 'react';

const LocationIcon = ({
  color,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill={color}
    className={className}
  >
    <path d="M10 0C7.243 0 5 2.243 5 5c0 .828.207 1.648.6 2.376l4.127 7.463a.312.312 0 00.546 0l4.128-7.465A5.009 5.009 0 0015 5c0-2.757-2.243-5-5-5zm0 7.5A2.503 2.503 0 017.5 5c0-1.378 1.122-2.5 2.5-2.5s2.5 1.122 2.5 2.5-1.122 2.5-2.5 2.5z" />
  </svg>
);

export default LocationIcon;
