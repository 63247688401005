import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Snackbar } from '../components';
import Header from './components/Header';
import PerpetualSchedules from './components/PerpetualSchedules';
import CalendarLoading from './components/CalendarLoading';
import * as sharedActions from '../redux/actions';
import * as perpetualSchedulesActions from './PerpetualSchedules/redux/actions';
import { Typography, CustomCalendar, CustomButton, MunicipalitySelector } from '../components'

import { convertData, convertHolidayData, getEmptySeasonalListConfig } from './helper';

import {
  Portlet,
  PortletBody,
  PortletHeader
} from '../../../_metronic/partials/content/Portlet';
import qs from 'querystring';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '65%',
    margin: 'auto',
    [theme.breakpoints.down(1670)]: {
      width: '75%'
    },
    [theme.breakpoints.down(1500)]: {
      width: '85%'
    },
    [theme.breakpoints.down(1350)]: {
      width: '100%'
    },
  },
  locality: {
    width: '40%',
    [theme.breakpoints.down(550)]: {
      width: '100%'
    },
  },
  porterHeader: {
    display: 'flex',
    width: '100%',
    height: 100,
  },
  calendarContainer: {
    margin: '20px 5px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editScheduleButton: {
    width: 180,
  },
}));

const Schedule = ({
  intl,
  responseMessage,
  responseStatus,
  data,
  listOfYears,
  fetchState,
  district_holidays,
  setDistrictAction,
  district,
  municipalities,
  completedSetup,
  getPerpetualSchedulesRequest,
  perpetualSchedulesDistrict,
  getSeasonalListRequest,
  setCompletedSetup,
  seasonalList,
  manages_schedules,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const isSendRequest = fetchState === 'send-request';

  const [events, setEvents] = useState([]);
  const cs = qs.parse(location.search.substring(1)).completedSetup


  useEffect(() => {
    if (district.district_id === '#all-districts#') {
      setDistrictAction(municipalities[0]);
    } else {
      const districts = municipalities.map((municipality) => municipality.district_id);
      if (manages_schedules !== 'f') {
        getPerpetualSchedulesRequest(districts);
      }
    }
  }, [district]);

  useEffect(() => {
    if (data && data.length) {
      const tempEvents = convertData(data);
      const holidayEvents = convertHolidayData(district_holidays);
      setEvents([...tempEvents, ...holidayEvents]);
    }
  }, [data, district_holidays]);

  const [displayPerpetualSchedules, setDisplayPerpetualSchedules] = useState(true);

  const handleExitWizard = () => {
    setDisplayPerpetualSchedules(false);
    const newUrl = window.location.pathname;
    history.replace(newUrl);
  };

  useEffect(() => {
    if (manages_schedules && manages_schedules === 'f') {
      setDisplayPerpetualSchedules(false);
    }
  }, [manages_schedules]);


  useEffect(() => {
    if (municipalities && municipalities.length && perpetualSchedulesDistrict && perpetualSchedulesDistrict.length && perpetualSchedulesDistrict.length === municipalities.length && !cs) {
      const districts = perpetualSchedulesDistrict.map((district) => `${district.project_id}#$#${district.district_id}`).join(',');
      getSeasonalListRequest(districts.split(','));
    } else if (municipalities && municipalities.length && perpetualSchedulesDistrict && perpetualSchedulesDistrict.length && perpetualSchedulesDistrict.length !== municipalities.length && !cs && manages_schedules !== 'f') {
      setCompletedSetup(false);
      history.push('/perpetual-schedules');
    }

  }, [perpetualSchedulesDistrict, municipalities]);

  useEffect(() => {
    if (seasonalList && seasonalList?.seasonal_list.length) {
      // Filter the seasonal list to return items where season_end_config or season_start_config is null, an empty object, or the string "{}"
      const filteredSeasonalList = getEmptySeasonalListConfig(seasonalList);
      if (filteredSeasonalList && filteredSeasonalList.seasonal_list.length) {
        history.push('/perpetual-schedules');
      } else {
        setCompletedSetup(true);
      }
    } else if (municipalities && municipalities.length && perpetualSchedulesDistrict && perpetualSchedulesDistrict.length && perpetualSchedulesDistrict.length === municipalities.length) {
      if (completedSetup && !cs) {
        setCompletedSetup(true);
        setDisplayPerpetualSchedules(false);
      } else if (cs && cs === 'true') {
        setCompletedSetup(true);
        setDisplayPerpetualSchedules(true);
      } else {
        setDisplayPerpetualSchedules(false);
      }
    }

  }, [seasonalList]);

  const handleCollectionChange = (collection) => {
    const tempEvents = convertData(data);
    const holidayEvents = convertHolidayData(district_holidays);
    const allEvents = [...tempEvents, ...holidayEvents];
    const collectionEvents = allEvents.filter((event) => event.id === collection.prompt_id);
    setEvents(collectionEvents);
  };

  return (
    <div className={classes.root}>
      {
        displayPerpetualSchedules
          ? <PerpetualSchedules handleExitWizard={handleExitWizard} cs={cs === 'true'}/>
          : (
            <>
              <Snackbar
                message={responseMessage}
                status={responseStatus}
              />
              <Portlet className='h-100 position-relative'>
                <PortletHeader
                  className={`${classes.porterHeader}`}
                  title={(
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <Typography variant="h1">
                        Schedule
                      </Typography>
                      <div style={{
                        width: '29%'
                      }}>
                        <MunicipalitySelector
                          hasAllDistrictOption={false}
                          title="Reporting District"
                          onChange={setDistrictAction}

                          value={district.district_id === '#all-districts#' ? municipalities[0] : district}
                        />
                      </div>

                    </div>
                  )} />
                <PortletBody className="pt-0">
                  <Header handleCollectionChange={handleCollectionChange} />
                  <div className={classes.calendarContainer}>
                    {
                      // eslint-disable-next-line no-nested-ternary
                      isSendRequest ? <CalendarLoading /> : listOfYears.length
                        ? (
                          <CustomCalendar events={events} />
                        )
                        : null
                    }
                  </div>
                  {/* Hiding the Edit Schedule button for now */}
                  {/* <div className={classes.buttonContainer}>
                    <CustomButton
                      paddingVertical={4}
                      className={classes.editScheduleButton}
                      variant={'contained'}
                      label={'Edit Schedule'}
                      onClick={() => {
                        console.log('Edit Schedule');
                      }}
                    />
                  </div> */}
                </PortletBody>
              </Portlet>
            </>
          )
      }
    </div>
  );
};

const mapStateToProps = (store) => ({
  responseMessage: store.schedule.responseMessage,
  responseStatus: store.schedule.responseStatus,
  fetchState: store.schedule.fetchState,
  data: store.schedule.data,
  listOfYears: store.schedule.years,
  district_holidays: store.schedule.district_holidays,
  district: store.common.district,
  municipalities: store.common.municipalities,
  completedSetup: store.perpetualSchedules.completedSetup,
  perpetualSchedulesDistrict: store.perpetualSchedules.districts,
  seasonalList: store.perpetualSchedules.seasonalList,
  user_info: store.auth.user_info,
  manages_schedules: store.auth.user_info.manages_schedules,
});

export default injectIntl(connect(mapStateToProps, { ...sharedActions, ...perpetualSchedulesActions })(Schedule));
