import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Opacity, Typography } from '../../../components';
import { ErrorContainer, LoadingSpinner } from '../../../components/loading';
import { Main } from './components';
import * as actions from './redux/actions';
import * as sharedActions from '../../../redux/actions';
import { Redirect } from 'react-router-dom';

import { CrownIcon } from '../../../../assets/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';



const MajorCampaigns = ({
  intl,
  loading = false,
  error,
  stage,
  district,
  municipalities,
  setDistrictAction,
  getMajorCampaignsDataRequestAction,
  majorCampaigns,
  majorCampaign,
  setMajorCampaignAction,
}) => {
  const history = useHistory();
  const path =
    useLocation().pathname;


  useEffect(() => {
    if (path === '/education/major-campaigns') {
      setMajorCampaignAction(null);

    }
  }, [path]);

  useEffect(() => {
    if (district.district_id === '#all-districts#') {
      setDistrictAction(municipalities[0]);
    }
  }, [district]);

  const goBack = () => {
    setMajorCampaignAction(null);
    history.push('/education/major-campaigns/');

  };


  return (
    <Opacity slide>

      <Typography className="d-flex align-items-center mb-3" variant="h1">
        {
          majorCampaign && (
            <div
              role="button"
              style={{ marginRight: 10 }}
            >
              <ArrowBackIcon onClick={goBack} />
            </div>
          )
        }
        {majorCampaign && majorCampaign.text ? majorCampaign.text : intl.formatMessage({ id: 'EDUCATION.MAJOR.CAMPAIGNS' })}
        <CrownIcon style={{ marginLeft: 7 }} width={15} height={15} color="#FFC611" />
      </Typography>

      {loading
        ? <LoadingSpinner height={350} />
        : error ? (
          <ErrorContainer
            small
            reportName="MajorCampaigns"
            height={350}
            error={error}
          />
        ) : <Main stage={stage} />}
    </Opacity>
  );
};

const mapStateToProps = (store) => ({
  stage: store.common.district.stage,
  district: store.common.district,
  municipalities: store.common.municipalities,
  majorCampaigns: store.majorCampaigns.major_campaigns,
  majorCampaign: store.majorCampaigns.major_campaign,

});

export default injectIntl(connect(mapStateToProps, { ...actions, ...sharedActions })(MajorCampaigns));
