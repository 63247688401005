import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  Typography, Pagination, Switch, AutoComplete, TextField, IconButton
} from '../../components';
import {
  Portlet, PortletBody, PortletHeader
} from '../../../../_metronic/partials/content/Portlet';
import * as actions from '../redux/actions';
import { TableLoading } from '../../components/loading';
import * as sharedActions from '../../redux/actions';
import { InfoIcon } from '../../../assets/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '75%',
    [theme.breakpoints.down('1200')]: {
      width: '94%'
    },
  },
  standardView: {
    display: 'flex',
  },
  tableRow: {
    minHeight: '45px',
  },
  border: {
    borderTop: `2px solid ${process.env.REACT_APP_COLOR_DISABLED_TEXT}`,
  },
  tableHeader: {
    minHeight: '55px',
    marginTop: '30px',
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  responsive: {
    marginLeft: '10px !important',
    [theme.breakpoints.down('1200')]: {
      paddingRight: '20px !important',
      marginLeft: '10px !important',
      marginBottom: '30px !important',
    },
  }
}));

const Main = ({
  intl,
  fetchState,
  data,
  locality,
  wasteAndRulesRequest,
  editWasteAndRulesRequest,
  localityList,
  setLocalityAction
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rules, setRules] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const isSendRequest = fetchState === 'send-request';

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (locality && locality.cms_locality_id) {
      wasteAndRulesRequest({ cms_locality_id: locality.cms_locality_id });
    }
  }, [locality]);

  useEffect(() => {
    if (data && data.length) {
      setRules(data.filter((item) => item.visible_to_clients !== 'f'));
    }
  }, [data]);

  return (
    <div className={classes.root}>
      <Portlet>
        <PortletHeader
          title={intl.formatMessage({ id: 'WASTE_AND_RECYCLING_RULES.MAIN_TITLE' })}
        />
        <PortletHeader
          title={(
            <Typography variant="bodySemiBold">
              {intl.formatMessage({ id: 'WASTE_AND_RECYCLING_RULES.MAIN_DESCRIPTION' })}
            </Typography>
          )}
        />
        <PortletBody>
          <div className="row w-100 d-flex flex-row justify-content-between">
            <TextField
              className={`col-md-12 col-xl-4 ${classes.responsive}`}
              label={intl.formatMessage({ id: 'GENERAL.SEARCH' })}
              onChange={(e) => {
                setSearch(e);
              }}
              value={search}
            />
            <AutoComplete
              className="col-md-12 col-xl-4"
              title={intl.formatMessage({ id: 'GENERAL.LOCALITY' })}
              options={localityList}
              optionTitle="cms_locality_nm"
              optionId="cms_locality_id"
              disableClearable
              onChange={(value) => setLocalityAction({ locality: value })}
              value={locality}
            />
          </div>
          { isSendRequest
            ? (
              <div className="pt-4">
                <TableLoading width="100%" />
              </div>
            )
            : (
              <div className={`row d-flex flex-column m-3 mb-0 ${classes.standardView}`}>
                <div className={`row w-100 d-flex align-items-center ${classes.tableHeader}`}>
                  <Typography
                    className="d-flex col-6 justify-content-start"
                    variant="bodySemiBold"
                  >
                    {intl.formatMessage({ id: 'WASTE_AND_RECYCLING_RULES.TITLE' })}
                  </Typography>

                  <Typography
                    className="d-flex pl-0 col-6 justify-content-center"
                    variant="bodySemiBold"
                  >
                    {intl.formatMessage({ id: 'WASTE_AND_RECYCLING_RULES.VALUE' })}
                  </Typography>

                </div>

                { rules && [...rules]
                  .filter((item) => (search !== '' ? item.waste_recycling_rule.toLowerCase().includes(search.toLowerCase()) : item))
                  .slice((page - 1) * rowsPerPage, (page * rowsPerPage))
                  .map((item, id) => (
                    <div
                      key={`RULES_${item.waste_recycling_rule} - ${String(id)}`}
                      className={`row w-100 d-flex h-100 ${classes.tableRow} ${classes.border}`}
                    >

                      <Typography
                        className={`col-6 h-100 d-flex align-items-center justify-content-start ${classes.tableRow}`}
                        variant="bodySemiBold"
                      >
                        {item.waste_recycling_rule}
                        {
                          item.tooltip && item.tooltip.length > 0
                          && (
                          <IconButton
                            tooltip={item.tooltip}
                          >
                            <InfoIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                          </IconButton>
                          )
                        }
                      </Typography>

                      <div
                        className={`col-6 h-100 d-flex align-items-center justify-content-center ${classes.tableRow}`}
                      >
                        <Switch
                          isActive={item.waste_recycling_rule_value === 'f'}
                          onChange={async () => {
                            await editWasteAndRulesRequest({
                              cms_locality_id: locality.cms_locality_id,
                              waste_recycling_rule_id: item.waste_recycling_rule_id,
                              rip_: item.rip_,
                              waste_recycling_rule_value: item.waste_recycling_rule_value === 't' ? 'f' : 't'
                            });
                            const temp = [...rules];
                            const ruleId = temp.findIndex(
                              (it) => it.waste_recycling_rule_id === item.waste_recycling_rule_id
                            );
                            temp[ruleId] = {
                              ...temp[ruleId],
                              waste_recycling_rule_value: temp[ruleId].waste_recycling_rule_value === 't' ? 'f' : 't'
                            };
                            setRules(temp);
                          }}
                          leftLabel={intl.formatMessage({ id: 'WASTE_AND_RECYCLING_RULES.VALUE.TRUE' })}
                          rightLabel={intl.formatMessage({ id: 'WASTE_AND_RECYCLING_RULES.VALUE.FALSE' })}
                        />
                      </div>

                    </div>
                  ))}
                <div className="mt-5 mb-4 d-flex justify-content-end align-items-center">
                  <Pagination
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handlePageChange={(value) => handleChangePage(value)}
                    handleRowsPerPageChange={(value) => setRowsPerPage(value)}
                    dataLength={data && data.length}
                  />
                </div>
              </div>
            )}
        </PortletBody>
      </Portlet>
    </div>
  );
};

const mapStateToProps = (store) => ({
  locality: store.common.locality,
  localityList: store.common.localityList,
  data: store.wasteAndRecyclingRules.data,
  fetchState: store.wasteAndRecyclingRules.fetchState,
});

export default injectIntl(
  connect(
    mapStateToProps,
    { ...actions, ...sharedActions }
  )(Main)
);
