import cts from '../../../../crud/cts';

export const getMajorCampaignsData = async ({ project_id, district_id, from, to }) => {
  const municipality = `${project_id}#$#${district_id}`;
  return cts(
    'get',
    `/education/major_campaigns?municipality=${encodeURIComponent(municipality)}&from=${from}&to=${to}`,
  )
};

export const getMajorCampaignsReportData = async ({ project_id, district_id }) => cts(
  'post',
  `/education/major_campaigns/report`,
  {
    district_id: `${project_id}#$#${district_id}`,
  }
);

export const getMajorCampaignsDetailsData = async ({ project_id, district_id, allocation_code }) => {
  const municipality = `${project_id}#$#${district_id}`;
  return cts(
    'get',
    `/education/major_campaigns/details?municipality=${encodeURIComponent(municipality)}&allocation_code=${allocation_code}`,
  )
};

