import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import { MunicipalitySelector, Typography } from '../../components';
import CurrentPeriodDatePicker from './CurrentPeriodDatePicker';
import * as actions from '../../redux/actions';

const SummaryHeader = ({
  intl,
  title,
  municipalities,
  district,
  setDistrictAction,
  fromDate,
  toDate,
  setDateRangeAction,
  allTime,
  handleAllTimeChange,
  handleDataRequest,
  handleSecondDataRequest,
  hasAllDistrictOption = true,
  hasAllTimeOption,
  startOfAllTime,
  county,
  isCounty,
  counties,
  setCountyAction,
  hasCounty,
  isCountyEnable = true,
  className,
  advocateAdmin
}) => {
  useEffect(() => {
    if (municipalities.length !== 0) {
      handleDataRequest({
        county: hasCounty !== null && isCounty ? encodeURIComponent(county.county_id) : '',
        municipality: district && district.district_id && !isCounty
          ? encodeURIComponent(district.district_id)
          : '',
        from: allTime ? startOfAllTime : fromDate,
        to: allTime ? moment().format('YYYY-MM-DD') : toDate
      });

      if (handleSecondDataRequest) {
        handleSecondDataRequest({
          county: hasCounty !== null && isCounty ? county.county_id : '',
          municipality: district && district.district_id && !isCounty
            ? district.district_id
            : '',
          from: allTime ? startOfAllTime : fromDate,
          to: allTime ? moment().format('YYYY-MM-DD') : toDate
        });
      }
    }
  }, [fromDate, toDate, allTime, district, county, isCounty]);

  return (
    <div className={`row mb-0 mb-lg-4 ${className}`}>
      <div className="col-12 col-lg-8 d-flex flex-column flex-lg-row align-items-start align-items-lg-center mb-4">
        <Typography className="mr-4" variant="h1">
          {title}
        </Typography>
        <CurrentPeriodDatePicker
          fromDate={fromDate}
          toDate={toDate}
          handleDateRangeChange={setDateRangeAction}
          allTime={allTime}
          handleAllTimeChange={handleAllTimeChange}
          hasAllTimeOption={hasAllTimeOption}
        />
      </div>
      <div className="col-12 col-lg-4">
        <MunicipalitySelector
          title={advocateAdmin !== true ? (hasCounty !== null && isCounty && counties) ? 'County'
            : intl.formatMessage({ id: 'REPORT.GENERAL.REPORTING_DISTRICT' })
            : intl.formatMessage({ id: 'REPORT.GENERAL.REPORTING_WORKPLACE' })}
          onChange={(hasCounty !== null && isCounty) ? setCountyAction : setDistrictAction}
          value={(hasCounty !== null && isCounty) ? county : district}
          hasAllDistrictOption={hasAllDistrictOption}
          isCountyEnable={isCountyEnable}
          disbleSingleCountyDropdown
        />
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  municipalities: store.common.municipalities,
  counties: store.common.counties,
  county: store.common.county,
  isCounty: store.common.isCounty,
  district: store.common.district,
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
  hasCounty: store.auth.user_info.counties,
  advocateAdmin: store.auth.advocateAdmin
});

export default injectIntl(connect(mapStateToProps, actions)(SummaryHeader));
