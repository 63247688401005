import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  useLocation, useHistory, Link
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import moment from 'moment';

import ReportPDF from './ReportPDF';
import { dataChangeTracking, isValidDate, checkValidDistrict } from '../../../../utils';
import { PdfIcon, BackIcon, } from '../../../../assets/icons';
import {
  Portlet, PortletBody, PortletHeader, PortletHeaderToolbar,
} from '../../../../../_metronic/partials/content/Portlet';
import {
  SummaryHeader,
  SummaryCurrentPeriod,
  SummaryPreviousPeriod,
  SummaryUserRating,
  SummaryPeerRating,
  RCSpinner,
} from '../../components';
import * as actions from '../redux/actions';
import * as sharedActions from '../../../redux/actions';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_PRIMARY,
  },
  pdfDisable: {
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_GRAY_DARK,
  }
}));

const Summary = ({
  intl,
  interactionReportRequest,
  data,
  detailData,
  district,
  setDistrictAction,
  fromDate,
  setDateRangeAction,
  toDate,
  fetchState,
  municipalities,
  county,
  counties,
  setCountyAction,
  isCounty,
  setIsCountyAction,
  hasCounty,
  advocateAdmin
}) => {
  const history = useHistory();
  const classes = useStyles();
  const queryParams = new URLSearchParams(useLocation().search);
  const isSendRequest = fetchState === 'send-request';
  const [pdfSpinner, setPdfSpinner] = useState(false);

  useEffect(() => {
    if (history.location.search) {
      if (queryParams.get('district') && checkValidDistrict(
        queryParams.get('district').replace('-', '#$#'),
        municipalities,
        'district_id'
      )) {
        setDistrictAction({
          district_id: queryParams.get('district').replace('-', '#$#'),
          district_nm: municipalities.filter((item) => item.district_id === queryParams.get('district').replace('-', '#$#'))[0].district_nm,
        });
        setIsCountyAction(false);
      } else {
        setDistrictAction(municipalities[0]);
      }
      if (queryParams.get('county') && checkValidDistrict(
        queryParams.get('county'),
        counties,
        'county_id'
      ) && hasCounty !== null) {
        setCountyAction({
          county_id: queryParams.get('county'),
          county_nm: counties.filter((item) => item.county_id === queryParams.get('county'))[0].county_nm,
        });
        setIsCountyAction(true);
      } else {
        setCountyAction(counties[0]);
      }
      if (queryParams.get('from') && queryParams.get('to') && isValidDate(queryParams.get('from')) && isValidDate(queryParams.get('to'))) {
        setDateRangeAction({
          fromDate: queryParams.get('from'),
          toDate: queryParams.get('to'),
        });
      }
    } else if (municipalities.length === 1) {
      setDistrictAction(municipalities[0]);
    } else {
      setDistrictAction({
        district_id: '#all-districts#',
        district_nm: 'All Districts'
      });
    }
  }, []);

  useEffect(() => {
    if (isCounty) {
      history.replace({
        pathname: '/report/interaction',
        search: `?county=${county.county_id}&from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
      });
    } else {
      history.replace({
        pathname: '/report/interaction',
        search: `?district=${district.district_id === '#all-districts#' ? 'all-districts' : district.district_id.replace('#$#', '-')}&from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
      });
    }
  }, [fromDate, toDate, district, county, isCounty]);

  const generatePDF = async () => {
    setPdfSpinner(true);
    const blob = await pdf((
      <ReportPDF
        district={district.district_id}
        interactionAtAGlance={intl.formatMessage({ id: 'REPORT.INTERACTIONS.SUMMARY.HEADER' })}
        currentPeriod={`${intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_PERIOD' })}:`}
        date={`${fromDate} - ${toDate}`}
        reportingDistrict={isCounty ? `Reporting County : ${county.county_nm}`
          : `Reporting District : ${district.district_id !== '#all-districts#' ? district.district_nm : 'All Districts'}`}
        currentPeriodData={data.current_period}
        currentPeriodType={dataChangeTracking(data.current_period, data.last_period)}
        previousPeriodData={data.last_period}
        rating={data.rating}
        peerRating={data.peer_rating}
        detailData={detailData.detail}
        peerDetail={detailData.detail_peer}
        isCounty={isCounty}
      />
    )).toBlob();
    saveAs(blob, 'Interaction Report.pdf');
    setPdfSpinner(false);
  };

  return (
    <Portlet>
      <PortletHeader
        title={(
          <Link to="/report/dashboard">
            <Button
              classes={{
                label: classes.label,
              }}
              startIcon={<BackIcon color={process.env.REACT_APP_COLOR_PRIMARY} />}
            >
              {intl.formatMessage({ id: 'REPORT.GENERAL.BACK' })}
            </Button>
          </Link>
        )}
        toolbar={(
          <PortletHeaderToolbar>
            <Button
              disabled={!data}
              classes={{
                label: !data ? classes.pdfDisable : classes.label,
              }}
              startIcon={(
                <PdfIcon
                  color={!data ? process.env.REACT_APP_COLOR_GRAY_DARK
                    : process.env.REACT_APP_COLOR_PRIMARY}
                />
              )}
              onClick={generatePDF}
            >
              {intl.formatMessage({ id: 'REPORT.GENERAL.PDF' })}
            </Button>
            <RCSpinner isActive={pdfSpinner} />
          </PortletHeaderToolbar>
        )}
      />
      <PortletBody>
        <SummaryHeader
          title={intl.formatMessage({ id: 'REPORT.INTERACTIONS.SUMMARY.HEADER' })}
          handleDataRequest={interactionReportRequest}
        />
        <div className="row mb-4">
          <SummaryCurrentPeriod
            type={dataChangeTracking(
              data ? data.current_period : 0,
              data ? data.last_period : 0
            )}
            data={data ? data.current_period : 0}
            isLoading={isSendRequest}
          />
          <SummaryPreviousPeriod
            data={data ? data.last_period : 0}
            isLoading={isSendRequest}
          />
          {advocateAdmin !== true
            && (
              <SummaryUserRating
                title={intl.formatMessage({ id: 'REPORT.GENERAL.YOUR_RATING' })}
                data={data ? data.rating : 0}
                isLoading={isSendRequest}
                keyword="Interactions"
              />
            )}
          {advocateAdmin !== true
            && (
              <SummaryPeerRating
                title={intl.formatMessage({ id: 'REPORT.GENERAL.PEER_GROUP_RATING' })}
                data={data ? data.peer_rating : 0}
                isLoading={isSendRequest}
                keyword="Interactions"
              />
            )}
        </div>
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
  fetchState: store.interactionReport.fetchState,
  detailData: store.interactionReport.data,
  municipalities: store.common.municipalities,
  county: store.common.county,
  counties: store.common.counties,
  isCounty: store.common.isCounty,
  hasCounty: store.auth.user_info.counties,
  advocateAdmin: store.auth.advocateAdmin
});

export default injectIntl(
  connect(
    mapStateToProps,
    { ...actions, ...sharedActions }
  )(Summary)
);
