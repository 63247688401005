import React from 'react';

const PlusIcon = ({
  color,
  className,
}) => (
  <svg className={`prefix__bi prefix__bi-plus ${className}`} width="1.3em" height="1.3em" viewBox="0 0 16 16" fill={color}>
    <path
      fillRule="evenodd"
      d="M8 4a.5.5 0 01.5.5v3h3a.5.5 0 010 1h-3v3a.5.5 0 01-1 0v-3h-3a.5.5 0 010-1h3v-3A.5.5 0 018 4z"
    />
  </svg>
);

export default PlusIcon;
