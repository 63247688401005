import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  useLocation, useHistory, Link
} from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import moment from 'moment';

import ReportPDF from './ReportPDF';
import {
  dataChangeTracking, addCommasToNumber, isValidDate, checkValidDistrict
} from '../../../../utils';
import { PdfIcon, BackIcon } from '../../../../assets/icons';
import { Typography } from '../../../components';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from '../../../../../_metronic/partials/content/Portlet';
import SummaryCell from './SummaryCell';
import { SummaryHeader, RCSpinner } from '../../components';
import { SummaryOtherLoading } from '../../../components/loading';
import * as actions from '../redux/actions';
import * as sharedActions from '../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '12px',
    fontWeight: 400,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_PRIMARY,
  },
  border: {
    '@media (min-width: 1200px)': {
      borderLeft: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
    },
  },
  border2: {
    '@media (max-width: 1199px)': {
      borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    },
  },
  border3: {
    [theme.breakpoints.between('990', '1199')]: {
      borderRight: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
    },
  },
  padding: {
    '@media (max-width: 1199px)': {
      padding: '30px !important',
    },
  },
  visibleUp: {
    [theme.breakpoints.up('990')]: {
      display: 'flex !important',
    },
    [theme.breakpoints.down('990')]: {
      display: 'none !important',
    },
  },
  VisibleDown: {
    [theme.breakpoints.up('990')]: {
      display: 'none !important',
    },
    [theme.breakpoints.down('990')]: {
      display: 'flex !important',
    },
  },
  description: {
    textAlign: 'right'
  },
  integerText: {
    fontSize: '45px',
    fontWeight: 600,
  },
  noData: {
    minHeight: '130px'
  },
  noDataMobile: {
    minHeight: '50px'
  },
  noDataColor: {
    color: process.env.REACT_APP_COLOR_GRAY_DARK
  },
  detailLoading: {
    height: '5vh'
  },
  pdfDisable: {
    fontSize: '13px',
    fontWeight: 500,
    textTransform: 'none',
    color: process.env.REACT_APP_COLOR_GRAY_DARK,
  }
}));

const Summary = ({
  intl,
  campaignReportRequest,
  data,
  municipalities,
  district,
  setDistrictAction,
  fromDate,
  toDate,
  setDateRangeAction,
  allTime,
  setCampaignReportAllTimeAction,
  fetchState,
  detailData,
  county,
  isCounty,
  setIsCountyAction,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);
  const isSendRequest = fetchState === 'send-request';
  const [pdfSpinner, setPdfSpinner] = useState(false);

  useEffect(() => {
    setIsCountyAction(false);
    if (history.location.search) {
      if (queryParams.get('district') && checkValidDistrict(
        queryParams.get('district').replace('-', '#$#'),
        municipalities,
        'district_id'
      )) {
        setDistrictAction({
          district_id: queryParams.get('district').replace('-', '#$#'),
          district_nm: municipalities.filter((item) => item.district_id === queryParams.get('district').replace('-', '#$#'))[0].district_nm,
        });
        setIsCountyAction(false);
      } else {
        setDistrictAction(municipalities[0]);
      }
      if (queryParams.get('from') && queryParams.get('to') && isValidDate(queryParams.get('from')) && isValidDate(queryParams.get('to'))) {
        setDateRangeAction({
          fromDate: queryParams.get('from'),
          toDate: queryParams.get('to'),
        });
      }
    } else {
      setDistrictAction(municipalities[0]);
    }
  }, []);

  useEffect(() => {
    if (isCounty) {
      history.replace({
        pathname: `/report/campaign/${county.county_id}/${county.county_nm}`,
        search: !allTime ? `?county=${county.county_id}&from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
          : `?county=${county.county_id}`
      });
    } else {
      history.replace({
        pathname: `/report/campaign/${district.district_id.replace('#$#', '-')}/${district.district_nm}`,
        search: !allTime ? `?district=${district.district_id === '#all-districts#' ? 'all-districts' : district.district_id.replace('#$#', '-')}&from=${moment(fromDate).format('YYYY-MM-DD')}&to=${moment(toDate).format('YYYY-MM-DD')}`
          : `?district=${district.district_id.replace('#$#', '-')}`
      });
    }
  }, [fromDate, toDate, district, county, isCounty]);

  const generatePDF = async () => {
    setPdfSpinner(true);
    const blob = await pdf((
      <ReportPDF
        district={district.district_id}
        usersAtAGlance={intl.formatMessage({ id: 'REPORT.CAMPAIGN.SUMMARY.HEADER' })}
        currentPeriod={`${intl.formatMessage({ id: 'REPORT.GENERAL.CURRENT_PERIOD' })}:`}
        date={`${fromDate.slice(0, 13)} - ${toDate.slice(0, 13)}`}
        reportingDistrict={isCounty ? `Reporting County : ${county.county_nm}`
          : `Reporting District : ${district.district_id !== '#all-districts#' ? district.district_nm : 'All Districts'}`}
        detailData={detailData.campaigns}
      />
    )).toBlob();
    saveAs(blob, 'Campaign Report.pdf');
    setPdfSpinner(false);
  };

  return (
    <Portlet>
      <PortletHeader
        title={(
          <Link to="/report/dashboard">
            <Button
              classes={{
                label: classes.label,
              }}
              startIcon={<BackIcon color={process.env.REACT_APP_COLOR_PRIMARY} />}
            >
              {intl.formatMessage({ id: 'REPORT.GENERAL.BACK' })}
            </Button>
          </Link>
            )}
        toolbar={(
          <PortletHeaderToolbar>
            <Button
              disabled={isSendRequest}
              classes={{
                label: isSendRequest ? classes.pdfDisable : classes.label,
              }}
              startIcon={(
                <PdfIcon
                  color={isSendRequest ? process.env.REACT_APP_COLOR_GRAY_DARK
                    : process.env.REACT_APP_COLOR_PRIMARY}
                />
              )}
              onClick={generatePDF}
            >
              {intl.formatMessage({ id: 'REPORT.GENERAL.PDF' })}
            </Button>
            <RCSpinner isActive={pdfSpinner} />
          </PortletHeaderToolbar>
            )}
      />
      <PortletBody>
        <SummaryHeader
          title={intl.formatMessage({ id: 'REPORT.CAMPAIGN.SUMMARY.HEADER' })}
          allTime={allTime}
          handleAllTimeChange={setCampaignReportAllTimeAction}
          handleDataRequest={campaignReportRequest}
          hasAllDistrictOption={false}
          hasAllTimeOption
          startOfAllTime="2019-10-01"
          isCountyEnable={false}
        />
        <div className="row mb-4 pt-0 align-items-between align-content-end position-relative">
          <div className="col-12 col-xl-6 pt-3">
            { !isSendRequest
              && (
                <>
                  <Typography className="mt-4" variant="h1">
                    {data && data.length !== 0
                      && `${intl.formatMessage({ id: 'REPORT.CAMPAIGN.SUMMARY.CURRENT_FEATURED_LESSON' })}`}
                  </Typography>
                  <Typography className="mb-1" variant="h2">
                    {data && data.length !== 0 ? data[0].name : null}
                  </Typography>
                </>
              )}
            <div className="row">
              <div className={`col-12 col-lg-6 pb-3 pb-xl-0 pb-lg-0 ${classes.border2} ${classes.border3} ${classes.padding}`}>
                <SummaryCell
                  isLoading={isSendRequest}
                  type="previous"
                  data={data && data.length !== 0 ? data[0].invited_users : 0}
                  description={intl.formatMessage({ id: 'REPORT.CAMPAIGN.SUMMARY.INVITED_USER' })}
                />
              </div>
              <div className={`col-12 col-lg-6 mb-3 mb-xl-0 mb-lg-0 pb-2 pb-lg-0 pl-xl-5 ${classes.border} ${classes.border2} ${classes.padding}`}>
                <SummaryCell
                  isLoading={isSendRequest}
                  type={dataChangeTracking(
                    data && data.length !== 0 && data[0] ? data[0].participation_rate : 0,
                    data && data.length !== 0 && data[1] ? data[1].participation_rate : 0
                  )}
                  data={data && data.length !== 0 ? data[0].participation_rate : 0}
                  color={process.env.REACT_APP_COLOR_POSITIVE}
                  description={intl.formatMessage({ id: 'REPORT.CAMPAIGN.SUMMARY.PARTICIPATION_RATE' })}
                />
              </div>
            </div>
          </div>
          <div className={`col-12 col-xl-6 d-flex flex-column pb-2 pl-xl-5 ${classes.border} ${classes.padding}`}>
            <div className={`h-100 d-flex flex-column justify-content-between mt-4 mt-lg-0 ${classes.visibleUp}`}>
              {!isSendRequest
                && (
                <Typography className="mt-4" variant="h1">
                  {intl.formatMessage({ id: 'REPORT.CAMPAIGN.SUMMARY.RECENT_FEATURED_LESSON' })}
                </Typography>
                )}
              { !isSendRequest
                ? data && data.length > 1 && data.map((item, index) => (
                  index > 0 && index < 4
                      && (
                      <div key={String(index)} className="row align-items-center">
                        <Typography className="col-8 col-lg-6" variant="h2">
                          {item.name}
                          {'*'}
                        </Typography>
                        <Typography className="col-4 col-lg-6 row justify-content-end justify-content-lg-start" variant="numberSmall">
                          {addCommasToNumber(item.invited_users)}
                        </Typography>
                      </div>
                      )
                ))
                : (
                  <SummaryOtherLoading />
                )}

            </div>

            <div className={`h-100 d-flex flex-column justify-content-start mt-4 mt-lg-0 ${classes.VisibleDown}`}>

              {!isSendRequest
                ? data && data.length > 1 && data.map((item, index) => (
                  index > 0 && index < 4
                      && (
                      <div key={String(index)} className="d-flex flex-column justify-content-center align-items-start">
                        <Typography className="mb-3" variant="h2">
                          {data[index].name}
                          {'*'}
                        </Typography>
                        <Typography className="mb-5" variant="numberSmall">
                          {addCommasToNumber(data[index].invited_users)}
                        </Typography>
                      </div>
                      )
                ))
                : (
                  <div className={`d-flex justify-content-center align-items-center ${classes.noDataMobile}`}>
                    <SummaryOtherLoading />
                  </div>
                )}

            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-0">
          <Typography variant="body">
            {intl.formatMessage({ id: 'REPORT.CAMPAIGN.SUMMARY.INVITED_USERS_TOTAL' })}
          </Typography>
        </div>
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  municipalities: store.common.municipalities,
  district: store.common.district,
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
  allTime: store.campaignReport.allTime,
  fetchState: store.campaignReport.fetchState,
  detailData: store.campaignReport.data,
  county: store.common.county,
  counties: store.common.counties,
  isCounty: store.common.isCounty,
  hasCounty: store.auth.user_info.counties,
});

export default injectIntl(
  connect(
    mapStateToProps,
    { ...actions, ...sharedActions }
  )(Summary)
);
