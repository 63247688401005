import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';
import createReducer from '../../../../../redux/createReducer';
import initialStore from './initialStore';

const WeWantedToKnowReducer = persistReducer({
  storage,
  key: 'whereDoesThisGo',
  blackList: ['currentQuestionIndex'],
  whitelist: []
}, createReducer(initialStore, {
  GET_WHERE_DOES_THIS_GO_DATA_REQUEST: (state) => Immutable({
    ...state,
    loading: true,
    error: false,
  }),
  GET_WHERE_DOES_THIS_GO_DATA_RECEIVE: (state, { payload }) => Immutable({
    ...state,
    loading: false,
    ...payload.data,
    error: false

  }),
  GET_WHERE_DOES_THIS_GO_DATA_FAIL: (state, { payload }) => Immutable({
    ...state,
    loading: false,
    error: payload.data,
  }),

  SET_CURRENT_QUESTION_INDEX: (state, { index }) => Immutable({
    ...state,
    currentQuestionIndex: index
  }),
}));

export default WeWantedToKnowReducer;
