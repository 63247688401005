import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, CustomButton, IconButton } from '../../components';
import { PlusCircleIcon, ExpandIcon, CollapseIcon } from '../../../assets/icons';
import SetupWizardLocationDetailsLocation from './SetupWizardLocationDetailsLocation';
import DefaultDaysOfTheWeek from '../helpers';

const useStyles = makeStyles(() => ({
  location: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_PRIMARY_LIGHT}`,
  },
  locationHidden: {
    maxHeight: 0,
    overflow: 'hidden',
  },
}));

const SetupWizardLocationDetails = ({
  intl,
  locations,
  handleLocationsChange,
}) => {
  const classes = useStyles();
  const [openLocationId, setOpenLocationId] = useState(0);
  const [locationTitles, setLocationTitles] = useState([]);

  useEffect(() => {
    setLocationTitles([
      intl.formatMessage({ id: 'EVENT.SETUP_WIZARD.LOCATION_DETAILS.DEFAULT_LOCATION' }),
      ...[...Array(locations.length - 1).keys()].map(
        (item) => `${intl.formatMessage({ id: 'EVENT.SETUP_WIZARD.LOCATION_DETAILS.EXTRA_LOCATION' })} ${item + 1}`
      )
    ]);
  }, [locations]);

  const handleAccordion = (id) => {
    if (id === openLocationId) {
      setOpenLocationId(-1);
    } else {
      setOpenLocationId(id);
    }
  };

  const addLocation = () => {
    setLocationTitles([
      ...locationTitles,
      `${intl.formatMessage({ id: 'EVENT.SETUP_WIZARD.LOCATION_DETAILS.EXTRA_LOCATION' })} ${locationTitles.length}`
    ]);

    handleLocationsChange([
      ...locations,
      {
        event_loc_admin_nm: '',
        display_addr: '',
        geo_loc: '',
        isManual: false,
        phone_nbr: '',
        alt_phone_nbr: '',
        email_addr: '',
        web_addr: '',
        daysOfTheWeek: DefaultDaysOfTheWeek,
        drop_event_loc_hours: [...Array(7).keys()].map((item) => (
          {
            dow_nbr: item,
            hours_lower_tm: '09:00',
            hours_upper_tm: '17:00',
            add_hours_lower_tm: null,
            add_hours_upper_tm: null
          }
        ))
      }
    ]);

    setOpenLocationId(locationTitles.length);
  };

  return (
    <div className="w-100">
      <Typography variant="h1" className="mb-2">
        {intl.formatMessage({ id: 'EVENT.SETUP_WIZARD.LOCATION_DETAILS.TITLE' })}
      </Typography>

      {
        locationTitles.map((item, index) => (
          <div key={`location_${item}`} className={locationTitles.length !== index + 1 ? classes.location : null}>
            <Typography className="mt-4 mb-4 d-flex justify-content-between align-items-center" variant="bodySemiBold">
              {item}
              <IconButton onClick={() => handleAccordion(index)}>
                {
                  index === openLocationId
                    ? <CollapseIcon />
                    : <ExpandIcon />
                }
              </IconButton>
            </Typography>
            <div className={index !== openLocationId ? classes.locationHidden : null}>
              <SetupWizardLocationDetailsLocation
                openLocationId={openLocationId}
                locationDetails={locations[index]}
                locations={locations}
                handleLocationsChange={handleLocationsChange}
              />
            </div>
          </div>
        ))
      }

      <CustomButton
        label={(
          <>
            <PlusCircleIcon
              className="mr-2"
              color={process.env.REACT_APP_COLOR_PRIMARY}
            />
            {intl.formatMessage({ id: 'EVENT.SETUP_WIZARD.LOCATION_DETAILS.ADD_LOCATION' })}
          </>
        )}
        color="primary"
        size="large"
        onClick={addLocation}
      />
    </div>

  );
};

export default injectIntl(SetupWizardLocationDetails);
