import { put, call, takeLatest } from 'redux-saga/effects';
import { createListOfYears } from '../helper';
import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  getSchedules,
  getZone,
  getPerpetualSchedulesHolidays
} from '../apis';

function* getScheduleListSaga({ payload: data }) {
  try {
    yield put(actions.setLoadingAction('send-request'));
    const res = yield call(getSchedules, data);
    if (res) {
      if (res.data.status === 'success') {
        const { data } = res.data;
        const firstYear = Number(data.DATA[0].year);
        const lastYear = Number(data.DATA[data.DATA.length - 1].year);
        const years = createListOfYears(firstYear, lastYear);
        yield put(actions.getAllScheduleReceive({
          data,
          years
        }));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getAllScheduleFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}


function* getZonesSaga({ payload: { district_id } }) {
  const res = yield call(getZone, { district_id });

  if (res.data) {
    yield put(actions.getZoneReceive(res.data));
  } else {
    yield put(actions.getZoneFail(res.error.detail));
  }
}

function* getPerpetualSchedulesHolidaysSaga({ payload: { project_id, district_id } }) {
  try {
    yield put(actions.setLoadingAction('send-request'));
    const res = yield call(getPerpetualSchedulesHolidays, project_id, district_id);
    if (res) {
      if (res.data.status === 'success') {
        yield put(actions.getPerpetualSchedulesHolidaysReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.getPerpetualSchedulesHolidaysFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}


/**
 * Saga watcher function
 */
function* scheduleSagas() {
  yield takeLatest(actionTypes.GET_SCHEDULE_REQUEST_ACTION, getScheduleListSaga);
  yield takeLatest(actionTypes.GET_ZONE_SCHEDULE_REQUEST, getZonesSaga);
  yield takeLatest(actionTypes.GET_PERPETUAL_SCHEDULES_HOLIDAYS_REQUEST, getPerpetualSchedulesHolidaysSaga);
}

export default scheduleSagas;
