import React from 'react';
import { connect } from 'react-redux';

import { Summary, Detail } from './components';

const NewSubscribersReport = ({
  data,
}) => (
  <div id="new-subscribers">
    <Summary
      data={data.summary}
    />
    <Detail
      data={data.detail}
      peerData={data.detail_peer}
      peerRating={data.summary ? data.summary.rating : 0}
    />
  </div>
);

const mapStateToProps = (store) => ({
  data: store.newSubscribersReport.data,
});

export default connect(mapStateToProps, null)(NewSubscribersReport);
