import { useEffect, useState } from 'react';

import { getAllCampaignApi } from '../../Campaign/apis';
import { campaignNormalizer } from '../../Campaign/helpers';
import { getDashboardReport } from './apis';

/**
 * Get upcoming campaigns
 *
 * @param {object} district Selected district
 */
const useUpcomingCampaigns = (district) => {
  const [campaigns, setCampaigns] = useState({
    campaigns: null,
    error: null,
    loading: null,
  });

  useEffect(() => {
    const getUpcomingCampaigns = async () => {
      try {
        setCampaigns({
          campaigns: null,
          loading: true,
          error: null,
        });
        const { data, error } = await getAllCampaignApi({
          district_id:
            district.district_id !== '0'
              ? encodeURIComponent(district.district_id)
              : '',
          // district_id: 'master#$#StandAloneTestDistrict'
        });
        if (error) throw new Error(error);
        setCampaigns({
          campaigns: campaignNormalizer(data.data.campaigns),
          loading: false,
          error: null,
        });
      } catch (E) {
        setCampaigns({
          campaigns: null,
          loading: false,
          error: E.message,
        });
      }
    };
    getUpcomingCampaigns();
  }, [district]);

  return campaigns;
};

/**
 * Get dashboard information
 *
 * @param {object} district Selected district
 */
const useDashboardInfo = (
  districtId,
  fromDate,
  toDate,
  municipalityFetchState,
  countyId,
  isCounty,
  hasCounty
) => {
  const isSucceedRequest = municipalityFetchState === 'succeed-request';
  const [dashboardDetails, setDashboardDetails] = useState({
    dashboardDetails: null,
    error: null,
    loading: null,
  });

  useEffect(() => {
    if (isSucceedRequest) {
      const getDashboardDetails = async () => {
        try {
          setDashboardDetails({
            dashboardDetails: null,
            loading: true,
            error: null,
          });
          const { data, error } = await getDashboardReport({
            county:
              hasCounty !== null && isCounty
                ? encodeURIComponent(countyId)
                : '',
            municipality:
              districtId
                && districtId !== '0'
                && !isCounty
                ? encodeURIComponent(districtId)
                : '',
            from: fromDate,
            to: toDate,
          });
          if (error) throw new Error(error);
          setDashboardDetails({
            dashboardDetails: typeof data.data === 'object' ? data.data : null,
            loading: false,
            error: null,
          });
        } catch (E) {
          setDashboardDetails({
            dashboardDetails: null,
            loading: false,
            error: E.message,
          });
        }
      };
      getDashboardDetails();
    }
  }, [districtId, fromDate, toDate, countyId, isSucceedRequest, isCounty, hasCounty]);

  return dashboardDetails;
};

export { useUpcomingCampaigns, useDashboardInfo };
