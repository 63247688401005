import { put, call, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import actionTypes from './actionTypes';

/**
 * REFACTOR
 *  fix the required cycle issue
 */
import {
  userList,
  addUser,
  deleteUser,
  getOneUser,
  checkUsername,
  checkEmail,
  userActivation,
  editUser,
  suggestUsername
} from '../apis';

function* userSaga({ payload: { search, offset, length } }) {
  const res = yield call(userList, { search, offset, length });
  if (res.data) {
    if (res.data.data.users) {
      yield put(actions.userListReceive(res.data));
    } else {
      yield put(actions.userListFail());
    }
  } else {
    yield put(actions.userListFail(res.error.detail));
  }
}

function* addUserSaga({
  payload: {
    user_name,
    first_name,
    last_name,
    email,
    phone,
    is_active,
    notes,
    job_title,
    permission_list,
    district_list
  }
}) {
  const res = yield call(addUser, {
    user_name,
    first_name,
    last_name,
    email,
    phone,
    is_active,
    notes,
    job_title,
    permission_list,
    district_list
  });
  if (res.data) {
    yield put(actions.addUserReceive(res.data));
  } else {
    yield put(actions.addUserFail(res.error.message));
  }
}

function* editUserSaga({
  payload: {
    user_id,
    user_name,
    first_name,
    last_name,
    email,
    phone,
    is_active,
    notes,
    job_title,
    permission_list,
    district_list
  }
}) {
  const res = yield call(editUser, {
    user_id,
    user_name,
    first_name,
    last_name,
    email,
    phone,
    is_active,
    notes,
    job_title,
    permission_list,
    district_list
  });

  if (res.data) {
    yield put(actions.editUserReceive(res.data.message));
  } else {
    yield put(actions.editUserFail(res.error.message));
  }
}

function* deleteUserSaga({ payload: { user_id } }) {
  const res = yield call(deleteUser, { user_id });

  if (res.data) {
    yield put(actions.deleteUserReceive(res.data.message));
  } else {
    yield put(actions.deleteUserFail(res.error.message));
  }
}

function* getOneUserSaga({ payload: { user_id } }) {
  const res = yield call(getOneUser, { user_id });
  if (res.data) {
    let permission = {};
    const item = res.data.data.permission_list;
    if (res.data.data.permission_list.length === 0) {
      permission = {
        cms: true,
        reminder: true,
        report: true,
        notification: true,
        education: true,
        schedules: true,
        marketing_support: true

      };
    } else {
      for (let i = 0; i < 7; i += 1) {
        permission = {
          ...permission,
          [item[i].group_id]: item[i].permission === 't'
        };
      }

      if (item.filter((i) => i.group_id === 'education').length <= 0) {
        permission = {
          ...permission,
          education: false
        };
      }
      if (item.filter((i) => i.group_id === 'schedules').length <= 0) {
        permission = {
          ...permission,
          schedules: false
        };
      }
      if (item.filter((i) => i.group_id === 'marketing_support').length <= 0) {
        permission = {
          ...permission,
          marketing_support: false
        };
      }
    }

    const tempUser = { ...res.data.data, permission_list: permission };

    yield put(actions.getOneUserReceive(tempUser));
  } else {
    yield put(actions.getOneUserFail(res.error.message));
  }
}

function* checkUsernameSaga({ payload: { user_name } }) {
  const res = yield call(checkUsername, { user_name });
  if (res.data) {
    yield put(actions.checkUsernameReceive(res.data.data.user_name_duplicate));
  }
}

function* checkEmailSaga({ payload: { user_name, email } }) {
  const res = yield call(checkEmail, { user_name, email });

  if (res.data) {
    yield put(actions.checkEmailReceive(res.data.data.email_user_duplicate));
  }
}

function* activationUserSaga({ payload: { user_id, is_active } }) {
  const res = yield call(userActivation, { user_id, is_active });
  if (res.data.status === 'success' && res.data.message === 'OK') {
    yield put(actions.activationUserReceive(res.data.message));
  }
}

function* suggestedUsernameSaga({ payload: { first_name, last_name } }) {
  const res = yield call(suggestUsername, { first_name, last_name });

  if (res.data) {
    yield put(actions.suggestUsernameReceive(res.data.data.user_name));
  } else {
    yield put(actions.suggestUsernameFail(res.error.message));
  }
}

function* userSagas() {
  yield takeLatest(actionTypes.USER_LIST_REQUEST, userSaga);
  yield takeLatest(actionTypes.ADD_USER_REQUEST, addUserSaga);
  yield takeLatest(actionTypes.EDIT_USER_REQUEST, editUserSaga);
  yield takeLatest(actionTypes.DELETE_USER_REQUEST, deleteUserSaga);
  yield takeLatest(actionTypes.GET_ONE_USER_REQUEST, getOneUserSaga);
  yield takeLatest(actionTypes.CHECK_USERNAME_REQUEST, checkUsernameSaga);
  yield takeLatest(actionTypes.CHECK_EMAIL_REQUEST, checkEmailSaga);
  yield takeLatest(actionTypes.ACTIVATION_USER_REQUEST, activationUserSaga);
  yield takeLatest(actionTypes.SUGGEST_USERNAME_REQUEST, suggestedUsernameSaga);
}

export default userSagas;
