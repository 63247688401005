/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

import { Typography, IconButton } from '../../components';
import { addCommasToNumber } from '../../../utils';
import { SummaryCellLoading } from '../../components/loading';
import { InfoIcon } from '../../../assets/icons';

const useStyles = makeStyles((theme) => ({
  content: {
    borderRight: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    paddingLeft: 30,
    [theme.breakpoints.down('1200')]: {
      borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
      borderRight: 'none',
      paddingBottom: 10,
    },
    [theme.breakpoints.down('992')]: {
      paddingLeft: 10,
    },
  },
  title: {
    minHeight: 30,
  },
  data: {
    minHeight: 80,
  },
}));

const SummaryPreviousPeriod = ({
  intl,
  title,
  data,
  description = intl.formatMessage({ id: 'REPORT.GENERAL.PREVIOUS_PERIOD' }),
  isLoading = true,
}) => {
  const classes = useStyles();

  return (
    <div className={`col-12 col-lg-6 col-xl-3 ${classes.content}`}>
      {isLoading
        ? (
          <SummaryCellLoading />
        ) : (
          <div>
            <Typography className={classes.title} variant="h2">
              {title}
            </Typography>
            <div className={classes.data}>
              <Typography variant="numberLarge">
                {addCommasToNumber(data)}
              </Typography>
            </div>
            <Typography variant="h2">
              {description}
              <IconButton
                tooltip={intl.formatMessage({ id: 'REPORT.GENERAL.PREVIOUS_PERIOD.TOOLTIP' })}
              >
                <InfoIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
              </IconButton>
            </Typography>
          </div>
        )}
    </div>
  );
};

export default injectIntl(SummaryPreviousPeriod);
