const actionTypes = {
  // Problem report actionTypes for setting loading status
  SET_PROBLEM_REPORT_LOADING_ACTION: 'SET_PROBLEM_REPORT_LOADING_ACTION',

  // Problem report actionTypes for fetching report data
  GET_PROBLEM_REPORT_REQUEST: 'GET_PROBLEM_REPORT_REQUEST',
  GET_PROBLEM_REPORT_RECEIVE: 'GET_PROBLEM_REPORT_RECEIVE',
  GET_PROBLEM_REPORT_FAIL: 'GET_PROBLEM_REPORT_FAIL',

  // Problem report actionTypes for fetching report summary data
  GET_PROBLEM_REPORT_SUMMARY_REQUEST: 'GET_PROBLEM_REPORT_SUMMARY_REQUEST',
  GET_PROBLEM_REPORT_SUMMARY_RECEIVE: 'GET_PROBLEM_REPORT_SUMMARY_RECEIVE',
  GET_PROBLEM_REPORT_SUMMARY_FAIL: 'GET_PROBLEM_REPORT_SUMMARY_FAIL',
};

export default actionTypes;
