import { put, call, takeLatest } from 'redux-saga/effects';
import actionTypes from './actionTypes';
import * as actions from './actions';
import { getWhereDoesThisGoData } from '../apis';

function* getWhereDoesThisGoDataSaga({ payload: { project_id, district_id } }) {
  const response = yield call(getWhereDoesThisGoData, { project_id, district_id });

  try {
    yield put(actions.getWhereDoesThisGoDataReceiveAction(response.data));
  } catch (error) {
    yield put(actions.getWhereDoesThisGoDataFailAction(response.error.detail.data));
  }
}

function* whereDoesThisGoSaga() {
  yield takeLatest(actionTypes.GET_WHERE_DOES_THIS_GO_DATA_REQUEST, getWhereDoesThisGoDataSaga);
}

export default whereDoesThisGoSaga;
