/**
 * Initial state for user page
 * @param {Array} userList : user's list fetch by username
 * @param {string} fetchState : show Condition // ' OK '
 */
const initialState = {
  fetchState: '',
  userList: [],
  valid_username: false,
  valid_email: false,
  loadingList: true,
  newUser: {
    user_id: null,
    user_name: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    is_active: 't',
    notes: '',
    job_title: '',
    permission_list: {
      notification: true,
      reminder: true,
      cms: true,
      report: true,
      education: true,
      schedules: true,
      marketing_support: true,
    },
    district_list: [],
  },
  suggestedUsername: null,
  responseMessage: null,
  responseStatus: 'success',
  navigateToList: false
};

export default initialState;
