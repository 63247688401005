import React from 'react';
import { FormattedMessage } from 'react-intl';

const Back = ({ title, onClick }) => (
  <button
    type="button"
    className="btn btn-secondary btn-elevate kt-login__btn-secondary"
    onClick={onClick}
  >
    <FormattedMessage id={title} />
  </button>
);

export default Back;
