import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../redux/createReducer';
import initialState from './store';

const campaignReportReducer = persistReducer(
  { storage, key: 'category', whitelist: [] },
  createReducer(initialState, {
    SET_CATEGORY_LOADING_ACTION: (state, { payload }) => Immutable({
      ...state,
      fetchState: payload,
      responseMessage: initialState.responseMessage,
    }),

    SET_CATEGORY_SNACKBAR_ACTION: (state, { payload }) => Immutable({
      ...state,
      snackbarState: payload,
      responseMessage: initialState.responseMessage,
    }),

    SET_LOCALITY_ACTION: (state, { payload }) => Immutable({
      ...state,
      locality: payload.locality,
    }),

    GET_WASTE_TYPES_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      wasteTypeList: payload.data.data || [],
    }),
    GET_WASTE_TYPES_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    SET_WASTE_TYPE_ACTION: (state, { payload }) => Immutable({
      ...state,
      wasteType: payload.wasteType,
    }),

    GET_CATEGORIES_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      categoryList: payload.data.data || [],
      fetchState: 'succeed-request'
    }),
    GET_CATEGORIES_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    GET_ALL_CATEGORIES_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      allCategoryList: payload.data.data || [],
    }),
    GET_ALL_CATEGORIES_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    DELETE_CATEGORY_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      categoryList: state.categoryList.filter((item) => item.mat_cat_id !== payload.data),
      snackbarState: 'succeed-request',
      responseStatus: 'success',
      responseMessage: 'Category has been removed',
    }),
    DELETE_CATEGORY_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    UNASSIGN_DEPOT_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      categoryList: state.categoryList.map((category) => (
        category.mat_cat_id === payload.data.category
          ? ({
            ...category,
            depots: category.depots.filter((depot) => depot.drop_depot_id !== payload.data.depot)
          })
          : category
      )),
      snackbarState: 'succeed-request',
      responseStatus: 'success',
      responseMessage: 'Depot has been removed',
    }),
    UNASSIGN_DEPOT_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    UNASSIGN_EVENT_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      categoryList: state.categoryList.map((category) => (
        category.mat_cat_id === payload.data.category
          ? ({
            ...category,
            events: category.events.filter((event) => event.drop_event_id !== payload.data.event)
          })
          : category
      )),
      snackbarState: 'succeed-request',
      responseStatus: 'success',
      responseMessage: 'Event has been removed',
    }),
    UNASSIGN_EVENT_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    RENAME_CATEGORY_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      categoryList: state.categoryList.map((category) => (
        category.mat_cat_id === payload.data.category
          ? ({
            ...category,
            mat_cat_admin_nm: payload.data.title
          })
          : category
      )),
      snackbarState: 'succeed-request',
      responseStatus: 'success',
      responseMessage: 'Category has been renamed',
    }),
    RENAME_CATEGORY_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    GET_DEPOTS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      depotList: payload.data.data || [],
    }),
    GET_DEPOTS_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    GET_EVENTS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      eventList: payload.data.data || [],
    }),
    GET_EVENTS_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    GET_COLLECTIONS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      collectionList: payload.data.data || [],
    }),
    GET_COLLECTIONS_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    GET_COLLECTION_DETAILS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      collectionDetails: payload.data.data || null,
    }),
    GET_COLLECTION_DETAILS_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    ASSIGN_DEPOT_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      categoryList: state.categoryList.map((category) => (
        category.mat_cat_id === payload.data.category
          ? ({
            ...category,
            depots: [...category.depots, payload.data.depot]
          })
          : category
      )),
      snackbarState: 'succeed-request',
      responseStatus: 'success',
      responseMessage: 'Depot has been assigned',
    }),
    ASSIGN_DEPOT_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    ASSIGN_EVENT_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      categoryList: state.categoryList.map((category) => (
        category.mat_cat_id === payload.data.category
          ? ({
            ...category,
            events: [...category.events, payload.data.event]
          })
          : category
      )),
      snackbarState: 'succeed-request',
      responseStatus: 'success',
      responseMessage: 'Event has been assigned',
    }),
    ASSIGN_EVENT_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    ASSIGN_COLLECTION_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      wasteTypeList: state.wasteTypeList.map(
        (wasteType) => (
          wasteType.waste_type_id === payload.data.wasteType
            ? {
              ...wasteType,
              coll_id: payload.data.collection
            } : wasteType
        )
      ),
      snackbarState: 'succeed-request',
      responseStatus: 'success',
      responseMessage: 'Collection has been assigned',
    }),
    ASSIGN_COLLECTION_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    MOVE_CATEGORY_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      categoryList: state.categoryList
        .filter(
          (category) => category.mat_cat_id !== payload.data.category
        ),
      snackbarState: 'succeed-request',
      responseStatus: 'success',
      responseMessage: 'Category has been moved',
    }),
    MOVE_CATEGORY_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    MOVE_MATERIALS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      categoryList: state.categoryList
        .map(
          (category) => (
            category.mat_cat_id === payload.data.currentCategory
              ? {
                ...category,
                mats: category.mats
                  .filter((item) => !payload.data.materials
                    .map((item2) => item2.mat_id)
                    .includes(item.mat_id))
              }
              : category)
        )
        .map((category) => (
          category.mat_cat_id_front === payload.data.category
            ? {
              ...category,
              mats: [...category.mats, ...payload.data.materials]
            }
            : category
        )),
      snackbarState: 'succeed-request',
      responseStatus: 'success',
      responseMessage: 'Materials have been moved',
    }),
    MOVE_MATERIALS_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    SEARCH_MATERIALS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      search: payload.data.data || [],
    }),
    SEARCH_MATERIALS_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    GET_SEARCH_RESULTS_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      categoryList: payload.data.data || [],
      fetchState: 'succeed-request'
    }),
    GET_SEARCH_RESULTS_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    GET_SEARCHED_WASTE_TYPE_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      wasteType: payload.data.data || [],
    }),
    GET_SEARCHED_WASTE_TYPE_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    ADD_CATEGORY_RECEIVE: (state) => Immutable({
      ...state,
      snackbarState: 'succeed-request',
      responseStatus: 'success',
      responseMessage: 'Category has been added',
    }),
    ADD_CATEGORY_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),

    UPDATE_CATEGORY_DESCRIPTION_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      categoryList: state.categoryList.map((category) => (
        category.mat_cat_id === payload.data.category ? {
          ...category,
          fixed_body_id: payload.data.descriptionId,
          disposal_header: payload.data.descriptionHeader,
          custom_body: payload.data.descriptionBody,
          more_info: payload.data.moreInfo,
        } : category
      )),
      snackbarState: 'succeed-request',
      responseStatus: 'success',
      responseMessage: 'Category has been updated',
    }),
    UPDATE_CATEGORY_DESCRIPTION_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.data.message,
    }),
  })
);

export default campaignReportReducer;
