import React from 'react';
import { injectIntl } from 'react-intl';
import { styled } from '@material-ui/core/styles';

const Tabs = ({
  intl,
  data,
  setSelectedIndex,
  selectedIndex
}) => (
  <div className="d-flex flex-wrap p-4">
    {data.map((tab) => (
      <CustomTab
        key={tab.id}
        onClick={() => setSelectedIndex(tab.id)}
        selected={selectedIndex === tab.id}
      >
        {intl.formatMessage({ id: tab.title })}
      </CustomTab>
    ))}
  </div>
);
export default injectIntl(Tabs);

const CustomTab = styled(({ color, ...props }) => <div {...props} />)({
  color: ({ selected }) => (selected ? '#fff' : '#2A95FF'),
  background: ({ selected }) => (selected ? '#2A95FF' : '#F1F9FF'),
  textShadow: ({ selected }) => (selected ? '-0.08ex 0 currentColor, 0.08ex 0 currentColor' : 'none'),
  borderRadius: 16,
  padding: '8px 18px',
  cursor: 'pointer',
  letterSpacing: '0.335px',
  transition: 'all .3s',
  fontSize: 12,
  marginBottom: 7,
  marginRight: 5,

  '&:hover': {
    background: '#2A95FF',
    color: '#fff',
    textShadow: '-0.08ex 0 currentColor, 0.08ex 0 currentColor',
  },
});
