import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';
import createReducer from '../../../../../redux/createReducer';
import initialStore from './initialStore';

const WeWantedToKnowReducer = persistReducer({
  storage,
  key: 'WWtK',
  blackList: ['showCASySettings', 'currentQuestionIndex']
}, createReducer(initialStore, {
  GET_WWTK_DATA_REQUEST: (state) => Immutable({
    ...state,
    loading: true,
    error: false,
  }),
  GET_WWTK_DATA_RECEIVE: (state, { payload }) => Immutable({
    ...state,
    loading: false,
    ...payload.data,
  }),
  GET_WWTK_DATA_FAIL: (state, { payload }) => Immutable({
    ...state,
    loading: false,
    error: payload,
  }),

  SET_CURRENT_QUESTION_INDEX: (state, { index }) => Immutable({
    ...state,
    currentQuestionIndex: index
  }),
  SET_SHOW_CASY_SETTINGS: (state, { value }) => Immutable({
    ...state,
    showCASySettings: value
  }),
  FEEDBACK_REQUEST_WWTK: (state) => Immutable({
    ...state,
    error: null,
  }),
  FEEDBACK_RECEIVE_WWTK: (state, { payload }) => Immutable({
    ...state,
    loading: false,
  }),
  FEEDBACK_FAIL_WWTK: (state, { payload }) => Immutable({
    ...state,
    error: payload,
  }),
}));

export default WeWantedToKnowReducer;
