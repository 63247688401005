import moment from 'moment';
import { Typography } from '../components';

export const createListOfYears = (firstYear, lastYear) => {
  const sub = lastYear - firstYear;
  const years = [
    {
      name: String(firstYear),
      id: String(firstYear),
    },
  ];
  if (sub > 1) {
    for (let i = 1; i <= sub; i += 1) {
      years.push({
        name: String(firstYear + i),
        id: String(firstYear + i),
      });
    }
  } else {
    years.push({
      name: String(lastYear),
      id: String(lastYear),
    });
  }
  return years;
};

export const getNormalizeData = (data) => {
  const months = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];
  const firstYear = moment(data[0].col_year).year();
  const lastYear = moment(data[data.length - 1].col_year).year();
  const arrayOfYears = createListOfYears(Number(firstYear), Number(lastYear));
  let allData = {};
  arrayOfYears.forEach((item) => {
    allData = {
      ...allData,
      [item.name]: [],
    };
    months.forEach((month, id) => {
      if (
        month === '01' ||
        month === '03' ||
        month === '05' ||
        month === '07' ||
        month === '08' ||
        month === '10' ||
        month === '12'
      ) {
        const tempYear = allData[item.name];
        tempYear[id + 1] = data.filter((it) =>
          moment(it.sched_dt).isBetween(
            `${item.name}-${month}-01`,
            `${item.name}-${month}-31`,
            undefined,
            '[]'
          )
        );
        allData[item.name] = tempYear;
      } else if (month === '02') {
        const tempYear = allData[item.name];
        tempYear[id + 1] = data.filter((it) =>
          moment(it.sched_dt).isBetween(
            `${item.name}-${month}-01`,
            `${item.name}-${month}-29`,
            undefined,
            '[]'
          )
        );
        allData[item.name] = tempYear;
      } else {
        const tempYear = allData[item.name];
        tempYear[id + 1] = data.filter((it) =>
          moment(it.sched_dt).isBetween(
            `${item.name}-${month}-01`,
            `${item.name}-${month}-30`,
            undefined,
            '[]'
          )
        );
        allData[item.name] = tempYear;
      }
    });
  });
  return { allData, arrayOfYears };
};

export const convertData = (data) => {
  const result = [];

  data.forEach((yearData) => {
    const year = yearData.year;

    yearData.months.forEach((monthData) => {
      const month = monthData.month;

      monthData.events.forEach((eventData) => {
        const day = eventData.day;
        const date = new Date(year, month - 1, day);

        eventData.collections.forEach((collection) => {
          result.push({
            id: collection.id,
            title: removeCollectionText(collection.title),
            allDay: true,
            start: date,
            end: date,
            holidayEvent: false,
          });
        });
      });
    });
  });

  return result;
};

export const getUniqueCollections = (data) => {
  const uniqueCollections = [];
  data.forEach((yearData) => {
    const year = yearData.year;

    yearData.months.forEach((monthData) => {
      const month = monthData.month;

      monthData.events.forEach((eventData) => {
        const day = eventData.day;
        const date = new Date(year, month - 1, day);

        eventData.collections.forEach((collection) => {
          if (
            !uniqueCollections.find(
              (uniqueCollection) => uniqueCollection.prompt_id === collection.id
            )
          ) {
            uniqueCollections.push({
              prompt_id: collection.id,
              prompt_nm: removeCollectionText(collection.title),
            });
          }
        });
      });
    });
  });
  return uniqueCollections;
};

// Removes the word 'collection' from the text
export const removeCollectionText = (text) => {
  return text.replace(/collection/gi, '').trim();
};

export const convertHolidayData = (holidays) => {
  const result = [];

  holidays.forEach((holiday) => {
    let date;
    const currentYear = new Date().getFullYear();
    const monthIndex = holiday.month - 1;
    const dayOfMonth = holiday.day_of_month;

    // if the holiday has a specific day then it is used otherwise if it is a day of the week, calculate to determin the date
    // i.e. US Thanksgiving being the 3rd thursday
    if (holiday.day_of_month) {
      date = new Date(currentYear, monthIndex, dayOfMonth);
    } else if (holiday.day_index && holiday.day_of_week) {
      const month = holiday.month - 1;
      const dayOfWeek = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
      ].indexOf(holiday.day_of_week.toLowerCase());
      const dayIndex = ['first', 'second', 'third', 'fourth', 'last'].indexOf(
        holiday.day_index.toLowerCase()
      );
      const nextMonth = month + 1;
      const lastDayOfNextMonth = new Date(currentYear, nextMonth, 0).getDate();

      const firstDayOfMonth = new Date(currentYear, month, 1);

      date = firstDayOfMonth;

      let count = 0;

      while (date.getMonth() === month) {
        if (date.getDay() === dayOfWeek) {
          // Check if it's the correct occurrence of the day or the last occurrence in the month.
          if (
            count === dayIndex ||
            (dayIndex === 4 && date.getDate() + 7 > lastDayOfNextMonth)
          ) {
            break;
          }
          count++;
        }
        date.setDate(date.getDate() + 1);
      }
    }

    if (date) {
      result.push({
        id: holiday.id,
        title: holiday.name,
        allDay: true,
        start: date,
        end: date,
        holidayEvent: true,
      });
    }
  });

  return result;
};

export const markSelectedHolidays = (holidayDates, selectedHolidays) => {
  const updatedMain = holidayDates.main.map((holiday) => ({
    ...holiday,
    checked: selectedHolidays.includes(holiday.id),
  }));

  const updatedAdditional = holidayDates.additional.map((holiday) => ({
    ...holiday,
    checked: selectedHolidays.includes(holiday.id),
  }));
  return {
    main: updatedMain,
    additional: updatedAdditional,
  };
};

export function convertHolidays(data) {
  const result = { main: [], additional: [] };
  data.forEach((holiday) => {
    const formattedHoliday = {
      name: holiday?.name,
      date: holiday?.description,
      checked: false,
      id: holiday.id,
    };
    if (
      (holiday.is_main && holiday.is_main == 'true') ||
      holiday.is_main === 't'
    ) {
      result.main.push(formattedHoliday);
    } else {
      result.additional.push(formattedHoliday);
    }
  });
  return result;
}

export const renderCheckboxLabel = (day) => {
  switch (day) {
    case 'monday':
      return (
        <Typography variant='bodyTableSemiBold'>
          Collections are one day later for the REST OF THE WEEK
          <Typography
            style={{ marginLeft: '5px' }}
            component='span'
            variant='body'
          >
            (For example, Monday collection shifts to Tuesday, Tuesday
            collection shifts to Wednesday, etc. )
          </Typography>
        </Typography>
      );
    case 'tuesday':
      return (
        <Typography variant='bodyTableSemiBold'>
          Collections are one day later for the REST OF THE WEEK
          <Typography
            style={{ marginLeft: '5px' }}
            component='span'
            variant='body'
          >
            (For example, no change to Monday collection; Tuesday collection
            shifts to Wednesday, etc. )
          </Typography>
        </Typography>
      );
    case 'wednesday':
      return (
        <Typography variant='bodyTableSemiBold'>
          Collections are one day later for the REST OF THE WEEK
          <Typography
            style={{ marginLeft: '5px' }}
            component='span'
            variant='body'
          >
            (For example, no change to Monday & Tuesday collection; Wednesday
            collection shifts to Thursday, etc. )
          </Typography>
        </Typography>
      );
    case 'thursday':
      return (
        <Typography variant='bodyTableSemiBold'>
          Collections are one day later for the REST OF THE WEEK
          <Typography
            style={{ marginLeft: '5px' }}
            component='span'
            variant='body'
          >
            (For example, no change to Monday - Wednesday collection; Thursday
            collection shifts to Friday, etc. )
          </Typography>
        </Typography>
      );
    case 'friday':
      return (
        <Typography variant='bodyTableSemiBold'>
          Friday collection shifts to Saturday; all other collections are not
          affected.
        </Typography>
      );
    case 'saturday':
      return (
        <Typography variant='bodyTableSemiBold'>
          Collections are one day later for the FOLLOWING WEEK
          <Typography
            style={{ marginLeft: '5px' }}
            component='span'
            variant='body'
          >
            (For example, Monday collection shifts to Tuesday, etc. )
          </Typography>
        </Typography>
      );
    case 'sunday':
      return (
        <Typography variant='bodyTableSemiBold'>
          Collections are one day later for the FOLLOWING WEEK
          <Typography
            style={{ marginLeft: '5px' }}
            component='span'
            variant='body'
          >
            (For example, Monday collection shifts to Tuesday, etc. )
          </Typography>
        </Typography>
      );
    default:
      return null;
  }
};

export const MOVES_TO_OPTIONS = [
  { action: 'Following', name: 'Collections move to following' },
  { action: 'Previous', name: 'Collections move to previous' },
  { action: 'NoChange', name: 'No change to the scheduled collections' },
  { action: 'NotANormalPickupDay', name: 'Not a normal collection day' },
  {
    action: 'CollectionCancelled',
    name: 'Collections are cancelled without any alternative pickup day',
  },
];
export const ALL_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const findNextDayHandler = (day) => {
  switch (day) {
    case 'monday':
      return 'tuesday';
    case 'tuesday':
      return 'wednesday';
    case 'wednesday':
      return 'thursday';
    case 'thursday':
      return 'friday';
    case 'friday':
      return 'saturday';
    case 'saturday':
      return 'sunday';
    case 'sunday':
      return null;
    default:
      return 'monday';
  }
};

export const findPreviousDayHandler = (day) => {
  switch (day) {
    case 'monday':
      return null;
    case 'tuesday':
      return 'monday';
    case 'wednesday':
      return 'tuesday';
    case 'thursday':
      return 'wednesday';
    case 'friday':
      return 'thursday';
    case 'saturday':
      return 'friday';
    case 'sunday':
      return 'saturday';
    default:
      return null;
  }
};

export const startAndEndDateInitial = {
  startDate: '',
  endDate: '',
  specificStartDate: { day: '', month: '' },
  specificEndDate: { day: '', month: '' },
  specificStartWeek: { week: '', month: '' },
  specificEndWeek: { week: '', month: '' },
  itHasAlreadyStarted: '',
  itHasAlreadyEnded: '',
  whenWillItStart: '',
  whenWillItEnd: '',
  approximateStartDate: '',
  approximateEndDate: '',
  otherStartDate: '',
  otherEndDate: '',
  canChangeEachYearStart: '',
  canChangeEachYearEnd: '',
};

export const WEEK_IN_MONTH = [
  { id: 'First Week', name: 'First' },
  { id: 'Second Week', name: 'Second' },
  { id: 'Third Week', name: 'Third' },
  { id: 'Fourth Week', name: 'Fourth' },
  { id: 'Last Week', name: 'Last' },
];

export const MONTHS_IN_YEAR = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getDaysInMonth = (month = 'January') => {
  const monthDays = {
    January: 31,
    February: 28,
    March: 31,
    April: 30,
    May: 31,
    June: 30,
    July: 31,
    August: 31,
    September: 30,
    October: 31,
    November: 30,
    December: 31,
  };

  const days = monthDays[month] || 0;
  return Array.from({ length: days }, (v, k) => (k + 1).toString());
};

export const isDayWithinTheMonth = (day, monthName) => {
  if (!day || !monthName) return true;
  const daysInMonth = {
    January: 31,
    February: 28,
    March: 31,
    April: 30,
    May: 31,
    June: 30,
    July: 31,
    August: 31,
    September: 30,
    October: 31,
    November: 30,
    December: 31,
  };

  return monthName && day <= daysInMonth[monthName];
};

export const isEqual = (v1, v2) => JSON.stringify(v1) === JSON.stringify(v2);

export const getEmptySeasonalListConfig = (seasonalList) => {
  // Filter the seasonal list to return items where season_end_config or season_start_config is null, an empty object, or the string "{}"
  const filteredSeasonalList = seasonalList.seasonal_list.filter((item) => {
    const isEndConfigEmpty =
      item.season_end_config === null ||
      (typeof item.season_end_config === 'object' &&
        Object.keys(item.season_end_config).length === 0) ||
      item.season_end_config === '{}';

    const isStartConfigEmpty =
      item.season_start_config === null ||
      (typeof item.season_start_config === 'object' &&
        Object.keys(item.season_start_config).length === 0) ||
      item.season_start_config === '{}';

    return isEndConfigEmpty || isStartConfigEmpty;
  });

  return {
    seasonal_list: filteredSeasonalList,
  };
};

export const hasCompletedPerpetualSetup = (municipalities, perpetualSchedulesDistrict, seasonalList) => {
  if (
    municipalities &&
    municipalities.length &&
    perpetualSchedulesDistrict &&
    perpetualSchedulesDistrict.length &&
    perpetualSchedulesDistrict.length === municipalities.length &&
    seasonalList && seasonalList?.seasonal_list.length
  ) {
    const filteredSeasonalList = getEmptySeasonalListConfig(seasonalList);
    if (filteredSeasonalList && filteredSeasonalList.seasonal_list.length) {
      return false;
    } else {
      return true;
    }
  }
}
