import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { PlusCircleIcon } from '../../../assets/icons';

import { Portlet, PortletBody } from '../../../../_metronic/partials/content/Portlet';
import { AutoComplete, CustomButton } from '../../components';
import * as actions from '../redux/actions';
import * as sharedActions from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '50%',
    [theme.breakpoints.down('1360')]: {
      width: '65%',
    },
    [theme.breakpoints.down('840')]: {
      width: '100%',
    },
    [theme.breakpoints.down('650')]: {
      flexDirection: 'column-reverse',
    },
  },
  addButton: {
    width: '250px',
    marginLeft: '20px',
    [theme.breakpoints.down('650')]: {
      width: '100%',
      marginLeft: '0px',
      marginTop: '10px',
    },
  },
  localities: {
    flexGrow: 1,
    [theme.breakpoints.down('650')]: {
      width: '100%',
    },
  }
}));

const Header = ({
  intl,
  localityList,
  locality,
  setLocalityAction,
  getCollectionsListRequest,
  setCollectionWizardStatusAction,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (locality && locality.cms_locality_id) {
      getCollectionsListRequest({
        locality: locality.cms_locality_id,
      });
    }
  }, [locality]);

  return (
    <Portlet>
      <PortletBody className="flex-row-reverse">
        <div className={`${classes.container}`}>
          <div className={`${classes.addButton}`}>
            <CustomButton
              label={(
                <>
                  <PlusCircleIcon
                    className="mr-2"
                    color={process.env.REACT_APP_COLOR_SECONDARY}
                  />
                  {intl.formatMessage({ id: 'COLLECTION.HEADER.ADD_COLLECTION' })}
                </>
              )}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => setCollectionWizardStatusAction(true)}
            />
          </div>

          <div className={`${classes.localities}`}>
            <AutoComplete
              title={intl.formatMessage({ id: 'GENERAL.LOCALITY' })}
              options={localityList}
              optionTitle="cms_locality_nm"
              optionId="cms_locality_id"
              disableClearable
              onChange={(value) => setLocalityAction({ locality: value })}
              value={locality}
            />
          </div>

        </div>
      </PortletBody>
    </Portlet>
  );
};

const mapStateToProps = (store) => ({
  localityList: store.common.localityList,
  locality: store.common.locality,
});

export default injectIntl(connect(mapStateToProps,
  { ...actions, ...sharedActions })(Header));
