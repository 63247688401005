import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, CustomButton, Pagination } from '../../../components';
import { toAbsoluteUrl } from '../../../../../_metronic';


import { LoadingSpinner, ErrorContainer } from '../../../components/loading';
import { PreviewQuizModal } from ".";

import { ProUser } from '../../EducationalContent/components';

import * as actions from '../redux/actions';



const useStyles = makeStyles({
  container: {
    padding: '20px 20px',
    marginLeft: '8px'
  },
  hr: {
    borderBottom: '2px solid #E7ECEF',
    marginTop: 15,
    marginBottom: 20,
  },
  contentContainer: {
    marginTop: 20,
  },
  button: {
    justifyContent: 'left',
    margin: '5px 0px',
    fontSize: 12,
  },
  quizButton: {
    width: '50%',
    marginLeft: '50%',
    marginTop: 5,
  },
  misunderstoodMaterialsItem: {
    cursor: 'default',
    pointerEvents: 'none',
  }
});

const MisunderstoodMaterials = ({
  intl, disabled = false, isUserPro, stage, district, misunderstood_materials_loading, error, misunderstoodMaterials, misunderstoodMaterialsQuizOptions, getMisunderstoodMaterialsRequestAction
}) => {

  useEffect(() => {
    if (district.district_id !== '#all-districts#' && isUserPro && stage >= 4) {
      const [projectId, districtId] = district.district_id.split('#$#');
      getMisunderstoodMaterialsRequestAction(projectId, districtId);
    } else {
      getMisunderstoodMaterialsRequestAction('556', '556');
    }
  }, [district]);

  const classes = useStyles({ disabled });
  const [page, setPage] = useState(1);
  const [isPreviewingMisunderstoodMaterialsQuiz, setIsPreviewingMisunderstoodMaterialsQuiz] = useState(false);

  const [numberOfQuizes, setNumberOfQuizes] = useState(0);

  const [currentMisunderstoodMaterials, setCurrentMisunderstoodMaterials] = useState(null);
  const [currentMisunderstoodMaterialsIndex, setCurrentMisunderstoodMaterialsIndex] = useState(0);


  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const nextQuizHandler = () => {
    misunderstoodMaterialsQuizHandler(currentMisunderstoodMaterialsIndex + 1);
  }

  const previousQuizHandler = () => {
    misunderstoodMaterialsQuizHandler(currentMisunderstoodMaterialsIndex - 1);
  }

  const misunderstoodMaterialsQuizHandler = (index = 0) => {
    setCurrentMisunderstoodMaterials(misunderstoodMaterials[index]);
    setNumberOfQuizes(misunderstoodMaterials.length);
    setIsPreviewingMisunderstoodMaterialsQuiz(true);
    setCurrentMisunderstoodMaterialsIndex(index);
  }

  return (
    <>
      {isUserPro && stage >= 4 ? (
        <>
          <div style={{ display: 'flex' }}>
            <img
              alt="Badge Icon"
              src={toAbsoluteUrl('/media/icons/badge.png')}
              className={`${classes.packImage}`}
              height={40}
              width={40}
            /><div>Ten items many people get wrong. Find out the right way to recycle or dispose of them to earn a badge!</div>
          </div>
          <div className={classes.contentContainer}>
            <Typography color="#6C7293" variant="h2">
              Materials
            </Typography>
            {misunderstood_materials_loading ? <LoadingSpinner height={430} /> : !misunderstoodMaterials || misunderstoodMaterials.length === 0 || error ? (
              <ErrorContainer
                small
                reportName="Misunderstood Materials"
                height={430}
                error={error}
              />
            ) : (
              <>
                {
                  misunderstoodMaterials && misunderstoodMaterials.length > 0 && misunderstoodMaterials.slice((page - 1) * 5, (page * 5)).map((mm, index) => {
                    return (
                      <CustomButton
                        paddingVertical={4}
                        className={`${classes.button} ${classes.misunderstoodMaterialsItem}`}
                        variant={'outlined'}
                        fontSize={12}
                        label={mm.mat_nm}
                        subLabel={mm.disposal_header}
                        onClick={() => { }}
                      />
                    )

                  })
                }
                <div className={`mt-5 mb-4 d-flex justify-content-end align-items-center w-100 ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
                  <Pagination
                    page={page}
                    rowsPerPage={5}
                    handlePageChange={(value) => handleChangePage(value)}
                    dataLength={misunderstoodMaterials && misunderstoodMaterials.length}
                    hideRowsPerPage
                  />
                </div>
              </>
            )}
          </div>
          <CustomButton
            paddingVertical={4}
            className={classes.quizButton}
            variant={'contained'}
            label={'Quiz Questions'}
            disabled={!misunderstoodMaterials || misunderstoodMaterials.length === 0 || error || misunderstood_materials_loading}
            onClick={() => {
              misunderstoodMaterialsQuizHandler();
            }} />
        </>
      ) : (
        <>
          {/* <ProUser
            sidePanel={true}
            title={intl.formatMessage({ id: 'EDUCATION.SURVEYS.AND.MORE.MISUNDERSTOOD.MATERIALS' })}
            description="Combat recycling contamination with this series of ten misunderstood materials. Teach your residents the proper disposal of these problem materials in this interactive lesson. "
            benefits={['Target specific materials, then teach their disposal', 'Quiz your residents on their understanding', 'Reward residents with a badge']}
          /> */}
          <>
            <div style={{ display: 'flex' }}>
              <img
                alt="Badge Icon"
                src={toAbsoluteUrl('/media/icons/badge.png')}
                className={`${classes.packImage}`}
                height={40}
                width={40}
              /><div>Ten items many people get wrong. Find out the right way to recycle or dispose of them to earn a badge!</div>
            </div>
            <div className={classes.contentContainer}>
              <Typography color="#6C7293" variant="h2">
                Materials
              </Typography>
              {misunderstood_materials_loading ? <LoadingSpinner height={430} /> : !misunderstoodMaterials || misunderstoodMaterials.length === 0 || error ? (
                <ErrorContainer
                  small
                  reportName="Misunderstood Materials"
                  height={430}
                  error={error}
                />
              ) : (
                <>
                  {
                    misunderstoodMaterials && misunderstoodMaterials.length > 0 && misunderstoodMaterials.slice((page - 1) * 5, (page * 5)).map((mm, index) => {
                      return (
                        <CustomButton
                          paddingVertical={4}
                          className={`${classes.button} ${classes.misunderstoodMaterialsItem}`}
                          variant={'outlined'}
                          fontSize={12}
                          label={mm.mat_nm}
                          subLabel={mm.disposal_header}
                          onClick={() => { }}
                        />
                      )

                    })
                  }
                  <div className={`mt-5 mb-4 d-flex justify-content-end align-items-center w-100 ${classes.smallerThanMdHide} ${classes.onlyMd}`}>
                    <Pagination
                      page={page}
                      rowsPerPage={5}
                      handlePageChange={(value) => handleChangePage(value)}
                      dataLength={misunderstoodMaterials && misunderstoodMaterials.length}
                      hideRowsPerPage
                    />
                  </div>
                </>
              )}
            </div>
            <CustomButton
              paddingVertical={4}
              className={classes.quizButton}
              variant={'contained'}
              label={'Quiz Questions'}
              disabled={!misunderstoodMaterials || misunderstoodMaterials.length === 0 || error || misunderstood_materials_loading}
              onClick={() => {
                misunderstoodMaterialsQuizHandler();
              }} />
          </>
        </>
      )}
      <>
        <PreviewQuizModal
          open={isPreviewingMisunderstoodMaterialsQuiz}
          onClose={() => setIsPreviewingMisunderstoodMaterialsQuiz()}
          rfLessonTitle={misunderstoodMaterials && misunderstoodMaterials.length ? misunderstoodMaterials[currentMisunderstoodMaterialsIndex].mat_nm : ''}
          rfQuiz={misunderstoodMaterials}
          nextHandler={nextQuizHandler}
          previewHandler={previousQuizHandler}
          currentRFQuizIndex={currentMisunderstoodMaterialsIndex}
          numberOfQuizes={numberOfQuizes}
          misunderstoodMaterialsQuizOptions={misunderstoodMaterialsQuizOptions}
        />
      </>
    </>

  )
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  loading: store.recyclingFundamentals.loading,
  error: store.recyclingFundamentals.error,
  misunderstoodMaterials: store.recyclingFundamentals.misunderstood_materials_data.misunderstood_materials,
  misunderstoodMaterialsQuizOptions: store.recyclingFundamentals.misunderstood_materials_data.quiz_options,
  misunderstood_materials_loading: store.recyclingFundamentals.misunderstood_materials_loading,
});

export default injectIntl(connect(mapStateToProps, actions)(MisunderstoodMaterials));
