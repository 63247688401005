/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import {
  Typography,
  // IconButton,
  TextField,
  DatePicker,
  CustomButton,
  // CheckBox,
  WysiwygEditor,
} from '../../components';
import {
  // InfoIcon,
  PlusCircleIcon
} from '../../../assets/icons';

const useStyles = makeStyles(() => ({
  datePicker: {
    width: '30%',
    marginRight: '3%',
    backgroundColor: `${process.env.REACT_APP_COLOR_SECONDARY}`,
  },
  addDate: {
    width: 'max-content',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
}));

const SetupWizardEventDetails = ({
  intl,
  name,
  handleNameChange,
  dates,
  handleDatesChange,
  description,
  handleDescriptionChange,
  // calendar,
  // handleCalendarChange,
}) => {
  const classes = useStyles();
  const [numberOfDates, setNumberOfDates] = useState(dates.length);

  useEffect(() => {
    setNumberOfDates(dates.length);
  }, [dates.length]);

  return (
    <div className="w-100 d-flex flex-column justify-content-start align-items-start">
      <Typography variant="h1">
        {intl.formatMessage({ id: 'EVENT.SETUP_WIZARD.EVENT_DETAILS.TITLE' })}
      </Typography>

      <Typography
        className="mt-3 d-flex align-item-center"
        variant="bodySemiBold"
      >
        {intl.formatMessage({ id: 'EVENT.SETUP_WIZARD.EVENT_DETAILS.NAME' })}
      </Typography>
      <TextField className="mt-3" onChange={handleNameChange} value={name} />

      <Typography className="mt-4" variant="bodySemiBold">
        {intl.formatMessage({ id: 'EVENT.SETUP_WIZARD.EVENT_DETAILS.DATES' })}
      </Typography>
      <div className="w-100 d-flex flex-wrap align-items-center">
        {[...Array(numberOfDates).keys()].map((item) => (
          <DatePicker
            key={item}
            className={`mt-3 ${classes.datePicker}`}
            label={intl.formatMessage({ id: 'GENERAL.DATE' })}
            onChange={(e) => {
              if (!e && numberOfDates !== 1) {
                handleDatesChange(
                  dates.filter((date, index) => item !== index)
                );
                setNumberOfDates(numberOfDates - 1);
              } else {
                handleDatesChange(
                  dates.map((date, index) => (item === index
                    ? { date: e ? moment(e).format('YYYY-MM-DD') : null }
                    : date))
                );
              }
            }}
            value={dates[item].date}
          />
        ))}
        <CustomButton
          className={classes.addDate}
          label={(
            <Typography
              variant="h2"
              color={process.env.REACT_APP_COLOR_PRIMARY}
            >
              <PlusCircleIcon
                className="mr-2"
                color={process.env.REACT_APP_COLOR_PRIMARY}
              />
              {intl.formatMessage({
                id: 'EVENT.SETUP_WIZARD.EVENT_DETAILS.ADD_DATE',
              })}
            </Typography>
          )}
          onClick={() => {
            setNumberOfDates(numberOfDates + 1);
            handleDatesChange([...dates, { date: null }]);
          }}
        />
      </div>

      <Typography className="mt-4" variant="bodySemiBold">
        {intl.formatMessage({
          id: 'EVENT.SETUP_WIZARD.EVENT_DETAILS.DESCRIPTION',
        })}
      </Typography>
      <div className=" w-100 mt-4 mb-5">
        <WysiwygEditor
          value={description}
          onChange={handleDescriptionChange}
          fullWidth
        />
      </div>

      {/* <CheckBox
        className="mr-2"
        checked={calendar}
        label={(
          <Typography variant="body">
            {intl.formatMessage({ id: 'EVENT.SETUP_WIZARD.EVENT_DETAILS.CHECKBOX' })}
          </Typography>
        )}
        onChange={handleCalendarChange}
      /> */}
    </div>
  );
};

export default injectIntl(SetupWizardEventDetails);
