import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import qs from 'querystring';
import { getNextnMonths } from '../helpers';
import {
  Description,
  CampaignItem,
  NoCampaign,
  CampaignError,
  Filter,
  NextMonthItem,
  SwitchSuccessful
} from '../components';
import tabs from './tabs.json';
import { ListLoading } from '../../components/loading';

const useStyles = makeStyles({
  campaignContainer: {
    marginTop: 50
  }
});

const CampaignSchedule = ({
  campaigns,
  campPage,
  intl,
  err,
  loading,
  filter = '',
  selectTemplate = false,
  onUpdate,
  uiOptions
}) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { campaignContainer } = useStyles();
  const [state, setState] = useState();
  const switchState = history.location.state;

  useEffect(() => {
    if (state === undefined) {
      setState(qs.parse(history.location.search.substring(1)).listState);
    }
  }, [history.location.search]);

  useEffect(() => {
    if (campPage.match(/library|history/)) {
      if (!('listState' in qs.parse(history.location.search.substring(1)))) {
        const pageFilter = {
          history: 'past',
          library: 'active'
        };
        history.replace({
          pathname: url,
          search: `?listState=${pageFilter[campPage]}`
        });
      }
    }
  }, []);

  const renderNextMonths = () => {
    const lastMonthNumber = (campaigns[campaigns.length - 1]);
    // Create array of these 12 months
    return getNextnMonths(lastMonthNumber.end_dt, 6).map((month) => (
      <NextMonthItem month={month} />
    ));
  };

  const changeFilter = () => {
    const pageFilter = {
      history: 'past',
      library: 'active'
    };
    const pageState = pageFilter[campPage];
    const newState = (state === pageState) ? 'reject' : pageState;
    setState(newState);
    history.replace({
      pathname: url,
      search: `?listState=${newState}`
    });
  };

  return (
    <div className="col-12 col-xl-12">
      <div className="pt-4 w-100 h-100 position-relative">
        {
          state
          && tabs[campPage][state]
            ? (
              <Description>
                {tabs[campPage][state || 'default'].description}
              </Description>
            ) : null
        }

        {
          switchState
          && switchState.switchStatus
            ? (
              <SwitchSuccessful
                from={switchState.from.title}
                to={switchState.to.title}
              />
            ) : null
        }

        {/* Filter for library page */}
        {
          campPage.match(/library|history/)
          && campPage !== 'library-template'
            ? (
              <Filter
                changeFilter={changeFilter}
                rightLabel={intl.formatMessage({ id: 'GENERAL.REJECTED' })}
                leftLabel={(campPage === 'library') ? intl.formatMessage({ id: 'GENERAL.AVAILABLE' }) : intl.formatMessage({ id: 'GENERAL.ALL' })}
                isActive={state === 'reject'}
              />
            ) : null
        }

        <div className={campaignContainer}>
          {
            loading
             && !err
             && !campaigns.length
             && <ListLoading />
          }
          {
            !err
            && !campaigns.length
            && !loading
              ? (
                <NoCampaign />
              ) : null
          }
          {
            err
            && !loading
            && !campaigns
              ? (
                <CampaignError />
              ) : null
          }
          {
            campaigns
            && campaigns.length
              ? (
                campaigns.map((campaign) => (
                  <CampaignItem
                    key={campaign.id}
                    data={campaign}
                    campPage={campPage}
                    switchPage={selectTemplate}
                    filter={filter}
                    onUpdate={onUpdate}
                    uiOptions={uiOptions}
                    switchedItem={
                      (switchState && switchState.switchStatus) ? switchState.previewId : null
                    }
                  />
                ))
              ) : null
          }
          {
            campaigns.length
            && campPage === 'schedule'
              ? (
                renderNextMonths()
              ) : null
          }
        </div>

      </div>
    </div>
  );
};

export default injectIntl(
  connect(
    (store) => ({
      district: store.common.district
    })
  )(CampaignSchedule)
);
