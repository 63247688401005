/* eslint-disable global-require */
import React from 'react';
import { injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

import GetAppIcon from '@material-ui/icons/GetApp';
import { Typography } from '../../components';
import { CloseIcon } from '../../../assets/icons';

import {
  useHistory
} from 'react-router-dom';

const useStyles = makeStyles({
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)'
  },
  image: {
    width: 270,
    height: 270,
    margin: '0 15px',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    borderRadius: 3,
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    transition: 'all .3s',
    '&:hover': {
      boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 4px 0px',
    }
  },
  dialogImage: {
    width: '100%',
  },
  iconContainer: {
    position: 'absolute',
    top: 10,
    right: 5,
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    borderRadius: 100,
    background: '#fff',
    cursor: 'pointer',
    marginRight: 5,
    border: '1px solid rgba(99, 99, 99, 0.2)',
    outline: 'none'
  },
  closeIcon: {
    height: 25,
    width: 25,
    color: '#000',
  },
  downloadIcon: {
    height: 25,
    width: 25,
    color: '#000',
  },
  noneImage: {
    cursor: 'default',
  }

});

const ImageCarousel = ({
  intl,
  selectedImageName,
  data,
  handleOpenDialog,
  handleCloseDialog
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleDownloading = () => { };
  const handleLinkClick = (data) => { 
    if (data.external === true) {
      window.open(data.link, '_blank').focus()
    } else {
      history.push(data.link);
    }
    handleCloseDialog()

  };
  return (
    <div className={classes.container} key={data.id}>
      <div
        role="button"
        onClick={() => handleOpenDialog(data.url)}
      >
        <img
          alt={data.url}
          className={`${classes.image} ${data.none || !data.isAllowed ? classes.noneImage : ''}`}
          src={require(`../../../assets/images/${data.url}-thumbnail-300x300.png`)}
        />
      </div>
      {selectedImageName === data.url && !data.link && !data.none && (
        <Dialog
          onClose={handleCloseDialog}
          open={selectedImageName === data.url}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
        >
          <div className={classes.iconContainer}>
            <a
              href={require(`../../../assets/images/${data.url}-full.png`)}
              download
              rel="noreferrer"
              onClick={handleDownloading}
              className={classes.icon}
            >
              <GetAppIcon className={classes.downloadIcon} />
            </a>
            <div
              role="button"
              onClick={handleCloseDialog}
              className={classes.icon}
            >
              <CloseIcon className={classes.closeIcon} />
            </div>
          </div>
          <img
            src={require(`../../../assets/images/${data.url}-full.png`)}
            alt={`${data.url} big`}
            className={classes.dialogImage}
          />
        </Dialog>
      )}
      {
        selectedImageName === data.url && data.link && data.isAllowed &&(
          handleLinkClick(data)
          )
      }
    </div>
  );
};

export default injectIntl(ImageCarousel);
