import { put, call, takeLatest } from 'redux-saga/effects';

import actionTypes from './actionTypes';
import * as actions from './actions';
import { sendResetLinkToEmail } from '../../apis';

function* EmailVerificationSaga({ payload: { email } }) {
  try {
    yield put(actions.setLoadingAction());
    const res = yield call(sendResetLinkToEmail, { email });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.EmailVerificationReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.EmailVerificationFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

/**
 * Saga watcher function
 */
function* sagas() {
  yield takeLatest(actionTypes.EMAIL_VERIFICATION_REQUEST, EmailVerificationSaga);
}

export default sagas;
