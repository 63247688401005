const actionTypes = {
  // New Subscribers report actionTypes for setting loading status
  SET_NEW_SUBSCRIBERS_REPORT_LOADING_ACTION: 'SET_NEW_SUBSCRIBERS_REPORT_LOADING_ACTION',

  // New Subscribers report actionTypes for fetching report data
  GET_NEW_SUBSCRIBERS_REPORT_REQUEST: 'GET_NEW_SUBSCRIBERS_REPORT_REQUEST',
  GET_NEW_SUBSCRIBERS_REPORT_RECEIVE: 'GET_NEW_SUBSCRIBERS_REPORT_RECEIVE',
  GET_NEW_SUBSCRIBERS_REPORT_FAIL: 'GET_NEW_SUBSCRIBERS_REPORT_FAIL',
};


export default actionTypes;
