/* eslint-disable max-len */
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { SortIcon, ViewDetailsIcon } from '../../../../assets/icons';
import {
  IconButton,
  Dialog,
  Pagination,
  Typography
} from '../../../components';

const useStyles = makeStyles(() => ({
  dataTable: {
    '& > div:last-child': {
      borderBottom: '0px !important',
    }
  },
  border: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
  },
  sortIcon: {
    width: '1rem',
    height: '1rem',
  },
  viewDetails: {
    color: process.env.REACT_APP_COLOR_PRIMARY
  },
  viewDetailsIcon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  profilePicture: {
    width: '100%',
    height: '100%',
    border: 'none',
    backgroundColor: '#ebebeb',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'relative'
  },
}));

const DetailTable = ({
  intl,
  searchValue,
  data,
  help
}) => {
  const classes = useStyles();
  const [sortedColumn, setSortedColumn] = useState('date'); // Sort is done based on this column
  const [isAscending, setIsAscending] = useState(false); // Shows whether sorting is ascending or not
  const [page, setPage] = useState(1); // Number of the page in Pagination
  const [rowsPerPage, setRowsPerPage] = useState(10); // Number of rows that wil be shown in table

  const sort = (column) => {
    if (sortedColumn === column) {
      setIsAscending(!isAscending);
    } else {
      setSortedColumn(column);
      setIsAscending(true);
    }
  };
  return (
    <div className="p-3">
      <div className={`row pt-3 pb-3 pl-4 pr-4 ${classes.border}`}>
        <div className="col-2 d-flex align-items-center">
          <Typography variant="bodySemiBold">
            {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.DATE' })}
          </Typography>
          <span className="d-flex flex-column">
            <IconButton onClick={() => sort('date')}>
              <SortIcon
                className={classes.sortIcon}
                color={process.env.REACT_APP_COLOR_TEXT}
              />
            </IconButton>
          </span>
        </div>
        <div className="col-3 d-flex align-items-center">
          <Typography variant="bodySemiBold">
            {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.PROBLEM_TYPE' })}
          </Typography>
          <span className="d-flex flex-column">
            <IconButton onClick={() => sort('problem_type')}>
              <SortIcon
                className={classes.sortIcon}
                color={process.env.REACT_APP_COLOR_TEXT}
              />
            </IconButton>
          </span>
        </div>
        <div className="col-2 d-flex align-items-center">
          <Typography variant="bodySemiBold">
            {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.NAME' })}
          </Typography>
          <span className="d-flex flex-column">
            <IconButton onClick={() => sort('name')}>
              <SortIcon
                className={classes.sortIcon}
                color={process.env.REACT_APP_COLOR_TEXT}
              />
            </IconButton>
          </span>
        </div>
        <div className="col-3 d-flex align-items-center">
          <Typography variant="bodySemiBold">
            {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.ADDRESS' })}
          </Typography>
          <span className="d-flex flex-column">
            <IconButton onClick={() => sort('address')}>
              <SortIcon
                className={classes.sortIcon}
                color={process.env.REACT_APP_COLOR_TEXT}
              />
            </IconButton>
          </span>
        </div>
        <div className="col" />
      </div>
      <div className={classes.dataTable}>
        {
        [...data]
          .filter((item) => (((help && help.type === 'all') || !help) ? item : item.problem_type_cd === help.type))
          .filter((item) => JSON.stringify(item).toLowerCase().includes(searchValue.toLowerCase()))
          .sort((a, b) => {
            if (!isAscending) {
              return ((a[sortedColumn] > b[sortedColumn]) ? -1 : 1);
            }
            return ((a[sortedColumn] > b[sortedColumn]) ? 1 : -1);
          })
          .slice((page - 1) * rowsPerPage, (page * rowsPerPage))
          .map((item, index) => (
            <div
              key={String(index)}
              className={`row pt-4 pb-4 pl-4 pr-4 ${classes.border}`}
            >
              <Typography className="col-2" variant="body">
                {item.date}
              </Typography>
              <Typography className="col-3" variant="body">
                {item.problem_type_label}
              </Typography>
              <Typography className="col-2" variant="body">
                {item.contact_full_nm}
              </Typography>
              <Typography className="col-3" variant="body">
                {item.address}
              </Typography>
              <div className="col">
                <Dialog
                  disableBackdropClick={false}
                  maxWidth="md"
                  hasButtons={false}
                  button={(
                    <div className="d-flex align-items-center">
                      <Typography className={`mr-2 ${classes.viewDetails}`} variant="body">
                        {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.VIEW_DETAILS' })}
                      </Typography>
                      <ViewDetailsIcon color={process.env.REACT_APP_COLOR_PRIMARY} className={classes.viewDetailsIcon} />
                    </div>
                  )}
                  title={(
                    <div className="d-flex align-items-center">
                      <Typography variant="h2">
                        {item.problem_type_label}
                      </Typography>
                      <Typography className="ml-4" variant="bodySemiBold">
                        {item.date}
                      </Typography>
                    </div>
                  )}
                  content={(
                    <>
                      <Typography variant="bodySemiBold">
                        {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.VIEW_DETAILS.PROBLEM_DETAILS' })}
                      </Typography>
                      <div className="row pt-4 pb-4">
                        <div className="col-3">
                          <div className="bg-success d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                            {
                                 item.has_photo === 'f'
                                   ? (
                                     <Typography variant="body">
                                       {intl.formatMessage({ id: 'GENERAL.NO_PHOTO' })}
                                     </Typography>
                                   )
                                   : (
                                     <div
                                       role="img"
                                       className={classes.profilePicture}
                                       style={{ backgroundImage: `url(https://cdn.recyclecoach.com/problem-reports/${item.img_path})` }}
                                       title="Problem Image"
                                     />
                                   )
                               }
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="mr-3">
                            <Typography className="mb-3" variant="bodySemiBold">
                              {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.VIEW_DETAILS.DESCRIPTION' })}
                            </Typography>
                            <Typography variant="bodySemiBold">
                              {item.problem_dscr}
                            </Typography>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row mb-5">
                            <Typography variant="bodySemiBold" className="col-5">
                              {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.VIEW_DETAILS.NAME' })}
                            </Typography>
                            <Typography variant="bodySemiBold" className="col">
                              {item.contact_full_nm}
                            </Typography>
                          </div>
                          <div className="row mb-5">
                            <Typography variant="bodySemiBold" className="col-5">
                              {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.VIEW_DETAILS.PHONE_NUMBER' })}
                            </Typography>
                            <Typography variant="bodySemiBold" className="col">
                              {item.contact_phone_nbr}
                            </Typography>
                          </div>
                          <div className="row mb-5">
                            <Typography variant="bodySemiBold" className="col-5">
                              {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.VIEW_DETAILS.ADDRESS' })}
                            </Typography>
                            <div className="col d-flex flex-column mt-3">
                              <Typography variant="bodySemiBold">
                                {item.address}
                              </Typography>
                              {item.location_latitude !== null && item.location_longitude !== null
                                      && (
                                      <a
                                        href={`https://www.google.com/maps/search/google+map+apis/@${item.location_latitude},${item.location_longitude},16z`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <Typography
                                          className={classes.viewDetails}
                                          variant="bodySemiBold"
                                        >
                                          {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.VIEW_ON_MAP' })}
                                        </Typography>
                                      </a>
                                      )}

                            </div>
                          </div>
                          <div className="row mb-5">
                            <Typography variant="bodySemiBold" className="col-5">
                              {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.VIEW_DETAILS.EMAIL' })}
                            </Typography>
                            <Typography variant="bodySemiBold" className="col">
                              {item.contact_email_addr}
                            </Typography>
                          </div>
                          <div className="row mb-5">
                            <Typography variant="bodySemiBold" className="col-5">
                              {intl.formatMessage({ id: 'REPORT.PROBLEM.DETAIL.TABLE.VIEW_DETAILS.DISTRICT' })}
                            </Typography>
                            <Typography variant="bodySemiBold" className="col">
                              {item.district_nm}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
          ))
}
      </div>
      <div className="mt-5 mb-4 d-flex justify-content-end align-items-center">
        <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={setPage}
          handleRowsPerPageChange={setRowsPerPage}
          dataLength={
          data
            .filter((item) => (((help && help.type === 'all') || !help) ? item : item.problem_type_cd === help.type))
            .filter((item) => item.toString().toLowerCase().includes(searchValue.toLowerCase())).length
        }
        />
      </div>
    </div>
  );
};

export default injectIntl(DetailTable);
