import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { PortletBody } from '../../../../../../_metronic/partials/content/Portlet';

import { MajorCampaignFeaturedLessonCard } from './index';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    border: '2px solid #2A95FF',
    borderRadius: 5,
    borderTop: 'none',
    position: 'relative',
    top: '-5px',
  },
}));

const MajorCampaignFeaturedLessons = ({
  intl,
  featuredLesson,
}) => {
  const classes = useStyles();
  const [page] = useState(1);

  return (
    <div className={classes.content}>
      <PortletBody>
        <div className="d-flex flex-wrap mt-3">
          {featuredLesson
            && featuredLesson
              .slice((page - 1) * 4, page * 4)
              .map((item) => (
                <MajorCampaignFeaturedLessonCard
                  key={item.id}
                  isDeletable={false}
                  data={item}
                />
              ))}
        </div>
      </PortletBody>
    </div>
  );
};

const mapStateToProps = (store) => ({
  featuredLesson: store.majorCampaigns.campaigns,

});

export default injectIntl(
  connect(mapStateToProps, null)(
    MajorCampaignFeaturedLessons
  )
);
