import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../redux/createReducer';
import initialState from './store';

const reminderReducer = persistReducer(
  { storage, key: 'reminder', whitelist: [] },
  createReducer(initialState, {
    SET_LOADING: (state) => Immutable({
      ...state,
      fetchState: 'send-request',
    }),
    RESET_LOADING: (state) => Immutable({
      ...state,
      fetchState: 'no-request'
    }),
    REMINDER_SET_VALUE_ACTION: (state, { payload }) => Immutable({
      ...state,
      newReminder: {
        ...state.newReminder,
        [payload.propertyName]: payload.data
      },
    }),
    REMINDER_RESET_VALUE_ACTION: (state) => Immutable({
      ...state,
      newReminder: initialState.newReminder
    }),
    REMINDER_LISTـRECEIVE: (state, { payload }) => Immutable({
      ...state,
      list: payload.data.data.reminders,
      listCount: payload.data.data.total,
      fetchState: 'succeed-request',
    }),
    REMINDER_LISTـFAIL: (state) => Immutable({
      ...state,
      fetchState: 'failed-request',
    }),
    DELETE_REMINDER_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.message,
      responseStatus: 'success'
    }),
    DELETE_REMINDER_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data.message,
      responseStatus: 'error'
    }),
    LOAD_REMINDER_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      newReminder: {
        ...state.newReminder,
        id: payload.data.reminder_id,
        district: payload.data.district_list,
        message: payload.data.message_body,
        dateFrom: payload.data.schedule_from,
        dateTo: payload.data.schedule_to,
        collectionType: payload.data.all_col === 't' ? 'all_collections' : 'selected_collection',
        collections: payload.data.collections_list,
      },
    }),
    LOAD_REMINDER_FAIL: (state) => Immutable({
      ...state,
    }),
    GET_COLLECTION_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      collectionList: payload.data,
    }),
    GET_COLLECTION_FAIL: (state) => Immutable({
      ...state,
    }),
    NEW_REMINDER_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.message,
      responseStatus: 'success',
      active: false
    }),
    NEW_REMINDER_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data.message,
      responseStatus: 'error'
    }),
    EDIT_REMINDER_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.message,
      responseStatus: 'success',
      active: false
    }),
    EDIT_REMINDER_FAIL: (state, { payload }) => Immutable({
      ...state,
      responseMessage: payload.data.message,
      responseStatus: 'error'
    }),
    NEW_REMINDER_REQUEST: (state) => Immutable({
      ...state,
      active: ''
    }),
    EDIT_REMINDER_REQUEST: (state) => Immutable({
      ...state,
      active: ''
    }),
    SET_FORM_LOADING: (state, payload) => Immutable({
      ...state,
      formLoading: payload.payload
    }),
  })
);

export default reminderReducer;
