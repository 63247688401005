import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  Typography, CustomButton, AddContent, Snackbar, CheckBox, CustomAlert
} from '../../components';

import {
  Portlet,
  PortletBody,
} from '../../../../_metronic/partials/content/Portlet';

import { convertHolidays } from '../helper';

import * as actions from './redux/actions';
import * as sharedActions from '../../redux/actions';

import CalendarChange from './components/CalendarChange';
import MultiDistrict from './components/MultiDistrict';
import SeasonalList from './components/SeasonalList';
import SeasonalCollections from './components/SeasonalCollections';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '65%',
    margin: 'auto',
    [theme.breakpoints.down(1670)]: {
      width: '75%'
    },
    [theme.breakpoints.down(1500)]: {
      width: '85%'
    },
    [theme.breakpoints.down(1350)]: {
      width: '100%'
    },
  },
  checkboxItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
  },
  nextButton: {
    width: 150,
    height: 48,
    marginTop: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 20,
  },
}));

const selectedHolidays = [];

const PeroetualSchedules = ({
  setDistrictAction,
  getHolidaysRequest,
  district,
  municipalities,
  holidays,
  loading,
  saveHolidaysRequest,
  holidaysChanges,
  getPerpetualSchedulesRequest,
  multiDistricts,
  setMultiDistricts,
  getSeasonalListRequest,
  seasonalList,
  setCompletedSetup,
  error,
  resetSeasonalList,
  perpetualSchedulesDistrict,
  seasonalListLoading,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [data, setData] = useState({
    main: [],
    additional: [],
  });
  const [showAdditional, setShowAdditional] = useState(false);

  const [noHolidaysChangesState, setNoHolidayChangesState] = useState(false);

  const [displayHolidayChange, setDisplayHolidayChange] = useState(false);

  const [savedHolidays, setSavedHolidays] = useState(false);
  const [gettingSeasonalList, setGettingSeasonalList] = useState(false);

  const [displaySeasonalCollection, setDisplaySeasonalCollection] = useState(false);
  const [showDisabledHolidayWarning, setShowDisabledHolidayWarning] =
    useState(false);


  useEffect(() => {
    resetSeasonalList();
  }, []);

  useEffect(() => {
    if (district?.district_id === '#all-districts#') {
      setDistrictAction(municipalities[0]);
    } else {
      const [projectId] = district.district_id.split('#$#');
      getHolidaysRequest(projectId);
      const districts = municipalities.map((municipality) => municipality.district_id);
      getPerpetualSchedulesRequest(districts);
    }
  }, [district]);

  useEffect(() => {
    if (municipalities && municipalities.length && municipalities.length > 1 && multiDistricts && multiDistricts.length) {
      setMultiDistricts([]);
    } else if (municipalities && municipalities.length === 1 && !multiDistricts.length) {
      // set default district if only one district
      setMultiDistricts([municipalities[0].district_id]);
    } else if (municipalities && municipalities.length && perpetualSchedulesDistrict && perpetualSchedulesDistrict.length && perpetualSchedulesDistrict.length === municipalities.length) {
      const districts = perpetualSchedulesDistrict.map((district) => `${district.project_id}#$#${district.district_id}`).join(',');
      getSeasonalListRequest(districts.split(','));
      setGettingSeasonalList(true);
    }
  }, [municipalities, perpetualSchedulesDistrict]);

  useEffect(() => {
    if (holidays) {
      const defaultHolidays = convertHolidays(holidays);
      setData(defaultHolidays, selectedHolidays);
    }
  }, [holidays]);

  useEffect(() => {
    if (!loading && savedHolidays && seasonalList && seasonalList.seasonal_list.length === 0) {
      setCompletedSetup(true);
      history.push('/schedules?completedSetup=true');
    }

  }, [loading, savedHolidays, seasonalList]);

  useEffect(() => {
    if (!seasonalListLoading && gettingSeasonalList) {
      setGettingSeasonalList(false);
      setSavedHolidays(true);
    }
  }, [seasonalListLoading]);

  const setNoHolidaysChangeHandler = () => {
    setNoHolidayChangesState((s) => !s);
    const updatedData = {
      main: data.main.map((i) => ({ ...i, checked: false })),
      additional: data.main.map((i) => ({ ...i, checked: false })),
    };
    setData(updatedData);
  };

  const selectedHolidayDates = [...data.main, ...data.additional]
    .filter((i) => i?.checked)
    .map((i) => i.date);
  const selectedHolidayIds = [...data.main, ...data.additional]
    .filter((i) => i?.checked)
    .map((i) => i.id);

  const isDisabled = (i) => (selectedHolidayDates.includes(i.date)
    && !selectedHolidayIds.includes(i.id))
    || noHolidaysChangesState;

  const toggleCheckedHandler = (day, type) => {
    const copyCountryData = { ...data };
    let copySection = copyCountryData[type];
    const updatedSection = copySection.map((i) => (i.name === day.name && i.date === day.date
      ? { ...i, checked: !i.checked }
      : i));
    copySection = updatedSection;
    copyCountryData[type] = copySection;
    setData(copyCountryData);
  };

  const handleHolidaysChange = () => {
    if (noHolidaysChangesState) {
      handleSaveHolidays(holidaysChanges);
    } else {
      setDisplayHolidayChange(true);
    }
  };

  const handleSaveHolidays = (holidayImpacts) => {
    const [projectId, districtId] = district.district_id.split('#$#');
    saveHolidaysRequest(projectId, districtId, selectedHolidayIds, holidayImpacts, multiDistricts);
    handleGetSeasonalList();
    // setSavedHolidays(true);
  };

  const handleGetSeasonalList = () => {
    if (multiDistricts && multiDistricts.length) {
      // gets the seasonal list for all districts (currently previously selected)
      const districts = perpetualSchedulesDistrict.map((district) => `${district.project_id}#$#${district.district_id}`).join(',');
      const allDistrictsSelected = [...multiDistricts, ...districts.split(',')].filter(district => district !== '');
      getSeasonalListRequest(allDistrictsSelected);
      setGettingSeasonalList(true);
    }
  };

  const goToSeasonalCollection = () => {
    setDisplaySeasonalCollection(true);
  };

  const canGoNext =
    data.main.some((i) => i.checked) ||
    data.additional.some((i) => i.checked) ||
    noHolidaysChangesState;

  return (
    <div className={classes.root}>
      <>
        {
          seasonalList && seasonalList.seasonal_list && seasonalList.seasonal_list.length ? (
            <>
              {
                displaySeasonalCollection ? (
                  <SeasonalCollections />
                ) : (
                  <SeasonalList goToSeasonalCollection={goToSeasonalCollection} />
                )
              }
            </>
          ) : (
            <>
              {
                displayHolidayChange ? (
                  <CalendarChange handleSaveHolidays={handleSaveHolidays} />
                ) : (
                  <>
                    {
                      municipalities && municipalities.length > 1 && !multiDistricts.length ? (
                        <MultiDistrict />
                      ) : (
                        <Portlet>
                          {
                            !loading && (
                              <PortletBody>
                                <Typography variant="h1" weight="bold" style={{ fontSize: 24 }}>
                                  Holiday Changes
                                </Typography>
                                <Typography variant="h2" style={{ margin: '10px 0', fontSize: 16 }}>
                                  Select only the holidays that affect collection.
                                </Typography>
                                <CheckBox
                                  style={{ margin: '10px 10px 10px 0px' }}
                                  label={(
                                    <Typography
                                      style={{ fontStyle: 'italic', fontSize: 13.5 }}
                                      variant="body"
                                    >
                                      Holidays never affect collection
                                    </Typography>
                                  )}
                                  checked={noHolidaysChangesState}
                                  onChange={setNoHolidaysChangeHandler}
                                  checkedIcon={
                                    <CheckCircleIcon fontSize="medium" style={{ color: '#00C8BC' }} />
                                  }
                                  icon={<RadioButtonUncheckedIcon fontSize="medium" />}
                                />
                                {data.main.map((i) => (
                                  <div key={i.name} className={classes.checkboxItem}>
                                    <CheckBox
                                      style={{ opacity: isDisabled(i) ? 0.3 : 1, marginRight: 10 }}
                                      label={<Typography variant="bodyTableSemiBold">{i.name}</Typography>}
                                      checked={i.checked}
                                      onChange={() => (
                                        noHolidaysChangesState
                                          ? undefined
                                          : isDisabled(i)
                                            ? setShowDisabledHolidayWarning(true)
                                            : toggleCheckedHandler(i, 'main')
                                      )}
                                      checkedIcon={
                                        <CheckCircleIcon fontSize="medium" style={{ color: '#00C8BC' }} />
                                      }
                                      icon={<RadioButtonUncheckedIcon fontSize="medium" />}
                                    />

                                    <Typography
                                      variant="body"
                                      style={{ fontSize: '11px', marginLeft: 10 }}
                                    >
                                      {i.date}
                                    </Typography>
                                  </div>
                                ))}
                                {!showAdditional && (
                                  <AddContent
                                    onClick={() => setShowAdditional((s) => !s)}
                                    label="Show additional holidays"
                                    style={{ margin: '10px 0 10px -3px' }}
                                  />
                                )}
                                {showAdditional && (
                                  <>
                                    <Typography
                                      style={{ margin: '15px 0 0 0' }}
                                      color={process.env.REACT_APP_COLOR_GRAY_SLATE}
                                      variant="h2"
                                    >
                                      ADDITIONAL HOLIDAY&apos;S
                                    </Typography>
                                    {data.additional.map((i) => (
                                      <div key={i.name} className={classes.checkboxItem}>
                                        <CheckBox
                                          style={{ opacity: isDisabled(i) ? 0.3 : 1, marginRight: 10 }}
                                          label={
                                            <Typography variant="bodySemiBold">{i.name}</Typography>
                                          }
                                          checked={i.checked}
                                          onChange={() => (
                                            noHolidaysChangesState
                                              ? undefined
                                              : isDisabled(i)
                                                ? setShowDisabledHolidayWarning(true)
                                                : toggleCheckedHandler(i, 'additional')
                                          )}
                                          checkedIcon={
                                            <CheckCircleIcon fontSize="medium" style={{ color: '#00C8BC' }} />
                                          }
                                          icon={<RadioButtonUncheckedIcon fontSize="medium" />}
                                        />
                                        <Typography
                                          variant="body"
                                          style={{ fontSize: '12px', marginLeft: 10 }}
                                        >
                                          {i.date}
                                        </Typography>
                                      </div>
                                    ))}
                                  </>
                                )}
                              </PortletBody>
                            )
                          }
                          <CustomAlert
                            open={showDisabledHolidayWarning}
                            setOpen={setShowDisabledHolidayWarning}
                            onSubmit={() => setShowDisabledHolidayWarning(false)}
                            description='This date has already been selected. If you would prefer this holiday name, un-select the other holiday and then you’ll be able to select this one.'
                            buttonNames={['Okay', '']}
                          />
                          <div className={classes.buttonContainer}>
                            <CustomButton
                              variant="contained"
                              size="large"
                              label={(
                                <Typography variant="h1" color="white">
                                  Next
                                </Typography>
                              )}
                              onClick={() => handleHolidaysChange()}
                              className={classes.nextButton}
                              disabled={!canGoNext}
                            />
                          </div>

                        </Portlet>
                      )
                    }
                  </>

                )
              }

            </>
          )
        }
      </>
      {
        error && (
          <Snackbar
            message="An error occurred. Please contact the Recycle Coach team."
            status="error"
          />
        )
      }
    </div>
  );
};

const mapStateToProps = (store) => ({
  district: store.common.district,
  municipalities: store.common.municipalities,
  holidays: store.perpetualSchedules.holidays,
  loading: store.perpetualSchedules.loading,
  holidaysChanges: store.perpetualSchedules.holidaysChanges,
  perpetualSchedulesDistrict: store.perpetualSchedules.districts,
  multiDistricts: store.perpetualSchedules.multiDistrict,
  seasonalList: store.perpetualSchedules.seasonalList,
  error: store.perpetualSchedules.error,
  seasonalListLoading: store.perpetualSchedules.seasonalListLoading,
});

export default injectIntl(connect(mapStateToProps, { ...actions, ...sharedActions })(PeroetualSchedules));
