import React from 'react';
import { injectIntl } from 'react-intl';
import {
  Link,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { toAbsoluteUrl } from '../../../_metronic';
import '../../../_metronic/_assets/sass/pages/login/login-1.scss';
import { CheckIcon } from '../../assets/icons';
import { Typography } from '../components';
import Login from './Login';
import LoginAs from './LoginAs';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import MFA from './MFA';
import OTP from './OTP';

const useStyles = makeStyles((theme) => ({
  aside: {
    position: 'relative',
    width: '100%',
    height: '60vh',
    backgroundColor: '#ffffff',
    backgroundImage: `url(${toAbsoluteUrl('/media/backgrounds/auth-page-bg.svg')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up(993)]: {
      width: '45%',
      minWidth: '645px',
      height: '100vh',
    },
  },
  websiteInfo: {
    position: 'absolute',
    bottom: 30,
    left: 80,
    [theme.breakpoints.up(993)]: {
      top: '35%',
      left: '30%',
    },
  },
  tickIcon: {
    width: 25,
    height: 25,
  },
}));

const AuthPage = ({
  intl
}) => {
  const classes = useStyles();

  return (
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <div
        id="kt_login"
        className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
          <div className={classes.aside}>
            <div className={classes.websiteInfo}>
              <Link to="/">
                <img
                  alt="Logo"
                  src={toAbsoluteUrl('/media/logos/logo-rc.svg')}
                />
              </Link>
              <Typography className="mt-2" variant="h1">
                {intl.formatMessage({ id: 'AUTH.GENERAL.TITLE' })}
              </Typography>
              <div className="d-flex pl-3 pt-3">
                <div className={classes.tickIcon}>
                  <CheckIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                </div>
                <Typography component="span" className="mt-2 ml-3" variant="h2Medium">
                  {intl.formatMessage({ id: 'AUTH.GENERAL.TEXT_1' })}
                </Typography>
              </div>
              <div className="d-flex pl-3 pt-2">
                <div className={classes.tickIcon}>
                  <CheckIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                </div>
                <Typography component="span" className="mt-2 ml-3" variant="h2Medium">
                  {intl.formatMessage({ id: 'AUTH.GENERAL.TEXT_2' })}
                </Typography>
              </div>
              <div className="d-flex pl-3 pt-2">
                <div className={classes.tickIcon}>
                  <CheckIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                </div>
                <Typography component="span" className="mt-2 ml-3" variant="h2Medium">
                  {intl.formatMessage({ id: 'AUTH.GENERAL.TEXT_3' })}
                </Typography>
              </div>
              <div className="d-flex pl-3 pt-2">
                <div className={classes.tickIcon}>
                  <CheckIcon color={process.env.REACT_APP_COLOR_PRIMARY} />
                </div>
                <Typography component="span" className="mt-2 ml-3" variant="h2Medium">
                  {intl.formatMessage({ id: 'AUTH.GENERAL.TEXT_4' })}
                </Typography>
              </div>
            </div>
          </div>
          <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
            <Switch>
              <Route path="/auth/login" component={Login} />
              <Route path="/auth/login-as" component={LoginAs} />
              <Route path="/auth/mfa" component={MFA} />
              <Route path="/auth/otp" component={OTP} />
              <Route path="/auth/forgot-password" component={ForgotPassword} />
              <Route path="/auth/reset-password" component={ResetPassword} />
              <Redirect from="/auth" exact to="/auth/login" />
              <Redirect to="/auth/login" />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(AuthPage);
