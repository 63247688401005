import React from 'react';
import { connect } from 'react-redux';
import Main from './components/Main';
import { Snackbar } from '../components';

const WasteAndRecyclingRules = ({
  responseMessage,
  responseStatus
}) => (
  <div className="d-flex justify-content-center align-items-center">
    <Snackbar
      message={responseMessage}
      status={responseStatus}
    />
    <Main />
  </div>
);


const mapStateToProps = (store) => ({
  responseMessage: store.wasteAndRecyclingRules.responseMessage,
  responseStatus: store.wasteAndRecyclingRules.responseStatus,
});

export default connect(mapStateToProps, null)(WasteAndRecyclingRules);
