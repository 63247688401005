import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { addCommasToNumber, removeCommasFromNumber } from '../../../../utils';
import { InfoTooltip, TextField, Typography } from '../../../components';
import * as actions from '../redux/actions';
import * as sharedActions from '../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  border: {
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`
  },
  value1: {
    color: '#3e95cd',
    [theme.breakpoints.down('450')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.down('370')]: {
      fontSize: '2.5rem',
    }
  },
  value2: {
    color: '#2e75cf',
    [theme.breakpoints.down('340')]: {
      fontSize: '3.5rem',
    },
    [theme.breakpoints.down('370')]: {
      fontSize: '2.5rem',
    }
  },
  value3: {
    color: '#3cbacd',
    [theme.breakpoints.down('340')]: {
      fontSize: '3.5rem',
    },
    [theme.breakpoints.down('370')]: {
      fontSize: '2.5rem',
    }
  },
  value4: {
    color: '#2a95ff',
    [theme.breakpoints.down('340')]: {
      fontSize: '3.5rem',
    },
    [theme.breakpoints.down('370')]: {
      fontSize: '2.5rem',
    }
  },
  peerValue: {
    color: process.env.REACT_APP_COLOR_GRAY_DARK,
    [theme.breakpoints.down('450')]: {
      fontSize: '2.5rem',
    }
  },
  circle1: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    backgroundColor: '#3e95cd'
  },
  circle2: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    backgroundColor: '#2e75cf'
  },
  circle3: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    backgroundColor: '#3cbacd'
  },
  circle4: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    backgroundColor: '#2a95ff'
  },
  description: {
    fontSize: '1.5rem',
    fontWeight: 400,
  },
  insidePeerData: {
    [theme.breakpoints.up('1200')]: {
      display: 'none !important'
    }
  }
}));

const PeerReportDetail = ({
  intl,
  data,
  households,
  district,
  fromDate,
  toDate,
  getMunicipalityHouseholdsRequest,
  setMunicipalityHouseholdsRequest,
  userReportRequest,
}) => {
  const classes = useStyles();
  const [householdCount, setHouseholdCount] = useState(0);
  const [household, setHousehold] = useState(households);

  useEffect(() => {
    getMunicipalityHouseholdsRequest({ districtId: district.district_id });
  }, []);

  useEffect(() => {
    setHouseholdCount(Number(households));
    setHousehold(Number(households));
  }, [households]);

  return (
    <>
      <div>
        <div className="row d-flex align-items-center p-4 mb-4">
          <div className="col-6" />
          <div className="col-3">
            <Typography variant="bodySemiBold">
              {intl.formatMessage({ id: 'REPORT.GENERAL.YOUR_RATING' })}
            </Typography>
          </div>
          <div className="col-3">
            <Typography className="d-flex align-items-center" variant="bodySemiBold">
              {intl.formatMessage({ id: 'REPORT.GENERAL.PEER_GROUP_RATING' })}
              <InfoTooltip tooltip={intl.formatMessage({ id: 'REPORT.GENERAL.SUMMARY.DESCRIPTION' })} />
            </Typography>
          </div>
        </div>
      </div>

      <div className={classes.border}>
        <div className="row d-flex align-items-center pb-4 pr-4 pl-4">
          <div className="col-1 mr-3 mr-xl-0">
            <div className={classes.circle1} />
          </div>
          <div className="col-10 col-xl-5">
            <Typography className="d-flex align-items-center" variant="h2">
              {intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.MOBILE.TITLE' })}
              <InfoTooltip tooltip={intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.MOBILE.DESCRIPTION' })} />
            </Typography>
          </div>
          <div className={`col-6 ${classes.insidePeerData}`} />
          <div className="col-3 mt-3 mt-xl-0">
            <Typography className={classes.value1} variant="numberSmall">
              {addCommasToNumber(data.mobile.rating)}
            </Typography>
          </div>
          <div className="col-3 mt-3 mt-xl-0">
            <Typography className={classes.peerValue} variant="numberSmall">
              {addCommasToNumber(data.mobile.peer_rating)}
            </Typography>
          </div>
        </div>
      </div>

      <div className={classes.border}>
        <div className="row d-flex align-items-center p-4">
          <div className="col-1 mr-3 mr-xl-0">
            <div className={classes.circle2} />
          </div>
          <div className="col-10 col-xl-5">
            <Typography className="d-flex align-items-center" variant="h2">
              {intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.WEB_APP.TITLE' })}
              <InfoTooltip tooltip={intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.WEB_APP.DESCRIPTION' })} />
            </Typography>
          </div>
          <div className={`col-6 ${classes.insidePeerData}`} />
          <div className="col-3 mt-3 mt-xl-0">
            <Typography className={classes.value2} variant="numberSmall">
              {addCommasToNumber(data.web_app.rating)}
            </Typography>
          </div>
          <div className="col-3 mt-3 mt-xl-0">
            <Typography className={classes.peerValue} variant="numberSmall">
              {addCommasToNumber(data.web_app.peer_rating)}
            </Typography>
          </div>
        </div>
      </div>

      <div className={classes.border}>
        <div className="row d-flex align-items-center p-4">
          <div className="col-1 mr-3 mr-xl-0">
            <div className={classes.circle3} />
          </div>
          <div className="col-10 col-xl-5">
            <Typography className="d-flex align-items-center" variant="h2">
              {intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.COMMUNICATION.TITLE' })}
              <InfoTooltip tooltip={intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.COMMUNICATION.DESCRIPTION' })} />
            </Typography>
          </div>
          <div className={`col-6 ${classes.insidePeerData}`} />
          <div className="col-3 mt-3 mt-xl-0">
            <Typography className={classes.value3} variant="numberSmall">
              {addCommasToNumber(data.communications.rating)}
            </Typography>
          </div>
          <div className="col-3 mt-3 mt-xl-0">
            <Typography className={classes.peerValue} variant="numberSmall">
              {addCommasToNumber(data.communications.peer_rating)}
            </Typography>
          </div>
        </div>
      </div>

      <div>
        <div className="row d-flex align-items-center p-4">
          <div className="col-1 mr-3 mr-xl-0">
            <div className={classes.circle4} />
          </div>
          <div className="col-10 col-xl-5">
            <Typography className="d-flex align-items-center" variant="h2">
              {intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.CALENDAR.TITLE' })}
              <InfoTooltip tooltip={intl.formatMessage({ id: 'REPORT.USERS.DETAIL.CATEGORIES.CALENDAR.DESCRIPTION' })} />
            </Typography>
          </div>
          <div className={`col-6 ${classes.insidePeerData}`} />
          <div className="col-3 mt-3 mt-xl-0">
            <Typography className={classes.value4} variant="numberSmall">
              {addCommasToNumber(data.calendars.rating)}
            </Typography>
          </div>
          <div className="col-3 mt-3 mt-xl-0">
            <Typography className={classes.peerValue} variant="numberSmall">
              {addCommasToNumber(data.calendars.peer_rating)}
            </Typography>
          </div>
        </div>
      </div>
      <Typography variant="h2Medium" className="row f-flex align-items-center">
        {intl.formatMessage({ id: 'REPORT.USERS.DETAIL.PEER_REPORT.DESCRIPTION.PART_1' })}
        <span className="col-12 col-xl-3">
          <TextField
            value={addCommasToNumber(householdCount)}
            onChange={(value) => setHouseholdCount(removeCommasFromNumber(value))}
            onBlur={() => {
              if (Number(removeCommasFromNumber(householdCount)) !== Number(household)) {
                setMunicipalityHouseholdsRequest({
                  districtId: district.district_id,
                  households: householdCount
                });
                userReportRequest({
                  municipality: encodeURIComponent(district.district_id),
                  from: fromDate,
                  to: toDate
                });
                setHousehold(householdCount);
              }
            }}
          />
        </span>
        {intl.formatMessage({ id: 'REPORT.USERS.DETAIL.PEER_REPORT.DESCRIPTION.PART_2' })}
      </Typography>
    </>
  );
};

const mapStateToProps = (store) => ({
  households: store.common.households,
  district: store.common.district,
  fromDate: store.common.fromDate,
  toDate: store.common.toDate,
});

export default injectIntl(connect(
  mapStateToProps,
  { ...actions, ...sharedActions }
)(PeerReportDetail));
