import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

import { Typography } from '../../components';
import Switch from '../../components/Switch';

const useStyles = makeStyles({
  container: {
    paddingTop: 20,
    paddingBottom: 20,
    marginTop: 20,
    marginBottom: 20,
    borderTop: '2px solid #eee',
    borderBottom: '2px solid #eee'
  }
});

const Filter = ({
  intl,
  children,
  changeFilter,
  isActive,
  rightLabel,
  leftLabel
}) => {
  const { container } = useStyles();

  return (
    <div className={container}>
      <Typography variant="h2">
        {children}
      </Typography>
      <div>
        <Switch
          leftLabel={leftLabel}
          rightLabel={rightLabel}
          onChange={(e) => changeFilter(e)}
          isActive={isActive}
        >
          <span style={{ display: 'inline-block', marginRight: 20 }}>
            {intl.formatMessage({ id: 'GENERAL.SHOWING' })}
          </span>
        </Switch>
      </div>
    </div>
  );
};

export default injectIntl(Filter);
