import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Formik } from 'formik';
import zxcvbn from 'zxcvbn';

import * as actions from './redux/actions';
import {
  Body,
  Title,
  SubTitle,
  Form,
  Alert,
  InputField,
  Action,
  Submit,
  Back,
} from '../components';

const ResetPassword = ({
  intl,
  fetchState,
  firstName,
  lastName,
  resetLoadingAction,
  getNameRequest,
  resetPasswordRequest,
}) => {
  const isSucceedRequest = fetchState === 'succeed-request';
  const isSendRequest = fetchState === 'send-request';
  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get('token');

  useEffect(() => {
    getNameRequest(token);
  }, [getNameRequest, token]);

  if (isSucceedRequest) {
    resetLoadingAction();
    return <Redirect to="/auth/login" />;
  }

  return (
    <Body>
      <Title text="AUTH.RESET.TITLE" />
      {firstName && lastName && <SubTitle text={`${firstName} ${lastName}`} />}
      <Formik
        initialValues={{ newPassword: '', newPasswordRepeat: '' }}
        validate={(values) => {
          const errors = {};

          if (!values.newPassword) {
            errors.newPassword = intl.formatMessage({
              id: 'VALIDATION.REQUIRED_FIELD'
            });
          }

          if (zxcvbn(values.newPassword).score < 4) {
            errors.newPassword = intl.formatMessage({
              id: 'AUTH.VALIDATION.WEAK_PASSWORD'
            });
          }

          if (!values.newPasswordRepeat) {
            errors.newPasswordRepeat = intl.formatMessage({
              id: 'VALIDATION.REQUIRED_FIELD'
            });
          }

          if (zxcvbn(values.newPasswordRepeat).score < 4) {
            errors.newPasswordRepeat = intl.formatMessage({
              id: 'AUTH.VALIDATION.WEAK_PASSWORD'
            });
          }
          if (values.newPassword !== values.newPasswordRepeat) {
            errors.newPasswordRepeat = intl.formatMessage({
              id: 'AUTH.VALIDATION.DIFFERENT_PASSWORD'
            });
          }

          return errors;
        }}
        onSubmit={(values) => resetPasswordRequest(
          values.newPassword,
          values.newPasswordRepeat,
          token
        )}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            <Alert
              condition={fetchState === 'failed-request'}
              responseMessage={{
                status: 'error',
                message: intl.formatMessage({
                  id: 'AUTH.RESET.EXPIRED_TOKEN'
                })
              }}
            />
            <InputField
              type="password"
              label={intl.formatMessage({ id: 'AUTH.INPUT.NEW_PASSWORD' })}
              name="newPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.newPassword}
              helperText={touched.newPassword && errors.newPassword}
              error={Boolean(touched.newPassword && errors.newPassword)}
            />
            <InputField
              type="password"
              label={intl.formatMessage({ id: 'AUTH.INPUT.REPEAT_PASSWORD' })}
              name="newPasswordRepeat"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.newPasswordRepeat}
              helperText={touched.newPasswordRepeat && errors.newPasswordRepeat}
              error={Boolean(touched.newPasswordRepeat && errors.newPasswordRepeat)}
            />
            <Action>
              <Link to="/auth">
                <Back title="GENERAL.BACK" onClick={resetLoadingAction} />
              </Link>
              <Submit title="AUTH.GENERAL.SUBMIT_BUTTON" isSubmitting={isSendRequest} />
            </Action>
          </Form>
        )}
      </Formik>
    </Body>
  );
};

const mapStateToProps = (store) => ({
  fetchState: store.auth.fetchState,
  firstName: store.auth.firstname,
  lastName: store.auth.lastname,
});

export default injectIntl(connect(mapStateToProps, actions)(ResetPassword));
