import { put, call, takeLatest } from 'redux-saga/effects';

import actionTypes from './actionTypes';
import * as actions from './actions';
import {
  getUserReport
} from '../apis';

function* userReportSaga({
  payload: {
    county, municipality, from, to
  }
}) {
  try {
    yield put(actions.setUserReportLoadingAction('send-request'));
    const res = yield call(getUserReport, {
      county, municipality, from, to
    });
    if (res.data) {
      if (res.data.status === 'success') {
        yield put(actions.userReportReceive(res.data));
      } else if (res.data.status === 'fail') {
        throw new Error(res.data.message);
      }
    } else if (res.error) {
      throw new Error(res.error.message);
    }
  } catch (error) {
    yield put(actions.userReportFail({
      data: {
        status: 'error',
        message: error.message
      }
    }));
  }
}

/**
 * Saga watcher function
 */
function* userReportSagas() {
  yield takeLatest(actionTypes.GET_USER_REPORT_REQUEST, userReportSaga);
}

export default userReportSagas;
