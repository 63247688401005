import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';

import moment from 'moment';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { Opacity, Typography } from '../../../components';
import { BlogCard } from '.';
import { changeCurrentMonthsIndexHandler, findCurrentMonthsDataIndexHandler } from '../../../../utils';

const BlogsContent = ({
  data,
  intl,
  saveCurrentBlogIndex,
  blogIndex,
}) => {
  const currentDate = moment().format('YYYY-MM');
  // const [currentBlogIndex, setCurrentBlogIndex] = useState(findCurrentMonthsDataIndexHandler(data));
  const [currentBlogIndex, setCurrentBlogIndex] = useState(blogIndex || findCurrentMonthsDataIndexHandler(data));

  const { date_from, month, blogs } = data[currentBlogIndex];

  const changeBlogsMonthHandler = (direction) => {
    const newCurrentMonthsIndex = changeCurrentMonthsIndexHandler(direction, currentBlogIndex, data.length)
    saveCurrentBlogIndex(newCurrentMonthsIndex)
    setCurrentBlogIndex(
      newCurrentMonthsIndex
    );
  };

  const isDeletable = date_from.slice(0, 7) === currentDate;


  const dateDiff = moment().diff(moment(month), 'months');

  const currentMonth = moment().format('MMMM YYYY');


  useEffect(() => {
    if (blogIndex && blogIndex === currentBlogIndex) {
      setCurrentBlogIndex(blogIndex);
    } else {
      const curentMonthIndex = findCurrentMonthsDataIndexHandler(data);
      setCurrentBlogIndex(curentMonthIndex)
    }
  }, []);


  // if (blogIndex && blogIndex !== currentBlogIndex) {
  //   console.log('blogIndex -- ', blogIndex)
  //   console.log('currentBlogIndex 1', currentBlogIndex)

  //   setCurrentBlogIndex(blogIndex);
  //   // setCurrentBlogIndex(blogIndex);
  // } else {
  //   console.log('blogIndex else', blogIndex)
  //   console.log('currentBlogIndex else', currentBlogIndex)
  //   const testIndex = findCurrentMonthsDataIndexHandler(data);
  //   console.log('testIndex', testIndex)


  //   setCurrentBlogIndex(testIndex)
  // }


  return (
    <>
      <Typography color="#6C7293" variant="h2" style={{ fontSize: 17 }}>
        {dateDiff === 0 && month === currentMonth ?
          intl.formatMessage({ id: 'EDUCATION.CURRENT.CONTENT' })
          : dateDiff <= 0
            ? intl.formatMessage({ id: 'EDUCATION.UPCOMING.CONTENT' })
            : intl.formatMessage({ id: 'EDUCATION.PRIOR.CONTENT' })
        }

      </Typography>
      <div className="d-flex mt-1 align-items-center">
        <NavigateBeforeIcon
          onClick={() => changeBlogsMonthHandler('backward')}
          fontSize="large"
          style={{
            color: '#757575',
            transition: 'all .3s',
            cursor: currentBlogIndex === data.length - 1 ? 'not-allowed' : 'pointer',
            opacity: currentBlogIndex === data.length - 1 ? 0.5 : 1,
          }}
          disabled={currentBlogIndex === data.length - 1}
        />
        <Typography style={{ textAlign: 'center', size: 14 }} color="#757575" variant="bodySemiBold">
          {month}
        </Typography>
        <NavigateNextIcon
          onClick={() => changeBlogsMonthHandler('forward')}
          fontSize="large"
          style={{
            color: '#757575',
            transition: 'all .3s',
            cursor: currentBlogIndex === 0 ? 'not-allowed' : 'pointer',
            opacity: currentBlogIndex === 0 ? 0.5 : 1,
          }}
          disabled={currentBlogIndex === 0}
        />
      </div>

      <Opacity key={currentBlogIndex}>
        <div className="d-flex flex-wrap mt-3">
          {blogs.map((item) => (
            <BlogCard key={item.id} isDeletable={isDeletable} data={item} />
          ))}
        </div>
      </Opacity>
    </>
  );
};

export default injectIntl(BlogsContent);
