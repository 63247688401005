/* eslint-disable import/prefer-default-export */
import cts from '../../../crud/cts';

/**
 * Discovery zone report API which fetches data
 */
export const getResidentKnowledgeIndicatorReport = async ({
  municipality, from, to, county
}) => cts(
  'get',
  `reports/resident-knowledge-indicator?county=${county}&from=${from}&to=${to}&municipality=${municipality}`,
);
