import actionTypes from './actionTypes';

// Profile actions for setting loading status
export const setLoadingAction = () => ({
  type: actionTypes.SET_LOADING
});

export const resetLoadingAction = () => ({
  type: actionTypes.RESET_LOADING
});

// Profile actions for editing user Profile
export const editProfileRequest = (user) => ({
  type: actionTypes.EDIT_PROFILE_REQUEST,
  payload: { user }
});

export const editProfileReceive = (data) => ({
  type: actionTypes.EDIT_PROFILE_RECEIVE,
  payload: { ...data }
});

export const editProfileFail = (error) => ({
  type: actionTypes.EDIT_PROFILE_FAIL,
  payload: { ...error }
});

// Profile actions for uploading user Profile image
export const editProfilePictureRequest = (image) => ({
  type: actionTypes.EDIT_PROFILE_PICTURE_REQUEST,
  payload: { image }
});

export const editProfilePictureReceive = (data) => ({
  type: actionTypes.EDIT_PROFILE_PICTURE_RECEIVE,
  payload: data
});

export const editProfilePictureFail = (error) => ({
  type: actionTypes.EDIT_PROFILE_PICTURE_FAIL,
  payload: { ...error }
});
