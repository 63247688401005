import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Immutable from 'seamless-immutable';

import createReducer from '../../../redux/createReducer';
import initialState from './store';

const wasteTypeReducer = persistReducer(
  { storage, key: 'wasteType', whitelist: [] },
  createReducer(initialState, {
    SET_WASTE_TYPE_LOADING_ACTION: (state, { payload }) => Immutable({
      ...state,
      fetchState: payload,
      responseMessage: initialState.responseMessage,
    }),

    SET_WASTE_TYPE_SNACKBAR_ACTION: (state, { payload }) => Immutable({
      ...state,
      snackbarState: payload.status,
      responseMessage: payload.message,
    }),


    GET_WASTE_TYPES_LIST_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'succeed-request',
      wasteTypeList: payload.data || []
    }),

    GET_WASTE_TYPES_LIST_FAIL: (state, { payload }) => Immutable({
      ...state,
      fetchState: 'failed-request',
      responseStatus: 'error',
      responseMessage: payload.message,
    }),


    CREATE_WASTE_TYPE_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'succeed-request',
      responseStatus: 'success',
      responseMessage: 'Waste Type has been created',
      wasteTypeList: [
        {
          waste_type_id: payload.id,
          waste_type_admin_nm: payload.name,
          coll_admin_nm: null,
          category_counts: '0',
          depot_counts: '0',
          event_counts: '0',
        },
        ...state.wasteTypeList
      ]
    }),

    CREATE_WASTE_TYPE_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: payload.status,
      responseMessage: payload.message,
    }),


    DELETE_WASTE_TYPE_RECEIVE: (state, { payload }) => Immutable({
      ...state,
      snackbarState: 'succeed-request',
      responseStatus: 'success',
      responseMessage: 'Waste Type has been removed',
      wasteTypeList: state.wasteTypeList.filter((item) => item.waste_type_id !== payload.wasteTypeId)
    }),

    DELETE_WASTE_TYPE_FAIL: (state, { payload }) => Immutable({
      ...state,
      snackbarState: payload.status,
      responseMessage: payload.message,
    }),
  })
);

export default wasteTypeReducer;
