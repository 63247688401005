/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { Portlet, PortletBody, PortletHeader } from '../../../../_metronic/partials/content/Portlet';
import {
  TextField,
  CustomButton,
  Status,
  Typography,
} from '../../components';
import {
  requiredValidation,
  emailValidation,
  phoneNumberValidation,
  passwordStrengthValidation,
  confirmPasswordValidation,
} from '../../../utils';
import * as actions from './redux/actions';
import { checkEmailDuplicate } from '../../redux/apis';

const useStyles = makeStyles((theme) => ({
  verticalLine: {
    borderLeft: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
    position: 'relative',
    left: '50%',
    height: '100%',
  },
  avatar: {
    border: `1px solid ${process.env.REACT_APP_COLOR_GRAY_DARK}`,
    borderRadius: 5,
    width: 200,
    maxHeight: 165,
    marginRight: theme.spacing(2),
  },
  profilePicture: {
    borderRadius: 5,
    width: '100%',
    height: '100%',
    border: 'none',
    backgroundColor: '#ebebeb',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'relative'
  },
  changePicture: {
    bottom: 0,
    backgroundColor: process.env.REACT_APP_COLOR_GRAY_DARK,
    opacity: 0.8,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    outline: 'none',
  },
  changePictureText: {
    color: process.env.REACT_APP_COLOR_SECONDARY,
  },
  submitButton: {
    width: 'max-content'
  }
}));

const Profile = ({
  intl,
  userInfo,
  fetchState,
  editProfileRequest,
  editProfilePictureRequest,
  resetLoadingAction,
}) => {
  const classes = useStyles();
  const isSucceedRequest = fetchState === 'succeed-request';
  const isFailedRequest = fetchState === 'failed-request';
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailDuplicate, setIsEmailDuplicate] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [millisecond, setMillisecond] = useState(0);

  useEffect(() => {
    resetLoadingAction();
    setFirstName(userInfo.first_name);
    setLastName(userInfo.last_name);
    setEmail(userInfo.email);
    setPhoneNumber(userInfo.phone);
    setJobTitle(userInfo.job_title);
  }, []);

  useEffect(() => {
    if (isSucceedRequest) {
      const time = new Date();
      setMillisecond(time.getMilliseconds());
    }
  }, [isSucceedRequest]);

  const callCheckEmailDuplicateAPI = async () => {
    const result = await checkEmailDuplicate({
      username: userInfo.user_name,
      email
    });
    setIsEmailDuplicate(result.data.data.email_user_duplicate);
  };

  const handleSubmit = () => {
    editProfileRequest({
      first_name: firstName,
      last_name: lastName,
      old_pass_word: currentPassword,
      pass_word: newPassword,
      email,
      phone: phoneNumber,
      job_title: jobTitle,

    });
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="w-75">
        {isSucceedRequest
        && (
        <Status color={process.env.REACT_APP_COLOR_TERTIARY}>
          { intl.formatMessage({ id: 'PROFILE.SUCCESS_MESSAGE' }) }
        </Status>
        )}
        {isFailedRequest
        && (
        <Status color={process.env.REACT_APP_COLOR_DELETE}>
          { intl.formatMessage({ id: 'PROFILE.FAIL_MESSAGE' }) }
        </Status>
        )}
        <Portlet>
          <PortletHeader title={intl.formatMessage({ id: 'PROFILE.HEADER' })} />
          <PortletBody>
            <div className="row">
              <div className="col-12 col-lg my-2">
                <div className="d-flex ml-0 mb-3">
                  <div className={`position-relative ${classes.avatar}`}>
                    <div
                      role="img"
                      className={classes.profilePicture}
                      style={{ backgroundImage: `url(${userInfo.image_profile}?${millisecond})` }}
                      title="Profile Image"
                    />
                    <label
                      className={`position-absolute d-flex justify-content-center w-100 m-0 p-2 ${classes.changePicture}`}
                      htmlFor="file-upload"
                    >
                      <Typography className={classes.changePictureText} variant="body">
                        {intl.formatMessage({ id: 'PROFILE.CHANGE_PICTURE' })}
                      </Typography>
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(e) => (
                        e.target.files[0]
                          ? editProfilePictureRequest(e.target.files[0])
                          : null
                      )}
                    />
                  </div>
                  <div>
                    <div className="d-flex justify-content-between">
                      <Typography variant="body">
                        {intl.formatMessage({ id: 'PROFILE.USERNAME' })}
                      </Typography>
                      <Typography variant="body">
                        {userInfo.user_name}
                      </Typography>
                    </div>
                    <TextField
                      className="mt-4"
                      label={intl.formatMessage({ id: 'PROFILE.FIRST_NAME' })}
                      value={firstName}
                      onChange={setFirstName}
                      invalidText={!requiredValidation(firstName) && intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })}
                    />
                    <TextField
                      className="mt-4"
                      label={intl.formatMessage({ id: 'PROFILE.LAST_NAME' })}
                      value={lastName}
                      onChange={setLastName}
                      invalidText={!requiredValidation(lastName) && intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })}
                    />
                  </div>
                </div>
                <TextField
                  className="mt-4"
                  label={intl.formatMessage({ id: 'PROFILE.EMAIL' })}
                  value={email}
                  onChange={setEmail}
                  onBlur={callCheckEmailDuplicateAPI}
                  invalidText={
                    !requiredValidation(email)
                      ? intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
                      : !emailValidation(email)
                        ? intl.formatMessage({ id: 'VALIDATION.EMAIL' })
                        : isEmailDuplicate && intl.formatMessage({ id: 'VALIDATION.EMAIL_DUPLICATE' })
                  }
                />
                <TextField
                  className="mt-4"
                  label={intl.formatMessage({ id: 'PROFILE.PHONE_NUMBER' })}
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  invalidText={!phoneNumberValidation(phoneNumber) && intl.formatMessage({ id: 'VALIDATION.PHONE_NUMBER' })}
                />
                <TextField
                  className="mt-4"
                  label={intl.formatMessage({ id: 'PROFILE.JOB_TITLE' })}
                  value={jobTitle}
                  onChange={setJobTitle}
                  invalidText={!requiredValidation(jobTitle) && intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })}
                />
              </div>
              <div className="col-1 position-relative">
                <div className={`${classes.verticalLine}`} />
              </div>
              <div className="col-12 col-lg my-2 d-flex flex-column justify-content-between">
                <div>
                  <TextField
                    label={intl.formatMessage({ id: 'PROFILE.CURRENT_PASSWORD' })}
                    type="password"
                    value={currentPassword}
                    onChange={setCurrentPassword}
                    invalidText={newPassword !== '' && currentPassword === '' && intl.formatMessage({ id: 'VALIDATION.CHANGE_PASSWORD' })}
                  />
                  <TextField
                    className="mt-4"
                    label={intl.formatMessage({ id: 'PROFILE.NEW_PASSWORD' })}
                    type="password"
                    value={newPassword}
                    onChange={setNewPassword}
                    invalidText={!passwordStrengthValidation(newPassword) && intl.formatMessage({ id: 'VALIDATION.WEAK_PASSWORD' })}
                  />
                  <TextField
                    className="mt-4"
                    label={intl.formatMessage({ id: 'PROFILE.CONFIRM_PASSWORD' })}
                    type="password"
                    value={confirmPassword}
                    onChange={setConfirmPassword}
                    invalidText={!confirmPasswordValidation(newPassword, confirmPassword) && intl.formatMessage({ id: 'VALIDATION.DIFFERENT_PASSWORD' })}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <CustomButton
                    className={classes.submitButton}
                    variant="contained"
                    label={intl.formatMessage({ id: 'PROFILE.UPDATE_PROFILE' })}
                    onClick={handleSubmit}
                    disabled={
                      !requiredValidation(firstName)
                      || !requiredValidation(lastName)
                      || !requiredValidation(email) || !emailValidation(email) || isEmailDuplicate
                      || !phoneNumberValidation(phoneNumber)
                      || !requiredValidation(jobTitle)
                      || (newPassword !== '' && currentPassword === '')
                      || !passwordStrengthValidation(newPassword)
                      || !confirmPasswordValidation(newPassword, confirmPassword)
                    }
                  />
                </div>
              </div>
            </div>
          </PortletBody>
        </Portlet>
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  userInfo: store.auth.user_info,
  fetchState: store.auth.fetchState,
});

export default injectIntl(
  connect(
    mapStateToProps,
    actions,
  )(Profile)
);
